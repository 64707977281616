import { useOrbCommStore, useEtaStore } from '@/stores';
import MapOrbcomm from '@/views/showonmap/MapOrbcomm_view.vue';
import $ from "jquery";

export default {
  name: "OrbComm",
  components: {
    MapOrbcomm,
  },
  data() {
    return {
      orbcomm_store: useOrbCommStore(),
      eta_store: useEtaStore(),
      MapOrbcommComp: MapOrbcomm,
      map: null,
      marker: null,
    }
  },

  mounted() {
    this.orbcomm_store.fetch_orbcomm()
    var mother = this
    window.showonmap_orbcomm = function (id) {
      mother.orbcomm_store.showonMap(id);
    }

    $(window).scroll(function () {
      if ($(window).scrollTop() + $(window).height() > $(document).height() - 100) {
        mother.orbcomm_store.isBottom = true
      } else {
        mother.orbcomm_store.isBottom = false
      }
    });
  },

  methods: {
  },

  watch: {
  },
  computed: {
  }
};