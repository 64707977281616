import { defineStore } from 'pinia';
import { useEtaStore, useCustomRangeStore, useAuthStore } from '../index';
import api from "@/plugins/axios";
import $ from "jquery";
import DataTable from 'datatables.net-dt';
import L from "leaflet";


export const useSummaryStore = defineStore({
    id: 'summary',
    state: () => ({
        eta_store: useEtaStore(),
        range_store: useCustomRangeStore(),
        authStore: useAuthStore(),
        isBottom: false,
        is_showdetail: false,

        sum_fleetarr_data: null,
        sum_fleetarr_table: null,
        search_fleetarr: null,
        th_fleetarr_arr: [
            "#",
            "Type",
            "Amount",
            "",
        ],
        td_fleetarr_arr: [
            0,
            0,
            0,
            24.5
        ],
        page_off_arr_fleetarr: [
            10,
            25,
            50,
            100
        ],
        page_off_fleetarr: 10,


        sum_sailinkarr_data: null,
        sum_sailinkarr_table: null,
        search_sailinkarr: null,
        th_sailinkarr_arr: [
            "#",
            "Type",
            "Amount",
            "",
        ],
        td_sailinkarr_arr: [
            0,
            0,
            0,
            24.5
        ],
        page_off_arr_sailinkarr: [
            10,
            25,
            50,
            100
        ],
        page_off_sailinkarr: 10,

        sum_thurayaarr_data: null,
        sum_thurayaarr_table: null,
        search_thurayaarr: null,
        th_thurayaarr_arr: [
            "#",
            "Type",
            "Amount",
            "",
        ],
        td_thurayaarr_arr: [
            0,
            0,
            0,
            24.5
        ],
        page_off_arr_thurayaarr: [
            10,
            25,
            50,
            100
        ],
        page_off_thurayaarr: 10,


        sum_svarr_data: null,
        sum_svarr_table: null,
        search_svarr: null,
        th_svarr_arr: [
            "#",
            "Type",
            "Amount",
            "",
        ],
        td_svarr_arr: [
            0,
            0,
            0,
            24.5
        ],
        page_off_arr_svarr: [
            10,
            25,
            50,
            100
        ],
        page_off_svarr: 10,


        sum_terminalarr_data: null,
        sum_terminalarr_table: null,
        search_terminalarr: null,
        th_terminalarr_arr: [
            "#",
            "Type",
            "Amount",
            "",
        ],
        td_terminalarr_arr: [
            0,
            0,
            0,
            24.5
        ],
        page_off_arr_terminalarr: [
            10,
            25,
            50,
            100
        ],
        page_off_terminalarr: 10,


        sum_iparr_data: null,
        sum_iparr_table: null,
        search_iparr: null,
        th_iparr_arr: [
            "#",
            "Type",
            "Amount",
            "",
        ],
        td_iparr_arr: [
            0,
            0,
            0,
            24.5
        ],
        page_off_arr_iparr: [
            10,
            25,
            50,
            100
        ],
        page_off_iparr: 10,


        sum_rparr_data: null,
        sum_rparr_table: null,
        search_rparr: null,
        th_rparr_arr: [
            "#",
            "Type",
            "Amount",
            "",
        ],
        td_rparr_arr: [
            0,
            0,
            0,
            24.5
        ],
        page_off_arr_rparr: [
            10,
            25,
            50,
            100
        ],
        page_off_rparr: 10,

        sum_iotarr_data: null,
        sum_iotarr_table: null,
        search_iotarr: null,
        th_iotarr_arr: [
            "#",
            "Type",
            "Amount",
            "",
        ],
        td_iotarr_arr: [
            0,
            0,
            0,
            24.5
        ],
        page_off_arr_iotarr: [
            10,
            25,
            50,
            100
        ],
        page_off_iotarr: 10,


        sum_userarr_data: null,
        sum_userarr_table: null,
        search_userarr: null,
        th_userarr_arr: [
            "#",
            "Type",
            "Amount",
            "",
        ],
        td_userarr_arr: [
            0,
            0,
            0,
            24.5
        ],
        page_off_arr_userarr: [
            10,
            25,
            50,
            100
        ],
        page_off_userarr: 10,


        sum_grarr_data: null,
        sum_grarr_table: null,
        search_grarr: null,
        th_grarr_arr: [
            "#",
            "Type",
            "Amount",
            "",
        ],
        td_grarr_arr: [
            0,
            0,
            0,
            24.5
        ],
        page_off_arr_grarr: [
            10,
            25,
            50,
            100
        ],
        page_off_grarr: 10,


        sum_ptarr_data: null,
        sum_ptarr_table: null,
        search_ptarr: null,
        th_ptarr_arr: [
            "#",
            "Type",
            "Amount",
            "",
        ],
        td_ptarr_arr: [
            0,
            0,
            0,
            24.5
        ],
        page_off_arr_ptarr: [
            10,
            25,
            50,
            100
        ],
        page_off_ptarr: 10,


        summary_detail_data: null,
        summary_detail_table: null,
        summary_detail_cat: '',
        th_sumdetail_arr: [
            "#",
            "Name",
            "Serial Number",
            "Thuraya Number",
            "Company",
            "Group",
        ],
        td_sumdetail_arr: [
            0,
            0,
            0,
            0,
            0,
            0,
        ],
        th_sumdetail_user_arr: [
            "#",
            "Name",
            "Email",
            "Username",
            "Role",
            "Last Login",
        ],
        td_sumdetail_user_arr: [
            0,
            0,
            0,
            0,
            0,
            0,
        ],
    }),

    actions: {


        // DATA FETCH FUNCTIONS
        //------------------------------------------------------------------
        //Fetch  Summary Data
        async fetch_summary() {
            await api.get(`/api/v1/navplus/summary`, {}
            ).then((res) => {
                console.log("SUMMARY RESPONSE");
                console.log(res);

                this.sum_fleetarr_data = res.data.fleetArr
                this.sum_sailinkarr_data = res.data.sailinkArr
                this.sum_thurayaarr_data = res.data.thurayaArr
                this.sum_svarr_data = res.data.svArr
                this.sum_terminalarr_data = res.data.terminalArr
                this.sum_iparr_data = res.data.ipArr
                this.sum_rparr_data = res.data.rpArr
                this.sum_iotarr_data = res.data.iotArr
                this.sum_userarr_data = res.data.userArr
                this.sum_grarr_data = res.data.grArr
                this.sum_ptarr_data = res.data.ptArr

                console.log(this.sum_fleetarr_data);

                if (this.search_fleetarr == null || this.search_fleetarr.length == 0) {
                    this.initTable_fleetarr();
                    this.initTable_sailinkarr();
                    this.initTable_thurayaarr();
                    this.initTable_svarr();
                    this.initTable_terminalarr();
                    this.initTable_iparr();
                    this.initTable_rparr();
                    this.initTable_iotarr();
                    this.initTable_userarr();
                    this.initTable_grarr();
                    this.initTable_ptarr(this.sum_ptarr_data);

                }
            }).catch((err) => {
            }).then(() => {
            });
        },
        //Fetch  Summary Data
        async fetch_summary_detail(item) {
            this.summary_detail_data = null



            await api.post("/api/v1/navplus/summary/list", {
                type: item[2]
            }
            ).then((res) => {
                console.log("SUMMARY DETAIL RESPONSE");
                console.log(res);
                this.summary_detail_data = res.data
                var mom = this;

                setTimeout(() => {
                    if (mom.summary_detail_cat == 'VESSEL') {
                        mom.initTable_sumdetail(res.data.vessels)
                    } else {
                        mom.initTable_sumdetail_user(res.data.users)
                    }

                }, 1000);
            }).catch((err) => {
            }).then(() => {
            });
        },
        //Fetch  Group Detail
        async fetch_group_detail(item) {
            await api.post("/api/v1/navplus/summary/list", {
                type: item[2]
            }
            ).then((res) => {
                console.log("SUMMARY DETAIL GROUP RESPONSE");
                console.log(res);
                setTimeout(() => {
                    this.initTable_ptarr(res.data.pts);
                }, 1000);
            }).catch((err) => {
            }).then(() => {
            });
        },
        //------------------------------------------------------------------



        //TABLE SEARCH & PAGE FUNCTIONS
        //------------------------------------------------------------------
        //search fleetarr
        fleetarrTable_search() {
            if (this.search_fleetarr != null && this.search_fleetarr.length > 0) {
                this.sum_fleetarr_table.search(this.search_fleetarr).draw();
            }
        },
        //data shorting fleetarr table 
        fleetarrTable_showof(page) {
            console.log("PAGE : " + page);
            console.log(this.sum_fleetarr_table);

            this.sum_fleetarr_table.page.len(page).draw()
            this.page_off_fleetarr = page;
        },
        //clear crewwatch table
        clear_fleetarrTable_search() {
            this.search_fleetarr = ''
            this.sum_fleetarr_table.search(this.search_fleetarr).draw();
        },
        //------------------------------------------------------------------



        //INITIALIZE TABLE FUNCTIONS
        //------------------------------------------------------------------
        //Initialize Fleet Array Table
        initTable_fleetarr() {
            var root = this

            console.log("MASUK INIT TABLE FLEET");
            console.log(this.sum_fleetarr_data);
            this.sum_fleetarr_table = new DataTable('#fleetarrTable', {
                data: this.sum_fleetarr_data,
                scrollCollapse: true,
                scroller: true,
                destroy: true,
                deferRender: true,
                ordering: true,
                bLengthChange: false,
                responsive: true,
                dom: 'lrtip',
                columns: [
                    {
                        width: "4%",
                        data: '', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                            return `
                            <div class="p-1 h-100 row`+ meta.row + `">
                            ${meta.row + 1}
                            </div>
                            `
                        }
                    },
                    {
                        width: "32.2%",
                        data: "0", className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                            return `
                            <div class="p-1 h-100 row`+ meta.row + `">
                            ${data}
                            </div>
                            `
                        }
                    },
                    {
                        width: "32.2%",
                        data: "1", className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                            return `
                                <div class="p-1 h-100 row`+ meta.row + `">
                                 ${data}
                                </div>
                                    `
                        }
                    },
                    {
                        width: "32.2%",
                        data: "", className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                            return `
                        
                              <div class="p-1 h-100">
                              <button id="fleetshow_btn"  type="button" class="btn btn-sm btn-primary w-100 rounded-none"><i class='bx bx-chevrons-down' style="font-size:1.5em"></i></button>
                              </div>
                              `
                        }
                    },
                ]
            });


            var mom = this;

            this.sum_fleetarr_table.on('click', '#fleetshow_btn', function (e) {
                let tr = e.target.closest('tr');
                let row = mom.sum_fleetarr_table.row(tr);
                console.log("SHOW FLEET ARR");
                console.log(row.data());
                mom.show_summary_detail(row.data(), "VESSEL");
            });







        },
        //Initialize Sailink Array Table
        initTable_sailinkarr() {
            var root = this

            console.log("MASUK INIT TABLE SAILINK");
            console.log(this.sum_sailinkarr_data);
            this.sum_sailinkarr_table = new DataTable('#sailinkarrTable', {
                data: this.sum_sailinkarr_data,
                scrollCollapse: true,
                scroller: true,
                destroy: true,
                deferRender: true,
                ordering: true,
                bLengthChange: false,
                responsive: true,
                dom: 'lrtip',
                columns: [
                    {
                        width: "4%",
                        data: '', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                            return `
                            <div class="p-1 h-100 row`+ meta.row + `">
                            ${meta.row + 1}
                            </div>
                            `
                        }
                    },
                    {
                        width: "32.2%",
                        data: "0", className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                            return `
                            <div class="p-1 h-100 row`+ meta.row + `">
                            ${data}
                            </div>
                            `
                        }
                    },
                    {
                        width: "32.2%",
                        data: "1", className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                            return `
                                <div class="p-1 h-100 row`+ meta.row + `">
                                 ${data}
                                </div>
                                    `
                        }
                    },
                    {
                        width: "32.2%",
                        data: "", className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                            return `
                        
                              <div class="p-1 h-100">
                              <button id="sailinkshow_btn"  type="button" class="btn btn-sm btn-primary w-100 rounded-none"><i class='bx bx-chevrons-down' style="font-size:1.5em"></i></button>
                              </div>
                              `
                        }
                    },
                ]
            });


            var mom = this;

            this.sum_sailinkarr_table.on('click', '#sailinkshow_btn', function (e) {
                let tr = e.target.closest('tr');
                let row = mom.sum_sailinkarr_table.row(tr);
                console.log("SHOW SAILINK ARR DETAIL");
                console.log(row.data());
                mom.show_summary_detail(row.data(), "VESSEL")
            });







        },
        //Initialize Thuraya Array Table
        initTable_thurayaarr() {
            var root = this

            console.log("MASUK INIT TABLE THURAYA");
            console.log(this.sum_thurayaarr_data);
            this.sum_thurayaarr_table = new DataTable('#thurayaarrTable', {
                data: this.sum_thurayaarr_data,
                scrollCollapse: true,
                scroller: true,
                destroy: true,
                deferRender: true,
                ordering: true,
                bLengthChange: false,
                responsive: true,
                dom: 'lrtip',
                columns: [
                    {
                        width: "4%",
                        data: '', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                            return `
                                <div class="p-1 h-100 row`+ meta.row + `">
                                ${meta.row + 1}
                                </div>
                                `
                        }
                    },
                    {
                        width: "32.2%",
                        data: "0", className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                            return `
                                <div class="p-1 h-100 row`+ meta.row + `">
                                ${data}
                                </div>
                                `
                        }
                    },
                    {
                        width: "32.2%",
                        data: "1", className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                            return `
                                    <div class="p-1 h-100 row`+ meta.row + `">
                                     ${data}
                                    </div>
                                        `
                        }
                    },
                    {
                        width: "32.2%",
                        data: "", className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                            return `
                            
                                  <div class="p-1 h-100">
                                  <button id="thurayashow_btn"  type="button" class="btn btn-sm btn-primary w-100 rounded-none"><i class='bx bx-chevrons-down' style="font-size:1.5em"></i></button>
                                  </div>
                                  `
                        }
                    },
                ]
            });


            var mom = this;

            this.sum_thurayaarr_table.on('click', '#thurayashow_btn', function (e) {
                let tr = e.target.closest('tr');
                let row = mom.sum_thurayaarr_table.row(tr);
                console.log("SHOW THURAYA ARR DETAIL");
                console.log(row.data());
                mom.show_summary_detail(row.data(), "VESSEL")
            });







        },
        //Initialize Service Array Table
        initTable_svarr() {
            var root = this

            console.log("MASUK INIT TABLE SERVICE");
            console.log(this.sum_svarr_data);
            this.sum_svarr_table = new DataTable('#svarrTable', {
                data: this.sum_svarr_data,
                scrollCollapse: true,
                scroller: true,
                destroy: true,
                deferRender: true,
                ordering: true,
                bLengthChange: false,
                responsive: true,
                dom: 'lrtip',
                columns: [
                    {
                        width: "4%",
                        data: '', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                            return `
                                            <div class="p-1 h-100 row`+ meta.row + `">
                                            ${meta.row + 1}
                                            </div>
                                            `
                        }
                    },
                    {
                        width: "32.2%",
                        data: "0", className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                            return `
                                            <div class="p-1 h-100 row`+ meta.row + `">
                                            ${data}
                                            </div>
                                            `
                        }
                    },
                    {
                        width: "32.2%",
                        data: "1", className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                            return `
                                                <div class="p-1 h-100 row`+ meta.row + `">
                                                 ${data}
                                                </div>
                                                    `
                        }
                    },
                    {
                        width: "32.2%",
                        data: "", className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                            return `
                                        
                                              <div class="p-1 h-100">
                                              <button id="svshow_btn"  type="button" class="btn btn-sm btn-primary w-100 rounded-none"><i class='bx bx-chevrons-down' style="font-size:1.5em"></i></button>
                                              </div>
                                              `
                        }
                    },
                ]
            });


            var mom = this;

            this.sum_svarr_table.on('click', '#svshow_btn', function (e) {
                let tr = e.target.closest('tr');
                let row = mom.sum_svarr_table.row(tr);
                console.log("SHOW SERVICE ARR DETAIL");
                console.log(row.data());
                mom.show_summary_detail(row.data(), "VESSEL")
            });







        },

        //Initialize Terminal Array Table
        initTable_terminalarr() {
            var root = this

            console.log("MASUK INIT TABLE SERVICE");
            console.log(this.sum_terminalarr_data);
            this.sum_terminalarr_table = new DataTable('#terminalarrTable', {
                data: this.sum_terminalarr_data,
                scrollCollapse: true,
                scroller: true,
                destroy: true,
                deferRender: true,
                ordering: true,
                bLengthChange: false,
                responsive: true,
                dom: 'lrtip',
                columns: [
                    {
                        width: "4%",
                        data: '', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                            return `
                                            <div class="p-1 h-100 row`+ meta.row + `">
                                            ${meta.row + 1}
                                            </div>
                                            `
                        }
                    },
                    {
                        width: "32.2%",
                        data: "0", className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                            return `
                                            <div class="p-1 h-100 row`+ meta.row + `">
                                            ${data}
                                            </div>
                                            `
                        }
                    },
                    {
                        width: "32.2%",
                        data: "1", className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                            return `
                                                <div class="p-1 h-100 row`+ meta.row + `">
                                                 ${data}
                                                </div>
                                                    `
                        }
                    },
                    {
                        width: "32.2%",
                        data: "", className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                            return `
                                        
                                              <div class="p-1 h-100">
                                              <button id="terminalshow_btn"  type="button" class="btn btn-sm btn-primary w-100 rounded-none"><i class='bx bx-chevrons-down' style="font-size:1.5em"></i></button>
                                              </div>
                                              `
                        }
                    },
                ]
            });


            var mom = this;

            this.sum_terminalarr_table.on('click', '#terminalshow_btn', function (e) {
                let tr = e.target.closest('tr');
                let row = mom.sum_terminalarr_table.row(tr);
                console.log("SHOW TERMINAL ARR DETAIL");
                console.log(row.data());
                mom.show_summary_detail(row.data(), "VESSEL")
            });







        },

        //Initialize IP Phone Array Table
        initTable_iparr() {
            var root = this

            console.log("MASUK INIT TABLE SERVICE");
            console.log(this.sum_iparr_data);
            this.sum_iparr_table = new DataTable('#iparrTable', {
                data: this.sum_iparr_data,
                scrollCollapse: true,
                scroller: true,
                destroy: true,
                deferRender: true,
                ordering: true,
                bLengthChange: false,
                responsive: true,
                dom: 'lrtip',
                columns: [
                    {
                        width: "4%",
                        data: '', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                            return `
                                                <div class="p-1 h-100 row`+ meta.row + `">
                                                ${meta.row + 1}
                                                </div>
                                                `
                        }
                    },
                    {
                        width: "32.2%",
                        data: "0", className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                            return `
                                                <div class="p-1 h-100 row`+ meta.row + `">
                                                ${data}
                                                </div>
                                                `
                        }
                    },
                    {
                        width: "32.2%",
                        data: "1", className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                            return `
                                                    <div class="p-1 h-100 row`+ meta.row + `">
                                                     ${data}
                                                    </div>
                                                        `
                        }
                    },
                    {
                        width: "32.2%",
                        data: "", className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                            return `
                                            
                                                  <div class="p-1 h-100">
                                                  <button id="ipshow_btn"  type="button" class="btn btn-sm btn-primary w-100 rounded-none"><i class='bx bx-chevrons-down' style="font-size:1.5em"></i></button>
                                                  </div>
                                                  `
                        }
                    },
                ]
            });


            var mom = this;

            this.sum_iparr_table.on('click', '#ipshow_btn', function (e) {
                let tr = e.target.closest('tr');
                let row = mom.sum_iparr_table.row(tr);
                console.log("SHOW IP PHONE ARR DETAIL");
                console.log(row.data());
                mom.show_summary_detail(row.data(), "VESSEL")
            });







        },


        //Initialize Rateplan Array Table
        initTable_rparr() {
            var root = this

            console.log("MASUK INIT TABLE SERVICE");
            console.log(this.sum_rparr_data);
            this.sum_rparr_table = new DataTable('#rparrTable', {
                data: this.sum_rparr_data,
                scrollCollapse: true,
                scroller: true,
                destroy: true,
                deferRender: true,
                ordering: true,
                bLengthChange: false,
                responsive: true,
                dom: 'lrtip',
                columns: [
                    {
                        width: "4%",
                        data: '', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                            return `
                                                    <div class="p-1 h-100 row`+ meta.row + `">
                                                    ${meta.row + 1}
                                                    </div>
                                                    `
                        }
                    },
                    {
                        width: "32.2%",
                        data: "0", className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                            return `
                                                    <div class="p-1 h-100 row`+ meta.row + `">
                                                    ${data}
                                                    </div>
                                                    `
                        }
                    },
                    {
                        width: "32.2%",
                        data: "1", className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                            return `
                                                        <div class="p-1 h-100 row`+ meta.row + `">
                                                         ${data}
                                                        </div>
                                                            `
                        }
                    },
                    {
                        width: "32.2%",
                        data: "", className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                            return `
                                                
                                                      <div class="p-1 h-100">
                                                      <button id="rpshow_btn"  type="button" class="btn btn-sm btn-primary w-100 rounded-none"><i class='bx bx-chevrons-down' style="font-size:1.5em"></i></button>
                                                      </div>
                                                      `
                        }
                    },
                ]
            });


            var mom = this;

            this.sum_rparr_table.on('click', '#rpshow_btn', function (e) {
                let tr = e.target.closest('tr');
                let row = mom.sum_rparr_table.row(tr);
                console.log("SHOW IP PHONE ARR DETAIL");
                console.log(row.data());
                mom.show_summary_detail(row.data(), "VESSEL")
            });







        },
        //Initialize IoT Array Table
        initTable_iotarr() {
            var root = this

            console.log("MASUK INIT TABLE IOT");
            console.log(this.sum_iotarr_data);
            this.sum_iotarr_table = new DataTable('#iotarrTable', {
                data: this.sum_iotarr_data,
                scrollCollapse: true,
                scroller: true,
                destroy: true,
                deferRender: true,
                ordering: true,
                bLengthChange: false,
                responsive: true,
                dom: 'lrtip',
                columns: [
                    {
                        width: "4%",
                        data: '', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                            return `
                                                                        <div class="p-1 h-100 row`+ meta.row + `">
                                                                        ${meta.row + 1}
                                                                        </div>
                                                                        `
                        }
                    },
                    {
                        width: "32.2%",
                        data: "0", className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                            return `
                                                                        <div class="p-1 h-100 row`+ meta.row + `">
                                                                        ${data}
                                                                        </div>
                                                                        `
                        }
                    },
                    {
                        width: "32.2%",
                        data: "1", className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                            return `
                                                                            <div class="p-1 h-100 row`+ meta.row + `">
                                                                             ${data}
                                                                            </div>
                                                                                `
                        }
                    },
                    {
                        width: "32.2%",
                        data: "", className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                            return `
                                                                    
                                                                          <div class="p-1 h-100">
                                                                          <button id="iotshow_btn"  type="button" class="btn btn-sm btn-primary w-100 rounded-none"><i class='bx bx-chevrons-down' style="font-size:1.5em"></i></button>
                                                                          </div>
                                                                          `
                        }
                    },
                ]
            });


            var mom = this;

            this.sum_iotarr_table.on('click', '#iotshow_btn', function (e) {
                let tr = e.target.closest('tr');
                let row = mom.sum_iotarr_table.row(tr);
                console.log("SHOW IOT ARR DETAIL");
                console.log(row.data());
                mom.show_summary_detail(row.data(), "VESSEL")
            });







        },

        //Initialize IoT Array Table
        initTable_userarr() {
            var root = this

            console.log("MASUK INIT TABLE IOT");
            console.log(this.sum_userarr_data);
            this.sum_userarr_table = new DataTable('#userarrTable', {
                data: this.sum_userarr_data,
                scrollCollapse: true,
                scroller: true,
                destroy: true,
                deferRender: true,
                ordering: true,
                bLengthChange: false,
                responsive: true,
                dom: 'lrtip',
                columns: [
                    {
                        width: "4%",
                        data: '', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                            return `
                                                                                <div class="p-1 h-100 row`+ meta.row + `">
                                                                                ${meta.row + 1}
                                                                                </div>
                                                                                `
                        }
                    },
                    {
                        width: "32.2%",
                        data: "0", className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                            return `
                                                                                <div class="p-1 h-100 row`+ meta.row + `">
                                                                                ${data}
                                                                                </div>
                                                                                `
                        }
                    },
                    {
                        width: "32.2%",
                        data: "1", className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                            return `
                                                                                    <div class="p-1 h-100 row`+ meta.row + `">
                                                                                     ${data}
                                                                                    </div>
                                                                                        `
                        }
                    },
                    {
                        width: "32.2%",
                        data: "", className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                            return `
                                                                            
                                                                                  <div class="p-1 h-100">
                                                                                  <button id="iotshow_btn"  type="button" class="btn btn-sm btn-primary w-100 rounded-none"><i class='bx bx-chevrons-down' style="font-size:1.5em"></i></button>
                                                                                  </div>
                                                                                  `
                        }
                    },
                ]
            });


            var mom = this;

            this.sum_userarr_table.on('click', '#iotshow_btn', function (e) {
                let tr = e.target.closest('tr');
                let row = mom.sum_userarr_table.row(tr);
                console.log("SHOW USER ARR DETAIL");
                console.log(row.data());
                mom.show_summary_detail(row.data(), "USER");
            });







        },

        //Initialize IoT Array Table
        initTable_grarr() {
            var root = this

            console.log("MASUK INIT TABLE IOT");
            console.log(this.sum_grarr_data);
            this.sum_grarr_table = new DataTable('#grarrTable', {
                data: this.sum_grarr_data,
                scrollCollapse: true,
                scroller: true,
                destroy: true,
                deferRender: true,
                ordering: true,
                bLengthChange: false,
                responsive: true,
                dom: 'lrtip',
                columns: [
                    {
                        width: "4%",
                        data: '', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                            return `
                                                                                        <div class="p-1 h-100 row`+ meta.row + `">
                                                                                        ${meta.row + 1}
                                                                                        </div>
                                                                                        `
                        }
                    },
                    {
                        width: "32.2%",
                        data: "0", className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                            return `
                                                                                        <div class="p-1 h-100 row`+ meta.row + `">
                                                                                        ${data}
                                                                                        </div>
                                                                                        `
                        }
                    },
                    {
                        width: "32.2%",
                        data: "1", className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                            return `
                                                                                            <div class="p-1 h-100 row`+ meta.row + `">
                                                                                             ${data}
                                                                                            </div>
                                                                                                `
                        }
                    },
                    {
                        width: "32.2%",
                        data: "", className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                            return `
                                                                                    
                                                                                          <div class="p-1 h-100">
                                                                                          <button id="groupshow_btn"  type="button" class="btn btn-sm btn-primary w-100 rounded-none"><i class='bx bx-chevrons-right' style="font-size:1.5em"></i></button>
                                                                                          </div>
                                                                                          `
                        }
                    },
                ]
            });


            var mom = this;

            this.sum_grarr_table.on('click', '#groupshow_btn', function (e) {
                let tr = e.target.closest('tr');
                let row = mom.sum_grarr_table.row(tr);
                console.log("SHOW GROUP ARR DETAIL");
                console.log(row.data());
                mom.fetch_group_detail(row.data());
            });







        },

        //Initialize IoT Array Table
        initTable_ptarr(ptarr_data) {
            var root = this

            console.log("MASUK INIT TABLE IOT");
            console.log(ptarr_data);
            this.sum_ptarr_table = new DataTable('#ptarrTable', {
                data: ptarr_data,
                scrollCollapse: true,
                scroller: true,
                destroy: true,
                deferRender: true,
                ordering: true,
                bLengthChange: false,
                responsive: true,
                dom: 'lrtip',
                columns: [
                    {
                        width: "4%",
                        data: '', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                            return `
                                                                                                <div class="p-1 h-100 row`+ meta.row + `">
                                                                                                ${meta.row + 1}
                                                                                                </div>
                                                                                                `
                        }
                    },
                    {
                        width: "32.2%",
                        data: "0", className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                            return `
                                                                                                <div class="p-1 h-100 row`+ meta.row + `">
                                                                                                ${data}
                                                                                                </div>
                                                                                                `
                        }
                    },
                    {
                        width: "32.2%",
                        data: "1", className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                            return `
                                                                                                    <div class="p-1 h-100 row`+ meta.row + `">
                                                                                                     ${data}
                                                                                                    </div>
                                                                                                        `
                        }
                    },
                    {
                        width: "32.2%",
                        data: "", className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                            return `
                                                                                            
                                                                                                  <div class="p-1 h-100">
                                                                                                  <button id="ptshow_btn"  type="button" class="btn btn-sm btn-primary w-100 rounded-none"><i class='bx bx-chevrons-down' style="font-size:1.5em"></i></button>
                                                                                                  </div>
                                                                                                  `
                        }
                    },
                ]
            });


            var mom = this;

            this.sum_ptarr_table.on('click', '#ptshow_btn', function (e) {
                let tr = e.target.closest('tr');
                let row = mom.sum_ptarr_table.row(tr);
                console.log("SHOW COMPANY ARR DETAIL");
                console.log(row.data());
                mom.show_summary_detail(row.data(), "VESSEL")
            });







        },
        //Initialize Crew Detail Table
        initTable_sumdetail(data_detail) {
            var root = this
            console.log("INIT SUMMARY DETAIL TABLE ");
            console.log(this.summary_detail_table);
            console.log("MASUK INIT TABLE");
            console.log(data_detail);


            try {
                if (data_detail) {
                    this.summary_detail_table = new DataTable('#sumdetailTable', {
                        data: data_detail,
                        scrollCollapse: true,
                        scroller: true,
                        destroy: true,
                        pageLength: 5,
                        deferRender: true,
                        ordering: true,
                        bLengthChange: false,
                        responsive: true,
                        dom: 'lrtip',
                        columns: [
                            {
                                width: "",
                                data: 'id', className: 'td_nowrap', render: (data, type, row, meta) => {
                                    return `
                                <div class="p-1 h-100 row`+ meta.row + `">
                                ${meta.row + 1}
                                </div>
                                    `
                                }
                            },
                            {
                                width: "",
                                data: 'name', className: 'td_nowrap', render: (data, type, row, meta) => {
                                    return `
                                <div class="p-1 h-100 row`+ meta.row + `">
                                ${data}
                                </div>
                                    `
                                }
                            },
                            {
                                width: "",
                                data: 'identifier', className: 'td_nowrap', render: (data, type, row, meta) => {
                                    return `
                                <div class="p-1 h-100 row`+ meta.row + `">
                                ${data ? data : '-'}
                                </div>
                                    `
                                }
                            },
                            {
                                width: "",
                                data: 'thuraya_number', className: 'td_nowrap', render: (data, type, row, meta) => {
                                    return `
                                <div class="p-1 h-100 row`+ meta.row + `">
                                ${data ? data : '-'}
                                </div>
                                    `
                                }
                            },
                            {
                                width: "",
                                data: 'pt', className: 'td_nowrap', render: (data, type, row, meta) => {
                                    return `
                                <div class="p-1 h-100 row`+ meta.row + `">
                                ${data.name}
                                </div>
                                    `
                                }
                            },
                            {
                                width: "",
                                data: 'pt', className: 'td_nowrap', render: (data, type, row, meta) => {
                                    return `
                                <div class="p-1 h-100 row`+ meta.row + `">
                                ${data.group_name ? data.group_name : '-'}
                                </div>
                                    `
                                }
                            },

                        ]
                    });
                }
            } catch (e) {

            }





        },
        //Initialize Crew Detail Table
        initTable_sumdetail_user(data_detail) {
            var root = this
            console.log("INIT SUMMARY DETAIL TABLE ");
            console.log(this.summary_detail_table);
            console.log("MASUK INIT TABLE");
            console.log(data_detail);


            try {
                if (data_detail) {
                    this.summary_detail_table = new DataTable('#sumdetailuserTable', {
                        data: data_detail,
                        scrollCollapse: true,
                        scroller: true,
                        destroy: true,
                        pageLength: 5,
                        deferRender: true,
                        ordering: true,
                        bLengthChange: false,
                        responsive: true,
                        dom: 'lrtip',
                        columns: [
                            {
                                width: "",
                                data: 'id', className: 'td_nowrap', render: (data, type, row, meta) => {
                                    return `
                                    <div class="p-1 h-100 row`+ meta.row + `">
                                    ${meta.row + 1}
                                    </div>
                                        `
                                }
                            },
                            {
                                width: "",
                                data: 'name', className: 'td_nowrap', render: (data, type, row, meta) => {
                                    return `
                                    <div class="p-1 h-100 row`+ meta.row + `">
                                    ${data}
                                    </div>
                                        `
                                }
                            },
                            {
                                width: "",
                                data: 'email', className: 'td_nowrap', render: (data, type, row, meta) => {
                                    return `
                                    <div class="p-1 h-100 row`+ meta.row + `">
                                    ${data ? data : '-'}
                                    </div>
                                        `
                                }
                            },
                            {
                                width: "",
                                data: 'username', className: 'td_nowrap', render: (data, type, row, meta) => {
                                    return `
                                    <div class="p-1 h-100 row`+ meta.row + `">
                                    ${data ? data : '-'}
                                    </div>
                                        `
                                }
                            },
                            {
                                width: "",
                                data: 'role_id', className: 'td_nowrap', render: (data, type, row, meta) => {
                                    return `
                                    <div class="p-1 h-100 row`+ meta.row + `">
                                    ${data ? data : '-'}
                                    </div>
                                        `
                                }
                            },
                            {
                                width: "",
                                data: 'lastLogin', className: 'td_nowrap', render: (data, type, row, meta) => {
                                    return `
                                    <div class="p-1 h-100 row`+ meta.row + `">
                                    ${data ? data : '-'}
                                    </div>
                                        `
                                }
                            },

                        ]
                    });
                }
            } catch (e) {

            }





        },
        //------------------------------------------------------------------




        //SHOW CREW FUNCTIONS
        //------------------------------------------------------------------
        //show crew
        show_summary_detail(item, cat) {
            console.log("SHOW SUMMARY DETAIL");
            this.is_showdetail = false;
            this.summary_detail_cat = cat;
            $('html, body').animate({ scrollTop: '0px' }, 5);
            setTimeout(() => {
                this.is_showdetail = true;

                this.fetch_summary_detail(item);


                setTimeout(() => {
                    $('html, body').animate({
                        scrollTop: $("#summarydetail_card").offset().top - 68
                    }, 500);
                }, 10);
            }, 5);
        },

        //close show on map iridium
        close_show_summary_detail() {
            this.is_showdetail = false;
            this.summary_detail_data = null;
            this.summary_detail_cat = '';
            $('html, body').animate({ scrollTop: '0px' }, 500);
        },
        //------------------------------------------------------------------



        // UNINITIALIZE DATA WHEN LOGOUT
        //------------------------------------------------------------------
        //Uninit Data 
        uninit_data() {

        }
        //------------------------------------------------------------------



    }
})
