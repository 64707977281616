import { render, staticRenderFns } from "./IridiumView.vue?vue&type=template&id=02451700&"
import script from "@/assets/js/scripts/iridium.js?vue&type=script&lang=js&"
export * from "@/assets/js/scripts/iridium.js?vue&type=script&lang=js&"
import style0 from "./IridiumView.vue?vue&type=style&index=0&id=02451700&prod&scopped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports