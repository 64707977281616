import { useAuthStore } from '@/stores';
import FooterView from "@/components/footers/FooterView.vue"
import { useNavplusStore } from '@/stores/modules/navplus.store'


import $ from 'jquery'
export default {
  name: "LoginComp",
  components: {
    FooterView,
  },
  data() {
    return {
      passhow: false,
      emptyemail: false,
      emptypassword: false,
      emailorname: "",
      password: "",
      auth_store: useAuthStore(),
      navplus_store : useNavplusStore(),
    };
  },
  mounted() {
    $("#login_form").submit(function (e) {
      e.preventDefault();
    });
  },
  methods: {
    async handleSubmit() {
      this.emptyemail = false
      this.emptypassword = false

      if (this.emailorname.length == 0 || !this.emailorname.replace(/\s/g, '').length) {
        this.emptyemail = true

      } else if (this.password.length == 0 || !this.password.replace(/\s/g, '').length) {
        this.emptypassword = true
      } else {
        this.loadingLogin = true
        this.error = false
        this.auth_store.login(this.emailorname, this.password);
      }



    },

    toggleShow() {
      this.passhow = !this.passhow;
    }

  },






};