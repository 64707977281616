import { defineStore } from 'pinia';
import api from "@/plugins/axios";
import router from '@/router'
import { useToastStore, useNavplusStore, useMapStore, useAdminStore, useCustomRangeStore, useEtaStore, useThurayaStore, useIridiumStore, useGlobalStarStore, useOrbCommStore, useSailinkStore, useSailoggerStore, useSnapshotStore } from '../index';
import $ from 'jquery'
import { useNotifStore } from './notification.store';



export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        loading: false,
        toast_store: useToastStore(),
        navplus_store: useNavplusStore(),
        admin_store: useAdminStore(),
        map_store: useMapStore(),
        customrange_store: useCustomRangeStore(),
        eta_store: useEtaStore(),
        thuraya_store: useThurayaStore(),
        iridium_store: useIridiumStore(),
        globalstar_store: useGlobalStarStore(),
        orbcomm_store: useOrbCommStore(),
        sailink_store: useSailinkStore(),
        sailogger_store : useSailoggerStore(),
        snapshot_store:useSnapshotStore(),
        notification_store:useNotifStore(),
        user: JSON.parse(localStorage.getItem('user')),
        error: null,
        returnUrl: null,
        userAllowed: null,
        is_outsidelogin: false,
        passhow: false,
        con_passhow: false,
        auth_updatepass:
        {
            new_password: "",
            confirmpass: "",
        },

        auth_updatepass_pass_error: false,

        auth_updateprofile: {
            name: "",
            username: "",
            email: "",
        },
        auth_updateprofile_data: null,

        auth_updateapi: {
            passHash: "",
            ip_whitelist: "",
            isEnabled: false,
        },
        auth_updateapi_data: null,
        auth_updateapi_show: false,


        auth_updateprofile: {
            name: "",
            username: "",
            email: "",
        },
        auth_updateprofile_data: null,


        auth_updateapi: {
            passHash: "",
            ip_whitelist: "",
            isEnabled: false
        },
        auth_updateapi_data: null,
        auth_updateapi_show: false
    }),
    actions: {



        //DATA FETCH FUNCTIONS 
        //------------------------------------------------------------------
        //Fetch User Allowed
        async fetch_allowed() {
            await api.get("/api/login_navplus", {
            }).then((res) => {
                console.log("MASUK ALLOWED");
                console.log(res);
                this.userAllowed = res.data.user;
                if (this.navplus_store.fetchingPremission) {
                    this.navplus_store.fetchingPremission = null
                }
                this.navplus_store.check_premission();

            }).catch((err) => {
            }).then(() => {
            });
        },
        //Fetch Edit Profile
        async fetch_edit_profile(id) {
            this.auth_updateprofile = {
                name: "",
                username: "",
                email: "",
            },
                this.auth_updateprofile_data = null,

                console.log("FETCH UPDATE PROFILE");
            await api.get("/api/v1/navplus/user/edit/" + id, {}
            ).then((res) => {
                console.log("FETCH UPDATE PROFILE RESPONSE");
                console.log(res.data.data);
                this.auth_updateprofile_data = res.data.data
                this.auth_updateprofile.name = res.data.data.user.name
                this.auth_updateprofile.username = res.data.data.user.username
                this.auth_updateprofile.email = res.data.data.user.email
            }).catch((err) => {
            }).then(() => {
            });
        },
        //Fetch Edit API
        async fetch_api_data(id) {


            this.auth_updateapi = {
                passHash: "",
                ip_whitelist: "",
                isEnabled: false,
            },
                this.auth_updateapi_data = null
            this.auth_updateapi_show = false

            console.log("FETCH API DATA");
            await api.get("/api/v1/navplus/user/apiAccess/" + id, {}
            ).then((res) => {
                console.log("FETCH  API DATA RESPONSE");
                console.log(res.data.data);

                this.auth_updateapi = {
                    passHash: res.data.data.passHash,
                    ip_whitelist: res.data.data.ip_whitelist,
                    isEnabled: res.data.data.isEnabled,
                },
                    this.auth_updateapi_data = res.data.data
            }).catch((err) => {
            }).then(() => {
            });
        },
        //Fecth User Data
        async fetch_userdata() {
            console.log("MASUK KE TOKEN USERDATA");
            await api.get(`/api/login_navplus`, {}
            ).then((res) => {

                console.log("GET USER DATA RESPONSE");
                console.log(res);
                this.user = res.data;
                localStorage.setItem('user', JSON.stringify(res.data));
                setTimeout(() => {
                    this.toast_store.notif('Hi ' + res.data.user.name + ' welcome back!', "let's start exploring", 'bg-dangernav', 'text-dark')
                    this.navplus_store.fetch_navplus();
                    
                    router.push({ path: '/dashboard' })
                }, 500);

            }).catch((err) => {
            }).then(() => {
            });
        },
        //------------------------------------------------------------------



        //DATA POST FUNCTIONS
        //------------------------------------------------------------------
        //Post Edit Profile
        async post_profile() {

            console.log("POST UPDATE PROFILE");

            await api.get(`/api/v1/navplus/user/edit/` + this.user.user.id, {
                name: this.auth_updateprofile.name,
                username: this.auth_updateprofile.username,
            }
            ).then((res) => {
                console.log("POST UPDATE PROFILE");
                console.log(res);

                if (res.status == 200) {
                    $('#profile_updateProfileModal_alert').removeClass('d-none')
                    $('#profile_updateProfileModal_alert').addClass('d-block')
                    $('#profile_updateProfileModal_alert').html('Profile update!')

                    setTimeout(() => {
                        $('#profile_updateProfileModal_alert').removeClass('d-block')
                        $('#profile_updateProfileModal_alert').addClass('d-none')
                        $('#profile_updateProfileModal_alert').html('')
                    }, 1500);

                    this.auth_updateprofile = {
                        name: "",
                        username: "",
                        email: "",
                    },
                        this.auth_updateprofile_data = null

                    this.fetch_edit_profile(this.user.user.id);

                } else {
                    $('#profile_updateProfileModal_alert').removeClass('alert-success')
                    $('#profile_updateProfileModal_alert').addClass('alert-danger')
                    $('#profile_updateProfileModal_alert').removeClass('d-none')
                    $('#profile_updateProfileModal_alert').addClass('d-block')
                    $('#profile_updateProfileModal_alert').html('Something wrong, cannot update!')

                    setTimeout(() => {
                        $('#profile_updateProfileModal_alert').addClass('alert-success')
                        $('#profile_updateProfileModal_alert').removeClass('alert-danger')
                        $('#profile_updateProfileModal_alert').removeClass('d-block')
                        $('#profile_updateProfileModal_alert').addClass('d-none')
                        $('#profile_updateProfileModal_alert').html('')
                    }, 1500);
                }

            }).catch((err) => {
                $('#profile_updateProfileModal_alert').removeClass('alert-success')
                $('#profile_updateProfileModal_alert').addClass('alert-danger')
                $('#profile_updateProfileModal_alert').removeClass('d-none')
                $('#profile_updateProfileModal_alert').addClass('d-block')
                $('#profile_updateProfileModal_alert').html('Something wrong, cannot update!')

                setTimeout(() => {
                    $('#profile_updateProfileModal_alert').addClass('alert-success')
                    $('#profile_updateProfileModal_alert').removeClass('alert-danger')
                    $('#profile_updateProfileModal_alert').removeClass('d-block')
                    $('#profile_updateProfileModal_alert').addClass('d-none')
                    $('#profile_updateProfileModal_alert').html('')
                }, 1500);
            }).then(() => {
            });
        },
        //Post Update API
        async post_update_api() {

            console.log("POST UPDATE API");

            await api.get(`/api/v1/navplus/user/apiAccess` + this.user.user.id, {
                id: this.user.user.id,
                ip_whitelist: this.auth_updateapi.ip_whitelist,
                isEnabled: this.auth_updateapi.isEnabled
            }
            ).then((res) => {
                console.log("POST UPDATE API");
                console.log(res);

                if (res.status == 200) {
                    $('#profile_updateApiModal_alert').removeClass('d-none')
                    $('#profile_updateApiModal_alert').addClass('d-block')
                    $('#profile_updateApiModal_alert').html('Company update!')

                    setTimeout(() => {
                        $('#profile_updateApiModal_alert').removeClass('d-block')
                        $('#profile_updateApiModal_alert').addClass('d-none')
                        $('#profile_updateApiModal_alert').html('')
                    }, 1500);

                    this.auth_updateapi = {
                        passHash: "",
                        ip_whitelist: "",
                        isEnabled: false
                    },
                        this.auth_updateapi_data = null
                    this.auth_updateapi_show = false

                    this.fetch_api_data(this.user.user.id)
                } else {
                    $('#profile_updateApiModal_alert').removeClass('alert-success')
                    $('#profile_updateApiModal_alert').addClass('alert-danger')
                    $('#profile_updateApiModal_alert').removeClass('d-none')
                    $('#profile_updateApiModal_alert').addClass('d-block')
                    $('#profile_updateApiModal_alert').html('Something wrong, cannot update!')

                    setTimeout(() => {
                        $('#profile_updateApiModal_alert').addClass('alert-success')
                        $('#profile_updateApiModal_alert').removeClass('alert-danger')
                        $('#profile_updateApiModal_alert').removeClass('d-block')
                        $('#profile_updateApiModal_alert').addClass('d-none')
                        $('#profile_updateApiModal_alert').html('')
                    }, 1500);
                }

            }).catch((err) => {
                $('#profile_updateApiModal_alert').removeClass('alert-success')
                $('#profile_updateApiModal_alert').addClass('alert-danger')
                $('#profile_updateApiModal_alert').removeClass('d-none')
                $('#profile_updateApiModal_alert').addClass('d-block')
                $('#profile_updateApiModal_alert').html('Something wrong, cannot update!')

                setTimeout(() => {
                    $('#profile_updateApiModal_alert').addClass('alert-success')
                    $('#profile_updateApiModal_alert').removeClass('alert-danger')
                    $('#profile_updateApiModal_alert').removeClass('d-block')
                    $('#profile_updateApiModal_alert').addClass('d-none')
                    $('#profile_updateApiModal_alert').html('')
                }, 1500);
            }).then(() => {
            });
        },
        //Post Update Password
        async post_update_pass() {

            console.log("POST UPDATE PASSWORD");

            await api.get(`/api/v1/navplus/user/edit/pass/` + this.user.user.id, {

                password: this.auth_updatepass.new_password,
                password_confirmation: this.auth_updatepass.confirmpass,

            }
            ).then((res) => {
                console.log("POST UPDATE RESPONSE");
                console.log(res);

                if (res.status == 200) {
                    $('#profile_updatePasswordModal_alert').removeClass('d-none')
                    $('#profile_updatePasswordModal_alert').addClass('d-block')
                    $('#profile_updatePasswordModal_alert').html('Password Updated')

                    setTimeout(() => {
                        $('#profile_updatePasswordModal_alert').removeClass('d-block')
                        $('#profile_updatePasswordModal_alert').addClass('d-none')
                        $('#profile_updatePasswordModal_alert').html('')
                    }, 1500);

                    this.auth_updatepass =
                    {
                        new_password: "",
                        confirmpass: "",
                    }

                } else {
                    $('#profile_updatePasswordModal_alert').removeClass('alert-success')
                    $('#profile_updatePasswordModal_alert').addClass('alert-danger')
                    $('#profile_updatePasswordModal_alert').removeClass('d-none')
                    $('#profile_updatePasswordModal_alert').addClass('d-block')
                    $('#profile_updatePasswordModal_alert').html('Something wrong, cannot update!')

                    setTimeout(() => {
                        $('#profile_updatePasswordModal_alert').addClass('alert-success')
                        $('#profile_updatePasswordModal_alert').removeClass('alert-danger')
                        $('#profile_updatePasswordModal_alert').removeClass('d-block')
                        $('#profile_updatePasswordModal_alert').addClass('d-none')
                        $('#profile_updatePasswordModal_alert').html('')
                    }, 1500);
                }

            }).catch((err) => {
                $('#profile_updatePasswordModal_alert').removeClass('alert-success')
                $('#profile_updatePasswordModal_alert').addClass('alert-danger')
                $('#profile_updatePasswordModal_alert').removeClass('d-none')
                $('#profile_updatePasswordModal_alert').addClass('d-block')
                $('#profile_updatePasswordModal_alert').html('Something wrong, cannot update!')

                setTimeout(() => {
                    $('#profile_updatePasswordModal_alert').addClass('alert-success')
                    $('#profile_updatePasswordModal_alert').removeClass('alert-danger')
                    $('#profile_updatePasswordModal_alert').removeClass('d-block')
                    $('#profile_updatePasswordModal_alert').addClass('d-none')
                    $('#profile_updatePasswordModal_alert').html('')
                }, 1500);
            }).then(() => {
            });
        },
        //------------------------------------------------------------------



        // LOGIN AND REGISTER FUNCTIONS
        //------------------------------------------------------------------
        //Login 
        async login(username, password) {
            $('#liveToast').hide()
            this.loading = true;
            console.log("MASUK KE SUBMIT");
            console.log(username);
            console.log(password);

            const user = await api.post(`https://navigatorplus.sailink.id/api/login`,
                {
                    email: username,
                    password: password,
                }



            ).then((res) => {

                if (res.data.token) {
                    this.loading = false;
                    this.user = res.data;
                    console.log(res.data);
                    localStorage.setItem('user', JSON.stringify(res.data));
                    localStorage.setItem("token", res.data.token);
                    this.toast_store.notif('Hi ' + res.data.user.name + ' welcome back!', "let's start exploring", 'bg-dangernav', 'text-dark')
                    this.navplus_store.fetch_navplus();
                    
                    router.push({ path: '/dashboard' })
                    console.log("==================== TOKEN ====================");
                    console.log(res.data.token);



                } else {
                    this.toast_store.notif('Cannot Login!', res.data.error.password[0], 'bg-dangernav', 'text-dark')
                }


            }).catch((err) => {

                this.loading = false;
                this.toast_store.notif('Cannot Login!', err.response.data.message, 'bg-dangernav', 'text-dark')

            }).then(() => {

            });
        },
        //Logout
        logout() {
            this.user = null;
            localStorage.removeItem('user');
            localStorage.removeItem('token');

            //Uninit Data One by One
            this.uninit_data()
            this.navplus_store.uninit_data();
            this.map_store.uninit_data();
            this.admin_store.uninit_data();
            this.customrange_store.uninit_data();
            this.eta_store.uninit_data();
            this.thuraya_store.uninit_data();
            this.iridium_store.uninit_data();
            this.globalstar_store.uninit_data();
            this.orbcomm_store.uninit_data();
            this.sailink_store.uninit_data();
            this.sailogger_store.uninit_data();
            this.snapshot_store.uninit_data();

            router.push('/login');
            location.reload();
            this.toast_store.notif('See you later!', 'Thank you for sailing with us', 'bg-dangernav', 'text-dark');
        },
        //------------------------------------------------------------------



        //BUTTON ACTIVATE FUNCTIONS  
        //------------------------------------------------------------------
        //Edit Profile Button
        edit_profile_btn() {
            if (this.auth_updateprofile_data) {
                if ((this.auth_updateprofile.name == this.auth_updateprofile_data.user.name) && (this.auth_updateprofile.username == this.auth_updateprofile_data.user.username)) {
                    return true
                } else {
                    return false
                }
            }
        },
        //Edit API Button
        update_api_btn() {
            if (this.auth_updateapi_data) {
                if (
                    (this.auth_updateapi.ip_whitelist == this.auth_updateapi_data.ip_whitelist)
                    &&
                    (this.auth_updateapi.isEnabled == this.auth_updateapi_data.isEnabled)
                ) {
                    return true
                } else {
                    return false
                }
            }

        },
        //Edit Password Button
        update_pass_btn() {
            var data_ = {
                password: this.auth_updatepass.new_password,
                password_confirmation: this.auth_updatepass.confirmpass,
            }
            if (
                data_.password
                && data_.password_confirmation
            ) {
                if (this.auth_updatepass_pass_error) {
                    return false
                } else {
                    return true
                }
            } else {
                return false
            }
        },
        //------------------------------------------------------------------



        //CHECKBOX CLICK FUNCTIONS
        //------------------------------------------------------------------
        //API Enabled Checkbox
        api_enabled_click() {
            console.log("CLICK ENABLED");
            console.log($('#profile_api_check').prop('checked'))


            if ($('#profile_api_check').prop('checked')) {
                this.auth_updateapi.isEnabled = true
            } else {
                this.auth_updateapi.isEnabled = false
            }
        },
        //------------------------------------------------------------------



        //CLOSE FUNCTIONS
        //------------------------------------------------------------------
        //Close Update API
        close_update_api() {
            this.auth_updateapi = {
                passHash: "",
                ip_whitelist: "",
                isEnabled: false
            },
                this.auth_updateapi_data = null
            this.auth_updateapi_show = false
        },
        //Close Change Password
        close_change_pass() {
            this.auth_updatepass =
            {
                new_password: "",
                confirmpass: "",
            }
        },
        //Close Edit Profile
        close_edit_profile() {
            this.auth_updateprofile = {
                name: "",
                username: "",
                email: "",
            },
                this.auth_updateprofile_data = null
        },
        //------------------------------------------------------------------



        //INPUT FORMAT CHECKER FUNCTIONS
        //------------------------------------------------------------------
        //Check Password Change Password
        is_updatepassword_correct() {

            if (this.auth_updatepass.new_password == this.auth_updatepass.confirmpass) {
                this.auth_updatepass_pass_error = false
            } else {
                this.auth_updatepass_pass_error = true
            }

        },
        //------------------------------------------------------------------



        // TOGGLES FUNCTIONS
        //------------------------------------------------------------------
        //Toggle Password Show Change Password
        toggle_up_ConShow() {
            this.con_passhow = !this.con_passhow;
        },
        //Toggle Password Hide Change Password
        toggle_up_Show() {
            this.passhow = !this.passhow;
        },
        //Toggle passHash Change API 
        toggle_passHash_show() {
            if (this.auth_updateapi.passHash.length > 0) {
                this.auth_updateapi_show = !this.auth_updateapi_show
            }

        },
        //------------------------------------------------------------------



        //ITEM INITIALIZATION FUNCTIONS
        //------------------------------------------------------------------
        //Update API Item Data
        update_api_item() {
            this.fetch_api_data(this.user.user.id)
        },
        //------------------------------------------------------------------


        // UNINITIALIZE DATA WHEN LOGOUT
        //------------------------------------------------------------------
        //Uninit Data 
        uninit_data() {
            console.log("UNINITIALIZED AUTH STORE")
            this.loading = false;
            this.error = null;
            this.returnUrl = null;
            this.userAllowed = null;
            this.is_outsidelogin = false;
            this.passhow = false;
            this.con_passhow = false;
            this.auth_updatepass =
            {
                new_password: "",
                confirmpass: "",
            };
            this.auth_updatepass_pass_error = false;
            this.auth_updateprofile = {
                name: "",
                username: "",
                email: "",
            };
            this.auth_updateprofile_data = null;
            this.auth_updateapi = {
                passHash: "",
                ip_whitelist: "",
                isEnabled: false,
            };
            this.auth_updateapi_data = null;
            this.auth_updateapi_show = false;
            this.auth_updateprofile = {
                name: "",
                username: "",
                email: "",
            },
                this.auth_updateprofile_data = null;
            this.auth_updateapi = {
                passHash: "",
                ip_whitelist: "",
                isEnabled: false
            };
            this.auth_updateapi_data = null;
            this.auth_updateapi_show = false;

        },
    }
});
