import axios from 'axios'
import {API_URL} from '../config'

const axiosAPI = axios.create();

axiosAPI.defaults.baseURL = API_URL;
axiosAPI.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

axiosAPI.interceptors.request.use(
    function(config) {
        config.headers.authorization = "Bearer " + localStorage.getItem("token");
        return config;
    },
    function(error) {
        return Promise.reject(error);
    }
);

axiosAPI.interceptors.response.use(
    function(response) {
        return response;
    },
    function(error)  {
        return Promise.reject(error);
    }
);

export default axiosAPI;
