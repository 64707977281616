import Vue from 'vue';
import App from './App.vue';
import router from './router';
import { createPinia } from 'pinia';
import 'jquery';
import './assets/maps/leaflet-1.8/leaflet.css';
import '@/plugins/ApexCharts';
import './registerServiceWorker';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "leaflet-rotatedmarker";
import 'leaflet.fullscreen/Control.FullScreen.css';
import 'leaflet-sidebar/src/L.Control.Sidebar.css';
import '@fortawesome/fontawesome-free/js/all.js';
import "datatables.net-dt/css/jquery.dataTables.min.css";
import 'daterangepicker';
import 'moment';
import 'daterangepicker/daterangepicker.css';
import 'leaflet-control-geocoder';
import 'leaflet-control-geocoder/dist/Control.Geocoder.css';




Vue.use(createPinia())
Vue.config.silent = true
Vue.config.productionTip = false

var vm = new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

