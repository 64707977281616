import { defineStore } from 'pinia';
import { useCustomRangeStore } from '../index';
import api from "@/plugins/axios";
import $ from "jquery";
import DataTable from 'datatables.net-dt';
import Chart from 'chart.js/auto'


export const useReportsStore = defineStore({
    id: 'reports',
    state: () => ({
        range_store: useCustomRangeStore(),
        reports_table: null,
        reportsapp_table: null,
        reportssource_table: null,
        reportsdestination_table: null,
        reportsconversation_table: null,
        is_showonmap: null,
        search_reports: null,
        reports_data: null,
        page_off: 10,
        show_range: "Last 24 Hours",
        export_range: "24",
        loading_range: false,
        date_range: null,
        isBottom: false,
        app_data: null,
        app_error: false,
        app_item: null,
        app_rule: '',
        app_service: '',
        app_chart: null,
        source_data: null,
        source_error: false,
        source_item: null,
        destination_data: null,
        destination_error: false,
        destination_item: null,
        conversation_data: null,
        conversation_error: false,
        conversation_item: null,
        inout: "IN",

        range_arr: [
            { label: "Today", val: "today" },
            { label: "Last 24 Hours", val: "24" },
            { label: "Last 7 Days", val: "7" },
            { label: "Last 30 Days", val: "30" },
            { label: "This Week", val: "week" },
            { label: "This Month", val: "month" },
            { label: "Last Month", val: "lmonth" },
            { label: "Custom Range", val: "24" },
        ],
        page_off_arr: [
            10,
            25,
            50,
            100
        ],

        th_reports_arr: [
            "No",
            "Vessel Name",
            "Company",
            "Reports",
            "",
            "",
            "",
        ],

        th_reports_app: [
            "Name",
            "Traffic",
            "% of Traffic",
        ],

        th_reports_source: [
            "Name",
            "IP",
            "Traffic",
            "% of Traffic",
        ],

        th_reports_destination: [
            "Name",
            "IP",
            "Traffic",
            "% of Traffic",
        ],

        th_reports_conversation: [
            "Source",
            "Destination",
            "Application",
            "Port",
            "Protocol",
            "DSCP",
            "Traffic"
        ],

        td_reports_arr: [
            4,
            20,
            20,
            12,
            12,
            12,
            12,
        ],

        td_reports_app: [
            33.4,
            33.4,
            33.4,
        ],

        td_reports_source: [
            25,
            25,
            25,
            25,
        ],

        td_reports_destination: [
            25,
            25,
            25,
            25,
        ],

        td_reports_conversation: [
            14.3,
            14.3,
            14.3,
            14.3,
            14.3,
            14.3,
            14.3,
        ],


        reports_range: "Last 24 Hours",
        reports_range_data: null,
        reports_range_arr: [
            { label: "Last Hour", val: "hourly" },
            { label: "Last 6 Hours", val: "6hour" },
            { label: "Last 24 Hours", val: "Daily" },
            { label: "Today", val: "today" },
            { label: "Yesterday", val: "yesterday" },
            { label: "Last 7 Days", val: "Weekly" },
            { label: "Last 30 Days", val: "Monthly" },
        ],

    }),

    actions: {


        // DATA FETCH FUNCTIONS
        //------------------------------------------------------------------
        //fetch table sensor management
        async fetch_reports() {
            await api.get(`/api/v1/navplus/sailink`, {}
            ).then((res) => {
                console.log("reports RESPONSE");
                console.log(res);
                if (this.search_reports == null || this.search_reports.length == 0) {
                    this.reports_data = res.data.data.vessels;
                    this.initTable();
                }
            }).catch((err) => {
            }).then(() => {
            });
        },
        async fetch_application(data, range, service, rule) {
            this.app_data = null
            this.app_rule = ''
            this.app_service = ''
            this.app_error = false
            this.reports_range = range.label
            var nfa_id;
            this.app_rule = rule
            this.app_service = service

            if (this.app_chart) {
                this.app_chart.destroy()
            }

            if (this.reportsapp_table) {
                this.reportsapp_table.clear().draw();
                $('#reportsTableApp').DataTable().destroy();
                this.reportsapp_table = null
            }

            if (service == 'hybrid') {
                if (rule == "unmetered") {
                    nfa_id = data.nfa_id_2
                } else {
                    nfa_id = data.nfa_id
                }

            } else {
                nfa_id = data.nfa_id
            }




            await api.get(`/api/v1/navplus/nta/app/` + data.id + "/" + nfa_id + "/" + range.val, {}
            ).then((res) => {
                console.log("APPLICATION RESPONSE");
                console.log(res);


                this.app_data = res.data.data;
                var datasets;
                var startTime = '';
                var chartTitle = '';
                var endTime = '';
                var unit = ' - ';
                const data = [
                    { year: '', count: 0 },
                    { year: '', count: 0 },
                    { year: '', count: 0 },
                    { year: '', count: 0 },

                ];
                var chartLabel = data.map(row => row.year);

                console.log(res.data.data.chart);


                if (res.data.data && res.data.data.isError == false) {
                    datasets = res.data.data.chart.datasets
                    chartTitle = res.data.data.chart.title
                    startTime = res.data.data.info.startTime
                    chartLabel = res.data.data.chart.labels_f
                    endTime = res.data.data.info.endTime
                    unit = res.data.data.chart.unit
                } else {
                    datasets = data
                }

                this.app_chart = new Chart(
                    document.getElementById('app_chart'),
                    {
                        type: 'line',
                        data: {
                            labels: chartLabel,
                            datasets: datasets
                        },
                        options: {
                            responsive: true,
                            maintainAspectRatio: false,
                            plugins: {
                                legend: {
                                    position: "top",
                                },
                                title: {
                                    display: true,
                                    text:
                                        chartTitle + " ( " +
                                        startTime +
                                        " - " +
                                        endTime +
                                        " )",
                                },
                                tooltip: {
                                    callbacks: {
                                        label: function (context) {

                                            let label = ' ' + context.dataset.label + ' : ' + context.parsed.y.toFixed(2) + ' ' + unit;
                                            return label;
                                        }
                                    },
                                },
                            },
                            scales: {
                                y: {

                                    title: {
                                        display: true,
                                        font: {
                                            weight: 'bold'
                                        },
                                        text: 'Traffic (' + unit + ')',
                                        test: 'Berhasil',
                                    },
                                    stacked: true,
                                    ticks: {


                                        callback: function (value, index, values) {

                                            return value + ' ' + unit;
                                        }
                                    },
                                },
                            },

                            interaction: {
                                mode: 'index',
                                intersect: false,
                            }
                        }
                    }
                );








                setTimeout(() => {
                    this.initTable_app(res.data.data)
                }, 1000);




            }).catch((err) => {
            }).then(() => {
            });
        },
        async fetch_source(data, range) {
            this.source_data = null
            this.source_error = false
            this.reports_range = range.label

            if (this.reportssource_table) {
                this.reportssource_table.clear().draw();
                $('#reportsTableSource').DataTable().destroy();
                this.reportssource_table = null
            }
            await api.get(`/api/v1/navplus/nta/src/` + data.id + "/" + data.nfa_id + "/" + range.val, {}
            ).then((res) => {
                console.log("SOURCE RESPONSE");
                console.log(res);


                this.source_data = res.data.data;
                setTimeout(() => {
                    this.initTable_source(res.data.data)
                }, 1000);




            }).catch((err) => {
            }).then(() => {
            });
        },
        async fetch_destination(data, range) {
            this.destination_data = null
            this.destination_error = false
            this.reports_range = range.label
            if (this.reportsdestination_table) {
                this.reportsdestination_table.clear().draw();
                $('#reportsTableDestination').DataTable().destroy();
                this.reportsdestination_table = null
            }
            await api.get(`/api/v1/navplus/nta/dst/` + data.id + "/" + data.nfa_id + "/" + range.val, {}
            ).then((res) => {
                console.log("DESTINATION RESPONSE");
                console.log(res);


                this.destination_data = res.data.data;
                setTimeout(() => {
                    this.initTable_destination(res.data.data)
                }, 1000);




            }).catch((err) => {
            }).then(() => {
            });
        },
        async fetch_conversation(data, range) {
            this.conversation_data = null
            this.conversation_error = false
            this.reports_range = range.label
            this.reports_range_data = range
            if (this.reportsconversation_table) {
                this.reportsconversation_table.clear().draw();
                $('#reportsTableConversation').DataTable().destroy();
                this.reportsconversation_table = null
            }
            await api.get(`/api/v1/navplus/nta/conv/` + data.id + "/" + data.nfa_id + "/" + range.val + "/" + this.inout, {}
            ).then((res) => {
                console.log("CONVERSATION RESPONSE");
                console.log(res);


                this.conversation_data = res.data.data;
                setTimeout(() => {
                    this.initTable_conversation(res.data.data)
                }, 1000);




            }).catch((err) => {
            }).then(() => {
            });
        },
        //------------------------------------------------------------------



        //TABLE SEARCH & PAGE FUNCTIONS
        //------------------------------------------------------------------
        //search reports table
        reportsTable_search() {
            if (this.search_reports != null && this.search_reports.length > 0) {
                this.reports_table.search(this.search_reports).draw();
            }
        },
        //data shorting reports table 
        reportsTable_showof(page) {
            console.log("PAGE : " + page);
            this.reports_table.page.len(page).draw()
            this.page_off = page;
        },
        //clear reports table
        clear_reportsTable_search() {
            this.search_reports = ''
            this.reports_table.search(this.search_reports).draw();
        },
        //------------------------------------------------------------------



        //INITIALIZE TABLE FUNCTIONS
        //------------------------------------------------------------------
        initTable() {
            var root = this
            if (this.reports_table) {
                console.log("MASUK CLEAR TABLE");
                root.reports_table = null;
                root.initTable()
            } else {
                console.log("MASUK INIT TABLE");
                console.log(this.reports_data);
                this.reports_table = new DataTable('#reportsTable', {
                    data: this.reports_data,
                    scrollCollapse: true,
                    scroller: true,
                    destroy: true,
                    deferRender: true,
                    ordering: true,
                    bLengthChange: false,
                    responsive: true,
                    dom: 'lrtip',
                    columns: [
                        {
                            width: "4%",
                            data: '', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                            <div class="p-1 h-100 row`+ meta.row + `">
                            ${meta.row + 1}
                            </div>
                            `
                            }
                        },
                        {
                            width: "20%",
                            data: 'name', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                            <div class="p-1 h-100 row`+ meta.row + `">
                            ${data}
                            </div>
                            `
                            }
                        },
                        {
                            width: "20%",
                            data: 'pt_name', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                                <div class="p-1 h-100 row`+ meta.row + `">
                                ${data}
                                </div>
                                    `
                            }
                        },
                        {
                            width: "12%",
                            data: 'service', className: 'p-0 no-wrap', render: (data, type, row, meta) => {

                                if (data == 'hybrid') {
                                    return ` <div  class="dropdown">
                                    <button class="btn btn-sm rounded-none btn-primary w-100 dropdown-toggle" data-bs-toggle="dropdown"
                                     aria-expanded="false">
                                    Application
                                    </button>
                                    <ul class="dropdown-menu border bg-main dropdown-menu-end rounded-none dropdown_nav">
                                    <li><a id="rpappm_btn" class="dropdown-item"  href="#" data-bs-toggle="modal" data-bs-target="#reportApplicationModal" >Metered</a></li>
                                    <li><a id="rpappunm_btn" class="dropdown-item"  href="#" data-bs-toggle="modal" data-bs-target="#reportApplicationModal" >Unmetered</a></li>
                                    </ul>
                                    </div>`
                                } else {
                                    return `
                        
                              <div class="p-1 h-100">
                              <button id="rpapp_btn"  data-bs-toggle="modal" data-bs-target="#reportApplicationModal"   type="button" class="btn btn-sm btn-primary w-100 rounded-none">Application</button>
                              </div>
                              `}
                            }
                        },
                        {
                            width: "12%",
                            data: 'id', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                        
                              <div class="p-1 h-100">
                              <button id="rpsource_btn"  data-bs-toggle="modal" data-bs-target="#reportSourceModal"  type="button" class="btn btn-sm btn-primary w-100 rounded-none">Source</button>
                              </div>
                              `
                            }
                        },
                        {
                            width: "12%",
                            data: 'id', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                        
                              <div class="p-1 h-100">
                              <button id="rpdestination_btn"  data-bs-toggle="modal" data-bs-target="#reportDestinationModal" type="button" class="btn btn-sm btn-primary w-100 rounded-none">Destination</button>
                              </div>
                              `
                            }
                        },
                        {
                            width: "12%",
                            data: 'id', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                        
                              <div class="p-1 h-100">
                              <button id="rpconversation_btn"  data-bs-toggle="modal" data-bs-target="#reportConversationModal"  type="button" class="btn btn-sm btn-primary w-100 rounded-none">Conversation</button>
                              </div>
                              `
                            }
                        },
                    ]
                });


                var mom = this;

                this.reports_table.on('click', '#rpapp_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.reports_table.row(tr);
                    console.log("APPLICATION DI CLICK");
                    console.log(row.data());
                    mom.app_item = row.data();
                    mom.fetch_application(row.data(), mom.reports_range_arr[2], row.data().service, row.data().service)
                });

                this.reports_table.on('click', '#rpappm_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.reports_table.row(tr);
                    console.log("APPLICATION DI CLICK");
                    console.log(row.data());
                    mom.app_item = row.data();
                    mom.fetch_application(row.data(), mom.reports_range_arr[2], row.data().service, 'metered')
                });

                this.reports_table.on('click', '#rpappunm_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.reports_table.row(tr);
                    console.log("APPLICATION DI CLICK");
                    console.log(row.data());
                    mom.app_item = row.data();
                    mom.fetch_application(row.data(), mom.reports_range_arr[2], row.data().service, 'unmetered')
                });



                this.reports_table.on('click', '#rpsource_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.reports_table.row(tr);
                    console.log("SOURCE DI CLICK");
                    console.log(row.data());
                    mom.source_item = row.data();
                    mom.fetch_source(row.data(), mom.reports_range_arr[2])
                });

                this.reports_table.on('click', '#rpdestination_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.reports_table.row(tr);
                    console.log("DESTINATION DI CLICK");
                    console.log(row.data());
                    mom.destination_item = row.data();
                    mom.fetch_destination(row.data(), mom.reports_range_arr[2])
                });

                this.reports_table.on('click', '#rpconversation_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.reports_table.row(tr);
                    console.log("CONVERSATION DI CLICK");
                    console.log(row.data());
                    mom.conversation_item = row.data();
                    mom.fetch_conversation(row.data(), mom.reports_range_arr[2])
                });
            }
        },
        initTable_app(dataApp) {
            console.log("INIT TABLE APPLICATION");
            console.log(this.reportsapp_table);
            console.log("MASUK INIT TABLE APPLICATION");
            console.log(dataApp);

            try {
                if (dataApp.dataTable) {
                    this.reportsapp_table = new DataTable('#reportsTableApp', {
                        data: dataApp.dataTable,
                        scrollCollapse: true,
                        scroller: true,
                        destroy: true,
                        pageLength: 5,
                        deferRender: true,
                        ordering: true,
                        bLengthChange: false,
                        responsive: true,
                        dom: 'lrtip',
                        columns: [
                            {
                                width: "33.4%",
                                data: 'Name', className: 'td_nowrap', render: (data, type, row, meta) => {
                                    return `
                                    <div class="p-1 h-100">
                                    ${data}
                                    </div>
                                    `
                                }
                            },
                            {
                                width: "33.4%",
                                data: 'volume', className: 'td_nowrap', render: (data, type, row, meta) => {
                                    return `
                                        <div class="p-0 h-100">
                                        ${data}
                                        </div>
                                            `
                                }
                            },
                            {
                                width: "33.4%",
                                data: 'traffic', className: 'td_nowrap', render: (data, type, row, meta) => {
                                    return `
                                        <div class="p-0 h-100">
                                        ${data}
                                        </div>
                                            `
                                }
                            },

                        ]
                    });
                }
            } catch (e) {
                this.app_error = true
                this.app_data = true

            }
        },
        initTable_source(dataSource) {
            var root = this
            console.log("INIT TABLE SOURCE");
            console.log(this.reportssource_table);
            console.log("MASUK INIT TABLE SOURCE");
            console.log(dataSource);


            try {
                if (dataSource.dataTable) {
                    this.reportssource_table = new DataTable('#reportsTableSource', {
                        data: dataSource.dataTable,
                        scrollCollapse: true,
                        scroller: true,
                        destroy: true,
                        pageLength: 5,
                        deferRender: true,
                        ordering: true,
                        bLengthChange: false,
                        responsive: true,
                        dom: 'lrtip',
                        columns: [
                            {
                                width: "25%",
                                data: 'Name', className: 'td_nowrap', render: (data, type, row, meta) => {
                                    return `
                                    <div class="p-1 h-100">
                                    ${data}
                                    </div>
                                    `
                                }
                            },
                            {
                                width: "25%",
                                data: 'ip', className: 'td_nowrap', render: (data, type, row, meta) => {
                                    return `
                                    <div class="p-0 h-100">
                                    ${data}
                                    </div>
                                    `
                                }
                            },
                            {
                                width: "25%",
                                data: 'volume', className: 'td_nowrap', render: (data, type, row, meta) => {
                                    return `
                                        <div class="p-0 h-100">
                                        ${data}
                                        </div>
                                            `
                                }
                            },
                            {
                                width: "25%",
                                data: 'traffic', className: 'td_nowrap', render: (data, type, row, meta) => {
                                    return `
                                        <div class="p-0 h-100">
                                        ${data}
                                        </div>
                                            `
                                }
                            },

                        ]
                    });
                }
            } catch (e) {
                this.source_error = true
                this.source_data = true

            }



        },
        initTable_destination(dataSource) {
            var root = this
            console.log("INIT TABLE DESTINATION");
            console.log(this.reportsdestination_table);
            console.log("MASUK INIT TABLE DESTINATIOB");
            console.log(dataSource);


            try {
                if (dataSource.dataTable) {
                    this.reportsdestination_table = new DataTable('#reportsTableDestination', {
                        data: dataSource.dataTable,
                        scrollCollapse: true,
                        scroller: true,
                        destroy: true,
                        pageLength: 5,
                        deferRender: true,
                        ordering: true,
                        bLengthChange: false,
                        responsive: true,
                        dom: 'lrtip',
                        columns: [
                            {
                                width: "25%",
                                data: 'Name', className: 'td_nowrap', render: (data, type, row, meta) => {
                                    return `
                                    <div class="p-1 h-100">
                                    ${data}
                                    </div>
                                    `
                                }
                            },
                            {
                                width: "25%",
                                data: 'ip', className: 'td_nowrap', render: (data, type, row, meta) => {
                                    return `
                                    <div class="p-0 h-100">
                                    ${data}
                                    </div>
                                    `
                                }
                            },
                            {
                                width: "25%",
                                data: 'volume', className: 'td_nowrap', render: (data, type, row, meta) => {
                                    return `
                                        <div class="p-0 h-100">
                                        ${data}
                                        </div>
                                            `
                                }
                            },
                            {
                                width: "25%",
                                data: 'traffic', className: 'td_nowrap', render: (data, type, row, meta) => {
                                    return `
                                        <div class="p-0 h-100">
                                        ${data}
                                        </div>
                                            `
                                }
                            },

                        ]
                    });
                }
            } catch (e) {
                this.destination_error = true
                this.destination_data = true

            }



        },
        initTable_conversation(dataSource) {
            console.log("INIT TABLE CONVERSATION");
            console.log(this.reportsconversation_table);
            console.log("MASUK INIT TABLE CONVERSATION");
            console.log(dataSource);

            try {
                if (dataSource.dataTable) {
                    this.reportsconversation_table = new DataTable('#reportsTableConversation', {
                        data: dataSource.dataTable,
                        scrollCollapse: true,
                        scroller: true,
                        destroy: true,
                        pageLength: 5,
                        deferRender: true,
                        ordering: true,
                        bLengthChange: false,
                        responsive: true,
                        dom: 'lrtip',
                        columns: [
                            {
                                width: "14.3%",
                                data: 'src', className: 'td_nowrap', render: (data, type, row, meta) => {
                                    return `
                                    <div class="p-1 h-100">
                                    ${data}
                                    </div>
                                    `
                                }
                            },
                            {
                                width: "14.3%",
                                data: 'dst', className: 'td_nowrap', render: (data, type, row, meta) => {
                                    return `
                                    <div class="p-0 h-100">
                                    ${data}
                                    </div>
                                    `
                                }
                            },
                            {
                                width: "14.3%",
                                data: 'app', className: 'td_nowrap', render: (data, type, row, meta) => {
                                    return `
                                        <div class="p-0 h-100">
                                        ${data}
                                        </div>
                                            `
                                }
                            },
                            {
                                width: "14.3%",
                                data: 'port', className: 'td_nowrap', render: (data, type, row, meta) => {
                                    return `
                                        <div class="p-0 h-100">
                                        ${data}
                                        </div>
                                            `
                                }
                            },
                            {
                                width: "14.3%",
                                data: 'prot', className: 'td_nowrap', render: (data, type, row, meta) => {
                                    return `
                                        <div class="p-0 h-100">
                                        ${data}
                                        </div>
                                            `
                                }
                            },
                            {
                                width: "14.3%",
                                data: 'dscp', className: 'td_nowrap', render: (data, type, row, meta) => {
                                    return `
                                        <div class="p-0 h-100">
                                        ${data}
                                        </div>
                                            `
                                }
                            },
                            {
                                width: "14.3%",
                                data: 'traffic', className: 'td_nowrap', render: (data, type, row, meta) => {
                                    return `
                                        <div class="p-0 h-100">
                                        ${data}
                                        </div>
                                            `
                                }
                            },

                        ]
                    });
                }
            } catch (e) {
                this.conversation_error = true
                this.conversation_data = true

            }



        },
        //------------------------------------------------------------------



        //OTHER FUNCTIONS
        //------------------------------------------------------------------
        inout_click(val) {
            this.inout = val
            setTimeout(() => {
                this.fetch_conversation(this.conversation_item, this.reports_range_data)
            }, 100);
        }
        //------------------------------------------------------------------



    }
})
