import { useOrbCommStore, useEtaStore } from '@/stores';


export default {
  name: "eta_modal",
  components:{
  },
  data() {
    return {
      orbcomm_store: useOrbCommStore(),
      eta_store: useEtaStore()
  }
  },

  mounted() {
  },

  methods: {
  },

  watch: {
  },
  computed: {
  }
};