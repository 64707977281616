import L from 'leaflet';
import $ from 'jquery';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';
import { useOrbCommStore, useMapStore, useCustomRangeStore, useEtaStore } from '@/stores';
import plc_orbcomm from '@/components/placeholders/OrbcommMap_Plc.vue';
import coordinate_modal from '@/components/modals/Coordinate_Modal.vue';
import dms_modal from '@/components/modals/DMS_Modal.vue';
import editdms_modal from '@/components/modals/EditDMS_Modal.vue';
import dd_modal from '@/components/modals/DD_Modal.vue';
import editdd_modal from '@/components/modals/EditDD_Modal.vue';
import range_modal from '@/components/modals/Range_Modal.vue';
import tablecoordinate_modal from '@/components/modals/TableCoordinate_Modal.vue';
import {
  LMap,
  LPopup,
  LControl,
  LTileLayer,
  LControlZoom,
  LGeoJson,
  LPolygon,

} from "vue2-leaflet";
import LControlFullscreen from "vue2-leaflet-fullscreen";
import LControlPolylineMeasure from "vue2-leaflet-polyline-measure";
import Vue2LeafletRotatedMarker from "vue2-leaflet-rotatedmarker";



export default {
  name: "ShowMapOrb",
  components: {
    plc_orbcomm,
    coordinate_modal,
    dms_modal,
    editdms_modal,
    dd_modal,
    editdd_modal,
    range_modal,
    tablecoordinate_modal,
    LMap,
    LTileLayer,
    LPopup,
    LControlZoom,
    LControl,
    LGeoJson,
    LPolygon,
    LControlPolylineMeasure,
    LControlFullscreen,
    "v-rotated-marker": Vue2LeafletRotatedMarker,
  },
  data() {
    return {
      orbcomm_store: useOrbCommStore(),
      eta_store: useEtaStore(),
      mapStore: useMapStore(),
      customrange_store: useCustomRangeStore(),
      plc_orbcomm: plc_orbcomm,
      coordinate_modal: coordinate_modal,
      dms_modal: dms_modal,
      dd_modal: dd_modal,
      editdms_modal: editdms_modal,
      editdd_modal : editdd_modal,
      range_modal: range_modal,
      tablecoordinate_modal: tablecoordinate_modal,
      unit: "nauticalmiles",
    }
  },

  mounted() {
    var mother = this

    mother.customrange_store.set_onclick_range('orbcomm')

    window.popup_tracking = function (index) {
      mother.popup_tracking(index)
    }


    //ETA FUNCTIONS
    window.start_draw_orbcomm = function () {
      mother.eta_store.start_draw(mother.$refs["polylineMeasure"].mapObject)
    }

    window.start_draw_multi_coor_orbcomm = function () {
      mother.eta_store.start_draw_multi_coor(mother.orbcomm_store.orbcomm_map, mother.$refs["polylineMeasure"].mapObject)
    }

    window.start_draw_marker_orbcomm = function () {
      mother.eta_store.start_draw_marker(mother.$refs["polylineMeasure"].mapObject)
    }


    window.count_ETA_orbcomm = function () {
      mother.eta_store.count_ETA(mother.$refs["polylineMeasure"].mapObject)
    }

    window.clear_ETA_orbcomm = function () {
      mother.eta_store.clear_ETA(mother.$refs["polylineMeasure"].mapObject)
    }

    window.open_ETA_orbcomm = function () {
      mother.eta_store.open_ETA(mother.orbcomm_store.orbcomm_map, 'orbcomm');
    }
    window.close_ETA_orbcomm = function () {
      mother.eta_store.close_ETA(mother.orbcomm_store.orbcomm_map, mother.$refs["polylineMeasure"].mapObject)
    }

    window.toggle_start_card_orbcomm = function () {
      mother.eta_store.toggle_start_card()
    }

    window.open_table_koordinat_orbcomm = function () {
      mother.eta_store.open_table_koordinat(mother.orbcomm_store.orbcomm_map)
    }

    window.toggle_detailroute_orbcomm = function (e){
      mother.eta_store.toggle_detailroute(e)
    }

    //END OF ETA FUNCTIONS


  },

  methods: {
    initialize() {
      this.initGalery()
      this.initMap()
    },

    initGalery() {
      const lightbox = new PhotoSwipeLightbox({
        gallery: '#orbcomm-gallery-container',
        children: 'a',
        pswpModule: () => import('photoswipe')
      });
      lightbox.init();
    },

    initMap() {
      this.orbcomm_store.initializeMap(this.$refs.orbcommMap.mapObject);
    },

    popup_tracking(index) {
      this.orbcomm_store.popup_tracking(index)
    },

    handleFinish() {
      setTimeout(() => {
        this.eta_store.finish_draw(this.$refs["polylineMeasure"].mapObject)
      }, 200);
    },

    handleAdd() {
      
      setTimeout(() => {
        this.eta_store.update_route_detail(this.$refs["polylineMeasure"].mapObject)
      }, 200);
    }

  },

  watch: {
  },
  computed: {
  }
};