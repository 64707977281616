import { 
    useNavplusStore, 
    useMapStore, 
    useOrbCommStore, 
    useSailinkStore, 
    useIridiumStore, 
    useGlobalStarStore, 
    useThurayaStore ,
    useAuthStore
} 
from '@/stores';


export default {
    components: {
    },
    data() {
        return {
            navplusStore: useNavplusStore(),
            mapStore: useMapStore(),
            orbcommStore : useOrbCommStore(),
            sailinkStore : useSailinkStore(),
            thurayaStore : useThurayaStore(),
            globalstarStore: useGlobalStarStore(),
            authStore: useAuthStore(),
            iridiumStore : useIridiumStore(),     
            weatherArray: [
                "clear_day.svg",
                "clear_night.svg",
                "cloudy.svg",
                "drizzle.svg",
                "flurries.svg",
                "fog.svg",
                "fog_light.svg",
                "freezing_drizzle.svg",
                "freezing_rain.svg",
                "freezing_rain_heavy.svg",
                "freezing_rain_light.svg",
                "ice_pellets.svg",
                "ice_pellets_heavy.svg",
                "ice_pellets_light.svg",
                "light_rain.svg",
                "mostly_clear_day.svg",
                "mostly_clear_night.svg",
                "mostly_cloudy.svg",
                "partly_cloudy_day.svg",
                "partly_cloudy_night.svg",
                "rain.svg",
                "rain_heavy.svg",
                "snow.svg",
                "snow_heavy.svg",
                "snow_light.svg",
                "tstorm.svg",
            ],
            windArray:[
                "wind-0.png",
                "wind-1.png",
                "wind-2.png",
                "wind-3.png",
                "wind-4.png",
                "wind-5.png",
                "wind-6.png",
                "wind-7.png",
                "wind-8.png",
                "wind-9.png",
                "wind-10.png",
                "wind-11.png",
                "wind-12.png",
                "wind-13.png",
                "wind-14.png",
                "wind-15.png",
            ]
        }
    },
    mounted() {

    },

    methods: {
        more_detail(markertype,id){
            var index;
             if(markertype=='sailink'){
                this.sailinkStore.showonMap(id) 
                index = 1
            }
            else if(markertype=='thuraya'){
                this.thurayaStore.showonMap(id) 
                index = 4
            }else if(markertype=='orbcomm'){
                this.orbcommStore.showonMap(id) 
                index = 7
            }else if(markertype=='gs'){
                this.globalstarStore.showonMap(id)
                index = 8
            }else if(markertype=='iridium'){
                this.iridiumStore.showonMap(id)
                index = 9
            }
            this.$root.$emit('swapComponent',index)
           },
    },
}
