import { defineStore } from 'pinia';



export const useMapStore = defineStore({
    id: 'map',
    state: () => ({
        last_center: [-1.4312313766684295, 119.1498789812993],
        last_zoom: 5,
        url: "https://maps.skyreach.id/sailink/{z}/{x}/{y}.png",
        mapOptions: { minZoom: 2, zoomControl: false, doubleClickZoom: true, attributionControl: false },
        RPMmapOptions: {
            minZoom: 3,
            zoomControl: false,
            doubleClickZoom: true,
            scrollWheelZoom: false,
        }
    }),
    actions: {



        // SET LAST ZOOM & CENTER
        //------------------------------------------------------------------
        setlast_center(latlng) {
            this.last_center = latlng
        },
        setlast_zoom(zoom) {
            this.last_zoom = zoom
        },
        //------------------------------------------------------------------



        // SET LAST ZOOM & CENTER
        //------------------------------------------------------------------
        uninit_data() {
            this.last_center = [-1.4312313766684295, 119.1498789812993];
            this.last_zoom = 5;
        },
        //------------------------------------------------------------------



    }
});
