import { render, staticRenderFns } from "./MythurayaView.vue?vue&type=template&id=4f5aea0a&"
import script from "@/assets/js/scripts/thuraya.js?vue&type=script&lang=js&"
export * from "@/assets/js/scripts/thuraya.js?vue&type=script&lang=js&"
import style0 from "./MythurayaView.vue?vue&type=style&index=0&id=4f5aea0a&prod&scopped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports