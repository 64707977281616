import { useNavplusStore, useMapStore } from '@/stores';


export default {
    components: {
    },
    data() {
        return {
            navplusStore: useNavplusStore(),
            mapStore: useMapStore(),     
        }
    },
    mounted() {
        
    },

    methods: {
    },
}
