import { useReportsStore, useEtaStore } from '@/stores';
import $ from "jquery";

export default {
  name: "OrbComm",
  components: {
  },
  data() {
    return {
      reports_store: useReportsStore(),
    }
  },

  mounted() {
    this.reports_store.fetch_reports()
    var mother = this
  },

  methods: {
  },

  watch: {
  },
  computed: {
  }
};