import { useGlobalStarStore, useEtaStore } from '@/stores';
import MapGlobalstar from '@/views/showonmap/MapGlobalStar_view.vue';
import $ from "jquery";

export default {
  name: "GlobalStar",
  components: {
    MapGlobalstar,
  },
  data() {
    return {
      globalstar_store: useGlobalStarStore(),
      eta_store: useEtaStore(),
      MapGlobalstarComp: MapGlobalstar,
      map: null,
      marker: null,
    }
  },

  mounted() {
    this.globalstar_store.fetch_globalstar()
    var mother = this
    window.showonmap_globalstar = function (id) {
       mother.globalstar_store.showonMap(id);
    }

    $(window).scroll(function () {
      if ($(window).scrollTop() + $(window).height() > $(document).height() - 100) {
        mother.globalstar_store.isBottom = true
      } else {
        mother.globalstar_store.isBottom = false
      }
    });
  },

  methods: {

  },

  watch: {
  },
  computed: {
  }
};