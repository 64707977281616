export var abs = {
  c1: [
    { lat: -13.7595, lng: 115.5042 },
    { lat: -14.0226, lng: 116.8727 },
    { lat: -14.1722, lng: 118.2564 },
    { lat: -14.1063, lng: 119.6431 },
    { lat: -13.8981, lng: 121.0201 },
    { lat: -13.6149, lng: 122.3832 },
    { lat: -13.4249, lng: 123.7621 },
    { lat: -13.3037, lng: 125.149 },
    { lat: -13.2173, lng: 126.5382 },
    { lat: -13.1504, lng: 127.9288 },
    { lat: -13.0828, lng: 129.3197 },
    { lat: -12.9924, lng: 130.7084 },
    { lat: -12.877, lng: 132.0957 },
    { lat: -12.7517, lng: 133.4831 },
    { lat: -12.6678, lng: 134.8715 },
    { lat: -12.6303, lng: 136.2619 },
    { lat: -12.632, lng: 137.6572 },
    { lat: -12.655, lng: 139.0504 },
    { lat: -12.6719, lng: 140.4318 },
    { lat: -12.6917, lng: 141.8285 },
    { lat: -12.7134, lng: 143.2442 },
    { lat: -12.6976, lng: 144.6225 },
    { lat: -12.6478, lng: 145.9617 },
    { lat: -12.7116, lng: 147.3898 },
    { lat: -12.8839, lng: 148.9079 },
    { lat: -12.6259, lng: 150.1086 },
    { lat: -11.788, lng: 150.8302 },
    { lat: -10.4091, lng: 150.8058 },
    { lat: -8.9112, lng: 150.8587 },
    { lat: -7.5875, lng: 151.0908 },
    { lat: -6.5399, lng: 150.4787 },
    { lat: -5.5062, lng: 149.6027 },
    { lat: -4.3962, lng: 148.7229 },
    { lat: -3.16, lng: 148.0995 },
    { lat: -1.8072, lng: 147.7413 },
    { lat: -0.3912, lng: 147.5884 },
    { lat: 0.863, lng: 147.3638 },
    { lat: 1.4004, lng: 146.4032 },
    { lat: 1.3977, lng: 144.8533 },
    { lat: 1.5771, lng: 143.4852 },
    { lat: 1.8226, lng: 142.1552 },
    { lat: 2.0519, lng: 140.7606 },
    { lat: 2.3503, lng: 139.396 },
    { lat: 2.7594, lng: 138.0802 },
    { lat: 3.3362, lng: 136.7976 },
    { lat: 4.1771, lng: 135.7182 },
    { lat: 5.3071, lng: 134.9229 },
    { lat: 6.6516, lng: 134.4074 },
    { lat: 7.9374, lng: 134.6239 },
    { lat: 8.9667, lng: 135.5609 },
    { lat: 9.5546, lng: 136.8228 },
    { lat: 9.9557, lng: 138.1597 },
    { lat: 10.2455, lng: 139.5185 },
    { lat: 10.4831, lng: 140.8804 },
    { lat: 10.6663, lng: 142.2603 },
    { lat: 10.793, lng: 143.674 },
    { lat: 10.9333, lng: 145.046 },
    { lat: 11.0978, lng: 146.3686 },
    { lat: 11.1487, lng: 147.8173 },
    { lat: 11.1299, lng: 149.3478 },
    { lat: 11.5321, lng: 150.4149 },
    { lat: 12.52, lng: 150.8409 },
    { lat: 14.0669, lng: 150.6265 },
    { lat: 15.3633, lng: 150.4277 },
    { lat: 15.8906, lng: 149.4106 },
    { lat: 15.9292, lng: 147.8678 },
    { lat: 15.966, lng: 146.5066 },
    { lat: 16.0089, lng: 145.1512 },
    { lat: 16.0599, lng: 143.7349 },
    { lat: 16.1095, lng: 142.3382 },
    { lat: 16.1731, lng: 140.9582 },
    { lat: 16.2768, lng: 139.5715 },
    { lat: 16.412, lng: 138.1799 },
    { lat: 16.5917, lng: 136.7957 },
    { lat: 16.865, lng: 135.4492 },
    { lat: 17.2543, lng: 134.0983 },
    { lat: 17.8771, lng: 132.853 },
    { lat: 19.0179, lng: 132.1771 },
    { lat: 20.395, lng: 132.1653 },
    { lat: 21.7704, lng: 132.1802 },
    { lat: 22.8958, lng: 131.3569 },
    { lat: 23.6029, lng: 130.1669 },
    { lat: 23.9933, lng: 128.8424 },
    { lat: 24.1841, lng: 127.4571 },
    { lat: 24.2455, lng: 126.0684 },
    { lat: 24.203, lng: 124.6777 },
    { lat: 24.055, lng: 123.2937 },
    { lat: 23.7971, lng: 121.9254 },
    { lat: 23.4313, lng: 120.5833 },
    { lat: 22.9497, lng: 119.2778 },
    { lat: 22.3757, lng: 118.0081 },
    { lat: 21.7531, lng: 116.7664 },
    { lat: 21.103, lng: 115.5338 },
    { lat: 20.449, lng: 114.2991 },
    { lat: 19.8576, lng: 113.0547 },
    { lat: 19.286, lng: 111.7749 },
    { lat: 18.8399, lng: 110.4489 },
    { lat: 19.0025, lng: 109.0686 },
    { lat: 19.843, lng: 108.1252 },
    { lat: 21.2731, lng: 108.286 },
    { lat: 22.6697, lng: 108.2168 },
    { lat: 23.5849, lng: 107.4736 },
    { lat: 23.8675, lng: 106.0048 },
    { lat: 24.1983, lng: 104.6371 },
    { lat: 24.7777, lng: 103.4455 },
    { lat: 25.6509, lng: 102.2922 },
    { lat: 25.8131, lng: 100.9888 },
    { lat: 25.3482, lng: 99.6537 },
    { lat: 24.6232, lng: 98.4724 },
    { lat: 23.8344, lng: 97.3249 },
    { lat: 23.0311, lng: 96.1885 },
    { lat: 22.066, lng: 95.1844 },
    { lat: 20.9176, lng: 94.4148 },
    { lat: 19.5998, lng: 93.9641 },
    { lat: 18.2286, lng: 93.6958 },
    { lat: 16.8484, lng: 93.7604 },
    { lat: 15.4683, lng: 93.9698 },
    { lat: 14.0869, lng: 94.0112 },
    { lat: 12.699, lng: 93.8289 },
    { lat: 11.3216, lng: 93.8938 },
    { lat: 9.9705, lng: 94.2588 },
    { lat: 8.6221, lng: 94.5431 },
    { lat: 7.2379, lng: 94.3403 },
    { lat: 6.09, lng: 93.5993 },
    { lat: 5.1408, lng: 92.5684 },
    { lat: 4.196, lng: 91.562 },
    { lat: 2.9933, lng: 90.8578 },
    { lat: 1.6178, lng: 90.8543 },
    { lat: 0.4483, lng: 91.5615 },
    { lat: -0.4662, lng: 92.6173 },
    { lat: -1.271, lng: 93.7466 },
    { lat: -2.1538, lng: 94.8236 },
    { lat: -3.2209, lng: 95.7189 },
    { lat: -4.4801, lng: 96.296 },
    { lat: -5.7959, lng: 96.7594 },
    { lat: -6.9329, lng: 97.54 },
    { lat: -7.8004, lng: 98.6121 },
    { lat: -8.5285, lng: 99.8194 },
    { lat: -9.3151, lng: 100.9587 },
    { lat: -10.0733, lng: 102.1295 },
    { lat: -10.7035, lng: 103.368 },
    { lat: -11.1714, lng: 104.6792 },
    { lat: -11.5378, lng: 106.0216 },
    { lat: -11.832, lng: 107.3816 },
    { lat: -12.086, lng: 108.7508 },
    { lat: -12.3753, lng: 110.1121 },
    { lat: -12.7122, lng: 111.4627 },
    { lat: -13.0781, lng: 112.8067 },
    { lat: -13.4429, lng: 114.1504 },
    { lat: -13.7595, lng: 115.5042 },
  ],
  c2: [
    { lat: -13.2423, lng: 114.501 },
    { lat: -13.5158, lng: 115.8468 },
    { lat: -13.7057, lng: 117.2077 },
    { lat: -13.7758, lng: 118.5785 },
    { lat: -13.6912, lng: 119.9495 },
    { lat: -13.5099, lng: 121.3112 },
    { lat: -13.293, lng: 122.6671 },
    { lat: -13.143, lng: 124.0323 },
    { lat: -13.046, lng: 125.4025 },
    { lat: -12.9824, lng: 126.7743 },
    { lat: -12.9102, lng: 128.1459 },
    { lat: -12.8132, lng: 129.5157 },
    { lat: -12.684, lng: 130.8831 },
    { lat: -12.5494, lng: 132.2499 },
    { lat: -12.4223, lng: 133.6174 },
    { lat: -12.3156, lng: 134.9867 },
    { lat: -12.2521, lng: 136.3585 },
    { lat: -12.2325, lng: 137.7323 },
    { lat: -12.2341, lng: 139.1047 },
    { lat: -12.2419, lng: 140.4785 },
    { lat: -12.2436, lng: 141.854 },
    { lat: -12.2212, lng: 143.2233 },
    { lat: -12.1979, lng: 144.5963 },
    { lat: -12.2018, lng: 145.9821 },
    { lat: -12.199, lng: 147.3371 },
    { lat: -12.2583, lng: 148.7163 },
    { lat: -12.1322, lng: 150.059 },
    { lat: -11.018, lng: 150.797 },
    { lat: -9.6518, lng: 150.8817 },
    { lat: -8.2391, lng: 150.9058 },
    { lat: -6.9154, lng: 150.7419 },
    { lat: -5.8363, lng: 149.8616 },
    { lat: -4.8186, lng: 148.9736 },
    { lat: -3.5971, lng: 148.3185 },
    { lat: -2.3006, lng: 147.8594 },
    { lat: -0.9609, lng: 147.6189 },
    { lat: 0.3419, lng: 147.2993 },
    { lat: 1.0817, lng: 146.1241 },
    { lat: 1.156, lng: 144.7517 },
    { lat: 1.3295, lng: 143.3924 },
    { lat: 1.5341, lng: 142.0334 },
    { lat: 1.7659, lng: 140.679 },
    { lat: 2.0531, lng: 139.3389 },
    { lat: 2.4184, lng: 138.0117 },
    { lat: 2.9183, lng: 136.7326 },
    { lat: 3.6463, lng: 135.5696 },
    { lat: 4.5961, lng: 134.5831 },
    { lat: 5.762, lng: 133.8545 },
    { lat: 7.1308, lng: 133.557 },
    { lat: 8.4002, lng: 133.9174 },
    { lat: 9.3729, lng: 134.9357 },
    { lat: 9.9823, lng: 136.1595 },
    { lat: 10.3846, lng: 137.4707 },
    { lat: 10.7019, lng: 138.8086 },
    { lat: 10.9531, lng: 140.1583 },
    { lat: 11.1604, lng: 141.5156 },
    { lat: 11.3493, lng: 142.8777 },
    { lat: 11.5238, lng: 144.2381 },
    { lat: 11.7092, lng: 145.5971 },
    { lat: 11.9534, lng: 146.9587 },
    { lat: 12.0314, lng: 148.3126 },
    { lat: 12.2471, lng: 149.7128 },
    { lat: 13.1602, lng: 150.6014 },
    { lat: 14.573, lng: 150.4377 },
    { lat: 15.1741, lng: 149.2574 },
    { lat: 15.1211, lng: 147.8825 },
    { lat: 15.2535, lng: 146.5152 },
    { lat: 15.3786, lng: 145.147 },
    { lat: 15.4368, lng: 143.7745 },
    { lat: 15.5191, lng: 142.404 },
    { lat: 15.62, lng: 141.034 },
    { lat: 15.7338, lng: 139.6643 },
    { lat: 15.8624, lng: 138.2993 },
    { lat: 16.03, lng: 136.9359 },
    { lat: 16.2606, lng: 135.5738 },
    { lat: 16.5407, lng: 134.2419 },
    { lat: 16.9502, lng: 132.9397 },
    { lat: 17.6501, lng: 131.6804 },
    { lat: 18.7525, lng: 131.0303 },
    { lat: 20.1611, lng: 130.9478 },
    { lat: 21.5485, lng: 130.8051 },
    { lat: 22.6376, lng: 130.1134 },
    { lat: 23.3267, lng: 128.8529 },
    { lat: 23.66, lng: 127.5321 },
    { lat: 23.7949, lng: 126.1775 },
    { lat: 23.8151, lng: 124.7964 },
    { lat: 23.6982, lng: 123.4281 },
    { lat: 23.4569, lng: 122.0781 },
    { lat: 23.1071, lng: 120.7484 },
    { lat: 22.6497, lng: 119.4545 },
    { lat: 22.1026, lng: 118.1948 },
    { lat: 21.4987, lng: 116.9592 },
    { lat: 20.867, lng: 115.7417 },
    { lat: 20.2311, lng: 114.5281 },
    { lat: 19.6292, lng: 113.2822 },
    { lat: 19.0805, lng: 112.0281 },
    { lat: 18.6477, lng: 110.756 },
    { lat: 18.6136, lng: 109.307 },
    { lat: 19.1965, lng: 108.1536 },
    { lat: 20.4915, lng: 107.7576 },
    { lat: 21.9109, lng: 107.5382 },
    { lat: 22.9817, lng: 106.912 },
    { lat: 23.4119, lng: 105.5261 },
    { lat: 23.7298, lng: 104.1856 },
    { lat: 24.1109, lng: 102.9076 },
    { lat: 24.6058, lng: 101.5884 },
    { lat: 24.6236, lng: 100.2417 },
    { lat: 24.1817, lng: 98.9224 },
    { lat: 23.5438, lng: 97.7137 },
    { lat: 22.7651, lng: 96.5814 },
    { lat: 21.8391, lng: 95.5645 },
    { lat: 20.6997, lng: 94.8039 },
    { lat: 19.4219, lng: 94.2983 },
    { lat: 18.066, lng: 94.0578 },
    { lat: 16.6976, lng: 94.15 },
    { lat: 15.3285, lng: 94.2831 },
    { lat: 13.9616, lng: 94.1976 },
    { lat: 12.5882, lng: 94.1263 },
    { lat: 11.2214, lng: 94.1941 },
    { lat: 9.8827, lng: 94.5047 },
    { lat: 8.5104, lng: 94.6539 },
    { lat: 7.1565, lng: 94.4353 },
    { lat: 6.016, lng: 93.6917 },
    { lat: 5.0275, lng: 92.7139 },
    { lat: 4.0681, lng: 91.7503 },
    { lat: 2.7998, lng: 91.1791 },
    { lat: 1.4539, lng: 91.2462 },
    { lat: 0.3025, lng: 92.0076 },
    { lat: -0.5803, lng: 93.0576 },
    { lat: -1.4062, lng: 94.1536 },
    { lat: -2.3337, lng: 95.1705 },
    { lat: -3.4126, lng: 96.0173 },
    { lat: -4.6597, lng: 96.5961 },
    { lat: -5.8819, lng: 97.2283 },
    { lat: -6.9684, lng: 98.0573 },
    { lat: -7.816, lng: 99.1351 },
    { lat: -8.5869, lng: 100.2834 },
    { lat: -9.3664, lng: 101.4075 },
    { lat: -10.068, lng: 102.588 },
    { lat: -10.6523, lng: 103.8322 },
    { lat: -11.0941, lng: 105.1326 },
    { lat: -11.4433, lng: 106.4605 },
    { lat: -11.7309, lng: 107.8036 },
    { lat: -11.9949, lng: 109.1515 },
    { lat: -12.2815, lng: 110.4944 },
    { lat: -12.6023, lng: 111.8299 },
    { lat: -12.9301, lng: 113.1637 },
    { lat: -13.2423, lng: 114.501 },
  ],
  c3: [
    { lat: -11.8603, lng: 113.5436 },
    { lat: -12.0525, lng: 114.9069 },
    { lat: -12.183, lng: 116.2787 },
    { lat: -12.2362, lng: 117.6544 },
    { lat: -12.2098, lng: 119.0313 },
    { lat: -12.1309, lng: 120.4061 },
    { lat: -12.0203, lng: 121.7791 },
    { lat: -11.9264, lng: 123.1532 },
    { lat: -11.8509, lng: 124.5283 },
    { lat: -11.7742, lng: 125.9037 },
    { lat: -11.6634, lng: 127.2762 },
    { lat: -11.4754, lng: 128.64 },
    { lat: -11.2026, lng: 129.9902 },
    { lat: -10.8746, lng: 131.3269 },
    { lat: -10.4964, lng: 132.6515 },
    { lat: -10.1322, lng: 133.9795 },
    { lat: -9.8198, lng: 135.3199 },
    { lat: -9.5687, lng: 136.6739 },
    { lat: -9.3492, lng: 138.0337 },
    { lat: -9.0699, lng: 139.3816 },
    { lat: -8.6915, lng: 140.708 },
    { lat: -8.0482, lng: 141.9131 },
    { lat: -7.1123, lng: 142.9302 },
    { lat: -6.0408, lng: 143.7693 },
    { lat: -5.1466, lng: 144.8465 },
    { lat: -4.2985, lng: 145.8584 },
    { lat: -3.4222, lng: 147.0854 },
    { lat: -2.3776, lng: 147.5138 },
    { lat: -1.4522, lng: 146.6785 },
    { lat: -1.0378, lng: 145.2801 },
    { lat: -0.6571, lng: 143.9707 },
    { lat: -0.3384, lng: 142.6474 },
    { lat: -0.0746, lng: 141.2786 },
    { lat: 0.2019, lng: 139.9378 },
    { lat: 0.4737, lng: 138.5849 },
    { lat: 0.7833, lng: 137.2432 },
    { lat: 1.149, lng: 135.9161 },
    { lat: 1.5862, lng: 134.6101 },
    { lat: 2.1288, lng: 133.3457 },
    { lat: 2.8342, lng: 132.163 },
    { lat: 3.71, lng: 131.1051 },
    { lat: 4.6882, lng: 130.1319 },
    { lat: 5.787, lng: 129.3119 },
    { lat: 7.0505, lng: 128.7724 },
    { lat: 8.4133, lng: 128.5855 },
    { lat: 9.7853, lng: 128.6142 },
    { lat: 11.1455, lng: 128.8966 },
    { lat: 12.4998, lng: 128.976 },
    { lat: 13.6624, lng: 128.369 },
    { lat: 14.4278, lng: 127.1801 },
    { lat: 15.1463, lng: 126.0174 },
    { lat: 16.0699, lng: 124.9922 },
    { lat: 17.2551, lng: 124.3618 },
    { lat: 18.6368, lng: 124.3896 },
    { lat: 20.0095, lng: 124.5135 },
    { lat: 20.889, lng: 123.5665 },
    { lat: 21.0543, lng: 122.2233 },
    { lat: 20.7928, lng: 120.8532 },
    { lat: 20.3749, lng: 119.5571 },
    { lat: 19.8145, lng: 118.2929 },
    { lat: 19.206, lng: 117.0592 },
    { lat: 18.5529, lng: 115.8477 },
    { lat: 17.8654, lng: 114.6529 },
    { lat: 17.1496, lng: 113.4798 },
    { lat: 16.176, lng: 112.4836 },
    { lat: 14.9722, lng: 112.2193 },
    { lat: 13.6799, lng: 112.8565 },
    { lat: 12.3697, lng: 112.7354 },
    { lat: 11.2092, lng: 111.9548 },
    { lat: 10.0925, lng: 111.1885 },
    { lat: 8.8762, lng: 110.4743 },
    { lat: 8.0345, lng: 109.4627 },
    { lat: 8.3656, lng: 108.3403 },
    { lat: 9.794, lng: 108.4164 },
    { lat: 11.1246, lng: 108.6637 },
    { lat: 12.48, lng: 108.797 },
    { lat: 13.835, lng: 109.2948 },
    { lat: 15.0917, lng: 109.3201 },
    { lat: 16.0571, lng: 108.4265 },
    { lat: 16.6573, lng: 107.1458 },
    { lat: 17.4596, lng: 106.0168 },
    { lat: 18.562, lng: 105.2299 },
    { lat: 19.7576, lng: 104.5398 },
    { lat: 20.5836, lng: 103.5081 },
    { lat: 20.8313, lng: 102.123 },
    { lat: 21.1647, lng: 100.7965 },
    { lat: 21.3486, lng: 99.4319 },
    { lat: 21.1012, lng: 98.1024 },
    { lat: 20.3113, lng: 96.9664 },
    { lat: 19.1557, lng: 96.2294 },
    { lat: 17.8127, lng: 96.0183 },
    { lat: 16.4323, lng: 96.0961 },
    { lat: 15.0749, lng: 96.0055 },
    { lat: 13.7385, lng: 95.6326 },
    { lat: 12.3928, lng: 95.3817 },
    { lat: 11.023, lng: 95.4842 },
    { lat: 9.6992, lng: 95.8612 },
    { lat: 8.3308, lng: 95.9352 },
    { lat: 7.0024, lng: 95.5895 },
    { lat: 5.8441, lng: 94.8496 },
    { lat: 4.9, lng: 93.8531 },
    { lat: 3.9766, lng: 92.8263 },
    { lat: 2.767, lng: 92.1736 },
    { lat: 1.4476, lng: 92.3321 },
    { lat: 0.3555, lng: 93.1714 },
    { lat: -0.4971, lng: 94.2492 },
    { lat: -1.2958, lng: 95.3738 },
    { lat: -2.1983, lng: 96.4079 },
    { lat: -3.2962, lng: 97.237 },
    { lat: -4.4977, lng: 97.9115 },
    { lat: -5.6561, lng: 98.6499 },
    { lat: -6.658, lng: 99.5944 },
    { lat: -7.5168, lng: 100.6702 },
    { lat: -8.3374, lng: 101.7769 },
    { lat: -9.0817, lng: 102.9348 },
    { lat: -9.6934, lng: 104.1663 },
    { lat: -10.1585, lng: 105.4639 },
    { lat: -10.5397, lng: 106.7862 },
    { lat: -10.8471, lng: 108.1284 },
    { lat: -11.1099, lng: 109.4809 },
    { lat: -11.3605, lng: 110.8348 },
    { lat: -11.6217, lng: 112.1874 },
    { lat: -11.8603, lng: 113.5436 },
  ],
  c4: [
    { lat: -3.567, lng: 113.8242 },
    { lat: -3.6838, lng: 114.2137 },
    { lat: -3.7395, lng: 114.6177 },
    { lat: -3.7578, lng: 115.024 },
    { lat: -3.7216, lng: 115.4299 },
    { lat: -3.6404, lng: 115.8294 },
    { lat: -3.4636, lng: 116.1856 },
    { lat: -3.1483, lng: 116.4554 },
    { lat: -2.8087, lng: 116.6672 },
    { lat: -2.4136, lng: 116.7805 },
    { lat: -2.0068, lng: 116.8152 },
    { lat: -1.6127, lng: 116.9082 },
    { lat: -1.245, lng: 117.0863 },
    { lat: -0.9287, lng: 117.3403 },
    { lat: -0.6324, lng: 117.62 },
    { lat: -0.3909, lng: 117.9479 },
    { lat: -0.1556, lng: 118.2803 },
    { lat: 0.0948, lng: 118.6011 },
    { lat: 0.3626, lng: 118.9084 },
    { lat: 0.6646, lng: 119.1806 },
    { lat: 1.0227, lng: 119.3754 },
    { lat: 1.4161, lng: 119.4702 },
    { lat: 1.8042, lng: 119.3896 },
    { lat: 1.9866, lng: 119.0247 },
    { lat: 2.1748, lng: 118.6629 },
    { lat: 2.2252, lng: 118.2613 },
    { lat: 2.2218, lng: 117.8536 },
    { lat: 2.2114, lng: 117.4465 },
    { lat: 2.1935, lng: 117.0397 },
    { lat: 2.1735, lng: 116.633 },
    { lat: 2.152, lng: 116.2263 },
    { lat: 2.1266, lng: 115.8199 },
    { lat: 2.0965, lng: 115.4137 },
    { lat: 2.0596, lng: 115.0082 },
    { lat: 2.0119, lng: 114.6038 },
    { lat: 1.955, lng: 114.2005 },
    { lat: 1.892, lng: 113.7982 },
    { lat: 1.8228, lng: 113.3969 },
    { lat: 1.7534, lng: 112.9956 },
    { lat: 1.6685, lng: 112.5973 },
    { lat: 1.5712, lng: 112.202 },
    { lat: 1.4693, lng: 111.8075 },
    { lat: 1.3884, lng: 111.4086 },
    { lat: 1.3208, lng: 111.0069 },
    { lat: 1.2552, lng: 110.6052 },
    { lat: 1.1812, lng: 110.2043 },
    { lat: 1.0664, lng: 109.8149 },
    { lat: 0.9164, lng: 109.4324 },
    { lat: 0.6659, lng: 109.1204 },
    { lat: 0.273, lng: 109.1382 },
    { lat: -0.039, lng: 109.4077 },
    { lat: -0.2581, lng: 109.7487 },
    { lat: -0.4707, lng: 110.0964 },
    { lat: -0.7244, lng: 110.4149 },
    { lat: -0.9662, lng: 110.7427 },
    { lat: -1.2389, lng: 111.0446 },
    { lat: -1.5366, lng: 111.3228 },
    { lat: -1.8272, lng: 111.6081 },
    { lat: -2.1258, lng: 111.8848 },
    { lat: -2.4222, lng: 112.1642 },
    { lat: -2.7098, lng: 112.4527 },
    { lat: -2.9694, lng: 112.7659 },
    { lat: -3.2073, lng: 113.0964 },
    { lat: -3.4163, lng: 113.4463 },
    { lat: -3.567, lng: 113.8242 },
  ],
  c5: [
    { lat: -4.226, lng: 125.491 },
    { lat: -4.291, lng: 125.96 },
    { lat: -4.279, lng: 127.145 },
    { lat: -4.245, lng: 127.343 },
    { lat: -3.645, lng: 128.164 },
    { lat: -3.032, lng: 127.561 },
    { lat: -2.752, lng: 126.984 },
    { lat: -2.616, lng: 125.795 },
    { lat: -2.95, lng: 124.687 },
    { lat: -3.01, lng: 124.599 },
    { lat: -3.611, lng: 124.342 },
    { lat: -3.96, lng: 124.782 },
    { lat: -4.226, lng: 125.491 },
  ],
  c6: [
    { lat: -10.5997, lng: 110.81 },
    { lat: -10.8168, lng: 112.157 },
    { lat: -11.0055, lng: 113.5084 },
    { lat: -11.1453, lng: 114.8654 },
    { lat: -11.2058, lng: 116.2285 },
    { lat: -11.2025, lng: 117.5928 },
    { lat: -11.1557, lng: 118.9565 },
    { lat: -11.0889, lng: 120.3193 },
    { lat: -11.0246, lng: 121.6822 },
    { lat: -10.9744, lng: 123.0459 },
    { lat: -10.9039, lng: 124.408 },
    { lat: -10.773, lng: 125.7669 },
    { lat: -10.5484, lng: 127.112 },
    { lat: -10.2077, lng: 128.4316 },
    { lat: -9.6967, lng: 129.6958 },
    { lat: -8.9686, lng: 130.8479 },
    { lat: -8.0654, lng: 131.8665 },
    { lat: -7.0899, lng: 132.8212 },
    { lat: -6.3881, lng: 133.9742 },
    { lat: -5.9193, lng: 135.2623 },
    { lat: -5.4649, lng: 136.5452 },
    { lat: -4.9667, lng: 137.8146 },
    { lat: -4.3018, lng: 139.0066 },
    { lat: -3.4619, lng: 140.0717 },
    { lat: -2.2756, lng: 140.2268 },
    { lat: -1.4928, lng: 139.0686 },
    { lat: -1.1066, lng: 137.7674 },
    { lat: -0.7501, lng: 136.4486 },
    { lat: -0.4261, lng: 135.1231 },
    { lat: -0.0849, lng: 133.8024 },
    { lat: 0.298, lng: 132.4921 },
    { lat: 0.8168, lng: 131.2327 },
    { lat: 1.4973, lng: 130.0509 },
    { lat: 2.2658, lng: 128.923 },
    { lat: 3.0213, lng: 127.7867 },
    { lat: 3.7229, lng: 126.6201 },
    { lat: 4.2806, lng: 125.3709 },
    { lat: 4.555, lng: 124.0487 },
    { lat: 4.4665, lng: 122.678 },
    { lat: 4.379, lng: 121.3277 },
    { lat: 4.9908, lng: 120.1496 },
    { lat: 6.0079, lng: 119.2261 },
    { lat: 6.8775, lng: 118.1799 },
    { lat: 7.5712, lng: 117.0109 },
    { lat: 7.883, lng: 115.6849 },
    { lat: 7.6648, lng: 114.356 },
    { lat: 6.93, lng: 113.2102 },
    { lat: 5.9601, lng: 112.2503 },
    { lat: 5.0115, lng: 111.2688 },
    { lat: 4.3182, lng: 110.1004 },
    { lat: 3.9658, lng: 108.7952 },
    { lat: 3.6411, lng: 107.4476 },
    { lat: 3.8619, lng: 106.1455 },
    { lat: 4.6892, lng: 105.0641 },
    { lat: 5.5995, lng: 104.0519 },
    { lat: 6.1927, lng: 102.8382 },
    { lat: 6.7808, lng: 101.613 },
    { lat: 7.634, lng: 100.573 },
    { lat: 8.5477, lng: 99.5191 },
    { lat: 9.6497, lng: 98.8659 },
    { lat: 10.9314, lng: 99.2824 },
    { lat: 12.0536, lng: 100.0651 },
    { lat: 13.1179, lng: 100.9233 },
    { lat: 13.9965, lng: 101.9373 },
    { lat: 14.9009, lng: 102.9844 },
    { lat: 16.0977, lng: 102.5614 },
    { lat: 16.6694, lng: 101.3308 },
    { lat: 16.7358, lng: 99.9863 },
    { lat: 16.1725, lng: 98.7555 },
    { lat: 15.1654, lng: 97.8351 },
    { lat: 14.0297, lng: 97.0873 },
    { lat: 12.7459, lng: 96.6269 },
    { lat: 11.4021, lng: 96.6786 },
    { lat: 10.1664, lng: 97.2758 },
    { lat: 8.9158, lng: 97.5799 },
    { lat: 7.6575, lng: 97.0252 },
    { lat: 6.4657, lng: 96.361 },
    { lat: 5.4389, lng: 95.4686 },
    { lat: 4.5628, lng: 94.4223 },
    { lat: 3.6507, lng: 93.4137 },
    { lat: 2.3914, lng: 92.9019 },
    { lat: 1.129, lng: 93.321 },
    { lat: 0.1806, lng: 94.2918 },
    { lat: -0.5784, lng: 95.4265 },
    { lat: -1.3452, lng: 96.5531 },
    { lat: -2.2858, lng: 97.5367 },
    { lat: -3.4133, lng: 98.3037 },
    { lat: -4.5772, lng: 99.0157 },
    { lat: -5.6525, lng: 99.8536 },
    { lat: -6.5918, lng: 100.8419 },
    { lat: -7.4431, lng: 101.9074 },
    { lat: -8.2206, lng: 103.0284 },
    { lat: -8.8654, lng: 104.2291 },
    { lat: -9.3519, lng: 105.5035 },
    { lat: -9.7597, lng: 106.8057 },
    { lat: -10.0812, lng: 108.1309 },
    { lat: -10.3478, lng: 109.4691 },
    { lat: -10.5997, lng: 110.81 },
  ],
  c7: [
    { lat: -10.0113, lng: 110.6579 },
    { lat: -10.2071, lng: 111.972 },
    { lat: -10.3806, lng: 113.2893 },
    { lat: -10.4835, lng: 114.6137 },
    { lat: -10.5005, lng: 115.9421 },
    { lat: -10.4579, lng: 117.2701 },
    { lat: -10.4007, lng: 118.5975 },
    { lat: -10.3544, lng: 119.9254 },
    { lat: -10.3176, lng: 121.2535 },
    { lat: -10.2853, lng: 122.5818 },
    { lat: -10.2025, lng: 123.9077 },
    { lat: -10.0346, lng: 125.2256 },
    { lat: -9.7523, lng: 126.5233 },
    { lat: -9.2204, lng: 127.7333 },
    { lat: -8.2389, lng: 128.5814 },
    { lat: -7.1176, lng: 128.045 },
    { lat: -6.5637, lng: 126.8553 },
    { lat: -6.2978, lng: 125.5502 },
    { lat: -6.13, lng: 124.232 },
    { lat: -6.0454, lng: 122.9066 },
    { lat: -6.0436, lng: 121.5778 },
    { lat: -6.0942, lng: 120.2503 },
    { lat: -6.1694, lng: 118.9237 },
    { lat: -6.1835, lng: 117.5953 },
    { lat: -6.089, lng: 116.2704 },
    { lat: -5.8968, lng: 114.9556 },
    { lat: -5.6192, lng: 113.6568 },
    { lat: -5.181, lng: 112.4062 },
    { lat: -4.46, lng: 111.2928 },
    { lat: -3.6027, lng: 110.2779 },
    { lat: -2.8332, lng: 109.1956 },
    { lat: -2.1543, lng: 108.0537 },
    { lat: -1.4554, lng: 106.9238 },
    { lat: -0.6211, lng: 105.892 },
    { lat: 0.3519, lng: 104.9885 },
    { lat: 1.3731, lng: 104.139 },
    { lat: 2.2493, lng: 103.1437 },
    { lat: 2.92, lng: 101.9975 },
    { lat: 3.6105, lng: 100.8658 },
    { lat: 4.626, lng: 100.021 },
    { lat: 5.8077, lng: 99.4153 },
    { lat: 6.6998, lng: 98.4845 },
    { lat: 6.2659, lng: 97.2864 },
    { lat: 5.4114, lng: 96.2745 },
    { lat: 4.6311, lng: 95.1999 },
    { lat: 3.8087, lng: 94.1559 },
    { lat: 2.7115, lng: 93.4331 },
    { lat: 1.4311, lng: 93.6317 },
    { lat: 0.4708, lng: 94.5337 },
    { lat: -0.24, lng: 95.6547 },
    { lat: -0.9075, lng: 96.8037 },
    { lat: -1.7405, lng: 97.8337 },
    { lat: -2.8002, lng: 98.6328 },
    { lat: -3.9284, lng: 99.3348 },
    { lat: -5.0074, lng: 100.1085 },
    { lat: -5.9602, lng: 101.0328 },
    { lat: -6.8217, lng: 102.0442 },
    { lat: -7.6138, lng: 103.1103 },
    { lat: -8.2616, lng: 104.2678 },
    { lat: -8.7612, lng: 105.4983 },
    { lat: -9.1538, lng: 106.7676 },
    { lat: -9.4927, lng: 108.0516 },
    { lat: -9.7557, lng: 109.354 },
    { lat: -10.0113, lng: 110.6579 },
  ],
  c8: [
    { lat: -8.396, lng: 121.225 },
    { lat: -8.396, lng: 121.228 },
    { lat: -8.538, lng: 122.285 },
    { lat: -8.438, lng: 123.324 },
    { lat: -8.438, lng: 123.325 },
    { lat: -8.437, lng: 123.324 },
    { lat: -8.281, lng: 122.232 },
    { lat: -8.395, lng: 121.228 },
    { lat: -8.396, lng: 121.225 },
  ],
  c9: [
    { lat: -8.774, lng: 109.325 },
    { lat: -8.854, lng: 109.889 },
    { lat: -8.974, lng: 110.69 },
    { lat: -9.1, lng: 111.506 },
    { lat: -9.231, lng: 112.338 },
    { lat: -9.32, lng: 113.177 },
    { lat: -9.374, lng: 114.027 },
    { lat: -9.302, lng: 114.872 },
    { lat: -9.13, lng: 115.715 },
    { lat: -8.937, lng: 116.57 },
    { lat: -8.909, lng: 116.694 },
    { lat: -8.308, lng: 116.613 },
    { lat: -8.262, lng: 116.456 },
    { lat: -7.93, lng: 115.52 },
    { lat: -7.679, lng: 114.833 },
    { lat: -7.63, lng: 114.612 },
    { lat: -7.471, lng: 113.743 },
    { lat: -7.313, lng: 112.891 },
    { lat: -7.161, lng: 112.056 },
    { lat: -7.036, lng: 111.581 },
    { lat: -6.954, lng: 111.23 },
    { lat: -6.741, lng: 110.417 },
    { lat: -6.456, lng: 109.612 },
    { lat: -6.42, lng: 109.499 },
    { lat: -6.191, lng: 108.823 },
    { lat: -5.895, lng: 108.044 },
    { lat: -5.815, lng: 107.888 },
    { lat: -5.449, lng: 107.266 },
    { lat: -5.222, lng: 106.967 },
    { lat: -4.83, lng: 106.492 },
    { lat: -4.634, lng: 106.286 },
    { lat: -4.098, lng: 105.728 },
    { lat: -4.049, lng: 105.68 },
    { lat: -3.466, lng: 105.152 },
    { lat: -3.234, lng: 104.976 },
    { lat: -2.886, lng: 104.77 },
    { lat: -2.307, lng: 104.521 },
    { lat: -1.729, lng: 104.32 },
    { lat: -1.498, lng: 104.223 },
    { lat: -1.152, lng: 104.086 },
    { lat: -0.576, lng: 103.774 },
    { lat: -0.182, lng: 103.515 },
    { lat: 0.0, lng: 103.377 },
    { lat: 0.552, lng: 102.829 },
    { lat: 0.575, lng: 102.8 },
    { lat: 1.026, lng: 102.153 },
    { lat: 1.148, lng: 101.916 },
    { lat: 1.351, lng: 101.483 },
    { lat: 1.658, lng: 100.82 },
    { lat: 1.719, lng: 100.703 },
    { lat: 2.026, lng: 100.164 },
    { lat: 2.29, lng: 99.866 },
    { lat: 2.653, lng: 99.521 },
    { lat: 2.861, lng: 99.385 },
    { lat: 3.432, lng: 99.025 },
    { lat: 3.64, lng: 98.899 },
    { lat: 4.003, lng: 98.666 },
    { lat: 4.489, lng: 98.282 },
    { lat: 4.574, lng: 98.157 },
    { lat: 4.832, lng: 97.653 },
    { lat: 4.798, lng: 97.012 },
    { lat: 4.581, lng: 96.369 },
    { lat: 4.562, lng: 96.335 },
    { lat: 4.269, lng: 95.728 },
    { lat: 3.985, lng: 95.242 },
    { lat: 3.889, lng: 95.09 },
    { lat: 3.411, lng: 94.498 },
    { lat: 3.362, lng: 94.455 },
    { lat: 2.841, lng: 94.11 },
    { lat: 2.272, lng: 93.981 },
    { lat: 1.704, lng: 94.13 },
    { lat: 1.261, lng: 94.419 },
    { lat: 1.136, lng: 94.525 },
    { lat: 0.726, lng: 95.034 },
    { lat: 0.569, lng: 95.272 },
    { lat: 0.355, lng: 95.656 },
    { lat: 0.042, lng: 96.282 },
    { lat: 0.0, lng: 96.387 },
    { lat: -0.209, lng: 96.914 },
    { lat: -0.5, lng: 97.551 },
    { lat: -0.57, lng: 97.684 },
    { lat: -0.907, lng: 98.195 },
    { lat: -1.142, lng: 98.468 },
    { lat: -1.576, lng: 98.848 },
    { lat: -1.715, lng: 98.964 },
    { lat: -2.288, lng: 99.369 },
    { lat: -2.551, lng: 99.519 },
    { lat: -2.862, lng: 99.721 },
    { lat: -3.437, lng: 100.078 },
    { lat: -3.651, lng: 100.21 },
    { lat: -4.014, lng: 100.486 },
    { lat: -4.571, lng: 100.913 },
    { lat: -4.592, lng: 100.934 },
    { lat: -5.174, lng: 101.519 },
    { lat: -5.271, lng: 101.621 },
    { lat: -5.757, lng: 102.2 },
    { lat: -5.874, lng: 102.337 },
    { lat: -6.344, lng: 102.902 },
    { lat: -6.474, lng: 103.065 },
    { lat: -6.934, lng: 103.69 },
    { lat: -7.004, lng: 103.801 },
    { lat: -7.418, lng: 104.541 },
    { lat: -7.53, lng: 104.788 },
    { lat: -7.7, lng: 105.279 },
    { lat: -7.926, lng: 106.023 },
    { lat: -8.142, lng: 106.672 },
    { lat: -8.17, lng: 106.78 },
    { lat: -8.33, lng: 107.538 },
    { lat: -8.521, lng: 108.311 },
    { lat: -8.721, lng: 109.098 },
    { lat: -8.774, lng: 109.325 },
  ],
};
export var absgain = {
  c1: "44.00",
  c2: "46.00",
  c3: "50.00",
  c4: "52.00",
  c5: "52.00",
  c6: "52.00",
  c7: "53.00",
  c8: "54.00",
  c9: "54.00",
};

export var abs_gnt = {
  c1: [
    { lat: -13.729, lng: 115.387 },
    { lat: -13.84, lng: 115.863 },
    { lat: -14.02, lng: 116.821 },
    { lat: -14.145, lng: 117.788 },
    { lat: -14.185, lng: 118.754 },
    { lat: -14.122, lng: 119.712 },
    { lat: -13.966, lng: 120.664 },
    { lat: -13.901, lng: 120.941 },
    { lat: -13.764, lng: 121.625 },
    { lat: -13.573, lng: 122.617 },
    { lat: -13.431, lng: 123.654 },
    { lat: -13.384, lng: 124.194 },
    { lat: -13.331, lng: 124.741 },
    { lat: -13.252, lng: 125.873 },
    { lat: -13.193, lng: 127.058 },
    { lat: -13.133, lng: 128.292 },
    { lat: -13.065, lng: 129.58 },
    { lat: -12.983, lng: 130.929 },
    { lat: -12.979, lng: 130.994 },
    { lat: -12.848, lng: 132.328 },
    { lat: -12.722, lng: 133.821 },
    { lat: -12.642, lng: 135.45 },
    { lat: -12.622, lng: 137.261 },
    { lat: -12.656, lng: 139.31 },
    { lat: -12.698, lng: 141.662 },
    { lat: -12.698, lng: 141.663 },
    { lat: -12.68, lng: 144.433 },
    { lat: -12.736, lng: 150.703 },
    { lat: -12.314, lng: 150.726 },
    { lat: -12.203, lng: 150.732 },
    { lat: -11.562, lng: 150.766 },
    { lat: -11.318, lng: 150.778 },
    { lat: -10.906, lng: 150.798 },
    { lat: -10.279, lng: 150.827 },
    { lat: -9.65, lng: 150.855 },
    { lat: -9.018, lng: 150.88 },
    { lat: -8.384, lng: 150.904 },
    { lat: -7.748, lng: 150.926 },
    { lat: -7.118, lng: 151.234 },
    { lat: -6.453, lng: 150.39 },
    { lat: -5.795, lng: 149.734 },
    { lat: -5.142, lng: 149.208 },
    { lat: -4.492, lng: 148.78 },
    { lat: -3.846, lng: 148.432 },
    { lat: -3.201, lng: 148.151 },
    { lat: -2.559, lng: 147.927 },
    { lat: -1.918, lng: 147.754 },
    { lat: -1.278, lng: 147.628 },
    { lat: -0.639, lng: 147.546 },
    { lat: -0.0, lng: 147.504 },
    { lat: 0.639, lng: 147.501 },
    { lat: 1.168, lng: 147.525 },
    { lat: 1.274, lng: 146.481 },
    { lat: 1.513, lng: 144.044 },
    { lat: 1.887, lng: 141.612 },
    { lat: 1.919, lng: 141.404 },
    { lat: 2.392, lng: 139.202 },
    { lat: 2.497, lng: 138.824 },
    { lat: 3.063, lng: 137.304 },
    { lat: 3.109, lng: 137.196 },
    { lat: 3.721, lng: 136.167 },
    { lat: 4.223, lng: 135.696 },
    { lat: 4.336, lng: 135.571 },
    { lat: 4.951, lng: 135.107 },
    { lat: 5.567, lng: 134.778 },
    { lat: 6.185, lng: 134.543 },
    { lat: 6.357, lng: 134.508 },
    { lat: 6.804, lng: 134.388 },
    { lat: 7.427, lng: 134.39 },
    { lat: 8.056, lng: 134.621 },
    { lat: 8.694, lng: 135.181 },
    { lat: 8.792, lng: 135.317 },
    { lat: 9.348, lng: 136.261 },
    { lat: 9.825, lng: 137.596 },
    { lat: 10.037, lng: 138.479 },
    { lat: 10.339, lng: 139.954 },
    { lat: 10.709, lng: 142.649 },
    { lat: 10.823, lng: 143.719 },
    { lat: 11.357, lng: 150.776 },
    { lat: 11.536, lng: 150.767 },
    { lat: 11.617, lng: 150.763 },
    { lat: 11.976, lng: 150.744 },
    { lat: 12.151, lng: 150.735 },
    { lat: 12.769, lng: 150.701 },
    { lat: 13.383, lng: 150.665 },
    { lat: 13.786, lng: 150.641 },
    { lat: 14.019, lng: 150.626 },
    { lat: 14.746, lng: 150.58 },
    { lat: 14.835, lng: 150.574 },
    { lat: 15.49, lng: 150.529 },
    { lat: 15.571, lng: 150.523 },
    { lat: 15.891, lng: 149.277 },
    { lat: 15.994, lng: 145.299 },
    { lat: 16.057, lng: 143.922 },
    { lat: 16.1, lng: 142.439 },
    { lat: 16.219, lng: 140.099 },
    { lat: 16.414, lng: 138.13 },
    { lat: 16.454, lng: 137.849 },
    { lat: 16.646, lng: 136.389 },
    { lat: 16.981, lng: 134.94 },
    { lat: 16.996, lng: 134.88 },
    { lat: 17.455, lng: 133.552 },
    { lat: 17.561, lng: 133.318 },
    { lat: 18.177, lng: 132.499 },
    { lat: 18.186, lng: 132.494 },
    { lat: 18.815, lng: 132.129 },
    { lat: 19.472, lng: 132.093 },
    { lat: 19.805, lng: 132.148 },
    { lat: 20.139, lng: 132.19 },
    { lat: 20.809, lng: 132.272 },
    { lat: 21.477, lng: 132.257 },
    { lat: 21.749, lng: 132.168 },
    { lat: 22.137, lng: 132.013 },
    { lat: 22.786, lng: 131.526 },
    { lat: 22.791, lng: 131.52 },
    { lat: 23.413, lng: 130.629 },
    { lat: 23.44, lng: 130.577 },
    { lat: 23.848, lng: 129.476 },
    { lat: 23.993, lng: 128.883 },
    { lat: 24.098, lng: 128.29 },
    { lat: 24.216, lng: 127.052 },
    { lat: 24.252, lng: 125.802 },
    { lat: 24.204, lng: 124.544 },
    { lat: 24.056, lng: 123.268 },
    { lat: 23.816, lng: 121.987 },
    { lat: 23.543, lng: 120.918 },
    { lat: 23.479, lng: 120.7 },
    { lat: 22.998, lng: 119.39 },
    { lat: 22.768, lng: 118.847 },
    { lat: 22.418, lng: 118.087 },
    { lat: 22.032, lng: 117.31 },
    { lat: 21.775, lng: 116.812 },
    { lat: 21.314, lng: 115.938 },
    { lat: 21.13, lng: 115.592 },
    { lat: 20.606, lng: 114.587 },
    { lat: 20.528, lng: 114.438 },
    { lat: 19.974, lng: 113.345 },
    { lat: 19.905, lng: 113.196 },
    { lat: 19.483, lng: 112.309 },
    { lat: 19.203, lng: 111.547 },
    { lat: 19.122, lng: 111.344 },
    { lat: 18.874, lng: 110.438 },
    { lat: 18.814, lng: 109.604 },
    { lat: 18.992, lng: 108.849 },
    { lat: 19.097, lng: 108.686 },
    { lat: 19.604, lng: 108.225 },
    { lat: 19.71, lng: 108.169 },
    { lat: 20.342, lng: 108.073 },
    { lat: 20.984, lng: 108.155 },
    { lat: 21.633, lng: 108.29 },
    { lat: 22.284, lng: 108.379 },
    { lat: 22.615, lng: 108.35 },
    { lat: 22.93, lng: 108.242 },
    { lat: 23.4, lng: 107.801 },
    { lat: 23.555, lng: 107.501 },
    { lat: 23.689, lng: 107.092 },
    { lat: 23.837, lng: 106.342 },
    { lat: 23.958, lng: 105.592 },
    { lat: 24.103, lng: 104.856 },
    { lat: 24.103, lng: 104.856 },
    { lat: 24.348, lng: 104.157 },
    { lat: 24.71, lng: 103.523 },
    { lat: 24.728, lng: 103.503 },
    { lat: 25.246, lng: 102.893 },
    { lat: 25.344, lng: 102.737 },
    { lat: 25.689, lng: 102.264 },
    { lat: 25.875, lng: 101.562 },
    { lat: 25.812, lng: 100.795 },
    { lat: 25.53, lng: 99.977 },
    { lat: 25.219, lng: 99.48 },
    { lat: 25.038, lng: 99.12 },
    { lat: 24.517, lng: 98.333 },
    { lat: 24.48, lng: 98.265 },
    { lat: 23.932, lng: 97.432 },
    { lat: 23.828, lng: 97.294 },
    { lat: 23.351, lng: 96.61 },
    { lat: 23.149, lng: 96.351 },
    { lat: 22.686, lng: 95.789 },
    { lat: 22.482, lng: 95.567 },
    { lat: 21.826, lng: 94.964 },
    { lat: 21.807, lng: 94.95 },
    { lat: 21.18, lng: 94.544 },
    { lat: 20.543, lng: 94.253 },
    { lat: 19.911, lng: 94.042 },
    { lat: 19.379, lng: 93.9 },
    { lat: 19.285, lng: 93.877 },
    { lat: 18.664, lng: 93.762 },
    { lat: 18.048, lng: 93.688 },
    { lat: 17.781, lng: 93.681 },
    { lat: 17.436, lng: 93.68 },
    { lat: 16.828, lng: 93.744 },
    { lat: 16.224, lng: 93.855 },
    { lat: 15.624, lng: 93.965 },
    { lat: 15.19, lng: 94.011 },
    { lat: 15.024, lng: 94.033 },
    { lat: 14.427, lng: 94.043 },
    { lat: 13.831, lng: 93.983 },
    { lat: 13.236, lng: 93.893 },
    { lat: 12.645, lng: 93.82 },
    { lat: 12.056, lng: 93.803 },
    { lat: 11.47, lng: 93.865 },
    { lat: 10.887, lng: 93.994 },
    { lat: 10.306, lng: 94.152 },
    { lat: 10.216, lng: 94.178 },
    { lat: 9.727, lng: 94.348 },
    { lat: 9.148, lng: 94.492 },
    { lat: 8.57, lng: 94.551 },
    { lat: 7.993, lng: 94.527 },
    { lat: 7.416, lng: 94.412 },
    { lat: 6.839, lng: 94.172 },
    { lat: 6.516, lng: 93.951 },
    { lat: 6.262, lng: 93.78 },
    { lat: 5.779, lng: 93.301 },
    { lat: 5.686, lng: 93.199 },
    { lat: 5.239, lng: 92.669 },
    { lat: 5.111, lng: 92.508 },
    { lat: 4.7, lng: 92.043 },
    { lat: 4.538, lng: 91.871 },
    { lat: 4.033, lng: 91.42 },
    { lat: 3.967, lng: 91.367 },
    { lat: 3.398, lng: 91.026 },
    { lat: 2.83, lng: 90.804 },
    { lat: 2.743, lng: 90.793 },
    { lat: 2.264, lng: 90.738 },
    { lat: 1.908, lng: 90.781 },
    { lat: 1.698, lng: 90.811 },
    { lat: 1.132, lng: 91.042 },
    { lat: 0.656, lng: 91.371 },
    { lat: 0.566, lng: 91.438 },
    { lat: 0.042, lng: 91.972 },
    { lat: -0.0, lng: 92.015 },
    { lat: -0.429, lng: 92.577 },
    { lat: -0.567, lng: 92.751 },
    { lat: -0.862, lng: 93.188 },
    { lat: -1.135, lng: 93.554 },
    { lat: -1.306, lng: 93.803 },
    { lat: -1.704, lng: 94.315 },
    { lat: -1.795, lng: 94.425 },
    { lat: -2.274, lng: 94.957 },
    { lat: -2.386, lng: 95.054 },
    { lat: -2.845, lng: 95.468 },
    { lat: -3.218, lng: 95.696 },
    { lat: -3.417, lng: 95.836 },
    { lat: -3.989, lng: 96.126 },
    { lat: -4.562, lng: 96.329 },
    { lat: -4.729, lng: 96.375 },
    { lat: -5.136, lng: 96.519 },
    { lat: -5.711, lng: 96.719 },
    { lat: -6.288, lng: 96.995 },
    { lat: -6.428, lng: 97.091 },
    { lat: -6.868, lng: 97.443 },
    { lat: -7.159, lng: 97.777 },
    { lat: -7.452, lng: 98.131 },
    { lat: -7.663, lng: 98.459 },
    { lat: -8.041, lng: 99.021 },
    { lat: -8.112, lng: 99.148 },
    { lat: -8.558, lng: 99.845 },
    { lat: -8.633, lng: 99.953 },
    { lat: -9.027, lng: 100.554 },
    { lat: -9.229, lng: 100.841 },
    { lat: -9.525, lng: 101.278 },
    { lat: -9.828, lng: 101.725 },
    { lat: -10.006, lng: 102.012 },
    { lat: -10.43, lng: 102.751 },
    { lat: -10.433, lng: 102.756 },
    { lat: -10.747, lng: 103.489 },
    { lat: -11.041, lng: 104.235 },
    { lat: -11.05, lng: 104.264 },
    { lat: -11.258, lng: 104.982 },
    { lat: -11.462, lng: 105.737 },
    { lat: -11.661, lng: 106.503 },
    { lat: -11.693, lng: 106.639 },
    { lat: -11.807, lng: 107.271 },
    { lat: -11.947, lng: 108.05 },
    { lat: -12.1, lng: 108.844 },
    { lat: -12.275, lng: 109.654 },
    { lat: -12.37, lng: 110.044 },
    { lat: -12.464, lng: 110.481 },
    { lat: -12.67, lng: 111.326 },
    { lat: -12.908, lng: 112.195 },
    { lat: -13.042, lng: 112.684 },
    { lat: -13.161, lng: 113.086 },
    { lat: -13.405, lng: 113.994 },
    { lat: -13.631, lng: 114.92 },
    { lat: -13.729, lng: 115.387 },
  ],
  c2: [
    { lat: -13.07, lng: 113.721 },
    { lat: -13.123, lng: 113.927 },
    { lat: -13.327, lng: 114.844 },
    { lat: -13.509, lng: 115.776 },
    { lat: -13.663, lng: 116.722 },
    { lat: -13.773, lng: 117.68 },
    { lat: -13.809, lng: 118.641 },
    { lat: -13.758, lng: 119.598 },
    { lat: -13.628, lng: 120.555 },
    { lat: -13.463, lng: 121.526 },
    { lat: -13.326, lng: 122.398 },
    { lat: -13.307, lng: 122.527 },
    { lat: -13.175, lng: 123.565 },
    { lat: -13.084, lng: 124.651 },
    { lat: -13.022, lng: 125.787 },
    { lat: -12.972, lng: 126.971 },
    { lat: -12.915, lng: 128.202 },
    { lat: -12.898, lng: 128.523 },
    { lat: -12.821, lng: 129.473 },
    { lat: -12.694, lng: 130.795 },
    { lat: -12.552, lng: 132.183 },
    { lat: -12.429, lng: 133.54 },
    { lat: -12.414, lng: 133.66 },
    { lat: -12.284, lng: 135.249 },
    { lat: -12.227, lng: 137.016 },
    { lat: -12.232, lng: 139.013 },
    { lat: -12.246, lng: 141.29 },
    { lat: -12.219, lng: 143.963 },
    { lat: -12.167, lng: 145.241 },
    { lat: -12.269, lng: 150.729 },
    { lat: -11.666, lng: 150.761 },
    { lat: -11.66, lng: 150.761 },
    { lat: -10.943, lng: 150.796 },
    { lat: -10.562, lng: 150.814 },
    { lat: -10.28, lng: 150.827 },
    { lat: -9.65, lng: 150.855 },
    { lat: -9.019, lng: 150.88 },
    { lat: -8.384, lng: 150.904 },
    { lat: -7.748, lng: 150.926 },
    { lat: -7.117, lng: 151.2 },
    { lat: -6.453, lng: 150.361 },
    { lat: -5.794, lng: 149.708 },
    { lat: -5.141, lng: 149.184 },
    { lat: -4.492, lng: 148.758 },
    { lat: -3.845, lng: 148.411 },
    { lat: -3.201, lng: 148.131 },
    { lat: -2.559, lng: 147.907 },
    { lat: -1.918, lng: 147.735 },
    { lat: -1.278, lng: 147.609 },
    { lat: -0.639, lng: 147.527 },
    { lat: -0.0, lng: 147.485 },
    { lat: 0.639, lng: 147.482 },
    { lat: 0.845, lng: 147.487 },
    { lat: 1.248, lng: 144.015 },
    { lat: 1.265, lng: 143.895 },
    { lat: 1.619, lng: 141.369 },
    { lat: 1.879, lng: 140.063 },
    { lat: 2.076, lng: 139.162 },
    { lat: 2.49, lng: 137.72 },
    { lat: 2.671, lng: 137.248 },
    { lat: 3.101, lng: 136.261 },
    { lat: 3.573, lng: 135.581 },
    { lat: 3.713, lng: 135.37 },
    { lat: 4.326, lng: 134.725 },
    { lat: 4.941, lng: 134.302 },
    { lat: 5.121, lng: 134.207 },
    { lat: 5.555, lng: 133.916 },
    { lat: 6.17, lng: 133.623 },
    { lat: 6.788, lng: 133.46 },
    { lat: 7.409, lng: 133.444 },
    { lat: 8.034, lng: 133.598 },
    { lat: 8.667, lng: 133.981 },
    { lat: 9.31, lng: 134.709 },
    { lat: 9.877, lng: 135.778 },
    { lat: 9.972, lng: 136.018 },
    { lat: 10.543, lng: 137.974 },
    { lat: 10.678, lng: 138.66 },
    { lat: 11.003, lng: 140.38 },
    { lat: 11.391, lng: 143.204 },
    { lat: 11.475, lng: 143.884 },
    { lat: 11.926, lng: 147.089 },
    { lat: 12.348, lng: 150.724 },
    { lat: 12.797, lng: 150.699 },
    { lat: 12.902, lng: 150.693 },
    { lat: 13.531, lng: 150.656 },
    { lat: 13.553, lng: 150.655 },
    { lat: 14.227, lng: 150.613 },
    { lat: 14.688, lng: 150.583 },
    { lat: 15.008, lng: 150.74 },
    { lat: 15.157, lng: 147.805 },
    { lat: 15.403, lng: 144.492 },
    { lat: 15.414, lng: 144.364 },
    { lat: 15.548, lng: 141.834 },
    { lat: 15.725, lng: 139.633 },
    { lat: 15.835, lng: 138.677 },
    { lat: 15.919, lng: 137.711 },
    { lat: 16.151, lng: 136.004 },
    { lat: 16.337, lng: 135.106 },
    { lat: 16.461, lng: 134.489 },
    { lat: 16.851, lng: 133.129 },
    { lat: 16.896, lng: 133.002 },
    { lat: 17.381, lng: 131.94 },
    { lat: 17.491, lng: 131.746 },
    { lat: 18.112, lng: 131.09 },
    { lat: 18.25, lng: 131.036 },
    { lat: 18.754, lng: 130.846 },
    { lat: 19.411, lng: 130.845 },
    { lat: 20.077, lng: 130.968 },
    { lat: 20.267, lng: 130.994 },
    { lat: 20.744, lng: 131.04 },
    { lat: 21.409, lng: 130.996 },
    { lat: 21.94, lng: 130.813 },
    { lat: 22.067, lng: 130.752 },
    { lat: 22.708, lng: 130.164 },
    { lat: 22.795, lng: 130.044 },
    { lat: 23.323, lng: 129.067 },
    { lat: 23.327, lng: 129.056 },
    { lat: 23.611, lng: 127.913 },
    { lat: 23.788, lng: 126.735 },
    { lat: 23.829, lng: 126.063 },
    { lat: 23.848, lng: 125.518 },
    { lat: 23.804, lng: 124.277 },
    { lat: 23.665, lng: 123.022 },
    { lat: 23.654, lng: 122.962 },
    { lat: 23.401, lng: 121.741 },
    { lat: 23.035, lng: 120.455 },
    { lat: 22.819, lng: 119.848 },
    { lat: 22.545, lng: 119.157 },
    { lat: 22.07, lng: 118.098 },
    { lat: 21.96, lng: 117.869 },
    { lat: 21.347, lng: 116.659 },
    { lat: 21.324, lng: 116.614 },
    { lat: 20.693, lng: 115.415 },
    { lat: 20.637, lng: 115.307 },
    { lat: 20.104, lng: 114.279 },
    { lat: 19.935, lng: 113.937 },
    { lat: 19.567, lng: 113.202 },
    { lat: 19.238, lng: 112.449 },
    { lat: 19.118, lng: 112.189 },
    { lat: 18.754, lng: 111.231 },
    { lat: 18.546, lng: 110.341 },
    { lat: 18.51, lng: 109.885 },
    { lat: 18.476, lng: 109.507 },
    { lat: 18.493, lng: 109.403 },
    { lat: 18.595, lng: 108.739 },
    { lat: 19.011, lng: 108.06 },
    { lat: 19.072, lng: 108.003 },
    { lat: 19.692, lng: 107.685 },
    { lat: 20.326, lng: 107.644 },
    { lat: 20.351, lng: 107.647 },
    { lat: 20.966, lng: 107.694 },
    { lat: 21.611, lng: 107.755 },
    { lat: 22.256, lng: 107.705 },
    { lat: 22.617, lng: 107.543 },
    { lat: 22.89, lng: 107.278 },
    { lat: 23.106, lng: 106.902 },
    { lat: 23.321, lng: 106.177 },
    { lat: 23.452, lng: 105.434 },
    { lat: 23.465, lng: 105.344 },
    { lat: 23.557, lng: 104.69 },
    { lat: 23.704, lng: 103.965 },
    { lat: 23.941, lng: 103.272 },
    { lat: 24.031, lng: 103.051 },
    { lat: 24.268, lng: 102.609 },
    { lat: 24.593, lng: 101.949 },
    { lat: 24.641, lng: 101.749 },
    { lat: 24.775, lng: 101.253 },
    { lat: 24.757, lng: 100.509 },
    { lat: 24.57, lng: 99.838 },
    { lat: 24.542, lng: 99.722 },
    { lat: 24.216, lng: 98.919 },
    { lat: 23.859, lng: 98.249 },
    { lat: 23.79, lng: 98.107 },
    { lat: 23.311, lng: 97.299 },
    { lat: 23.171, lng: 97.097 },
    { lat: 22.741, lng: 96.487 },
    { lat: 22.499, lng: 96.194 },
    { lat: 22.006, lng: 95.663 },
    { lat: 21.84, lng: 95.508 },
    { lat: 21.192, lng: 95.017 },
    { lat: 20.781, lng: 94.777 },
    { lat: 20.553, lng: 94.663 },
    { lat: 19.92, lng: 94.418 },
    { lat: 19.293, lng: 94.234 },
    { lat: 18.672, lng: 94.101 },
    { lat: 18.055, lng: 94.025 },
    { lat: 17.442, lng: 94.017 },
    { lat: 16.835, lng: 94.073 },
    { lat: 16.358, lng: 94.148 },
    { lat: 16.231, lng: 94.175 },
    { lat: 15.63, lng: 94.289 },
    { lat: 15.03, lng: 94.343 },
    { lat: 14.432, lng: 94.323 },
    { lat: 13.835, lng: 94.248 },
    { lat: 13.24, lng: 94.152 },
    { lat: 12.648, lng: 94.074 },
    { lat: 12.059, lng: 94.052 },
    { lat: 11.473, lng: 94.1 },
    { lat: 10.89, lng: 94.209 },
    { lat: 10.827, lng: 94.225 },
    { lat: 10.309, lng: 94.396 },
    { lat: 9.729, lng: 94.571 },
    { lat: 9.151, lng: 94.687 },
    { lat: 8.868, lng: 94.708 },
    { lat: 8.572, lng: 94.745 },
    { lat: 7.995, lng: 94.717 },
    { lat: 7.628, lng: 94.631 },
    { lat: 7.417, lng: 94.592 },
    { lat: 6.841, lng: 94.38 },
    { lat: 6.264, lng: 93.996 },
    { lat: 6.197, lng: 93.936 },
    { lat: 5.688, lng: 93.453 },
    { lat: 5.546, lng: 93.292 },
    { lat: 5.113, lng: 92.769 },
    { lat: 5.019, lng: 92.661 },
    { lat: 4.539, lng: 92.115 },
    { lat: 4.457, lng: 92.036 },
    { lat: 3.968, lng: 91.611 },
    { lat: 3.669, lng: 91.411 },
    { lat: 3.399, lng: 91.252 },
    { lat: 2.831, lng: 91.044 },
    { lat: 2.264, lng: 90.979 },
    { lat: 1.698, lng: 91.061 },
    { lat: 1.132, lng: 91.293 },
    { lat: 1.017, lng: 91.373 },
    { lat: 0.566, lng: 91.706 },
    { lat: 0.306, lng: 91.972 },
    { lat: -0.0, lng: 92.287 },
    { lat: -0.221, lng: 92.577 },
    { lat: -0.567, lng: 93.015 },
    { lat: -0.683, lng: 93.187 },
    { lat: -1.135, lng: 93.795 },
    { lat: -1.14, lng: 93.802 },
    { lat: -1.597, lng: 94.422 },
    { lat: -1.705, lng: 94.559 },
    { lat: -2.144, lng: 95.05 },
    { lat: -2.275, lng: 95.191 },
    { lat: -2.846, lng: 95.673 },
    { lat: -2.871, lng: 95.688 },
    { lat: -3.418, lng: 96.071 },
    { lat: -3.99, lng: 96.342 },
    { lat: -4.011, lng: 96.349 },
    { lat: -4.564, lng: 96.595 },
    { lat: -5.138, lng: 96.809 },
    { lat: -5.713, lng: 97.037 },
    { lat: -5.749, lng: 97.055 },
    { lat: -6.291, lng: 97.382 },
    { lat: -6.73, lng: 97.75 },
    { lat: -6.872, lng: 97.884 },
    { lat: -7.327, lng: 98.436 },
    { lat: -7.457, lng: 98.598 },
    { lat: -7.808, lng: 99.124 },
    { lat: -8.046, lng: 99.461 },
    { lat: -8.272, lng: 99.821 },
    { lat: -8.639, lng: 100.346 },
    { lat: -8.759, lng: 100.53 },
    { lat: -9.234, lng: 101.205 },
    { lat: -9.266, lng: 101.252 },
    { lat: -9.752, lng: 101.984 },
    { lat: -9.834, lng: 102.115 },
    { lat: -10.169, lng: 102.721 },
    { lat: -10.441, lng: 103.263 },
    { lat: -10.526, lng: 103.462 },
    { lat: -10.804, lng: 104.204 },
    { lat: -11.063, lng: 104.941 },
    { lat: -11.067, lng: 104.955 },
    { lat: -11.262, lng: 105.708 },
    { lat: -11.451, lng: 106.471 },
    { lat: -11.629, lng: 107.243 },
    { lat: -11.714, lng: 107.633 },
    { lat: -11.781, lng: 108.022 },
    { lat: -11.92, lng: 108.812 },
    { lat: -12.076, lng: 109.617 },
    { lat: -12.264, lng: 110.442 },
    { lat: -12.392, lng: 110.957 },
    { lat: -12.467, lng: 111.285 },
    { lat: -12.674, lng: 112.145 },
    { lat: -12.9, lng: 113.027 },
    { lat: -13.07, lng: 113.721 },
  ],
  c3: [
    { lat: -12.452, lng: 113.326 },
    { lat: -12.545, lng: 113.795 },
    { lat: -12.704, lng: 114.694 },
    { lat: -12.848, lng: 115.609 },
    { lat: -12.963, lng: 116.537 },
    { lat: -13.039, lng: 117.477 },
    { lat: -13.065, lng: 118.426 },
    { lat: -13.024, lng: 119.38 },
    { lat: -12.932, lng: 120.342 },
    { lat: -12.818, lng: 121.323 },
    { lat: -12.716, lng: 122.335 },
    { lat: -12.708, lng: 122.438 },
    { lat: -12.619, lng: 123.378 },
    { lat: -12.546, lng: 124.463 },
    { lat: -12.489, lng: 125.592 },
    { lat: -12.428, lng: 126.763 },
    { lat: -12.348, lng: 127.975 },
    { lat: -12.279, lng: 128.797 },
    { lat: -12.23, lng: 129.225 },
    { lat: -12.046, lng: 130.51 },
    { lat: -11.842, lng: 131.854 },
    { lat: -11.762, lng: 132.424 },
    { lat: -11.603, lng: 133.263 },
    { lat: -11.394, lng: 134.783 },
    { lat: -11.271, lng: 136.47 },
    { lat: -11.261, lng: 136.799 },
    { lat: -11.184, lng: 138.342 },
    { lat: -11.114, lng: 140.455 },
    { lat: -10.969, lng: 142.853 },
    { lat: -10.833, lng: 144.062 },
    { lat: -10.613, lng: 145.602 },
    { lat: -10.268, lng: 146.993 },
    { lat: -9.718, lng: 148.69 },
    { lat: -9.666, lng: 148.785 },
    { lat: -9.032, lng: 149.475 },
    { lat: -8.388, lng: 149.761 },
    { lat: -7.739, lng: 149.788 },
    { lat: -7.09, lng: 149.761 },
    { lat: -6.445, lng: 149.943 },
    { lat: -6.276, lng: 150.116 },
    { lat: -5.794, lng: 149.656 },
    { lat: -5.141, lng: 149.137 },
    { lat: -4.491, lng: 148.714 },
    { lat: -3.845, lng: 148.369 },
    { lat: -3.201, lng: 148.09 },
    { lat: -2.559, lng: 147.868 },
    { lat: -1.918, lng: 147.697 },
    { lat: -1.278, lng: 147.571 },
    { lat: -0.639, lng: 147.489 },
    { lat: -0.0, lng: 147.448 },
    { lat: 0.027, lng: 147.447 },
    { lat: 0.513, lng: 143.963 },
    { lat: 0.632, lng: 143.232 },
    { lat: 0.922, lng: 141.311 },
    { lat: 1.251, lng: 139.622 },
    { lat: 1.356, lng: 139.093 },
    { lat: 1.85, lng: 137.156 },
    { lat: 1.864, lng: 137.109 },
    { lat: 2.475, lng: 135.442 },
    { lat: 2.48, lng: 135.431 },
    { lat: 3.085, lng: 134.276 },
    { lat: 3.387, lng: 133.898 },
    { lat: 3.695, lng: 133.483 },
    { lat: 4.306, lng: 132.89 },
    { lat: 4.73, lng: 132.573 },
    { lat: 4.916, lng: 132.403 },
    { lat: 5.527, lng: 131.968 },
    { lat: 6.139, lng: 131.66 },
    { lat: 6.623, lng: 131.513 },
    { lat: 6.753, lng: 131.46 },
    { lat: 7.369, lng: 131.349 },
    { lat: 7.988, lng: 131.382 },
    { lat: 8.612, lng: 131.528 },
    { lat: 9.24, lng: 131.817 },
    { lat: 9.876, lng: 132.281 },
    { lat: 10.251, lng: 132.676 },
    { lat: 10.521, lng: 133.009 },
    { lat: 11.184, lng: 134.181 },
    { lat: 11.476, lng: 134.824 },
    { lat: 11.872, lng: 135.99 },
    { lat: 12.279, lng: 137.048 },
    { lat: 12.574, lng: 137.961 },
    { lat: 13.25, lng: 138.943 },
    { lat: 13.872, lng: 138.297 },
    { lat: 13.91, lng: 138.141 },
    { lat: 14.341, lng: 136.521 },
    { lat: 14.44, lng: 136.211 },
    { lat: 14.677, lng: 134.97 },
    { lat: 14.992, lng: 133.822 },
    { lat: 15.048, lng: 133.55 },
    { lat: 15.399, lng: 132.206 },
    { lat: 15.552, lng: 131.734 },
    { lat: 15.781, lng: 130.954 },
    { lat: 16.127, lng: 130.058 },
    { lat: 16.236, lng: 129.804 },
    { lat: 16.722, lng: 128.921 },
    { lat: 16.834, lng: 128.784 },
    { lat: 17.339, lng: 128.274 },
    { lat: 17.867, lng: 128.05 },
    { lat: 17.973, lng: 128.011 },
    { lat: 18.621, lng: 127.99 },
    { lat: 19.279, lng: 128.108 },
    { lat: 19.941, lng: 128.255 },
    { lat: 20.446, lng: 128.325 },
    { lat: 20.605, lng: 128.336 },
    { lat: 21.26, lng: 128.196 },
    { lat: 21.753, lng: 127.889 },
    { lat: 21.903, lng: 127.746 },
    { lat: 22.336, lng: 126.991 },
    { lat: 22.506, lng: 126.521 },
    { lat: 22.645, lng: 125.938 },
    { lat: 22.775, lng: 124.802 },
    { lat: 22.77, lng: 123.621 },
    { lat: 22.625, lng: 122.399 },
    { lat: 22.358, lng: 121.155 },
    { lat: 22.195, lng: 120.618 },
    { lat: 21.936, lng: 119.878 },
    { lat: 21.442, lng: 118.677 },
    { lat: 21.409, lng: 118.603 },
    { lat: 20.797, lng: 117.345 },
    { lat: 20.721, lng: 117.196 },
    { lat: 20.172, lng: 116.136 },
    { lat: 20.015, lng: 115.825 },
    { lat: 19.579, lng: 114.987 },
    { lat: 19.317, lng: 114.446 },
    { lat: 19.039, lng: 113.898 },
    { lat: 18.625, lng: 113.003 },
    { lat: 18.555, lng: 112.865 },
    { lat: 18.112, lng: 111.874 },
    { lat: 17.933, lng: 111.318 },
    { lat: 17.795, lng: 110.947 },
    { lat: 17.595, lng: 110.072 },
    { lat: 17.555, lng: 109.256 },
    { lat: 17.664, lng: 108.491 },
    { lat: 17.822, lng: 108.087 },
    { lat: 17.936, lng: 107.777 },
    { lat: 18.415, lng: 107.146 },
    { lat: 18.436, lng: 107.127 },
    { lat: 19.028, lng: 106.731 },
    { lat: 19.452, lng: 106.618 },
    { lat: 19.652, lng: 106.554 },
    { lat: 20.282, lng: 106.465 },
    { lat: 20.918, lng: 106.408 },
    { lat: 21.243, lng: 106.336 },
    { lat: 21.55, lng: 106.169 },
    { lat: 21.936, lng: 105.759 },
    { lat: 22.159, lng: 105.249 },
    { lat: 22.213, lng: 105.067 },
    { lat: 22.332, lng: 104.336 },
    { lat: 22.421, lng: 103.604 },
    { lat: 22.534, lng: 102.885 },
    { lat: 22.693, lng: 102.184 },
    { lat: 22.693, lng: 102.184 },
    { lat: 22.885, lng: 101.496 },
    { lat: 23.041, lng: 100.804 },
    { lat: 23.102, lng: 100.094 },
    { lat: 23.02, lng: 99.356 },
    { lat: 22.81, lng: 98.598 },
    { lat: 22.553, lng: 97.986 },
    { lat: 22.482, lng: 97.825 },
    { lat: 22.001, lng: 97.033 },
    { lat: 21.879, lng: 96.873 },
    { lat: 21.304, lng: 96.216 },
    { lat: 21.222, lng: 96.14 },
    { lat: 20.578, lng: 95.654 },
    { lat: 19.999, lng: 95.319 },
    { lat: 19.941, lng: 95.291 },
    { lat: 19.312, lng: 95.053 },
    { lat: 18.689, lng: 94.893 },
    { lat: 18.071, lng: 94.81 },
    { lat: 17.459, lng: 94.806 },
    { lat: 16.887, lng: 94.864 },
    { lat: 16.851, lng: 94.87 },
    { lat: 16.246, lng: 94.985 },
    { lat: 15.644, lng: 95.055 },
    { lat: 15.043, lng: 95.048 },
    { lat: 14.443, lng: 94.974 },
    { lat: 13.845, lng: 94.86 },
    { lat: 13.25, lng: 94.737 },
    { lat: 12.657, lng: 94.644 },
    { lat: 12.067, lng: 94.614 },
    { lat: 11.481, lng: 94.66 },
    { lat: 10.897, lng: 94.771 },
    { lat: 10.623, lng: 94.839 },
    { lat: 10.316, lng: 94.939 },
    { lat: 9.736, lng: 95.112 },
    { lat: 9.157, lng: 95.223 },
    { lat: 8.578, lng: 95.254 },
    { lat: 7.999, lng: 95.211 },
    { lat: 7.422, lng: 95.092 },
    { lat: 6.845, lng: 94.874 },
    { lat: 6.352, lng: 94.564 },
    { lat: 6.268, lng: 94.515 },
    { lat: 5.692, lng: 94.018 },
    { lat: 5.592, lng: 93.91 },
    { lat: 5.116, lng: 93.368 },
    { lat: 5.036, lng: 93.273 },
    { lat: 4.542, lng: 92.695 },
    { lat: 4.493, lng: 92.644 },
    { lat: 3.97, lng: 92.151 },
    { lat: 3.787, lng: 92.018 },
    { lat: 3.401, lng: 91.774 },
    { lat: 2.833, lng: 91.551 },
    { lat: 2.265, lng: 91.491 },
    { lat: 1.699, lng: 91.591 },
    { lat: 1.133, lng: 91.846 },
    { lat: 0.956, lng: 91.975 },
    { lat: 0.567, lng: 92.279 },
    { lat: 0.277, lng: 92.577 },
    { lat: -0.0, lng: 92.874 },
    { lat: -0.239, lng: 93.185 },
    { lat: -0.567, lng: 93.607 },
    { lat: -0.702, lng: 93.799 },
    { lat: -1.136, lng: 94.388 },
    { lat: -1.158, lng: 94.418 },
    { lat: -1.634, lng: 95.042 },
    { lat: -1.706, lng: 95.133 },
    { lat: -2.209, lng: 95.674 },
    { lat: -2.277, lng: 95.746 },
    { lat: -2.848, lng: 96.231 },
    { lat: -2.991, lng: 96.319 },
    { lat: -3.421, lng: 96.614 },
    { lat: -3.993, lng: 96.91 },
    { lat: -4.177, lng: 96.988 },
    { lat: -4.567, lng: 97.189 },
    { lat: -5.143, lng: 97.466 },
    { lat: -5.564, lng: 97.687 },
    { lat: -5.719, lng: 97.784 },
    { lat: -6.298, lng: 98.222 },
    { lat: -6.466, lng: 98.382 },
    { lat: -6.881, lng: 98.809 },
    { lat: -7.092, lng: 99.074 },
    { lat: -7.467, lng: 99.545 },
    { lat: -7.623, lng: 99.77 },
    { lat: -8.057, lng: 100.364 },
    { lat: -8.133, lng: 100.476 },
    { lat: -8.65, lng: 101.194 },
    { lat: -8.65, lng: 101.194 },
    { lat: -9.148, lng: 101.922 },
    { lat: -9.247, lng: 102.071 },
    { lat: -9.599, lng: 102.657 },
    { lat: -9.85, lng: 103.116 },
    { lat: -9.984, lng: 103.397 },
    { lat: -10.296, lng: 104.14 },
    { lat: -10.464, lng: 104.576 },
    { lat: -10.565, lng: 104.888 },
    { lat: -10.79, lng: 105.641 },
    { lat: -11.01, lng: 106.405 },
    { lat: -11.099, lng: 106.743 },
    { lat: -11.189, lng: 107.174 },
    { lat: -11.34, lng: 107.951 },
    { lat: -11.492, lng: 108.739 },
    { lat: -11.653, lng: 109.542 },
    { lat: -11.769, lng: 110.073 },
    { lat: -11.822, lng: 110.359 },
    { lat: -11.987, lng: 111.191 },
    { lat: -12.169, lng: 112.041 },
    { lat: -12.365, lng: 112.911 },
    { lat: -12.452, lng: 113.326 },
  ],
  c4: [
    { lat: -11.849, lng: 113.433 },
    { lat: -11.885, lng: 113.652 },
    { lat: -12.007, lng: 114.536 },
    { lat: -12.111, lng: 115.434 },
    { lat: -12.188, lng: 116.345 },
    { lat: -12.233, lng: 117.269 },
    { lat: -12.241, lng: 118.205 },
    { lat: -12.209, lng: 119.153 },
    { lat: -12.147, lng: 120.117 },
    { lat: -12.075, lng: 121.103 },
    { lat: -12.059, lng: 121.334 },
    { lat: -11.994, lng: 122.114 },
    { lat: -11.923, lng: 123.157 },
    { lat: -11.866, lng: 124.239 },
    { lat: -11.808, lng: 125.358 },
    { lat: -11.731, lng: 126.513 },
    { lat: -11.626, lng: 127.703 },
    { lat: -11.623, lng: 127.728 },
    { lat: -11.423, lng: 128.91 },
    { lat: -11.174, lng: 130.155 },
    { lat: -11.081, lng: 130.587 },
    { lat: -10.835, lng: 131.429 },
    { lat: -10.511, lng: 132.637 },
    { lat: -10.466, lng: 132.763 },
    { lat: -10.067, lng: 134.171 },
    { lat: -9.942, lng: 134.843 },
    { lat: -9.726, lng: 135.71 },
    { lat: -9.453, lng: 137.413 },
    { lat: -9.387, lng: 137.85 },
    { lat: -9.105, lng: 139.259 },
    { lat: -8.814, lng: 140.35 },
    { lat: -8.506, lng: 141.207 },
    { lat: -8.208, lng: 141.689 },
    { lat: -7.588, lng: 142.457 },
    { lat: -7.068, lng: 143.013 },
    { lat: -6.963, lng: 143.074 },
    { lat: -6.334, lng: 143.494 },
    { lat: -5.707, lng: 144.086 },
    { lat: -5.083, lng: 144.937 },
    { lat: -5.032, lng: 145.016 },
    { lat: -4.455, lng: 145.664 },
    { lat: -3.826, lng: 146.489 },
    { lat: -3.194, lng: 147.3 },
    { lat: -2.558, lng: 147.744 },
    { lat: -1.916, lng: 147.441 },
    { lat: -1.273, lng: 146.105 },
    { lat: -0.633, lng: 143.969 },
    { lat: -0.633, lng: 143.968 },
    { lat: -0.067, lng: 141.284 },
    { lat: -0.0, lng: 140.971 },
    { lat: 0.371, lng: 139.045 },
    { lat: 0.623, lng: 137.932 },
    { lat: 0.815, lng: 137.088 },
    { lat: 1.238, lng: 135.604 },
    { lat: 1.323, lng: 135.333 },
    { lat: 1.849, lng: 133.925 },
    { lat: 1.936, lng: 133.738 },
    { lat: 2.457, lng: 132.717 },
    { lat: 2.757, lng: 132.289 },
    { lat: 3.065, lng: 131.83 },
    { lat: 3.673, lng: 131.136 },
    { lat: 3.84, lng: 130.983 },
    { lat: 4.279, lng: 130.494 },
    { lat: 4.886, lng: 129.961 },
    { lat: 5.104, lng: 129.812 },
    { lat: 5.492, lng: 129.481 },
    { lat: 6.099, lng: 129.108 },
    { lat: 6.709, lng: 128.883 },
    { lat: 6.869, lng: 128.849 },
    { lat: 7.319, lng: 128.702 },
    { lat: 7.932, lng: 128.603 },
    { lat: 8.547, lng: 128.57 },
    { lat: 9.164, lng: 128.58 },
    { lat: 9.784, lng: 128.619 },
    { lat: 10.407, lng: 128.718 },
    { lat: 11.032, lng: 128.839 },
    { lat: 11.502, lng: 128.94 },
    { lat: 11.659, lng: 128.97 },
    { lat: 12.287, lng: 129.055 },
    { lat: 12.912, lng: 128.952 },
    { lat: 13.526, lng: 128.496 },
    { lat: 13.546, lng: 128.469 },
    { lat: 14.129, lng: 127.694 },
    { lat: 14.238, lng: 127.497 },
    { lat: 14.724, lng: 126.7 },
    { lat: 14.826, lng: 126.52 },
    { lat: 15.323, lng: 125.788 },
    { lat: 15.466, lng: 125.603 },
    { lat: 15.93, lng: 125.084 },
    { lat: 16.287, lng: 124.799 },
    { lat: 16.546, lng: 124.604 },
    { lat: 17.174, lng: 124.359 },
    { lat: 17.732, lng: 124.322 },
    { lat: 17.812, lng: 124.31 },
    { lat: 18.458, lng: 124.368 },
    { lat: 19.109, lng: 124.485 },
    { lat: 19.763, lng: 124.555 },
    { lat: 20.248, lng: 124.473 },
    { lat: 20.408, lng: 124.39 },
    { lat: 20.902, lng: 123.68 },
    { lat: 21.006, lng: 123.207 },
    { lat: 21.069, lng: 122.644 },
    { lat: 20.965, lng: 121.495 },
    { lat: 20.912, lng: 121.273 },
    { lat: 20.634, lng: 120.273 },
    { lat: 20.167, lng: 119.036 },
    { lat: 20.155, lng: 119.009 },
    { lat: 19.574, lng: 117.796 },
    { lat: 19.446, lng: 117.538 },
    { lat: 18.96, lng: 116.599 },
    { lat: 18.751, lng: 116.196 },
    { lat: 18.324, lng: 115.442 },
    { lat: 18.068, lng: 114.966 },
    { lat: 17.666, lng: 114.323 },
    { lat: 17.398, lng: 113.867 },
    { lat: 16.9, lng: 113.213 },
    { lat: 16.741, lng: 112.973 },
    { lat: 16.099, lng: 112.339 },
    { lat: 15.473, lng: 112.098 },
    { lat: 14.863, lng: 112.259 },
    { lat: 14.425, lng: 112.544 },
    { lat: 14.26, lng: 112.624 },
    { lat: 13.656, lng: 112.88 },
    { lat: 13.049, lng: 112.934 },
    { lat: 12.438, lng: 112.801 },
    { lat: 11.824, lng: 112.403 },
    { lat: 11.211, lng: 111.962 },
    { lat: 11.008, lng: 111.819 },
    { lat: 10.601, lng: 111.515 },
    { lat: 9.996, lng: 111.119 },
    { lat: 9.394, lng: 110.804 },
    { lat: 9.274, lng: 110.733 },
    { lat: 8.792, lng: 110.378 },
    { lat: 8.192, lng: 109.865 },
    { lat: 8.132, lng: 109.796 },
    { lat: 7.85, lng: 108.992 },
    { lat: 8.169, lng: 108.396 },
    { lat: 8.403, lng: 108.297 },
    { lat: 8.756, lng: 108.262 },
    { lat: 9.349, lng: 108.341 },
    { lat: 9.944, lng: 108.45 },
    { lat: 10.54, lng: 108.563 },
    { lat: 11.139, lng: 108.656 },
    { lat: 11.738, lng: 108.731 },
    { lat: 12.34, lng: 108.793 },
    { lat: 12.945, lng: 108.928 },
    { lat: 13.553, lng: 109.118 },
    { lat: 13.555, lng: 109.118 },
    { lat: 14.168, lng: 109.362 },
    { lat: 14.782, lng: 109.517 },
    { lat: 14.983, lng: 109.422 },
    { lat: 15.388, lng: 109.308 },
    { lat: 15.733, lng: 108.806 },
    { lat: 15.978, lng: 108.515 },
    { lat: 16.134, lng: 108.118 },
    { lat: 16.516, lng: 107.433 },
    { lat: 16.56, lng: 107.37 },
    { lat: 16.896, lng: 106.754 },
    { lat: 17.148, lng: 106.418 },
    { lat: 17.394, lng: 106.11 },
    { lat: 17.747, lng: 105.748 },
    { lat: 18.062, lng: 105.51 },
    { lat: 18.355, lng: 105.297 },
    { lat: 18.97, lng: 105.005 },
    { lat: 19.025, lng: 104.988 },
    { lat: 19.588, lng: 104.694 },
    { lat: 19.973, lng: 104.47 },
    { lat: 20.203, lng: 104.21 },
    { lat: 20.442, lng: 103.839 },
    { lat: 20.662, lng: 103.152 },
    { lat: 20.782, lng: 102.559 },
    { lat: 20.799, lng: 102.45 },
    { lat: 20.914, lng: 101.749 },
    { lat: 21.073, lng: 101.063 },
    { lat: 21.243, lng: 100.384 },
    { lat: 21.332, lng: 99.91 },
    { lat: 21.364, lng: 99.699 },
    { lat: 21.351, lng: 98.99 },
    { lat: 21.297, lng: 98.745 },
    { lat: 21.17, lng: 98.255 },
    { lat: 20.766, lng: 97.485 },
    { lat: 20.622, lng: 97.302 },
    { lat: 19.99, lng: 96.663 },
    { lat: 19.976, lng: 96.652 },
    { lat: 19.343, lng: 96.307 },
    { lat: 18.717, lng: 96.092 },
    { lat: 18.098, lng: 95.997 },
    { lat: 17.485, lng: 96.008 },
    { lat: 16.876, lng: 96.075 },
    { lat: 16.404, lng: 96.109 },
    { lat: 16.27, lng: 96.124 },
    { lat: 15.665, lng: 96.112 },
    { lat: 15.061, lng: 96.0 },
    { lat: 14.684, lng: 95.888 },
    { lat: 14.459, lng: 95.833 },
    { lat: 13.859, lng: 95.667 },
    { lat: 13.262, lng: 95.51 },
    { lat: 12.669, lng: 95.398 },
    { lat: 12.078, lng: 95.356 },
    { lat: 11.491, lng: 95.398 },
    { lat: 10.937, lng: 95.5 },
    { lat: 10.907, lng: 95.508 },
    { lat: 10.326, lng: 95.706 },
    { lat: 9.745, lng: 95.864 },
    { lat: 9.165, lng: 95.95 },
    { lat: 8.634, lng: 95.955 },
    { lat: 8.585, lng: 95.957 },
    { lat: 8.503, lng: 95.946 },
    { lat: 8.006, lng: 95.891 },
    { lat: 7.428, lng: 95.756 },
    { lat: 6.85, lng: 95.54 },
    { lat: 6.273, lng: 95.184 },
    { lat: 6.273, lng: 95.184 },
    { lat: 5.696, lng: 94.736 },
    { lat: 5.5, lng: 94.526 },
    { lat: 5.121, lng: 94.116 },
    { lat: 4.928, lng: 93.885 },
    { lat: 4.546, lng: 93.429 },
    { lat: 4.386, lng: 93.252 },
    { lat: 3.973, lng: 92.831 },
    { lat: 3.711, lng: 92.623 },
    { lat: 3.403, lng: 92.408 },
    { lat: 2.834, lng: 92.166 },
    { lat: 2.267, lng: 92.1 },
    { lat: 1.7, lng: 92.212 },
    { lat: 1.134, lng: 92.488 },
    { lat: 1.012, lng: 92.58 },
    { lat: 0.567, lng: 92.949 },
    { lat: 0.346, lng: 93.186 },
    { lat: -0.0, lng: 93.58 },
    { lat: -0.163, lng: 93.797 },
    { lat: -0.568, lng: 94.343 },
    { lat: -0.617, lng: 94.414 },
    { lat: -1.049, lng: 95.036 },
    { lat: -1.137, lng: 95.163 },
    { lat: -1.515, lng: 95.664 },
    { lat: -1.707, lng: 95.908 },
    { lat: -2.084, lng: 96.3 },
    { lat: -2.279, lng: 96.499 },
    { lat: -2.851, lng: 96.941 },
    { lat: -2.863, lng: 96.949 },
    { lat: -3.424, lng: 97.325 },
    { lat: -3.981, lng: 97.62 },
    { lat: -3.997, lng: 97.63 },
    { lat: -4.572, lng: 97.957 },
    { lat: -5.148, lng: 98.281 },
    { lat: -5.2, lng: 98.314 },
    { lat: -5.727, lng: 98.703 },
    { lat: -6.088, lng: 99.011 },
    { lat: -6.307, lng: 99.217 },
    { lat: -6.758, lng: 99.709 },
    { lat: -6.891, lng: 99.863 },
    { lat: -7.323, lng: 100.413 },
    { lat: -7.479, lng: 100.61 },
    { lat: -7.859, lng: 101.126 },
    { lat: -8.07, lng: 101.408 },
    { lat: -8.382, lng: 101.849 },
    { lat: -8.664, lng: 102.254 },
    { lat: -8.875, lng: 102.582 },
    { lat: -9.264, lng: 103.238 },
    { lat: -9.308, lng: 103.322 },
    { lat: -9.642, lng: 104.062 },
    { lat: -9.875, lng: 104.618 },
    { lat: -9.943, lng: 104.809 },
    { lat: -10.183, lng: 105.56 },
    { lat: -10.424, lng: 106.322 },
    { lat: -10.502, lng: 106.588 },
    { lat: -10.619, lng: 107.09 },
    { lat: -10.786, lng: 107.864 },
    { lat: -10.946, lng: 108.65 },
    { lat: -11.109, lng: 109.449 },
    { lat: -11.161, lng: 109.705 },
    { lat: -11.254, lng: 110.258 },
    { lat: -11.404, lng: 111.082 },
    { lat: -11.565, lng: 111.922 },
    { lat: -11.73, lng: 112.78 },
    { lat: -11.849, lng: 113.433 },
  ],
  c5: [
    { lat: -3.524, lng: 113.696 },
    { lat: -3.696, lng: 114.209 },
    { lat: -3.777, lng: 115.066 },
    { lat: -3.632, lng: 115.923 },
    { lat: -3.542, lng: 116.112 },
    { lat: -2.955, lng: 116.627 },
    { lat: -2.561, lng: 116.743 },
    { lat: -2.364, lng: 116.793 },
    { lat: -1.773, lng: 116.837 },
    { lat: -1.182, lng: 117.105 },
    { lat: -0.666, lng: 117.583 },
    { lat: -0.592, lng: 117.665 },
    { lat: -0.0, lng: 118.498 },
    { lat: 0.001, lng: 118.499 },
    { lat: 0.594, lng: 119.16 },
    { lat: 1.189, lng: 119.456 },
    { lat: 1.783, lng: 119.475 },
    { lat: 2.225, lng: 118.551 },
    { lat: 2.22, lng: 117.629 },
    { lat: 2.179, lng: 116.725 },
    { lat: 2.13, lng: 115.84 },
    { lat: 2.06, lng: 114.973 },
    { lat: 1.946, lng: 114.121 },
    { lat: 1.804, lng: 113.285 },
    { lat: 1.758, lng: 113.009 },
    { lat: 1.639, lng: 112.462 },
    { lat: 1.429, lng: 111.654 },
    { lat: 1.297, lng: 110.86 },
    { lat: 1.165, lng: 110.083 },
    { lat: 1.164, lng: 110.08 },
    { lat: 0.872, lng: 109.308 },
    { lat: 0.581, lng: 109.002 },
    { lat: 0.003, lng: 109.303 },
    { lat: -0.0, lng: 109.304 },
    { lat: -0.437, lng: 110.071 },
    { lat: -0.583, lng: 110.227 },
    { lat: -1.05, lng: 110.856 },
    { lat: -1.167, lng: 110.97 },
    { lat: -1.753, lng: 111.536 },
    { lat: -1.888, lng: 111.666 },
    { lat: -2.34, lng: 112.082 },
    { lat: -2.761, lng: 112.504 },
    { lat: -2.93, lng: 112.703 },
    { lat: -3.383, lng: 113.355 },
    { lat: -3.524, lng: 113.696 },
  ],
  c6: [
    { lat: -4.226, lng: 125.491 },
    { lat: -4.291, lng: 125.96 },
    { lat: -4.279, lng: 127.145 },
    { lat: -4.245, lng: 127.343 },
    { lat: -3.645, lng: 128.164 },
    { lat: -3.032, lng: 127.561 },
    { lat: -2.752, lng: 126.984 },
    { lat: -2.616, lng: 125.795 },
    { lat: -2.95, lng: 124.687 },
    { lat: -3.01, lng: 124.599 },
    { lat: -3.611, lng: 124.342 },
    { lat: -3.96, lng: 124.782 },
    { lat: -4.226, lng: 125.491 },
  ],
  c7: [
    { lat: -10.584, lng: 110.672 },
    { lat: -10.627, lng: 110.946 },
    { lat: -10.755, lng: 111.774 },
    { lat: -10.886, lng: 112.617 },
    { lat: -11.004, lng: 113.475 },
    { lat: -11.104, lng: 114.346 },
    { lat: -11.171, lng: 115.227 },
    { lat: -11.207, lng: 116.121 },
    { lat: -11.212, lng: 117.027 },
    { lat: -11.195, lng: 117.948 },
    { lat: -11.161, lng: 118.887 },
    { lat: -11.112, lng: 119.845 },
    { lat: -11.062, lng: 120.827 },
    { lat: -11.019, lng: 121.839 },
    { lat: -10.978, lng: 122.881 },
    { lat: -10.935, lng: 123.956 },
    { lat: -10.911, lng: 124.382 },
    { lat: -10.853, lng: 125.056 },
    { lat: -10.717, lng: 126.179 },
    { lat: -10.514, lng: 127.324 },
    { lat: -10.385, lng: 127.878 },
    { lat: -10.188, lng: 128.476 },
    { lat: -9.806, lng: 129.508 },
    { lat: -9.735, lng: 129.639 },
    { lat: -9.21, lng: 130.538 },
    { lat: -9.014, lng: 130.773 },
    { lat: -8.607, lng: 131.324 },
    { lat: -8.021, lng: 131.894 },
    { lat: -7.999, lng: 131.923 },
    { lat: -7.391, lng: 132.517 },
    { lat: -6.854, lng: 133.057 },
    { lat: -6.783, lng: 133.171 },
    { lat: -6.184, lng: 134.462 },
    { lat: -6.184, lng: 134.463 },
    { lat: -5.664, lng: 136.025 },
    { lat: -5.588, lng: 136.196 },
    { lat: -5.032, lng: 137.709 },
    { lat: -4.986, lng: 137.787 },
    { lat: -4.374, lng: 138.878 },
    { lat: -4.027, lng: 139.5 },
    { lat: -3.757, lng: 139.814 },
    { lat: -3.135, lng: 140.334 },
    { lat: -2.508, lng: 140.442 },
    { lat: -1.878, lng: 139.856 },
    { lat: -1.531, lng: 139.107 },
    { lat: -1.247, lng: 138.31 },
    { lat: -0.918, lng: 137.092 },
    { lat: -0.619, lng: 135.93 },
    { lat: -0.47, lng: 135.298 },
    { lat: -0.053, lng: 133.661 },
    { lat: -0.0, lng: 133.474 },
    { lat: 0.409, lng: 132.147 },
    { lat: 0.612, lng: 131.652 },
    { lat: 1.069, lng: 130.74 },
    { lat: 1.221, lng: 130.478 },
    { lat: 1.828, lng: 129.566 },
    { lat: 1.925, lng: 129.435 },
    { lat: 2.432, lng: 128.65 },
    { lat: 2.767, lng: 128.214 },
    { lat: 3.034, lng: 127.74 },
    { lat: 3.503, lng: 127.054 },
    { lat: 3.632, lng: 126.768 },
    { lat: 4.079, lng: 125.935 },
    { lat: 4.226, lng: 125.472 },
    { lat: 4.468, lng: 124.84 },
    { lat: 4.579, lng: 123.752 },
    { lat: 4.47, lng: 122.674 },
    { lat: 4.32, lng: 121.625 },
    { lat: 4.519, lng: 120.643 },
    { lat: 4.773, lng: 120.424 },
    { lat: 5.364, lng: 119.829 },
    { lat: 5.41, lng: 119.765 },
    { lat: 5.956, lng: 119.285 },
    { lat: 6.25, lng: 118.916 },
    { lat: 6.545, lng: 118.617 },
    { lat: 6.972, lng: 118.08 },
    { lat: 7.131, lng: 117.838 },
    { lat: 7.48, lng: 117.236 },
    { lat: 7.709, lng: 116.626 },
    { lat: 7.794, lng: 116.383 },
    { lat: 7.912, lng: 115.518 },
    { lat: 7.791, lng: 114.635 },
    { lat: 7.672, lng: 114.367 },
    { lat: 7.351, lng: 113.727 },
    { lat: 7.062, lng: 113.374 },
    { lat: 6.547, lng: 112.801 },
    { lat: 6.461, lng: 112.717 },
    { lat: 5.864, lng: 112.159 },
    { lat: 5.566, lng: 111.889 },
    { lat: 5.269, lng: 111.549 },
    { lat: 4.807, lng: 111.029 },
    { lat: 4.674, lng: 110.794 },
    { lat: 4.324, lng: 110.212 },
    { lat: 4.106, lng: 109.427 },
    { lat: 4.076, lng: 109.235 },
    { lat: 3.925, lng: 108.657 },
    { lat: 3.748, lng: 107.899 },
    { lat: 3.608, lng: 107.153 },
    { lat: 3.667, lng: 106.427 },
    { lat: 4.05, lng: 105.869 },
    { lat: 4.114, lng: 105.728 },
    { lat: 4.624, lng: 105.122 },
    { lat: 4.696, lng: 105.047 },
    { lat: 5.199, lng: 104.528 },
    { lat: 5.343, lng: 104.381 },
    { lat: 5.773, lng: 103.809 },
    { lat: 5.869, lng: 103.716 },
    { lat: 6.152, lng: 103.042 },
    { lat: 6.338, lng: 102.371 },
    { lat: 6.339, lng: 102.368 },
    { lat: 6.653, lng: 101.709 },
    { lat: 6.909, lng: 101.481 },
    { lat: 7.175, lng: 101.072 },
    { lat: 7.481, lng: 100.797 },
    { lat: 7.729, lng: 100.444 },
    { lat: 8.054, lng: 100.093 },
    { lat: 8.274, lng: 99.821 },
    { lat: 8.627, lng: 99.426 },
    { lat: 8.9, lng: 99.212 },
    { lat: 9.202, lng: 98.908 },
    { lat: 9.784, lng: 98.783 },
    { lat: 10.372, lng: 98.985 },
    { lat: 10.964, lng: 99.333 },
    { lat: 11.09, lng: 99.423 },
    { lat: 11.56, lng: 99.708 },
    { lat: 12.158, lng: 100.118 },
    { lat: 12.315, lng: 100.237 },
    { lat: 12.761, lng: 100.624 },
    { lat: 13.257, lng: 101.043 },
    { lat: 13.369, lng: 101.184 },
    { lat: 13.95, lng: 101.836 },
    { lat: 13.983, lng: 101.904 },
    { lat: 14.501, lng: 102.624 },
    { lat: 14.606, lng: 102.818 },
    { lat: 15.219, lng: 103.101 },
    { lat: 15.82, lng: 102.852 },
    { lat: 15.82, lng: 102.852 },
    { lat: 16.32, lng: 102.232 },
    { lat: 16.41, lng: 102.091 },
    { lat: 16.622, lng: 101.58 },
    { lat: 16.766, lng: 100.905 },
    { lat: 16.78, lng: 100.212 },
    { lat: 16.632, lng: 99.499 },
    { lat: 16.333, lng: 98.996 },
    { lat: 16.2, lng: 98.749 },
    { lat: 15.711, lng: 98.314 },
    { lat: 15.362, lng: 97.953 },
    { lat: 15.097, lng: 97.78 },
    { lat: 14.488, lng: 97.362 },
    { lat: 14.148, lng: 97.131 },
    { lat: 13.883, lng: 97.002 },
    { lat: 13.284, lng: 96.759 },
    { lat: 12.688, lng: 96.599 },
    { lat: 12.097, lng: 96.56 },
    { lat: 11.51, lng: 96.643 },
    { lat: 11.016, lng: 96.791 },
    { lat: 10.926, lng: 96.844 },
    { lat: 10.346, lng: 97.201 },
    { lat: 9.968, lng: 97.343 },
    { lat: 9.767, lng: 97.552 },
    { lat: 9.186, lng: 97.67 },
    { lat: 8.603, lng: 97.498 },
    { lat: 8.059, lng: 97.193 },
    { lat: 8.02, lng: 97.181 },
    { lat: 7.44, lng: 96.935 },
    { lat: 6.86, lng: 96.616 },
    { lat: 6.662, lng: 96.466 },
    { lat: 6.282, lng: 96.25 },
    { lat: 5.752, lng: 95.787 },
    { lat: 5.704, lng: 95.75 },
    { lat: 5.143, lng: 95.134 },
    { lat: 5.127, lng: 95.117 },
    { lat: 4.625, lng: 94.492 },
    { lat: 4.551, lng: 94.402 },
    { lat: 4.095, lng: 93.858 },
    { lat: 3.978, lng: 93.728 },
    { lat: 3.412, lng: 93.226 },
    { lat: 3.406, lng: 93.221 },
    { lat: 2.837, lng: 92.953 },
    { lat: 2.269, lng: 92.876 },
    { lat: 1.701, lng: 92.991 },
    { lat: 1.31, lng: 93.191 },
    { lat: 1.135, lng: 93.297 },
    { lat: 0.573, lng: 93.798 },
    { lat: 0.568, lng: 93.804 },
    { lat: 0.085, lng: 94.413 },
    { lat: -0.0, lng: 94.53 },
    { lat: -0.324, lng: 95.032 },
    { lat: -0.569, lng: 95.417 },
    { lat: -0.722, lng: 95.657 },
    { lat: -1.139, lng: 96.281 },
    { lat: -1.144, lng: 96.288 },
    { lat: -1.639, lng: 96.925 },
    { lat: -1.71, lng: 97.009 },
    { lat: -2.282, lng: 97.538 },
    { lat: -2.332, lng: 97.574 },
    { lat: -2.855, lng: 97.967 },
    { lat: -3.32, lng: 98.241 },
    { lat: -3.429, lng: 98.312 },
    { lat: -4.003, lng: 98.663 },
    { lat: -4.457, lng: 98.93 },
    { lat: -4.579, lng: 99.014 },
    { lat: -5.157, lng: 99.431 },
    { lat: -5.407, lng: 99.629 },
    { lat: -5.737, lng: 99.928 },
    { lat: -6.141, lng: 100.332 },
    { lat: -6.32, lng: 100.527 },
    { lat: -6.761, lng: 101.042 },
    { lat: -6.906, lng: 101.214 },
    { lat: -7.331, lng: 101.76 },
    { lat: -7.495, lng: 101.971 },
    { lat: -7.864, lng: 102.488 },
    { lat: -8.088, lng: 102.812 },
    { lat: -8.342, lng: 103.223 },
    { lat: -8.687, lng: 103.837 },
    { lat: -8.749, lng: 103.964 },
    { lat: -9.058, lng: 104.706 },
    { lat: -9.297, lng: 105.309 },
    { lat: -9.346, lng: 105.456 },
    { lat: -9.574, lng: 106.211 },
    { lat: -9.807, lng: 106.977 },
    { lat: -9.924, lng: 107.386 },
    { lat: -10.005, lng: 107.751 },
    { lat: -10.162, lng: 108.531 },
    { lat: -10.316, lng: 109.322 },
    { lat: -10.473, lng: 110.127 },
    { lat: -10.584, lng: 110.672 },
  ],
  c8: [
    { lat: -9.983, lng: 110.465 },
    { lat: -10.044, lng: 110.85 },
    { lat: -10.163, lng: 111.672 },
    { lat: -10.284, lng: 112.51 },
    { lat: -10.391, lng: 113.36 },
    { lat: -10.466, lng: 114.221 },
    { lat: -10.502, lng: 115.092 },
    { lat: -10.503, lng: 115.973 },
    { lat: -10.475, lng: 116.867 },
    { lat: -10.436, lng: 117.777 },
    { lat: -10.395, lng: 118.709 },
    { lat: -10.361, lng: 119.664 },
    { lat: -10.335, lng: 120.646 },
    { lat: -10.306, lng: 121.655 },
    { lat: -10.283, lng: 122.695 },
    { lat: -10.264, lng: 123.117 },
    { lat: -10.219, lng: 123.756 },
    { lat: -10.099, lng: 124.838 },
    { lat: -9.911, lng: 125.938 },
    { lat: -9.735, lng: 126.63 },
    { lat: -9.569, lng: 127.036 },
    { lat: -9.149, lng: 127.891 },
    { lat: -8.956, lng: 128.101 },
    { lat: -8.545, lng: 128.489 },
    { lat: -7.933, lng: 128.675 },
    { lat: -7.312, lng: 128.332 },
    { lat: -6.815, lng: 127.574 },
    { lat: -6.681, lng: 127.258 },
    { lat: -6.425, lng: 126.291 },
    { lat: -6.224, lng: 125.099 },
    { lat: -6.101, lng: 123.966 },
    { lat: -6.038, lng: 122.881 },
    { lat: -6.039, lng: 121.84 },
    { lat: -6.06, lng: 120.832 },
    { lat: -6.118, lng: 119.856 },
    { lat: -6.173, lng: 118.905 },
    { lat: -6.194, lng: 117.973 },
    { lat: -6.164, lng: 117.055 },
    { lat: -6.079, lng: 116.152 },
    { lat: -5.951, lng: 115.262 },
    { lat: -5.899, lng: 114.969 },
    { lat: -5.79, lng: 114.388 },
    { lat: -5.594, lng: 113.528 },
    { lat: -5.319, lng: 112.677 },
    { lat: -5.28, lng: 112.588 },
    { lat: -4.855, lng: 111.829 },
    { lat: -4.681, lng: 111.572 },
    { lat: -4.207, lng: 110.986 },
    { lat: -4.089, lng: 110.847 },
    { lat: -3.499, lng: 110.163 },
    { lat: -3.498, lng: 110.162 },
    { lat: -2.938, lng: 109.366 },
    { lat: -2.91, lng: 109.32 },
    { lat: -2.463, lng: 108.59 },
    { lat: -2.323, lng: 108.351 },
    { lat: -2.031, lng: 107.83 },
    { lat: -1.739, lng: 107.371 },
    { lat: -1.569, lng: 107.082 },
    { lat: -1.157, lng: 106.512 },
    { lat: -1.036, lng: 106.346 },
    { lat: -0.578, lng: 105.853 },
    { lat: -0.37, lng: 105.622 },
    { lat: -0.0, lng: 105.295 },
    { lat: 0.438, lng: 104.912 },
    { lat: 0.577, lng: 104.802 },
    { lat: 1.153, lng: 104.337 },
    { lat: 1.291, lng: 104.219 },
    { lat: 1.728, lng: 103.786 },
    { lat: 1.962, lng: 103.537 },
    { lat: 2.301, lng: 103.066 },
    { lat: 2.44, lng: 102.861 },
    { lat: 2.819, lng: 102.191 },
    { lat: 2.873, lng: 102.085 },
    { lat: 3.167, lng: 101.526 },
    { lat: 3.443, lng: 101.079 },
    { lat: 3.591, lng: 100.872 },
    { lat: 4.014, lng: 100.445 },
    { lat: 4.274, lng: 100.235 },
    { lat: 4.586, lng: 100.034 },
    { lat: 5.159, lng: 99.744 },
    { lat: 5.41, lng: 99.629 },
    { lat: 5.733, lng: 99.462 },
    { lat: 6.306, lng: 99.094 },
    { lat: 6.388, lng: 99.029 },
    { lat: 6.781, lng: 98.401 },
    { lat: 6.644, lng: 97.745 },
    { lat: 6.291, lng: 97.348 },
    { lat: 6.116, lng: 97.074 },
    { lat: 5.711, lng: 96.666 },
    { lat: 5.534, lng: 96.409 },
    { lat: 5.132, lng: 95.929 },
    { lat: 5.022, lng: 95.756 },
    { lat: 4.572, lng: 95.113 },
    { lat: 4.555, lng: 95.09 },
    { lat: 4.091, lng: 94.475 },
    { lat: 3.98, lng: 94.341 },
    { lat: 3.487, lng: 93.841 },
    { lat: 3.408, lng: 93.772 },
    { lat: 2.838, lng: 93.456 },
    { lat: 2.27, lng: 93.359 },
    { lat: 1.702, lng: 93.49 },
    { lat: 1.154, lng: 93.802 },
    { lat: 1.135, lng: 93.815 },
    { lat: 0.568, lng: 94.396 },
    { lat: 0.554, lng: 94.414 },
    { lat: 0.127, lng: 95.032 },
    { lat: -0.0, lng: 95.236 },
    { lat: -0.241, lng: 95.655 },
    { lat: -0.569, lng: 96.23 },
    { lat: -0.602, lng: 96.284 },
    { lat: -0.976, lng: 96.918 },
    { lat: -1.14, lng: 97.163 },
    { lat: -1.471, lng: 97.56 },
    { lat: -1.712, lng: 97.821 },
    { lat: -2.199, lng: 98.213 },
    { lat: -2.284, lng: 98.281 },
    { lat: -2.858, lng: 98.677 },
    { lat: -3.217, lng: 98.885 },
    { lat: -3.432, lng: 99.027 },
    { lat: -4.007, lng: 99.387 },
    { lat: -4.309, lng: 99.577 },
    { lat: -4.584, lng: 99.78 },
    { lat: -5.163, lng: 100.234 },
    { lat: -5.216, lng: 100.28 },
    { lat: -5.745, lng: 100.799 },
    { lat: -5.92, lng: 100.986 },
    { lat: -6.329, lng: 101.447 },
    { lat: -6.543, lng: 101.701 },
    { lat: -6.916, lng: 102.16 },
    { lat: -7.119, lng: 102.426 },
    { lat: -7.507, lng: 102.946 },
    { lat: -7.649, lng: 103.16 },
    { lat: -8.103, lng: 103.899 },
    { lat: -8.103, lng: 103.899 },
    { lat: -8.425, lng: 104.638 },
    { lat: -8.71, lng: 105.328 },
    { lat: -8.729, lng: 105.386 },
    { lat: -8.959, lng: 106.136 },
    { lat: -9.193, lng: 106.899 },
    { lat: -9.332, lng: 107.356 },
    { lat: -9.406, lng: 107.67 },
    { lat: -9.575, lng: 108.448 },
    { lat: -9.733, lng: 109.236 },
    { lat: -9.893, lng: 110.037 },
    { lat: -9.983, lng: 110.465 },
  ],
  c9: [
    { lat: -8.396, lng: 121.225 },
    { lat: -8.396, lng: 121.228 },
    { lat: -8.538, lng: 122.285 },
    { lat: -8.438, lng: 123.324 },
    { lat: -8.438, lng: 123.325 },
    { lat: -8.437, lng: 123.324 },
    { lat: -8.281, lng: 122.232 },
    { lat: -8.395, lng: 121.228 },
    { lat: -8.396, lng: 121.225 },
  ],
  c10: [
    { lat: -8.774, lng: 109.325 },
    { lat: -8.854, lng: 109.889 },
    { lat: -8.974, lng: 110.69 },
    { lat: -9.1, lng: 111.506 },
    { lat: -9.231, lng: 112.338 },
    { lat: -9.32, lng: 113.177 },
    { lat: -9.374, lng: 114.027 },
    { lat: -9.302, lng: 114.872 },
    { lat: -9.13, lng: 115.715 },
    { lat: -8.937, lng: 116.57 },
    { lat: -8.909, lng: 116.694 },
    { lat: -8.308, lng: 116.613 },
    { lat: -8.262, lng: 116.456 },
    { lat: -7.93, lng: 115.52 },
    { lat: -7.679, lng: 114.833 },
    { lat: -7.63, lng: 114.612 },
    { lat: -7.471, lng: 113.743 },
    { lat: -7.313, lng: 112.891 },
    { lat: -7.161, lng: 112.056 },
    { lat: -7.036, lng: 111.581 },
    { lat: -6.954, lng: 111.23 },
    { lat: -6.741, lng: 110.417 },
    { lat: -6.456, lng: 109.612 },
    { lat: -6.42, lng: 109.499 },
    { lat: -6.191, lng: 108.823 },
    { lat: -5.895, lng: 108.044 },
    { lat: -5.815, lng: 107.888 },
    { lat: -5.449, lng: 107.266 },
    { lat: -5.222, lng: 106.967 },
    { lat: -4.83, lng: 106.492 },
    { lat: -4.634, lng: 106.286 },
    { lat: -4.098, lng: 105.728 },
    { lat: -4.049, lng: 105.68 },
    { lat: -3.466, lng: 105.152 },
    { lat: -3.234, lng: 104.976 },
    { lat: -2.886, lng: 104.77 },
    { lat: -2.307, lng: 104.521 },
    { lat: -1.729, lng: 104.32 },
    { lat: -1.498, lng: 104.223 },
    { lat: -1.152, lng: 104.086 },
    { lat: -0.576, lng: 103.774 },
    { lat: -0.182, lng: 103.515 },
    { lat: -0.0, lng: 103.377 },
    { lat: 0.552, lng: 102.829 },
    { lat: 0.575, lng: 102.8 },
    { lat: 1.026, lng: 102.153 },
    { lat: 1.148, lng: 101.916 },
    { lat: 1.351, lng: 101.483 },
    { lat: 1.658, lng: 100.82 },
    { lat: 1.719, lng: 100.703 },
    { lat: 2.026, lng: 100.164 },
    { lat: 2.29, lng: 99.866 },
    { lat: 2.653, lng: 99.521 },
    { lat: 2.861, lng: 99.385 },
    { lat: 3.432, lng: 99.025 },
    { lat: 3.64, lng: 98.899 },
    { lat: 4.003, lng: 98.666 },
    { lat: 4.489, lng: 98.282 },
    { lat: 4.574, lng: 98.157 },
    { lat: 4.832, lng: 97.653 },
    { lat: 4.798, lng: 97.012 },
    { lat: 4.581, lng: 96.369 },
    { lat: 4.562, lng: 96.335 },
    { lat: 4.269, lng: 95.728 },
    { lat: 3.985, lng: 95.242 },
    { lat: 3.889, lng: 95.09 },
    { lat: 3.411, lng: 94.498 },
    { lat: 3.362, lng: 94.455 },
    { lat: 2.841, lng: 94.11 },
    { lat: 2.272, lng: 93.981 },
    { lat: 1.704, lng: 94.13 },
    { lat: 1.261, lng: 94.419 },
    { lat: 1.136, lng: 94.525 },
    { lat: 0.726, lng: 95.034 },
    { lat: 0.569, lng: 95.272 },
    { lat: 0.355, lng: 95.656 },
    { lat: 0.042, lng: 96.282 },
    { lat: -0.0, lng: 96.387 },
    { lat: -0.209, lng: 96.914 },
    { lat: -0.5, lng: 97.551 },
    { lat: -0.57, lng: 97.684 },
    { lat: -0.907, lng: 98.195 },
    { lat: -1.142, lng: 98.468 },
    { lat: -1.576, lng: 98.848 },
    { lat: -1.715, lng: 98.964 },
    { lat: -2.288, lng: 99.369 },
    { lat: -2.551, lng: 99.519 },
    { lat: -2.862, lng: 99.721 },
    { lat: -3.437, lng: 100.078 },
    { lat: -3.651, lng: 100.21 },
    { lat: -4.014, lng: 100.486 },
    { lat: -4.571, lng: 100.913 },
    { lat: -4.592, lng: 100.934 },
    { lat: -5.174, lng: 101.519 },
    { lat: -5.271, lng: 101.621 },
    { lat: -5.757, lng: 102.2 },
    { lat: -5.874, lng: 102.337 },
    { lat: -6.344, lng: 102.902 },
    { lat: -6.474, lng: 103.065 },
    { lat: -6.934, lng: 103.69 },
    { lat: -7.004, lng: 103.801 },
    { lat: -7.418, lng: 104.541 },
    { lat: -7.53, lng: 104.788 },
    { lat: -7.7, lng: 105.279 },
    { lat: -7.926, lng: 106.023 },
    { lat: -8.142, lng: 106.672 },
    { lat: -8.17, lng: 106.78 },
    { lat: -8.33, lng: 107.538 },
    { lat: -8.521, lng: 108.311 },
    { lat: -8.721, lng: 109.098 },
    { lat: -8.774, lng: 109.325 },
  ],
};
export var abs_gntgain = {
  c1: "-10.46",
  c2: "-9.46",
  c3: "-7.46",
  c4: "-5.46",
  c5: "-3.46",
  c6: "-3.46",
  c7: "-3.46",
  c8: "-2.46",
  c9: "-1.46",
  c10: "-1.46",
};
