export var hts = {
  c1: [
    {
      lat: 6.527,
      lng: 110.289,
    },
    {
      lat: 6.419,
      lng: 110.529,
    },
    {
      lat: 6.111,
      lng: 111.06,
    },
    {
      lat: 6.081,
      lng: 111.102,
    },
    {
      lat: 5.676,
      lng: 111.592,
    },
    {
      lat: 5.64,
      lng: 111.629,
    },
    {
      lat: 5.201,
      lng: 112.001,
    },
    {
      lat: 4.999,
      lng: 112.13,
    },
    {
      lat: 4.764,
      lng: 112.266,
    },
    {
      lat: 4.329,
      lng: 112.438,
    },
    {
      lat: 3.894,
      lng: 112.532,
    },
    {
      lat: 3.461,
      lng: 112.552,
    },
    {
      lat: 3.028,
      lng: 112.499,
    },
    {
      lat: 2.595,
      lng: 112.373,
    },
    {
      lat: 2.289,
      lng: 112.23,
    },
    {
      lat: 2.163,
      lng: 112.164,
    },
    {
      lat: 1.731,
      lng: 111.856,
    },
    {
      lat: 1.609,
      lng: 111.745,
    },
    {
      lat: 1.299,
      lng: 111.414,
    },
    {
      lat: 1.172,
      lng: 111.25,
    },
    {
      lat: 0.867,
      lng: 110.759,
    },
    {
      lat: 0.861,
      lng: 110.748,
    },
    {
      lat: 0.653,
      lng: 110.241,
    },
    {
      lat: 0.511,
      lng: 109.73,
    },
    {
      lat: 0.434,
      lng: 109.219,
    },
    {
      lat: 0.434,
      lng: 109.214,
    },
    {
      lat: 0.418,
      lng: 108.694,
    },
    {
      lat: 0.435,
      lng: 108.464,
    },
    {
      lat: 0.458,
      lng: 108.17,
    },
    {
      lat: 0.558,
      lng: 107.64,
    },
    {
      lat: 0.718,
      lng: 107.106,
    },
    {
      lat: 0.873,
      lng: 106.726,
    },
    {
      lat: 0.946,
      lng: 106.566,
    },
    {
      lat: 1.265,
      lng: 106.019,
    },
    {
      lat: 1.311,
      lng: 105.952,
    },
    {
      lat: 1.714,
      lng: 105.462,
    },
    {
      lat: 1.75,
      lng: 105.424,
    },
    {
      lat: 2.189,
      lng: 105.046,
    },
    {
      lat: 2.434,
      lng: 104.888,
    },
    {
      lat: 2.629,
      lng: 104.772,
    },
    {
      lat: 3.069,
      lng: 104.593,
    },
    {
      lat: 3.509,
      lng: 104.494,
    },
    {
      lat: 3.948,
      lng: 104.476,
    },
    {
      lat: 4.387,
      lng: 104.54,
    },
    {
      lat: 4.826,
      lng: 104.689,
    },
    {
      lat: 4.958,
      lng: 104.759,
    },
    {
      lat: 5.264,
      lng: 104.942,
    },
    {
      lat: 5.659,
      lng: 105.265,
    },
    {
      lat: 5.701,
      lng: 105.305,
    },
    {
      lat: 6.103,
      lng: 105.782,
    },
    {
      lat: 6.136,
      lng: 105.831,
    },
    {
      lat: 6.415,
      lng: 106.305,
    },
    {
      lat: 6.567,
      lng: 106.642,
    },
    {
      lat: 6.643,
      lng: 106.829,
    },
    {
      lat: 6.794,
      lng: 107.356,
    },
    {
      lat: 6.884,
      lng: 107.884,
    },
    {
      lat: 6.914,
      lng: 108.412,
    },
    {
      lat: 6.884,
      lng: 108.94,
    },
    {
      lat: 6.794,
      lng: 109.47,
    },
    {
      lat: 6.643,
      lng: 109.999,
    },
    {
      lat: 6.527,
      lng: 110.289,
    },
  ],
  c2: [
    {
      lat: 7.86,
      lng: 109.007,
    },
    {
      lat: 7.807,
      lng: 109.385,
    },
    {
      lat: 7.684,
      lng: 109.916,
    },
    {
      lat: 7.511,
      lng: 110.446,
    },
    {
      lat: 7.399,
      lng: 110.714,
    },
    {
      lat: 7.281,
      lng: 110.976,
    },
    {
      lat: 6.984,
      lng: 111.506,
    },
    {
      lat: 6.951,
      lng: 111.557,
    },
    {
      lat: 6.602,
      lng: 112.036,
    },
    {
      lat: 6.508,
      lng: 112.149,
    },
    {
      lat: 6.108,
      lng: 112.567,
    },
    {
      lat: 6.067,
      lng: 112.606,
    },
    {
      lat: 5.628,
      lng: 112.958,
    },
    {
      lat: 5.407,
      lng: 113.102,
    },
    {
      lat: 5.192,
      lng: 113.233,
    },
    {
      lat: 4.756,
      lng: 113.434,
    },
    {
      lat: 4.321,
      lng: 113.573,
    },
    {
      lat: 3.888,
      lng: 113.652,
    },
    {
      lat: 3.455,
      lng: 113.674,
    },
    {
      lat: 3.022,
      lng: 113.638,
    },
    {
      lat: 2.591,
      lng: 113.544,
    },
    {
      lat: 2.159,
      lng: 113.39,
    },
    {
      lat: 1.834,
      lng: 113.227,
    },
    {
      lat: 1.728,
      lng: 113.169,
    },
    {
      lat: 1.296,
      lng: 112.87,
    },
    {
      lat: 1.149,
      lng: 112.745,
    },
    {
      lat: 0.865,
      lng: 112.476,
    },
    {
      lat: 0.666,
      lng: 112.254,
    },
    {
      lat: 0.433,
      lng: 111.954,
    },
    {
      lat: 0.298,
      lng: 111.758,
    },
    {
      lat: 0.016,
      lng: 111.257,
    },
    {
      lat: -0.0,
      lng: 111.222,
    },
    {
      lat: -0.194,
      lng: 110.752,
    },
    {
      lat: -0.348,
      lng: 110.243,
    },
    {
      lat: -0.434,
      lng: 109.813,
    },
    {
      lat: -0.45,
      lng: 109.73,
    },
    {
      lat: -0.504,
      lng: 109.214,
    },
    {
      lat: -0.514,
      lng: 108.694,
    },
    {
      lat: -0.481,
      lng: 108.17,
    },
    {
      lat: -0.436,
      lng: 107.852,
    },
    {
      lat: -0.405,
      lng: 107.641,
    },
    {
      lat: -0.282,
      lng: 107.109,
    },
    {
      lat: -0.111,
      lng: 106.571,
    },
    {
      lat: -0.0,
      lng: 106.298,
    },
    {
      lat: 0.117,
      lng: 106.029,
    },
    {
      lat: 0.409,
      lng: 105.481,
    },
    {
      lat: 0.437,
      lng: 105.435,
    },
    {
      lat: 0.79,
      lng: 104.924,
    },
    {
      lat: 0.876,
      lng: 104.815,
    },
    {
      lat: 1.29,
      lng: 104.359,
    },
    {
      lat: 1.315,
      lng: 104.333,
    },
    {
      lat: 1.755,
      lng: 103.959,
    },
    {
      lat: 2.021,
      lng: 103.777,
    },
    {
      lat: 2.195,
      lng: 103.665,
    },
    {
      lat: 2.636,
      lng: 103.445,
    },
    {
      lat: 3.076,
      lng: 103.291,
    },
    {
      lat: 3.517,
      lng: 103.2,
    },
    {
      lat: 3.958,
      lng: 103.173,
    },
    {
      lat: 4.399,
      lng: 103.21,
    },
    {
      lat: 4.839,
      lng: 103.317,
    },
    {
      lat: 5.279,
      lng: 103.496,
    },
    {
      lat: 5.447,
      lng: 103.592,
    },
    {
      lat: 5.718,
      lng: 103.757,
    },
    {
      lat: 6.155,
      lng: 104.102,
    },
    {
      lat: 6.156,
      lng: 104.103,
    },
    {
      lat: 6.593,
      lng: 104.559,
    },
    {
      lat: 6.645,
      lng: 104.622,
    },
    {
      lat: 7.024,
      lng: 105.146,
    },
    {
      lat: 7.027,
      lng: 105.153,
    },
    {
      lat: 7.316,
      lng: 105.673,
    },
    {
      lat: 7.459,
      lng: 105.998,
    },
    {
      lat: 7.542,
      lng: 106.202,
    },
    {
      lat: 7.709,
      lng: 106.731,
    },
    {
      lat: 7.824,
      lng: 107.262,
    },
    {
      lat: 7.877,
      lng: 107.691,
    },
    {
      lat: 7.89,
      lng: 107.792,
    },
    {
      lat: 7.908,
      lng: 108.323,
    },
    {
      lat: 7.881,
      lng: 108.854,
    },
    {
      lat: 7.86,
      lng: 109.007,
    },
  ],
  c3: [
    {
      lat: 8.281,
      lng: 110.487,
    },
    {
      lat: 8.126,
      lng: 110.906,
    },
    {
      lat: 7.88,
      lng: 111.436,
    },
    {
      lat: 7.828,
      lng: 111.532,
    },
    {
      lat: 7.574,
      lng: 111.966,
    },
    {
      lat: 7.381,
      lng: 112.248,
    },
    {
      lat: 7.196,
      lng: 112.495,
    },
    {
      lat: 6.938,
      lng: 112.8,
    },
    {
      lat: 6.724,
      lng: 113.025,
    },
    {
      lat: 6.497,
      lng: 113.242,
    },
    {
      lat: 6.119,
      lng: 113.555,
    },
    {
      lat: 6.058,
      lng: 113.602,
    },
    {
      lat: 5.621,
      lng: 113.893,
    },
    {
      lat: 5.249,
      lng: 114.091,
    },
    {
      lat: 5.185,
      lng: 114.124,
    },
    {
      lat: 4.75,
      lng: 114.299,
    },
    {
      lat: 4.316,
      lng: 114.421,
    },
    {
      lat: 3.883,
      lng: 114.493,
    },
    {
      lat: 3.451,
      lng: 114.516,
    },
    {
      lat: 3.019,
      lng: 114.491,
    },
    {
      lat: 2.587,
      lng: 114.417,
    },
    {
      lat: 2.156,
      lng: 114.291,
    },
    {
      lat: 1.938,
      lng: 114.2,
    },
    {
      lat: 1.725,
      lng: 114.108,
    },
    {
      lat: 1.294,
      lng: 113.864,
    },
    {
      lat: 1.098,
      lng: 113.726,
    },
    {
      lat: 0.863,
      lng: 113.55,
    },
    {
      lat: 0.519,
      lng: 113.241,
    },
    {
      lat: 0.432,
      lng: 113.156,
    },
    {
      lat: 0.072,
      lng: 112.751,
    },
    {
      lat: -0.0,
      lng: 112.659,
    },
    {
      lat: -0.284,
      lng: 112.256,
    },
    {
      lat: -0.433,
      lng: 112.006,
    },
    {
      lat: -0.57,
      lng: 111.757,
    },
    {
      lat: -0.794,
      lng: 111.254,
    },
    {
      lat: -0.866,
      lng: 111.047,
    },
    {
      lat: -0.967,
      lng: 110.747,
    },
    {
      lat: -1.094,
      lng: 110.237,
    },
    {
      lat: -1.178,
      lng: 109.724,
    },
    {
      lat: -1.224,
      lng: 109.207,
    },
    {
      lat: -1.233,
      lng: 108.686,
    },
    {
      lat: -1.206,
      lng: 108.162,
    },
    {
      lat: -1.143,
      lng: 107.634,
    },
    {
      lat: -1.042,
      lng: 107.102,
    },
    {
      lat: -0.899,
      lng: 106.566,
    },
    {
      lat: -0.873,
      lng: 106.489,
    },
    {
      lat: -0.713,
      lng: 106.026,
    },
    {
      lat: -0.476,
      lng: 105.48,
    },
    {
      lat: -0.437,
      lng: 105.404,
    },
    {
      lat: -0.18,
      lng: 104.928,
    },
    {
      lat: -0.0,
      lng: 104.645,
    },
    {
      lat: 0.19,
      lng: 104.37,
    },
    {
      lat: 0.439,
      lng: 104.053,
    },
    {
      lat: 0.658,
      lng: 103.804,
    },
    {
      lat: 0.878,
      lng: 103.574,
    },
    {
      lat: 1.269,
      lng: 103.225,
    },
    {
      lat: 1.318,
      lng: 103.183,
    },
    {
      lat: 1.759,
      lng: 102.866,
    },
    {
      lat: 2.177,
      lng: 102.625,
    },
    {
      lat: 2.2,
      lng: 102.612,
    },
    {
      lat: 2.641,
      lng: 102.416,
    },
    {
      lat: 3.083,
      lng: 102.275,
    },
    {
      lat: 3.524,
      lng: 102.187,
    },
    {
      lat: 3.966,
      lng: 102.153,
    },
    {
      lat: 4.408,
      lng: 102.175,
    },
    {
      lat: 4.849,
      lng: 102.256,
    },
    {
      lat: 5.29,
      lng: 102.403,
    },
    {
      lat: 5.372,
      lng: 102.442,
    },
    {
      lat: 5.731,
      lng: 102.616,
    },
    {
      lat: 6.17,
      lng: 102.901,
    },
    {
      lat: 6.231,
      lng: 102.949,
    },
    {
      lat: 6.609,
      lng: 103.262,
    },
    {
      lat: 6.821,
      lng: 103.469,
    },
    {
      lat: 7.047,
      lng: 103.709,
    },
    {
      lat: 7.283,
      lng: 103.993,
    },
    {
      lat: 7.483,
      lng: 104.266,
    },
    {
      lat: 7.653,
      lng: 104.521,
    },
    {
      lat: 7.916,
      lng: 104.983,
    },
    {
      lat: 7.952,
      lng: 105.051,
    },
    {
      lat: 8.19,
      lng: 105.582,
    },
    {
      lat: 8.344,
      lng: 106.025,
    },
    {
      lat: 8.374,
      lng: 106.115,
    },
    {
      lat: 8.511,
      lng: 106.648,
    },
    {
      lat: 8.604,
      lng: 107.181,
    },
    {
      lat: 8.656,
      lng: 107.714,
    },
    {
      lat: 8.667,
      lng: 108.248,
    },
    {
      lat: 8.64,
      lng: 108.78,
    },
    {
      lat: 8.574,
      lng: 109.313,
    },
    {
      lat: 8.468,
      lng: 109.845,
    },
    {
      lat: 8.32,
      lng: 110.376,
    },
    {
      lat: 8.281,
      lng: 110.487,
    },
  ],
};

export var htsgain = {
  c1: "61",
  c2: "59",
  c3: "57",
};

export var hts_gnt = {
  c1: [
    {
      lat: 8.752,
      lng: 108.399,
    },
    {
      lat: 8.724,
      lng: 108.772,
    },
    {
      lat: 8.652,
      lng: 109.305,
    },
    {
      lat: 8.544,
      lng: 109.837,
    },
    {
      lat: 8.397,
      lng: 110.368,
    },
    {
      lat: 8.278,
      lng: 110.704,
    },
    {
      lat: 8.206,
      lng: 110.899,
    },
    {
      lat: 7.966,
      lng: 111.429,
    },
    {
      lat: 7.826,
      lng: 111.689,
    },
    {
      lat: 7.67,
      lng: 111.958,
    },
    {
      lat: 7.379,
      lng: 112.386,
    },
    {
      lat: 7.303,
      lng: 112.487,
    },
    {
      lat: 6.936,
      lng: 112.921,
    },
    {
      lat: 6.844,
      lng: 113.017,
    },
    {
      lat: 6.496,
      lng: 113.345,
    },
    {
      lat: 6.237,
      lng: 113.548,
    },
    {
      lat: 6.057,
      lng: 113.681,
    },
    {
      lat: 5.62,
      lng: 113.94,
    },
    {
      lat: 5.289,
      lng: 114.089,
    },
    {
      lat: 5.185,
      lng: 114.134,
    },
    {
      lat: 4.75,
      lng: 114.276,
    },
    {
      lat: 4.316,
      lng: 114.372,
    },
    {
      lat: 3.883,
      lng: 114.429,
    },
    {
      lat: 3.451,
      lng: 114.451,
    },
    {
      lat: 3.019,
      lng: 114.437,
    },
    {
      lat: 2.587,
      lng: 114.384,
    },
    {
      lat: 2.156,
      lng: 114.287,
    },
    {
      lat: 1.899,
      lng: 114.201,
    },
    {
      lat: 1.725,
      lng: 114.14,
    },
    {
      lat: 1.294,
      lng: 113.934,
    },
    {
      lat: 0.969,
      lng: 113.727,
    },
    {
      lat: 0.863,
      lng: 113.654,
    },
    {
      lat: 0.432,
      lng: 113.286,
    },
    {
      lat: 0.388,
      lng: 113.242,
    },
    {
      lat: -0.0,
      lng: 112.81,
    },
    {
      lat: -0.047,
      lng: 112.751,
    },
    {
      lat: -0.4,
      lng: 112.256,
    },
    {
      lat: -0.432,
      lng: 112.203,
    },
    {
      lat: -0.693,
      lng: 111.756,
    },
    {
      lat: -0.866,
      lng: 111.401,
    },
    {
      lat: -0.934,
      lng: 111.253,
    },
    {
      lat: -1.123,
      lng: 110.745,
    },
    {
      lat: -1.263,
      lng: 110.235,
    },
    {
      lat: -1.302,
      lng: 110.031,
    },
    {
      lat: -1.358,
      lng: 109.721,
    },
    {
      lat: -1.408,
      lng: 109.204,
    },
    {
      lat: -1.417,
      lng: 108.684,
    },
    {
      lat: -1.388,
      lng: 108.159,
    },
    {
      lat: -1.321,
      lng: 107.632,
    },
    {
      lat: -1.307,
      lng: 107.562,
    },
    {
      lat: -1.217,
      lng: 107.1,
    },
    {
      lat: -1.074,
      lng: 106.564,
    },
    {
      lat: -0.89,
      lng: 106.024,
    },
    {
      lat: -0.874,
      lng: 105.984,
    },
    {
      lat: -0.658,
      lng: 105.479,
    },
    {
      lat: -0.438,
      lng: 105.05,
    },
    {
      lat: -0.369,
      lng: 104.928,
    },
    {
      lat: -0.009,
      lng: 104.371,
    },
    {
      lat: -0.0,
      lng: 104.358,
    },
    {
      lat: 0.439,
      lng: 103.822,
    },
    {
      lat: 0.454,
      lng: 103.805,
    },
    {
      lat: 0.878,
      lng: 103.394,
    },
    {
      lat: 1.084,
      lng: 103.228,
    },
    {
      lat: 1.318,
      lng: 103.048,
    },
    {
      lat: 1.759,
      lng: 102.772,
    },
    {
      lat: 2.042,
      lng: 102.63,
    },
    {
      lat: 2.2,
      lng: 102.551,
    },
    {
      lat: 2.641,
      lng: 102.378,
    },
    {
      lat: 3.083,
      lng: 102.253,
    },
    {
      lat: 3.524,
      lng: 102.172,
    },
    {
      lat: 3.966,
      lng: 102.136,
    },
    {
      lat: 4.408,
      lng: 102.146,
    },
    {
      lat: 4.85,
      lng: 102.207,
    },
    {
      lat: 5.291,
      lng: 102.32,
    },
    {
      lat: 5.564,
      lng: 102.426,
    },
    {
      lat: 5.732,
      lng: 102.492,
    },
    {
      lat: 6.172,
      lng: 102.728,
    },
    {
      lat: 6.459,
      lng: 102.927,
    },
    {
      lat: 6.612,
      lng: 103.038,
    },
    {
      lat: 7.051,
      lng: 103.422,
    },
    {
      lat: 7.071,
      lng: 103.443,
    },
    {
      lat: 7.488,
      lng: 103.897,
    },
    {
      lat: 7.543,
      lng: 103.966,
    },
    {
      lat: 7.914,
      lng: 104.493,
    },
    {
      lat: 7.923,
      lng: 104.509,
    },
    {
      lat: 8.198,
      lng: 105.024,
    },
    {
      lat: 8.354,
      lng: 105.403,
    },
    {
      lat: 8.413,
      lng: 105.557,
    },
    {
      lat: 8.57,
      lng: 106.093,
    },
    {
      lat: 8.677,
      lng: 106.629,
    },
    {
      lat: 8.742,
      lng: 107.166,
    },
    {
      lat: 8.764,
      lng: 107.591,
    },
    {
      lat: 8.77,
      lng: 107.702,
    },
    {
      lat: 8.764,
      lng: 108.237,
    },
    {
      lat: 8.752,
      lng: 108.399,
    },
  ],
  c2: [
    {
      lat: 9.171,
      lng: 109.945,
    },
    {
      lat: 9.086,
      lng: 110.302,
    },
    {
      lat: 8.921,
      lng: 110.833,
    },
    {
      lat: 8.714,
      lng: 111.363,
    },
    {
      lat: 8.709,
      lng: 111.375,
    },
    {
      lat: 8.464,
      lng: 111.892,
    },
    {
      lat: 8.258,
      lng: 112.259,
    },
    {
      lat: 8.16,
      lng: 112.421,
    },
    {
      lat: 7.811,
      lng: 112.927,
    },
    {
      lat: 7.795,
      lng: 112.949,
    },
    {
      lat: 7.368,
      lng: 113.458,
    },
    {
      lat: 7.35,
      lng: 113.476,
    },
    {
      lat: 6.927,
      lng: 113.892,
    },
    {
      lat: 6.791,
      lng: 114.005,
    },
    {
      lat: 6.487,
      lng: 114.247,
    },
    {
      lat: 6.05,
      lng: 114.529,
    },
    {
      lat: 6.035,
      lng: 114.536,
    },
    {
      lat: 5.614,
      lng: 114.751,
    },
    {
      lat: 5.179,
      lng: 114.914,
    },
    {
      lat: 4.745,
      lng: 115.028,
    },
    {
      lat: 4.348,
      lng: 115.098,
    },
    {
      lat: 4.312,
      lng: 115.104,
    },
    {
      lat: 3.88,
      lng: 115.149,
    },
    {
      lat: 3.448,
      lng: 115.164,
    },
    {
      lat: 3.016,
      lng: 115.15,
    },
    {
      lat: 2.94,
      lng: 115.142,
    },
    {
      lat: 2.585,
      lng: 115.108,
    },
    {
      lat: 2.154,
      lng: 115.032,
    },
    {
      lat: 1.723,
      lng: 114.917,
    },
    {
      lat: 1.293,
      lng: 114.753,
    },
    {
      lat: 1.173,
      lng: 114.694,
    },
    {
      lat: 0.862,
      lng: 114.533,
    },
    {
      lat: 0.431,
      lng: 114.239,
    },
    {
      lat: 0.403,
      lng: 114.216,
    },
    {
      lat: -0.0,
      lng: 113.861,
    },
    {
      lat: -0.125,
      lng: 113.731,
    },
    {
      lat: -0.432,
      lng: 113.381,
    },
    {
      lat: -0.542,
      lng: 113.242,
    },
    {
      lat: -0.864,
      lng: 112.787,
    },
    {
      lat: -0.89,
      lng: 112.747,
    },
    {
      lat: -1.186,
      lng: 112.249,
    },
    {
      lat: -1.298,
      lng: 112.031,
    },
    {
      lat: -1.434,
      lng: 111.748,
    },
    {
      lat: -1.635,
      lng: 111.243,
    },
    {
      lat: -1.734,
      lng: 110.922,
    },
    {
      lat: -1.789,
      lng: 110.735,
    },
    {
      lat: -1.901,
      lng: 110.224,
    },
    {
      lat: -1.972,
      lng: 109.71,
    },
    {
      lat: -2.005,
      lng: 109.192,
    },
    {
      lat: -2.004,
      lng: 108.672,
    },
    {
      lat: -1.971,
      lng: 108.147,
    },
    {
      lat: -1.907,
      lng: 107.62,
    },
    {
      lat: -1.811,
      lng: 107.089,
    },
    {
      lat: -1.746,
      lng: 106.814,
    },
    {
      lat: -1.684,
      lng: 106.553,
    },
    {
      lat: -1.526,
      lng: 106.014,
    },
    {
      lat: -1.329,
      lng: 105.47,
    },
    {
      lat: -1.312,
      lng: 105.431,
    },
    {
      lat: -1.092,
      lng: 104.921,
    },
    {
      lat: -0.876,
      lng: 104.499,
    },
    {
      lat: -0.803,
      lng: 104.366,
    },
    {
      lat: -0.451,
      lng: 103.805,
    },
    {
      lat: -0.439,
      lng: 103.787,
    },
    {
      lat: -0.012,
      lng: 103.237,
    },
    {
      lat: -0.0,
      lng: 103.223,
    },
    {
      lat: 0.44,
      lng: 102.767,
    },
    {
      lat: 0.566,
      lng: 102.659,
    },
    {
      lat: 0.88,
      lng: 102.397,
    },
    {
      lat: 1.321,
      lng: 102.101,
    },
    {
      lat: 1.387,
      lng: 102.064,
    },
    {
      lat: 1.762,
      lng: 101.854,
    },
    {
      lat: 2.204,
      lng: 101.658,
    },
    {
      lat: 2.646,
      lng: 101.507,
    },
    {
      lat: 2.991,
      lng: 101.419,
    },
    {
      lat: 3.088,
      lng: 101.393,
    },
    {
      lat: 3.53,
      lng: 101.313,
    },
    {
      lat: 3.973,
      lng: 101.271,
    },
    {
      lat: 4.416,
      lng: 101.268,
    },
    {
      lat: 4.82,
      lng: 101.305,
    },
    {
      lat: 4.858,
      lng: 101.308,
    },
    {
      lat: 5.301,
      lng: 101.392,
    },
    {
      lat: 5.743,
      lng: 101.53,
    },
    {
      lat: 6.184,
      lng: 101.733,
    },
    {
      lat: 6.255,
      lng: 101.774,
    },
    {
      lat: 6.625,
      lng: 101.996,
    },
    {
      lat: 7.009,
      lng: 102.287,
    },
    {
      lat: 7.066,
      lng: 102.332,
    },
    {
      lat: 7.505,
      lng: 102.731,
    },
    {
      lat: 7.581,
      lng: 102.809,
    },
    {
      lat: 7.943,
      lng: 103.207,
    },
    {
      lat: 8.047,
      lng: 103.335,
    },
    {
      lat: 8.379,
      lng: 103.799,
    },
    {
      lat: 8.422,
      lng: 103.864,
    },
    {
      lat: 8.717,
      lng: 104.398,
    },
    {
      lat: 8.811,
      lng: 104.612,
    },
    {
      lat: 8.946,
      lng: 104.935,
    },
    {
      lat: 9.119,
      lng: 105.474,
    },
    {
      lat: 9.234,
      lng: 105.974,
    },
    {
      lat: 9.243,
      lng: 106.014,
    },
    {
      lat: 9.331,
      lng: 106.553,
    },
    {
      lat: 9.384,
      lng: 107.092,
    },
    {
      lat: 9.408,
      lng: 107.63,
    },
    {
      lat: 9.403,
      lng: 108.167,
    },
    {
      lat: 9.371,
      lng: 108.703,
    },
    {
      lat: 9.308,
      lng: 109.237,
    },
    {
      lat: 9.213,
      lng: 109.77,
    },
    {
      lat: 9.171,
      lng: 109.945,
    },
  ],
  c3: [
    {
      lat: 9.606,
      lng: 110.461,
    },
    {
      lat: 9.518,
      lng: 110.774,
    },
    {
      lat: 9.334,
      lng: 111.305,
    },
    {
      lat: 9.145,
      lng: 111.748,
    },
    {
      lat: 9.107,
      lng: 111.835,
    },
    {
      lat: 8.84,
      lng: 112.363,
    },
    {
      lat: 8.692,
      lng: 112.616,
    },
    {
      lat: 8.523,
      lng: 112.89,
    },
    {
      lat: 8.245,
      lng: 113.29,
    },
    {
      lat: 8.149,
      lng: 113.417,
    },
    {
      lat: 7.801,
      lng: 113.838,
    },
    {
      lat: 7.703,
      lng: 113.943,
    },
    {
      lat: 7.359,
      lng: 114.293,
    },
    {
      lat: 7.16,
      lng: 114.469,
    },
    {
      lat: 6.919,
      lng: 114.674,
    },
    {
      lat: 6.481,
      lng: 114.986,
    },
    {
      lat: 6.463,
      lng: 114.997,
    },
    {
      lat: 6.044,
      lng: 115.243,
    },
    {
      lat: 5.609,
      lng: 115.437,
    },
    {
      lat: 5.295,
      lng: 115.538,
    },
    {
      lat: 5.174,
      lng: 115.578,
    },
    {
      lat: 4.741,
      lng: 115.678,
    },
    {
      lat: 4.308,
      lng: 115.741,
    },
    {
      lat: 3.876,
      lng: 115.775,
    },
    {
      lat: 3.445,
      lng: 115.784,
    },
    {
      lat: 3.014,
      lng: 115.768,
    },
    {
      lat: 2.583,
      lng: 115.728,
    },
    {
      lat: 2.152,
      lng: 115.661,
    },
    {
      lat: 2.055,
      lng: 115.64,
    },
    {
      lat: 1.722,
      lng: 115.567,
    },
    {
      lat: 1.291,
      lng: 115.435,
    },
    {
      lat: 0.861,
      lng: 115.252,
    },
    {
      lat: 0.725,
      lng: 115.178,
    },
    {
      lat: 0.431,
      lng: 115.011,
    },
    {
      lat: 0.007,
      lng: 114.7,
    },
    {
      lat: -0.0,
      lng: 114.694,
    },
    {
      lat: -0.431,
      lng: 114.293,
    },
    {
      lat: -0.502,
      lng: 114.216,
    },
    {
      lat: -0.863,
      lng: 113.791,
    },
    {
      lat: -0.912,
      lng: 113.727,
    },
    {
      lat: -1.261,
      lng: 113.235,
    },
    {
      lat: -1.295,
      lng: 113.181,
    },
    {
      lat: -1.564,
      lng: 112.739,
    },
    {
      lat: -1.729,
      lng: 112.424,
    },
    {
      lat: -1.822,
      lng: 112.24,
    },
    {
      lat: -2.036,
      lng: 111.737,
    },
    {
      lat: -2.166,
      lng: 111.351,
    },
    {
      lat: -2.205,
      lng: 111.231,
    },
    {
      lat: -2.336,
      lng: 110.723,
    },
    {
      lat: -2.426,
      lng: 110.211,
    },
    {
      lat: -2.48,
      lng: 109.697,
    },
    {
      lat: -2.503,
      lng: 109.179,
    },
    {
      lat: -2.496,
      lng: 108.658,
    },
    {
      lat: -2.463,
      lng: 108.134,
    },
    {
      lat: -2.403,
      lng: 107.607,
    },
    {
      lat: -2.315,
      lng: 107.076,
    },
    {
      lat: -2.197,
      lng: 106.541,
    },
    {
      lat: -2.184,
      lng: 106.49,
    },
    {
      lat: -2.058,
      lng: 106.001,
    },
    {
      lat: -1.885,
      lng: 105.458,
    },
    {
      lat: -1.751,
      lng: 105.108,
    },
    {
      lat: -1.675,
      lng: 104.909,
    },
    {
      lat: -1.429,
      lng: 104.356,
    },
    {
      lat: -1.316,
      lng: 104.132,
    },
    {
      lat: -1.136,
      lng: 103.797,
    },
    {
      lat: -0.878,
      lng: 103.372,
    },
    {
      lat: -0.785,
      lng: 103.232,
    },
    {
      lat: -0.44,
      lng: 102.76,
    },
    {
      lat: -0.355,
      lng: 102.66,
    },
    {
      lat: -0.0,
      lng: 102.26,
    },
    {
      lat: 0.194,
      lng: 102.078,
    },
    {
      lat: 0.44,
      lng: 101.853,
    },
    {
      lat: 0.881,
      lng: 101.527,
    },
    {
      lat: 0.955,
      lng: 101.482,
    },
    {
      lat: 1.323,
      lng: 101.255,
    },
    {
      lat: 1.765,
      lng: 101.041,
    },
    {
      lat: 2.207,
      lng: 100.873,
    },
    {
      lat: 2.279,
      lng: 100.852,
    },
    {
      lat: 2.65,
      lng: 100.733,
    },
    {
      lat: 3.093,
      lng: 100.628,
    },
    {
      lat: 3.536,
      lng: 100.555,
    },
    {
      lat: 3.979,
      lng: 100.513,
    },
    {
      lat: 4.423,
      lng: 100.501,
    },
    {
      lat: 4.866,
      lng: 100.523,
    },
    {
      lat: 5.309,
      lng: 100.587,
    },
    {
      lat: 5.534,
      lng: 100.644,
    },
    {
      lat: 5.753,
      lng: 100.698,
    },
    {
      lat: 6.195,
      lng: 100.861,
    },
    {
      lat: 6.637,
      lng: 101.095,
    },
    {
      lat: 6.695,
      lng: 101.132,
    },
    {
      lat: 7.079,
      lng: 101.387,
    },
    {
      lat: 7.402,
      lng: 101.651,
    },
    {
      lat: 7.519,
      lng: 101.749,
    },
    {
      lat: 7.959,
      lng: 102.171,
    },
    {
      lat: 7.964,
      lng: 102.177,
    },
    {
      lat: 8.397,
      lng: 102.66,
    },
    {
      lat: 8.434,
      lng: 102.705,
    },
    {
      lat: 8.82,
      lng: 103.238,
    },
    {
      lat: 8.834,
      lng: 103.26,
    },
    {
      lat: 9.132,
      lng: 103.774,
    },
    {
      lat: 9.267,
      lng: 104.064,
    },
    {
      lat: 9.377,
      lng: 104.314,
    },
    {
      lat: 9.566,
      lng: 104.855,
    },
    {
      lat: 9.691,
      lng: 105.339,
    },
    {
      lat: 9.706,
      lng: 105.399,
    },
    {
      lat: 9.812,
      lng: 105.942,
    },
    {
      lat: 9.886,
      lng: 106.484,
    },
    {
      lat: 9.932,
      lng: 107.025,
    },
    {
      lat: 9.954,
      lng: 107.565,
    },
    {
      lat: 9.952,
      lng: 108.103,
    },
    {
      lat: 9.925,
      lng: 108.639,
    },
    {
      lat: 9.87,
      lng: 109.175,
    },
    {
      lat: 9.786,
      lng: 109.709,
    },
    {
      lat: 9.668,
      lng: 110.242,
    },
    {
      lat: 9.606,
      lng: 110.461,
    },
  ],
};

export var hts_gntgain = {
  c1: "8",
  c2: "6",
  c3: "4",
};
