import 'photoswipe/style.css';
import { useSailoggerStore, useMapStore, useCustomRangeStore, useSnapshotStore } from '@/stores';
import plc_rpmreport from '@/components/placeholders/RpmReport_Plc.vue';
import range_modal from '@/components/modals/Range_Modal.vue';
import {
  LMap,
  LPopup,
  LControl,
  LTileLayer,
  LControlZoom,
  LGeoJson,
  LPolygon,

} from "vue2-leaflet";
import LControlFullscreen from "vue2-leaflet-fullscreen";
import LControlPolylineMeasure from "vue2-leaflet-polyline-measure";
import Vue2LeafletRotatedMarker from "vue2-leaflet-rotatedmarker";



export default {
  name: "RpmReport",
  components: {
    range_modal,
    LMap,
    LTileLayer,
    LPopup,
    LControlZoom,
    LControl,
    LGeoJson,
    LPolygon,
    LControlPolylineMeasure,
    LControlFullscreen,
    "v-rotated-marker": Vue2LeafletRotatedMarker,
  },
  data() {
    return {
      snapshot_store: useSnapshotStore(),
    }
  },

  mounted() {
    var mother = this
    
    window.open_folder_snapshot = function (dir_label) {
      mother.snapshot_store.folder_click(dir_label);
    }
  },

  methods: {

  },

  watch: {
  },
  computed: {
  }
};