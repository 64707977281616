import { defineStore } from 'pinia';
import { useEtaStore, useCustomRangeStore, useAuthStore } from '../index';
import api from "@/plugins/axios";
import $ from "jquery";
import DataTable from 'datatables.net-dt';
import L from "leaflet";



export const useGlobalStarStore = defineStore({
    id: 'globalstar',
    state: () => ({
        eta_store: useEtaStore(),
        range_store: useCustomRangeStore(),
        authStore: useAuthStore(),
        globalstar_table: null,
        globalstaruser_table: null,
        globalstartrack_table: null,
        is_showonmap: null,
        search_globalstar: null,
        search_globalstaruser: null,
        search_globalstartrack: null,
        globalstar_data: null,
        last_center: [-1.4312313766684295, 119.1498789812993],
        last_zoom: 6,
        mapglobalstar_data: null,
        mapglobalstar_data_date: null,
        page_off: 10,
        page_off_tracking: 10,
        show_range: "Last 24 Hours",
        export_range: "24",
        mapglobalstar_id: null,
        loading_range: false,
        tracking_layer: L.layerGroup(),
        target_layer: L.layerGroup(),
        link_layer: L.layerGroup(),
        search_layer: L.layerGroup(),
        marker_tracking: [],
        moving_marker: null,
        tracking_path: null,
        tracking_latLng: null,
        tracking_rotation: null,
        interval_tracking: null,
        globalstar_map: null,
        date_range: null,
        isBottom: false,
        range_arr: [
            { label: "Today", val: "today" },
            { label: "Last 24 Hours", val: "24" },
            { label: "Last 7 Days", val: "7" },
            { label: "Last 30 Days", val: "30" },
            { label: "This Week", val: "week" },
            { label: "This Month", val: "month" },
            { label: "Last Month", val: "lmonth" },
            { label: "Custom Range", val: "24" },
        ],
        page_off_arr: [
            10,
            25,
            50,
            100
        ],

        th_globalstar_arr: [
            "No",
            "Vessel Name",
            "Company",
            ""
        ],
        th_globalstar_track_arr: [
            "#",
            "Latitude,Longitude",
            "DMS",
            "Speed\n(Knots)",
            "Heading\n(Degree)",
            "Distance\n(Nmi)",
            "Date/Time\n(UTC)",
            "Date/Time\n(GMT+7)"
        ],
        th_globalstar_user_arr: [
            "#",
            "Name",
            "Email",
            "Role",
            "Last Login"
        ],
        td_globalstar_arr: [
            0,
            0,
            0,
            24.5
        ],
        td_globalstar_track_arr: [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
        ],


    }),

    actions: {



        // DATA FETCH FUNCTIONS
        //------------------------------------------------------------------
        //Fetch Globalstar Data
        async fetch_globalstar() {
            await api.get(`/api/v1/navplus/gs`, {}
            ).then((res) => {
                console.log("GLOBALSTAR RESPONSE");
                console.log(res);
                if (this.search_globalstar == null || this.search_globalstar.length == 0) {
                    this.globalstar_data = res.data.data.vessels;
                    this.initTable();
                }
            }).catch((err) => {
            }).then(() => {
            });
        },
        //Fetch Globalstar Map
        async fetch_globalstar_map(id) {
            this.mapglobalstar_data = null;
            this.mapglobalstar_id = id;
            await api.get(`/api/v1/navplus/gs/` + id, {}
            ).then((res) => {
                console.log("SHOW ON MAP GLOBALSTAR FETCH");
                console.log(res);
                this.mapglobalstar_data = res.data.data
                setTimeout(() => {
                    this.initTable_user()
                    this.initTable_tracking(res.data.data)
                }, 1000);

            }).catch((err) => {
            }).then(() => {
            });
        },
        //Fetch Globalstar Map Date
        async fetch_globalstarmap_date(range) {
            this.last_center = [-1.4312313766684295, 119.1498789812993]
            this.loading_range = true
            this.mapglobalstar_data_date = null
            this.export_range = range.toLowerCase()
            await api.post(`/api/v1/navplus/gs/date
                    `, {
                id: this.mapglobalstar_id,
                range: range.toLowerCase()
            }
            ).then((res) => {
                console.log("SHOW ON MAP GLOBALSTAR FETCH DATE");
                console.log(res);
                this.loading_range = false
                this.mapglobalstar_data_date = res.data.data
                this.moveTracking(res.data.data.tracking)
                this.initTable_tracking(res.data.data)
                console.log("TABLE TRACKING ISINYA");
                if (res.data.data.maptop != null) {
                    this.last_center = [res.data.data.maptop.lat, res.data.data.maptop.lon]
                    this.globalstar_map.setView(new L.LatLng(res.data.data.maptop.lat, res.data.data.maptop.lon), 6);
                } else {
                    this.last_center = [-1.4312313766684295, 119.1498789812993]
                    this.globalstar_map.setView(new L.LatLng(-1.4312313766684295, 119.1498789812993), 2);
                }

            }).catch((err) => {
            }).then(() => {
            });
        },
        //------------------------------------------------------------------



        //TABLE SEARCH & PAGE FUNCTIONS
        //------------------------------------------------------------------
        //search globalstar table
        globalstarTable_search() {
            if (this.search_globalstar != null && this.search_globalstar.length > 0) {
                this.globalstar_table.search(this.search_globalstar).draw();
            }
        },
        //search globalstar user table
        userTable_search() {
            this.globalstaruser_table.search(this.search_globalstaruser).draw();
        },
        //search globalstar tracking table
        trackTable_search() {
            this.globalstartrack_table.search(this.search_globalstartrack).draw();
        },
        //data shorting globalstar table 
        globalstarTable_showof(page) {
            console.log("PAGE : " + page);
            this.globalstar_table.page.len(page).draw()
            this.page_off = page;
        },
        //data shorting globalstar table 
        globalstarTrackTable_showof(page) {
            console.log("PAGE : " + page);
            this.globalstartrack_table.page.len(page).draw()
            this.page_off_tracking = page;
        },
        //clear globalstar table
        clear_globalstarTable_search() {
            this.search_globalstar = ''
            this.globalstar_table.search(this.search_globalstar).draw();
        },
        //------------------------------------------------------------------



        //INITIALIZE TABLE FUNCTIONS
        //------------------------------------------------------------------
        //Initialize Globalstar Table
        initTable() {
            var root = this
            if (this.globalstar_table) {
                console.log("MASUK CLEAR TABLE");
                root.globalstar_table = null;
                root.initTable()
            } else {
                console.log("MASUK INIT TABLE");
                console.log(this.globalstar_data);
                this.globalstar_table = new DataTable('#globalstarTable', {
                    data: this.globalstar_data,
                    scrollCollapse: true,
                    scroller: true,
                    destroy: true,
                    deferRender: true,
                    ordering: true,
                    bLengthChange: false,
                    responsive: true,
                    dom: 'lrtip',
                    columns: [
                        {
                            width: "3%",
                            data: '', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                            <div class="p-1 h-100 row`+ meta.row + `">
                            ${meta.row + 1}
                            </div>
                            `
                            }
                        },
                        {
                            width: "32.3%",
                            data: 'name', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                            <div class="p-1 h-100 row`+ meta.row + `">
                            ${data}
                            </div>
                            `
                            }
                        },
                        {
                            width: "32.3%",
                            data: 'pt_name', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                                <div class="p-1 h-100 row`+ meta.row + `">
                                ${data}
                                </div>
                                    `
                            }
                        },
                        {
                            width: "32.3%",
                            data: 'id', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                        
                              <div class="p-1 h-100">
                              <button onclick="showonmap_globalstar('`+ data + `')"  type="button" class="btn btn-sm btn-primary w-100 rounded-none">Show on Map</button>
                              </div>
                              `
                            }
                        },
                    ]
                });
            }
        },
        //Initialize Globalstar User Table
        initTable_user() {
            var root = this
            console.log("INIT TABLE USER");
            console.log(this.globalstaruser_table);
            console.log("MASUK INIT TABLE USER");
            console.log(this.mapglobalstar_data.vesselUsers);
            this.globalstaruser_table = new DataTable('#globalstarTableUser', {
                data: this.mapglobalstar_data.vesselUsers,
                scrollCollapse: true,
                scroller: true,
                destroy: true,
                pageLength: 5,
                deferRender: true,
                ordering: true,
                bLengthChange: false,
                responsive: true,
                dom: 'lrtip',
                columns: [
                    {
                        width: "3%",
                        data: '', className: 'td_nowrap', render: (data, type, row, meta) => {
                            return `
                            <div class="p-1 h-100">
                            ${meta.row + 1}
                            </div>
                            `
                        }
                    },
                    {
                        width: "24.25%",
                        data: 'name', className: 'td_nowrap', render: (data, type, row, meta) => {
                            return `
                            <div class="p-0 h-100">
                            ${data}
                            </div>
                            `
                        }
                    },
                    {
                        width: "24.25%",
                        data: 'email', className: 'td_nowrap', render: (data, type, row, meta) => {
                            return `
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                                    `
                        }
                    },
                    {
                        width: "14.25%",
                        data: 'role_name', className: 'td_nowrap', render: (data, type, row, meta) => {
                            return `
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                                    `
                        }
                    },
                    {
                        width: "34.25%",
                        data: 'lastLogin', className: 'td_nowrap', render: (data, type, row, meta) => {
                            return `
                        
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                              `
                        }
                    },
                ]
            });
        },
        //Initialize Globalstar Tracking Table
        initTable_tracking(data) {
            console.log("INIT TABLE TRACKING");
            console.log(this.globalstartrack_table);
            console.log("MASUK INIT TABLE TRACKING");
            console.log(data.tracking);
            this.globalstartrack_table = new DataTable('#globalstarTableTrack', {
                data: data.dataTable,
                scrollCollapse: true,
                scroller: true,
                destroy: true,
                pageLength: 10,
                deferRender: true,
                ordering: true,
                bLengthChange: false,
                responsive: true,
                dom: 'lrtip',
                columns: [
                    {
                        width: "3%",
                        data: '', className: 'td_nowrap', render: (data, type, row, meta) => {
                            return `
                            <div class="p-1 h-100">
                            ${meta.row + 1}
                            </div>
                            `
                        }
                    },
                    {
                        width: "13.2%",
                        data: 'latlong', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                            return `
                            <div onclick="popup_tracking('`+ meta.row + `')" class="p-0 h-100 link_tracking">
                            ${data}
                            </div>
                            `
                        }
                    },
                    {
                        width: "13.2%",
                        data: 'dms', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                            return `
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                                    `
                        }
                    },
                    {
                        width: "13.2%",
                        data: 'speed', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                            return `
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                                    `
                        }
                    },
                    {
                        width: "13.2%",
                        data: 'heading', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                            return `
                        
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                              `
                        }


                    },
                    {
                        width: "13.2%",
                        data: 'distance', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                            return `
                        
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                              `
                        }


                    },
                    {
                        width: "13.2%",
                        data: 'dateTime', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                            return `
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                              `
                        }
                    },
                    {
                        width: "13.2%",
                        data: 'dateTime_local', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                            return `  
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                              `
                        }
                    },
                ]
            });
        },
        //------------------------------------------------------------------



        //SHOW ON MAP FUNCTIONS
        //------------------------------------------------------------------
        //show on map globalstar
        showonMap(id) {
            console.log("GLOBALSTAR SHOW ON MAP");
            this.is_showonmap = false;
            $('html, body').animate({ scrollTop: '0px' }, 5);
            setTimeout(() => {
                if (this.eta_store.tableRoutedetail) {
                    this.eta_store.tableRoutedetail.destroy();
                    this.eta_store.tableRoutedetail = null
                }

                this.is_showonmap = true;
                this.mapglobalstar_data = null;
                this.mapglobalstar_data_date = null;
                this.globalstar_id = null;
                this.show_range = "Last 24 Hours";
                this.tracking_layer.clearLayers();
                this.target_layer.clearLayers();
                this.link_layer.clearLayers();
                this.fetch_globalstar_map(id);

                setTimeout(() => {
                    $('html, body').animate({
                        scrollTop: $("#mapglobalstar_card").offset().top - 68
                    }, 500);
                }, 10);

                setTimeout(() => {
                    this.fetch_globalstarmap_date("24");
                }, 1000);
            }, 5);
        },
        //close show on map globalstar
        closeonMap() {
            this.is_showonmap = null;
            this.mapglobalstar_id = null;
            this.mapglobalstar_data = null;
            this.mapglobalstar_data_date = null;
            this.show_range = "Last 24 Hours";
            this.tracking_layer.clearLayers();
            this.target_layer.clearLayers();
            this.link_layer.clearLayers();
            $('html, body').animate({ scrollTop: '0px' }, 500);

            if (this.eta_store.tableRoutedetail) {
                this.eta_store.tableRoutedetail.destroy();
                this.eta_store.tableRoutedetail = null
            }
        },
        //------------------------------------------------------------------



        //CHANGE RANGE FUNCTIONS
        //------------------------------------------------------------------
        change_range(index) {
            this.show_range = this.range_arr[index].label;
            this.fetch_globalstarmap_date(this.range_arr[index].val);
            this.link_layer.clearLayers();
        },
        //------------------------------------------------------------------



        //MAP ESSENTIALS FUNCTIONS
        //------------------------------------------------------------------
        //Initialize Map Globalstar
        initializeMap(map_) {
            console.log("MAP INITIALIZED");

            this.globalstar_map = map_;
            this.target_layer.addTo(this.globalstar_map);
            this.tracking_layer.addTo(this.globalstar_map);
            this.link_layer.addTo(this.globalstar_map);
            this.search_layer.addTo(this.globalstar_map);
            this.globalstar_map.scrollWheelZoom.disable();
            var searchLayer = this.search_layer;
            var mymap = this.globalstar_map;
            this.eta_store.init_eta(mymap, 'globalstar');
            this.eta_store.init_start_eta(mymap, 'globalstar', this.mapglobalstar_data.maptop);
            this.eta_store.init_detail_route(mymap, 'globalstar')
            this.eta_store.table_koordinat();
            setTimeout(() => {
                L.Control.geocoder(
                    {
                        defaultMarkGeocode: false

                    }
                ).on('markgeocode', function (e) {
                    console.log(e);
                    console.log(e.geocode.center);
                    console.log(e.geocode.html);
                    mymap.setView(new L.LatLng(e.geocode.center.lat, e.geocode.center.lng), 8);

                    var circle = L.circle([e.geocode.center.lat, e.geocode.center.lng], {
                        color: "#1DC01D",
                        fillColor: "#1DC01D",
                        fillOpacity: 0.0,
                        opacity: 0.0,
                        weight: 0,
                        radius: 1600,
                    }).bindPopup(
                        e.geocode.html.length > 0 ?
                            e.geocode.html : e.geocode.name
                    )

                    circle.on("add", function (event) {
                        event.target.openPopup();
                    });

                    circle.addTo(searchLayer)
                }).addTo(this.globalstar_map);
            }, 500);

        },
        //Set View Map
        setview_map() {
            this.globalstar_map.setView(new L.LatLng(this.mapglobalstar_data_date.maptop.lat, this.mapglobalstar_data_date.maptop.lon), 6);
        },
        //On Map Click
        onmap_click() {
            this.search_layer.clearLayers();
        },
        //Fullscreen Options
        fullscreen_scroll() {
            console.log("CLICK FULL");
            this.globalstar_map.invalidateSize();
            if (this.globalstar_map.isFullscreen() == true) {
                this.globalstar_map.scrollWheelZoom.enable();
            } else {
                this.globalstar_map.scrollWheelZoom.disable();
            }
        },
        //Return Icon Moving Marker
        return_track_icon() {
            const svgIcon = L.divIcon({
                html: `
                <svg
                stroke="#0000FF" 
                stroke-width="0"
                viewBox="0 0 57 57"
                xmlns="http://www.w3.org/2000/svg"
                >
                <path d="M50.8,56.4L30.6,43.7c-1.3-0.8-3-0.8-4.3,0L6.2,56.4c-3.4,2.2-7.5-1.6-5.7-5.2L24.9,2.2c1.5-3,5.7-3,7.2,0l24.5,48.9
                C58.4,54.8,54.3,58.5,50.8,56.4z" fill="#0000FF"></path>
                </svg>`,
                className: "",
                iconSize: [57 / 3.5, 57 / 3.5],
                iconAnchor: [57 / 3.5 / 2, 57 / 3.5 / 2],
            });
            return svgIcon
        },
        //Return Icon Iridium Marker
        return_icon(maptop) {
            let svgIcon;
            svgIcon = L.divIcon({
                html: `
                    <svg
                    stroke="#FFF" 
                    stroke-width="10"
                    viewBox="0 0 40 100"
                    xmlns="http://www.w3.org/2000/svg"
                    >
                    <path d="M 37.93 32.68 L 20 0 L 2.07 32.68 c -1.35 2.45 -2.07 5.41 -2.07 8.44 v 57.33 H 40 V 41.12 c 0 -3.03 -0.72 -5.98 -2.07 -8.44 Z" fill="`+ maptop.fill + `"></path>
                    </svg>`,
                className: "",
                iconSize: [40 / 5, 100 / 5],
                iconAnchor: [40 / 5 / 2, 100 / 5 / 2],
            });

            return svgIcon;


        },
        //Popup Tracking
        popup_tracking(index) {
            console.log(index);
            this.link_layer.clearLayers();
            var circle = L.circle([this.marker_tracking[index].lat, this.marker_tracking[index].lng], {
                color: "#1DC01D",
                fillColor: "#1DC01D",
                fillOpacity: 0.0,
                opacity: 0.0,
                weight: 0,
                radius: 1600,
            }).bindPopup(
                "<table><tr><td><b>Latitude</b></td><td>:</td><td>" +
                this.marker_tracking[index].lat +
                "</td></tr><tr><td><b>Longitude</b></td><td>:</td><td>" +
                this.marker_tracking[index].lng +
                "</td></tr><tr>" +
                "<td><b>DMS</b></td><td>:</td>" +
                "<td>" +
                this.marker_tracking[index].dms +
                "</td>" +
                "</tr>" +
                "<tr>" +
                "<td><b>Speed</b></td>" +
                "<td>:</td>" +
                " <td>" +
                this.marker_tracking[index].speed +
                "&nbsp;knots </td>" +
                "</tr>" +
                "<tr>" +
                " <td><b>Heading</b></td>" +
                "<td>:</td>" +
                "<td>" +
                this.marker_tracking[index].heading +
                "</td>" +
                " </tr>" +
                "<tr>" +
                "<td><b>Last Update</b></td>" +
                "<td>:</td>" +
                "<td>" +
                this.marker_tracking[index].updated +
                "</td>" +
                "</tr>" +
                "</table>"
            )

            circle.on("add", function (event) {
                event.target.openPopup();
            });

            this.globalstar_map.setView(new L.LatLng(this.marker_tracking[index].lat, this.marker_tracking[index].lng), 6);

            circle.addTo(this.link_layer)


            $('html, body').animate({ scrollTop: '0px' }, 5);
            setTimeout(() => {
                setTimeout(() => {
                    $('html, body').animate({
                        scrollTop: ($("#map_globalstar").offset().top) - 68
                    }, 500);
                }, 10);
            }, 10);
        },
        //-----------------------------------------------------------------



        //MOVING MARKER FUNCTIONS
        //-----------------------------------------------------------------
        //fetch tracking 24 hour marker clicked
        moveTracking(data_tracking) {
            clearInterval(this.interval_tracking);
            this.tracking_path = null;
            this.tracking_latLng = null;
            this.tracking_rotation = null;
            console.log("MASUK KE MOVE TRACKING");
            console.log(data_tracking);
            this.marker_tracking = data_tracking;
            this.tracking_layer.clearLayers();
            this.target_layer.clearLayers();
            this.moving_marker = null;

            if (data_tracking.length > 0) {
                this.moving_marker = L.marker([data_tracking[0].lat, data_tracking[0].lng], {
                    icon: this.return_track_icon(),
                    rotationAngle: data_tracking[0].heading,
                }).addTo(this.target_layer);

                var firstpolyline = new L.Polyline(data_tracking, {
                    color: '#FEE800',
                    weight: 6,
                    opacity: 0.5,
                    smoothFactor: 1
                });
                this.tracking_layer.addLayer(firstpolyline);
                var mom = this
                var i = data_tracking.length
                setTimeout(() => {
                    data_tracking.forEach(function (coord) {
                        i--
                        if (i != 0) {
                            if (mom.authStore.userAllowed && !mom.authStore.userAllowed.allowed['hide marker dot']) {
                                var circle = L.circle([coord.lat, coord.lng], {
                                    color: "#1DC01D",
                                    fillColor: "#1DC01D",
                                    fillOpacity: 0.3,
                                    opacity: 0.6,
                                    weight: 2,
                                    radius: 1600,
                                }).bindPopup(
                                    "<table><tr><td><b>Latitude</b></td><td>:</td><td>" +
                                    coord.lat +
                                    "</td></tr><tr><td><b>Longitude</b></td><td>:</td><td>" +
                                    coord.lng +
                                    "</td></tr><tr>" +
                                    "<td><b>DMS</b></td><td>:</td>" +
                                    "<td>" +
                                    coord.dms +
                                    "</td>" +
                                    "</tr>" +
                                    "<tr>" +
                                    "<td><b>Speed</b></td>" +
                                    "<td>:</td>" +
                                    " <td>" +
                                    coord.speed +
                                    "&nbsp;knots </td>" +
                                    "</tr>" +
                                    "<tr>" +
                                    " <td><b>Heading</b></td>" +
                                    "<td>:</td>" +
                                    "<td>" +
                                    coord.heading +
                                    "</td>" +
                                    " </tr>" +
                                    "<tr>" +
                                    "<td><b>Last Update</b></td>" +
                                    "<td>:</td>" +
                                    "<td>" +
                                    coord.updated +
                                    "</td>" +
                                    "</tr>" +
                                    "</table>"
                                ).addTo(mom.target_layer)

                            }
                        }
                    });
                }, 1600);
                this.initialdata_moving();
                this.movingStart();
            }


        },
        //moving marker
        initialdata_moving() {
            this.tracking_path = this.marker_tracking.slice()
            if (this.tracking_path[0]) {
                this.tracking_latLng = L.latLng([this.tracking_path[0].lat, this.tracking_path[0].lng])
                this.tracking_rotation = this.tracking_path[0].heading
            }

        },
        //moving marker
        movingStart() {
            var mother = this
            this.interval_tracking = setInterval(function () {
                mother.simulate()
            }, 250);
        },
        simulate() {
            if (this.tracking_path && this.tracking_path.length > 0) {
                let point = this.tracking_path.shift()
                this.tracking_latLng = L.latLng([point.lat, point.lng])
                this.tracking_rotation = point.heading
                this.moving_marker.setLatLng(this.tracking_latLng)
                this.moving_marker.slideTo(this.tracking_latLng, {
                    duration: 500,
                });
                this.moving_marker.options.rotationAngle = this.tracking_rotation;
            } else {
                this.target_layer.removeLayer(this.moving_marker);
                clearInterval(this.interval_tracking);
                this.tracking_path = null;
            }

        },
        //-----------------------------------------------------------------



        // UNINITIALIZE DATA WHEN LOGOUT
        //------------------------------------------------------------------
        //Uninit Data 
        uninit_data() {
            this.globalstar_table = null;
            this.globalstaruser_table = null;
            this.globalstartrack_table = null;
            this.is_showonmap = null;
            this.search_globalstar = null;
            this.search_globalstaruser = null;
            this.search_globalstartrack = null;
            this.globalstar_data = null;
            this.last_center = [-1.4312313766684295, 119.1498789812993];
            this.last_zoom = 6;
            this.mapglobalstar_data = null;
            this.mapglobalstar_data_date = null;
            this.page_off = 10;
            this.page_off_tracking = 10;
            this.show_range = "Last 24 Hours";
            this.export_range = "24";
            this.mapglobalstar_id = null;
            this.loading_range = false;
            this.tracking_layer = L.layerGroup();
            this.target_layer = L.layerGroup();
            this.link_layer = L.layerGroup();
            this.search_layer = L.layerGroup();
            this.marker_tracking = [];
            this.moving_marker = null;
            this.tracking_path = null;
            this.tracking_latLng = null;
            this.tracking_rotation = null;
            this.interval_tracking = null;
            this.globalstar_map = null;
            this.date_range = null;
            this.isBottom = false;
            this.range_arr = [
                { label: "Today", val: "today" },
                { label: "Last 24 Hours", val: "24" },
                { label: "Last 7 Days", val: "7" },
                { label: "Last 30 Days", val: "30" },
                { label: "This Week", val: "week" },
                { label: "This Month", val: "month" },
                { label: "Last Month", val: "lmonth" },
                { label: "Custom Range", val: "24" },
            ];
            this.page_off_arr = [
                10,
                25,
                50,
                100
            ];

            this.th_globalstar_arr = [
                "No",
                "Vessel Name",
                "Company",
                ""
            ];
            this.th_globalstar_track_arr = [
                "#",
                "Latitude,Longitude",
                "DMS",
                "Speed\n(Knots)",
                "Heading\n(Degree)",
                "Distance\n(Nmi)",
                "Date/Time\n(UTC)",
                "Date/Time\n(GMT+7)"
            ];
            this.th_globalstar_user_arr = [
                "#",
                "Name",
                "Email",
                "Role",
                "Last Login"
            ];
            this.td_globalstar_arr = [
                0,
                0,
                0,
                24.5
            ];
            this.td_globalstar_track_arr = [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
            ];

        }
        //------------------------------------------------------------------

        

    }
})
