import { useSailoggerStore} from '@/stores';
import RpmReport from '@/views/showonmap/RpmReportSailogger_view.vue';

export default {
  name: "Sailogger",
  components: {
    RpmReport
  },
  data() {
    return {
      sailogger_store: useSailoggerStore(),
      RpmReportComp : RpmReport,
    }
  },

  mounted() {
    this.sailogger_store.fetch_sailogger()
  },

  methods: {
  },

  watch: {
  },
  computed: {
  }
};