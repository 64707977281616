import { defineStore } from 'pinia';
import { useEtaStore, useCustomRangeStore, useAuthStore } from '../index';
import api from "@/plugins/axios";
import $ from "jquery";
import DataTable from 'datatables.net-dt';
import L from "leaflet";


export const useCrewwatchStore = defineStore({
    id: 'crewwatch',
    state: () => ({
        eta_store: useEtaStore(),
        range_store: useCustomRangeStore(),
        authStore: useAuthStore(),
        crewwatch_table: null,
        crewdetail_table: null,
        is_showcrew: null,
        is_showcrewdetail: false,
        search_crewwatch: null,
        search_detail: null,
        crewwatch_data: null,
        page_off: 10,
        page_off_detail: 10,
        show_range: "Last 24 Hours",
        export_range: "24",
        loading_range: false,
        date_range: null,
        isBottom: false,
        range_arr: [
            { label: "Today", val: "today" },
            { label: "Last 24 Hours", val: "24" },
            { label: "Last 7 Days", val: "7" },
            { label: "Last 30 Days", val: "30" },
            { label: "This Week", val: "week" },
            { label: "This Month", val: "month" },
            { label: "Last Month", val: "lmonth" },
            { label: "Custom Range", val: "24" },
        ],
        page_off_arr: [
            10,
            25,
            50,
            100
        ],
        page_off_detail_arr: [
            10,
            25,
            50,
            100
        ],
        th_crewwatch_arr: [
            "No",
            "Vessel Name",
            "Company",
            "Crew",
        ],
        td_crewwatch_arr: [
            0,
            0,
            0,
            24.5
        ],

        th_crewdetail: [
            "#(Latitude,Longitude)",
            "Skin Temperature (°C)",
            "Heart Rate (Beat per Minutes)",
            "Calorie (kcal)",
            "Walk (Steps)",
            "Distance (Meter)",
            "Date/Time (GMT+7)",
            "Status",
        ],
        td_crewdetail: [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
        ],
        showcrew_data: null,
        showcrew_id: null,
        showcrew_detail: null
    }),

    actions: {


        // DATA FETCH FUNCTIONS
        //------------------------------------------------------------------
        //Fetch Crewwatch Data
        async fetch_crewwatch() {
            await api.get(`/api/v1/navplus/crew`, {}
            ).then((res) => {
                console.log("CREWWATCH RESPONSE");
                console.log(res);
                if (this.search_crewwatch == null || this.search_crewwatch.length == 0) {
                    this.crewwatch_data = res.data.data.vessels;
                    this.initTable();
                }
            }).catch((err) => {
            }).then(() => {
            });
        },
        //Fetch Iridium Show on Map
        async fetch_showcrew(id) {
            this.showcrew_data = null;
            this.showcrew_id = id;
            await api.post(`/api/v1/navplus/crew/list`, {
                id: id,
            }
            ).then((res) => {
                console.log("SHOW CREW FETCH");
                console.log(res);
                this.showcrew_data = res.data.data
                // setTimeout(() => {
                //     this.initTable_user()
                //     this.initTable_tracking(res.data.data)
                // }, 1000);

            }).catch((err) => {
            }).then(() => {
            });
        },
        //Fetch Iridium Show on Map
        async fetch_crew_detail(id) {
            $('html, body').animate({ scrollTop: '0px' }, 5);
            setTimeout(() => {
                setTimeout(() => {
                    $('html, body').animate({
                        scrollTop: $("#crew_detail_card").offset().top - 68
                    }, 500);
                }, 10);
            }, 5);
            this.showcrew_detail = null;
            this.is_showcrewdetail = true;

            if (this.crewdetail_table) {
                this.crewdetail_table.clear().draw();
                $('#crewDetailTable').DataTable().destroy();
                this.crewdetail_table = null
            }
            await api.post(`/api/v1/navplus/crew/detail`, {
                id: id,
                range: 'today',
            }
            ).then((res) => {
                console.log("SHOW CREW DETAIL");
                console.log(res);
                this.showcrew_detail = res.data.data

                setTimeout(() => {
                    this.initTable_crewdetail(res.data.data.message)
                }, 1000);

            }).catch((err) => {
            }).then(() => {
            });
        },
        //------------------------------------------------------------------



        //TABLE SEARCH & PAGE FUNCTIONS
        //------------------------------------------------------------------
        //search iridium table
        crewwatchTable_search() {
            if (this.search_crewwatch != null && this.search_crewwatch.length > 0) {
                this.crewwatch_table.search(this.search_crewwatch).draw();
            }
        },
        //data shorting crewwatch table 
        crewwatchTable_showof(page) {
            console.log("PAGE : " + page);
            this.crewwatch_table.page.len(page).draw()
            this.page_off = page;
        },
        //clear crewwatch table
        clear_crewwatchTable_search() {
            this.search_crewwatch = ''
            this.crewwatch_table.search(this.search_crewwatch).draw();
        },
        //search iridium table
        detailTable_search() {
            if (this.search_detail != null && this.search_detail.length > 0) {
                this.crewdetail_table.search(this.search_detail).draw();
            }
        },
        //data shorting crewwatch table 
        detailTable_showof(page) {
            console.log("PAGE : " + page);
            this.crewdetail_table.page.len(page).draw()
            this.page_off = page;
        },
        //clear crewwatch table
        clear_detailTable_search() {
            this.search_detail = ''
            this.crewdetail_table.search(this.search_detail).draw();
        },
        //------------------------------------------------------------------



        //INITIALIZE TABLE FUNCTIONS
        //------------------------------------------------------------------
        //Initialize Iridium Table
        initTable() {
            var root = this
            if (this.crewwatch_table) {
                console.log("MASUK CLEAR TABLE");
                root.crewwatch_table = null;
                root.initTable()
            } else {
                console.log("MASUK INIT TABLE");
                console.log(this.crewwatch_data);
                this.crewwatch_table = new DataTable('#crewwatchTable', {
                    data: this.crewwatch_data,
                    scrollCollapse: true,
                    scroller: true,
                    destroy: true,
                    deferRender: true,
                    ordering: true,
                    bLengthChange: false,
                    responsive: true,
                    dom: 'lrtip',
                    columns: [
                        {
                            width: "4%",
                            data: '', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                            <div class="p-1 h-100 row`+ meta.row + `">
                            ${meta.row + 1}
                            </div>
                            `
                            }
                        },
                        {
                            width: "32.2%",
                            data: 'name', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                            <div class="p-1 h-100 row`+ meta.row + `">
                            ${data}
                            </div>
                            `
                            }
                        },
                        {
                            width: "32.2%",
                            data: 'pt_name', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                                <div class="p-1 h-100 row`+ meta.row + `">
                                ${data}
                                </div>
                                    `
                            }
                        },
                        {
                            width: "32.2%",
                            data: 'id', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                        
                              <div class="p-1 h-100">
                              <button id="showcrew_btn"  type="button" class="btn btn-sm btn-primary w-100 rounded-none"><i class="fas fa-users me-2"></i>Show Crew</button>
                              </div>
                              `
                            }
                        },
                    ]
                });


                var mom = this;

                this.crewwatch_table.on('click', '#showcrew_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.crewwatch_table.row(tr);
                    console.log("SHOW CREWWATCH DI CLICK");
                    console.log(row.data());

                    mom.show_crew(row.data())

                });






            }
        },
        //Initialize Crew Detail Table
        initTable_crewdetail(data_detail) {
            var root = this
            console.log("INIT CREW DETAIL TABLE ");
            console.log(this.crewdetail_table);
            console.log("MASUK INIT TABLE");
            console.log(data_detail);


            try {
                if (data_detail) {
                    this.crewdetail_table = new DataTable('#crewDetailTable', {
                        data: data_detail,
                        scrollCollapse: true,
                        scroller: true,
                        destroy: true,
                        pageLength: 5,
                        deferRender: true,
                        ordering: true,
                        bLengthChange: false,
                        responsive: true,
                        dom: 'lrtip',
                        columns: [
                            {
                                width: "",
                                data: 'latitude', className: 'td_nowrap', render: (data, type, row, meta) => {
                                    return `
                                    <div class="p-1 h-100 row`+ meta.row + `">
                                    ${data}
                                    </div>
                                        `
                                }
                            },
                            {
                                width: "",
                                data: 'latitude', className: 'td_nowrap', render: (data, type, row, meta) => {
                                    return `
                                    <div class="p-1 h-100 row`+ meta.row + `">
                                    ${data}
                                    </div>
                                        `
                                }
                            },
                            {
                                width: "",
                                data: 'latitude', className: 'td_nowrap', render: (data, type, row, meta) => {
                                    return `
                                    <div class="p-1 h-100 row`+ meta.row + `">
                                    ${data}
                                    </div>
                                        `
                                }
                            },
                            {
                                width: "",
                                data: 'latitude', className: 'td_nowrap', render: (data, type, row, meta) => {
                                    return `
                                    <div class="p-1 h-100 row`+ meta.row + `">
                                    ${data}
                                    </div>
                                        `
                                }
                            },
                            {
                                width: "",
                                data: 'latitude', className: 'td_nowrap', render: (data, type, row, meta) => {
                                    return `
                                    <div class="p-1 h-100 row`+ meta.row + `">
                                    ${data}
                                    </div>
                                        `
                                }
                            },
                            {
                                width: "",
                                data: 'latitude', className: 'td_nowrap', render: (data, type, row, meta) => {
                                    return `
                                    <div class="p-1 h-100 row`+ meta.row + `">
                                    ${data}
                                    </div>
                                        `
                                }
                            },
                            {
                                width: "",
                                data: 'latitude', className: 'td_nowrap', render: (data, type, row, meta) => {
                                    return `
                                    <div class="p-1 h-100 row`+ meta.row + `">
                                    ${data}
                                    </div>
                                        `
                                }
                            },
                            {
                                width: "",
                                data: 'latitude', className: 'td_nowrap', render: (data, type, row, meta) => {
                                    return `
                                    <div class="p-1 h-100 row`+ meta.row + `">
                                    ${data}
                                    </div>
                                        `
                                }
                            },
                        ]
                    });
                }
            } catch (e) {

            }





        },
        //------------------------------------------------------------------



        //CHANGE RANGE FUNCTIONS
        //------------------------------------------------------------------
        change_range(index) {
            this.show_range = this.range_arr[index].label;
            this.fetch_IridiumMap_date(this.range_arr[index].val);
            this.link_layer.clearLayers();
        },
        //------------------------------------------------------------------



        //SHOW CREW FUNCTIONS
        //------------------------------------------------------------------
        //show crew
        show_crew(item) {
            console.log("SHOW CREWWATCH");
            this.is_showcrew = false;

            $('html, body').animate({ scrollTop: '0px' }, 5);
            setTimeout(() => {

                this.is_showcrew = true;
                this.showcrew_data = null;
                this.showcrew_id = null;
                this.showcrew_detail = null;
                this.is_showcrewdetail = false;
                this.fetch_showcrew(item.id);
                // this.mapiridium_data = null;
                // this.mapiridium_data_date = null;
                // this.iridium_id = null;
                // this.show_range = "Last 24 Hours";
                // this.tracking_layer.clearLayers();
                // this.target_layer.clearLayers();
                // this.link_layer.clearLayers();
                // this.fetch_IridiumMap(id);



                setTimeout(() => {
                    $('html, body').animate({
                        scrollTop: $("#showcrew_card").offset().top - 68
                    }, 500);
                }, 10);
                // setTimeout(() => {
                //     this.fetch_IridiumMap_date("24");
                // }, 1000);
            }, 5);
        },

        //close show on map iridium
        close_show_crew() {
            this.is_showcrew = null;
            this.showcrew_id = null;
            this.showcrew_data = null;
            this.showcrew_detail = null;
            this.is_showcrewdetail = false;
            // this.show_range = "Last 24 Hours";
            // this.tracking_layer.clearLayers();
            // this.target_layer.clearLayers();
            // this.link_layer.clearLayers();
            $('html, body').animate({ scrollTop: '0px' }, 500);
        },
        //------------------------------------------------------------------



        // UNINITIALIZE DATA WHEN LOGOUT
        //------------------------------------------------------------------
        //Uninit Data 
        uninit_data() {

        }
        //------------------------------------------------------------------



    }
})
