export var cns11 = {
  c9: [
    { lat: 9.0289, lng: 99.6861 },
    { lat: 8.6232, lng: 101.0 },
    { lat: 8.2628, lng: 102.3283 },
    { lat: 7.8983, lng: 103.6533 },
    { lat: 7.3754, lng: 104.9261 },
    { lat: 6.7028, lng: 106.1252 },
    { lat: 6.0447, lng: 107.3313 },
    { lat: 5.5986, lng: 108.6336 },
    { lat: 5.2201, lng: 109.9538 },
    { lat: 4.9581, lng: 111.3056 },
    { lat: 4.8196, lng: 112.6719 },
    { lat: 4.7891, lng: 114.0487 },
    { lat: 4.5977, lng: 115.4073 },
    { lat: 4.2163, lng: 116.7283 },
    { lat: 4.0066, lng: 118.0861 },
    { lat: 4.0037, lng: 119.4614 },
    { lat: 4.0777, lng: 120.8344 },
    { lat: 4.1742, lng: 122.2078 },
    { lat: 4.3691, lng: 123.5677 },
    { lat: 4.625, lng: 124.9201 },
    { lat: 4.6996, lng: 126.2909 },
    { lat: 4.4231, lng: 127.6278 },
    { lat: 3.8499, lng: 128.8832 },
    { lat: 3.1924, lng: 130.0882 },
    { lat: 2.524, lng: 131.2884 },
    { lat: 2.1393, lng: 132.6092 },
    { lat: 1.8945, lng: 133.9614 },
    { lat: 1.6989, lng: 135.324 },
    { lat: 1.4065, lng: 136.6674 },
    { lat: 0.9654, lng: 137.9661 },
    { lat: 0.4035, lng: 139.2218 },
    { lat: -0.187, lng: 140.463 },
    { lat: -1.0586, lng: 141.5206 },
    { lat: -2.2364, lng: 142.2141 },
    { lat: -3.5878, lng: 142.4567 },
    { lat: -4.9654, lng: 142.4418 },
    { lat: -6.3364, lng: 142.5231 },
    { lat: -7.6863, lng: 142.7905 },
    { lat: -9.0289, lng: 143.0825 },
    { lat: -10.3875, lng: 142.9932 },
    { lat: -11.5414, lng: 142.2677 },
    { lat: -12.2847, lng: 141.1106 },
    { lat: -12.8557, lng: 139.861 },
    { lat: -13.3497, lng: 138.5827 },
    { lat: -13.7104, lng: 137.263 },
    { lat: -13.3555, lng: 135.9312 },
    { lat: -12.7717, lng: 134.6976 },
    { lat: -12.3078, lng: 133.4536 },
    { lat: -12.7153, lng: 132.1206 },
    { lat: -12.7058, lng: 130.7507 },
    { lat: -12.7456, lng: 129.3758 },
    { lat: -12.9007, lng: 128.0079 },
    { lat: -13.1297, lng: 126.6529 },
    { lat: -13.4333, lng: 125.31 },
    { lat: -13.7188, lng: 123.9648 },
    { lat: -13.9619, lng: 122.6104 },
    { lat: -14.0874, lng: 121.2415 },
    { lat: -14.0853, lng: 119.8658 },
    { lat: -13.9653, lng: 118.4957 },
    { lat: -13.7029, lng: 117.1461 },
    { lat: -13.3324, lng: 115.8212 },
    { lat: -12.9355, lng: 114.5038 },
    { lat: -12.6017, lng: 113.1699 },
    { lat: -12.4023, lng: 111.8093 },
    { lat: -12.2615, lng: 110.4402 },
    { lat: -12.0356, lng: 109.0842 },
    { lat: -11.6622, lng: 107.7605 },
    { lat: -11.2718, lng: 106.4416 },
    { lat: -10.8633, lng: 105.1278 },
    { lat: -10.3626, lng: 103.8467 },
    { lat: -9.7625, lng: 102.6098 },
    { lat: -8.9751, lng: 101.4849 },
    { lat: -7.9598, lng: 100.5612 },
    { lat: -6.7945, lng: 99.83 },
    { lat: -5.6019, lng: 99.1518 },
    { lat: -4.6433, lng: 98.1688 },
    { lat: -3.824, lng: 97.0675 },
    { lat: -2.8736, lng: 96.0797 },
    { lat: -1.7003, lng: 95.3635 },
    { lat: -0.4867, lng: 94.7158 },
    { lat: 0.6244, lng: 93.909 },
    { lat: 1.7157, lng: 93.0683 },
    { lat: 2.907, lng: 92.386 },
    { lat: 4.2302, lng: 92.0405 },
    { lat: 5.6116, lng: 92.0213 },
    { lat: 6.9316, lng: 92.3522 },
    { lat: 7.9921, lng: 93.2186 },
    { lat: 8.7506, lng: 94.3583 },
    { lat: 9.2991, lng: 95.6179 },
    { lat: 9.4737, lng: 96.9811 },
    { lat: 9.3453, lng: 98.3473 },
    { lat: 9.0289, lng: 99.6861 },
  ],
  c8: [
    { lat: 7.95, lng: 100.688 },
    { lat: 7.614, lng: 101.405 },
    { lat: 7.133, lng: 102.537 },
    { lat: 6.81, lng: 103.569 },
    { lat: 6.738, lng: 103.671 },
    { lat: 6.06, lng: 104.803 },
    { lat: 5.673, lng: 105.385 },
    { lat: 5.18, lng: 105.933 },
    { lat: 4.802, lng: 107.075 },
    { lat: 4.627, lng: 108.224 },
    { lat: 4.543, lng: 108.917 },
    { lat: 4.455, lng: 109.38 },
    { lat: 4.162, lng: 110.54 },
    { lat: 3.848, lng: 111.708 },
    { lat: 3.773, lng: 112.889 },
    { lat: 3.871, lng: 114.085 },
    { lat: 3.668, lng: 115.285 },
    { lat: 3.428, lng: 116.27 },
    { lat: 3.31, lng: 116.493 },
    { lat: 3.056, lng: 117.717 },
    { lat: 3.218, lng: 118.966 },
    { lat: 3.442, lng: 119.682 },
    { lat: 3.507, lng: 120.236 },
    { lat: 3.621, lng: 121.52 },
    { lat: 3.746, lng: 122.824 },
    { lat: 3.98, lng: 124.156 },
    { lat: 4.226, lng: 125.514 },
    { lat: 4.121, lng: 126.883 },
    { lat: 3.601, lng: 128.26 },
    { lat: 3.489, lng: 128.484 },
    { lat: 2.607, lng: 129.653 },
    { lat: 2.332, lng: 130.186 },
    { lat: 1.966, lng: 131.098 },
    { lat: 1.672, lng: 132.593 },
    { lat: 1.492, lng: 134.134 },
    { lat: 1.275, lng: 135.723 },
    { lat: 1.179, lng: 136.095 },
    { lat: 0.833, lng: 137.363 },
    { lat: 0.042, lng: 139.067 },
    { lat: 0.0, lng: 139.163 },
    { lat: -1.026, lng: 140.858 },
    { lat: -1.192, lng: 141.038 },
    { lat: -2.388, lng: 141.739 },
    { lat: -3.586, lng: 141.923 },
    { lat: -4.784, lng: 141.898 },
    { lat: -5.985, lng: 141.899 },
    { lat: -7.193, lng: 142.044 },
    { lat: -8.407, lng: 142.28 },
    { lat: -9.624, lng: 142.355 },
    { lat: -10.655, lng: 142.029 },
    { lat: -10.833, lng: 141.859 },
    { lat: -11.756, lng: 140.413 },
    { lat: -11.998, lng: 139.536 },
    { lat: -12.231, lng: 138.722 },
    { lat: -12.182, lng: 136.977 },
    { lat: -11.925, lng: 136.653 },
    { lat: -10.727, lng: 135.032 },
    { lat: -11.029, lng: 133.483 },
    { lat: -11.612, lng: 132.022 },
    { lat: -11.786, lng: 130.695 },
    { lat: -11.815, lng: 130.536 },
    { lat: -12.189, lng: 129.111 },
    { lat: -12.508, lng: 127.708 },
    { lat: -12.844, lng: 126.335 },
    { lat: -12.892, lng: 126.207 },
    { lat: -13.237, lng: 124.994 },
    { lat: -13.55, lng: 123.663 },
    { lat: -13.797, lng: 122.343 },
    { lat: -13.906, lng: 121.024 },
    { lat: -13.878, lng: 119.708 },
    { lat: -13.742, lng: 118.399 },
    { lat: -13.469, lng: 117.096 },
    { lat: -13.077, lng: 115.801 },
    { lat: -12.693, lng: 114.574 },
    { lat: -12.68, lng: 114.526 },
    { lat: -12.392, lng: 113.278 },
    { lat: -12.206, lng: 112.051 },
    { lat: -12.083, lng: 110.839 },
    { lat: -11.937, lng: 109.637 },
    { lat: -11.64, lng: 108.438 },
    { lat: -11.45, lng: 107.847 },
    { lat: -11.262, lng: 107.247 },
    { lat: -10.933, lng: 106.067 },
    { lat: -10.539, lng: 104.894 },
    { lat: -10.266, lng: 104.226 },
    { lat: -10.071, lng: 103.729 },
    { lat: -9.498, lng: 102.569 },
    { lat: -9.102, lng: 101.997 },
    { lat: -8.587, lng: 101.414 },
    { lat: -7.95, lng: 100.827 },
    { lat: -6.967, lng: 100.265 },
    { lat: -6.805, lng: 100.163 },
    { lat: -5.663, lng: 99.641 },
    { lat: -4.679, lng: 99.127 },
    { lat: -4.526, lng: 98.956 },
    { lat: -3.649, lng: 98.0 },
    { lat: -3.392, lng: 97.624 },
    { lat: -2.692, lng: 96.877 },
    { lat: -2.26, lng: 96.525 },
    { lat: -1.184, lng: 95.754 },
    { lat: -1.13, lng: 95.727 },
    { lat: 0.0, lng: 95.173 },
    { lat: 0.727, lng: 94.63 },
    { lat: 1.13, lng: 94.326 },
    { lat: 2.073, lng: 93.5 },
    { lat: 2.262, lng: 93.367 },
    { lat: 3.395, lng: 92.843 },
    { lat: 4.53, lng: 92.638 },
    { lat: 5.668, lng: 92.753 },
    { lat: 6.808, lng: 93.215 },
    { lat: 7.108, lng: 93.463 },
    { lat: 7.951, lng: 94.394 },
    { lat: 8.073, lng: 94.591 },
    { lat: 8.621, lng: 95.725 },
    { lat: 8.849, lng: 96.862 },
    { lat: 8.744, lng: 98.0 },
    { lat: 8.396, lng: 99.137 },
    { lat: 8.06, lng: 100.271 },
    { lat: 7.95, lng: 100.688 },
  ],
  c6: [
    { lat: 6.804, lng: 98.861 },
    { lat: 5.85, lng: 98.0 },
    { lat: 5.663, lng: 97.852 },
    { lat: 4.752, lng: 96.873 },
    { lat: 4.526, lng: 96.761 },
    { lat: 3.393, lng: 96.555 },
    { lat: 2.339, lng: 96.877 },
    { lat: 2.261, lng: 96.93 },
    { lat: 1.431, lng: 98.0 },
    { lat: 1.128, lng: 98.273 },
    { lat: 0.238, lng: 99.124 },
    { lat: 0.0, lng: 99.364 },
    { lat: -0.742, lng: 100.246 },
    { lat: -1.13, lng: 100.721 },
    { lat: -1.436, lng: 101.371 },
    { lat: -1.489, lng: 102.498 },
    { lat: -1.31, lng: 103.626 },
    { lat: -1.295, lng: 104.759 },
    { lat: -1.539, lng: 105.898 },
    { lat: -1.918, lng: 107.043 },
    { lat: -2.157, lng: 108.193 },
    { lat: -2.142, lng: 109.348 },
    { lat: -2.04, lng: 110.51 },
    { lat: -2.163, lng: 111.683 },
    { lat: -2.274, lng: 112.032 },
    { lat: -2.369, lng: 112.866 },
    { lat: -2.373, lng: 114.057 },
    { lat: -2.292, lng: 115.258 },
    { lat: -2.34, lng: 116.474 },
    { lat: -2.47, lng: 117.705 },
    { lat: -2.449, lng: 118.949 },
    { lat: -2.293, lng: 119.555 },
    { lat: -1.512, lng: 120.193 },
    { lat: -1.148, lng: 120.537 },
    { lat: 0.0, lng: 120.32 },
    { lat: 0.346, lng: 120.184 },
    { lat: 1.147, lng: 119.95 },
    { lat: 1.839, lng: 120.198 },
    { lat: 2.296, lng: 120.446 },
    { lat: 2.595, lng: 121.491 },
    { lat: 2.854, lng: 122.796 },
    { lat: 3.293, lng: 124.13 },
    { lat: 3.466, lng: 124.531 },
    { lat: 3.62, lng: 125.488 },
    { lat: 3.509, lng: 126.856 },
    { lat: 3.48, lng: 126.933 },
    { lat: 2.323, lng: 128.0 },
    { lat: 1.161, lng: 128.128 },
    { lat: 1.08, lng: 128.188 },
    { lat: 1.162, lng: 128.441 },
    { lat: 1.242, lng: 129.619 },
    { lat: 1.226, lng: 131.081 },
    { lat: 1.17, lng: 132.335 },
    { lat: 1.162, lng: 132.582 },
    { lat: 1.058, lng: 134.126 },
    { lat: 0.843, lng: 135.715 },
    { lat: 0.392, lng: 137.359 },
    { lat: 0.0, lng: 138.193 },
    { lat: -0.515, lng: 139.069 },
    { lat: -1.19, lng: 140.16 },
    { lat: -2.033, lng: 140.89 },
    { lat: -2.385, lng: 141.104 },
    { lat: -3.581, lng: 141.322 },
    { lat: -4.778, lng: 141.304 },
    { lat: -5.977, lng: 141.271 },
    { lat: -6.908, lng: 141.336 },
    { lat: -7.181, lng: 141.342 },
    { lat: -8.391, lng: 141.421 },
    { lat: -9.595, lng: 141.027 },
    { lat: -10.55, lng: 140.145 },
    { lat: -10.721, lng: 138.398 },
    { lat: -10.127, lng: 136.577 },
    { lat: -9.763, lng: 134.872 },
    { lat: -10.213, lng: 133.351 },
    { lat: -10.62, lng: 132.384 },
    { lat: -10.857, lng: 131.9 },
    { lat: -11.247, lng: 130.447 },
    { lat: -11.623, lng: 129.025 },
    { lat: -11.742, lng: 128.604 },
    { lat: -12.046, lng: 127.64 },
    { lat: -12.461, lng: 126.28 },
    { lat: -12.871, lng: 125.199 },
    { lat: -12.942, lng: 124.952 },
    { lat: -13.298, lng: 123.628 },
    { lat: -13.565, lng: 122.313 },
    { lat: -13.683, lng: 120.997 },
    { lat: -13.648, lng: 119.682 },
    { lat: -13.496, lng: 118.373 },
    { lat: -13.201, lng: 117.07 },
    { lat: -12.792, lng: 115.776 },
    { lat: -12.705, lng: 115.498 },
    { lat: -12.422, lng: 114.506 },
    { lat: -12.142, lng: 113.26 },
    { lat: -11.961, lng: 112.035 },
    { lat: -11.835, lng: 110.825 },
    { lat: -11.682, lng: 109.625 },
    { lat: -11.457, lng: 108.749 },
    { lat: -11.371, lng: 108.426 },
    { lat: -11.0, lng: 107.237 },
    { lat: -10.667, lng: 106.059 },
    { lat: -10.269, lng: 104.921 },
    { lat: -10.257, lng: 104.887 },
    { lat: -9.802, lng: 103.723 },
    { lat: -9.196, lng: 102.564 },
    { lat: -9.103, lng: 102.43 },
    { lat: -8.203, lng: 101.41 },
    { lat: -7.951, lng: 101.177 },
    { lat: -6.805, lng: 100.519 },
    { lat: -5.971, lng: 100.26 },
    { lat: -5.664, lng: 100.126 },
    { lat: -4.526, lng: 99.803 },
    { lat: -3.392, lng: 99.251 },
    { lat: -3.165, lng: 99.125 },
    { lat: -2.26, lng: 98.253 },
    { lat: -1.993, lng: 98.0 },
    { lat: -1.128, lng: 97.199 },
    { lat: -0.749, lng: 96.878 },
    { lat: 0.0, lng: 96.486 },
    { lat: 1.103, lng: 95.755 },
    { lat: 1.13, lng: 95.741 },
    { lat: 2.243, lng: 94.628 },
    { lat: 2.261, lng: 94.612 },
    { lat: 3.394, lng: 93.858 },
    { lat: 4.529, lng: 93.543 },
    { lat: 5.666, lng: 93.875 },
    { lat: 6.762, lng: 94.603 },
    { lat: 6.806, lng: 94.656 },
    { lat: 7.451, lng: 95.732 },
    { lat: 7.724, lng: 96.866 },
    { lat: 7.293, lng: 98.0 },
    { lat: 6.804, lng: 98.861 },
  ],
  c7: [
    { lat: -1.156, lng: 125.466 },
    { lat: -1.2, lng: 125.425 },
    { lat: -1.156, lng: 125.324 },
    { lat: -0.93, lng: 125.421 },
    { lat: -1.156, lng: 125.466 },
  ],
  c5: [
    { lat: 0.0, lng: 136.98 },
    { lat: -0.129, lng: 137.357 },
    { lat: -1.15, lng: 139.079 },
    { lat: -1.187, lng: 139.139 },
    { lat: -2.38, lng: 140.213 },
    { lat: -3.575, lng: 140.525 },
    { lat: -4.768, lng: 140.434 },
    { lat: -5.963, lng: 140.204 },
    { lat: -7.159, lng: 139.961 },
    { lat: -8.148, lng: 139.703 },
    { lat: -8.353, lng: 139.404 },
    { lat: -8.808, lng: 138.054 },
    { lat: -8.826, lng: 136.365 },
    { lat: -8.858, lng: 134.737 },
    { lat: -9.441, lng: 133.311 },
    { lat: -9.467, lng: 133.239 },
    { lat: -10.15, lng: 131.794 },
    { lat: -10.583, lng: 130.498 },
    { lat: -10.628, lng: 130.356 },
    { lat: -11.15, lng: 128.956 },
    { lat: -11.568, lng: 127.572 },
    { lat: -11.712, lng: 127.136 },
    { lat: -12.031, lng: 126.22 },
    { lat: -12.565, lng: 124.9 },
    { lat: -12.851, lng: 124.182 },
    { lat: -13.016, lng: 123.591 },
    { lat: -13.306, lng: 122.28 },
    { lat: -13.434, lng: 120.967 },
    { lat: -13.391, lng: 119.653 },
    { lat: -13.22, lng: 118.344 },
    { lat: -12.899, lng: 117.041 },
    { lat: -12.719, lng: 116.475 },
    { lat: -12.486, lng: 115.751 },
    { lat: -12.135, lng: 114.485 },
    { lat: -11.861, lng: 113.241 },
    { lat: -11.685, lng: 112.018 },
    { lat: -11.557, lng: 110.81 },
    { lat: -11.468, lng: 110.137 },
    { lat: -11.395, lng: 109.61 },
    { lat: -11.075, lng: 108.414 },
    { lat: -10.706, lng: 107.226 },
    { lat: -10.37, lng: 106.049 },
    { lat: -10.272, lng: 105.771 },
    { lat: -9.967, lng: 104.88 },
    { lat: -9.5, lng: 103.717 },
    { lat: -9.105, lng: 102.973 },
    { lat: -8.827, lng: 102.559 },
    { lat: -7.951, lng: 101.581 },
    { lat: -7.666, lng: 101.405 },
    { lat: -6.805, lng: 100.909 },
    { lat: -5.664, lng: 100.615 },
    { lat: -4.527, lng: 100.626 },
    { lat: -3.394, lng: 101.243 },
    { lat: -3.285, lng: 101.376 },
    { lat: -2.627, lng: 102.502 },
    { lat: -2.263, lng: 103.618 },
    { lat: -2.258, lng: 103.63 },
    { lat: -2.111, lng: 104.763 },
    { lat: -2.264, lng: 105.322 },
    { lat: -2.363, lng: 105.903 },
    { lat: -2.664, lng: 107.049 },
    { lat: -2.857, lng: 108.2 },
    { lat: -2.911, lng: 109.356 },
    { lat: -2.965, lng: 110.521 },
    { lat: -3.075, lng: 111.695 },
    { lat: -3.288, lng: 112.88 },
    { lat: -3.344, lng: 114.073 },
    { lat: -3.289, lng: 115.276 },
    { lat: -3.289, lng: 116.492 },
    { lat: -3.375, lng: 117.725 },
    { lat: -3.438, lng: 118.62 },
    { lat: -3.454, lng: 118.972 },
    { lat: -3.44, lng: 119.301 },
    { lat: -3.354, lng: 120.231 },
    { lat: -3.418, lng: 121.513 },
    { lat: -3.451, lng: 121.617 },
    { lat: -3.62, lng: 122.82 },
    { lat: -3.775, lng: 124.148 },
    { lat: -3.699, lng: 125.491 },
    { lat: -3.475, lng: 126.03 },
    { lat: -2.833, lng: 126.831 },
    { lat: -2.321, lng: 127.56 },
    { lat: -1.836, lng: 128.202 },
    { lat: -1.163, lng: 128.957 },
    { lat: -0.607, lng: 129.611 },
    { lat: 0.0, lng: 130.469 },
    { lat: 0.252, lng: 131.072 },
    { lat: 0.477, lng: 132.574 },
    { lat: 0.515, lng: 134.119 },
    { lat: 0.35, lng: 135.711 },
    { lat: 0.0, lng: 136.98 },
  ],
  c4: [
    { lat: -1.184, lng: 137.958 },
    { lat: -2.334, lng: 139.118 },
    { lat: -2.375, lng: 139.155 },
    { lat: -3.566, lng: 139.495 },
    { lat: -4.757, lng: 139.358 },
    { lat: -4.967, lng: 139.301 },
    { lat: -5.944, lng: 138.791 },
    { lat: -7.004, lng: 137.795 },
    { lat: -7.122, lng: 137.546 },
    { lat: -7.582, lng: 136.191 },
    { lat: -7.839, lng: 134.601 },
    { lat: -8.257, lng: 133.855 },
    { lat: -8.579, lng: 133.118 },
    { lat: -9.412, lng: 131.715 },
    { lat: -9.423, lng: 131.692 },
    { lat: -10.021, lng: 130.271 },
    { lat: -10.555, lng: 129.054 },
    { lat: -10.621, lng: 128.883 },
    { lat: -11.104, lng: 127.509 },
    { lat: -11.559, lng: 126.157 },
    { lat: -11.688, lng: 125.867 },
    { lat: -12.127, lng: 124.843 },
    { lat: -12.654, lng: 123.544 },
    { lat: -12.829, lng: 123.043 },
    { lat: -13.016, lng: 122.243 },
    { lat: -13.154, lng: 120.934 },
    { lat: -13.103, lng: 119.621 },
    { lat: -12.911, lng: 118.313 },
    { lat: -12.736, lng: 117.63 },
    { lat: -12.549, lng: 117.009 },
    { lat: -12.148, lng: 115.723 },
    { lat: -11.812, lng: 114.461 },
    { lat: -11.548, lng: 113.221 },
    { lat: -11.494, lng: 112.844 },
    { lat: -11.371, lng: 112.0 },
    { lat: -11.236, lng: 110.793 },
    { lat: -11.065, lng: 109.595 },
    { lat: -10.744, lng: 108.4 },
    { lat: -10.376, lng: 107.214 },
    { lat: -10.278, lng: 106.873 },
    { lat: -10.032, lng: 106.039 },
    { lat: -9.641, lng: 104.872 },
    { lat: -9.162, lng: 103.71 },
    { lat: -9.107, lng: 103.607 },
    { lat: -8.403, lng: 102.553 },
    { lat: -7.952, lng: 102.05 },
    { lat: -6.896, lng: 101.398 },
    { lat: -6.805, lng: 101.346 },
    { lat: -5.665, lng: 101.143 },
    { lat: -4.707, lng: 101.383 },
    { lat: -4.528, lng: 101.442 },
    { lat: -3.462, lng: 102.506 },
    { lat: -3.394, lng: 102.616 },
    { lat: -2.861, lng: 103.634 },
    { lat: -2.713, lng: 104.767 },
    { lat: -2.928, lng: 105.907 },
    { lat: -3.303, lng: 107.055 },
    { lat: -3.402, lng: 107.506 },
    { lat: -3.524, lng: 108.207 },
    { lat: -3.592, lng: 109.365 },
    { lat: -3.668, lng: 110.531 },
    { lat: -3.772, lng: 111.707 },
    { lat: -3.925, lng: 112.893 },
    { lat: -4.005, lng: 114.088 },
    { lat: -4.009, lng: 115.293 },
    { lat: -4.016, lng: 116.511 },
    { lat: -4.048, lng: 117.743 },
    { lat: -4.098, lng: 118.991 },
    { lat: -4.082, lng: 120.254 },
    { lat: -4.193, lng: 121.54 },
    { lat: -4.47, lng: 122.853 },
    { lat: -4.62, lng: 123.784 },
    { lat: -4.659, lng: 124.186 },
    { lat: -4.63, lng: 125.206 },
    { lat: -4.611, lng: 125.533 },
    { lat: -4.089, lng: 126.881 },
    { lat: -3.523, lng: 128.257 },
    { lat: -3.488, lng: 128.331 },
    { lat: -2.445, lng: 129.647 },
    { lat: -2.33, lng: 129.752 },
    { lat: -1.167, lng: 130.926 },
    { lat: -1.056, lng: 131.079 },
    { lat: -0.405, lng: 132.574 },
    { lat: -0.132, lng: 134.117 },
    { lat: -0.272, lng: 135.71 },
    { lat: -0.828, lng: 137.363 },
    { lat: -1.184, lng: 137.958 },
  ],
  c3: [
    { lat: -1.179, lng: 135.951 },
    { lat: -1.966, lng: 137.391 },
    { lat: -2.368, lng: 137.784 },
    { lat: -3.556, lng: 138.116 },
    { lat: -4.74, lng: 137.738 },
    { lat: -4.98, lng: 137.577 },
    { lat: -5.912, lng: 136.253 },
    { lat: -6.038, lng: 136.014 },
    { lat: -6.375, lng: 134.435 },
    { lat: -7.06, lng: 133.303 },
    { lat: -7.239, lng: 132.96 },
    { lat: -8.225, lng: 131.871 },
    { lat: -8.407, lng: 131.564 },
    { lat: -9.329, lng: 130.182 },
    { lat: -9.384, lng: 130.122 },
    { lat: -10.044, lng: 128.808 },
    { lat: -10.529, lng: 127.596 },
    { lat: -10.584, lng: 127.441 },
    { lat: -11.052, lng: 126.092 },
    { lat: -11.634, lng: 124.78 },
    { lat: -11.666, lng: 124.716 },
    { lat: -12.185, lng: 123.486 },
    { lat: -12.638, lng: 122.197 },
    { lat: -12.797, lng: 121.295 },
    { lat: -12.841, lng: 120.897 },
    { lat: -12.78, lng: 119.586 },
    { lat: -12.766, lng: 119.492 },
    { lat: -12.518, lng: 118.274 },
    { lat: -12.141, lng: 116.973 },
    { lat: -11.77, lng: 115.693 },
    { lat: -11.515, lng: 114.695 },
    { lat: -11.441, lng: 114.434 },
    { lat: -11.169, lng: 113.196 },
    { lat: -11.007, lng: 111.978 },
    { lat: -10.873, lng: 110.774 },
    { lat: -10.696, lng: 109.578 },
    { lat: -10.373, lng: 108.385 },
    { lat: -10.286, lng: 108.104 },
    { lat: -9.971, lng: 107.201 },
    { lat: -9.651, lng: 106.028 },
    { lat: -9.277, lng: 104.863 },
    { lat: -9.109, lng: 104.459 },
    { lat: -8.73, lng: 103.702 },
    { lat: -7.953, lng: 102.582 },
    { lat: -7.908, lng: 102.547 },
    { lat: -6.807, lng: 101.848 },
    { lat: -5.665, lng: 101.72 },
    { lat: -4.529, lng: 102.224 },
    { lat: -4.241, lng: 102.511 },
    { lat: -3.518, lng: 103.637 },
    { lat: -3.397, lng: 104.338 },
    { lat: -3.319, lng: 104.772 },
    { lat: -3.398, lng: 105.152 },
    { lat: -3.535, lng: 105.913 },
    { lat: -3.879, lng: 107.061 },
    { lat: -4.096, lng: 108.215 },
    { lat: -4.17, lng: 109.375 },
    { lat: -4.266, lng: 110.542 },
    { lat: -4.402, lng: 111.72 },
    { lat: -4.557, lng: 112.772 },
    { lat: -4.575, lng: 112.908 },
    { lat: -4.683, lng: 114.105 },
    { lat: -4.727, lng: 115.313 },
    { lat: -4.755, lng: 116.533 },
    { lat: -4.764, lng: 117.766 },
    { lat: -4.796, lng: 119.016 },
    { lat: -4.794, lng: 120.281 },
    { lat: -4.899, lng: 121.569 },
    { lat: -5.087, lng: 122.882 },
    { lat: -5.218, lng: 124.215 },
    { lat: -5.211, lng: 125.565 },
    { lat: -4.988, lng: 126.928 },
    { lat: -4.739, lng: 128.319 },
    { lat: -4.659, lng: 128.941 },
    { lat: -4.449, lng: 129.737 },
    { lat: -3.504, lng: 130.894 },
    { lat: -3.214, lng: 131.142 },
    { lat: -2.34, lng: 131.958 },
    { lat: -1.871, lng: 132.598 },
    { lat: -1.174, lng: 133.798 },
    { lat: -1.082, lng: 134.126 },
    { lat: -1.103, lng: 135.72 },
    { lat: -1.179, lng: 135.951 },
  ],
  c2: [
    { lat: -4.64, lng: 107.742 },
    { lat: -4.7717, lng: 108.7224 },
    { lat: -4.8272, lng: 109.7108 },
    { lat: -4.9076, lng: 110.6973 },
    { lat: -5.0172, lng: 111.681 },
    { lat: -5.1445, lng: 112.6626 },
    { lat: -5.2681, lng: 113.6447 },
    { lat: -5.3744, lng: 114.6287 },
    { lat: -5.4589, lng: 115.6149 },
    { lat: -5.5209, lng: 116.6028 },
    { lat: -5.5211, lng: 117.5926 },
    { lat: -5.5204, lng: 118.5824 },
    { lat: -5.4988, lng: 119.5719 },
    { lat: -5.4891, lng: 120.5615 },
    { lat: -5.5464, lng: 121.5496 },
    { lat: -5.6638, lng: 122.5324 },
    { lat: -5.7751, lng: 123.5159 },
    { lat: -5.849, lng: 124.5028 },
    { lat: -5.8655, lng: 125.4924 },
    { lat: -5.8133, lng: 126.4809 },
    { lat: -5.786, lng: 127.4697 },
    { lat: -5.8212, lng: 128.4562 },
    { lat: -6.3034, lng: 129.3352 },
    { lat: -6.8262, lng: 130.1711 },
    { lat: -7.7823, lng: 130.1988 },
    { lat: -8.5772, lng: 129.6301 },
    { lat: -9.2702, lng: 128.9155 },
    { lat: -9.6938, lng: 128.0275 },
    { lat: -10.0674, lng: 127.1113 },
    { lat: -10.4193, lng: 126.1859 },
    { lat: -10.7792, lng: 125.2638 },
    { lat: -11.1807, lng: 124.3593 },
    { lat: -11.6352, lng: 123.4798 },
    { lat: -11.9756, lng: 122.5504 },
    { lat: -12.2261, lng: 121.5933 },
    { lat: -12.337, lng: 120.6107 },
    { lat: -12.2782, lng: 119.6229 },
    { lat: -12.1146, lng: 118.6472 },
    { lat: -11.8875, lng: 117.6838 },
    { lat: -11.6265, lng: 116.7289 },
    { lat: -11.3363, lng: 115.7825 },
    { lat: -11.0782, lng: 114.8271 },
    { lat: -10.8671, lng: 113.8603 },
    { lat: -10.7082, lng: 112.8834 },
    { lat: -10.5949, lng: 111.9 },
    { lat: -10.4835, lng: 110.9164 },
    { lat: -10.3405, lng: 109.9371 },
    { lat: -10.085, lng: 108.9835 },
    { lat: -9.7757, lng: 108.0434 },
    { lat: -9.4873, lng: 107.0966 },
    { lat: -9.2511, lng: 106.1354 },
    { lat: -8.9369, lng: 105.1975 },
    { lat: -8.5375, lng: 104.2921 },
    { lat: -8.0546, lng: 103.4293 },
    { lat: -7.3091, lng: 102.7761 },
    { lat: -6.4361, lng: 102.4022 },
    { lat: -5.4585, lng: 102.4699 },
    { lat: -4.639, lng: 103.0492 },
    { lat: -4.126, lng: 103.8845 },
    { lat: -3.9629, lng: 104.8584 },
    { lat: -4.1226, lng: 105.8332 },
    { lat: -4.4076, lng: 106.7806 },
    { lat: -4.64, lng: 107.742 },
  ],
  c1: [
    { lat: -5.697, lng: 111.81 },
    { lat: -5.884, lng: 112.945 },
    { lat: -6.093, lng: 114.151 },
    { lat: -6.296, lng: 115.369 },
    { lat: -6.492, lng: 116.601 },
    { lat: -6.559, lng: 117.842 },
    { lat: -6.489, lng: 119.092 },
    { lat: -6.335, lng: 120.354 },
    { lat: -6.308, lng: 121.641 },
    { lat: -6.423, lng: 122.956 },
    { lat: -6.532, lng: 124.294 },
    { lat: -6.615, lng: 125.656 },
    { lat: -6.713, lng: 127.046 },
    { lat: -6.992, lng: 128.007 },
    { lat: -8.176, lng: 128.561 },
    { lat: -9.207, lng: 127.279 },
    { lat: -9.335, lng: 127.105 },
    { lat: -9.739, lng: 125.939 },
    { lat: -10.247, lng: 124.62 },
    { lat: -10.469, lng: 124.13 },
    { lat: -10.814, lng: 123.328 },
    { lat: -11.475, lng: 122.065 },
    { lat: -11.614, lng: 121.695 },
    { lat: -11.785, lng: 120.783 },
    { lat: -11.727, lng: 119.48 },
    { lat: -11.567, lng: 118.629 },
    { lat: -11.419, lng: 118.173 },
    { lat: -10.985, lng: 116.878 },
    { lat: -10.676, lng: 115.612 },
    { lat: -10.439, lng: 114.367 },
    { lat: -10.332, lng: 113.655 },
    { lat: -10.227, lng: 113.14 },
    { lat: -10.072, lng: 111.928 },
    { lat: -9.9, lng: 110.726 },
    { lat: -9.672, lng: 109.534 },
    { lat: -9.301, lng: 108.345 },
    { lat: -9.125, lng: 107.727 },
    { lat: -8.895, lng: 107.167 },
    { lat: -8.605, lng: 106.001 },
    { lat: -8.239, lng: 104.841 },
    { lat: -7.958, lng: 104.264 },
    { lat: -7.451, lng: 103.681 },
    { lat: -6.809, lng: 103.175 },
    { lat: -5.668, lng: 103.179 },
    { lat: -5.079, lng: 103.651 },
    { lat: -4.609, lng: 104.784 },
    { lat: -4.85, lng: 105.928 },
    { lat: -5.263, lng: 107.082 },
    { lat: -5.472, lng: 108.24 },
    { lat: -5.489, lng: 109.401 },
    { lat: -5.552, lng: 110.571 },
    { lat: -5.689, lng: 111.752 },
    { lat: -5.697, lng: 111.81 },
  ],
};
export var cns11gain = {
  c1: "55.00",
  c2: "54.00",
  c3: "53.00",
  c4: "52.00",
  c5: "51.00",
  c6: "50.00",
  c7: "50.00",
  c8: "49.00",
  c9: "48.00",
};
export var cns11_gnt = {
  c1: [
    { lat: 7.949, lng: 99.996 },
    { lat: 7.908, lng: 100.27 },
    { lat: 7.741, lng: 101.406 },
    { lat: 7.43, lng: 102.541 },
    { lat: 6.953, lng: 103.674 },
    { lat: 6.811, lng: 103.968 },
    { lat: 6.253, lng: 104.806 },
    { lat: 5.784, lng: 105.943 },
    { lat: 5.675, lng: 106.244 },
    { lat: 5.322, lng: 107.083 },
    { lat: 4.87, lng: 108.228 },
    { lat: 4.544, lng: 109.027 },
    { lat: 4.356, lng: 109.378 },
    { lat: 3.959, lng: 110.536 },
    { lat: 3.759, lng: 111.706 },
    { lat: 3.715, lng: 112.888 },
    { lat: 3.678, lng: 114.08 },
    { lat: 3.626, lng: 115.284 },
    { lat: 3.444, lng: 116.496 },
    { lat: 3.429, lng: 116.563 },
    { lat: 2.991, lng: 117.716 },
    { lat: 2.889, lng: 118.958 },
    { lat: 2.959, lng: 120.221 },
    { lat: 3.072, lng: 121.503 },
    { lat: 3.249, lng: 122.808 },
    { lat: 3.447, lng: 124.136 },
    { lat: 3.38, lng: 125.479 },
    { lat: 3.113, lng: 126.84 },
    { lat: 2.742, lng: 128.227 },
    { lat: 2.329, lng: 129.53 },
    { lat: 2.27, lng: 129.642 },
    { lat: 1.623, lng: 131.089 },
    { lat: 1.321, lng: 132.585 },
    { lat: 1.173, lng: 133.408 },
    { lat: 1.054, lng: 134.125 },
    { lat: 0.682, lng: 135.713 },
    { lat: 0.24, lng: 137.358 },
    { lat: 0.0, lng: 137.87 },
    { lat: -0.607, lng: 139.07 },
    { lat: -1.189, lng: 139.825 },
    { lat: -2.325, lng: 140.903 },
    { lat: -2.384, lng: 140.97 },
    { lat: -3.585, lng: 141.829 },
    { lat: -4.787, lng: 142.203 },
    { lat: -5.987, lng: 141.993 },
    { lat: -7.186, lng: 141.629 },
    { lat: -8.395, lng: 141.634 },
    { lat: -8.606, lng: 141.611 },
    { lat: -9.606, lng: 141.513 },
    { lat: -10.797, lng: 140.352 },
    { lat: -10.861, lng: 140.211 },
    { lat: -11.509, lng: 138.561 },
    { lat: -11.94, lng: 137.274 },
    { lat: -12.071, lng: 136.953 },
    { lat: -12.559, lng: 135.382 },
    { lat: -12.402, lng: 133.73 },
    { lat: -11.844, lng: 133.272 },
    { lat: -10.886, lng: 131.905 },
    { lat: -11.218, lng: 130.443 },
    { lat: -11.46, lng: 129.001 },
    { lat: -11.642, lng: 127.582 },
    { lat: -11.714, lng: 127.24 },
    { lat: -11.974, lng: 126.212 },
    { lat: -12.274, lng: 124.862 },
    { lat: -12.447, lng: 123.518 },
    { lat: -12.608, lng: 122.194 },
    { lat: -12.771, lng: 120.89 },
    { lat: -12.786, lng: 120.69 },
    { lat: -12.884, lng: 119.597 },
    { lat: -12.843, lng: 118.306 },
    { lat: -12.736, lng: 117.651 },
    { lat: -12.641, lng: 117.018 },
    { lat: -12.328, lng: 115.738 },
    { lat: -11.994, lng: 114.474 },
    { lat: -11.728, lng: 113.232 },
    { lat: -11.542, lng: 112.01 },
    { lat: -11.482, lng: 111.591 },
    { lat: -11.387, lng: 110.801 },
    { lat: -11.168, lng: 109.6 },
    { lat: -10.955, lng: 108.409 },
    { lat: -10.752, lng: 107.228 },
    { lat: -10.523, lng: 106.054 },
    { lat: -10.27, lng: 105.235 },
    { lat: -10.167, lng: 104.885 },
    { lat: -9.611, lng: 103.719 },
    { lat: -9.105, lng: 103.049 },
    { lat: -8.681, lng: 102.557 },
    { lat: -7.952, lng: 101.825 },
    { lat: -7.352, lng: 101.402 },
    { lat: -6.805, lng: 100.874 },
    { lat: -6.032, lng: 100.26 },
    { lat: -5.663, lng: 99.605 },
    { lat: -5.325, lng: 99.128 },
    { lat: -4.527, lng: 98.048 },
    { lat: -4.481, lng: 98.0 },
    { lat: -3.392, lng: 97.34 },
    { lat: -2.403, lng: 96.877 },
    { lat: -2.26, lng: 96.827 },
    { lat: -1.13, lng: 96.38 },
    { lat: -0.0, lng: 95.799 },
    { lat: 0.074, lng: 95.756 },
    { lat: 1.13, lng: 94.98 },
    { lat: 1.648, lng: 94.629 },
    { lat: 2.262, lng: 94.238 },
    { lat: 3.395, lng: 93.722 },
    { lat: 4.163, lng: 93.49 },
    { lat: 4.529, lng: 93.383 },
    { lat: 5.667, lng: 93.37 },
    { lat: 5.942, lng: 93.476 },
    { lat: 6.807, lng: 93.853 },
    { lat: 7.545, lng: 94.596 },
    { lat: 7.95, lng: 95.24 },
    { lat: 8.195, lng: 95.728 },
    { lat: 8.445, lng: 96.863 },
    { lat: 8.297, lng: 98.0 },
    { lat: 8.059, lng: 99.135 },
    { lat: 7.949, lng: 99.996 },
  ],
  c2: [
    { lat: 7.948, lng: 97.154 },
    { lat: 7.824, lng: 98.0 },
    { lat: 7.582, lng: 99.134 },
    { lat: 7.43, lng: 100.268 },
    { lat: 7.275, lng: 101.401 },
    { lat: 6.957, lng: 102.535 },
    { lat: 6.809, lng: 102.883 },
    { lat: 6.152, lng: 103.663 },
    { lat: 5.671, lng: 104.564 },
    { lat: 5.45, lng: 104.794 },
    { lat: 4.977, lng: 105.93 },
    { lat: 4.729, lng: 107.073 },
    { lat: 4.54, lng: 107.64 },
    { lat: 4.228, lng: 108.217 },
    { lat: 3.715, lng: 109.368 },
    { lat: 3.443, lng: 110.528 },
    { lat: 3.41, lng: 110.735 },
    { lat: 2.954, lng: 111.693 },
    { lat: 2.563, lng: 112.869 },
    { lat: 2.45, lng: 114.058 },
    { lat: 2.508, lng: 115.262 },
    { lat: 2.377, lng: 116.474 },
    { lat: 2.287, lng: 117.422 },
    { lat: 2.128, lng: 117.699 },
    { lat: 2.289, lng: 117.992 },
    { lat: 2.336, lng: 118.946 },
    { lat: 2.494, lng: 120.21 },
    { lat: 2.669, lng: 121.493 },
    { lat: 2.874, lng: 122.797 },
    { lat: 3.092, lng: 124.124 },
    { lat: 3.037, lng: 125.467 },
    { lat: 2.772, lng: 126.829 },
    { lat: 2.407, lng: 128.216 },
    { lat: 2.325, lng: 128.474 },
    { lat: 1.711, lng: 129.628 },
    { lat: 1.208, lng: 131.081 },
    { lat: 1.168, lng: 131.294 },
    { lat: 0.946, lng: 132.579 },
    { lat: 0.724, lng: 134.121 },
    { lat: 0.352, lng: 135.711 },
    { lat: 0.0, lng: 136.928 },
    { lat: -0.127, lng: 137.357 },
    { lat: -1.028, lng: 139.077 },
    { lat: -1.187, lng: 139.282 },
    { lat: -2.381, lng: 140.433 },
    { lat: -3.445, lng: 140.968 },
    { lat: -3.579, lng: 141.063 },
    { lat: -4.777, lng: 141.268 },
    { lat: -5.273, lng: 141.131 },
    { lat: -5.974, lng: 141.025 },
    { lat: -7.174, lng: 140.875 },
    { lat: -8.381, lng: 140.899 },
    { lat: -9.587, lng: 140.656 },
    { lat: -10.174, lng: 140.067 },
    { lat: -10.768, lng: 139.142 },
    { lat: -11.04, lng: 138.462 },
    { lat: -11.492, lng: 136.833 },
    { lat: -11.712, lng: 135.213 },
    { lat: -10.65, lng: 133.811 },
    { lat: -10.308, lng: 133.365 },
    { lat: -10.234, lng: 131.806 },
    { lat: -10.591, lng: 130.903 },
    { lat: -10.77, lng: 130.376 },
    { lat: -11.118, lng: 128.952 },
    { lat: -11.331, lng: 127.539 },
    { lat: -11.63, lng: 126.166 },
    { lat: -11.689, lng: 125.954 },
    { lat: -11.995, lng: 124.826 },
    { lat: -12.238, lng: 123.492 },
    { lat: -12.436, lng: 122.174 },
    { lat: -12.614, lng: 120.872 },
    { lat: -12.712, lng: 119.579 },
    { lat: -12.664, lng: 118.289 },
    { lat: -12.467, lng: 117.002 },
    { lat: -12.142, lng: 115.723 },
    { lat: -11.794, lng: 114.459 },
    { lat: -11.513, lng: 113.218 },
    { lat: -11.497, lng: 113.11 },
    { lat: -11.355, lng: 111.999 },
    { lat: -11.21, lng: 110.791 },
    { lat: -10.985, lng: 109.591 },
    { lat: -10.761, lng: 108.401 },
    { lat: -10.54, lng: 107.22 },
    { lat: -10.288, lng: 106.047 },
    { lat: -10.274, lng: 106.001 },
    { lat: -9.945, lng: 104.879 },
    { lat: -9.352, lng: 103.714 },
    { lat: -9.106, lng: 103.389 },
    { lat: -8.387, lng: 102.553 },
    { lat: -7.952, lng: 102.116 },
    { lat: -6.935, lng: 101.399 },
    { lat: -6.806, lng: 101.274 },
    { lat: -5.664, lng: 100.395 },
    { lat: -5.449, lng: 100.257 },
    { lat: -4.691, lng: 99.127 },
    { lat: -4.526, lng: 98.903 },
    { lat: -3.676, lng: 98.0 },
    { lat: -3.392, lng: 97.828 },
    { lat: -2.26, lng: 97.303 },
    { lat: -1.412, lng: 96.877 },
    { lat: -1.131, lng: 96.766 },
    { lat: -0.0, lng: 96.262 },
    { lat: 0.843, lng: 95.754 },
    { lat: 1.13, lng: 95.544 },
    { lat: 2.261, lng: 94.791 },
    { lat: 2.556, lng: 94.627 },
    { lat: 3.394, lng: 94.238 },
    { lat: 4.529, lng: 93.902 },
    { lat: 5.666, lng: 93.918 },
    { lat: 6.806, lng: 94.467 },
    { lat: 6.94, lng: 94.602 },
    { lat: 7.664, lng: 95.731 },
    { lat: 7.948, lng: 96.716 },
    { lat: 7.982, lng: 96.865 },
    { lat: 7.948, lng: 97.154 },
  ],
  c3: [
    { lat: 2.264, lng: 105.29 },
    { lat: 1.654, lng: 105.898 },
    { lat: 1.132, lng: 106.253 },
    { lat: 0.0, lng: 106.932 },
    { lat: -0.19, lng: 107.037 },
    { lat: -1.133, lng: 108.129 },
    { lat: -1.412, lng: 107.04 },
    { lat: -1.314, lng: 105.897 },
    { lat: -1.132, lng: 105.142 },
    { lat: -0.933, lng: 104.759 },
    { lat: -0.065, lng: 103.626 },
    { lat: 0.0, lng: 103.549 },
    { lat: 1.11, lng: 102.497 },
    { lat: 1.131, lng: 102.463 },
    { lat: 2.017, lng: 101.371 },
    { lat: 2.261, lng: 101.227 },
    { lat: 2.458, lng: 101.373 },
    { lat: 2.667, lng: 102.502 },
    { lat: 2.839, lng: 103.634 },
    { lat: 2.622, lng: 104.766 },
    { lat: 2.264, lng: 105.29 },
  ],
  c4: [
    { lat: 1.259, lng: 116.461 },
    { lat: 1.142, lng: 116.532 },
    { lat: 0.707, lng: 117.684 },
    { lat: 0.0, lng: 118.407 },
    { lat: -0.756, lng: 118.927 },
    { lat: -1.146, lng: 119.112 },
    { lat: -1.393, lng: 118.933 },
    { lat: -2.219, lng: 117.701 },
    { lat: -1.88, lng: 116.467 },
    { lat: -1.552, lng: 115.249 },
    { lat: -1.325, lng: 114.045 },
    { lat: -1.138, lng: 112.981 },
    { lat: -1.092, lng: 112.854 },
    { lat: -0.088, lng: 111.671 },
    { lat: 0.0, lng: 111.622 },
    { lat: 0.132, lng: 111.671 },
    { lat: 1.137, lng: 112.096 },
    { lat: 1.958, lng: 112.861 },
    { lat: 2.086, lng: 114.053 },
    { lat: 1.792, lng: 115.252 },
    { lat: 1.259, lng: 116.461 },
  ],
  c5: [
    { lat: 6.805, lng: 100.983 },
    { lat: 6.664, lng: 101.396 },
    { lat: 5.845, lng: 102.524 },
    { lat: 5.667, lng: 102.749 },
    { lat: 5.063, lng: 102.516 },
    { lat: 4.529, lng: 102.069 },
    { lat: 4.282, lng: 101.381 },
    { lat: 3.657, lng: 100.25 },
    { lat: 3.393, lng: 100.067 },
    { lat: 2.26, lng: 99.729 },
    { lat: 1.13, lng: 100.141 },
    { lat: 0.978, lng: 100.245 },
    { lat: 0.0, lng: 101.222 },
    { lat: -0.171, lng: 101.369 },
    { lat: -0.942, lng: 102.496 },
    { lat: -1.131, lng: 102.713 },
    { lat: -1.66, lng: 103.627 },
    { lat: -2.067, lng: 104.763 },
    { lat: -2.264, lng: 105.429 },
    { lat: -2.325, lng: 105.903 },
    { lat: -2.521, lng: 107.047 },
    { lat: -2.772, lng: 108.199 },
    { lat: -2.786, lng: 109.355 },
    { lat: -2.394, lng: 110.514 },
    { lat: -2.272, lng: 111.027 },
    { lat: -2.006, lng: 111.681 },
    { lat: -2.009, lng: 112.861 },
    { lat: -2.278, lng: 113.904 },
    { lat: -2.306, lng: 114.056 },
    { lat: -2.662, lng: 115.264 },
    { lat: -3.01, lng: 116.486 },
    { lat: -3.141, lng: 117.719 },
    { lat: -2.908, lng: 118.958 },
    { lat: -2.482, lng: 120.21 },
    { lat: -2.297, lng: 120.689 },
    { lat: -1.149, lng: 121.377 },
    { lat: 0.0, lng: 120.878 },
    { lat: 1.147, lng: 120.25 },
    { lat: 2.007, lng: 121.479 },
    { lat: 2.301, lng: 121.921 },
    { lat: 2.454, lng: 122.786 },
    { lat: 2.694, lng: 124.112 },
    { lat: 2.653, lng: 125.455 },
    { lat: 2.389, lng: 126.818 },
    { lat: 2.319, lng: 127.083 },
    { lat: 1.636, lng: 128.197 },
    { lat: 1.164, lng: 129.336 },
    { lat: 0.856, lng: 129.613 },
    { lat: 0.5, lng: 131.073 },
    { lat: 0.505, lng: 132.574 },
    { lat: 0.354, lng: 134.118 },
    { lat: 0.0, lng: 135.636 },
    { lat: -0.017, lng: 135.71 },
    { lat: -0.51, lng: 137.359 },
    { lat: -1.186, lng: 138.604 },
    { lat: -1.592, lng: 139.091 },
    { lat: -2.378, lng: 139.853 },
    { lat: -3.574, lng: 140.433 },
    { lat: -4.769, lng: 140.53 },
    { lat: -5.964, lng: 140.301 },
    { lat: -7.162, lng: 140.135 },
    { lat: -8.366, lng: 140.101 },
    { lat: -9.143, lng: 139.872 },
    { lat: -9.566, lng: 139.654 },
    { lat: -10.434, lng: 138.342 },
    { lat: -10.723, lng: 137.17 },
    { lat: -10.832, lng: 136.705 },
    { lat: -10.694, lng: 135.877 },
    { lat: -10.401, lng: 134.976 },
    { lat: -9.449, lng: 133.737 },
    { lat: -9.178, lng: 133.198 },
    { lat: -9.427, lng: 132.574 },
    { lat: -9.655, lng: 131.724 },
    { lat: -10.325, lng: 130.313 },
    { lat: -10.564, lng: 129.51 },
    { lat: -10.734, lng: 128.899 },
    { lat: -10.982, lng: 127.493 },
    { lat: -11.306, lng: 126.124 },
    { lat: -11.669, lng: 124.836 },
    { lat: -11.682, lng: 124.786 },
    { lat: -12.005, lng: 123.464 },
    { lat: -12.244, lng: 122.151 },
    { lat: -12.438, lng: 120.852 },
    { lat: -12.536, lng: 119.561 },
    { lat: -12.479, lng: 118.271 },
    { lat: -12.271, lng: 116.984 },
    { lat: -11.934, lng: 115.706 },
    { lat: -11.571, lng: 114.444 },
    { lat: -11.509, lng: 114.171 },
    { lat: -11.309, lng: 113.205 },
    { lat: -11.158, lng: 111.987 },
    { lat: -11.01, lng: 110.781 },
    { lat: -10.78, lng: 109.581 },
    { lat: -10.543, lng: 108.392 },
    { lat: -10.302, lng: 107.212 },
    { lat: -10.28, lng: 107.11 },
    { lat: -10.058, lng: 106.04 },
    { lat: -9.695, lng: 104.873 },
    { lat: -9.107, lng: 103.782 },
    { lat: -9.061, lng: 103.708 },
    { lat: -8.058, lng: 102.548 },
    { lat: -7.953, lng: 102.443 },
    { lat: -6.806, lng: 101.63 },
    { lat: -6.249, lng: 101.393 },
    { lat: -5.665, lng: 100.959 },
    { lat: -4.567, lng: 100.254 },
    { lat: -4.527, lng: 100.192 },
    { lat: -3.78, lng: 99.125 },
    { lat: -3.393, lng: 98.71 },
    { lat: -2.569, lng: 98.0 },
    { lat: -2.259, lng: 97.858 },
    { lat: -1.129, lng: 97.363 },
    { lat: -0.219, lng: 96.878 },
    { lat: -0.0, lng: 96.784 },
    { lat: 1.129, lng: 96.227 },
    { lat: 1.852, lng: 95.754 },
    { lat: 2.261, lng: 95.489 },
    { lat: 3.393, lng: 94.873 },
    { lat: 4.081, lng: 94.621 },
    { lat: 4.528, lng: 94.491 },
    { lat: 5.665, lng: 94.534 },
    { lat: 5.819, lng: 94.61 },
    { lat: 6.805, lng: 95.41 },
    { lat: 7.015, lng: 95.735 },
    { lat: 7.322, lng: 96.867 },
    { lat: 7.19, lng: 98.0 },
    { lat: 7.022, lng: 99.132 },
    { lat: 6.895, lng: 100.264 },
    { lat: 6.805, lng: 100.983 },
  ],
  c6: [
    { lat: -1.162, lng: 128.63 },
    { lat: -2.324, lng: 128.304 },
    { lat: -2.442, lng: 128.217 },
    { lat: -3.48, lng: 127.02 },
    { lat: -3.573, lng: 126.858 },
    { lat: -3.477, lng: 126.352 },
    { lat: -2.313, lng: 125.49 },
    { lat: -1.158, lng: 126.227 },
    { lat: -0.796, lng: 126.788 },
    { lat: -0.628, lng: 128.183 },
    { lat: -1.162, lng: 128.63 },
  ],
  c7: [
    { lat: 2.271, lng: 110.046 },
    { lat: 1.135, lng: 109.938 },
    { lat: 0.834, lng: 109.34 },
    { lat: 1.134, lng: 108.579 },
    { lat: 1.721, lng: 108.19 },
    { lat: 2.268, lng: 108.013 },
    { lat: 2.513, lng: 108.196 },
    { lat: 2.522, lng: 109.352 },
    { lat: 2.271, lng: 110.046 },
  ],
  c8: [
    { lat: 0.0, lng: 132.759 },
    { lat: -0.07, lng: 134.117 },
    { lat: -0.43, lng: 135.711 },
    { lat: -0.94, lng: 137.365 },
    { lat: -1.184, lng: 137.811 },
    { lat: -2.276, lng: 139.116 },
    { lat: -2.375, lng: 139.212 },
    { lat: -3.569, lng: 139.762 },
    { lat: -4.762, lng: 139.788 },
    { lat: -5.954, lng: 139.503 },
    { lat: -6.314, lng: 139.447 },
    { lat: -7.149, lng: 139.296 },
    { lat: -8.348, lng: 139.108 },
    { lat: -9.539, lng: 138.357 },
    { lat: -9.647, lng: 138.196 },
    { lat: -9.889, lng: 136.536 },
    { lat: -9.485, lng: 135.653 },
    { lat: -8.911, lng: 134.744 },
    { lat: -8.245, lng: 133.092 },
    { lat: -8.24, lng: 133.075 },
    { lat: -8.244, lng: 133.069 },
    { lat: -8.965, lng: 131.633 },
    { lat: -9.401, lng: 131.123 },
    { lat: -9.864, lng: 130.25 },
    { lat: -10.32, lng: 128.843 },
    { lat: -10.532, lng: 127.759 },
    { lat: -10.592, lng: 127.442 },
    { lat: -10.942, lng: 126.078 },
    { lat: -11.339, lng: 124.744 },
    { lat: -11.65, lng: 123.807 },
    { lat: -11.743, lng: 123.433 },
    { lat: -12.029, lng: 122.127 },
    { lat: -12.241, lng: 120.831 },
    { lat: -12.339, lng: 119.541 },
    { lat: -12.272, lng: 118.251 },
    { lat: -12.052, lng: 116.965 },
    { lat: -11.701, lng: 115.687 },
    { lat: -11.519, lng: 115.063 },
    { lat: -11.332, lng: 114.427 },
    { lat: -11.083, lng: 113.191 },
    { lat: -10.937, lng: 111.975 },
    { lat: -10.787, lng: 110.769 },
    { lat: -10.549, lng: 109.571 },
    { lat: -10.298, lng: 108.382 },
    { lat: -10.288, lng: 108.33 },
    { lat: -10.05, lng: 107.203 },
    { lat: -9.804, lng: 106.033 },
    { lat: -9.416, lng: 104.867 },
    { lat: -9.109, lng: 104.296 },
    { lat: -8.734, lng: 103.703 },
    { lat: -7.954, lng: 102.811 },
    { lat: -7.583, lng: 102.543 },
    { lat: -6.807, lng: 101.991 },
    { lat: -5.665, lng: 101.532 },
    { lat: -4.795, lng: 101.384 },
    { lat: -4.528, lng: 101.277 },
    { lat: -3.394, lng: 101.289 },
    { lat: -3.279, lng: 101.376 },
    { lat: -2.671, lng: 102.501 },
    { lat: -2.661, lng: 103.632 },
    { lat: -2.717, lng: 104.767 },
    { lat: -2.82, lng: 105.906 },
    { lat: -3.066, lng: 107.052 },
    { lat: -3.403, lng: 108.155 },
    { lat: -3.414, lng: 108.206 },
    { lat: -3.499, lng: 109.364 },
    { lat: -3.407, lng: 109.841 },
    { lat: -3.158, lng: 110.523 },
    { lat: -2.784, lng: 111.69 },
    { lat: -2.748, lng: 112.871 },
    { lat: -3.007, lng: 114.067 },
    { lat: -3.423, lng: 115.043 },
    { lat: -3.506, lng: 115.281 },
    { lat: -3.79, lng: 116.505 },
    { lat: -3.876, lng: 117.738 },
    { lat: -3.697, lng: 118.979 },
    { lat: -3.497, lng: 120.235 },
    { lat: -3.447, lng: 120.744 },
    { lat: -3.257, lng: 121.508 },
    { lat: -3.383, lng: 122.812 },
    { lat: -3.458, lng: 122.969 },
    { lat: -3.736, lng: 124.147 },
    { lat: -4.414, lng: 125.523 },
    { lat: -4.641, lng: 126.715 },
    { lat: -4.661, lng: 126.91 },
    { lat: -4.644, lng: 126.977 },
    { lat: -3.923, lng: 128.275 },
    { lat: -3.49, lng: 128.674 },
    { lat: -2.33, lng: 129.594 },
    { lat: -2.247, lng: 129.641 },
    { lat: -1.166, lng: 130.289 },
    { lat: -0.468, lng: 131.073 },
    { lat: 0.0, lng: 132.496 },
    { lat: 0.01, lng: 132.572 },
    { lat: 0.0, lng: 132.759 },
  ],
  c9: [
    { lat: 5.663, lng: 99.682 },
    { lat: 4.527, lng: 99.376 },
    { lat: 4.062, lng: 99.125 },
    { lat: 3.392, lng: 98.849 },
    { lat: 2.261, lng: 98.532 },
    { lat: 1.13, lng: 98.699 },
    { lat: 0.397, lng: 99.123 },
    { lat: 0.0, lng: 99.332 },
    { lat: -1.129, lng: 100.065 },
    { lat: -2.238, lng: 99.124 },
    { lat: -1.128, lng: 98.335 },
    { lat: -0.689, lng: 98.0 },
    { lat: -0.0, lng: 97.778 },
    { lat: 1.131, lng: 97.268 },
    { lat: 1.532, lng: 96.878 },
    { lat: 2.26, lng: 96.512 },
    { lat: 3.326, lng: 95.75 },
    { lat: 3.393, lng: 95.715 },
    { lat: 4.527, lng: 95.33 },
    { lat: 5.664, lng: 95.441 },
    { lat: 6.02, lng: 95.74 },
    { lat: 6.44, lng: 96.869 },
    { lat: 6.226, lng: 98.0 },
    { lat: 5.872, lng: 99.129 },
    { lat: 5.663, lng: 99.682 },
  ],
  c10: [
    { lat: 1.159, lng: 126.849 },
    { lat: 1.107, lng: 126.792 },
    { lat: 0.0, lng: 125.605 },
    { lat: -0.12, lng: 125.417 },
    { lat: -0.515, lng: 124.077 },
    { lat: 0.0, lng: 123.512 },
    { lat: 1.135, lng: 122.763 },
    { lat: 1.151, lng: 122.752 },
    { lat: 1.159, lng: 122.764 },
    { lat: 2.099, lng: 124.098 },
    { lat: 2.001, lng: 125.439 },
    { lat: 1.189, lng: 126.793 },
    { lat: 1.159, lng: 126.849 },
  ],
  c11: [
    { lat: -1.181, lng: 136.625 },
    { lat: -1.52, lng: 137.377 },
    { lat: -2.371, lng: 138.406 },
    { lat: -3.563, lng: 139.0 },
    { lat: -4.753, lng: 138.96 },
    { lat: -5.942, lng: 138.613 },
    { lat: -7.134, lng: 138.306 },
    { lat: -8.244, lng: 137.967 },
    { lat: -8.322, lng: 137.682 },
    { lat: -8.56, lng: 136.326 },
    { lat: -8.291, lng: 135.896 },
    { lat: -7.591, lng: 134.57 },
    { lat: -7.276, lng: 132.964 },
    { lat: -8.13, lng: 131.532 },
    { lat: -8.219, lng: 131.47 },
    { lat: -9.326, lng: 130.181 },
    { lat: -9.384, lng: 130.1 },
    { lat: -9.87, lng: 128.786 },
    { lat: -10.173, lng: 127.39 },
    { lat: -10.503, lng: 126.152 },
    { lat: -10.536, lng: 126.029 },
    { lat: -10.954, lng: 124.699 },
    { lat: -11.411, lng: 123.394 },
    { lat: -11.632, lng: 122.765 },
    { lat: -11.787, lng: 122.1 },
    { lat: -12.02, lng: 120.807 },
    { lat: -12.119, lng: 119.518 },
    { lat: -12.039, lng: 118.229 },
    { lat: -11.806, lng: 116.944 },
    { lat: -11.531, lng: 115.969 },
    { lat: -11.434, lng: 115.667 },
    { lat: -11.07, lng: 114.409 },
    { lat: -10.829, lng: 113.175 },
    { lat: -10.689, lng: 111.961 },
    { lat: -10.537, lng: 110.757 },
    { lat: -10.296, lng: 109.583 },
    { lat: -10.291, lng: 109.56 },
    { lat: -10.017, lng: 108.372 },
    { lat: -9.769, lng: 107.194 },
    { lat: -9.518, lng: 106.025 },
    { lat: -9.111, lng: 104.881 },
    { lat: -9.102, lng: 104.859 },
    { lat: -8.368, lng: 103.696 },
    { lat: -7.955, lng: 103.224 },
    { lat: -7.004, lng: 102.536 },
    { lat: -6.807, lng: 102.396 },
    { lat: -5.666, lng: 102.032 },
    { lat: -4.529, lng: 102.145 },
    { lat: -4.007, lng: 102.509 },
    { lat: -3.472, lng: 103.638 },
    { lat: -3.397, lng: 104.251 },
    { lat: -3.331, lng: 104.772 },
    { lat: -3.376, lng: 105.911 },
    { lat: -3.399, lng: 106.007 },
    { lat: -3.571, lng: 107.058 },
    { lat: -3.824, lng: 108.212 },
    { lat: -3.955, lng: 109.371 },
    { lat: -3.752, lng: 110.533 },
    { lat: -3.474, lng: 111.701 },
    { lat: -3.454, lng: 112.883 },
    { lat: -3.692, lng: 114.081 },
    { lat: -4.153, lng: 115.297 },
    { lat: -4.484, lng: 116.524 },
    { lat: -4.582, lng: 117.721 },
    { lat: -4.584, lng: 117.76 },
    { lat: -4.582, lng: 117.773 },
    { lat: -4.331, lng: 118.999 },
    { lat: -4.112, lng: 120.255 },
    { lat: -4.051, lng: 121.535 },
    { lat: -4.311, lng: 122.847 },
    { lat: -4.619, lng: 123.624 },
    { lat: -4.748, lng: 124.19 },
    { lat: -5.073, lng: 125.557 },
    { lat: -5.289, lng: 126.946 },
    { lat: -5.063, lng: 128.338 },
    { lat: -4.66, lng: 128.978 },
    { lat: -3.832, lng: 129.704 },
    { lat: -3.498, lng: 129.944 },
    { lat: -2.335, lng: 130.768 },
    { lat: -1.898, lng: 131.096 },
    { lat: -1.169, lng: 131.731 },
    { lat: -0.716, lng: 132.576 },
    { lat: -0.603, lng: 134.12 },
    { lat: -0.894, lng: 135.716 },
    { lat: -1.181, lng: 136.625 },
  ],
  c12: [
    { lat: -2.366, lng: 137.503 },
    { lat: -3.555, lng: 138.073 },
    { lat: -4.743, lng: 138.031 },
    { lat: -5.849, lng: 137.662 },
    { lat: -5.929, lng: 137.562 },
    { lat: -6.815, lng: 136.098 },
    { lat: -6.345, lng: 134.433 },
    { lat: -6.052, lng: 132.842 },
    { lat: -6.237, lng: 131.341 },
    { lat: -7.028, lng: 130.864 },
    { lat: -8.202, lng: 130.32 },
    { lat: -8.414, lng: 130.073 },
    { lat: -9.361, lng: 128.739 },
    { lat: -9.366, lng: 128.725 },
    { lat: -9.707, lng: 127.335 },
    { lat: -10.078, lng: 125.976 },
    { lat: -10.48, lng: 124.788 },
    { lat: -10.524, lng: 124.65 },
    { lat: -11.018, lng: 123.35 },
    { lat: -11.483, lng: 122.066 },
    { lat: -11.613, lng: 121.613 },
    { lat: -11.772, lng: 120.781 },
    { lat: -11.871, lng: 119.494 },
    { lat: -11.779, lng: 118.205 },
    { lat: -11.544, lng: 116.984 },
    { lat: -11.528, lng: 116.921 },
    { lat: -11.122, lng: 115.644 },
    { lat: -10.775, lng: 114.389 },
    { lat: -10.545, lng: 113.158 },
    { lat: -10.412, lng: 111.946 },
    { lat: -10.309, lng: 111.145 },
    { lat: -10.249, lng: 110.743 },
    { lat: -9.973, lng: 109.546 },
    { lat: -9.702, lng: 108.36 },
    { lat: -9.455, lng: 107.184 },
    { lat: -9.198, lng: 106.016 },
    { lat: -9.115, lng: 105.782 },
    { lat: -8.724, lng: 104.851 },
    { lat: -7.957, lng: 103.689 },
    { lat: -7.956, lng: 103.688 },
    { lat: -6.809, lng: 102.851 },
    { lat: -5.667, lng: 102.594 },
    { lat: -4.53, lng: 103.085 },
    { lat: -4.135, lng: 103.642 },
    { lat: -3.83, lng: 104.775 },
    { lat: -3.804, lng: 105.916 },
    { lat: -3.994, lng: 107.063 },
    { lat: -4.284, lng: 108.218 },
    { lat: -4.466, lng: 109.38 },
    { lat: -4.295, lng: 110.543 },
    { lat: -4.024, lng: 111.712 },
    { lat: -4.03, lng: 112.895 },
    { lat: -4.336, lng: 114.096 },
    { lat: -4.566, lng: 114.646 },
    { lat: -4.817, lng: 115.316 },
    { lat: -5.129, lng: 116.546 },
    { lat: -5.198, lng: 117.783 },
    { lat: -4.965, lng: 119.023 },
    { lat: -4.76, lng: 120.28 },
    { lat: -4.754, lng: 121.563 },
    { lat: -4.98, lng: 122.876 },
    { lat: -5.313, lng: 124.22 },
    { lat: -5.674, lng: 125.592 },
    { lat: -5.802, lng: 126.201 },
    { lat: -5.985, lng: 126.992 },
    { lat: -6.092, lng: 128.409 },
    { lat: -5.838, lng: 129.748 },
    { lat: -5.805, lng: 129.828 },
    { lat: -4.675, lng: 130.83 },
    { lat: -4.005, lng: 131.182 },
    { lat: -3.508, lng: 131.367 },
    { lat: -2.34, lng: 132.021 },
    { lat: -1.738, lng: 132.595 },
    { lat: -1.217, lng: 134.128 },
    { lat: -1.533, lng: 135.729 },
    { lat: -2.283, lng: 137.403 },
    { lat: -2.366, lng: 137.503 },
  ],
  c13: [
    { lat: -4.539, lng: 107.33 },
    { lat: -4.788, lng: 108.227 },
    { lat: -4.974, lng: 109.39 },
    { lat: -4.85, lng: 110.554 },
    { lat: -4.63, lng: 111.725 },
    { lat: -4.661, lng: 112.91 },
    { lat: -4.969, lng: 114.113 },
    { lat: -5.468, lng: 115.337 },
    { lat: -5.722, lng: 116.214 },
    { lat: -5.822, lng: 116.572 },
    { lat: -5.879, lng: 117.811 },
    { lat: -5.738, lng: 118.511 },
    { lat: -5.628, lng: 119.051 },
    { lat: -5.378, lng: 120.306 },
    { lat: -5.343, lng: 121.59 },
    { lat: -5.574, lng: 122.907 },
    { lat: -5.779, lng: 123.665 },
    { lat: -5.939, lng: 124.256 },
    { lat: -6.31, lng: 125.634 },
    { lat: -6.738, lng: 127.048 },
    { lat: -6.991, lng: 127.949 },
    { lat: -7.823, lng: 128.559 },
    { lat: -8.178, lng: 128.73 },
    { lat: -8.267, lng: 128.603 },
    { lat: -9.045, lng: 127.262 },
    { lat: -9.329, lng: 126.767 },
    { lat: -9.565, lng: 125.92 },
    { lat: -9.984, lng: 124.592 },
    { lat: -10.461, lng: 123.604 },
    { lat: -10.577, lng: 123.303 },
    { lat: -11.08, lng: 122.024 },
    { lat: -11.449, lng: 120.748 },
    { lat: -11.581, lng: 119.632 },
    { lat: -11.595, lng: 119.467 },
    { lat: -11.575, lng: 119.231 },
    { lat: -11.463, lng: 118.177 },
    { lat: -11.151, lng: 116.89 },
    { lat: -10.774, lng: 115.618 },
    { lat: -10.446, lng: 114.368 },
    { lat: -10.333, lng: 113.744 },
    { lat: -10.202, lng: 113.138 },
    { lat: -10.059, lng: 111.927 },
    { lat: -9.885, lng: 110.726 },
    { lat: -9.616, lng: 109.531 },
    { lat: -9.349, lng: 108.347 },
    { lat: -9.122, lng: 107.267 },
    { lat: -9.097, lng: 107.173 },
    { lat: -8.791, lng: 106.005 },
    { lat: -8.301, lng: 104.842 },
    { lat: -7.958, lng: 104.323 },
    { lat: -7.242, lng: 103.678 },
    { lat: -6.809, lng: 103.362 },
    { lat: -5.668, lng: 103.231 },
    { lat: -5.0, lng: 103.65 },
    { lat: -4.532, lng: 104.336 },
    { lat: -4.369, lng: 104.781 },
    { lat: -4.276, lng: 105.921 },
    { lat: -4.468, lng: 107.069 },
    { lat: -4.539, lng: 107.33 },
  ],
  c14: [
    { lat: -2.353, lng: 134.807 },
    { lat: -2.514, lng: 135.762 },
    { lat: -3.544, lng: 136.621 },
    { lat: -4.726, lng: 136.291 },
    { lat: -5.022, lng: 135.92 },
    { lat: -4.817, lng: 134.298 },
    { lat: -4.704, lng: 134.016 },
    { lat: -3.52, lng: 133.207 },
    { lat: -2.349, lng: 134.053 },
    { lat: -2.304, lng: 134.158 },
    { lat: -2.353, lng: 134.807 },
  ],
  c15: [
    { lat: -5.711, lng: 114.313 },
    { lat: -6.179, lng: 115.364 },
    { lat: -6.532, lng: 116.603 },
    { lat: -6.61, lng: 117.845 },
    { lat: -6.384, lng: 119.087 },
    { lat: -6.103, lng: 120.342 },
    { lat: -6.027, lng: 121.625 },
    { lat: -6.246, lng: 122.945 },
    { lat: -6.615, lng: 124.3 },
    { lat: -6.963, lng: 125.508 },
    { lat: -7.11, lng: 125.693 },
    { lat: -8.15, lng: 126.759 },
    { lat: -8.694, lng: 125.831 },
    { lat: -9.299, lng: 124.773 },
    { lat: -9.374, lng: 124.529 },
    { lat: -9.933, lng: 123.237 },
    { lat: -10.443, lng: 122.438 },
    { lat: -10.629, lng: 121.978 },
    { lat: -11.005, lng: 120.704 },
    { lat: -11.162, lng: 119.427 },
    { lat: -11.032, lng: 118.14 },
    { lat: -10.728, lng: 116.858 },
    { lat: -10.383, lng: 115.591 },
    { lat: -10.351, lng: 115.468 },
    { lat: -9.979, lng: 114.339 },
    { lat: -9.76, lng: 113.113 },
    { lat: -9.644, lng: 111.906 },
    { lat: -9.477, lng: 110.707 },
    { lat: -9.217, lng: 109.515 },
    { lat: -9.133, lng: 109.141 },
    { lat: -8.863, lng: 108.33 },
    { lat: -8.59, lng: 107.158 },
    { lat: -8.319, lng: 105.994 },
    { lat: -7.961, lng: 105.148 },
    { lat: -7.712, lng: 104.831 },
    { lat: -6.811, lng: 104.027 },
    { lat: -5.67, lng: 104.043 },
    { lat: -5.08, lng: 104.789 },
    { lat: -4.85, lng: 105.928 },
    { lat: -5.029, lng: 107.078 },
    { lat: -5.338, lng: 108.237 },
    { lat: -5.532, lng: 109.402 },
    { lat: -5.428, lng: 110.568 },
    { lat: -5.232, lng: 111.739 },
    { lat: -5.284, lng: 112.927 },
    { lat: -5.635, lng: 114.135 },
    { lat: -5.711, lng: 114.313 },
  ],
};
export var cns11_gntgain = {
  c1: "1",
  c2: "2",
  c3: "2",
  c4: "2",
  c5: "3",
  c6: "3",
  c7: "3",
  c8: "4",
  c9: "4",
  c10: "4",
  c11: "5",
  c12: "6",
  c13: "7",
  c14: "7",
  c15: "8",
};
