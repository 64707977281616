import { useManifestStore } from '@/stores';
import {
  LMap,
  LPopup,
  LControl,
  LTileLayer,
  LControlZoom,
  LGeoJson,
  LPolygon,

} from "vue2-leaflet";
import LControlFullscreen from "vue2-leaflet-fullscreen";
import LControlPolylineMeasure from "vue2-leaflet-polyline-measure";
import Vue2LeafletRotatedMarker from "vue2-leaflet-rotatedmarker";


export default {
  name: "plc_ShowMapSailink",
  components:{
    LMap,
    LTileLayer,
    LPopup,
    LControlZoom,
    LControl,
    LGeoJson,
    LPolygon,
    LControlPolylineMeasure,
    LControlFullscreen,
    "v-rotated-marker": Vue2LeafletRotatedMarker,
  },
  data() {
    return {
      manifest_store : useManifestStore(),
    //   mapStore: useMapStore(),
    }
  },

  mounted() {
  },

  methods: {
  },

  watch: {
  },
  computed: {
  }
};