import Vue from "vue";
import { useAuthStore } from '@/stores';
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import LoginView from "@/views/LoginView.vue";
import DashboardView from "@/views/DashboardView.vue";
import OutsideLogin from "@/views/OutsideLogin.vue";


Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "home",
        component: HomeView,
        meta: {
        
            title: "SAILINK :: All in One Maritime Solutions",
            icon: "/sailink.ico",
 
        },
    },

    {
        path: "/login",
        name: "login",
        component: LoginView,
        meta: {
            title: "SAILINK :: Login",
            icon: "/sailink.ico",
        },
    },

    {
        path: "/dashboard",
        name: "dashboard",
        component: DashboardView,
        meta: {
            title: "Navigatorplus :: Dashboard",
            icon: "/favicon.ico",
        },
    },

    {
        path: "/outsidelogin/:token",
        name: "outsidelogin",
        component: OutsideLogin,
        props: true,
        meta: {
            title: "Login",
            navbar: false,
        },
    },
   
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});


router.beforeEach((to, form, next) => {
    const auth = useAuthStore();
    if (to.name !== "login" && to.name !== "home" && to.name !== "privacy" && to.name !== "outsidelogin" && !auth.user) {
        next({name: "home"});
    }else if(to.name == "login" && auth.user){
        next({name: "home"});
    }else next();
});
export default router;
