import { useManifestStore} from '@/stores';
import ManifestReport from '@/views/showonmap/ShowManifest_view.vue';

export default {
  name: "Manifest",
  components: {
    ManifestReport
  },
  data() {
    return {
      manifest_store: useManifestStore(),
      ShowManifestComp : ManifestReport,
    }
  },

  mounted() {
    this.manifest_store.fetch_manifest()
  },

  methods: {
  },

  watch: {
  },
  computed: {
  }
};