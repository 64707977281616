import { defineStore } from 'pinia';
import api from "@/plugins/axios";
import { useAuthStore, useIridiumStore, useSailinkStore, useThurayaStore } from '../index';
import $ from 'jquery'
import pusher from '@/plugins/pusher';
import DataTable from 'datatables.net-dt';



export const useNotifStore = defineStore({
    id: 'notificatin',
    state: () => ({
        auth_store:useAuthStore(),
        sailink_store:useSailinkStore(),
        thuraya_store:useThurayaStore(),
        iridium_store:useIridiumStore(),
        notification:null,
        notification_detail:null,
        channel_notification: "navplus.notification.",
        event_notification: "NotificationReceived",
        notifcenter_table:null,
        search_notif:'',
        page_off: 10,
        page_off_arr: [
            10,
            25,
            50,
            100
        ],
        th_notif: [
            "",
            "",
        ],
        td_notif: [
            0,
            0,
        ],
        notif_type:'',
        notif_modal_id:'#sailinkspeedHistoryModal',
        notif_arr:[],
        notification_type:'',
    }),
    actions: {



        //DATA FETCH FUNCTIONS 
        //------------------------------------------------------------------
        //Fetch User Allowed
        async fetch_notification() {
            console.log("FETCH NOTIFICATION");
            console.log(localStorage.getItem("token"));

            if (this.notifcenter_table) {
                this.notifcenter_table.clear().draw();
                $('#notifCenterTable').DataTable().destroy();
                this.notifcenter_table = null
            }
            
            await api.get("/api/v1/navplus/notification/get", {
            }).then((res) => {
                console.log("FETCH NOTIFICATION");
                console.log(res);
                this.notification = res.data.data

                setTimeout(() => {
                    this.initTable_notif_center(res.data.data.notifications)
                }, 1000);

            }).catch((err) => {
            }).then(() => {
            });



        },
        async fetch_read(item) {
            console.log("READ NOTIFICATION");
            
            await api.get("/api/v1/navplus/notification/read/"+item.id, {
            }).then((res) => {
                console.log("READ SUCCESS");
                console.log(res);
            }).catch((err) => {
            }).then(() => {
            });



        },
        async fetch_show(item) {
            console.log("SHOW NOTIFICATION");
            console.log(item.msg_id);
            
            await api.get("/api/v1/navplus/notification/show/"+item.id, {
            }).then((res) => {
                console.log("SHOW SUCCESS");
                console.log(res);
                this.notification_detail = res.data.data
            }).catch((err) => {
            }).then(() => {
            });



        },
        //------------------------------------------------------------------


        //WEBSOCKET
        //------------------------------------------------------------------
        subscribe_websocket_notif(){
            var mom = this;
            pusher.subscribe(mom.channel_notification + mom.auth_store.user.user.id)
                            .bind('pusher:subscription_succeeded', function () {
                                console.log('Notification Websocket subscription for user id :');
                                console.log(mom.auth_store.user.user.id);
                            })
                            .bind("App\\Events\\" + mom.event_notification, (data) => {
                                console.log(" Notification - Update ")
                                console.log(data);
                                if (useAuthStore.user != null) {
                                    mom.fetch_notification()
                                }

                            });
        },
        //------------------------------------------------------------------

        //DATA POST FUNCTIONS
        //------------------------------------------------------------------
        //Post Edit Profile
        async post_profile() {

            console.log("POST UPDATE PROFILE");

            await api.get(`/api/v1/navplus/user/edit/` + this.user.user.id, {
                name: this.auth_updateprofile.name,
                username: this.auth_updateprofile.username,
            }
            ).then((res) => {
                console.log("POST UPDATE PROFILE");
                console.log(res);

                if (res.status == 200) {
                    $('#profile_updateProfileModal_alert').removeClass('d-none')
                    $('#profile_updateProfileModal_alert').addClass('d-block')
                    $('#profile_updateProfileModal_alert').html('Profile update!')

                    setTimeout(() => {
                        $('#profile_updateProfileModal_alert').removeClass('d-block')
                        $('#profile_updateProfileModal_alert').addClass('d-none')
                        $('#profile_updateProfileModal_alert').html('')
                    }, 1500);

                    this.auth_updateprofile = {
                        name: "",
                        username: "",
                        email: "",
                    },
                        this.auth_updateprofile_data = null

                    this.fetch_edit_profile(this.user.user.id);

                } else {
                    $('#profile_updateProfileModal_alert').removeClass('alert-success')
                    $('#profile_updateProfileModal_alert').addClass('alert-danger')
                    $('#profile_updateProfileModal_alert').removeClass('d-none')
                    $('#profile_updateProfileModal_alert').addClass('d-block')
                    $('#profile_updateProfileModal_alert').html('Something wrong, cannot update!')

                    setTimeout(() => {
                        $('#profile_updateProfileModal_alert').addClass('alert-success')
                        $('#profile_updateProfileModal_alert').removeClass('alert-danger')
                        $('#profile_updateProfileModal_alert').removeClass('d-block')
                        $('#profile_updateProfileModal_alert').addClass('d-none')
                        $('#profile_updateProfileModal_alert').html('')
                    }, 1500);
                }

            }).catch((err) => {
                $('#profile_updateProfileModal_alert').removeClass('alert-success')
                $('#profile_updateProfileModal_alert').addClass('alert-danger')
                $('#profile_updateProfileModal_alert').removeClass('d-none')
                $('#profile_updateProfileModal_alert').addClass('d-block')
                $('#profile_updateProfileModal_alert').html('Something wrong, cannot update!')

                setTimeout(() => {
                    $('#profile_updateProfileModal_alert').addClass('alert-success')
                    $('#profile_updateProfileModal_alert').removeClass('alert-danger')
                    $('#profile_updateProfileModal_alert').removeClass('d-block')
                    $('#profile_updateProfileModal_alert').addClass('d-none')
                    $('#profile_updateProfileModal_alert').html('')
                }, 1500);
            }).then(() => {
            });
        },
        //------------------------------------------------------------------



        //TABLE SEARCH & PAGE FUNCTIONS
        //------------------------------------------------------------------
        //search reports table
        notifTable_search() {
            if (this.search_notif != null && this.search_notif.length > 0) {
                this.notifcenter_table.search(this.search_notif).draw();
            }
        },
        //data shorting reports table 
        notifTable_showof(page) {
            console.log("PAGE : " + page);
            this.notifcenter_table.page.len(page).draw()
            this.page_off = page;
        },
                //clear reports table
                clear_notifTable_search() {
                    this.search_notif = ''
                    this.notifcenter_table.search(this.search_notif).draw();
                },
         //------------------------------------------------------------------



        // ONCLICK 
        //------------------------------------------------------------------
        onclick_notification(data,index){
            this.clear_notifTable_search()
            this.close_notifcenter()
            $("#check_notif_center_"+data.msg_id).prop('checked', false);
            $("#check_notif_"+data.msg_id).prop('checked', false);
            var mom = this;
            if(this.notification.notifications[index].unRead){
                this.notification.notifications[index].unRead = false;
            this.notification.new_notification = this.notification.new_notification-1;
            }
            this.notification_detail = null
            this.notification_type = data.type
            this.fetch_read(data)
            setTimeout(() => {
                mom.fetch_show(data)
            }, 500);
            
        },
        onclick_notif_speedhistory(index,type){
            var mom = this;
            this.notif_type = type;
            
            if(type == 'sailink'){
                this.notif_modal_id = '#sailinkspeedHistoryModal'
                if(mom.sailink_store.speedhistory_data.notifications[index].unRead){
                    mom.sailink_store.speedhistory_data.notifications[index].unRead = false;
                }
                mom.notification_detail = null
                mom.fetch_read(mom.sailink_store.speedhistory_data.notifications[index])
                this.notification_type = mom.sailink_store.speedhistory_data.notifications[index].type
                setTimeout(() => {
                    mom.fetch_show(mom.sailink_store.speedhistory_data.notifications[index])
                }, 500);
            }else if(type == 'thuraya'){
                this.notif_modal_id = '#thurayaspeedHistoryModal'
                if(mom.thuraya_store.speedhistory_data.notifications[index].unRead){
                    mom.thuraya_store.speedhistory_data.notifications[index].unRead = false;
                }
                mom.notification_detail = null
                mom.fetch_read(mom.thuraya_store.speedhistory_data.notifications[index])
                this.notification_type = mom.thuraya_store.speedhistory_data.notifications[index].type
                setTimeout(() => {
                    mom.fetch_show(mom.thuraya_store.speedhistory_data.notifications[index])
                }, 500);
            }else if(type == 'iridium'){
                this.notif_modal_id = '#iridiumspeedHistoryModal'
                console.log(mom.iridium_store.speedhistory_data.notifications[index]);
                
                if(mom.iridium_store.speedhistory_data.notifications[index].unRead){
                    mom.iridium_store.speedhistory_data.notifications[index].unRead = false;
                }
                mom.notification_detail = null
                mom.fetch_read(mom.iridium_store.speedhistory_data.notifications[index])
                this.notification_type = mom.iridium_store.speedhistory_data.notifications[index].type
                setTimeout(() => {
                    mom.fetch_show(mom.iridium_store.speedhistory_data.notifications[index])
                }, 500);
            }

         
            
        },


        reinit_speedhistory(){
            if(this.notif_type == 'sailink'){
                this.sailink_store.speedhistory_table.clear();
                this.sailink_store.speedhistory_table = null;
                this.sailink_store.initTable_history(this.sailink_store.speedhistory_data.notifications);
            }else if(this.notif_type == 'thuraya'){
                this.thuraya_store.speedhistory_table.clear();
                this.thuraya_store.speedhistory_table = null;
                this.thuraya_store.initTable_history(this.thuraya_store.speedhistory_data.notifications);
            }else if(this.notif_type == 'iridium'){
                this.iridium_store.speedhistory_table.clear();
                this.iridium_store.speedhistory_table = null;
                this.iridium_store.initTable_history(this.iridium_store.speedhistory_data.notifications);
            }
           
        },
        //------------------------------------------------------------------



        //INITIALIZE TABLE
        //------------------------------------------------------------------
        initTable_notif_center(dataNotif) {
            var root = this
            console.log("INIT TABLE NOTIFICATION");
            console.log(this.notifcenter_table);
            console.log("MASUK INIT TABLE notification");
            console.log(dataNotif);


            try {
                if (dataNotif) {
                    this.notifcenter_table = new DataTable('#notifCenterTable', {
                        data: dataNotif,
                        scrollCollapse: true,
                        scroller: true,
                        destroy: true,
                        pageLength: 5,
                        deferRender: true,
                        ordering: true,
                        bLengthChange: false,
                        responsive: true,
                        dom: 'lrtip',
                        columns: [
                            {
                                width: "5%",
                                data: 'msg_id', className: 'td_nowrap', render: (data, type, row, meta) => {
                                    return `
                                    <div class="p-1 h-100 row`+ meta.row + `">
                                    <div class="form-check">
                                    <input ${this.notification.notifications[meta.row].unRead == 1 ? '' : 'disabled'} onchange="oncheck_notif('${data}','center')" id="check_notif_center_${data}" class="form-check-input" type="checkbox" value="">
                                    </div>
                                    </div>
                                    `
                                }
                            },
                            {
                                width: "95%",
                                data: 'dateTime_local', className: 'td_nowrap', render: (data, type, row, meta) => {
                                    return `
                                    <div onclick="onclick_notification('${meta.row}','${meta.row}','VIEWALL')" id="notif_btn" class="p-1 h-100 pointer_cursor" data-bs-toggle="modal" data-bs-target="#notification_detailModalonCenter">
                                     <h8 class="${!this.notification.notifications[meta.row].unRead ? 'text-secondary opacity-50' : 'text-dark'}"><b>${(this.notification.notifications[meta.row].type == 1 || this.notification.notifications[meta.row].type == 2 || this.notification.notifications[meta.row].type == 5) ? 'Speed Alert' : (this.notification.notifications[meta.row].type == 3) ?'RPM Alert' : 'Speed Alert'}</b></h8>${this.notification.notifications[meta.row].unRead ? `<i  style="font-size: 0.6em;" class='bx bxs-circle text-danger ms-2'></i>`:``}<br/>
                                    <span class="${!this.notification.notifications[meta.row].unRead ? 'text-secondary opacity-50' : 'text-dark'}" style="font-size: 0.8em;">${this.notification.notifications[meta.row].msg}</span><br/>
                                    <p class="${!this.notification.notifications[meta.row].unRead ? 'text-secondary opacity-50' : 'text-primary'}" style="font-size: 0.8em;" ><b>${this.notification.notifications[meta.row].dateDiff} (${data ? data : '-'})</b></p>
                                    </div>
                                    `
                                }
                            },

                        ]
                    });
                }
            } catch (e) {

            }

    



        },
        //------------------------------------------------------------------

        // UNINITIALIZE SHOW ALL
        //------------------------------------------------------------------
        show_all_init(){

            var mom = this;
            if(this.notif_arr.length>0){
                for (let i = 0; i < mom.notif_arr.length; i++) {          
                    $('#check_notif_'+mom.notif_arr[i]).prop('checked', false);
                }
                this.notif_arr = []
            }
                        
            

            if (this.notifcenter_table) {
                this.notifcenter_table.clear().draw();
                $('#notifCenterTable').DataTable().destroy();
                this.notifcenter_table = null
            }
                setTimeout(() => {
                    this.initTable_notif_center(this.notification.notifications)
                }, 1000);
        },
        async clear_notif(notif_data){

            for (let i = 0; i < notif_data.length; i++) {
                await api.get("api/v1/navplus/notification/read/"+notif_data[i].id, {
                }).then((res) => {
                    console.log("CLEAR NOTIFICATION"+i);
                    console.log(res);
                }).catch((err) => {
                }).then(() => {
                });
            }
            
            
        },
        async clear_selected_notif(notif_data){

            for (let i = 0; i < notif_data.length; i++) {
                await api.get("api/v1/navplus/notification/read/"+notif_data[i].id, {
                }).then((res) => {
                    console.log("CLEAR NOTIFICATION"+i);
                    console.log(res);
                }).catch((err) => {
                }).then(() => {
                });
            }
            
            
        },

        onclear_all_notif(){
            this.clear_notif(this.notification.notifications)
            for (let i = 0; i < this.notification.notifications.length; i++) {
                this.notification.notifications[i].unRead = false
            }
        },
        onclear_selected_notif(){
            var mom = this;
            this.clear_selected_notif(this.notif_arr)
            console.log(mom.notif_arr);
            
            for (let i = 0; i < this.notification.notifications.length; i++) {
                if(mom.notif_arr.includes(mom.notification.notifications[i].msg_id)){
                    mom.notification.notifications[i].unRead = false
                    $('#check_notif_'+mom.notification.notifications[i].msg_id).prop('checked', false);
                    mom.notification.new_notification = mom.notification.new_notification-1;
                }
            }
            this.notif_arr = []
        },

        onclear_selected_notif_center(){
            var mom = this;
            this.clear_selected_notif(this.notif_arr)
            console.log(mom.notif_arr);
            console.log("ONCLEAR SELECTED CENTER");
            console.log(this.notif_arr)
            for (let i = 0; i < this.notification.notifications.length; i++) {
                console.log("THIS IS CHECK");
                if(mom.notif_arr.includes(mom.notification.notifications[i].msg_id.toString())){
                    mom.notification.notifications[i].unRead = false
                    $('#check_notif_center'+mom.notification.notifications[i].msg_id).prop('checked', false);
                    console.log("THIS IS CHECK");
                    
                    console.log(mom.notification.new_notification);
                    
                    mom.notification.new_notification = mom.notification.new_notification-1;
                }
            }
            setTimeout(() => {
                this.initTable_notif_center(this.notification.notifications)
            }, 1000);
            this.notif_arr = []
        },

        
        oncheck_notif(id){
            console.log(id);
            
            if($('#check_notif_'+id).prop('checked')){
                console.log("CHECK NOTIF TRUE")
                this.notif_arr.push(id);
            }else{
                console.log("CHECK NOTIF FALSE")
                this.notif_arr = this.notif_arr.filter(e => e !== id);
            }
        },
        oncheck_notif_center(id){
            console.log(id);
            
            if($('#check_notif_center_'+id).prop('checked')){
                console.log("CHECK NOTIF TRUE")
                this.notif_arr.push(id);
            }else{
                console.log("CHECK NOTIF FALSE")
                this.notif_arr = this.notif_arr.filter(e => e !== id);
            }
        },
        kFormatter(num) {
            return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
        },
        //------------------------------------------------------------------



        // UNINITIALIZE DATA WHEN LOGOUT
        //------------------------------------------------------------------
        //Close
        close_notifcenter(){
            this.notif_arr = [];
        },
        //Uninit Data 
        uninit_data() {
    
        },
    }
});
