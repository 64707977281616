export var ocn = {
  c1: [
    { lat: 13.269, lng: 82.718 },
    { lat: 12.677, lng: 82.74 },
    { lat: 12.634, lng: 82.705 },
    { lat: 12.164, lng: 82.127 },
    { lat: 12.095, lng: 81.996 },
    { lat: 11.95, lng: 81.529 },
    { lat: 12.104, lng: 81.272 },
    { lat: 12.699, lng: 80.987 },
    { lat: 13.29, lng: 81.158 },
    { lat: 13.483, lng: 81.408 },
    { lat: 13.699, lng: 82.009 },
    { lat: 13.416, lng: 82.647 },
    { lat: 13.269, lng: 82.718 },
  ],
  c2: [
    { lat: 25.027, lng: 65.545 },
    { lat: 24.902, lng: 66.056 },
    { lat: 24.403, lng: 67.019 },
    { lat: 24.288, lng: 67.192 },
    { lat: 23.728, lng: 68.01 },
    { lat: 23.585, lng: 68.185 },
    { lat: 22.973, lng: 68.994 },
    { lat: 22.894, lng: 69.092 },
    { lat: 22.215, lng: 69.884 },
    { lat: 22.152, lng: 69.963 },
    { lat: 21.543, lng: 70.644 },
    { lat: 21.282, lng: 70.911 },
    { lat: 20.88, lng: 71.304 },
    { lat: 20.291, lng: 71.854 },
    { lat: 20.225, lng: 71.913 },
    { lat: 19.577, lng: 72.471 },
    { lat: 19.167, lng: 72.789 },
    { lat: 18.935, lng: 72.967 },
    { lat: 18.301, lng: 73.369 },
    { lat: 17.676, lng: 73.751 },
    { lat: 17.671, lng: 73.755 },
    { lat: 17.047, lng: 74.037 },
    { lat: 16.428, lng: 74.294 },
    { lat: 15.812, lng: 74.507 },
    { lat: 15.202, lng: 74.633 },
    { lat: 14.6, lng: 74.536 },
    { lat: 14.231, lng: 74.269 },
    { lat: 14.008, lng: 73.996 },
    { lat: 13.816, lng: 73.655 },
    { lat: 13.621, lng: 73.007 },
    { lat: 13.436, lng: 72.545 },
    { lat: 13.352, lng: 72.364 },
    { lat: 13.206, lng: 71.7 },
    { lat: 13.069, lng: 71.03 },
    { lat: 13.021, lng: 70.341 },
    { lat: 13.022, lng: 69.639 },
    { lat: 13.041, lng: 68.926 },
    { lat: 13.223, lng: 68.18 },
    { lat: 13.369, lng: 67.429 },
    { lat: 13.566, lng: 66.693 },
    { lat: 13.575, lng: 66.658 },
    { lat: 13.901, lng: 65.852 },
    { lat: 14.214, lng: 65.106 },
    { lat: 14.246, lng: 65.028 },
    { lat: 14.713, lng: 64.162 },
    { lat: 14.859, lng: 63.906 },
    { lat: 15.376, lng: 63.23 },
    { lat: 15.498, lng: 63.085 },
    { lat: 16.14, lng: 62.366 },
    { lat: 16.317, lng: 62.198 },
    { lat: 16.78, lng: 61.808 },
    { lat: 17.418, lng: 61.483 },
    { lat: 18.054, lng: 61.282 },
    { lat: 18.692, lng: 61.146 },
    { lat: 19.33, lng: 61.119 },
    { lat: 19.97, lng: 61.103 },
    { lat: 20.029, lng: 61.114 },
    { lat: 20.611, lng: 61.171 },
    { lat: 21.253, lng: 61.307 },
    { lat: 21.762, lng: 61.368 },
    { lat: 21.9, lng: 61.388 },
    { lat: 22.546, lng: 61.599 },
    { lat: 23.05, lng: 61.75 },
    { lat: 23.196, lng: 61.81 },
    { lat: 23.841, lng: 62.223 },
    { lat: 23.913, lng: 62.277 },
    { lat: 24.484, lng: 62.758 },
    { lat: 24.591, lng: 62.867 },
    { lat: 25.013, lng: 63.55 },
    { lat: 25.101, lng: 63.928 },
    { lat: 25.193, lng: 64.317 },
    { lat: 25.123, lng: 65.167 },
    { lat: 25.027, lng: 65.545 },
  ],
  c3: [
    { lat: 26.54, lng: 117.06 },
    { lat: 26.046, lng: 117.634 },
    { lat: 25.886, lng: 117.701 },
    { lat: 25.219, lng: 117.747 },
    { lat: 24.554, lng: 117.673 },
    { lat: 23.892, lng: 117.473 },
    { lat: 23.232, lng: 117.186 },
    { lat: 22.973, lng: 117.061 },
    { lat: 22.576, lng: 116.855 },
    { lat: 21.923, lng: 116.416 },
    { lat: 21.455, lng: 116.151 },
    { lat: 21.275, lng: 116.003 },
    { lat: 20.63, lng: 115.438 },
    { lat: 20.554, lng: 115.366 },
    { lat: 19.987, lng: 114.752 },
    { lat: 19.873, lng: 114.629 },
    { lat: 19.545, lng: 113.948 },
    { lat: 19.479, lng: 113.303 },
    { lat: 19.328, lng: 112.947 },
    { lat: 19.2, lng: 112.639 },
    { lat: 18.9, lng: 111.979 },
    { lat: 18.682, lng: 111.356 },
    { lat: 18.675, lng: 111.332 },
    { lat: 18.329, lng: 110.68 },
    { lat: 18.14, lng: 110.046 },
    { lat: 18.66, lng: 109.808 },
    { lat: 19.277, lng: 109.705 },
    { lat: 19.9, lng: 109.784 },
    { lat: 20.531, lng: 110.103 },
    { lat: 20.828, lng: 110.284 },
    { lat: 21.168, lng: 110.526 },
    { lat: 21.81, lng: 111.014 },
    { lat: 21.816, lng: 111.018 },
    { lat: 22.458, lng: 111.492 },
    { lat: 22.693, lng: 111.758 },
    { lat: 23.115, lng: 112.181 },
    { lat: 23.406, lng: 112.494 },
    { lat: 23.778, lng: 112.868 },
    { lat: 24.166, lng: 113.253 },
    { lat: 24.447, lng: 113.514 },
    { lat: 25.005, lng: 114.041 },
    { lat: 25.122, lng: 114.163 },
    { lat: 25.722, lng: 114.83 },
    { lat: 25.808, lng: 114.932 },
    { lat: 26.318, lng: 115.615 },
    { lat: 26.515, lng: 116.211 },
    { lat: 26.553, lng: 116.347 },
    { lat: 26.548, lng: 117.039 },
    { lat: 26.54, lng: 117.06 },
  ],
  c4: [
    { lat: 21.639, lng: 67.913 },
    { lat: 21.12, lng: 68.736 },
    { lat: 20.959, lng: 68.947 },
    { lat: 20.316, lng: 69.676 },
    { lat: 20.296, lng: 69.696 },
    { lat: 19.643, lng: 70.296 },
    { lat: 19.179, lng: 70.659 },
    { lat: 18.998, lng: 70.787 },
    { lat: 18.362, lng: 71.134 },
    { lat: 17.734, lng: 71.336 },
    { lat: 17.114, lng: 71.376 },
    { lat: 16.501, lng: 71.298 },
    { lat: 16.328, lng: 71.212 },
    { lat: 15.898, lng: 70.894 },
    { lat: 15.722, lng: 70.614 },
    { lat: 15.541, lng: 69.937 },
    { lat: 15.525, lng: 69.225 },
    { lat: 15.585, lng: 68.491 },
    { lat: 15.703, lng: 67.737 },
    { lat: 15.915, lng: 66.954 },
    { lat: 16.008, lng: 66.744 },
    { lat: 16.377, lng: 66.104 },
    { lat: 16.658, lng: 65.681 },
    { lat: 17.08, lng: 65.178 },
    { lat: 17.302, lng: 64.978 },
    { lat: 17.938, lng: 64.634 },
    { lat: 18.577, lng: 64.348 },
    { lat: 19.22, lng: 64.076 },
    { lat: 19.86, lng: 63.975 },
    { lat: 20.497, lng: 64.044 },
    { lat: 20.881, lng: 64.146 },
    { lat: 21.133, lng: 64.25 },
    { lat: 21.717, lng: 64.692 },
    { lat: 21.76, lng: 64.759 },
    { lat: 22.093, lng: 65.374 },
    { lat: 22.151, lng: 66.147 },
    { lat: 22.064, lng: 66.954 },
    { lat: 21.695, lng: 67.827 },
    { lat: 21.639, lng: 67.913 },
  ],
  c5: [
    { lat: 7.975, lng: 108.317 },
    { lat: 7.4, lng: 108.394 },
    { lat: 6.827, lng: 108.455 },
    { lat: 6.254, lng: 108.408 },
    { lat: 5.682, lng: 108.31 },
    { lat: 5.384, lng: 108.238 },
    { lat: 5.111, lng: 108.146 },
    { lat: 4.54, lng: 107.826 },
    { lat: 4.224, lng: 107.641 },
    { lat: 3.97, lng: 107.541 },
    { lat: 3.402, lng: 107.321 },
    { lat: 2.836, lng: 107.05 },
    { lat: 2.833, lng: 107.047 },
    { lat: 2.266, lng: 106.657 },
    { lat: 2.037, lng: 106.471 },
    { lat: 1.698, lng: 106.014 },
    { lat: 1.605, lng: 105.898 },
    { lat: 1.268, lng: 105.328 },
    { lat: 1.235, lng: 104.759 },
    { lat: 1.552, lng: 104.194 },
    { lat: 1.697, lng: 103.839 },
    { lat: 1.794, lng: 103.628 },
    { lat: 2.262, lng: 103.14 },
    { lat: 2.43, lng: 103.065 },
    { lat: 2.828, lng: 102.897 },
    { lat: 3.395, lng: 102.751 },
    { lat: 3.962, lng: 102.808 },
    { lat: 4.376, lng: 103.077 },
    { lat: 4.53, lng: 103.167 },
    { lat: 5.085, lng: 103.651 },
    { lat: 5.1, lng: 103.66 },
    { lat: 5.67, lng: 104.13 },
    { lat: 5.76, lng: 104.228 },
    { lat: 6.241, lng: 104.725 },
    { lat: 6.311, lng: 104.807 },
    { lat: 6.793, lng: 105.387 },
    { lat: 6.815, lng: 105.407 },
    { lat: 7.39, lng: 105.923 },
    { lat: 7.452, lng: 105.974 },
    { lat: 7.921, lng: 106.561 },
    { lat: 7.944, lng: 107.14 },
    { lat: 7.969, lng: 107.188 },
    { lat: 8.249, lng: 107.729 },
    { lat: 8.03, lng: 108.304 },
    { lat: 7.975, lng: 108.317 },
  ],
  c6: [
    { lat: 27.075, lng: 65.391 },
    { lat: 26.548, lng: 66.282 },
    { lat: 26.332, lng: 66.61 },
    { lat: 25.822, lng: 67.342 },
    { lat: 25.61, lng: 67.598 },
    { lat: 24.953, lng: 68.41 },
    { lat: 24.901, lng: 68.465 },
    { lat: 24.203, lng: 69.289 },
    { lat: 24.077, lng: 69.44 },
    { lat: 23.512, lng: 70.103 },
    { lat: 23.227, lng: 70.425 },
    { lat: 22.83, lng: 70.841 },
    { lat: 22.284, lng: 71.4 },
    { lat: 22.157, lng: 71.537 },
    { lat: 21.489, lng: 72.27 },
    { lat: 21.442, lng: 72.317 },
    { lat: 20.829, lng: 72.936 },
    { lat: 20.545, lng: 73.216 },
    { lat: 20.175, lng: 73.594 },
    { lat: 19.611, lng: 74.095 },
    { lat: 19.529, lng: 74.167 },
    { lat: 18.889, lng: 74.695 },
    { lat: 18.518, lng: 74.973 },
    { lat: 18.253, lng: 75.225 },
    { lat: 17.623, lng: 75.707 },
    { lat: 17.497, lng: 75.809 },
    { lat: 16.992, lng: 76.421 },
    { lat: 16.877, lng: 76.563 },
    { lat: 16.502, lng: 77.271 },
    { lat: 16.353, lng: 77.761 },
    { lat: 16.294, lng: 77.949 },
    { lat: 16.181, lng: 78.61 },
    { lat: 16.312, lng: 79.237 },
    { lat: 16.323, lng: 79.306 },
    { lat: 16.393, lng: 79.867 },
    { lat: 16.467, lng: 80.494 },
    { lat: 16.578, lng: 81.115 },
    { lat: 16.693, lng: 81.734 },
    { lat: 16.845, lng: 82.346 },
    { lat: 16.877, lng: 82.447 },
    { lat: 17.027, lng: 82.953 },
    { lat: 17.272, lng: 83.553 },
    { lat: 17.458, lng: 84.158 },
    { lat: 17.461, lng: 84.171 },
    { lat: 17.602, lng: 84.764 },
    { lat: 17.872, lng: 85.359 },
    { lat: 18.04, lng: 85.962 },
    { lat: 18.047, lng: 85.992 },
    { lat: 18.187, lng: 86.566 },
    { lat: 18.395, lng: 87.164 },
    { lat: 18.546, lng: 87.766 },
    { lat: 18.637, lng: 88.003 },
    { lat: 18.789, lng: 88.361 },
    { lat: 18.962, lng: 88.961 },
    { lat: 19.046, lng: 89.565 },
    { lat: 19.145, lng: 90.168 },
    { lat: 19.084, lng: 90.779 },
    { lat: 18.883, lng: 91.395 },
    { lat: 18.636, lng: 92.009 },
    { lat: 18.599, lng: 92.064 },
    { lat: 18.194, lng: 92.627 },
    { lat: 17.981, lng: 92.989 },
    { lat: 17.799, lng: 93.239 },
    { lat: 17.379, lng: 93.847 },
    { lat: 17.366, lng: 93.867 },
    { lat: 16.963, lng: 94.451 },
    { lat: 16.756, lng: 94.781 },
    { lat: 16.506, lng: 95.051 },
    { lat: 16.15, lng: 95.623 },
    { lat: 16.11, lng: 95.647 },
    { lat: 15.55, lng: 95.936 },
    { lat: 15.055, lng: 95.661 },
    { lat: 14.953, lng: 95.566 },
    { lat: 14.721, lng: 95.081 },
    { lat: 14.36, lng: 94.76 },
    { lat: 14.158, lng: 94.507 },
    { lat: 13.77, lng: 93.955 },
    { lat: 13.753, lng: 93.931 },
    { lat: 13.443, lng: 93.355 },
    { lat: 13.183, lng: 92.936 },
    { lat: 13.045, lng: 92.782 },
    { lat: 12.597, lng: 92.356 },
    { lat: 12.448, lng: 92.216 },
    { lat: 12.193, lng: 91.641 },
    { lat: 12.016, lng: 91.377 },
    { lat: 11.816, lng: 91.07 },
    { lat: 11.442, lng: 90.499 },
    { lat: 11.436, lng: 90.478 },
    { lat: 11.279, lng: 89.922 },
    { lat: 11.2, lng: 89.341 },
    { lat: 11.132, lng: 88.758 },
    { lat: 11.046, lng: 88.175 },
    { lat: 10.966, lng: 87.591 },
    { lat: 10.873, lng: 87.254 },
    { lat: 10.79, lng: 87.009 },
    { lat: 10.412, lng: 86.435 },
    { lat: 10.299, lng: 86.028 },
    { lat: 10.251, lng: 85.851 },
    { lat: 10.023, lng: 85.268 },
    { lat: 9.728, lng: 84.748 },
    { lat: 9.69, lng: 84.689 },
    { lat: 9.309, lng: 84.11 },
    { lat: 9.155, lng: 83.9 },
    { lat: 8.912, lng: 83.531 },
    { lat: 8.62, lng: 82.945 },
    { lat: 8.583, lng: 82.886 },
    { lat: 8.22, lng: 82.362 },
    { lat: 8.013, lng: 81.849 },
    { lat: 7.967, lng: 81.77 },
    { lat: 7.566, lng: 81.183 },
    { lat: 7.442, lng: 80.969 },
    { lat: 7.159, lng: 80.594 },
    { lat: 6.911, lng: 79.994 },
    { lat: 6.873, lng: 79.921 },
    { lat: 6.451, lng: 79.401 },
    { lat: 6.501, lng: 78.781 },
    { lat: 6.361, lng: 78.167 },
    { lat: 6.737, lng: 77.524 },
    { lat: 6.894, lng: 77.358 },
    { lat: 7.21, lng: 76.868 },
    { lat: 7.481, lng: 76.595 },
    { lat: 7.848, lng: 76.194 },
    { lat: 8.069, lng: 75.908 },
    { lat: 8.512, lng: 75.507 },
    { lat: 8.659, lng: 75.341 },
    { lat: 9.006, lng: 74.822 },
    { lat: 9.252, lng: 74.662 },
    { lat: 9.846, lng: 74.11 },
    { lat: 9.851, lng: 74.096 },
    { lat: 10.015, lng: 73.42 },
    { lat: 10.022, lng: 72.754 },
    { lat: 9.878, lng: 72.097 },
    { lat: 9.876, lng: 72.072 },
    { lat: 9.701, lng: 71.438 },
    { lat: 9.549, lng: 70.771 },
    { lat: 9.31, lng: 70.489 },
    { lat: 8.884, lng: 70.15 },
    { lat: 8.728, lng: 70.011 },
    { lat: 8.146, lng: 69.585 },
    { lat: 7.558, lng: 69.672 },
    { lat: 7.506, lng: 69.585 },
    { lat: 7.567, lng: 68.939 },
    { lat: 7.578, lng: 68.882 },
    { lat: 7.568, lng: 68.836 },
    { lat: 7.495, lng: 68.185 },
    { lat: 7.389, lng: 67.483 },
    { lat: 7.591, lng: 66.743 },
    { lat: 7.335, lng: 66.039 },
    { lat: 7.024, lng: 65.418 },
    { lat: 6.999, lng: 65.334 },
    { lat: 7.027, lng: 65.202 },
    { lat: 7.17, lng: 64.572 },
    { lat: 7.632, lng: 63.765 },
    { lat: 7.638, lng: 63.759 },
    { lat: 8.243, lng: 62.999 },
    { lat: 8.308, lng: 62.916 },
    { lat: 8.854, lng: 62.105 },
    { lat: 8.885, lng: 62.057 },
    { lat: 9.258, lng: 61.205 },
    { lat: 9.403, lng: 60.371 },
    { lat: 9.492, lng: 60.021 },
    { lat: 9.601, lng: 59.512 },
    { lat: 9.765, lng: 58.642 },
    { lat: 9.708, lng: 57.793 },
    { lat: 9.636, lng: 56.931 },
    { lat: 9.655, lng: 56.034 },
    { lat: 9.765, lng: 55.099 },
    { lat: 9.675, lng: 54.183 },
    { lat: 9.62, lng: 53.852 },
    { lat: 9.534, lng: 53.256 },
    { lat: 9.272, lng: 52.331 },
    { lat: 9.051, lng: 51.932 },
    { lat: 8.828, lng: 51.421 },
    { lat: 8.482, lng: 50.463 },
    { lat: 8.472, lng: 50.398 },
    { lat: 8.311, lng: 49.44 },
    { lat: 8.338, lng: 48.341 },
    { lat: 8.513, lng: 47.167 },
    { lat: 8.54, lng: 47.102 },
    { lat: 9.073, lng: 45.842 },
    { lat: 9.19, lng: 45.619 },
    { lat: 9.836, lng: 44.547 },
    { lat: 10.211, lng: 44.258 },
    { lat: 10.472, lng: 44.081 },
    { lat: 11.104, lng: 43.839 },
    { lat: 11.737, lng: 43.668 },
    { lat: 11.775, lng: 43.702 },
    { lat: 12.346, lng: 44.36 },
    { lat: 12.548, lng: 44.693 },
    { lat: 12.749, lng: 45.863 },
    { lat: 12.846, lng: 47.025 },
    { lat: 12.879, lng: 47.407 },
    { lat: 12.934, lng: 48.147 },
    { lat: 12.902, lng: 49.272 },
    { lat: 12.924, lng: 50.343 },
    { lat: 13.103, lng: 51.333 },
    { lat: 13.36, lng: 51.997 },
    { lat: 13.463, lng: 52.241 },
    { lat: 13.901, lng: 53.102 },
    { lat: 13.944, lng: 53.233 },
    { lat: 14.213, lng: 53.982 },
    { lat: 14.531, lng: 54.369 },
    { lat: 14.85, lng: 54.743 },
    { lat: 15.135, lng: 54.994 },
    { lat: 15.637, lng: 55.439 },
    { lat: 15.742, lng: 55.529 },
    { lat: 16.358, lng: 55.89 },
    { lat: 16.618, lng: 56.053 },
    { lat: 16.972, lng: 56.336 },
    { lat: 17.594, lng: 56.627 },
    { lat: 17.612, lng: 56.641 },
    { lat: 18.213, lng: 57.057 },
    { lat: 18.613, lng: 57.206 },
    { lat: 18.843, lng: 57.259 },
    { lat: 19.477, lng: 57.429 },
    { lat: 20.018, lng: 57.602 },
    { lat: 20.113, lng: 57.634 },
    { lat: 20.754, lng: 57.8 },
    { lat: 21.287, lng: 58.021 },
    { lat: 21.394, lng: 58.06 },
    { lat: 22.041, lng: 58.232 },
    { lat: 22.593, lng: 58.4 },
    { lat: 22.691, lng: 58.428 },
    { lat: 23.348, lng: 58.571 },
    { lat: 23.828, lng: 58.786 },
    { lat: 24.003, lng: 58.831 },
    { lat: 24.67, lng: 58.93 },
    { lat: 25.152, lng: 59.109 },
    { lat: 25.335, lng: 59.176 },
    { lat: 25.988, lng: 59.639 },
    { lat: 25.992, lng: 59.642 },
    { lat: 26.506, lng: 60.308 },
    { lat: 26.637, lng: 60.448 },
    { lat: 27.056, lng: 60.955 },
    { lat: 27.28, lng: 61.362 },
    { lat: 27.421, lng: 61.681 },
    { lat: 27.576, lng: 62.494 },
    { lat: 27.625, lng: 63.342 },
    { lat: 27.529, lng: 64.239 },
    { lat: 27.181, lng: 65.22 },
    { lat: 27.075, lng: 65.391 },
  ],
  c7: [
    { lat: 34.122, lng: 127.148 },
    { lat: 33.572, lng: 127.566 },
    { lat: 33.396, lng: 127.734 },
    { lat: 33.048, lng: 128.185 },
    { lat: 32.681, lng: 128.38 },
    { lat: 32.049, lng: 128.597 },
    { lat: 31.953, lng: 128.62 },
    { lat: 31.224, lng: 128.689 },
    { lat: 30.5, lng: 128.697 },
    { lat: 30.236, lng: 128.675 },
    { lat: 29.779, lng: 128.626 },
    { lat: 29.062, lng: 128.469 },
    { lat: 28.344, lng: 128.132 },
    { lat: 27.63, lng: 127.742 },
    { lat: 27.427, lng: 127.62 },
    { lat: 26.923, lng: 127.332 },
    { lat: 26.222, lng: 126.902 },
    { lat: 25.53, lng: 126.515 },
    { lat: 24.843, lng: 126.082 },
    { lat: 24.651, lng: 125.967 },
    { lat: 24.162, lng: 125.675 },
    { lat: 23.484, lng: 125.147 },
    { lat: 22.921, lng: 124.757 },
    { lat: 22.812, lng: 124.643 },
    { lat: 22.141, lng: 123.989 },
    { lat: 21.658, lng: 123.731 },
    { lat: 22.121, lng: 123.377 },
    { lat: 22.195, lng: 123.137 },
    { lat: 22.751, lng: 122.849 },
    { lat: 23.098, lng: 122.628 },
    { lat: 23.391, lng: 122.492 },
    { lat: 24.004, lng: 122.122 },
    { lat: 24.034, lng: 122.1 },
    { lat: 24.677, lng: 121.576 },
    { lat: 24.687, lng: 121.566 },
    { lat: 24.961, lng: 120.916 },
    { lat: 24.636, lng: 120.328 },
    { lat: 24.542, lng: 120.115 },
    { lat: 23.952, lng: 119.557 },
    { lat: 23.483, lng: 119.199 },
    { lat: 23.285, lng: 119.09 },
    { lat: 22.625, lng: 118.671 },
    { lat: 21.97, lng: 118.271 },
    { lat: 21.904, lng: 118.225 },
    { lat: 21.32, lng: 117.834 },
    { lat: 20.675, lng: 117.427 },
    { lat: 20.526, lng: 117.331 },
    { lat: 20.036, lng: 117.047 },
    { lat: 19.403, lng: 116.727 },
    { lat: 18.775, lng: 116.461 },
    { lat: 18.638, lng: 116.408 },
    { lat: 18.15, lng: 116.161 },
    { lat: 17.529, lng: 115.837 },
    { lat: 16.913, lng: 115.628 },
    { lat: 16.303, lng: 115.54 },
    { lat: 15.696, lng: 115.517 },
    { lat: 15.096, lng: 115.692 },
    { lat: 14.506, lng: 115.934 },
    { lat: 14.499, lng: 115.936 },
    { lat: 13.905, lng: 116.213 },
    { lat: 13.312, lng: 116.427 },
    { lat: 13.264, lng: 116.445 },
    { lat: 12.721, lng: 116.599 },
    { lat: 12.129, lng: 116.64 },
    { lat: 11.539, lng: 116.633 },
    { lat: 10.949, lng: 116.471 },
    { lat: 10.361, lng: 116.291 },
    { lat: 10.07, lng: 116.188 },
    { lat: 9.773, lng: 116.009 },
    { lat: 9.186, lng: 115.54 },
    { lat: 9.152, lng: 115.512 },
    { lat: 8.601, lng: 115.154 },
    { lat: 8.201, lng: 114.848 },
    { lat: 8.017, lng: 114.658 },
    { lat: 7.658, lng: 114.215 },
    { lat: 7.782, lng: 113.617 },
    { lat: 8.005, lng: 113.072 },
    { lat: 8.029, lng: 113.027 },
    { lat: 8.578, lng: 112.457 },
    { lat: 8.582, lng: 112.454 },
    { lat: 9.155, lng: 112.199 },
    { lat: 9.736, lng: 112.139 },
    { lat: 10.317, lng: 112.101 },
    { lat: 10.901, lng: 112.094 },
    { lat: 11.487, lng: 112.2 },
    { lat: 12.075, lng: 112.227 },
    { lat: 12.666, lng: 112.368 },
    { lat: 13.26, lng: 112.571 },
    { lat: 13.856, lng: 112.71 },
    { lat: 14.451, lng: 112.594 },
    { lat: 14.755, lng: 112.231 },
    { lat: 15.017, lng: 111.639 },
    { lat: 14.578, lng: 110.998 },
    { lat: 14.43, lng: 110.895 },
    { lat: 13.83, lng: 110.479 },
    { lat: 13.624, lng: 110.331 },
    { lat: 13.449, lng: 109.72 },
    { lat: 13.281, lng: 109.112 },
    { lat: 13.223, lng: 108.971 },
    { lat: 12.954, lng: 108.5 },
    { lat: 13.217, lng: 108.301 },
    { lat: 13.538, lng: 107.935 },
    { lat: 13.804, lng: 107.732 },
    { lat: 14.381, lng: 107.383 },
    { lat: 14.395, lng: 107.371 },
    { lat: 14.894, lng: 106.813 },
    { lat: 14.986, lng: 106.723 },
    { lat: 15.582, lng: 106.336 },
    { lat: 15.85, lng: 106.263 },
    { lat: 16.182, lng: 106.201 },
    { lat: 16.608, lng: 106.3 },
    { lat: 16.788, lng: 106.359 },
    { lat: 17.396, lng: 106.496 },
    { lat: 18.009, lng: 106.768 },
    { lat: 18.588, lng: 107.016 },
    { lat: 18.626, lng: 107.027 },
    { lat: 19.246, lng: 107.286 },
    { lat: 19.871, lng: 107.569 },
    { lat: 20.18, lng: 107.737 },
    { lat: 20.5, lng: 107.903 },
    { lat: 21.134, lng: 108.289 },
    { lat: 21.353, lng: 108.45 },
    { lat: 21.774, lng: 108.763 },
    { lat: 22.307, lng: 109.164 },
    { lat: 22.42, lng: 109.249 },
    { lat: 23.072, lng: 109.83 },
    { lat: 23.125, lng: 109.881 },
    { lat: 23.731, lng: 110.457 },
    { lat: 23.863, lng: 110.604 },
    { lat: 24.398, lng: 111.233 },
    { lat: 24.474, lng: 111.326 },
    { lat: 25.074, lng: 112.059 },
    { lat: 25.076, lng: 112.061 },
    { lat: 25.753, lng: 112.82 },
    { lat: 25.756, lng: 112.822 },
    { lat: 26.416, lng: 113.593 },
    { lat: 26.446, lng: 113.626 },
    { lat: 27.115, lng: 114.389 },
    { lat: 27.143, lng: 114.423 },
    { lat: 27.748, lng: 115.192 },
    { lat: 27.853, lng: 115.342 },
    { lat: 28.311, lng: 115.998 },
    { lat: 28.579, lng: 116.52 },
    { lat: 28.735, lng: 116.792 },
    { lat: 29.012, lng: 117.566 },
    { lat: 29.111, lng: 118.309 },
    { lat: 29.096, lng: 119.03 },
    { lat: 29.008, lng: 119.737 },
    { lat: 28.977, lng: 120.463 },
    { lat: 29.431, lng: 121.33 },
    { lat: 29.451, lng: 121.35 },
    { lat: 30.191, lng: 122.078 },
    { lat: 30.608, lng: 122.437 },
    { lat: 30.936, lng: 122.698 },
    { lat: 31.69, lng: 123.309 },
    { lat: 32.191, lng: 123.742 },
    { lat: 32.457, lng: 123.988 },
    { lat: 33.235, lng: 124.655 },
    { lat: 33.762, lng: 125.138 },
    { lat: 34.033, lng: 125.491 },
    { lat: 34.427, lng: 126.245 },
    { lat: 34.254, lng: 127.015 },
    { lat: 34.122, lng: 127.148 },
  ],
  c8: [
    { lat: 16.756, lng: 100.96 },
    { lat: 16.467, lng: 101.538 },
    { lat: 16.156, lng: 101.943 },
    { lat: 15.637, lng: 102.11 },
    { lat: 15.556, lng: 102.123 },
    { lat: 14.958, lng: 102.158 },
    { lat: 14.844, lng: 102.092 },
    { lat: 14.83, lng: 101.506 },
    { lat: 14.578, lng: 100.917 },
    { lat: 14.358, lng: 100.785 },
    { lat: 13.985, lng: 100.326 },
    { lat: 14.032, lng: 99.744 },
    { lat: 14.356, lng: 99.277 },
    { lat: 14.457, lng: 99.165 },
    { lat: 14.374, lng: 98.582 },
    { lat: 14.381, lng: 98.0 },
    { lat: 14.95, lng: 97.444 },
    { lat: 15.08, lng: 97.415 },
    { lat: 15.548, lng: 97.33 },
    { lat: 15.725, lng: 97.413 },
    { lat: 16.148, lng: 97.609 },
    { lat: 16.458, lng: 98.0 },
    { lat: 16.485, lng: 98.589 },
    { lat: 16.751, lng: 98.867 },
    { lat: 16.938, lng: 99.182 },
    { lat: 17.171, lng: 99.776 },
    { lat: 16.995, lng: 100.365 },
    { lat: 16.758, lng: 100.953 },
    { lat: 16.756, lng: 100.96 },
  ],
  c9: [
    { lat: 43.405, lng: 129.206 },
    { lat: 42.504, lng: 129.328 },
    { lat: 42.153, lng: 129.37 },
    { lat: 41.615, lng: 129.364 },
    { lat: 40.742, lng: 129.389 },
    { lat: 40.615, lng: 129.393 },
    { lat: 39.888, lng: 129.455 },
    { lat: 39.336, lng: 129.589 },
    { lat: 39.056, lng: 129.641 },
    { lat: 38.239, lng: 129.834 },
    { lat: 38.173, lng: 129.862 },
    { lat: 37.444, lng: 130.162 },
    { lat: 37.248, lng: 130.272 },
    { lat: 36.669, lng: 130.625 },
    { lat: 36.417, lng: 130.737 },
    { lat: 35.907, lng: 131.124 },
    { lat: 35.72, lng: 131.276 },
    { lat: 35.333, lng: 131.988 },
    { lat: 35.199, lng: 132.317 },
    { lat: 34.934, lng: 132.696 },
    { lat: 34.481, lng: 133.187 },
    { lat: 34.401, lng: 133.331 },
    { lat: 33.932, lng: 134.005 },
    { lat: 33.774, lng: 134.104 },
    { lat: 33.133, lng: 134.493 },
    { lat: 33.043, lng: 134.518 },
    { lat: 32.294, lng: 134.518 },
    { lat: 31.551, lng: 134.458 },
    { lat: 31.221, lng: 134.377 },
    { lat: 30.807, lng: 134.245 },
    { lat: 30.068, lng: 133.973 },
    { lat: 29.333, lng: 133.641 },
    { lat: 28.603, lng: 133.25 },
    { lat: 27.98, lng: 132.812 },
    { lat: 27.874, lng: 132.725 },
    { lat: 27.151, lng: 132.151 },
    { lat: 26.428, lng: 131.424 },
    { lat: 26.31, lng: 131.275 },
    { lat: 25.692, lng: 130.239 },
    { lat: 25.679, lng: 130.216 },
    { lat: 25.003, lng: 129.178 },
    { lat: 24.969, lng: 129.14 },
    { lat: 24.271, lng: 128.413 },
    { lat: 23.823, lng: 128.018 },
    { lat: 23.586, lng: 127.84 },
    { lat: 22.91, lng: 127.33 },
    { lat: 22.242, lng: 126.874 },
    { lat: 22.074, lng: 126.763 },
    { lat: 21.582, lng: 126.482 },
    { lat: 20.93, lng: 126.184 },
    { lat: 20.283, lng: 125.91 },
    { lat: 19.641, lng: 125.635 },
    { lat: 19.003, lng: 125.389 },
    { lat: 18.653, lng: 125.231 },
    { lat: 18.369, lng: 125.116 },
    { lat: 17.74, lng: 124.868 },
    { lat: 17.115, lng: 124.634 },
    { lat: 16.492, lng: 124.38 },
    { lat: 15.874, lng: 124.162 },
    { lat: 15.259, lng: 123.919 },
    { lat: 15.244, lng: 123.912 },
    { lat: 14.646, lng: 123.628 },
    { lat: 14.035, lng: 123.343 },
    { lat: 13.428, lng: 123.04 },
    { lat: 13.275, lng: 122.948 },
    { lat: 12.82, lng: 122.581 },
    { lat: 12.236, lng: 122.15 },
    { lat: 12.215, lng: 122.133 },
    { lat: 11.613, lng: 121.631 },
    { lat: 11.329, lng: 121.391 },
    { lat: 11.013, lng: 121.098 },
    { lat: 10.449, lng: 120.653 },
    { lat: 10.416, lng: 120.602 },
    { lat: 9.916, lng: 119.96 },
    { lat: 9.816, lng: 119.687 },
    { lat: 9.684, lng: 119.301 },
    { lat: 9.22, lng: 118.756 },
    { lat: 9.101, lng: 118.622 },
    { lat: 8.683, lng: 117.964 },
    { lat: 8.628, lng: 117.908 },
    { lat: 8.042, lng: 117.439 },
    { lat: 7.861, lng: 117.289 },
    { lat: 7.459, lng: 117.067 },
    { lat: 6.879, lng: 116.753 },
    { lat: 6.561, lng: 116.604 },
    { lat: 6.299, lng: 116.452 },
    { lat: 5.721, lng: 115.959 },
    { lat: 5.715, lng: 115.955 },
    { lat: 5.144, lng: 115.609 },
    { lat: 4.674, lng: 115.311 },
    { lat: 4.569, lng: 115.255 },
    { lat: 3.995, lng: 115.013 },
    { lat: 3.422, lng: 114.791 },
    { lat: 3.069, lng: 114.668 },
    { lat: 2.851, lng: 114.564 },
    { lat: 2.279, lng: 114.239 },
    { lat: 1.997, lng: 114.052 },
    { lat: 1.708, lng: 113.786 },
    { lat: 1.269, lng: 113.449 },
    { lat: 1.138, lng: 113.346 },
    { lat: 0.569, lng: 112.996 },
    { lat: 0.374, lng: 112.851 },
    { lat: 0.0, lng: 112.488 },
    { lat: -0.27, lng: 112.26 },
    { lat: 0.0, lng: 111.758 },
    { lat: 0.08, lng: 111.671 },
    { lat: 0.568, lng: 111.365 },
    { lat: 1.136, lng: 111.175 },
    { lat: 1.704, lng: 111.177 },
    { lat: 1.978, lng: 111.094 },
    { lat: 2.272, lng: 110.983 },
    { lat: 2.841, lng: 110.739 },
    { lat: 3.055, lng: 110.522 },
    { lat: 3.408, lng: 109.964 },
    { lat: 3.414, lng: 109.944 },
    { lat: 3.408, lng: 109.939 },
    { lat: 2.838, lng: 109.648 },
    { lat: 2.65, lng: 109.353 },
    { lat: 2.269, lng: 109.083 },
    { lat: 1.811, lng: 108.767 },
    { lat: 1.701, lng: 108.678 },
    { lat: 1.134, lng: 108.249 },
    { lat: 1.049, lng: 108.186 },
    { lat: 0.567, lng: 107.969 },
    { lat: 0.0, lng: 107.745 },
    { lat: -0.367, lng: 107.61 },
    { lat: -0.566, lng: 107.483 },
    { lat: -1.051, lng: 107.038 },
    { lat: -1.133, lng: 106.984 },
    { lat: -1.699, lng: 106.747 },
    { lat: -2.266, lng: 106.673 },
    { lat: -2.833, lng: 106.51 },
    { lat: -2.97, lng: 106.478 },
    { lat: -3.4, lng: 106.398 },
    { lat: -3.967, lng: 105.955 },
    { lat: -3.993, lng: 105.917 },
    { lat: -4.009, lng: 105.347 },
    { lat: -3.965, lng: 105.182 },
    { lat: -3.797, lng: 104.776 },
    { lat: -3.657, lng: 104.206 },
    { lat: -3.396, lng: 104.051 },
    { lat: -2.829, lng: 103.773 },
    { lat: -2.569, lng: 103.632 },
    { lat: -2.262, lng: 103.487 },
    { lat: -1.696, lng: 103.235 },
    { lat: -1.462, lng: 103.062 },
    { lat: -1.131, lng: 102.819 },
    { lat: -0.688, lng: 102.496 },
    { lat: -0.565, lng: 102.309 },
    { lat: -0.385, lng: 101.933 },
    { lat: -0.021, lng: 101.37 },
    { lat: 0.0, lng: 101.34 },
    { lat: 0.4, lng: 100.808 },
    { lat: 0.565, lng: 100.388 },
    { lat: 0.627, lng: 100.244 },
    { lat: 0.831, lng: 99.683 },
    { lat: 1.126, lng: 99.124 },
    { lat: 1.128, lng: 99.11 },
    { lat: 1.514, lng: 98.562 },
    { lat: 1.696, lng: 98.329 },
    { lat: 2.052, lng: 98.0 },
    { lat: 2.26, lng: 97.875 },
    { lat: 2.781, lng: 97.438 },
    { lat: 2.826, lng: 97.398 },
    { lat: 3.268, lng: 96.876 },
    { lat: 3.393, lng: 96.708 },
    { lat: 3.959, lng: 96.322 },
    { lat: 3.972, lng: 96.312 },
    { lat: 4.4, lng: 95.747 },
    { lat: 4.527, lng: 95.531 },
    { lat: 4.835, lng: 95.181 },
    { lat: 4.986, lng: 94.616 },
    { lat: 5.006, lng: 94.05 },
    { lat: 5.024, lng: 93.484 },
    { lat: 4.842, lng: 92.919 },
    { lat: 4.543, lng: 92.354 },
    { lat: 4.531, lng: 92.298 },
    { lat: 4.432, lng: 91.787 },
    { lat: 4.088, lng: 91.222 },
    { lat: 3.965, lng: 90.901 },
    { lat: 3.858, lng: 90.655 },
    { lat: 3.737, lng: 90.086 },
    { lat: 3.539, lng: 89.516 },
    { lat: 3.4, lng: 89.298 },
    { lat: 3.24, lng: 88.946 },
    { lat: 3.274, lng: 88.371 },
    { lat: 3.375, lng: 87.794 },
    { lat: 3.404, lng: 87.725 },
    { lat: 3.737, lng: 87.212 },
    { lat: 3.869, lng: 86.63 },
    { lat: 3.976, lng: 86.378 },
    { lat: 4.212, lng: 86.043 },
    { lat: 4.549, lng: 85.52 },
    { lat: 4.59, lng: 85.451 },
    { lat: 4.549, lng: 85.308 },
    { lat: 4.468, lng: 84.868 },
    { lat: 4.289, lng: 84.283 },
    { lat: 4.164, lng: 83.695 },
    { lat: 4.086, lng: 83.104 },
    { lat: 3.987, lng: 82.956 },
    { lat: 3.785, lng: 82.515 },
    { lat: 3.566, lng: 81.922 },
    { lat: 3.421, lng: 81.532 },
    { lat: 3.353, lng: 81.326 },
    { lat: 3.066, lng: 80.729 },
    { lat: 2.854, lng: 80.33 },
    { lat: 2.752, lng: 80.128 },
    { lat: 2.589, lng: 79.522 },
    { lat: 2.441, lng: 78.912 },
    { lat: 2.331, lng: 78.297 },
    { lat: 2.288, lng: 78.098 },
    { lat: 2.209, lng: 77.68 },
    { lat: 2.254, lng: 77.055 },
    { lat: 2.292, lng: 76.728 },
    { lat: 2.336, lng: 76.426 },
    { lat: 2.528, lng: 75.789 },
    { lat: 2.732, lng: 75.148 },
    { lat: 2.874, lng: 74.775 },
    { lat: 2.982, lng: 74.499 },
    { lat: 3.185, lng: 73.846 },
    { lat: 3.313, lng: 73.19 },
    { lat: 3.339, lng: 72.532 },
    { lat: 3.464, lng: 71.863 },
    { lat: 3.464, lng: 71.863 },
    { lat: 3.464, lng: 71.863 },
    { lat: 3.448, lng: 71.194 },
    { lat: 3.265, lng: 70.525 },
    { lat: 3.217, lng: 69.845 },
    { lat: 2.982, lng: 69.164 },
    { lat: 2.9, lng: 68.968 },
    { lat: 2.725, lng: 68.477 },
    { lat: 2.324, lng: 67.796 },
    { lat: 2.319, lng: 67.786 },
    { lat: 1.839, lng: 67.088 },
    { lat: 1.745, lng: 66.955 },
    { lat: 1.445, lng: 66.378 },
    { lat: 1.216, lng: 65.655 },
    { lat: 1.166, lng: 65.491 },
    { lat: 1.021, lng: 64.922 },
    { lat: 0.954, lng: 64.177 },
    { lat: 0.942, lng: 63.421 },
    { lat: 1.026, lng: 62.653 },
    { lat: 1.172, lng: 61.872 },
    { lat: 1.175, lng: 61.869 },
    { lat: 1.764, lng: 61.424 },
    { lat: 2.354, lng: 61.078 },
    { lat: 2.449, lng: 61.045 },
    { lat: 2.944, lng: 60.862 },
    { lat: 3.535, lng: 60.741 },
    { lat: 4.127, lng: 60.496 },
    { lat: 4.72, lng: 60.306 },
    { lat: 4.981, lng: 60.084 },
    { lat: 5.319, lng: 59.74 },
    { lat: 5.696, lng: 59.194 },
    { lat: 5.925, lng: 58.768 },
    { lat: 6.134, lng: 58.308 },
    { lat: 6.299, lng: 57.431 },
    { lat: 6.226, lng: 56.564 },
    { lat: 6.21, lng: 55.672 },
    { lat: 6.098, lng: 54.772 },
    { lat: 5.983, lng: 54.285 },
    { lat: 5.832, lng: 53.871 },
    { lat: 5.412, lng: 52.967 },
    { lat: 5.399, lng: 52.937 },
    { lat: 4.864, lng: 52.052 },
    { lat: 4.808, lng: 51.91 },
    { lat: 4.467, lng: 51.091 },
    { lat: 4.372, lng: 50.069 },
    { lat: 4.231, lng: 49.351 },
    { lat: 4.034, lng: 49.041 },
    { lat: 4.237, lng: 48.76 },
    { lat: 4.458, lng: 47.893 },
    { lat: 4.868, lng: 46.817 },
    { lat: 4.911, lng: 46.691 },
    { lat: 5.492, lng: 45.827 },
    { lat: 5.738, lng: 45.367 },
    { lat: 6.122, lng: 44.727 },
    { lat: 6.454, lng: 43.976 },
    { lat: 6.759, lng: 43.43 },
    { lat: 7.32, lng: 42.451 },
    { lat: 7.399, lng: 42.308 },
    { lat: 8.048, lng: 40.94 },
    { lat: 8.12, lng: 40.815 },
    { lat: 8.699, lng: 39.787 },
    { lat: 9.166, lng: 38.934 },
    { lat: 9.356, lng: 38.6 },
    { lat: 10.004, lng: 37.967 },
    { lat: 10.65, lng: 37.509 },
    { lat: 11.292, lng: 37.324 },
    { lat: 11.928, lng: 37.397 },
    { lat: 12.222, lng: 37.537 },
    { lat: 12.559, lng: 37.669 },
    { lat: 13.175, lng: 38.385 },
    { lat: 13.356, lng: 38.588 },
    { lat: 13.788, lng: 39.219 },
    { lat: 14.046, lng: 39.797 },
    { lat: 14.38, lng: 40.592 },
    { lat: 14.571, lng: 41.022 },
    { lat: 14.965, lng: 42.12 },
    { lat: 14.999, lng: 42.234 },
    { lat: 15.247, lng: 43.48 },
    { lat: 15.488, lng: 44.675 },
    { lat: 15.504, lng: 44.773 },
    { lat: 15.679, lng: 45.844 },
    { lat: 15.839, lng: 46.983 },
    { lat: 15.961, lng: 48.099 },
    { lat: 16.008, lng: 48.273 },
    { lat: 16.253, lng: 49.105 },
    { lat: 16.582, lng: 49.907 },
    { lat: 16.648, lng: 50.039 },
    { lat: 17.116, lng: 50.917 },
    { lat: 17.177, lng: 51.008 },
    { lat: 17.718, lng: 51.717 },
    { lat: 17.785, lng: 51.789 },
    { lat: 18.41, lng: 52.19 },
    { lat: 18.69, lng: 52.339 },
    { lat: 19.041, lng: 52.51 },
    { lat: 19.682, lng: 52.665 },
    { lat: 20.054, lng: 52.759 },
    { lat: 20.326, lng: 52.828 },
    { lat: 20.979, lng: 52.865 },
    { lat: 21.646, lng: 52.683 },
    { lat: 22.319, lng: 52.478 },
    { lat: 23.002, lng: 52.177 },
    { lat: 23.702, lng: 51.663 },
    { lat: 24.406, lng: 51.183 },
    { lat: 25.12, lng: 50.642 },
    { lat: 25.842, lng: 50.09 },
    { lat: 26.569, lng: 49.566 },
    { lat: 27.298, lng: 49.136 },
    { lat: 27.882, lng: 49.206 },
    { lat: 27.997, lng: 49.271 },
    { lat: 28.655, lng: 49.813 },
    { lat: 28.671, lng: 49.861 },
    { lat: 28.82, lng: 50.859 },
    { lat: 28.885, lng: 51.948 },
    { lat: 28.865, lng: 53.065 },
    { lat: 28.849, lng: 54.149 },
    { lat: 28.828, lng: 55.208 },
    { lat: 28.831, lng: 56.226 },
    { lat: 28.919, lng: 57.172 },
    { lat: 28.92, lng: 57.21 },
    { lat: 28.931, lng: 58.143 },
    { lat: 29.041, lng: 59.042 },
    { lat: 29.321, lng: 59.837 },
    { lat: 29.444, lng: 60.401 },
    { lat: 29.494, lng: 60.677 },
    { lat: 29.541, lng: 61.569 },
    { lat: 29.521, lng: 62.48 },
    { lat: 29.423, lng: 63.412 },
    { lat: 29.246, lng: 63.961 },
    { lat: 29.076, lng: 64.436 },
    { lat: 28.496, lng: 65.53 },
    { lat: 28.455, lng: 65.595 },
    { lat: 27.826, lng: 66.622 },
    { lat: 27.699, lng: 66.777 },
    { lat: 26.967, lng: 67.679 },
    { lat: 26.887, lng: 67.772 },
    { lat: 26.251, lng: 68.428 },
    { lat: 25.781, lng: 68.926 },
    { lat: 25.543, lng: 69.17 },
    { lat: 24.843, lng: 69.917 },
    { lat: 24.769, lng: 69.999 },
    { lat: 24.149, lng: 70.667 },
    { lat: 23.854, lng: 71.002 },
    { lat: 23.461, lng: 71.474 },
    { lat: 23.041, lng: 71.943 },
    { lat: 22.782, lng: 72.24 },
    { lat: 22.211, lng: 72.859 },
    { lat: 22.11, lng: 72.971 },
    { lat: 21.444, lng: 73.731 },
    { lat: 21.44, lng: 73.735 },
    { lat: 20.786, lng: 74.401 },
    { lat: 20.588, lng: 74.602 },
    { lat: 20.132, lng: 75.126 },
    { lat: 19.834, lng: 75.426 },
    { lat: 19.484, lng: 75.846 },
    { lat: 19.208, lng: 76.209 },
    { lat: 18.837, lng: 76.762 },
    { lat: 18.701, lng: 76.958 },
    { lat: 18.352, lng: 77.672 },
    { lat: 18.182, lng: 78.283 },
    { lat: 18.159, lng: 78.355 },
    { lat: 18.036, lng: 79.023 },
    { lat: 18.09, lng: 79.663 },
    { lat: 18.117, lng: 80.303 },
    { lat: 18.139, lng: 80.396 },
    { lat: 18.257, lng: 80.927 },
    { lat: 18.362, lng: 81.552 },
    { lat: 18.55, lng: 82.166 },
    { lat: 18.717, lng: 82.556 },
    { lat: 18.817, lng: 82.77 },
    { lat: 19.053, lng: 83.376 },
    { lat: 19.268, lng: 83.984 },
    { lat: 19.311, lng: 84.079 },
    { lat: 19.549, lng: 84.584 },
    { lat: 19.845, lng: 85.182 },
    { lat: 19.913, lng: 85.326 },
    { lat: 20.134, lng: 85.781 },
    { lat: 20.526, lng: 86.24 },
    { lat: 20.632, lng: 86.361 },
    { lat: 20.988, lng: 86.956 },
    { lat: 21.139, lng: 87.345 },
    { lat: 21.229, lng: 87.56 },
    { lat: 21.636, lng: 88.152 },
    { lat: 21.759, lng: 88.374 },
    { lat: 22.026, lng: 88.747 },
    { lat: 22.008, lng: 89.372 },
    { lat: 22.032, lng: 89.993 },
    { lat: 21.938, lng: 90.62 },
    { lat: 21.73, lng: 90.961 },
    { lat: 21.57, lng: 91.26 },
    { lat: 21.09, lng: 91.822 },
    { lat: 21.031, lng: 91.903 },
    { lat: 20.651, lng: 92.532 },
    { lat: 20.455, lng: 92.82 },
    { lat: 20.238, lng: 93.157 },
    { lat: 19.923, lng: 93.774 },
    { lat: 19.825, lng: 93.945 },
    { lat: 19.595, lng: 94.388 },
    { lat: 19.387, lng: 94.995 },
    { lat: 19.229, lng: 95.599 },
    { lat: 19.197, lng: 95.97 },
    { lat: 19.173, lng: 96.201 },
    { lat: 19.196, lng: 96.395 },
    { lat: 19.233, lng: 96.8 },
    { lat: 19.257, lng: 97.4 },
    { lat: 19.323, lng: 98.0 },
    { lat: 19.449, lng: 98.601 },
    { lat: 19.458, lng: 99.202 },
    { lat: 19.422, lng: 99.803 },
    { lat: 19.336, lng: 100.402 },
    { lat: 19.239, lng: 101.002 },
    { lat: 19.201, lng: 101.277 },
    { lat: 19.156, lng: 101.601 },
    { lat: 19.046, lng: 102.2 },
    { lat: 18.997, lng: 102.8 },
    { lat: 19.088, lng: 103.406 },
    { lat: 19.129, lng: 104.012 },
    { lat: 19.213, lng: 104.621 },
    { lat: 19.221, lng: 104.635 },
    { lat: 19.524, lng: 105.244 },
    { lat: 19.85, lng: 105.59 },
    { lat: 20.122, lng: 105.887 },
    { lat: 20.481, lng: 106.216 },
    { lat: 20.829, lng: 106.547 },
    { lat: 21.115, lng: 106.777 },
    { lat: 21.6, lng: 107.221 },
    { lat: 21.755, lng: 107.347 },
    { lat: 22.4, lng: 107.877 },
    { lat: 22.441, lng: 107.914 },
    { lat: 23.05, lng: 108.438 },
    { lat: 23.237, lng: 108.617 },
    { lat: 23.707, lng: 109.07 },
    { lat: 23.963, lng: 109.327 },
    { lat: 24.371, lng: 109.736 },
    { lat: 24.668, lng: 110.048 },
    { lat: 25.042, lng: 110.491 },
    { lat: 25.258, lng: 110.77 },
    { lat: 25.724, lng: 111.392 },
    { lat: 25.808, lng: 111.499 },
    { lat: 26.412, lng: 112.201 },
    { lat: 26.458, lng: 112.255 },
    { lat: 27.106, lng: 112.938 },
    { lat: 27.2, lng: 113.042 },
    { lat: 27.81, lng: 113.789 },
    { lat: 27.845, lng: 113.831 },
    { lat: 28.5, lng: 114.639 },
    { lat: 28.524, lng: 114.667 },
    { lat: 29.244, lng: 115.438 },
    { lat: 29.302, lng: 115.497 },
    { lat: 29.956, lng: 116.346 },
    { lat: 29.98, lng: 116.383 },
    { lat: 30.495, lng: 117.19 },
    { lat: 30.74, lng: 117.751 },
    { lat: 30.865, lng: 118.01 },
    { lat: 31.124, lng: 118.815 },
    { lat: 31.363, lng: 119.627 },
    { lat: 31.548, lng: 120.006 },
    { lat: 31.834, lng: 120.519 },
    { lat: 32.328, lng: 121.131 },
    { lat: 32.9, lng: 121.622 },
    { lat: 33.099, lng: 121.77 },
    { lat: 33.868, lng: 122.144 },
    { lat: 34.645, lng: 122.447 },
    { lat: 35.426, lng: 122.608 },
    { lat: 35.765, lng: 122.633 },
    { lat: 36.214, lng: 122.673 },
    { lat: 37.006, lng: 122.6 },
    { lat: 37.711, lng: 122.567 },
    { lat: 37.812, lng: 122.55 },
    { lat: 38.625, lng: 122.376 },
    { lat: 39.307, lng: 122.378 },
    { lat: 39.46, lng: 122.369 },
    { lat: 40.321, lng: 122.562 },
    { lat: 41.219, lng: 123.124 },
    { lat: 41.41, lng: 123.323 },
    { lat: 42.167, lng: 124.192 },
    { lat: 42.612, lng: 124.842 },
    { lat: 43.165, lng: 125.685 },
    { lat: 43.551, lng: 126.315 },
    { lat: 44.136, lng: 127.651 },
    { lat: 44.079, lng: 128.616 },
    { lat: 43.429, lng: 129.196 },
    { lat: 43.405, lng: 129.206 },
  ],
  c10: [
    { lat: 11.429, lng: 104.275 },
    { lat: 11.364, lng: 104.337 },
    { lat: 10.849, lng: 104.729 },
    { lat: 10.268, lng: 104.755 },
    { lat: 9.688, lng: 104.572 },
    { lat: 9.34, lng: 104.289 },
    { lat: 9.109, lng: 104.187 },
    { lat: 8.531, lng: 103.812 },
    { lat: 8.412, lng: 103.697 },
    { lat: 8.111, lng: 103.12 },
    { lat: 8.065, lng: 102.549 },
    { lat: 7.952, lng: 102.237 },
    { lat: 7.831, lng: 101.976 },
    { lat: 7.895, lng: 101.407 },
    { lat: 7.951, lng: 101.287 },
    { lat: 8.416, lng: 100.843 },
    { lat: 8.524, lng: 100.715 },
    { lat: 9.1, lng: 100.72 },
    { lat: 9.676, lng: 100.642 },
    { lat: 10.023, lng: 100.858 },
    { lat: 10.256, lng: 100.962 },
    { lat: 10.837, lng: 101.422 },
    { lat: 10.856, lng: 101.442 },
    { lat: 11.036, lng: 102.019 },
    { lat: 11.279, lng: 102.6 },
    { lat: 11.423, lng: 102.759 },
    { lat: 11.81, lng: 103.189 },
    { lat: 11.78, lng: 103.767 },
    { lat: 11.429, lng: 104.275 },
  ],
  c11: [
    { lat: 20.115, lng: 120.221 },
    { lat: 19.488, lng: 120.287 },
    { lat: 18.861, lng: 120.228 },
    { lat: 18.236, lng: 120.07 },
    { lat: 17.612, lng: 119.81 },
    { lat: 17.032, lng: 119.459 },
    { lat: 16.986, lng: 119.28 },
    { lat: 16.872, lng: 118.778 },
    { lat: 16.972, lng: 118.657 },
    { lat: 17.579, lng: 118.333 },
    { lat: 18.018, lng: 118.28 },
    { lat: 18.195, lng: 118.277 },
    { lat: 18.817, lng: 118.363 },
    { lat: 18.979, lng: 118.42 },
    { lat: 19.446, lng: 118.622 },
    { lat: 20.082, lng: 118.958 },
    { lat: 20.538, lng: 119.34 },
    { lat: 20.663, lng: 120.04 },
    { lat: 20.115, lng: 120.221 },
  ],
  c12: [
    { lat: 47.446, lng: 131.543 },
    { lat: 47.284, lng: 132.046 },
    { lat: 46.788, lng: 132.758 },
    { lat: 46.554, lng: 132.906 },
    { lat: 45.986, lng: 133.209 },
    { lat: 45.614, lng: 133.388 },
    { lat: 44.958, lng: 133.471 },
    { lat: 44.67, lng: 133.561 },
    { lat: 43.729, lng: 133.5 },
    { lat: 43.597, lng: 133.474 },
    { lat: 42.807, lng: 133.409 },
    { lat: 41.959, lng: 133.294 },
    { lat: 41.9, lng: 133.287 },
    { lat: 41.014, lng: 133.204 },
    { lat: 40.409, lng: 133.216 },
    { lat: 40.161, lng: 133.346 },
    { lat: 39.713, lng: 133.75 },
    { lat: 39.376, lng: 134.218 },
    { lat: 39.229, lng: 134.432 },
    { lat: 38.596, lng: 134.981 },
    { lat: 38.58, lng: 134.999 },
    { lat: 37.895, lng: 135.543 },
    { lat: 37.818, lng: 135.616 },
    { lat: 37.385, lng: 136.212 },
    { lat: 37.066, lng: 136.44 },
    { lat: 36.743, lng: 136.79 },
    { lat: 36.3, lng: 136.934 },
    { lat: 35.713, lng: 137.099 },
    { lat: 35.524, lng: 137.13 },
    { lat: 34.747, lng: 137.175 },
    { lat: 34.347, lng: 137.194 },
    { lat: 33.977, lng: 137.18 },
    { lat: 33.211, lng: 137.108 },
    { lat: 32.445, lng: 136.89 },
    { lat: 32.202, lng: 136.833 },
    { lat: 31.691, lng: 136.715 },
    { lat: 30.938, lng: 136.418 },
    { lat: 30.194, lng: 136.125 },
    { lat: 29.453, lng: 135.749 },
    { lat: 28.721, lng: 135.373 },
    { lat: 27.995, lng: 134.984 },
    { lat: 27.272, lng: 134.495 },
    { lat: 26.555, lng: 133.978 },
    { lat: 26.387, lng: 133.862 },
    { lat: 25.846, lng: 133.479 },
    { lat: 25.14, lng: 132.895 },
    { lat: 24.446, lng: 132.424 },
    { lat: 24.056, lng: 132.095 },
    { lat: 23.75, lng: 131.76 },
    { lat: 23.055, lng: 130.95 },
    { lat: 23.037, lng: 130.927 },
    { lat: 22.36, lng: 129.971 },
    { lat: 22.293, lng: 129.896 },
    { lat: 21.682, lng: 129.232 },
    { lat: 21.358, lng: 128.846 },
    { lat: 21.005, lng: 128.345 },
    { lat: 20.534, lng: 127.865 },
    { lat: 20.344, lng: 127.733 },
    { lat: 19.695, lng: 127.321 },
    { lat: 19.053, lng: 126.994 },
    { lat: 18.418, lng: 126.752 },
    { lat: 17.787, lng: 126.527 },
    { lat: 17.64, lng: 126.463 },
    { lat: 17.159, lng: 126.253 },
    { lat: 16.534, lng: 125.974 },
    { lat: 15.912, lng: 125.667 },
    { lat: 15.293, lng: 125.363 },
    { lat: 15.185, lng: 125.295 },
    { lat: 14.676, lng: 124.99 },
    { lat: 14.064, lng: 124.675 },
    { lat: 13.534, lng: 124.345 },
    { lat: 13.453, lng: 124.304 },
    { lat: 12.848, lng: 124.024 },
    { lat: 12.244, lng: 123.716 },
    { lat: 11.738, lng: 123.432 },
    { lat: 11.642, lng: 123.37 },
    { lat: 11.042, lng: 122.974 },
    { lat: 10.555, lng: 122.633 },
    { lat: 10.445, lng: 122.553 },
    { lat: 9.85, lng: 122.131 },
    { lat: 9.305, lng: 121.856 },
    { lat: 9.258, lng: 121.831 },
    { lat: 8.669, lng: 121.559 },
    { lat: 8.082, lng: 121.282 },
    { lat: 7.689, lng: 121.08 },
    { lat: 7.496, lng: 120.946 },
    { lat: 6.912, lng: 120.638 },
    { lat: 6.417, lng: 120.359 },
    { lat: 6.33, lng: 120.305 },
    { lat: 5.749, lng: 120.033 },
    { lat: 5.17, lng: 119.805 },
    { lat: 4.593, lng: 119.652 },
    { lat: 4.551, lng: 119.637 },
    { lat: 4.015, lng: 119.199 },
    { lat: 3.807, lng: 118.982 },
    { lat: 3.436, lng: 118.372 },
    { lat: 3.42, lng: 118.346 },
    { lat: 2.896, lng: 117.713 },
    { lat: 2.86, lng: 117.668 },
    { lat: 2.436, lng: 117.088 },
    { lat: 2.286, lng: 116.988 },
    { lat: 1.713, lng: 116.48 },
    { lat: 1.693, lng: 116.465 },
    { lat: 1.141, lng: 116.035 },
    { lat: 0.998, lng: 115.85 },
    { lat: 0.57, lng: 115.322 },
    { lat: 0.505, lng: 115.242 },
    { lat: 0.0, lng: 114.884 },
    { lat: -0.355, lng: 114.64 },
    { lat: -0.57, lng: 114.476 },
    { lat: -1.093, lng: 114.044 },
    { lat: -1.139, lng: 114.004 },
    { lat: -1.695, lng: 113.452 },
    { lat: -1.708, lng: 113.44 },
    { lat: -2.262, lng: 112.864 },
    { lat: -2.276, lng: 112.851 },
    { lat: -2.845, lng: 112.378 },
    { lat: -2.958, lng: 112.282 },
    { lat: -3.243, lng: 111.697 },
    { lat: -3.412, lng: 111.422 },
    { lat: -3.561, lng: 111.115 },
    { lat: -3.979, lng: 110.61 },
    { lat: -4.047, lng: 110.538 },
    { lat: -4.266, lng: 109.958 },
    { lat: -4.344, lng: 109.378 },
    { lat: -4.499, lng: 108.8 },
    { lat: -4.543, lng: 108.751 },
    { lat: -4.98, lng: 108.23 },
    { lat: -5.11, lng: 107.759 },
    { lat: -5.139, lng: 107.656 },
    { lat: -5.238, lng: 107.082 },
    { lat: -5.676, lng: 106.638 },
    { lat: -5.784, lng: 106.516 },
    { lat: -6.053, lng: 105.947 },
    { lat: -6.06, lng: 105.374 },
    { lat: -6.141, lng: 104.804 },
    { lat: -6.058, lng: 104.232 },
    { lat: -5.755, lng: 103.659 },
    { lat: -5.669, lng: 103.591 },
    { lat: -5.098, lng: 103.242 },
    { lat: -4.82, lng: 103.081 },
    { lat: -4.53, lng: 102.883 },
    { lat: -3.961, lng: 102.543 },
    { lat: -3.907, lng: 102.508 },
    { lat: -3.394, lng: 102.208 },
    { lat: -2.918, lng: 101.938 },
    { lat: -2.828, lng: 101.878 },
    { lat: -2.304, lng: 101.372 },
    { lat: -2.261, lng: 101.336 },
    { lat: -1.769, lng: 100.808 },
    { lat: -1.696, lng: 100.683 },
    { lat: -1.438, lng: 100.245 },
    { lat: -1.162, lng: 99.683 },
    { lat: -1.13, lng: 99.608 },
    { lat: -0.912, lng: 99.123 },
    { lat: -0.618, lng: 98.56 },
    { lat: -0.563, lng: 98.452 },
    { lat: -0.223, lng: 98.0 },
    { lat: 0.0, lng: 97.649 },
    { lat: 0.118, lng: 97.435 },
    { lat: 0.565, lng: 96.956 },
    { lat: 0.62, lng: 96.878 },
    { lat: 0.992, lng: 96.315 },
    { lat: 1.13, lng: 96.132 },
    { lat: 1.483, lng: 95.753 },
    { lat: 1.696, lng: 95.285 },
    { lat: 1.73, lng: 95.192 },
    { lat: 1.88, lng: 94.628 },
    { lat: 2.003, lng: 94.064 },
    { lat: 2.068, lng: 93.501 },
    { lat: 1.965, lng: 92.937 },
    { lat: 1.813, lng: 92.371 },
    { lat: 1.697, lng: 92.089 },
    { lat: 1.586, lng: 91.806 },
    { lat: 1.238, lng: 91.24 },
    { lat: 1.132, lng: 90.903 },
    { lat: 1.077, lng: 90.673 },
    { lat: 1.056, lng: 90.104 },
    { lat: 1.087, lng: 89.533 },
    { lat: 1.102, lng: 88.961 },
    { lat: 1.133, lng: 88.853 },
    { lat: 1.262, lng: 88.387 },
    { lat: 1.457, lng: 87.812 },
    { lat: 1.602, lng: 87.234 },
    { lat: 1.702, lng: 86.823 },
    { lat: 1.744, lng: 86.655 },
    { lat: 1.883, lng: 86.073 },
    { lat: 2.058, lng: 85.489 },
    { lat: 2.08, lng: 84.905 },
    { lat: 2.121, lng: 84.318 },
    { lat: 2.135, lng: 83.729 },
    { lat: 2.074, lng: 83.138 },
    { lat: 1.947, lng: 82.545 },
    { lat: 1.834, lng: 81.95 },
    { lat: 1.71, lng: 81.521 },
    { lat: 1.659, lng: 81.352 },
    { lat: 1.389, lng: 80.752 },
    { lat: 1.192, lng: 80.149 },
    { lat: 1.141, lng: 79.966 },
    { lat: 1.025, lng: 79.541 },
    { lat: 0.875, lng: 78.93 },
    { lat: 0.684, lng: 78.316 },
    { lat: 0.608, lng: 77.697 },
    { lat: 0.584, lng: 77.074 },
    { lat: 0.595, lng: 76.446 },
    { lat: 0.646, lng: 75.814 },
    { lat: 0.795, lng: 75.178 },
    { lat: 0.942, lng: 74.535 },
    { lat: 1.088, lng: 73.888 },
    { lat: 1.151, lng: 73.59 },
    { lat: 1.236, lng: 73.236 },
    { lat: 1.351, lng: 72.578 },
    { lat: 1.384, lng: 71.915 },
    { lat: 1.345, lng: 71.247 },
    { lat: 1.287, lng: 70.574 },
    { lat: 1.157, lng: 69.904 },
    { lat: 1.156, lng: 69.895 },
    { lat: 0.837, lng: 69.211 },
    { lat: 0.607, lng: 68.519 },
    { lat: 0.58, lng: 68.464 },
    { lat: 0.3, lng: 67.819 },
    { lat: -0.0, lng: 67.292 },
    { lat: -0.1, lng: 67.109 },
    { lat: -0.367, lng: 66.391 },
    { lat: -0.569, lng: 65.663 },
    { lat: -0.583, lng: 65.613 },
    { lat: -0.742, lng: 64.925 },
    { lat: -0.913, lng: 64.177 },
    { lat: -1.07, lng: 63.419 },
    { lat: -1.173, lng: 62.716 },
    { lat: -1.182, lng: 62.651 },
    { lat: -1.343, lng: 61.869 },
    { lat: -1.322, lng: 61.079 },
    { lat: -1.286, lng: 60.277 },
    { lat: -1.18, lng: 59.568 },
    { lat: -1.157, lng: 59.462 },
    { lat: -0.904, lng: 58.636 },
    { lat: -0.592, lng: 57.803 },
    { lat: -0.586, lng: 57.793 },
    { lat: -0.44, lng: 56.931 },
    { lat: -0.291, lng: 56.052 },
    { lat: -0.314, lng: 55.151 },
    { lat: -0.597, lng: 54.231 },
    { lat: -0.598, lng: 54.228 },
    { lat: -0.842, lng: 53.281 },
    { lat: -1.02, lng: 52.31 },
    { lat: -1.193, lng: 51.311 },
    { lat: -1.202, lng: 51.28 },
    { lat: -1.459, lng: 50.279 },
    { lat: -1.678, lng: 49.215 },
    { lat: -1.815, lng: 48.62 },
    { lat: -1.905, lng: 48.113 },
    { lat: -2.072, lng: 46.972 },
    { lat: -2.25, lng: 45.785 },
    { lat: -2.421, lng: 44.546 },
    { lat: -2.447, lng: 44.176 },
    { lat: -2.503, lng: 43.253 },
    { lat: -2.468, lng: 41.904 },
    { lat: -2.495, lng: 40.469 },
    { lat: -2.479, lng: 39.049 },
    { lat: -2.478, lng: 38.944 },
    { lat: -2.267, lng: 37.323 },
    { lat: -1.876, lng: 35.573 },
    { lat: -1.876, lng: 35.573 },
    { lat: -1.254, lng: 34.626 },
    { lat: -0.626, lng: 34.902 },
    { lat: -0.0, lng: 35.608 },
    { lat: 0.025, lng: 35.66 },
    { lat: 0.623, lng: 36.802 },
    { lat: 0.909, lng: 37.419 },
    { lat: 1.243, lng: 38.024 },
    { lat: 1.776, lng: 39.006 },
    { lat: 1.859, lng: 39.166 },
    { lat: 2.473, lng: 40.037 },
    { lat: 2.767, lng: 40.442 },
    { lat: 3.086, lng: 40.841 },
    { lat: 3.699, lng: 41.316 },
    { lat: 4.316, lng: 41.412 },
    { lat: 4.938, lng: 41.155 },
    { lat: 5.565, lng: 40.62 },
    { lat: 6.078, lng: 39.878 },
    { lat: 6.2, lng: 39.713 },
    { lat: 6.845, lng: 38.508 },
    { lat: 7.091, lng: 38.017 },
    { lat: 7.498, lng: 37.161 },
    { lat: 8.06, lng: 35.933 },
    { lat: 8.159, lng: 35.708 },
    { lat: 8.823, lng: 34.389 },
    { lat: 9.3, lng: 33.386 },
    { lat: 9.496, lng: 32.939 },
    { lat: 10.167, lng: 31.85 },
    { lat: 10.832, lng: 31.113 },
    { lat: 11.485, lng: 30.921 },
    { lat: 12.127, lng: 31.135 },
    { lat: 12.352, lng: 31.389 },
    { lat: 12.756, lng: 31.744 },
    { lat: 13.377, lng: 32.601 },
    { lat: 13.478, lng: 32.833 },
    { lat: 13.978, lng: 33.973 },
    { lat: 14.175, lng: 34.426 },
    { lat: 14.578, lng: 35.327 },
    { lat: 14.848, lng: 35.885 },
    { lat: 15.182, lng: 36.527 },
    { lat: 15.489, lng: 37.246 },
    { lat: 15.773, lng: 38.009 },
    { lat: 16.018, lng: 38.585 },
    { lat: 16.364, lng: 39.462 },
    { lat: 16.507, lng: 39.867 },
    { lat: 16.89, lng: 41.142 },
    { lat: 16.935, lng: 41.328 },
    { lat: 17.198, lng: 42.399 },
    { lat: 17.495, lng: 43.413 },
    { lat: 17.544, lng: 43.574 },
    { lat: 17.748, lng: 44.779 },
    { lat: 17.978, lng: 45.922 },
    { lat: 18.029, lng: 46.069 },
    { lat: 18.359, lng: 46.944 },
    { lat: 18.616, lng: 47.48 },
    { lat: 18.894, lng: 47.853 },
    { lat: 19.233, lng: 48.227 },
    { lat: 19.716, lng: 48.582 },
    { lat: 19.867, lng: 48.651 },
    { lat: 20.516, lng: 48.807 },
    { lat: 21.124, lng: 48.961 },
    { lat: 21.169, lng: 48.972 },
    { lat: 21.835, lng: 48.927 },
    { lat: 22.515, lng: 48.705 },
    { lat: 23.206, lng: 48.363 },
    { lat: 23.914, lng: 47.843 },
    { lat: 24.637, lng: 47.164 },
    { lat: 25.361, lng: 46.584 },
    { lat: 26.1, lng: 45.877 },
    { lat: 26.85, lng: 45.129 },
    { lat: 27.61, lng: 44.354 },
    { lat: 28.375, lng: 43.626 },
    { lat: 29.149, lng: 42.907 },
    { lat: 29.9, lng: 42.626 },
    { lat: 30.256, lng: 43.092 },
    { lat: 30.527, lng: 44.105 },
    { lat: 30.548, lng: 44.249 },
    { lat: 30.832, lng: 45.373 },
    { lat: 30.953, lng: 46.614 },
    { lat: 31.035, lng: 47.27 },
    { lat: 31.089, lng: 47.797 },
    { lat: 31.134, lng: 49.02 },
    { lat: 31.136, lng: 50.237 },
    { lat: 31.119, lng: 51.429 },
    { lat: 31.08, lng: 52.601 },
    { lat: 30.991, lng: 53.773 },
    { lat: 30.91, lng: 54.904 },
    { lat: 30.845, lng: 55.995 },
    { lat: 30.82, lng: 57.032 },
    { lat: 30.863, lng: 58.005 },
    { lat: 30.93, lng: 58.945 },
    { lat: 30.957, lng: 59.274 },
    { lat: 30.995, lng: 59.87 },
    { lat: 30.994, lng: 60.815 },
    { lat: 30.957, lng: 61.762 },
    { lat: 30.891, lng: 62.706 },
    { lat: 30.705, lng: 63.515 },
    { lat: 30.65, lng: 63.718 },
    { lat: 30.227, lng: 64.788 },
    { lat: 29.877, lng: 65.491 },
    { lat: 29.674, lng: 65.883 },
    { lat: 29.104, lng: 66.677 },
    { lat: 28.801, lng: 67.068 },
    { lat: 28.359, lng: 67.544 },
    { lat: 27.628, lng: 68.296 },
    { lat: 27.607, lng: 68.317 },
    { lat: 26.909, lng: 68.977 },
    { lat: 26.368, lng: 69.515 },
    { lat: 26.198, lng: 69.68 },
    { lat: 25.494, lng: 70.387 },
    { lat: 25.281, lng: 70.607 },
    { lat: 24.795, lng: 71.136 },
    { lat: 24.391, lng: 71.6 },
    { lat: 24.102, lng: 71.943 },
    { lat: 23.63, lng: 72.526 },
    { lat: 23.415, lng: 72.791 },
    { lat: 22.845, lng: 73.43 },
    { lat: 22.738, lng: 73.562 },
    { lat: 22.103, lng: 74.299 },
    { lat: 22.067, lng: 74.342 },
    { lat: 21.426, lng: 75.132 },
    { lat: 21.401, lng: 75.162 },
    { lat: 20.761, lng: 75.943 },
    { lat: 20.742, lng: 75.968 },
    { lat: 20.192, lng: 76.719 },
    { lat: 20.086, lng: 76.902 },
    { lat: 19.775, lng: 77.457 },
    { lat: 19.538, lng: 78.157 },
    { lat: 19.417, lng: 78.624 },
    { lat: 19.364, lng: 78.841 },
    { lat: 19.339, lng: 79.498 },
    { lat: 19.384, lng: 80.137 },
    { lat: 19.385, lng: 80.142 },
    { lat: 19.517, lng: 80.772 },
    { lat: 19.628, lng: 81.401 },
    { lat: 19.889, lng: 82.011 },
    { lat: 19.968, lng: 82.188 },
    { lat: 20.164, lng: 82.618 },
    { lat: 20.421, lng: 83.226 },
    { lat: 20.571, lng: 83.491 },
    { lat: 20.793, lng: 83.821 },
    { lat: 21.185, lng: 84.414 },
    { lat: 21.185, lng: 84.415 },
    { lat: 21.537, lng: 85.012 },
    { lat: 21.804, lng: 85.349 },
    { lat: 22.03, lng: 85.596 },
    { lat: 22.432, lng: 86.001 },
    { lat: 22.623, lng: 86.171 },
    { lat: 23.058, lng: 86.764 },
    { lat: 23.062, lng: 86.768 },
    { lat: 23.618, lng: 87.347 },
    { lat: 23.699, lng: 87.449 },
    { lat: 24.11, lng: 87.94 },
    { lat: 24.338, lng: 88.328 },
    { lat: 24.444, lng: 88.549 },
    { lat: 24.578, lng: 89.176 },
    { lat: 24.641, lng: 89.808 },
    { lat: 24.509, lng: 90.453 },
    { lat: 24.306, lng: 90.87 },
    { lat: 24.144, lng: 91.11 },
    { lat: 23.646, lng: 91.762 },
    { lat: 23.641, lng: 91.769 },
    { lat: 23.124, lng: 92.42 },
    { lat: 22.994, lng: 92.566 },
    { lat: 22.493, lng: 93.069 },
    { lat: 22.348, lng: 93.224 },
    { lat: 21.987, lng: 93.706 },
    { lat: 21.706, lng: 94.107 },
    { lat: 21.551, lng: 94.334 },
    { lat: 21.193, lng: 94.954 },
    { lat: 21.07, lng: 95.344 },
    { lat: 21.0, lng: 95.568 },
    { lat: 20.962, lng: 96.177 },
    { lat: 20.953, lng: 96.785 },
    { lat: 20.939, lng: 97.392 },
    { lat: 20.937, lng: 98.0 },
    { lat: 20.951, lng: 98.608 },
    { lat: 20.901, lng: 99.215 },
    { lat: 20.832, lng: 99.822 },
    { lat: 20.787, lng: 100.428 },
    { lat: 20.695, lng: 101.034 },
    { lat: 20.566, lng: 101.638 },
    { lat: 20.456, lng: 102.243 },
    { lat: 20.511, lng: 102.853 },
    { lat: 20.597, lng: 103.466 },
    { lat: 20.715, lng: 104.082 },
    { lat: 20.938, lng: 104.706 },
    { lat: 21.097, lng: 104.965 },
    { lat: 21.322, lng: 105.343 },
    { lat: 21.738, lng: 105.85 },
    { lat: 21.847, lng: 105.995 },
    { lat: 22.383, lng: 106.607 },
    { lat: 22.425, lng: 106.658 },
    { lat: 23.034, lng: 107.29 },
    { lat: 23.077, lng: 107.336 },
    { lat: 23.69, lng: 107.936 },
    { lat: 23.783, lng: 108.031 },
    { lat: 24.352, lng: 108.587 },
    { lat: 24.495, lng: 108.738 },
    { lat: 25.021, lng: 109.314 },
    { lat: 25.135, lng: 109.452 },
    { lat: 25.699, lng: 110.107 },
    { lat: 25.754, lng: 110.175 },
    { lat: 26.325, lng: 110.906 },
    { lat: 26.385, lng: 110.982 },
    { lat: 26.904, lng: 111.651 },
    { lat: 27.08, lng: 111.857 },
    { lat: 27.592, lng: 112.426 },
    { lat: 27.781, lng: 112.633 },
    { lat: 28.327, lng: 113.227 },
    { lat: 28.49, lng: 113.422 },
    { lat: 29.031, lng: 114.043 },
    { lat: 29.209, lng: 114.236 },
    { lat: 29.896, lng: 114.911 },
    { lat: 29.935, lng: 114.95 },
    { lat: 30.667, lng: 115.598 },
    { lat: 30.976, lng: 115.854 },
    { lat: 31.412, lng: 116.325 },
    { lat: 31.961, lng: 116.812 },
    { lat: 32.168, lng: 117.081 },
    { lat: 32.934, lng: 117.804 },
    { lat: 32.939, lng: 117.807 },
    { lat: 33.704, lng: 118.342 },
    { lat: 34.164, lng: 118.146 },
    { lat: 34.448, lng: 117.952 },
    { lat: 34.643, lng: 117.516 },
    { lat: 34.945, lng: 116.835 },
    { lat: 35.156, lng: 116.373 },
    { lat: 35.229, lng: 116.147 },
    { lat: 35.506, lng: 115.457 },
    { lat: 35.809, lng: 114.772 },
    { lat: 35.872, lng: 114.672 },
    { lat: 36.245, lng: 114.118 },
    { lat: 36.627, lng: 113.68 },
    { lat: 36.852, lng: 113.5 },
    { lat: 37.411, lng: 113.181 },
    { lat: 38.209, lng: 113.052 },
    { lat: 38.221, lng: 113.05 },
    { lat: 39.053, lng: 113.19 },
    { lat: 39.832, lng: 113.459 },
    { lat: 39.906, lng: 113.486 },
    { lat: 40.791, lng: 114.184 },
    { lat: 41.268, lng: 114.673 },
    { lat: 41.709, lng: 115.229 },
    { lat: 42.075, lng: 115.76 },
    { lat: 42.561, lng: 116.778 },
    { lat: 42.71, lng: 117.61 },
    { lat: 42.727, lng: 117.702 },
    { lat: 42.821, lng: 118.612 },
    { lat: 42.875, lng: 119.515 },
    { lat: 42.864, lng: 120.4 },
    { lat: 43.105, lng: 121.407 },
    { lat: 43.727, lng: 122.621 },
    { lat: 43.911, lng: 122.851 },
    { lat: 44.876, lng: 124.171 },
    { lat: 44.944, lng: 124.273 },
    { lat: 45.846, lng: 125.719 },
    { lat: 46.033, lng: 126.065 },
    { lat: 46.628, lng: 127.239 },
    { lat: 47.198, lng: 128.382 },
    { lat: 47.386, lng: 128.827 },
    { lat: 47.636, lng: 130.104 },
    { lat: 47.546, lng: 131.14 },
    { lat: 47.446, lng: 131.543 },
  ],
  c13: [
    { lat: 12.535, lng: 17.059 },
    { lat: 13.083, lng: 22.292 },
    { lat: 13.3, lng: 23.585 },
    { lat: 13.645, lng: 25.176 },
    { lat: 13.964, lng: 26.829 },
    { lat: 14.211, lng: 27.753 },
    { lat: 14.586, lng: 29.277 },
    { lat: 14.786, lng: 29.955 },
    { lat: 15.178, lng: 31.325 },
    { lat: 15.365, lng: 31.952 },
    { lat: 15.775, lng: 33.099 },
    { lat: 15.958, lng: 33.545 },
    { lat: 16.406, lng: 34.653 },
    { lat: 16.553, lng: 35.021 },
    { lat: 16.974, lng: 36.12 },
    { lat: 17.144, lng: 36.567 },
    { lat: 17.498, lng: 37.508 },
    { lat: 17.731, lng: 38.147 },
    { lat: 18.006, lng: 38.815 },
    { lat: 18.313, lng: 39.825 },
    { lat: 18.423, lng: 40.109 },
    { lat: 18.904, lng: 41.255 },
    { lat: 18.916, lng: 41.279 },
    { lat: 19.358, lng: 42.428 },
    { lat: 19.486, lng: 42.881 },
    { lat: 19.723, lng: 43.578 },
    { lat: 20.078, lng: 44.291 },
    { lat: 20.268, lng: 44.563 },
    { lat: 20.705, lng: 45.014 },
    { lat: 21.166, lng: 45.283 },
    { lat: 21.352, lng: 45.381 },
    { lat: 22.015, lng: 45.508 },
    { lat: 22.689, lng: 45.488 },
    { lat: 22.846, lng: 45.431 },
    { lat: 23.384, lng: 45.186 },
    { lat: 24.098, lng: 44.651 },
    { lat: 24.822, lng: 44.068 },
    { lat: 25.563, lng: 43.313 },
    { lat: 25.77, lng: 43.096 },
    { lat: 26.316, lng: 42.502 },
    { lat: 27.094, lng: 41.434 },
    { lat: 27.89, lng: 40.257 },
    { lat: 28.712, lng: 38.848 },
    { lat: 29.363, lng: 37.534 },
    { lat: 29.576, lng: 37.055 },
    { lat: 30.449, lng: 35.314 },
    { lat: 31.349, lng: 33.419 },
    { lat: 32.231, lng: 31.934 },
    { lat: 32.708, lng: 31.957 },
    { lat: 32.959, lng: 32.439 },
    { lat: 33.13, lng: 33.565 },
    { lat: 33.121, lng: 35.79 },
    { lat: 33.053, lng: 37.88 },
    { lat: 32.868, lng: 39.947 },
    { lat: 32.717, lng: 41.803 },
    { lat: 32.735, lng: 43.336 },
    { lat: 32.693, lng: 44.856 },
    { lat: 32.695, lng: 45.151 },
    { lat: 32.693, lng: 46.257 },
    { lat: 32.639, lng: 47.65 },
    { lat: 32.658, lng: 48.919 },
    { lat: 32.606, lng: 50.205 },
    { lat: 32.533, lng: 51.463 },
    { lat: 32.442, lng: 52.692 },
    { lat: 32.363, lng: 53.872 },
    { lat: 32.244, lng: 55.047 },
    { lat: 32.176, lng: 56.151 },
    { lat: 32.135, lng: 57.211 },
    { lat: 32.166, lng: 58.2 },
    { lat: 32.151, lng: 59.198 },
    { lat: 32.155, lng: 60.164 },
    { lat: 32.117, lng: 61.136 },
    { lat: 32.037, lng: 62.113 },
    { lat: 31.828, lng: 63.136 },
    { lat: 31.557, lng: 64.167 },
    { lat: 31.369, lng: 64.649 },
    { lat: 31.119, lng: 65.249 },
    { lat: 30.556, lng: 66.243 },
    { lat: 30.459, lng: 66.395 },
    { lat: 29.785, lng: 67.264 },
    { lat: 29.448, lng: 67.638 },
    { lat: 29.036, lng: 68.058 },
    { lat: 28.303, lng: 68.733 },
    { lat: 28.076, lng: 68.948 },
    { lat: 27.579, lng: 69.398 },
    { lat: 26.863, lng: 70.057 },
    { lat: 26.746, lng: 70.168 },
    { lat: 26.153, lng: 70.752 },
    { lat: 25.681, lng: 71.246 },
    { lat: 25.45, lng: 71.488 },
    { lat: 24.794, lng: 72.231 },
    { lat: 24.752, lng: 72.282 },
    { lat: 24.066, lng: 73.144 },
    { lat: 24.06, lng: 73.152 },
    { lat: 23.376, lng: 74.022 },
    { lat: 23.374, lng: 74.024 },
    { lat: 22.735, lng: 74.867 },
    { lat: 22.695, lng: 74.921 },
    { lat: 22.09, lng: 75.693 },
    { lat: 22.024, lng: 75.781 },
    { lat: 21.47, lng: 76.496 },
    { lat: 21.359, lng: 76.69 },
    { lat: 21.043, lng: 77.249 },
    { lat: 20.733, lng: 77.971 },
    { lat: 20.686, lng: 78.126 },
    { lat: 20.525, lng: 78.669 },
    { lat: 20.459, lng: 79.339 },
    { lat: 20.493, lng: 79.99 },
    { lat: 20.617, lng: 80.625 },
    { lat: 20.627, lng: 80.666 },
    { lat: 20.781, lng: 81.253 },
    { lat: 21.066, lng: 81.863 },
    { lat: 21.227, lng: 82.217 },
    { lat: 21.354, lng: 82.473 },
    { lat: 21.687, lng: 83.076 },
    { lat: 21.842, lng: 83.264 },
    { lat: 22.19, lng: 83.659 },
    { lat: 22.467, lng: 84.038 },
    { lat: 22.643, lng: 84.248 },
    { lat: 23.096, lng: 84.826 },
    { lat: 23.106, lng: 84.838 },
    { lat: 23.734, lng: 85.373 },
    { lat: 23.777, lng: 85.406 },
    { lat: 24.377, lng: 85.894 },
    { lat: 24.466, lng: 85.974 },
    { lat: 24.931, lng: 86.571 },
    { lat: 25.022, lng: 86.666 },
    { lat: 25.464, lng: 87.162 },
    { lat: 25.671, lng: 87.479 },
    { lat: 25.838, lng: 87.773 },
    { lat: 26.055, lng: 88.401 },
    { lat: 26.248, lng: 89.032 },
    { lat: 26.215, lng: 89.683 },
    { lat: 26.089, lng: 90.338 },
    { lat: 25.845, lng: 90.999 },
    { lat: 25.617, lng: 91.512 },
    { lat: 25.544, lng: 91.658 },
    { lat: 25.086, lng: 92.321 },
    { lat: 24.946, lng: 92.508 },
    { lat: 24.572, lng: 92.978 },
    { lat: 24.284, lng: 93.367 },
    { lat: 24.057, lng: 93.629 },
    { lat: 23.628, lng: 94.155 },
    { lat: 23.528, lng: 94.272 },
    { lat: 23.017, lng: 94.908 },
    { lat: 22.979, lng: 94.983 },
    { lat: 22.739, lng: 95.533 },
    { lat: 22.611, lng: 96.152 },
    { lat: 22.568, lng: 96.769 },
    { lat: 22.403, lng: 97.385 },
    { lat: 22.332, lng: 97.719 },
    { lat: 22.286, lng: 98.0 },
    { lat: 22.207, lng: 98.614 },
    { lat: 22.1, lng: 99.226 },
    { lat: 21.949, lng: 99.838 },
    { lat: 21.867, lng: 100.449 },
    { lat: 21.782, lng: 101.06 },
    { lat: 21.704, lng: 101.455 },
    { lat: 21.662, lng: 101.67 },
    { lat: 21.609, lng: 102.281 },
    { lat: 21.674, lng: 102.897 },
    { lat: 21.714, lng: 103.153 },
    { lat: 21.767, lng: 103.517 },
    { lat: 21.892, lng: 104.139 },
    { lat: 22.15, lng: 104.772 },
    { lat: 22.367, lng: 105.115 },
    { lat: 22.548, lng: 105.417 },
    { lat: 23.019, lng: 106.064 },
    { lat: 23.026, lng: 106.074 },
    { lat: 23.52, lng: 106.74 },
    { lat: 23.676, lng: 106.909 },
    { lat: 24.135, lng: 107.425 },
    { lat: 24.337, lng: 107.629 },
    { lat: 24.807, lng: 108.126 },
    { lat: 25.005, lng: 108.333 },
    { lat: 25.437, lng: 108.835 },
    { lat: 25.681, lng: 109.125 },
    { lat: 26.027, lng: 109.552 },
    { lat: 26.365, lng: 109.967 },
    { lat: 26.619, lng: 110.282 },
    { lat: 27.058, lng: 110.833 },
    { lat: 27.206, lng: 111.024 },
    { lat: 27.759, lng: 111.703 },
    { lat: 27.828, lng: 111.786 },
    { lat: 28.467, lng: 112.506 },
    { lat: 28.536, lng: 112.577 },
    { lat: 29.182, lng: 113.227 },
    { lat: 29.38, lng: 113.411 },
    { lat: 29.905, lng: 113.932 },
    { lat: 30.409, lng: 114.306 },
    { lat: 30.633, lng: 114.486 },
    { lat: 31.367, lng: 114.935 },
    { lat: 32.108, lng: 115.309 },
    { lat: 32.849, lng: 115.407 },
    { lat: 33.588, lng: 115.168 },
    { lat: 33.734, lng: 115.022 },
    { lat: 34.212, lng: 114.391 },
    { lat: 34.314, lng: 114.271 },
    { lat: 34.671, lng: 113.752 },
    { lat: 35.047, lng: 113.228 },
    { lat: 35.13, lng: 113.109 },
    { lat: 35.668, lng: 112.479 },
    { lat: 35.795, lng: 112.315 },
    { lat: 36.302, lng: 111.862 },
    { lat: 36.564, lng: 111.663 },
    { lat: 37.356, lng: 111.412 },
    { lat: 37.872, lng: 111.431 },
    { lat: 38.17, lng: 111.45 },
    { lat: 39.002, lng: 111.639 },
    { lat: 39.153, lng: 111.71 },
    { lat: 39.857, lng: 112.048 },
    { lat: 40.734, lng: 112.6 },
    { lat: 41.3, lng: 113.037 },
    { lat: 41.634, lng: 113.321 },
    { lat: 42.562, lng: 114.201 },
    { lat: 42.595, lng: 114.235 },
    { lat: 43.487, lng: 115.372 },
    { lat: 43.528, lng: 115.442 },
    { lat: 44.052, lng: 116.441 },
    { lat: 44.395, lng: 117.457 },
    { lat: 44.531, lng: 118.411 },
    { lat: 44.64, lng: 119.071 },
    { lat: 44.692, lng: 119.388 },
    { lat: 44.823, lng: 120.366 },
    { lat: 45.031, lng: 121.397 },
    { lat: 45.441, lng: 122.553 },
    { lat: 45.85, lng: 123.305 },
    { lat: 46.206, lng: 123.944 },
    { lat: 46.959, lng: 125.073 },
    { lat: 47.307, lng: 125.618 },
    { lat: 48.121, lng: 127.008 },
    { lat: 48.299, lng: 127.338 },
    { lat: 49.077, lng: 129.013 },
    { lat: 49.416, lng: 130.064 },
    { lat: 49.555, lng: 130.536 },
    { lat: 49.836, lng: 131.949 },
    { lat: 49.896, lng: 133.201 },
    { lat: 49.78, lng: 134.288 },
    { lat: 49.777, lng: 134.306 },
    { lat: 49.532, lng: 135.297 },
    { lat: 49.203, lng: 136.209 },
    { lat: 48.964, lng: 137.028 },
    { lat: 48.905, lng: 137.158 },
    { lat: 48.817, lng: 138.346 },
    { lat: 48.433, lng: 139.218 },
    { lat: 48.18, lng: 139.765 },
    { lat: 48.001, lng: 140.039 },
    { lat: 47.24, lng: 140.481 },
    { lat: 47.232, lng: 140.483 },
    { lat: 46.222, lng: 140.299 },
    { lat: 45.904, lng: 140.283 },
    { lat: 45.231, lng: 140.047 },
    { lat: 44.238, lng: 139.53 },
    { lat: 43.79, lng: 139.336 },
    { lat: 43.278, lng: 139.13 },
    { lat: 42.344, lng: 138.796 },
    { lat: 41.953, lng: 138.783 },
    { lat: 41.453, lng: 138.753 },
    { lat: 40.723, lng: 138.837 },
    { lat: 40.587, lng: 138.825 },
    { lat: 39.744, lng: 138.995 },
    { lat: 39.621, lng: 139.021 },
    { lat: 38.904, lng: 139.032 },
    { lat: 38.304, lng: 139.042 },
    { lat: 38.075, lng: 139.025 },
    { lat: 37.261, lng: 139.065 },
    { lat: 37.003, lng: 139.103 },
    { lat: 36.464, lng: 139.168 },
    { lat: 35.668, lng: 139.128 },
    { lat: 35.616, lng: 139.124 },
    { lat: 34.882, lng: 139.095 },
    { lat: 34.102, lng: 139.001 },
    { lat: 33.916, lng: 138.957 },
    { lat: 33.328, lng: 138.837 },
    { lat: 32.561, lng: 138.653 },
    { lat: 31.796, lng: 138.346 },
    { lat: 31.039, lng: 138.031 },
    { lat: 30.654, lng: 137.87 },
    { lat: 30.292, lng: 137.734 },
    { lat: 29.541, lng: 137.238 },
    { lat: 28.805, lng: 136.85 },
    { lat: 28.563, lng: 136.705 },
    { lat: 28.075, lng: 136.423 },
    { lat: 27.35, lng: 135.951 },
    { lat: 26.633, lng: 135.485 },
    { lat: 25.924, lng: 135.039 },
    { lat: 25.219, lng: 134.536 },
    { lat: 24.522, lng: 134.051 },
    { lat: 24.295, lng: 133.87 },
    { lat: 23.828, lng: 133.487 },
    { lat: 23.14, lng: 132.932 },
    { lat: 22.462, lng: 132.458 },
    { lat: 22.235, lng: 132.287 },
    { lat: 21.786, lng: 131.867 },
    { lat: 21.113, lng: 131.244 },
    { lat: 20.907, lng: 131.061 },
    { lat: 20.444, lng: 130.546 },
    { lat: 19.818, lng: 129.969 },
    { lat: 19.784, lng: 129.943 },
    { lat: 19.132, lng: 129.422 },
    { lat: 18.489, lng: 129.026 },
    { lat: 18.115, lng: 128.779 },
    { lat: 17.846, lng: 128.481 },
    { lat: 17.205, lng: 127.855 },
    { lat: 17.167, lng: 127.825 },
    { lat: 16.575, lng: 127.469 },
    { lat: 15.951, lng: 127.149 },
    { lat: 15.33, lng: 126.845 },
    { lat: 15.087, lng: 126.697 },
    { lat: 14.712, lng: 126.495 },
    { lat: 14.097, lng: 126.168 },
    { lat: 13.484, lng: 125.755 },
    { lat: 13.422, lng: 125.718 },
    { lat: 12.875, lng: 125.4 },
    { lat: 12.268, lng: 124.971 },
    { lat: 12.079, lng: 124.836 },
    { lat: 11.664, lng: 124.583 },
    { lat: 11.063, lng: 124.213 },
    { lat: 10.698, lng: 123.99 },
    { lat: 10.465, lng: 123.851 },
    { lat: 9.869, lng: 123.494 },
    { lat: 9.408, lng: 123.187 },
    { lat: 9.275, lng: 123.106 },
    { lat: 8.685, lng: 122.835 },
    { lat: 8.097, lng: 122.592 },
    { lat: 7.581, lng: 122.376 },
    { lat: 7.511, lng: 122.347 },
    { lat: 6.927, lng: 122.133 },
    { lat: 6.344, lng: 121.947 },
    { lat: 5.762, lng: 121.725 },
    { lat: 5.413, lng: 121.593 },
    { lat: 5.182, lng: 121.49 },
    { lat: 4.603, lng: 121.249 },
    { lat: 4.025, lng: 121.07 },
    { lat: 3.448, lng: 120.873 },
    { lat: 3.444, lng: 120.872 },
    { lat: 2.872, lng: 120.672 },
    { lat: 2.296, lng: 120.426 },
    { lat: 1.923, lng: 120.199 },
    { lat: 1.721, lng: 119.996 },
    { lat: 1.344, lng: 119.559 },
    { lat: 1.146, lng: 119.355 },
    { lat: 0.7, lng: 118.927 },
    { lat: 0.573, lng: 118.776 },
    { lat: 0.206, lng: 118.302 },
    { lat: 0.051, lng: 117.682 },
    { lat: 0.0, lng: 117.579 },
    { lat: -0.235, lng: 117.067 },
    { lat: -0.571, lng: 116.547 },
    { lat: -0.623, lng: 116.456 },
    { lat: -0.902, lng: 115.849 },
    { lat: -1.141, lng: 115.498 },
    { lat: -1.32, lng: 115.247 },
    { lat: -1.71, lng: 114.941 },
    { lat: -2.049, lng: 114.652 },
    { lat: -2.28, lng: 114.377 },
    { lat: -2.533, lng: 114.059 },
    { lat: -2.848, lng: 113.711 },
    { lat: -3.037, lng: 113.47 },
    { lat: -3.417, lng: 113.11 },
    { lat: -3.663, lng: 112.887 },
    { lat: -3.986, lng: 112.577 },
    { lat: -4.244, lng: 112.307 },
    { lat: -4.554, lng: 111.797 },
    { lat: -4.61, lng: 111.724 },
    { lat: -5.001, lng: 111.145 },
    { lat: -5.122, lng: 111.021 },
    { lat: -5.525, lng: 110.57 },
    { lat: -5.69, lng: 110.196 },
    { lat: -5.783, lng: 109.991 },
    { lat: -5.977, lng: 109.413 },
    { lat: -6.122, lng: 108.834 },
    { lat: -6.255, lng: 108.631 },
    { lat: -6.464, lng: 108.262 },
    { lat: -6.637, lng: 107.687 },
    { lat: -6.7, lng: 107.11 },
    { lat: -6.82, lng: 106.904 },
    { lat: -6.994, lng: 106.54 },
    { lat: -7.18, lng: 105.969 },
    { lat: -7.24, lng: 105.395 },
    { lat: -7.296, lng: 104.823 },
    { lat: -7.246, lng: 104.25 },
    { lat: -7.081, lng: 103.676 },
    { lat: -6.809, lng: 103.217 },
    { lat: -6.721, lng: 103.101 },
    { lat: -6.237, lng: 102.628 },
    { lat: -6.082, lng: 102.526 },
    { lat: -5.666, lng: 102.285 },
    { lat: -5.097, lng: 101.97 },
    { lat: -5.067, lng: 101.95 },
    { lat: -4.528, lng: 101.615 },
    { lat: -4.187, lng: 101.38 },
    { lat: -3.961, lng: 101.199 },
    { lat: -3.47, lng: 100.813 },
    { lat: -3.393, lng: 100.729 },
    { lat: -2.959, lng: 100.249 },
    { lat: -2.826, lng: 99.962 },
    { lat: -2.707, lng: 99.685 },
    { lat: -2.371, lng: 99.123 },
    { lat: -2.26, lng: 98.944 },
    { lat: -2.053, lng: 98.562 },
    { lat: -1.764, lng: 98.0 },
    { lat: -1.695, lng: 97.842 },
    { lat: -1.555, lng: 97.439 },
    { lat: -1.321, lng: 96.877 },
    { lat: -1.13, lng: 96.524 },
    { lat: -0.976, lng: 96.317 },
    { lat: -0.565, lng: 95.851 },
    { lat: -0.478, lng: 95.755 },
    { lat: -0.154, lng: 95.192 },
    { lat: -0.042, lng: 94.631 },
    { lat: 0.0, lng: 94.445 },
    { lat: 0.098, lng: 94.067 },
    { lat: 0.048, lng: 93.503 },
    { lat: 0.038, lng: 92.94 },
    { lat: 0.0, lng: 92.625 },
    { lat: -0.027, lng: 92.375 },
    { lat: -0.158, lng: 91.809 },
    { lat: -0.336, lng: 91.242 },
    { lat: -0.344, lng: 90.674 },
    { lat: -0.256, lng: 90.105 },
    { lat: -0.192, lng: 89.535 },
    { lat: -0.095, lng: 88.963 },
    { lat: 0.0, lng: 88.715 },
    { lat: 0.123, lng: 88.39 },
    { lat: 0.31, lng: 87.815 },
    { lat: 0.461, lng: 87.239 },
    { lat: 0.567, lng: 86.719 },
    { lat: 0.58, lng: 86.66 },
    { lat: 0.765, lng: 86.08 },
    { lat: 0.872, lng: 85.497 },
    { lat: 0.941, lng: 84.913 },
    { lat: 1.0, lng: 84.326 },
    { lat: 0.999, lng: 83.738 },
    { lat: 0.929, lng: 83.147 },
    { lat: 0.83, lng: 82.554 },
    { lat: 0.723, lng: 81.958 },
    { lat: 0.57, lng: 81.478 },
    { lat: 0.536, lng: 81.36 },
    { lat: 0.31, lng: 80.758 },
    { lat: 0.132, lng: 80.153 },
    { lat: -0.0, lng: 79.656 },
    { lat: -0.029, lng: 79.545 },
    { lat: -0.209, lng: 78.933 },
    { lat: -0.383, lng: 78.317 },
    { lat: -0.468, lng: 77.697 },
    { lat: -0.554, lng: 77.074 },
    { lat: -0.554, lng: 76.447 },
    { lat: -0.496, lng: 75.815 },
    { lat: -0.376, lng: 75.18 },
    { lat: -0.295, lng: 74.539 },
    { lat: -0.174, lng: 73.894 },
    { lat: -0.03, lng: 73.243 },
    { lat: -0.0, lng: 72.609 },
    { lat: 0.001, lng: 72.587 },
    { lat: 0.023, lng: 71.925 },
    { lat: -0.0, lng: 71.502 },
    { lat: -0.014, lng: 71.257 },
    { lat: -0.051, lng: 70.583 },
    { lat: -0.246, lng: 69.902 },
    { lat: -0.468, lng: 69.214 },
    { lat: -0.58, lng: 68.847 },
    { lat: -0.667, lng: 68.519 },
    { lat: -0.93, lng: 67.814 },
    { lat: -1.163, lng: 67.254 },
    { lat: -1.217, lng: 67.1 },
    { lat: -1.42, lng: 66.378 },
    { lat: -1.574, lng: 65.648 },
    { lat: -1.751, lng: 64.981 },
    { lat: -1.77, lng: 64.907 },
    { lat: -1.938, lng: 64.157 },
    { lat: -2.145, lng: 63.394 },
    { lat: -2.329, lng: 62.62 },
    { lat: -2.347, lng: 62.516 },
    { lat: -2.459, lng: 61.836 },
    { lat: -2.527, lng: 61.042 },
    { lat: -2.572, lng: 60.235 },
    { lat: -2.552, lng: 59.418 },
    { lat: -2.497, lng: 58.588 },
    { lat: -2.423, lng: 57.743 },
    { lat: -2.381, lng: 56.879 },
    { lat: -2.375, lng: 56.821 },
    { lat: -2.268, lng: 56.002 },
    { lat: -2.202, lng: 55.103 },
    { lat: -2.267, lng: 54.178 },
    { lat: -2.333, lng: 53.229 },
    { lat: -2.398, lng: 52.558 },
    { lat: -2.421, lng: 52.255 },
    { lat: -2.536, lng: 51.252 },
    { lat: -2.709, lng: 50.215 },
    { lat: -2.924, lng: 49.141 },
    { lat: -3.026, lng: 48.631 },
    { lat: -3.124, lng: 48.03 },
    { lat: -3.295, lng: 46.88 },
    { lat: -3.434, lng: 45.685 },
    { lat: -3.589, lng: 44.435 },
    { lat: -3.66, lng: 43.134 },
    { lat: -3.687, lng: 42.641 },
    { lat: -3.732, lng: 41.764 },
    { lat: -3.81, lng: 40.312 },
    { lat: -3.852, lng: 38.765 },
    { lat: -3.814, lng: 37.112 },
    { lat: -3.752, lng: 35.895 },
    { lat: -3.718, lng: 35.317 },
    { lat: -3.571, lng: 33.333 },
    { lat: -3.281, lng: 31.096 },
    { lat: -3.172, lng: 30.367 },
    { lat: -2.761, lng: 28.472 },
    { lat: -2.555, lng: 27.748 },
    { lat: -1.924, lng: 26.261 },
    { lat: -1.283, lng: 25.95 },
    { lat: -0.64, lng: 26.924 },
    { lat: -0.0, lng: 28.345 },
    { lat: 0.132, lng: 28.779 },
    { lat: 0.634, lng: 30.391 },
    { lat: 0.932, lng: 31.42 },
    { lat: 1.261, lng: 32.427 },
    { lat: 1.694, lng: 33.61 },
    { lat: 1.884, lng: 34.143 },
    { lat: 2.504, lng: 35.382 },
    { lat: 2.582, lng: 35.496 },
    { lat: 3.122, lng: 36.388 },
    { lat: 3.741, lng: 37.05 },
    { lat: 3.9, lng: 37.097 },
    { lat: 4.364, lng: 37.201 },
    { lat: 4.991, lng: 37.008 },
    { lat: 5.215, lng: 36.824 },
    { lat: 5.626, lng: 36.436 },
    { lat: 6.273, lng: 35.256 },
    { lat: 6.566, lng: 34.567 },
    { lat: 6.931, lng: 33.778 },
    { lat: 7.598, lng: 32.0 },
    { lat: 7.602, lng: 31.992 },
    { lat: 8.28, lng: 30.194 },
    { lat: 8.729, lng: 28.726 },
    { lat: 8.98, lng: 27.802 },
    { lat: 9.704, lng: 24.865 },
    { lat: 10.053, lng: 23.635 },
    { lat: 10.438, lng: 22.042 },
    { lat: 11.199, lng: 18.687 },
    { lat: 11.474, lng: 17.023 },
  ],
};
export var ocngain = {
  c1: "48",
  c2: "48",
  c3: "48",
  c4: "49",
  c5: "47",
  c6: "47",
  c7: "47",
  c8: "47",
  c9: "46",
  c10: "46",
  c11: "46",
  c12: "45",
  c13: "44",
};

export var ocn_gnt = {
  c1: [
    { lat: 46.925, lng: 137.187 },
    { lat: 46.638, lng: 137.306 },
    { lat: 45.947, lng: 137.28 },
    { lat: 45.277, lng: 137.183 },
    { lat: 44.967, lng: 137.093 },
    { lat: 44.001, lng: 136.804 },
    { lat: 43.461, lng: 136.684 },
    { lat: 43.059, lng: 136.545 },
    { lat: 42.133, lng: 136.256 },
    { lat: 41.427, lng: 136.086 },
    { lat: 41.232, lng: 136.032 },
    { lat: 40.351, lng: 135.869 },
    { lat: 39.701, lng: 135.815 },
    { lat: 39.495, lng: 135.808 },
    { lat: 38.658, lng: 135.823 },
    { lat: 38.348, lng: 135.865 },
    { lat: 37.841, lng: 135.931 },
    { lat: 37.111, lng: 136.02 },
    { lat: 37.037, lng: 136.035 },
    { lat: 36.244, lng: 136.144 },
    { lat: 35.839, lng: 136.167 },
    { lat: 35.468, lng: 136.333 },
    { lat: 34.806, lng: 136.489 },
    { lat: 34.702, lng: 136.528 },
    { lat: 33.943, lng: 136.68 },
    { lat: 33.518, lng: 136.66 },
    { lat: 33.179, lng: 136.621 },
    { lat: 32.418, lng: 136.467 },
    { lat: 31.656, lng: 136.167 },
    { lat: 30.901, lng: 135.813 },
    { lat: 30.153, lng: 135.438 },
    { lat: 29.41, lng: 135.005 },
    { lat: 28.675, lng: 134.561 },
    { lat: 27.95, lng: 134.148 },
    { lat: 27.233, lng: 133.761 },
    { lat: 26.526, lng: 133.402 },
    { lat: 25.825, lng: 133.063 },
    { lat: 25.133, lng: 132.755 },
    { lat: 24.446, lng: 132.425 },
    { lat: 23.763, lng: 132.049 },
    { lat: 23.55, lng: 131.915 },
    { lat: 23.083, lng: 131.613 },
    { lat: 22.409, lng: 131.171 },
    { lat: 21.739, lng: 130.704 },
    { lat: 21.33, lng: 130.395 },
    { lat: 21.073, lng: 130.186 },
    { lat: 20.409, lng: 129.595 },
    { lat: 20.07, lng: 129.265 },
    { lat: 19.751, lng: 129.005 },
    { lat: 19.101, lng: 128.498 },
    { lat: 18.603, lng: 128.143 },
    { lat: 18.456, lng: 127.977 },
    { lat: 17.926, lng: 127.252 },
    { lat: 17.804, lng: 127.116 },
    { lat: 17.167, lng: 126.535 },
    { lat: 16.824, lng: 126.3 },
    { lat: 16.538, lng: 126.116 },
    { lat: 15.912, lng: 125.677 },
    { lat: 15.521, lng: 125.351 },
    { lat: 15.286, lng: 125.077 },
    { lat: 14.666, lng: 124.521 },
    { lat: 14.653, lng: 124.511 },
    { lat: 14.054, lng: 124.212 },
    { lat: 13.445, lng: 123.88 },
    { lat: 12.982, lng: 123.587 },
    { lat: 12.837, lng: 123.495 },
    { lat: 12.233, lng: 123.115 },
    { lat: 11.694, lng: 122.755 },
    { lat: 11.631, lng: 122.713 },
    { lat: 11.033, lng: 122.37 },
    { lat: 10.437, lng: 122.047 },
    { lat: 10.23, lng: 121.939 },
    { lat: 9.844, lng: 121.759 },
    { lat: 9.254, lng: 121.482 },
    { lat: 8.665, lng: 121.202 },
    { lat: 8.541, lng: 121.142 },
    { lat: 8.078, lng: 120.894 },
    { lat: 7.493, lng: 120.621 },
    { lat: 6.91, lng: 120.395 },
    { lat: 6.876, lng: 120.385 },
    { lat: 6.329, lng: 120.213 },
    { lat: 5.749, lng: 120.031 },
    { lat: 5.171, lng: 119.886 },
    { lat: 4.593, lng: 119.741 },
    { lat: 4.156, lng: 119.623 },
    { lat: 4.017, lng: 119.577 },
    { lat: 3.441, lng: 119.352 },
    { lat: 2.866, lng: 119.123 },
    { lat: 2.491, lng: 118.949 },
    { lat: 2.291, lng: 118.833 },
    { lat: 1.717, lng: 118.466 },
    { lat: 1.508, lng: 118.31 },
    { lat: 1.144, lng: 118.045 },
    { lat: 0.706, lng: 117.684 },
    { lat: 0.572, lng: 117.54 },
    { lat: 0.209, lng: 117.067 },
    { lat: 0.0, lng: 116.689 },
    { lat: -0.134, lng: 116.455 },
    { lat: -0.499, lng: 115.847 },
    { lat: -0.57, lng: 115.733 },
    { lat: -0.873, lng: 115.244 },
    { lat: -1.14, lng: 114.762 },
    { lat: -1.195, lng: 114.644 },
    { lat: -1.608, lng: 114.048 },
    { lat: -1.709, lng: 113.937 },
    { lat: -2.157, lng: 113.457 },
    { lat: -2.277, lng: 113.279 },
    { lat: -2.527, lng: 112.868 },
    { lat: -2.845, lng: 112.49 },
    { lat: -3.003, lng: 112.283 },
    { lat: -3.413, lng: 111.793 },
    { lat: -3.509, lng: 111.701 },
    { lat: -3.976, lng: 111.122 },
    { lat: -3.98, lng: 111.117 },
    { lat: -4.426, lng: 110.545 },
    { lat: -4.548, lng: 110.368 },
    { lat: -4.758, lng: 109.968 },
    { lat: -4.963, lng: 109.39 },
    { lat: -5.114, lng: 109.049 },
    { lat: -5.213, lng: 108.814 },
    { lat: -5.289, lng: 108.236 },
    { lat: -5.415, lng: 107.661 },
    { lat: -5.539, lng: 107.087 },
    { lat: -5.578, lng: 106.513 },
    { lat: -5.517, lng: 105.938 },
    { lat: -5.319, lng: 105.363 },
    { lat: -5.102, lng: 104.931 },
    { lat: -5.021, lng: 104.788 },
    { lat: -4.591, lng: 104.215 },
    { lat: -4.532, lng: 104.157 },
    { lat: -3.97, lng: 103.641 },
    { lat: -3.963, lng: 103.634 },
    { lat: -3.398, lng: 103.07 },
    { lat: -3.395, lng: 103.067 },
    { lat: -2.977, lng: 102.503 },
    { lat: -2.828, lng: 102.293 },
    { lat: -2.572, lng: 101.937 },
    { lat: -2.261, lng: 101.517 },
    { lat: -2.16, lng: 101.372 },
    { lat: -1.79, lng: 100.808 },
    { lat: -1.696, lng: 100.69 },
    { lat: -1.369, lng: 100.246 },
    { lat: -1.131, lng: 99.903 },
    { lat: -0.98, lng: 99.684 },
    { lat: -0.565, lng: 99.167 },
    { lat: -0.53, lng: 99.124 },
    { lat: -0.078, lng: 98.561 },
    { lat: 0.0, lng: 98.458 },
    { lat: 0.335, lng: 98.0 },
    { lat: 0.562, lng: 97.62 },
    { lat: 0.692, lng: 97.438 },
    { lat: 1.087, lng: 96.876 },
    { lat: 1.13, lng: 96.8 },
    { lat: 1.406, lng: 96.317 },
    { lat: 1.685, lng: 95.754 },
    { lat: 1.696, lng: 95.734 },
    { lat: 1.995, lng: 95.191 },
    { lat: 2.262, lng: 94.749 },
    { lat: 2.336, lng: 94.628 },
    { lat: 2.623, lng: 94.062 },
    { lat: 2.81, lng: 93.498 },
    { lat: 2.828, lng: 93.444 },
    { lat: 3.004, lng: 92.932 },
    { lat: 3.16, lng: 92.365 },
    { lat: 3.232, lng: 91.797 },
    { lat: 3.263, lng: 91.229 },
    { lat: 3.237, lng: 90.661 },
    { lat: 3.171, lng: 90.091 },
    { lat: 3.054, lng: 89.521 },
    { lat: 2.95, lng: 88.949 },
    { lat: 2.834, lng: 88.472 },
    { lat: 2.814, lng: 88.376 },
    { lat: 2.69, lng: 87.802 },
    { lat: 2.572, lng: 87.226 },
    { lat: 2.461, lng: 86.648 },
    { lat: 2.361, lng: 86.069 },
    { lat: 2.271, lng: 85.528 },
    { lat: 2.265, lng: 85.488 },
    { lat: 2.166, lng: 84.904 },
    { lat: 2.094, lng: 84.318 },
    { lat: 2.021, lng: 83.73 },
    { lat: 1.931, lng: 83.139 },
    { lat: 1.869, lng: 82.546 },
    { lat: 1.82, lng: 81.95 },
    { lat: 1.722, lng: 81.351 },
    { lat: 1.71, lng: 81.238 },
    { lat: 1.663, lng: 80.75 },
    { lat: 1.601, lng: 80.145 },
    { lat: 1.494, lng: 79.537 },
    { lat: 1.393, lng: 78.926 },
    { lat: 1.296, lng: 78.311 },
    { lat: 1.189, lng: 77.693 },
    { lat: 1.145, lng: 77.331 },
    { lat: 1.119, lng: 77.07 },
    { lat: 1.057, lng: 76.443 },
    { lat: 1.006, lng: 75.812 },
    { lat: 0.974, lng: 75.176 },
    { lat: 0.927, lng: 74.536 },
    { lat: 0.874, lng: 73.89 },
    { lat: 0.82, lng: 73.24 },
    { lat: 0.737, lng: 72.584 },
    { lat: 0.633, lng: 71.923 },
    { lat: 0.577, lng: 71.558 },
    { lat: 0.535, lng: 71.256 },
    { lat: 0.423, lng: 70.582 },
    { lat: 0.315, lng: 69.902 },
    { lat: 0.189, lng: 69.215 },
    { lat: 0.081, lng: 68.521 },
    { lat: -0.0, lng: 67.993 },
    { lat: -0.024, lng: 67.819 },
    { lat: -0.088, lng: 67.11 },
    { lat: -0.143, lng: 66.391 },
    { lat: -0.159, lng: 65.664 },
    { lat: -0.155, lng: 64.929 },
    { lat: -0.171, lng: 64.183 },
    { lat: -0.185, lng: 63.427 },
    { lat: -0.212, lng: 62.661 },
    { lat: -0.254, lng: 61.883 },
    { lat: -0.336, lng: 61.092 },
    { lat: -0.421, lng: 60.289 },
    { lat: -0.529, lng: 59.471 },
    { lat: -0.591, lng: 59.063 },
    { lat: -0.65, lng: 58.639 },
    { lat: -0.75, lng: 57.791 },
    { lat: -0.866, lng: 56.926 },
    { lat: -0.971, lng: 56.043 },
    { lat: -1.08, lng: 55.14 },
    { lat: -1.193, lng: 54.46 },
    { lat: -1.234, lng: 54.216 },
    { lat: -1.403, lng: 53.267 },
    { lat: -1.611, lng: 52.292 },
    { lat: -1.802, lng: 51.636 },
    { lat: -1.907, lng: 51.285 },
    { lat: -2.207, lng: 50.245 },
    { lat: -2.414, lng: 49.625 },
    { lat: -2.564, lng: 49.167 },
    { lat: -2.893, lng: 48.049 },
    { lat: -3.034, lng: 47.523 },
    { lat: -3.213, lng: 46.887 },
    { lat: -3.493, lng: 45.679 },
    { lat: -3.668, lng: 44.648 },
    { lat: -3.702, lng: 44.422 },
    { lat: -3.859, lng: 43.109 },
    { lat: -3.974, lng: 41.731 },
    { lat: -4.025, lng: 40.279 },
    { lat: -4.016, lng: 38.738 },
    { lat: -3.956, lng: 37.087 },
    { lat: -3.811, lng: 35.3 },
    { lat: -3.762, lng: 34.888 },
    { lat: -3.55, lng: 33.337 },
    { lat: -3.206, lng: 31.113 },
    { lat: -3.168, lng: 30.913 },
    { lat: -2.664, lng: 28.493 },
    { lat: -2.553, lng: 28.029 },
    { lat: -1.923, lng: 26.442 },
    { lat: -1.285, lng: 25.529 },
    { lat: -0.642, lng: 25.869 },
    { lat: -0.0, lng: 27.072 },
    { lat: 0.632, lng: 28.764 },
    { lat: 0.637, lng: 28.776 },
    { lat: 1.266, lng: 31.111 },
    { lat: 1.333, lng: 31.39 },
    { lat: 1.887, lng: 33.417 },
    { lat: 1.93, lng: 33.587 },
    { lat: 2.353, lng: 35.524 },
    { lat: 2.499, lng: 36.042 },
    { lat: 2.899, lng: 37.25 },
    { lat: 3.11, lng: 37.762 },
    { lat: 3.545, lng: 38.812 },
    { lat: 3.719, lng: 39.222 },
    { lat: 4.303, lng: 40.235 },
    { lat: 4.329, lng: 40.272 },
    { lat: 4.944, lng: 40.676 },
    { lat: 5.563, lng: 40.756 },
    { lat: 6.189, lng: 40.405 },
    { lat: 6.712, lng: 39.718 },
    { lat: 6.827, lng: 39.566 },
    { lat: 7.477, lng: 38.219 },
    { lat: 7.613, lng: 37.851 },
    { lat: 8.14, lng: 36.588 },
    { lat: 8.459, lng: 35.773 },
    { lat: 8.814, lng: 34.774 },
    { lat: 9.274, lng: 33.4 },
    { lat: 9.501, lng: 32.759 },
    { lat: 10.188, lng: 31.055 },
    { lat: 10.703, lng: 30.115 },
    { lat: 10.87, lng: 29.788 },
    { lat: 11.545, lng: 28.976 },
    { lat: 11.951, lng: 29.062 },
    { lat: 12.192, lng: 29.112 },
    { lat: 12.821, lng: 29.853 },
    { lat: 13.228, lng: 30.641 },
    { lat: 13.432, lng: 31.056 },
    { lat: 13.899, lng: 32.49 },
    { lat: 14.02, lng: 32.827 },
    { lat: 14.389, lng: 34.263 },
    { lat: 14.591, lng: 34.991 },
    { lat: 14.816, lng: 35.908 },
    { lat: 15.153, lng: 37.251 },
    { lat: 15.199, lng: 37.446 },
    { lat: 15.53, lng: 38.907 },
    { lat: 15.706, lng: 39.667 },
    { lat: 15.85, lng: 40.283 },
    { lat: 16.193, lng: 41.567 },
    { lat: 16.264, lng: 41.849 },
    { lat: 16.525, lng: 42.793 },
    { lat: 16.827, lng: 43.887 },
    { lat: 16.85, lng: 43.967 },
    { lat: 17.208, lng: 45.075 },
    { lat: 17.402, lng: 45.578 },
    { lat: 17.639, lng: 46.103 },
    { lat: 17.996, lng: 46.817 },
    { lat: 18.142, lng: 47.058 },
    { lat: 18.596, lng: 47.926 },
    { lat: 18.635, lng: 47.987 },
    { lat: 19.207, lng: 48.799 },
    { lat: 19.241, lng: 48.831 },
    { lat: 19.831, lng: 49.414 },
    { lat: 20.07, lng: 49.534 },
    { lat: 20.472, lng: 49.718 },
    { lat: 21.133, lng: 49.694 },
    { lat: 21.809, lng: 49.433 },
    { lat: 22.509, lng: 48.808 },
    { lat: 23.19, lng: 47.704 },
    { lat: 23.249, lng: 47.599 },
    { lat: 24.005, lng: 45.889 },
    { lat: 24.03, lng: 45.822 },
    { lat: 24.6, lng: 44.096 },
    { lat: 24.875, lng: 43.203 },
    { lat: 25.201, lng: 42.146 },
    { lat: 25.738, lng: 40.568 },
    { lat: 26.037, lng: 39.76 },
    { lat: 26.588, lng: 38.385 },
    { lat: 27.444, lng: 36.322 },
    { lat: 27.47, lng: 36.267 },
    { lat: 28.299, lng: 34.494 },
    { lat: 29.151, lng: 32.874 },
    { lat: 30.01, lng: 31.329 },
    { lat: 30.862, lng: 30.038 },
    { lat: 31.265, lng: 29.634 },
    { lat: 31.668, lng: 29.455 },
    { lat: 32.263, lng: 30.225 },
    { lat: 32.35, lng: 30.48 },
    { lat: 32.65, lng: 32.079 },
    { lat: 32.8, lng: 34.184 },
    { lat: 32.795, lng: 34.416 },
    { lat: 32.742, lng: 36.415 },
    { lat: 32.725, lng: 38.367 },
    { lat: 32.659, lng: 40.228 },
    { lat: 32.571, lng: 41.983 },
    { lat: 32.517, lng: 43.587 },
    { lat: 32.405, lng: 45.164 },
    { lat: 32.214, lng: 46.737 },
    { lat: 32.004, lng: 48.246 },
    { lat: 31.701, lng: 49.765 },
    { lat: 31.554, lng: 50.369 },
    { lat: 31.339, lng: 51.256 },
    { lat: 30.956, lng: 52.693 },
    { lat: 30.571, lng: 53.921 },
    { lat: 30.511, lng: 54.109 },
    { lat: 30.108, lng: 55.435 },
    { lat: 29.801, lng: 56.65 },
    { lat: 29.624, lng: 57.395 },
    { lat: 29.527, lng: 57.806 },
    { lat: 29.313, lng: 58.894 },
    { lat: 29.161, lng: 59.92 },
    { lat: 29.035, lng: 60.91 },
    { lat: 28.867, lng: 61.898 },
    { lat: 28.67, lng: 62.879 },
    { lat: 28.569, lng: 63.384 },
    { lat: 28.47, lng: 63.839 },
    { lat: 28.204, lng: 64.807 },
    { lat: 27.872, lng: 65.78 },
    { lat: 27.726, lng: 66.23 },
    { lat: 27.535, lng: 66.732 },
    { lat: 27.129, lng: 67.687 },
    { lat: 26.953, lng: 68.003 },
    { lat: 26.517, lng: 68.688 },
    { lat: 26.223, lng: 69.083 },
    { lat: 25.598, lng: 69.754 },
    { lat: 25.516, lng: 69.833 },
    { lat: 24.823, lng: 70.415 },
    { lat: 24.187, lng: 70.913 },
    { lat: 24.139, lng: 70.946 },
    { lat: 23.463, lng: 71.419 },
    { lat: 22.793, lng: 71.909 },
    { lat: 22.619, lng: 72.045 },
    { lat: 22.128, lng: 72.407 },
    { lat: 21.468, lng: 72.949 },
    { lat: 21.355, lng: 73.047 },
    { lat: 20.812, lng: 73.51 },
    { lat: 20.309, lng: 73.962 },
    { lat: 20.161, lng: 74.098 },
    { lat: 19.513, lng: 74.759 },
    { lat: 19.467, lng: 74.809 },
    { lat: 18.867, lng: 75.526 },
    { lat: 18.807, lng: 75.601 },
    { lat: 18.448, lng: 76.331 },
    { lat: 18.238, lng: 77.028 },
    { lat: 18.206, lng: 77.221 },
    { lat: 18.124, lng: 77.705 },
    { lat: 18.185, lng: 78.155 },
    { lat: 18.208, lng: 78.349 },
    { lat: 18.344, lng: 78.982 },
    { lat: 18.528, lng: 79.607 },
    { lat: 18.764, lng: 80.065 },
    { lat: 18.839, lng: 80.213 },
    { lat: 19.103, lng: 80.824 },
    { lat: 19.361, lng: 81.27 },
    { lat: 19.457, lng: 81.423 },
    { lat: 19.848, lng: 82.016 },
    { lat: 19.968, lng: 82.205 },
    { lat: 20.255, lng: 82.607 },
    { lat: 20.581, lng: 82.968 },
    { lat: 20.799, lng: 83.183 },
    { lat: 21.2, lng: 83.611 },
    { lat: 21.343, lng: 83.759 },
    { lat: 21.824, lng: 84.229 },
    { lat: 21.959, lng: 84.328 },
    { lat: 22.454, lng: 84.745 },
    { lat: 22.624, lng: 84.893 },
    { lat: 23.086, lng: 85.335 },
    { lat: 23.239, lng: 85.466 },
    { lat: 23.724, lng: 85.898 },
    { lat: 23.886, lng: 86.038 },
    { lat: 24.302, lng: 86.638 },
    { lat: 24.363, lng: 86.734 },
    { lat: 24.646, lng: 87.247 },
    { lat: 24.896, lng: 87.866 },
    { lat: 24.996, lng: 88.262 },
    { lat: 25.051, lng: 88.495 },
    { lat: 25.167, lng: 89.127 },
    { lat: 25.278, lng: 89.759 },
    { lat: 25.362, lng: 90.392 },
    { lat: 25.436, lng: 91.026 },
    { lat: 25.446, lng: 91.664 },
    { lat: 25.38, lng: 92.305 },
    { lat: 25.25, lng: 92.946 },
    { lat: 24.938, lng: 93.581 },
    { lat: 24.931, lng: 93.593 },
    { lat: 24.41, lng: 94.243 },
    { lat: 24.277, lng: 94.384 },
    { lat: 23.737, lng: 94.889 },
    { lat: 23.624, lng: 94.975 },
    { lat: 22.977, lng: 95.438 },
    { lat: 22.838, lng: 95.531 },
    { lat: 22.335, lng: 95.842 },
    { lat: 21.89, lng: 96.163 },
    { lat: 21.698, lng: 96.296 },
    { lat: 21.127, lng: 96.783 },
    { lat: 21.066, lng: 96.839 },
    { lat: 20.512, lng: 97.394 },
    { lat: 20.438, lng: 97.504 },
    { lat: 20.128, lng: 98.0 },
    { lat: 19.843, lng: 98.603 },
    { lat: 19.815, lng: 98.673 },
    { lat: 19.589, lng: 99.203 },
    { lat: 19.478, lng: 99.803 },
    { lat: 19.47, lng: 100.405 },
    { lat: 19.497, lng: 101.007 },
    { lat: 19.59, lng: 101.612 },
    { lat: 19.759, lng: 102.221 },
    { lat: 19.827, lng: 102.428 },
    { lat: 19.946, lng: 102.833 },
    { lat: 20.259, lng: 103.452 },
    { lat: 20.459, lng: 103.827 },
    { lat: 20.584, lng: 104.076 },
    { lat: 20.966, lng: 104.708 },
    { lat: 21.096, lng: 104.851 },
    { lat: 21.524, lng: 105.355 },
    { lat: 21.736, lng: 105.635 },
    { lat: 22.013, lng: 106.005 },
    { lat: 22.381, lng: 106.413 },
    { lat: 22.61, lng: 106.671 },
    { lat: 23.031, lng: 107.069 },
    { lat: 23.308, lng: 107.355 },
    { lat: 23.688, lng: 107.807 },
    { lat: 23.867, lng: 108.038 },
    { lat: 24.351, lng: 108.567 },
    { lat: 24.505, lng: 108.739 },
    { lat: 25.006, lng: 109.437 },
    { lat: 25.024, lng: 109.468 },
    { lat: 25.389, lng: 110.132 },
    { lat: 25.712, lng: 110.791 },
    { lat: 25.73, lng: 110.829 },
    { lat: 25.901, lng: 111.512 },
    { lat: 26.043, lng: 112.195 },
    { lat: 26.23, lng: 112.891 },
    { lat: 26.421, lng: 113.593 },
    { lat: 26.447, lng: 113.661 },
    { lat: 26.723, lng: 114.321 },
    { lat: 27.16, lng: 115.03 },
    { lat: 27.201, lng: 115.09 },
    { lat: 27.834, lng: 115.903 },
    { lat: 27.871, lng: 115.942 },
    { lat: 28.581, lng: 116.56 },
    { lat: 29.1, lng: 116.872 },
    { lat: 29.293, lng: 116.995 },
    { lat: 30.005, lng: 117.155 },
    { lat: 30.195, lng: 117.12 },
    { lat: 30.715, lng: 117.036 },
    { lat: 31.309, lng: 116.656 },
    { lat: 31.42, lng: 116.552 },
    { lat: 31.762, lng: 116.032 },
    { lat: 32.101, lng: 115.381 },
    { lat: 32.109, lng: 115.367 },
    { lat: 32.458, lng: 114.733 },
    { lat: 32.765, lng: 114.072 },
    { lat: 32.802, lng: 113.97 },
    { lat: 33.038, lng: 113.403 },
    { lat: 33.312, lng: 112.733 },
    { lat: 33.499, lng: 112.31 },
    { lat: 33.676, lng: 112.078 },
    { lat: 34.058, lng: 111.424 },
    { lat: 34.219, lng: 111.067 },
    { lat: 34.98, lng: 110.917 },
    { lat: 35.763, lng: 111.222 },
    { lat: 36.565, lng: 111.697 },
    { lat: 37.036, lng: 112.017 },
    { lat: 37.38, lng: 112.207 },
    { lat: 38.212, lng: 112.78 },
    { lat: 38.681, lng: 113.167 },
    { lat: 39.063, lng: 113.468 },
    { lat: 39.935, lng: 114.257 },
    { lat: 39.981, lng: 114.303 },
    { lat: 40.83, lng: 115.181 },
    { lat: 41.039, lng: 115.431 },
    { lat: 41.762, lng: 116.479 },
    { lat: 41.781, lng: 116.508 },
    { lat: 42.417, lng: 117.587 },
    { lat: 42.74, lng: 118.216 },
    { lat: 42.963, lng: 118.668 },
    { lat: 43.439, lng: 119.752 },
    { lat: 43.78, lng: 120.568 },
    { lat: 43.902, lng: 120.863 },
    { lat: 44.341, lng: 121.994 },
    { lat: 44.766, lng: 123.153 },
    { lat: 44.901, lng: 123.593 },
    { lat: 45.119, lng: 124.307 },
    { lat: 45.407, lng: 125.453 },
    { lat: 45.679, lng: 126.62 },
    { lat: 45.903, lng: 127.787 },
    { lat: 46.101, lng: 128.963 },
    { lat: 46.329, lng: 130.093 },
    { lat: 46.353, lng: 130.21 },
    { lat: 46.68, lng: 131.553 },
    { lat: 47.076, lng: 133.003 },
    { lat: 47.467, lng: 134.509 },
    { lat: 47.672, lng: 135.898 },
    { lat: 47.439, lng: 136.886 },
    { lat: 46.925, lng: 137.187 },
  ],
  c2: [
    { lat: 44.741, lng: 134.424 },
    { lat: 43.814, lng: 134.558 },
    { lat: 43.594, lng: 134.56 },
    { lat: 42.892, lng: 134.497 },
    { lat: 42.078, lng: 134.441 },
    { lat: 41.988, lng: 134.43 },
    { lat: 41.098, lng: 134.318 },
    { lat: 40.486, lng: 134.3 },
    { lat: 40.229, lng: 134.266 },
    { lat: 39.374, lng: 134.191 },
    { lat: 38.931, lng: 134.225 },
    { lat: 38.54, lng: 134.218 },
    { lat: 37.723, lng: 134.292 },
    { lat: 37.58, lng: 134.318 },
    { lat: 36.924, lng: 134.444 },
    { lat: 36.466, lng: 134.583 },
    { lat: 36.138, lng: 134.629 },
    { lat: 35.359, lng: 134.749 },
    { lat: 35.187, lng: 134.756 },
    { lat: 34.581, lng: 134.738 },
    { lat: 33.811, lng: 134.692 },
    { lat: 33.352, lng: 134.619 },
    { lat: 33.048, lng: 134.604 },
    { lat: 32.292, lng: 134.47 },
    { lat: 31.54, lng: 134.267 },
    { lat: 30.794, lng: 134.018 },
    { lat: 30.055, lng: 133.752 },
    { lat: 29.685, lng: 133.598 },
    { lat: 29.323, lng: 133.454 },
    { lat: 28.598, lng: 133.147 },
    { lat: 27.879, lng: 132.812 },
    { lat: 27.168, lng: 132.491 },
    { lat: 26.883, lng: 132.346 },
    { lat: 26.462, lng: 132.125 },
    { lat: 25.763, lng: 131.763 },
    { lat: 25.069, lng: 131.377 },
    { lat: 24.38, lng: 130.96 },
    { lat: 23.698, lng: 130.55 },
    { lat: 23.021, lng: 130.133 },
    { lat: 22.991, lng: 130.112 },
    { lat: 22.348, lng: 129.666 },
    { lat: 21.679, lng: 129.165 },
    { lat: 21.196, lng: 128.801 },
    { lat: 21.016, lng: 128.647 },
    { lat: 20.356, lng: 128.078 },
    { lat: 19.997, lng: 127.73 },
    { lat: 19.695, lng: 127.337 },
    { lat: 19.33, lng: 126.829 },
    { lat: 19.04, lng: 126.59 },
    { lat: 18.4, lng: 126.166 },
    { lat: 17.766, lng: 125.801 },
    { lat: 17.69, lng: 125.752 },
    { lat: 17.17, lng: 124.94 },
    { lat: 17.122, lng: 124.902 },
    { lat: 16.495, lng: 124.497 },
    { lat: 15.875, lng: 124.21 },
    { lat: 15.26, lng: 123.948 },
    { lat: 15.134, lng: 123.895 },
    { lat: 14.647, lng: 123.683 },
    { lat: 14.036, lng: 123.354 },
    { lat: 13.427, lng: 123.003 },
    { lat: 13.351, lng: 122.958 },
    { lat: 12.821, lng: 122.64 },
    { lat: 12.218, lng: 122.271 },
    { lat: 11.988, lng: 122.122 },
    { lat: 11.617, lng: 121.885 },
    { lat: 11.02, lng: 121.566 },
    { lat: 10.426, lng: 121.322 },
    { lat: 10.37, lng: 121.297 },
    { lat: 9.834, lng: 121.046 },
    { lat: 9.244, lng: 120.746 },
    { lat: 8.787, lng: 120.514 },
    { lat: 8.656, lng: 120.437 },
    { lat: 8.069, lng: 120.093 },
    { lat: 7.485, lng: 119.834 },
    { lat: 7.328, lng: 119.773 },
    { lat: 6.902, lng: 119.578 },
    { lat: 6.322, lng: 119.362 },
    { lat: 5.742, lng: 119.132 },
    { lat: 5.492, lng: 119.045 },
    { lat: 5.164, lng: 118.913 },
    { lat: 4.587, lng: 118.714 },
    { lat: 4.011, lng: 118.5 },
    { lat: 3.63, lng: 118.352 },
    { lat: 3.436, lng: 118.264 },
    { lat: 2.861, lng: 117.962 },
    { lat: 2.418, lng: 117.704 },
    { lat: 2.288, lng: 117.602 },
    { lat: 1.714, lng: 117.126 },
    { lat: 1.665, lng: 117.077 },
    { lat: 1.286, lng: 116.461 },
    { lat: 1.142, lng: 116.274 },
    { lat: 0.854, lng: 115.849 },
    { lat: 0.57, lng: 115.434 },
    { lat: 0.443, lng: 115.242 },
    { lat: 0.069, lng: 114.639 },
    { lat: 0.0, lng: 114.555 },
    { lat: -0.415, lng: 114.041 },
    { lat: -0.569, lng: 113.871 },
    { lat: -0.915, lng: 113.446 },
    { lat: -1.138, lng: 113.142 },
    { lat: -1.346, lng: 112.855 },
    { lat: -1.706, lng: 112.393 },
    { lat: -1.81, lng: 112.268 },
    { lat: -2.274, lng: 111.836 },
    { lat: -2.416, lng: 111.686 },
    { lat: -2.835, lng: 111.104 },
    { lat: -2.841, lng: 111.095 },
    { lat: -3.17, lng: 110.523 },
    { lat: -3.408, lng: 110.146 },
    { lat: -3.594, lng: 109.947 },
    { lat: -3.865, lng: 109.369 },
    { lat: -3.975, lng: 109.195 },
    { lat: -4.153, lng: 108.794 },
    { lat: -4.304, lng: 108.219 },
    { lat: -4.43, lng: 107.644 },
    { lat: -4.538, lng: 107.231 },
    { lat: -4.57, lng: 107.071 },
    { lat: -4.607, lng: 106.498 },
    { lat: -4.536, lng: 106.064 },
    { lat: -4.507, lng: 105.924 },
    { lat: -4.161, lng: 105.349 },
    { lat: -3.965, lng: 105.059 },
    { lat: -3.738, lng: 104.775 },
    { lat: -3.397, lng: 104.411 },
    { lat: -3.266, lng: 104.203 },
    { lat: -2.834, lng: 103.633 },
    { lat: -2.829, lng: 103.625 },
    { lat: -2.406, lng: 103.065 },
    { lat: -2.262, lng: 102.865 },
    { lat: -2.016, lng: 102.499 },
    { lat: -1.696, lng: 102.005 },
    { lat: -1.651, lng: 101.934 },
    { lat: -1.319, lng: 101.37 },
    { lat: -1.13, lng: 101.089 },
    { lat: -0.928, lng: 100.808 },
    { lat: -0.565, lng: 100.255 },
    { lat: -0.558, lng: 100.246 },
    { lat: -0.175, lng: 99.682 },
    { lat: 0.0, lng: 99.452 },
    { lat: 0.272, lng: 99.121 },
    { lat: 0.566, lng: 98.787 },
    { lat: 0.777, lng: 98.562 },
    { lat: 1.127, lng: 98.057 },
    { lat: 1.172, lng: 98.0 },
    { lat: 1.586, lng: 97.439 },
    { lat: 1.695, lng: 97.272 },
    { lat: 1.964, lng: 96.877 },
    { lat: 2.26, lng: 96.411 },
    { lat: 2.332, lng: 96.315 },
    { lat: 2.651, lng: 95.752 },
    { lat: 2.827, lng: 95.368 },
    { lat: 2.915, lng: 95.188 },
    { lat: 3.214, lng: 94.624 },
    { lat: 3.393, lng: 94.242 },
    { lat: 3.493, lng: 94.059 },
    { lat: 3.668, lng: 93.494 },
    { lat: 3.818, lng: 92.927 },
    { lat: 3.922, lng: 92.36 },
    { lat: 3.945, lng: 91.792 },
    { lat: 3.943, lng: 91.223 },
    { lat: 3.874, lng: 90.655 },
    { lat: 3.767, lng: 90.085 },
    { lat: 3.609, lng: 89.515 },
    { lat: 3.473, lng: 88.943 },
    { lat: 3.402, lng: 88.702 },
    { lat: 3.318, lng: 88.371 },
    { lat: 3.167, lng: 87.797 },
    { lat: 3.047, lng: 87.221 },
    { lat: 2.928, lng: 86.643 },
    { lat: 2.843, lng: 86.064 },
    { lat: 2.839, lng: 86.038 },
    { lat: 2.761, lng: 85.482 },
    { lat: 2.671, lng: 84.898 },
    { lat: 2.615, lng: 84.312 },
    { lat: 2.569, lng: 83.723 },
    { lat: 2.495, lng: 83.132 },
    { lat: 2.448, lng: 82.539 },
    { lat: 2.419, lng: 81.942 },
    { lat: 2.349, lng: 81.344 },
    { lat: 2.293, lng: 80.742 },
    { lat: 2.282, lng: 80.595 },
    { lat: 2.251, lng: 80.137 },
    { lat: 2.143, lng: 79.529 },
    { lat: 2.061, lng: 78.918 },
    { lat: 1.971, lng: 78.303 },
    { lat: 1.849, lng: 77.685 },
    { lat: 1.748, lng: 77.063 },
    { lat: 1.719, lng: 76.79 },
    { lat: 1.689, lng: 76.436 },
    { lat: 1.636, lng: 75.805 },
    { lat: 1.612, lng: 75.169 },
    { lat: 1.572, lng: 74.528 },
    { lat: 1.519, lng: 73.883 },
    { lat: 1.48, lng: 73.232 },
    { lat: 1.393, lng: 72.577 },
    { lat: 1.312, lng: 71.916 },
    { lat: 1.231, lng: 71.249 },
    { lat: 1.156, lng: 70.812 },
    { lat: 1.122, lng: 70.576 },
    { lat: 1.038, lng: 69.897 },
    { lat: 0.922, lng: 69.21 },
    { lat: 0.838, lng: 68.517 },
    { lat: 0.759, lng: 67.816 },
    { lat: 0.706, lng: 67.106 },
    { lat: 0.69, lng: 66.388 },
    { lat: 0.684, lng: 65.662 },
    { lat: 0.7, lng: 64.926 },
    { lat: 0.677, lng: 64.18 },
    { lat: 0.644, lng: 63.424 },
    { lat: 0.586, lng: 62.875 },
    { lat: 0.567, lng: 62.659 },
    { lat: 0.48, lng: 61.882 },
    { lat: 0.349, lng: 61.092 },
    { lat: 0.246, lng: 60.29 },
    { lat: 0.104, lng: 59.474 },
    { lat: -0.0, lng: 58.853 },
    { lat: -0.031, lng: 58.643 },
    { lat: -0.144, lng: 57.796 },
    { lat: -0.253, lng: 56.932 },
    { lat: -0.353, lng: 56.051 },
    { lat: -0.415, lng: 55.151 },
    { lat: -0.531, lng: 54.229 },
    { lat: -0.598, lng: 53.667 },
    { lat: -0.642, lng: 53.284 },
    { lat: -0.74, lng: 52.315 },
    { lat: -0.957, lng: 51.317 },
    { lat: -1.145, lng: 50.289 },
    { lat: -1.206, lng: 49.987 },
    { lat: -1.365, lng: 49.227 },
    { lat: -1.677, lng: 48.124 },
    { lat: -1.82, lng: 47.563 },
    { lat: -1.969, lng: 46.978 },
    { lat: -2.279, lng: 45.783 },
    { lat: -2.442, lng: 45.071 },
    { lat: -2.536, lng: 44.536 },
    { lat: -2.673, lng: 43.239 },
    { lat: -2.8, lng: 41.872 },
    { lat: -2.865, lng: 40.432 },
    { lat: -2.83, lng: 38.906 },
    { lat: -2.731, lng: 37.271 },
    { lat: -2.523, lng: 35.503 },
    { lat: -2.503, lng: 35.4 },
    { lat: -1.902, lng: 33.59 },
    { lat: -1.886, lng: 33.551 },
    { lat: -1.26, lng: 32.68 },
    { lat: -0.63, lng: 32.686 },
    { lat: -0.0, lng: 33.447 },
    { lat: 0.113, lng: 33.69 },
    { lat: 0.626, lng: 35.128 },
    { lat: 0.768, lng: 35.645 },
    { lat: 1.246, lng: 37.109 },
    { lat: 1.336, lng: 37.398 },
    { lat: 1.86, lng: 38.807 },
    { lat: 1.933, lng: 38.993 },
    { lat: 2.453, lng: 40.473 },
    { lat: 2.47, lng: 40.507 },
    { lat: 3.079, lng: 41.684 },
    { lat: 3.172, lng: 41.833 },
    { lat: 3.685, lng: 42.795 },
    { lat: 3.905, lng: 43.103 },
    { lat: 4.292, lng: 43.615 },
    { lat: 4.902, lng: 43.999 },
    { lat: 5.517, lng: 44.018 },
    { lat: 6.14, lng: 43.528 },
    { lat: 6.699, lng: 42.601 },
    { lat: 6.775, lng: 42.5 },
    { lat: 7.418, lng: 41.311 },
    { lat: 7.545, lng: 40.983 },
    { lat: 8.075, lng: 39.66 },
    { lat: 8.216, lng: 39.27 },
    { lat: 8.744, lng: 37.831 },
    { lat: 8.899, lng: 37.383 },
    { lat: 9.42, lng: 36.012 },
    { lat: 9.815, lng: 35.156 },
    { lat: 10.095, lng: 34.522 },
    { lat: 10.767, lng: 33.361 },
    { lat: 11.427, lng: 32.803 },
    { lat: 12.067, lng: 33.001 },
    { lat: 12.547, lng: 33.528 },
    { lat: 12.691, lng: 33.685 },
    { lat: 13.303, lng: 34.711 },
    { lat: 13.411, lng: 34.977 },
    { lat: 13.898, lng: 36.158 },
    { lat: 13.994, lng: 36.475 },
    { lat: 14.445, lng: 37.94 },
    { lat: 14.475, lng: 38.04 },
    { lat: 14.802, lng: 39.36 },
    { lat: 15.036, lng: 40.252 },
    { lat: 15.145, lng: 40.702 },
    { lat: 15.479, lng: 41.975 },
    { lat: 15.595, lng: 42.435 },
    { lat: 15.787, lng: 43.2 },
    { lat: 16.102, lng: 44.366 },
    { lat: 16.154, lng: 44.558 },
    { lat: 16.414, lng: 45.487 },
    { lat: 16.72, lng: 46.453 },
    { lat: 16.757, lng: 46.551 },
    { lat: 17.16, lng: 47.55 },
    { lat: 17.304, lng: 47.896 },
    { lat: 17.591, lng: 48.503 },
    { lat: 17.895, lng: 49.154 },
    { lat: 18.04, lng: 49.42 },
    { lat: 18.495, lng: 50.213 },
    { lat: 18.548, lng: 50.285 },
    { lat: 19.107, lng: 51.016 },
    { lat: 19.171, lng: 51.073 },
    { lat: 19.731, lng: 51.59 },
    { lat: 20.016, lng: 51.736 },
    { lat: 20.367, lng: 51.944 },
    { lat: 21.018, lng: 52.042 },
    { lat: 21.69, lng: 51.798 },
    { lat: 22.377, lng: 51.34 },
    { lat: 23.084, lng: 50.616 },
    { lat: 23.686, lng: 49.739 },
    { lat: 23.821, lng: 49.496 },
    { lat: 24.463, lng: 48.06 },
    { lat: 24.605, lng: 47.701 },
    { lat: 25.126, lng: 46.339 },
    { lat: 25.429, lng: 45.472 },
    { lat: 25.764, lng: 44.506 },
    { lat: 26.275, lng: 43.135 },
    { lat: 26.639, lng: 42.289 },
    { lat: 27.12, lng: 41.049 },
    { lat: 27.951, lng: 39.374 },
    { lat: 28.208, lng: 39.002 },
    { lat: 28.759, lng: 38.202 },
    { lat: 29.238, lng: 37.702 },
    { lat: 29.551, lng: 37.383 },
    { lat: 30.277, lng: 37.57 },
    { lat: 30.453, lng: 37.902 },
    { lat: 30.813, lng: 39.216 },
    { lat: 30.882, lng: 39.394 },
    { lat: 31.087, lng: 40.567 },
    { lat: 31.208, lng: 42.031 },
    { lat: 31.199, lng: 43.562 },
    { lat: 31.251, lng: 44.355 },
    { lat: 31.271, lng: 44.931 },
    { lat: 31.24, lng: 46.34 },
    { lat: 31.073, lng: 47.812 },
    { lat: 30.927, lng: 49.196 },
    { lat: 30.88, lng: 49.434 },
    { lat: 30.623, lng: 50.65 },
    { lat: 30.301, lng: 52.051 },
    { lat: 29.938, lng: 53.421 },
    { lat: 29.861, lng: 53.672 },
    { lat: 29.503, lng: 54.781 },
    { lat: 29.142, lng: 56.039 },
    { lat: 28.943, lng: 56.834 },
    { lat: 28.841, lng: 57.217 },
    { lat: 28.608, lng: 58.32 },
    { lat: 28.408, lng: 59.376 },
    { lat: 28.236, lng: 60.391 },
    { lat: 28.112, lng: 61.36 },
    { lat: 27.928, lng: 62.259 },
    { lat: 27.909, lng: 62.345 },
    { lat: 27.667, lng: 63.324 },
    { lat: 27.374, lng: 64.302 },
    { lat: 27.084, lng: 65.196 },
    { lat: 27.058, lng: 65.268 },
    { lat: 26.592, lng: 66.266 },
    { lat: 26.323, lng: 66.815 },
    { lat: 26.059, lng: 67.261 },
    { lat: 25.59, lng: 68.065 },
    { lat: 25.469, lng: 68.247 },
    { lat: 24.88, lng: 68.978 },
    { lat: 24.606, lng: 69.286 },
    { lat: 24.188, lng: 69.667 },
    { lat: 23.505, lng: 70.275 },
    { lat: 23.391, lng: 70.382 },
    { lat: 22.83, lng: 70.853 },
    { lat: 22.217, lng: 71.416 },
    { lat: 22.16, lng: 71.461 },
    { lat: 21.498, lng: 72.012 },
    { lat: 21.041, lng: 72.405 },
    { lat: 20.84, lng: 72.576 },
    { lat: 20.188, lng: 73.163 },
    { lat: 20.006, lng: 73.325 },
    { lat: 19.54, lng: 73.773 },
    { lat: 19.121, lng: 74.185 },
    { lat: 18.896, lng: 74.421 },
    { lat: 18.385, lng: 74.995 },
    { lat: 18.255, lng: 75.161 },
    { lat: 17.808, lng: 75.761 },
    { lat: 17.61, lng: 76.274 },
    { lat: 17.534, lng: 76.469 },
    { lat: 17.383, lng: 77.151 },
    { lat: 17.352, lng: 77.812 },
    { lat: 17.477, lng: 78.447 },
    { lat: 17.558, lng: 78.693 },
    { lat: 17.661, lng: 79.072 },
    { lat: 17.853, lng: 79.693 },
    { lat: 18.142, lng: 80.237 },
    { lat: 18.172, lng: 80.297 },
    { lat: 18.458, lng: 80.903 },
    { lat: 18.737, lng: 81.414 },
    { lat: 18.788, lng: 81.503 },
    { lat: 19.139, lng: 82.1 },
    { lat: 19.339, lng: 82.453 },
    { lat: 19.486, lng: 82.696 },
    { lat: 19.948, lng: 83.263 },
    { lat: 19.962, lng: 83.278 },
    { lat: 20.444, lng: 83.86 },
    { lat: 20.562, lng: 84.023 },
    { lat: 20.909, lng: 84.444 },
    { lat: 21.18, lng: 84.721 },
    { lat: 21.494, lng: 85.016 },
    { lat: 21.803, lng: 85.393 },
    { lat: 21.993, lng: 85.599 },
    { lat: 22.431, lng: 86.045 },
    { lat: 22.569, lng: 86.176 },
    { lat: 23.054, lng: 86.765 },
    { lat: 23.062, lng: 86.779 },
    { lat: 23.387, lng: 87.369 },
    { lat: 23.693, lng: 87.875 },
    { lat: 23.747, lng: 87.973 },
    { lat: 23.925, lng: 88.593 },
    { lat: 24.057, lng: 89.218 },
    { lat: 24.212, lng: 89.84 },
    { lat: 24.311, lng: 90.376 },
    { lat: 24.326, lng: 90.466 },
    { lat: 24.382, lng: 91.095 },
    { lat: 24.38, lng: 91.727 },
    { lat: 24.293, lng: 92.141 },
    { lat: 24.24, lng: 92.365 },
    { lat: 23.989, lng: 93.005 },
    { lat: 23.632, lng: 93.591 },
    { lat: 23.592, lng: 93.647 },
    { lat: 22.983, lng: 94.236 },
    { lat: 22.925, lng: 94.292 },
    { lat: 22.34, lng: 94.707 },
    { lat: 21.997, lng: 94.935 },
    { lat: 21.702, lng: 95.141 },
    { lat: 21.12, lng: 95.565 },
    { lat: 21.069, lng: 95.607 },
    { lat: 20.466, lng: 96.184 },
    { lat: 20.44, lng: 96.209 },
    { lat: 19.939, lng: 96.794 },
    { lat: 19.815, lng: 96.944 },
    { lat: 19.457, lng: 97.399 },
    { lat: 19.195, lng: 97.861 },
    { lat: 19.111, lng: 98.0 },
    { lat: 18.886, lng: 98.598 },
    { lat: 18.691, lng: 99.195 },
    { lat: 18.593, lng: 99.792 },
    { lat: 18.63, lng: 100.391 },
    { lat: 18.661, lng: 100.99 },
    { lat: 18.754, lng: 101.591 },
    { lat: 18.925, lng: 102.196 },
    { lat: 19.105, lng: 102.804 },
    { lat: 19.21, lng: 103.038 },
    { lat: 19.378, lng: 103.417 },
    { lat: 19.726, lng: 104.037 },
    { lat: 19.838, lng: 104.237 },
    { lat: 20.06, lng: 104.662 },
    { lat: 20.47, lng: 105.109 },
    { lat: 20.643, lng: 105.304 },
    { lat: 21.105, lng: 105.859 },
    { lat: 21.173, lng: 105.951 },
    { lat: 21.741, lng: 106.609 },
    { lat: 21.746, lng: 106.613 },
    { lat: 22.39, lng: 107.163 },
    { lat: 22.527, lng: 107.292 },
    { lat: 23.042, lng: 107.87 },
    { lat: 23.127, lng: 107.972 },
    { lat: 23.699, lng: 108.577 },
    { lat: 23.783, lng: 108.668 },
    { lat: 24.316, lng: 109.364 },
    { lat: 24.366, lng: 109.454 },
    { lat: 24.679, lng: 110.05 },
    { lat: 25.011, lng: 110.74 },
    { lat: 25.05, lng: 110.879 },
    { lat: 25.184, lng: 111.417 },
    { lat: 25.238, lng: 112.083 },
    { lat: 25.379, lng: 112.765 },
    { lat: 25.478, lng: 113.446 },
    { lat: 25.687, lng: 114.149 },
    { lat: 25.792, lng: 114.339 },
    { lat: 26.076, lng: 114.89 },
    { lat: 26.498, lng: 115.621 },
    { lat: 26.518, lng: 115.652 },
    { lat: 27.152, lng: 116.464 },
    { lat: 27.203, lng: 116.531 },
    { lat: 27.893, lng: 117.319 },
    { lat: 27.914, lng: 117.344 },
    { lat: 28.629, lng: 118.04 },
    { lat: 29.058, lng: 118.296 },
    { lat: 29.344, lng: 118.477 },
    { lat: 30.06, lng: 118.727 },
    { lat: 30.775, lng: 118.724 },
    { lat: 30.776, lng: 118.724 },
    { lat: 31.485, lng: 118.374 },
    { lat: 31.646, lng: 118.211 },
    { lat: 32.163, lng: 117.602 },
    { lat: 32.186, lng: 117.574 },
    { lat: 32.524, lng: 116.952 },
    { lat: 32.774, lng: 116.274 },
    { lat: 32.87, lng: 116.019 },
    { lat: 33.027, lng: 115.595 },
    { lat: 33.337, lng: 114.931 },
    { lat: 33.565, lng: 114.49 },
    { lat: 33.693, lng: 114.274 },
    { lat: 34.076, lng: 113.622 },
    { lat: 34.284, lng: 113.333 },
    { lat: 34.707, lng: 113.019 },
    { lat: 35.035, lng: 112.829 },
    { lat: 35.807, lng: 112.702 },
    { lat: 36.6, lng: 112.848 },
    { lat: 37.413, lng: 113.222 },
    { lat: 38.245, lng: 113.745 },
    { lat: 38.438, lng: 113.891 },
    { lat: 39.096, lng: 114.366 },
    { lat: 39.939, lng: 115.1 },
    { lat: 39.968, lng: 115.13 },
    { lat: 40.799, lng: 116.185 },
    { lat: 40.877, lng: 116.298 },
    { lat: 41.45, lng: 117.241 },
    { lat: 41.827, lng: 117.901 },
    { lat: 42.038, lng: 118.309 },
    { lat: 42.474, lng: 119.351 },
    { lat: 42.844, lng: 120.258 },
    { lat: 42.909, lng: 120.419 },
    { lat: 43.292, lng: 121.493 },
    { lat: 43.664, lng: 122.59 },
    { lat: 43.957, lng: 123.594 },
    { lat: 43.984, lng: 123.689 },
    { lat: 44.244, lng: 124.782 },
    { lat: 44.46, lng: 125.876 },
    { lat: 44.601, lng: 126.947 },
    { lat: 44.653, lng: 127.982 },
    { lat: 44.746, lng: 129.061 },
    { lat: 44.967, lng: 130.253 },
    { lat: 45.125, lng: 131.429 },
    { lat: 45.227, lng: 132.59 },
    { lat: 45.2, lng: 133.67 },
    { lat: 44.761, lng: 134.418 },
    { lat: 44.741, lng: 134.424 },
  ],
  c3: [
    { lat: 42.534, lng: 129.759 },
    { lat: 42.095, lng: 130.324 },
    { lat: 41.802, lng: 131.132 },
    { lat: 41.744, lng: 131.184 },
    { lat: 41.021, lng: 131.613 },
    { lat: 40.897, lng: 131.614 },
    { lat: 40.037, lng: 131.629 },
    { lat: 39.57, lng: 131.658 },
    { lat: 39.192, lng: 131.641 },
    { lat: 38.372, lng: 131.826 },
    { lat: 38.322, lng: 131.848 },
    { lat: 37.567, lng: 132.031 },
    { lat: 37.314, lng: 132.196 },
    { lat: 36.78, lng: 132.327 },
    { lat: 36.248, lng: 132.513 },
    { lat: 36.0, lng: 132.565 },
    { lat: 35.229, lng: 132.773 },
    { lat: 35.116, lng: 132.8 },
    { lat: 34.465, lng: 132.947 },
    { lat: 33.899, lng: 133.048 },
    { lat: 33.707, lng: 133.057 },
    { lat: 32.949, lng: 133.009 },
    { lat: 32.199, lng: 132.948 },
    { lat: 31.829, lng: 132.865 },
    { lat: 31.45, lng: 132.761 },
    { lat: 30.707, lng: 132.524 },
    { lat: 29.972, lng: 132.269 },
    { lat: 29.243, lng: 131.988 },
    { lat: 28.519, lng: 131.659 },
    { lat: 27.802, lng: 131.305 },
    { lat: 27.092, lng: 130.956 },
    { lat: 26.387, lng: 130.573 },
    { lat: 25.762, lng: 130.247 },
    { lat: 25.69, lng: 130.207 },
    { lat: 24.998, lng: 129.796 },
    { lat: 24.309, lng: 129.317 },
    { lat: 23.625, lng: 128.801 },
    { lat: 23.497, lng: 128.696 },
    { lat: 22.937, lng: 128.022 },
    { lat: 22.651, lng: 127.676 },
    { lat: 22.245, lng: 126.96 },
    { lat: 22.117, lng: 126.774 },
    { lat: 21.572, lng: 126.206 },
    { lat: 21.112, lng: 125.779 },
    { lat: 20.905, lng: 125.448 },
    { lat: 20.538, lng: 124.918 },
    { lat: 20.246, lng: 124.751 },
    { lat: 19.603, lng: 124.409 },
    { lat: 18.967, lng: 124.146 },
    { lat: 18.336, lng: 123.942 },
    { lat: 17.709, lng: 123.731 },
    { lat: 17.086, lng: 123.55 },
    { lat: 16.925, lng: 123.497 },
    { lat: 16.463, lng: 123.211 },
    { lat: 15.842, lng: 122.804 },
    { lat: 15.229, lng: 122.578 },
    { lat: 15.083, lng: 122.521 },
    { lat: 14.619, lng: 122.385 },
    { lat: 14.011, lng: 122.163 },
    { lat: 13.405, lng: 121.899 },
    { lat: 12.802, lng: 121.573 },
    { lat: 12.751, lng: 121.547 },
    { lat: 12.2, lng: 121.248 },
    { lat: 11.601, lng: 120.904 },
    { lat: 11.153, lng: 120.719 },
    { lat: 11.006, lng: 120.645 },
    { lat: 10.413, lng: 120.367 },
    { lat: 9.821, lng: 120.026 },
    { lat: 9.674, lng: 119.94 },
    { lat: 9.231, lng: 119.645 },
    { lat: 8.643, lng: 119.325 },
    { lat: 8.437, lng: 119.209 },
    { lat: 8.057, lng: 118.912 },
    { lat: 7.473, lng: 118.542 },
    { lat: 7.416, lng: 118.514 },
    { lat: 6.89, lng: 118.186 },
    { lat: 6.31, lng: 117.94 },
    { lat: 6.059, lng: 117.819 },
    { lat: 5.731, lng: 117.557 },
    { lat: 5.153, lng: 117.192 },
    { lat: 5.097, lng: 117.16 },
    { lat: 4.577, lng: 116.793 },
    { lat: 4.106, lng: 116.513 },
    { lat: 4.001, lng: 116.44 },
    { lat: 3.427, lng: 115.92 },
    { lat: 3.393, lng: 115.885 },
    { lat: 3.075, lng: 115.272 },
    { lat: 2.945, lng: 114.666 },
    { lat: 2.851, lng: 114.591 },
    { lat: 2.279, lng: 114.202 },
    { lat: 2.081, lng: 114.053 },
    { lat: 1.708, lng: 113.751 },
    { lat: 1.377, lng: 113.449 },
    { lat: 1.197, lng: 112.854 },
    { lat: 1.138, lng: 112.765 },
    { lat: 0.789, lng: 112.261 },
    { lat: 0.766, lng: 111.672 },
    { lat: 1.069, lng: 111.087 },
    { lat: 0.71, lng: 110.502 },
    { lat: 0.993, lng: 109.921 },
    { lat: 0.567, lng: 109.743 },
    { lat: 0.0, lng: 109.611 },
    { lat: -0.567, lng: 109.615 },
    { lat: -1.014, lng: 109.341 },
    { lat: -1.134, lng: 109.235 },
    { lat: -1.463, lng: 108.765 },
    { lat: -1.701, lng: 108.416 },
    { lat: -1.861, lng: 108.191 },
    { lat: -2.267, lng: 107.827 },
    { lat: -2.38, lng: 107.62 },
    { lat: -2.497, lng: 107.047 },
    { lat: -2.512, lng: 106.475 },
    { lat: -2.62, lng: 105.905 },
    { lat: -2.391, lng: 105.333 },
    { lat: -2.264, lng: 105.087 },
    { lat: -2.091, lng: 104.763 },
    { lat: -1.778, lng: 104.194 },
    { lat: -1.697, lng: 104.043 },
    { lat: -1.48, lng: 103.627 },
    { lat: -1.131, lng: 103.145 },
    { lat: -1.069, lng: 103.061 },
    { lat: -0.772, lng: 102.496 },
    { lat: -0.565, lng: 102.161 },
    { lat: -0.38, lng: 101.933 },
    { lat: -0.146, lng: 101.37 },
    { lat: 0.0, lng: 101.175 },
    { lat: 0.31, lng: 100.808 },
    { lat: 0.565, lng: 100.447 },
    { lat: 0.729, lng: 100.244 },
    { lat: 1.123, lng: 99.684 },
    { lat: 1.13, lng: 99.676 },
    { lat: 1.6, lng: 99.123 },
    { lat: 1.696, lng: 99.023 },
    { lat: 2.231, lng: 98.561 },
    { lat: 2.26, lng: 98.526 },
    { lat: 2.718, lng: 98.0 },
    { lat: 2.826, lng: 97.878 },
    { lat: 3.339, lng: 97.438 },
    { lat: 3.392, lng: 97.367 },
    { lat: 3.807, lng: 96.875 },
    { lat: 3.959, lng: 96.645 },
    { lat: 4.233, lng: 96.311 },
    { lat: 4.527, lng: 95.864 },
    { lat: 4.634, lng: 95.746 },
    { lat: 4.879, lng: 95.181 },
    { lat: 4.984, lng: 94.615 },
    { lat: 5.077, lng: 94.049 },
    { lat: 5.097, lng: 93.96 },
    { lat: 5.24, lng: 93.482 },
    { lat: 5.234, lng: 92.915 },
    { lat: 5.218, lng: 92.348 },
    { lat: 5.12, lng: 91.78 },
    { lat: 5.101, lng: 91.72 },
    { lat: 4.991, lng: 91.212 },
    { lat: 4.82, lng: 90.644 },
    { lat: 4.598, lng: 90.075 },
    { lat: 4.536, lng: 89.957 },
    { lat: 4.359, lng: 89.506 },
    { lat: 4.168, lng: 88.935 },
    { lat: 3.97, lng: 88.39 },
    { lat: 3.963, lng: 88.363 },
    { lat: 3.77, lng: 87.789 },
    { lat: 3.641, lng: 87.213 },
    { lat: 3.514, lng: 86.636 },
    { lat: 3.411, lng: 86.056 },
    { lat: 3.408, lng: 86.018 },
    { lat: 3.361, lng: 85.473 },
    { lat: 3.292, lng: 84.89 },
    { lat: 3.27, lng: 84.302 },
    { lat: 3.259, lng: 83.713 },
    { lat: 3.225, lng: 83.121 },
    { lat: 3.21, lng: 82.527 },
    { lat: 3.225, lng: 81.929 },
    { lat: 3.206, lng: 81.329 },
    { lat: 3.176, lng: 80.726 },
    { lat: 3.198, lng: 80.119 },
    { lat: 3.116, lng: 79.511 },
    { lat: 3.033, lng: 78.9 },
    { lat: 3.007, lng: 78.284 },
    { lat: 2.863, lng: 77.694 },
    { lat: 2.858, lng: 77.667 },
    { lat: 2.767, lng: 77.045 },
    { lat: 2.743, lng: 76.417 },
    { lat: 2.673, lng: 75.786 },
    { lat: 2.632, lng: 75.15 },
    { lat: 2.651, lng: 74.508 },
    { lat: 2.612, lng: 73.862 },
    { lat: 2.571, lng: 73.211 },
    { lat: 2.496, lng: 72.556 },
    { lat: 2.368, lng: 71.896 },
    { lat: 2.344, lng: 71.228 },
    { lat: 2.312, lng: 70.925 },
    { lat: 2.282, lng: 70.555 },
    { lat: 2.216, lng: 69.875 },
    { lat: 2.129, lng: 69.189 },
    { lat: 2.096, lng: 68.495 },
    { lat: 2.04, lng: 67.794 },
    { lat: 2.06, lng: 67.083 },
    { lat: 2.081, lng: 66.363 },
    { lat: 2.119, lng: 65.635 },
    { lat: 2.087, lng: 64.899 },
    { lat: 2.038, lng: 64.154 },
    { lat: 1.867, lng: 63.402 },
    { lat: 1.758, lng: 62.902 },
    { lat: 1.715, lng: 62.639 },
    { lat: 1.555, lng: 61.864 },
    { lat: 1.312, lng: 61.079 },
    { lat: 1.178, lng: 60.562 },
    { lat: 1.122, lng: 60.28 },
    { lat: 0.964, lng: 59.466 },
    { lat: 0.759, lng: 58.638 },
    { lat: 0.648, lng: 57.792 },
    { lat: 0.593, lng: 57.326 },
    { lat: 0.551, lng: 56.93 },
    { lat: 0.463, lng: 56.05 },
    { lat: 0.443, lng: 55.15 },
    { lat: 0.393, lng: 54.23 },
    { lat: 0.357, lng: 53.287 },
    { lat: 0.411, lng: 52.319 },
    { lat: 0.36, lng: 51.326 },
    { lat: 0.356, lng: 50.304 },
    { lat: 0.455, lng: 49.248 },
    { lat: 0.408, lng: 48.159 },
    { lat: 0.344, lng: 47.031 },
    { lat: 0.502, lng: 45.856 },
    { lat: 0.61, lng: 45.317 },
    { lat: 1.22, lng: 45.348 },
    { lat: 1.828, lng: 45.775 },
    { lat: 1.854, lng: 45.809 },
    { lat: 2.395, lng: 46.952 },
    { lat: 2.43, lng: 46.981 },
    { lat: 3.034, lng: 47.499 },
    { lat: 3.569, lng: 47.99 },
    { lat: 3.637, lng: 48.047 },
    { lat: 4.239, lng: 48.641 },
    { lat: 4.617, lng: 48.976 },
    { lat: 4.84, lng: 49.189 },
    { lat: 5.444, lng: 49.4 },
    { lat: 6.056, lng: 49.083 },
    { lat: 6.274, lng: 48.74 },
    { lat: 6.682, lng: 48.056 },
    { lat: 6.868, lng: 47.519 },
    { lat: 7.259, lng: 46.278 },
    { lat: 7.329, lng: 46.059 },
    { lat: 7.566, lng: 44.995 },
    { lat: 7.994, lng: 43.626 },
    { lat: 7.997, lng: 43.614 },
    { lat: 8.402, lng: 42.155 },
    { lat: 8.663, lng: 41.418 },
    { lat: 8.967, lng: 40.542 },
    { lat: 9.333, lng: 39.522 },
    { lat: 9.67, lng: 38.737 },
    { lat: 10.002, lng: 38.018 },
    { lat: 10.665, lng: 36.963 },
    { lat: 11.318, lng: 36.44 },
    { lat: 11.95, lng: 36.671 },
    { lat: 12.572, lng: 37.246 },
    { lat: 12.62, lng: 37.317 },
    { lat: 13.18, lng: 38.236 },
    { lat: 13.336, lng: 38.599 },
    { lat: 13.773, lng: 39.638 },
    { lat: 13.856, lng: 39.901 },
    { lat: 14.239, lng: 41.2 },
    { lat: 14.345, lng: 41.557 },
    { lat: 14.585, lng: 42.449 },
    { lat: 14.908, lng: 43.621 },
    { lat: 14.915, lng: 43.647 },
    { lat: 15.194, lng: 44.817 },
    { lat: 15.465, lng: 45.804 },
    { lat: 15.498, lng: 45.929 },
    { lat: 15.761, lng: 47.019 },
    { lat: 16.023, lng: 47.889 },
    { lat: 16.079, lng: 48.047 },
    { lat: 16.439, lng: 49.025 },
    { lat: 16.599, lng: 49.47 },
    { lat: 16.79, lng: 49.979 },
    { lat: 17.169, lng: 50.895 },
    { lat: 17.18, lng: 50.925 },
    { lat: 17.529, lng: 51.796 },
    { lat: 17.765, lng: 52.27 },
    { lat: 17.99, lng: 52.634 },
    { lat: 18.362, lng: 53.344 },
    { lat: 18.439, lng: 53.459 },
    { lat: 18.905, lng: 54.258 },
    { lat: 18.963, lng: 54.334 },
    { lat: 19.591, lng: 54.729 },
    { lat: 19.906, lng: 54.822 },
    { lat: 20.228, lng: 54.982 },
    { lat: 20.882, lng: 54.946 },
    { lat: 21.553, lng: 54.608 },
    { lat: 22.23, lng: 54.259 },
    { lat: 22.925, lng: 53.676 },
    { lat: 23.454, lng: 53.137 },
    { lat: 23.634, lng: 52.934 },
    { lat: 24.375, lng: 51.738 },
    { lat: 24.531, lng: 51.462 },
    { lat: 25.135, lng: 50.39 },
    { lat: 25.502, lng: 49.721 },
    { lat: 25.919, lng: 48.815 },
    { lat: 26.322, lng: 47.95 },
    { lat: 26.72, lng: 47.152 },
    { lat: 27.519, lng: 45.715 },
    { lat: 27.519, lng: 45.713 },
    { lat: 28.286, lng: 44.926 },
    { lat: 28.354, lng: 44.974 },
    { lat: 28.961, lng: 45.577 },
    { lat: 29.024, lng: 45.723 },
    { lat: 29.459, lng: 46.662 },
    { lat: 29.571, lng: 47.224 },
    { lat: 29.618, lng: 47.812 },
    { lat: 29.587, lng: 49.082 },
    { lat: 29.438, lng: 50.396 },
    { lat: 29.315, lng: 50.996 },
    { lat: 29.15, lng: 51.76 },
    { lat: 28.902, lng: 53.041 },
    { lat: 28.496, lng: 54.375 },
    { lat: 28.347, lng: 54.961 },
    { lat: 28.18, lng: 55.601 },
    { lat: 27.863, lng: 56.785 },
    { lat: 27.599, lng: 57.902 },
    { lat: 27.429, lng: 58.939 },
    { lat: 27.402, lng: 59.11 },
    { lat: 27.258, lng: 59.952 },
    { lat: 27.062, lng: 60.952 },
    { lat: 26.881, lng: 61.923 },
    { lat: 26.63, lng: 62.902 },
    { lat: 26.49, lng: 63.315 },
    { lat: 26.233, lng: 63.917 },
    { lat: 25.787, lng: 64.924 },
    { lat: 25.724, lng: 65.066 },
    { lat: 25.278, lng: 65.926 },
    { lat: 24.989, lng: 66.413 },
    { lat: 24.635, lng: 66.944 },
    { lat: 24.279, lng: 67.399 },
    { lat: 23.842, lng: 67.976 },
    { lat: 23.58, lng: 68.317 },
    { lat: 23.048, lng: 68.973 },
    { lat: 22.892, lng: 69.146 },
    { lat: 22.215, lng: 69.881 },
    { lat: 22.146, lng: 69.964 },
    { lat: 21.544, lng: 70.622 },
    { lat: 21.303, lng: 70.906 },
    { lat: 20.879, lng: 71.343 },
    { lat: 20.42, lng: 71.826 },
    { lat: 20.223, lng: 71.995 },
    { lat: 19.574, lng: 72.564 },
    { lat: 19.352, lng: 72.753 },
    { lat: 18.932, lng: 73.094 },
    { lat: 18.294, lng: 73.633 },
    { lat: 18.275, lng: 73.648 },
    { lat: 17.66, lng: 74.177 },
    { lat: 17.246, lng: 74.503 },
    { lat: 17.03, lng: 74.751 },
    { lat: 16.589, lng: 75.276 },
    { lat: 16.398, lng: 75.601 },
    { lat: 16.175, lng: 76.0 },
    { lat: 16.056, lng: 76.675 },
    { lat: 16.192, lng: 77.312 },
    { lat: 16.291, lng: 77.95 },
    { lat: 16.347, lng: 78.074 },
    { lat: 16.509, lng: 78.57 },
    { lat: 16.768, lng: 79.183 },
    { lat: 16.927, lng: 79.603 },
    { lat: 16.989, lng: 79.798 },
    { lat: 17.317, lng: 80.399 },
    { lat: 17.518, lng: 80.708 },
    { lat: 17.675, lng: 80.995 },
    { lat: 17.963, lng: 81.597 },
    { lat: 18.113, lng: 81.82 },
    { lat: 18.334, lng: 82.19 },
    { lat: 18.624, lng: 82.791 },
    { lat: 18.71, lng: 82.939 },
    { lat: 18.996, lng: 83.382 },
    { lat: 19.315, lng: 83.798 },
    { lat: 19.441, lng: 83.966 },
    { lat: 19.802, lng: 84.559 },
    { lat: 19.923, lng: 84.734 },
    { lat: 20.235, lng: 85.144 },
    { lat: 20.535, lng: 85.623 },
    { lat: 20.615, lng: 85.736 },
    { lat: 21.022, lng: 86.326 },
    { lat: 21.151, lng: 86.502 },
    { lat: 21.458, lng: 86.914 },
    { lat: 21.701, lng: 87.52 },
    { lat: 21.769, lng: 87.651 },
    { lat: 22.029, lng: 88.12 },
    { lat: 22.332, lng: 88.723 },
    { lat: 22.35, lng: 89.348 },
    { lat: 22.383, lng: 89.451 },
    { lat: 22.529, lng: 89.96 },
    { lat: 22.68, lng: 90.575 },
    { lat: 22.675, lng: 91.198 },
    { lat: 22.655, lng: 91.822 },
    { lat: 22.488, lng: 92.451 },
    { lat: 22.352, lng: 92.631 },
    { lat: 21.927, lng: 93.092 },
    { lat: 21.711, lng: 93.311 },
    { lat: 21.152, lng: 93.734 },
    { lat: 21.076, lng: 93.802 },
    { lat: 20.481, lng: 94.364 },
    { lat: 20.446, lng: 94.389 },
    { lat: 19.855, lng: 94.985 },
    { lat: 19.82, lng: 95.013 },
    { lat: 19.198, lng: 95.589 },
    { lat: 19.188, lng: 95.6 },
    { lat: 18.731, lng: 96.206 },
    { lat: 18.58, lng: 96.532 },
    { lat: 18.458, lng: 96.806 },
    { lat: 18.051, lng: 97.405 },
    { lat: 17.966, lng: 97.542 },
    { lat: 17.661, lng: 98.0 },
    { lat: 17.481, lng: 98.593 },
    { lat: 17.358, lng: 98.946 },
    { lat: 17.238, lng: 99.184 },
    { lat: 16.97, lng: 99.773 },
    { lat: 16.999, lng: 100.365 },
    { lat: 17.175, lng: 100.961 },
    { lat: 17.342, lng: 101.558 },
    { lat: 17.364, lng: 101.597 },
    { lat: 17.56, lng: 102.158 },
    { lat: 17.745, lng: 102.76 },
    { lat: 17.941, lng: 103.364 },
    { lat: 17.983, lng: 103.457 },
    { lat: 18.179, lng: 103.973 },
    { lat: 18.446, lng: 104.586 },
    { lat: 18.605, lng: 104.727 },
    { lat: 19.145, lng: 105.224 },
    { lat: 19.226, lng: 105.278 },
    { lat: 19.852, lng: 105.823 },
    { lat: 19.897, lng: 105.874 },
    { lat: 20.374, lng: 106.517 },
    { lat: 20.485, lng: 106.593 },
    { lat: 21.119, lng: 107.054 },
    { lat: 21.315, lng: 107.2 },
    { lat: 21.758, lng: 107.581 },
    { lat: 22.061, lng: 107.883 },
    { lat: 22.405, lng: 108.217 },
    { lat: 22.747, lng: 108.572 },
    { lat: 23.056, lng: 108.873 },
    { lat: 23.402, lng: 109.27 },
    { lat: 23.721, lng: 109.899 },
    { lat: 23.744, lng: 109.946 },
    { lat: 24.009, lng: 110.621 },
    { lat: 24.216, lng: 111.294 },
    { lat: 24.098, lng: 111.932 },
    { lat: 24.084, lng: 112.584 },
    { lat: 24.079, lng: 113.24 },
    { lat: 23.958, lng: 113.883 },
    { lat: 24.46, lng: 114.624 },
    { lat: 24.474, lng: 114.64 },
    { lat: 24.914, lng: 115.369 },
    { lat: 25.161, lng: 115.69 },
    { lat: 25.46, lng: 116.143 },
    { lat: 25.857, lng: 116.725 },
    { lat: 25.994, lng: 116.93 },
    { lat: 26.373, lng: 117.7 },
    { lat: 26.569, lng: 118.021 },
    { lat: 26.901, lng: 118.516 },
    { lat: 27.284, lng: 119.06 },
    { lat: 27.579, lng: 119.384 },
    { lat: 27.993, lng: 119.714 },
    { lat: 28.701, lng: 120.096 },
    { lat: 29.413, lng: 120.36 },
    { lat: 30.131, lng: 120.587 },
    { lat: 30.854, lng: 120.753 },
    { lat: 31.581, lng: 120.802 },
    { lat: 32.252, lng: 120.644 },
    { lat: 32.306, lng: 120.622 },
    { lat: 32.862, lng: 120.062 },
    { lat: 33.016, lng: 119.867 },
    { lat: 33.25, lng: 119.412 },
    { lat: 33.471, lng: 118.712 },
    { lat: 33.584, lng: 117.983 },
    { lat: 33.672, lng: 117.522 },
    { lat: 33.722, lng: 117.263 },
    { lat: 33.937, lng: 116.566 },
    { lat: 34.164, lng: 115.873 },
    { lat: 34.354, lng: 115.436 },
    { lat: 34.485, lng: 115.202 },
    { lat: 34.997, lng: 114.575 },
    { lat: 35.088, lng: 114.492 },
    { lat: 35.855, lng: 114.174 },
    { lat: 36.395, lng: 114.154 },
    { lat: 36.643, lng: 114.154 },
    { lat: 37.453, lng: 114.392 },
    { lat: 37.563, lng: 114.447 },
    { lat: 38.284, lng: 114.839 },
    { lat: 39.14, lng: 115.531 },
    { lat: 39.335, lng: 115.732 },
    { lat: 40.029, lng: 116.61 },
    { lat: 40.16, lng: 116.806 },
    { lat: 40.836, lng: 117.87 },
    { lat: 40.959, lng: 118.127 },
    { lat: 41.283, lng: 118.883 },
    { lat: 41.67, lng: 119.899 },
    { lat: 41.968, lng: 120.711 },
    { lat: 42.042, lng: 120.933 },
    { lat: 42.337, lng: 121.957 },
    { lat: 42.593, lng: 122.985 },
    { lat: 42.806, lng: 124.012 },
    { lat: 42.98, lng: 125.04 },
    { lat: 43.008, lng: 126.004 },
    { lat: 42.875, lng: 126.887 },
    { lat: 42.747, lng: 127.779 },
    { lat: 42.706, lng: 128.733 },
    { lat: 42.577, lng: 129.641 },
    { lat: 42.534, lng: 129.759 },
  ],
  c4: [
    { lat: 7.992, lng: 111.242 },
    { lat: 7.417, lng: 111.385 },
    { lat: 6.842, lng: 111.453 },
    { lat: 6.267, lng: 111.224 },
    { lat: 6.115, lng: 111.174 },
    { lat: 5.693, lng: 110.994 },
    { lat: 5.122, lng: 111.019 },
    { lat: 4.551, lng: 111.06 },
    { lat: 3.98, lng: 110.949 },
    { lat: 3.41, lng: 110.854 },
    { lat: 2.841, lng: 110.691 },
    { lat: 2.272, lng: 110.697 },
    { lat: 1.898, lng: 110.509 },
    { lat: 2.271, lng: 110.322 },
    { lat: 2.839, lng: 110.161 },
    { lat: 3.113, lng: 109.94 },
    { lat: 3.407, lng: 109.833 },
    { lat: 3.976, lng: 109.847 },
    { lat: 4.546, lng: 109.751 },
    { lat: 5.116, lng: 109.638 },
    { lat: 5.688, lng: 109.713 },
    { lat: 6.26, lng: 109.778 },
    { lat: 6.834, lng: 109.842 },
    { lat: 7.247, lng: 110.033 },
    { lat: 7.409, lng: 110.123 },
    { lat: 7.986, lng: 110.376 },
    { lat: 8.247, lng: 110.657 },
    { lat: 8.001, lng: 111.239 },
    { lat: 7.992, lng: 111.242 },
  ],
  c5: [
    { lat: 19.977, lng: 114.271 },
    { lat: 19.356, lng: 114.452 },
    { lat: 19.038, lng: 114.527 },
    { lat: 18.737, lng: 114.551 },
    { lat: 18.118, lng: 114.479 },
    { lat: 17.502, lng: 114.358 },
    { lat: 17.467, lng: 114.348 },
    { lat: 16.885, lng: 114.004 },
    { lat: 16.271, lng: 113.608 },
    { lat: 16.256, lng: 113.597 },
    { lat: 15.662, lng: 113.336 },
    { lat: 15.057, lng: 113.116 },
    { lat: 14.454, lng: 112.854 },
    { lat: 14.357, lng: 112.813 },
    { lat: 13.854, lng: 112.523 },
    { lat: 13.256, lng: 112.189 },
    { lat: 13.054, lng: 112.106 },
    { lat: 12.66, lng: 111.822 },
    { lat: 12.069, lng: 111.57 },
    { lat: 11.482, lng: 111.616 },
    { lat: 10.894, lng: 111.375 },
    { lat: 10.889, lng: 111.372 },
    { lat: 10.323, lng: 110.746 },
    { lat: 10.517, lng: 110.162 },
    { lat: 10.719, lng: 109.579 },
    { lat: 10.445, lng: 108.976 },
    { lat: 10.451, lng: 108.388 },
    { lat: 10.285, lng: 108.001 },
    { lat: 10.193, lng: 107.792 },
    { lat: 9.766, lng: 107.194 },
    { lat: 9.699, lng: 106.936 },
    { lat: 9.596, lng: 106.607 },
    { lat: 9.26, lng: 106.018 },
    { lat: 9.115, lng: 105.745 },
    { lat: 8.875, lng: 105.43 },
    { lat: 9.009, lng: 104.857 },
    { lat: 8.975, lng: 104.281 },
    { lat: 9.034, lng: 103.708 },
    { lat: 9.106, lng: 103.523 },
    { lat: 9.218, lng: 103.138 },
    { lat: 9.373, lng: 102.567 },
    { lat: 9.443, lng: 101.996 },
    { lat: 9.374, lng: 101.423 },
    { lat: 9.389, lng: 100.852 },
    { lat: 9.343, lng: 100.281 },
    { lat: 9.335, lng: 99.71 },
    { lat: 9.432, lng: 99.14 },
    { lat: 9.674, lng: 98.608 },
    { lat: 9.702, lng: 98.571 },
    { lat: 10.253, lng: 98.293 },
    { lat: 10.833, lng: 98.119 },
    { lat: 11.415, lng: 98.169 },
    { lat: 11.999, lng: 98.447 },
    { lat: 12.248, lng: 98.576 },
    { lat: 12.585, lng: 98.75 },
    { lat: 13.173, lng: 99.132 },
    { lat: 13.213, lng: 99.158 },
    { lat: 13.764, lng: 99.687 },
    { lat: 13.814, lng: 99.742 },
    { lat: 14.091, lng: 100.327 },
    { lat: 14.359, lng: 100.856 },
    { lat: 14.391, lng: 100.914 },
    { lat: 14.555, lng: 101.501 },
    { lat: 14.729, lng: 102.089 },
    { lat: 14.854, lng: 102.678 },
    { lat: 14.962, lng: 102.961 },
    { lat: 15.095, lng: 103.273 },
    { lat: 15.391, lng: 103.871 },
    { lat: 15.501, lng: 104.466 },
    { lat: 15.569, lng: 104.685 },
    { lat: 15.703, lng: 105.066 },
    { lat: 15.778, lng: 105.664 },
    { lat: 15.871, lng: 106.264 },
    { lat: 15.884, lng: 106.862 },
    { lat: 15.817, lng: 107.458 },
    { lat: 15.874, lng: 108.061 },
    { lat: 16.117, lng: 108.679 },
    { lat: 16.207, lng: 109.291 },
    { lat: 16.212, lng: 109.298 },
    { lat: 16.758, lng: 109.938 },
    { lat: 16.826, lng: 109.981 },
    { lat: 17.441, lng: 110.442 },
    { lat: 17.691, lng: 110.625 },
    { lat: 18.063, lng: 111.152 },
    { lat: 18.156, lng: 111.285 },
    { lat: 18.686, lng: 111.633 },
    { lat: 19.311, lng: 111.962 },
    { lat: 19.476, lng: 112.038 },
    { lat: 19.943, lng: 112.452 },
    { lat: 20.253, lng: 112.755 },
    { lat: 20.096, lng: 113.374 },
    { lat: 20.513, lng: 114.066 },
    { lat: 19.977, lng: 114.271 },
  ],
  c6: [
    { lat: 5.322, lng: 59.458 },
    { lat: 4.993, lng: 60.083 },
    { lat: 4.718, lng: 60.534 },
    { lat: 4.124, lng: 60.897 },
    { lat: 3.534, lng: 60.853 },
    { lat: 2.947, lng: 60.366 },
    { lat: 2.876, lng: 60.222 },
    { lat: 2.535, lng: 59.419 },
    { lat: 2.363, lng: 59.158 },
    { lat: 2.112, lng: 58.603 },
    { lat: 1.88, lng: 57.764 },
    { lat: 1.832, lng: 56.901 },
    { lat: 1.783, lng: 56.366 },
    { lat: 1.76, lng: 56.022 },
    { lat: 1.785, lng: 55.819 },
    { lat: 1.926, lng: 55.115 },
    { lat: 2.252, lng: 54.178 },
    { lat: 2.39, lng: 53.879 },
    { lat: 2.992, lng: 53.44 },
    { lat: 3.591, lng: 53.479 },
    { lat: 4.188, lng: 53.772 },
    { lat: 4.482, lng: 54.02 },
    { lat: 4.779, lng: 54.613 },
    { lat: 4.911, lng: 54.907 },
    { lat: 5.316, lng: 55.774 },
    { lat: 5.362, lng: 55.95 },
    { lat: 5.517, lng: 56.644 },
    { lat: 5.556, lng: 57.513 },
    { lat: 5.556, lng: 58.368 },
    { lat: 5.442, lng: 59.219 },
    { lat: 5.322, lng: 59.458 },
  ],
  c7: [
    { lat: 9.704, lng: 88.264 },
    { lat: 9.346, lng: 88.82 },
    { lat: 9.12, lng: 89.155 },
    { lat: 8.81, lng: 89.415 },
    { lat: 8.541, lng: 89.567 },
    { lat: 7.965, lng: 89.771 },
    { lat: 7.39, lng: 89.949 },
    { lat: 6.817, lng: 90.003 },
    { lat: 6.246, lng: 89.878 },
    { lat: 5.7, lng: 89.485 },
    { lat: 5.676, lng: 89.466 },
    { lat: 5.268, lng: 88.918 },
    { lat: 5.108, lng: 88.656 },
    { lat: 4.975, lng: 88.347 },
    { lat: 4.656, lng: 87.775 },
    { lat: 4.542, lng: 87.461 },
    { lat: 4.471, lng: 87.2 },
    { lat: 4.358, lng: 86.622 },
    { lat: 4.222, lng: 86.042 },
    { lat: 4.189, lng: 85.459 },
    { lat: 4.172, lng: 84.874 },
    { lat: 4.162, lng: 84.286 },
    { lat: 4.255, lng: 83.693 },
    { lat: 4.35, lng: 83.098 },
    { lat: 4.425, lng: 82.5 },
    { lat: 4.563, lng: 81.975 },
    { lat: 4.594, lng: 81.897 },
    { lat: 4.813, lng: 81.289 },
    { lat: 5.01, lng: 80.678 },
    { lat: 5.144, lng: 80.47 },
    { lat: 5.714, lng: 80.045 },
    { lat: 5.721, lng: 80.039 },
    { lat: 5.932, lng: 80.036 },
    { lat: 6.296, lng: 80.029 },
    { lat: 6.87, lng: 80.376 },
    { lat: 7.137, lng: 80.595 },
    { lat: 7.443, lng: 80.889 },
    { lat: 7.688, lng: 81.177 },
    { lat: 8.016, lng: 81.495 },
    { lat: 8.268, lng: 81.756 },
    { lat: 8.589, lng: 82.249 },
    { lat: 8.641, lng: 82.342 },
    { lat: 8.959, lng: 82.929 },
    { lat: 9.159, lng: 83.396 },
    { lat: 9.22, lng: 83.516 },
    { lat: 9.529, lng: 84.1 },
    { lat: 9.708, lng: 84.688 },
    { lat: 9.728, lng: 84.78 },
    { lat: 9.911, lng: 85.273 },
    { lat: 10.063, lng: 85.859 },
    { lat: 10.051, lng: 86.451 },
    { lat: 10.076, lng: 87.039 },
    { lat: 10.048, lng: 87.627 },
    { lat: 9.738, lng: 88.224 },
    { lat: 9.704, lng: 88.264 },
  ],
  c8: [
    { lat: 15.1, lng: 56.046 },
    { lat: 14.458, lng: 56.65 },
    { lat: 14.239, lng: 56.772 },
    { lat: 13.828, lng: 56.994 },
    { lat: 13.207, lng: 57.141 },
    { lat: 13.038, lng: 57.085 },
    { lat: 12.602, lng: 56.836 },
    { lat: 12.2, lng: 56.392 },
    { lat: 12.004, lng: 56.24 },
    { lat: 11.402, lng: 55.881 },
    { lat: 10.791, lng: 55.923 },
    { lat: 10.668, lng: 55.83 },
    { lat: 10.197, lng: 55.274 },
    { lat: 10.046, lng: 55.042 },
    { lat: 9.641, lng: 54.19 },
    { lat: 9.613, lng: 54.144 },
    { lat: 9.252, lng: 53.314 },
    { lat: 9.038, lng: 52.572 },
    { lat: 8.995, lng: 52.388 },
    { lat: 8.811, lng: 51.424 },
    { lat: 8.752, lng: 50.406 },
    { lat: 8.759, lng: 49.342 },
    { lat: 8.764, lng: 48.243 },
    { lat: 8.868, lng: 47.08 },
    { lat: 9.029, lng: 45.854 },
    { lat: 9.212, lng: 44.674 },
    { lat: 9.232, lng: 44.56 },
    { lat: 9.635, lng: 43.134 },
    { lat: 9.889, lng: 42.419 },
    { lat: 10.317, lng: 41.516 },
    { lat: 10.552, lng: 41.098 },
    { lat: 11.198, lng: 40.539 },
    { lat: 11.825, lng: 40.721 },
    { lat: 11.972, lng: 40.836 },
    { lat: 12.441, lng: 41.308 },
    { lat: 12.777, lng: 41.917 },
    { lat: 13.039, lng: 42.43 },
    { lat: 13.287, lng: 43.07 },
    { lat: 13.622, lng: 43.986 },
    { lat: 13.689, lng: 44.218 },
    { lat: 13.954, lng: 45.376 },
    { lat: 14.178, lng: 46.221 },
    { lat: 14.242, lng: 46.48 },
    { lat: 14.473, lng: 47.568 },
    { lat: 14.683, lng: 48.627 },
    { lat: 14.721, lng: 48.773 },
    { lat: 14.945, lng: 49.634 },
    { lat: 15.148, lng: 50.634 },
    { lat: 15.267, lng: 51.163 },
    { lat: 15.372, lng: 51.599 },
    { lat: 15.518, lng: 52.567 },
    { lat: 15.665, lng: 53.511 },
    { lat: 15.74, lng: 54.457 },
    { lat: 15.515, lng: 55.478 },
    { lat: 15.1, lng: 56.046 },
  ],
  c9: [
    { lat: 18.637, lng: 87.977 },
    { lat: 18.04, lng: 88.41 },
    { lat: 18.018, lng: 88.421 },
    { lat: 17.406, lng: 88.539 },
    { lat: 16.797, lng: 88.606 },
    { lat: 16.193, lng: 88.594 },
    { lat: 15.591, lng: 88.568 },
    { lat: 15.54, lng: 88.557 },
    { lat: 14.993, lng: 88.44 },
    { lat: 14.398, lng: 88.312 },
    { lat: 13.805, lng: 88.185 },
    { lat: 13.554, lng: 88.064 },
    { lat: 13.215, lng: 87.895 },
    { lat: 12.643, lng: 87.515 },
    { lat: 12.629, lng: 87.499 },
    { lat: 12.255, lng: 86.941 },
    { lat: 12.05, lng: 86.382 },
    { lat: 12.041, lng: 86.357 },
    { lat: 11.853, lng: 85.771 },
    { lat: 11.757, lng: 85.179 },
    { lat: 11.647, lng: 84.586 },
    { lat: 11.601, lng: 83.987 },
    { lat: 11.607, lng: 83.383 },
    { lat: 11.733, lng: 82.767 },
    { lat: 11.753, lng: 82.156 },
    { lat: 11.844, lng: 81.537 },
    { lat: 12.02, lng: 80.907 },
    { lat: 12.114, lng: 80.501 },
    { lat: 12.173, lng: 80.275 },
    { lat: 12.489, lng: 79.624 },
    { lat: 12.722, lng: 79.343 },
    { lat: 13.25, lng: 78.925 },
    { lat: 13.323, lng: 78.861 },
    { lat: 13.922, lng: 78.745 },
    { lat: 14.186, lng: 78.832 },
    { lat: 14.518, lng: 78.895 },
    { lat: 15.114, lng: 79.247 },
    { lat: 15.21, lng: 79.36 },
    { lat: 15.705, lng: 79.943 },
    { lat: 15.706, lng: 79.943 },
    { lat: 16.017, lng: 80.543 },
    { lat: 16.296, lng: 80.858 },
    { lat: 16.493, lng: 81.124 },
    { lat: 16.821, lng: 81.72 },
    { lat: 16.888, lng: 81.814 },
    { lat: 17.213, lng: 82.309 },
    { lat: 17.483, lng: 82.772 },
    { lat: 17.544, lng: 82.903 },
    { lat: 17.83, lng: 83.5 },
    { lat: 18.077, lng: 83.922 },
    { lat: 18.175, lng: 84.091 },
    { lat: 18.422, lng: 84.691 },
    { lat: 18.632, lng: 85.294 },
    { lat: 18.671, lng: 85.395 },
    { lat: 18.882, lng: 85.892 },
    { lat: 18.918, lng: 86.508 },
    { lat: 18.891, lng: 87.127 },
    { lat: 18.819, lng: 87.746 },
    { lat: 18.637, lng: 87.977 },
  ],
  c10: [
    { lat: 12.163, lng: 118.922 },
    { lat: 12.113, lng: 118.875 },
    { lat: 12.16, lng: 118.744 },
    { lat: 12.57, lng: 118.92 },
    { lat: 12.163, lng: 118.922 },
  ],
  c11: [
    { lat: 13.353, lng: 119.003 },
    { lat: 13.345, lng: 119.0 },
    { lat: 13.353, lng: 119.001 },
    { lat: 13.368, lng: 119.003 },
    { lat: 13.353, lng: 119.003 },
  ],
  c12: [
    { lat: 14.357, lng: 95.827 },
    { lat: 13.765, lng: 95.711 },
    { lat: 13.662, lng: 95.678 },
    { lat: 13.766, lng: 95.338 },
    { lat: 14.359, lng: 95.165 },
    { lat: 14.758, lng: 95.665 },
    { lat: 14.357, lng: 95.827 },
  ],
  c13: [
    { lat: 25.264, lng: 60.566 },
    { lat: 25.205, lng: 60.886 },
    { lat: 25.091, lng: 61.811 },
    { lat: 24.569, lng: 62.876 },
    { lat: 24.47, lng: 63.054 },
    { lat: 23.762, lng: 63.973 },
    { lat: 23.702, lng: 64.031 },
    { lat: 23.068, lng: 64.743 },
    { lat: 22.597, lng: 65.215 },
    { lat: 22.385, lng: 65.423 },
    { lat: 21.707, lng: 66.104 },
    { lat: 21.595, lng: 66.312 },
    { lat: 21.121, lng: 67.219 },
    { lat: 21.014, lng: 67.408 },
    { lat: 20.713, lng: 68.089 },
    { lat: 20.459, lng: 68.902 },
    { lat: 20.311, lng: 69.22 },
    { lat: 20.096, lng: 69.728 },
    { lat: 19.644, lng: 70.253 },
    { lat: 19.233, lng: 70.647 },
    { lat: 18.997, lng: 70.835 },
    { lat: 18.358, lng: 71.284 },
    { lat: 17.727, lng: 71.589 },
    { lat: 17.108, lng: 71.604 },
    { lat: 16.586, lng: 71.166 },
    { lat: 16.513, lng: 70.814 },
    { lat: 16.47, lng: 70.481 },
    { lat: 16.045, lng: 69.847 },
    { lat: 16.198, lng: 69.1 },
    { lat: 16.366, lng: 68.34 },
    { lat: 16.22, lng: 67.634 },
    { lat: 16.504, lng: 66.83 },
    { lat: 16.627, lng: 66.7 },
    { lat: 17.266, lng: 66.111 },
    { lat: 17.7, lng: 65.8 },
    { lat: 17.905, lng: 65.634 },
    { lat: 18.548, lng: 65.186 },
    { lat: 19.195, lng: 64.752 },
    { lat: 19.33, lng: 64.599 },
    { lat: 19.871, lng: 63.683 },
    { lat: 19.889, lng: 63.639 },
    { lat: 20.077, lng: 62.767 },
    { lat: 20.462, lng: 61.814 },
    { lat: 20.596, lng: 61.538 },
    { lat: 20.946, lng: 60.801 },
    { lat: 21.307, lng: 60.042 },
    { lat: 21.475, lng: 59.74 },
    { lat: 22.014, lng: 58.824 },
    { lat: 22.232, lng: 58.55 },
    { lat: 22.709, lng: 58.052 },
    { lat: 23.409, lng: 57.309 },
    { lat: 23.906, lng: 56.885 },
    { lat: 24.108, lng: 56.733 },
    { lat: 24.8, lng: 56.415 },
    { lat: 24.863, lng: 56.42 },
    { lat: 25.354, lng: 57.139 },
    { lat: 25.428, lng: 57.401 },
    { lat: 25.477, lng: 58.028 },
    { lat: 25.492, lng: 58.952 },
    { lat: 25.363, lng: 59.923 },
    { lat: 25.264, lng: 60.566 },
  ],
  c14: [
    { lat: 21.804, lng: 110.673 },
    { lat: 21.17, lng: 110.693 },
    { lat: 20.535, lng: 110.384 },
    { lat: 20.362, lng: 110.24 },
    { lat: 19.903, lng: 109.994 },
    { lat: 19.275, lng: 109.554 },
    { lat: 19.234, lng: 109.518 },
    { lat: 19.272, lng: 109.32 },
    { lat: 19.395, lng: 108.912 },
    { lat: 19.882, lng: 108.489 },
    { lat: 20.509, lng: 108.6 },
    { lat: 21.141, lng: 108.8 },
    { lat: 21.779, lng: 109.115 },
    { lat: 21.78, lng: 109.116 },
    { lat: 22.078, lng: 109.775 },
    { lat: 21.86, lng: 110.387 },
    { lat: 21.804, lng: 110.673 },
  ],
  c15: [
    { lat: 32.778, lng: 130.108 },
    { lat: 32.246, lng: 130.404 },
    { lat: 32.052, lng: 130.408 },
    { lat: 31.316, lng: 130.396 },
    { lat: 30.587, lng: 130.344 },
    { lat: 30.057, lng: 130.28 },
    { lat: 29.862, lng: 130.233 },
    { lat: 29.137, lng: 129.963 },
    { lat: 28.443, lng: 129.616 },
    { lat: 28.415, lng: 129.599 },
    { lat: 27.687, lng: 128.958 },
    { lat: 27.081, lng: 128.296 },
    { lat: 26.96, lng: 128.163 },
    { lat: 26.242, lng: 127.358 },
    { lat: 26.093, lng: 127.174 },
    { lat: 25.655, lng: 126.264 },
    { lat: 25.509, lng: 126.008 },
    { lat: 25.141, lng: 125.353 },
    { lat: 24.799, lng: 124.971 },
    { lat: 24.162, lng: 124.34 },
    { lat: 24.111, lng: 124.287 },
    { lat: 23.43, lng: 123.647 },
    { lat: 23.001, lng: 123.323 },
    { lat: 22.759, lng: 123.073 },
    { lat: 22.095, lng: 122.551 },
    { lat: 21.697, lng: 122.319 },
    { lat: 21.44, lng: 122.16 },
    { lat: 20.789, lng: 121.715 },
    { lat: 20.148, lng: 121.428 },
    { lat: 19.835, lng: 121.258 },
    { lat: 20.143, lng: 121.247 },
    { lat: 20.773, lng: 121.159 },
    { lat: 21.403, lng: 120.915 },
    { lat: 21.479, lng: 120.879 },
    { lat: 22.038, lng: 120.699 },
    { lat: 22.676, lng: 120.463 },
    { lat: 22.789, lng: 120.446 },
    { lat: 23.321, lng: 120.294 },
    { lat: 23.978, lng: 120.383 },
    { lat: 24.649, lng: 120.73 },
    { lat: 25.322, lng: 120.972 },
    { lat: 25.45, lng: 121.03 },
    { lat: 26.003, lng: 121.276 },
    { lat: 26.69, lng: 121.559 },
    { lat: 27.382, lng: 121.829 },
    { lat: 28.082, lng: 122.083 },
    { lat: 28.79, lng: 122.394 },
    { lat: 29.509, lng: 122.757 },
    { lat: 29.848, lng: 122.966 },
    { lat: 30.244, lng: 123.322 },
    { lat: 30.996, lng: 124.117 },
    { lat: 30.999, lng: 124.121 },
    { lat: 31.771, lng: 125.044 },
    { lat: 31.895, lng: 125.234 },
    { lat: 31.893, lng: 126.043 },
    { lat: 32.408, lng: 127.071 },
    { lat: 32.655, lng: 127.902 },
    { lat: 32.688, lng: 128.028 },
    { lat: 32.883, lng: 128.966 },
    { lat: 33.104, lng: 129.936 },
    { lat: 32.778, lng: 130.108 },
  ],
  c16: [
    { lat: 40.45, lng: 124.821 },
    { lat: 39.655, lng: 125.166 },
    { lat: 39.619, lng: 125.184 },
    { lat: 38.771, lng: 125.032 },
    { lat: 37.922, lng: 124.594 },
    { lat: 37.356, lng: 124.113 },
    { lat: 37.073, lng: 123.877 },
    { lat: 36.232, lng: 123.022 },
    { lat: 36.008, lng: 122.726 },
    { lat: 35.804, lng: 121.832 },
    { lat: 35.806, lng: 121.023 },
    { lat: 35.63, lng: 120.162 },
    { lat: 35.59, lng: 119.356 },
    { lat: 35.512, lng: 118.545 },
    { lat: 35.508, lng: 117.765 },
    { lat: 35.642, lng: 117.028 },
    { lat: 35.94, lng: 116.518 },
    { lat: 36.095, lng: 116.382 },
    { lat: 36.713, lng: 116.065 },
    { lat: 37.521, lng: 116.183 },
    { lat: 38.359, lng: 116.729 },
    { lat: 38.822, lng: 117.192 },
    { lat: 39.233, lng: 117.751 },
    { lat: 39.495, lng: 118.235 },
    { lat: 39.813, lng: 119.181 },
    { lat: 40.153, lng: 120.156 },
    { lat: 40.203, lng: 120.338 },
    { lat: 40.375, lng: 121.102 },
    { lat: 40.508, lng: 122.026 },
    { lat: 40.703, lng: 122.992 },
    { lat: 40.738, lng: 123.899 },
    { lat: 40.545, lng: 124.705 },
    { lat: 40.45, lng: 124.821 },
  ],
  c17: [
    { lat: 0.283, lng: 16.841 },
    { lat: 0.29, lng: 17.28 },
    { lat: 0.65, lng: 21.395 },
    { lat: 1.286, lng: 25.09 },
    { lat: 1.304, lng: 25.189 },
    { lat: 1.913, lng: 28.381 },
    { lat: 1.957, lng: 28.626 },
    { lat: 2.467, lng: 31.25 },
    { lat: 2.529, lng: 31.527 },
    { lat: 2.984, lng: 33.441 },
    { lat: 3.141, lng: 34.094 },
    { lat: 3.514, lng: 35.354 },
    { lat: 3.751, lng: 36.006 },
    { lat: 4.226, lng: 37.037 },
    { lat: 4.362, lng: 37.309 },
    { lat: 4.978, lng: 38.058 },
    { lat: 5.6, lng: 38.198 },
    { lat: 6.231, lng: 37.835 },
    { lat: 6.873, lng: 36.98 },
    { lat: 7.235, lng: 36.236 },
    { lat: 7.53, lng: 35.566 },
    { lat: 8.115, lng: 33.959 },
    { lat: 8.202, lng: 33.722 },
    { lat: 8.887, lng: 31.667 },
    { lat: 9.026, lng: 31.244 },
    { lat: 9.587, lng: 29.377 },
    { lat: 10.204, lng: 27.55 },
    { lat: 10.292, lng: 27.276 },
    { lat: 11.0, lng: 25.352 },
    { lat: 11.697, lng: 24.096 },
    { lat: 12.35, lng: 24.307 },
    { lat: 12.604, lng: 24.75 },
    { lat: 12.976, lng: 25.357 },
    { lat: 13.573, lng: 27.14 },
    { lat: 13.609, lng: 27.285 },
    { lat: 14.151, lng: 29.328 },
    { lat: 14.223, lng: 29.67 },
    { lat: 14.715, lng: 31.77 },
    { lat: 14.715, lng: 31.771 },
    { lat: 15.097, lng: 33.693 },
    { lat: 15.265, lng: 34.442 },
    { lat: 15.466, lng: 35.422 },
    { lat: 15.815, lng: 36.978 },
    { lat: 15.822, lng: 37.009 },
    { lat: 16.129, lng: 38.51 },
    { lat: 16.363, lng: 39.481 },
    { lat: 16.471, lng: 39.89 },
    { lat: 16.828, lng: 41.181 },
    { lat: 16.926, lng: 41.539 },
    { lat: 17.179, lng: 42.411 },
    { lat: 17.493, lng: 43.468 },
    { lat: 17.533, lng: 43.58 },
    { lat: 17.952, lng: 44.664 },
    { lat: 18.078, lng: 44.947 },
    { lat: 18.487, lng: 45.642 },
    { lat: 18.684, lng: 45.976 },
    { lat: 19.101, lng: 46.54 },
    { lat: 19.297, lng: 46.851 },
    { lat: 19.785, lng: 47.369 },
    { lat: 19.922, lng: 47.497 },
    { lat: 20.567, lng: 47.765 },
    { lat: 21.233, lng: 47.696 },
    { lat: 21.927, lng: 47.166 },
    { lat: 22.656, lng: 46.09 },
    { lat: 23.014, lng: 45.311 },
    { lat: 23.437, lng: 44.265 },
    { lat: 23.726, lng: 43.413 },
    { lat: 24.263, lng: 41.893 },
    { lat: 24.451, lng: 41.324 },
    { lat: 25.113, lng: 39.382 },
    { lat: 25.291, lng: 38.879 },
    { lat: 25.968, lng: 37.035 },
    { lat: 26.531, lng: 35.545 },
    { lat: 26.836, lng: 34.731 },
    { lat: 27.713, lng: 32.517 },
    { lat: 28.629, lng: 29.975 },
    { lat: 29.32, lng: 28.046 },
    { lat: 29.576, lng: 27.251 },
    { lat: 30.595, lng: 23.83 },
    { lat: 31.488, lng: 18.384 },
  ],
  c18: [
    { lat: 34.81, lng: 18.789 },
    { lat: 35.07, lng: 19.358 },
    { lat: 34.685, lng: 26.85 },
    { lat: 34.467, lng: 30.693 },
    { lat: 34.299, lng: 33.63 },
    { lat: 34.095, lng: 36.205 },
    { lat: 33.94, lng: 38.409 },
    { lat: 33.776, lng: 40.423 },
    { lat: 33.619, lng: 42.273 },
    { lat: 33.441, lng: 44.021 },
    { lat: 33.208, lng: 45.722 },
    { lat: 32.952, lng: 47.346 },
    { lat: 32.63, lng: 48.944 },
    { lat: 32.313, lng: 50.201 },
    { lat: 32.232, lng: 50.525 },
    { lat: 31.847, lng: 52.014 },
    { lat: 31.357, lng: 53.51 },
    { lat: 31.327, lng: 53.607 },
    { lat: 30.934, lng: 54.888 },
    { lat: 30.617, lng: 56.142 },
    { lat: 30.365, lng: 57.098 },
    { lat: 30.298, lng: 57.352 },
    { lat: 30.083, lng: 58.463 },
    { lat: 29.933, lng: 59.507 },
    { lat: 29.812, lng: 60.512 },
    { lat: 29.655, lng: 61.513 },
    { lat: 29.495, lng: 62.493 },
    { lat: 29.335, lng: 63.452 },
    { lat: 29.254, lng: 63.806 },
    { lat: 29.101, lng: 64.425 },
    { lat: 28.838, lng: 65.389 },
    { lat: 28.592, lng: 66.325 },
    { lat: 28.389, lng: 66.931 },
    { lat: 28.258, lng: 67.276 },
    { lat: 27.81, lng: 68.246 },
    { lat: 27.612, lng: 68.657 },
    { lat: 27.268, lng: 69.223 },
    { lat: 26.876, lng: 69.751 },
    { lat: 26.4, lng: 70.275 },
    { lat: 26.164, lng: 70.48 },
    { lat: 25.468, lng: 71.018 },
    { lat: 24.783, lng: 71.463 },
    { lat: 24.697, lng: 71.518 },
    { lat: 24.106, lng: 71.847 },
    { lat: 23.434, lng: 72.253 },
    { lat: 22.766, lng: 72.699 },
    { lat: 22.703, lng: 72.747 },
    { lat: 22.104, lng: 73.169 },
    { lat: 21.445, lng: 73.689 },
    { lat: 21.378, lng: 73.748 },
    { lat: 20.79, lng: 74.252 },
    { lat: 20.38, lng: 74.641 },
    { lat: 20.139, lng: 74.894 },
    { lat: 19.626, lng: 75.463 },
    { lat: 19.489, lng: 75.677 },
    { lat: 19.184, lng: 76.213 },
    { lat: 18.889, lng: 76.929 },
    { lat: 18.826, lng: 77.254 },
    { lat: 18.755, lng: 77.613 },
    { lat: 18.804, lng: 78.207 },
    { lat: 18.808, lng: 78.264 },
    { lat: 18.914, lng: 78.904 },
    { lat: 19.104, lng: 79.53 },
    { lat: 19.385, lng: 80.099 },
    { lat: 19.404, lng: 80.139 },
    { lat: 19.678, lng: 80.751 },
    { lat: 19.987, lng: 81.224 },
    { lat: 20.074, lng: 81.345 },
    { lat: 20.48, lng: 81.938 },
    { lat: 20.598, lng: 82.095 },
    { lat: 20.97, lng: 82.521 },
    { lat: 21.216, lng: 82.762 },
    { lat: 21.588, lng: 83.088 },
    { lat: 21.841, lng: 83.347 },
    { lat: 22.207, lng: 83.656 },
    { lat: 22.47, lng: 83.851 },
    { lat: 23.009, lng: 84.205 },
    { lat: 23.106, lng: 84.288 },
    { lat: 23.738, lng: 84.764 },
    { lat: 23.745, lng: 84.77 },
    { lat: 24.388, lng: 85.299 },
    { lat: 24.426, lng: 85.33 },
    { lat: 25.02, lng: 85.911 },
    { lat: 25.035, lng: 85.935 },
    { lat: 25.363, lng: 86.523 },
    { lat: 25.62, lng: 87.146 },
    { lat: 25.673, lng: 87.331 },
    { lat: 25.787, lng: 87.778 },
    { lat: 25.929, lng: 88.413 },
    { lat: 26.022, lng: 89.052 },
    { lat: 26.102, lng: 89.692 },
    { lat: 26.191, lng: 90.331 },
    { lat: 26.262, lng: 90.97 },
    { lat: 26.268, lng: 91.613 },
    { lat: 26.253, lng: 92.256 },
    { lat: 26.163, lng: 92.901 },
    { lat: 25.911, lng: 93.552 },
    { lat: 25.595, lng: 94.192 },
    { lat: 25.589, lng: 94.201 },
    { lat: 25.054, lng: 94.851 },
    { lat: 24.93, lng: 94.955 },
    { lat: 24.272, lng: 95.484 },
    { lat: 24.251, lng: 95.5 },
    { lat: 23.621, lng: 95.876 },
    { lat: 23.165, lng: 96.143 },
    { lat: 22.975, lng: 96.254 },
    { lat: 22.333, lng: 96.666 },
    { lat: 22.185, lng: 96.773 },
    { lat: 21.697, lng: 97.173 },
    { lat: 21.458, lng: 97.39 },
    { lat: 21.065, lng: 97.822 },
    { lat: 20.933, lng: 98.0 },
    { lat: 20.56, lng: 98.606 },
    { lat: 20.438, lng: 98.877 },
    { lat: 20.292, lng: 99.209 },
    { lat: 20.165, lng: 99.812 },
    { lat: 20.109, lng: 100.416 },
    { lat: 20.133, lng: 101.021 },
    { lat: 20.227, lng: 101.629 },
    { lat: 20.388, lng: 102.241 },
    { lat: 20.45, lng: 102.435 },
    { lat: 20.584, lng: 102.856 },
    { lat: 20.933, lng: 103.48 },
    { lat: 21.087, lng: 103.774 },
    { lat: 21.246, lng: 104.107 },
    { lat: 21.648, lng: 104.744 },
    { lat: 21.728, lng: 104.837 },
    { lat: 22.204, lng: 105.396 },
    { lat: 22.373, lng: 105.617 },
    { lat: 22.682, lng: 106.05 },
    { lat: 23.023, lng: 106.432 },
    { lat: 23.274, lng: 106.721 },
    { lat: 23.678, lng: 107.138 },
    { lat: 23.915, lng: 107.406 },
    { lat: 24.342, lng: 107.948 },
    { lat: 24.454, lng: 108.092 },
    { lat: 25.011, lng: 108.714 },
    { lat: 25.085, lng: 108.798 },
    { lat: 25.566, lng: 109.499 },
    { lat: 25.691, lng: 109.717 },
    { lat: 25.972, lng: 110.202 },
    { lat: 26.3, lng: 110.903 },
    { lat: 26.39, lng: 111.208 },
    { lat: 26.508, lng: 111.595 },
    { lat: 26.699, lng: 112.291 },
    { lat: 26.932, lng: 113.0 },
    { lat: 27.121, lng: 113.567 },
    { lat: 27.178, lng: 113.717 },
    { lat: 27.642, lng: 114.482 },
    { lat: 27.836, lng: 114.759 },
    { lat: 28.368, lng: 115.31 },
    { lat: 28.548, lng: 115.48 },
    { lat: 29.259, lng: 115.902 },
    { lat: 29.968, lng: 116.028 },
    { lat: 30.643, lng: 115.78 },
    { lat: 30.673, lng: 115.768 },
    { lat: 31.245, lng: 115.192 },
    { lat: 31.371, lng: 115.066 },
    { lat: 31.701, lng: 114.57 },
    { lat: 32.022, lng: 113.917 },
    { lat: 32.06, lng: 113.795 },
    { lat: 32.24, lng: 113.242 },
    { lat: 32.462, lng: 112.568 },
    { lat: 32.617, lng: 111.881 },
    { lat: 32.624, lng: 111.169 },
    { lat: 32.557, lng: 110.448 },
    { lat: 32.517, lng: 109.735 },
    { lat: 32.502, lng: 109.03 },
    { lat: 32.542, lng: 108.334 },
    { lat: 32.653, lng: 108.116 },
    { lat: 33.39, lng: 107.783 },
    { lat: 34.147, lng: 108.009 },
    { lat: 34.922, lng: 108.537 },
    { lat: 35.087, lng: 108.695 },
    { lat: 35.715, lng: 109.356 },
    { lat: 35.857, lng: 109.549 },
    { lat: 36.525, lng: 110.299 },
    { lat: 36.623, lng: 110.427 },
    { lat: 37.349, lng: 111.179 },
    { lat: 37.498, lng: 111.353 },
    { lat: 38.185, lng: 111.931 },
    { lat: 38.687, lng: 112.385 },
    { lat: 39.036, lng: 112.681 },
    { lat: 39.878, lng: 113.471 },
    { lat: 39.907, lng: 113.497 },
    { lat: 40.799, lng: 114.391 },
    { lat: 40.977, lng: 114.587 },
    { lat: 41.719, lng: 115.475 },
    { lat: 41.897, lng: 115.702 },
    { lat: 42.606, lng: 116.794 },
    { lat: 42.678, lng: 116.916 },
    { lat: 43.227, lng: 117.892 },
    { lat: 43.687, lng: 118.807 },
    { lat: 43.782, lng: 119.0 },
    { lat: 44.292, lng: 120.125 },
    { lat: 44.759, lng: 121.23 },
    { lat: 44.777, lng: 121.274 },
    { lat: 45.247, lng: 122.452 },
    { lat: 45.71, lng: 123.664 },
    { lat: 45.911, lng: 124.256 },
    { lat: 46.133, lng: 124.893 },
    { lat: 46.441, lng: 126.09 },
    { lat: 46.751, lng: 127.322 },
    { lat: 47.032, lng: 128.57 },
    { lat: 47.298, lng: 129.693 },
    { lat: 47.345, lng: 129.879 },
    { lat: 47.749, lng: 131.306 },
    { lat: 48.254, lng: 132.881 },
    { lat: 48.703, lng: 134.164 },
    { lat: 48.886, lng: 134.655 },
    { lat: 49.477, lng: 136.497 },
    { lat: 49.78, lng: 138.123 },
    { lat: 49.789, lng: 139.469 },
    { lat: 49.411, lng: 140.372 },
    { lat: 49.29, lng: 140.413 },
    { lat: 48.355, lng: 140.454 },
    { lat: 48.245, lng: 140.431 },
    { lat: 47.197, lng: 140.118 },
    { lat: 46.742, lng: 139.92 },
    { lat: 46.156, lng: 139.592 },
    { lat: 45.135, lng: 138.998 },
    { lat: 44.153, lng: 138.488 },
    { lat: 44.146, lng: 138.484 },
    { lat: 43.182, lng: 138.015 },
    { lat: 42.248, lng: 137.649 },
    { lat: 41.787, lng: 137.493 },
    { lat: 41.34, lng: 137.384 },
    { lat: 40.461, lng: 137.259 },
    { lat: 40.143, lng: 137.235 },
    { lat: 39.606, lng: 137.252 },
    { lat: 38.875, lng: 137.315 },
    { lat: 38.772, lng: 137.339 },
    { lat: 37.961, lng: 137.538 },
    { lat: 37.794, lng: 137.558 },
    { lat: 37.171, lng: 137.863 },
    { lat: 36.954, lng: 137.991 },
    { lat: 36.406, lng: 138.384 },
    { lat: 36.206, lng: 138.496 },
    { lat: 35.642, lng: 138.782 },
    { lat: 35.261, lng: 138.863 },
    { lat: 34.878, lng: 139.037 },
    { lat: 34.106, lng: 139.044 },
    { lat: 34.006, lng: 139.019 },
    { lat: 33.333, lng: 138.909 },
    { lat: 32.558, lng: 138.601 },
    { lat: 31.789, lng: 138.237 },
    { lat: 31.025, lng: 137.804 },
    { lat: 30.267, lng: 137.325 },
    { lat: 29.522, lng: 136.916 },
    { lat: 28.782, lng: 136.454 },
    { lat: 28.055, lng: 136.07 },
    { lat: 27.333, lng: 135.626 },
    { lat: 26.62, lng: 135.23 },
    { lat: 25.913, lng: 134.815 },
    { lat: 25.214, lng: 134.426 },
    { lat: 24.651, lng: 134.011 },
    { lat: 24.516, lng: 133.92 },
    { lat: 23.827, lng: 133.478 },
    { lat: 23.144, lng: 133.014 },
    { lat: 22.465, lng: 132.515 },
    { lat: 22.18, lng: 132.269 },
    { lat: 21.79, lng: 131.982 },
    { lat: 21.121, lng: 131.45 },
    { lat: 20.458, lng: 130.934 },
    { lat: 20.451, lng: 130.927 },
    { lat: 19.798, lng: 130.323 },
    { lat: 19.331, lng: 129.84 },
    { lat: 19.139, lng: 129.638 },
    { lat: 18.488, lng: 128.978 },
    { lat: 18.309, lng: 128.825 },
    { lat: 17.846, lng: 128.473 },
    { lat: 17.208, lng: 127.969 },
    { lat: 16.98, lng: 127.786 },
    { lat: 16.567, lng: 127.183 },
    { lat: 16.393, lng: 126.938 },
    { lat: 15.936, lng: 126.602 },
    { lat: 15.313, lng: 126.177 },
    { lat: 15.018, lng: 125.972 },
    { lat: 14.692, lng: 125.682 },
    { lat: 14.074, lng: 125.144 },
    { lat: 14.034, lng: 125.111 },
    { lat: 13.461, lng: 124.689 },
    { lat: 12.852, lng: 124.256 },
    { lat: 12.845, lng: 124.251 },
    { lat: 12.247, lng: 123.866 },
    { lat: 11.643, lng: 123.427 },
    { lat: 11.632, lng: 123.42 },
    { lat: 11.044, lng: 123.047 },
    { lat: 10.448, lng: 122.746 },
    { lat: 10.173, lng: 122.595 },
    { lat: 9.854, lng: 122.417 },
    { lat: 9.262, lng: 122.109 },
    { lat: 8.673, lng: 121.834 },
    { lat: 8.596, lng: 121.797 },
    { lat: 8.085, lng: 121.552 },
    { lat: 7.5, lng: 121.289 },
    { lat: 6.916, lng: 121.066 },
    { lat: 6.784, lng: 121.022 },
    { lat: 6.335, lng: 120.88 },
    { lat: 5.754, lng: 120.712 },
    { lat: 5.175, lng: 120.572 },
    { lat: 4.598, lng: 120.447 },
    { lat: 4.021, lng: 120.33 },
    { lat: 3.67, lng: 120.241 },
    { lat: 3.444, lng: 120.175 },
    { lat: 2.869, lng: 119.955 },
    { lat: 2.294, lng: 119.707 },
    { lat: 1.994, lng: 119.569 },
    { lat: 1.719, lng: 119.43 },
    { lat: 1.146, lng: 119.133 },
    { lat: 0.846, lng: 118.928 },
    { lat: 0.572, lng: 118.692 },
    { lat: 0.169, lng: 118.302 },
    { lat: 0.0, lng: 118.131 },
    { lat: -0.4, lng: 117.683 },
    { lat: -0.572, lng: 117.448 },
    { lat: -0.829, lng: 117.069 },
    { lat: -1.14, lng: 116.459 },
    { lat: -1.142, lng: 116.456 },
    { lat: -1.469, lng: 115.854 },
    { lat: -1.711, lng: 115.449 },
    { lat: -1.819, lng: 115.252 },
    { lat: -2.089, lng: 114.653 },
    { lat: -2.28, lng: 114.397 },
    { lat: -2.513, lng: 114.059 },
    { lat: -2.848, lng: 113.658 },
    { lat: -2.99, lng: 113.469 },
    { lat: -3.329, lng: 112.881 },
    { lat: -3.416, lng: 112.769 },
    { lat: -3.875, lng: 112.298 },
    { lat: -3.984, lng: 112.195 },
    { lat: -4.39, lng: 111.719 },
    { lat: -4.552, lng: 111.475 },
    { lat: -4.761, lng: 111.139 },
    { lat: -5.12, lng: 110.654 },
    { lat: -5.185, lng: 110.562 },
    { lat: -5.468, lng: 109.984 },
    { lat: -5.686, lng: 109.423 },
    { lat: -5.693, lng: 109.406 },
    { lat: -5.917, lng: 108.829 },
    { lat: -5.983, lng: 108.251 },
    { lat: -6.14, lng: 107.675 },
    { lat: -6.233, lng: 107.1 },
    { lat: -6.248, lng: 106.89 },
    { lat: -6.279, lng: 106.525 },
    { lat: -6.261, lng: 105.951 },
    { lat: -6.245, lng: 105.885 },
    { lat: -6.127, lng: 105.375 },
    { lat: -5.892, lng: 104.8 },
    { lat: -5.671, lng: 104.456 },
    { lat: -5.516, lng: 104.225 },
    { lat: -5.1, lng: 103.781 },
    { lat: -4.965, lng: 103.65 },
    { lat: -4.53, lng: 103.156 },
    { lat: -4.454, lng: 103.078 },
    { lat: -3.962, lng: 102.585 },
    { lat: -3.882, lng: 102.508 },
    { lat: -3.394, lng: 102.025 },
    { lat: -3.332, lng: 101.94 },
    { lat: -2.879, lng: 101.374 },
    { lat: -2.827, lng: 101.297 },
    { lat: -2.511, lng: 100.81 },
    { lat: -2.261, lng: 100.5 },
    { lat: -2.056, lng: 100.246 },
    { lat: -1.695, lng: 99.795 },
    { lat: -1.616, lng: 99.684 },
    { lat: -1.133, lng: 99.122 },
    { lat: -1.131, lng: 99.119 },
    { lat: -0.71, lng: 98.562 },
    { lat: -0.564, lng: 98.36 },
    { lat: -0.296, lng: 98.0 },
    { lat: 0.0, lng: 97.556 },
    { lat: 0.075, lng: 97.439 },
    { lat: 0.418, lng: 96.879 },
    { lat: 0.564, lng: 96.594 },
    { lat: 0.725, lng: 96.316 },
    { lat: 1.011, lng: 95.753 },
    { lat: 1.13, lng: 95.532 },
    { lat: 1.314, lng: 95.192 },
    { lat: 1.662, lng: 94.628 },
    { lat: 1.695, lng: 94.561 },
    { lat: 1.952, lng: 94.064 },
    { lat: 2.176, lng: 93.5 },
    { lat: 2.262, lng: 93.283 },
    { lat: 2.401, lng: 92.935 },
    { lat: 2.579, lng: 92.368 },
    { lat: 2.677, lng: 91.801 },
    { lat: 2.721, lng: 91.233 },
    { lat: 2.734, lng: 90.665 },
    { lat: 2.679, lng: 90.095 },
    { lat: 2.608, lng: 89.525 },
    { lat: 2.525, lng: 88.953 },
    { lat: 2.413, lng: 88.38 },
    { lat: 2.28, lng: 87.806 },
    { lat: 2.268, lng: 87.747 },
    { lat: 2.166, lng: 87.23 },
    { lat: 2.06, lng: 86.652 },
    { lat: 1.956, lng: 86.073 },
    { lat: 1.853, lng: 85.492 },
    { lat: 1.736, lng: 84.908 },
    { lat: 1.705, lng: 84.669 },
    { lat: 1.659, lng: 84.322 },
    { lat: 1.564, lng: 83.734 },
    { lat: 1.476, lng: 83.144 },
    { lat: 1.405, lng: 82.551 },
    { lat: 1.337, lng: 81.955 },
    { lat: 1.239, lng: 81.356 },
    { lat: 1.158, lng: 80.754 },
    { lat: 1.141, lng: 80.636 },
    { lat: 1.074, lng: 80.15 },
    { lat: 0.984, lng: 79.542 },
    { lat: 0.889, lng: 78.93 },
    { lat: 0.798, lng: 78.315 },
    { lat: 0.718, lng: 77.696 },
    { lat: 0.646, lng: 77.074 },
    { lat: 0.573, lng: 76.449 },
    { lat: 0.573, lng: 76.446 },
    { lat: 0.53, lng: 75.815 },
    { lat: 0.501, lng: 75.179 },
    { lat: 0.442, lng: 74.538 },
    { lat: 0.394, lng: 73.893 },
    { lat: 0.328, lng: 73.242 },
    { lat: 0.237, lng: 72.586 },
    { lat: 0.126, lng: 71.925 },
    { lat: 0.004, lng: 71.257 },
    { lat: -0.0, lng: 71.237 },
    { lat: -0.112, lng: 70.583 },
    { lat: -0.23, lng: 69.902 },
    { lat: -0.357, lng: 69.215 },
    { lat: -0.488, lng: 68.52 },
    { lat: -0.581, lng: 67.972 },
    { lat: -0.605, lng: 67.817 },
    { lat: -0.681, lng: 67.106 },
    { lat: -0.759, lng: 66.388 },
    { lat: -0.794, lng: 65.66 },
    { lat: -0.817, lng: 64.924 },
    { lat: -0.833, lng: 64.178 },
    { lat: -0.832, lng: 63.423 },
    { lat: -0.842, lng: 62.656 },
    { lat: -0.854, lng: 61.878 },
    { lat: -0.904, lng: 61.087 },
    { lat: -0.969, lng: 60.283 },
    { lat: -1.052, lng: 59.464 },
    { lat: -1.163, lng: 58.631 },
    { lat: -1.183, lng: 58.465 },
    { lat: -1.264, lng: 57.782 },
    { lat: -1.373, lng: 56.915 },
    { lat: -1.498, lng: 56.03 },
    { lat: -1.64, lng: 55.125 },
    { lat: -1.791, lng: 54.348 },
    { lat: -1.821, lng: 54.197 },
    { lat: -2.062, lng: 53.242 },
    { lat: -2.339, lng: 52.26 },
    { lat: -2.4, lng: 52.069 },
    { lat: -2.664, lng: 51.244 },
    { lat: -3.014, lng: 50.287 },
    { lat: -3.055, lng: 50.19 },
    { lat: -3.481, lng: 49.095 },
    { lat: -3.632, lng: 48.623 },
    { lat: -3.839, lng: 47.963 },
    { lat: -4.181, lng: 46.785 },
    { lat: -4.262, lng: 46.415 },
    { lat: -4.425, lng: 45.568 },
    { lat: -4.598, lng: 44.305 },
    { lat: -4.748, lng: 42.981 },
    { lat: -4.836, lng: 41.595 },
    { lat: -4.875, lng: 40.134 },
    { lat: -4.888, lng: 38.577 },
    { lat: -4.806, lng: 36.917 },
    { lat: -4.715, lng: 35.105 },
    { lat: -4.529, lng: 33.111 },
    { lat: -4.423, lng: 32.126 },
    { lat: -4.27, lng: 30.847 },
    { lat: -3.887, lng: 28.162 },
    { lat: -3.834, lng: 27.819 },
    { lat: -3.301, lng: 24.643 },
    { lat: -3.227, lng: 24.07 },
    { lat: -2.659, lng: 16.851 },
  ],
};
export var ocn_gntgain = {
  c1: "-2",
  c2: "-1",
  c3: "0",
  c4: "0",
  c5: "0",
  c6: "1",
  c7: "1",
  c8: "1",
  c9: "1",
  c10: "1",
  c11: "1",
  c12: "1",
  c13: "1",
  c14: "1",
  c15: "1",
  c16: "1",
  c17: "-3",
  c18: "-3",
};
