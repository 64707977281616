import { useCustomRangeStore } from '@/stores';



export default {
    name: "range_modal",
    data() {
        return {
            customrange_store: useCustomRangeStore(),
        }
    },

    mounted() {
    },

    methods: {
    },

    watch: {
    },
    computed: {
    }
};