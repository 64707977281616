import { defineStore } from 'pinia';
import { useEtaStore, useCustomRangeStore, useMapStore } from '../index';
import api from "@/plugins/axios";
import $ from "jquery";
import DataTable from 'datatables.net-dt';
import L from "leaflet";
import Chart from 'chart.js/auto'



export const useManifestStore = defineStore({
    id: 'manifest',
    state: () => ({
        eta_store: useEtaStore(),
        map_store: useMapStore(),
        range_store: useCustomRangeStore(),
        manifest_table: null,
        manifestsuser_table: null,
        manifeststrack_table: null,
        manifestmap_table: null,
        manifestreport_item: null,
        manifest_chart: null,
        manifestreport_data: null,
        is_manifestreport: null,
        search_manifest: null,
        search_manifestoutput: null,
        search_manifestmap: null,
        search_manifeststrack: null,
        manifest_data: null,
        last_center: [-1.4312313766684295, 119.1498789812993],
        last_zoom: 6,
        mapmanifest_data: null,
        mapmanifest_data_date: null,
        page_off: 10,
        page_off_output: 10,
        page_off_map: 10,
        show_range: "Last 24 Hours",
        manifestreport_range: "Today",
        export_range: "24",
        mapmanifest_id: null,
        loading_range: false,
        tracking_layer: L.layerGroup(),
        target_layer: L.layerGroup(),
        link_layer: L.layerGroup(),
        search_layer: L.layerGroup(),
        marker_tracking: [],
        moving_marker: null,
        tracking_path: null,
        tracking_latLng: null,
        tracking_rotation: null,
        interval_tracking: null,
        manifest_map: null,
        date_range: null,
        isBottom: false,
        manifestreport_range_arr: [
            { label: "Today", val: "today" },
            { label: "Last 24 Hours", val: "24" },
            { label: "Last 7 Days", val: "7" },
            { label: "Last 30 Days", val: "30" },
            { label: "This Week", val: "week" },
            { label: "This Month", val: "month" },
            { label: "Last Month", val: "lmonth" },
            { label: "Custom Range", val: "24" },
        ],
        range_arr: [
            { label: "Today", val: "today" },
            { label: "Last 24 Hours", val: "24" },
            { label: "Last 7 Days", val: "7" },
            { label: "Last 30 Days", val: "30" },
            { label: "This Week", val: "week" },
            { label: "This Month", val: "month" },
            { label: "Last Month", val: "lmonth" },
            { label: "Custom Range", val: "24" },
        ],
        page_off_arr: [
            10,
            25,
            50,
            100
        ],

        page_off_output_arr
            : [
                10,
                25,
                50,
                100
            ],

        page_off_map_arr
            : [
                10,
                25,
                50,
                100
            ],

        th_manifest_arr: [
            "No",
            "Thuraya Number",
            "Vessel Name",
            "Company",
            "Manifest",
        ],
        th_manifest_map_arr: [
            "",
        ],
        th_manifest_output_arr: [
            "#",
            "Date",
        ],
        th_manifest_user_arr: [
            "#",
            "Name",
            "Email",
            "Role",
            "Last Login"
        ],
        td_manifest_arr: [
            4,
            24,
            28.5,
            28.5,
            15,
        ],
        td_button: [
            { a: 'not', b: '' },
            { a: 'not', b: '' },
            { a: 'not', b: '' },
            { a: 'not', b: '' },
            { a: 'button_show', b: 'btn-success' },
        ],
        td_manifest_output_arr: [
            20,
            80,
        ],


    }),

    actions: {



        // DATA FETCH FUNCTIONS
        //------------------------------------------------------------------
        //Fetch Manifest Data
        async fetch_manifest() {
            await api.get(`/api/v1/navplus/manifest`, {}
            ).then((res) => {
                console.log("manifests RESPONSE");
                console.log(res);
                if (this.search_manifests == null || this.search_manifests.length == 0) {
                    this.manifest_data = res.data.data.vessels;
                    this.initTable();
                }

            }).catch((err) => {
            }).then(() => {
            });
        },
        async fetch_manifest_report(item, range) {
            this.manifestreport_data = null;
            if (this.manifest_chart) {
                console.log("DESTROY CHART");
                this.manifest_chart.destroy();
                this.manifest_chart = null
            }

            console.log(item.id);
            console.log(range);


            await api.post(`/api/v1/navplus/manifest`, {
                thuraya_number: item.thuraya_number,
                range: range,
            }
            ).then((res) => {
                console.log("MANIFEST REPORT FETCH");
                console.log(res);
                this.manifestreport_data = res.data.data

                setTimeout(() => {
                    this.initTable_output(res.data.data)
                    if (res.data.data.gmaps) {
                        this.initTable_map(res.data.data)
                    }
                }, 1000);


            }).catch((err) => {
            }).then(() => {
            });
        },
        //------------------------------------------------------------------



        //TABLE SEARCH & PAGE FUNCTIONS
        //------------------------------------------------------------------
        //search manifests table
        manifestTable_search() {
            if (this.search_manifest != null && this.search_manifest.length > 0) {
                this.manifest_table.search(this.search_manifest).draw();
            }
        },
        //search manifests table
        outputTable_search() {

            this.manifestoutput_table.search(this.search_manifestoutput).draw();

        },
        //search manifests table
        mapTable_search() {

            this.manifestmap_table.search(this.search_manifestmap).draw();

        },
        //clear manifest table
        clear_manifestTable_search() {
            this.search_manifest = ''
            this.manifest_table.search(this.search_manifest).draw();
        },
        //clear manifest table
        clear_manifestTableOutput_search() {
            this.search_manifestoutput = ''
            this.manifestoutput_table.search(this.search_manifestoutput).draw();
        },
        //clear manifest table
        clear_manifestTableMap_search() {
            this.search_manifestmap = ''
            this.manifestmap_table.search(this.search_manifestmap).draw();
        },
        //search manifests user table
        userTable_search() {
            this.manifestsuser_table.search(this.search_manifestsuser).draw();
        },
        //search manifests tracking table
        trackTable_search() {
            this.manifeststrack_table.search(this.search_manifeststrack).draw();
        },
        //data shorting manifests table 
        manifestsTable_showof(page) {
            console.log("PAGE : " + page);
            this.manifest_table.page.len(page).draw()
            this.page_off = page;
        },
        manifestOutputTable_showof(page) {
            console.log("PAGE : " + page);
            this.manifestoutput_table.page.len(page).draw()
            this.page_off_output = page;
        },

        manifestMapTable_showof(page) {
            console.log("PAGE : " + page);
            this.manifestmap_table.page.len(page).draw()
            this.page_off_map = page;
        },

        //data shorting manifests table 
        manifestsTrackTable_showof(page) {
            console.log("PAGE : " + page);
            this.manifeststrack_table.page.len(page).draw()
            this.page_off_tracking = page;
        },
        //------------------------------------------------------------------


        //INITIALIZE TABLE FUNCTIONS
        //------------------------------------------------------------------
        //Initable Manifest
        initTable() {
            var root = this
            if (this.manifest_table) {
                console.log("MASUK CLEAR TABLE");
                root.manifest_table = null;
                root.initTable()
            } else {
                console.log("MASUK INIT TABLE");
                console.log(this.manifest_data);
                this.manifest_table = new DataTable('#manifestTable', {
                    data: this.manifest_data,
                    scrollCollapse: true,
                    scroller: true,
                    destroy: true,
                    deferRender: true,
                    ordering: true,
                    bLengthChange: false,
                    responsive: true,
                    dom: 'lrtip',
                    columns: [
                        {
                            width: "4%",
                            data: '', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                            <div class="p-1 h-100 row`+ meta.row + `">
                            ${meta.row + 1}
                            </div>
                            `
                            }
                        },
                        {
                            width: "24%",
                            data: 'thuraya_number', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                            <div class="p-1 h-100 row`+ meta.row + `">
                            +${data}
                            </div>
                            `
                            }
                        },
                        {
                            width: "28.5%",
                            data: 'name', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                                <div class="p-1 h-100 row`+ meta.row + `">
                                ${data}
                                </div>
                                    `
                            }
                        },
                        {
                            width: "28.5%",
                            data: 'pt_name', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                                <div class="p-1 h-100 row`+ meta.row + `">
                                ${data}
                                </div>
                                    `
                            }
                        },
                        {
                            width: "15%",
                            data: 'id', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                        
                              <div class="p-1 h-100">
                              <button id="manifestreport_btn"  type="button" class="btn btn-sm btn-success w-100 rounded-none"><i class="fas fa-chart-line"></i></button>
                              </div>
                              `
                            }
                        },

                    ]
                });


                var mom = this;

                this.manifest_table.on('click', '#manifestreport_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.manifest_table.row(tr);
                    console.log(row.data());
                    mom.manifest_report(row.data())
                });
            }
        },
        //Initable Output
        initTable_output(dataoutput) {
            var mom = this;
            this.manifestoutput_table = new DataTable('#manifestoutputTable', {
                data: dataoutput.output,
                scrollCollapse: true,
                scroller: true,
                destroy: true,
                pageLength: 10,
                deferRender: true,
                ordering: true,
                bLengthChange: false,
                responsive: true,
                dom: 'lrtip',
                columns: mom.return_td()
            });

        },
        //Initable Map
        initTable_map(datagmap) {
            console.log("INIT TABLE MAP");
            console.log(this.manifestmap_table);
            console.log("MASUK INIT TABLE Map");
            var mom = this;
            this.manifestmap_table = new DataTable('#manifestTableMaps', {
                data: datagmap.gmaps,
                scrollCollapse: true,
                stateSave: true,
                scroller: true,
                destroy: true,
                pageLength: 10,
                deferRender: true,
                ordering: true,
                bLengthChange: false,
                responsive: true,
                dom: 'lrtip',
                columns: [
                    {
                        width: "",
                        data: '', className: 'td_nowrap', render: (data, type, row, meta) => {


                            return `
                           <div 
                                                class="p-1  h-100 d-flex align-items-center justify-content-center">
                                                <div class="text-start">
                                                    <div class="card rounded-none "
                                                        style="min-width:35em;min-height:6em;">
                                                        <div class="card-header pb-2">
                                                            <h7 class="card-title">
                                                                ${datagmap.gmaps[meta.row].dateTime}
                                                            </h7>
                                                        </div>
                                                        <div
                                                            class="card-body  d-flex align-items-center justify-content-center">
                                                            <div class="row w-100">
                                                                <div class="col-sm-6" >
                                                                <table class="table table-striped  w-auto map_mapsnp">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Item</th>
                                                                            <th>Amount</th>
                                                                        </tr>
                                                                        ${this.return_onmaptable(datagmap.gmaps[meta.row].fishes)
                                }
                                                                    </thead>
                                                                    </table>
              
                                                                </div>
                                                                <div class="col-sm-6">
                                                                    <div class=" h-100 w-100"
                                                                        style="background-color: #AAD3DF;min-height:10em;">
                                                                        <div id="map_fishes_${meta.row}" class="w-100 h-100"></div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                            `
                        }


                    },
                ]
            });


            for (let i = 0; i < datagmap.gmaps.length; i++) {
                var map = L.map('map_fishes_' + i,
                    {
                        fullscreenControl: true,
                    }
                ).setView([datagmap.gmaps[i].lat, datagmap.gmaps[i].lng], 8);
                L.tileLayer(mom.map_store.url, {
                    maxZoom: 19,
                }).addTo(map);

                map.invalidateSize();
                var marker = L.marker([datagmap.gmaps[i].lat, datagmap.gmaps[i].lng]).addTo(map);
            }


        },
        //------------------------------------------------------------------



        //SHOW REPORT FUNCTIONS
        //------------------------------------------------------------------
        //manifest report 
        manifest_report(item) {

            console.log("MANIFEST REPORT CLICK")
            this.is_manifestreport = false;
            $('html, body').animate({ scrollTop: '0px' }, 5);

            setTimeout(() => {

                this.manifestreport_item = item;
                this.is_manifestreport = true;
                this.page_off_output = 10;
                this.page_off_map = 10;
                this.manifestreport_range = "Today";
                this.fetch_manifest_report(item, 'today');


                setTimeout(() => {
                    $('html, body').animate({
                        scrollTop: ($("#manifestreport_card").offset().top) - 68
                    }, 500);
                }, 10);

            }, 5);
        },
        //close show on map manifests
        close_manifestreport() {
            this.manifestreport_item = null;
            this.is_manifestreport = false;
            this.manifestreport_range = "Today";
            $('html, body').animate({ scrollTop: '0px' }, 500);
        },
        //------------------------------------------------------------------



        //CHANGE RANGE FUNCTIONS
        //------------------------------------------------------------------
        change_range(range_label, range) {
            console.log("MANIFEST REPORT CLICK")
            this.is_manifestreport = false;
            $('html, body').animate({ scrollTop: '0px' }, 5);

            setTimeout(() => {
                this.is_manifestreport = true;
                this.manifestreport_data = null;
                this.manifestreport_range = range_label;

                this.fetch_manifest_report(this.manifestreport_item, range);

                setTimeout(() => {
                    $('html, body').animate({
                        scrollTop: ($("#manifestreport_card").offset().top) - 68
                    }, 500);
                }, 10);

            }, 5);
        },
        //------------------------------------------------------------------



        //MAP ESSENTIALS FUNCTIONS
        //------------------------------------------------------------------
        initializeMap(map_) {
            console.log("MAP INITIALIZED");

            this.manifest_map = map_;
            this.target_layer.addTo(this.manifest_map);
            this.tracking_layer.addTo(this.manifest_map);
            this.link_layer.addTo(this.manifest_map);
            this.search_layer.addTo(this.manifest_map);
            this.manifest_map.scrollWheelZoom.disable();
            var searchLayer = this.search_layer;
            var mymap = this.manifest_map;
            this.eta_store.init_eta(mymap, 'manifests');
            this.eta_store.init_start_eta(mymap, 'manifests', this.mapmanifest_data_date.maptop);
            this.eta_store.init_detail_route(mymap, 'manifests')
            this.eta_store.table_koordinat();
            L.Control.geocoder(
                {
                    defaultMarkGeocode: false

                }
            ).on('markgeocode', function (e) {
                console.log(e);
                console.log(e.geocode.center);
                console.log(e.geocode.html);
                mymap.setView(new L.LatLng(e.geocode.center.lat, e.geocode.center.lng), 8);

                var circle = L.circle([e.geocode.center.lat, e.geocode.center.lng], {
                    color: "#1DC01D",
                    fillColor: "#1DC01D",
                    fillOpacity: 0.0,
                    opacity: 0.0,
                    weight: 0,
                    radius: 6000,
                }).bindPopup(
                    e.geocode.html.length > 0 ?
                        e.geocode.html : e.geocode.name
                )

                circle.on("add", function (event) {
                    event.target.openPopup();
                });

                circle.addTo(searchLayer)
            }).addTo(this.manifest_map);


        },
        onmap_click() {
            this.search_layer.clearLayers();
        },
        fullscreen_scroll() {
            console.log("CLICK FULL");
            this.manifest_map.invalidateSize();
            if (this.manifest_map.isFullscreen() == true) {
                this.manifest_map.scrollWheelZoom.enable();
            } else {
                this.manifest_map.scrollWheelZoom.disable();
            }
        },
        return_track_icon() {
            const svgIcon = L.divIcon({
                html: `
                <svg
                stroke="#0000FF" 
                stroke-width="0"
                viewBox="0 0 57 57"
                xmlns="http://www.w3.org/2000/svg"
                >
                <path d="M50.8,56.4L30.6,43.7c-1.3-0.8-3-0.8-4.3,0L6.2,56.4c-3.4,2.2-7.5-1.6-5.7-5.2L24.9,2.2c1.5-3,5.7-3,7.2,0l24.5,48.9
                C58.4,54.8,54.3,58.5,50.8,56.4z" fill="#0000FF"></path>
                </svg>`,
                className: "",
                iconSize: [57 / 3.5, 57 / 3.5],
                iconAnchor: [57 / 3.5 / 2, 57 / 3.5 / 2],
            });
            return svgIcon
        },
        setview_map() {
            this.manifest_map.setView(new L.LatLng(this.mapmanifest_data_date.maptop.lat, this.mapmanifest_data_date.maptop.lon), 6);
        },
        //------------------------------------------------------------------




        //MOVING MARKER FUNCTIONS
        //-----------------------------------------------------------------
        //fetch tracking 24 hour marker clicked
        moveTracking(data_tracking) {
            clearInterval(this.interval_tracking);
            this.tracking_path = null;
            this.tracking_latLng = null;
            this.tracking_rotation = null;
            console.log("MASUK KE MOVE TRACKING");
            console.log(data_tracking);
            this.marker_tracking = data_tracking;
            this.tracking_layer.clearLayers();
            this.target_layer.clearLayers();
            this.moving_marker = null;

            if (data_tracking.length > 0) {
                this.moving_marker = L.marker([data_tracking[0].lat, data_tracking[0].lng], {
                    icon: this.return_track_icon(),
                    rotationAngle: data_tracking[0].heading,
                }).addTo(this.target_layer);

                var firstpolyline = new L.Polyline(data_tracking, {
                    color: '#FEE800',
                    weight: 3,
                    opacity: 0.5,
                    smoothFactor: 1
                });
                this.tracking_layer.addLayer(firstpolyline);
                var mom = this
                setTimeout(() => {
                    data_tracking.forEach(function (coord) {
                        var circle = L.circle([coord.lat, coord.lng], {
                            color: "#1DC01D",
                            fillColor: "#1DC01D",
                            fillOpacity: 0.3,
                            opacity: 0.6,
                            weight: 2,
                            radius: 6000,
                        }).bindPopup(
                            "<table><tr><td><b>Latitude</b></td><td>:</td><td>" +
                            coord.lat +
                            "</td></tr><tr><td><b>Longitude</b></td><td>:</td><td>" +
                            coord.lng +
                            "</td></tr><tr>" +
                            "<td><b>DMS</b></td><td>:</td>" +
                            "<td>" +
                            coord.dms +
                            "</td>" +
                            "</tr>" +
                            "<tr>" +
                            "<td><b>Speed</b></td>" +
                            "<td>:</td>" +
                            " <td>" +
                            coord.speed +
                            "&nbsp;knots </td>" +
                            "</tr>" +
                            "<tr>" +
                            " <td><b>Heading</b></td>" +
                            "<td>:</td>" +
                            "<td>" +
                            coord.heading +
                            "</td>" +
                            " </tr>" +
                            "<tr>" +
                            "<td><b>Last Update</b></td>" +
                            "<td>:</td>" +
                            "<td>" +
                            coord.updated +
                            "</td>" +
                            "</tr>" +
                            "</table>"
                        ).addTo(mom.target_layer)

                    });
                }, 1600);
                this.initialdata_moving();
                this.movingStart();
            }


        },
        //moving marker
        initialdata_moving() {
            this.tracking_path = this.marker_tracking.slice()
            if (this.tracking_path[0]) {
                this.tracking_latLng = L.latLng([this.tracking_path[0].lat, this.tracking_path[0].lng])
                this.tracking_rotation = this.tracking_path[0].heading
            }

        },
        //moving marker
        movingStart() {
            var mother = this
            this.interval_tracking = setInterval(function () {
                mother.simulate()
            }, 250);
        },
        simulate() {
            if (this.tracking_path && this.tracking_path.length > 0) {
                let point = this.tracking_path.shift()
                this.tracking_latLng = L.latLng([point.lat, point.lng])
                this.tracking_rotation = point.heading
                this.moving_marker.setLatLng(this.tracking_latLng)
                this.moving_marker.slideTo(this.tracking_latLng, {
                    duration: 500,
                });
                this.moving_marker.options.rotationAngle = this.tracking_rotation;
            } else {
                this.target_layer.removeLayer(this.moving_marker);
                clearInterval(this.interval_tracking);
                this.tracking_path = null;
            }

        },
        return_icon(maptop) {
            let svgIcon;
            svgIcon = L.divIcon({
                html: `
                    <svg
                    stroke="#FFF" 
                    stroke-width="10"
                    viewBox="0 0 40 100"
                    xmlns="http://www.w3.org/2000/svg"
                    >
                    <path d="M 37.93 32.68 L 20 0 L 2.07 32.68 c -1.35 2.45 -2.07 5.41 -2.07 8.44 v 57.33 H 40 V 41.12 c 0 -3.03 -0.72 -5.98 -2.07 -8.44 Z" fill="`+ maptop.fill + `"></path>
                    </svg>`,
                className: "",
                iconSize: [40 / 5, 100 / 5],
                iconAnchor: [40 / 5 / 2, 100 / 5 / 2],
            });

            return svgIcon;


        },
        popup_tracking(index) {
            console.log(index);
            this.link_layer.clearLayers();
            var circle = L.circle([this.marker_tracking[index].lat, this.marker_tracking[index].lng], {
                color: "#1DC01D",
                fillColor: "#1DC01D",
                fillOpacity: 0.0,
                opacity: 0.0,
                weight: 0,
                radius: 6000,
            }).bindPopup(
                "<table><tr><td><b>Latitude</b></td><td>:</td><td>" +
                this.marker_tracking[index].lat +
                "</td></tr><tr><td><b>Longitude</b></td><td>:</td><td>" +
                this.marker_tracking[index].lng +
                "</td></tr><tr>" +
                "<td><b>DMS</b></td><td>:</td>" +
                "<td>" +
                this.marker_tracking[index].dms +
                "</td>" +
                "</tr>" +
                "<tr>" +
                "<td><b>Speed</b></td>" +
                "<td>:</td>" +
                " <td>" +
                this.marker_tracking[index].speed +
                "&nbsp;knots </td>" +
                "</tr>" +
                "<tr>" +
                " <td><b>Heading</b></td>" +
                "<td>:</td>" +
                "<td>" +
                this.marker_tracking[index].heading +
                "</td>" +
                " </tr>" +
                "<tr>" +
                "<td><b>Last Update</b></td>" +
                "<td>:</td>" +
                "<td>" +
                this.marker_tracking[index].updated +
                "</td>" +
                "</tr>" +
                "</table>"
            )

            circle.on("add", function (event) {
                event.target.openPopup();
            });

            this.manifest_map.setView(new L.LatLng(this.marker_tracking[index].lat, this.marker_tracking[index].lng), 6);

            circle.addTo(this.link_layer)
            $('html, body').animate({
                scrollTop: $("#map_manifests").offset().top - 85
            }, 10);

           
        },
        //-----------------------------------------------------------------



        //RETURN FUNCTIONS
        //-----------------------------------------------------------------
        return_onmaptable(fishes) {
            var str = ``
            for (let i = 0; i < fishes.length; i++) {
                str = str + `
                  <tr>
                  <td>${fishes[i].type}</td>
                  <td>${fishes[i].amount}</td>
                  </tr>
            `;
            }
            return str
        },
        return_td() {
            let arr = [];
            var obj;
            var satuan = "Kg"

            var mom = this;
            for (let i = 0; i < (this.manifestreport_data.fish.length + 2); i++) {
                if (i == 0) {
                    obj = {
                        width: "",
                        data: '', className: 'td_nowrap', render: (data, type, row, meta) => {
                            return `
                            <div class="p-1 h-100">
                            ${meta.row + 1}
                            </div>
                            `
                        }
                    }
                } else if (i == 1) {
                    obj = {
                        width: "",
                        data: 'dateTime', className: 'td_nowrap', render: (data, type, row, meta) => {
                            return `
                            <div class="p-1 h-100">
                            ${data}
                            </div>
                            `
                        }
                    }
                } else {

                    console.log("BERULANG" + (i - 2));

                    obj = {
                        width: "",
                        data: mom.manifestreport_data.fish[i - 2], className: 'td_nowrap', render: (data, type, row, meta) => {
                            return `
                            <div class="p-1 h-100">
                            ${data} ${satuan}
                            </div>
                            `
                        }
                    }

                }

                arr.push(obj);
            }
            return arr;
        },
        //-----------------------------------------------------------------



        //SET CHART FUNCTIONS
        //-----------------------------------------------------------------
        set_manifestchart() {
            console.log("MASUK SET CHART");

            var datas = this.manifestreport_data.graph;
            var mom = this;
            var label_ = [];
            var datasets_ = [];

            console.log(datas);


            if (this.manifestreport_data.graph) {
                label_ = datas.label
                datasets_ = datas.data
            }

            console.log("SAMPAI SINI");
            console.log(datasets_);
            console.log(label_);



            setTimeout(() => {
                try {
                    console.log("BERHASIL CHART");
                    mom.manifest_chart = new Chart(
                        document.getElementById('manifest_chart'),
                        {
                            type: 'line',
                            data: {
                                labels: label_,
                                datasets: [
                                    {
                                        label: "Total Catch / Message (kg)",
                                        data: datasets_,
                                        backgroundColor: "rgb(51, 153, 255)",
                                    },
                                ],
                            },
                            options: {
                                responsive: true,
                                maintainAspectRatio: false,
                                plugins: {
                                    legend: {
                                        position: "top",
                                    },
                                },
                                scales: {
                                    y: {
                                        title: {
                                            display: false,
                                            text: "RPM",
                                        },
                                    },
                                },
                                interaction: {
                                    mode: "index",
                                    intersect: false,
                                },
                            }
                        }
                    );

                    $('#manifest_chart').removeClass('d-none');
                    $('#manifest_chart').addClass('d-block');

                    $('#manifest_chart_load').removeClass('d-flex');
                    $('#manifest_chart_load').addClass('d-none');

                } catch (e) {
                    console.log("GAGAL CHART");
                }
            }, 500);

        },
        //-----------------------------------------------------------------

    }
})
