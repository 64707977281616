
    export var horizon = {
        c1: [{
                lat: 3.603,
                lng: 113.805
            },
            {
                lat: 3.285,
                lng: 114.420
            },
            {
                lat: 2.999,
                lng: 114.808
            },
            {
                lat: 2.403,
                lng: 115.192
            },
            {
                lat: 1.810,
                lng: 115.136
            },
            {
                lat: 1.218,
                lng: 114.601
            },
            {
                lat: 1.204,
                lng: 114.577
            },
            {
                lat: 0.744,
                lng: 113.288
            },
            {
                lat: 0.628,
                lng: 112.451
            },
            {
                lat: 0.576,
                lng: 111.916
            },
            {
                lat: 0.628,
                lng: 110.453
            },
            {
                lat: 0.631,
                lng: 110.430
            },
            {
                lat: 0.950,
                lng: 108.875
            },
            {
                lat: 1.239,
                lng: 108.041
            },
            {
                lat: 1.740,
                lng: 107.125
            },
            {
                lat: 1.848,
                lng: 106.974
            },
            {
                lat: 2.457,
                lng: 106.630
            },
            {
                lat: 3.062,
                lng: 106.806
            },
            {
                lat: 3.185,
                lng: 106.950
            },
            {
                lat: 3.660,
                lng: 107.775
            },
            {
                lat: 3.915,
                lng: 108.552
            },
            {
                lat: 4.154,
                lng: 110.105
            },
            {
                lat: 4.129,
                lng: 111.596
            },
            {
                lat: 3.869,
                lng: 113.030
            },
            {
                lat: 3.603,
                lng: 113.805
            },
        ],
        c2: [{
                lat: 4.820,
                lng: 111.877
            },
            {
                lat: 4.667,
                lng: 112.907
            },
            {
                lat: 4.293,
                lng: 114.290
            },
            {
                lat: 4.191,
                lng: 114.553
            },
            {
                lat: 3.643,
                lng: 115.631
            },
            {
                lat: 3.585,
                lng: 115.721
            },
            {
                lat: 2.988,
                lng: 116.348
            },
            {
                lat: 2.394,
                lng: 116.652
            },
            {
                lat: 1.803,
                lng: 116.642
            },
            {
                lat: 1.213,
                lng: 116.302
            },
            {
                lat: 0.820,
                lng: 115.832
            },
            {
                lat: 0.623,
                lng: 115.510
            },
            {
                lat: 0.260,
                lng: 114.600
            },
            {
                lat: 0.031,
                lng: 113.630
            },
            {
                lat: -0.029,
                lng: 113.298
            },
            {
                lat: -0.133,
                lng: 111.923
            },
            {
                lat: -0.096,
                lng: 110.461
            },
            {
                lat: 0.031,
                lng: 109.357
            },
            {
                lat: 0.095,
                lng: 108.895
            },
            {
                lat: 0.503,
                lng: 107.192
            },
            {
                lat: 0.637,
                lng: 106.781
            },
            {
                lat: 1.248,
                lng: 105.388
            },
            {
                lat: 1.319,
                lng: 105.285
            },
            {
                lat: 1.860,
                lng: 104.608
            },
            {
                lat: 2.472,
                lng: 104.270
            },
            {
                lat: 3.082,
                lng: 104.375
            },
            {
                lat: 3.687,
                lng: 104.940
            },
            {
                lat: 3.697,
                lng: 104.956
            },
            {
                lat: 4.283,
                lng: 106.219
            },
            {
                lat: 4.442,
                lng: 106.711
            },
            {
                lat: 4.789,
                lng: 108.379
            },
            {
                lat: 4.855,
                lng: 109.129
            },
            {
                lat: 4.914,
                lng: 109.961
            },
            {
                lat: 4.873,
                lng: 111.466
            },
            {
                lat: 4.820,
                lng: 111.877
            },
        ],
        c3: [{
                lat: 5.428,
                lng: 111.395
            },
            {
                lat: 5.245,
                lng: 112.802
            },
            {
                lat: 4.929,
                lng: 114.190
            },
            {
                lat: 4.787,
                lng: 114.630
            },
            {
                lat: 4.435,
                lng: 115.529
            },
            {
                lat: 4.176,
                lng: 116.048
            },
            {
                lat: 3.655,
                lng: 116.828
            },
            {
                lat: 3.575,
                lng: 116.931
            },
            {
                lat: 2.979,
                lng: 117.450
            },
            {
                lat: 2.388,
                lng: 117.707
            },
            {
                lat: 1.798,
                lng: 117.716
            },
            {
                lat: 1.210,
                lng: 117.468
            },
            {
                lat: 0.717,
                lng: 117.023
            },
            {
                lat: 0.621,
                lng: 116.916
            },
            {
                lat: 0.031,
                lng: 115.878
            },
            {
                lat: 0.016,
                lng: 115.843
            },
            {
                lat: -0.359,
                lng: 114.599
            },
            {
                lat: -0.564,
                lng: 113.425
            },
            {
                lat: -0.584,
                lng: 113.292
            },
            {
                lat: -0.670,
                lng: 111.914
            },
            {
                lat: -0.644,
                lng: 110.453
            },
            {
                lat: -0.570,
                lng: 109.632
            },
            {
                lat: -0.495,
                lng: 108.890
            },
            {
                lat: -0.192,
                lng: 107.198
            },
            {
                lat: 0.032,
                lng: 106.302
            },
            {
                lat: 0.330,
                lng: 105.330
            },
            {
                lat: 0.641,
                lng: 104.492
            },
            {
                lat: 1.255,
                lng: 103.266
            },
            {
                lat: 1.321,
                lng: 103.180
            },
            {
                lat: 1.871,
                lng: 102.512
            },
            {
                lat: 2.486,
                lng: 102.162
            },
            {
                lat: 3.100,
                lng: 102.227
            },
            {
                lat: 3.709,
                lng: 102.724
            },
            {
                lat: 3.751,
                lng: 102.788
            },
            {
                lat: 4.311,
                lng: 103.759
            },
            {
                lat: 4.687,
                lng: 104.723
            },
            {
                lat: 4.902,
                lng: 105.448
            },
            {
                lat: 5.157,
                lng: 106.538
            },
            {
                lat: 5.403,
                lng: 108.236
            },
            {
                lat: 5.458,
                lng: 109.251
            },
            {
                lat: 5.486,
                lng: 109.836
            },
            {
                lat: 5.433,
                lng: 111.353
            },
            {
                lat: 5.428,
                lng: 111.395
            },
        ],
        c4: [{
                lat: 5.388,
                lng: 114.269
            },
            {
                lat: 5.011,
                lng: 115.441
            },
            {
                lat: 4.770,
                lng: 116.021
            },
            {
                lat: 4.405,
                lng: 116.737
            },
            {
                lat: 4.164,
                lng: 117.135
            },
            {
                lat: 3.566,
                lng: 117.873
            },
            {
                lat: 3.408,
                lng: 118.004
            },
            {
                lat: 2.973,
                lng: 118.335
            },
            {
                lat: 2.383,
                lng: 118.566
            },
            {
                lat: 1.794,
                lng: 118.583
            },
            {
                lat: 1.207,
                lng: 118.385
            },
            {
                lat: 0.896,
                lng: 118.160
            },
            {
                lat: 0.619,
                lng: 117.930
            },
            {
                lat: 0.031,
                lng: 117.147
            },
            {
                lat: -0.033,
                lng: 117.031
            },
            {
                lat: -0.535,
                lng: 115.839
            },
            {
                lat: -0.561,
                lng: 115.752
            },
            {
                lat: -0.842,
                lng: 114.590
            },
            {
                lat: -1.027,
                lng: 113.279
            },
            {
                lat: -1.108,
                lng: 111.899
            },
            {
                lat: -1.090,
                lng: 110.437
            },
            {
                lat: -0.967,
                lng: 108.875
            },
            {
                lat: -0.727,
                lng: 107.186
            },
            {
                lat: -0.575,
                lng: 106.421
            },
            {
                lat: -0.323,
                lng: 105.330
            },
            {
                lat: 0.032,
                lng: 104.089
            },
            {
                lat: 0.336,
                lng: 103.231
            },
            {
                lat: 0.645,
                lng: 102.445
            },
            {
                lat: 1.262,
                lng: 101.259
            },
            {
                lat: 1.717,
                lng: 100.678
            },
            {
                lat: 1.881,
                lng: 100.467
            },
            {
                lat: 2.500,
                lng: 100.082
            },
            {
                lat: 3.117,
                lng: 100.123
            },
            {
                lat: 3.401,
                lng: 100.348
            },
            {
                lat: 3.730,
                lng: 100.612
            },
            {
                lat: 4.337,
                lng: 101.568
            },
            {
                lat: 4.741,
                lng: 102.515
            },
            {
                lat: 4.934,
                lng: 103.042
            },
            {
                lat: 5.356,
                lng: 104.532
            },
            {
                lat: 5.516,
                lng: 105.288
            },
            {
                lat: 5.714,
                lng: 106.384
            },
            {
                lat: 5.902,
                lng: 108.105
            },
            {
                lat: 5.958,
                lng: 109.721
            },
            {
                lat: 5.895,
                lng: 111.251
            },
            {
                lat: 5.721,
                lng: 112.707
            },
            {
                lat: 5.436,
                lng: 114.100
            },
            {
                lat: 5.388,
                lng: 114.269
            },
        ],
        c5: [{
                lat: 6.000,
                lng: 113.324
            },
            {
                lat: 5.861,
                lng: 114.017
            },
            {
                lat: 5.480,
                lng: 115.361
            },
            {
                lat: 5.369,
                lng: 115.670
            },
            {
                lat: 4.957,
                lng: 116.657
            },
            {
                lat: 4.757,
                lng: 117.057
            },
            {
                lat: 4.224,
                lng: 117.914
            },
            {
                lat: 4.155,
                lng: 118.012
            },
            {
                lat: 3.559,
                lng: 118.664
            },
            {
                lat: 2.967,
                lng: 119.082
            },
            {
                lat: 2.737,
                lng: 119.167
            },
            {
                lat: 2.378,
                lng: 119.297
            },
            {
                lat: 1.791,
                lng: 119.319
            },
            {
                lat: 1.512,
                lng: 119.239
            },
            {
                lat: 1.205,
                lng: 119.146
            },
            {
                lat: 0.618,
                lng: 118.759
            },
            {
                lat: 0.079,
                lng: 118.171
            },
            {
                lat: 0.031,
                lng: 118.110
            },
            {
                lat: -0.559,
                lng: 117.052
            },
            {
                lat: -0.571,
                lng: 117.026
            },
            {
                lat: -0.977,
                lng: 115.828
            },
            {
                lat: -1.155,
                lng: 115.045
            },
            {
                lat: -1.248,
                lng: 114.575
            },
            {
                lat: -1.409,
                lng: 113.263
            },
            {
                lat: -1.482,
                lng: 111.881
            },
            {
                lat: -1.471,
                lng: 110.417
            },
            {
                lat: -1.371,
                lng: 108.854
            },
            {
                lat: -1.179,
                lng: 107.224
            },
            {
                lat: -1.171,
                lng: 107.165
            },
            {
                lat: -0.836,
                lng: 105.314
            },
            {
                lat: -0.578,
                lng: 104.192
            },
            {
                lat: -0.320,
                lng: 103.231
            },
            {
                lat: 0.032,
                lng: 102.070
            },
            {
                lat: 0.516,
                lng: 100.779
            },
            {
                lat: 0.648,
                lng: 100.434
            },
            {
                lat: 1.269,
                lng: 99.181
            },
            {
                lat: 1.892,
                lng: 98.317
            },
            {
                lat: 2.515,
                lng: 97.872
            },
            {
                lat: 3.136,
                lng: 97.889
            },
            {
                lat: 3.753,
                lng: 98.391
            },
            {
                lat: 4.362,
                lng: 99.377
            },
            {
                lat: 4.609,
                lng: 99.966
            },
            {
                lat: 4.963,
                lng: 100.846
            },
            {
                lat: 5.407,
                lng: 102.291
            },
            {
                lat: 5.552,
                lng: 102.845
            },
            {
                lat: 5.886,
                lng: 104.361
            },
            {
                lat: 6.119,
                lng: 105.818
            },
            {
                lat: 6.181,
                lng: 106.242
            },
            {
                lat: 6.334,
                lng: 107.983
            },
            {
                lat: 6.370,
                lng: 109.613
            },
            {
                lat: 6.302,
                lng: 111.153
            },
            {
                lat: 6.133,
                lng: 112.617
            },
            {
                lat: 6.000,
                lng: 113.324
            },
        ],
        c6: [{
                lat: 6.635,
                lng: 111.314
            },
            {
                lat: 6.497,
                lng: 112.533
            },
            {
                lat: 6.238,
                lng: 113.939
            },
            {
                lat: 5.976,
                lng: 114.948
            },
            {
                lat: 5.880,
                lng: 115.287
            },
            {
                lat: 5.410,
                lng: 116.585
            },
            {
                lat: 5.355,
                lng: 116.712
            },
            {
                lat: 4.783,
                lng: 117.841
            },
            {
                lat: 4.748,
                lng: 117.903
            },
            {
                lat: 4.148,
                lng: 118.754
            },
            {
                lat: 3.845,
                lng: 119.067
            },
            {
                lat: 3.553,
                lng: 119.355
            },
            {
                lat: 2.963,
                lng: 119.743
            },
            {
                lat: 2.375,
                lng: 119.941
            },
            {
                lat: 1.788,
                lng: 119.964
            },
            {
                lat: 1.203,
                lng: 119.811
            },
            {
                lat: 0.617,
                lng: 119.468
            },
            {
                lat: 0.399,
                lng: 119.268
            },
            {
                lat: 0.031,
                lng: 118.897
            },
            {
                lat: -0.470,
                lng: 118.168
            },
            {
                lat: -0.558,
                lng: 118.014
            },
            {
                lat: -0.999,
                lng: 117.015
            },
            {
                lat: -1.151,
                lng: 116.554
            },
            {
                lat: -1.357,
                lng: 115.814
            },
            {
                lat: -1.596,
                lng: 114.559
            },
            {
                lat: -1.744,
                lng: 113.244
            },
            {
                lat: -1.757,
                lng: 112.985
            },
            {
                lat: -1.813,
                lng: 111.860
            },
            {
                lat: -1.808,
                lng: 110.394
            },
            {
                lat: -1.772,
                lng: 109.735
            },
            {
                lat: -1.723,
                lng: 108.829
            },
            {
                lat: -1.549,
                lng: 107.140
            },
            {
                lat: -1.268,
                lng: 105.289
            },
            {
                lat: -1.186,
                lng: 104.857
            },
            {
                lat: -0.837,
                lng: 103.212
            },
            {
                lat: -0.581,
                lng: 102.169
            },
            {
                lat: -0.186,
                lng: 100.788
            },
            {
                lat: 0.032,
                lng: 100.054
            },
            {
                lat: 0.652,
                lng: 98.318
            },
            {
                lat: 0.935,
                lng: 97.697
            },
            {
                lat: 1.277,
                lng: 96.886
            },
            {
                lat: 1.905,
                lng: 95.815
            },
            {
                lat: 2.533,
                lng: 95.229
            },
            {
                lat: 3.159,
                lng: 95.223
            },
            {
                lat: 3.779,
                lng: 95.835
            },
            {
                lat: 4.272,
                lng: 96.792
            },
            {
                lat: 4.390,
                lng: 97.005
            },
            {
                lat: 4.993,
                lng: 98.589
            },
            {
                lat: 5.318,
                lng: 99.681
            },
            {
                lat: 5.585,
                lng: 100.624
            },
            {
                lat: 5.934,
                lng: 102.090
            },
            {
                lat: 6.161,
                lng: 103.231
            },
            {
                lat: 6.336,
                lng: 104.200
            },
            {
                lat: 6.586,
                lng: 106.108
            },
            {
                lat: 6.698,
                lng: 107.644
            },
            {
                lat: 6.715,
                lng: 107.866
            },
            {
                lat: 6.738,
                lng: 109.510
            },
            {
                lat: 6.663,
                lng: 111.060
            },
            {
                lat: 6.635,
                lng: 111.314
            },
        ],
        c7: [{
                lat: 6.593,
                lng: 113.759
            },
            {
                lat: 6.574,
                lng: 113.864
            },
            {
                lat: 6.235,
                lng: 115.217
            },
            {
                lat: 5.959,
                lng: 116.061
            },
            {
                lat: 5.794,
                lng: 116.519
            },
            {
                lat: 5.344,
                lng: 117.552
            },
            {
                lat: 5.229,
                lng: 117.776
            },
            {
                lat: 4.739,
                lng: 118.624
            },
            {
                lat: 4.467,
                lng: 118.995
            },
            {
                lat: 4.141,
                lng: 119.412
            },
            {
                lat: 3.548,
                lng: 119.967
            },
            {
                lat: 3.170,
                lng: 120.200
            },
            {
                lat: 2.958,
                lng: 120.331
            },
            {
                lat: 2.371,
                lng: 120.523
            },
            {
                lat: 1.786,
                lng: 120.545
            },
            {
                lat: 1.201,
                lng: 120.399
            },
            {
                lat: 1.048,
                lng: 120.317
            },
            {
                lat: 0.616,
                lng: 120.087
            },
            {
                lat: 0.031,
                lng: 119.578
            },
            {
                lat: -0.222,
                lng: 119.269
            },
            {
                lat: -0.557,
                lng: 118.805
            },
            {
                lat: -0.910,
                lng: 118.159
            },
            {
                lat: -1.148,
                lng: 117.616
            },
            {
                lat: -1.370,
                lng: 117.002
            },
            {
                lat: -1.688,
                lng: 115.798
            },
            {
                lat: -1.746,
                lng: 115.482
            },
            {
                lat: -1.905,
                lng: 114.541
            },
            {
                lat: -2.042,
                lng: 113.224
            },
            {
                lat: -2.108,
                lng: 111.838
            },
            {
                lat: -2.108,
                lng: 110.370
            },
            {
                lat: -2.037,
                lng: 108.803
            },
            {
                lat: -1.887,
                lng: 107.112
            },
            {
                lat: -1.788,
                lng: 106.349
            },
            {
                lat: -1.640,
                lng: 105.260
            },
            {
                lat: -1.274,
                lng: 103.184
            },
            {
                lat: -1.193,
                lng: 102.788
            },
            {
                lat: -0.732,
                lng: 100.769
            },
            {
                lat: -0.584,
                lng: 100.160
            },
            {
                lat: 0.032,
                lng: 97.920
            },
            {
                lat: 0.091,
                lng: 97.740
            },
            {
                lat: 0.656,
                lng: 95.858
            },
            {
                lat: 1.287,
                lng: 94.027
            },
            {
                lat: 1.797,
                lng: 92.822
            },
            {
                lat: 1.922,
                lng: 92.447
            },
            {
                lat: 2.560,
                lng: 91.304
            },
            {
                lat: 3.193,
                lng: 91.256
            },
            {
                lat: 3.491,
                lng: 91.848
            },
            {
                lat: 3.815,
                lng: 92.326
            },
            {
                lat: 4.426,
                lng: 93.982
            },
            {
                lat: 5.028,
                lng: 96.055
            },
            {
                lat: 5.101,
                lng: 96.357
            },
            {
                lat: 5.619,
                lng: 98.333
            },
            {
                lat: 5.868,
                lng: 99.425
            },
            {
                lat: 6.199,
                lng: 100.960
            },
            {
                lat: 6.383,
                lng: 101.901
            },
            {
                lat: 6.730,
                lng: 104.049
            },
            {
                lat: 6.758,
                lng: 104.289
            },
            {
                lat: 6.951,
                lng: 105.979
            },
            {
                lat: 7.061,
                lng: 107.754
            },
            {
                lat: 7.073,
                lng: 109.410
            },
            {
                lat: 6.994,
                lng: 110.970
            },
            {
                lat: 6.829,
                lng: 112.450
            },
            {
                lat: 6.593,
                lng: 113.759
            },
        ],
        c8: [{
                lat: 4.577,
                lng: 87.870
            },
            {
                lat: 4.728,
                lng: 90.341
            },
            {
                lat: 5.075,
                lng: 92.555
            },
            {
                lat: 5.659,
                lng: 95.740
            },
            {
                lat: 5.701,
                lng: 95.976
            },
            {
                lat: 6.237,
                lng: 98.686
            },
            {
                lat: 6.330,
                lng: 99.185
            },
            {
                lat: 6.775,
                lng: 101.723
            },
            {
                lat: 6.801,
                lng: 101.906
            },
            {
                lat: 7.086,
                lng: 103.903
            },
            {
                lat: 7.279,
                lng: 105.856
            },
            {
                lat: 7.325,
                lng: 106.768
            },
            {
                lat: 7.374,
                lng: 107.647
            },
            {
                lat: 7.378,
                lng: 109.314
            },
            {
                lat: 7.293,
                lng: 110.885
            },
            {
                lat: 7.238,
                lng: 111.380
            },
            {
                lat: 7.128,
                lng: 112.373
            },
            {
                lat: 6.885,
                lng: 113.791
            },
            {
                lat: 6.571,
                lng: 115.075
            },
            {
                lat: 6.552,
                lng: 115.151
            },
            {
                lat: 6.137,
                lng: 116.456
            },
            {
                lat: 5.946,
                lng: 116.948
            },
            {
                lat: 5.613,
                lng: 117.715
            },
            {
                lat: 5.334,
                lng: 118.276
            },
            {
                lat: 4.944,
                lng: 118.933
            },
            {
                lat: 4.732,
                lng: 119.265
            },
            {
                lat: 4.135,
                lng: 119.996
            },
            {
                lat: 3.997,
                lng: 120.121
            },
            {
                lat: 3.543,
                lng: 120.533
            },
            {
                lat: 2.955,
                lng: 120.878
            },
            {
                lat: 2.368,
                lng: 121.053
            },
            {
                lat: 1.783,
                lng: 121.074
            },
            {
                lat: 1.199,
                lng: 120.942
            },
            {
                lat: 0.615,
                lng: 120.648
            },
            {
                lat: 0.221,
                lng: 120.331
            },
            {
                lat: 0.031,
                lng: 120.173
            },
            {
                lat: -0.556,
                lng: 119.484
            },
            {
                lat: -0.700,
                lng: 119.264
            },
            {
                lat: -1.145,
                lng: 118.463
            },
            {
                lat: -1.287,
                lng: 118.147
            },
            {
                lat: -1.694,
                lng: 116.987
            },
            {
                lat: -1.740,
                lng: 116.813
            },
            {
                lat: -1.984,
                lng: 115.780
            },
            {
                lat: -2.182,
                lng: 114.522
            },
            {
                lat: -2.308,
                lng: 113.203
            },
            {
                lat: -2.359,
                lng: 112.080
            },
            {
                lat: -2.372,
                lng: 111.816
            },
            {
                lat: -2.376,
                lng: 110.345
            },
            {
                lat: -2.371,
                lng: 110.222
            },
            {
                lat: -2.317,
                lng: 108.776
            },
            {
                lat: -2.188,
                lng: 107.082
            },
            {
                lat: -1.973,
                lng: 105.227
            },
            {
                lat: -1.799,
                lng: 104.078
            },
            {
                lat: -1.650,
                lng: 103.149
            },
            {
                lat: -1.199,
                lng: 100.792
            },
            {
                lat: -1.187,
                lng: 100.736
            },
            {
                lat: -0.588,
                lng: 98.036
            },
            {
                lat: -0.510,
                lng: 97.728
            },
            {
                lat: 0.033,
                lng: 95.374
            },
            {
                lat: 0.569,
                lng: 93.100
            },
            {
                lat: 0.662,
                lng: 92.581
            },
            {
                lat: 1.226,
                lng: 87.843
            },
        ],
        c9: [{
                lat: 5.440,
                lng: 87.882
            },
            {
                lat: 5.716,
                lng: 92.002
            },
            {
                lat: 6.188,
                lng: 95.620
            },
            {
                lat: 6.279,
                lng: 96.169
            },
            {
                lat: 6.729,
                lng: 98.959
            },
            {
                lat: 6.842,
                lng: 99.685
            },
            {
                lat: 7.127,
                lng: 101.551
            },
            {
                lat: 7.387,
                lng: 103.627
            },
            {
                lat: 7.404,
                lng: 103.764
            },
            {
                lat: 7.585,
                lng: 105.736
            },
            {
                lat: 7.668,
                lng: 107.541
            },
            {
                lat: 7.664,
                lng: 109.221
            },
            {
                lat: 7.578,
                lng: 110.800
            },
            {
                lat: 7.412,
                lng: 112.296
            },
            {
                lat: 7.198,
                lng: 113.522
            },
            {
                lat: 7.163,
                lng: 113.723
            },
            {
                lat: 6.848,
                lng: 115.086
            },
            {
                lat: 6.555,
                lng: 116.048
            },
            {
                lat: 6.443,
                lng: 116.396
            },
            {
                lat: 5.951,
                lng: 117.657
            },
            {
                lat: 5.935,
                lng: 117.694
            },
            {
                lat: 5.343,
                lng: 118.876
            },
            {
                lat: 5.326,
                lng: 118.907
            },
            {
                lat: 4.725,
                lng: 119.838
            },
            {
                lat: 4.540,
                lng: 120.060
            },
            {
                lat: 4.130,
                lng: 120.546
            },
            {
                lat: 3.539,
                lng: 121.044
            },
            {
                lat: 3.200,
                lng: 121.231
            },
            {
                lat: 2.951,
                lng: 121.376
            },
            {
                lat: 2.365,
                lng: 121.553
            },
            {
                lat: 1.781,
                lng: 121.570
            },
            {
                lat: 1.198,
                lng: 121.431
            },
            {
                lat: 1.028,
                lng: 121.346
            },
            {
                lat: 0.615,
                lng: 121.154
            },
            {
                lat: 0.031,
                lng: 120.718
            },
            {
                lat: -0.332,
                lng: 120.330
            },
            {
                lat: -0.555,
                lng: 120.078
            },
            {
                lat: -1.096,
                lng: 119.254
            },
            {
                lat: -1.143,
                lng: 119.170
            },
            {
                lat: -1.614,
                lng: 118.134
            },
            {
                lat: -1.736,
                lng: 117.785
            },
            {
                lat: -1.985,
                lng: 116.971
            },
            {
                lat: -2.247,
                lng: 115.763
            },
            {
                lat: -2.341,
                lng: 115.137
            },
            {
                lat: -2.432,
                lng: 114.502
            },
            {
                lat: -2.554,
                lng: 113.182
            },
            {
                lat: -2.617,
                lng: 111.792
            },
            {
                lat: -2.625,
                lng: 110.320
            },
            {
                lat: -2.576,
                lng: 108.748
            },
            {
                lat: -2.460,
                lng: 107.051
            },
            {
                lat: -2.395,
                lng: 106.412
            },
            {
                lat: -2.270,
                lng: 105.192
            },
            {
                lat: -1.986,
                lng: 103.111
            },
            {
                lat: -1.809,
                lng: 102.025
            },
            {
                lat: -1.578,
                lng: 100.696
            },
            {
                lat: -1.206,
                lng: 98.708
            },
            {
                lat: -1.000,
                lng: 97.691
            },
            {
                lat: -0.592,
                lng: 95.528
            },
            {
                lat: -0.150,
                lng: 93.127
            },
            {
                lat: 0.033,
                lng: 91.746
            },
            {
                lat: 0.331,
                lng: 87.841
            },
        ],
        c10: [{
                lat: 6.062,
                lng: 87.891
            },
            {
                lat: 6.338,
                lng: 92.733
            },
            {
                lat: 6.609,
                lng: 95.273
            },
            {
                lat: 6.886,
                lng: 97.316
            },
            {
                lat: 7.086,
                lng: 98.740
            },
            {
                lat: 7.433,
                lng: 101.310
            },
            {
                lat: 7.443,
                lng: 101.387
            },
            {
                lat: 7.704,
                lng: 103.627
            },
            {
                lat: 7.863,
                lng: 105.621
            },
            {
                lat: 7.926,
                lng: 107.317
            },
            {
                lat: 7.932,
                lng: 107.443
            },
            {
                lat: 7.923,
                lng: 109.132
            },
            {
                lat: 7.870,
                lng: 110.032
            },
            {
                lat: 7.832,
                lng: 110.720
            },
            {
                lat: 7.671,
                lng: 112.223
            },
            {
                lat: 7.433,
                lng: 113.654
            },
            {
                lat: 7.175,
                lng: 114.757
            },
            {
                lat: 7.113,
                lng: 115.025
            },
            {
                lat: 6.727,
                lng: 116.338
            },
            {
                lat: 6.542,
                lng: 116.854
            },
            {
                lat: 6.255,
                lng: 117.603
            },
            {
                lat: 5.925,
                lng: 118.345
            },
            {
                lat: 5.684,
                lng: 118.823
            },
            {
                lat: 5.319,
                lng: 119.488
            },
            {
                lat: 4.972,
                lng: 120.005
            },
            {
                lat: 4.719,
                lng: 120.373
            },
            {
                lat: 4.125,
                lng: 121.038
            },
            {
                lat: 3.976,
                lng: 121.160
            },
            {
                lat: 3.535,
                lng: 121.541
            },
            {
                lat: 2.948,
                lng: 121.864
            },
            {
                lat: 2.363,
                lng: 122.024
            },
            {
                lat: 1.779,
                lng: 122.038
            },
            {
                lat: 1.197,
                lng: 121.911
            },
            {
                lat: 0.614,
                lng: 121.634
            },
            {
                lat: 0.246,
                lng: 121.359
            },
            {
                lat: 0.031,
                lng: 121.204
            },
            {
                lat: -0.554,
                lng: 120.614
            },
            {
                lat: -0.773,
                lng: 120.324
            },
            {
                lat: -1.141,
                lng: 119.786
            },
            {
                lat: -1.437,
                lng: 119.242
            },
            {
                lat: -1.733,
                lng: 118.578
            },
            {
                lat: -1.907,
                lng: 118.119
            },
            {
                lat: -2.244,
                lng: 116.955
            },
            {
                lat: -2.333,
                lng: 116.536
            },
            {
                lat: -2.490,
                lng: 115.744
            },
            {
                lat: -2.663,
                lng: 114.482
            },
            {
                lat: -2.775,
                lng: 113.161
            },
            {
                lat: -2.836,
                lng: 111.769
            },
            {
                lat: -2.848,
                lng: 110.294
            },
            {
                lat: -2.809,
                lng: 108.719
            },
            {
                lat: -2.711,
                lng: 107.019
            },
            {
                lat: -2.542,
                lng: 105.156
            },
            {
                lat: -2.410,
                lng: 104.067
            },
            {
                lat: -2.287,
                lng: 103.070
            },
            {
                lat: -1.927,
                lng: 100.649
            },
            {
                lat: -1.819,
                lng: 99.984
            },
            {
                lat: -1.423,
                lng: 97.640
            },
            {
                lat: -1.213,
                lng: 96.352
            },
            {
                lat: -0.712,
                lng: 93.081
            },
            {
                lat: -0.597,
                lng: 92.048
            },
            {
                lat: -0.326,
                lng: 87.841
            },
        ]
    };

    export var horizongain = {
        "c1": '-1',
        "c2": '-2',
        "c3": '-3',
        "c4": '-4',
        "c5": '-5',
        "c6": '-6',
        "c7": '-7',
        "c8": '-8',
        "c9": '-9',
        "c10": '-10',
    };

    export var horizon_gnt = {
        c1: [{
                lat: 3.608,
                lng: 113.267
            },
            {
                lat: 3.003,
                lng: 114.238
            },
            {
                lat: 2.695,
                lng: 114.479
            },
            {
                lat: 2.406,
                lng: 114.650
            },
            {
                lat: 1.812,
                lng: 114.633
            },
            {
                lat: 1.696,
                lng: 114.553
            },
            {
                lat: 1.220,
                lng: 114.055
            },
            {
                lat: 0.871,
                lng: 113.285
            },
            {
                lat: 0.629,
                lng: 112.075
            },
            {
                lat: 0.607,
                lng: 111.915
            },
            {
                lat: 0.620,
                lng: 110.453
            },
            {
                lat: 0.631,
                lng: 110.375
            },
            {
                lat: 0.913,
                lng: 108.877
            },
            {
                lat: 1.240,
                lng: 107.927
            },
            {
                lat: 1.686,
                lng: 107.129
            },
            {
                lat: 1.849,
                lng: 106.913
            },
            {
                lat: 2.457,
                lng: 106.605
            },
            {
                lat: 3.062,
                lng: 106.793
            },
            {
                lat: 3.199,
                lng: 106.947
            },
            {
                lat: 3.660,
                lng: 107.748
            },
            {
                lat: 3.929,
                lng: 108.550
            },
            {
                lat: 4.138,
                lng: 110.108
            },
            {
                lat: 4.050,
                lng: 111.608
            },
            {
                lat: 3.697,
                lng: 113.053
            },
            {
                lat: 3.608,
                lng: 113.267
            },
        ],
        c2: [{
                lat: 4.831,
                lng: 111.053
            },
            {
                lat: 4.805,
                lng: 111.479
            },
            {
                lat: 4.525,
                lng: 112.930
            },
            {
                lat: 4.198,
                lng: 113.926
            },
            {
                lat: 4.025,
                lng: 114.328
            },
            {
                lat: 3.591,
                lng: 115.070
            },
            {
                lat: 3.029,
                lng: 115.697
            },
            {
                lat: 2.992,
                lng: 115.731
            },
            {
                lat: 2.398,
                lng: 116.021
            },
            {
                lat: 1.806,
                lng: 116.034
            },
            {
                lat: 1.338,
                lng: 115.814
            },
            {
                lat: 1.215,
                lng: 115.740
            },
            {
                lat: 0.624,
                lng: 114.963
            },
            {
                lat: 0.453,
                lng: 114.598
            },
            {
                lat: 0.077,
                lng: 113.298
            },
            {
                lat: 0.031,
                lng: 112.937
            },
            {
                lat: -0.069,
                lng: 111.922
            },
            {
                lat: -0.056,
                lng: 110.461
            },
            {
                lat: 0.031,
                lng: 109.620
            },
            {
                lat: 0.123,
                lng: 108.895
            },
            {
                lat: 0.521,
                lng: 107.192
            },
            {
                lat: 0.637,
                lng: 106.836
            },
            {
                lat: 1.248,
                lng: 105.480
            },
            {
                lat: 1.395,
                lng: 105.280
            },
            {
                lat: 1.859,
                lng: 104.738
            },
            {
                lat: 2.471,
                lng: 104.445
            },
            {
                lat: 3.080,
                lng: 104.567
            },
            {
                lat: 3.552,
                lng: 104.985
            },
            {
                lat: 3.685,
                lng: 105.137
            },
            {
                lat: 4.282,
                lng: 106.336
            },
            {
                lat: 4.415,
                lng: 106.717
            },
            {
                lat: 4.776,
                lng: 108.382
            },
            {
                lat: 4.852,
                lng: 109.386
            },
            {
                lat: 4.888,
                lng: 109.967
            },
            {
                lat: 4.831,
                lng: 111.053
            },
        ],
        c3: [{
                lat: 5.449,
                lng: 109.884
            },
            {
                lat: 5.362,
                lng: 111.368
            },
            {
                lat: 5.124,
                lng: 112.825
            },
            {
                lat: 4.794,
                lng: 114.028
            },
            {
                lat: 4.729,
                lng: 114.223
            },
            {
                lat: 4.182,
                lng: 115.427
            },
            {
                lat: 4.092,
                lng: 115.576
            },
            {
                lat: 3.581,
                lng: 116.268
            },
            {
                lat: 2.984,
                lng: 116.788
            },
            {
                lat: 2.688,
                lng: 116.922
            },
            {
                lat: 2.392,
                lng: 117.044
            },
            {
                lat: 1.801,
                lng: 117.067
            },
            {
                lat: 1.585,
                lng: 116.993
            },
            {
                lat: 1.211,
                lng: 116.843
            },
            {
                lat: 0.622,
                lng: 116.309
            },
            {
                lat: 0.312,
                lng: 115.841
            },
            {
                lat: 0.031,
                lng: 115.255
            },
            {
                lat: -0.192,
                lng: 114.601
            },
            {
                lat: -0.459,
                lng: 113.295
            },
            {
                lat: -0.566,
                lng: 112.072
            },
            {
                lat: -0.578,
                lng: 111.916
            },
            {
                lat: -0.568,
                lng: 110.585
            },
            {
                lat: -0.567,
                lng: 110.455
            },
            {
                lat: -0.423,
                lng: 108.891
            },
            {
                lat: -0.130,
                lng: 107.198
            },
            {
                lat: 0.032,
                lng: 106.539
            },
            {
                lat: 0.405,
                lng: 105.328
            },
            {
                lat: 0.641,
                lng: 104.704
            },
            {
                lat: 1.254,
                lng: 103.534
            },
            {
                lat: 1.573,
                lng: 103.157
            },
            {
                lat: 1.869,
                lng: 102.827
            },
            {
                lat: 2.484,
                lng: 102.536
            },
            {
                lat: 3.096,
                lng: 102.630
            },
            {
                lat: 3.400,
                lng: 102.869
            },
            {
                lat: 3.705,
                lng: 103.135
            },
            {
                lat: 4.307,
                lng: 104.097
            },
            {
                lat: 4.589,
                lng: 104.749
            },
            {
                lat: 4.899,
                lng: 105.697
            },
            {
                lat: 5.118,
                lng: 106.548
            },
            {
                lat: 5.377,
                lng: 108.242
            },
            {
                lat: 5.450,
                lng: 109.815
            },
            {
                lat: 5.451,
                lng: 109.844
            },
            {
                lat: 5.449,
                lng: 109.884
            },
        ],
        c4: [{
                lat: 5.397,
                lng: 113.630
            },
            {
                lat: 5.261,
                lng: 114.132
            },
            {
                lat: 4.777,
                lng: 115.425
            },
            {
                lat: 4.750,
                lng: 115.482
            },
            {
                lat: 4.171,
                lng: 116.501
            },
            {
                lat: 3.941,
                lng: 116.796
            },
            {
                lat: 3.572,
                lng: 117.212
            },
            {
                lat: 2.978,
                lng: 117.650
            },
            {
                lat: 2.387,
                lng: 117.871
            },
            {
                lat: 1.797,
                lng: 117.895
            },
            {
                lat: 1.209,
                lng: 117.718
            },
            {
                lat: 0.620,
                lng: 117.306
            },
            {
                lat: 0.378,
                lng: 117.029
            },
            {
                lat: 0.031,
                lng: 116.534
            },
            {
                lat: -0.301,
                lng: 115.841
            },
            {
                lat: -0.562,
                lng: 115.051
            },
            {
                lat: -0.680,
                lng: 114.594
            },
            {
                lat: -0.888,
                lng: 113.284
            },
            {
                lat: -0.983,
                lng: 111.904
            },
            {
                lat: -0.976,
                lng: 110.442
            },
            {
                lat: -0.863,
                lng: 108.879
            },
            {
                lat: -0.631,
                lng: 107.189
            },
            {
                lat: -0.574,
                lng: 106.894
            },
            {
                lat: -0.220,
                lng: 105.332
            },
            {
                lat: 0.032,
                lng: 104.450
            },
            {
                lat: 0.476,
                lng: 103.227
            },
            {
                lat: 0.644,
                lng: 102.811
            },
            {
                lat: 1.260,
                lng: 101.695
            },
            {
                lat: 1.878,
                lng: 100.993
            },
            {
                lat: 2.496,
                lng: 100.690
            },
            {
                lat: 3.112,
                lng: 100.777
            },
            {
                lat: 3.724,
                lng: 101.260
            },
            {
                lat: 4.330,
                lng: 102.143
            },
            {
                lat: 4.537,
                lng: 102.577
            },
            {
                lat: 4.928,
                lng: 103.522
            },
            {
                lat: 5.258,
                lng: 104.562
            },
            {
                lat: 5.510,
                lng: 105.635
            },
            {
                lat: 5.664,
                lng: 106.399
            },
            {
                lat: 5.865,
                lng: 108.116
            },
            {
                lat: 5.913,
                lng: 109.733
            },
            {
                lat: 5.825,
                lng: 111.267
            },
            {
                lat: 5.610,
                lng: 112.730
            },
            {
                lat: 5.397,
                lng: 113.630
            },
        ],
        c5: [{
                lat: 6.010,
                lng: 112.717
            },
            {
                lat: 5.705,
                lng: 114.048
            },
            {
                lat: 5.377,
                lng: 115.077
            },
            {
                lat: 5.256,
                lng: 115.400
            },
            {
                lat: 4.765,
                lng: 116.460
            },
            {
                lat: 4.619,
                lng: 116.707
            },
            {
                lat: 4.162,
                lng: 117.385
            },
            {
                lat: 3.584,
                lng: 117.986
            },
            {
                lat: 3.565,
                lng: 118.004
            },
            {
                lat: 2.972,
                lng: 118.393
            },
            {
                lat: 2.382,
                lng: 118.588
            },
            {
                lat: 1.794,
                lng: 118.609
            },
            {
                lat: 1.207,
                lng: 118.460
            },
            {
                lat: 0.698,
                lng: 118.164
            },
            {
                lat: 0.619,
                lng: 118.113
            },
            {
                lat: 0.031,
                lng: 117.494
            },
            {
                lat: -0.262,
                lng: 117.030
            },
            {
                lat: -0.560,
                lng: 116.413
            },
            {
                lat: -0.771,
                lng: 115.833
            },
            {
                lat: -1.077,
                lng: 114.582
            },
            {
                lat: -1.158,
                lng: 114.035
            },
            {
                lat: -1.256,
                lng: 113.270
            },
            {
                lat: -1.337,
                lng: 111.889
            },
            {
                lat: -1.332,
                lng: 110.425
            },
            {
                lat: -1.239,
                lng: 108.861
            },
            {
                lat: -1.175,
                lng: 108.298
            },
            {
                lat: -1.039,
                lng: 107.172
            },
            {
                lat: -0.708,
                lng: 105.319
            },
            {
                lat: -0.577,
                lng: 104.740
            },
            {
                lat: -0.173,
                lng: 103.234
            },
            {
                lat: 0.032,
                lng: 102.564
            },
            {
                lat: 0.647,
                lng: 100.980
            },
            {
                lat: 0.761,
                lng: 100.767
            },
            {
                lat: 1.267,
                lng: 99.820
            },
            {
                lat: 1.888,
                lng: 99.077
            },
            {
                lat: 2.509,
                lng: 98.751
            },
            {
                lat: 3.128,
                lng: 98.841
            },
            {
                lat: 3.743,
                lng: 99.348
            },
            {
                lat: 4.241,
                lng: 100.096
            },
            {
                lat: 4.352,
                lng: 100.264
            },
            {
                lat: 4.953,
                lng: 101.587
            },
            {
                lat: 5.222,
                lng: 102.356
            },
            {
                lat: 5.543,
                lng: 103.433
            },
            {
                lat: 5.783,
                lng: 104.395
            },
            {
                lat: 6.112,
                lng: 106.220
            },
            {
                lat: 6.119,
                lng: 106.261
            },
            {
                lat: 6.285,
                lng: 107.998
            },
            {
                lat: 6.316,
                lng: 109.628
            },
            {
                lat: 6.227,
                lng: 111.171
            },
            {
                lat: 6.025,
                lng: 112.641
            },
            {
                lat: 6.010,
                lng: 112.717
            },
        ],
        c6: [{
                lat: 6.663,
                lng: 109.682
            },
            {
                lat: 6.582,
                lng: 111.081
            },
            {
                lat: 6.388,
                lng: 112.558
            },
            {
                lat: 6.093,
                lng: 113.970
            },
            {
                lat: 5.985,
                lng: 114.358
            },
            {
                lat: 5.684,
                lng: 115.324
            },
            {
                lat: 5.363,
                lng: 116.143
            },
            {
                lat: 5.134,
                lng: 116.630
            },
            {
                lat: 4.754,
                lng: 117.327
            },
            {
                lat: 4.346,
                lng: 117.899
            },
            {
                lat: 4.154,
                lng: 118.146
            },
            {
                lat: 3.559,
                lng: 118.701
            },
            {
                lat: 2.968,
                lng: 119.042
            },
            {
                lat: 2.472,
                lng: 119.186
            },
            {
                lat: 2.379,
                lng: 119.213
            },
            {
                lat: 1.791,
                lng: 119.230
            },
            {
                lat: 1.775,
                lng: 119.227
            },
            {
                lat: 1.205,
                lng: 119.099
            },
            {
                lat: 0.618,
                lng: 118.799
            },
            {
                lat: 0.031,
                lng: 118.280
            },
            {
                lat: -0.056,
                lng: 118.171
            },
            {
                lat: -0.559,
                lng: 117.413
            },
            {
                lat: -0.746,
                lng: 117.022
            },
            {
                lat: -1.153,
                lng: 115.854
            },
            {
                lat: -1.162,
                lng: 115.822
            },
            {
                lat: -1.417,
                lng: 114.568
            },
            {
                lat: -1.572,
                lng: 113.254
            },
            {
                lat: -1.644,
                lng: 111.871
            },
            {
                lat: -1.642,
                lng: 110.406
            },
            {
                lat: -1.562,
                lng: 108.841
            },
            {
                lat: -1.394,
                lng: 107.151
            },
            {
                lat: -1.183,
                lng: 105.732
            },
            {
                lat: -1.113,
                lng: 105.299
            },
            {
                lat: -0.676,
                lng: 103.221
            },
            {
                lat: -0.580,
                lng: 102.826
            },
            {
                lat: 0.011,
                lng: 100.790
            },
            {
                lat: 0.032,
                lng: 100.720
            },
            {
                lat: 0.651,
                lng: 99.060
            },
            {
                lat: 1.274,
                lng: 97.829
            },
            {
                lat: 1.424,
                lng: 97.640
            },
            {
                lat: 1.899,
                lng: 96.978
            },
            {
                lat: 2.524,
                lng: 96.594
            },
            {
                lat: 3.146,
                lng: 96.712
            },
            {
                lat: 3.534,
                lng: 97.102
            },
            {
                lat: 3.764,
                lng: 97.304
            },
            {
                lat: 4.375,
                lng: 98.290
            },
            {
                lat: 4.978,
                lng: 99.690
            },
            {
                lat: 5.018,
                lng: 99.807
            },
            {
                lat: 5.572,
                lng: 101.492
            },
            {
                lat: 5.754,
                lng: 102.160
            },
            {
                lat: 6.151,
                lng: 103.883
            },
            {
                lat: 6.224,
                lng: 104.241
            },
            {
                lat: 6.508,
                lng: 106.134
            },
            {
                lat: 6.652,
                lng: 107.886
            },
            {
                lat: 6.669,
                lng: 109.327
            },
            {
                lat: 6.672,
                lng: 109.529
            },
            {
                lat: 6.663,
                lng: 109.682
            },
        ],
        c7: [{
                lat: 6.605,
                lng: 113.051
            },
            {
                lat: 6.434,
                lng: 113.896
            },
            {
                lat: 6.057,
                lng: 115.253
            },
            {
                lat: 5.967,
                lng: 115.518
            },
            {
                lat: 5.564,
                lng: 116.559
            },
            {
                lat: 5.351,
                lng: 117.026
            },
            {
                lat: 4.904,
                lng: 117.824
            },
            {
                lat: 4.745,
                lng: 118.079
            },
            {
                lat: 4.147,
                lng: 118.819
            },
            {
                lat: 3.862,
                lng: 119.065
            },
            {
                lat: 3.554,
                lng: 119.323
            },
            {
                lat: 2.963,
                lng: 119.637
            },
            {
                lat: 2.376,
                lng: 119.788
            },
            {
                lat: 1.789,
                lng: 119.798
            },
            {
                lat: 1.203,
                lng: 119.674
            },
            {
                lat: 0.617,
                lng: 119.400
            },
            {
                lat: 0.436,
                lng: 119.268
            },
            {
                lat: 0.031,
                lng: 118.946
            },
            {
                lat: -0.558,
                lng: 118.222
            },
            {
                lat: -0.591,
                lng: 118.166
            },
            {
                lat: -1.146,
                lng: 117.011
            },
            {
                lat: -1.149,
                lng: 117.002
            },
            {
                lat: -1.492,
                lng: 115.807
            },
            {
                lat: -1.717,
                lng: 114.552
            },
            {
                lat: -1.752,
                lng: 114.234
            },
            {
                lat: -1.854,
                lng: 113.237
            },
            {
                lat: -1.920,
                lng: 111.853
            },
            {
                lat: -1.921,
                lng: 110.386
            },
            {
                lat: -1.853,
                lng: 108.819
            },
            {
                lat: -1.780,
                lng: 107.979
            },
            {
                lat: -1.706,
                lng: 107.128
            },
            {
                lat: -1.463,
                lng: 105.275
            },
            {
                lat: -1.190,
                lng: 103.707
            },
            {
                lat: -1.090,
                lng: 103.197
            },
            {
                lat: -0.583,
                lng: 100.975
            },
            {
                lat: -0.530,
                lng: 100.778
            },
            {
                lat: 0.032,
                lng: 98.775
            },
            {
                lat: 0.397,
                lng: 97.732
            },
            {
                lat: 0.655,
                lng: 96.940
            },
            {
                lat: 1.282,
                lng: 95.433
            },
            {
                lat: 1.912,
                lng: 94.385
            },
            {
                lat: 2.542,
                lng: 93.896
            },
            {
                lat: 3.169,
                lng: 94.042
            },
            {
                lat: 3.789,
                lng: 94.803
            },
            {
                lat: 4.401,
                lng: 96.077
            },
            {
                lat: 4.597,
                lng: 96.634
            },
            {
                lat: 5.006,
                lng: 97.677
            },
            {
                lat: 5.587,
                lng: 99.559
            },
            {
                lat: 5.600,
                lng: 99.605
            },
            {
                lat: 6.183,
                lng: 101.910
            },
            {
                lat: 6.199,
                lng: 101.980
            },
            {
                lat: 6.603,
                lng: 104.098
            },
            {
                lat: 6.743,
                lng: 105.141
            },
            {
                lat: 6.856,
                lng: 106.013
            },
            {
                lat: 6.981,
                lng: 107.781
            },
            {
                lat: 6.993,
                lng: 109.434
            },
            {
                lat: 6.902,
                lng: 110.996
            },
            {
                lat: 6.715,
                lng: 112.479
            },
            {
                lat: 6.605,
                lng: 113.051
            },
        ],
        c8: [{
                lat: 2.930,
                lng: 87.853
            },
            {
                lat: 3.215,
                lng: 88.614
            },
            {
                lat: 3.827,
                lng: 91.167
            },
            {
                lat: 3.892,
                lng: 91.473
            },
            {
                lat: 4.436,
                lng: 93.188
            },
            {
                lat: 5.037,
                lng: 95.343
            },
            {
                lat: 5.261,
                lng: 96.261
            },
            {
                lat: 5.631,
                lng: 97.598
            },
            {
                lat: 6.045,
                lng: 99.336
            },
            {
                lat: 6.214,
                lng: 100.063
            },
            {
                lat: 6.577,
                lng: 101.814
            },
            {
                lat: 6.782,
                lng: 102.996
            },
            {
                lat: 6.941,
                lng: 103.963
            },
            {
                lat: 7.167,
                lng: 105.899
            },
            {
                lat: 7.275,
                lng: 107.681
            },
            {
                lat: 7.279,
                lng: 109.202
            },
            {
                lat: 7.279,
                lng: 109.346
            },
            {
                lat: 7.274,
                lng: 109.431
            },
            {
                lat: 7.189,
                lng: 110.915
            },
            {
                lat: 7.010,
                lng: 112.404
            },
            {
                lat: 6.743,
                lng: 113.825
            },
            {
                lat: 6.581,
                lng: 114.467
            },
            {
                lat: 6.387,
                lng: 115.186
            },
            {
                lat: 5.953,
                lng: 116.446
            },
            {
                lat: 5.934,
                lng: 116.494
            },
            {
                lat: 5.355,
                lng: 117.756
            },
            {
                lat: 5.341,
                lng: 117.784
            },
            {
                lat: 4.738,
                lng: 118.748
            },
            {
                lat: 4.536,
                lng: 118.986
            },
            {
                lat: 4.141,
                lng: 119.435
            },
            {
                lat: 3.549,
                lng: 119.891
            },
            {
                lat: 2.960,
                lng: 120.164
            },
            {
                lat: 2.607,
                lng: 120.242
            },
            {
                lat: 2.373,
                lng: 120.295
            },
            {
                lat: 1.787,
                lng: 120.297
            },
            {
                lat: 1.759,
                lng: 120.291
            },
            {
                lat: 1.202,
                lng: 120.183
            },
            {
                lat: 0.616,
                lng: 119.940
            },
            {
                lat: 0.031,
                lng: 119.534
            },
            {
                lat: -0.233,
                lng: 119.269
            },
            {
                lat: -0.557,
                lng: 118.901
            },
            {
                lat: -1.015,
                lng: 118.156
            },
            {
                lat: -1.147,
                lng: 117.884
            },
            {
                lat: -1.482,
                lng: 116.998
            },
            {
                lat: -1.744,
                lng: 115.981
            },
            {
                lat: -1.786,
                lng: 115.792
            },
            {
                lat: -1.985,
                lng: 114.535
            },
            {
                lat: -2.107,
                lng: 113.219
            },
            {
                lat: -2.167,
                lng: 111.833
            },
            {
                lat: -2.171,
                lng: 110.365
            },
            {
                lat: -2.115,
                lng: 108.796
            },
            {
                lat: -1.988,
                lng: 107.102
            },
            {
                lat: -1.793,
                lng: 105.404
            },
            {
                lat: -1.774,
                lng: 105.247
            },
            {
                lat: -1.451,
                lng: 103.168
            },
            {
                lat: -1.196,
                lng: 101.813
            },
            {
                lat: -0.972,
                lng: 100.754
            },
            {
                lat: -0.586,
                lng: 99.034
            },
            {
                lat: -0.248,
                lng: 97.737
            },
            {
                lat: 0.033,
                lng: 96.588
            },
            {
                lat: 0.659,
                lng: 94.333
            },
            {
                lat: 1.085,
                lng: 93.019
            },
            {
                lat: 1.293,
                lng: 92.182
            },
            {
                lat: 1.935,
                lng: 89.890
            },
            {
                lat: 2.429,
                lng: 87.849
            },
        ],
        c9: [{
                lat: 4.654,
                lng: 87.871
            },
            {
                lat: 4.795,
                lng: 90.219
            },
            {
                lat: 5.081,
                lng: 92.109
            },
            {
                lat: 5.666,
                lng: 95.293
            },
            {
                lat: 5.781,
                lng: 95.920
            },
            {
                lat: 6.246,
                lng: 98.123
            },
            {
                lat: 6.442,
                lng: 99.124
            },
            {
                lat: 6.816,
                lng: 101.106
            },
            {
                lat: 6.914,
                lng: 101.656
            },
            {
                lat: 7.242,
                lng: 103.835
            },
            {
                lat: 7.360,
                lng: 104.965
            },
            {
                lat: 7.449,
                lng: 105.790
            },
            {
                lat: 7.549,
                lng: 107.585
            },
            {
                lat: 7.549,
                lng: 109.259
            },
            {
                lat: 7.458,
                lng: 110.836
            },
            {
                lat: 7.278,
                lng: 112.333
            },
            {
                lat: 7.213,
                lng: 112.701
            },
            {
                lat: 7.022,
                lng: 113.758
            },
            {
                lat: 6.685,
                lng: 115.122
            },
            {
                lat: 6.564,
                lng: 115.521
            },
            {
                lat: 6.261,
                lng: 116.432
            },
            {
                lat: 5.941,
                lng: 117.241
            },
            {
                lat: 5.732,
                lng: 117.695
            },
            {
                lat: 5.332,
                lng: 118.470
            },
            {
                lat: 5.038,
                lng: 118.920
            },
            {
                lat: 4.731,
                lng: 119.364
            },
            {
                lat: 4.135,
                lng: 119.988
            },
            {
                lat: 3.944,
                lng: 120.127
            },
            {
                lat: 3.544,
                lng: 120.417
            },
            {
                lat: 2.956,
                lng: 120.673
            },
            {
                lat: 2.370,
                lng: 120.785
            },
            {
                lat: 1.785,
                lng: 120.778
            },
            {
                lat: 1.200,
                lng: 120.660
            },
            {
                lat: 0.616,
                lng: 120.422
            },
            {
                lat: 0.465,
                lng: 120.329
            },
            {
                lat: 0.031,
                lng: 120.057
            },
            {
                lat: -0.556,
                lng: 119.498
            },
            {
                lat: -0.736,
                lng: 119.263
            },
            {
                lat: -1.145,
                lng: 118.627
            },
            {
                lat: -1.378,
                lng: 118.144
            },
            {
                lat: -1.739,
                lng: 117.128
            },
            {
                lat: -1.782,
                lng: 116.983
            },
            {
                lat: -2.049,
                lng: 115.776
            },
            {
                lat: -2.224,
                lng: 114.519
            },
            {
                lat: -2.333,
                lng: 113.201
            },
            {
                lat: -2.356,
                lng: 112.633
            },
            {
                lat: -2.390,
                lng: 111.814
            },
            {
                lat: -2.396,
                lng: 110.344
            },
            {
                lat: -2.374,
                lng: 109.656
            },
            {
                lat: -2.349,
                lng: 108.772
            },
            {
                lat: -2.242,
                lng: 107.076
            },
            {
                lat: -2.057,
                lng: 105.217
            },
            {
                lat: -1.802,
                lng: 103.363
            },
            {
                lat: -1.770,
                lng: 103.136
            },
            {
                lat: -1.356,
                lng: 100.720
            },
            {
                lat: -1.202,
                lng: 99.898
            },
            {
                lat: -0.752,
                lng: 97.713
            },
            {
                lat: -0.590,
                lng: 96.886
            },
            {
                lat: 0.033,
                lng: 93.878
            },
            {
                lat: 0.197,
                lng: 93.125
            },
            {
                lat: 0.668,
                lng: 89.615
            },
            {
                lat: 0.766,
                lng: 87.842
            },
        ],
        c10: [{
                lat: 5.414,
                lng: 87.881
            },
            {
                lat: 5.716,
                lng: 92.034
            },
            {
                lat: 6.211,
                lng: 95.601
            },
            {
                lat: 6.282,
                lng: 95.992
            },
            {
                lat: 6.788,
                lng: 98.924
            },
            {
                lat: 6.850,
                lng: 99.284
            },
            {
                lat: 7.215,
                lng: 101.506
            },
            {
                lat: 7.402,
                lng: 102.876
            },
            {
                lat: 7.517,
                lng: 103.713
            },
            {
                lat: 7.708,
                lng: 105.685
            },
            {
                lat: 7.795,
                lng: 107.494
            },
            {
                lat: 7.790,
                lng: 109.178
            },
            {
                lat: 7.701,
                lng: 110.762
            },
            {
                lat: 7.530,
                lng: 112.263
            },
            {
                lat: 7.278,
                lng: 113.694
            },
            {
                lat: 7.188,
                lng: 114.088
            },
            {
                lat: 6.956,
                lng: 115.061
            },
            {
                lat: 6.553,
                lng: 116.374
            },
            {
                lat: 6.550,
                lng: 116.384
            },
            {
                lat: 6.066,
                lng: 117.637
            },
            {
                lat: 5.931,
                lng: 117.945
            },
            {
                lat: 5.452,
                lng: 118.859
            },
            {
                lat: 5.324,
                lng: 119.086
            },
            {
                lat: 4.724,
                lng: 119.919
            },
            {
                lat: 4.593,
                lng: 120.053
            },
            {
                lat: 4.130,
                lng: 120.523
            },
            {
                lat: 3.540,
                lng: 120.909
            },
            {
                lat: 2.953,
                lng: 121.126
            },
            {
                lat: 2.367,
                lng: 121.216
            },
            {
                lat: 1.783,
                lng: 121.201
            },
            {
                lat: 1.199,
                lng: 121.089
            },
            {
                lat: 0.615,
                lng: 120.875
            },
            {
                lat: 0.031,
                lng: 120.531
            },
            {
                lat: -0.210,
                lng: 120.331
            },
            {
                lat: -0.555,
                lng: 120.027
            },
            {
                lat: -1.143,
                lng: 119.263
            },
            {
                lat: -1.150,
                lng: 119.252
            },
            {
                lat: -1.694,
                lng: 118.130
            },
            {
                lat: -1.735,
                lng: 118.017
            },
            {
                lat: -2.049,
                lng: 116.967
            },
            {
                lat: -2.284,
                lng: 115.760
            },
            {
                lat: -2.340,
                lng: 115.336
            },
            {
                lat: -2.444,
                lng: 114.501
            },
            {
                lat: -2.547,
                lng: 113.183
            },
            {
                lat: -2.601,
                lng: 111.794
            },
            {
                lat: -2.610,
                lng: 110.322
            },
            {
                lat: -2.571,
                lng: 108.748
            },
            {
                lat: -2.474,
                lng: 107.049
            },
            {
                lat: -2.396,
                lng: 106.178
            },
            {
                lat: -2.309,
                lng: 105.187
            },
            {
                lat: -2.060,
                lng: 103.101
            },
            {
                lat: -1.812,
                lng: 101.439
            },
            {
                lat: -1.692,
                lng: 100.681
            },
            {
                lat: -1.209,
                lng: 97.865
            },
            {
                lat: -1.173,
                lng: 97.672
            },
            {
                lat: -0.594,
                lng: 94.245
            },
            {
                lat: -0.407,
                lng: 93.113
            },
            {
                lat: -0.001,
                lng: 87.841
            },
        ]
    };

    export var horizon_gntgain = {
        "c1": '-1',
        "c2": '-2',
        "c3": '-3',
        "c4": '-4',
        "c5": '-5',
        "c6": '-6',
        "c7": '-7',
        "c8": '-8',
        "c9": '-9',
        "c10": '-10',
    };


