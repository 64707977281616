import { defineStore } from 'pinia'
import $ from 'jquery'



export const useToastStore = defineStore({
    id: 'toast',
    state: () => ({
        toast_title: "",
        toast_message:"",
        toast_style:"",
        toast_text:"",
    }),
    actions: {



        //NOTIFICATION FUNCTIONS
        //------------------------------------------------------------------
        notif(title,message,style,text){
            this.toast_title = title;
            this.toast_message = message;
            this.toast_style = style;
            this.toast_text = text;
            console.log("STYLE NYA");
            console.log(style);
            $('#liveToast').show()
            setTimeout(() => {
                $('#liveToast').hide() 
            }, 5000);
        }
        //------------------------------------------------------------------
    
    
    
    }
})
