export const beam3 = {
    c1: [{
            lat: -5.208,
            lng: 111.224
        },
        {
            lat: -5.280,
            lng: 111.614
        },
        {
            lat: -5.437,
            lng: 112.107
        },
        {
            lat: -5.632,
            lng: 112.533
        },
        {
            lat: -5.663,
            lng: 112.593
        },
        {
            lat: -5.993,
            lng: 113.070
        },
        {
            lat: -6.062,
            lng: 113.150
        },
        {
            lat: -6.466,
            lng: 113.534
        },
        {
            lat: -6.494,
            lng: 113.557
        },
        {
            lat: -6.927,
            lng: 113.826
        },
        {
            lat: -7.274,
            lng: 113.973
        },
        {
            lat: -7.362,
            lng: 114.006
        },
        {
            lat: -7.798,
            lng: 114.097
        },
        {
            lat: -8.235,
            lng: 114.116
        },
        {
            lat: -8.674,
            lng: 114.065
        },
        {
            lat: -9.115,
            lng: 113.945
        },
        {
            lat: -9.440,
            lng: 113.804
        },
        {
            lat: -9.557,
            lng: 113.749
        },
        {
            lat: -10.003,
            lng: 113.460
        },
        {
            lat: -10.279,
            lng: 113.229
        },
        {
            lat: -10.451,
            lng: 113.063
        },
        {
            lat: -10.781,
            lng: 112.675
        },
        {
            lat: -10.903,
            lng: 112.503
        },
        {
            lat: -11.128,
            lng: 112.132
        },
        {
            lat: -11.363,
            lng: 111.636
        },
        {
            lat: -11.381,
            lng: 111.593
        },
        {
            lat: -11.548,
            lng: 111.058
        },
        {
            lat: -11.654,
            lng: 110.527
        },
        {
            lat: -11.699,
            lng: 110.000
        },
        {
            lat: -11.686,
            lng: 109.476
        },
        {
            lat: -11.613,
            lng: 108.956
        },
        {
            lat: -11.477,
            lng: 108.441
        },
        {
            lat: -11.425,
            lng: 108.304
        },
        {
            lat: -11.261,
            lng: 107.933
        },
        {
            lat: -10.993,
            lng: 107.483
        },
        {
            lat: -10.958,
            lng: 107.434
        },
        {
            lat: -10.555,
            lng: 106.985
        },
        {
            lat: -10.514,
            lng: 106.949
        },
        {
            lat: -10.114,
            lng: 106.658
        },
        {
            lat: -9.797,
            lng: 106.495
        },
        {
            lat: -9.672,
            lng: 106.439
        },
        {
            lat: -9.228,
            lng: 106.319
        },
        {
            lat: -8.784,
            lng: 106.276
        },
        {
            lat: -8.340,
            lng: 106.308
        },
        {
            lat: -7.895,
            lng: 106.414
        },
        {
            lat: -7.450,
            lng: 106.598
        },
        {
            lat: -7.128,
            lng: 106.787
        },
        {
            lat: -7.006,
            lng: 106.865
        },
        {
            lat: -6.560,
            lng: 107.237
        },
        {
            lat: -6.415,
            lng: 107.387
        },
        {
            lat: -6.115,
            lng: 107.742
        },
        {
            lat: -5.963,
            lng: 107.956
        },
        {
            lat: -5.669,
            lng: 108.456
        },
        {
            lat: -5.643,
            lng: 108.507
        },
        {
            lat: -5.423,
            lng: 109.045
        },
        {
            lat: -5.272,
            lng: 109.574
        },
        {
            lat: -5.219,
            lng: 109.885
        },
        {
            lat: -5.186,
            lng: 110.095
        },
        {
            lat: -5.159,
            lng: 110.608
        },
        {
            lat: -5.189,
            lng: 111.115
        },
        {
            lat: -5.208,
            lng: 111.224
        },
    ],
    c2: [{
            lat: -4.333,
            lng: 111.781
        },
        {
            lat: -4.423,
            lng: 112.158
        },
        {
            lat: -4.590,
            lng: 112.648
        },
        {
            lat: -4.759,
            lng: 113.020
        },
        {
            lat: -4.814,
            lng: 113.131
        },
        {
            lat: -5.112,
            lng: 113.608
        },
        {
            lat: -5.188,
            lng: 113.711
        },
        {
            lat: -5.503,
            lng: 114.078
        },
        {
            lat: -5.618,
            lng: 114.194
        },
        {
            lat: -6.027,
            lng: 114.537
        },
        {
            lat: -6.050,
            lng: 114.554
        },
        {
            lat: -6.482,
            lng: 114.817
        },
        {
            lat: -6.835,
            lng: 114.975
        },
        {
            lat: -6.916,
            lng: 115.008
        },
        {
            lat: -7.350,
            lng: 115.132
        },
        {
            lat: -7.786,
            lng: 115.196
        },
        {
            lat: -8.222,
            lng: 115.206
        },
        {
            lat: -8.661,
            lng: 115.162
        },
        {
            lat: -9.100,
            lng: 115.064
        },
        {
            lat: -9.542,
            lng: 114.910
        },
        {
            lat: -9.879,
            lng: 114.748
        },
        {
            lat: -9.985,
            lng: 114.695
        },
        {
            lat: -10.430,
            lng: 114.408
        },
        {
            lat: -10.718,
            lng: 114.178
        },
        {
            lat: -10.878,
            lng: 114.038
        },
        {
            lat: -11.274,
            lng: 113.624
        },
        {
            lat: -11.330,
            lng: 113.557
        },
        {
            lat: -11.682,
            lng: 113.079
        },
        {
            lat: -11.786,
            lng: 112.911
        },
        {
            lat: -11.996,
            lng: 112.537
        },
        {
            lat: -12.241,
            lng: 111.998
        },
        {
            lat: -12.250,
            lng: 111.974
        },
        {
            lat: -12.423,
            lng: 111.462
        },
        {
            lat: -12.554,
            lng: 110.928
        },
        {
            lat: -12.637,
            lng: 110.396
        },
        {
            lat: -12.674,
            lng: 109.866
        },
        {
            lat: -12.666,
            lng: 109.338
        },
        {
            lat: -12.612,
            lng: 108.813
        },
        {
            lat: -12.511,
            lng: 108.291
        },
        {
            lat: -12.358,
            lng: 107.773
        },
        {
            lat: -12.335,
            lng: 107.715
        },
        {
            lat: -12.144,
            lng: 107.261
        },
        {
            lat: -11.904,
            lng: 106.822
        },
        {
            lat: -11.863,
            lng: 106.755
        },
        {
            lat: -11.492,
            lng: 106.259
        },
        {
            lat: -11.466,
            lng: 106.230
        },
        {
            lat: -11.026,
            lng: 105.805
        },
        {
            lat: -10.990,
            lng: 105.778
        },
        {
            lat: -10.583,
            lng: 105.498
        },
        {
            lat: -10.245,
            lng: 105.325
        },
        {
            lat: -10.139,
            lng: 105.276
        },
        {
            lat: -9.695,
            lng: 105.131
        },
        {
            lat: -9.250,
            lng: 105.050
        },
        {
            lat: -8.804,
            lng: 105.030
        },
        {
            lat: -8.359,
            lng: 105.068
        },
        {
            lat: -7.913,
            lng: 105.165
        },
        {
            lat: -7.468,
            lng: 105.322
        },
        {
            lat: -7.022,
            lng: 105.541
        },
        {
            lat: -6.726,
            lng: 105.729
        },
        {
            lat: -6.577,
            lng: 105.828
        },
        {
            lat: -6.132,
            lng: 106.192
        },
        {
            lat: -5.980,
            lng: 106.340
        },
        {
            lat: -5.687,
            lng: 106.652
        },
        {
            lat: -5.469,
            lng: 106.920
        },
        {
            lat: -5.242,
            lng: 107.238
        },
        {
            lat: -5.087,
            lng: 107.482
        },
        {
            lat: -4.797,
            lng: 108.025
        },
        {
            lat: -4.794,
            lng: 108.032
        },
        {
            lat: -4.575,
            lng: 108.572
        },
        {
            lat: -4.411,
            lng: 109.103
        },
        {
            lat: -4.350,
            lng: 109.382
        },
        {
            lat: -4.299,
            lng: 109.627
        },
        {
            lat: -4.234,
            lng: 110.145
        },
        {
            lat: -4.214,
            lng: 110.656
        },
        {
            lat: -4.238,
            lng: 111.162
        },
        {
            lat: -4.306,
            lng: 111.663
        },
        {
            lat: -4.333,
            lng: 111.781
        },
    ],
    c3: [{
            lat: -3.892,
            lng: 112.897
        },
        {
            lat: -3.996,
            lng: 113.166
        },
        {
            lat: -4.231,
            lng: 113.647
        },
        {
            lat: -4.320,
            lng: 113.799
        },
        {
            lat: -4.528,
            lng: 114.123
        },
        {
            lat: -4.749,
            lng: 114.413
        },
        {
            lat: -4.903,
            lng: 114.592
        },
        {
            lat: -5.179,
            lng: 114.875
        },
        {
            lat: -5.383,
            lng: 115.053
        },
        {
            lat: -5.610,
            lng: 115.233
        },
        {
            lat: -6.025,
            lng: 115.502
        },
        {
            lat: -6.042,
            lng: 115.512
        },
        {
            lat: -6.474,
            lng: 115.724
        },
        {
            lat: -6.907,
            lng: 115.877
        },
        {
            lat: -7.097,
            lng: 115.922
        },
        {
            lat: -7.342,
            lng: 115.978
        },
        {
            lat: -7.777,
            lng: 116.031
        },
        {
            lat: -8.213,
            lng: 116.036
        },
        {
            lat: -8.651,
            lng: 115.996
        },
        {
            lat: -9.090,
            lng: 115.910
        },
        {
            lat: -9.530,
            lng: 115.777
        },
        {
            lat: -9.613,
            lng: 115.743
        },
        {
            lat: -9.973,
            lng: 115.594
        },
        {
            lat: -10.417,
            lng: 115.356
        },
        {
            lat: -10.711,
            lng: 115.161
        },
        {
            lat: -10.863,
            lng: 115.055
        },
        {
            lat: -11.312,
            lng: 114.677
        },
        {
            lat: -11.388,
            lng: 114.603
        },
        {
            lat: -11.764,
            lng: 114.203
        },
        {
            lat: -11.886,
            lng: 114.055
        },
        {
            lat: -12.220,
            lng: 113.601
        },
        {
            lat: -12.280,
            lng: 113.511
        },
        {
            lat: -12.595,
            lng: 112.970
        },
        {
            lat: -12.682,
            lng: 112.791
        },
        {
            lat: -12.849,
            lng: 112.431
        },
        {
            lat: -13.050,
            lng: 111.893
        },
        {
            lat: -13.156,
            lng: 111.527
        },
        {
            lat: -13.205,
            lng: 111.356
        },
        {
            lat: -13.318,
            lng: 110.821
        },
        {
            lat: -13.390,
            lng: 110.287
        },
        {
            lat: -13.423,
            lng: 109.755
        },
        {
            lat: -13.418,
            lng: 109.224
        },
        {
            lat: -13.374,
            lng: 108.695
        },
        {
            lat: -13.289,
            lng: 108.169
        },
        {
            lat: -13.234,
            lng: 107.942
        },
        {
            lat: -13.161,
            lng: 107.646
        },
        {
            lat: -12.988,
            lng: 107.127
        },
        {
            lat: -12.809,
            lng: 106.716
        },
        {
            lat: -12.761,
            lng: 106.612
        },
        {
            lat: -12.475,
            lng: 106.103
        },
        {
            lat: -12.374,
            lng: 105.953
        },
        {
            lat: -12.113,
            lng: 105.603
        },
        {
            lat: -11.935,
            lng: 105.397
        },
        {
            lat: -11.651,
            lng: 105.114
        },
        {
            lat: -11.493,
            lng: 104.973
        },
        {
            lat: -11.049,
            lng: 104.650
        },
        {
            lat: -11.037,
            lng: 104.643
        },
        {
            lat: -10.604,
            lng: 104.409
        },
        {
            lat: -10.159,
            lng: 104.236
        },
        {
            lat: -10.083,
            lng: 104.216
        },
        {
            lat: -9.713,
            lng: 104.121
        },
        {
            lat: -9.267,
            lng: 104.061
        },
        {
            lat: -8.821,
            lng: 104.053
        },
        {
            lat: -8.374,
            lng: 104.096
        },
        {
            lat: -7.928,
            lng: 104.189
        },
        {
            lat: -7.482,
            lng: 104.334
        },
        {
            lat: -7.036,
            lng: 104.532
        },
        {
            lat: -6.920,
            lng: 104.596
        },
        {
            lat: -6.590,
            lng: 104.784
        },
        {
            lat: -6.144,
            lng: 105.095
        },
        {
            lat: -5.968,
            lng: 105.240
        },
        {
            lat: -5.699,
            lng: 105.474
        },
        {
            lat: -5.338,
            lng: 105.841
        },
        {
            lat: -5.255,
            lng: 105.933
        },
        {
            lat: -4.867,
            lng: 106.419
        },
        {
            lat: -4.810,
            lng: 106.498
        },
        {
            lat: -4.499,
            lng: 106.981
        },
        {
            lat: -4.366,
            lng: 107.222
        },
        {
            lat: -4.206,
            lng: 107.533
        },
        {
            lat: -3.975,
            lng: 108.076
        },
        {
            lat: -3.921,
            lng: 108.230
        },
        {
            lat: -3.795,
            lng: 108.610
        },
        {
            lat: -3.660,
            lng: 109.138
        },
        {
            lat: -3.568,
            lng: 109.660
        },
        {
            lat: -3.513,
            lng: 110.176
        },
        {
            lat: -3.497,
            lng: 110.686
        },
        {
            lat: -3.518,
            lng: 111.192
        },
        {
            lat: -3.576,
            lng: 111.692
        },
        {
            lat: -3.673,
            lng: 112.188
        },
        {
            lat: -3.812,
            lng: 112.680
        },
        {
            lat: -3.892,
            lng: 112.897
        },
    ]
};

export const beam3gain = {
    "c1": '61',
    "c2": '59',
    "c3": '57',
};

export const beam3_gnt = {
    c1: [{
            lat: -3.464,
            lng: 111.926
        },
        {
            lat: -3.520,
            lng: 112.194
        },
        {
            lat: -3.666,
            lng: 112.685
        },
        {
            lat: -3.858,
            lng: 113.171
        },
        {
            lat: -3.890,
            lng: 113.237
        },
        {
            lat: -4.101,
            lng: 113.652
        },
        {
            lat: -4.319,
            lng: 113.999
        },
        {
            lat: -4.407,
            lng: 114.128
        },
        {
            lat: -4.748,
            lng: 114.553
        },
        {
            lat: -4.788,
            lng: 114.597
        },
        {
            lat: -5.179,
            lng: 114.980
        },
        {
            lat: -5.271,
            lng: 115.059
        },
        {
            lat: -5.610,
            lng: 115.319
        },
        {
            lat: -5.907,
            lng: 115.508
        },
        {
            lat: -6.041,
            lng: 115.588
        },
        {
            lat: -6.474,
            lng: 115.794
        },
        {
            lat: -6.875,
            lng: 115.935
        },
        {
            lat: -6.907,
            lng: 115.946
        },
        {
            lat: -7.341,
            lng: 116.048
        },
        {
            lat: -7.776,
            lng: 116.102
        },
        {
            lat: -8.212,
            lng: 116.110
        },
        {
            lat: -8.650,
            lng: 116.073
        },
        {
            lat: -9.089,
            lng: 115.992
        },
        {
            lat: -9.529,
            lng: 115.865
        },
        {
            lat: -9.892,
            lng: 115.720
        },
        {
            lat: -9.971,
            lng: 115.688
        },
        {
            lat: -10.415,
            lng: 115.460
        },
        {
            lat: -10.861,
            lng: 115.172
        },
        {
            lat: -10.901,
            lng: 115.143
        },
        {
            lat: -11.310,
            lng: 114.820
        },
        {
            lat: -11.564,
            lng: 114.585
        },
        {
            lat: -11.761,
            lng: 114.387
        },
        {
            lat: -12.072,
            lng: 114.034
        },
        {
            lat: -12.216,
            lng: 113.850
        },
        {
            lat: -12.475,
            lng: 113.488
        },
        {
            lat: -12.675,
            lng: 113.161
        },
        {
            lat: -12.800,
            lng: 112.944
        },
        {
            lat: -13.062,
            lng: 112.403
        },
        {
            lat: -13.143,
            lng: 112.195
        },
        {
            lat: -13.269,
            lng: 111.863
        },
        {
            lat: -13.428,
            lng: 111.325
        },
        {
            lat: -13.541,
            lng: 110.789
        },
        {
            lat: -13.612,
            lng: 110.254
        },
        {
            lat: -13.642,
            lng: 109.721
        },
        {
            lat: -13.632,
            lng: 109.190
        },
        {
            lat: -13.582,
            lng: 108.662
        },
        {
            lat: -13.490,
            lng: 108.136
        },
        {
            lat: -13.352,
            lng: 107.615
        },
        {
            lat: -13.248,
            lng: 107.321
        },
        {
            lat: -13.166,
            lng: 107.097
        },
        {
            lat: -12.927,
            lng: 106.584
        },
        {
            lat: -12.816,
            lng: 106.394
        },
        {
            lat: -12.622,
            lng: 106.079
        },
        {
            lat: -12.379,
            lng: 105.751
        },
        {
            lat: -12.239,
            lng: 105.582
        },
        {
            lat: -11.938,
            lng: 105.263
        },
        {
            lat: -11.756,
            lng: 105.097
        },
        {
            lat: -11.495,
            lng: 104.882
        },
        {
            lat: -11.122,
            lng: 104.630
        },
        {
            lat: -11.050,
            lng: 104.585
        },
        {
            lat: -10.605,
            lng: 104.358
        },
        {
            lat: -10.184,
            lng: 104.201
        },
        {
            lat: -10.160,
            lng: 104.192
        },
        {
            lat: -9.714,
            lng: 104.078
        },
        {
            lat: -9.268,
            lng: 104.015
        },
        {
            lat: -8.822,
            lng: 104.000
        },
        {
            lat: -8.375,
            lng: 104.030
        },
        {
            lat: -7.929,
            lng: 104.107
        },
        {
            lat: -7.483,
            lng: 104.233
        },
        {
            lat: -7.037,
            lng: 104.412
        },
        {
            lat: -6.639,
            lng: 104.623
        },
        {
            lat: -6.592,
            lng: 104.648
        },
        {
            lat: -6.146,
            lng: 104.941
        },
        {
            lat: -5.750,
            lng: 105.258
        },
        {
            lat: -5.701,
            lng: 105.298
        },
        {
            lat: -5.257,
            lng: 105.730
        },
        {
            lat: -5.144,
            lng: 105.854
        },
        {
            lat: -4.812,
            lng: 106.254
        },
        {
            lat: -4.682,
            lng: 106.430
        },
        {
            lat: -4.368,
            lng: 106.907
        },
        {
            lat: -4.317,
            lng: 106.992
        },
        {
            lat: -4.027,
            lng: 107.542
        },
        {
            lat: -3.925,
            lng: 107.777
        },
        {
            lat: -3.796,
            lng: 108.084
        },
        {
            lat: -3.617,
            lng: 108.618
        },
        {
            lat: -3.485,
            lng: 109.145
        },
        {
            lat: -3.479,
            lng: 109.177
        },
        {
            lat: -3.392,
            lng: 109.667
        },
        {
            lat: -3.340,
            lng: 110.182
        },
        {
            lat: -3.327,
            lng: 110.692
        },
        {
            lat: -3.352,
            lng: 111.198
        },
        {
            lat: -3.416,
            lng: 111.698
        },
        {
            lat: -3.464,
            lng: 111.926
        },
    ],
    c2: [{
            lat: -3.026,
            lng: 112.774
        },
        {
            lat: -3.172,
            lng: 113.195
        },
        {
            lat: -3.386,
            lng: 113.678
        },
        {
            lat: -3.454,
            lng: 113.806
        },
        {
            lat: -3.647,
            lng: 114.156
        },
        {
            lat: -3.883,
            lng: 114.513
        },
        {
            lat: -3.966,
            lng: 114.630
        },
        {
            lat: -4.312,
            lng: 115.051
        },
        {
            lat: -4.355,
            lng: 115.097
        },
        {
            lat: -4.742,
            lng: 115.478
        },
        {
            lat: -4.835,
            lng: 115.558
        },
        {
            lat: -5.173,
            lng: 115.827
        },
        {
            lat: -5.443,
            lng: 116.009
        },
        {
            lat: -5.604,
            lng: 116.112
        },
        {
            lat: -6.035,
            lng: 116.340
        },
        {
            lat: -6.284,
            lng: 116.444
        },
        {
            lat: -6.467,
            lng: 116.520
        },
        {
            lat: -6.900,
            lng: 116.654
        },
        {
            lat: -7.334,
            lng: 116.742
        },
        {
            lat: -7.769,
            lng: 116.787
        },
        {
            lat: -8.205,
            lng: 116.792
        },
        {
            lat: -8.642,
            lng: 116.758
        },
        {
            lat: -9.081,
            lng: 116.686
        },
        {
            lat: -9.520,
            lng: 116.573
        },
        {
            lat: -9.962,
            lng: 116.416
        },
        {
            lat: -10.405,
            lng: 116.211
        },
        {
            lat: -10.516,
            lng: 116.149
        },
        {
            lat: -10.850,
            lng: 115.962
        },
        {
            lat: -11.297,
            lng: 115.658
        },
        {
            lat: -11.393,
            lng: 115.583
        },
        {
            lat: -11.746,
            lng: 115.295
        },
        {
            lat: -12.032,
            lng: 115.028
        },
        {
            lat: -12.198,
            lng: 114.860
        },
        {
            lat: -12.538,
            lng: 114.478
        },
        {
            lat: -12.654,
            lng: 114.334
        },
        {
            lat: -12.952,
            lng: 113.931
        },
        {
            lat: -13.114,
            lng: 113.679
        },
        {
            lat: -13.293,
            lng: 113.387
        },
        {
            lat: -13.571,
            lng: 112.844
        },
        {
            lat: -13.581,
            lng: 112.822
        },
        {
            lat: -13.802,
            lng: 112.302
        },
        {
            lat: -13.984,
            lng: 111.762
        },
        {
            lat: -14.061,
            lng: 111.465
        },
        {
            lat: -14.125,
            lng: 111.223
        },
        {
            lat: -14.228,
            lng: 110.684
        },
        {
            lat: -14.293,
            lng: 110.147
        },
        {
            lat: -14.321,
            lng: 109.612
        },
        {
            lat: -14.314,
            lng: 109.079
        },
        {
            lat: -14.269,
            lng: 108.547
        },
        {
            lat: -14.186,
            lng: 108.018
        },
        {
            lat: -14.145,
            lng: 107.837
        },
        {
            lat: -14.067,
            lng: 107.492
        },
        {
            lat: -13.907,
            lng: 106.969
        },
        {
            lat: -13.722,
            lng: 106.508
        },
        {
            lat: -13.698,
            lng: 106.450
        },
        {
            lat: -13.442,
            lng: 105.936
        },
        {
            lat: -13.287,
            lng: 105.681
        },
        {
            lat: -13.123,
            lng: 105.429
        },
        {
            lat: -12.847,
            lng: 105.070
        },
        {
            lat: -12.730,
            lng: 104.932
        },
        {
            lat: -12.405,
            lng: 104.592
        },
        {
            lat: -12.245,
            lng: 104.445
        },
        {
            lat: -11.961,
            lng: 104.208
        },
        {
            lat: -11.629,
            lng: 103.975
        },
        {
            lat: -11.516,
            lng: 103.900
        },
        {
            lat: -11.070,
            lng: 103.655
        },
        {
            lat: -10.785,
            lng: 103.534
        },
        {
            lat: -10.623,
            lng: 103.466
        },
        {
            lat: -10.177,
            lng: 103.324
        },
        {
            lat: -9.730,
            lng: 103.232
        },
        {
            lat: -9.283,
            lng: 103.184
        },
        {
            lat: -9.228,
            lng: 103.183
        },
        {
            lat: -8.836,
            lng: 103.173
        },
        {
            lat: -8.389,
            lng: 103.203
        },
        {
            lat: -7.942,
            lng: 103.276
        },
        {
            lat: -7.495,
            lng: 103.395
        },
        {
            lat: -7.485,
            lng: 103.399
        },
        {
            lat: -7.049,
            lng: 103.556
        },
        {
            lat: -6.603,
            lng: 103.768
        },
        {
            lat: -6.157,
            lng: 104.033
        },
        {
            lat: -6.045,
            lng: 104.111
        },
        {
            lat: -5.711,
            lng: 104.349
        },
        {
            lat: -5.266,
            lng: 104.726
        },
        {
            lat: -5.254,
            lng: 104.738
        },
        {
            lat: -4.822,
            lng: 105.169
        },
        {
            lat: -4.676,
            lng: 105.334
        },
        {
            lat: -4.378,
            lng: 105.698
        },
        {
            lat: -4.219,
            lng: 105.911
        },
        {
            lat: -3.935,
            lng: 106.340
        },
        {
            lat: -3.850,
            lng: 106.476
        },
        {
            lat: -3.550,
            lng: 107.030
        },
        {
            lat: -3.491,
            lng: 107.155
        },
        {
            lat: -3.302,
            lng: 107.575
        },
        {
            lat: -3.105,
            lng: 108.113
        },
        {
            lat: -3.048,
            lng: 108.305
        },
        {
            lat: -2.948,
            lng: 108.644
        },
        {
            lat: -2.830,
            lng: 109.169
        },
        {
            lat: -2.750,
            lng: 109.689
        },
        {
            lat: -2.706,
            lng: 110.203
        },
        {
            lat: -2.696,
            lng: 110.713
        },
        {
            lat: -2.719,
            lng: 111.218
        },
        {
            lat: -2.776,
            lng: 111.719
        },
        {
            lat: -2.870,
            lng: 112.215
        },
        {
            lat: -3.003,
            lng: 112.707
        },
        {
            lat: -3.026,
            lng: 112.774
        },
    ],
    c3: [{
            lat: -2.167,
            lng: 111.113
        },
        {
            lat: -2.172,
            lng: 111.232
        },
        {
            lat: -2.224,
            lng: 111.733
        },
        {
            lat: -2.309,
            lng: 112.230
        },
        {
            lat: -2.430,
            lng: 112.722
        },
        {
            lat: -2.591,
            lng: 113.211
        },
        {
            lat: -2.592,
            lng: 113.213
        },
        {
            lat: -2.782,
            lng: 113.695
        },
        {
            lat: -3.020,
            lng: 114.172
        },
        {
            lat: -3.022,
            lng: 114.175
        },
        {
            lat: -3.301,
            lng: 114.651
        },
        {
            lat: -3.449,
            lng: 114.865
        },
        {
            lat: -3.638,
            lng: 115.122
        },
        {
            lat: -3.878,
            lng: 115.409
        },
        {
            lat: -4.043,
            lng: 115.588
        },
        {
            lat: -4.308,
            lng: 115.848
        },
        {
            lat: -4.535,
            lng: 116.047
        },
        {
            lat: -4.738,
            lng: 116.212
        },
        {
            lat: -5.144,
            lng: 116.497
        },
        {
            lat: -5.168,
            lng: 116.513
        },
        {
            lat: -5.599,
            lng: 116.767
        },
        {
            lat: -5.956,
            lng: 116.934
        },
        {
            lat: -6.030,
            lng: 116.969
        },
        {
            lat: -6.462,
            lng: 117.135
        },
        {
            lat: -6.895,
            lng: 117.255
        },
        {
            lat: -7.329,
            lng: 117.333
        },
        {
            lat: -7.334,
            lng: 117.334
        },
        {
            lat: -7.763,
            lng: 117.378
        },
        {
            lat: -8.199,
            lng: 117.383
        },
        {
            lat: -8.636,
            lng: 117.352
        },
        {
            lat: -9.074,
            lng: 117.283
        },
        {
            lat: -9.426,
            lng: 117.196
        },
        {
            lat: -9.513,
            lng: 117.175
        },
        {
            lat: -9.954,
            lng: 117.034
        },
        {
            lat: -10.396,
            lng: 116.850
        },
        {
            lat: -10.840,
            lng: 116.619
        },
        {
            lat: -10.873,
            lng: 116.599
        },
        {
            lat: -11.286,
            lng: 116.351
        },
        {
            lat: -11.721,
            lng: 116.037
        },
        {
            lat: -11.735,
            lng: 116.027
        },
        {
            lat: -12.185,
            lng: 115.652
        },
        {
            lat: -12.365,
            lng: 115.483
        },
        {
            lat: -12.639,
            lng: 115.208
        },
        {
            lat: -12.886,
            lng: 114.933
        },
        {
            lat: -13.096,
            lng: 114.677
        },
        {
            lat: -13.318,
            lng: 114.386
        },
        {
            lat: -13.557,
            lng: 114.029
        },
        {
            lat: -13.677,
            lng: 113.840
        },
        {
            lat: -13.977,
            lng: 113.296
        },
        {
            lat: -14.024,
            lng: 113.197
        },
        {
            lat: -14.230,
            lng: 112.753
        },
        {
            lat: -14.434,
            lng: 112.211
        },
        {
            lat: -14.503,
            lng: 111.986
        },
        {
            lat: -14.601,
            lng: 111.670
        },
        {
            lat: -14.731,
            lng: 111.129
        },
        {
            lat: -14.823,
            lng: 110.589
        },
        {
            lat: -14.881,
            lng: 110.051
        },
        {
            lat: -14.907,
            lng: 109.513
        },
        {
            lat: -14.900,
            lng: 108.978
        },
        {
            lat: -14.862,
            lng: 108.443
        },
        {
            lat: -14.789,
            lng: 107.911
        },
        {
            lat: -14.679,
            lng: 107.381
        },
        {
            lat: -14.618,
            lng: 107.162
        },
        {
            lat: -14.535,
            lng: 106.853
        },
        {
            lat: -14.352,
            lng: 106.329
        },
        {
            lat: -14.192,
            lng: 105.964
        },
        {
            lat: -14.123,
            lng: 105.810
        },
        {
            lat: -13.847,
            lng: 105.296
        },
        {
            lat: -13.756,
            lng: 105.151
        },
        {
            lat: -13.512,
            lng: 104.788
        },
        {
            lat: -13.315,
            lng: 104.537
        },
        {
            lat: -13.102,
            lng: 104.290
        },
        {
            lat: -12.872,
            lng: 104.052
        },
        {
            lat: -12.600,
            lng: 103.804
        },
        {
            lat: -12.427,
            lng: 103.658
        },
        {
            lat: -11.981,
            lng: 103.339
        },
        {
            lat: -11.973,
            lng: 103.334
        },
        {
            lat: -11.534,
            lng: 103.073
        },
        {
            lat: -11.141,
            lng: 102.892
        },
        {
            lat: -11.087,
            lng: 102.866
        },
        {
            lat: -10.639,
            lng: 102.698
        },
        {
            lat: -10.191,
            lng: 102.580
        },
        {
            lat: -9.808,
            lng: 102.515
        },
        {
            lat: -9.744,
            lng: 102.503
        },
        {
            lat: -9.296,
            lng: 102.458
        },
        {
            lat: -8.848,
            lng: 102.451
        },
        {
            lat: -8.400,
            lng: 102.481
        },
        {
            lat: -7.953,
            lng: 102.549
        },
        {
            lat: -7.506,
            lng: 102.659
        },
        {
            lat: -7.059,
            lng: 102.815
        },
        {
            lat: -6.909,
            lng: 102.882
        },
        {
            lat: -6.612,
            lng: 103.011
        },
        {
            lat: -6.166,
            lng: 103.251
        },
        {
            lat: -5.720,
            lng: 103.546
        },
        {
            lat: -5.685,
            lng: 103.572
        },
        {
            lat: -5.275,
            lng: 103.884
        },
        {
            lat: -4.923,
            lng: 104.199
        },
        {
            lat: -4.830,
            lng: 104.284
        },
        {
            lat: -4.386,
            lng: 104.748
        },
        {
            lat: -4.342,
            lng: 104.799
        },
        {
            lat: -3.942,
            lng: 105.293
        },
        {
            lat: -3.878,
            lng: 105.380
        },
        {
            lat: -3.499,
            lng: 105.944
        },
        {
            lat: -3.497,
            lng: 105.948
        },
        {
            lat: -3.177,
            lng: 106.506
        },
        {
            lat: -3.057,
            lng: 106.752
        },
        {
            lat: -2.910,
            lng: 107.056
        },
        {
            lat: -2.692,
            lng: 107.598
        },
        {
            lat: -2.614,
            lng: 107.831
        },
        {
            lat: -2.512,
            lng: 108.133
        },
        {
            lat: -2.370,
            lng: 108.662
        },
        {
            lat: -2.265,
            lng: 109.186
        },
        {
            lat: -2.195,
            lng: 109.704
        },
        {
            lat: -2.170,
            lng: 110.040
        },
        {
            lat: -2.156,
            lng: 110.218
        },
        {
            lat: -2.148,
            lng: 110.727
        },
        {
            lat: -2.167,
            lng: 111.113
        },
    ]
};

export const beam3_gntgain = {
    "c1": '8',
    "c2": '6',
    "c3": '4',
};
