
    // downlink
    export var utelsat = {
        c1: [{
                lat: -50.148,
                lng: 146.801
            },
            {
                lat: -49.962,
                lng: 145.301
            },
            {
                lat: -49.877,
                lng: 144.656
            },
            {
                lat: -49.754,
                lng: 143.748
            },
            {
                lat: -49.573,
                lng: 142.473
            },
            {
                lat: -49.428,
                lng: 141.502
            },
            {
                lat: -49.302,
                lng: 140.687
            },
            {
                lat: -49.083,
                lng: 139.332
            },
            {
                lat: -48.891,
                lng: 138.198
            },
            {
                lat: -48.716,
                lng: 137.208
            },
            {
                lat: -48.554,
                lng: 136.318
            },
            {
                lat: -48.401,
                lng: 135.505
            },
            {
                lat: -48.256,
                lng: 134.752
            },
            {
                lat: -48.059,
                lng: 133.842
            },
            {
                lat: -47.870,
                lng: 133.000
            },
            {
                lat: -47.655,
                lng: 132.080
            },
            {
                lat: -47.449,
                lng: 131.224
            },
            {
                lat: -47.249,
                lng: 130.422
            },
            {
                lat: -47.056,
                lng: 129.665
            },
            {
                lat: -46.868,
                lng: 128.948
            },
            {
                lat: -46.685,
                lng: 128.265
            },
            {
                lat: -46.330,
                lng: 126.988
            },
            {
                lat: -46.196,
                lng: 126.522
            },
            {
                lat: -45.839,
                lng: 125.387
            },
            {
                lat: -45.493,
                lng: 124.329
            },
            {
                lat: -45.158,
                lng: 123.336
            },
            {
                lat: -44.831,
                lng: 122.399
            },
            {
                lat: -44.599,
                lng: 121.729
            },
            {
                lat: -44.316,
                lng: 120.954
            },
            {
                lat: -44.039,
                lng: 120.211
            },
            {
                lat: -43.767,
                lng: 119.498
            },
            {
                lat: -43.500,
                lng: 118.811
            },
            {
                lat: -43.174,
                lng: 118.034
            },
            {
                lat: -42.721,
                lng: 116.896
            },
            {
                lat: -42.280,
                lng: 115.821
            },
            {
                lat: -41.850,
                lng: 114.852
            },
            {
                lat: -41.475,
                lng: 114.010
            },
            {
                lat: -41.106,
                lng: 113.201
            },
            {
                lat: -40.616,
                lng: 112.205
            },
            {
                lat: -40.261,
                lng: 111.497
            },
            {
                lat: -39.912,
                lng: 110.813
            },
            {
                lat: -39.439,
                lng: 109.779
            },
            {
                lat: -39.002,
                lng: 109.142
            },
            {
                lat: -38.572,
                lng: 108.528
            },
            {
                lat: -38.362,
                lng: 108.241
            },
            {
                lat: -37.826,
                lng: 107.370
            },
            {
                lat: -37.299,
                lng: 106.535
            },
            {
                lat: -37.208,
                lng: 106.426
            },
            {
                lat: -36.745,
                lng: 105.826
            },
            {
                lat: -36.289,
                lng: 105.245
            },
            {
                lat: -35.803,
                lng: 104.777
            },
            {
                lat: -35.324,
                lng: 104.324
            },
            {
                lat: -34.901,
                lng: 103.973
            },
            {
                lat: -34.386,
                lng: 103.565
            },
            {
                lat: -33.474,
                lng: 102.952
            },
            {
                lat: -32.592,
                lng: 102.624
            },
            {
                lat: -31.722,
                lng: 102.278
            },
            {
                lat: -31.395,
                lng: 102.257
            },
            {
                lat: -30.878,
                lng: 102.202
            },
            {
                lat: -30.042,
                lng: 102.068
            },
            {
                lat: -29.222,
                lng: 102.057
            },
            {
                lat: -28.602,
                lng: 102.055
            },
            {
                lat: -28.413,
                lng: 102.057
            },
            {
                lat: -27.615,
                lng: 102.129
            },
            {
                lat: -26.822,
                lng: 102.119
            },
            {
                lat: -26.545,
                lng: 102.217
            },
            {
                lat: -26.050,
                lng: 102.412
            },
            {
                lat: -25.272,
                lng: 102.407
            },
            {
                lat: -25.003,
                lng: 102.599
            },
            {
                lat: -24.523,
                lng: 102.923
            },
            {
                lat: -24.125,
                lng: 103.227
            },
            {
                lat: -23.786,
                lng: 103.563
            },
            {
                lat: -23.315,
                lng: 103.884
            },
            {
                lat: -23.047,
                lng: 104.047
            },
            {
                lat: -22.533,
                lng: 104.559
            },
            {
                lat: -22.319,
                lng: 104.670
            },
            {
                lat: -21.575,
                lng: 104.786
            },
            {
                lat: -20.891,
                lng: 104.965
            },
            {
                lat: -20.839,
                lng: 104.972
            },
            {
                lat: -20.096,
                lng: 104.870
            },
            {
                lat: -19.359,
                lng: 104.779
            },
            {
                lat: -18.620,
                lng: 104.487
            },
            {
                lat: -17.886,
                lng: 104.184
            },
            {
                lat: -17.654,
                lng: 104.047
            },
            {
                lat: -17.153,
                lng: 103.765
            },
            {
                lat: -16.423,
                lng: 103.267
            },
            {
                lat: -15.697,
                lng: 102.718
            },
            {
                lat: -15.676,
                lng: 102.700
            },
            {
                lat: -14.974,
                lng: 102.088
            },
            {
                lat: -14.377,
                lng: 101.580
            },
            {
                lat: -14.256,
                lng: 101.477
            },
            {
                lat: -13.544,
                lng: 100.903
            },
            {
                lat: -13.027,
                lng: 100.505
            },
            {
                lat: -12.838,
                lng: 100.364
            },
            {
                lat: -12.135,
                lng: 99.841
            },
            {
                lat: -11.608,
                lng: 99.473
            },
            {
                lat: -11.438,
                lng: 99.352
            },
            {
                lat: -10.745,
                lng: 98.916
            },
            {
                lat: -10.176,
                lng: 98.487
            },
            {
                lat: -10.054,
                lng: 98.395
            },
            {
                lat: -9.367,
                lng: 97.874
            },
            {
                lat: -8.902,
                lng: 97.562
            },
            {
                lat: -8.684,
                lng: 97.416
            },
            {
                lat: -8.001,
                lng: 96.816
            },
            {
                lat: -7.840,
                lng: 96.690
            },
            {
                lat: -7.323,
                lng: 96.274
            },
            {
                lat: -6.843,
                lng: 95.847
            },
            {
                lat: -6.646,
                lng: 95.662
            },
            {
                lat: -5.980,
                lng: 95.035
            },
            {
                lat: -5.972,
                lng: 95.027
            },
            {
                lat: -5.300,
                lng: 94.328
            },
            {
                lat: -5.220,
                lng: 94.246
            },
            {
                lat: -4.631,
                lng: 93.603
            },
            {
                lat: -4.508,
                lng: 93.473
            },
            {
                lat: -3.963,
                lng: 92.877
            },
            {
                lat: -3.815,
                lng: 92.714
            },
            {
                lat: -3.298,
                lng: 92.121
            },
            {
                lat: -3.143,
                lng: 91.967
            },
            {
                lat: -2.636,
                lng: 91.454
            },
            {
                lat: -2.422,
                lng: 91.229
            },
            {
                lat: -1.974,
                lng: 90.742
            },
            {
                lat: -1.696,
                lng: 90.502
            },
            {
                lat: -1.315,
                lng: 90.170
            },
            {
                lat: -0.839,
                lng: 89.785
            },
            {
                lat: -0.657,
                lng: 89.639
            },
            {
                lat: 0.000,
                lng: 89.176
            },
            {
                lat: 0.166,
                lng: 89.077
            },
            {
                lat: 0.656,
                lng: 88.784
            },
            {
                lat: 1.312,
                lng: 88.412
            },
            {
                lat: 1.385,
                lng: 88.383
            },
            {
                lat: 1.968,
                lng: 88.139
            },
            {
                lat: 2.624,
                lng: 87.864
            },
            {
                lat: 3.281,
                lng: 87.755
            },
            {
                lat: 3.411,
                lng: 87.718
            },
            {
                lat: 3.938,
                lng: 87.553
            },
            {
                lat: 4.596,
                lng: 87.548
            },
            {
                lat: 5.255,
                lng: 87.508
            },
            {
                lat: 5.916,
                lng: 87.548
            },
            {
                lat: 6.578,
                lng: 87.619
            },
            {
                lat: 7.242,
                lng: 87.686
            },
            {
                lat: 7.907,
                lng: 87.764
            },
            {
                lat: 8.573,
                lng: 87.798
            },
            {
                lat: 9.241,
                lng: 87.790
            },
            {
                lat: 9.911,
                lng: 87.784
            },
            {
                lat: 10.582,
                lng: 87.679
            },
            {
                lat: 11.256,
                lng: 87.633
            },
            {
                lat: 11.930,
                lng: 87.471
            },
            {
                lat: 12.066,
                lng: 87.442
            },
            {
                lat: 12.607,
                lng: 87.311
            },
            {
                lat: 13.287,
                lng: 87.169
            },
            {
                lat: 13.970,
                lng: 87.023
            },
            {
                lat: 14.477,
                lng: 86.929
            },
            {
                lat: 14.655,
                lng: 86.890
            },
            {
                lat: 15.344,
                lng: 86.764
            },
            {
                lat: 16.037,
                lng: 86.655
            },
            {
                lat: 16.733,
                lng: 86.512
            },
            {
                lat: 17.018,
                lng: 86.454
            },
            {
                lat: 17.432,
                lng: 86.360
            },
            {
                lat: 18.134,
                lng: 86.172
            },
            {
                lat: 18.759,
                lng: 85.913
            },
            {
                lat: 18.839,
                lng: 85.873
            },
            {
                lat: 19.548,
                lng: 85.553
            },
            {
                lat: 20.056,
                lng: 85.328
            },
            {
                lat: 20.261,
                lng: 85.227
            },
            {
                lat: 20.979,
                lng: 84.905
            },
            {
                lat: 21.318,
                lng: 84.738
            },
            {
                lat: 21.702,
                lng: 84.547
            },
            {
                lat: 22.434,
                lng: 84.403
            },
            {
                lat: 23.173,
                lng: 84.315
            },
            {
                lat: 23.563,
                lng: 84.266
            },
            {
                lat: 23.918,
                lng: 84.225
            },
            {
                lat: 24.673,
                lng: 84.295
            },
            {
                lat: 25.435,
                lng: 84.395
            },
            {
                lat: 26.095,
                lng: 84.603
            },
            {
                lat: 26.209,
                lng: 84.639
            },
            {
                lat: 26.990,
                lng: 84.877
            },
            {
                lat: 27.784,
                lng: 85.280
            },
            {
                lat: 28.587,
                lng: 85.652
            },
            {
                lat: 28.831,
                lng: 85.814
            },
            {
                lat: 29.405,
                lng: 86.202
            },
            {
                lat: 30.233,
                lng: 86.736
            },
            {
                lat: 30.476,
                lng: 86.925
            },
            {
                lat: 31.077,
                lng: 87.423
            },
            {
                lat: 31.757,
                lng: 88.022
            },
            {
                lat: 31.938,
                lng: 88.206
            },
            {
                lat: 32.362,
                lng: 88.654
            },
            {
                lat: 32.791,
                lng: 89.111
            },
            {
                lat: 32.817,
                lng: 89.142
            },
            {
                lat: 33.187,
                lng: 89.653
            },
            {
                lat: 33.561,
                lng: 90.173
            },
            {
                lat: 33.727,
                lng: 90.471
            },
            {
                lat: 34.198,
                lng: 91.232
            },
            {
                lat: 34.666,
                lng: 92.037
            },
            {
                lat: 34.802,
                lng: 92.312
            },
            {
                lat: 35.219,
                lng: 93.360
            },
            {
                lat: 35.643,
                lng: 94.435
            },
            {
                lat: 35.670,
                lng: 94.498
            },
            {
                lat: 35.914,
                lng: 95.032
            },
            {
                lat: 36.160,
                lng: 95.574
            },
            {
                lat: 36.414,
                lng: 96.155
            },
            {
                lat: 36.670,
                lng: 96.745
            },
            {
                lat: 36.695,
                lng: 96.796
            },
            {
                lat: 37.055,
                lng: 97.421
            },
            {
                lat: 37.419,
                lng: 98.061
            },
            {
                lat: 37.718,
                lng: 98.521
            },
            {
                lat: 38.048,
                lng: 99.026
            },
            {
                lat: 38.380,
                lng: 99.542
            },
            {
                lat: 38.756,
                lng: 100.056
            },
            {
                lat: 39.275,
                lng: 100.700
            },
            {
                lat: 39.801,
                lng: 101.367
            },
            {
                lat: 39.807,
                lng: 101.373
            },
            {
                lat: 40.348,
                lng: 102.121
            },
            {
                lat: 40.898,
                lng: 102.900
            },
            {
                lat: 41.266,
                lng: 103.377
            },
            {
                lat: 42.011,
                lng: 104.307
            },
            {
                lat: 42.514,
                lng: 105.039
            },
            {
                lat: 43.026,
                lng: 105.801
            },
            {
                lat: 43.180,
                lng: 106.031
            },
            {
                lat: 43.786,
                lng: 106.995
            },
            {
                lat: 44.407,
                lng: 108.015
            },
            {
                lat: 44.631,
                lng: 108.390
            },
            {
                lat: 45.159,
                lng: 109.304
            },
            {
                lat: 45.698,
                lng: 110.268
            },
            {
                lat: 46.167,
                lng: 111.235
            },
            {
                lat: 46.634,
                lng: 112.239
            },
            {
                lat: 47.112,
                lng: 113.299
            },
            {
                lat: 47.549,
                lng: 114.282
            },
            {
                lat: 47.818,
                lng: 114.926
            },
            {
                lat: 48.092,
                lng: 115.594
            },
            {
                lat: 48.370,
                lng: 116.287
            },
            {
                lat: 48.654,
                lng: 117.007
            },
            {
                lat: 48.935,
                lng: 117.775
            },
            {
                lat: 49.237,
                lng: 118.666
            },
            {
                lat: 49.547,
                lng: 119.604
            },
            {
                lat: 49.866,
                lng: 120.593
            },
            {
                lat: 50.195,
                lng: 121.643
            },
            {
                lat: 50.483,
                lng: 122.644
            },
            {
                lat: 50.699,
                lng: 123.448
            },
            {
                lat: 50.922,
                lng: 124.292
            },
            {
                lat: 51.151,
                lng: 125.183
            },
            {
                lat: 51.389,
                lng: 126.127
            },
            {
                lat: 51.665,
                lng: 127.349
            },
            {
                lat: 51.956,
                lng: 128.680
            },
            {
                lat: 52.268,
                lng: 130.154
            },
            {
                lat: 52.433,
                lng: 130.958
            },
            {
                lat: 52.605,
                lng: 131.819
            },
            {
                lat: 52.794,
                lng: 132.868
            },
            {
                lat: 52.998,
                lng: 134.028
            },
            {
                lat: 53.221,
                lng: 135.337
            },
            {
                lat: 53.474,
                lng: 136.869
            },
            {
                lat: 53.654,
                lng: 137.978
            },
            {
                lat: 53.862,
                lng: 139.304
            },
            {
                lat: 53.983,
                lng: 140.101
            },
            {
                lat: 54.124,
                lng: 141.050
            },
            {
                lat: 54.303,
                lng: 142.296
            },
            {
                lat: 54.426,
                lng: 143.182
            },
            {
                lat: 54.511,
                lng: 143.810
            },
            {
                lat: 54.690,
                lng: 145.175
            },
        ],
        c2: [{
                lat: -48.682,
                lng: 147.110
            },
            {
                lat: -48.549,
                lng: 146.006
            },
            {
                lat: -48.484,
                lng: 145.490
            },
            {
                lat: -48.389,
                lng: 144.761
            },
            {
                lat: -48.251,
                lng: 143.734
            },
            {
                lat: -48.045,
                lng: 142.291
            },
            {
                lat: -47.880,
                lng: 141.193
            },
            {
                lat: -47.735,
                lng: 140.273
            },
            {
                lat: -47.498,
                lng: 138.849
            },
            {
                lat: -47.287,
                lng: 137.648
            },
            {
                lat: -47.065,
                lng: 136.465
            },
            {
                lat: -46.859,
                lng: 135.412
            },
            {
                lat: -46.664,
                lng: 134.456
            },
            {
                lat: -46.479,
                lng: 133.576
            },
            {
                lat: -46.302,
                lng: 132.757
            },
            {
                lat: -46.131,
                lng: 131.988
            },
            {
                lat: -45.966,
                lng: 131.263
            },
            {
                lat: -45.806,
                lng: 130.575
            },
            {
                lat: -45.563,
                lng: 129.634
            },
            {
                lat: -45.329,
                lng: 128.752
            },
            {
                lat: -45.064,
                lng: 127.846
            },
            {
                lat: -44.808,
                lng: 126.993
            },
            {
                lat: -44.557,
                lng: 126.185
            },
            {
                lat: -44.313,
                lng: 125.417
            },
            {
                lat: -44.007,
                lng: 124.449
            },
            {
                lat: -43.710,
                lng: 123.534
            },
            {
                lat: -43.392,
                lng: 122.559
            },
            {
                lat: -43.081,
                lng: 121.634
            },
            {
                lat: -42.661,
                lng: 120.421
            },
            {
                lat: -42.253,
                lng: 119.280
            },
            {
                lat: -41.904,
                lng: 118.451
            },
            {
                lat: -41.437,
                lng: 117.423
            },
            {
                lat: -40.980,
                lng: 116.451
            },
            {
                lat: -40.619,
                lng: 115.529
            },
            {
                lat: -40.179,
                lng: 114.551
            },
            {
                lat: -39.748,
                lng: 113.618
            },
            {
                lat: -39.468,
                lng: 113.045
            },
            {
                lat: -39.025,
                lng: 112.117
            },
            {
                lat: -38.589,
                lng: 111.229
            },
            {
                lat: -38.301,
                lng: 110.772
            },
            {
                lat: -37.904,
                lng: 110.121
            },
            {
                lat: -37.512,
                lng: 109.493
            },
            {
                lat: -37.155,
                lng: 108.969
            },
            {
                lat: -36.803,
                lng: 108.460
            },
            {
                lat: -36.479,
                lng: 108.029
            },
            {
                lat: -35.976,
                lng: 107.359
            },
            {
                lat: -35.480,
                lng: 106.713
            },
            {
                lat: -34.975,
                lng: 106.164
            },
            {
                lat: -34.519,
                lng: 105.688
            },
            {
                lat: -33.602,
                lng: 105.081
            },
            {
                lat: -33.143,
                lng: 104.676
            },
            {
                lat: -32.688,
                lng: 104.283
            },
            {
                lat: -31.827,
                lng: 104.141
            },
            {
                lat: -30.957,
                lng: 103.658
            },
            {
                lat: -30.246,
                lng: 103.721
            },
            {
                lat: -30.131,
                lng: 103.738
            },
            {
                lat: -29.297,
                lng: 103.496
            },
            {
                lat: -28.489,
                lng: 103.575
            },
            {
                lat: -27.753,
                lng: 103.634
            },
            {
                lat: -27.689,
                lng: 103.638
            },
            {
                lat: -26.908,
                lng: 103.913
            },
            {
                lat: -26.265,
                lng: 104.007
            },
            {
                lat: -26.126,
                lng: 104.027
            },
            {
                lat: -25.369,
                lng: 104.534
            },
            {
                lat: -25.266,
                lng: 104.585
            },
            {
                lat: -24.619,
                lng: 105.042
            },
            {
                lat: -24.401,
                lng: 105.223
            },
            {
                lat: -23.869,
                lng: 105.434
            },
            {
                lat: -23.335,
                lng: 105.795
            },
            {
                lat: -23.129,
                lng: 105.933
            },
            {
                lat: -22.377,
                lng: 106.036
            },
            {
                lat: -21.630,
                lng: 106.112
            },
            {
                lat: -21.462,
                lng: 106.099
            },
            {
                lat: -20.881,
                lng: 106.034
            },
            {
                lat: -20.135,
                lng: 105.886
            },
            {
                lat: -19.393,
                lng: 105.679
            },
            {
                lat: -18.652,
                lng: 105.378
            },
            {
                lat: -18.063,
                lng: 105.067
            },
            {
                lat: -17.913,
                lng: 104.994
            },
            {
                lat: -17.180,
                lng: 104.585
            },
            {
                lat: -16.448,
                lng: 104.067
            },
            {
                lat: -15.877,
                lng: 103.624
            },
            {
                lat: -15.720,
                lng: 103.506
            },
            {
                lat: -14.994,
                lng: 102.847
            },
            {
                lat: -14.555,
                lng: 102.472
            },
            {
                lat: -14.275,
                lng: 102.235
            },
            {
                lat: -13.561,
                lng: 101.632
            },
            {
                lat: -13.268,
                lng: 101.384
            },
            {
                lat: -12.853,
                lng: 101.047
            },
            {
                lat: -12.149,
                lng: 100.509
            },
            {
                lat: -11.912,
                lng: 100.336
            },
            {
                lat: -11.451,
                lng: 100.007
            },
            {
                lat: -10.756,
                lng: 99.542
            },
            {
                lat: -10.472,
                lng: 99.326
            },
            {
                lat: -10.065,
                lng: 99.014
            },
            {
                lat: -9.377,
                lng: 98.508
            },
            {
                lat: -9.198,
                lng: 98.382
            },
            {
                lat: -8.692,
                lng: 98.029
            },
            {
                lat: -8.069,
                lng: 97.487
            },
            {
                lat: -8.009,
                lng: 97.433
            },
            {
                lat: -7.330,
                lng: 96.908
            },
            {
                lat: -7.003,
                lng: 96.626
            },
            {
                lat: -6.653,
                lng: 96.317
            },
            {
                lat: -6.106,
                lng: 95.800
            },
            {
                lat: -5.978,
                lng: 95.679
            },
            {
                lat: -5.305,
                lng: 95.001
            },
            {
                lat: -5.303,
                lng: 94.999
            },
            {
                lat: -4.635,
                lng: 94.307
            },
            {
                lat: -4.547,
                lng: 94.216
            },
            {
                lat: -3.967,
                lng: 93.598
            },
            {
                lat: -3.827,
                lng: 93.448
            },
            {
                lat: -3.302,
                lng: 92.865
            },
            {
                lat: -3.131,
                lng: 92.693
            },
            {
                lat: -2.638,
                lng: 92.198
            },
            {
                lat: -2.404,
                lng: 91.950
            },
            {
                lat: -1.976,
                lng: 91.496
            },
            {
                lat: -1.661,
                lng: 91.217
            },
            {
                lat: -1.316,
                lng: 90.911
            },
            {
                lat: -0.803,
                lng: 90.494
            },
            {
                lat: -0.658,
                lng: 90.372
            },
            {
                lat: 0.000,
                lng: 89.878
            },
            {
                lat: 0.168,
                lng: 89.782
            },
            {
                lat: 0.657,
                lng: 89.483
            },
            {
                lat: 1.314,
                lng: 89.108
            },
            {
                lat: 1.370,
                lng: 89.084
            },
            {
                lat: 1.970,
                lng: 88.813
            },
            {
                lat: 2.626,
                lng: 88.567
            },
            {
                lat: 3.284,
                lng: 88.454
            },
            {
                lat: 3.435,
                lng: 88.416
            },
            {
                lat: 3.941,
                lng: 88.268
            },
            {
                lat: 4.600,
                lng: 88.233
            },
            {
                lat: 5.259,
                lng: 88.205
            },
            {
                lat: 5.920,
                lng: 88.203
            },
            {
                lat: 6.583,
                lng: 88.254
            },
            {
                lat: 7.246,
                lng: 88.276
            },
            {
                lat: 7.912,
                lng: 88.343
            },
            {
                lat: 8.578,
                lng: 88.337
            },
            {
                lat: 9.247,
                lng: 88.350
            },
            {
                lat: 9.917,
                lng: 88.320
            },
            {
                lat: 10.588,
                lng: 88.243
            },
            {
                lat: 11.262,
                lng: 88.195
            },
            {
                lat: 11.634,
                lng: 88.120
            },
            {
                lat: 11.938,
                lng: 88.054
            },
            {
                lat: 12.616,
                lng: 87.926
            },
            {
                lat: 13.296,
                lng: 87.804
            },
            {
                lat: 13.979,
                lng: 87.652
            },
            {
                lat: 14.235,
                lng: 87.624
            },
            {
                lat: 14.666,
                lng: 87.568
            },
            {
                lat: 15.355,
                lng: 87.432
            },
            {
                lat: 16.048,
                lng: 87.353
            },
            {
                lat: 16.745,
                lng: 87.251
            },
            {
                lat: 17.132,
                lng: 87.191
            },
            {
                lat: 17.445,
                lng: 87.134
            },
            {
                lat: 18.149,
                lng: 86.983
            },
            {
                lat: 18.856,
                lng: 86.778
            },
            {
                lat: 19.201,
                lng: 86.695
            },
            {
                lat: 19.567,
                lng: 86.592
            },
            {
                lat: 20.281,
                lng: 86.292
            },
            {
                lat: 20.701,
                lng: 86.139
            },
            {
                lat: 21.000,
                lng: 86.012
            },
            {
                lat: 21.724,
                lng: 85.716
            },
            {
                lat: 22.416,
                lng: 85.613
            },
            {
                lat: 22.457,
                lng: 85.607
            },
            {
                lat: 23.195,
                lng: 85.436
            },
            {
                lat: 23.941,
                lng: 85.337
            },
            {
                lat: 24.698,
                lng: 85.454
            },
            {
                lat: 25.460,
                lng: 85.514
            },
            {
                lat: 26.235,
                lng: 85.779
            },
            {
                lat: 27.019,
                lng: 86.074
            },
            {
                lat: 27.673,
                lng: 86.404
            },
            {
                lat: 27.815,
                lng: 86.480
            },
            {
                lat: 28.623,
                lng: 86.975
            },
            {
                lat: 29.438,
                lng: 87.531
            },
            {
                lat: 29.443,
                lng: 87.534
            },
            {
                lat: 29.860,
                lng: 87.911
            },
            {
                lat: 30.280,
                lng: 88.294
            },
            {
                lat: 30.625,
                lng: 88.600
            },
            {
                lat: 31.133,
                lng: 89.124
            },
            {
                lat: 31.527,
                lng: 89.647
            },
            {
                lat: 32.014,
                lng: 90.379
            },
            {
                lat: 32.241,
                lng: 90.682
            },
            {
                lat: 32.555,
                lng: 91.199
            },
            {
                lat: 32.872,
                lng: 91.724
            },
            {
                lat: 32.920,
                lng: 91.826
            },
            {
                lat: 33.315,
                lng: 92.739
            },
            {
                lat: 33.710,
                lng: 93.762
            },
            {
                lat: 33.889,
                lng: 94.270
            },
            {
                lat: 34.087,
                lng: 94.802
            },
            {
                lat: 34.515,
                lng: 95.884
            },
            {
                lat: 34.887,
                lng: 96.717
            },
            {
                lat: 35.030,
                lng: 97.030
            },
            {
                lat: 35.321,
                lng: 97.630
            },
            {
                lat: 35.614,
                lng: 98.241
            },
            {
                lat: 35.890,
                lng: 98.722
            },
            {
                lat: 36.490,
                lng: 99.633
            },
            {
                lat: 36.889,
                lng: 100.210
            },
            {
                lat: 37.304,
                lng: 100.739
            },
            {
                lat: 37.724,
                lng: 101.284
            },
            {
                lat: 37.898,
                lng: 101.486
            },
            {
                lat: 38.408,
                lng: 102.077
            },
            {
                lat: 38.926,
                lng: 102.689
            },
            {
                lat: 39.516,
                lng: 103.393
            },
            {
                lat: 39.982,
                lng: 103.943
            },
            {
                lat: 40.525,
                lng: 104.648
            },
            {
                lat: 41.077,
                lng: 105.383
            },
            {
                lat: 41.401,
                lng: 105.804
            },
            {
                lat: 41.801,
                lng: 106.329
            },
            {
                lat: 42.207,
                lng: 106.873
            },
            {
                lat: 42.741,
                lng: 107.679
            },
            {
                lat: 43.286,
                lng: 108.523
            },
            {
                lat: 43.395,
                lng: 108.697
            },
            {
                lat: 44.020,
                lng: 109.796
            },
            {
                lat: 44.338,
                lng: 110.371
            },
            {
                lat: 44.660,
                lng: 110.964
            },
            {
                lat: 44.835,
                lng: 111.294
            },
            {
                lat: 45.415,
                lng: 112.443
            },
            {
                lat: 45.710,
                lng: 113.046
            },
            {
                lat: 46.011,
                lng: 113.669
            },
            {
                lat: 46.310,
                lng: 114.352
            },
            {
                lat: 46.601,
                lng: 115.044
            },
            {
                lat: 46.897,
                lng: 115.764
            },
            {
                lat: 47.198,
                lng: 116.512
            },
            {
                lat: 47.506,
                lng: 117.293
            },
            {
                lat: 47.642,
                lng: 117.661
            },
            {
                lat: 47.926,
                lng: 118.493
            },
            {
                lat: 48.216,
                lng: 119.365
            },
            {
                lat: 48.514,
                lng: 120.279
            },
            {
                lat: 48.819,
                lng: 121.244
            },
            {
                lat: 49.049,
                lng: 122.055
            },
            {
                lat: 49.285,
                lng: 122.905
            },
            {
                lat: 49.586,
                lng: 124.042
            },
            {
                lat: 49.900,
                lng: 125.262
            },
            {
                lat: 50.157,
                lng: 126.358
            },
            {
                lat: 50.425,
                lng: 127.535
            },
            {
                lat: 50.708,
                lng: 128.813
            },
            {
                lat: 51.009,
                lng: 130.219
            },
            {
                lat: 51.260,
                lng: 131.587
            },
            {
                lat: 51.394,
                lng: 132.330
            },
            {
                lat: 51.534,
                lng: 133.122
            },
            {
                lat: 51.681,
                lng: 133.975
            },
            {
                lat: 51.839,
                lng: 134.901
            },
            {
                lat: 52.009,
                lng: 135.924
            },
            {
                lat: 52.196,
                lng: 137.078
            },
            {
                lat: 52.205,
                lng: 137.137
            },
            {
                lat: 52.366,
                lng: 138.282
            },
            {
                lat: 52.554,
                lng: 139.648
            },
            {
                lat: 52.666,
                lng: 140.467
            },
            {
                lat: 52.797,
                lng: 141.442
            },
            {
                lat: 52.966,
                lng: 142.719
            },
            {
                lat: 53.084,
                lng: 143.626
            },
            {
                lat: 53.167,
                lng: 144.269
            },
            {
                lat: 53.344,
                lng: 145.662
            },
        ],
        c3: [{
                lat: 21.124,
                lng: 151.109
            },
            {
                lat: 21.029,
                lng: 149.985
            },
            {
                lat: 20.970,
                lng: 149.498
            },
            {
                lat: 20.867,
                lng: 148.813
            },
            {
                lat: 20.684,
                lng: 147.853
            },
            {
                lat: 20.548,
                lng: 147.201
            },
            {
                lat: 20.418,
                lng: 146.647
            },
            {
                lat: 20.169,
                lng: 145.719
            },
            {
                lat: 19.929,
                lng: 144.940
            },
            {
                lat: 19.695,
                lng: 144.260
            },
            {
                lat: 19.263,
                lng: 143.545
            },
            {
                lat: 18.838,
                lng: 142.919
            },
            {
                lat: 18.426,
                lng: 142.510
            },
            {
                lat: 18.018,
                lng: 142.141
            },
            {
                lat: 17.295,
                lng: 141.769
            },
            {
                lat: 17.222,
                lng: 141.730
            },
            {
                lat: 16.456,
                lng: 141.895
            },
            {
                lat: 16.084,
                lng: 142.224
            },
            {
                lat: 15.714,
                lng: 142.580
            },
            {
                lat: 15.425,
                lng: 143.096
            },
            {
                lat: 14.998,
                lng: 143.862
            },
            {
                lat: 14.634,
                lng: 144.381
            },
            {
                lat: 14.272,
                lng: 144.961
            },
            {
                lat: 13.993,
                lng: 146.070
            },
            {
                lat: 13.801,
                lng: 146.946
            },
            {
                lat: 13.617,
                lng: 148.049
            },
            {
                lat: 13.472,
                lng: 149.068
            },
            {
                lat: 13.407,
                lng: 149.793
            },
            {
                lat: 13.380,
                lng: 150.306
            },
            {
                lat: 13.375,
                lng: 151.443
            },
        ],
        c4: [{
                lat: -46.846,
                lng: 147.682
            },
            {
                lat: -46.693,
                lng: 146.365
            },
            {
                lat: -46.624,
                lng: 145.798
            },
            {
                lat: -46.524,
                lng: 144.999
            },
            {
                lat: -46.378,
                lng: 143.874
            },
            {
                lat: -46.262,
                lng: 143.016
            },
            {
                lat: -46.162,
                lng: 142.295
            },
            {
                lat: -45.989,
                lng: 141.092
            },
            {
                lat: -45.838,
                lng: 140.085
            },
            {
                lat: -45.701,
                lng: 139.266
            },
            {
                lat: -45.573,
                lng: 138.525
            },
            {
                lat: -45.337,
                lng: 137.211
            },
            {
                lat: -45.120,
                lng: 136.058
            },
            {
                lat: -44.916,
                lng: 135.020
            },
            {
                lat: -44.655,
                lng: 133.702
            },
            {
                lat: -44.410,
                lng: 132.513
            },
            {
                lat: -44.126,
                lng: 131.211
            },
            {
                lat: -43.856,
                lng: 130.021
            },
            {
                lat: -43.597,
                lng: 128.919
            },
            {
                lat: -43.348,
                lng: 127.890
            },
            {
                lat: -43.146,
                lng: 127.130
            },
            {
                lat: -42.948,
                lng: 126.403
            },
            {
                lat: -42.740,
                lng: 125.735
            },
            {
                lat: -42.535,
                lng: 125.092
            },
            {
                lat: -42.136,
                lng: 123.877
            },
            {
                lat: -41.806,
                lng: 122.881
            },
            {
                lat: -41.484,
                lng: 121.938
            },
            {
                lat: -41.239,
                lng: 121.241
            },
            {
                lat: -40.998,
                lng: 120.569
            },
            {
                lat: -40.781,
                lng: 119.960
            },
            {
                lat: -40.566,
                lng: 119.369
            },
            {
                lat: -40.145,
                lng: 118.238
            },
            {
                lat: -39.935,
                lng: 117.749
            },
            {
                lat: -39.443,
                lng: 116.751
            },
            {
                lat: -38.960,
                lng: 115.805
            },
            {
                lat: -38.625,
                lng: 114.986
            },
            {
                lat: -38.206,
                lng: 114.062
            },
            {
                lat: -37.794,
                lng: 113.176
            },
            {
                lat: -37.524,
                lng: 112.683
            },
            {
                lat: -37.112,
                lng: 111.906
            },
            {
                lat: -36.706,
                lng: 111.158
            },
            {
                lat: -36.109,
                lng: 110.345
            },
            {
                lat: -35.694,
                lng: 109.811
            },
            {
                lat: -35.194,
                lng: 109.111
            },
            {
                lat: -34.700,
                lng: 108.437
            },
            {
                lat: -34.185,
                lng: 107.904
            },
            {
                lat: -33.754,
                lng: 107.476
            },
            {
                lat: -33.290,
                lng: 107.046
            },
            {
                lat: -32.831,
                lng: 106.629
            },
            {
                lat: -31.947,
                lng: 106.190
            },
            {
                lat: -31.077,
                lng: 105.761
            },
            {
                lat: -30.238,
                lng: 105.680
            },
            {
                lat: -29.983,
                lng: 105.617
            },
            {
                lat: -29.405,
                lng: 105.506
            },
            {
                lat: -28.594,
                lng: 105.568
            },
            {
                lat: -28.009,
                lng: 105.717
            },
            {
                lat: -27.801,
                lng: 105.829
            },
            {
                lat: -27.015,
                lng: 106.049
            },
            {
                lat: -26.792,
                lng: 106.188
            },
            {
                lat: -26.256,
                lng: 106.668
            },
            {
                lat: -25.732,
                lng: 106.739
            },
            {
                lat: -25.478,
                lng: 106.784
            },
            {
                lat: -24.806,
                lng: 107.357
            },
            {
                lat: -24.730,
                lng: 107.391
            },
            {
                lat: -23.967,
                lng: 107.543
            },
            {
                lat: -23.209,
                lng: 107.702
            },
            {
                lat: -23.169,
                lng: 107.702
            },
            {
                lat: -22.453,
                lng: 107.762
            },
            {
                lat: -21.693,
                lng: 107.601
            },
            {
                lat: -20.942,
                lng: 107.521
            },
            {
                lat: -20.189,
                lng: 107.234
            },
            {
                lat: -19.441,
                lng: 106.955
            },
            {
                lat: -18.698,
                lng: 106.642
            },
            {
                lat: -17.953,
                lng: 106.139
            },
            {
                lat: -17.333,
                lng: 105.797
            },
            {
                lat: -17.218,
                lng: 105.738
            },
            {
                lat: -16.482,
                lng: 105.163
            },
            {
                lat: -15.751,
                lng: 104.560
            },
            {
                lat: -15.662,
                lng: 104.469
            },
            {
                lat: -15.022,
                lng: 103.859
            },
            {
                lat: -14.417,
                lng: 103.316
            },
            {
                lat: -14.301,
                lng: 103.217
            },
            {
                lat: -13.584,
                lng: 102.552
            },
            {
                lat: -13.222,
                lng: 102.226
            },
            {
                lat: -12.873,
                lng: 101.925
            },
            {
                lat: -12.167,
                lng: 101.365
            },
            {
                lat: -11.928,
                lng: 101.169
            },
            {
                lat: -11.467,
                lng: 100.820
            },
            {
                lat: -10.771,
                lng: 100.321
            },
            {
                lat: -10.540,
                lng: 100.149
            },
            {
                lat: -10.078,
                lng: 99.805
            },
            {
                lat: -9.389,
                lng: 99.289
            },
            {
                lat: -9.248,
                lng: 99.186
            },
            {
                lat: -8.703,
                lng: 98.791
            },
            {
                lat: -8.091,
                lng: 98.275
            },
            {
                lat: -8.019,
                lng: 98.213
            },
            {
                lat: -7.339,
                lng: 97.700
            },
            {
                lat: -7.003,
                lng: 97.401
            },
            {
                lat: -6.661,
                lng: 97.100
            },
            {
                lat: -6.084,
                lng: 96.564
            },
            {
                lat: -5.985,
                lng: 96.470
            },
            {
                lat: -5.312,
                lng: 95.831
            },
            {
                lat: -5.236,
                lng: 95.752
            },
            {
                lat: -4.641,
                lng: 95.133
            },
            {
                lat: -4.470,
                lng: 94.960
            },
            {
                lat: -3.972,
                lng: 94.441
            },
            {
                lat: -3.731,
                lng: 94.185
            },
            {
                lat: -3.305,
                lng: 93.727
            },
            {
                lat: -3.004,
                lng: 93.423
            },
            {
                lat: -2.641,
                lng: 93.056
            },
            {
                lat: -2.288,
                lng: 92.674
            },
            {
                lat: -1.978,
                lng: 92.343
            },
            {
                lat: -1.517,
                lng: 91.936
            },
            {
                lat: -1.318,
                lng: 91.756
            },
            {
                lat: -0.658,
                lng: 91.209
            },
            {
                lat: -0.657,
                lng: 91.208
            },
            {
                lat: 0.000,
                lng: 90.676
            },
            {
                lat: 0.327,
                lng: 90.492
            },
            {
                lat: 0.658,
                lng: 90.300
            },
            {
                lat: 1.315,
                lng: 89.885
            },
            {
                lat: 1.538,
                lng: 89.791
            },
            {
                lat: 1.972,
                lng: 89.598
            },
            {
                lat: 2.629,
                lng: 89.354
            },
            {
                lat: 3.286,
                lng: 89.213
            },
            {
                lat: 3.688,
                lng: 89.124
            },
            {
                lat: 3.945,
                lng: 89.063
            },
            {
                lat: 4.604,
                lng: 88.977
            },
            {
                lat: 5.264,
                lng: 88.980
            },
            {
                lat: 5.925,
                lng: 88.920
            },
            {
                lat: 6.588,
                lng: 88.977
            },
            {
                lat: 7.252,
                lng: 88.957
            },
            {
                lat: 7.918,
                lng: 89.024
            },
            {
                lat: 8.585,
                lng: 88.997
            },
            {
                lat: 9.254,
                lng: 89.018
            },
            {
                lat: 9.924,
                lng: 88.987
            },
            {
                lat: 10.596,
                lng: 88.922
            },
            {
                lat: 11.271,
                lng: 88.883
            },
            {
                lat: 11.601,
                lng: 88.833
            },
            {
                lat: 11.947,
                lng: 88.776
            },
            {
                lat: 12.626,
                lng: 88.670
            },
            {
                lat: 13.307,
                lng: 88.539
            },
            {
                lat: 13.990,
                lng: 88.426
            },
            {
                lat: 14.549,
                lng: 88.375
            },
            {
                lat: 14.678,
                lng: 88.364
            },
            {
                lat: 15.368,
                lng: 88.219
            },
            {
                lat: 16.063,
                lng: 88.195
            },
            {
                lat: 16.760,
                lng: 88.101
            },
            {
                lat: 17.461,
                lng: 88.024
            },
            {
                lat: 17.744,
                lng: 87.992
            },
            {
                lat: 18.167,
                lng: 87.936
            },
            {
                lat: 18.876,
                lng: 87.830
            },
            {
                lat: 19.589,
                lng: 87.704
            },
            {
                lat: 20.126,
                lng: 87.549
            },
            {
                lat: 20.305,
                lng: 87.486
            },
            {
                lat: 21.027,
                lng: 87.324
            },
            {
                lat: 21.753,
                lng: 87.075
            },
            {
                lat: 21.955,
                lng: 87.049
            },
            {
                lat: 22.487,
                lng: 86.980
            },
            {
                lat: 23.225,
                lng: 86.794
            },
            {
                lat: 23.973,
                lng: 86.768
            },
            {
                lat: 24.730,
                lng: 86.858
            },
            {
                lat: 25.494,
                lng: 86.931
            },
            {
                lat: 26.272,
                lng: 87.249
            },
            {
                lat: 27.058,
                lng: 87.551
            },
            {
                lat: 27.701,
                lng: 87.995
            },
            {
                lat: 27.861,
                lng: 88.114
            },
            {
                lat: 28.672,
                lng: 88.648
            },
            {
                lat: 29.099,
                lng: 89.087
            },
            {
                lat: 29.507,
                lng: 89.566
            },
            {
                lat: 30.018,
                lng: 90.121
            },
            {
                lat: 30.357,
                lng: 90.577
            },
            {
                lat: 30.718,
                lng: 91.135
            },
            {
                lat: 31.241,
                lng: 92.086
            },
            {
                lat: 31.267,
                lng: 92.137
            },
            {
                lat: 31.710,
                lng: 93.134
            },
            {
                lat: 32.033,
                lng: 94.115
            },
            {
                lat: 32.183,
                lng: 94.545
            },
            {
                lat: 32.429,
                lng: 95.140
            },
            {
                lat: 32.847,
                lng: 96.197
            },
            {
                lat: 33.148,
                lng: 96.912
            },
            {
                lat: 33.309,
                lng: 97.297
            },
            {
                lat: 33.568,
                lng: 97.870
            },
            {
                lat: 33.829,
                lng: 98.452
            },
            {
                lat: 34.126,
                lng: 99.033
            },
            {
                lat: 34.529,
                lng: 99.722
            },
            {
                lat: 35.102,
                lng: 100.665
            },
            {
                lat: 35.436,
                lng: 101.146
            },
            {
                lat: 36.083,
                lng: 102.025
            },
            {
                lat: 36.783,
                lng: 102.885
            },
            {
                lat: 37.077,
                lng: 103.233
            },
            {
                lat: 37.578,
                lng: 103.783
            },
            {
                lat: 38.086,
                lng: 104.353
            },
            {
                lat: 38.759,
                lng: 105.160
            },
            {
                lat: 39.126,
                lng: 105.594
            },
            {
                lat: 39.655,
                lng: 106.211
            },
            {
                lat: 40.193,
                lng: 106.854
            },
            {
                lat: 40.603,
                lng: 107.387
            },
            {
                lat: 41.018,
                lng: 107.939
            },
            {
                lat: 41.303,
                lng: 108.324
            },
            {
                lat: 41.877,
                lng: 109.158
            },
            {
                lat: 42.462,
                lng: 110.037
            },
            {
                lat: 42.936,
                lng: 110.844
            },
            {
                lat: 43.316,
                lng: 111.547
            },
            {
                lat: 43.703,
                lng: 112.277
            },
            {
                lat: 44.071,
                lng: 112.999
            },
            {
                lat: 44.446,
                lng: 113.751
            },
            {
                lat: 44.737,
                lng: 114.383
            },
            {
                lat: 45.033,
                lng: 115.037
            },
            {
                lat: 45.389,
                lng: 115.903
            },
            {
                lat: 45.752,
                lng: 116.810
            },
            {
                lat: 46.136,
                lng: 117.856
            },
            {
                lat: 46.530,
                lng: 118.962
            },
            {
                lat: 46.892,
                lng: 120.065
            },
            {
                lat: 47.123,
                lng: 120.868
            },
            {
                lat: 47.360,
                lng: 121.707
            },
            {
                lat: 47.603,
                lng: 122.586
            },
            {
                lat: 47.853,
                lng: 123.509
            },
            {
                lat: 48.134,
                lng: 124.679
            },
            {
                lat: 48.427,
                lng: 125.934
            },
            {
                lat: 48.584,
                lng: 126.646
            },
            {
                lat: 48.746,
                lng: 127.390
            },
            {
                lat: 48.921,
                lng: 128.365
            },
            {
                lat: 49.104,
                lng: 129.404
            },
            {
                lat: 49.298,
                lng: 130.521
            },
            {
                lat: 49.504,
                lng: 131.733
            },
            {
                lat: 49.683,
                lng: 132.944
            },
            {
                lat: 49.877,
                lng: 134.285
            },
            {
                lat: 49.983,
                lng: 135.020
            },
            {
                lat: 50.095,
                lng: 135.810
            },
            {
                lat: 50.216,
                lng: 136.668
            },
            {
                lat: 50.348,
                lng: 137.615
            },
            {
                lat: 50.492,
                lng: 138.785
            },
            {
                lat: 50.664,
                lng: 140.180
            },
            {
                lat: 50.767,
                lng: 141.015
            },
            {
                lat: 50.889,
                lng: 142.008
            },
            {
                lat: 51.049,
                lng: 143.309
            },
            {
                lat: 51.162,
                lng: 144.231
            },
            {
                lat: 51.242,
                lng: 144.885
            },
            {
                lat: 51.416,
                lng: 146.310
            },
        ],
        c5: [{
                lat: 24.588,
                lng: 150.806
            },
            {
                lat: 24.524,
                lng: 150.022
            },
            {
                lat: 24.486,
                lng: 149.653
            },
            {
                lat: 24.423,
                lng: 149.132
            },
            {
                lat: 24.319,
                lng: 148.399
            },
            {
                lat: 24.137,
                lng: 147.369
            },
            {
                lat: 23.973,
                lng: 146.586
            },
            {
                lat: 23.817,
                lng: 145.933
            },
            {
                lat: 23.679,
                lng: 145.363
            },
            {
                lat: 23.545,
                lng: 144.851
            },
            {
                lat: 23.286,
                lng: 143.951
            },
            {
                lat: 23.035,
                lng: 143.167
            },
            {
                lat: 22.791,
                lng: 142.466
            },
            {
                lat: 22.551,
                lng: 141.821
            },
            {
                lat: 22.315,
                lng: 141.226
            },
            {
                lat: 22.083,
                lng: 140.675
            },
            {
                lat: 21.853,
                lng: 140.159
            },
            {
                lat: 21.624,
                lng: 139.626
            },
            {
                lat: 21.397,
                lng: 139.123
            },
            {
                lat: 21.172,
                lng: 138.647
            },
            {
                lat: 20.950,
                lng: 138.195
            },
            {
                lat: 20.522,
                lng: 137.569
            },
            {
                lat: 20.099,
                lng: 136.988
            },
            {
                lat: 19.672,
                lng: 136.271
            },
            {
                lat: 19.250,
                lng: 135.604
            },
            {
                lat: 18.453,
                lng: 135.112
            },
            {
                lat: 17.665,
                lng: 134.671
            },
            {
                lat: 16.895,
                lng: 134.504
            },
            {
                lat: 16.540,
                lng: 134.692
            },
            {
                lat: 16.152,
                lng: 134.868
            },
            {
                lat: 15.410,
                lng: 135.223
            },
            {
                lat: 14.927,
                lng: 135.757
            },
            {
                lat: 14.686,
                lng: 135.992
            },
            {
                lat: 14.325,
                lng: 136.394
            },
            {
                lat: 13.965,
                lng: 136.815
            },
            {
                lat: 13.653,
                lng: 137.351
            },
            {
                lat: 13.257,
                lng: 138.029
            },
            {
                lat: 12.900,
                lng: 138.582
            },
            {
                lat: 12.544,
                lng: 139.170
            },
            {
                lat: 12.462,
                lng: 139.338
            },
            {
                lat: 12.151,
                lng: 139.976
            },
            {
                lat: 11.841,
                lng: 140.661
            },
            {
                lat: 11.567,
                lng: 141.241
            },
            {
                lat: 11.293,
                lng: 141.863
            },
            {
                lat: 11.136,
                lng: 142.198
            },
            {
                lat: 10.783,
                lng: 143.014
            },
            {
                lat: 10.433,
                lng: 143.932
            },
            {
                lat: 10.177,
                lng: 144.540
            },
            {
                lat: 9.922,
                lng: 145.214
            },
            {
                lat: 9.728,
                lng: 145.791
            },
            {
                lat: 9.552,
                lng: 146.314
            },
            {
                lat: 9.378,
                lng: 146.901
            },
            {
                lat: 9.205,
                lng: 147.579
            },
            {
                lat: 9.036,
                lng: 148.404
            },
            {
                lat: 8.883,
                lng: 149.361
            },
            {
                lat: 8.811,
                lng: 150.041
            },
            {
                lat: 8.779,
                lng: 150.523
            },
            {
                lat: 8.760,
                lng: 151.611
            },
        ],
        c6: [{
                lat: -43.898,
                lng: 148.247
            },
            {
                lat: -43.781,
                lng: 147.166
            },
            {
                lat: -43.727,
                lng: 146.663
            },
            {
                lat: -43.650,
                lng: 145.952
            },
            {
                lat: -43.541,
                lng: 144.949
            },
            {
                lat: -43.457,
                lng: 144.181
            },
            {
                lat: -43.387,
                lng: 143.535
            },
            {
                lat: -43.301,
                lng: 142.797
            },
            {
                lat: -43.226,
                lng: 142.149
            },
            {
                lat: -43.093,
                lng: 141.027
            },
            {
                lat: -42.977,
                lng: 140.060
            },
            {
                lat: -42.872,
                lng: 139.198
            },
            {
                lat: -42.776,
                lng: 138.413
            },
            {
                lat: -42.687,
                lng: 137.689
            },
            {
                lat: -42.523,
                lng: 136.378
            },
            {
                lat: -42.412,
                lng: 135.683
            },
            {
                lat: -42.306,
                lng: 135.027
            },
            {
                lat: -42.104,
                lng: 133.813
            },
            {
                lat: -41.914,
                lng: 132.701
            },
            {
                lat: -41.734,
                lng: 131.671
            },
            {
                lat: -41.579,
                lng: 130.904
            },
            {
                lat: -41.429,
                lng: 130.173
            },
            {
                lat: -41.282,
                lng: 129.476
            },
            {
                lat: -41.139,
                lng: 128.807
            },
            {
                lat: -40.919,
                lng: 127.847
            },
            {
                lat: -40.679,
                lng: 126.930
            },
            {
                lat: -40.445,
                lng: 126.061
            },
            {
                lat: -40.217,
                lng: 125.232
            },
            {
                lat: -39.993,
                lng: 124.439
            },
            {
                lat: -39.624,
                lng: 123.325
            },
            {
                lat: -39.330,
                lng: 122.362
            },
            {
                lat: -39.043,
                lng: 121.444
            },
            {
                lat: -38.668,
                lng: 120.355
            },
            {
                lat: -38.302,
                lng: 119.321
            },
            {
                lat: -38.113,
                lng: 118.802
            },
            {
                lat: -37.858,
                lng: 118.160
            },
            {
                lat: -37.607,
                lng: 117.538
            },
            {
                lat: -37.358,
                lng: 116.934
            },
            {
                lat: -37.113,
                lng: 116.347
            },
            {
                lat: -37.110,
                lng: 116.342
            },
            {
                lat: -36.831,
                lng: 115.759
            },
            {
                lat: -36.555,
                lng: 115.192
            },
            {
                lat: -36.282,
                lng: 114.641
            },
            {
                lat: -36.012,
                lng: 114.104
            },
            {
                lat: -35.931,
                lng: 113.960
            },
            {
                lat: -35.449,
                lng: 113.130
            },
            {
                lat: -34.975,
                lng: 112.335
            },
            {
                lat: -34.284,
                lng: 111.453
            },
            {
                lat: -34.000,
                lng: 111.129
            },
            {
                lat: -33.514,
                lng: 110.447
            },
            {
                lat: -33.034,
                lng: 109.790
            },
            {
                lat: -32.142,
                lng: 109.323
            },
            {
                lat: -31.253,
                lng: 108.687
            },
            {
                lat: -30.414,
                lng: 108.673
            },
            {
                lat: -29.577,
                lng: 108.538
            },
            {
                lat: -29.531,
                lng: 108.540
            },
            {
                lat: -28.770,
                lng: 108.744
            },
            {
                lat: -28.336,
                lng: 108.993
            },
            {
                lat: -27.982,
                lng: 109.139
            },
            {
                lat: -27.233,
                lng: 109.508
            },
            {
                lat: -27.201,
                lng: 109.524
            },
            {
                lat: -26.421,
                lng: 109.810
            },
            {
                lat: -26.005,
                lng: 109.978
            },
            {
                lat: -25.646,
                lng: 110.063
            },
            {
                lat: -24.867,
                lng: 110.111
            },
            {
                lat: -24.272,
                lng: 110.234
            },
            {
                lat: -24.099,
                lng: 110.251
            },
            {
                lat: -23.330,
                lng: 110.248
            },
            {
                lat: -22.566,
                lng: 110.229
            },
            {
                lat: -21.801,
                lng: 110.043
            },
            {
                lat: -21.043,
                lng: 109.873
            },
            {
                lat: -20.280,
                lng: 109.471
            },
            {
                lat: -19.531,
                lng: 109.236
            },
            {
                lat: -18.775,
                lng: 108.691
            },
            {
                lat: -18.026,
                lng: 108.178
            },
            {
                lat: -17.424,
                lng: 107.718
            },
            {
                lat: -17.282,
                lng: 107.625
            },
            {
                lat: -16.909,
                lng: 107.264
            },
            {
                lat: -16.538,
                lng: 106.909
            },
            {
                lat: -16.000,
                lng: 106.384
            },
            {
                lat: -15.801,
                lng: 106.205
            },
            {
                lat: -15.432,
                lng: 105.786
            },
            {
                lat: -15.065,
                lng: 105.373
            },
            {
                lat: -14.912,
                lng: 105.205
            },
            {
                lat: -14.339,
                lng: 104.655
            },
            {
                lat: -13.859,
                lng: 104.086
            },
            {
                lat: -13.616,
                lng: 103.824
            },
            {
                lat: -12.901,
                lng: 103.141
            },
            {
                lat: -12.764,
                lng: 103.007
            },
            {
                lat: -12.193,
                lng: 102.519
            },
            {
                lat: -11.567,
                lng: 101.957
            },
            {
                lat: -11.488,
                lng: 101.891
            },
            {
                lat: -10.790,
                lng: 101.371
            },
            {
                lat: -10.223,
                lng: 100.933
            },
            {
                lat: -10.096,
                lng: 100.840
            },
            {
                lat: -9.404,
                lng: 100.263
            },
            {
                lat: -8.994,
                lng: 99.968
            },
            {
                lat: -8.717,
                lng: 99.772
            },
            {
                lat: -8.032,
                lng: 99.223
            },
            {
                lat: -7.811,
                lng: 99.046
            },
            {
                lat: -7.351,
                lng: 98.679
            },
            {
                lat: -6.783,
                lng: 98.168
            },
            {
                lat: -6.671,
                lng: 98.068
            },
            {
                lat: -5.995,
                lng: 97.495
            },
            {
                lat: -5.815,
                lng: 97.320
            },
            {
                lat: -5.320,
                lng: 96.841
            },
            {
                lat: -4.993,
                lng: 96.502
            },
            {
                lat: -4.648,
                lng: 96.140
            },
            {
                lat: -4.205,
                lng: 95.704
            },
            {
                lat: -3.978,
                lng: 95.476
            },
            {
                lat: -3.457,
                lng: 94.922
            },
            {
                lat: -3.310,
                lng: 94.767
            },
            {
                lat: -2.714,
                lng: 94.155
            },
            {
                lat: -2.645,
                lng: 94.086
            },
            {
                lat: -2.015,
                lng: 93.401
            },
            {
                lat: -1.981,
                lng: 93.365
            },
            {
                lat: -1.320,
                lng: 92.771
            },
            {
                lat: -1.194,
                lng: 92.658
            },
            {
                lat: -0.659,
                lng: 92.184
            },
            {
                lat: -0.352,
                lng: 91.927
            },
            {
                lat: 0.000,
                lng: 91.638
            },
            {
                lat: 0.658,
                lng: 91.249
            },
            {
                lat: 0.717,
                lng: 91.208
            },
            {
                lat: 1.316,
                lng: 90.803
            },
            {
                lat: 1.974,
                lng: 90.534
            },
            {
                lat: 2.036,
                lng: 90.507
            },
            {
                lat: 2.632,
                lng: 90.256
            },
            {
                lat: 3.290,
                lng: 90.107
            },
            {
                lat: 3.949,
                lng: 89.945
            },
            {
                lat: 4.566,
                lng: 89.857
            },
            {
                lat: 4.608,
                lng: 89.851
            },
            {
                lat: 5.269,
                lng: 89.857
            },
            {
                lat: 5.931,
                lng: 89.772
            },
            {
                lat: 6.594,
                lng: 89.817
            },
            {
                lat: 7.259,
                lng: 89.782
            },
            {
                lat: 7.925,
                lng: 89.861
            },
            {
                lat: 8.593,
                lng: 89.826
            },
            {
                lat: 9.262,
                lng: 89.835
            },
            {
                lat: 9.934,
                lng: 89.836
            },
            {
                lat: 10.607,
                lng: 89.768
            },
            {
                lat: 11.282,
                lng: 89.753
            },
            {
                lat: 11.959,
                lng: 89.657
            },
            {
                lat: 12.326,
                lng: 89.615
            },
            {
                lat: 12.639,
                lng: 89.582
            },
            {
                lat: 13.320,
                lng: 89.456
            },
            {
                lat: 14.006,
                lng: 89.408
            },
            {
                lat: 14.694,
                lng: 89.324
            },
            {
                lat: 15.385,
                lng: 89.218
            },
            {
                lat: 15.667,
                lng: 89.222
            },
            {
                lat: 16.081,
                lng: 89.228
            },
            {
                lat: 16.779,
                lng: 89.151
            },
            {
                lat: 17.482,
                lng: 89.104
            },
            {
                lat: 18.190,
                lng: 89.089
            },
            {
                lat: 18.900,
                lng: 89.029
            },
            {
                lat: 19.616,
                lng: 88.975
            },
            {
                lat: 19.616,
                lng: 88.975
            },
            {
                lat: 20.336,
                lng: 88.899
            },
            {
                lat: 21.061,
                lng: 88.846
            },
            {
                lat: 21.789,
                lng: 88.665
            },
            {
                lat: 22.527,
                lng: 88.672
            },
            {
                lat: 22.595,
                lng: 88.661
            },
            {
                lat: 23.267,
                lng: 88.556
            },
            {
                lat: 24.017,
                lng: 88.532
            },
            {
                lat: 24.779,
                lng: 88.740
            },
            {
                lat: 25.546,
                lng: 88.885
            },
            {
                lat: 26.328,
                lng: 89.242
            },
            {
                lat: 26.516,
                lng: 89.359
            },
            {
                lat: 27.123,
                lng: 89.768
            },
            {
                lat: 27.906,
                lng: 90.459
            },
            {
                lat: 27.934,
                lng: 90.489
            },
            {
                lat: 28.341,
                lng: 90.981
            },
            {
                lat: 28.751,
                lng: 91.481
            },
            {
                lat: 28.767,
                lng: 91.502
            },
            {
                lat: 29.047,
                lng: 91.981
            },
            {
                lat: 29.330,
                lng: 92.467
            },
            {
                lat: 29.635,
                lng: 93.128
            },
            {
                lat: 29.791,
                lng: 93.445
            },
            {
                lat: 30.268,
                lng: 94.451
            },
            {
                lat: 30.533,
                lng: 95.051
            },
            {
                lat: 30.707,
                lng: 95.469
            },
            {
                lat: 30.939,
                lng: 95.991
            },
            {
                lat: 31.173,
                lng: 96.520
            },
            {
                lat: 31.461,
                lng: 97.172
            },
            {
                lat: 31.619,
                lng: 97.592
            },
            {
                lat: 31.839,
                lng: 98.137
            },
            {
                lat: 32.060,
                lng: 98.690
            },
            {
                lat: 32.420,
                lng: 99.456
            },
            {
                lat: 32.647,
                lng: 99.879
            },
            {
                lat: 32.993,
                lng: 100.512
            },
            {
                lat: 33.343,
                lng: 101.159
            },
            {
                lat: 33.375,
                lng: 101.220
            },
            {
                lat: 33.760,
                lng: 101.876
            },
            {
                lat: 34.150,
                lng: 102.548
            },
            {
                lat: 34.343,
                lng: 102.850
            },
            {
                lat: 34.753,
                lng: 103.475
            },
            {
                lat: 35.167,
                lng: 104.118
            },
            {
                lat: 35.326,
                lng: 104.363
            },
            {
                lat: 35.813,
                lng: 104.927
            },
            {
                lat: 36.307,
                lng: 105.511
            },
            {
                lat: 36.858,
                lng: 106.203
            },
            {
                lat: 37.316,
                lng: 106.779
            },
            {
                lat: 37.824,
                lng: 107.342
            },
            {
                lat: 38.339,
                lng: 107.928
            },
            {
                lat: 38.823,
                lng: 108.494
            },
            {
                lat: 39.313,
                lng: 109.082
            },
            {
                lat: 39.393,
                lng: 109.185
            },
            {
                lat: 39.945,
                lng: 110.014
            },
            {
                lat: 40.508,
                lng: 110.884
            },
            {
                lat: 40.873,
                lng: 111.420
            },
            {
                lat: 41.244,
                lng: 111.974
            },
            {
                lat: 41.658,
                lng: 112.649
            },
            {
                lat: 42.183,
                lng: 113.706
            },
            {
                lat: 42.721,
                lng: 114.825
            },
            {
                lat: 42.920,
                lng: 115.288
            },
            {
                lat: 43.405,
                lng: 116.458
            },
            {
                lat: 43.652,
                lng: 117.071
            },
            {
                lat: 43.903,
                lng: 117.703
            },
            {
                lat: 44.114,
                lng: 118.334
            },
            {
                lat: 44.327,
                lng: 118.985
            },
            {
                lat: 44.556,
                lng: 119.750
            },
            {
                lat: 44.790,
                lng: 120.544
            },
            {
                lat: 44.965,
                lng: 121.239
            },
            {
                lat: 45.144,
                lng: 121.958
            },
            {
                lat: 45.327,
                lng: 122.704
            },
            {
                lat: 45.514,
                lng: 123.479
            },
            {
                lat: 45.643,
                lng: 124.182
            },
            {
                lat: 45.776,
                lng: 124.910
            },
            {
                lat: 45.912,
                lng: 125.667
            },
            {
                lat: 46.052,
                lng: 126.456
            },
            {
                lat: 46.166,
                lng: 127.218
            },
            {
                lat: 46.283,
                lng: 128.014
            },
            {
                lat: 46.405,
                lng: 128.848
            },
            {
                lat: 46.533,
                lng: 129.726
            },
            {
                lat: 46.602,
                lng: 130.503
            },
            {
                lat: 46.675,
                lng: 131.320
            },
            {
                lat: 46.754,
                lng: 132.182
            },
            {
                lat: 46.839,
                lng: 133.098
            },
            {
                lat: 46.881,
                lng: 133.938
            },
            {
                lat: 46.929,
                lng: 134.833
            },
            {
                lat: 46.985,
                lng: 135.798
            },
            {
                lat: 47.052,
                lng: 136.853
            },
            {
                lat: 47.063,
                lng: 137.780
            },
            {
                lat: 47.085,
                lng: 138.806
            },
            {
                lat: 47.123,
                lng: 139.969
            },
            {
                lat: 47.186,
                lng: 141.347
            },
            {
                lat: 47.231,
                lng: 142.174
            },
            {
                lat: 47.295,
                lng: 143.155
            },
            {
                lat: 47.393,
                lng: 144.434
            },
            {
                lat: 47.472,
                lng: 145.338
            },
            {
                lat: 47.533,
                lng: 145.976
            },
            {
                lat: 47.680,
                lng: 147.369
            },
        ],
        c7: [{
                lat: -26.500,
                lng: 124.494
            },
            {
                lat: -26.702,
                lng: 125.065
            },
            {
                lat: -26.906,
                lng: 125.654
            },
            {
                lat: -27.113,
                lng: 126.261
            },
            {
                lat: -27.321,
                lng: 126.888
            },
            {
                lat: -27.523,
                lng: 127.601
            },
            {
                lat: -27.765,
                lng: 128.130
            },
            {
                lat: -28.010,
                lng: 128.676
            },
            {
                lat: -28.257,
                lng: 129.243
            },
            {
                lat: -28.507,
                lng: 129.832
            },
            {
                lat: -28.865,
                lng: 130.584
            },
            {
                lat: -29.177,
                lng: 131.179
            },
            {
                lat: -29.492,
                lng: 131.805
            },
            {
                lat: -29.905,
                lng: 132.012
            },
            {
                lat: -30.378,
                lng: 132.251
            },
            {
                lat: -30.835,
                lng: 132.618
            },
            {
                lat: -31.298,
                lng: 133.012
            },
            {
                lat: -32.197,
                lng: 133.340
            },
            {
                lat: -32.259,
                lng: 133.359
            },
            {
                lat: -33.088,
                lng: 133.442
            },
            {
                lat: -33.924,
                lng: 133.807
            },
            {
                lat: -34.017,
                lng: 133.849
            },
            {
                lat: -34.475,
                lng: 133.931
            },
            {
                lat: -34.938,
                lng: 134.032
            },
            {
                lat: -35.164,
                lng: 133.580
            },
            {
                lat: -35.414,
                lng: 132.796
            },
            {
                lat: -35.668,
                lng: 132.047
            },
            {
                lat: -35.676,
                lng: 132.005
            },
            {
                lat: -35.856,
                lng: 131.184
            },
            {
                lat: -36.041,
                lng: 130.397
            },
            {
                lat: -36.149,
                lng: 129.489
            },
            {
                lat: -36.262,
                lng: 128.618
            },
            {
                lat: -36.257,
                lng: 128.133
            },
            {
                lat: -36.235,
                lng: 127.341
            },
            {
                lat: -36.215,
                lng: 126.578
            },
            {
                lat: -36.089,
                lng: 125.478
            },
            {
                lat: -35.968,
                lng: 124.431
            },
            {
                lat: -35.908,
                lng: 124.102
            },
            {
                lat: -35.663,
                lng: 123.082
            },
            {
                lat: -35.424,
                lng: 122.107
            },
            {
                lat: -35.239,
                lng: 121.505
            },
            {
                lat: -35.056,
                lng: 120.919
            },
            {
                lat: -34.698,
                lng: 119.793
            },
            {
                lat: -34.633,
                lng: 119.623
            },
            {
                lat: -34.284,
                lng: 118.611
            },
            {
                lat: -33.941,
                lng: 117.642
            },
            {
                lat: -33.519,
                lng: 116.668
            },
            {
                lat: -33.041,
                lng: 116.112
            },
            {
                lat: -32.568,
                lng: 115.578
            },
            {
                lat: -32.102,
                lng: 115.097
            },
            {
                lat: -31.642,
                lng: 114.633
            },
            {
                lat: -30.795,
                lng: 114.641
            },
            {
                lat: -29.943,
                lng: 114.421
            },
            {
                lat: -29.791,
                lng: 114.438
            },
            {
                lat: -29.129,
                lng: 114.656
            },
            {
                lat: -28.642,
                lng: 114.908
            },
            {
                lat: -28.335,
                lng: 115.064
            },
            {
                lat: -27.671,
                lng: 115.515
            },
            {
                lat: -27.556,
                lng: 115.602
            },
            {
                lat: -27.185,
                lng: 115.905
            },
            {
                lat: -26.815,
                lng: 116.213
            },
            {
                lat: -26.791,
                lng: 116.277
            },
            {
                lat: -26.406,
                lng: 116.533
            },
            {
                lat: -26.023,
                lng: 116.792
            },
            {
                lat: -25.936,
                lng: 116.914
            },
            {
                lat: -25.699,
                lng: 118.049
            },
            {
                lat: -25.787,
                lng: 118.768
            },
            {
                lat: -25.878,
                lng: 119.505
            },
            {
                lat: -25.924,
                lng: 120.227
            },
            {
                lat: -25.971,
                lng: 120.967
            },
            {
                lat: -26.129,
                lng: 121.812
            },
            {
                lat: -26.290,
                lng: 122.685
            },
            {
                lat: -26.382,
                lng: 123.528
            },
            {
                lat: -26.476,
                lng: 124.400
            },
            {
                lat: -26.500,
                lng: 124.494
            },
        ],
        c8: [{
                lat: 38.560,
                lng: 122.271
            },
            {
                lat: 38.455,
                lng: 122.386
            },
            {
                lat: 38.056,
                lng: 122.616
            },
            {
                lat: 37.661,
                lng: 122.854
            },
            {
                lat: 37.527,
                lng: 122.924
            },
            {
                lat: 36.754,
                lng: 123.195
            },
            {
                lat: 36.234,
                lng: 122.529
            },
            {
                lat: 35.723,
                lng: 121.900
            },
            {
                lat: 34.761,
                lng: 121.226
            },
            {
                lat: 34.233,
                lng: 120.219
            },
            {
                lat: 33.716,
                lng: 119.268
            },
            {
                lat: 33.237,
                lng: 118.422
            },
            {
                lat: 32.717,
                lng: 117.637
            },
            {
                lat: 32.255,
                lng: 117.236
            },
            {
                lat: 31.797,
                lng: 116.852
            },
            {
                lat: 31.344,
                lng: 116.475
            },
            {
                lat: 30.895,
                lng: 116.113
            },
            {
                lat: 30.313,
                lng: 116.063
            },
            {
                lat: 30.048,
                lng: 116.023
            },
            {
                lat: 29.225,
                lng: 116.140
            },
            {
                lat: 28.822,
                lng: 116.285
            },
            {
                lat: 28.422,
                lng: 116.448
            },
            {
                lat: 27.968,
                lng: 116.978
            },
            {
                lat: 27.663,
                lng: 117.335
            },
            {
                lat: 27.124,
                lng: 117.698
            },
            {
                lat: 26.888,
                lng: 117.870
            },
            {
                lat: 26.411,
                lng: 118.528
            },
            {
                lat: 26.152,
                lng: 118.949
            },
            {
                lat: 25.835,
                lng: 119.475
            },
            {
                lat: 25.423,
                lng: 120.080
            },
            {
                lat: 25.219,
                lng: 120.421
            },
            {
                lat: 24.981,
                lng: 120.955
            },
            {
                lat: 24.745,
                lng: 121.498
            },
            {
                lat: 24.719,
                lng: 121.568
            },
            {
                lat: 24.439,
                lng: 122.046
            },
            {
                lat: 24.160,
                lng: 122.532
            },
            {
                lat: 24.007,
                lng: 122.882
            },
            {
                lat: 23.732,
                lng: 123.721
            },
            {
                lat: 23.533,
                lng: 124.272
            },
            {
                lat: 23.335,
                lng: 124.836
            },
            {
                lat: 23.283,
                lng: 124.944
            },
            {
                lat: 23.040,
                lng: 125.562
            },
            {
                lat: 22.799,
                lng: 126.195
            },
            {
                lat: 22.650,
                lng: 126.554
            },
            {
                lat: 21.868,
                lng: 126.488
            },
            {
                lat: 21.298,
                lng: 126.687
            },
            {
                lat: 21.104,
                lng: 126.678
            },
            {
                lat: 20.341,
                lng: 126.795
            },
            {
                lat: 19.568,
                lng: 126.612
            },
            {
                lat: 18.804,
                lng: 126.521
            },
            {
                lat: 18.773,
                lng: 126.536
            },
            {
                lat: 18.063,
                lng: 126.837
            },
            {
                lat: 17.317,
                lng: 126.967
            },
            {
                lat: 17.032,
                lng: 127.077
            },
            {
                lat: 16.580,
                lng: 127.220
            },
            {
                lat: 16.218,
                lng: 127.487
            },
            {
                lat: 15.857,
                lng: 127.760
            },
            {
                lat: 15.593,
                lng: 127.910
            },
            {
                lat: 15.131,
                lng: 128.194
            },
            {
                lat: 14.773,
                lng: 128.531
            },
            {
                lat: 14.416,
                lng: 128.877
            },
            {
                lat: 14.349,
                lng: 128.953
            },
            {
                lat: 14.028,
                lng: 129.333
            },
            {
                lat: 13.707,
                lng: 129.723
            },
            {
                lat: 13.332,
                lng: 130.229
            },
            {
                lat: 13.001,
                lng: 130.635
            },
            {
                lat: 12.648,
                lng: 131.105
            },
            {
                lat: 12.295,
                lng: 131.590
            },
            {
                lat: 12.284,
                lng: 131.607
            },
            {
                lat: 11.938,
                lng: 132.128
            },
            {
                lat: 11.592,
                lng: 132.668
            },
            {
                lat: 11.316,
                lng: 133.180
            },
            {
                lat: 10.892,
                lng: 133.882
            },
            {
                lat: 10.571,
                lng: 134.376
            },
            {
                lat: 10.250,
                lng: 134.889
            },
            {
                lat: 10.186,
                lng: 134.989
            },
            {
                lat: 9.832,
                lng: 135.517
            },
            {
                lat: 9.477,
                lng: 136.070
            },
            {
                lat: 9.046,
                lng: 136.782
            },
            {
                lat: 8.768,
                lng: 137.209
            },
            {
                lat: 8.411,
                lng: 137.738
            },
            {
                lat: 8.055,
                lng: 138.296
            },
            {
                lat: 7.579,
                lng: 138.907
            },
            {
                lat: 7.335,
                lng: 139.192
            },
            {
                lat: 6.974,
                lng: 139.576
            },
            {
                lat: 6.612,
                lng: 139.984
            },
            {
                lat: 6.254,
                lng: 140.605
            },
            {
                lat: 5.895,
                lng: 141.275
            },
            {
                lat: 5.734,
                lng: 141.456
            },
            {
                lat: 5.166,
                lng: 142.055
            },
            {
                lat: 4.803,
                lng: 142.538
            },
            {
                lat: 4.439,
                lng: 143.063
            },
            {
                lat: 4.073,
                lng: 143.532
            },
            {
                lat: 3.707,
                lng: 144.047
            },
            {
                lat: 3.420,
                lng: 144.512
            },
            {
                lat: 3.133,
                lng: 145.022
            },
            {
                lat: 2.975,
                lng: 145.319
            },
            {
                lat: 2.607,
                lng: 145.807
            },
            {
                lat: 2.237,
                lng: 146.364
            },
            {
                lat: 1.867,
                lng: 146.954
            },
            {
                lat: 1.497,
                lng: 147.661
            },
            {
                lat: 1.311,
                lng: 148.091
            },
            {
                lat: 1.125,
                lng: 148.590
            },
            {
                lat: 0.939,
                lng: 149.196
            },
            {
                lat: 0.753,
                lng: 150.018
            },
            {
                lat: 0.407,
                lng: 150.492
            },
            {
                lat: 0.234,
                lng: 150.857
            },
            {
                lat: 0.061,
                lng: 151.728
            },
        ],
        c9: [{
                lat: -6.153,
                lng: 151.636
            },
            {
                lat: -6.302,
                lng: 150.577
            },
            {
                lat: -6.461,
                lng: 150.112
            },
            {
                lat: -6.783,
                lng: 149.484
            },
            {
                lat: -7.309,
                lng: 149.973
            },
            {
                lat: -7.559,
                lng: 150.331
            },
            {
                lat: -8.324,
                lng: 150.535
            },
            {
                lat: -8.598,
                lng: 150.825
            },
            {
                lat: -8.884,
                lng: 151.588
            },
        ],
        c10: [{
                lat: -22.982,
                lng: 150.926
            },
            {
                lat: -22.942,
                lng: 150.096
            },
            {
                lat: -22.929,
                lng: 149.709
            },
            {
                lat: -22.916,
                lng: 149.159
            },
            {
                lat: -22.909,
                lng: 148.381
            },
            {
                lat: -22.923,
                lng: 147.282
            },
            {
                lat: -22.951,
                lng: 146.440
            },
            {
                lat: -22.987,
                lng: 145.731
            },
            {
                lat: -23.095,
                lng: 145.134
            },
            {
                lat: -23.173,
                lng: 143.936
            },
            {
                lat: -23.261,
                lng: 142.914
            },
            {
                lat: -23.356,
                lng: 142.010
            },
            {
                lat: -23.456,
                lng: 141.191
            },
            {
                lat: -23.473,
                lng: 140.389
            },
            {
                lat: -23.556,
                lng: 139.155
            },
            {
                lat: -23.646,
                lng: 138.040
            },
            {
                lat: -23.643,
                lng: 137.278
            },
            {
                lat: -23.643,
                lng: 136.558
            },
            {
                lat: -23.646,
                lng: 135.873
            },
            {
                lat: -23.650,
                lng: 135.218
            },
            {
                lat: -23.549,
                lng: 134.451
            },
            {
                lat: -23.452,
                lng: 133.722
            },
            {
                lat: -23.357,
                lng: 133.024
            },
            {
                lat: -23.264,
                lng: 132.355
            },
            {
                lat: -23.067,
                lng: 131.378
            },
            {
                lat: -22.875,
                lng: 130.453
            },
            {
                lat: -22.765,
                lng: 129.766
            },
            {
                lat: -22.597,
                lng: 129.128
            },
            {
                lat: -22.431,
                lng: 128.511
            },
            {
                lat: -22.267,
                lng: 127.914
            },
            {
                lat: -22.104,
                lng: 127.334
            },
            {
                lat: -21.880,
                lng: 126.710
            },
            {
                lat: -21.685,
                lng: 125.935
            },
            {
                lat: -21.492,
                lng: 125.187
            },
            {
                lat: -21.217,
                lng: 124.395
            },
            {
                lat: -20.945,
                lng: 123.632
            },
            {
                lat: -20.801,
                lng: 123.173
            },
            {
                lat: -20.484,
                lng: 122.244
            },
            {
                lat: -20.171,
                lng: 121.352
            },
            {
                lat: -20.056,
                lng: 121.056
            },
            {
                lat: -19.779,
                lng: 120.334
            },
            {
                lat: -19.504,
                lng: 119.634
            },
            {
                lat: -19.202,
                lng: 118.879
            },
            {
                lat: -18.797,
                lng: 118.005
            },
            {
                lat: -18.583,
                lng: 117.434
            },
            {
                lat: -18.370,
                lng: 116.875
            },
            {
                lat: -18.215,
                lng: 116.525
            },
            {
                lat: -17.886,
                lng: 115.806
            },
            {
                lat: -17.561,
                lng: 115.106
            },
            {
                lat: -17.552,
                lng: 115.087
            },
            {
                lat: -17.301,
                lng: 114.424
            },
            {
                lat: -17.052,
                lng: 113.776
            },
            {
                lat: -16.747,
                lng: 112.921
            },
            {
                lat: -16.643,
                lng: 112.549
            },
            {
                lat: -16.449,
                lng: 111.954
            },
            {
                lat: -16.255,
                lng: 111.370
            },
            {
                lat: -15.942,
                lng: 110.562
            },
            {
                lat: -15.846,
                lng: 110.224
            },
            {
                lat: -15.633,
                lng: 109.661
            },
            {
                lat: -15.422,
                lng: 109.108
            },
            {
                lat: -15.162,
                lng: 108.593
            },
            {
                lat: -14.849,
                lng: 107.989
            },
            {
                lat: -14.414,
                lng: 107.316
            },
            {
                lat: -14.192,
                lng: 106.886
            },
            {
                lat: -13.675,
                lng: 106.095
            },
            {
                lat: -13.493,
                lng: 105.811
            },
            {
                lat: -12.949,
                lng: 105.118
            },
            {
                lat: -12.696,
                lng: 104.752
            },
            {
                lat: -12.230,
                lng: 104.196
            },
            {
                lat: -11.750,
                lng: 103.705
            },
            {
                lat: -11.522,
                lng: 103.487
            },
            {
                lat: -10.820,
                lng: 102.872
            },
            {
                lat: -10.614,
                lng: 102.668
            },
            {
                lat: -10.121,
                lng: 102.231
            },
            {
                lat: -9.510,
                lng: 101.677
            },
            {
                lat: -9.426,
                lng: 101.604
            },
            {
                lat: -8.737,
                lng: 101.121
            },
            {
                lat: -8.284,
                lng: 100.711
            },
            {
                lat: -8.050,
                lng: 100.513
            },
            {
                lat: -7.366,
                lng: 99.944
            },
            {
                lat: -7.193,
                lng: 99.795
            },
            {
                lat: -6.685,
                lng: 99.378
            },
            {
                lat: -6.118,
                lng: 98.911
            },
            {
                lat: -6.008,
                lng: 98.819
            },
            {
                lat: -5.331,
                lng: 98.120
            },
            {
                lat: -5.277,
                lng: 98.069
            },
            {
                lat: -4.657,
                lng: 97.464
            },
            {
                lat: -4.444,
                lng: 97.246
            },
            {
                lat: -3.986,
                lng: 96.783
            },
            {
                lat: -3.664,
                lng: 96.444
            },
            {
                lat: -3.317,
                lng: 96.069
            },
            {
                lat: -2.919,
                lng: 95.659
            },
            {
                lat: -2.650,
                lng: 95.390
            },
            {
                lat: -2.206,
                lng: 94.889
            },
            {
                lat: -1.985,
                lng: 94.644
            },
            {
                lat: -1.431,
                lng: 94.131
            },
            {
                lat: -1.322,
                lng: 94.031
            },
            {
                lat: -0.660,
                lng: 93.392
            },
            {
                lat: -0.653,
                lng: 93.385
            },
            {
                lat: 0.000,
                lng: 92.849
            },
            {
                lat: 0.272,
                lng: 92.652
            },
            {
                lat: 0.659,
                lng: 92.396
            },
            {
                lat: 1.318,
                lng: 91.949
            },
            {
                lat: 1.350,
                lng: 91.934
            },
            {
                lat: 1.977,
                lng: 91.641
            },
            {
                lat: 2.635,
                lng: 91.331
            },
            {
                lat: 3.091,
                lng: 91.244
            },
            {
                lat: 3.294,
                lng: 91.207
            },
            {
                lat: 3.954,
                lng: 90.989
            },
            {
                lat: 4.614,
                lng: 90.927
            },
            {
                lat: 5.276,
                lng: 90.890
            },
            {
                lat: 5.938,
                lng: 90.838
            },
            {
                lat: 6.603,
                lng: 90.851
            },
            {
                lat: 7.268,
                lng: 90.833
            },
            {
                lat: 7.935,
                lng: 90.907
            },
            {
                lat: 8.604,
                lng: 90.896
            },
            {
                lat: 9.275,
                lng: 90.916
            },
            {
                lat: 9.947,
                lng: 90.954
            },
            {
                lat: 10.404,
                lng: 90.905
            },
            {
                lat: 10.621,
                lng: 90.883
            },
            {
                lat: 11.298,
                lng: 90.906
            },
            {
                lat: 11.976,
                lng: 90.837
            },
            {
                lat: 12.657,
                lng: 90.792
            },
            {
                lat: 13.340,
                lng: 90.702
            },
            {
                lat: 14.027,
                lng: 90.662
            },
            {
                lat: 14.716,
                lng: 90.597
            },
            {
                lat: 14.755,
                lng: 90.593
            },
            {
                lat: 15.408,
                lng: 90.526
            },
            {
                lat: 16.105,
                lng: 90.525
            },
            {
                lat: 16.805,
                lng: 90.478
            },
            {
                lat: 17.511,
                lng: 90.507
            },
            {
                lat: 18.219,
                lng: 90.497
            },
            {
                lat: 18.934,
                lng: 90.540
            },
            {
                lat: 19.652,
                lng: 90.566
            },
            {
                lat: 20.053,
                lng: 90.554
            },
            {
                lat: 20.375,
                lng: 90.542
            },
            {
                lat: 21.106,
                lng: 90.664
            },
            {
                lat: 21.839,
                lng: 90.652
            },
            {
                lat: 22.581,
                lng: 90.751
            },
            {
                lat: 23.327,
                lng: 90.794
            },
            {
                lat: 24.085,
                lng: 91.006
            },
            {
                lat: 24.854,
                lng: 91.350
            },
            {
                lat: 25.128,
                lng: 91.478
            },
            {
                lat: 25.632,
                lng: 91.724
            },
            {
                lat: 26.029,
                lng: 92.076
            },
            {
                lat: 26.429,
                lng: 92.434
            },
            {
                lat: 26.610,
                lng: 92.623
            },
            {
                lat: 27.243,
                lng: 93.326
            },
            {
                lat: 27.467,
                lng: 93.668
            },
            {
                lat: 27.775,
                lng: 94.171
            },
            {
                lat: 28.086,
                lng: 94.683
            },
            {
                lat: 28.086,
                lng: 94.683
            },
            {
                lat: 28.364,
                lng: 95.192
            },
            {
                lat: 28.645,
                lng: 95.708
            },
            {
                lat: 28.952,
                lng: 96.268
            },
            {
                lat: 29.180,
                lng: 96.754
            },
            {
                lat: 29.416,
                lng: 97.277
            },
            {
                lat: 29.654,
                lng: 97.808
            },
            {
                lat: 29.855,
                lng: 98.268
            },
            {
                lat: 30.156,
                lng: 98.900
            },
            {
                lat: 30.411,
                lng: 99.459
            },
            {
                lat: 30.667,
                lng: 100.027
            },
            {
                lat: 30.778,
                lng: 100.287
            },
            {
                lat: 31.189,
                lng: 101.193
            },
            {
                lat: 31.430,
                lng: 101.782
            },
            {
                lat: 31.674,
                lng: 102.381
            },
            {
                lat: 31.736,
                lng: 102.532
            },
            {
                lat: 31.970,
                lng: 103.077
            },
            {
                lat: 32.206,
                lng: 103.631
            },
            {
                lat: 32.448,
                lng: 104.260
            },
            {
                lat: 32.692,
                lng: 104.902
            },
            {
                lat: 32.732,
                lng: 105.014
            },
            {
                lat: 33.060,
                lng: 105.668
            },
            {
                lat: 33.392,
                lng: 106.340
            },
            {
                lat: 33.723,
                lng: 107.002
            },
            {
                lat: 34.237,
                lng: 107.936
            },
            {
                lat: 34.734,
                lng: 108.927
            },
            {
                lat: 35.169,
                lng: 109.687
            },
            {
                lat: 35.468,
                lng: 110.272
            },
            {
                lat: 35.770,
                lng: 110.873
            },
            {
                lat: 36.234,
                lng: 111.438
            },
            {
                lat: 36.705,
                lng: 112.024
            },
            {
                lat: 36.780,
                lng: 112.131
            },
            {
                lat: 37.321,
                lng: 113.135
            },
            {
                lat: 37.597,
                lng: 113.657
            },
            {
                lat: 37.875,
                lng: 114.193
            },
            {
                lat: 37.962,
                lng: 114.379
            },
            {
                lat: 38.377,
                lng: 115.445
            },
            {
                lat: 38.800,
                lng: 116.565
            },
            {
                lat: 38.981,
                lng: 117.478
            },
            {
                lat: 39.165,
                lng: 118.423
            },
            {
                lat: 39.174,
                lng: 119.199
            },
            {
                lat: 39.184,
                lng: 119.994
            },
            {
                lat: 38.956,
                lng: 121.338
            },
            {
                lat: 38.560,
                lng: 122.271
            },
        ],
        c11: [{
                lat: 28.711,
                lng: 107.699
            },
            {
                lat: 28.526,
                lng: 108.024
            },
            {
                lat: 27.988,
                lng: 108.816
            },
            {
                lat: 27.965,
                lng: 108.842
            },
            {
                lat: 27.459,
                lng: 109.623
            },
            {
                lat: 27.223,
                lng: 109.921
            },
            {
                lat: 26.759,
                lng: 110.352
            },
            {
                lat: 26.466,
                lng: 110.645
            },
            {
                lat: 26.129,
                lng: 111.128
            },
            {
                lat: 25.729,
                lng: 111.625
            },
            {
                lat: 25.488,
                lng: 111.911
            },
            {
                lat: 24.995,
                lng: 112.548
            },
            {
                lat: 24.852,
                lng: 112.709
            },
            {
                lat: 24.257,
                lng: 113.334
            },
            {
                lat: 24.138,
                lng: 113.482
            },
            {
                lat: 23.835,
                lng: 113.876
            },
            {
                lat: 23.532,
                lng: 114.274
            },
            {
                lat: 23.501,
                lng: 114.311
            },
            {
                lat: 23.153,
                lng: 114.715
            },
            {
                lat: 22.806,
                lng: 115.124
            },
            {
                lat: 22.804,
                lng: 115.126
            },
            {
                lat: 22.439,
                lng: 115.452
            },
            {
                lat: 22.075,
                lng: 115.782
            },
            {
                lat: 21.953,
                lng: 115.882
            },
            {
                lat: 21.346,
                lng: 116.431
            },
            {
                lat: 21.074,
                lng: 116.647
            },
            {
                lat: 20.616,
                lng: 116.986
            },
            {
                lat: 20.025,
                lng: 117.353
            },
            {
                lat: 19.885,
                lng: 117.442
            },
            {
                lat: 19.154,
                lng: 117.820
            },
            {
                lat: 18.857,
                lng: 118.034
            },
            {
                lat: 18.432,
                lng: 118.320
            },
            {
                lat: 17.708,
                lng: 118.727
            },
            {
                lat: 17.677,
                lng: 118.746
            },
            {
                lat: 16.989,
                lng: 119.194
            },
            {
                lat: 16.606,
                lng: 119.548
            },
            {
                lat: 16.281,
                lng: 119.867
            },
            {
                lat: 15.758,
                lng: 120.486
            },
            {
                lat: 15.579,
                lng: 120.660
            },
            {
                lat: 14.868,
                lng: 121.187
            },
            {
                lat: 14.709,
                lng: 121.380
            },
            {
                lat: 14.166,
                lng: 121.935
            },
            {
                lat: 13.710,
                lng: 122.345
            },
            {
                lat: 13.461,
                lng: 122.576
            },
            {
                lat: 13.109,
                lng: 122.892
            },
            {
                lat: 12.757,
                lng: 123.214
            },
            {
                lat: 12.604,
                lng: 123.324
            },
            {
                lat: 12.051,
                lng: 123.773
            },
            {
                lat: 11.651,
                lng: 124.425
            },
            {
                lat: 11.360,
                lng: 124.831
            },
            {
                lat: 11.065,
                lng: 125.222
            },
            {
                lat: 10.770,
                lng: 125.622
            },
            {
                lat: 10.665,
                lng: 125.771
            },
            {
                lat: 10.316,
                lng: 126.210
            },
            {
                lat: 9.967,
                lng: 126.660
            },
            {
                lat: 9.812,
                lng: 126.872
            },
            {
                lat: 9.542,
                lng: 127.293
            },
            {
                lat: 9.272,
                lng: 127.725
            },
            {
                lat: 8.970,
                lng: 128.257
            },
            {
                lat: 8.576,
                lng: 128.829
            },
            {
                lat: 8.252,
                lng: 129.253
            },
            {
                lat: 7.928,
                lng: 129.689
            },
            {
                lat: 7.875,
                lng: 129.757
            },
            {
                lat: 7.523,
                lng: 130.218
            },
            {
                lat: 7.172,
                lng: 130.695
            },
            {
                lat: 6.803,
                lng: 131.240
            },
            {
                lat: 6.466,
                lng: 131.633
            },
            {
                lat: 6.113,
                lng: 132.111
            },
            {
                lat: 5.760,
                lng: 132.607
            },
            {
                lat: 5.468,
                lng: 132.921
            },
            {
                lat: 5.047,
                lng: 133.330
            },
            {
                lat: 4.690,
                lng: 133.682
            },
            {
                lat: 4.332,
                lng: 134.049
            },
            {
                lat: 4.016,
                lng: 134.403
            },
            {
                lat: 3.699,
                lng: 134.771
            },
            {
                lat: 3.617,
                lng: 134.858
            },
            {
                lat: 3.258,
                lng: 135.207
            },
            {
                lat: 2.898,
                lng: 135.572
            },
            {
                lat: 2.537,
                lng: 135.838
            },
            {
                lat: 2.176,
                lng: 136.116
            },
            {
                lat: 1.815,
                lng: 136.375
            },
            {
                lat: 1.453,
                lng: 136.648
            },
            {
                lat: 1.111,
                lng: 136.971
            },
            {
                lat: 0.728,
                lng: 137.322
            },
            {
                lat: 0.000,
                lng: 137.298
            },
            {
                lat: -0.728,
                lng: 137.645
            },
            {
                lat: -1.457,
                lng: 137.848
            },
            {
                lat: -2.187,
                lng: 137.978
            },
            {
                lat: -2.916,
                lng: 137.896
            },
            {
                lat: -3.644,
                lng: 137.684
            },
            {
                lat: -4.373,
                lng: 137.565
            },
            {
                lat: -4.384,
                lng: 137.560
            },
            {
                lat: -5.099,
                lng: 137.188
            },
            {
                lat: -5.826,
                lng: 136.899
            },
            {
                lat: -6.553,
                lng: 136.625
            },
            {
                lat: -7.280,
                lng: 136.382
            },
            {
                lat: -7.913,
                lng: 136.186
            },
            {
                lat: -8.009,
                lng: 136.161
            },
            {
                lat: -8.746,
                lng: 136.287
            },
            {
                lat: -9.491,
                lng: 136.600
            },
            {
                lat: -10.235,
                lng: 136.785
            },
            {
                lat: -10.986,
                lng: 137.138
            },
            {
                lat: -11.369,
                lng: 137.516
            },
            {
                lat: -11.755,
                lng: 137.917
            },
            {
                lat: -12.138,
                lng: 138.218
            },
            {
                lat: -12.523,
                lng: 138.539
            },
            {
                lat: -13.290,
                lng: 138.966
            },
            {
                lat: -14.066,
                lng: 139.516
            },
            {
                lat: -14.459,
                lng: 139.876
            },
            {
                lat: -14.854,
                lng: 140.262
            },
            {
                lat: -15.624,
                lng: 140.414
            },
            {
                lat: -16.357,
                lng: 140.355
            },
            {
                lat: -16.388,
                lng: 140.344
            },
            {
                lat: -16.763,
                lng: 140.123
            },
            {
                lat: -17.139,
                lng: 139.919
            },
            {
                lat: -17.508,
                lng: 139.556
            },
            {
                lat: -17.879,
                lng: 139.215
            },
            {
                lat: -17.949,
                lng: 139.054
            },
            {
                lat: -18.249,
                lng: 138.051
            },
            {
                lat: -18.551,
                lng: 137.124
            },
            {
                lat: -18.628,
                lng: 136.872
            },
            {
                lat: -18.812,
                lng: 135.725
            },
            {
                lat: -18.999,
                lng: 134.659
            },
            {
                lat: -19.135,
                lng: 133.317
            },
            {
                lat: -19.211,
                lng: 132.553
            },
            {
                lat: -19.198,
                lng: 131.476
            },
            {
                lat: -19.187,
                lng: 130.456
            },
            {
                lat: -19.090,
                lng: 129.413
            },
            {
                lat: -18.995,
                lng: 128.421
            },
            {
                lat: -18.858,
                lng: 127.650
            },
            {
                lat: -18.745,
                lng: 127.038
            },
            {
                lat: -18.634,
                lng: 126.443
            },
            {
                lat: -18.382,
                lng: 125.468
            },
            {
                lat: -18.132,
                lng: 124.534
            },
            {
                lat: -17.934,
                lng: 123.960
            },
            {
                lat: -17.730,
                lng: 123.326
            },
            {
                lat: -17.527,
                lng: 122.710
            },
            {
                lat: -17.306,
                lng: 122.138
            },
            {
                lat: -17.087,
                lng: 121.580
            },
            {
                lat: -16.811,
                lng: 120.961
            },
            {
                lat: -16.545,
                lng: 120.400
            },
            {
                lat: -16.281,
                lng: 119.852
            },
            {
                lat: -15.972,
                lng: 119.276
            },
            {
                lat: -15.494,
                lng: 118.376
            },
            {
                lat: -15.073,
                lng: 117.680
            },
            {
                lat: -14.724,
                lng: 117.090
            },
            {
                lat: -14.278,
                lng: 116.217
            },
            {
                lat: -13.948,
                lng: 115.393
            },
            {
                lat: -13.769,
                lng: 114.917
            },
            {
                lat: -13.584,
                lng: 114.307
            },
            {
                lat: -13.400,
                lng: 113.708
            },
            {
                lat: -13.186,
                lng: 112.582
            },
            {
                lat: -13.144,
                lng: 112.365
            },
            {
                lat: -12.966,
                lng: 111.486
            },
            {
                lat: -12.774,
                lng: 110.426
            },
            {
                lat: -12.589,
                lng: 109.395
            },
            {
                lat: -12.338,
                lng: 108.374
            },
            {
                lat: -12.331,
                lng: 108.353
            },
            {
                lat: -12.131,
                lng: 107.847
            },
            {
                lat: -11.932,
                lng: 107.347
            },
            {
                lat: -11.592,
                lng: 106.650
            },
            {
                lat: -11.414,
                lng: 106.326
            },
            {
                lat: -10.874,
                lng: 105.519
            },
            {
                lat: -10.746,
                lng: 105.309
            },
            {
                lat: -10.164,
                lng: 104.548
            },
            {
                lat: -9.985,
                lng: 104.308
            },
            {
                lat: -9.463,
                lng: 103.752
            },
            {
                lat: -9.018,
                lng: 103.312
            },
            {
                lat: -8.768,
                lng: 103.084
            },
            {
                lat: -8.077,
                lng: 102.406
            },
            {
                lat: -8.009,
                lng: 102.346
            },
            {
                lat: -7.391,
                lng: 101.844
            },
            {
                lat: -6.943,
                lng: 101.408
            },
            {
                lat: -6.706,
                lng: 101.196
            },
            {
                lat: -6.026,
                lng: 100.639
            },
            {
                lat: -5.888,
                lng: 100.503
            },
            {
                lat: -5.347,
                lng: 99.982
            },
            {
                lat: -5.005,
                lng: 99.638
            },
            {
                lat: -4.671,
                lng: 99.314
            },
            {
                lat: -4.193,
                lng: 98.799
            },
            {
                lat: -3.998,
                lng: 98.595
            },
            {
                lat: -3.393,
                lng: 97.979
            },
            {
                lat: -3.326,
                lng: 97.909
            },
            {
                lat: -2.673,
                lng: 97.180
            },
            {
                lat: -2.657,
                lng: 97.163
            },
            {
                lat: -2.326,
                lng: 96.778
            },
            {
                lat: -1.995,
                lng: 96.396
            },
            {
                lat: -1.990,
                lng: 96.391
            },
            {
                lat: -1.325,
                lng: 95.744
            },
            {
                lat: -1.216,
                lng: 95.625
            },
            {
                lat: -0.662,
                lng: 95.048
            },
            {
                lat: -0.469,
                lng: 94.867
            },
            {
                lat: 0.000,
                lng: 94.439
            },
            {
                lat: 0.405,
                lng: 94.122
            },
            {
                lat: 0.661,
                lng: 93.938
            },
            {
                lat: 1.321,
                lng: 93.439
            },
            {
                lat: 1.398,
                lng: 93.392
            },
            {
                lat: 1.980,
                lng: 93.073
            },
            {
                lat: 2.640,
                lng: 92.766
            },
            {
                lat: 2.877,
                lng: 92.687
            },
            {
                lat: 3.300,
                lng: 92.564
            },
            {
                lat: 3.961,
                lng: 92.393
            },
            {
                lat: 4.622,
                lng: 92.277
            },
            {
                lat: 5.285,
                lng: 92.286
            },
            {
                lat: 5.949,
                lng: 92.203
            },
            {
                lat: 6.614,
                lng: 92.263
            },
            {
                lat: 7.281,
                lng: 92.270
            },
            {
                lat: 7.950,
                lng: 92.369
            },
            {
                lat: 8.620,
                lng: 92.389
            },
            {
                lat: 9.293,
                lng: 92.475
            },
            {
                lat: 9.968,
                lng: 92.562
            },
            {
                lat: 10.644,
                lng: 92.562
            },
            {
                lat: 11.323,
                lng: 92.605
            },
            {
                lat: 12.003,
                lng: 92.611
            },
            {
                lat: 12.686,
                lng: 92.596
            },
            {
                lat: 12.686,
                lng: 92.596
            },
            {
                lat: 13.371,
                lng: 92.519
            },
            {
                lat: 14.060,
                lng: 92.519
            },
            {
                lat: 14.751,
                lng: 92.485
            },
            {
                lat: 15.444,
                lng: 92.363
            },
            {
                lat: 16.144,
                lng: 92.444
            },
            {
                lat: 16.846,
                lng: 92.403
            },
            {
                lat: 17.193,
                lng: 92.413
            },
            {
                lat: 17.553,
                lng: 92.427
            },
            {
                lat: 18.266,
                lng: 92.520
            },
            {
                lat: 18.983,
                lng: 92.601
            },
            {
                lat: 19.704,
                lng: 92.667
            },
            {
                lat: 20.433,
                lng: 92.831
            },
            {
                lat: 21.169,
                lng: 93.030
            },
            {
                lat: 21.909,
                lng: 93.193
            },
            {
                lat: 22.043,
                lng: 93.247
            },
            {
                lat: 22.660,
                lng: 93.505
            },
            {
                lat: 23.417,
                lng: 93.785
            },
            {
                lat: 24.188,
                lng: 94.299
            },
            {
                lat: 24.629,
                lng: 94.627
            },
            {
                lat: 24.971,
                lng: 94.903
            },
            {
                lat: 25.370,
                lng: 95.324
            },
            {
                lat: 25.772,
                lng: 95.752
            },
            {
                lat: 25.774,
                lng: 95.755
            },
            {
                lat: 26.153,
                lng: 96.284
            },
            {
                lat: 26.536,
                lng: 96.823
            },
            {
                lat: 26.595,
                lng: 96.909
            },
            {
                lat: 26.912,
                lng: 97.404
            },
            {
                lat: 27.231,
                lng: 97.907
            },
            {
                lat: 27.439,
                lng: 98.269
            },
            {
                lat: 27.774,
                lng: 98.975
            },
            {
                lat: 27.996,
                lng: 99.504
            },
            {
                lat: 28.219,
                lng: 100.040
            },
            {
                lat: 28.331,
                lng: 100.359
            },
            {
                lat: 28.590,
                lng: 101.105
            },
            {
                lat: 28.918,
                lng: 102.181
            },
            {
                lat: 29.136,
                lng: 103.236
            },
            {
                lat: 29.245,
                lng: 104.267
            },
            {
                lat: 29.294,
                lng: 105.292
            },
            {
                lat: 29.189,
                lng: 106.263
            },
            {
                lat: 28.954,
                lng: 107.185
            },
            {
                lat: 28.711,
                lng: 107.699
            },
        ],
        c12: [{
                lat: 24.556,
                lng: 103.660
            },
            {
                lat: 24.352,
                lng: 104.247
            },
            {
                lat: 23.923,
                lng: 105.046
            },
            {
                lat: 23.856,
                lng: 105.140
            },
            {
                lat: 23.414,
                lng: 105.824
            },
            {
                lat: 23.145,
                lng: 106.276
            },
            {
                lat: 22.919,
                lng: 106.616
            },
            {
                lat: 22.428,
                lng: 107.187
            },
            {
                lat: 22.239,
                lng: 107.349
            },
            {
                lat: 21.699,
                lng: 107.750
            },
            {
                lat: 21.391,
                lng: 108.031
            },
            {
                lat: 20.980,
                lng: 108.427
            },
            {
                lat: 20.614,
                lng: 108.751
            },
            {
                lat: 20.261,
                lng: 109.013
            },
            {
                lat: 19.528,
                lng: 109.179
            },
            {
                lat: 19.292,
                lng: 109.294
            },
            {
                lat: 18.809,
                lng: 109.572
            },
            {
                lat: 18.089,
                lng: 109.886
            },
            {
                lat: 18.058,
                lng: 109.893
            },
            {
                lat: 17.366,
                lng: 110.000
            },
            {
                lat: 16.649,
                lng: 110.171
            },
            {
                lat: 16.335,
                lng: 110.368
            },
            {
                lat: 15.944,
                lng: 110.621
            },
            {
                lat: 15.240,
                lng: 111.031
            },
            {
                lat: 15.190,
                lng: 111.053
            },
            {
                lat: 14.537,
                lng: 111.417
            },
            {
                lat: 14.225,
                lng: 111.815
            },
            {
                lat: 13.849,
                lng: 112.180
            },
            {
                lat: 13.213,
                lng: 112.589
            },
            {
                lat: 13.152,
                lng: 112.642
            },
            {
                lat: 12.455,
                lng: 113.051
            },
            {
                lat: 12.040,
                lng: 113.352
            },
            {
                lat: 11.762,
                lng: 113.535
            },
            {
                lat: 11.068,
                lng: 113.965
            },
            {
                lat: 10.397,
                lng: 114.045
            },
            {
                lat: 10.368,
                lng: 114.049
            },
            {
                lat: 9.676,
                lng: 114.454
            },
            {
                lat: 9.245,
                lng: 114.896
            },
            {
                lat: 8.995,
                lng: 115.355
            },
            {
                lat: 8.573,
                lng: 114.767
            },
            {
                lat: 8.279,
                lng: 114.435
            },
            {
                lat: 8.119,
                lng: 114.686
            },
            {
                lat: 7.965,
                lng: 115.767
            },
            {
                lat: 7.731,
                lng: 116.866
            },
            {
                lat: 7.629,
                lng: 117.045
            },
            {
                lat: 7.388,
                lng: 117.980
            },
            {
                lat: 7.187,
                lng: 118.547
            },
            {
                lat: 6.987,
                lng: 119.123
            },
            {
                lat: 6.966,
                lng: 119.164
            },
            {
                lat: 6.817,
                lng: 119.739
            },
            {
                lat: 6.668,
                lng: 120.324
            },
            {
                lat: 6.484,
                lng: 120.883
            },
            {
                lat: 6.300,
                lng: 121.452
            },
            {
                lat: 6.235,
                lng: 121.554
            },
            {
                lat: 5.924,
                lng: 122.025
            },
            {
                lat: 5.612,
                lng: 122.506
            },
            {
                lat: 5.508,
                lng: 122.790
            },
            {
                lat: 5.216,
                lng: 123.306
            },
            {
                lat: 4.924,
                lng: 123.834
            },
            {
                lat: 4.779,
                lng: 124.096
            },
            {
                lat: 4.505,
                lng: 124.518
            },
            {
                lat: 4.231,
                lng: 124.949
            },
            {
                lat: 3.916,
                lng: 125.464
            },
            {
                lat: 3.534,
                lng: 126.028
            },
            {
                lat: 3.184,
                lng: 126.455
            },
            {
                lat: 2.833,
                lng: 126.894
            },
            {
                lat: 2.821,
                lng: 126.909
            },
            {
                lat: 2.475,
                lng: 127.267
            },
            {
                lat: 2.128,
                lng: 127.634
            },
            {
                lat: 1.775,
                lng: 127.983
            },
            {
                lat: 1.421,
                lng: 128.343
            },
            {
                lat: 1.302,
                lng: 128.465
            },
            {
                lat: 0.712,
                lng: 129.021
            },
            {
                lat: 0.000,
                lng: 129.163
            },
            {
                lat: -0.713,
                lng: 129.668
            },
            {
                lat: -1.427,
                lng: 129.931
            },
            {
                lat: -2.141,
                lng: 129.961
            },
            {
                lat: -2.855,
                lng: 129.884
            },
            {
                lat: -3.569,
                lng: 129.822
            },
            {
                lat: -4.281,
                lng: 129.498
            },
            {
                lat: -4.991,
                lng: 129.097
            },
            {
                lat: -5.255,
                lng: 128.974
            },
            {
                lat: -5.701,
                lng: 128.689
            },
            {
                lat: -6.408,
                lng: 128.167
            },
            {
                lat: -6.947,
                lng: 127.647
            },
            {
                lat: -7.111,
                lng: 127.430
            },
            {
                lat: -7.464,
                lng: 127.144
            },
            {
                lat: -7.816,
                lng: 126.865
            },
            {
                lat: -8.503,
                lng: 126.445
            },
            {
                lat: -8.523,
                lng: 126.427
            },
            {
                lat: -8.870,
                lng: 125.892
            },
            {
                lat: -9.216,
                lng: 125.372
            },
            {
                lat: -9.404,
                lng: 125.159
            },
            {
                lat: -9.651,
                lng: 124.524
            },
            {
                lat: -9.898,
                lng: 123.905
            },
            {
                lat: -9.937,
                lng: 123.836
            },
            {
                lat: -10.097,
                lng: 123.167
            },
            {
                lat: -10.257,
                lng: 122.514
            },
            {
                lat: -10.257,
                lng: 121.828
            },
            {
                lat: -10.258,
                lng: 121.157
            },
            {
                lat: -10.170,
                lng: 120.476
            },
            {
                lat: -10.083,
                lng: 119.810
            },
            {
                lat: -10.003,
                lng: 119.160
            },
            {
                lat: -9.923,
                lng: 118.523
            },
            {
                lat: -9.835,
                lng: 117.897
            },
            {
                lat: -9.748,
                lng: 117.282
            },
            {
                lat: -9.726,
                lng: 116.693
            },
            {
                lat: -9.704,
                lng: 116.113
            },
            {
                lat: -9.624,
                lng: 114.974
            },
            {
                lat: -9.510,
                lng: 113.861
            },
            {
                lat: -9.432,
                lng: 112.786
            },
            {
                lat: -9.383,
                lng: 111.744
            },
            {
                lat: -9.300,
                lng: 110.722
            },
            {
                lat: -9.218,
                lng: 109.723
            },
            {
                lat: -9.000,
                lng: 108.726
            },
            {
                lat: -8.858,
                lng: 108.270
            },
            {
                lat: -8.695,
                lng: 107.738
            },
            {
                lat: -8.318,
                lng: 106.763
            },
            {
                lat: -8.139,
                lng: 106.410
            },
            {
                lat: -7.785,
                lng: 105.793
            },
            {
                lat: -7.439,
                lng: 105.329
            },
            {
                lat: -7.056,
                lng: 104.826
            },
            {
                lat: -6.747,
                lng: 104.477
            },
            {
                lat: -6.149,
                lng: 103.871
            },
            {
                lat: -6.061,
                lng: 103.794
            },
            {
                lat: -5.718,
                lng: 103.423
            },
            {
                lat: -5.377,
                lng: 103.057
            },
            {
                lat: -5.268,
                lng: 102.948
            },
            {
                lat: -4.697,
                lng: 102.415
            },
            {
                lat: -4.351,
                lng: 102.050
            },
            {
                lat: -4.019,
                lng: 101.714
            },
            {
                lat: -3.526,
                lng: 101.183
            },
            {
                lat: -3.344,
                lng: 100.999
            },
            {
                lat: -2.752,
                lng: 100.339
            },
            {
                lat: -2.671,
                lng: 100.241
            },
            {
                lat: -2.101,
                lng: 99.518
            },
            {
                lat: -2.000,
                lng: 99.398
            },
            {
                lat: -1.333,
                lng: 98.710
            },
            {
                lat: -1.331,
                lng: 98.708
            },
            {
                lat: -0.731,
                lng: 97.920
            },
            {
                lat: -0.665,
                lng: 97.837
            },
            {
                lat: -0.061,
                lng: 97.142
            },
            {
                lat: 0.000,
                lng: 97.078
            },
            {
                lat: 0.663,
                lng: 96.445
            },
            {
                lat: 0.733,
                lng: 96.379
            },
            {
                lat: 1.325,
                lng: 95.865
            },
            {
                lat: 1.635,
                lng: 95.630
            },
            {
                lat: 1.987,
                lng: 95.396
            },
            {
                lat: 2.648,
                lng: 95.027
            },
            {
                lat: 2.953,
                lng: 94.907
            },
            {
                lat: 3.310,
                lng: 94.789
            },
            {
                lat: 3.973,
                lng: 94.582
            },
            {
                lat: 4.637,
                lng: 94.546
            },
            {
                lat: 5.302,
                lng: 94.549
            },
            {
                lat: 5.968,
                lng: 94.585
            },
            {
                lat: 6.638,
                lng: 94.778
            },
            {
                lat: 7.308,
                lng: 94.910
            },
            {
                lat: 7.806,
                lng: 95.155
            },
            {
                lat: 7.983,
                lng: 95.261
            },
            {
                lat: 8.658,
                lng: 95.454
            },
            {
                lat: 9.337,
                lng: 95.782
            },
            {
                lat: 10.010,
                lng: 96.114
            },
            {
                lat: 10.019,
                lng: 96.119
            },
            {
                lat: 10.699,
                lng: 96.222
            },
            {
                lat: 11.385,
                lng: 96.419
            },
            {
                lat: 12.069,
                lng: 96.443
            },
            {
                lat: 12.757,
                lng: 96.491
            },
            {
                lat: 12.894,
                lng: 96.449
            },
            {
                lat: 13.444,
                lng: 96.332
            },
            {
                lat: 14.138,
                lng: 96.403
            },
            {
                lat: 14.832,
                lng: 96.329
            },
            {
                lat: 15.527,
                lng: 96.166
            },
            {
                lat: 16.230,
                lng: 96.201
            },
            {
                lat: 16.385,
                lng: 96.183
            },
            {
                lat: 16.935,
                lng: 96.142
            },
            {
                lat: 17.645,
                lng: 96.146
            },
            {
                lat: 18.362,
                lng: 96.265
            },
            {
                lat: 19.085,
                lng: 96.395
            },
            {
                lat: 19.811,
                lng: 96.500
            },
            {
                lat: 20.547,
                lng: 96.720
            },
            {
                lat: 21.264,
                lng: 97.150
            },
            {
                lat: 21.295,
                lng: 97.171
            },
            {
                lat: 22.043,
                lng: 97.405
            },
            {
                lat: 22.428,
                lng: 97.768
            },
            {
                lat: 22.815,
                lng: 98.136
            },
            {
                lat: 23.127,
                lng: 98.458
            },
            {
                lat: 23.604,
                lng: 99.105
            },
            {
                lat: 23.995,
                lng: 99.569
            },
            {
                lat: 24.414,
                lng: 100.333
            },
            {
                lat: 24.509,
                lng: 100.612
            },
            {
                lat: 24.709,
                lng: 101.580
            },
            {
                lat: 24.771,
                lng: 102.520
            },
            {
                lat: 24.622,
                lng: 103.399
            },
            {
                lat: 24.556,
                lng: 103.660
            },
        ],
        c13: [{
                lat: 5.447,
                lng: 109.623
            },
            {
                lat: 5.194,
                lng: 109.217
            },
            {
                lat: 5.440,
                lng: 109.028
            },
            {
                lat: 5.554,
                lng: 109.250
            },
            {
                lat: 5.447,
                lng: 109.623
            },
        ],
        c14: [{
                lat: 4.680,
                lng: 100.380
            },
            {
                lat: 4.645,
                lng: 100.424
            },
            {
                lat: 4.013,
                lng: 100.885
            },
            {
                lat: 3.581,
                lng: 101.186
            },
            {
                lat: 3.346,
                lng: 101.349
            },
            {
                lat: 2.679,
                lng: 101.936
            },
            {
                lat: 2.640,
                lng: 101.973
            },
            {
                lat: 2.011,
                lng: 102.592
            },
            {
                lat: 1.781,
                lng: 102.783
            },
            {
                lat: 1.342,
                lng: 103.099
            },
            {
                lat: 0.758,
                lng: 103.612
            },
            {
                lat: 0.672,
                lng: 103.715
            },
            {
                lat: 0.236,
                lng: 104.467
            },
            {
                lat: 0.000,
                lng: 104.929
            },
            {
                lat: -0.254,
                lng: 105.340
            },
            {
                lat: -0.475,
                lng: 106.228
            },
            {
                lat: -0.676,
                lng: 107.106
            },
            {
                lat: -0.684,
                lng: 107.133
            },
            {
                lat: -0.851,
                lng: 108.054
            },
            {
                lat: -0.874,
                lng: 108.991
            },
            {
                lat: -0.811,
                lng: 109.947
            },
            {
                lat: -0.681,
                lng: 110.506
            },
            {
                lat: -0.564,
                lng: 110.921
            },
            {
                lat: -0.226,
                lng: 111.917
            },
            {
                lat: 0.000,
                lng: 112.316
            },
            {
                lat: 0.228,
                lng: 112.941
            },
            {
                lat: 0.405,
                lng: 113.463
            },
            {
                lat: 0.583,
                lng: 113.993
            },
            {
                lat: 0.686,
                lng: 114.242
            },
            {
                lat: 1.114,
                lng: 115.082
            },
            {
                lat: 1.377,
                lng: 115.614
            },
            {
                lat: 1.655,
                lng: 116.209
            },
            {
                lat: 1.864,
                lng: 116.740
            },
            {
                lat: 2.074,
                lng: 117.280
            },
            {
                lat: 2.109,
                lng: 117.376
            },
            {
                lat: 2.292,
                lng: 118.573
            },
            {
                lat: 2.294,
                lng: 119.803
            },
            {
                lat: 2.198,
                lng: 120.431
            },
            {
                lat: 2.101,
                lng: 121.072
            },
            {
                lat: 2.093,
                lng: 121.093
            },
            {
                lat: 1.875,
                lng: 121.730
            },
            {
                lat: 1.656,
                lng: 122.382
            },
            {
                lat: 1.402,
                lng: 122.869
            },
            {
                lat: 1.052,
                lng: 123.204
            },
            {
                lat: 0.702,
                lng: 123.547
            },
            {
                lat: 0.571,
                lng: 123.743
            },
            {
                lat: 0.000,
                lng: 124.407
            },
            {
                lat: -0.352,
                lng: 124.715
            },
            {
                lat: -0.705,
                lng: 125.031
            },
            {
                lat: -1.225,
                lng: 125.233
            },
            {
                lat: -1.410,
                lng: 125.298
            },
            {
                lat: -2.116,
                lng: 125.352
            },
            {
                lat: -2.822,
                lng: 125.346
            },
            {
                lat: -2.841,
                lng: 125.343
            },
            {
                lat: -3.527,
                lng: 125.193
            },
            {
                lat: -3.876,
                lng: 124.766
            },
            {
                lat: -4.225,
                lng: 124.349
            },
            {
                lat: -4.579,
                lng: 124.066
            },
            {
                lat: -4.923,
                lng: 123.716
            },
            {
                lat: -5.225,
                lng: 123.250
            },
            {
                lat: -5.527,
                lng: 122.793
            },
            {
                lat: -5.614,
                lng: 122.626
            },
            {
                lat: -5.835,
                lng: 122.069
            },
            {
                lat: -6.055,
                lng: 121.523
            },
            {
                lat: -6.287,
                lng: 120.595
            },
            {
                lat: -6.371,
                lng: 120.272
            },
            {
                lat: -6.557,
                lng: 119.049
            },
            {
                lat: -6.630,
                lng: 117.851
            },
            {
                lat: -6.678,
                lng: 116.689
            },
            {
                lat: -6.750,
                lng: 115.568
            },
            {
                lat: -6.711,
                lng: 114.463
            },
            {
                lat: -6.757,
                lng: 113.400
            },
            {
                lat: -6.696,
                lng: 112.351
            },
            {
                lat: -6.717,
                lng: 111.338
            },
            {
                lat: -6.676,
                lng: 110.340
            },
            {
                lat: -6.476,
                lng: 109.346
            },
            {
                lat: -6.201,
                lng: 108.367
            },
            {
                lat: -6.113,
                lng: 108.149
            },
            {
                lat: -5.793,
                lng: 107.397
            },
            {
                lat: -5.414,
                lng: 106.675
            },
            {
                lat: -5.253,
                lng: 106.439
            },
            {
                lat: -4.727,
                lng: 105.804
            },
            {
                lat: -4.285,
                lng: 105.476
            },
            {
                lat: -4.046,
                lng: 105.275
            },
            {
                lat: -3.367,
                lng: 104.715
            },
            {
                lat: -3.184,
                lng: 104.539
            },
            {
                lat: -2.689,
                lng: 103.968
            },
            {
                lat: -2.384,
                lng: 103.647
            },
            {
                lat: -2.013,
                lng: 103.219
            },
            {
                lat: -1.341,
                lng: 102.958
            },
            {
                lat: -1.214,
                lng: 102.772
            },
            {
                lat: -0.789,
                lng: 101.932
            },
            {
                lat: -0.669,
                lng: 101.709
            },
            {
                lat: -0.226,
                lng: 101.106
            },
            {
                lat: 0.000,
                lng: 100.719
            },
            {
                lat: 0.249,
                lng: 100.294
            },
            {
                lat: 0.667,
                lng: 99.783
            },
            {
                lat: 0.890,
                lng: 99.497
            },
            {
                lat: 1.332,
                lng: 98.841
            },
            {
                lat: 1.436,
                lng: 98.712
            },
            {
                lat: 1.996,
                lng: 98.226
            },
            {
                lat: 2.278,
                lng: 97.945
            },
            {
                lat: 2.659,
                lng: 97.608
            },
            {
                lat: 3.324,
                lng: 97.546
            },
            {
                lat: 3.989,
                lng: 97.343
            },
            {
                lat: 4.660,
                lng: 97.795
            },
            {
                lat: 4.982,
                lng: 98.052
            },
            {
                lat: 5.137,
                lng: 98.849
            },
            {
                lat: 5.007,
                lng: 99.638
            },
            {
                lat: 4.680,
                lng: 100.380
            },
        ]
    };

    export var utelsatgain = {
        "c1": '38',
        "c2": '40',
        "c3": '42',
        "c4": '42',
        "c5": '44',
        "c6": '44',
        "c7": '46',
        "c8": '46',
        "c9": '46',
        "c10": '46',
        "c11": '48',
        "c12": '50',
        "c13": '50',
        "c14": '51',
    };

    // uplink
    export var utelsat_gnt = {
        c1: [{
                lat: 48.968,
                lng: 140.863
            },
            {
                lat: 49.042,
                lng: 141.683
            },
            {
                lat: 49.135,
                lng: 142.659
            },
            {
                lat: 49.263,
                lng: 143.932
            },
            {
                lat: 49.358,
                lng: 144.834
            },
            {
                lat: 49.428,
                lng: 145.472
            },
            {
                lat: 49.590,
                lng: 146.889
            },
        ],
        c2: [{
                lat: 25.526,
                lng: 150.734
            },
            {
                lat: 25.435,
                lng: 149.902
            },
            {
                lat: 25.374,
                lng: 149.516
            },
            {
                lat: 25.266,
                lng: 148.974
            },
            {
                lat: 25.073,
                lng: 148.218
            },
            {
                lat: 24.893,
                lng: 147.649
            },
            {
                lat: 24.719,
                lng: 147.177
            },
            {
                lat: 24.257,
                lng: 146.401
            },
            {
                lat: 23.808,
                lng: 145.792
            },
            {
                lat: 23.179,
                lng: 145.414
            },
            {
                lat: 22.962,
                lng: 145.304
            },
            {
                lat: 22.134,
                lng: 145.005
            },
            {
                lat: 21.735,
                lng: 145.084
            },
            {
                lat: 21.340,
                lng: 145.194
            },
            {
                lat: 21.141,
                lng: 145.248
            },
            {
                lat: 20.558,
                lng: 145.526
            },
            {
                lat: 20.170,
                lng: 145.746
            },
            {
                lat: 19.786,
                lng: 146.005
            },
            {
                lat: 19.282,
                lng: 146.219
            },
            {
                lat: 19.016,
                lng: 146.461
            },
            {
                lat: 18.629,
                lng: 146.633
            },
            {
                lat: 18.245,
                lng: 146.848
            },
            {
                lat: 17.453,
                lng: 146.739
            },
            {
                lat: 17.012,
                lng: 146.654
            },
            {
                lat: 16.662,
                lng: 146.575
            },
            {
                lat: 16.258,
                lng: 146.247
            },
            {
                lat: 15.857,
                lng: 145.971
            },
            {
                lat: 15.066,
                lng: 145.570
            },
            {
                lat: 14.276,
                lng: 145.064
            },
            {
                lat: 13.500,
                lng: 144.834
            },
            {
                lat: 13.376,
                lng: 144.795
            },
            {
                lat: 12.728,
                lng: 144.610
            },
            {
                lat: 11.971,
                lng: 144.740
            },
            {
                lat: 11.598,
                lng: 144.963
            },
            {
                lat: 11.227,
                lng: 145.215
            },
            {
                lat: 10.855,
                lng: 145.466
            },
            {
                lat: 10.484,
                lng: 145.751
            },
            {
                lat: 10.395,
                lng: 145.966
            },
            {
                lat: 10.087,
                lng: 146.830
            },
            {
                lat: 9.783,
                lng: 147.915
            },
            {
                lat: 9.540,
                lng: 149.003
            },
            {
                lat: 9.424,
                lng: 149.781
            },
            {
                lat: 9.370,
                lng: 150.334
            },
            {
                lat: 9.332,
                lng: 151.571
            },
        ],
        c3: [{
                lat: -46.523,
                lng: 147.657
            },
            {
                lat: -46.378,
                lng: 146.341
            },
            {
                lat: -46.313,
                lng: 145.737
            },
            {
                lat: -46.223,
                lng: 144.883
            },
            {
                lat: -46.099,
                lng: 143.678
            },
            {
                lat: -46.007,
                lng: 142.756
            },
            {
                lat: -45.931,
                lng: 141.980
            },
            {
                lat: -45.809,
                lng: 140.680
            },
            {
                lat: -45.710,
                lng: 139.588
            },
            {
                lat: -45.590,
                lng: 138.191
            },
            {
                lat: -45.491,
                lng: 136.976
            },
            {
                lat: -45.407,
                lng: 135.888
            },
            {
                lat: -45.333,
                lng: 134.893
            },
            {
                lat: -45.240,
                lng: 133.900
            },
            {
                lat: -45.156,
                lng: 132.975
            },
            {
                lat: -45.077,
                lng: 132.107
            },
            {
                lat: -45.005,
                lng: 131.287
            },
            {
                lat: -44.904,
                lng: 130.436
            },
            {
                lat: -44.809,
                lng: 129.627
            },
            {
                lat: -44.718,
                lng: 128.856
            },
            {
                lat: -44.631,
                lng: 128.116
            },
            {
                lat: -44.509,
                lng: 127.334
            },
            {
                lat: -44.391,
                lng: 126.585
            },
            {
                lat: -44.277,
                lng: 125.864
            },
            {
                lat: -44.166,
                lng: 125.168
            },
            {
                lat: -44.022,
                lng: 124.438
            },
            {
                lat: -43.882,
                lng: 123.734
            },
            {
                lat: -43.744,
                lng: 123.054
            },
            {
                lat: -43.610,
                lng: 122.395
            },
            {
                lat: -43.589,
                lng: 122.316
            },
            {
                lat: -43.395,
                lng: 121.603
            },
            {
                lat: -43.206,
                lng: 120.915
            },
            {
                lat: -43.019,
                lng: 120.249
            },
            {
                lat: -42.836,
                lng: 119.604
            },
            {
                lat: -42.456,
                lng: 118.514
            },
            {
                lat: -42.086,
                lng: 117.480
            },
            {
                lat: -41.786,
                lng: 116.773
            },
            {
                lat: -41.313,
                lng: 115.840
            },
            {
                lat: -40.849,
                lng: 114.953
            },
            {
                lat: -40.488,
                lng: 114.291
            },
            {
                lat: -40.132,
                lng: 113.652
            },
            {
                lat: -39.692,
                lng: 112.946
            },
            {
                lat: -39.150,
                lng: 112.247
            },
            {
                lat: -38.617,
                lng: 111.580
            },
            {
                lat: -38.121,
                lng: 110.904
            },
            {
                lat: -37.634,
                lng: 110.256
            },
            {
                lat: -37.562,
                lng: 110.166
            },
            {
                lat: -37.054,
                lng: 109.607
            },
            {
                lat: -36.553,
                lng: 109.071
            },
            {
                lat: -36.057,
                lng: 108.525
            },
            {
                lat: -35.567,
                lng: 107.999
            },
            {
                lat: -35.088,
                lng: 107.573
            },
            {
                lat: -34.615,
                lng: 107.161
            },
            {
                lat: -34.043,
                lng: 106.712
            },
            {
                lat: -33.687,
                lng: 106.440
            },
            {
                lat: -32.782,
                lng: 105.850
            },
            {
                lat: -31.901,
                lng: 105.424
            },
            {
                lat: -31.034,
                lng: 105.008
            },
            {
                lat: -30.643,
                lng: 104.912
            },
            {
                lat: -30.189,
                lng: 104.807
            },
            {
                lat: -29.352,
                lng: 104.529
            },
            {
                lat: -28.537,
                lng: 104.506
            },
            {
                lat: -27.732,
                lng: 104.498
            },
            {
                lat: -27.415,
                lng: 104.469
            },
            {
                lat: -26.933,
                lng: 104.436
            },
            {
                lat: -26.151,
                lng: 104.547
            },
            {
                lat: -25.369,
                lng: 104.522
            },
            {
                lat: -25.114,
                lng: 104.528
            },
            {
                lat: -24.596,
                lng: 104.552
            },
            {
                lat: -23.833,
                lng: 104.622
            },
            {
                lat: -23.268,
                lng: 104.811
            },
            {
                lat: -23.082,
                lng: 104.857
            },
            {
                lat: -22.333,
                lng: 105.004
            },
            {
                lat: -21.620,
                lng: 105.199
            },
            {
                lat: -21.592,
                lng: 105.205
            },
            {
                lat: -20.863,
                lng: 105.588
            },
            {
                lat: -20.532,
                lng: 105.794
            },
            {
                lat: -20.141,
                lng: 106.042
            },
            {
                lat: -19.659,
                lng: 106.470
            },
            {
                lat: -19.429,
                lng: 106.655
            },
            {
                lat: -18.845,
                lng: 107.177
            },
            {
                lat: -18.720,
                lng: 107.246
            },
            {
                lat: -18.003,
                lng: 107.552
            },
            {
                lat: -17.297,
                lng: 107.681
            },
            {
                lat: -17.284,
                lng: 107.682
            },
            {
                lat: -17.181,
                lng: 107.648
            },
            {
                lat: -16.556,
                lng: 107.446
            },
            {
                lat: -15.832,
                lng: 107.198
            },
            {
                lat: -15.104,
                lng: 106.689
            },
            {
                lat: -14.382,
                lng: 106.215
            },
            {
                lat: -14.082,
                lng: 105.936
            },
            {
                lat: -13.660,
                lng: 105.547
            },
            {
                lat: -12.945,
                lng: 104.952
            },
            {
                lat: -12.783,
                lng: 104.769
            },
            {
                lat: -12.230,
                lng: 104.173
            },
            {
                lat: -11.771,
                lng: 103.708
            },
            {
                lat: -11.521,
                lng: 103.440
            },
            {
                lat: -10.855,
                lng: 102.702
            },
            {
                lat: -10.815,
                lng: 102.659
            },
            {
                lat: -10.464,
                lng: 102.267
            },
            {
                lat: -10.114,
                lng: 101.879
            },
            {
                lat: -9.996,
                lng: 101.738
            },
            {
                lat: -9.417,
                lng: 101.048
            },
            {
                lat: -9.213,
                lng: 100.811
            },
            {
                lat: -8.724,
                lng: 100.234
            },
            {
                lat: -8.411,
                lng: 99.908
            },
            {
                lat: -8.036,
                lng: 99.515
            },
            {
                lat: -7.599,
                lng: 99.027
            },
            {
                lat: -7.351,
                lng: 98.742
            },
            {
                lat: -6.771,
                lng: 98.167
            },
            {
                lat: -6.671,
                lng: 98.068
            },
            {
                lat: -6.334,
                lng: 97.698
            },
            {
                lat: -5.998,
                lng: 97.332
            },
            {
                lat: -5.993,
                lng: 97.326
            },
            {
                lat: -5.319,
                lng: 96.690
            },
            {
                lat: -5.112,
                lng: 96.509
            },
            {
                lat: -4.647,
                lng: 96.085
            },
            {
                lat: -4.172,
                lng: 95.702
            },
            {
                lat: -3.978,
                lng: 95.536
            },
            {
                lat: -3.311,
                lng: 94.994
            },
            {
                lat: -3.198,
                lng: 94.914
            },
            {
                lat: -2.646,
                lng: 94.496
            },
            {
                lat: -2.232,
                lng: 94.144
            },
            {
                lat: -1.983,
                lng: 93.919
            },
            {
                lat: -1.321,
                lng: 93.454
            },
            {
                lat: -1.244,
                lng: 93.390
            },
            {
                lat: -0.660,
                lng: 92.889
            },
            {
                lat: -0.384,
                lng: 92.653
            },
            {
                lat: 0.000,
                lng: 92.313
            },
            {
                lat: 0.500,
                lng: 91.927
            },
            {
                lat: 0.659,
                lng: 91.802
            },
            {
                lat: 1.317,
                lng: 91.222
            },
            {
                lat: 1.330,
                lng: 91.213
            },
            {
                lat: 1.974,
                lng: 90.762
            },
            {
                lat: 2.316,
                lng: 90.512
            },
            {
                lat: 2.632,
                lng: 90.284
            },
            {
                lat: 3.289,
                lng: 89.911
            },
            {
                lat: 3.452,
                lng: 89.825
            },
            {
                lat: 3.947,
                lng: 89.547
            },
            {
                lat: 4.605,
                lng: 89.260
            },
            {
                lat: 4.871,
                lng: 89.160
            },
            {
                lat: 5.264,
                lng: 88.996
            },
            {
                lat: 5.924,
                lng: 88.737
            },
            {
                lat: 6.523,
                lng: 88.519
            },
            {
                lat: 6.584,
                lng: 88.492
            },
            {
                lat: 7.245,
                lng: 88.143
            },
            {
                lat: 7.779,
                lng: 87.875
            },
            {
                lat: 7.907,
                lng: 87.788
            },
            {
                lat: 8.569,
                lng: 87.366
            },
            {
                lat: 8.796,
                lng: 87.228
            },
            {
                lat: 9.233,
                lng: 86.900
            },
            {
                lat: 9.899,
                lng: 86.641
            },
            {
                lat: 10.060,
                lng: 86.600
            },
            {
                lat: 10.569,
                lng: 86.453
            },
            {
                lat: 11.243,
                lng: 86.551
            },
            {
                lat: 11.588,
                lng: 86.699
            },
            {
                lat: 11.923,
                lng: 86.838
            },
            {
                lat: 12.609,
                lng: 87.437
            },
            {
                lat: 12.652,
                lng: 87.488
            },
            {
                lat: 13.252,
                lng: 88.254
            },
            {
                lat: 13.303,
                lng: 88.311
            },
            {
                lat: 13.999,
                lng: 88.957
            },
            {
                lat: 14.192,
                lng: 89.066
            },
            {
                lat: 14.693,
                lng: 89.258
            },
            {
                lat: 15.386,
                lng: 89.268
            },
            {
                lat: 15.775,
                lng: 89.234
            },
            {
                lat: 16.081,
                lng: 89.210
            },
            {
                lat: 16.776,
                lng: 88.955
            },
            {
                lat: 17.468,
                lng: 88.695
            },
            {
                lat: 17.474,
                lng: 88.693
            },
            {
                lat: 18.174,
                lng: 88.323
            },
            {
                lat: 18.602,
                lng: 88.096
            },
            {
                lat: 18.878,
                lng: 87.944
            },
            {
                lat: 19.587,
                lng: 87.581
            },
            {
                lat: 19.746,
                lng: 87.500
            },
            {
                lat: 20.299,
                lng: 87.207
            },
            {
                lat: 21.019,
                lng: 86.940
            },
            {
                lat: 21.059,
                lng: 86.927
            },
            {
                lat: 21.744,
                lng: 86.688
            },
            {
                lat: 22.478,
                lng: 86.559
            },
            {
                lat: 23.138,
                lng: 86.462
            },
            {
                lat: 23.217,
                lng: 86.449
            },
            {
                lat: 23.966,
                lng: 86.477
            },
            {
                lat: 24.723,
                lng: 86.554
            },
            {
                lat: 25.488,
                lng: 86.689
            },
            {
                lat: 26.147,
                lng: 86.927
            },
            {
                lat: 26.265,
                lng: 86.972
            },
            {
                lat: 27.052,
                lng: 87.315
            },
            {
                lat: 27.854,
                lng: 87.864
            },
            {
                lat: 28.119,
                lng: 88.075
            },
            {
                lat: 28.669,
                lng: 88.526
            },
            {
                lat: 29.276,
                lng: 89.125
            },
            {
                lat: 29.500,
                lng: 89.367
            },
            {
                lat: 30.127,
                lng: 90.147
            },
            {
                lat: 30.353,
                lng: 90.449
            },
            {
                lat: 30.950,
                lng: 91.195
            },
            {
                lat: 31.220,
                lng: 91.552
            },
            {
                lat: 31.696,
                lng: 92.258
            },
            {
                lat: 32.114,
                lng: 92.926
            },
            {
                lat: 32.439,
                lng: 93.355
            },
            {
                lat: 33.018,
                lng: 94.140
            },
            {
                lat: 33.262,
                lng: 94.517
            },
            {
                lat: 33.594,
                lng: 95.087
            },
            {
                lat: 33.930,
                lng: 95.667
            },
            {
                lat: 33.957,
                lng: 95.717
            },
            {
                lat: 34.343,
                lng: 96.307
            },
            {
                lat: 34.733,
                lng: 96.911
            },
            {
                lat: 34.912,
                lng: 97.216
            },
            {
                lat: 35.219,
                lng: 97.706
            },
            {
                lat: 35.528,
                lng: 98.204
            },
            {
                lat: 35.894,
                lng: 98.801
            },
            {
                lat: 36.356,
                lng: 99.569
            },
            {
                lat: 36.907,
                lng: 100.507
            },
            {
                lat: 37.276,
                lng: 101.048
            },
            {
                lat: 37.602,
                lng: 101.525
            },
            {
                lat: 37.931,
                lng: 102.012
            },
            {
                lat: 38.418,
                lng: 102.736
            },
            {
                lat: 38.982,
                lng: 103.525
            },
            {
                lat: 39.486,
                lng: 104.166
            },
            {
                lat: 39.998,
                lng: 104.831
            },
            {
                lat: 40.049,
                lng: 104.899
            },
            {
                lat: 40.600,
                lng: 105.672
            },
            {
                lat: 41.160,
                lng: 106.480
            },
            {
                lat: 41.628,
                lng: 107.181
            },
            {
                lat: 41.971,
                lng: 107.717
            },
            {
                lat: 42.317,
                lng: 108.268
            },
            {
                lat: 42.668,
                lng: 108.921
            },
            {
                lat: 43.023,
                lng: 109.596
            },
            {
                lat: 43.559,
                lng: 110.635
            },
            {
                lat: 43.886,
                lng: 111.344
            },
            {
                lat: 44.217,
                lng: 112.078
            },
            {
                lat: 44.563,
                lng: 112.912
            },
            {
                lat: 44.915,
                lng: 113.781
            },
            {
                lat: 45.193,
                lng: 114.580
            },
            {
                lat: 45.569,
                lng: 115.791
            },
            {
                lat: 45.957,
                lng: 117.072
            },
            {
                lat: 46.270,
                lng: 118.313
            },
            {
                lat: 46.594,
                lng: 119.628
            },
            {
                lat: 46.601,
                lng: 119.661
            },
            {
                lat: 46.858,
                lng: 120.930
            },
            {
                lat: 47.127,
                lng: 122.278
            },
            {
                lat: 47.337,
                lng: 123.608
            },
            {
                lat: 47.559,
                lng: 125.030
            },
            {
                lat: 47.730,
                lng: 126.433
            },
            {
                lat: 47.821,
                lng: 127.176
            },
            {
                lat: 47.915,
                lng: 127.950
            },
            {
                lat: 47.988,
                lng: 128.699
            },
            {
                lat: 48.064,
                lng: 129.483
            },
            {
                lat: 48.146,
                lng: 130.306
            },
            {
                lat: 48.233,
                lng: 131.173
            },
            {
                lat: 48.289,
                lng: 131.996
            },
            {
                lat: 48.351,
                lng: 132.869
            },
            {
                lat: 48.420,
                lng: 133.800
            },
            {
                lat: 48.498,
                lng: 134.805
            },
            {
                lat: 48.568,
                lng: 135.838
            },
            {
                lat: 48.650,
                lng: 136.979
            },
            {
                lat: 48.751,
                lng: 138.271
            },
            {
                lat: 48.811,
                lng: 138.997
            },
            {
                lat: 48.879,
                lng: 139.797
            },
            {
                lat: 48.968,
                lng: 140.863
            },
        ],
        c4: [{
                lat: 46.796,
                lng: 132.005
            },
            {
                lat: 46.869,
                lng: 133.179
            },
            {
                lat: 46.901,
                lng: 133.994
            },
            {
                lat: 46.938,
                lng: 134.861
            },
            {
                lat: 46.984,
                lng: 135.793
            },
            {
                lat: 47.038,
                lng: 136.808
            },
            {
                lat: 47.077,
                lng: 137.830
            },
            {
                lat: 47.130,
                lng: 138.975
            },
            {
                lat: 47.203,
                lng: 140.300
            },
            {
                lat: 47.252,
                lng: 141.066
            },
            {
                lat: 47.313,
                lng: 141.937
            },
            {
                lat: 47.364,
                lng: 142.681
            },
            {
                lat: 47.431,
                lng: 143.564
            },
            {
                lat: 47.530,
                lng: 144.716
            },
            {
                lat: 47.606,
                lng: 145.531
            },
            {
                lat: 47.664,
                lng: 146.108
            },
            {
                lat: 47.809,
                lng: 147.441
            },
        ],
        c5: [{
                lat: 28.130,
                lng: 150.515
            },
            {
                lat: 28.038,
                lng: 149.536
            },
            {
                lat: 27.985,
                lng: 149.090
            },
            {
                lat: 27.898,
                lng: 148.460
            },
            {
                lat: 27.752,
                lng: 147.576
            },
            {
                lat: 27.622,
                lng: 146.903
            },
            {
                lat: 27.499,
                lng: 146.340
            },
            {
                lat: 27.269,
                lng: 145.405
            },
            {
                lat: 27.051,
                lng: 144.626
            },
            {
                lat: 26.795,
                lng: 143.970
            },
            {
                lat: 26.545,
                lng: 143.387
            },
            {
                lat: 26.300,
                lng: 142.858
            },
            {
                lat: 26.059,
                lng: 142.374
            },
            {
                lat: 25.667,
                lng: 141.755
            },
            {
                lat: 25.281,
                lng: 141.199
            },
            {
                lat: 25.141,
                lng: 141.001
            },
            {
                lat: 24.705,
                lng: 140.592
            },
            {
                lat: 24.273,
                lng: 140.224
            },
            {
                lat: 23.429,
                lng: 139.679
            },
            {
                lat: 22.614,
                lng: 139.493
            },
            {
                lat: 22.572,
                lng: 139.483
            },
            {
                lat: 21.804,
                lng: 139.296
            },
            {
                lat: 21.007,
                lng: 139.245
            },
            {
                lat: 20.412,
                lng: 139.307
            },
            {
                lat: 20.225,
                lng: 139.349
            },
            {
                lat: 19.451,
                lng: 139.554
            },
            {
                lat: 18.678,
                lng: 139.706
            },
            {
                lat: 18.453,
                lng: 139.765
            },
            {
                lat: 17.911,
                lng: 139.905
            },
            {
                lat: 17.140,
                lng: 139.941
            },
            {
                lat: 16.371,
                lng: 139.947
            },
            {
                lat: 16.043,
                lng: 139.936
            },
            {
                lat: 15.603,
                lng: 139.914
            },
            {
                lat: 14.837,
                lng: 139.831
            },
            {
                lat: 14.074,
                lng: 139.731
            },
            {
                lat: 13.316,
                lng: 139.702
            },
            {
                lat: 12.793,
                lng: 139.663
            },
            {
                lat: 12.560,
                lng: 139.643
            },
            {
                lat: 11.811,
                lng: 139.702
            },
            {
                lat: 11.072,
                lng: 140.054
            },
            {
                lat: 10.331,
                lng: 140.299
            },
            {
                lat: 10.065,
                lng: 140.662
            },
            {
                lat: 9.611,
                lng: 141.298
            },
            {
                lat: 9.248,
                lng: 141.708
            },
            {
                lat: 8.885,
                lng: 142.149
            },
            {
                lat: 8.596,
                lng: 142.710
            },
            {
                lat: 8.308,
                lng: 143.318
            },
            {
                lat: 8.172,
                lng: 143.645
            },
            {
                lat: 7.817,
                lng: 144.582
            },
            {
                lat: 7.641,
                lng: 145.107
            },
            {
                lat: 7.465,
                lng: 145.684
            },
            {
                lat: 7.296,
                lng: 146.255
            },
            {
                lat: 7.129,
                lng: 146.899
            },
            {
                lat: 6.962,
                lng: 147.651
            },
            {
                lat: 6.799,
                lng: 148.589
            },
            {
                lat: 6.771,
                lng: 148.783
            },
            {
                lat: 6.687,
                lng: 149.644
            },
            {
                lat: 6.648,
                lng: 150.255
            },
            {
                lat: 6.631,
                lng: 150.687
            },
            {
                lat: 6.623,
                lng: 151.635
            },
        ],
        c6: [{
                lat: -44.661,
                lng: 148.188
            },
            {
                lat: -44.522,
                lng: 146.909
            },
            {
                lat: -44.463,
                lng: 146.361
            },
            {
                lat: -44.379,
                lng: 145.586
            },
            {
                lat: -44.261,
                lng: 144.495
            },
            {
                lat: -44.170,
                lng: 143.660
            },
            {
                lat: -44.094,
                lng: 142.958
            },
            {
                lat: -43.968,
                lng: 141.783
            },
            {
                lat: -43.862,
                lng: 140.797
            },
            {
                lat: -43.768,
                lng: 139.917
            },
            {
                lat: -43.685,
                lng: 139.124
            },
            {
                lat: -43.608,
                lng: 138.397
            },
            {
                lat: -43.538,
                lng: 137.723
            },
            {
                lat: -43.410,
                lng: 136.494
            },
            {
                lat: -43.295,
                lng: 135.387
            },
            {
                lat: -43.192,
                lng: 134.373
            },
            {
                lat: -43.096,
                lng: 133.432
            },
            {
                lat: -43.007,
                lng: 132.550
            },
            {
                lat: -42.924,
                lng: 131.718
            },
            {
                lat: -42.850,
                lng: 130.936
            },
            {
                lat: -42.779,
                lng: 130.190
            },
            {
                lat: -42.713,
                lng: 129.475
            },
            {
                lat: -42.649,
                lng: 128.788
            },
            {
                lat: -42.508,
                lng: 127.445
            },
            {
                lat: -42.377,
                lng: 126.188
            },
            {
                lat: -42.225,
                lng: 124.959
            },
            {
                lat: -42.082,
                lng: 123.796
            },
            {
                lat: -41.849,
                lng: 122.554
            },
            {
                lat: -41.625,
                lng: 121.378
            },
            {
                lat: -41.466,
                lng: 120.725
            },
            {
                lat: -41.310,
                lng: 120.090
            },
            {
                lat: -41.018,
                lng: 118.978
            },
            {
                lat: -40.760,
                lng: 118.288
            },
            {
                lat: -40.506,
                lng: 117.621
            },
            {
                lat: -40.256,
                lng: 116.975
            },
            {
                lat: -40.010,
                lng: 116.349
            },
            {
                lat: -39.973,
                lng: 116.269
            },
            {
                lat: -39.394,
                lng: 115.215
            },
            {
                lat: -38.829,
                lng: 114.222
            },
            {
                lat: -38.322,
                lng: 113.362
            },
            {
                lat: -37.737,
                lng: 112.459
            },
            {
                lat: -37.214,
                lng: 111.739
            },
            {
                lat: -36.698,
                lng: 111.050
            },
            {
                lat: -36.179,
                lng: 110.396
            },
            {
                lat: -35.694,
                lng: 109.821
            },
            {
                lat: -35.206,
                lng: 109.296
            },
            {
                lat: -34.724,
                lng: 108.790
            },
            {
                lat: -34.254,
                lng: 108.393
            },
            {
                lat: -33.788,
                lng: 108.009
            },
            {
                lat: -33.327,
                lng: 107.632
            },
            {
                lat: -32.871,
                lng: 107.267
            },
            {
                lat: -31.986,
                lng: 106.831
            },
            {
                lat: -31.117,
                lng: 106.436
            },
            {
                lat: -30.268,
                lng: 106.200
            },
            {
                lat: -29.432,
                lng: 105.997
            },
            {
                lat: -28.684,
                lng: 106.025
            },
            {
                lat: -28.618,
                lng: 106.029
            },
            {
                lat: -27.811,
                lng: 106.017
            },
            {
                lat: -27.018,
                lng: 106.102
            },
            {
                lat: -26.729,
                lng: 106.160
            },
            {
                lat: -26.235,
                lng: 106.266
            },
            {
                lat: -25.451,
                lng: 106.235
            },
            {
                lat: -24.788,
                lng: 106.351
            },
            {
                lat: -24.681,
                lng: 106.370
            },
            {
                lat: -23.916,
                lng: 106.450
            },
            {
                lat: -23.162,
                lng: 106.670
            },
            {
                lat: -23.108,
                lng: 106.686
            },
            {
                lat: -22.413,
                lng: 106.865
            },
            {
                lat: -21.682,
                lng: 107.147
            },
            {
                lat: -21.674,
                lng: 107.150
            },
            {
                lat: -20.944,
                lng: 107.566
            },
            {
                lat: -20.633,
                lng: 107.761
            },
            {
                lat: -20.219,
                lng: 107.989
            },
            {
                lat: -19.575,
                lng: 108.390
            },
            {
                lat: -19.498,
                lng: 108.426
            },
            {
                lat: -18.775,
                lng: 108.692
            },
            {
                lat: -18.048,
                lng: 108.790
            },
            {
                lat: -17.695,
                lng: 108.776
            },
            {
                lat: -17.321,
                lng: 108.755
            },
            {
                lat: -16.712,
                lng: 108.485
            },
            {
                lat: -16.589,
                lng: 108.433
            },
            {
                lat: -15.861,
                lng: 108.120
            },
            {
                lat: -15.131,
                lng: 107.579
            },
            {
                lat: -14.406,
                lng: 107.031
            },
            {
                lat: -14.275,
                lng: 106.905
            },
            {
                lat: -13.682,
                lng: 106.347
            },
            {
                lat: -12.964,
                lng: 105.725
            },
            {
                lat: -12.943,
                lng: 105.700
            },
            {
                lat: -12.594,
                lng: 105.297
            },
            {
                lat: -12.247,
                lng: 104.900
            },
            {
                lat: -11.992,
                lng: 104.626
            },
            {
                lat: -11.536,
                lng: 104.157
            },
            {
                lat: -11.045,
                lng: 103.593
            },
            {
                lat: -10.829,
                lng: 103.347
            },
            {
                lat: -10.168,
                lng: 102.606
            },
            {
                lat: -10.127,
                lng: 102.559
            },
            {
                lat: -9.777,
                lng: 102.136
            },
            {
                lat: -9.428,
                lng: 101.718
            },
            {
                lat: -9.379,
                lng: 101.661
            },
            {
                lat: -8.734,
                lng: 100.908
            },
            {
                lat: -8.574,
                lng: 100.741
            },
            {
                lat: -8.045,
                lng: 100.184
            },
            {
                lat: -7.751,
                lng: 99.844
            },
            {
                lat: -7.360,
                lng: 99.401
            },
            {
                lat: -6.930,
                lng: 98.972
            },
            {
                lat: -6.678,
                lng: 98.716
            },
            {
                lat: -6.147,
                lng: 98.123
            },
            {
                lat: -5.999,
                lng: 97.959
            },
            {
                lat: -5.324,
                lng: 97.339
            },
            {
                lat: -5.270,
                lng: 97.288
            },
            {
                lat: -4.652,
                lng: 96.723
            },
            {
                lat: -4.327,
                lng: 96.471
            },
            {
                lat: -3.982,
                lng: 96.184
            },
            {
                lat: -3.346,
                lng: 95.672
            },
            {
                lat: -3.315,
                lng: 95.646
            },
            {
                lat: -2.649,
                lng: 95.150
            },
            {
                lat: -2.342,
                lng: 94.892
            },
            {
                lat: -1.985,
                lng: 94.577
            },
            {
                lat: -1.370,
                lng: 94.130
            },
            {
                lat: -1.322,
                lng: 94.094
            },
            {
                lat: -0.660,
                lng: 93.536
            },
            {
                lat: -0.484,
                lng: 93.385
            },
            {
                lat: 0.000,
                lng: 92.959
            },
            {
                lat: 0.375,
                lng: 92.653
            },
            {
                lat: 0.659,
                lng: 92.422
            },
            {
                lat: 1.189,
                lng: 91.932
            },
            {
                lat: 1.318,
                lng: 91.815
            },
            {
                lat: 1.976,
                lng: 91.360
            },
            {
                lat: 2.152,
                lng: 91.224
            },
            {
                lat: 2.634,
                lng: 90.860
            },
            {
                lat: 3.188,
                lng: 90.530
            },
            {
                lat: 3.291,
                lng: 90.468
            },
            {
                lat: 3.949,
                lng: 90.121
            },
            {
                lat: 4.552,
                lng: 89.857
            },
            {
                lat: 4.608,
                lng: 89.832
            },
            {
                lat: 5.268,
                lng: 89.601
            },
            {
                lat: 5.928,
                lng: 89.392
            },
            {
                lat: 6.577,
                lng: 89.228
            },
            {
                lat: 6.590,
                lng: 89.224
            },
            {
                lat: 7.252,
                lng: 89.029
            },
            {
                lat: 7.916,
                lng: 88.844
            },
            {
                lat: 8.581,
                lng: 88.649
            },
            {
                lat: 8.650,
                lng: 88.628
            },
            {
                lat: 9.247,
                lng: 88.405
            },
            {
                lat: 9.917,
                lng: 88.350
            },
            {
                lat: 10.590,
                lng: 88.364
            },
            {
                lat: 11.268,
                lng: 88.645
            },
            {
                lat: 11.500,
                lng: 88.825
            },
            {
                lat: 11.953,
                lng: 89.209
            },
            {
                lat: 12.282,
                lng: 89.611
            },
            {
                lat: 12.645,
                lng: 90.029
            },
            {
                lat: 13.335,
                lng: 90.395
            },
            {
                lat: 13.543,
                lng: 90.462
            },
            {
                lat: 14.025,
                lng: 90.583
            },
            {
                lat: 14.425,
                lng: 90.556
            },
            {
                lat: 14.715,
                lng: 90.540
            },
            {
                lat: 15.405,
                lng: 90.355
            },
            {
                lat: 16.098,
                lng: 90.163
            },
            {
                lat: 16.366,
                lng: 90.042
            },
            {
                lat: 16.793,
                lng: 89.860
            },
            {
                lat: 17.491,
                lng: 89.573
            },
            {
                lat: 17.694,
                lng: 89.465
            },
            {
                lat: 18.192,
                lng: 89.193
            },
            {
                lat: 18.817,
                lng: 88.866
            },
            {
                lat: 18.896,
                lng: 88.824
            },
            {
                lat: 19.605,
                lng: 88.466
            },
            {
                lat: 19.976,
                lng: 88.275
            },
            {
                lat: 20.318,
                lng: 88.097
            },
            {
                lat: 21.038,
                lng: 87.830
            },
            {
                lat: 21.375,
                lng: 87.719
            },
            {
                lat: 21.764,
                lng: 87.591
            },
            {
                lat: 22.498,
                lng: 87.459
            },
            {
                lat: 23.239,
                lng: 87.389
            },
            {
                lat: 23.988,
                lng: 87.410
            },
            {
                lat: 24.748,
                lng: 87.554
            },
            {
                lat: 25.515,
                lng: 87.731
            },
            {
                lat: 26.295,
                lng: 88.093
            },
            {
                lat: 27.086,
                lng: 88.519
            },
            {
                lat: 27.342,
                lng: 88.724
            },
            {
                lat: 27.893,
                lng: 89.186
            },
            {
                lat: 28.525,
                lng: 89.779
            },
            {
                lat: 28.714,
                lng: 89.972
            },
            {
                lat: 29.084,
                lng: 90.393
            },
            {
                lat: 29.457,
                lng: 90.820
            },
            {
                lat: 29.554,
                lng: 90.937
            },
            {
                lat: 29.870,
                lng: 91.388
            },
            {
                lat: 30.189,
                lng: 91.846
            },
            {
                lat: 30.417,
                lng: 92.201
            },
            {
                lat: 30.947,
                lng: 92.911
            },
            {
                lat: 31.294,
                lng: 93.408
            },
            {
                lat: 31.622,
                lng: 93.986
            },
            {
                lat: 31.912,
                lng: 94.506
            },
            {
                lat: 32.205,
                lng: 95.035
            },
            {
                lat: 32.234,
                lng: 95.074
            },
            {
                lat: 32.585,
                lng: 95.647
            },
            {
                lat: 32.939,
                lng: 96.230
            },
            {
                lat: 33.131,
                lng: 96.571
            },
            {
                lat: 33.574,
                lng: 97.400
            },
            {
                lat: 34.093,
                lng: 98.408
            },
            {
                lat: 34.204,
                lng: 98.608
            },
            {
                lat: 34.563,
                lng: 99.248
            },
            {
                lat: 34.927,
                lng: 99.902
            },
            {
                lat: 35.073,
                lng: 100.167
            },
            {
                lat: 35.377,
                lng: 100.712
            },
            {
                lat: 35.683,
                lng: 101.268
            },
            {
                lat: 36.077,
                lng: 101.929
            },
            {
                lat: 36.588,
                lng: 102.778
            },
            {
                lat: 37.101,
                lng: 103.612
            },
            {
                lat: 37.752,
                lng: 104.529
            },
            {
                lat: 38.135,
                lng: 105.066
            },
            {
                lat: 38.658,
                lng: 105.750
            },
            {
                lat: 39.189,
                lng: 106.461
            },
            {
                lat: 39.398,
                lng: 106.744
            },
            {
                lat: 39.834,
                lng: 107.338
            },
            {
                lat: 40.276,
                lng: 107.954
            },
            {
                lat: 40.719,
                lng: 108.618
            },
            {
                lat: 41.168,
                lng: 109.307
            },
            {
                lat: 41.412,
                lng: 109.689
            },
            {
                lat: 42.008,
                lng: 110.759
            },
            {
                lat: 42.312,
                lng: 111.317
            },
            {
                lat: 42.620,
                lng: 111.893
            },
            {
                lat: 42.628,
                lng: 111.910
            },
            {
                lat: 42.891,
                lng: 112.508
            },
            {
                lat: 43.159,
                lng: 113.125
            },
            {
                lat: 43.430,
                lng: 113.760
            },
            {
                lat: 43.705,
                lng: 114.416
            },
            {
                lat: 43.955,
                lng: 115.063
            },
            {
                lat: 44.273,
                lng: 115.995
            },
            {
                lat: 44.598,
                lng: 116.968
            },
            {
                lat: 44.920,
                lng: 118.182
            },
            {
                lat: 45.253,
                lng: 119.465
            },
            {
                lat: 45.450,
                lng: 120.378
            },
            {
                lat: 45.652,
                lng: 121.330
            },
            {
                lat: 45.793,
                lng: 122.032
            },
            {
                lat: 45.983,
                lng: 123.282
            },
            {
                lat: 46.183,
                lng: 124.610
            },
            {
                lat: 46.323,
                lng: 125.896
            },
            {
                lat: 46.474,
                lng: 127.270
            },
            {
                lat: 46.571,
                lng: 128.609
            },
            {
                lat: 46.680,
                lng: 130.055
            },
            {
                lat: 46.735,
                lng: 131.001
            },
            {
                lat: 46.796,
                lng: 132.005
            },
        ],
        c7: [{
                lat: 44.783,
                lng: 133.790
            },
            {
                lat: 44.749,
                lng: 134.986
            },
            {
                lat: 44.728,
                lng: 136.301
            },
            {
                lat: 44.707,
                lng: 137.071
            },
            {
                lat: 44.692,
                lng: 137.897
            },
            {
                lat: 44.686,
                lng: 138.796
            },
            {
                lat: 44.689,
                lng: 139.791
            },
            {
                lat: 44.699,
                lng: 140.644
            },
            {
                lat: 44.721,
                lng: 141.608
            },
            {
                lat: 44.762,
                lng: 142.741
            },
            {
                lat: 44.794,
                lng: 143.409
            },
            {
                lat: 44.837,
                lng: 144.189
            },
            {
                lat: 44.904,
                lng: 145.263
            },
            {
                lat: 44.958,
                lng: 145.994
            },
            {
                lat: 45.062,
                lng: 147.178
            },
            {
                lat: 45.151,
                lng: 148.058
            },
        ],
        c8: [{
                lat: 30.610,
                lng: 150.258
            },
            {
                lat: 30.515,
                lng: 149.277
            },
            {
                lat: 30.462,
                lng: 148.826
            },
            {
                lat: 30.377,
                lng: 148.190
            },
            {
                lat: 30.237,
                lng: 147.294
            },
            {
                lat: 30.113,
                lng: 146.613
            },
            {
                lat: 29.999,
                lng: 146.042
            },
            {
                lat: 29.785,
                lng: 145.093
            },
            {
                lat: 29.584,
                lng: 144.301
            },
            {
                lat: 29.379,
                lng: 143.526
            },
            {
                lat: 29.181,
                lng: 142.839
            },
            {
                lat: 28.994,
                lng: 142.226
            },
            {
                lat: 28.810,
                lng: 141.663
            },
            {
                lat: 28.454,
                lng: 140.651
            },
            {
                lat: 28.186,
                lng: 140.096
            },
            {
                lat: 27.921,
                lng: 139.580
            },
            {
                lat: 27.474,
                lng: 138.743
            },
            {
                lat: 27.000,
                lng: 137.944
            },
            {
                lat: 26.535,
                lng: 137.219
            },
            {
                lat: 26.091,
                lng: 136.757
            },
            {
                lat: 25.652,
                lng: 136.330
            },
            {
                lat: 25.221,
                lng: 135.986
            },
            {
                lat: 24.794,
                lng: 135.666
            },
            {
                lat: 23.960,
                lng: 135.239
            },
            {
                lat: 23.561,
                lng: 135.097
            },
            {
                lat: 23.140,
                lng: 134.934
            },
            {
                lat: 22.336,
                lng: 134.779
            },
            {
                lat: 21.540,
                lng: 134.649
            },
            {
                lat: 21.187,
                lng: 134.628
            },
            {
                lat: 20.753,
                lng: 134.603
            },
            {
                lat: 19.973,
                lng: 134.574
            },
            {
                lat: 19.206,
                lng: 134.731
            },
            {
                lat: 19.067,
                lng: 134.731
            },
            {
                lat: 18.435,
                lng: 134.729
            },
            {
                lat: 17.676,
                lng: 134.899
            },
            {
                lat: 16.917,
                lng: 134.994
            },
            {
                lat: 16.866,
                lng: 134.996
            },
            {
                lat: 16.159,
                lng: 135.023
            },
            {
                lat: 15.409,
                lng: 135.198
            },
            {
                lat: 14.654,
                lng: 135.154
            },
            {
                lat: 14.261,
                lng: 135.193
            },
            {
                lat: 13.905,
                lng: 135.208
            },
            {
                lat: 13.160,
                lng: 135.260
            },
            {
                lat: 12.416,
                lng: 135.296
            },
            {
                lat: 11.679,
                lng: 135.492
            },
            {
                lat: 11.362,
                lng: 135.575
            },
            {
                lat: 10.943,
                lng: 135.678
            },
            {
                lat: 10.212,
                lng: 135.940
            },
            {
                lat: 9.852,
                lng: 136.316
            },
            {
                lat: 9.494,
                lng: 136.709
            },
            {
                lat: 9.263,
                lng: 136.908
            },
            {
                lat: 8.771,
                lng: 137.323
            },
            {
                lat: 8.414,
                lng: 137.833
            },
            {
                lat: 8.057,
                lng: 138.372
            },
            {
                lat: 7.677,
                lng: 138.962
            },
            {
                lat: 7.341,
                lng: 139.496
            },
            {
                lat: 6.984,
                lng: 140.102
            },
            {
                lat: 6.626,
                lng: 140.752
            },
            {
                lat: 6.418,
                lng: 141.226
            },
            {
                lat: 6.211,
                lng: 141.726
            },
            {
                lat: 5.915,
                lng: 142.517
            },
            {
                lat: 5.736,
                lng: 142.997
            },
            {
                lat: 5.558,
                lng: 143.509
            },
            {
                lat: 5.381,
                lng: 144.060
            },
            {
                lat: 5.203,
                lng: 144.659
            },
            {
                lat: 5.030,
                lng: 145.338
            },
            {
                lat: 4.858,
                lng: 146.104
            },
            {
                lat: 4.678,
                lng: 147.015
            },
            {
                lat: 4.500,
                lng: 148.160
            },
            {
                lat: 4.385,
                lng: 149.212
            },
            {
                lat: 4.330,
                lng: 149.960
            },
            {
                lat: 4.304,
                lng: 150.490
            },
            {
                lat: 4.286,
                lng: 151.712
            },
        ],
        c9: [{
                lat: -42.752,
                lng: 148.476
            },
            {
                lat: -42.624,
                lng: 147.252
            },
            {
                lat: -42.566,
                lng: 146.691
            },
            {
                lat: -42.483,
                lng: 145.897
            },
            {
                lat: -42.367,
                lng: 144.776
            },
            {
                lat: -42.279,
                lng: 143.918
            },
            {
                lat: -42.205,
                lng: 143.197
            },
            {
                lat: -42.077,
                lng: 142.012
            },
            {
                lat: -41.968,
                lng: 141.015
            },
            {
                lat: -41.873,
                lng: 140.138
            },
            {
                lat: -41.786,
                lng: 139.347
            },
            {
                lat: -41.706,
                lng: 138.620
            },
            {
                lat: -41.631,
                lng: 137.945
            },
            {
                lat: -41.495,
                lng: 136.714
            },
            {
                lat: -41.373,
                lng: 135.606
            },
            {
                lat: -41.260,
                lng: 134.589
            },
            {
                lat: -41.155,
                lng: 133.644
            },
            {
                lat: -41.056,
                lng: 132.759
            },
            {
                lat: -40.951,
                lng: 131.898
            },
            {
                lat: -40.850,
                lng: 131.082
            },
            {
                lat: -40.754,
                lng: 130.304
            },
            {
                lat: -40.662,
                lng: 129.561
            },
            {
                lat: -40.569,
                lng: 128.842
            },
            {
                lat: -40.479,
                lng: 128.150
            },
            {
                lat: -40.309,
                lng: 126.836
            },
            {
                lat: -40.140,
                lng: 125.594
            },
            {
                lat: -39.980,
                lng: 124.421
            },
            {
                lat: -39.797,
                lng: 123.266
            },
            {
                lat: -39.620,
                lng: 122.167
            },
            {
                lat: -39.444,
                lng: 121.349
            },
            {
                lat: -39.274,
                lng: 120.616
            },
            {
                lat: -39.107,
                lng: 119.905
            },
            {
                lat: -38.747,
                lng: 118.727
            },
            {
                lat: -38.396,
                lng: 117.609
            },
            {
                lat: -38.089,
                lng: 116.810
            },
            {
                lat: -37.755,
                lng: 116.017
            },
            {
                lat: -37.427,
                lng: 115.254
            },
            {
                lat: -36.951,
                lng: 114.343
            },
            {
                lat: -36.481,
                lng: 113.528
            },
            {
                lat: -36.020,
                lng: 112.747
            },
            {
                lat: -35.895,
                lng: 112.561
            },
            {
                lat: -35.392,
                lng: 111.890
            },
            {
                lat: -34.896,
                lng: 111.246
            },
            {
                lat: -34.413,
                lng: 110.720
            },
            {
                lat: -33.936,
                lng: 110.213
            },
            {
                lat: -33.476,
                lng: 109.731
            },
            {
                lat: -32.999,
                lng: 109.257
            },
            {
                lat: -32.106,
                lng: 108.753
            },
            {
                lat: -31.229,
                lng: 108.293
            },
            {
                lat: -30.893,
                lng: 108.194
            },
            {
                lat: -30.376,
                lng: 108.047
            },
            {
                lat: -29.536,
                lng: 107.821
            },
            {
                lat: -28.720,
                lng: 107.865
            },
            {
                lat: -28.267,
                lng: 107.895
            },
            {
                lat: -27.915,
                lng: 107.939
            },
            {
                lat: -27.119,
                lng: 108.023
            },
            {
                lat: -26.606,
                lng: 108.148
            },
            {
                lat: -26.336,
                lng: 108.222
            },
            {
                lat: -25.555,
                lng: 108.326
            },
            {
                lat: -24.914,
                lng: 108.423
            },
            {
                lat: -24.783,
                lng: 108.451
            },
            {
                lat: -24.017,
                lng: 108.599
            },
            {
                lat: -23.443,
                lng: 108.828
            },
            {
                lat: -23.265,
                lng: 108.902
            },
            {
                lat: -22.519,
                lng: 109.202
            },
            {
                lat: -22.135,
                lng: 109.326
            },
            {
                lat: -21.774,
                lng: 109.452
            },
            {
                lat: -21.044,
                lng: 109.896
            },
            {
                lat: -20.995,
                lng: 109.912
            },
            {
                lat: -20.307,
                lng: 110.104
            },
            {
                lat: -19.575,
                lng: 110.327
            },
            {
                lat: -19.399,
                lng: 110.352
            },
            {
                lat: -18.842,
                lng: 110.418
            },
            {
                lat: -18.107,
                lng: 110.359
            },
            {
                lat: -17.373,
                lng: 110.176
            },
            {
                lat: -16.636,
                lng: 109.804
            },
            {
                lat: -15.901,
                lng: 109.344
            },
            {
                lat: -15.715,
                lng: 109.188
            },
            {
                lat: -15.168,
                lng: 108.775
            },
            {
                lat: -14.437,
                lng: 108.118
            },
            {
                lat: -14.147,
                lng: 107.820
            },
            {
                lat: -13.711,
                lng: 107.409
            },
            {
                lat: -13.349,
                lng: 107.038
            },
            {
                lat: -12.988,
                lng: 106.674
            },
            {
                lat: -12.944,
                lng: 106.618
            },
            {
                lat: -12.606,
                lng: 106.228
            },
            {
                lat: -12.269,
                lng: 105.844
            },
            {
                lat: -11.991,
                lng: 105.521
            },
            {
                lat: -11.556,
                lng: 105.046
            },
            {
                lat: -11.074,
                lng: 104.473
            },
            {
                lat: -10.847,
                lng: 104.209
            },
            {
                lat: -10.191,
                lng: 103.467
            },
            {
                lat: -10.143,
                lng: 103.412
            },
            {
                lat: -9.792,
                lng: 102.968
            },
            {
                lat: -9.442,
                lng: 102.529
            },
            {
                lat: -9.425,
                lng: 102.509
            },
            {
                lat: -9.085,
                lng: 102.113
            },
            {
                lat: -8.747,
                lng: 101.721
            },
            {
                lat: -8.608,
                lng: 101.572
            },
            {
                lat: -8.057,
                lng: 100.990
            },
            {
                lat: -7.782,
                lng: 100.661
            },
            {
                lat: -7.369,
                lng: 100.180
            },
            {
                lat: -6.974,
                lng: 99.777
            },
            {
                lat: -6.687,
                lng: 99.484
            },
            {
                lat: -6.176,
                lng: 98.915
            },
            {
                lat: -6.007,
                lng: 98.724
            },
            {
                lat: -5.331,
                lng: 98.092
            },
            {
                lat: -5.307,
                lng: 98.070
            },
            {
                lat: -4.657,
                lng: 97.465
            },
            {
                lat: -4.381,
                lng: 97.243
            },
            {
                lat: -3.987,
                lng: 96.933
            },
            {
                lat: -3.404,
                lng: 96.435
            },
            {
                lat: -3.318,
                lng: 96.362
            },
            {
                lat: -2.652,
                lng: 95.889
            },
            {
                lat: -2.364,
                lng: 95.645
            },
            {
                lat: -1.987,
                lng: 95.323
            },
            {
                lat: -1.355,
                lng: 94.875
            },
            {
                lat: -1.323,
                lng: 94.851
            },
            {
                lat: -0.661,
                lng: 94.286
            },
            {
                lat: -0.474,
                lng: 94.123
            },
            {
                lat: 0.000,
                lng: 93.709
            },
            {
                lat: 0.397,
                lng: 93.384
            },
            {
                lat: 0.660,
                lng: 93.166
            },
            {
                lat: 1.203,
                lng: 92.658
            },
            {
                lat: 1.319,
                lng: 92.550
            },
            {
                lat: 1.978,
                lng: 92.079
            },
            {
                lat: 2.147,
                lng: 91.945
            },
            {
                lat: 2.636,
                lng: 91.561
            },
            {
                lat: 3.153,
                lng: 91.245
            },
            {
                lat: 3.294,
                lng: 91.159
            },
            {
                lat: 3.953,
                lng: 90.811
            },
            {
                lat: 4.492,
                lng: 90.568
            },
            {
                lat: 4.612,
                lng: 90.513
            },
            {
                lat: 5.272,
                lng: 90.327
            },
            {
                lat: 5.933,
                lng: 90.158
            },
            {
                lat: 6.596,
                lng: 90.085
            },
            {
                lat: 7.261,
                lng: 90.016
            },
            {
                lat: 7.927,
                lng: 90.044
            },
            {
                lat: 8.596,
                lng: 90.092
            },
            {
                lat: 9.267,
                lng: 90.273
            },
            {
                lat: 9.943,
                lng: 90.594
            },
            {
                lat: 10.326,
                lng: 90.898
            },
            {
                lat: 10.624,
                lng: 91.111
            },
            {
                lat: 11.308,
                lng: 91.618
            },
            {
                lat: 11.500,
                lng: 91.733
            },
            {
                lat: 11.993,
                lng: 91.939
            },
            {
                lat: 12.679,
                lng: 92.177
            },
            {
                lat: 13.365,
                lng: 92.160
            },
            {
                lat: 14.052,
                lng: 92.075
            },
            {
                lat: 14.234,
                lng: 92.022
            },
            {
                lat: 14.739,
                lng: 91.871
            },
            {
                lat: 15.428,
                lng: 91.563
            },
            {
                lat: 15.723,
                lng: 91.454
            },
            {
                lat: 16.121,
                lng: 91.311
            },
            {
                lat: 16.815,
                lng: 90.941
            },
            {
                lat: 16.979,
                lng: 90.867
            },
            {
                lat: 17.513,
                lng: 90.623
            },
            {
                lat: 18.181,
                lng: 90.278
            },
            {
                lat: 18.214,
                lng: 90.261
            },
            {
                lat: 18.919,
                lng: 89.875
            },
            {
                lat: 19.317,
                lng: 89.685
            },
            {
                lat: 19.628,
                lng: 89.528
            },
            {
                lat: 20.342,
                lng: 89.177
            },
            {
                lat: 20.530,
                lng: 89.106
            },
            {
                lat: 21.063,
                lng: 88.909
            },
            {
                lat: 21.789,
                lng: 88.676
            },
            {
                lat: 22.211,
                lng: 88.601
            },
            {
                lat: 22.524,
                lng: 88.547
            },
            {
                lat: 23.266,
                lng: 88.496
            },
            {
                lat: 24.017,
                lng: 88.563
            },
            {
                lat: 24.779,
                lng: 88.749
            },
            {
                lat: 25.549,
                lng: 88.987
            },
            {
                lat: 26.037,
                lng: 89.266
            },
            {
                lat: 26.334,
                lng: 89.445
            },
            {
                lat: 27.130,
                lng: 89.996
            },
            {
                lat: 27.543,
                lng: 90.379
            },
            {
                lat: 27.944,
                lng: 90.771
            },
            {
                lat: 28.520,
                lng: 91.425
            },
            {
                lat: 28.775,
                lng: 91.737
            },
            {
                lat: 29.297,
                lng: 92.458
            },
            {
                lat: 29.629,
                lng: 92.973
            },
            {
                lat: 29.957,
                lng: 93.492
            },
            {
                lat: 30.507,
                lng: 94.444
            },
            {
                lat: 30.571,
                lng: 94.543
            },
            {
                lat: 30.881,
                lng: 95.080
            },
            {
                lat: 31.193,
                lng: 95.627
            },
            {
                lat: 31.408,
                lng: 96.038
            },
            {
                lat: 31.738,
                lng: 96.717
            },
            {
                lat: 31.976,
                lng: 97.261
            },
            {
                lat: 32.215,
                lng: 97.814
            },
            {
                lat: 32.353,
                lng: 98.157
            },
            {
                lat: 32.755,
                lng: 98.967
            },
            {
                lat: 33.034,
                lng: 99.544
            },
            {
                lat: 33.315,
                lng: 100.132
            },
            {
                lat: 33.333,
                lng: 100.173
            },
            {
                lat: 33.614,
                lng: 100.789
            },
            {
                lat: 33.897,
                lng: 101.416
            },
            {
                lat: 34.309,
                lng: 102.268
            },
            {
                lat: 34.585,
                lng: 102.766
            },
            {
                lat: 34.940,
                lng: 103.343
            },
            {
                lat: 35.299,
                lng: 103.934
            },
            {
                lat: 35.553,
                lng: 104.332
            },
            {
                lat: 35.927,
                lng: 104.898
            },
            {
                lat: 36.304,
                lng: 105.479
            },
            {
                lat: 36.774,
                lng: 106.150
            },
            {
                lat: 37.326,
                lng: 106.917
            },
            {
                lat: 37.842,
                lng: 107.599
            },
            {
                lat: 38.367,
                lng: 108.309
            },
            {
                lat: 38.478,
                lng: 108.460
            },
            {
                lat: 38.955,
                lng: 109.113
            },
            {
                lat: 39.440,
                lng: 109.794
            },
            {
                lat: 39.923,
                lng: 110.501
            },
            {
                lat: 40.415,
                lng: 111.240
            },
            {
                lat: 40.555,
                lng: 111.459
            },
            {
                lat: 40.849,
                lng: 112.007
            },
            {
                lat: 41.147,
                lng: 112.572
            },
            {
                lat: 41.448,
                lng: 113.154
            },
            {
                lat: 41.754,
                lng: 113.755
            },
            {
                lat: 41.818,
                lng: 113.894
            },
            {
                lat: 42.061,
                lng: 114.496
            },
            {
                lat: 42.308,
                lng: 115.115
            },
            {
                lat: 42.558,
                lng: 115.753
            },
            {
                lat: 42.812,
                lng: 116.412
            },
            {
                lat: 43.101,
                lng: 117.257
            },
            {
                lat: 43.337,
                lng: 118.068
            },
            {
                lat: 43.577,
                lng: 118.910
            },
            {
                lat: 43.802,
                lng: 120.039
            },
            {
                lat: 44.036,
                lng: 121.226
            },
            {
                lat: 44.206,
                lng: 122.371
            },
            {
                lat: 44.384,
                lng: 123.577
            },
            {
                lat: 44.484,
                lng: 124.708
            },
            {
                lat: 44.591,
                lng: 125.899
            },
            {
                lat: 44.652,
                lng: 127.061
            },
            {
                lat: 44.721,
                lng: 128.292
            },
            {
                lat: 44.742,
                lng: 129.487
            },
            {
                lat: 44.772,
                lng: 130.765
            },
            {
                lat: 44.775,
                lng: 132.051
            },
            {
                lat: 44.790,
                lng: 133.450
            },
            {
                lat: 44.783,
                lng: 133.790
            },
        ],
        c10: [{
                lat: 43.466,
                lng: 131.443
            },
            {
                lat: 43.402,
                lng: 132.803
            },
            {
                lat: 43.289,
                lng: 133.904
            },
            {
                lat: 43.186,
                lng: 135.093
            },
            {
                lat: 43.113,
                lng: 136.236
            },
            {
                lat: 43.054,
                lng: 137.496
            },
            {
                lat: 43.029,
                lng: 137.878
            },
            {
                lat: 42.968,
                lng: 138.582
            },
            {
                lat: 42.913,
                lng: 139.339
            },
            {
                lat: 42.866,
                lng: 140.164
            },
            {
                lat: 42.828,
                lng: 141.078
            },
            {
                lat: 42.820,
                lng: 142.037
            },
            {
                lat: 42.829,
                lng: 143.163
            },
            {
                lat: 42.844,
                lng: 143.826
            },
            {
                lat: 42.871,
                lng: 144.599
            },
            {
                lat: 42.918,
                lng: 145.565
            },
            {
                lat: 42.957,
                lng: 146.193
            },
            {
                lat: 43.022,
                lng: 147.066
            },
            {
                lat: 43.052,
                lng: 147.498
            },
            {
                lat: 43.135,
                lng: 148.432
            },
        ],
        c11: [{
                lat: 32.161,
                lng: 150.089
            },
            {
                lat: 32.044,
                lng: 148.939
            },
            {
                lat: 31.979,
                lng: 148.418
            },
            {
                lat: 31.873,
                lng: 147.684
            },
            {
                lat: 31.699,
                lng: 146.654
            },
            {
                lat: 31.545,
                lng: 145.871
            },
            {
                lat: 31.402,
                lng: 145.216
            },
            {
                lat: 31.227,
                lng: 144.413
            },
            {
                lat: 31.062,
                lng: 143.717
            },
            {
                lat: 30.903,
                lng: 143.095
            },
            {
                lat: 30.749,
                lng: 142.529
            },
            {
                lat: 30.452,
                lng: 141.521
            },
            {
                lat: 30.166,
                lng: 140.635
            },
            {
                lat: 29.893,
                lng: 139.891
            },
            {
                lat: 29.625,
                lng: 139.209
            },
            {
                lat: 29.364,
                lng: 138.578
            },
            {
                lat: 29.106,
                lng: 137.990
            },
            {
                lat: 28.854,
                lng: 137.452
            },
            {
                lat: 28.605,
                lng: 136.945
            },
            {
                lat: 28.115,
                lng: 136.007
            },
            {
                lat: 27.653,
                lng: 135.397
            },
            {
                lat: 27.198,
                lng: 134.832
            },
            {
                lat: 26.783,
                lng: 134.391
            },
            {
                lat: 26.372,
                lng: 133.978
            },
            {
                lat: 26.310,
                lng: 133.913
            },
            {
                lat: 25.453,
                lng: 133.310
            },
            {
                lat: 24.618,
                lng: 132.909
            },
            {
                lat: 23.804,
                lng: 132.722
            },
            {
                lat: 23.520,
                lng: 132.650
            },
            {
                lat: 22.993,
                lng: 132.462
            },
            {
                lat: 22.191,
                lng: 132.257
            },
            {
                lat: 21.408,
                lng: 132.285
            },
            {
                lat: 21.130,
                lng: 132.250
            },
            {
                lat: 20.622,
                lng: 132.158
            },
            {
                lat: 19.847,
                lng: 132.146
            },
            {
                lat: 19.083,
                lng: 132.267
            },
            {
                lat: 18.910,
                lng: 132.274
            },
            {
                lat: 18.315,
                lng: 132.237
            },
            {
                lat: 17.563,
                lng: 132.443
            },
            {
                lat: 16.807,
                lng: 132.510
            },
            {
                lat: 16.704,
                lng: 132.536
            },
            {
                lat: 16.057,
                lng: 132.610
            },
            {
                lat: 15.311,
                lng: 132.774
            },
            {
                lat: 14.561,
                lng: 132.740
            },
            {
                lat: 14.219,
                lng: 132.828
            },
            {
                lat: 13.821,
                lng: 132.897
            },
            {
                lat: 13.081,
                lng: 132.996
            },
            {
                lat: 12.344,
                lng: 133.104
            },
            {
                lat: 11.612,
                lng: 133.330
            },
            {
                lat: 11.590,
                lng: 133.338
            },
            {
                lat: 10.882,
                lng: 133.538
            },
            {
                lat: 10.154,
                lng: 133.797
            },
            {
                lat: 9.811,
                lng: 134.125
            },
            {
                lat: 9.469,
                lng: 134.466
            },
            {
                lat: 9.438,
                lng: 134.495
            },
            {
                lat: 9.078,
                lng: 134.780
            },
            {
                lat: 8.718,
                lng: 135.077
            },
            {
                lat: 8.363,
                lng: 135.561
            },
            {
                lat: 8.007,
                lng: 136.066
            },
            {
                lat: 7.923,
                lng: 136.191
            },
            {
                lat: 7.608,
                lng: 136.628
            },
            {
                lat: 7.294,
                lng: 137.085
            },
            {
                lat: 6.937,
                lng: 137.621
            },
            {
                lat: 6.580,
                lng: 138.188
            },
            {
                lat: 6.501,
                lng: 138.355
            },
            {
                lat: 6.185,
                lng: 138.971
            },
            {
                lat: 5.869,
                lng: 139.627
            },
            {
                lat: 5.511,
                lng: 140.320
            },
            {
                lat: 5.153,
                lng: 141.069
            },
            {
                lat: 5.121,
                lng: 141.150
            },
            {
                lat: 4.780,
                lng: 142.076
            },
            {
                lat: 4.610,
                lng: 142.579
            },
            {
                lat: 4.439,
                lng: 143.116
            },
            {
                lat: 4.282,
                lng: 143.615
            },
            {
                lat: 4.124,
                lng: 144.151
            },
            {
                lat: 3.966,
                lng: 144.732
            },
            {
                lat: 3.809,
                lng: 145.370
            },
            {
                lat: 3.724,
                lng: 145.744
            },
            {
                lat: 3.567,
                lng: 146.542
            },
            {
                lat: 3.410,
                lng: 147.493
            },
            {
                lat: 3.332,
                lng: 148.063
            },
            {
                lat: 3.255,
                lng: 148.742
            },
            {
                lat: 3.179,
                lng: 149.628
            },
            {
                lat: 3.143,
                lng: 150.257
            },
            {
                lat: 3.126,
                lng: 150.702
            },
            {
                lat: 3.113,
                lng: 151.682
            },
        ],
        c12: [{
                lat: -41.600,
                lng: 148.729
            },
            {
                lat: -41.468,
                lng: 147.425
            },
            {
                lat: -41.411,
                lng: 146.842
            },
            {
                lat: -41.332,
                lng: 146.018
            },
            {
                lat: -41.222,
                lng: 144.856
            },
            {
                lat: -41.141,
                lng: 143.966
            },
            {
                lat: -41.074,
                lng: 143.217
            },
            {
                lat: -40.969,
                lng: 142.146
            },
            {
                lat: -40.880,
                lng: 141.227
            },
            {
                lat: -40.801,
                lng: 140.411
            },
            {
                lat: -40.730,
                lng: 139.669
            },
            {
                lat: -40.604,
                lng: 138.347
            },
            {
                lat: -40.494,
                lng: 137.179
            },
            {
                lat: -40.371,
                lng: 136.057
            },
            {
                lat: -40.258,
                lng: 135.030
            },
            {
                lat: -40.153,
                lng: 134.079
            },
            {
                lat: -40.055,
                lng: 133.188
            },
            {
                lat: -39.952,
                lng: 132.330
            },
            {
                lat: -39.855,
                lng: 131.516
            },
            {
                lat: -39.762,
                lng: 130.742
            },
            {
                lat: -39.672,
                lng: 130.002
            },
            {
                lat: -39.575,
                lng: 129.272
            },
            {
                lat: -39.481,
                lng: 128.571
            },
            {
                lat: -39.389,
                lng: 127.895
            },
            {
                lat: -39.300,
                lng: 127.242
            },
            {
                lat: -39.113,
                lng: 125.973
            },
            {
                lat: -38.934,
                lng: 124.777
            },
            {
                lat: -38.782,
                lng: 123.925
            },
            {
                lat: -38.635,
                lng: 123.105
            },
            {
                lat: -38.512,
                lng: 122.457
            },
            {
                lat: -38.259,
                lng: 121.318
            },
            {
                lat: -38.013,
                lng: 120.234
            },
            {
                lat: -37.733,
                lng: 119.154
            },
            {
                lat: -37.459,
                lng: 118.120
            },
            {
                lat: -37.193,
                lng: 117.354
            },
            {
                lat: -36.879,
                lng: 116.587
            },
            {
                lat: -36.570,
                lng: 115.847
            },
            {
                lat: -36.066,
                lng: 114.809
            },
            {
                lat: -35.674,
                lng: 114.113
            },
            {
                lat: -35.287,
                lng: 113.441
            },
            {
                lat: -35.027,
                lng: 113.045
            },
            {
                lat: -34.530,
                lng: 112.363
            },
            {
                lat: -34.040,
                lng: 111.707
            },
            {
                lat: -33.602,
                lng: 111.191
            },
            {
                lat: -33.168,
                lng: 110.691
            },
            {
                lat: -33.089,
                lng: 110.607
            },
            {
                lat: -32.187,
                lng: 110.013
            },
            {
                lat: -31.300,
                lng: 109.439
            },
            {
                lat: -30.972,
                lng: 109.337
            },
            {
                lat: -30.445,
                lng: 109.187
            },
            {
                lat: -29.603,
                lng: 108.963
            },
            {
                lat: -28.785,
                lng: 109.000
            },
            {
                lat: -28.432,
                lng: 109.043
            },
            {
                lat: -27.980,
                lng: 109.109
            },
            {
                lat: -27.183,
                lng: 109.206
            },
            {
                lat: -26.825,
                lng: 109.307
            },
            {
                lat: -26.402,
                lng: 109.472
            },
            {
                lat: -25.623,
                lng: 109.629
            },
            {
                lat: -25.375,
                lng: 109.678
            },
            {
                lat: -24.852,
                lng: 109.829
            },
            {
                lat: -24.092,
                lng: 110.112
            },
            {
                lat: -24.040,
                lng: 110.129
            },
            {
                lat: -23.337,
                lng: 110.393
            },
            {
                lat: -22.797,
                lng: 110.645
            },
            {
                lat: -22.592,
                lng: 110.767
            },
            {
                lat: -21.843,
                lng: 110.961
            },
            {
                lat: -21.474,
                lng: 111.152
            },
            {
                lat: -21.108,
                lng: 111.345
            },
            {
                lat: -20.366,
                lng: 111.489
            },
            {
                lat: -19.802,
                lng: 111.550
            },
            {
                lat: -19.627,
                lng: 111.573
            },
            {
                lat: -18.888,
                lng: 111.559
            },
            {
                lat: -18.150,
                lng: 111.457
            },
            {
                lat: -17.409,
                lng: 111.163
            },
            {
                lat: -16.667,
                lng: 110.702
            },
            {
                lat: -16.026,
                lng: 110.276
            },
            {
                lat: -15.930,
                lng: 110.219
            },
            {
                lat: -15.193,
                lng: 109.576
            },
            {
                lat: -14.825,
                lng: 109.214
            },
            {
                lat: -14.459,
                lng: 108.859
            },
            {
                lat: -14.459,
                lng: 108.859
            },
            {
                lat: -14.094,
                lng: 108.476
            },
            {
                lat: -13.730,
                lng: 108.100
            },
            {
                lat: -13.308,
                lng: 107.630
            },
            {
                lat: -13.005,
                lng: 107.319
            },
            {
                lat: -12.677,
                lng: 106.906
            },
            {
                lat: -12.349,
                lng: 106.500
            },
            {
                lat: -12.283,
                lng: 106.424
            },
            {
                lat: -11.925,
                lng: 106.006
            },
            {
                lat: -11.568,
                lng: 105.595
            },
            {
                lat: -11.432,
                lng: 105.423
            },
            {
                lat: -10.857,
                lng: 104.741
            },
            {
                lat: -10.567,
                lng: 104.394
            },
            {
                lat: -10.152,
                lng: 103.923
            },
            {
                lat: -9.756,
                lng: 103.407
            },
            {
                lat: -9.450,
                lng: 103.020
            },
            {
                lat: -8.976,
                lng: 102.454
            },
            {
                lat: -8.754,
                lng: 102.196
            },
            {
                lat: -8.126,
                lng: 101.521
            },
            {
                lat: -8.063,
                lng: 101.454
            },
            {
                lat: -7.719,
                lng: 101.040
            },
            {
                lat: -7.375,
                lng: 100.631
            },
            {
                lat: -7.367,
                lng: 100.623
            },
            {
                lat: -6.692,
                lng: 99.935
            },
            {
                lat: -6.525,
                lng: 99.741
            },
            {
                lat: -6.011,
                lng: 99.165
            },
            {
                lat: -5.717,
                lng: 98.884
            },
            {
                lat: -5.334,
                lng: 98.522
            },
            {
                lat: -4.805,
                lng: 98.042
            },
            {
                lat: -4.661,
                lng: 97.908
            },
            {
                lat: -3.990,
                lng: 97.361
            },
            {
                lat: -3.827,
                lng: 97.219
            },
            {
                lat: -3.320,
                lng: 96.778
            },
            {
                lat: -2.794,
                lng: 96.415
            },
            {
                lat: -2.654,
                lng: 96.316
            },
            {
                lat: -1.988,
                lng: 95.747
            },
            {
                lat: -1.823,
                lng: 95.634
            },
            {
                lat: -1.324,
                lng: 95.276
            },
            {
                lat: -0.837,
                lng: 94.869
            },
            {
                lat: -0.661,
                lng: 94.719
            },
            {
                lat: 0.000,
                lng: 94.140
            },
            {
                lat: 0.023,
                lng: 94.121
            },
            {
                lat: 0.660,
                lng: 93.602
            },
            {
                lat: 0.888,
                lng: 93.387
            },
            {
                lat: 1.320,
                lng: 92.981
            },
            {
                lat: 1.738,
                lng: 92.665
            },
            {
                lat: 1.979,
                lng: 92.491
            },
            {
                lat: 2.637,
                lng: 91.973
            },
            {
                lat: 2.664,
                lng: 91.955
            },
            {
                lat: 3.296,
                lng: 91.562
            },
            {
                lat: 3.852,
                lng: 91.264
            },
            {
                lat: 3.955,
                lng: 91.210
            },
            {
                lat: 4.614,
                lng: 90.925
            },
            {
                lat: 5.275,
                lng: 90.746
            },
            {
                lat: 5.846,
                lng: 90.621
            },
            {
                lat: 5.937,
                lng: 90.599
            },
            {
                lat: 6.600,
                lng: 90.579
            },
            {
                lat: 7.265,
                lng: 90.560
            },
            {
                lat: 7.934,
                lng: 90.719
            },
            {
                lat: 8.006,
                lng: 90.735
            },
            {
                lat: 8.604,
                lng: 90.876
            },
            {
                lat: 9.278,
                lng: 91.222
            },
            {
                lat: 9.795,
                lng: 91.586
            },
            {
                lat: 9.957,
                lng: 91.699
            },
            {
                lat: 10.638,
                lng: 92.114
            },
            {
                lat: 11.106,
                lng: 92.436
            },
            {
                lat: 11.322,
                lng: 92.561
            },
            {
                lat: 12.006,
                lng: 92.774
            },
            {
                lat: 12.692,
                lng: 92.940
            },
            {
                lat: 13.378,
                lng: 92.903
            },
            {
                lat: 14.065,
                lng: 92.783
            },
            {
                lat: 14.134,
                lng: 92.762
            },
            {
                lat: 14.753,
                lng: 92.554
            },
            {
                lat: 15.441,
                lng: 92.238
            },
            {
                lat: 15.557,
                lng: 92.186
            },
            {
                lat: 16.134,
                lng: 91.940
            },
            {
                lat: 16.763,
                lng: 91.592
            },
            {
                lat: 16.828,
                lng: 91.556
            },
            {
                lat: 17.527,
                lng: 91.249
            },
            {
                lat: 17.974,
                lng: 91.004
            },
            {
                lat: 18.228,
                lng: 90.870
            },
            {
                lat: 18.932,
                lng: 90.478
            },
            {
                lat: 19.092,
                lng: 90.408
            },
            {
                lat: 19.643,
                lng: 90.166
            },
            {
                lat: 20.311,
                lng: 89.831
            },
            {
                lat: 20.357,
                lng: 89.809
            },
            {
                lat: 21.078,
                lng: 89.551
            },
            {
                lat: 21.805,
                lng: 89.318
            },
            {
                lat: 21.853,
                lng: 89.309
            },
            {
                lat: 22.540,
                lng: 89.189
            },
            {
                lat: 23.283,
                lng: 89.163
            },
            {
                lat: 24.035,
                lng: 89.245
            },
            {
                lat: 24.798,
                lng: 89.457
            },
            {
                lat: 25.571,
                lng: 89.738
            },
            {
                lat: 26.103,
                lng: 90.076
            },
            {
                lat: 26.358,
                lng: 90.249
            },
            {
                lat: 27.159,
                lng: 90.882
            },
            {
                lat: 27.450,
                lng: 91.174
            },
            {
                lat: 27.978,
                lng: 91.761
            },
            {
                lat: 28.348,
                lng: 92.216
            },
            {
                lat: 28.816,
                lng: 92.860
            },
            {
                lat: 29.065,
                lng: 93.245
            },
            {
                lat: 29.366,
                lng: 93.756
            },
            {
                lat: 29.670,
                lng: 94.274
            },
            {
                lat: 29.681,
                lng: 94.294
            },
            {
                lat: 29.963,
                lng: 94.805
            },
            {
                lat: 30.247,
                lng: 95.322
            },
            {
                lat: 30.575,
                lng: 96.007
            },
            {
                lat: 30.772,
                lng: 96.384
            },
            {
                lat: 31.018,
                lng: 96.920
            },
            {
                lat: 31.265,
                lng: 97.463
            },
            {
                lat: 31.502,
                lng: 98.024
            },
            {
                lat: 31.713,
                lng: 98.555
            },
            {
                lat: 31.937,
                lng: 99.112
            },
            {
                lat: 32.163,
                lng: 99.678
            },
            {
                lat: 32.476,
                lng: 100.520
            },
            {
                lat: 32.616,
                lng: 100.835
            },
            {
                lat: 32.912,
                lng: 101.458
            },
            {
                lat: 33.211,
                lng: 102.094
            },
            {
                lat: 33.452,
                lng: 102.573
            },
            {
                lat: 33.857,
                lng: 103.427
            },
            {
                lat: 34.150,
                lng: 103.966
            },
            {
                lat: 34.445,
                lng: 104.516
            },
            {
                lat: 34.747,
                lng: 104.951
            },
            {
                lat: 35.085,
                lng: 105.427
            },
            {
                lat: 35.427,
                lng: 105.916
            },
            {
                lat: 36.059,
                lng: 106.824
            },
            {
                lat: 36.430,
                lng: 107.326
            },
            {
                lat: 36.939,
                lng: 108.009
            },
            {
                lat: 37.455,
                lng: 108.720
            },
            {
                lat: 37.781,
                lng: 109.151
            },
            {
                lat: 38.141,
                lng: 109.640
            },
            {
                lat: 38.505,
                lng: 110.144
            },
            {
                lat: 39.046,
                lng: 110.935
            },
            {
                lat: 39.596,
                lng: 111.765
            },
            {
                lat: 39.695,
                lng: 111.923
            },
            {
                lat: 40.218,
                lng: 112.849
            },
            {
                lat: 40.752,
                lng: 113.825
            },
            {
                lat: 41.090,
                lng: 114.579
            },
            {
                lat: 41.325,
                lng: 115.174
            },
            {
                lat: 41.563,
                lng: 115.787
            },
            {
                lat: 41.804,
                lng: 116.418
            },
            {
                lat: 42.049,
                lng: 117.068
            },
            {
                lat: 42.049,
                lng: 117.068
            },
            {
                lat: 42.376,
                lng: 118.226
            },
            {
                lat: 42.713,
                lng: 119.447
            },
            {
                lat: 42.929,
                lng: 120.562
            },
            {
                lat: 43.151,
                lng: 121.734
            },
            {
                lat: 43.272,
                lng: 122.806
            },
            {
                lat: 43.400,
                lng: 123.929
            },
            {
                lat: 43.463,
                lng: 124.993
            },
            {
                lat: 43.532,
                lng: 126.111
            },
            {
                lat: 43.544,
                lng: 127.173
            },
            {
                lat: 43.562,
                lng: 128.291
            },
            {
                lat: 43.532,
                lng: 129.363
            },
            {
                lat: 43.508,
                lng: 130.497
            },
            {
                lat: 43.466,
                lng: 131.443
            },
        ],
        c13: [{
                lat: 42.105,
                lng: 128.307
            },
            {
                lat: 41.929,
                lng: 129.676
            },
            {
                lat: 41.791,
                lng: 130.572
            },
            {
                lat: 41.658,
                lng: 131.511
            },
            {
                lat: 41.498,
                lng: 132.406
            },
            {
                lat: 41.272,
                lng: 133.238
            },
            {
                lat: 41.056,
                lng: 134.101
            },
            {
                lat: 40.847,
                lng: 135.018
            },
            {
                lat: 40.781,
                lng: 135.295
            },
            {
                lat: 40.631,
                lng: 136.194
            },
            {
                lat: 40.488,
                lng: 137.161
            },
            {
                lat: 40.338,
                lng: 138.251
            },
            {
                lat: 40.201,
                lng: 139.465
            },
            {
                lat: 40.133,
                lng: 139.633
            },
            {
                lat: 39.912,
                lng: 140.888
            },
            {
                lat: 39.809,
                lng: 141.596
            },
            {
                lat: 39.714,
                lng: 142.378
            },
            {
                lat: 39.631,
                lng: 143.678
            },
            {
                lat: 39.575,
                lng: 144.355
            },
            {
                lat: 39.531,
                lng: 145.145
            },
            {
                lat: 39.505,
                lng: 146.133
            },
            {
                lat: 39.507,
                lng: 146.775
            },
            {
                lat: 39.534,
                lng: 147.665
            },
            {
                lat: 39.555,
                lng: 148.111
            },
            {
                lat: 39.633,
                lng: 149.124
            },
        ],
        c14: [{
                lat: 34.969,
                lng: 149.807
            },
            {
                lat: 34.853,
                lng: 148.783
            },
            {
                lat: 34.787,
                lng: 148.337
            },
            {
                lat: 34.680,
                lng: 147.710
            },
            {
                lat: 34.499,
                lng: 146.833
            },
            {
                lat: 34.338,
                lng: 146.170
            },
            {
                lat: 34.186,
                lng: 145.617
            },
            {
                lat: 34.101,
                lng: 145.308
            },
            {
                lat: 33.934,
                lng: 144.699
            },
            {
                lat: 33.774,
                lng: 144.163
            },
            {
                lat: 33.469,
                lng: 143.237
            },
            {
                lat: 33.176,
                lng: 142.446
            },
            {
                lat: 32.893,
                lng: 141.748
            },
            {
                lat: 32.604,
                lng: 140.959
            },
            {
                lat: 32.323,
                lng: 140.249
            },
            {
                lat: 32.048,
                lng: 139.601
            },
            {
                lat: 31.779,
                lng: 139.003
            },
            {
                lat: 31.500,
                lng: 138.266
            },
            {
                lat: 31.227,
                lng: 137.584
            },
            {
                lat: 30.959,
                lng: 136.949
            },
            {
                lat: 30.696,
                lng: 136.352
            },
            {
                lat: 30.435,
                lng: 135.772
            },
            {
                lat: 30.177,
                lng: 135.223
            },
            {
                lat: 29.923,
                lng: 134.701
            },
            {
                lat: 29.672,
                lng: 134.204
            },
            {
                lat: 29.191,
                lng: 133.278
            },
            {
                lat: 28.686,
                lng: 132.314
            },
            {
                lat: 28.217,
                lng: 131.610
            },
            {
                lat: 27.755,
                lng: 130.952
            },
            {
                lat: 27.217,
                lng: 130.596
            },
            {
                lat: 26.887,
                lng: 130.335
            },
            {
                lat: 26.035,
                lng: 129.796
            },
            {
                lat: 25.216,
                lng: 129.640
            },
            {
                lat: 24.590,
                lng: 129.647
            },
            {
                lat: 24.411,
                lng: 129.588
            },
            {
                lat: 23.611,
                lng: 129.538
            },
            {
                lat: 22.810,
                lng: 129.341
            },
            {
                lat: 22.366,
                lng: 129.388
            },
            {
                lat: 22.026,
                lng: 129.347
            },
            {
                lat: 21.247,
                lng: 129.334
            },
            {
                lat: 20.469,
                lng: 129.265
            },
            {
                lat: 20.143,
                lng: 129.326
            },
            {
                lat: 19.704,
                lng: 129.341
            },
            {
                lat: 18.938,
                lng: 129.317
            },
            {
                lat: 18.180,
                lng: 129.366
            },
            {
                lat: 17.919,
                lng: 129.456
            },
            {
                lat: 17.433,
                lng: 129.573
            },
            {
                lat: 16.682,
                lng: 129.612
            },
            {
                lat: 15.940,
                lng: 129.811
            },
            {
                lat: 15.811,
                lng: 129.858
            },
            {
                lat: 15.201,
                lng: 130.005
            },
            {
                lat: 14.461,
                lng: 130.090
            },
            {
                lat: 13.729,
                lng: 130.334
            },
            {
                lat: 13.592,
                lng: 130.379
            },
            {
                lat: 12.996,
                lng: 130.502
            },
            {
                lat: 12.266,
                lng: 130.680
            },
            {
                lat: 11.540,
                lng: 130.953
            },
            {
                lat: 11.274,
                lng: 131.068
            },
            {
                lat: 10.816,
                lng: 131.235
            },
            {
                lat: 10.093,
                lng: 131.520
            },
            {
                lat: 9.754,
                lng: 131.863
            },
            {
                lat: 9.414,
                lng: 132.217
            },
            {
                lat: 9.382,
                lng: 132.247
            },
            {
                lat: 9.024,
                lng: 132.538
            },
            {
                lat: 8.667,
                lng: 132.840
            },
            {
                lat: 8.317,
                lng: 133.297
            },
            {
                lat: 7.968,
                lng: 133.771
            },
            {
                lat: 7.959,
                lng: 133.783
            },
            {
                lat: 7.604,
                lng: 134.251
            },
            {
                lat: 7.249,
                lng: 134.738
            },
            {
                lat: 6.919,
                lng: 135.168
            },
            {
                lat: 6.588,
                lng: 135.615
            },
            {
                lat: 6.536,
                lng: 135.684
            },
            {
                lat: 6.183,
                lng: 136.355
            },
            {
                lat: 5.828,
                lng: 137.065
            },
            {
                lat: 5.589,
                lng: 137.464
            },
            {
                lat: 5.350,
                lng: 137.878
            },
            {
                lat: 5.114,
                lng: 138.281
            },
            {
                lat: 4.757,
                lng: 139.030
            },
            {
                lat: 4.400,
                lng: 139.837
            },
            {
                lat: 4.020,
                lng: 140.703
            },
            {
                lat: 3.683,
                lng: 141.563
            },
            {
                lat: 3.503,
                lng: 142.075
            },
            {
                lat: 3.323,
                lng: 142.620
            },
            {
                lat: 3.144,
                lng: 143.203
            },
            {
                lat: 2.963,
                lng: 143.835
            },
            {
                lat: 2.669,
                lng: 144.830
            },
            {
                lat: 2.454,
                lng: 145.633
            },
            {
                lat: 2.239,
                lng: 146.569
            },
            {
                lat: 2.116,
                lng: 147.260
            },
            {
                lat: 1.993,
                lng: 148.084
            },
            {
                lat: 1.871,
                lng: 149.164
            },
            {
                lat: 1.811,
                lng: 149.930
            },
            {
                lat: 1.782,
                lng: 150.473
            },
            {
                lat: 1.755,
                lng: 151.688
            },
        ],
        c15: [{
                lat: -40.089,
                lng: 148.969
            },
            {
                lat: -39.975,
                lng: 147.743
            },
            {
                lat: -39.928,
                lng: 147.183
            },
            {
                lat: -39.866,
                lng: 146.390
            },
            {
                lat: -39.789,
                lng: 145.270
            },
            {
                lat: -39.710,
                lng: 144.169
            },
            {
                lat: -39.653,
                lng: 143.274
            },
            {
                lat: -39.607,
                lng: 142.501
            },
            {
                lat: -39.569,
                lng: 141.810
            },
            {
                lat: -39.507,
                lng: 141.032
            },
            {
                lat: -39.452,
                lng: 140.324
            },
            {
                lat: -39.358,
                lng: 139.060
            },
            {
                lat: -39.278,
                lng: 137.942
            },
            {
                lat: -39.208,
                lng: 136.930
            },
            {
                lat: -39.125,
                lng: 135.942
            },
            {
                lat: -39.048,
                lng: 135.027
            },
            {
                lat: -38.978,
                lng: 134.169
            },
            {
                lat: -38.913,
                lng: 133.361
            },
            {
                lat: -38.821,
                lng: 132.532
            },
            {
                lat: -38.734,
                lng: 131.745
            },
            {
                lat: -38.651,
                lng: 130.995
            },
            {
                lat: -38.571,
                lng: 130.276
            },
            {
                lat: -38.481,
                lng: 129.563
            },
            {
                lat: -38.393,
                lng: 128.876
            },
            {
                lat: -38.309,
                lng: 128.215
            },
            {
                lat: -38.226,
                lng: 127.575
            },
            {
                lat: -38.043,
                lng: 126.317
            },
            {
                lat: -37.868,
                lng: 125.132
            },
            {
                lat: -37.858,
                lng: 125.076
            },
            {
                lat: -37.640,
                lng: 123.910
            },
            {
                lat: -37.429,
                lng: 122.804
            },
            {
                lat: -37.187,
                lng: 121.686
            },
            {
                lat: -36.951,
                lng: 120.620
            },
            {
                lat: -36.656,
                lng: 119.583
            },
            {
                lat: -36.367,
                lng: 118.591
            },
            {
                lat: -36.323,
                lng: 118.447
            },
            {
                lat: -35.933,
                lng: 117.351
            },
            {
                lat: -35.550,
                lng: 116.305
            },
            {
                lat: -35.213,
                lng: 115.527
            },
            {
                lat: -34.741,
                lng: 114.708
            },
            {
                lat: -34.275,
                lng: 113.925
            },
            {
                lat: -34.190,
                lng: 113.793
            },
            {
                lat: -33.699,
                lng: 113.099
            },
            {
                lat: -33.214,
                lng: 112.433
            },
            {
                lat: -32.751,
                lng: 112.016
            },
            {
                lat: -32.293,
                lng: 111.614
            },
            {
                lat: -31.394,
                lng: 110.907
            },
            {
                lat: -30.531,
                lng: 110.582
            },
            {
                lat: -29.683,
                lng: 110.296
            },
            {
                lat: -28.864,
                lng: 110.340
            },
            {
                lat: -28.823,
                lng: 110.346
            },
            {
                lat: -28.060,
                lng: 110.506
            },
            {
                lat: -27.264,
                lng: 110.613
            },
            {
                lat: -27.261,
                lng: 110.613
            },
            {
                lat: -26.490,
                lng: 111.070
            },
            {
                lat: -26.209,
                lng: 111.169
            },
            {
                lat: -25.721,
                lng: 111.464
            },
            {
                lat: -25.108,
                lng: 111.719
            },
            {
                lat: -24.956,
                lng: 111.816
            },
            {
                lat: -24.200,
                lng: 112.246
            },
            {
                lat: -24.058,
                lng: 112.312
            },
            {
                lat: -23.450,
                lng: 112.678
            },
            {
                lat: -23.040,
                lng: 112.939
            },
            {
                lat: -22.709,
                lng: 113.185
            },
            {
                lat: -21.950,
                lng: 113.232
            },
            {
                lat: -21.553,
                lng: 113.372
            },
            {
                lat: -21.209,
                lng: 113.549
            },
            {
                lat: -20.459,
                lng: 113.573
            },
            {
                lat: -19.709,
                lng: 113.497
            },
            {
                lat: -18.958,
                lng: 113.282
            },
            {
                lat: -18.214,
                lng: 113.083
            },
            {
                lat: -17.465,
                lng: 112.665
            },
            {
                lat: -16.717,
                lng: 112.104
            },
            {
                lat: -15.974,
                lng: 111.516
            },
            {
                lat: -15.717,
                lng: 111.207
            },
            {
                lat: -15.229,
                lng: 110.709
            },
            {
                lat: -14.859,
                lng: 110.293
            },
            {
                lat: -14.490,
                lng: 109.886
            },
            {
                lat: -14.462,
                lng: 109.844
            },
            {
                lat: -14.107,
                lng: 109.408
            },
            {
                lat: -13.755,
                lng: 108.980
            },
            {
                lat: -13.444,
                lng: 108.616
            },
            {
                lat: -13.028,
                lng: 108.170
            },
            {
                lat: -12.555,
                lng: 107.471
            },
            {
                lat: -12.301,
                lng: 107.153
            },
            {
                lat: -11.681,
                lng: 106.374
            },
            {
                lat: -11.583,
                lng: 106.261
            },
            {
                lat: -11.226,
                lng: 105.808
            },
            {
                lat: -10.870,
                lng: 105.362
            },
            {
                lat: -10.841,
                lng: 105.324
            },
            {
                lat: -10.502,
                lng: 104.916
            },
            {
                lat: -10.164,
                lng: 104.514
            },
            {
                lat: -10.017,
                lng: 104.313
            },
            {
                lat: -9.460,
                lng: 103.585
            },
            {
                lat: -9.261,
                lng: 103.342
            },
            {
                lat: -8.763,
                lng: 102.748
            },
            {
                lat: -8.452,
                lng: 102.394
            },
            {
                lat: -8.071,
                lng: 101.982
            },
            {
                lat: -7.649,
                lng: 101.473
            },
            {
                lat: -7.382,
                lng: 101.153
            },
            {
                lat: -6.823,
                lng: 100.576
            },
            {
                lat: -6.698,
                lng: 100.448
            },
            {
                lat: -6.063,
                lng: 99.707
            },
            {
                lat: -6.016,
                lng: 99.654
            },
            {
                lat: -5.339,
                lng: 99.005
            },
            {
                lat: -5.166,
                lng: 98.850
            },
            {
                lat: -4.664,
                lng: 98.396
            },
            {
                lat: -4.212,
                lng: 98.013
            },
            {
                lat: -3.993,
                lng: 97.832
            },
            {
                lat: -3.323,
                lng: 97.247
            },
            {
                lat: -3.253,
                lng: 97.198
            },
            {
                lat: -2.656,
                lng: 96.787
            },
            {
                lat: -2.214,
                lng: 96.401
            },
            {
                lat: -1.989,
                lng: 96.208
            },
            {
                lat: -1.325,
                lng: 95.753
            },
            {
                lat: -1.165,
                lng: 95.624
            },
            {
                lat: -0.662,
                lng: 95.208
            },
            {
                lat: -0.267,
                lng: 94.866
            },
            {
                lat: 0.000,
                lng: 94.633
            },
            {
                lat: 0.620,
                lng: 94.123
            },
            {
                lat: 0.661,
                lng: 94.090
            },
            {
                lat: 1.321,
                lng: 93.469
            },
            {
                lat: 1.418,
                lng: 93.392
            },
            {
                lat: 1.980,
                lng: 92.965
            },
            {
                lat: 2.344,
                lng: 92.675
            },
            {
                lat: 2.639,
                lng: 92.444
            },
            {
                lat: 3.298,
                lng: 92.015
            },
            {
                lat: 3.377,
                lng: 91.973
            },
            {
                lat: 3.957,
                lng: 91.664
            },
            {
                lat: 4.617,
                lng: 91.392
            },
            {
                lat: 4.939,
                lng: 91.302
            },
            {
                lat: 5.278,
                lng: 91.210
            },
            {
                lat: 5.940,
                lng: 91.109
            },
            {
                lat: 6.605,
                lng: 91.107
            },
            {
                lat: 7.271,
                lng: 91.175
            },
            {
                lat: 7.940,
                lng: 91.391
            },
            {
                lat: 8.124,
                lng: 91.467
            },
            {
                lat: 8.612,
                lng: 91.675
            },
            {
                lat: 9.289,
                lng: 92.109
            },
            {
                lat: 9.554,
                lng: 92.302
            },
            {
                lat: 9.968,
                lng: 92.575
            },
            {
                lat: 10.650,
                lng: 92.971
            },
            {
                lat: 10.995,
                lng: 93.171
            },
            {
                lat: 11.334,
                lng: 93.347
            },
            {
                lat: 12.018,
                lng: 93.512
            },
            {
                lat: 12.705,
                lng: 93.666
            },
            {
                lat: 13.391,
                lng: 93.610
            },
            {
                lat: 13.982,
                lng: 93.501
            },
            {
                lat: 14.078,
                lng: 93.481
            },
            {
                lat: 14.767,
                lng: 93.272
            },
            {
                lat: 15.456,
                lng: 92.943
            },
            {
                lat: 15.477,
                lng: 92.934
            },
            {
                lat: 16.148,
                lng: 92.630
            },
            {
                lat: 16.699,
                lng: 92.341
            },
            {
                lat: 16.843,
                lng: 92.264
            },
            {
                lat: 17.542,
                lng: 91.949
            },
            {
                lat: 17.886,
                lng: 91.751
            },
            {
                lat: 18.243,
                lng: 91.552
            },
            {
                lat: 18.949,
                lng: 91.195
            },
            {
                lat: 19.030,
                lng: 91.160
            },
            {
                lat: 19.660,
                lng: 90.888
            },
            {
                lat: 20.228,
                lng: 90.582
            },
            {
                lat: 20.374,
                lng: 90.507
            },
            {
                lat: 21.096,
                lng: 90.299
            },
            {
                lat: 21.804,
                lng: 90.069
            },
            {
                lat: 21.824,
                lng: 90.063
            },
            {
                lat: 22.559,
                lng: 89.930
            },
            {
                lat: 23.303,
                lng: 89.935
            },
            {
                lat: 24.057,
                lng: 90.016
            },
            {
                lat: 24.822,
                lng: 90.286
            },
            {
                lat: 25.597,
                lng: 90.601
            },
            {
                lat: 25.932,
                lng: 90.842
            },
            {
                lat: 26.388,
                lng: 91.207
            },
            {
                lat: 27.193,
                lng: 91.906
            },
            {
                lat: 27.227,
                lng: 91.944
            },
            {
                lat: 27.622,
                lng: 92.441
            },
            {
                lat: 28.020,
                lng: 92.947
            },
            {
                lat: 28.040,
                lng: 92.975
            },
            {
                lat: 28.385,
                lng: 93.487
            },
            {
                lat: 28.732,
                lng: 94.007
            },
            {
                lat: 28.870,
                lng: 94.254
            },
            {
                lat: 29.272,
                lng: 95.025
            },
            {
                lat: 29.753,
                lng: 96.013
            },
            {
                lat: 29.775,
                lng: 96.057
            },
            {
                lat: 30.193,
                lng: 97.089
            },
            {
                lat: 30.576,
                lng: 98.132
            },
            {
                lat: 30.688,
                lng: 98.452
            },
            {
                lat: 30.992,
                lng: 99.214
            },
            {
                lat: 31.192,
                lng: 99.762
            },
            {
                lat: 31.395,
                lng: 100.319
            },
            {
                lat: 31.650,
                lng: 100.954
            },
            {
                lat: 31.839,
                lng: 101.472
            },
            {
                lat: 32.049,
                lng: 102.056
            },
            {
                lat: 32.262,
                lng: 102.650
            },
            {
                lat: 32.647,
                lng: 103.579
            },
            {
                lat: 32.840,
                lng: 103.943
            },
            {
                lat: 33.224,
                lng: 104.584
            },
            {
                lat: 33.612,
                lng: 105.243
            },
            {
                lat: 33.734,
                lng: 105.458
            },
            {
                lat: 34.161,
                lng: 106.140
            },
            {
                lat: 34.594,
                lng: 106.844
            },
            {
                lat: 34.874,
                lng: 107.209
            },
            {
                lat: 35.574,
                lng: 108.100
            },
            {
                lat: 36.073,
                lng: 108.754
            },
            {
                lat: 36.580,
                lng: 109.435
            },
            {
                lat: 36.656,
                lng: 109.539
            },
            {
                lat: 37.130,
                lng: 110.168
            },
            {
                lat: 37.611,
                lng: 110.822
            },
            {
                lat: 38.122,
                lng: 111.557
            },
            {
                lat: 38.642,
                lng: 112.325
            },
            {
                lat: 38.680,
                lng: 112.384
            },
            {
                lat: 39.241,
                lng: 113.364
            },
            {
                lat: 39.813,
                lng: 114.400
            },
            {
                lat: 40.109,
                lng: 115.018
            },
            {
                lat: 40.345,
                lng: 115.601
            },
            {
                lat: 40.583,
                lng: 116.200
            },
            {
                lat: 40.825,
                lng: 116.817
            },
            {
                lat: 41.070,
                lng: 117.454
            },
            {
                lat: 41.087,
                lng: 117.507
            },
            {
                lat: 41.358,
                lng: 118.592
            },
            {
                lat: 41.637,
                lng: 119.729
            },
            {
                lat: 41.805,
                lng: 120.768
            },
            {
                lat: 41.978,
                lng: 121.854
            },
            {
                lat: 42.072,
                lng: 122.869
            },
            {
                lat: 42.171,
                lng: 123.929
            },
            {
                lat: 42.200,
                lng: 124.919
            },
            {
                lat: 42.233,
                lng: 125.952
            },
            {
                lat: 42.189,
                lng: 126.892
            },
            {
                lat: 42.149,
                lng: 127.873
            },
            {
                lat: 42.105,
                lng: 128.307
            },
        ],
        c16: [{
                lat: -16.394,
                lng: 122.701
            },
            {
                lat: -16.696,
                lng: 123.730
            },
            {
                lat: -16.962,
                lng: 124.334
            },
            {
                lat: -17.230,
                lng: 124.957
            },
            {
                lat: -17.623,
                lng: 125.405
            },
            {
                lat: -18.019,
                lng: 125.869
            },
            {
                lat: -18.173,
                lng: 126.141
            },
            {
                lat: -18.506,
                lng: 126.705
            },
            {
                lat: -18.841,
                lng: 127.289
            },
            {
                lat: -19.247,
                lng: 127.843
            },
            {
                lat: -19.658,
                lng: 128.420
            },
            {
                lat: -20.456,
                lng: 129.013
            },
            {
                lat: -21.257,
                lng: 129.517
            },
            {
                lat: -22.058,
                lng: 129.908
            },
            {
                lat: -22.351,
                lng: 129.373
            },
            {
                lat: -22.543,
                lng: 128.712
            },
            {
                lat: -22.737,
                lng: 128.070
            },
            {
                lat: -22.795,
                lng: 127.926
            },
            {
                lat: -22.876,
                lng: 127.112
            },
            {
                lat: -22.957,
                lng: 126.325
            },
            {
                lat: -22.780,
                lng: 125.355
            },
            {
                lat: -22.605,
                lng: 124.425
            },
            {
                lat: -22.518,
                lng: 124.181
            },
            {
                lat: -22.234,
                lng: 123.302
            },
            {
                lat: -21.954,
                lng: 122.457
            },
            {
                lat: -21.619,
                lng: 121.837
            },
            {
                lat: -21.211,
                lng: 121.352
            },
            {
                lat: -20.806,
                lng: 120.882
            },
            {
                lat: -20.409,
                lng: 120.532
            },
            {
                lat: -20.014,
                lng: 120.192
            },
            {
                lat: -19.246,
                lng: 119.844
            },
            {
                lat: -18.476,
                lng: 119.341
            },
            {
                lat: -17.743,
                lng: 119.551
            },
            {
                lat: -17.008,
                lng: 119.647
            },
            {
                lat: -16.928,
                lng: 119.691
            },
            {
                lat: -16.618,
                lng: 120.118
            },
            {
                lat: -16.308,
                lng: 120.552
            },
            {
                lat: -16.257,
                lng: 120.706
            },
            {
                lat: -16.261,
                lng: 121.383
            },
            {
                lat: -16.266,
                lng: 122.076
            },
            {
                lat: -16.394,
                lng: 122.701
            },
        ],
        c17: [{
                lat: 27.707,
                lng: 118.022
            },
            {
                lat: 27.695,
                lng: 118.097
            },
            {
                lat: 27.449,
                lng: 119.269
            },
            {
                lat: 27.023,
                lng: 120.033
            },
            {
                lat: 26.801,
                lng: 120.174
            },
            {
                lat: 26.243,
                lng: 120.434
            },
            {
                lat: 25.437,
                lng: 120.314
            },
            {
                lat: 24.611,
                lng: 119.728
            },
            {
                lat: 24.194,
                lng: 119.292
            },
            {
                lat: 23.779,
                lng: 118.870
            },
            {
                lat: 23.516,
                lng: 118.342
            },
            {
                lat: 23.256,
                lng: 117.827
            },
            {
                lat: 23.295,
                lng: 116.596
            },
            {
                lat: 23.630,
                lng: 116.137
            },
            {
                lat: 23.911,
                lng: 115.715
            },
            {
                lat: 24.363,
                lng: 115.300
            },
            {
                lat: 24.725,
                lng: 114.965
            },
            {
                lat: 25.114,
                lng: 114.740
            },
            {
                lat: 25.725,
                lng: 114.338
            },
            {
                lat: 25.877,
                lng: 114.283
            },
            {
                lat: 26.692,
                lng: 114.615
            },
            {
                lat: 27.529,
                lng: 115.161
            },
            {
                lat: 27.652,
                lng: 115.502
            },
            {
                lat: 27.738,
                lng: 116.184
            },
            {
                lat: 27.825,
                lng: 116.880
            },
            {
                lat: 27.707,
                lng: 118.022
            },
        ],
        c18: [{
                lat: -26.260,
                lng: 120.711
            },
            {
                lat: -26.419,
                lng: 121.308
            },
            {
                lat: -26.740,
                lng: 122.298
            },
            {
                lat: -27.067,
                lng: 123.335
            },
            {
                lat: -27.271,
                lng: 123.860
            },
            {
                lat: -27.585,
                lng: 124.776
            },
            {
                lat: -27.905,
                lng: 125.737
            },
            {
                lat: -28.100,
                lng: 126.295
            },
            {
                lat: -28.298,
                lng: 126.870
            },
            {
                lat: -28.542,
                lng: 127.388
            },
            {
                lat: -28.788,
                lng: 127.923
            },
            {
                lat: -29.037,
                lng: 128.478
            },
            {
                lat: -29.289,
                lng: 129.054
            },
            {
                lat: -29.319,
                lng: 129.123
            },
            {
                lat: -29.560,
                lng: 129.620
            },
            {
                lat: -29.804,
                lng: 130.135
            },
            {
                lat: -30.050,
                lng: 130.671
            },
            {
                lat: -30.299,
                lng: 131.228
            },
            {
                lat: -30.803,
                lng: 132.198
            },
            {
                lat: -31.059,
                lng: 132.714
            },
            {
                lat: -31.318,
                lng: 133.254
            },
            {
                lat: -31.576,
                lng: 133.771
            },
            {
                lat: -31.838,
                lng: 134.315
            },
            {
                lat: -32.103,
                lng: 134.888
            },
            {
                lat: -32.373,
                lng: 135.494
            },
            {
                lat: -32.881,
                lng: 136.285
            },
            {
                lat: -33.401,
                lng: 137.163
            },
            {
                lat: -33.927,
                lng: 138.057
            },
            {
                lat: -34.469,
                lng: 139.076
            },
            {
                lat: -34.926,
                lng: 139.645
            },
            {
                lat: -35.393,
                lng: 140.288
            },
            {
                lat: -35.506,
                lng: 140.426
            },
            {
                lat: -35.974,
                lng: 140.466
            },
            {
                lat: -36.448,
                lng: 140.543
            },
            {
                lat: -36.598,
                lng: 140.040
            },
            {
                lat: -36.783,
                lng: 138.738
            },
            {
                lat: -36.982,
                lng: 137.578
            },
            {
                lat: -37.050,
                lng: 136.884
            },
            {
                lat: -37.121,
                lng: 135.948
            },
            {
                lat: -37.197,
                lng: 135.072
            },
            {
                lat: -37.192,
                lng: 134.371
            },
            {
                lat: -37.190,
                lng: 133.701
            },
            {
                lat: -37.193,
                lng: 132.441
            },
            {
                lat: -37.159,
                lng: 131.187
            },
            {
                lat: -37.134,
                lng: 130.015
            },
            {
                lat: -37.016,
                lng: 128.754
            },
            {
                lat: -36.906,
                lng: 127.569
            },
            {
                lat: -36.760,
                lng: 126.390
            },
            {
                lat: -36.622,
                lng: 125.274
            },
            {
                lat: -36.437,
                lng: 124.145
            },
            {
                lat: -36.258,
                lng: 123.070
            },
            {
                lat: -36.007,
                lng: 121.955
            },
            {
                lat: -35.763,
                lng: 120.891
            },
            {
                lat: -35.585,
                lng: 120.231
            },
            {
                lat: -35.353,
                lng: 119.471
            },
            {
                lat: -35.123,
                lng: 118.735
            },
            {
                lat: -34.772,
                lng: 117.833
            },
            {
                lat: -34.427,
                lng: 116.968
            },
            {
                lat: -34.221,
                lng: 116.510
            },
            {
                lat: -33.801,
                lng: 115.678
            },
            {
                lat: -33.388,
                lng: 114.878
            },
            {
                lat: -33.017,
                lng: 114.413
            },
            {
                lat: -32.649,
                lng: 113.962
            },
            {
                lat: -32.437,
                lng: 113.728
            },
            {
                lat: -31.976,
                lng: 113.261
            },
            {
                lat: -31.519,
                lng: 112.811
            },
            {
                lat: -30.643,
                lng: 112.342
            },
            {
                lat: -29.790,
                lng: 112.029
            },
            {
                lat: -29.766,
                lng: 112.030
            },
            {
                lat: -28.969,
                lng: 112.093
            },
            {
                lat: -28.331,
                lng: 112.332
            },
            {
                lat: -28.173,
                lng: 112.421
            },
            {
                lat: -27.383,
                lng: 112.718
            },
            {
                lat: -27.214,
                lng: 112.843
            },
            {
                lat: -26.926,
                lng: 113.259
            },
            {
                lat: -26.639,
                lng: 113.680
            },
            {
                lat: -26.638,
                lng: 113.684
            },
            {
                lat: -26.362,
                lng: 114.112
            },
            {
                lat: -26.087,
                lng: 114.545
            },
            {
                lat: -25.926,
                lng: 115.136
            },
            {
                lat: -25.766,
                lng: 115.565
            },
            {
                lat: -25.530,
                lng: 116.661
            },
            {
                lat: -25.557,
                lng: 117.956
            },
            {
                lat: -25.695,
                lng: 118.706
            },
            {
                lat: -25.836,
                lng: 119.476
            },
            {
                lat: -26.047,
                lng: 120.085
            },
            {
                lat: -26.260,
                lng: 120.711
            },
        ],
        c19: [{
                lat: -6.691,
                lng: 151.683
            },
            {
                lat: -6.735,
                lng: 150.455
            },
            {
                lat: -6.791,
                lng: 149.926
            },
            {
                lat: -6.937,
                lng: 148.891
            },
            {
                lat: -7.072,
                lng: 148.178
            },
            {
                lat: -7.208,
                lng: 147.592
            },
            {
                lat: -7.484,
                lng: 146.630
            },
            {
                lat: -7.845,
                lng: 145.914
            },
            {
                lat: -8.208,
                lng: 145.298
            },
            {
                lat: -8.569,
                lng: 144.634
            },
            {
                lat: -8.930,
                lng: 144.043
            },
            {
                lat: -8.988,
                lng: 144.020
            },
            {
                lat: -9.675,
                lng: 143.761
            },
            {
                lat: -10.038,
                lng: 143.279
            },
            {
                lat: -10.402,
                lng: 142.837
            },
            {
                lat: -10.773,
                lng: 142.650
            },
            {
                lat: -11.144,
                lng: 142.481
            },
            {
                lat: -11.541,
                lng: 142.142
            },
            {
                lat: -11.878,
                lng: 141.831
            },
            {
                lat: -12.247,
                lng: 141.566
            },
            {
                lat: -12.617,
                lng: 141.321
            },
            {
                lat: -12.980,
                lng: 140.893
            },
            {
                lat: -13.344,
                lng: 140.492
            },
            {
                lat: -13.447,
                lng: 140.364
            },
            {
                lat: -13.756,
                lng: 139.932
            },
            {
                lat: -14.066,
                lng: 139.523
            },
            {
                lat: -14.434,
                lng: 139.249
            },
            {
                lat: -14.804,
                lng: 138.992
            },
            {
                lat: -14.961,
                lng: 138.648
            },
            {
                lat: -15.217,
                lng: 137.695
            },
            {
                lat: -15.475,
                lng: 136.808
            },
            {
                lat: -15.885,
                lng: 136.658
            },
            {
                lat: -16.211,
                lng: 136.246
            },
            {
                lat: -16.572,
                lng: 135.791
            },
            {
                lat: -16.933,
                lng: 135.357
            },
            {
                lat: -17.020,
                lng: 135.143
            },
            {
                lat: -16.987,
                lng: 133.902
            },
            {
                lat: -16.959,
                lng: 132.745
            },
            {
                lat: -16.773,
                lng: 131.722
            },
            {
                lat: -16.628,
                lng: 131.008
            },
            {
                lat: -16.485,
                lng: 130.321
            },
            {
                lat: -16.331,
                lng: 129.792
            },
            {
                lat: -16.179,
                lng: 129.278
            },
            {
                lat: -15.878,
                lng: 128.291
            },
            {
                lat: -15.831,
                lng: 128.053
            },
            {
                lat: -15.535,
                lng: 127.024
            },
            {
                lat: -15.243,
                lng: 126.044
            },
            {
                lat: -15.011,
                lng: 125.061
            },
            {
                lat: -14.860,
                lng: 124.299
            },
            {
                lat: -14.685,
                lng: 123.478
            },
            {
                lat: -14.513,
                lng: 122.682
            },
            {
                lat: -14.422,
                lng: 121.945
            },
            {
                lat: -14.333,
                lng: 121.226
            },
            {
                lat: -14.283,
                lng: 120.539
            },
            {
                lat: -14.235,
                lng: 119.869
            },
            {
                lat: -14.314,
                lng: 118.631
            },
            {
                lat: -14.561,
                lng: 117.496
            },
            {
                lat: -14.721,
                lng: 117.004
            },
            {
                lat: -15.021,
                lng: 116.468
            },
            {
                lat: -15.401,
                lng: 115.779
            },
            {
                lat: -15.558,
                lng: 115.497
            },
            {
                lat: -15.685,
                lng: 114.410
            },
            {
                lat: -15.539,
                lng: 113.809
            },
            {
                lat: -15.395,
                lng: 113.219
            },
            {
                lat: -15.308,
                lng: 113.071
            },
            {
                lat: -15.068,
                lng: 112.523
            },
            {
                lat: -14.829,
                lng: 111.985
            },
            {
                lat: -14.543,
                lng: 111.580
            },
            {
                lat: -14.065,
                lng: 110.745
            },
            {
                lat: -13.794,
                lng: 110.352
            },
            {
                lat: -13.241,
                lng: 109.543
            },
            {
                lat: -13.059,
                lng: 109.332
            },
            {
                lat: -12.806,
                lng: 108.873
            },
            {
                lat: -12.554,
                lng: 108.420
            },
            {
                lat: -12.324,
                lng: 108.077
            },
            {
                lat: -11.747,
                lng: 107.312
            },
            {
                lat: -11.603,
                lng: 107.132
            },
            {
                lat: -11.293,
                lng: 106.689
            },
            {
                lat: -10.984,
                lng: 106.252
            },
            {
                lat: -10.887,
                lng: 106.129
            },
            {
                lat: -10.533,
                lng: 105.672
            },
            {
                lat: -10.181,
                lng: 105.221
            },
            {
                lat: -10.177,
                lng: 105.217
            },
            {
                lat: -9.824,
                lng: 104.726
            },
            {
                lat: -9.472,
                lng: 104.243
            },
            {
                lat: -9.467,
                lng: 104.236
            },
            {
                lat: -9.119,
                lng: 103.812
            },
            {
                lat: -8.773,
                lng: 103.393
            },
            {
                lat: -8.667,
                lng: 103.269
            },
            {
                lat: -8.080,
                lng: 102.602
            },
            {
                lat: -7.868,
                lng: 102.331
            },
            {
                lat: -7.390,
                lng: 101.755
            },
            {
                lat: -7.079,
                lng: 101.420
            },
            {
                lat: -6.705,
                lng: 101.032
            },
            {
                lat: -6.290,
                lng: 100.533
            },
            {
                lat: -6.022,
                lng: 100.219
            },
            {
                lat: -5.470,
                lng: 99.667
            },
            {
                lat: -5.343,
                lng: 99.545
            },
            {
                lat: -4.668,
                lng: 98.943
            },
            {
                lat: -4.524,
                lng: 98.815
            },
            {
                lat: -3.996,
                lng: 98.365
            },
            {
                lat: -3.556,
                lng: 97.986
            },
            {
                lat: -3.326,
                lng: 97.785
            },
            {
                lat: -2.658,
                lng: 97.320
            },
            {
                lat: -2.488,
                lng: 97.175
            },
            {
                lat: -1.991,
                lng: 96.747
            },
            {
                lat: -1.472,
                lng: 96.387
            },
            {
                lat: -1.326,
                lng: 96.289
            },
            {
                lat: -0.663,
                lng: 95.763
            },
            {
                lat: -0.495,
                lng: 95.618
            },
            {
                lat: 0.000,
                lng: 95.193
            },
            {
                lat: 0.405,
                lng: 94.867
            },
            {
                lat: 0.661,
                lng: 94.657
            },
            {
                lat: 1.220,
                lng: 94.128
            },
            {
                lat: 1.322,
                lng: 94.033
            },
            {
                lat: 1.982,
                lng: 93.514
            },
            {
                lat: 2.116,
                lng: 93.403
            },
            {
                lat: 2.641,
                lng: 92.989
            },
            {
                lat: 3.060,
                lng: 92.692
            },
            {
                lat: 3.300,
                lng: 92.533
            },
            {
                lat: 3.960,
                lng: 92.189
            },
            {
                lat: 4.405,
                lng: 92.005
            },
            {
                lat: 4.620,
                lng: 91.920
            },
            {
                lat: 5.282,
                lng: 91.747
            },
            {
                lat: 5.945,
                lng: 91.689
            },
            {
                lat: 6.610,
                lng: 91.708
            },
            {
                lat: 7.277,
                lng: 91.845
            },
            {
                lat: 7.948,
                lng: 92.132
            },
            {
                lat: 8.079,
                lng: 92.194
            },
            {
                lat: 8.621,
                lng: 92.459
            },
            {
                lat: 9.299,
                lng: 92.945
            },
            {
                lat: 9.425,
                lng: 93.032
            },
            {
                lat: 9.979,
                lng: 93.388
            },
            {
                lat: 10.661,
                lng: 93.757
            },
            {
                lat: 10.955,
                lng: 93.918
            },
            {
                lat: 11.346,
                lng: 94.114
            },
            {
                lat: 12.030,
                lng: 94.226
            },
            {
                lat: 12.718,
                lng: 94.391
            },
            {
                lat: 13.404,
                lng: 94.331
            },
            {
                lat: 13.901,
                lng: 94.254
            },
            {
                lat: 14.093,
                lng: 94.222
            },
            {
                lat: 14.782,
                lng: 94.035
            },
            {
                lat: 15.472,
                lng: 93.700
            },
            {
                lat: 15.475,
                lng: 93.699
            },
            {
                lat: 16.165,
                lng: 93.415
            },
            {
                lat: 16.742,
                lng: 93.113
            },
            {
                lat: 16.861,
                lng: 93.052
            },
            {
                lat: 17.560,
                lng: 92.731
            },
            {
                lat: 17.940,
                lng: 92.525
            },
            {
                lat: 18.262,
                lng: 92.344
            },
            {
                lat: 18.968,
                lng: 91.998
            },
            {
                lat: 19.109,
                lng: 91.939
            },
            {
                lat: 19.680,
                lng: 91.705
            },
            {
                lat: 20.335,
                lng: 91.368
            },
            {
                lat: 20.394,
                lng: 91.337
            },
            {
                lat: 21.119,
                lng: 91.166
            },
            {
                lat: 21.846,
                lng: 90.914
            },
            {
                lat: 22.066,
                lng: 90.888
            },
            {
                lat: 22.583,
                lng: 90.830
            },
            {
                lat: 23.329,
                lng: 90.846
            },
            {
                lat: 24.083,
                lng: 90.937
            },
            {
                lat: 24.851,
                lng: 91.258
            },
            {
                lat: 25.464,
                lng: 91.549
            },
            {
                lat: 25.629,
                lng: 91.635
            },
            {
                lat: 26.026,
                lng: 91.994
            },
            {
                lat: 26.426,
                lng: 92.358
            },
            {
                lat: 26.719,
                lng: 92.648
            },
            {
                lat: 27.240,
                lng: 93.250
            },
            {
                lat: 27.549,
                lng: 93.689
            },
            {
                lat: 28.078,
                lng: 94.498
            },
            {
                lat: 28.205,
                lng: 94.716
            },
            {
                lat: 28.672,
                lng: 95.716
            },
            {
                lat: 28.960,
                lng: 96.436
            },
            {
                lat: 29.072,
                lng: 96.719
            },
            {
                lat: 29.426,
                lng: 97.730
            },
            {
                lat: 29.728,
                lng: 98.744
            },
            {
                lat: 29.909,
                lng: 99.405
            },
            {
                lat: 30.005,
                lng: 99.771
            },
            {
                lat: 30.274,
                lng: 100.818
            },
            {
                lat: 30.512,
                lng: 101.875
            },
            {
                lat: 30.821,
                lng: 102.987
            },
            {
                lat: 30.948,
                lng: 103.481
            },
            {
                lat: 31.216,
                lng: 104.170
            },
            {
                lat: 31.557,
                lng: 104.796
            },
            {
                lat: 31.902,
                lng: 105.437
            },
            {
                lat: 31.960,
                lng: 105.568
            },
            {
                lat: 32.355,
                lng: 106.304
            },
            {
                lat: 32.756,
                lng: 107.063
            },
            {
                lat: 32.870,
                lng: 107.250
            },
            {
                lat: 33.338,
                lng: 107.796
            },
            {
                lat: 33.811,
                lng: 108.360
            },
            {
                lat: 34.478,
                lng: 109.226
            },
            {
                lat: 34.780,
                lng: 109.605
            },
            {
                lat: 35.267,
                lng: 110.163
            },
            {
                lat: 35.761,
                lng: 110.742
            },
            {
                lat: 36.262,
                lng: 111.367
            },
            {
                lat: 36.771,
                lng: 112.018
            },
            {
                lat: 36.861,
                lng: 112.147
            },
            {
                lat: 37.342,
                lng: 112.887
            },
            {
                lat: 37.832,
                lng: 113.659
            },
            {
                lat: 38.215,
                lng: 114.300
            },
            {
                lat: 38.603,
                lng: 114.966
            },
            {
                lat: 38.950,
                lng: 115.681
            },
            {
                lat: 39.283,
                lng: 116.530
            },
            {
                lat: 39.622,
                lng: 117.413
            },
            {
                lat: 39.913,
                lng: 118.485
            },
            {
                lat: 40.211,
                lng: 119.609
            },
            {
                lat: 40.325,
                lng: 120.552
            },
            {
                lat: 40.443,
                lng: 121.532
            },
            {
                lat: 40.443,
                lng: 121.532
            },
            {
                lat: 40.443,
                lng: 121.532
            },
            {
                lat: 40.469,
                lng: 122.420
            },
            {
                lat: 40.498,
                lng: 123.339
            },
            {
                lat: 40.442,
                lng: 124.164
            },
            {
                lat: 40.389,
                lng: 125.015
            },
            {
                lat: 40.112,
                lng: 126.525
            },
            {
                lat: 39.975,
                lng: 127.067
            },
            {
                lat: 39.731,
                lng: 127.939
            },
            {
                lat: 39.511,
                lng: 128.623
            },
            {
                lat: 39.295,
                lng: 129.331
            },
            {
                lat: 39.230,
                lng: 129.535
            },
            {
                lat: 38.864,
                lng: 129.837
            },
            {
                lat: 38.501,
                lng: 130.152
            },
            {
                lat: 38.339,
                lng: 130.328
            },
            {
                lat: 37.723,
                lng: 131.040
            },
            {
                lat: 37.490,
                lng: 131.432
            },
            {
                lat: 36.871,
                lng: 131.840
            },
            {
                lat: 36.621,
                lng: 132.214
            },
            {
                lat: 35.813,
                lng: 132.307
            },
            {
                lat: 35.706,
                lng: 132.345
            },
            {
                lat: 35.266,
                lng: 132.564
            },
            {
                lat: 34.831,
                lng: 132.799
            },
            {
                lat: 34.723,
                lng: 132.766
            },
            {
                lat: 33.897,
                lng: 132.447
            },
            {
                lat: 32.951,
                lng: 131.789
            },
            {
                lat: 32.451,
                lng: 131.063
            },
            {
                lat: 31.960,
                lng: 130.388
            },
            {
                lat: 31.676,
                lng: 129.544
            },
            {
                lat: 31.397,
                lng: 128.744
            },
            {
                lat: 31.122,
                lng: 127.982
            },
            {
                lat: 30.852,
                lng: 127.253
            },
            {
                lat: 30.823,
                lng: 127.126
            },
            {
                lat: 30.723,
                lng: 126.110
            },
            {
                lat: 30.627,
                lng: 125.136
            },
            {
                lat: 30.559,
                lng: 124.226
            },
            {
                lat: 30.493,
                lng: 123.348
            },
            {
                lat: 30.517,
                lng: 122.736
            },
            {
                lat: 30.583,
                lng: 121.871
            },
            {
                lat: 30.587,
                lng: 121.121
            },
            {
                lat: 30.593,
                lng: 120.389
            },
            {
                lat: 30.592,
                lng: 119.668
            },
            {
                lat: 30.594,
                lng: 118.963
            },
            {
                lat: 30.557,
                lng: 118.241
            },
            {
                lat: 30.522,
                lng: 117.535
            },
            {
                lat: 30.453,
                lng: 116.818
            },
            {
                lat: 30.385,
                lng: 116.116
            },
            {
                lat: 30.290,
                lng: 115.407
            },
            {
                lat: 30.196,
                lng: 114.714
            },
            {
                lat: 30.033,
                lng: 114.017
            },
            {
                lat: 29.872,
                lng: 113.335
            },
            {
                lat: 29.851,
                lng: 113.264
            },
            {
                lat: 29.502,
                lng: 112.452
            },
            {
                lat: 29.157,
                lng: 111.665
            },
            {
                lat: 28.921,
                lng: 111.302
            },
            {
                lat: 28.079,
                lng: 110.832
            },
            {
                lat: 27.601,
                lng: 110.791
            },
            {
                lat: 27.268,
                lng: 110.727
            },
            {
                lat: 26.492,
                lng: 111.119
            },
            {
                lat: 26.299,
                lng: 111.215
            },
            {
                lat: 25.719,
                lng: 111.435
            },
            {
                lat: 25.193,
                lng: 111.761
            },
            {
                lat: 24.960,
                lng: 111.906
            },
            {
                lat: 24.203,
                lng: 112.302
            },
            {
                lat: 24.136,
                lng: 112.350
            },
            {
                lat: 23.458,
                lng: 112.828
            },
            {
                lat: 23.125,
                lng: 112.980
            },
            {
                lat: 22.710,
                lng: 113.215
            },
            {
                lat: 21.963,
                lng: 113.512
            },
            {
                lat: 21.915,
                lng: 113.537
            },
            {
                lat: 21.231,
                lng: 114.033
            },
            {
                lat: 20.859,
                lng: 114.191
            },
            {
                lat: 20.497,
                lng: 114.413
            },
            {
                lat: 19.870,
                lng: 114.897
            },
            {
                lat: 19.777,
                lng: 115.039
            },
            {
                lat: 19.189,
                lng: 115.763
            },
            {
                lat: 19.076,
                lng: 116.050
            },
            {
                lat: 18.644,
                lng: 116.711
            },
            {
                lat: 18.396,
                lng: 117.497
            },
            {
                lat: 18.339,
                lng: 117.794
            },
            {
                lat: 18.391,
                lng: 118.443
            },
            {
                lat: 18.444,
                lng: 119.104
            },
            {
                lat: 18.474,
                lng: 119.279
            },
            {
                lat: 18.673,
                lng: 119.949
            },
            {
                lat: 18.874,
                lng: 120.637
            },
            {
                lat: 19.101,
                lng: 121.133
            },
            {
                lat: 19.329,
                lng: 121.641
            },
            {
                lat: 19.679,
                lng: 122.479
            },
            {
                lat: 19.559,
                lng: 123.135
            },
            {
                lat: 19.440,
                lng: 123.806
            },
            {
                lat: 19.432,
                lng: 123.811
            },
            {
                lat: 19.072,
                lng: 124.186
            },
            {
                lat: 18.713,
                lng: 124.569
            },
            {
                lat: 18.405,
                lng: 124.698
            },
            {
                lat: 17.962,
                lng: 124.583
            },
            {
                lat: 17.230,
                lng: 124.946
            },
            {
                lat: 16.719,
                lng: 125.267
            },
            {
                lat: 16.501,
                lng: 125.344
            },
            {
                lat: 15.775,
                lng: 125.728
            },
            {
                lat: 15.325,
                lng: 126.088
            },
            {
                lat: 15.054,
                lng: 126.193
            },
            {
                lat: 14.334,
                lng: 126.658
            },
            {
                lat: 13.861,
                lng: 126.960
            },
            {
                lat: 13.613,
                lng: 127.037
            },
            {
                lat: 12.890,
                lng: 127.337
            },
            {
                lat: 12.182,
                lng: 127.834
            },
            {
                lat: 12.176,
                lng: 127.837
            },
            {
                lat: 11.457,
                lng: 128.150
            },
            {
                lat: 10.742,
                lng: 128.585
            },
            {
                lat: 10.434,
                lng: 128.812
            },
            {
                lat: 10.027,
                lng: 129.021
            },
            {
                lat: 9.673,
                lng: 129.336
            },
            {
                lat: 9.319,
                lng: 129.659
            },
            {
                lat: 8.985,
                lng: 130.063
            },
            {
                lat: 8.612,
                lng: 130.440
            },
            {
                lat: 8.261,
                lng: 130.919
            },
            {
                lat: 7.909,
                lng: 131.414
            },
            {
                lat: 7.806,
                lng: 131.572
            },
            {
                lat: 7.504,
                lng: 131.955
            },
            {
                lat: 7.203,
                lng: 132.350
            },
            {
                lat: 6.877,
                lng: 132.790
            },
            {
                lat: 6.552,
                lng: 133.245
            },
            {
                lat: 6.495,
                lng: 133.317
            },
            {
                lat: 6.143,
                lng: 133.936
            },
            {
                lat: 5.790,
                lng: 134.584
            },
            {
                lat: 5.371,
                lng: 135.195
            },
            {
                lat: 5.078,
                lng: 135.630
            },
            {
                lat: 4.723,
                lng: 136.318
            },
            {
                lat: 4.367,
                lng: 137.047
            },
            {
                lat: 4.134,
                lng: 137.489
            },
            {
                lat: 3.893,
                lng: 137.942
            },
            {
                lat: 3.652,
                lng: 138.416
            },
            {
                lat: 3.292,
                lng: 139.147
            },
            {
                lat: 2.932,
                lng: 139.935
            },
            {
                lat: 2.748,
                lng: 140.335
            },
            {
                lat: 2.479,
                lng: 140.943
            },
            {
                lat: 2.209,
                lng: 141.595
            },
            {
                lat: 2.027,
                lng: 142.104
            },
            {
                lat: 1.846,
                lng: 142.645
            },
            {
                lat: 1.664,
                lng: 143.225
            },
            {
                lat: 1.481,
                lng: 143.853
            },
            {
                lat: 1.257,
                lng: 144.452
            },
            {
                lat: 1.002,
                lng: 145.166
            },
            {
                lat: 0.745,
                lng: 145.980
            },
            {
                lat: 0.560,
                lng: 146.643
            },
            {
                lat: 0.374,
                lng: 147.415
            },
            {
                lat: 0.187,
                lng: 148.370
            },
            {
                lat: 0.094,
                lng: 148.971
            },
            {
                lat: 0.000,
                lng: 149.748
            },
            {
                lat: -0.056,
                lng: 150.345
            },
            {
                lat: -0.084,
                lng: 150.767
            },
            {
                lat: -0.113,
                lng: 151.693
            },
        ],
        c20: [{
                lat: -28.573,
                lng: 118.782
            },
            {
                lat: -28.772,
                lng: 119.608
            },
            {
                lat: -28.973,
                lng: 120.461
            },
            {
                lat: -29.312,
                lng: 121.455
            },
            {
                lat: -29.657,
                lng: 122.495
            },
            {
                lat: -29.689,
                lng: 122.579
            },
            {
                lat: -29.933,
                lng: 123.201
            },
            {
                lat: -30.179,
                lng: 123.842
            },
            {
                lat: -30.429,
                lng: 124.506
            },
            {
                lat: -30.681,
                lng: 125.194
            },
            {
                lat: -30.701,
                lng: 125.241
            },
            {
                lat: -31.195,
                lng: 126.118
            },
            {
                lat: -31.697,
                lng: 127.051
            },
            {
                lat: -32.183,
                lng: 127.708
            },
            {
                lat: -32.676,
                lng: 128.407
            },
            {
                lat: -33.152,
                lng: 128.840
            },
            {
                lat: -33.634,
                lng: 129.302
            },
            {
                lat: -34.027,
                lng: 129.397
            },
            {
                lat: -34.520,
                lng: 129.184
            },
            {
                lat: -34.705,
                lng: 128.343
            },
            {
                lat: -34.789,
                lng: 127.477
            },
            {
                lat: -34.875,
                lng: 126.643
            },
            {
                lat: -34.818,
                lng: 125.647
            },
            {
                lat: -34.764,
                lng: 124.693
            },
            {
                lat: -34.638,
                lng: 123.686
            },
            {
                lat: -34.516,
                lng: 122.719
            },
            {
                lat: -34.331,
                lng: 121.718
            },
            {
                lat: -34.151,
                lng: 120.756
            },
            {
                lat: -33.929,
                lng: 119.963
            },
            {
                lat: -33.710,
                lng: 119.196
            },
            {
                lat: -33.441,
                lng: 118.604
            },
            {
                lat: -33.061,
                lng: 117.896
            },
            {
                lat: -32.686,
                lng: 117.215
            },
            {
                lat: -32.305,
                lng: 116.585
            },
            {
                lat: -31.928,
                lng: 115.978
            },
            {
                lat: -31.716,
                lng: 115.691
            },
            {
                lat: -30.822,
                lng: 115.047
            },
            {
                lat: -30.455,
                lng: 114.894
            },
            {
                lat: -29.963,
                lng: 114.738
            },
            {
                lat: -29.157,
                lng: 115.098
            },
            {
                lat: -29.066,
                lng: 115.189
            },
            {
                lat: -28.616,
                lng: 116.143
            },
            {
                lat: -28.469,
                lng: 117.181
            },
            {
                lat: -28.444,
                lng: 117.312
            },
            {
                lat: -28.501,
                lng: 118.015
            },
            {
                lat: -28.560,
                lng: 118.734
            },
            {
                lat: -28.573,
                lng: 118.782
            },
        ],
        c21: [{
                lat: 28.470,
                lng: 103.198
            },
            {
                lat: 28.376,
                lng: 103.892
            },
            {
                lat: 28.274,
                lng: 104.834
            },
            {
                lat: 27.958,
                lng: 105.695
            },
            {
                lat: 27.805,
                lng: 105.898
            },
            {
                lat: 27.562,
                lng: 106.529
            },
            {
                lat: 27.088,
                lng: 107.336
            },
            {
                lat: 27.082,
                lng: 107.340
            },
            {
                lat: 26.545,
                lng: 108.120
            },
            {
                lat: 26.342,
                lng: 108.329
            },
            {
                lat: 25.856,
                lng: 108.846
            },
            {
                lat: 25.592,
                lng: 109.037
            },
            {
                lat: 25.105,
                lng: 109.553
            },
            {
                lat: 24.849,
                lng: 109.774
            },
            {
                lat: 24.262,
                lng: 110.229
            },
            {
                lat: 24.103,
                lng: 110.333
            },
            {
                lat: 23.380,
                lng: 110.902
            },
            {
                lat: 23.362,
                lng: 110.916
            },
            {
                lat: 22.617,
                lng: 111.302
            },
            {
                lat: 22.226,
                lng: 111.469
            },
            {
                lat: 21.874,
                lng: 111.634
            },
            {
                lat: 21.139,
                lng: 112.037
            },
            {
                lat: 21.068,
                lng: 112.058
            },
            {
                lat: 20.403,
                lng: 112.327
            },
            {
                lat: 19.772,
                lng: 112.615
            },
            {
                lat: 19.673,
                lng: 112.671
            },
            {
                lat: 18.950,
                lng: 113.075
            },
            {
                lat: 18.639,
                lng: 113.265
            },
            {
                lat: 18.233,
                lng: 113.574
            },
            {
                lat: 17.688,
                lng: 114.010
            },
            {
                lat: 17.525,
                lng: 114.198
            },
            {
                lat: 16.814,
                lng: 114.721
            },
            {
                lat: 16.745,
                lng: 114.782
            },
            {
                lat: 16.114,
                lng: 115.455
            },
            {
                lat: 15.906,
                lng: 115.620
            },
            {
                lat: 15.415,
                lng: 116.166
            },
            {
                lat: 15.073,
                lng: 116.486
            },
            {
                lat: 14.720,
                lng: 116.985
            },
            {
                lat: 14.328,
                lng: 117.414
            },
            {
                lat: 14.027,
                lng: 117.815
            },
            {
                lat: 13.609,
                lng: 118.383
            },
            {
                lat: 13.345,
                lng: 118.987
            },
            {
                lat: 13.082,
                lng: 119.453
            },
            {
                lat: 12.666,
                lng: 120.281
            },
            {
                lat: 12.580,
                lng: 120.571
            },
            {
                lat: 12.442,
                lng: 121.185
            },
            {
                lat: 12.304,
                lng: 121.812
            },
            {
                lat: 12.019,
                lng: 122.678
            },
            {
                lat: 11.840,
                lng: 123.038
            },
            {
                lat: 11.331,
                lng: 123.814
            },
            {
                lat: 11.095,
                lng: 124.222
            },
            {
                lat: 10.636,
                lng: 124.690
            },
            {
                lat: 10.162,
                lng: 125.407
            },
            {
                lat: 9.941,
                lng: 125.630
            },
            {
                lat: 9.592,
                lng: 126.057
            },
            {
                lat: 9.243,
                lng: 126.495
            },
            {
                lat: 9.163,
                lng: 126.651
            },
            {
                lat: 8.856,
                lng: 127.109
            },
            {
                lat: 8.549,
                lng: 127.578
            },
            {
                lat: 8.290,
                lng: 128.033
            },
            {
                lat: 7.852,
                lng: 128.634
            },
            {
                lat: 7.586,
                lng: 129.061
            },
            {
                lat: 7.320,
                lng: 129.498
            },
            {
                lat: 7.154,
                lng: 129.736
            },
            {
                lat: 6.803,
                lng: 130.241
            },
            {
                lat: 6.452,
                lng: 130.763
            },
            {
                lat: 6.284,
                lng: 131.088
            },
            {
                lat: 6.017,
                lng: 131.551
            },
            {
                lat: 5.751,
                lng: 132.027
            },
            {
                lat: 5.469,
                lng: 132.431
            },
            {
                lat: 5.188,
                lng: 132.847
            },
            {
                lat: 5.043,
                lng: 133.038
            },
            {
                lat: 4.690,
                lng: 133.677
            },
            {
                lat: 4.336,
                lng: 134.346
            },
            {
                lat: 4.033,
                lng: 134.842
            },
            {
                lat: 3.623,
                lng: 135.503
            },
            {
                lat: 3.265,
                lng: 136.088
            },
            {
                lat: 2.907,
                lng: 136.704
            },
            {
                lat: 2.682,
                lng: 137.163
            },
            {
                lat: 2.435,
                lng: 137.633
            },
            {
                lat: 2.188,
                lng: 138.123
            },
            {
                lat: 1.827,
                lng: 138.777
            },
            {
                lat: 1.464,
                lng: 139.478
            },
            {
                lat: 1.102,
                lng: 140.071
            },
            {
                lat: 0.734,
                lng: 140.716
            },
            {
                lat: 0.368,
                lng: 141.429
            },
            {
                lat: 0.000,
                lng: 142.210
            },
            {
                lat: -0.369,
                lng: 142.928
            },
            {
                lat: -0.740,
                lng: 143.728
            },
            {
                lat: -1.045,
                lng: 144.083
            },
            {
                lat: -1.351,
                lng: 144.468
            },
            {
                lat: -1.484,
                lng: 144.638
            },
            {
                lat: -1.858,
                lng: 145.151
            },
            {
                lat: -2.234,
                lng: 145.730
            },
            {
                lat: -2.979,
                lng: 145.738
            },
            {
                lat: -3.721,
                lng: 145.426
            },
            {
                lat: -3.775,
                lng: 145.350
            },
            {
                lat: -4.115,
                lng: 144.861
            },
            {
                lat: -4.455,
                lng: 144.419
            },
            {
                lat: -4.818,
                lng: 143.701
            },
            {
                lat: -5.180,
                lng: 143.056
            },
            {
                lat: -5.544,
                lng: 142.541
            },
            {
                lat: -5.907,
                lng: 142.066
            },
            {
                lat: -6.143,
                lng: 141.686
            },
            {
                lat: -6.386,
                lng: 141.284
            },
            {
                lat: -6.628,
                lng: 140.901
            },
            {
                lat: -6.988,
                lng: 140.333
            },
            {
                lat: -7.348,
                lng: 139.803
            },
            {
                lat: -7.713,
                lng: 139.573
            },
            {
                lat: -8.078,
                lng: 139.358
            },
            {
                lat: -8.155,
                lng: 139.248
            },
            {
                lat: -8.473,
                lng: 138.697
            },
            {
                lat: -8.791,
                lng: 138.175
            },
            {
                lat: -9.525,
                lng: 137.957
            },
            {
                lat: -9.985,
                lng: 137.360
            },
            {
                lat: -10.241,
                lng: 137.028
            },
            {
                lat: -10.600,
                lng: 136.627
            },
            {
                lat: -10.960,
                lng: 136.244
            },
            {
                lat: -11.324,
                lng: 136.022
            },
            {
                lat: -11.689,
                lng: 135.811
            },
            {
                lat: -11.696,
                lng: 135.801
            },
            {
                lat: -12.040,
                lng: 135.061
            },
            {
                lat: -12.385,
                lng: 134.359
            },
            {
                lat: -12.616,
                lng: 133.980
            },
            {
                lat: -12.849,
                lng: 133.498
            },
            {
                lat: -13.082,
                lng: 133.031
            },
            {
                lat: -13.358,
                lng: 132.252
            },
            {
                lat: -13.511,
                lng: 131.316
            },
            {
                lat: -13.665,
                lng: 130.422
            },
            {
                lat: -13.710,
                lng: 129.810
            },
            {
                lat: -13.793,
                lng: 128.642
            },
            {
                lat: -13.759,
                lng: 127.751
            },
            {
                lat: -13.727,
                lng: 126.893
            },
            {
                lat: -13.642,
                lng: 126.150
            },
            {
                lat: -13.558,
                lng: 125.430
            },
            {
                lat: -13.537,
                lng: 125.205
            },
            {
                lat: -13.483,
                lng: 124.420
            },
            {
                lat: -13.430,
                lng: 123.657
            },
            {
                lat: -13.338,
                lng: 122.899
            },
            {
                lat: -13.246,
                lng: 122.161
            },
            {
                lat: -13.182,
                lng: 121.453
            },
            {
                lat: -13.118,
                lng: 120.761
            },
            {
                lat: -13.072,
                lng: 120.090
            },
            {
                lat: -13.026,
                lng: 119.434
            },
            {
                lat: -12.995,
                lng: 118.180
            },
            {
                lat: -13.030,
                lng: 116.990
            },
            {
                lat: -13.113,
                lng: 115.853
            },
            {
                lat: -13.218,
                lng: 114.870
            },
            {
                lat: -13.239,
                lng: 114.761
            },
            {
                lat: -13.364,
                lng: 113.698
            },
            {
                lat: -13.284,
                lng: 112.608
            },
            {
                lat: -13.134,
                lng: 112.008
            },
            {
                lat: -13.059,
                lng: 111.509
            },
            {
                lat: -12.894,
                lng: 110.958
            },
            {
                lat: -12.729,
                lng: 110.416
            },
            {
                lat: -12.361,
                lng: 109.516
            },
            {
                lat: -12.272,
                lng: 109.326
            },
            {
                lat: -11.952,
                lng: 108.842
            },
            {
                lat: -11.632,
                lng: 108.365
            },
            {
                lat: -11.554,
                lng: 108.217
            },
            {
                lat: -11.237,
                lng: 107.685
            },
            {
                lat: -10.923,
                lng: 107.161
            },
            {
                lat: -10.909,
                lng: 107.141
            },
            {
                lat: -10.552,
                lng: 106.652
            },
            {
                lat: -10.196,
                lng: 106.171
            },
            {
                lat: -10.166,
                lng: 106.118
            },
            {
                lat: -9.840,
                lng: 105.618
            },
            {
                lat: -9.516,
                lng: 105.124
            },
            {
                lat: -9.487,
                lng: 105.084
            },
            {
                lat: -9.136,
                lng: 104.623
            },
            {
                lat: -8.786,
                lng: 104.167
            },
            {
                lat: -8.769,
                lng: 104.146
            },
            {
                lat: -8.430,
                lng: 103.752
            },
            {
                lat: -8.091,
                lng: 103.363
            },
            {
                lat: -7.962,
                lng: 103.189
            },
            {
                lat: -7.399,
                lng: 102.471
            },
            {
                lat: -7.218,
                lng: 102.267
            },
            {
                lat: -6.713,
                lng: 101.728
            },
            {
                lat: -6.424,
                lng: 101.364
            },
            {
                lat: -6.029,
                lng: 100.889
            },
            {
                lat: -5.628,
                lng: 100.485
            },
            {
                lat: -5.350,
                lng: 100.210
            },
            {
                lat: -4.735,
                lng: 99.623
            },
            {
                lat: -4.673,
                lng: 99.568
            },
            {
                lat: -4.000,
                lng: 98.990
            },
            {
                lat: -3.759,
                lng: 98.779
            },
            {
                lat: -3.329,
                lng: 98.419
            },
            {
                lat: -2.686,
                lng: 97.956
            },
            {
                lat: -2.660,
                lng: 97.938
            },
            {
                lat: -1.993,
                lng: 97.379
            },
            {
                lat: -1.658,
                lng: 97.157
            },
            {
                lat: -1.328,
                lng: 96.937
            },
            {
                lat: -0.663,
                lng: 96.404
            },
            {
                lat: -0.632,
                lng: 96.378
            },
            {
                lat: 0.000,
                lng: 95.851
            },
            {
                lat: 0.298,
                lng: 95.618
            },
            {
                lat: 0.662,
                lng: 95.336
            },
            {
                lat: 1.136,
                lng: 94.872
            },
            {
                lat: 1.323,
                lng: 94.697
            },
            {
                lat: 1.983,
                lng: 94.170
            },
            {
                lat: 2.018,
                lng: 94.140
            },
            {
                lat: 2.643,
                lng: 93.629
            },
            {
                lat: 2.927,
                lng: 93.421
            },
            {
                lat: 3.303,
                lng: 93.159
            },
            {
                lat: 3.963,
                lng: 92.812
            },
            {
                lat: 4.153,
                lng: 92.725
            },
            {
                lat: 4.624,
                lng: 92.530
            },
            {
                lat: 5.286,
                lng: 92.380
            },
            {
                lat: 5.950,
                lng: 92.353
            },
            {
                lat: 6.616,
                lng: 92.414
            },
            {
                lat: 7.284,
                lng: 92.590
            },
            {
                lat: 7.872,
                lng: 92.916
            },
            {
                lat: 7.957,
                lng: 92.967
            },
            {
                lat: 8.631,
                lng: 93.312
            },
            {
                lat: 9.262,
                lng: 93.764
            },
            {
                lat: 9.310,
                lng: 93.798
            },
            {
                lat: 9.991,
                lng: 94.213
            },
            {
                lat: 10.673,
                lng: 94.546
            },
            {
                lat: 10.942,
                lng: 94.675
            },
            {
                lat: 11.358,
                lng: 94.877
            },
            {
                lat: 12.043,
                lng: 95.000
            },
            {
                lat: 12.732,
                lng: 95.159
            },
            {
                lat: 13.419,
                lng: 95.125
            },
            {
                lat: 13.984,
                lng: 95.036
            },
            {
                lat: 14.109,
                lng: 95.017
            },
            {
                lat: 14.800,
                lng: 94.871
            },
            {
                lat: 15.490,
                lng: 94.559
            },
            {
                lat: 15.662,
                lng: 94.498
            },
            {
                lat: 16.185,
                lng: 94.306
            },
            {
                lat: 16.881,
                lng: 93.925
            },
            {
                lat: 16.913,
                lng: 93.911
            },
            {
                lat: 17.581,
                lng: 93.628
            },
            {
                lat: 18.172,
                lng: 93.335
            },
            {
                lat: 18.284,
                lng: 93.278
            },
            {
                lat: 18.992,
                lng: 92.947
            },
            {
                lat: 19.456,
                lng: 92.771
            },
            {
                lat: 19.705,
                lng: 92.675
            },
            {
                lat: 20.420,
                lng: 92.324
            },
            {
                lat: 20.898,
                lng: 92.241
            },
            {
                lat: 21.146,
                lng: 92.204
            },
            {
                lat: 21.875,
                lng: 91.991
            },
            {
                lat: 22.614,
                lng: 91.938
            },
            {
                lat: 23.360,
                lng: 91.947
            },
            {
                lat: 24.118,
                lng: 92.116
            },
            {
                lat: 24.891,
                lng: 92.526
            },
            {
                lat: 25.674,
                lng: 93.009
            },
            {
                lat: 25.933,
                lng: 93.286
            },
            {
                lat: 26.483,
                lng: 93.977
            },
            {
                lat: 26.729,
                lng: 94.317
            },
            {
                lat: 27.022,
                lng: 94.810
            },
            {
                lat: 27.317,
                lng: 95.309
            },
            {
                lat: 27.327,
                lng: 95.327
            },
            {
                lat: 27.745,
                lng: 96.310
            },
            {
                lat: 28.062,
                lng: 97.284
            },
            {
                lat: 28.218,
                lng: 97.882
            },
            {
                lat: 28.309,
                lng: 98.255
            },
            {
                lat: 28.455,
                lng: 99.208
            },
            {
                lat: 28.519,
                lng: 100.148
            },
            {
                lat: 28.563,
                lng: 101.095
            },
            {
                lat: 28.575,
                lng: 102.044
            },
            {
                lat: 28.498,
                lng: 102.971
            },
            {
                lat: 28.470,
                lng: 103.198
            },
        ],
        c22: [{
                lat: 37.557,
                lng: 121.663
            },
            {
                lat: 37.079,
                lng: 122.380
            },
            {
                lat: 36.717,
                lng: 122.762
            },
            {
                lat: 36.216,
                lng: 122.320
            },
            {
                lat: 35.723,
                lng: 121.902
            },
            {
                lat: 35.039,
                lng: 121.685
            },
            {
                lat: 34.789,
                lng: 121.569
            },
            {
                lat: 34.646,
                lng: 121.266
            },
            {
                lat: 34.406,
                lng: 120.632
            },
            {
                lat: 34.168,
                lng: 120.018
            },
            {
                lat: 33.934,
                lng: 119.422
            },
            {
                lat: 33.702,
                lng: 118.842
            },
            {
                lat: 33.674,
                lng: 118.721
            },
            {
                lat: 33.509,
                lng: 117.912
            },
            {
                lat: 33.347,
                lng: 117.127
            },
            {
                lat: 33.204,
                lng: 116.332
            },
            {
                lat: 33.064,
                lng: 115.558
            },
            {
                lat: 33.064,
                lng: 114.270
            },
            {
                lat: 33.278,
                lng: 113.344
            },
            {
                lat: 33.346,
                lng: 113.225
            },
            {
                lat: 34.138,
                lng: 113.080
            },
            {
                lat: 34.615,
                lng: 113.531
            },
            {
                lat: 35.098,
                lng: 114.001
            },
            {
                lat: 35.582,
                lng: 114.441
            },
            {
                lat: 36.073,
                lng: 114.899
            },
            {
                lat: 36.385,
                lng: 115.424
            },
            {
                lat: 36.700,
                lng: 115.965
            },
            {
                lat: 37.154,
                lng: 116.883
            },
            {
                lat: 37.379,
                lng: 117.566
            },
            {
                lat: 37.606,
                lng: 118.271
            },
            {
                lat: 37.714,
                lng: 119.134
            },
            {
                lat: 37.825,
                lng: 120.024
            },
            {
                lat: 37.666,
                lng: 121.432
            },
            {
                lat: 37.557,
                lng: 121.663
            },
        ],
        c23: [{
                lat: 26.038,
                lng: 102.143
            },
            {
                lat: 25.912,
                lng: 102.924
            },
            {
                lat: 25.794,
                lng: 103.827
            },
            {
                lat: 25.631,
                lng: 104.726
            },
            {
                lat: 25.429,
                lng: 105.621
            },
            {
                lat: 25.422,
                lng: 105.637
            },
            {
                lat: 25.141,
                lng: 106.493
            },
            {
                lat: 24.727,
                lng: 107.314
            },
            {
                lat: 24.722,
                lng: 107.322
            },
            {
                lat: 24.148,
                lng: 108.098
            },
            {
                lat: 24.000,
                lng: 108.231
            },
            {
                lat: 23.478,
                lng: 108.842
            },
            {
                lat: 23.272,
                lng: 109.034
            },
            {
                lat: 22.651,
                lng: 109.534
            },
            {
                lat: 22.537,
                lng: 109.609
            },
            {
                lat: 21.797,
                lng: 109.951
            },
            {
                lat: 21.492,
                lng: 110.106
            },
            {
                lat: 21.064,
                lng: 110.349
            },
            {
                lat: 20.329,
                lng: 110.622
            },
            {
                lat: 20.241,
                lng: 110.664
            },
            {
                lat: 19.602,
                lng: 110.972
            },
            {
                lat: 18.964,
                lng: 111.237
            },
            {
                lat: 18.876,
                lng: 111.280
            },
            {
                lat: 18.157,
                lng: 111.644
            },
            {
                lat: 17.728,
                lng: 111.853
            },
            {
                lat: 17.441,
                lng: 112.017
            },
            {
                lat: 16.731,
                lng: 112.468
            },
            {
                lat: 16.596,
                lng: 112.533
            },
            {
                lat: 16.021,
                lng: 112.855
            },
            {
                lat: 15.348,
                lng: 113.204
            },
            {
                lat: 15.313,
                lng: 113.227
            },
            {
                lat: 14.611,
                lng: 113.719
            },
            {
                lat: 14.204,
                lng: 113.940
            },
            {
                lat: 13.908,
                lng: 114.123
            },
            {
                lat: 13.205,
                lng: 114.460
            },
            {
                lat: 12.896,
                lng: 114.664
            },
            {
                lat: 12.510,
                lng: 114.991
            },
            {
                lat: 11.813,
                lng: 115.448
            },
            {
                lat: 11.776,
                lng: 115.480
            },
            {
                lat: 11.451,
                lng: 115.836
            },
            {
                lat: 11.125,
                lng: 116.196
            },
            {
                lat: 10.943,
                lng: 116.406
            },
            {
                lat: 10.445,
                lng: 117.262
            },
            {
                lat: 10.337,
                lng: 117.423
            },
            {
                lat: 10.087,
                lng: 117.958
            },
            {
                lat: 9.836,
                lng: 118.502
            },
            {
                lat: 9.772,
                lng: 118.677
            },
            {
                lat: 9.421,
                lng: 119.641
            },
            {
                lat: 9.111,
                lng: 120.727
            },
            {
                lat: 9.087,
                lng: 120.843
            },
            {
                lat: 8.925,
                lng: 121.464
            },
            {
                lat: 8.763,
                lng: 122.097
            },
            {
                lat: 8.454,
                lng: 123.181
            },
            {
                lat: 8.385,
                lng: 123.395
            },
            {
                lat: 8.112,
                lng: 124.046
            },
            {
                lat: 7.838,
                lng: 124.714
            },
            {
                lat: 7.776,
                lng: 124.840
            },
            {
                lat: 7.481,
                lng: 125.453
            },
            {
                lat: 7.186,
                lng: 126.083
            },
            {
                lat: 7.090,
                lng: 126.248
            },
            {
                lat: 6.815,
                lng: 126.887
            },
            {
                lat: 6.541,
                lng: 127.545
            },
            {
                lat: 6.402,
                lng: 127.785
            },
            {
                lat: 6.056,
                lng: 128.418
            },
            {
                lat: 5.710,
                lng: 129.074
            },
            {
                lat: 5.707,
                lng: 129.079
            },
            {
                lat: 5.356,
                lng: 129.612
            },
            {
                lat: 5.005,
                lng: 130.163
            },
            {
                lat: 4.728,
                lng: 130.711
            },
            {
                lat: 4.303,
                lng: 131.467
            },
            {
                lat: 3.972,
                lng: 131.981
            },
            {
                lat: 3.641,
                lng: 132.515
            },
            {
                lat: 3.595,
                lng: 132.582
            },
            {
                lat: 3.239,
                lng: 133.060
            },
            {
                lat: 2.883,
                lng: 133.556
            },
            {
                lat: 2.607,
                lng: 134.041
            },
            {
                lat: 2.331,
                lng: 134.545
            },
            {
                lat: 2.169,
                lng: 134.821
            },
            {
                lat: 1.810,
                lng: 135.300
            },
            {
                lat: 1.449,
                lng: 135.801
            },
            {
                lat: 1.088,
                lng: 136.285
            },
            {
                lat: 0.727,
                lng: 136.794
            },
            {
                lat: 0.612,
                lng: 136.943
            },
            {
                lat: 0.306,
                lng: 137.329
            },
            {
                lat: 0.000,
                lng: 137.732
            },
            {
                lat: -0.365,
                lng: 138.176
            },
            {
                lat: -0.730,
                lng: 138.647
            },
            {
                lat: -1.462,
                lng: 139.084
            },
            {
                lat: -2.197,
                lng: 139.548
            },
            {
                lat: -2.928,
                lng: 139.435
            },
            {
                lat: -3.293,
                lng: 139.202
            },
            {
                lat: -3.657,
                lng: 138.983
            },
            {
                lat: -4.021,
                lng: 138.717
            },
            {
                lat: -4.384,
                lng: 138.467
            },
            {
                lat: -4.905,
                lng: 137.723
            },
            {
                lat: -5.102,
                lng: 137.428
            },
            {
                lat: -5.463,
                lng: 137.093
            },
            {
                lat: -5.824,
                lng: 136.773
            },
            {
                lat: -6.182,
                lng: 136.319
            },
            {
                lat: -6.540,
                lng: 135.885
            },
            {
                lat: -6.712,
                lng: 135.662
            },
            {
                lat: -7.255,
                lng: 135.054
            },
            {
                lat: -7.979,
                lng: 134.767
            },
            {
                lat: -8.258,
                lng: 134.386
            },
            {
                lat: -8.536,
                lng: 134.016
            },
            {
                lat: -8.689,
                lng: 133.792
            },
            {
                lat: -9.411,
                lng: 133.428
            },
            {
                lat: -9.755,
                lng: 132.975
            },
            {
                lat: -10.098,
                lng: 132.537
            },
            {
                lat: -10.119,
                lng: 132.507
            },
            {
                lat: -10.469,
                lng: 131.930
            },
            {
                lat: -10.820,
                lng: 131.374
            },
            {
                lat: -11.108,
                lng: 130.986
            },
            {
                lat: -11.519,
                lng: 130.257
            },
            {
                lat: -11.785,
                lng: 129.417
            },
            {
                lat: -11.931,
                lng: 128.586
            },
            {
                lat: -12.076,
                lng: 127.785
            },
            {
                lat: -12.152,
                lng: 127.078
            },
            {
                lat: -12.252,
                lng: 126.211
            },
            {
                lat: -12.258,
                lng: 125.425
            },
            {
                lat: -12.265,
                lng: 124.663
            },
            {
                lat: -12.247,
                lng: 123.912
            },
            {
                lat: -12.230,
                lng: 123.181
            },
            {
                lat: -12.179,
                lng: 122.457
            },
            {
                lat: -12.130,
                lng: 121.751
            },
            {
                lat: -12.072,
                lng: 121.058
            },
            {
                lat: -12.015,
                lng: 120.382
            },
            {
                lat: -11.966,
                lng: 119.722
            },
            {
                lat: -11.917,
                lng: 119.076
            },
            {
                lat: -11.912,
                lng: 118.999
            },
            {
                lat: -11.834,
                lng: 117.825
            },
            {
                lat: -11.806,
                lng: 116.633
            },
            {
                lat: -11.806,
                lng: 115.488
            },
            {
                lat: -11.811,
                lng: 115.378
            },
            {
                lat: -11.878,
                lng: 114.394
            },
            {
                lat: -11.928,
                lng: 113.324
            },
            {
                lat: -11.882,
                lng: 112.259
            },
            {
                lat: -11.743,
                lng: 111.200
            },
            {
                lat: -11.698,
                lng: 111.052
            },
            {
                lat: -11.463,
                lng: 110.138
            },
            {
                lat: -11.265,
                lng: 109.606
            },
            {
                lat: -11.067,
                lng: 109.081
            },
            {
                lat: -10.947,
                lng: 108.844
            },
            {
                lat: -10.510,
                lng: 108.025
            },
            {
                lat: -10.225,
                lng: 107.573
            },
            {
                lat: -9.940,
                lng: 106.998
            },
            {
                lat: -9.509,
                lng: 106.252
            },
            {
                lat: -9.335,
                lng: 105.994
            },
            {
                lat: -8.804,
                lng: 105.250
            },
            {
                lat: -8.633,
                lng: 105.006
            },
            {
                lat: -8.106,
                lng: 104.350
            },
            {
                lat: -7.897,
                lng: 104.043
            },
            {
                lat: -7.412,
                lng: 103.388
            },
            {
                lat: -7.165,
                lng: 103.107
            },
            {
                lat: -6.724,
                lng: 102.618
            },
            {
                lat: -6.409,
                lng: 102.195
            },
            {
                lat: -6.038,
                lng: 101.726
            },
            {
                lat: -5.656,
                lng: 101.306
            },
            {
                lat: -5.357,
                lng: 101.006
            },
            {
                lat: -4.774,
                lng: 100.431
            },
            {
                lat: -4.680,
                lng: 100.342
            },
            {
                lat: -4.005,
                lng: 99.777
            },
            {
                lat: -3.790,
                lng: 99.576
            },
            {
                lat: -3.333,
                lng: 99.182
            },
            {
                lat: -2.717,
                lng: 98.741
            },
            {
                lat: -2.664,
                lng: 98.705
            },
            {
                lat: -1.996,
                lng: 98.150
            },
            {
                lat: -1.649,
                lng: 97.932
            },
            {
                lat: -1.329,
                lng: 97.739
            },
            {
                lat: -0.664,
                lng: 97.209
            },
            {
                lat: -0.581,
                lng: 97.144
            },
            {
                lat: 0.000,
                lng: 96.690
            },
            {
                lat: 0.402,
                lng: 96.377
            },
            {
                lat: 0.663,
                lng: 96.183
            },
            {
                lat: 1.217,
                lng: 95.625
            },
            {
                lat: 1.325,
                lng: 95.520
            },
            {
                lat: 1.986,
                lng: 94.998
            },
            {
                lat: 2.103,
                lng: 94.887
            },
            {
                lat: 2.646,
                lng: 94.423
            },
            {
                lat: 3.002,
                lng: 94.162
            },
            {
                lat: 3.306,
                lng: 93.950
            },
            {
                lat: 3.967,
                lng: 93.581
            },
            {
                lat: 4.223,
                lng: 93.462
            },
            {
                lat: 4.629,
                lng: 93.288
            },
            {
                lat: 5.291,
                lng: 93.155
            },
            {
                lat: 5.956,
                lng: 93.143
            },
            {
                lat: 6.623,
                lng: 93.258
            },
            {
                lat: 7.293,
                lng: 93.481
            },
            {
                lat: 7.548,
                lng: 93.635
            },
            {
                lat: 7.967,
                lng: 93.890
            },
            {
                lat: 8.642,
                lng: 94.256
            },
            {
                lat: 8.992,
                lng: 94.493
            },
            {
                lat: 9.322,
                lng: 94.725
            },
            {
                lat: 10.004,
                lng: 95.129
            },
            {
                lat: 10.573,
                lng: 95.401
            },
            {
                lat: 10.687,
                lng: 95.457
            },
            {
                lat: 11.373,
                lng: 95.770
            },
            {
                lat: 12.059,
                lng: 95.898
            },
            {
                lat: 12.749,
                lng: 96.056
            },
            {
                lat: 13.438,
                lng: 96.042
            },
            {
                lat: 14.128,
                lng: 95.949
            },
            {
                lat: 14.497,
                lng: 95.887
            },
            {
                lat: 14.821,
                lng: 95.834
            },
            {
                lat: 15.513,
                lng: 95.549
            },
            {
                lat: 16.121,
                lng: 95.349
            },
            {
                lat: 16.209,
                lng: 95.321
            },
            {
                lat: 16.906,
                lng: 95.000
            },
            {
                lat: 17.457,
                lng: 94.780
            },
            {
                lat: 17.608,
                lng: 94.720
            },
            {
                lat: 18.313,
                lng: 94.408
            },
            {
                lat: 18.760,
                lng: 94.217
            },
            {
                lat: 19.022,
                lng: 94.106
            },
            {
                lat: 19.737,
                lng: 93.886
            },
            {
                lat: 20.238,
                lng: 93.694
            },
            {
                lat: 20.455,
                lng: 93.612
            },
            {
                lat: 21.183,
                lng: 93.529
            },
            {
                lat: 21.915,
                lng: 93.374
            },
            {
                lat: 22.631,
                lng: 93.367
            },
            {
                lat: 22.656,
                lng: 93.367
            },
            {
                lat: 23.408,
                lng: 93.492
            },
            {
                lat: 23.654,
                lng: 93.584
            },
            {
                lat: 24.172,
                lng: 93.795
            },
            {
                lat: 24.956,
                lng: 94.465
            },
            {
                lat: 25.274,
                lng: 94.785
            },
            {
                lat: 25.761,
                lng: 95.463
            },
            {
                lat: 25.922,
                lng: 95.795
            },
            {
                lat: 26.210,
                lng: 96.730
            },
            {
                lat: 26.419,
                lng: 97.659
            },
            {
                lat: 26.444,
                lng: 98.546
            },
            {
                lat: 26.419,
                lng: 99.428
            },
            {
                lat: 26.280,
                lng: 100.283
            },
            {
                lat: 26.185,
                lng: 101.163
            },
            {
                lat: 26.055,
                lng: 102.040
            },
            {
                lat: 26.038,
                lng: 102.143
            },
        ],
        c24: [{
                lat: 23.810,
                lng: 104.114
            },
            {
                lat: 23.716,
                lng: 104.971
            },
            {
                lat: 23.497,
                lng: 105.854
            },
            {
                lat: 23.159,
                lng: 106.592
            },
            {
                lat: 23.104,
                lng: 106.685
            },
            {
                lat: 22.783,
                lng: 107.057
            },
            {
                lat: 22.464,
                lng: 107.432
            },
            {
                lat: 22.439,
                lng: 107.453
            },
            {
                lat: 22.076,
                lng: 107.773
            },
            {
                lat: 21.715,
                lng: 108.096
            },
            {
                lat: 21.666,
                lng: 108.132
            },
            {
                lat: 20.987,
                lng: 108.581
            },
            {
                lat: 20.653,
                lng: 108.765
            },
            {
                lat: 20.258,
                lng: 108.943
            },
            {
                lat: 19.533,
                lng: 109.292
            },
            {
                lat: 19.428,
                lng: 109.341
            },
            {
                lat: 18.809,
                lng: 109.590
            },
            {
                lat: 18.089,
                lng: 109.885
            },
            {
                lat: 18.063,
                lng: 109.894
            },
            {
                lat: 17.372,
                lng: 110.162
            },
            {
                lat: 16.658,
                lng: 110.448
            },
            {
                lat: 16.636,
                lng: 110.459
            },
            {
                lat: 15.951,
                lng: 110.829
            },
            {
                lat: 15.240,
                lng: 111.031
            },
            {
                lat: 15.189,
                lng: 111.052
            },
            {
                lat: 14.536,
                lng: 111.358
            },
            {
                lat: 13.832,
                lng: 111.618
            },
            {
                lat: 13.668,
                lng: 111.665
            },
            {
                lat: 13.129,
                lng: 111.830
            },
            {
                lat: 12.433,
                lng: 112.222
            },
            {
                lat: 12.110,
                lng: 112.313
            },
            {
                lat: 11.733,
                lng: 112.432
            },
            {
                lat: 11.040,
                lng: 112.790
            },
            {
                lat: 10.660,
                lng: 113.033
            },
            {
                lat: 10.349,
                lng: 113.252
            },
            {
                lat: 9.658,
                lng: 113.604
            },
            {
                lat: 9.428,
                lng: 113.845
            },
            {
                lat: 8.976,
                lng: 114.407
            },
            {
                lat: 8.636,
                lng: 114.779
            },
            {
                lat: 8.298,
                lng: 115.412
            },
            {
                lat: 8.133,
                lng: 115.797
            },
            {
                lat: 7.888,
                lng: 116.320
            },
            {
                lat: 7.642,
                lng: 116.850
            },
            {
                lat: 7.627,
                lng: 116.897
            },
            {
                lat: 7.451,
                lng: 117.424
            },
            {
                lat: 7.275,
                lng: 117.960
            },
            {
                lat: 7.120,
                lng: 118.510
            },
            {
                lat: 6.964,
                lng: 119.070
            },
            {
                lat: 6.952,
                lng: 119.117
            },
            {
                lat: 6.822,
                lng: 119.717
            },
            {
                lat: 6.692,
                lng: 120.329
            },
            {
                lat: 6.574,
                lng: 120.955
            },
            {
                lat: 6.457,
                lng: 121.594
            },
            {
                lat: 6.313,
                lng: 122.285
            },
            {
                lat: 6.166,
                lng: 122.905
            },
            {
                lat: 5.973,
                lng: 123.575
            },
            {
                lat: 5.779,
                lng: 124.263
            },
            {
                lat: 5.641,
                lng: 124.597
            },
            {
                lat: 5.452,
                lng: 125.129
            },
            {
                lat: 5.263,
                lng: 125.673
            },
            {
                lat: 4.956,
                lng: 126.389
            },
            {
                lat: 4.641,
                lng: 127.155
            },
            {
                lat: 4.263,
                lng: 127.878
            },
            {
                lat: 4.006,
                lng: 128.287
            },
            {
                lat: 3.749,
                lng: 128.706
            },
            {
                lat: 3.561,
                lng: 128.977
            },
            {
                lat: 3.209,
                lng: 129.471
            },
            {
                lat: 2.856,
                lng: 129.982
            },
            {
                lat: 2.635,
                lng: 130.378
            },
            {
                lat: 2.148,
                lng: 131.154
            },
            {
                lat: 1.792,
                lng: 131.539
            },
            {
                lat: 1.435,
                lng: 131.937
            },
            {
                lat: 1.207,
                lng: 132.233
            },
            {
                lat: 0.719,
                lng: 132.803
            },
            {
                lat: 0.360,
                lng: 133.154
            },
            {
                lat: 0.000,
                lng: 133.518
            },
            {
                lat: -0.721,
                lng: 133.944
            },
            {
                lat: -1.444,
                lng: 134.286
            },
            {
                lat: -2.167,
                lng: 134.495
            },
            {
                lat: -2.887,
                lng: 134.140
            },
            {
                lat: -3.607,
                lng: 133.807
            },
            {
                lat: -3.965,
                lng: 133.480
            },
            {
                lat: -4.322,
                lng: 133.166
            },
            {
                lat: -4.803,
                lng: 132.753
            },
            {
                lat: -5.037,
                lng: 132.557
            },
            {
                lat: -5.751,
                lng: 132.070
            },
            {
                lat: -6.108,
                lng: 131.797
            },
            {
                lat: -6.465,
                lng: 131.534
            },
            {
                lat: -6.835,
                lng: 131.250
            },
            {
                lat: -7.178,
                lng: 131.016
            },
            {
                lat: -7.892,
                lng: 130.613
            },
            {
                lat: -8.212,
                lng: 130.249
            },
            {
                lat: -8.531,
                lng: 129.896
            },
            {
                lat: -8.598,
                lng: 129.821
            },
            {
                lat: -9.310,
                lng: 129.325
            },
            {
                lat: -9.748,
                lng: 128.540
            },
            {
                lat: -10.000,
                lng: 127.955
            },
            {
                lat: -10.394,
                lng: 127.085
            },
            {
                lat: -10.674,
                lng: 126.122
            },
            {
                lat: -10.789,
                lng: 125.629
            },
            {
                lat: -10.877,
                lng: 124.893
            },
            {
                lat: -10.965,
                lng: 124.176
            },
            {
                lat: -11.003,
                lng: 123.460
            },
            {
                lat: -11.040,
                lng: 122.762
            },
            {
                lat: -11.031,
                lng: 122.066
            },
            {
                lat: -11.023,
                lng: 121.386
            },
            {
                lat: -10.989,
                lng: 120.713
            },
            {
                lat: -10.955,
                lng: 120.054
            },
            {
                lat: -10.911,
                lng: 119.406
            },
            {
                lat: -10.868,
                lng: 118.771
            },
            {
                lat: -10.776,
                lng: 117.535
            },
            {
                lat: -10.706,
                lng: 116.347
            },
            {
                lat: -10.600,
                lng: 115.189
            },
            {
                lat: -10.550,
                lng: 114.079
            },
            {
                lat: -10.500,
                lng: 112.999
            },
            {
                lat: -10.422,
                lng: 111.942
            },
            {
                lat: -10.301,
                lng: 111.118
            },
            {
                lat: -10.270,
                lng: 110.897
            },
            {
                lat: -10.068,
                lng: 109.869
            },
            {
                lat: -9.755,
                lng: 108.847
            },
            {
                lat: -9.549,
                lng: 108.349
            },
            {
                lat: -9.302,
                lng: 107.828
            },
            {
                lat: -8.834,
                lng: 106.969
            },
            {
                lat: -8.757,
                lng: 106.823
            },
            {
                lat: -8.461,
                lng: 106.327
            },
            {
                lat: -8.166,
                lng: 105.839
            },
            {
                lat: -8.129,
                lng: 105.785
            },
            {
                lat: -7.842,
                lng: 105.329
            },
            {
                lat: -7.555,
                lng: 104.879
            },
            {
                lat: -7.430,
                lng: 104.692
            },
            {
                lat: -6.821,
                lng: 103.932
            },
            {
                lat: -6.739,
                lng: 103.840
            },
            {
                lat: -6.170,
                lng: 103.018
            },
            {
                lat: -6.050,
                lng: 102.854
            },
            {
                lat: -5.419,
                lng: 102.118
            },
            {
                lat: -5.367,
                lng: 102.062
            },
            {
                lat: -4.689,
                lng: 101.435
            },
            {
                lat: -4.491,
                lng: 101.232
            },
            {
                lat: -4.012,
                lng: 100.800
            },
            {
                lat: -3.540,
                lng: 100.369
            },
            {
                lat: -3.339,
                lng: 100.189
            },
            {
                lat: -2.668,
                lng: 99.731
            },
            {
                lat: -2.435,
                lng: 99.527
            },
            {
                lat: -1.999,
                lng: 99.185
            },
            {
                lat: -1.332,
                lng: 98.795
            },
            {
                lat: -1.212,
                lng: 98.708
            },
            {
                lat: -0.665,
                lng: 98.335
            },
            {
                lat: -0.117,
                lng: 97.917
            },
            {
                lat: 0.000,
                lng: 97.832
            },
            {
                lat: 0.664,
                lng: 97.330
            },
            {
                lat: 0.857,
                lng: 97.146
            },
            {
                lat: 1.327,
                lng: 96.696
            },
            {
                lat: 1.684,
                lng: 96.390
            },
            {
                lat: 1.989,
                lng: 96.161
            },
            {
                lat: 2.508,
                lng: 95.648
            },
            {
                lat: 2.650,
                lng: 95.515
            },
            {
                lat: 3.312,
                lng: 95.005
            },
            {
                lat: 3.443,
                lng: 94.922
            },
            {
                lat: 3.973,
                lng: 94.617
            },
            {
                lat: 4.635,
                lng: 94.297
            },
            {
                lat: 4.879,
                lng: 94.230
            },
            {
                lat: 5.299,
                lng: 94.136
            },
            {
                lat: 5.965,
                lng: 94.166
            },
            {
                lat: 6.633,
                lng: 94.291
            },
            {
                lat: 6.721,
                lng: 94.328
            },
            {
                lat: 7.305,
                lng: 94.608
            },
            {
                lat: 7.980,
                lng: 95.034
            },
            {
                lat: 8.274,
                lng: 95.192
            },
            {
                lat: 8.657,
                lng: 95.412
            },
            {
                lat: 9.338,
                lng: 95.912
            },
            {
                lat: 9.662,
                lng: 96.079
            },
            {
                lat: 10.021,
                lng: 96.280
            },
            {
                lat: 10.706,
                lng: 96.617
            },
            {
                lat: 11.393,
                lng: 96.911
            },
            {
                lat: 12.080,
                lng: 97.024
            },
            {
                lat: 12.771,
                lng: 97.192
            },
            {
                lat: 13.461,
                lng: 97.177
            },
            {
                lat: 14.153,
                lng: 97.105
            },
            {
                lat: 14.847,
                lng: 97.018
            },
            {
                lat: 15.335,
                lng: 96.807
            },
            {
                lat: 15.540,
                lng: 96.731
            },
            {
                lat: 16.240,
                lng: 96.594
            },
            {
                lat: 16.939,
                lng: 96.303
            },
            {
                lat: 16.988,
                lng: 96.286
            },
            {
                lat: 17.643,
                lng: 96.056
            },
            {
                lat: 18.351,
                lng: 95.858
            },
            {
                lat: 18.577,
                lng: 95.773
            },
            {
                lat: 19.062,
                lng: 95.596
            },
            {
                lat: 19.783,
                lng: 95.518
            },
            {
                lat: 20.505,
                lng: 95.355
            },
            {
                lat: 20.659,
                lng: 95.377
            },
            {
                lat: 21.241,
                lng: 95.464
            },
            {
                lat: 21.982,
                lng: 95.560
            },
            {
                lat: 22.435,
                lng: 95.763
            },
            {
                lat: 22.738,
                lng: 95.940
            },
            {
                lat: 23.091,
                lng: 96.386
            },
            {
                lat: 23.445,
                lng: 96.840
            },
            {
                lat: 23.530,
                lng: 97.106
            },
            {
                lat: 23.725,
                lng: 97.761
            },
            {
                lat: 23.838,
                lng: 98.652
            },
            {
                lat: 23.910,
                lng: 99.545
            },
            {
                lat: 23.913,
                lng: 100.429
            },
            {
                lat: 23.962,
                lng: 101.340
            },
            {
                lat: 23.956,
                lng: 102.247
            },
            {
                lat: 23.916,
                lng: 103.156
            },
            {
                lat: 23.820,
                lng: 104.058
            },
            {
                lat: 23.810,
                lng: 104.114
            },
        ],
        c25: [{
                lat: 21.586,
                lng: 105.064
            },
            {
                lat: 21.548,
                lng: 105.175
            },
            {
                lat: 21.099,
                lng: 105.978
            },
            {
                lat: 20.888,
                lng: 106.196
            },
            {
                lat: 20.334,
                lng: 106.686
            },
            {
                lat: 20.171,
                lng: 106.802
            },
            {
                lat: 19.454,
                lng: 107.293
            },
            {
                lat: 19.367,
                lng: 107.341
            },
            {
                lat: 18.734,
                lng: 107.621
            },
            {
                lat: 18.092,
                lng: 107.916
            },
            {
                lat: 18.017,
                lng: 107.945
            },
            {
                lat: 17.300,
                lng: 108.142
            },
            {
                lat: 16.585,
                lng: 108.324
            },
            {
                lat: 16.372,
                lng: 108.389
            },
            {
                lat: 15.875,
                lng: 108.540
            },
            {
                lat: 15.161,
                lng: 108.571
            },
            {
                lat: 14.455,
                lng: 108.729
            },
            {
                lat: 14.085,
                lng: 108.767
            },
            {
                lat: 13.750,
                lng: 108.813
            },
            {
                lat: 13.044,
                lng: 108.796
            },
            {
                lat: 12.349,
                lng: 109.060
            },
            {
                lat: 11.646,
                lng: 108.954
            },
            {
                lat: 10.951,
                lng: 109.034
            },
            {
                lat: 10.533,
                lng: 108.982
            },
            {
                lat: 10.254,
                lng: 108.945
            },
            {
                lat: 10.184,
                lng: 108.920
            },
            {
                lat: 9.551,
                lng: 108.441
            },
            {
                lat: 8.858,
                lng: 108.292
            },
            {
                lat: 8.321,
                lng: 107.686
            },
            {
                lat: 8.801,
                lng: 106.829
            },
            {
                lat: 8.831,
                lng: 106.763
            },
            {
                lat: 9.141,
                lng: 106.386
            },
            {
                lat: 9.452,
                lng: 106.011
            },
            {
                lat: 9.501,
                lng: 105.828
            },
            {
                lat: 9.552,
                lng: 105.129
            },
            {
                lat: 9.481,
                lng: 104.751
            },
            {
                lat: 9.338,
                lng: 104.219
            },
            {
                lat: 9.399,
                lng: 103.360
            },
            {
                lat: 9.093,
                lng: 102.893
            },
            {
                lat: 8.788,
                lng: 102.432
            },
            {
                lat: 8.758,
                lng: 102.406
            },
            {
                lat: 8.068,
                lng: 101.781
            },
            {
                lat: 7.387,
                lng: 101.534
            },
            {
                lat: 7.018,
                lng: 101.415
            },
            {
                lat: 6.708,
                lng: 101.329
            },
            {
                lat: 6.030,
                lng: 101.039
            },
            {
                lat: 5.357,
                lng: 101.032
            },
            {
                lat: 4.685,
                lng: 100.988
            },
            {
                lat: 4.013,
                lng: 100.899
            },
            {
                lat: 3.472,
                lng: 101.181
            },
            {
                lat: 3.345,
                lng: 101.260
            },
            {
                lat: 2.677,
                lng: 101.552
            },
            {
                lat: 2.232,
                lng: 101.960
            },
            {
                lat: 2.010,
                lng: 102.241
            },
            {
                lat: 1.599,
                lng: 102.779
            },
            {
                lat: 1.342,
                lng: 103.316
            },
            {
                lat: 1.169,
                lng: 103.618
            },
            {
                lat: 0.672,
                lng: 104.035
            },
            {
                lat: 0.453,
                lng: 104.468
            },
            {
                lat: 0.120,
                lng: 105.339
            },
            {
                lat: 0.000,
                lng: 105.741
            },
            {
                lat: -0.506,
                lng: 106.228
            },
            {
                lat: -0.675,
                lng: 106.380
            },
            {
                lat: -1.251,
                lng: 107.141
            },
            {
                lat: -1.352,
                lng: 107.234
            },
            {
                lat: -1.864,
                lng: 108.077
            },
            {
                lat: -2.034,
                lng: 108.445
            },
            {
                lat: -2.444,
                lng: 109.036
            },
            {
                lat: -2.721,
                lng: 109.891
            },
            {
                lat: -2.738,
                lng: 110.008
            },
            {
                lat: -2.870,
                lng: 110.994
            },
            {
                lat: -2.755,
                lng: 111.990
            },
            {
                lat: -2.734,
                lng: 112.111
            },
            {
                lat: -2.542,
                lng: 113.005
            },
            {
                lat: -2.305,
                lng: 114.045
            },
            {
                lat: -2.238,
                lng: 115.123
            },
            {
                lat: -2.109,
                lng: 116.229
            },
            {
                lat: -2.072,
                lng: 116.769
            },
            {
                lat: -1.980,
                lng: 117.370
            },
            {
                lat: -1.889,
                lng: 118.552
            },
            {
                lat: -1.723,
                lng: 119.153
            },
            {
                lat: -1.557,
                lng: 119.766
            },
            {
                lat: -1.395,
                lng: 120.880
            },
            {
                lat: -1.116,
                lng: 121.028
            },
            {
                lat: -0.698,
                lng: 121.288
            },
            {
                lat: 0.000,
                lng: 121.631
            },
            {
                lat: 0.269,
                lng: 121.012
            },
            {
                lat: 0.448,
                lng: 120.369
            },
            {
                lat: 0.627,
                lng: 119.740
            },
            {
                lat: 0.695,
                lng: 119.447
            },
            {
                lat: 1.041,
                lng: 119.093
            },
            {
                lat: 1.387,
                lng: 118.745
            },
            {
                lat: 1.426,
                lng: 118.533
            },
            {
                lat: 1.386,
                lng: 118.319
            },
            {
                lat: 1.263,
                lng: 117.342
            },
            {
                lat: 1.181,
                lng: 116.194
            },
            {
                lat: 1.061,
                lng: 115.081
            },
            {
                lat: 1.001,
                lng: 114.000
            },
            {
                lat: 0.761,
                lng: 112.946
            },
            {
                lat: 0.926,
                lng: 111.925
            },
            {
                lat: 0.985,
                lng: 110.927
            },
            {
                lat: 1.113,
                lng: 109.952
            },
            {
                lat: 1.357,
                lng: 109.048
            },
            {
                lat: 1.395,
                lng: 109.002
            },
            {
                lat: 2.034,
                lng: 108.458
            },
            {
                lat: 2.432,
                lng: 108.097
            },
            {
                lat: 2.710,
                lng: 107.922
            },
            {
                lat: 3.385,
                lng: 107.411
            },
            {
                lat: 4.064,
                lng: 107.522
            },
            {
                lat: 4.741,
                lng: 107.308
            },
            {
                lat: 4.741,
                lng: 107.308
            },
            {
                lat: 4.741,
                lng: 107.308
            },
            {
                lat: 5.427,
                lng: 107.836
            },
            {
                lat: 6.112,
                lng: 108.109
            },
            {
                lat: 6.796,
                lng: 108.105
            },
            {
                lat: 7.323,
                lng: 108.494
            },
            {
                lat: 7.303,
                lng: 109.446
            },
            {
                lat: 7.050,
                lng: 110.386
            },
            {
                lat: 6.834,
                lng: 110.790
            },
            {
                lat: 6.568,
                lng: 111.319
            },
            {
                lat: 6.165,
                lng: 112.182
            },
            {
                lat: 6.121,
                lng: 112.279
            },
            {
                lat: 5.898,
                lng: 112.768
            },
            {
                lat: 5.674,
                lng: 113.264
            },
            {
                lat: 5.495,
                lng: 113.621
            },
            {
                lat: 5.199,
                lng: 114.272
            },
            {
                lat: 4.823,
                lng: 115.206
            },
            {
                lat: 4.699,
                lng: 115.309
            },
            {
                lat: 4.140,
                lng: 115.989
            },
            {
                lat: 3.921,
                lng: 116.353
            },
            {
                lat: 3.726,
                lng: 116.908
            },
            {
                lat: 3.530,
                lng: 117.472
            },
            {
                lat: 3.462,
                lng: 117.650
            },
            {
                lat: 3.246,
                lng: 118.132
            },
            {
                lat: 3.029,
                lng: 118.622
            },
            {
                lat: 2.976,
                lng: 119.851
            },
            {
                lat: 2.961,
                lng: 121.132
            },
            {
                lat: 2.799,
                lng: 122.053
            },
            {
                lat: 2.634,
                lng: 122.444
            },
            {
                lat: 2.457,
                lng: 123.123
            },
            {
                lat: 2.280,
                lng: 123.819
            },
            {
                lat: 2.109,
                lng: 124.117
            },
            {
                lat: 1.760,
                lng: 124.671
            },
            {
                lat: 1.410,
                lng: 125.241
            },
            {
                lat: 1.410,
                lng: 125.241
            },
            {
                lat: 1.059,
                lng: 125.698
            },
            {
                lat: 0.707,
                lng: 126.168
            },
            {
                lat: 0.000,
                lng: 126.229
            },
            {
                lat: -0.707,
                lng: 126.256
            },
            {
                lat: -1.162,
                lng: 126.791
            },
            {
                lat: -1.416,
                lng: 126.942
            },
            {
                lat: -1.678,
                lng: 126.817
            },
            {
                lat: -2.122,
                lng: 126.395
            },
            {
                lat: -2.833,
                lng: 126.855
            },
            {
                lat: -2.982,
                lng: 126.926
            },
            {
                lat: -3.544,
                lng: 127.127
            },
            {
                lat: -3.832,
                lng: 127.031
            },
            {
                lat: -4.252,
                lng: 126.924
            },
            {
                lat: -4.587,
                lng: 127.146
            },
            {
                lat: -4.969,
                lng: 127.352
            },
            {
                lat: -5.251,
                lng: 127.265
            },
            {
                lat: -5.678,
                lng: 127.134
            },
            {
                lat: -6.390,
                lng: 127.057
            },
            {
                lat: -7.100,
                lng: 126.823
            },
            {
                lat: -7.806,
                lng: 126.380
            },
            {
                lat: -7.902,
                lng: 126.271
            },
            {
                lat: -8.202,
                lng: 125.870
            },
            {
                lat: -8.503,
                lng: 125.477
            },
            {
                lat: -8.895,
                lng: 125.004
            },
            {
                lat: -9.194,
                lng: 124.404
            },
            {
                lat: -9.384,
                lng: 123.669
            },
            {
                lat: -9.510,
                lng: 122.993
            },
            {
                lat: -9.635,
                lng: 122.332
            },
            {
                lat: -9.723,
                lng: 121.008
            },
            {
                lat: -9.717,
                lng: 119.715
            },
            {
                lat: -9.662,
                lng: 118.460
            },
            {
                lat: -9.584,
                lng: 117.837
            },
            {
                lat: -9.507,
                lng: 117.227
            },
            {
                lat: -9.369,
                lng: 116.041
            },
            {
                lat: -9.165,
                lng: 114.880
            },
            {
                lat: -8.964,
                lng: 113.830
            },
            {
                lat: -8.947,
                lng: 113.753
            },
            {
                lat: -8.753,
                lng: 112.663
            },
            {
                lat: -8.545,
                lng: 111.601
            },
            {
                lat: -8.320,
                lng: 110.564
            },
            {
                lat: -8.200,
                lng: 110.053
            },
            {
                lat: -8.074,
                lng: 109.549
            },
            {
                lat: -7.777,
                lng: 108.552
            },
            {
                lat: -7.502,
                lng: 107.581
            },
            {
                lat: -7.471,
                lng: 107.505
            },
            {
                lat: -7.213,
                lng: 107.050
            },
            {
                lat: -6.955,
                lng: 106.600
            },
            {
                lat: -6.771,
                lng: 106.278
            },
            {
                lat: -6.505,
                lng: 105.655
            },
            {
                lat: -6.074,
                lng: 104.923
            },
            {
                lat: -5.928,
                lng: 104.720
            },
            {
                lat: -5.386,
                lng: 103.966
            },
            {
                lat: -5.251,
                lng: 103.799
            },
            {
                lat: -4.703,
                lng: 103.189
            },
            {
                lat: -4.438,
                lng: 102.894
            },
            {
                lat: -4.025,
                lng: 102.496
            },
            {
                lat: -3.505,
                lng: 102.007
            },
            {
                lat: -3.349,
                lng: 101.870
            },
            {
                lat: -2.676,
                lng: 101.475
            },
            {
                lat: -2.315,
                lng: 101.139
            },
            {
                lat: -2.005,
                lng: 100.878
            },
            {
                lat: -1.336,
                lng: 100.667
            },
            {
                lat: -0.711,
                lng: 100.297
            },
            {
                lat: -0.667,
                lng: 100.274
            },
            {
                lat: 0.000,
                lng: 100.011
            },
            {
                lat: 0.667,
                lng: 99.724
            },
            {
                lat: 0.871,
                lng: 99.497
            },
            {
                lat: 1.332,
                lng: 98.994
            },
            {
                lat: 1.844,
                lng: 98.719
            },
            {
                lat: 1.997,
                lng: 98.632
            },
            {
                lat: 2.433,
                lng: 97.949
            },
            {
                lat: 2.660,
                lng: 97.731
            },
            {
                lat: 3.053,
                lng: 97.191
            },
            {
                lat: 3.321,
                lng: 96.924
            },
            {
                lat: 3.886,
                lng: 96.453
            },
            {
                lat: 3.984,
                lng: 96.385
            },
            {
                lat: 4.646,
                lng: 95.950
            },
            {
                lat: 5.255,
                lng: 95.753
            },
            {
                lat: 5.311,
                lng: 95.741
            },
            {
                lat: 5.979,
                lng: 95.756
            },
            {
                lat: 6.191,
                lng: 95.805
            },
            {
                lat: 6.649,
                lng: 95.962
            },
            {
                lat: 7.324,
                lng: 96.346
            },
            {
                lat: 7.805,
                lng: 96.687
            },
            {
                lat: 8.002,
                lng: 96.871
            },
            {
                lat: 8.683,
                lng: 97.339
            },
            {
                lat: 8.962,
                lng: 97.568
            },
            {
                lat: 9.369,
                lng: 97.987
            },
            {
                lat: 10.054,
                lng: 98.339
            },
            {
                lat: 10.738,
                lng: 98.530
            },
            {
                lat: 10.805,
                lng: 98.562
            },
            {
                lat: 11.430,
                lng: 98.916
            },
            {
                lat: 12.115,
                lng: 98.833
            },
            {
                lat: 12.808,
                lng: 99.008
            },
            {
                lat: 13.371,
                lng: 98.914
            },
            {
                lat: 13.498,
                lng: 98.905
            },
            {
                lat: 14.191,
                lng: 98.796
            },
            {
                lat: 14.888,
                lng: 98.726
            },
            {
                lat: 15.493,
                lng: 98.448
            },
            {
                lat: 15.581,
                lng: 98.412
            },
            {
                lat: 16.283,
                lng: 98.298
            },
            {
                lat: 16.985,
                lng: 98.060
            },
            {
                lat: 17.421,
                lng: 97.985
            },
            {
                lat: 17.694,
                lng: 97.934
            },
            {
                lat: 18.408,
                lng: 97.864
            },
            {
                lat: 19.127,
                lng: 97.826
            },
            {
                lat: 19.859,
                lng: 98.033
            },
            {
                lat: 20.604,
                lng: 98.503
            },
            {
                lat: 20.761,
                lng: 98.709
            },
            {
                lat: 21.042,
                lng: 99.203
            },
            {
                lat: 21.324,
                lng: 99.704
            },
            {
                lat: 21.387,
                lng: 99.860
            },
            {
                lat: 21.643,
                lng: 100.659
            },
            {
                lat: 21.868,
                lng: 101.606
            },
            {
                lat: 21.960,
                lng: 102.531
            },
            {
                lat: 21.995,
                lng: 103.455
            },
            {
                lat: 21.841,
                lng: 104.332
            },
            {
                lat: 21.586,
                lng: 105.064
            },
        ],
        c26: [{
                lat: -6.264,
                lng: 119.036
            },
            {
                lat: -6.286,
                lng: 119.005
            },
            {
                lat: -6.262,
                lng: 118.942
            },
            {
                lat: -6.257,
                lng: 119.000
            },
            {
                lat: -6.264,
                lng: 119.036
            },
        ]
    };

    export var utelsat_gntgain = {
        "c1": '-6',
        "c2": '-6',
        "c3": '-6',
        "c4": '-4',
        "c5": '-4',
        "c6": '-4',
        "c7": '-2',
        "c8": '-2',
        "c9": '-2',
        "c10": '-1',
        "c11": '-1',
        "c12": '-1',
        "c13": '0',
        "c14": '0',
        "c15": '0',
        "c16": '0',
        "c17": '0',
        "c18": '1',
        "c19": '1',
        "c20": '2',
        "c21": '2',
        "c22": '2',
        "c23": '3',
        "c24": '4',
        "c25": '5',
        "c26": '6',
    };


