import axios from "@/plugins/axios_outside";
import { useAuthStore } from '@/stores';

export default {
    data() {
        return {
            auth_store: useAuthStore(),
        };
    },
    mounted() {
        console.log(this.$route.params.token);
        this.fetch_outside(this.$route.params.token)
    },

    methods: {

        async fetch_outside(token) {

            await axios.post(`/api/login_by_id`, {
                'token': token,
            }
            ).then((res) => {
                console.log(res);

                if (res.data.status == 'Token is Valid') {
                    this.auth_store.is_outsidelogin = true
                    console.log("MASUK KE TOKEN IS VALID");
                    localStorage.setItem("token", res.data.token);
                    setTimeout(() => {
                        this.auth_store.fetch_userdata()
                    }, 500);
                } else {
                    this.auth_store.logout()
                    this.$router.push("/");
                }

            }).catch((err) => {
                this.auth_store.logout()
                this.$router.push("/");
                console.log(e)
            }).then(() => {
            });

        },
    },
};