import 'photoswipe/style.css';
import { useMapStore, useCustomRangeStore, useManifestStore } from '@/stores';
import plc_manifestreport from '@/components/placeholders/ManifestReport_Plc.vue';
// import plc_mapiridium from '@/components/placeholders/IridiumMap_Plc.vue';
import range_modal from '@/components/modals/Range_Modal.vue';
import {
  LMap,
  LPopup,
  LControl,
  LTileLayer,
  LControlZoom,
  LGeoJson,
  LPolygon,

} from "vue2-leaflet";
import LControlFullscreen from "vue2-leaflet-fullscreen";
import LControlPolylineMeasure from "vue2-leaflet-polyline-measure";
import Vue2LeafletRotatedMarker from "vue2-leaflet-rotatedmarker";



export default {
  name: "RpmReport",
  components: {
    range_modal,
    LMap,
    LTileLayer,
    LPopup,
    LControlZoom,
    LControl,
    LGeoJson,
    LPolygon,
    LControlPolylineMeasure,
    LControlFullscreen,
    "v-rotated-marker": Vue2LeafletRotatedMarker,
  },
  data() {
    return {
      manifest_store: useManifestStore(),
      mapStore: useMapStore(),
      customrange_store: useCustomRangeStore(),
      range_modal: range_modal,
      unit: "nauticalmiles",
      plc_manifestreport: plc_manifestreport,
    }
  },

  mounted() {
    var mother = this
    // mother.customrange_store.set_onclick_range('sailogger')
  },

  methods: {
    // initialize() {
    //   this.initGalery()
    //   this.initMap()
    // },

   
    // initialize() {
    //   this.$refs.sailoggerMap.mapObject.setView(new L.LatLng(-1.4312313766684295, 119.1498789812993), 2);
    //   if(this.sailogger_store.rpmreport_data.maptop){
    //     this.sailogger_store.initializeMap(this.$refs.sailoggerMap.mapObject,this.$refs.sailoggerMarker.mapObject);
    //   }
    // },


    // initialize_rpmmap() {
    //   this.$refs.rpmMap.mapObject.setView(new L.LatLng(-1.4312313766684295, 119.1498789812993), 2);
    //   if(this.sailogger_store.map_rpmdata){
    //     this.sailogger_store.initializeMap_Rpm(this.$refs.rpmMap.mapObject,this.$refs.rpmMarker.mapObject);
    //   }
    // },

    // click_marker(){
      
    //   this.$refs.rpmMap.mapObject.invalidateSize();
    //   this.$refs.rpmMap.mapObject.setView(new L.LatLng(this.sailogger_store.map_rpmdata.latitude, this.sailogger_store.map_rpmdata.longitude), 8);
    //   setTimeout(() => {
    //     this.$refs.rpmMarker.mapObject.fire('click')
    //   }, 500);
    // }


  },

  watch: {
  },
  computed: {
  }
};