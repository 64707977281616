import { render, staticRenderFns } from "./CrewwatchView.vue?vue&type=template&id=a71de9aa&"
import script from "@/assets/js/scripts/crewwatch.js?vue&type=script&lang=js&"
export * from "@/assets/js/scripts/crewwatch.js?vue&type=script&lang=js&"
import style0 from "./CrewwatchView.vue?vue&type=style&index=0&id=a71de9aa&prod&scopped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports