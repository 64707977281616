/* eslint-disable no-console */

import { register } from 'register-service-worker'
//import alertify from 'alertifyjs'

const notifiUserAboutUpdate = worker => {
  console.log('notifiUserAboutUpdate');
  //alertify.confirm('new update!', () => {
    worker.postMessage({ action: "skipWaiting" });
  //})
}

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered (registration) {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound (registration) {
      console.log('New content is downloading.')
    },
    updated (registration) {
      console.log('New content is available; please refresh.');
      registration.waiting.postMessage({ action: "skipWaiting" });
      notifiUserAboutUpdate(registration.waiting);
      const script = document.createElement('script');
      script.setAttribute('type', 'text/javascript');
      script.innerHTML = 'window.location.reload(true);';

      const meta = document.createElement('meta');
      meta.setAttribute('http-equiv', 'refresh');
      meta.setAttribute('content', '0;');

      const noscript = document.createElement('noscript');
      noscript.appendChild(meta);

      const body = document.getElementsByTagName('body')[0];
      body.appendChild(script);
      body.appendChild(noscript);

      setTimeout(() => {
        window.location.reload(true)
      }, 1000)
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  });

  var refreshing;
  navigator.serviceWorker.addEventListener("controllerchange", function() {
    if (refreshing) return;
    window.location.reload();
    refreshing = true;
    console.log('refreshing');
  });
}
