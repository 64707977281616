import FooterView from "@/components/footers/FooterView.vue"
import 'bootstrap';

export default {
  name: "App",
  components: {
    FooterView
  },
  data: function () {
    return {
      detailTitle: 'test',
      detailBody: 'test body',
      detailImg: [
        [{ src: require("@/assets/images/skyway1.jpg") },
        { src: require("@/assets/images/skyway2.jpg") },
        { src: require("@/assets/images/skyway3.jpg") },],
        [{ src: require("@/assets/images/discovery1.jpg") },
        { src: require("@/assets/images/discovery2.jpg") },
        { src: require("@/assets/images/discovery3.jpg") },],
        [{ src: require("@/assets/images/vsat1.png") },
        { src: require("@/assets/images/vsat2.jpg") },
        { src: require("@/assets/images/vsat3.jpg") },]
      ],
      detailProductImg: [],
    };
  },

  methods: {
    open_detail(title, body, index) {
      this.detailTitle = title;
      this.detailBody = body;  
      this.detailProductImg = this.detailImg[index]
    }
  }
};