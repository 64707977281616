import { useOrbCommStore, useEtaStore } from '@/stores';


export default {
    name: "table_coordinate",
    data() {
        return {
            orbcomm_store: useOrbCommStore(),
            eta_store:useEtaStore()
        }
    },

    mounted() {
        var mother = this;
        window.koordinat_move = function(index, indexnew){
            mother.eta_store.koordinat_move(index, indexnew)
        }

        window.koordinat_delete = function(id){
            mother.eta_store.koordinat_delete(id)
        }

        window.koordinat_edit = function(id, format){
            mother.eta_store.koordinat_edit(id, format) 
        }
        
    },

    methods: {
    },

    watch: {
    },
    computed: {
    }
};