import { defineStore } from 'pinia';
import { useEtaStore, useCustomRangeStore, useAuthStore, useMapStore, useNotifStore } from '../index';
import api from "@/plugins/axios";
import $ from "jquery";
import DataTable from 'datatables.net-dt';
import L from "leaflet";
import Chart from 'chart.js/auto';
import 'leaflet-rotatedmarker';
import 'leaflet.markercluster';




export const useThurayaStore = defineStore({
    id: 'thuraya',
    state: () => ({
        eta_store: useEtaStore(),
        range_store: useCustomRangeStore(),
        notification_store:useNotifStore(),
        map_store: useMapStore(),
        authStore: useAuthStore(),
        thuraya_table: null,
        thurayauser_table: null,
        thurayatrack_table: null,
        thurayaCDR_table: null,
        is_showonmap: null,
        search_thuraya: null,
        search_thurayauser: null,
        search_thurayatrack: null,
        search_thurayacdr: null,
        thuraya_data: null,
        chatroom_data: null,
        chatroom_from: null,
        chatroom_to: null,
        chatroom_sending: false,
        chatroom_id: null,
        chatroom_thurayanumber: null,
        chatroom_body: '',
        chatroom_interval: null,
        chatroom_search: '',
        chatroom_messages: null,
        call_data: null,
        call_item: null,
        call_totalrounded: 0,
        call_connected: false,
        cdr_data: null,
        cdr_item: null,
        cdr_filter: 'all',
        cdr_range: 'month',
        cdr_rangelabel: 'This Month',
        last_center: [-1.4312313766684295, 119.1498789812993],
        last_zoom: 6,
        mapthuraya_data: null,
        mapthuraya_data_date: null,
        page_off: 10,
        page_off_tracking: 10,
        page_off_cdr: 10,
        show_range: "Last 24 Hours",
        export_range: "24",
        mapthuraya_id: null,
        loading_range: false,
        tracking_layer: L.layerGroup(),
        target_layer: L.layerGroup(),
        link_layer: L.layerGroup(),
        search_layer: L.layerGroup(),
        marker_tracking: [],
        moving_marker: null,
        tracking_path: null,
        tracking_latLng: null,
        tracking_rotation: null,
        interval_tracking: null,
        thuraya_map: null,
        date_range: null,
        isBottom: false,
        change_vesselname: null,
        changevesselname_data: null,
        change_vesselid: null,
        loading_changename: false,
        cdr_chart: null,
        range_arr: [
            { label: "Today", val: "today" },
            { label: "Last 24 Hours", val: "24" },
            { label: "Last 7 Days", val: "7" },
            { label: "Last 30 Days", val: "30" },
            { label: "This Week", val: "week" },
            { label: "This Month", val: "month" },
            { label: "Last Month", val: "lmonth" },
            { label: "Custom Range", val: "24" },
        ],

        cdrrange_arr: [
            { label: "Today", val: "today" },
            { label: "This Week", val: "week" },
            { label: "This Month", val: "month" },
            { label: "Last Month", val: "lmonth" },
            { label: "Custom Range", val: "24" },
        ],

        page_off_arr: [
            10,
            25,
            50,
            100
        ],

        th_thuraya_arr: [
            "No",
            "Vessel Name",
            "Company",
            "Group",
            "Thuraya Number",
            "",
            "",
            "",
            "",
            ""
        ],
        th_thuraya_track_arr: [
            "#",
            "Latitude,Longitude",
            "DMS",
            "Speed\n(Knots)",
            "Heading\n(Degree)",
            "Distance\n(Nmi)",
            "Date/Time\n(UTC)",
            "Date/Time\n(GMT+7)"
        ],
        th_thuraya_user_arr: [
            "#",
            "Name",
            "Email",
            "Role",
            "Last Login"
        ],

        th_thuraya_cdr_arr: [
            "Services",
            "MSISDN",
            "Type",
            "Plcode",
            "Network",
            "Start_Date",
            "Called_Party",
            "Duration",
            "Band",
            "Amount",
            "DateTime Local"
        ],

        td_button: [
            { a: 'text', b: '' },
            { a: 'text', b: 'btn-primary' },
            { a: 'text', b: '' },
            { a: 'text', b: '' },
            { a: 'text', b: '' },
            { a: 'button_config', b: 'btn-primary' },
            { a: 'button_sos', b: 'btn-danger' },
            { a: 'button_tracking', b: 'btn-secondary' },
            { a: 'button_cdr', b: 'btn-success' },
            { a: 'button_showonmap', b: 'btn-primary' }
        ],
        td_thuraya_arr: [
            5,
            15.5,
            15.5,
            8.5,
            18.5,
            8.5,
            5.5,
            7.5,
            5.5,
            8.5,
        ],
        td_thuraya_track_arr: [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
        ],

        td_thuraya_cdr_arr: [
            9,
            9,
            9,
            9,
            9,
            9,
            9,
            9,
            9,
            9,
            9,
        ],

        th_whitelist_item: null,
        th_whitelist_data: null,

        th_vesseldetail_item: null,
        th_vesseldetail_data: null,

        th_manifestlisting_item: null,
        th_manifestlisting_data: null,

        th_adminpass_item: null,
        th_adminpass_data: null,

        th_intervalconfig_item: null,
        th_intervalconfig_data: null,
        th_intervalconfig_interval: 0,

        th_gpsemail_item: null,
        th_gpsemail_data: null,
        th_gpsemail_loading: false,

        th_speednotif_item: null,
        th_speednotif_data: null,
        th_speednotif_loading: false,

        th_reboot_data: null,
        th_reboot_item: null,

        th_sos_history_data: null,
        th_sos_history_item: null,
        th_sos_history_table: null,
        th_sos_history_arr: [
            "",
        ],
        td_sos_history_arr: [
            0,
        ],

        th_sosconfig_data: null,
        th_sosconfig_item: null,
        th_sosconfig_names: [
            "",
            "",
            "",
            "",
        ],
        th_sosconfig_phones: [
            "",
            "",
            "",
            "",
        ],
        th_sosconfig_emails: [
            "",
            "",
            "",
        ],

        th_geo_data: null,
        th_geo_item: null,
        th_geo_tab: [
            {
                label: 'Restricted Zone'
            },
            {
                label: 'Radio Silence'
            },
        ],
        th_geo_tab_index: 0,
        th_geo_area_index: 0,
        th_geo_map: null,
        th_geo_areagroup: null,
        th_geo_markergroup: null,
        th_geo_loadingarea: false,
        th_geo_area_arr: [],
        th_geo_area_data: null,


        speedhistory_data: null,
        speedhistory_item: null,
        speedhistory_table: null,
        th_speedhistory_arr: [
            "",
            "",
            "",
        ],
        th_speedhistory_plc: [
            "",
            "",
            "",
        ],
        td_speedhistory_arr: [
            5,
            5,
            90,
        ],
        speedhistory_page_off: 10,
        search_speedhistory: '',
        speedhistory_page_off_arr: [
            10,
            25,
            50,
            100
        ],
        speedhistory_notifarr:[]
        
    }),

    actions: {



        // DATA FETCH FUNCTIONS
        //------------------------------------------------------------------
        //Fetch Thuraya Data
        async fetch_thuraya() {
            await api.get(`/api/v1/navplus/thuraya`, {}
            ).then((res) => {
                console.log("thuraya RESPONSE");
                console.log(res);
                if (this.search_thuraya == null || this.search_thuraya.length == 0) {
                    this.thuraya_data = res.data.data.vessels;
                    this.initTable();
                }

            }).catch((err) => {
            }).then(() => {
            });
        },
        //Fetch Vessel Name
        async fetch_vesselname(item) {
            this.changevesselname_data = null

            await api.get(`/api/v1/navplus/vessel/name/` + item.id, {}
            ).then((res) => {
                console.log("CHANGE VESSEL NAME RESPONSE");
                console.log(res);

                this.changevesselname_data = res.data.data
            }).catch((err) => {

            }).then(() => {
            });

        },
        //Fetch Chatroom
        async fetch_chatroom(item) {
            this.chatroom_data = null
            this.chatroom_from = null
            this.chatroom_to = null
            this.chatroom_body = ''
            this.chatroom_messages = null
            this.chatroom_thurayanumber = null

            await api.get(`/api/v1/navplus/sms/fetch/` + item.id, {}
            ).then((res) => {
                console.log("FETCH SMS RESPONSE");
                console.log(res);
                console.log(this.authStore.user.user);

                this.chatroom_id = item.id
                this.chatroom_data = res.data
                this.chatroom_messages = res.data.messages.messages
                this.chatroom_from = this.authStore.user.user.username
                this.chatroom_to = res.data.messages.name
                this.chatroom_body = ''
                this.chatroom_thurayanumber = item.thuraya_number
                var mom = this
                this.chatroom_interval = setInterval(


                    function () {
                        if (mom.chatroom_data) {
                            mom.gradual_chatroom()
                        } else {
                            clearInterval(mom.chatroom_interval);
                        }
                    }
                    , 1000);


                setTimeout(() => {
                    var objDiv = document.getElementById("chatroom_content");
                    objDiv.scrollTop = objDiv.scrollHeight;
                }, 500);

            }).catch((err) => {

            }).then(() => {
            });
        },
        //Fetch Thuraya Call
        async fetch_call(item) {
            this.call_data = null

            await api.get(`/api/v1/navplus/call/get/` + item.thuraya_number, {}
            ).then((res) => {
                console.log("FETCH CALL RESPONSE");
                console.log(res);
                this.call_data = res.data.data
                this.startCall()
            }).catch((err) => {

            }).then(() => {
            });
        },
        //Fetch CDR
        async fetch_cdr(item) {
            this.cdr_data = null
            this.cdr_item = null
            this.cdr_filter = 'all'
            this.page_off_cdr = 10;
            this.search_thurayacdr = ''

            if (this.cdr_chart) {
                this.cdr_chart.destroy()
            }

            if (this.thurayaCDR_table) {
                this.thurayaCDR_table.clear().draw();
                $('#thurayaTableCDR').DataTable().destroy();
                this.thurayaCDR_table = null
            }

            await api.post(`/api/v1/navplus/thuraya/cdr`, {
                thuraya_number: item.thuraya_number,
                range: this.cdr_range,
            }
            ).then((res) => {


                this.cdr_item = item
                this.cdr_data = res.data.data

                this.initTable_cdr(res.data.data)

                console.log("FETCH CDR RESPONSE");
                console.log(res);

                var label_ = [];
                var amount_ = [];
                var total_ = [];


                if (res.data.data.chart) {
                    label_ = res.data.data.chart.labels
                    amount_ = res.data.data.chart.amount
                    total_ = res.data.data.chart.total
                }



                this.cdr_chart = new Chart(
                    document.getElementById('cdr_chart'),
                    {
                        type: 'bar',
                        data: {
                            labels: label_,
                            datasets: [{
                                label: 'Usage by Day',
                                backgroundColor: '#F49223',
                                data: amount_,
                            },
                            {
                                label: 'Total Usage',
                                backgroundColor: '#325d9e',
                                data: total_,
                            }]
                        },
                        options: {
                            responsive: true,
                            maintainAspectRatio: false,
                            plugins: {
                                legend: {
                                    position: "top",
                                },
                                title: {
                                    display: false,
                                    text: 'Chart.js Bar Chart - Stacked'
                                },
                                tooltip: {
                                    callbacks: {
                                        footer: function (items) {
                                            return 'Total Balanced Usage : ' + parseFloat(items.reduce((a, b) => a + b.parsed.y, 0)).toFixed(2) + ' Unit'
                                        },
                                        label: function (items) {
                                            // console.log(items);
                                            return items.dataset.label + ' : ' + parseFloat(items.parsed.y).toFixed(2) + ' Unit';
                                        }
                                    }
                                },
                            },
                            scales: {
                                x: {
                                    stacked: true,
                                },
                                y: {
                                    stacked: true,
                                },
                            },
                            interaction: {
                                mode: 'x',
                                intersect: false,
                            },
                        }
                    }
                );

            }).catch((err) => {

            }).then(() => {
            });
        },
        //Fetch Thuraya Show on Map 
        async fetch_thurayaMap(id) {
            this.mapthuraya_data = null;
            this.mapthuraya_id = id;
            await api.get(`/api/v1/navplus/thuraya/` + id, {}
            ).then((res) => {
                console.log("SHOW ON MAP thuraya FETCH");
                console.log(res);
                this.mapthuraya_data = res.data.data
                setTimeout(() => {
                    this.initTable_user()
                    this.initTable_tracking(res.data.data)
                }, 1000);

            }).catch((err) => {
            }).then(() => {
            });
        },
        //Fetch Thuraya Show on Map Date
        async fetch_ThurayaMap_date(range) {
            this.last_center = [-1.4312313766684295, 119.1498789812993]
            this.loading_range = true
            this.mapthuraya_data_date = null
            this.export_range = range.toLowerCase()
            await api.post(`/api/v1/navplus/thuraya/date
                    `, {
                id: this.mapthuraya_id,
                range: range.toLowerCase()
            }
            ).then((res) => {
                console.log("SHOW ON MAP thuraya FETCH DATE");
                console.log(res);
                this.loading_range = false
                this.mapthuraya_data_date = res.data.data
                this.moveTracking(res.data.data.tracking)
                this.initTable_tracking(res.data.data)
                console.log("TABLE TRACKING ISINYA");
                if (res.data.data.maptop != null) {
                    this.last_center = [res.data.data.maptop.lat, res.data.data.maptop.lon]
                    this.thuraya_map.setView(new L.LatLng(res.data.data.maptop.lat, res.data.data.maptop.lon), 6);
                } else {
                    this.last_center = [-1.4312313766684295, 119.1498789812993]
                    this.thuraya_map.setView(new L.LatLng(-1.4312313766684295, 119.1498789812993), 2);
                }

            }).catch((err) => {
            }).then(() => {
            });
        },
        //Fetch Whitelist Config
        async fetch_whitelist(item) {
            this.th_whitelist_data = null
            await api.get(`/api/v1/navplus/thuraya/` + item.id + `/whitelist`, {}
            ).then((res) => {
                console.log("FETCH WHITELIST CONFIG RESPONSE");
                console.log(res);
                if (res.status == 200) {
                    this.th_whitelist_data = res.data.data
                }
            }).catch((err) => {

            }).then(() => {
            });
        },
        //Fetch Veesel Detail Config
        async fetch_vesseldetail(item) {
            this.th_vesseldetail_data = null
            await api.get("/api/v1/navplus/thuraya/" + item.id + "/vessel_detail", {}
            ).then((res) => {
                console.log("FETCH VESSEL DETAIL CONFIG RESPONSE");
                console.log(res);
                this.th_vesseldetail_data = res.data.data
            }).catch((err) => {

            }).then(() => {
            });
        },
        //Fetch Manifest Listing Config
        async fetch_manifest_listing(item) {
            this.th_manifestlisting_data = null
            await api.get("/api/v1/navplus/thuraya/" + item.id + "/listing", {}
            ).then((res) => {
                console.log("FETCH MANIFEST LISTING CONFIG RESPONSE");
                console.log(res);
                this.th_manifestlisting_data = res.data.data
            }).catch((err) => {

            }).then(() => {
            });
        },
        //Fetch Admin Password Config
        async fetch_admin_passsword(item) {
            this.th_adminpass_data = null
            await api.get("/api/v1/navplus/thuraya/" + item.id + "/pass", {}
            ).then((res) => {
                console.log("FETCH ADMIN PASSWORD RESPONSE");
                console.log(res);
                this.th_adminpass_data = res.data.data

                setTimeout(() => {
                    if (res.data.data.deffPass) {
                        $('#adminPass1').prop('checked', true);
                        $('#adminPass2').prop('checked', false);
                    } else {
                        $('#adminPass1').prop('checked', false);
                        $('#adminPass2').prop('checked', true);
                    }
                }, 500);
            }).catch((err) => {

            }).then(() => {
            });
        },
        //Fetch Interval Config Config
        async fetch_interval_config(item) {
            this.th_intervalconfig_data = null
            this.th_intervalconfig_interval = 0
            await api.get("/api/v1/navplus/thuraya/" + item.id + "/interval", {}
            ).then((res) => {
                console.log("FETCH INTERVAL CONFIG RESPONSE");
                console.log(res);
                this.th_intervalconfig_data = res.data.data
                this.th_intervalconfig_interval = parseInt(res.data.data.latest_content)
            }).catch((err) => {

            }).then(() => {
            });
        },
        //Fetch GPS Email Config
        async fetch_gpsemail(item) {
            this.th_gpsemail_data = null
            this.th_gpsemail_loading = false
            await api.get("/api/v1/navplus/thuraya/" + item.id + "/email", {}
            ).then((res) => {
                console.log("FETCH GPS EMAIL CONFIG RESPONSE");
                console.log(res);
                this.th_gpsemail_data = res.data.data
                setTimeout(() => {
                    if (res.data.data.gpsEmailEnabled
                    ) {
                        $('#gpmsmailEnabled1').prop('checked', true);
                        $('#gpmsmailEnabled2').prop('checked', false);
                    } else {
                        $('#gpmsmailEnabled1').prop('checked', false);
                        $('#gpmsmailEnabled2').prop('checked', true);
                    }
                }, 500);
            }).catch((err) => {

            }).then(() => {
            });
        },
        //Fetch GPS Email Config
        async fetch_speed_notif(item) {
            this.th_speednotif_data = null
            this.th_speednotif_loading = false,
                await api.get("/api/v1/navplus/speed/" + item.id, {}
                ).then((res) => {
                    console.log("FETCH SPEED NOTIF CONFIG RESPONSE");
                    console.log(res);
                    this.th_speednotif_data = res.data.data
                    setTimeout(() => {
                        if (res.data.data.isEnabled
                        ) {
                            $('#th_speedEnabled1').prop('checked', true);
                            $('#th_speedEnabled2').prop('checked', false);
                        } else {
                            $('#th_speedEnabled1').prop('checked', false);
                            $('#th_speedEnabled2').prop('checked', true);
                        }
                    }, 500);
                }).catch((err) => {

                }).then(() => {
                });
        },
        async fetch_sos_config(item) {
            this.th_sosconfig_data = null
            await api.get("/api/v1/navplus/thuraya/" + item.id + "/sos", {}
            ).then((res) => {
                console.log("FETCH SOS CONFIG RESPONSE");
                console.log(res);
                this.th_sosconfig_data = res.data.data
                this.th_sosconfig_names = res.data.data.latest_name
                this.th_sosconfig_phones = res.data.data.latest_phone
                this.th_sosconfig_emails = res.data.data.latest_mail
                setTimeout(() => {
                    if (res.data.data.sosTestEnabled
                    ) {
                        $('#sosTestEnabled').prop('checked', true);
                        $('#sosTestEnabled_label').html('[Enabled]');
                    } else {
                        $('#sosTestEnabled').prop('checked', false);
                        $('#sosTestEnabled_label').html('[Disabled]');
                    }
                }, 500);
            }).catch((err) => {

            }).then(() => {
            });
        },
        async fetch_reboot_command(item) {
            this.th_reboot_data = null
            await api.get("/api/v1/navplus/thuraya/" + item.id + "/reboot", {}
            ).then((res) => {
                console.log("FETCH REBOOT COMMAND RESPONSE");
                console.log(res);
                this.th_reboot_data = res.data.data
                // setTimeout(() => {
                //     if (res.data.data.sosTestEnabled
                //     ) {
                //         $('#sosTestEnabled').prop('checked', true);
                //         $('#sosTestEnabled_label').html('[Enabled]');
                //     } else {
                //         $('#sosTestEnabled').prop('checked', false);
                //         $('#sosTestEnabled_label').html('[Disabled]');
                //     }
                // }, 500);
            }).catch((err) => {

            }).then(() => {
            });
        },
        async fetch_sos_history(item) {
            this.th_sos_history_data = null
            await api.get("/api/v1/navplus/thuraya/" + item.id + "/fetchSosHistory", {}
            ).then((res) => {
                console.log("FETCH SOS HISTORY RESPONSE");
                console.log(res);
                this.th_sos_history_data = res.data.data

                setTimeout(() => {
                    this.initTable_sos_history(res.data.data.messages)
                }, 1000);
            }).catch((err) => {

            }).then(() => {
            });
        },
        async fetch_geo_config(item) {

            this.th_geo_data = null
            this.th_geo_map = null
            this.th_geo_tab_index = 0
            this.th_geo_area_index = 0
            this.th_geo_loadingarea = false
            this.th_geo_area_arr = []

            await api.get("/api/v1/navplus/thuraya/" + item.id + "/fetchGeofencing", {}
            ).then((res) => {
                console.log("FETCH GEO CONFIG RESPONSE");
                console.log(res);
                this.th_geo_data = res.data.data
                this.fetch_geo_area()

                setTimeout(() => {
                    if (res.data.data.latest_status == 'CONFIRMED' || res.data.data.latest_status == 'FAILED') {
                        $('#geoaction_card').addClass('d-block')
                        $('#geoaction_card').removeClass('d-none')
                    } else {
                        $('#geoaction_card').addClass('d-none')
                        $('#geoaction_card').removeClass('d-block')
                    }
                }, 4000);

            }).catch((err) => {

            }).then(() => {
            });
        },
        async fetch_geo_area() {
            this.th_geo_loadingarea = true;
            this.th_geo_area_data = null
            await api.post("/api/v1/navplus/thuraya/fetchGeofencingPoly", {
                id: this.th_geo_item.id,
                type: (this.th_geo_tab_index + 1),
                area: this.th_geo_area_index,
            }
            ).then((res) => {
                console.log("FETCH AREA RESPONSE");
                console.log(res);
                this.th_geo_loadingarea = false;
                this.draw_polygon(res.data.data.polyPath)
                this.th_geo_area_data = res.data.data

                if (res.data.data.polyPath.length > 0) {
                    for (let i = 0; i < res.data.data.polyPath.length; i++) {
                        this.th_geo_area_arr.push([res.data.data.polyPath[i].lat, res.data.data.polyPath[i].lng])
                    }
                }

                $('#geobtn_reset').prop("disabled", true)
                if (res.data.data.polyPath.length > 2) {
                    $('#geobtn_clear').prop("disabled", false)
                    $('#geobtn_submit').prop("disabled", false)
                    $('#geobtn_submit').removeClass('btn-secondary')
                    $('#geobtn_submit').addClass('btn-primary')
                } else {
                    $('#geobtn_clear').prop("disabled", true)
                    $('#geobtn_submit').prop("disabled", true)
                    $('#geobtn_submit').removeClass('btn-primary')
                    $('#geobtn_submit').addClass('btn-secondary')
                }
            }).catch((err) => {

            }).then(() => {
            });
        },
        async fetch_speedhistory(item) {

           

            await api.get(`/api/v1/navplus/notification/get/speed/` + item.id, {}
            ).then((res) => {
                console.log("SPEED HISTORY RESPONSE");
                console.log(res);
                if(this.speedhistory_data == null){
                this.speedhistory_data = res.data.data
                if(this.speedhistory_table != null){
                    this.speedhistory_table.clear();
                    this.speedhistory_table = null;
                    console.log("HISTORY TABLE IS CLEARED");
                    
                }
                setTimeout(() => {
                       this.initTable_history(this.speedhistory_data.notifications)
                }, 5000);
            }

            }).catch((err) => {

            }).then(() => {
            });

        },
        //------------------------------------------------------------------



        // POST DATA FUNCTIONS
        //------------------------------------------------------------------
        //Post Chatroom Chat
        async post_chatroom() {
            var message = this.chatroom_body
            this.chatroom_body = ''
            this.chatroom_sending = true
            await api.post(`/api/v1/navplus/sms/send`, {
                thuraya_number: this.chatroom_thurayanumber,
                pesan: message,
            }
            ).then((res) => {
                console.log("POST SMS RESPONSE");
                console.log(res);
                this.chatroom_sending = false



                setTimeout(() => {
                    var objDiv = document.getElementById("chatroom_content");
                    objDiv.scrollTop = objDiv.scrollHeight;
                }, 1000);

            }).catch((err) => {

            }).then(() => {
            });



        },
        //Post Vessel Name
        async post_vesselname() {
            this.loading_changename = true


            await api.post(`/api/v1/navplus/vessel/name`, {
                id: this.change_vesselid,
                name: this.change_vesselname,
            }
            ).then((res) => {
                console.log("VESSEL CHANGE NAME RESPONSE");
                console.log(res);

                this.loading_changename = false


                if (res.status == 200) {
                    $('#thurayaNameModal_alert').removeClass('d-none')
                    $('#thurayaNameModal_alert').addClass('d-block')
                    $('#thurayaNameModal_alert').html('Vessel name changed!')

                    setTimeout(() => {
                        $('#thurayaNameModal_alert').removeClass('d-block')
                        $('#thurayaNameModal_alert').addClass('d-none')
                        $('#thurayaNameModal_alert').html('')
                    }, 1500);

                    this.changevesselname_data = res.data.data
                    this.fetch_thuraya();
                } else {
                    $('#thurayaNameModal_alert').removeClass('alert-success')
                    $('#thurayaNameModal_alert').addClass('alert-danger')
                    $('#thurayaNameModal_alert').removeClass('d-none')
                    $('#thurayaNameModal_alert').addClass('d-block')
                    $('#thurayaNameModal_alert').html('Something wrong, cannot change!')

                    setTimeout(() => {
                        $('#thurayaNameModal_alert').addClass('alert-success')
                        $('#thurayaNameModal_alert').removeClass('alert-danger')
                        $('#thurayaNameModal_alert').removeClass('d-block')
                        $('#thurayaNameModal_alert').addClass('d-none')
                        $('#thurayaNameModal_alert').html('')
                    }, 1500);
                }






            }).catch((err) => {
                $('#thurayaNameModal_alert').removeClass('alert-success')
                $('#thurayaNameModal_alert').addClass('alert-danger')
                $('#thurayaNameModal_alert').removeClass('d-none')
                $('#thurayaNameModal_alert').addClass('d-block')
                $('#thurayaNameModal_alert').html('Something wrong, cannot change!')

                setTimeout(() => {
                    $('#thurayaNameModal_alert').addClass('alert-success')
                    $('#thurayaNameModal_alert').removeClass('alert-danger')
                    $('#thurayaNameModal_alert').removeClass('d-block')
                    $('#thurayaNameModal_alert').addClass('d-none')
                    $('#thurayaNameModal_alert').html('')
                }, 1500);
            }).then(() => {
            });

        },
        //Post Whitelist Config
        async post_whitelist() {
            await api.post(`/api/v1/navplus/thuraya/whitelist`, {
                thuraya_number: this.th_whitelist_item.thuraya_number,
                recipients: this.th_whitelist_data.latest_content,
                names: this.th_whitelist_data.latest_names,
            }
            ).then((res) => {
                console.log("POST WHITELIST CONFIG RESPONSE");
                console.log(res);
                this.fetch_whitelist(this.th_whitelist_item);
            }).catch((err) => {

            }).then(() => {
            });
        },
        //Post Vessel Detail Config
        async post_vesseldetail() {
            await api.post(`/api/v1/navplus/thuraya/vessel_detail`, {
                thuraya_number: this.th_vesseldetail_item.thuraya_number,
                imo: this.th_vesseldetail_data.latest_content.imo,
                mmsi: this.th_vesseldetail_data.latest_content.mmsi,
                callsign: this.th_vesseldetail_data.latest_content.callsign,
                type: this.th_vesseldetail_data.latest_content.type,
                gross_tonnage: this.th_vesseldetail_data.latest_content.gross_tonnage,
                flag_state: this.th_vesseldetail_data.latest_content.flag_state,
                owner: this.th_vesseldetail_data.latest_content.owner,
                cso_first: this.th_vesseldetail_data.latest_content.cso_first,
                cso_second: this.th_vesseldetail_data.latest_content.cso_second,
            }
            ).then((res) => {
                console.log("POST VESSEL DETAIL CONFIG RESPONSE");
                console.log(res);
                this.fetch_vesseldetail(this.th_vesseldetail_item);
            }).catch((err) => {

            }).then(() => {
            });
        },
        //Post Manifest Config
        async post_manifestlisting() {
            await api.post(`/api/v1/navplus/thuraya/listing`, {
                thuraya_number: this.th_manifestlisting_item.thuraya_number,
                types: this.th_manifestlisting_data.latest_content,
            }
            ).then((res) => {
                console.log("POST MANIFEST LISTING CONFIG RESPONSE");
                console.log(res);
                this.fetch_manifest_listing(this.th_manifestlisting_item);
            }).catch((err) => {

            }).then(() => {
            });
        },
        //Post Admin Password Config
        async post_admin_password() {
            await api.post(`/api/v1/navplus/thuraya/pass`, {
                thuraya_number: this.th_adminpass_item.thuraya_number,
                passRadio: this.th_adminpass_data.deffPass,
            }
            ).then((res) => {
                console.log("POST MANIFEST LISTING CONFIG RESPONSE");
                console.log(res);
                this.fetch_admin_passsword(this.th_adminpass_item);
            }).catch((err) => {

            }).then(() => {
            });
        },
        //Post Interval Config
        async post_interval_config() {
            await api.post(`/api/v1/navplus/thuraya/interval`, {
                thuraya_number: this.th_intervalconfig_item.thuraya_number,
                interval: this.th_intervalconfig_interval,
            }
            ).then((res) => {
                console.log("POST INTERVAL CONFIG RESPONSE");
                console.log(res);
                this.fetch_interval_config(this.th_intervalconfig_item);
            }).catch((err) => {

            }).then(() => {
            });
        },
        //Post GPS Email Config
        async post_gpsemail() {
            this.th_gpsemail_loading = true
            await api.post(`/api/v1/navplus/thuraya/email`, {
                id: this.th_gpsemail_item.id,
                trackingContact: this.th_gpsemail_data.trackingContact,
                gpsEmailEnabled: this.th_gpsemail_data.gpsEmailEnabled,
            }
            ).then((res) => {
                console.log("POST GPS EMAIL CONFIG RESPONSE");
                console.log(res);
                this.th_gpsemail_loading = false
                this.fetch_gpsemail(this.th_gpsemail_item);
                if (res.status == 200) {
                    $('#thurayaGPSEmailModal_alert').removeClass('d-none')
                    $('#thurayaGPSEmailModal_alert').addClass('d-block')
                    $('#thurayaGPSEmailModal_alert').html('Successfully Updated GPS Email Report Configuration!')

                    setTimeout(() => {
                        $('#thurayaGPSEmailModal_alert').removeClass('d-block')
                        $('#thurayaGPSEmailModal_alert').addClass('d-none')
                        $('#thurayaGPSEmailModal_alert').html('')
                    }, 1500);

                } else {
                    $('#thurayaGPSEmailModal_alert').removeClass('alert-success')
                    $('#thurayaGPSEmailModal_alert').addClass('alert-danger')
                    $('#thurayaGPSEmailModal_alert').removeClass('d-none')
                    $('#thurayaGPSEmailModal_alert').addClass('d-block')
                    $('#thurayaGPSEmailModal_alert').html('Something wrong, cannot change!')

                    setTimeout(() => {
                        $('#thurayaGPSEmailModal_alert').addClass('alert-success')
                        $('#thurayaGPSEmailModal_alert').removeClass('alert-danger')
                        $('#thurayaGPSEmailModal_alert').removeClass('d-block')
                        $('#thurayaGPSEmailModal_alert').addClass('d-none')
                        $('#thurayaGPSEmailModal_alert').html('')
                    }, 1500);
                }
            }).catch((err) => {
                $('#thurayaGPSEmailModal_alert').removeClass('alert-success')
                $('#thurayaGPSEmailModal_alert').addClass('alert-danger')
                $('#thurayaGPSEmailModal_alert').removeClass('d-none')
                $('#thurayaGPSEmailModal_alert').addClass('d-block')
                $('#thurayaGPSEmailModal_alert').html('Something wrong, cannot change!')

                setTimeout(() => {
                    $('#thurayaGPSEmailModal_alert').addClass('alert-success')
                    $('#thurayaGPSEmailModal_alert').removeClass('alert-danger')
                    $('#thurayaGPSEmailModal_alert').removeClass('d-block')
                    $('#thurayaGPSEmailModal_alert').addClass('d-none')
                    $('#thurayaGPSEmailModal_alert').html('')
                }, 1500);
            }).then(() => {
            });
        },

        //Post Speed Notif Config
        async post_speed_notif() {
            this.th_speednotif_loading = true
            await api.post("/api/v1/navplus/speed/" + this.th_speednotif_item.id, {
                id: this.th_speednotif_data.id,
                isEnabled: this.th_speednotif_data.isEnabled,
                speed_min: this.th_speednotif_data.min,
                speed_max: this.th_speednotif_data.max,
                emails: this.th_speednotif_data.emails,
            }
            ).then((res) => {
                console.log("POST SPEED NOTIF CONFIG RESPONSE");
                console.log(res);
                this.th_speednotif_loading = false
                this.fetch_speed_notif(this.th_speednotif_item);

                if (res.status == 200) {
                    $('#thurayaSppedNotifModal_alert').removeClass('d-none')
                    $('#thurayaSppedNotifModal_alert').addClass('d-block')
                    $('#thurayaSppedNotifModal_alert').html('Successfully Updated Speed Notification Configuration!')

                    setTimeout(() => {
                        $('#thurayaSppedNotifModal_alert').removeClass('d-block')
                        $('#thurayaSppedNotifModal_alert').addClass('d-none')
                        $('#thurayaSppedNotifModal_alert').html('')
                    }, 1500);

                } else {
                    $('#thurayaSppedNotifModal_alert').removeClass('alert-success')
                    $('#thurayaSppedNotifModal_alert').addClass('alert-danger')
                    $('#thurayaSppedNotifModal_alert').removeClass('d-none')
                    $('#thurayaSppedNotifModal_alert').addClass('d-block')
                    $('#thurayaSppedNotifModal_alert').html('Something wrong, cannot change!')

                    setTimeout(() => {
                        $('#thurayaSppedNotifModal_alert').addClass('alert-success')
                        $('#thurayaSppedNotifModal_alert').removeClass('alert-danger')
                        $('#thurayaSppedNotifModal_alert').removeClass('d-block')
                        $('#thurayaSppedNotifModal_alert').addClass('d-none')
                        $('#thurayaSppedNotifModal_alert').html('')
                    }, 1500);
                }
            }).catch((err) => {
                $('#thurayaSppedNotifModal_alert').removeClass('alert-success')
                $('#thurayaSppedNotifModal_alert').addClass('alert-danger')
                $('#thurayaSppedNotifModal_alert').removeClass('d-none')
                $('#thurayaSppedNotifModal_alert').addClass('d-block')
                $('#thurayaSppedNotifModal_alert').html('Something wrong, cannot change!')

                setTimeout(() => {
                    $('#thurayaSppedNotifModal_alert').addClass('alert-success')
                    $('#thurayaSppedNotifModal_alert').removeClass('alert-danger')
                    $('#thurayaSppedNotifModal_alert').removeClass('d-block')
                    $('#thurayaSppedNotifModal_alert').addClass('d-none')
                    $('#thurayaSppedNotifModal_alert').html('')
                }, 1500);
            }).then(() => {
            });
        },

        //Post SOS Config
        async post_sos_config() {

            await api.post("/api/v1/navplus/thuraya/sos", {
                thuraya_number: this.th_sosconfig_item.thuraya_number,
                names: this.th_sosconfig_names,
                phones: this.th_sosconfig_phones,
                emails: this.th_sosconfig_emails,
            }
            ).then((res) => {
                console.log("POST SOS CONFIG RESPONSE");
                console.log(res);
                this.fetch_sos_config(this.th_sosconfig_item);
            }).catch((err) => {

            }).then(() => {
            });
        },

        //Post SOS Enabled 
        async post_sos_enabled() {
            var isEnabled = '';
            if (this.th_sosconfig_data.sosTestEnabled) {
                isEnabled = 'Enabled'
            } else {
                isEnabled = 'Disabled'
            }
            await api.post("/api/v1/navplus/thuraya/enableSosTest", {
                thuraya_number: this.th_sosconfig_item.thuraya_number,
                enable: this.th_sosconfig_data.sosTestEnabled,
            }
            ).then((res) => {
                console.log("POST SOS ENABLED RESPONSE");
                console.log(res);
                if (res.status == 200) {
                    $('#thurayaSOSConfigModal_alert').removeClass('d-none')
                    $('#thurayaSOSConfigModal_alert').addClass('d-block')
                    $('#thurayaSOSConfigModal_alert').html(isEnabled + ' SOS Test Success')

                    setTimeout(() => {
                        $('#thurayaSOSConfigModal_alert').removeClass('d-block')
                        $('#thurayaSOSConfigModal_alert').addClass('d-none')
                        $('#thurayaSOSConfigModal_alert').html('')
                    }, 1500);

                } else {
                    $('#thurayaSOSConfigModal_alert').removeClass('alert-success')
                    $('#thurayaSOSConfigModal_alert').addClass('alert-danger')
                    $('#thurayaSOSConfigModal_alert').removeClass('d-none')
                    $('#thurayaSOSConfigModal_alert').addClass('d-block')
                    $('#thurayaSOSConfigModal_alert').html('cannot change!')

                    setTimeout(() => {
                        $('#thurayaSOSConfigModal_alert').addClass('alert-success')
                        $('#thurayaSOSConfigModal_alert').removeClass('alert-danger')
                        $('#thurayaSOSConfigModal_alert').removeClass('d-block')
                        $('#thurayaSOSConfigModal_alert').addClass('d-none')
                        $('#thurayaSOSConfigModal_alert').html('')
                    }, 1500);
                }

            }).catch((err) => {
                $('#thurayaSOSConfigModal_alert').removeClass('alert-success')
                $('#thurayaSOSConfigModal_alert').addClass('alert-danger')
                $('#thurayaSOSConfigModal_alert').removeClass('d-none')
                $('#thurayaSOSConfigModal_alert').addClass('d-block')
                $('#thurayaSOSConfigModal_alert').html('cannot change!')

                setTimeout(() => {
                    $('#thurayaSOSConfigModal_alert').addClass('alert-success')
                    $('#thurayaSOSConfigModal_alert').removeClass('alert-danger')
                    $('#thurayaSOSConfigModal_alert').removeClass('d-block')
                    $('#thurayaSOSConfigModal_alert').addClass('d-none')
                    $('#thurayaSOSConfigModal_alert').html('')
                }, 1500);
            }).then(() => {
            });
        },
        //Post SOS Enabled 
        async post_sos_enabled() {
            var isEnabled = '';
            if (this.th_sosconfig_data.sosTestEnabled) {
                isEnabled = 'Enabled'
            } else {
                isEnabled = 'Disabled'
            }
            await api.post("/api/v1/navplus/thuraya/enableSosTest", {
                thuraya_number: this.th_sosconfig_item.thuraya_number,
                enable: this.th_sosconfig_data.sosTestEnabled,
            }
            ).then((res) => {
                console.log("POST SOS ENABLED RESPONSE");
                console.log(res);
                if (res.status == 200) {
                    $('#thurayaSOSConfigModal_alert').removeClass('d-none')
                    $('#thurayaSOSConfigModal_alert').addClass('d-block')
                    $('#thurayaSOSConfigModal_alert').html(isEnabled + ' SOS Test Success')

                    setTimeout(() => {
                        $('#thurayaSOSConfigModal_alert').removeClass('d-block')
                        $('#thurayaSOSConfigModal_alert').addClass('d-none')
                        $('#thurayaSOSConfigModal_alert').html('')
                    }, 1500);

                } else {
                    $('#thurayaSOSConfigModal_alert').removeClass('alert-success')
                    $('#thurayaSOSConfigModal_alert').addClass('alert-danger')
                    $('#thurayaSOSConfigModal_alert').removeClass('d-none')
                    $('#thurayaSOSConfigModal_alert').addClass('d-block')
                    $('#thurayaSOSConfigModal_alert').html('cannot change!')

                    setTimeout(() => {
                        $('#thurayaSOSConfigModal_alert').addClass('alert-success')
                        $('#thurayaSOSConfigModal_alert').removeClass('alert-danger')
                        $('#thurayaSOSConfigModal_alert').removeClass('d-block')
                        $('#thurayaSOSConfigModal_alert').addClass('d-none')
                        $('#thurayaSOSConfigModal_alert').html('')
                    }, 1500);
                }

            }).catch((err) => {
                $('#thurayaSOSConfigModal_alert').removeClass('alert-success')
                $('#thurayaSOSConfigModal_alert').addClass('alert-danger')
                $('#thurayaSOSConfigModal_alert').removeClass('d-none')
                $('#thurayaSOSConfigModal_alert').addClass('d-block')
                $('#thurayaSOSConfigModal_alert').html('cannot change!')

                setTimeout(() => {
                    $('#thurayaSOSConfigModal_alert').addClass('alert-success')
                    $('#thurayaSOSConfigModal_alert').removeClass('alert-danger')
                    $('#thurayaSOSConfigModal_alert').removeClass('d-block')
                    $('#thurayaSOSConfigModal_alert').addClass('d-none')
                    $('#thurayaSOSConfigModal_alert').html('')
                }, 1500);
            }).then(() => {
            });
        },

        //Post Reboot Command
        async post_reboot_command() {
            await api.post(`/api/v1/navplus/thuraya/reboot`, {
                thuraya_number: this.th_reboot_item.thuraya_number,
            }
            ).then((res) => {
                console.log("POST REBOOT COMMAND RESPONSE");
                console.log(res);
                this.fetch_reboot_command(this.th_reboot_item);
            }).catch((err) => {

            }).then(() => {
            });
        },
        //Post Geofencing
        async post_geo_config() {
            this.th_geo_area_arr = [];
            console.log(this.th_geo_item.thuraya_number);
            console.log(this.th_geo_area_arr);
            console.log(this.th_geo_tab_index + 1);
            console.log(this.th_geo_area_index);
            console.log(this.th_geo_data.cbValue[this.th_geo_tab_index + 1][this.th_geo_area_index]);

            await api.post(`/api/v1/navplus/thuraya/pushGeofencing`, {
                thuraya_number: this.th_geo_item.thuraya_number,
                poly: this.th_geo_area_arr,
                type: this.th_geo_tab_index + 1,
                area: this.th_geo_area_index,
                enable: this.th_geo_data.cbValue[this.th_geo_tab_index + 1][this.th_geo_area_index],
            }
            ).then((res) => {
                console.log("POST GEOFENCING");
                console.log(res);
                this.fetch_geo_config(this.th_geo_item)
            }).catch((err) => {

            }).then(() => {
            });
        },
        //------------------------------------------------------------------



        //TABLE SEARCH & PAGE FUNCTIONS
        //------------------------------------------------------------------
        //Search Thuraya Table
        thurayaTable_search() {
            if (this.search_thuraya != null && this.search_thuraya.length > 0) {
                this.thuraya_table.search(this.search_thuraya).draw();
            }
        },
        //Page Off Thuraya Table
        thurayaTable_showof(page) {
            console.log("PAGE : " + page);
            this.thuraya_table.page.len(page).draw()
            this.page_off = page;
        },
        //Clear Thuraya Table Search
        clear_thurayaTable_search() {
            this.search_thuraya = ''
            this.thuraya_table.search(this.search_thuraya).draw();
        },
        //Search CDR Table
        thurayaCDRTable_search() {
            if (this.search_thurayacdr != null && this.search_thurayacdr.length > 0) {
                this.thurayaCDR_table.search(this.search_thurayacdr).draw();
            }
        },
        //Filter CDR Table
        thurayaCDRTable_filter(filter) {
            this.cdr_filter = filter
            var filter_;
            if (filter == 'all') {
                filter_ = ''
            } else {
                filter_ = filter
            }
            this.thurayaCDR_table.search(filter_).draw();
        },
        //Page Off CDR Table
        thurayaCDRTable_showof(page) {
            console.log("PAGE : " + page);
            this.thurayaCDR_table.page.len(page).draw()
            this.page_off_cdr = page;
        },
        //Clear CDR Table Search
        clear_thurayaCDRTable_search() {
            this.search_thurayacdr = ''
            this.thurayaCDR_table.search(this.search_thurayacdr).draw();
        },
        //Search Thuraya User Table
        userTable_search() {
            this.thurayauser_table.search(this.search_thurayauser).draw();
        },
        //Search Thuraya Tracking Table
        trackTable_search() {
            this.thurayatrack_table.search(this.search_thurayatrack).draw();
        },
        //Page Off Thuraya Tracking Table
        thurayaTrackTable_showof(page) {
            console.log("PAGE : " + page);
            this.thurayatrack_table.page.len(page).draw()
            this.page_off_tracking = page;
        },
        speedhistoryTable_search() {
            console.log(this.search_speedhistory)
            if (this.search_speedhistory != null && this.search_speedhistory.length > 0) {
                this.speedhistory_table.search(this.search_speedhistory).draw();
            }
        },
        clear_speedhistoryTable_search() {
            this.search_speedhistory = ''
            this.speedhistory_table.search(this.search_speedhistory).draw();
        },
        speedhistoryTable_showof(page) {
            console.log("PAGE : " + page);
            this.speedhistory_table.page.len(page).draw()
            this.speedhistory_page_off = page;
        },
        //------------------------------------------------------------------



        //INITIALIZE TABLE FUNCTIONS
        //------------------------------------------------------------------
        //Initialize Table Thuraya
        initTable() {
            var root = this
            if (this.thuraya_table) {
                console.log("MASUK CLEAR TABLE");
                root.thuraya_table = null;
                root.initTable()
            } else {
                console.log("MASUK INIT TABLE");
                console.log(this.thuraya_data);
                this.thuraya_table = new DataTable('#thurayaTable', {
                    data: this.thuraya_data,
                    scrollCollapse: true,
                    scroller: true,
                    destroy: true,
                    deferRender: true,
                    ordering: true,
                    bLengthChange: false,
                    responsive: true,
                    dom: 'lrtip',
                    columns: [
                        {
                            width: "5%",
                            data: '', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                            <div class="p-1 h-100 row`+ meta.row + `">
                            ${meta.row + 1}
                            </div>
                            `
                            }
                        },
                        {
                            width: " 15.5%",
                            data: 'name', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                if (this.authStore.userAllowed && this.authStore.userAllowed.allowed['edit vessel detail']) {
                                    return `
                                    <div class="p-1 h-100 row`+ meta.row + `">
                                    <button id="thname_btn" data-bs-toggle="modal" data-bs-target="#thurayaNameModal"  type="button" class="btn btn-sm btn-primary rounded-none"><i class="fas fa-cog"></i></button>
                                    ${data}
                                    `+ (this.thuraya_data[meta.row].status.toUpperCase() != 'ACTIVE' ? `<span class="badge rounded-none text-bg-secondary">${this.thuraya_data[meta.row].status}</span>` : ``) + `
                                    </div>
                                    `
                                } else {
                                    return `
                                    <div class="p-1 h-100 row`+ meta.row + `">
                                    ${data}
                                    `+ (this.thuraya_data[meta.row].status.toUpperCase() != 'ACTIVE' ? `<span class="badge rounded-none text-bg-secondary">${this.thuraya_data[meta.row].status}</span>` : ``) + `
                                    </div>
                                    `
                                }

                            }
                        },
                        {
                            width: " 15.5%",
                            data: 'pt_name', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                                <div class="p-1 h-100 row`+ meta.row + `">
                                ${data ? data : '-'}
                                </div>
                                    `
                            }
                        },
                        {
                            width: " 8.5%",
                            data: 'pt_group', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                                <div class="p-1 h-100 row`+ meta.row + `">
                                ${data ? data : '-'}
                                </div>
                                    `
                            }
                        },
                        {
                            width: " 18.5%",
                            data: 'thuraya_number', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                                <div class="p-1 h-100 row`+ meta.row + `">
                                <button `+ (this.thuraya_data[meta.row].callEnabled == 0 ? 'disabled' : '') + ` id="thchat_btn" data-bs-toggle="modal" data-bs-target="#thurayaChatModal"  type="button" class="btn btn-sm ` + (this.thuraya_data[meta.row].callEnabled == 0 ? 'btn-secondary' : 'btn-primary') + ` rounded-none"><i class="fas fa-comments"></i></button>
                                <button `+ (this.thuraya_data[meta.row].callEnabled == 0 || this.thuraya_data[meta.row].callEnabled == 1 ? 'disabled' : '') + ` id="thcall_btn" data-bs-toggle="modal" data-bs-target="#thurayaCallModal" type="button" class="btn btn-sm ` + (this.thuraya_data[meta.row].callEnabled == 0 || this.thuraya_data[meta.row].callEnabled == 1 ? `btn-secondary` : `btn-success`) + ` rounded-none"><i class="fas fa-phone-alt"></i></button>
                                ${data} 
                                </div>
                                    `
                            }
                        },
                        {
                            width: " 8.5%",
                            data: 'id', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                        
                             <div  class="dropdown">
          <button class="btn btn-sm rounded-none btn-primary dropdown-toggle" data-bs-toggle="dropdown"
            aria-expanded="false">
            <i class="fas fa-wrench me-1"></i>Config
          </button>

          <ul class="dropdown-menu border bg-main dropdown-menu-end rounded-none dropdown_nav">
    
          ${this.authStore.userAllowed.allowed['view whitelist contact'] ? `<li><a id="thwhitelist_btn" data-bs-toggle="modal" data-bs-target="#thurayaWhiteListModal" class="dropdown-item" href="#">Whitelist</a></li>` : ``}
          ${this.authStore.userAllowed.allowed['view vessel detail'] ? `<li><a id="thvdetail_btn" data-bs-toggle="modal" data-bs-target="#thurayaVesselDetailModal" class="dropdown-item" href="#">Vessel Detail</a></li>` : ``}  
          ${this.authStore.userAllowed.allowed['view listing'] ? `<li><a id="thmanifest_btn" data-bs-toggle="modal" data-bs-target="#thurayaManifestModal" class="dropdown-item" href="#">Manifest Listing</a></li>` : ``}
          ${`<li><a id="thgeo_btn" data-bs-toggle="modal" data-bs-target="#thurayaGeoModal" class="dropdown-item" href="#">Geofencing</a></li>`}
          ${this.authStore.userAllowed.role_id <= 2 ? `<li><a id="thadminpass_btn" data-bs-toggle="modal" data-bs-target="#thurayaAdminPassModal" class="dropdown-item" href="#">Admin Password</a></li>` : ``}
          ${this.thuraya_data[meta.row].thuraya_ver == 2.0 ? `<li><a id="threboot_btn" data-bs-toggle="modal" data-bs-target="#thurayaRebootModal" class="dropdown-item" href="#">Reboot Command</a></li>` : ``}
          </ul>
        </div>
                              `
                            }
                        },
                        {
                            width: " 5.5%",
                            data: 'id', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                                ${this.authStore.userAllowed.allowed['view sos contact'] ? `
                                     <div  class="dropdown">
          <button class="btn btn-sm rounded-none btn-danger dropdown-toggle" data-bs-toggle="dropdown"
            aria-expanded="false">
            SOS
          </button>

          <ul class="dropdown-menu border bg-main dropdown-menu-end rounded-none dropdown_nav">
    
            <li><a id="thsosconfig_btn" data-bs-toggle="modal" data-bs-target="#thurayaSOSContactModal" class="dropdown-item" href="#"><i class="fas fa-cog me-2"></i>Config</a></li>
            
            <li><a id="thsoshistory_btn"  data-bs-toggle="modal" data-bs-target="#sosHistoryModal" class="dropdown-item" href="#">History</a></li>
          </ul>
        </div>
                                    ` : ``}
                             
                              `
                            }
                        },
                        {
                            width: " 7.5%",
                            data: 'id', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                        
                               <div  class="dropdown">
                            <button class="btn btn-sm rounded-none btn-dark dropdown-toggle" data-bs-toggle="dropdown"
                                aria-expanded="false">
                                Tracking
                            </button>

                            <ul class="dropdown-menu border bg-main dropdown-menu-end rounded-none dropdown_nav">
                            ${this.authStore.userAllowed.allowed['view tracking'] ? `<li><a id="thinterval_btn" data-bs-toggle="modal" data-bs-target="#thurayaTrackingIntervalModal" class="dropdown-item" href="#">Interval</a></li>` : ``}
                            ${this.authStore.userAllowed.allowed['view tracking contact'] ? `<li><a id="thgpsmail_btn" data-bs-toggle="modal" data-bs-target="#thurayaGPSEmailModal" class="dropdown-item" href="#">GPS Email</a></li>` : ``}
                            ${this.authStore.userAllowed.allowed['view tracking'] ? `<li><a id="thspeednotif_btn" data-bs-toggle="modal" data-bs-target="#thurayaSpeedNotifModal" class="dropdown-item" href="#">Speed Alert</a></li>` : ``}
                            ${this.authStore.userAllowed.allowed['view tracking'] ? `<li><a id="thspeedhistory_btn" data-bs-toggle="modal" data-bs-target="#thurayaspeedHistoryModal" class="dropdown-item" href="#">↳ History</a></li>` : ``}
                                
                                
                            </ul>
                            </div>
                              `
                            }
                        },
                        {
                            width: " 5.5%",
                            data: 'id', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                if (this.authStore.userAllowed.allowed['view cdr']) {
                                    return `
                              <div class="p-1 h-100">
                              <button id="thcdr_btn" data-bs-toggle="modal" data-bs-target="#thurayaCDRModal"  type="button" class="btn btn-sm btn-success w-100 rounded-none">CDR</button>
                              </div>
                              `
                                } else {
                                    return `
                        
                              <div class="p-1 h-100">
                              <button disabled type="button" class="btn btn-sm btn-secondary w-100 rounded-none">CDR</button>
                              </div>
                              `
                                }

                            }
                        },
                        {
                            width: " 8.5%",
                            data: 'id', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                if (this.thuraya_data[meta.row].tracking == true) {
                                    return `
                        
                                    <div class="p-1 h-100">
                                    <button onclick="showonmap_thuraya('`+ data + `')"  type="button" class="btn btn-sm btn-primary w-100 rounded-none">Show on Map</button>
                                    </div>
                                    `
                                } else {
                                    return `
                        
                              <div class="p-1 h-100">
                              <button disabled  type="button" class="btn btn-sm btn-secondary w-100 rounded-none">Show on Map</button>
                              </div>
                              `
                                }

                            }
                        },
                    ]
                });


                var mom = this;

                this.thuraya_table.on('click', '#thname_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.thuraya_table.row(tr);
                    console.log("NAME DI CLICK");
                    console.log(row.data());
                    mom.change_vesselname = null
                    setTimeout(() => {
                        mom.change_vesselname = row.data().name
                        mom.change_vesselid = row.data().id
                    }, 1000);
                    mom.fetch_vesselname(row.data())

                });


                this.thuraya_table.on('click', '#thchat_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.thuraya_table.row(tr);
                    console.log("CHATROOM DI CLICK");
                    console.log(row.data());

                    mom.fetch_chatroom(row.data())

                });


                this.thuraya_table.on('click', '#thcall_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.thuraya_table.row(tr);
                    console.log("CALL DI CLICK");
                    console.log(row.data());
                    mom.call_item = row.data()
                    mom.fetch_call(row.data())
                });

                this.thuraya_table.on('click', '#thcdr_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.thuraya_table.row(tr);
                    console.log("CDR DI CLICK");
                    console.log(row.data());

                    mom.cdr_range = 'month'
                    mom.cdr_rangelabel = 'This Month'

                    mom.fetch_cdr(row.data())
                });


                this.thuraya_table.on('click', '#thwhitelist_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.thuraya_table.row(tr);
                    console.log("WHITELIST CONFIG DI CLICK");
                    console.log(row.data());

                    mom.th_whitelist_item = row.data()
                    mom.fetch_whitelist(row.data())
                });

                this.thuraya_table.on('click', '#thvdetail_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.thuraya_table.row(tr);
                    console.log("VESSEL DETAIL CONFIG DI CLICK");
                    console.log(row.data());

                    mom.th_vesseldetail_item = row.data()
                    mom.fetch_vesseldetail(row.data())
                });


                this.thuraya_table.on('click', '#thmanifest_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.thuraya_table.row(tr);
                    console.log("MANIFEST LISTING CONFIG DI CLICK");
                    console.log(row.data());

                    mom.th_manifestlisting_item = row.data()
                    mom.fetch_manifest_listing(row.data())
                });

                this.thuraya_table.on('click', '#thadminpass_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.thuraya_table.row(tr);
                    console.log("ADMIN PASSWORD CONFIG DI CLICK");
                    console.log(row.data());

                    mom.th_adminpass_item = row.data()
                    mom.fetch_admin_passsword(row.data())
                });

                this.thuraya_table.on('click', '#thinterval_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.thuraya_table.row(tr);
                    console.log("INTERVALCONFIG DI CLICK");
                    console.log(row.data());

                    mom.th_intervalconfig_item = row.data()
                    mom.fetch_interval_config(row.data())
                });

                this.thuraya_table.on('click', '#thgpsmail_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.thuraya_table.row(tr);
                    console.log("GPS EMAIL DI CLICK");
                    console.log(row.data());

                    mom.th_gpsemail_item = row.data()
                    mom.fetch_gpsemail(row.data())
                });

                this.thuraya_table.on('click', '#thspeednotif_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.thuraya_table.row(tr);
                    console.log("SPEED NOTIF DI CLICK");
                    console.log(row.data());

                    mom.th_speednotif_item = row.data()
                    mom.fetch_speed_notif(row.data())
                });

                this.thuraya_table.on('click', '#thspeedhistory_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.thuraya_table.row(tr);
                    console.log("SPEED HISTORY DI CLICK");
                    console.log(row.data());
                    mom.speedhistory_data = null;
                    mom.search_speedhistory = '';
                    mom.fetch_speedhistory(row.data());

                });

                this.thuraya_table.on('click', '#thsosconfig_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.thuraya_table.row(tr);
                    console.log("SOS CONFIG DI CLICK");
                    console.log(row.data());

                    mom.th_sosconfig_item = row.data()
                    mom.fetch_sos_config(row.data())
                });

                this.thuraya_table.on('click', '#threboot_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.thuraya_table.row(tr);
                    console.log("REBOOT DI CLICK");
                    console.log(row.data());

                    mom.th_reboot_item = row.data()
                    mom.fetch_reboot_command(row.data())
                });
                this.thuraya_table.on('click', '#thsoshistory_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.thuraya_table.row(tr);
                    console.log("REBOOT DI CLICK");
                    console.log(row.data());

                    mom.th_sos_history_item = row.data()
                    mom.fetch_sos_history(row.data())
                });
                this.thuraya_table.on('click', '#thgeo_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.thuraya_table.row(tr);
                    console.log("GEOFENCING DI CLICK");
                    console.log(row.data());

                    mom.th_geo_item = row.data()
                    mom.fetch_geo_config(row.data())
                });


            }
        },
        //Initialize Table User Thuraya
        initTable_user() {
            var root = this
            console.log("INIT TABLE USER");
            console.log(this.thurayauser_table);
            console.log("MASUK INIT TABLE USER");
            console.log(this.mapthuraya_data.vesselUsers);
            this.thurayauser_table = new DataTable('#thurayaTableUser', {
                data: this.mapthuraya_data.vesselUsers,
                scrollCollapse: true,
                scroller: true,
                destroy: true,
                pageLength: 5,
                deferRender: true,
                ordering: true,
                bLengthChange: false,
                responsive: true,
                dom: 'lrtip',
                columns: [
                    {
                        width: "3%",
                        data: '', className: 'td_nowrap', render: (data, type, row, meta) => {
                            return `
                            <div class="p-1 h-100">
                            ${meta.row + 1}
                            </div>
                            `
                        }
                    },
                    {
                        width: "24.25%",
                        data: 'name', className: 'td_nowrap', render: (data, type, row, meta) => {
                            return `
                            <div class="p-0 h-100">
                            ${data}
                            </div>
                            `
                        }
                    },
                    {
                        width: "24.25%",
                        data: 'email', className: 'td_nowrap', render: (data, type, row, meta) => {
                            return `
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                                    `
                        }
                    },
                    {
                        width: "14.25%",
                        data: 'role_name', className: 'td_nowrap', render: (data, type, row, meta) => {
                            return `
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                                    `
                        }
                    },
                    {
                        width: "34.25%",
                        data: 'lastLogin', className: 'td_nowrap', render: (data, type, row, meta) => {
                            return `
                        
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                              `
                        }
                    },
                ]
            });
        },
        //Initialize Table Tracking
        initTable_tracking(data) {
            console.log("INIT TABLE TRACKING");
            console.log(this.thurayatrack_table);
            console.log("MASUK INIT TABLE TRACKING");
            console.log(data.tracking);
            this.thurayatrack_table = new DataTable('#thurayaTableTrack', {
                data: data.dataTable,
                scrollCollapse: true,
                scroller: true,
                destroy: true,
                pageLength: 10,
                deferRender: true,
                ordering: true,
                bLengthChange: false,
                responsive: true,
                dom: 'lrtip',
                columns: [
                    {
                        width: "3%",
                        data: '', className: 'td_nowrap', render: (data, type, row, meta) => {
                            return `
                            <div class="p-1 h-100">
                            ${meta.row + 1}
                            </div>
                            `
                        }
                    },
                    {
                        width: "13.2%",
                        data: 'latlong', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                            return `
                            <div onclick="popup_tracking('`+ meta.row + `')" class="p-0 h-100 link_tracking">
                            ${data}
                            </div>
                            `
                        }
                    },
                    {
                        width: "13.2%",
                        data: 'dms', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                            return `
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                                    `
                        }
                    },
                    {
                        width: "13.2%",
                        data: 'speed', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                            return `
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                                    `
                        }
                    },
                    {
                        width: "13.2%",
                        data: 'heading', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                            return `
                        
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                              `
                        }


                    },
                    {
                        width: "13.2%",
                        data: 'distance', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                            return `
                        
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                              `
                        }


                    },
                    {
                        width: "13.2%",
                        data: 'dateTime', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                            return `
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                              `
                        }
                    },
                    {
                        width: "13.2%",
                        data: 'dateTime_local', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                            return `  
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                              `
                        }
                    },
                ]
            });
        },
        //Initialize Table CDR
        initTable_cdr(data) {
            console.log("INIT TABLE CDR");
            console.log(this.thurayaCDR_table);
            console.log("MASUK INIT TABLE CDR");
            try {
                this.thurayaCDR_table = new DataTable('#thurayaTableCDR', {
                    data: data.output,
                    scrollCollapse: true,
                    scroller: true,
                    destroy: true,
                    pageLength: 10,
                    deferRender: true,
                    ordering: true,
                    bLengthChange: false,
                    responsive: true,
                    dom: 'lrtip',
                    columns: [
                        {
                            width: "9%",
                            data: 'Services', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                <div class="p-1 h-100">
                                ${data}
                                </div>
                                `
                            }
                        },
                        {
                            width: "9%",
                            data: 'MSISDN', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                <div class="p-1 h-100">
                                ${data}
                                </div>
                                `
                            }
                        },
                        {
                            width: "9%",
                            data: 'Type', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                <div class="p-1 h-100">
                                ${data}
                                </div>
                                `
                            }
                        },
                        {
                            width: "9%",
                            data: 'Plcode', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                <div class="p-1 h-100">
                                ${data}
                                </div>
                                `
                            }
                        },
                        {
                            width: "9%",
                            data: 'Network', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                <div class="p-1 h-100">
                                ${data}
                                </div>
                                `
                            }
                        },
                        {
                            width: "9%",
                            data: 'Start_Date', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                <div class="p-1 h-100">
                                ${data}
                                </div>
                                `
                            }
                        },
                        {
                            width: "9%",
                            data: 'Called_Party', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                <div class="p-1 h-100">
                                ${data}
                                </div>
                                `
                            }
                        },
                        {
                            width: "9%",
                            data: 'Duration', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                <div class="p-1 h-100">
                                ${data}
                                </div>
                                `
                            }
                        },
                        {
                            width: "9%",
                            data: 'Band', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                <div class="p-1 h-100">
                                ${data}
                                </div>
                                `
                            }
                        },
                        {
                            width: "9%",
                            data: 'Amount', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                <div class="p-1 h-100">
                                ${data}
                                </div>
                                `
                            }
                        },
                        {
                            width: "9%",
                            data: 'dateTime_local', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                <div class="p-1 h-100">
                                ${data}
                                </div>
                                `
                            }
                        },
                    ]
                });

                if (this.thurayaCDR_table) {
                    $("#cdrfilter1").trigger("click");
                }


            } catch (e) {

            }

        },
        //Initialize SOS History
        initTable_sos_history(data_history) {
            console.log("INIT TABLE IMAGES");
            console.log("MASUK INIT TABLE IMAGES");
            var mom = this;
            var history = data_history;
            this.th_sos_history_table = new DataTable('#sosHistoryTable', {
                data: data_history,
                scrollCollapse: true,
                stateSave: true,
                scroller: true,
                destroy: true,
                pageLength: 10,
                deferRender: true,
                ordering: true,
                bLengthChange: false,
                responsive: true,
                dom: 'lrtip',
                columns: [
                    {
                        width: "",
                        data: '', className: 'td_nowrap', render: (data, type, row, meta) => {


                            return `
                            <div id="image-detail" class="p-1 btn h-100 d-flex align-items-center justify-content-center">
                            <div class="text-start">
                             <div  class="card rounded-none " style="min-width:6em;min-height:6em;">
                             <div class="card-header">${history[meta.row].dateTime_local}</div>
                             <div style="background-color:#AAD3DF"  class="card-body p-0 d-flex align-items-center justify-content-center">
                                <div id="map_sos_history_${meta.row}" style="width:20em;height:20em"></div>
                             </div>  
                            </div>
                            </div>
                            </div>
                            `
                        }


                    },
                ]
            });

            for (let i = 0; i < data_history.length; i++) {
                var map = L.map('map_sos_history_' + i,
                    {
                        fullscreenControl: true,
                    }
                ).setView([data_history[i].latitude, data_history[i].longitude], 8);
                L.tileLayer(mom.map_store.url, {
                    maxZoom: 19,
                }).addTo(map);

                map.invalidateSize();
                var marker = L.marker([data_history[i].latitude, data_history[i].longitude], {
                    icon: this.return_icon_history(data_history[i]),
                    rotationAngle: data_history[i].heading

                }).addTo(map).bindPopup(
                    `
                    <ul class="list-group border-0">
  <li class="list-group-item border-0"><b>Latitude : </b>${data_history[i].latitude}</li>
  <li class="list-group-item border-0"><b>Longitude : </b>${data_history[i].longitude}</li>
  <li class="list-group-item border-0"><b>DMS : </b>${data_history[i].dms}</li>
  <li class="list-group-item border-0"><b>Speed : </b>${data_history[i].speed}</li>
  <li class="list-group-item border-0"><b>Heading : </b>${data_history[i].heading}</li>
  <li class="list-group-item border-0"><b>Altitude : </b>${data_history[i].altitude}</li>
  <li class="list-group-item border-0"><b>Updated : </b>${data_history[i].updated_at}</li>
  <li class="list-group-item border-0"><button disabled type="button" class="btn btn-outline-danger rounded-none w-100">SOS ALERT</button></li>
  
</ul>
                    `
                );
            }
        },
        initTable_history(dataHistory) {

            var root = this
            console.log("INIT TABLE HISTORY NOTIF");
            console.log(this.speedhistory_table);
            console.log("MASUK INIT TABLE HISTORY NOTIF");
            console.log(dataHistory);

         

            try {
                if (dataHistory) {
                    this.speedhistory_table = new DataTable('#thspeedhistoryTable', {
                        data: dataHistory,
                        scrollCollapse: true,
                        scroller: true,
                        destroy: true,
                        pageLength: 5,
                        deferRender: true,
                        ordering: true,
                        bLengthChange: false,
                        responsive: true,
                        dom: 'lrtip',
                        columns: [
                            {
                                width: "5%",
                                data: '', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                    return `
                                <div class="p-1 h-100 row`+ meta.row + `">
                                ${meta.row + 1}
                                </div>
                                `
                                }
                            },
                            {
                                width: "2%",
                                data: 'msg_id', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                    return `
                                <div class="p-1 h-100 row`+ meta.row + `">
                                <div class="form-check">
  <input ${this.speedhistory_data.notifications[meta.row].unRead == 1 ? '' : 'disabled'} onchange="oncheck_notif('${data}','thuraya')" id="checknotif_${data}" class="form-check-input" type="checkbox" value="">
</div>
                                </div>
                                `
                                }
                            },
                            {
                                width: "92%",
                                data: 'dateTime_local', className: 'td_nowrap', render: (data, type, row, meta) => {
                                    return `
                                    <div onclick="onclick_notif_speedhistory('${meta.row}','thuraya')" id="notif_btn" class="p-1 h-100 pointer_cursor" data-bs-toggle="modal" data-bs-target="#notification_speed_detailModal">
                                     <h8 class="${!this.speedhistory_data.notifications[meta.row].unRead ? 'text-secondary opacity-50' : 'text-dark'}"><b>${(this.speedhistory_data.notifications[meta.row].type == 1 || this.speedhistory_data.notifications[meta.row].type == 2 || this.speedhistory_data.notifications[meta.row].type == 5) ? 'Speed Alert' : (this.speedhistory_data.notifications[meta.row].type == 3) ? 'RPM Alert' : 'Speed Alert'}</b></h8>${this.speedhistory_data.notifications[meta.row].unRead ? `<i  style="font-size: 0.6em;" class='bx bxs-circle text-danger ms-2'></i>` : ``}<br/>
                                    <span class="${!this.speedhistory_data.notifications[meta.row].unRead ? 'text-secondary opacity-50' : 'text-dark'}" style="font-size: 0.8em;">${this.speedhistory_data.notifications[meta.row].msg}</span><br/>
                                    <p class="${!this.speedhistory_data.notifications[meta.row].unRead ? 'text-secondary opacity-50' : 'text-primary'}" style="font-size: 0.8em;" ><b>${this.speedhistory_data.notifications[meta.row].dateDiff} (${data ? data : '-'})</b></p>
                                    </div>
                                    `
                                }
                            },

                        ]
                    });
                }
            } catch (e) {

            }
        },
        //------------------------------------------------------------------



        //CHATROOM ACTIONS FUNCTIONS
        //------------------------------------------------------------------
        //Filter Chatroom 
        filter_chatroom() {
            const message = this.chatroom_messages
            console.log(" HASIL SEARCH ");

            const filter_message = message.filter(val => val.body.includes(this.chatroom_search));
            console.log(filter_message);
            if (this.chatroom_search) {
                this.chatroom_messages = filter_message
            } else {
                this.chatroom_messages = this.chatroom_data.messages.messages
                clearInterval(this.chatroom_interval);
            }

        },
        //Close Chatroom
        close_chatroom() {
            clearInterval(this.chatroom_interval);
            this.chatroom_data = null
        },
        //Clear Chatroom
        clear_chatroom_search() {
            this.chatroom_search = ''
            this.chatroom_messages = this.chatroom_data.messages.messages
            clearInterval(this.chatroom_interval);
        },
        //Request Chatroom Data Gradually
        async gradual_chatroom() {
            await api.get(`/api/v1/navplus/sms/fetch/` + this.chatroom_id, {}
            ).then((res) => {
                console.log("UPDATE CHATROOM");
                console.log(res);
                this.chatroom_messages = res.data.messages.messages
                if (!this.chatroom_search && this.chatroom_search.length == 0) {
                    this.chatroom_messages = res.data.messages.messages
                }
            }).catch((err) => {

            }).then(() => {
            });


        },
        //------------------------------------------------------------------



        //SHOW ON MAP FUNCTIONS
        //------------------------------------------------------------------
        //Show on Map Thuraya
        showonMap(id) {
            console.log("THURAYA SHOW ON MAP");
            this.is_showonmap = false;
            $('html, body').animate({ scrollTop: '0px' }, 5);
            setTimeout(() => {
                if (this.eta_store.tableRoutedetail) {
                    this.eta_store.tableRoutedetail.destroy();
                    this.eta_store.tableRoutedetail = null
                }
                this.is_showonmap = true;
                this.mapthuraya_data = null;
                this.mapthuraya_data_date = null;
                this.mapthuraya_id = null;
                this.show_range = "Last 24 Hours";
                this.tracking_layer.clearLayers();
                this.target_layer.clearLayers();
                this.link_layer.clearLayers();
                this.fetch_thurayaMap(id);

                setTimeout(() => {
                    $('html, body').animate({
                        scrollTop: $("#mapthuraya_card").offset().top - 68
                    }, 500);
                }, 10);
                setTimeout(() => {
                    this.fetch_ThurayaMap_date("24");
                }, 1000);
            }, 5);
        },
        //Close Show on Map Thuraya
        closeonMap() {
            this.is_showonmap = null;
            this.mapthuraya_id = null;
            this.mapthuraya_data = null;
            this.mapthuraya_data_date = null;
            this.show_range = "Last 24 Hours";
            this.tracking_layer.clearLayers();
            this.target_layer.clearLayers();
            this.link_layer.clearLayers();
            $('html, body').animate({ scrollTop: '0px' }, 500);

            if (this.eta_store.tableRoutedetail) {
                this.eta_store.tableRoutedetail.destroy();
                this.eta_store.tableRoutedetail = null
            }
        },
        //------------------------------------------------------------------



        //CHANGE RANGE FUNCTIONS
        //------------------------------------------------------------------
        //Change Range CDR
        changerange_cdr(val, label) {
            this.cdr_range = val
            this.cdr_rangelabel = label
            this.fetch_cdr(this.cdr_item)
        },
        //Change Range
        change_range(index) {
            this.show_range = this.range_arr[index].label;
            this.fetch_ThurayaMap_date(this.range_arr[index].val);
            this.link_layer.clearLayers();
        },
        change_geotab(geo_tab) {
            this.th_geo_tab_index = geo_tab
            this.th_geo_area_index = 0
            setTimeout(() => {
                this.change_geoarea(0)
            }, 500);
        },
        change_geoarea(area) {
            this.th_geo_area_index = area
            this.th_geo_area_arr = []
            this.fetch_geo_area();
        },
        //------------------------------------------------------------------



        //THURAYA CALL FUNCTIONS
        //------------------------------------------------------------------
        //Start Call
        startCall() {
            var supreme = this
            let name = this.call_item.name;
            let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

            let initials = [...name.matchAll(rgx)] || [];

            initials = (
                (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
            ).toUpperCase();
            console.log("INITIAL");
            console.log(initials);
            this.call_connected = true;
            document.getElementById("time-status").classList.add("d-none");
            document.getElementById("totaltime-status").classList.add("d-none");
            document.getElementById("progress-tatus").classList.remove("d-none");
            document.getElementById("hangup-btn").style.color = "#fff";
            document.getElementById("hangup-btn").style.cursor = "pointer";
            document.getElementById("call-btn").style.color = "#A1B8AB";
            document.getElementById("call-btn").style.cursor = "not-allowed";
            var callInterval;
            var thuraya_number = "";

            //from API
            var quotaCall = this.call_data.quota;
            var limited = !this.call_data.nolimit;

            // if (this.callData.nolimit == false) {limited = true } else {limited = false}

            var durationCall = 0;
            var cd = 30;


            const webSocketServer = "wss://pbx.sailink.id:8099/ws";
            const domain = "pbx.sailink.id";
            const aor = "sip:99305@" + domain;
            const displayName = "99305";
            const authorizationUsername = "99305";
            const authorizationPassword = "cb7ac990829f2f40f14b010271c5494c";
            // const target = "sip:" + thurayaNumber + "@" + domain;
            function pad(num, size) {
                var s = "000" + num;
                return s.substring(s.length - size);
            }

            function getFormat(second) {
                var m = Math.floor(second / 60);
                var s = second - m * 60;
                var formatted = pad(m, 2) + ':' + pad(s, 2);
                return formatted;
            }
            function getFormatRoundUp(second) {
                var m = Math.floor(second / 60);
                var formatted = pad(m, 2);
                return formatted;
            }

            function getAudio(id) {
                const el = document.getElementById(id);
                if (!(el instanceof HTMLAudioElement)) {
                    throw new Error(`Element "${id}" not found or not an audio element.`);
                }
                console.log("audio element is valid");
                return el;
            }
            function getButton(id) {
                const el = document.getElementById(id);
                if (!(el instanceof HTMLButtonElement)) {
                    throw new Error(`Element "${id}" not found or not a button element.`);
                }
                console.log("button is valid");
                return el;
            }
            function getDiv(id) {
                const el = document.getElementById(id);

                if (!(el instanceof HTMLSpanElement)) {
                    console.log("TIDAK KETEMU");
                    console.log(id);

                    throw new Error(`Element "${id}" not found or not a div element.`);

                }

                return el;
            }

            const audioElement = getAudio("remoteAudio");
            const hangupButton = getButton("hangup-btn");
            const statusDiv = getDiv("thurayaCallStatus");

            const simpleUserDelegate = {
                onCallCreated: () => {
                    // console.log(`[${displayName}] Call created`);
                    // statusDiv.innerHTML = "Call created";

                    console.log(`[${displayName}] Call Requested`);
                    statusDiv.innerHTML = "Call Requested";
                },
                onCallAnswered: () => {
                    // console.log(`[${displayName}] Call answered`);
                    // statusDiv.innerHTML = "Call answered";
                    document.getElementById("progress-tatus").classList.add("d-none");
                    document.getElementById("totaltime-status").classList.add("d-none");
                    document.getElementById("time-status").classList.remove("d-none");
                    console.log(`[${displayName}] On Progress Call`);
                    statusDiv.innerHTML = "On Progress Call";

                    // start counting
                    console.log("started counting");

                    durationCall = 0;

                    callInterval = setInterval(() => {
                        console.log("==============================");
                        durationCall++;
                        if (durationCall > cd) {
                            quotaCall--;
                        }
                        console.log("duration   = " + durationCall);
                        console.log("quota      = " + quotaCall);
                        console.log("call duration  == " + getFormat(durationCall));
                        console.log("call quota     == " + getFormat(quotaCall));
                        console.log("\n");

                        // $('#callDuration').html(getFormat(durationCall));
                        $("#callQuota").html(getFormatRoundUp(quotaCall));

                        if (limited) {
                            console.log("masuk limited");
                            if (quotaCall < 1 || durationCall >= 180) {
                                doHangup();
                                alert(
                                    "Quota Call HABIS / Durasi Sesi melebihi batas (3 Menit)"
                                );
                            }
                        }
                    }, 1000);
                },

                onCallHangup: () => {


                    console.log('INI ADALAH SISA DURATION CALL : ')
                    console.log(durationCall)
                    console.log('INI ADALAH SISA QUOTA CALL : ')
                    console.log(quotaCall)
                    var sisaqt = getFormatRoundUp(quotaCall)

                    var dataCLsend = {
                        token: localStorage.getItem("token"),
                        thuraya_number: supreme.call_item.thuraya_number,
                        duration: durationCall,
                        quota: sisaqt,
                    }

                    supreme.store_call(dataCLsend)


                    console.log(`[${displayName}] Call hangup`);
                    statusDiv.innerHTML = "Call disconnected";
                    document.getElementById("time-status").classList.add("d-none");
                    document.getElementById("progress-tatus").classList.add("d-none");
                    document
                        .getElementById("totaltime-status")
                        .classList.remove("d-none");
                    document.getElementById("call-btn").style.color = "#ffff";
                    document.getElementById("call-btn").style.cursor = "pointer";
                    document.getElementById("hangup-btn").style.color = "#CB7C7C";
                    document.getElementById("hangup-btn").style.cursor = "not-allowed";
                    this.call_connected = false;

                    // stop counting
                    console.log("stop counting");
                    clearInterval(callInterval);
                    console.log("TOTAL CALL TIME == " + durationCall + " Second");
                    let sisa = getFormatRoundUp(quotaCall);
                    this.call_totalrounded = getFormatRoundUp(durationCall);
                    console.log("TOTAL ROUNDED UP == " + sisa + " Minute");
                    save_call_log(durationCall, sisa);
                },
                onCallHold: (held) => {
                    console.log(`[${displayName}] Call hold ${held}`);
                },
            };
            const simpleUserOptions = {
                aor,
                delegate: simpleUserDelegate,
                media: {
                    remote: {
                        audio: audioElement,
                    },
                },
                userAgentOptions: {
                    logLevel: "debug",
                    displayName,
                    authorizationUsername,
                    authorizationPassword,
                    logConfiguration: true,
                },
            };

            const simpleUser = new SIP.Web.SimpleUser(
                webSocketServer,
                simpleUserOptions
            );

            async function doCall(thurayaNumber, noLimit) {
                let target = "sip:" + thurayaNumber + "@" + domain;
                thuraya_number = thurayaNumber;
                limited = !noLimit;

                statusDiv.innerHTML = "Connecting...";
                await simpleUser.connect();
                statusDiv.innerHTML = "Registering...";
                await simpleUser.register();
                statusDiv.innerHTML = "Calling... " + thurayaNumber;
                await simpleUser.call(target, { inviteWithoutSdp: false });
            }

            console.log("THURAYA NUMBERNYA");
            console.log(this.call_item.thuraya_number);
            doCall(this.call_item.thuraya_number);

            async function doHangup() {
                try {
                    await simpleUser.disconnect();
                    await simpleUser.hangup();
                } catch (error) {
                    console.log(`[${displayName}] Call hangup`);
                    statusDiv.innerHTML = "Call disconnected";

                    // stop counting
                    console.log("stop counting");
                    clearInterval(callInterval);
                    console.log("TOTAL CALL TIME == " + durationCall + " Second");
                    console.log(getFormatRoundUp(quotaCall))
                    //save_call_log(durationCall, getFormatRoundUp(quotaCall));
                }
            }

            // callButton.addEventListener("click", () => {
            //     doCall();
            // });

            hangupButton.addEventListener("click", () => {
                doHangup();
                // clearInterval(callCounter);
            });

            function save_call_log(duration, sisa) {
                // call api store call
                var _token = $('input[name="_token"]').val();

                $.ajax({
                    url: "ajax/storeCallLog",
                    method: "POST",
                    data: {
                        thuraya_number: thuraya_number,
                        duration: duration,
                        sisa: sisa,
                        _token: _token,
                    },
                    dataType: "json",
                }).done(function (con) {
                    console.log("log saved");
                });
            }
        },
        //Store Call
        async store_call(data) {

            await api.get("/api/v1/navplus/call/store/" + data.thuraya_number + "/" + data.duration + "/" + data.quota, {}
            ).then((res) => {
                console.log("STORE CALL RESPONSE");
                console.log(res);

            }).catch((err) => {

            }).then(() => {
            });



        },
        //------------------------------------------------------------------



        //MAP ESSENTIALS FUNCTIONS
        //------------------------------------------------------------------
        //Initialize Map
        initializeMap(map_) {
            console.log("MAP THURAYA INITIALIZED");
            this.thuraya_map = map_;
            this.target_layer.addTo(this.thuraya_map);
            this.tracking_layer.addTo(this.thuraya_map);
            this.link_layer.addTo(this.thuraya_map);
            this.search_layer.addTo(this.thuraya_map);
            this.thuraya_map.scrollWheelZoom.disable();
            var searchLayer = this.search_layer;
            var mymap = this.thuraya_map;

            this.eta_store.init_eta(mymap, 'thuraya');
            this.eta_store.init_start_eta(mymap, 'thuraya', this.mapthuraya_data.maptop);
            this.eta_store.init_detail_route(mymap, 'thuraya')
            this.eta_store.table_koordinat();

            setTimeout(() => {
                L.Control.geocoder(
                    {
                        defaultMarkGeocode: false

                    }
                ).on('markgeocode', function (e) {
                    console.log(e);
                    console.log(e.geocode.center);
                    console.log(e.geocode.html);
                    mymap.setView(new L.LatLng(e.geocode.center.lat, e.geocode.center.lng), 8);

                    var circle = L.circle([e.geocode.center.lat, e.geocode.center.lng], {
                        color: "#1DC01D",
                        fillColor: "#1DC01D",
                        fillOpacity: 0.0,
                        opacity: 0.0,
                        weight: 0,
                        radius: 1600,
                    }).bindPopup(
                        e.geocode.html.length > 0 ?
                            e.geocode.html : e.geocode.name
                    )

                    circle.on("add", function (event) {
                        event.target.openPopup();
                    });

                    circle.addTo(searchLayer)
                }).addTo(this.thuraya_map);

            }, 500);


        },
        //On Map Click
        onmap_click() {
            this.search_layer.clearLayers();
        },
        //Set View Map
        setview_map() {
            this.thuraya_map.setView(new L.LatLng(this.mapthuraya_data_date.maptop.lat, this.mapthuraya_data_date.maptop.lon), 6);
        },
        //Fullscreen Options
        fullscreen_scroll() {
            console.log("CLICK FULL");
            this.thuraya_map.invalidateSize();
            if (this.thuraya_map.isFullscreen() == true) {
                this.thuraya_map.scrollWheelZoom.enable();
            } else {
                this.thuraya_map.scrollWheelZoom.disable();
            }
        },
        //Return Icon Thuraya Marker
        return_icon(maptop) {
            let svgIcon;
            svgIcon = L.divIcon({
                html: `
                    <svg
                    stroke="#FFF" 
                    stroke-width="10"
                    viewBox="0 0 40 100"
                    xmlns="http://www.w3.org/2000/svg"
                    >
                    <path d="M 37.93 32.68 L 20 0 L 2.07 32.68 c -1.35 2.45 -2.07 5.41 -2.07 8.44 v 57.33 H 40 V 41.12 c 0 -3.03 -0.72 -5.98 -2.07 -8.44 Z" fill="`+ maptop.fill + `"></path>
                    </svg>`,
                className: "",
                iconSize: [40 / 5, 100 / 5],
                iconAnchor: [40 / 5 / 2, 100 / 5 / 2],
            });

            return svgIcon;


        },
        //Return Icon Sos History
        return_icon_history(maptop) {
            let svgIcon;
            svgIcon = L.divIcon({
                html: `
                    <svg
                    stroke="#FFF" 
                    stroke-width="10"
                    viewBox="0 0 40 100"
                    xmlns="http://www.w3.org/2000/svg"
                    >
                    <path d="M 37.93 32.68 L 20 0 L 2.07 32.68 c -1.35 2.45 -2.07 5.41 -2.07 8.44 v 57.33 H 40 V 41.12 c 0 -3.03 -0.72 -5.98 -2.07 -8.44 Z" fill="red"></path>
                    </svg>`,
                className: "",
                iconSize: [40 / 5, 100 / 5],
                iconAnchor: [40 / 5 / 2, 100 / 5 / 2],
            });

            return svgIcon;


        },
        return_icon_geo(index) {
            let svgIcon;
            svgIcon = L.divIcon({
                html: `
                
                 <div
                                        style='position: relative;text-align: center;color: white;'
                                        >
                                          <svg
                                            
                                            id="a"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 288.32 341.71"
                                          >
                                            <path
                                              fill='#1A77D2'
                                              stroke="#ffff"
                                              stroke-width="7"
                                              d="M144.16,0C64.54,0,0,64.54,0,144.16c0,70.41,50.48,129.02,117.22,141.64l26.94,55.92,26.94-55.92c66.74-12.62,117.22-71.23,117.22-141.64C288.32,64.54,223.78,0,144.16,0Z"
                                            />
                                          </svg>
                                          <p
                                            style="
                                              position: absolute;
                                              font-size: 12px;
                                              top: 45%;
                                              left: 50%;
                                              transform: translate(-50%, -50%);
                                            "
                                          >
                                           <b> ${index}</b>
                                          </p>
                                        </div>
                
                
                `,
                className: "",
                iconSize: [40 / 1.3, 100 / 1.3],
                iconAnchor: [
                    40 / 1.3 / 2,
                    100 / 1.3 / 2,
                ]
            });

            return svgIcon;


        },
        //Return Icon Moving Marker
        return_track_icon() {
            const svgIcon = L.divIcon({
                html: `
                <svg
                stroke="#0000FF" 
                stroke-width="0"
                viewBox="0 0 57 57"
                xmlns="http://www.w3.org/2000/svg"
                >
                <path d="M50.8,56.4L30.6,43.7c-1.3-0.8-3-0.8-4.3,0L6.2,56.4c-3.4,2.2-7.5-1.6-5.7-5.2L24.9,2.2c1.5-3,5.7-3,7.2,0l24.5,48.9
                C58.4,54.8,54.3,58.5,50.8,56.4z" fill="#0000FF"></path>
                </svg>`,
                className: "",
                iconSize: [57 / 3.5, 57 / 3.5],
                iconAnchor: [57 / 3.5 / 2, 57 / 3.5 / 2],
            });
            return svgIcon
        },
        //Popup Tracking
        popup_tracking(index) {
            console.log(index);
            this.link_layer.clearLayers();
            var circle = L.circle([this.marker_tracking[index].lat, this.marker_tracking[index].lng], {
                color: "#1DC01D",
                fillColor: "#1DC01D",
                fillOpacity: 0.0,
                opacity: 0.0,
                weight: 0,
                radius: 1600,
            }).bindPopup(
                "<table><tr><td><b>Latitude</b></td><td>:</td><td>" +
                this.marker_tracking[index].lat +
                "</td></tr><tr><td><b>Longitude</b></td><td>:</td><td>" +
                this.marker_tracking[index].lng +
                "</td></tr><tr>" +
                "<td><b>DMS</b></td><td>:</td>" +
                "<td>" +
                this.marker_tracking[index].dms +
                "</td>" +
                "</tr>" +
                "<tr>" +
                "<td><b>Speed</b></td>" +
                "<td>:</td>" +
                " <td>" +
                this.marker_tracking[index].speed +
                "&nbsp;knots </td>" +
                "</tr>" +
                "<tr>" +
                " <td><b>Heading</b></td>" +
                "<td>:</td>" +
                "<td>" +
                this.marker_tracking[index].heading +
                "</td>" +
                " </tr>" +
                "<tr>" +
                "<td><b>Last Update</b></td>" +
                "<td>:</td>" +
                "<td>" +
                this.marker_tracking[index].updated +
                "</td>" +
                "</tr>" +
                "</table>"
            )

            circle.on("add", function (event) {
                event.target.openPopup();
            });

            this.thuraya_map.setView(new L.LatLng(this.marker_tracking[index].lat, this.marker_tracking[index].lng), 6);

            circle.addTo(this.link_layer)



            $('html, body').animate({ scrollTop: '0px' }, 5);
            setTimeout(() => {
                setTimeout(() => {
                    $('html, body').animate({
                        scrollTop: ($("#map_thuraya").offset().top) - 68
                    }, 500);
                }, 10);
            }, 10);
        },
        //onload geo map
        onload_geomap(index) {
            var mom = this;
            setTimeout(() => {

                if (mom.th_geo_map == null) {
                    mom.th_geo_map = L.map('geo_map_card',
                        {
                            fullscreenControl: true,
                        }
                    ).setView([-1.368935277916185, 117.93684649270449], 3);
                    L.tileLayer(mom.map_store.url, {
                        maxZoom: 19,
                    }).addTo(mom.th_geo_map);

                    mom.th_geo_map.invalidateSize();
                    mom.th_geo_map.on('click', function (e) {
                        var popLocation = e.latlng;
                        console.log(e.latlng)
                        console.log(e.latlng.lat)
                        console.log(e.latlng.lng)
                        mom.th_geo_area_arr.push([e.latlng.lat, e.latlng.lng]);
                        console.log(mom.th_geo_area_arr);

                        mom.draw_polygon_onclick(mom.th_geo_area_arr);
                        // var popup = L.popup()
                        // .setLatLng(popLocation)
                        // .setContent('<p>Hello world!<br />This is a nice popup.</p>')
                        // .openOn(mom.th_geo_map);        
                    });


                    // Create a custom control
                    var customControl = L.control({
                        position: 'bottomright' // Position of the control on the map
                    });

                    var maps_ = mom.th_geo_map
                    // Define the content and behavior of the control
                    customControl.onAdd = function (maps_) {
                        var div = L.DomUtil.create('div', 'custom-control'); // Create a <div> element with a class name 'custom-control'
                        div.innerHTML = `
                        
                        <div id="geoaction_card" class="card rounded-none h-100 d-block" style="width: 15rem;">
                            <div class="card-body border d-flex align-items-center justify-content-center">
                              
                                <div class="btn-group rounded-none" role="group" aria-label="Basic example">
                                <button id="geobtn_reset" disabled type="button" class="btn btn-primary rounded-none">Reset</button>
                                <button id="geobtn_clear"  disabled type="button" class="btn btn-primary rounded-none">Clear</button>
                                <button id="geobtn_submit" disabled type="button" class="btn btn-secondary rounded-none">Submit</button>
                                </div>

                            </div>
                        </div>
                        
                        `; // Set the content of the control
                        return div;
                    };

                    // Add the control to the map
                    customControl.addTo(mom.th_geo_map);

                    L.DomUtil.addClass(mom.th_geo_map._container, 'crosshair-cursor-enabled');


                    mom.th_geo_areagroup = L.layerGroup();
                    mom.th_geo_map.addLayer(mom.th_geo_areagroup);


                    $("#geoaction_card").on("click", (e) => {
                        e.stopPropagation();
                    });
                    $("#geobtn_reset").on("click", (e) => {
                        e.stopPropagation();
                        this.geo_reset()
                    });
                    $("#geobtn_clear").on("click", (e) => {
                        e.stopPropagation();
                        this.geo_clear()
                    });
                    $("#geobtn_submit").on("click", (e) => {
                        e.stopPropagation();
                        this.geo_submit()
                    });


                } else {
                    mom.th_geo_map.invalidateSize();
                }

            }, 2000);

        },
        //------------------------------------------------------------------



        //MOVING MARKER TRACKING ANIMATION
        //------------------------------------------------------------------
        //Fetch Tracking 24 Hour Marker Clicked
        moveTracking(data_tracking) {
            clearInterval(this.interval_tracking);
            this.tracking_path = null;
            this.tracking_latLng = null;
            this.tracking_rotation = null;
            console.log("MASUK KE MOVE TRACKING");
            console.log(data_tracking);
            this.marker_tracking = data_tracking;
            this.tracking_layer.clearLayers();
            this.target_layer.clearLayers();
            this.moving_marker = null;

            if (data_tracking.length > 0) {
                this.moving_marker = L.marker([data_tracking[0].lat, data_tracking[0].lng], {
                    icon: this.return_track_icon(),
                    rotationAngle: data_tracking[0].heading,
                }).addTo(this.target_layer);

                var firstpolyline = new L.Polyline(data_tracking, {
                    color: '#FEE800',
                    weight: 6,
                    opacity: 0.5,
                    smoothFactor: 1
                });
                this.tracking_layer.addLayer(firstpolyline);
                var mom = this
                var i = data_tracking.length
                setTimeout(() => {
                    data_tracking.forEach(function (coord) {
                        i--
                        if (i != 0) {
                            if (mom.authStore.userAllowed && !mom.authStore.userAllowed.allowed['hide marker dot']) {
                                var circle = L.circle([coord.lat, coord.lng], {
                                    color: "#1DC01D",
                                    fillColor: "#1DC01D",
                                    fillOpacity: 0.3,
                                    opacity: 0.6,
                                    weight: 2,
                                    radius: 1600,
                                }).bindPopup(
                                    "<table><tr><td><b>Latitude</b></td><td>:</td><td>" +
                                    coord.lat +
                                    "</td></tr><tr><td><b>Longitude</b></td><td>:</td><td>" +
                                    coord.lng +
                                    "</td></tr><tr>" +
                                    "<td><b>DMS</b></td><td>:</td>" +
                                    "<td>" +
                                    coord.dms +
                                    "</td>" +
                                    "</tr>" +
                                    "<tr>" +
                                    "<td><b>Speed</b></td>" +
                                    "<td>:</td>" +
                                    " <td>" +
                                    coord.speed +
                                    "&nbsp;knots </td>" +
                                    "</tr>" +
                                    "<tr>" +
                                    " <td><b>Heading</b></td>" +
                                    "<td>:</td>" +
                                    "<td>" +
                                    coord.heading +
                                    "</td>" +
                                    " </tr>" +
                                    "<tr>" +
                                    "<td><b>Last Update</b></td>" +
                                    "<td>:</td>" +
                                    "<td>" +
                                    coord.updated +
                                    "</td>" +
                                    "</tr>" +
                                    "</table>"
                                ).addTo(mom.target_layer)
                            }
                        }
                    });
                }, 1600);
                this.initialdata_moving();
                this.movingStart();
            }


        },
        //Initialize Data Moving Marker
        initialdata_moving() {
            this.tracking_path = this.marker_tracking.slice()
            if (this.tracking_path[0]) {
                this.tracking_latLng = L.latLng([this.tracking_path[0].lat, this.tracking_path[0].lng])
                this.tracking_rotation = this.tracking_path[0].heading
            }

        },
        //Start Moving 
        movingStart() {
            var mother = this
            this.interval_tracking = setInterval(function () {
                mother.simulate()
            }, 250);
        },
        //Simulate
        simulate() {
            if (this.tracking_path && this.tracking_path.length > 0) {
                let point = this.tracking_path.shift()
                this.tracking_latLng = L.latLng([point.lat, point.lng])
                this.tracking_rotation = point.heading
                this.moving_marker.setLatLng(this.tracking_latLng)
                this.moving_marker.slideTo(this.tracking_latLng, {
                    duration: 500,
                });
                this.moving_marker.options.rotationAngle = this.tracking_rotation;
            } else {
                this.target_layer.removeLayer(this.moving_marker);
                clearInterval(this.interval_tracking);
                this.tracking_path = null;
            }

        },
        //------------------------------------------------------------------


        //CHECK INPUT FUNCTIONS
        //------------------------------------------------------------------
        check_interval_input() {

            if (this.th_intervalconfig_interval > 14400) {
                this.th_intervalconfig_interval = 14400
            }

            if (this.th_intervalconfig_interval < 1) {
                this.th_intervalconfig_interval = 1
            }

        },
        onchange_sosenabled() {

            if (this.th_sosconfig_data.sosTestEnabled) {
                $('#sosTestEnabled_label').html('[Enabled]');
            } else {
                $('#sosTestEnabled_label').html('[Disabled]');
            }

            this.post_sos_enabled()
        },
        //------------------------------------------------------------------


        // GEOFENCING FUNCTIONS
        //------------------------------------------------------------------
        close_geofencing() {
            this.th_geo_data = null
            this.th_geo_map = null
            this.th_geo_tab_index = 0
            this.th_geo_area_index = 0
            this.th_geo_loadingarea = false
        },
        draw_polygon(latlngs) {
            var latlngs_ = [];
            var mom = this;
            if (latlngs && latlngs.length > 0) {
                if (this.th_geo_map) {

                    this.th_geo_map.setView(new L.LatLng(latlngs[0].lat, latlngs[0].lng), 4);
                    this.th_geo_areagroup.clearLayers();

                    for (let i = 0; i < latlngs.length; i++) {
                        latlngs_.push([latlngs[i].lat, latlngs[i].lng])
                        var marker = L.marker([latlngs[i].lat, latlngs[i].lng], {
                            draggable: 'true',
                            icon: this.return_icon_geo((i + 1)),
                        });
                        marker.on('dragend', function (event) {

                            var marker = event.target;
                            var position = marker.getLatLng();
                            console.log(position);
                            mom.on_dragend(position, i)
                        });
                        this.th_geo_areagroup.addLayer(marker)
                    }

                    var polygon = L.polygon(latlngs_, { color: 'red' })
                    polygon.addTo(this.th_geo_areagroup);

                }
            } else {
                if (this.th_geo_areagroup) {
                    this.th_geo_areagroup.clearLayers()
                }

            }


        },
        draw_polygon_onclick(latlngs) {
            var mom = this;
            if (latlngs && latlngs.length > 0) {
                if (this.th_geo_map) {
                    this.th_geo_areagroup.clearLayers();
                    for (let i = 0; i < latlngs.length; i++) {
                        var marker = L.marker([latlngs[i][0], latlngs[i][1]], {
                            draggable: 'true',
                            icon: this.return_icon_geo((i + 1)),
                        });
                        marker.on('dragend', function (event) {

                            var marker = event.target;
                            var position = marker.getLatLng();
                            console.log(position);
                            mom.on_dragend(position, i)
                        });
                        this.th_geo_areagroup.addLayer(marker)
                    }
                    var polygon = L.polygon(latlngs, { color: 'red' })
                    polygon.addTo(this.th_geo_areagroup);

                }


                $('#geobtn_reset').prop("disabled", false)
                $('#geobtn_clear').prop("disabled", false)

                if (
                    latlngs.length > 2
                ) {
                    $('#geobtn_submit').prop("disabled", false)
                    $('#geobtn_submit').removeClass('btn-secondary')
                    $('#geobtn_submit').addClass('btn-primary')
                }

            } else {
                if (this.th_geo_areagroup) {
                    this.th_geo_areagroup.clearLayers()
                }

            }


        },
        on_dragend(position, index) {
            this.th_geo_area_arr[index][0] = position.lat
            this.th_geo_area_arr[index][1] = position.lng
            this.draw_polygon_onclick(this.th_geo_area_arr)
        },
        change_cbValue(tab, area, id) {
            console.log(this.th_geo_data.cbValue[tab][area]);
            console.log($(id)[0].checked)
            if ($(id)[0].checked) {
                this.th_geo_data.cbValue[tab][area] = 1;
            } else {
                this.th_geo_data.cbValue[tab][area] = 0;
            }
            console.log(this.th_geo_data.cbValue[tab][area]);
        },
        geo_reset() {
            console.log(this.th_geo_area_data);
            this.th_geo_area_arr = []

            this.draw_polygon(this.th_geo_area_data.polyPath)

            if (this.th_geo_area_data.polyPath.length > 0) {
                for (let i = 0; i < this.th_geo_area_data.polyPath.length; i++) {
                    this.th_geo_area_arr.push([this.th_geo_area_data.polyPath[i].lat, this.th_geo_area_data.polyPath[i].lng])
                }
            }

            if (this.th_geo_area_data.polyPath.length == 0) {
                $('#geobtn_clear').prop("disabled", true)
                $('#geobtn_submit').prop("disabled", true)
                $('#geobtn_submit').removeClass('btn-primary')
                $('#geobtn_submit').addClass('btn-secondary')

            } else {
                $('#geobtn_clear').prop("disabled", false)
                $('#geobtn_submit').prop("disabled", false)
                $('#geobtn_submit').removeClass('btn-secondary')
                $('#geobtn_submit').addClass('btn-primary')
            }
            $('#geobtn_reset').prop("disabled", true)
        },
        geo_clear() {
            console.log("ONCLICK CLEAR");

            this.th_geo_areagroup.clearLayers()
            this.th_geo_area_arr = []
            $('#geobtn_submit').prop("disabled", true)
            $('#geobtn_submit').removeClass('btn-primary')
            $('#geobtn_submit').addClass('btn-secondary')
            if (this.th_geo_area_arr.length == 0) {
                $('#geobtn_clear').prop("disabled", true)
            }
            if (this.th_geo_area_data.polyPath.length == 0) {
                $('#geobtn_reset').prop("disabled", true)
            } else {
                $('#geobtn_reset').prop("disabled", false)
            }
        },
        geo_submit() {
            console.log("ONCLICK SUBMIT");
            this.post_geo_config();
        },
        //------------------------------------------------------------------

        // UNINITIALIZE DATA WHEN LOGOUT
        //------------------------------------------------------------------
        close_speedhistory(){
            if(this.speedhistory_table != null){
                this.speedhistory_table.clear();
                this.speedhistory_table = null;
                console.log("HISTORY TABLE IS CLEARED");
            }
            this.speedhistory_data = null;
        },
        clear_all_th_notif(){
            this.speedhistory_notifarr = []
            this.notification_store.clear_notif(this.speedhistory_data.notifications)
            var notif_data = this.speedhistory_data.notifications
            for (let i = 0; i < notif_data.length; i++) {
                notif_data[i].unRead = false
            }
            this.reinit_speedhistory(notif_data)
        },
        clear_th_notif(){
            var mom = this;
            this.notification_store.clear_selected_notif(this.speedhistory_notifarr)
            var notif_data = this.speedhistory_data.notifications
            for (let i = 0; i < notif_data.length; i++) {
                if(mom.speedhistory_notifarr.includes(notif_data[i].msg_id.toString())){
                    notif_data[i].unRead = false
                }
            }
            this.speedhistory_notifarr = []
            this.reinit_speedhistory(notif_data);
        },
        reinit_speedhistory(notif_data){
            this.speedhistory_table.clear();
            this.speedhistory_table = null;
            this.initTable_history(notif_data);
        },
        oncheck_notif(id){
            if($('#checknotif_'+id).prop('checked')){
                console.log("CHECK NOTIF TRUE")
                this.speedhistory_notifarr.push(id);
            }else{
                console.log("CHECK NOTIF FALSE")
                this.speedhistory_notifarr = this.speedhistory_notifarr.filter(e => e !== id);
            }
        },
        //Uninit Data 
        uninit_data() {
            this.thuraya_table = null;
            this.thurayauser_table = null;
            this.thurayatrack_table = null;
            this.thurayaCDR_table = null;
            this.is_showonmap = null;
            this.search_thuraya = null;
            this.search_thurayauser = null;
            this.search_thurayatrack = null;
            this.search_thurayacdr = null;
            this.thuraya_data = null;
            this.chatroom_data = null;
            this.chatroom_from = null;
            this.chatroom_to = null;
            this.chatroom_sending = false;
            this.chatroom_id = null;
            this.chatroom_thurayanumber = null;
            this.chatroom_body = '';
            this.chatroom_interval = null;
            this.chatroom_search = '';
            this.chatroom_messages = null;
            this.call_data = null;
            this.call_item = null;
            this.call_totalrounded = 0;
            this.call_connected = false;
            this.cdr_data = null;
            this.cdr_item = null;
            this.cdr_filter = 'all';
            this.cdr_range = 'month';
            this.cdr_rangelabel = 'This Month';
            this.last_center = [-1.4312313766684295, 119.1498789812993];
            this.last_zoom = 6;
            this.mapthuraya_data = null;
            this.mapthuraya_data_date = null;
            this.page_off = 10;
            this.page_off_tracking = 10;
            this.page_off_cdr = 10;
            this.show_range = "Last 24 Hours";
            this.export_range = "24";
            this.mapthuraya_id = null;
            this.loading_range = false;
            this.tracking_layer = L.layerGroup();
            this.target_layer = L.layerGroup();
            this.link_layer = L.layerGroup();
            this.search_layer = L.layerGroup();
            this.marker_tracking = [];
            this.moving_marker = null;
            this.tracking_path = null;
            this.tracking_latLng = null;
            this.tracking_rotation = null;
            this.interval_tracking = null;
            this.thuraya_map = null;
            this.date_range = null;
            this.isBottom = false;
            this.change_vesselname = null;
            this.changevesselname_data = null;
            this.change_vesselid = null;
            this.loading_changename = false;
            this.cdr_chart = null;
            this.range_arr = [
                { label: "Today", val: "today" },
                { label: "Last 24 Hours", val: "24" },
                { label: "Last 7 Days", val: "7" },
                { label: "Last 30 Days", val: "30" },
                { label: "This Week", val: "week" },
                { label: "This Month", val: "month" },
                { label: "Last Month", val: "lmonth" },
                { label: "Custom Range", val: "24" },
            ];
            this.cdrrange_arr = [
                { label: "Today", val: "today" },
                { label: "This Week", val: "week" },
                { label: "This Month", val: "month" },
                { label: "Last Month", val: "lmonth" },
                { label: "Custom Range", val: "24" },
            ];

            this.page_off_arr = [
                10,
                25,
                50,
                100
            ];

            this.th_thuraya_arr = [
                "No",
                "Vessel Name",
                "Company",
                "Group",
                "Thuraya Number",
                "",
                "",
                "",
                "",
                ""
            ];
            this.th_thuraya_track_arr = [
                "#",
                "Latitude,Longitude",
                "DMS",
                "Speed\n(Knots)",
                "Heading\n(Degree)",
                "Distance\n(Nmi)",
                "Date/Time\n(UTC)",
                "Date/Time\n(GMT+7)"
            ];
            this.th_thuraya_user_arr = [
                "#",
                "Name",
                "Email",
                "Role",
                "Last Login"
            ];

            this.th_thuraya_cdr_arr = [
                "Services",
                "MSISDN",
                "Type",
                "Plcode",
                "Network",
                "Start_Date",
                "Called_Party",
                "Duration",
                "Band",
                "Amount",
                "DateTime Local"
            ];

            this.td_button = [
                { a: 'text', b: '' },
                { a: 'text', b: 'btn-primary' },
                { a: 'text', b: '' },
                { a: 'text', b: '' },
                { a: 'text', b: '' },
                { a: 'button_config', b: 'btn-primary' },
                { a: 'button_sos', b: 'btn-danger' },
                { a: 'button_tracking', b: 'btn-secondary' },
                { a: 'button_cdr', b: 'btn-success' },
                { a: 'button_showonmap', b: 'btn-primary' }
            ];
            this.td_thuraya_arr = [
                5,
                15.5,
                15.5,
                8.5,
                18.5,
                8.5,
                5.5,
                7.5,
                5.5,
                8.5,
            ];
            this.td_thuraya_track_arr = [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
            ];

            this.td_thuraya_cdr_arr = [
                9,
                9,
                9,
                9,
                9,
                9,
                9,
                9,
                9,
                9,
                9,
            ];
        }
        //------------------------------------------------------------------



    }
})
