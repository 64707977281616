import { useNavplusStore, useMapStore, useAuthStore, useOrbCommStore } from '@/stores';
import $ from 'jquery'
import router from '@/router'
import {
  LMap,
  LPopup,
  LControl,
  LTileLayer,
  LControlZoom,
  LGeoJson,
  LPolygon,

} from "vue2-leaflet";
import LControlFullscreen from "vue2-leaflet-fullscreen";
import LControlPolylineMeasure from "vue2-leaflet-polyline-measure";
import Vue2LeafletRotatedMarker from "vue2-leaflet-rotatedmarker";
import MarkerDrawer from '@/components/sidebars/MarkerDrawer.vue';
import SearchVessel from '@/components/sidebars/SearchVessel.vue';



export default {
  name: "navplus" ,
  components: {
    LMap,
    LTileLayer,
    LPopup,
    LControlZoom,
    LControl,
    LGeoJson,
    LPolygon,
    LControlPolylineMeasure,
    LControlFullscreen,
    "v-rotated-marker": Vue2LeafletRotatedMarker,
    MarkerDrawer,
    SearchVessel,
  },
  data() {
    return {
      url: "https://maps.skyreach.id/sailink/{z}/{x}/{y}.png",
      navplusStore: useNavplusStore(),
      mapStore: useMapStore(),
      authStore: useAuthStore(),
      orbcommStore: useOrbCommStore(),
      unit: "nauticalmiles",
      selectDateBmkg: 0,
      weatherCode: {
        Cerah: "Clear",
        "Cerah Berawan": "Mostly Clear",
        Berawan: "Partly Cloudy",
        "Berawan Tebal": "Mostly Cloudy",
        Asap: "Smoke",
        Kabut: "Fog",
        "Hujan Ringan": "Light Rain",
        "Hujan Sedang": "Moderate Rain",
        "Hujan Lebat": "Heavy Rain",
        "Hujan Lokal": "Local Rain",
        "Hujan Badai": "Rain Storm",
        "No Data": "No Data",
      },
      windCode: {
        Utara: "N",
        "Utara Timur Laut": "NNE",
        "Timur Laut": "NE",
        "Timur Timur Laut": "ENE",
        Timur: "E",
        "Timur Tenggara": "ESE",
        Tenggara: "SE",
        "Selatan Tenggara": "SSE",
        Selatan: "S",
        "Selatan Barat Daya": "SSW",
        "Barat Daya": "SW",
        "Barat Barat daya": "WSW",
        Barat: "W",
        "Barat Barat Laut": "WNW",
        "Barat Laut": "NW",
        "Utara Barat Laut": "NNW",
      },
      waveCode: {
        Tenang: "Calm/Smooth",
        Rendah: "Slight",
        Sedang: "Moderate",
        Tinggi: "Rough",
        "Sangat Tinggi": "Very rough",
        Ekstrem: "High",
        "Sangat Ekstrem": "Very high",
      },

    }
  },
  mounted() {
    console.log("MASUK NAVPLUS");
    this.navplusStore.check_premission();
    this.initialized();
    this.$root.$on('invalidateMap', () => {
      this.invalidateMap()
    })
  },

  methods: {
    invalidateMap() {
      console.log("INVALIDATE CLICK");
      setTimeout(() => {
        this.$refs.myMap.mapObject.invalidateSize();
      }, 1000);

    },

    initialized() {
      console.log("INITIALIZED 1");
      var root = this

      this.navplusStore.initializeMap(this.$refs.myMap.mapObject);


      this.navplusStore.sidebar.on('hidden', function () {
        console.log("Vessel Sidebar Close");
        root.navplusStore.targetLayer.clearLayers();
        root.navplusStore.trackingLayer.clearLayers();
        root.navplusStore.markerClicked = null;
      });

      console.log("INITIALIZED 2");

      if (this.navplusStore.markerClicked) {
        this.navplusStore.toggle_sidebar(this.navplusStore.markerClicked, this.navplusStore.markerType)
      }

      if (this.navplusStore.searchbarClicked) {
        this.navplusStore.toggle_searchbar()
      }

      console.log("INITIALIZED 3");

      if (this.authStore.userAllowed && this.authStore.userAllowed.allowed['show marker alert']) {
        this.navplusStore.markerBlink = 1;
        this.navplusStore.intervalBlink = setInterval(() => {
          if (root.authStore.userAllowed.allowed['show marker alert']) {
            root.navplusStore.markerBlink = !root.navplusStore.markerBlink
          }
        }, 1000);
      } else {
        this.navplusStore.markerBlink = 0;
        clearInterval(this.navplusStore.intervalBlink);
      }

      console.log("INITIALIZED 4");

      if ((!this.navplusStore.sailink_vessels || !this.navplusStore.thuraya_vessels) || !this.navplusStore.iot_inactive || !this.navplusStore.waveData) {
        console.log("FETCH PERTAMA");
        this.navplusStore.fetch_navplus();
      }
    },

    update_slider() {
      this.$refs.myGeoJson.optionsStyle = {
        fillOpacity: this.navplusStore.slider / 100,
        opacity: 0
      }
      $("#slider_val").html(this.navplusStore.slider)
    },
    update_center(center) {
      this.mapStore.last_center = [center.lat, center.lng]
    },
    update_zoom(zoom) {
      this.mapStore.last_zoom = zoom
    },
    toogle_measure(e) {
      if (e.sttus) {
        $('#polyline-measure-control').addClass('actif_measure')
      } else {
        $('#polyline-measure-control').removeClass('actif_measure')
      }
    },



    dateDiffInDays(marker, updated) {
      const _MS_PER_DAY = 1000 * 60 * 60 * 24;
      const _MS_PER_HOUR = 1000 * 60 * 60;
      const _MS_PER_MINUTE = 1000 * 60;

      var last_up = marker[updated].split(" ", 2).join("T");

      var date1 = new Date(last_up);
      var date2 = new Date();

      var a = date1;
      var b = date2;

      var utc1 = Date.UTC(
        a.getFullYear(),
        a.getMonth(),
        a.getDate(),
        a.getHours(),
        a.getMinutes(),
        a.getSeconds()
      );
      var utc2 = Date.UTC(
        b.getFullYear(),
        b.getMonth(),
        b.getDate(),
        b.getHours(),
        b.getMinutes(),
        b.getSeconds()
      );

      var m = Math.floor((utc2 - utc1) / _MS_PER_MINUTE);
      var diff = m + " Minutes Ago";

      if (m > 60) {
        var h = Math.floor((utc2 - utc1) / _MS_PER_HOUR);
        diff = h + " Hours Ago";

        if (h > 24) {
          var d = Math.floor((utc2 - utc1) / _MS_PER_DAY);
          diff = d + " Days Ago";
        }
      }

      return diff;
    },

    return_bmkgstyle() {
      var style;
      if (!this.navplusStore.markerClicked && this.navplusStore.searchbarClicked) {
        style = 'left:0%;width:50vw'
      } else if (this.navplusStore.markerClicked && !this.navplusStore.searchbarClicked) {
        style = 'left: 0%;width:50vw'
      } else if (this.navplusStore.markerClicked && this.navplusStore.searchbarClicked) {
        style = 'left:0%;width:10vw'
      } else {
        style = 'left: 22%;width:50vw'
      }
      return style;
    },



 

  },

  computed: {
    optionsBmkg() {
      return {
        onEachFeature: this.onEachFeatureFunction,
      };
    },

    onEachFeatureFunction() {
      var color = "gray";
      var wave = "";
      var changeDate = this.selectDateBmkg;
      var home = this;

      return (feature, layer) => {
        var WP_1 = feature.properties.WP_1;
        var WP_IMM = feature.properties.WP_IMM;
        var link =
          "https://peta-maritim.bmkg.go.id/public_api/perairan/" +
          WP_1 +
          "_" +
          WP_IMM +
          ".json";

        $.ajax({
          headers: { "Accept": "application/json" },
          type: 'GET',
          url: link,
          crossDomain: true,
          beforeSend: function (xhr) {
            xhr.withCredentials = true;
          },
          success: function (area) {
            let valid_from = new Date(area.data[changeDate].valid_from);
            let valid_to = new Date(area.data[changeDate].valid_to);
            let weatherCat =
              home.weatherCode[
              area.data[changeDate].weather == null
                ? "No Data"
                : area.data[changeDate].weather
              ];
            let windFrom = home.windCode[area.data[changeDate].wind_from];
            let windTo = home.windCode[area.data[changeDate].wind_to];
            let waveCat = home.waveCode[area.data[changeDate].wave_cat];
            var content = "";
            content += "<div>";
            content +=
              '<p style="padding:0;white-space: initial;"><strong><b>' +
              area.name +
              "</b></strong></p>";
            content +=
              '<table  style="border-collapse: collapse;text-align:center;">';
            content += '<tr class="bold">';
            content += "<td >Valid from</td>";
            content +=
              "<td>: " +
              valid_from.toLocaleDateString("en-GB", {
                timeZone: "Asia/Jakarta",
              }) +
              " " +
              valid_from.toLocaleTimeString("en-GB", {
                timeZone: "Asia/Jakarta",
              }) +
              " ICT</td>";
            content += "</tr>";
            content += '<tr class="bold">';
            content += "<td>Valid to</td>";
            content +=
              "<td>: " +
              valid_to.toLocaleDateString("en-GB", { timeZone: "Asia/Jakarta" }) +
              " " +
              valid_to.toLocaleTimeString("en-GB", { timeZone: "Asia/Jakarta" }) +
              " ICT</td>";
            content += "</tr>";
            content += "</table>";
            content += "<br>";
            content +=
              '<table class="mt-2" style="background-color:#F7F7F7; border: 1px solid #fff;">';
            content += '<tr class="middle">';
            content +=
              "<td style='padding:1px;border: 1px solid #fff;'><strong><i class='bx bx-cloud'></i>&nbsp;&nbsp;Weather</strong></td>";
            content +=
              "<td style='padding:1px;border: 1px solid #fff;'><strong><i class='bx bx-wind'></i>&nbsp;&nbsp;Wind</strong></td>";
            content +=
              "<td style='padding:1px;border: 1px solid #fff;'><strong><i class='bx bx-water'></i>&nbsp;&nbsp;Waves</strong></td>";
            content += "</tr>";
            content += '<tr class="middle bold">';
            content +=
              '<td style="padding: 2px;border: 1px solid #fff;">' +
              weatherCat +
              "</td>";
            content += '<td style="padding: 2px;border: 1px solid #fff;">';
            content +=
              area.data[0].wind_speed_min + " - " + area.data[0].wind_speed_max;
            content += "<br>";
            content += windFrom + " -> " + windTo;
            content += "</td>";
            content += '<td style="padding: 2px;border: 1px solid #fff;">';
            content += waveCat;
            content += "<br>";
            content += area.data[0].wave_desc;
            content += "</td>";
            content += "</tr>";
            content += "</table>";
            content += "</div>";
            layer.bindPopup(content, { permanent: false, sticky: true })
          },
          error: function (data) {

          }
        });

        if (changeDate == 0) {
          wave = this.navplusStore.waveData[WP_1].today;
        } else if (changeDate == 1) {
          wave = this.navplusStore.waveData[WP_1].tommorow;
        } else if (changeDate == 2) {
          wave = this.navplusStore.waveData[WP_1].h2;
        } else if (changeDate == 3) {
          wave = this.navplusStore.waveData[WP_1].h3;
        }
        if (wave == "Tenang") {
          color = "#718BEB";
        }
        if (wave == "Rendah") {
          color = "#79D057";
        }
        if (wave == "Sedang") {
          color = "#6A808C";
        }
        if (wave == "Tinggi") {
          color = "#E37833";
        }
        if (wave == "Sangat Tinggi") {
          color = "#B1303B";
        }
        if (wave == "Ekstrem") {
          color = "#CF3BC9";
        }
        if (wave == "Sangat Ekstrem") {
          color = "#000000";
        }
        layer.setStyle({ fillColor: color });
      };
    },
  }
}
