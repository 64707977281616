import { useCrewwatchStore, useEtaStore } from '@/stores';
import  ShowCrew from '@/views/showonmap/ShowCrew_view.vue';
import $ from "jquery";

export default {
  name: "Crewwatch",
  components: {
    ShowCrew,
  },
  data() {
    return {
      crewwatch_store: useCrewwatchStore(),
      ShowCrewComp: ShowCrew,
    }
  },

  mounted() {
    this.crewwatch_store.fetch_crewwatch()
    var mother = this

    // window.showonmap_iridium = function (id) {
    //    mother.iridium_store.showonMap(id);
    // }

    // window.onchange_click = function (id,index) {
    //   mother.iridium_store.onchange_click(id,index);
    // }

    // window.roledown_click= function (id,index,value) {
    //   mother.iridium_store.roledown_click(id,index,value);
    // }

    // $(window).scroll(function () {
    //   if ($(window).scrollTop() + $(window).height() > $(document).height() - 100) {
    //     mother.iridium_store.isBottom = true
    //   } else {
    //     mother.iridium_store.isBottom = false
    //   }
    // });
  },

  methods: {

  },

  watch: {
  },
  computed: {
  }
};