import Pusher from 'pusher-js';

import {PUSHER_HOST, PUSHER_APP_KEY} from '../config'

const pusher = new Pusher(PUSHER_APP_KEY, {
    cluster: 'local',
    wssHost: PUSHER_HOST,
    wsHost: PUSHER_HOST,
    httpHost: PUSHER_HOST,
    wssPort: 443,
    wsPort: 80,
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
    forceTLS: true,
});

pusher.logToConsole = true;

pusher.connection.bind('error', function (err) {
    console.log(err);
});

pusher.connection.bind('state_change', function(states) {
    // states = {previous: 'oldState', current: 'newState'}
    console.log("current state is " + states.current);
});


// const channel = pusher.subscribe('trades');
// channel.bind("App\\Events\\NewTrade", (data) => {
//     console.log(" Web Socket ")
//     console.log(data);
// });
console.log("Initiate Pusher");


//Sailink
// var sailink_channel ="navplus.sailink.iot-status";
// var sailink_event = "IotStatusOnlyUpdated";

// var pusher_sailink = pusher.subscribe(sailink_channel);
//     pusher_sailink.bind('pusher:subscription_succeeded', function() {
//     console.log("Subscribed");
// });
  
// pusher_sailink.bind("App\\Events\\"+sailink_event, (data) => {
//     console.log(" Web Socket ")
//     console.log(data);
// });




//IoT Alert
// var channel ="navplus.sailink.iot-status";
// var event = "IotStatusOnlyUpdated";

// var pusher_listener = pusher.subscribe(channel);
//     pusher_listener.bind('pusher:subscription_succeeded', function() {
//     console.log("Subscribed");
// });
  
// pusher_listener.bind('pusher:subscription_error', function() {
//     console.log("Not Subscribed");
// });

// pusher_listener.bind("App\\Events\\"+event, (data) => {
//     console.log(" Web Socket ")
//     console.log(data);
// });

export default pusher;


//Channels