import { useCrewwatchStore } from '@/stores';

export default {
  name: "SohowCrew_plc",
  data() {
    return {
      crewwatch_store: useCrewwatchStore(),
    }
  },
  mounted() {
  },
  methods: {
  },
  watch: {
  },
  computed: {
  }
};