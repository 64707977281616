export var apstar92 = {
  c1: [
    { lat: 33.2201, lng: 62.587 },
    { lat: 33.2852, lng: 64.0823 },
    { lat: 33.3302, lng: 65.5782 },
    { lat: 33.3549, lng: 67.0746 },
    { lat: 33.3588, lng: 68.5714 },
    { lat: 33.3391, lng: 70.0678 },
    { lat: 33.2973, lng: 71.5637 },
    { lat: 33.2452, lng: 73.0596 },
    { lat: 33.1793, lng: 74.5549 },
    { lat: 33.0805, lng: 76.048 },
    { lat: 32.947, lng: 77.5385 },
    { lat: 32.778, lng: 79.0256 },
    { lat: 32.5727, lng: 80.5082 },
    { lat: 32.3222, lng: 81.9835 },
    { lat: 32.0133, lng: 83.4477 },
    { lat: 31.6444, lng: 84.898 },
    { lat: 31.2164, lng: 86.332 },
    { lat: 30.756, lng: 87.7561 },
    { lat: 30.2752, lng: 89.1733 },
    { lat: 29.7911, lng: 90.5896 },
    { lat: 29.3021, lng: 92.0042 },
    { lat: 28.794, lng: 93.4118 },
    { lat: 28.2882, lng: 94.8199 },
    { lat: 27.8384, lng: 96.2477 },
    { lat: 27.4605, lng: 97.6956 },
    { lat: 27.1705, lng: 99.1635 },
    { lat: 26.9842, lng: 100.649 },
    { lat: 27.0064, lng: 102.1376 },
    { lat: 27.255, lng: 103.6201 },
    { lat: 27.7173, lng: 105.041 },
    { lat: 28.3923, lng: 106.3689 },
    { lat: 29.2811, lng: 107.5753 },
    { lat: 30.2571, lng: 108.714 },
    { lat: 31.2198, lng: 109.849 },
    { lat: 32.0525, lng: 111.1223 },
    { lat: 33.0862, lng: 112.1751 },
    { lat: 34.4562, lng: 112.7269 },
    { lat: 35.8774, lng: 112.5099 },
    { lat: 37.2602, lng: 111.8039 },
    { lat: 38.5297, lng: 111.0245 },
    { lat: 39.7732, lng: 110.2481 },
    { lat: 41.1532, lng: 109.6125 },
    { lat: 42.6167, lng: 109.2668 },
    { lat: 43.9847, lng: 109.6719 },
    { lat: 45.2955, lng: 110.454 },
    { lat: 46.611, lng: 111.1775 },
    { lat: 48.0441, lng: 111.3925 },
    { lat: 49.5663, lng: 111.2412 },
    { lat: 51.079, lng: 111.1582 },
    { lat: 52.3364, lng: 111.6087 },
    { lat: 53.1985, lng: 112.8351 },
    { lat: 53.7273, lng: 114.3062 },
    { lat: 53.9476, lng: 115.8107 },
    { lat: 54.0287, lng: 117.3074 },
    { lat: 54.4398, lng: 118.6831 },
    { lat: 55.2076, lng: 119.9305 },
    { lat: 56.2569, lng: 120.9829 },
    { lat: 57.5382, lng: 121.798 },
    { lat: 58.8852, lng: 122.4943 },
    { lat: 60.1774, lng: 123.158 },
    { lat: 61.4774, lng: 123.8455 },
    { lat: 62.8367, lng: 124.6306 },
    { lat: 63.9012, lng: 125.6273 },
    { lat: 64.548, lng: 126.8751 },
    { lat: 64.8653, lng: 128.2548 },
    { lat: 64.9268, lng: 129.6665 },
    { lat: 64.7324, lng: 131.1104 },
    { lat: 64.2823, lng: 132.5863 },
    { lat: 63.6908, lng: 134.0424 },
    { lat: 63.0709, lng: 135.4276 },
    { lat: 62.4224, lng: 136.7417 },
    { lat: 61.6931, lng: 137.976 },
    { lat: 60.7526, lng: 139.1082 },
    { lat: 59.6051, lng: 140.1367 },
    { lat: 58.3845, lng: 141.054 },
    { lat: 57.132, lng: 141.856 },
    { lat: 55.7752, lng: 142.3901 },
    { lat: 54.2796, lng: 142.5676 },
    { lat: 52.7749, lng: 142.5186 },
    { lat: 51.2963, lng: 142.2757 },
    { lat: 49.8742, lng: 141.8439 },
    { lat: 48.514, lng: 141.2318 },
    { lat: 47.1492, lng: 140.6081 },
    { lat: 45.7517, lng: 140.0464 },
    { lat: 44.3106, lng: 139.7386 },
    { lat: 42.819, lng: 139.8015 },
    { lat: 41.3318, lng: 140.0839 },
    { lat: 39.8926, lng: 140.4591 },
    { lat: 38.4478, lng: 140.8366 },
    { lat: 36.9713, lng: 141.1252 },
    { lat: 35.4824, lng: 141.0895 },
    { lat: 34.0718, lng: 140.6392 },
    { lat: 32.9101, lng: 139.6918 },
    { lat: 31.6948, lng: 138.8211 },
    { lat: 30.3573, lng: 138.1598 },
    { lat: 28.9983, lng: 137.5294 },
    { lat: 27.67, lng: 136.8405 },
    { lat: 26.4009, lng: 136.0487 },
    { lat: 25.1849, lng: 135.1774 },
    { lat: 24.0238, lng: 134.2322 },
    { lat: 22.9261, lng: 133.2162 },
    { lat: 21.9014, lng: 132.1303 },
    { lat: 20.9162, lng: 130.9937 },
    { lat: 19.8803, lng: 129.9158 },
    { lat: 18.6911, lng: 129.0258 },
    { lat: 17.3915, lng: 128.2794 },
    { lat: 16.0315, lng: 127.6278 },
    { lat: 14.708, lng: 126.983 },
    { lat: 13.3089, lng: 126.4781 },
    { lat: 11.7734, lng: 126.1985 },
    { lat: 10.3062, lng: 126.3508 },
    { lat: 9.0032, lng: 127.0318 },
    { lat: 7.7582, lng: 127.8962 },
    { lat: 6.4068, lng: 128.4129 },
    { lat: 4.9937, lng: 128.7712 },
    { lat: 3.536, lng: 129.0431 },
    { lat: 2.0648, lng: 129.3135 },
    { lat: 0.6123, lng: 129.6702 },
    { lat: -0.8256, lng: 130.0888 },
    { lat: -2.2652, lng: 130.4693 },
    { lat: -3.7076, lng: 130.8266 },
    { lat: -5.1494, lng: 131.2782 },
    { lat: -6.595, lng: 131.7341 },
    { lat: -8.0594, lng: 131.8638 },
    { lat: -9.5413, lng: 131.7062 },
    { lat: -11.027, lng: 131.5957 },
    { lat: -12.5142, lng: 131.5717 },
    { lat: -14.0019, lng: 131.5282 },
    { lat: -15.4896, lng: 131.4367 },
    { lat: -16.9655, lng: 131.2351 },
    { lat: -18.4029, lng: 130.7859 },
    { lat: -19.8134, lng: 130.2455 },
    { lat: -21.2523, lng: 130.3411 },
    { lat: -22.7158, lng: 130.7573 },
    { lat: -24.1848, lng: 130.8569 },
    { lat: -25.6343, lng: 130.55 },
    { lat: -27.0449, lng: 129.9898 },
    { lat: -28.4051, lng: 129.3854 },
    { lat: -29.7725, lng: 128.7887 },
    { lat: -31.1516, lng: 128.193 },
    { lat: -32.4528, lng: 127.4683 },
    { lat: -33.6673, lng: 126.6057 },
    { lat: -34.8018, lng: 125.6238 },
    { lat: -35.8112, lng: 124.5141 },
    { lat: -36.6071, lng: 123.2585 },
    { lat: -37.2349, lng: 121.9 },
    { lat: -37.7612, lng: 120.4993 },
    { lat: -38.2586, lng: 119.0871 },
    { lat: -38.7873, lng: 117.6883 },
    { lat: -39.3019, lng: 116.2859 },
    { lat: -39.7214, lng: 114.8489 },
    { lat: -40.0487, lng: 113.3818 },
    { lat: -40.3071, lng: 111.9103 },
    { lat: -40.4973, lng: 110.4369 },
    { lat: -40.57, lng: 108.9418 },
    { lat: -40.5051, lng: 107.4185 },
    { lat: -40.1421, lng: 105.9875 },
    { lat: -39.3779, lng: 104.7287 },
    { lat: -38.2903, lng: 103.7142 },
    { lat: -37.0945, lng: 102.8146 },
    { lat: -36.2232, lng: 101.6027 },
    { lat: -35.4701, lng: 100.3022 },
    { lat: -34.7839, lng: 98.979 },
    { lat: -34.2255, lng: 97.5933 },
    { lat: -33.5998, lng: 96.2492 },
    { lat: -32.7694, lng: 95.0214 },
    { lat: -31.7906, lng: 93.8933 },
    { lat: -30.7891, lng: 92.7927 },
    { lat: -30.1635, lng: 91.4666 },
    { lat: -29.7685, lng: 90.0052 },
    { lat: -29.2981, lng: 88.5996 },
    { lat: -28.7789, lng: 87.2203 },
    { lat: -28.3149, lng: 85.7593 },
    { lat: -27.7835, lng: 84.3329 },
    { lat: -26.8288, lng: 83.2807 },
    { lat: -25.5209, lng: 82.7293 },
    { lat: -24.0592, lng: 82.6044 },
    { lat: -22.9703, lng: 81.7317 },
    { lat: -22.1193, lng: 80.3758 },
    { lat: -21.1802, lng: 79.2088 },
    { lat: -20.1501, lng: 78.1671 },
    { lat: -19.0301, lng: 77.1921 },
    { lat: -17.8323, lng: 76.2866 },
    { lat: -16.6423, lng: 75.3791 },
    { lat: -15.7589, lng: 74.2064 },
    { lat: -15.1466, lng: 72.8179 },
    { lat: -14.6439, lng: 71.4026 },
    { lat: -14.2401, lng: 69.9699 },
    { lat: -13.8913, lng: 68.5186 },
    { lat: -13.6038, lng: 67.0483 },
    { lat: -13.4693, lng: 65.5597 },
    { lat: -13.3971, lng: 64.0638 },
    { lat: -13.2896, lng: 62.571 },
    { lat: -13.1467, lng: 61.0813 },
  ],
  c2: [
    { lat: 30.0519, lng: 62.225 },
    { lat: 30.223, lng: 63.6977 },
    { lat: 30.4031, lng: 65.169 },
    { lat: 30.5919, lng: 66.6389 },
    { lat: 30.7897, lng: 68.1076 },
    { lat: 30.9984, lng: 69.5748 },
    { lat: 31.2197, lng: 71.0408 },
    { lat: 31.4184, lng: 72.5097 },
    { lat: 31.5775, lng: 73.9834 },
    { lat: 31.6844, lng: 75.4616 },
    { lat: 31.7317, lng: 76.9434 },
    { lat: 31.7133, lng: 78.4248 },
    { lat: 31.629, lng: 79.9048 },
    { lat: 31.4839, lng: 81.3798 },
    { lat: 31.2773, lng: 82.8482 },
    { lat: 30.9963, lng: 84.3026 },
    { lat: 30.642, lng: 85.7419 },
    { lat: 30.2426, lng: 87.1694 },
    { lat: 29.8096, lng: 88.5872 },
    { lat: 29.3741, lng: 90.004 },
    { lat: 28.9261, lng: 91.4167 },
    { lat: 28.4391, lng: 92.8174 },
    { lat: 27.937, lng: 94.2131 },
    { lat: 27.4838, lng: 95.6223 },
    { lat: 27.0783, lng: 97.0467 },
    { lat: 26.7129, lng: 98.4869 },
    { lat: 26.4584, lng: 99.9448 },
    { lat: 26.358, lng: 101.4217 },
    { lat: 26.4207, lng: 102.9 },
    { lat: 26.6532, lng: 104.3659 },
    { lat: 27.0994, lng: 105.7741 },
    { lat: 27.8164, lng: 107.0657 },
    { lat: 28.7233, lng: 108.2468 },
    { lat: 29.7251, lng: 109.335 },
    { lat: 30.6617, lng: 110.4835 },
    { lat: 31.5253, lng: 111.6997 },
    { lat: 32.5777, lng: 112.7317 },
    { lat: 33.8482, lng: 113.4341 },
    { lat: 35.2734, lng: 113.3727 },
    { lat: 36.7137, lng: 112.7819 },
    { lat: 37.9791, lng: 112.0661 },
    { lat: 39.2229, lng: 111.3351 },
    { lat: 40.6321, lng: 110.7174 },
    { lat: 42.0984, lng: 110.4283 },
    { lat: 43.3801, lng: 110.946 },
    { lat: 44.5654, lng: 111.8861 },
    { lat: 45.7687, lng: 112.7756 },
    { lat: 47.0829, lng: 113.284 },
    { lat: 48.5836, lng: 113.1528 },
    { lat: 50.0987, lng: 113.2447 },
    { lat: 51.3315, lng: 113.919 },
    { lat: 52.0638, lng: 115.1481 },
    { lat: 52.4929, lng: 116.5984 },
    { lat: 52.7395, lng: 118.066 },
    { lat: 52.8568, lng: 119.5389 },
    { lat: 53.2375, lng: 120.9304 },
    { lat: 53.9612, lng: 122.2229 },
    { lat: 54.9627, lng: 123.3509 },
    { lat: 56.1594, lng: 124.2314 },
    { lat: 57.416, lng: 125.0197 },
    { lat: 58.4923, lng: 125.9911 },
    { lat: 59.3212, lng: 127.1521 },
    { lat: 59.8649, lng: 128.5033 },
    { lat: 60.1372, lng: 129.9989 },
    { lat: 60.1862, lng: 131.4797 },
    { lat: 60.0158, lng: 132.9324 },
    { lat: 59.6342, lng: 134.3543 },
    { lat: 59.1054, lng: 135.7251 },
    { lat: 58.4436, lng: 137.0403 },
    { lat: 57.6335, lng: 138.2764 },
    { lat: 56.5615, lng: 139.2561 },
    { lat: 55.2176, lng: 139.9451 },
    { lat: 53.7872, lng: 140.3714 },
    { lat: 52.3351, lng: 140.5176 },
    { lat: 50.8819, lng: 140.2726 },
    { lat: 49.4582, lng: 139.819 },
    { lat: 48.0695, lng: 139.325 },
    { lat: 46.6611, lng: 138.8558 },
    { lat: 45.201, lng: 138.5927 },
    { lat: 43.7182, lng: 138.5657 },
    { lat: 42.2644, lng: 138.7609 },
    { lat: 40.8321, lng: 139.1368 },
    { lat: 39.3945, lng: 139.5548 },
    { lat: 37.9497, lng: 139.9536 },
    { lat: 36.5006, lng: 140.0081 },
    { lat: 35.0593, lng: 139.6729 },
    { lat: 33.7201, lng: 139.0538 },
    { lat: 32.4453, lng: 138.2959 },
    { lat: 31.0798, lng: 137.7252 },
    { lat: 29.6809, lng: 137.2217 },
    { lat: 28.3442, lng: 136.5837 },
    { lat: 27.0649, lng: 135.8341 },
    { lat: 25.835, lng: 135.0078 },
    { lat: 24.6539, lng: 134.1133 },
    { lat: 23.5333, lng: 133.1453 },
    { lat: 22.5108, lng: 132.0733 },
    { lat: 21.5358, lng: 130.9551 },
    { lat: 20.5501, lng: 129.8506 },
    { lat: 19.4381, lng: 128.8675 },
    { lat: 18.1938, lng: 128.0721 },
    { lat: 16.8578, lng: 127.4328 },
    { lat: 15.5168, lng: 126.7833 },
    { lat: 14.1457, lng: 126.2302 },
    { lat: 12.7155, lng: 125.8896 },
    { lat: 11.2298, lng: 125.7815 },
    { lat: 9.7637, lng: 125.9926 },
    { lat: 8.4667, lng: 126.6789 },
    { lat: 7.1871, lng: 127.437 },
    { lat: 5.8253, lng: 127.9604 },
    { lat: 4.332, lng: 128.0225 },
    { lat: 2.8919, lng: 128.3196 },
    { lat: 1.5175, lng: 128.8931 },
    { lat: 0.0888, lng: 129.2742 },
    { lat: -1.3471, lng: 129.6406 },
    { lat: -2.7681, lng: 130.0673 },
    { lat: -4.1873, lng: 130.4794 },
    { lat: -5.6133, lng: 130.8986 },
    { lat: -7.0535, lng: 131.2601 },
    { lat: -8.5192, lng: 131.3902 },
    { lat: -9.9825, lng: 131.2358 },
    { lat: -11.4401, lng: 130.9216 },
    { lat: -12.9167, lng: 130.8425 },
    { lat: -14.3978, lng: 130.7421 },
    { lat: -15.8728, lng: 130.5728 },
    { lat: -17.3286, lng: 130.3922 },
    { lat: -18.7367, lng: 129.9179 },
    { lat: -20.1119, lng: 129.3407 },
    { lat: -21.5335, lng: 129.5809 },
    { lat: -22.9832, lng: 129.9908 },
    { lat: -24.4356, lng: 129.9864 },
    { lat: -25.858, lng: 129.6446 },
    { lat: -27.2336, lng: 129.0359 },
    { lat: -28.5319, lng: 128.3168 },
    { lat: -29.7723, lng: 127.5205 },
    { lat: -31.0057, lng: 126.7054 },
    { lat: -32.2212, lng: 125.8553 },
    { lat: -33.4054, lng: 124.9514 },
    { lat: -34.4773, lng: 123.9341 },
    { lat: -35.3668, lng: 122.7555 },
    { lat: -36.1114, lng: 121.4825 },
    { lat: -36.7277, lng: 120.1365 },
    { lat: -37.2935, lng: 118.7612 },
    { lat: -37.8306, lng: 117.3692 },
    { lat: -38.3137, lng: 115.9674 },
    { lat: -38.7243, lng: 114.5609 },
    { lat: -39.0613, lng: 113.135 },
    { lat: -39.3218, lng: 111.6571 },
    { lat: -39.4939, lng: 110.1321 },
    { lat: -39.3263, lng: 108.6933 },
    { lat: -38.717, lng: 107.3947 },
    { lat: -37.7856, lng: 106.2446 },
    { lat: -36.6892, lng: 105.2541 },
    { lat: -35.6342, lng: 104.2362 },
    { lat: -34.8298, lng: 103.0002 },
    { lat: -34.1075, lng: 101.6968 },
    { lat: -33.4088, lng: 100.3783 },
    { lat: -32.5953, lng: 99.1579 },
    { lat: -31.5733, lng: 98.112 },
    { lat: -30.591, lng: 97.018 },
    { lat: -29.801, lng: 95.7487 },
    { lat: -29.1909, lng: 94.423 },
    { lat: -28.7352, lng: 93.0664 },
    { lat: -28.2985, lng: 91.6319 },
    { lat: -27.8547, lng: 90.1152 },
    { lat: -27.1731, lng: 88.8397 },
    { lat: -26.1082, lng: 88.0092 },
    { lat: -24.7934, lng: 87.4654 },
    { lat: -23.3564, lng: 87.0503 },
    { lat: -22.3366, lng: 86.0524 },
    { lat: -21.6274, lng: 84.6726 },
    { lat: -20.8472, lng: 83.453 },
    { lat: -19.9979, lng: 82.2291 },
    { lat: -19.0151, lng: 81.1166 },
    { lat: -17.8932, lng: 80.1489 },
    { lat: -16.827, lng: 79.1242 },
    { lat: -15.8636, lng: 77.9978 },
    { lat: -15.046, lng: 76.7668 },
    { lat: -14.3737, lng: 75.4433 },
    { lat: -13.8046, lng: 74.0758 },
    { lat: -13.3075, lng: 72.6799 },
    { lat: -12.8454, lng: 71.2708 },
    { lat: -12.446, lng: 69.8438 },
    { lat: -12.1509, lng: 68.3926 },
    { lat: -11.9225, lng: 66.9283 },
    { lat: -11.74, lng: 65.4577 },
    { lat: -11.6026, lng: 63.9825 },
    { lat: -11.5101, lng: 62.5034 },
    { lat: -11.4624, lng: 61.0203 },
  ],
  c3: [
    { lat: 25.707, lng: 61.8156 },
    { lat: 25.8777, lng: 63.2681 },
    { lat: 26.064, lng: 64.7183 },
    { lat: 26.266, lng: 66.1659 },
    { lat: 26.4854, lng: 67.6108 },
    { lat: 26.7806, lng: 69.0414 },
    { lat: 27.1797, lng: 70.4499 },
    { lat: 27.6796, lng: 71.8226 },
    { lat: 28.2342, lng: 73.1745 },
    { lat: 28.7226, lng: 74.5519 },
    { lat: 29.1546, lng: 75.9498 },
    { lat: 29.5562, lng: 77.3567 },
    { lat: 29.8838, lng: 78.7794 },
    { lat: 30.0759, lng: 80.2278 },
    { lat: 30.1349, lng: 81.6908 },
    { lat: 30.0659, lng: 83.1504 },
    { lat: 29.8849, lng: 84.5997 },
    { lat: 29.6056, lng: 86.0357 },
    { lat: 29.2746, lng: 87.4603 },
    { lat: 28.9107, lng: 88.8762 },
    { lat: 28.5448, lng: 90.2922 },
    { lat: 28.1361, lng: 91.696 },
    { lat: 27.6554, lng: 93.0787 },
    { lat: 27.1923, lng: 94.465 },
    { lat: 26.7619, lng: 95.8596 },
    { lat: 26.3629, lng: 97.2671 },
    { lat: 26.003, lng: 98.6894 },
    { lat: 25.7665, lng: 100.1284 },
    { lat: 25.6718, lng: 101.5826 },
    { lat: 25.7025, lng: 103.042 },
    { lat: 25.859, lng: 104.5043 },
    { lat: 26.2187, lng: 105.9154 },
    { lat: 26.818, lng: 107.2467 },
    { lat: 27.6166, lng: 108.4674 },
    { lat: 28.5661, lng: 109.5828 },
    { lat: 29.5694, lng: 110.6424 },
    { lat: 30.527, lng: 111.747 },
    { lat: 31.4076, lng: 112.9284 },
    { lat: 32.5285, lng: 113.8533 },
    { lat: 33.8859, lng: 114.3303 },
    { lat: 35.2841, lng: 114.1518 },
    { lat: 36.6691, lng: 113.577 },
    { lat: 37.9912, lng: 112.8837 },
    { lat: 39.2781, lng: 112.1675 },
    { lat: 40.6508, lng: 111.8449 },
    { lat: 42.0735, lng: 111.9916 },
    { lat: 43.247, lng: 112.7724 },
    { lat: 44.2157, lng: 114.004 },
    { lat: 45.4192, lng: 114.7047 },
    { lat: 46.8681, lng: 114.8738 },
    { lat: 48.3877, lng: 114.9833 },
    { lat: 49.6517, lng: 115.5142 },
    { lat: 50.4835, lng: 116.7022 },
    { lat: 50.9927, lng: 118.0985 },
    { lat: 51.2264, lng: 119.5115 },
    { lat: 51.3419, lng: 120.9557 },
    { lat: 51.4629, lng: 122.4423 },
    { lat: 51.6436, lng: 123.9351 },
    { lat: 52.278, lng: 125.1684 },
    { lat: 53.401, lng: 126.1136 },
    { lat: 54.5408, lng: 127.0338 },
    { lat: 55.5138, lng: 128.0514 },
    { lat: 56.1738, lng: 129.301 },
    { lat: 56.5137, lng: 130.7797 },
    { lat: 56.5432, lng: 132.2665 },
    { lat: 56.2664, lng: 133.6708 },
    { lat: 55.6791, lng: 134.9685 },
    { lat: 54.7777, lng: 136.1395 },
    { lat: 53.6117, lng: 137.0292 },
    { lat: 52.2415, lng: 137.4664 },
    { lat: 50.7874, lng: 137.609 },
    { lat: 49.3253, lng: 137.5048 },
    { lat: 47.9132, lng: 137.1173 },
    { lat: 46.4767, lng: 136.8675 },
    { lat: 45.0263, lng: 136.8572 },
    { lat: 43.5995, lng: 137.0781 },
    { lat: 42.1932, lng: 137.5048 },
    { lat: 40.7814, lng: 137.9286 },
    { lat: 39.3581, lng: 138.3012 },
    { lat: 37.9214, lng: 138.5123 },
    { lat: 36.4705, lng: 138.496 },
    { lat: 35.0482, lng: 138.2197 },
    { lat: 33.6736, lng: 137.6917 },
    { lat: 32.282, lng: 137.2293 },
    { lat: 30.8664, lng: 136.877 },
    { lat: 29.4883, lng: 136.3932 },
    { lat: 28.1677, lng: 135.7555 },
    { lat: 26.9241, lng: 134.9918 },
    { lat: 25.7281, lng: 134.153 },
    { lat: 24.5785, lng: 133.2486 },
    { lat: 23.5127, lng: 132.2472 },
    { lat: 22.5127, lng: 131.1808 },
    { lat: 21.5578, lng: 130.0787 },
    { lat: 20.5476, lng: 129.0168 },
    { lat: 19.4297, lng: 128.0709 },
    { lat: 18.1815, lng: 127.3292 },
    { lat: 16.8618, lng: 126.6966 },
    { lat: 15.503, lng: 126.1402 },
    { lat: 14.1345, lng: 125.6622 },
    { lat: 12.7177, lng: 125.3317 },
    { lat: 11.2366, lng: 125.22 },
    { lat: 9.8084, lng: 125.4247 },
    { lat: 8.4562, lng: 125.9291 },
    { lat: 7.1767, lng: 126.6701 },
    { lat: 5.8129, lng: 127.1348 },
    { lat: 4.3323, lng: 127.2243 },
    { lat: 2.9221, lng: 127.5759 },
    { lat: 1.5466, lng: 128.0612 },
    { lat: 0.1649, lng: 128.5353 },
    { lat: -1.2205, lng: 129.0053 },
    { lat: -2.6177, lng: 129.4344 },
    { lat: -4.0283, lng: 129.8125 },
    { lat: -5.4288, lng: 130.2366 },
    { lat: -6.8405, lng: 130.615 },
    { lat: -8.2841, lng: 130.8172 },
    { lat: -9.7243, lng: 130.6702 },
    { lat: -11.1589, lng: 130.3297 },
    { lat: -12.5955, lng: 130.072 },
    { lat: -14.0262, lng: 129.7946 },
    { lat: -15.459, lng: 129.5584 },
    { lat: -16.9193, lng: 129.5974 },
    { lat: -18.3164, lng: 129.2716 },
    { lat: -19.6352, lng: 128.5092 },
    { lat: -21.0228, lng: 128.4769 },
    { lat: -22.4564, lng: 128.8795 },
    { lat: -23.8798, lng: 129.0634 },
    { lat: -25.3101, lng: 128.9028 },
    { lat: -26.7186, lng: 128.3971 },
    { lat: -27.9747, lng: 127.6637 },
    { lat: -29.1088, lng: 126.7644 },
    { lat: -30.2361, lng: 125.8282 },
    { lat: -31.3323, lng: 124.8507 },
    { lat: -32.3578, lng: 123.8172 },
    { lat: -33.335, lng: 122.7383 },
    { lat: -34.3052, lng: 121.634 },
    { lat: -35.1879, lng: 120.4667 },
    { lat: -35.8951, lng: 119.1951 },
    { lat: -36.4614, lng: 117.8472 },
    { lat: -36.9491, lng: 116.4726 },
    { lat: -37.353, lng: 115.0711 },
    { lat: -37.6278, lng: 113.6331 },
    { lat: -37.7645, lng: 112.1567 },
    { lat: -37.6192, lng: 110.7252 },
    { lat: -36.9974, lng: 109.4508 },
    { lat: -36.0535, lng: 108.2854 },
    { lat: -35.2167, lng: 107.0946 },
    { lat: -34.472, lng: 105.867 },
    { lat: -33.7433, lng: 104.5961 },
    { lat: -32.9652, lng: 103.3552 },
    { lat: -32.0964, lng: 102.1915 },
    { lat: -31.1522, lng: 101.0833 },
    { lat: -30.1534, lng: 100.0133 },
    { lat: -29.3273, lng: 98.8301 },
    { lat: -28.718, lng: 97.5049 },
    { lat: -28.1623, lng: 96.1466 },
    { lat: -27.6327, lng: 94.7736 },
    { lat: -26.9795, lng: 93.4725 },
    { lat: -26.1131, lng: 92.3087 },
    { lat: -24.9417, lng: 91.6446 },
    { lat: -23.504, lng: 91.4887 },
    { lat: -22.4222, lng: 90.6925 },
    { lat: -21.6823, lng: 89.3019 },
    { lat: -20.9237, lng: 88.0315 },
    { lat: -20.132, lng: 86.852 },
    { lat: -19.3103, lng: 85.6486 },
    { lat: -18.4255, lng: 84.4673 },
    { lat: -17.3998, lng: 83.4222 },
    { lat: -16.3808, lng: 82.3785 },
    { lat: -15.5049, lng: 81.2103 },
    { lat: -14.6992, lng: 79.9896 },
    { lat: -13.9344, lng: 78.7467 },
    { lat: -13.2615, lng: 77.4385 },
    { lat: -12.5895, lng: 76.1431 },
    { lat: -11.8612, lng: 74.9014 },
    { lat: -11.2004, lng: 73.5522 },
    { lat: -10.375, lng: 72.3588 },
    { lat: -9.0833, lng: 71.6784 },
    { lat: -8.0131, lng: 70.8043 },
    { lat: -7.3236, lng: 69.5872 },
    { lat: -6.9675, lng: 68.1767 },
    { lat: -6.7735, lng: 66.6999 },
    { lat: -6.5723, lng: 65.2576 },
    { lat: -6.4043, lng: 63.8133 },
    { lat: -6.2819, lng: 62.356 },
    { lat: -6.2051, lng: 60.8856 },
  ],
  c4: [
    { lat: 18.8031, lng: 61.32 },
    { lat: 18.8944, lng: 62.7841 },
    { lat: 19.1529, lng: 64.1966 },
    { lat: 19.5785, lng: 65.5572 },
    { lat: 20.134, lng: 66.877 },
    { lat: 20.7336, lng: 68.181 },
    { lat: 21.371, lng: 69.4688 },
    { lat: 22.0442, lng: 70.7392 },
    { lat: 22.7697, lng: 71.9812 },
    { lat: 23.5518, lng: 73.1911 },
    { lat: 24.2964, lng: 74.4227 },
    { lat: 24.9873, lng: 75.6856 },
    { lat: 25.6972, lng: 76.9393 },
    { lat: 26.3869, lng: 78.2023 },
    { lat: 27.0252, lng: 79.4913 },
    { lat: 27.6324, lng: 80.8018 },
    { lat: 28.0865, lng: 82.1615 },
    { lat: 28.3326, lng: 83.5815 },
    { lat: 28.3893, lng: 85.0183 },
    { lat: 28.2665, lng: 86.45 },
    { lat: 28.0504, lng: 87.8759 },
    { lat: 27.8047, lng: 89.2954 },
    { lat: 27.5113, lng: 90.7039 },
    { lat: 27.1386, lng: 92.0936 },
    { lat: 26.7081, lng: 93.4689 },
    { lat: 26.2812, lng: 94.8429 },
    { lat: 25.8655, lng: 96.2208 },
    { lat: 25.4713, lng: 97.6091 },
    { lat: 25.1577, lng: 99.0124 },
    { lat: 24.9481, lng: 100.4317 },
    { lat: 24.8614, lng: 101.8662 },
    { lat: 24.8985, lng: 103.3147 },
    { lat: 25.0635, lng: 104.7455 },
    { lat: 25.3589, lng: 106.1442 },
    { lat: 25.8113, lng: 107.506 },
    { lat: 26.441, lng: 108.8114 },
    { lat: 27.2994, lng: 109.9544 },
    { lat: 28.3176, lng: 110.9749 },
    { lat: 29.3202, lng: 112.004 },
    { lat: 30.2644, lng: 113.092 },
    { lat: 31.1887, lng: 114.2004 },
    { lat: 32.3461, lng: 115.0595 },
    { lat: 33.6835, lng: 115.436 },
    { lat: 35.1031, lng: 115.14 },
    { lat: 36.4502, lng: 114.5728 },
    { lat: 37.7093, lng: 113.9432 },
    { lat: 39.0881, lng: 113.4769 },
    { lat: 40.545, lng: 113.2962 },
    { lat: 41.753, lng: 113.8631 },
    { lat: 42.761, lng: 114.9877 },
    { lat: 43.8518, lng: 115.9024 },
    { lat: 45.0865, lng: 116.5306 },
    { lat: 46.532, lng: 116.8367 },
    { lat: 47.8538, lng: 117.341 },
    { lat: 48.8022, lng: 118.4133 },
    { lat: 49.3774, lng: 119.724 },
    { lat: 49.5818, lng: 121.1496 },
    { lat: 49.5857, lng: 122.6034 },
    { lat: 49.4956, lng: 124.0309 },
    { lat: 49.3325, lng: 125.4403 },
    { lat: 49.2187, lng: 126.879 },
    { lat: 49.1745, lng: 128.355 },
    { lat: 49.0643, lng: 129.7945 },
    { lat: 48.6878, lng: 131.0882 },
    { lat: 47.9997, lng: 132.216 },
    { lat: 46.8582, lng: 133.115 },
    { lat: 45.5758, lng: 133.8773 },
    { lat: 44.3464, lng: 134.5658 },
    { lat: 43.0823, lng: 135.2158 },
    { lat: 41.7575, lng: 135.8375 },
    { lat: 40.3876, lng: 136.3128 },
    { lat: 38.9848, lng: 136.5512 },
    { lat: 37.559, lng: 136.5875 },
    { lat: 36.1261, lng: 136.4754 },
    { lat: 34.6889, lng: 136.2844 },
    { lat: 33.2533, lng: 136.1673 },
    { lat: 31.8235, lng: 136.0525 },
    { lat: 30.4076, lng: 135.7763 },
    { lat: 29.0455, lng: 135.3056 },
    { lat: 27.7989, lng: 134.5983 },
    { lat: 26.6193, lng: 133.7689 },
    { lat: 25.4715, lng: 132.9007 },
    { lat: 24.3657, lng: 131.9785 },
    { lat: 23.3154, lng: 130.993 },
    { lat: 22.3554, lng: 129.9279 },
    { lat: 21.4119, lng: 128.8391 },
    { lat: 20.3947, lng: 127.8042 },
    { lat: 19.2347, lng: 126.9731 },
    { lat: 17.9561, lng: 126.3279 },
    { lat: 16.6185, lng: 125.7882 },
    { lat: 15.2615, lng: 125.3004 },
    { lat: 13.8963, lng: 124.8691 },
    { lat: 12.4761, lng: 124.6685 },
    { lat: 11.0263, lng: 124.6451 },
    { lat: 9.5954, lng: 124.7448 },
    { lat: 8.2722, lng: 125.2874 },
    { lat: 6.9642, lng: 125.9113 },
    { lat: 5.5718, lng: 126.2313 },
    { lat: 4.1525, lng: 126.4652 },
    { lat: 2.7437, lng: 126.7546 },
    { lat: 1.3785, lng: 127.2204 },
    { lat: 0.0388, lng: 127.7451 },
    { lat: -1.2937, lng: 128.286 },
    { lat: -2.64, lng: 128.8123 },
    { lat: -4.0369, lng: 129.1133 },
    { lat: -5.4391, lng: 129.4432 },
    { lat: -6.8101, lng: 129.9623 },
    { lat: -8.2122, lng: 130.0542 },
    { lat: -9.6366, lng: 129.8507 },
    { lat: -11.056, lng: 129.5991 },
    { lat: -12.422, lng: 129.1309 },
    { lat: -13.7625, lng: 128.6187 },
    { lat: -15.1383, lng: 128.3911 },
    { lat: -16.5372, lng: 128.3632 },
    { lat: -17.928, lng: 128.2752 },
    { lat: -19.26, lng: 127.6782 },
    { lat: -20.6071, lng: 127.2713 },
    { lat: -22.0284, lng: 127.5918 },
    { lat: -23.4653, lng: 127.8732 },
    { lat: -24.891, lng: 127.8825 },
    { lat: -26.2685, lng: 127.5287 },
    { lat: -27.5415, lng: 126.8537 },
    { lat: -28.632, lng: 125.9287 },
    { lat: -29.6372, lng: 124.8852 },
    { lat: -30.686, lng: 123.8952 },
    { lat: -31.6818, lng: 122.8656 },
    { lat: -32.5236, lng: 121.6985 },
    { lat: -33.2947, lng: 120.4735 },
    { lat: -34.0802, lng: 119.2718 },
    { lat: -34.7896, lng: 118.026 },
    { lat: -35.3565, lng: 116.6897 },
    { lat: -35.706, lng: 115.2983 },
    { lat: -35.8008, lng: 113.8693 },
    { lat: -35.6468, lng: 112.4344 },
    { lat: -35.2561, lng: 111.0599 },
    { lat: -34.6725, lng: 109.7415 },
    { lat: -34.0359, lng: 108.4582 },
    { lat: -33.3553, lng: 107.2023 },
    { lat: -32.6245, lng: 105.9509 },
    { lat: -31.7623, lng: 104.8091 },
    { lat: -30.7044, lng: 103.8638 },
    { lat: -29.6459, lng: 102.8879 },
    { lat: -28.7256, lng: 101.7775 },
    { lat: -28.0917, lng: 100.5175 },
    { lat: -27.5741, lng: 99.1718 },
    { lat: -27.0314, lng: 97.7943 },
    { lat: -26.2834, lng: 96.5952 },
    { lat: -25.2308, lng: 95.6967 },
    { lat: -23.9473, lng: 95.0599 },
    { lat: -22.654, lng: 94.4272 },
    { lat: -21.7625, lng: 93.301 },
    { lat: -20.9661, lng: 92.0803 },
    { lat: -20.1486, lng: 90.9094 },
    { lat: -19.3009, lng: 89.7483 },
    { lat: -18.3983, lng: 88.6247 },
    { lat: -17.4224, lng: 87.5689 },
    { lat: -16.4295, lng: 86.5251 },
    { lat: -15.4792, lng: 85.4449 },
    { lat: -14.7357, lng: 84.2151 },
    { lat: -14.0202, lng: 82.9659 },
    { lat: -13.226, lng: 81.7711 },
    { lat: -12.4418, lng: 80.5557 },
    { lat: -11.5501, lng: 79.4336 },
    { lat: -10.3853, lng: 78.5754 },
    { lat: -9.0411, lng: 78.4488 },
    { lat: -7.6129, lng: 79.0393 },
    { lat: -6.3584, lng: 78.7314 },
    { lat: -5.4001, lng: 77.6197 },
    { lat: -4.8441, lng: 76.3 },
    { lat: -4.3699, lng: 74.9393 },
    { lat: -3.8971, lng: 73.5799 },
    { lat: -3.4821, lng: 72.2016 },
    { lat: -3.1109, lng: 70.8101 },
    { lat: -2.7864, lng: 69.4071 },
    { lat: -2.522, lng: 67.9924 },
    { lat: -2.2927, lng: 66.5712 },
    { lat: -2.0868, lng: 65.1461 },
    { lat: -1.9203, lng: 63.7165 },
    { lat: -1.7974, lng: 62.2824 },
    { lat: -1.7184, lng: 60.8437 },
  ],
  c5: [
    { lat: 13.2529, lng: 61.0841 },
    { lat: 13.3781, lng: 62.4986 },
    { lat: 13.5074, lng: 63.9125 },
    { lat: 13.6409, lng: 65.3258 },
    { lat: 13.7983, lng: 66.7346 },
    { lat: 14.1102, lng: 68.1139 },
    { lat: 14.543, lng: 69.4711 },
    { lat: 14.9314, lng: 70.8392 },
    { lat: 15.4251, lng: 72.166 },
    { lat: 16.1036, lng: 73.4138 },
    { lat: 16.9983, lng: 74.4923 },
    { lat: 18.0803, lng: 75.4133 },
    { lat: 19.1396, lng: 76.3573 },
    { lat: 20.1498, lng: 77.3467 },
    { lat: 21.1835, lng: 78.3178 },
    { lat: 22.2363, lng: 79.2739 },
    { lat: 23.2308, lng: 80.2808 },
    { lat: 24.1672, lng: 81.3441 },
    { lat: 25.0478, lng: 82.4674 },
    { lat: 25.7203, lng: 83.7069 },
    { lat: 26.157, lng: 85.0541 },
    { lat: 26.399, lng: 86.4476 },
    { lat: 26.473, lng: 87.8716 },
    { lat: 26.4444, lng: 89.2919 },
    { lat: 26.3024, lng: 90.7015 },
    { lat: 26.0212, lng: 92.0905 },
    { lat: 25.6536, lng: 93.4652 },
    { lat: 25.2879, lng: 94.8359 },
    { lat: 24.909, lng: 96.2031 },
    { lat: 24.5072, lng: 97.5677 },
    { lat: 24.1782, lng: 98.9471 },
    { lat: 23.9725, lng: 100.347 },
    { lat: 23.9074, lng: 101.7628 },
    { lat: 23.9764, lng: 103.1897 },
    { lat: 24.1374, lng: 104.5998 },
    { lat: 24.3835, lng: 105.989 },
    { lat: 24.7044, lng: 107.3706 },
    { lat: 25.0957, lng: 108.7506 },
    { lat: 25.6997, lng: 110.0205 },
    { lat: 26.5912, lng: 111.1178 },
    { lat: 27.574, lng: 112.1446 },
    { lat: 28.5646, lng: 113.1602 },
    { lat: 29.4731, lng: 114.252 },
    { lat: 30.3912, lng: 115.3323 },
    { lat: 31.5122, lng: 116.2162 },
    { lat: 32.8033, lng: 116.7173 },
    { lat: 34.2005, lng: 116.5943 },
    { lat: 35.5738, lng: 116.1702 },
    { lat: 36.8805, lng: 115.5526 },
    { lat: 38.1972, lng: 115.0761 },
    { lat: 39.5981, lng: 115.0642 },
    { lat: 40.855, lng: 115.6024 },
    { lat: 41.6881, lng: 116.7844 },
    { lat: 42.5917, lng: 117.8698 },
    { lat: 43.8287, lng: 118.47 },
    { lat: 45.2425, lng: 118.8852 },
    { lat: 46.5256, lng: 119.4701 },
    { lat: 47.3351, lng: 120.5659 },
    { lat: 47.7366, lng: 121.9022 },
    { lat: 47.7702, lng: 123.3164 },
    { lat: 47.5513, lng: 124.7556 },
    { lat: 47.2412, lng: 126.1454 },
    { lat: 46.8318, lng: 127.4849 },
    { lat: 46.2755, lng: 128.7713 },
    { lat: 45.5652, lng: 130.0041 },
    { lat: 44.7256, lng: 131.167 },
    { lat: 43.7946, lng: 132.2348 },
    { lat: 42.7306, lng: 133.1577 },
    { lat: 41.4977, lng: 133.8809 },
    { lat: 40.1586, lng: 134.3126 },
    { lat: 38.7446, lng: 134.4638 },
    { lat: 37.3264, lng: 134.5774 },
    { lat: 35.9157, lng: 134.6945 },
    { lat: 34.503, lng: 134.8029 },
    { lat: 33.0851, lng: 134.8954 },
    { lat: 31.6702, lng: 134.8495 },
    { lat: 30.2661, lng: 134.5954 },
    { lat: 28.9802, lng: 134.0341 },
    { lat: 27.8282, lng: 133.1924 },
    { lat: 26.6625, lng: 132.3795 },
    { lat: 25.4938, lng: 131.5795 },
    { lat: 24.3675, lng: 130.7149 },
    { lat: 23.3871, lng: 129.701 },
    { lat: 22.5267, lng: 128.566 },
    { lat: 21.5765, lng: 127.5045 },
    { lat: 20.4867, lng: 126.6044 },
    { lat: 19.2635, lng: 125.8873 },
    { lat: 17.9665, lng: 125.3213 },
    { lat: 16.6276, lng: 124.8397 },
    { lat: 15.2897, lng: 124.347 },
    { lat: 13.9189, lng: 124.0303 },
    { lat: 12.4996, lng: 123.9717 },
    { lat: 11.0708, lng: 123.9229 },
    { lat: 9.6642, lng: 123.9951 },
    { lat: 8.3189, lng: 124.4462 },
    { lat: 6.9708, lng: 124.9021 },
    { lat: 5.5943, lng: 125.2182 },
    { lat: 4.1992, lng: 125.4629 },
    { lat: 2.8, lng: 125.7362 },
    { lat: 1.4266, lng: 126.1066 },
    { lat: 0.1363, lng: 126.6673 },
    { lat: -1.1306, lng: 127.3086 },
    { lat: -2.4174, lng: 127.9322 },
    { lat: -3.7821, lng: 128.2537 },
    { lat: -5.1871, lng: 128.4799 },
    { lat: -6.5477, lng: 129.0083 },
    { lat: -7.9107, lng: 129.1173 },
    { lat: -9.3001, lng: 128.897 },
    { lat: -10.7184, lng: 128.6304 },
    { lat: -11.9928, lng: 128.0082 },
    { lat: -13.1186, lng: 127.1048 },
    { lat: -14.2862, lng: 126.5596 },
    { lat: -15.544, lng: 126.4381 },
    { lat: -16.9165, lng: 126.7069 },
    { lat: -18.2708, lng: 126.5737 },
    { lat: -19.585, lng: 125.9345 },
    { lat: -20.9992, lng: 125.7514 },
    { lat: -22.4271, lng: 125.9662 },
    { lat: -23.7824, lng: 126.3519 },
    { lat: -25.1686, lng: 126.4976 },
    { lat: -26.5455, lng: 126.2654 },
    { lat: -27.6893, lng: 125.4757 },
    { lat: -28.6938, lng: 124.4062 },
    { lat: -29.697, lng: 123.4178 },
    { lat: -30.6166, lng: 122.3513 },
    { lat: -31.4303, lng: 121.1751 },
    { lat: -32.1162, lng: 119.9332 },
    { lat: -32.6898, lng: 118.6406 },
    { lat: -33.2033, lng: 117.3143 },
    { lat: -33.6519, lng: 115.9555 },
    { lat: -33.8747, lng: 114.5728 },
    { lat: -33.8067, lng: 113.1698 },
    { lat: -33.5009, lng: 111.7775 },
    { lat: -33.0298, lng: 110.4387 },
    { lat: -32.3802, lng: 109.1846 },
    { lat: -31.5206, lng: 108.0842 },
    { lat: -30.3934, lng: 107.2827 },
    { lat: -28.9981, lng: 106.8298 },
    { lat: -27.9269, lng: 106.0227 },
    { lat: -27.3224, lng: 104.7077 },
    { lat: -26.8479, lng: 103.3646 },
    { lat: -26.404, lng: 102.0493 },
    { lat: -25.85, lng: 100.7243 },
    { lat: -25.1332, lng: 99.4692 },
    { lat: -24.0196, lng: 98.7139 },
    { lat: -22.7015, lng: 98.226 },
    { lat: -21.7249, lng: 97.293 },
    { lat: -20.959, lng: 96.0692 },
    { lat: -20.1245, lng: 94.8894 },
    { lat: -19.1208, lng: 93.9092 },
    { lat: -17.882, lng: 93.2369 },
    { lat: -16.6218, lng: 92.6233 },
    { lat: -15.4466, lng: 91.9478 },
    { lat: -14.6891, lng: 90.8622 },
    { lat: -14.2849, lng: 89.4102 },
    { lat: -13.6159, lng: 88.153 },
    { lat: -13.0043, lng: 86.9124 },
    { lat: -12.3923, lng: 85.6278 },
    { lat: -11.4593, lng: 84.4465 },
    { lat: -10.2752, lng: 84.019 },
    { lat: -9.0364, lng: 84.4558 },
    { lat: -7.9711, lng: 85.7124 },
    { lat: -6.9757, lng: 86.4086 },
    { lat: -5.9878, lng: 85.701 },
    { lat: -5.1733, lng: 84.4414 },
    { lat: -4.6048, lng: 83.1624 },
    { lat: -4.0338, lng: 81.8732 },
    { lat: -3.4412, lng: 80.5729 },
    { lat: -2.9112, lng: 79.2547 },
    { lat: -2.3803, lng: 77.9423 },
    { lat: -1.7536, lng: 76.6696 },
    { lat: -1.1067, lng: 75.4025 },
    { lat: -0.4963, lng: 74.1167 },
    { lat: 0.2362, lng: 72.9055 },
    { lat: 1.0776, lng: 71.7609 },
    { lat: 1.7987, lng: 70.5473 },
    { lat: 2.3604, lng: 69.251 },
    { lat: 2.763, lng: 67.8826 },
    { lat: 3.0657, lng: 66.4954 },
    { lat: 3.2846, lng: 65.097 },
    { lat: 3.458, lng: 63.6906 },
    { lat: 3.5903, lng: 62.2769 },
    { lat: 3.6817, lng: 60.8557 },
  ],
  c6: [
    { lat: -19.046, lng: 113.225 },
    { lat: -19.058, lng: 113.232 },
    { lat: -19.545, lng: 113.854 },
    { lat: -19.636, lng: 114.523 },
    { lat: -19.842, lng: 113.787 },
    { lat: -20.102, lng: 112.989 },
    { lat: -20.218, lng: 112.215 },
    { lat: -20.371, lng: 111.42 },
    { lat: -20.282, lng: 110.679 },
    { lat: -19.777, lng: 110.266 },
    { lat: -19.128, lng: 110.712 },
    { lat: -18.961, lng: 111.018 },
    { lat: -18.689, lng: 111.836 },
    { lat: -18.731, lng: 112.57 },
    { lat: -19.046, lng: 113.225 },
  ],
  c7: [
    { lat: -22.64, lng: 120.791 },
    { lat: -22.651, lng: 120.961 },
    { lat: -22.758, lng: 121.624 },
    { lat: -23.119, lng: 122.238 },
    { lat: -23.244, lng: 122.367 },
    { lat: -23.474, lng: 122.852 },
    { lat: -23.808, lng: 123.47 },
    { lat: -23.864, lng: 123.567 },
    { lat: -24.152, lng: 124.085 },
    { lat: -24.497, lng: 124.43 },
    { lat: -24.803, lng: 124.65 },
    { lat: -25.148, lng: 124.83 },
    { lat: -25.811, lng: 124.931 },
    { lat: -26.489, lng: 124.717 },
    { lat: -27.162, lng: 124.227 },
    { lat: -27.181, lng: 124.212 },
    { lat: -27.883, lng: 123.388 },
    { lat: -27.891, lng: 123.376 },
    { lat: -28.542, lng: 122.539 },
    { lat: -28.612, lng: 122.466 },
    { lat: -29.336, lng: 121.758 },
    { lat: -29.483, lng: 121.602 },
    { lat: -30.073, lng: 120.934 },
    { lat: -30.259, lng: 120.673 },
    { lat: -30.798, lng: 119.782 },
    { lat: -30.836, lng: 119.704 },
    { lat: -31.154, lng: 118.923 },
    { lat: -31.419, lng: 118.078 },
    { lat: -31.591, lng: 117.248 },
    { lat: -31.667, lng: 116.44 },
    { lat: -31.677, lng: 115.646 },
    { lat: -31.59, lng: 114.879 },
    { lat: -31.488, lng: 114.111 },
    { lat: -31.348, lng: 113.352 },
    { lat: -31.139, lng: 112.96 },
    { lat: -30.966, lng: 112.684 },
    { lat: -30.455, lng: 112.181 },
    { lat: -30.355, lng: 112.103 },
    { lat: -29.768, lng: 111.603 },
    { lat: -29.647, lng: 111.56 },
    { lat: -29.068, lng: 111.406 },
    { lat: -28.365, lng: 111.42 },
    { lat: -27.704, lng: 111.481 },
    { lat: -27.666, lng: 111.48 },
    { lat: -27.608, lng: 111.516 },
    { lat: -26.953, lng: 111.997 },
    { lat: -26.62, lng: 112.654 },
    { lat: -26.32, lng: 113.53 },
    { lat: -26.185, lng: 113.977 },
    { lat: -26.05, lng: 114.381 },
    { lat: -25.793, lng: 115.213 },
    { lat: -25.432, lng: 115.966 },
    { lat: -25.135, lng: 116.141 },
    { lat: -24.75, lng: 116.346 },
    { lat: -24.558, lng: 116.292 },
    { lat: -24.095, lng: 116.142 },
    { lat: -23.447, lng: 115.879 },
    { lat: -22.782, lng: 116.219 },
    { lat: -22.581, lng: 116.775 },
    { lat: -22.392, lng: 117.531 },
    { lat: -22.128, lng: 118.293 },
    { lat: -22.17, lng: 118.984 },
    { lat: -22.248, lng: 119.662 },
    { lat: -22.483, lng: 120.306 },
    { lat: -22.64, lng: 120.791 },
  ],
  c8: [
    { lat: -19.408, lng: 123.021 },
    { lat: -20.027, lng: 123.352 },
    { lat: -20.665, lng: 123.015 },
    { lat: -20.99, lng: 122.596 },
    { lat: -21.319, lng: 121.875 },
    { lat: -21.334, lng: 121.199 },
    { lat: -21.351, lng: 120.974 },
    { lat: -21.424, lng: 120.504 },
    { lat: -21.365, lng: 120.444 },
    { lat: -20.866, lng: 119.923 },
    { lat: -20.744, lng: 119.888 },
    { lat: -20.114, lng: 119.807 },
    { lat: -19.478, lng: 120.084 },
    { lat: -19.409, lng: 120.176 },
    { lat: -19.033, lng: 120.907 },
    { lat: -18.972, lng: 121.581 },
    { lat: -19.027, lng: 122.233 },
    { lat: -19.293, lng: 122.851 },
    { lat: -19.408, lng: 123.021 },
  ],
  c9: [
    { lat: -16.873, lng: 103.088 },
    { lat: -17.019, lng: 103.497 },
    { lat: -17.482, lng: 103.647 },
    { lat: -18.128, lng: 103.283 },
    { lat: -18.22, lng: 103.125 },
    { lat: -18.611, lng: 102.111 },
    { lat: -18.789, lng: 101.143 },
    { lat: -18.408, lng: 100.359 },
    { lat: -18.246, lng: 100.201 },
    { lat: -17.608, lng: 100.26 },
    { lat: -17.253, lng: 100.762 },
    { lat: -16.922, lng: 101.715 },
    { lat: -16.909, lng: 101.778 },
    { lat: -16.831, lng: 102.686 },
    { lat: -16.873, lng: 103.088 },
  ],
  c10: [
    { lat: 45.2542, lng: 126.3983 },
    { lat: 44.7088, lng: 127.675 },
    { lat: 44.0048, lng: 128.8801 },
    { lat: 43.1373, lng: 129.9878 },
    { lat: 42.0865, lng: 130.915 },
    { lat: 40.8957, lng: 131.6329 },
    { lat: 39.5842, lng: 132.1327 },
    { lat: 38.2111, lng: 132.4082 },
    { lat: 36.8064, lng: 132.4797 },
    { lat: 35.4295, lng: 132.6758 },
    { lat: 34.0756, lng: 133.0166 },
    { lat: 32.6851, lng: 133.2842 },
    { lat: 31.306, lng: 133.2757 },
    { lat: 30.0141, lng: 132.7445 },
    { lat: 28.8925, lng: 131.9032 },
    { lat: 27.8481, lng: 130.9728 },
    { lat: 26.6549, lng: 130.2453 },
    { lat: 25.4869, lng: 129.4726 },
    { lat: 24.433, lng: 128.5557 },
    { lat: 23.5048, lng: 127.505 },
    { lat: 22.5393, lng: 126.4882 },
    { lat: 21.4675, lng: 125.5954 },
    { lat: 20.2509, lng: 124.8949 },
    { lat: 18.9655, lng: 124.3435 },
    { lat: 17.6469, lng: 123.8721 },
    { lat: 16.3127, lng: 123.4398 },
    { lat: 14.951, lng: 123.1181 },
    { lat: 13.5631, lng: 123.032 },
    { lat: 12.1575, lng: 123.0968 },
    { lat: 10.7526, lng: 123.0208 },
    { lat: 9.3729, lng: 123.1817 },
    { lat: 8.0081, lng: 123.5337 },
    { lat: 6.6254, lng: 123.7433 },
    { lat: 5.2555, lng: 124.0316 },
    { lat: 3.8809, lng: 124.3021 },
    { lat: 2.4668, lng: 124.3191 },
    { lat: 1.1173, lng: 124.545 },
    { lat: -0.0912, lng: 125.1568 },
    { lat: -1.1286, lng: 126.1565 },
    { lat: -2.3454, lng: 126.8191 },
    { lat: -3.7128, lng: 127.1112 },
    { lat: -5.0787, lng: 127.2892 },
    { lat: -6.469, lng: 127.6626 },
    { lat: -7.8149, lng: 127.7244 },
    { lat: -9.0783, lng: 127.0996 },
    { lat: -10.4331, lng: 126.6461 },
    { lat: -11.6216, lng: 126.0602 },
    { lat: -12.2764, lng: 124.8253 },
    { lat: -12.4921, lng: 123.4479 },
    { lat: -12.4505, lng: 122.0475 },
    { lat: -12.5243, lng: 120.6421 },
    { lat: -12.5896, lng: 119.2503 },
    { lat: -12.5556, lng: 117.8803 },
    { lat: -12.3327, lng: 116.5214 },
    { lat: -12.0761, lng: 115.1517 },
    { lat: -11.9169, lng: 113.7651 },
    { lat: -11.7723, lng: 112.3886 },
    { lat: -11.7302, lng: 110.9843 },
    { lat: -11.8589, lng: 109.5546 },
    { lat: -12.1705, lng: 108.2504 },
    { lat: -12.6435, lng: 106.991 },
    { lat: -13.2283, lng: 105.5629 },
    { lat: -13.3144, lng: 104.2984 },
    { lat: -12.4375, lng: 103.4523 },
    { lat: -11.2586, lng: 102.5976 },
    { lat: -10.0169, lng: 102.1423 },
    { lat: -8.7586, lng: 102.6967 },
    { lat: -7.5465, lng: 103.0076 },
    { lat: -6.5619, lng: 102.648 },
    { lat: -6.0346, lng: 101.432 },
    { lat: -5.7692, lng: 99.8162 },
    { lat: -5.478, lng: 98.4689 },
    { lat: -5.1831, lng: 97.1983 },
    { lat: -4.9089, lng: 95.8043 },
    { lat: -4.655, lng: 94.3847 },
    { lat: -4.4137, lng: 93.0409 },
    { lat: -4.1519, lng: 91.6343 },
    { lat: -3.8263, lng: 90.2802 },
    { lat: -3.3318, lng: 89.0347 },
    { lat: -2.4381, lng: 87.6791 },
    { lat: -1.3976, lng: 87.34 },
    { lat: -0.5051, lng: 88.2121 },
    { lat: -0.2244, lng: 89.6167 },
    { lat: 0.2441, lng: 90.9379 },
    { lat: 1.4848, lng: 91.647 },
    { lat: 2.638, lng: 91.2736 },
    { lat: 3.497, lng: 90.043 },
    { lat: 4.0881, lng: 88.8223 },
    { lat: 4.5249, lng: 87.4935 },
    { lat: 4.7602, lng: 86.0659 },
    { lat: 4.4864, lng: 84.7487 },
    { lat: 3.8359, lng: 83.5253 },
    { lat: 3.096, lng: 82.3286 },
    { lat: 2.6949, lng: 81.0498 },
    { lat: 2.9197, lng: 79.6357 },
    { lat: 3.3721, lng: 78.3118 },
    { lat: 3.9051, lng: 77.0351 },
    { lat: 4.4408, lng: 75.7289 },
    { lat: 4.8799, lng: 74.4055 },
    { lat: 5.2522, lng: 73.062 },
    { lat: 5.6863, lng: 71.696 },
    { lat: 6.1886, lng: 70.4504 },
    { lat: 6.8534, lng: 69.2459 },
    { lat: 7.8128, lng: 67.9359 },
    { lat: 8.7327, lng: 67.6668 },
    { lat: 9.4444, lng: 68.7985 },
    { lat: 9.8159, lng: 70.1784 },
    { lat: 9.9387, lng: 71.5686 },
    { lat: 10.1365, lng: 72.9564 },
    { lat: 10.3489, lng: 74.3433 },
    { lat: 10.5382, lng: 75.7289 },
    { lat: 10.8623, lng: 77.0909 },
    { lat: 11.3963, lng: 78.3882 },
    { lat: 12.3068, lng: 79.4111 },
    { lat: 13.5094, lng: 80.1698 },
    { lat: 14.767, lng: 80.7638 },
    { lat: 16.0896, lng: 81.2211 },
    { lat: 17.4102, lng: 81.6879 },
    { lat: 18.6031, lng: 82.3989 },
    { lat: 19.7479, lng: 83.1996 },
    { lat: 20.8915, lng: 83.9974 },
    { lat: 21.9714, lng: 84.8951 },
    { lat: 22.9267, lng: 85.9236 },
    { lat: 23.6734, lng: 87.0967 },
    { lat: 24.2045, lng: 88.3899 },
    { lat: 24.5103, lng: 89.7641 },
    { lat: 24.5474, lng: 91.1568 },
    { lat: 24.3493, lng: 92.5414 },
    { lat: 24.0724, lng: 93.9151 },
    { lat: 23.7486, lng: 95.2791 },
    { lat: 23.4051, lng: 96.6364 },
    { lat: 23.0684, lng: 97.9971 },
    { lat: 22.7934, lng: 99.3686 },
    { lat: 22.6927, lng: 100.7612 },
    { lat: 22.7491, lng: 102.1674 },
    { lat: 22.8877, lng: 103.5618 },
    { lat: 23.0997, lng: 104.9434 },
    { lat: 23.3786, lng: 106.3151 },
    { lat: 23.6954, lng: 107.6816 },
    { lat: 24.0182, lng: 109.0477 },
    { lat: 24.4474, lng: 110.376 },
    { lat: 25.0775, lng: 111.6273 },
    { lat: 25.8694, lng: 112.7834 },
    { lat: 26.7576, lng: 113.8674 },
    { lat: 27.6706, lng: 114.9176 },
    { lat: 28.5514, lng: 116.0185 },
    { lat: 29.4614, lng: 117.0923 },
    { lat: 30.6228, lng: 117.8436 },
    { lat: 31.9436, lng: 118.2793 },
    { lat: 33.3394, lng: 118.4098 },
    { lat: 34.7194, lng: 118.1488 },
    { lat: 36.0618, lng: 117.5845 },
    { lat: 37.3947, lng: 117.3442 },
    { lat: 38.693, lng: 117.5456 },
    { lat: 39.7344, lng: 118.3541 },
    { lat: 40.5871, lng: 119.5868 },
    { lat: 41.759, lng: 120.2739 },
    { lat: 43.1235, lng: 120.6738 },
    { lat: 44.4206, lng: 121.2973 },
    { lat: 45.3225, lng: 122.2545 },
    { lat: 45.6363, lng: 123.6042 },
    { lat: 45.5737, lng: 125.0426 },
    { lat: 45.2542, lng: 126.3983 },
  ],
  c11: [
    { lat: -28.762, lng: 118.33 },
    { lat: -28.935, lng: 118.063 },
    { lat: -29.051, lng: 117.276 },
    { lat: -28.933, lng: 116.553 },
    { lat: -28.841, lng: 116.371 },
    { lat: -28.622, lng: 116.647 },
    { lat: -28.406, lng: 117.465 },
    { lat: -28.565, lng: 118.168 },
    { lat: -28.762, lng: 118.33 },
  ],
  c12: [
    { lat: 42.5215, lng: 126.5092 },
    { lat: 41.7218, lng: 127.7285 },
    { lat: 40.8284, lng: 128.7426 },
    { lat: 39.5675, lng: 129.3397 },
    { lat: 38.1926, lng: 129.5401 },
    { lat: 36.8067, lng: 129.6111 },
    { lat: 35.4248, lng: 129.7001 },
    { lat: 34.0572, lng: 130.0368 },
    { lat: 32.6909, lng: 129.8816 },
    { lat: 31.7704, lng: 128.9883 },
    { lat: 31.542, lng: 127.5775 },
    { lat: 30.553, lng: 126.686 },
    { lat: 29.191, lng: 126.3548 },
    { lat: 27.8309, lng: 126.2157 },
    { lat: 26.5142, lng: 125.7283 },
    { lat: 25.6009, lng: 124.665 },
    { lat: 24.5747, lng: 123.7976 },
    { lat: 23.2857, lng: 123.3451 },
    { lat: 21.9061, lng: 123.0648 },
    { lat: 20.5526, lng: 122.7497 },
    { lat: 19.2023, lng: 122.422 },
    { lat: 17.8663, lng: 122.0164 },
    { lat: 16.5342, lng: 121.615 },
    { lat: 15.1528, lng: 121.5402 },
    { lat: 13.779, lng: 121.7589 },
    { lat: 12.3969, lng: 121.9062 },
    { lat: 10.9997, lng: 121.8434 },
    { lat: 9.6252, lng: 121.9429 },
    { lat: 8.2148, lng: 122.0963 },
    { lat: 6.8701, lng: 121.9817 },
    { lat: 5.5153, lng: 121.6342 },
    { lat: 4.2038, lng: 121.2348 },
    { lat: 4.0637, lng: 119.9327 },
    { lat: 4.1435, lng: 118.5304 },
    { lat: 3.8494, lng: 117.2189 },
    { lat: 2.8923, lng: 116.2328 },
    { lat: 1.5302, lng: 116.4284 },
    { lat: 0.1713, lng: 116.7045 },
    { lat: -1.1924, lng: 116.9686 },
    { lat: -2.3932, lng: 117.5752 },
    { lat: -2.8816, lng: 118.8729 },
    { lat: -2.9292, lng: 120.2829 },
    { lat: -2.3755, lng: 121.526 },
    { lat: -1.6981, lng: 122.744 },
    { lat: -1.6529, lng: 124.0577 },
    { lat: -2.6246, lng: 125.0377 },
    { lat: -3.9472, lng: 125.4143 },
    { lat: -5.3294, lng: 125.4526 },
    { lat: -6.6993, lng: 125.4427 },
    { lat: -7.6268, lng: 124.4313 },
    { lat: -8.4463, lng: 123.2926 },
    { lat: -9.3217, lng: 122.233 },
    { lat: -10.1065, lng: 121.092 },
    { lat: -10.3245, lng: 119.716 },
    { lat: -10.3221, lng: 118.3205 },
    { lat: -10.1738, lng: 116.9482 },
    { lat: -9.8549, lng: 115.5834 },
    { lat: -9.4136, lng: 114.2677 },
    { lat: -8.8564, lng: 112.9974 },
    { lat: -8.1674, lng: 111.786 },
    { lat: -7.3427, lng: 110.6787 },
    { lat: -6.5405, lng: 109.5349 },
    { lat: -5.8866, lng: 108.3113 },
    { lat: -5.3939, lng: 107.0169 },
    { lat: -4.9299, lng: 105.7016 },
    { lat: -4.4764, lng: 104.3857 },
    { lat: -4.0376, lng: 103.0659 },
    { lat: -3.6078, lng: 101.7388 },
    { lat: -3.0885, lng: 100.4551 },
    { lat: -2.4385, lng: 99.2063 },
    { lat: -1.4391, lng: 98.2782 },
    { lat: -0.2085, lng: 97.6106 },
    { lat: 0.9924, lng: 96.9279 },
    { lat: 2.0624, lng: 96.0305 },
    { lat: 3.0548, lng: 95.0496 },
    { lat: 3.9978, lng: 94.0362 },
    { lat: 4.966, lng: 93.0192 },
    { lat: 5.9749, lng: 92.0591 },
    { lat: 7.0109, lng: 91.1904 },
    { lat: 8.2316, lng: 90.3787 },
    { lat: 9.525, lng: 89.999 },
    { lat: 10.8067, lng: 90.3017 },
    { lat: 12.2658, lng: 90.6692 },
    { lat: 13.0308, lng: 91.5231 },
    { lat: 13.0582, lng: 92.8195 },
    { lat: 12.9777, lng: 94.2405 },
    { lat: 13.7111, lng: 95.4295 },
    { lat: 14.6251, lng: 96.4873 },
    { lat: 15.8705, lng: 96.8871 },
    { lat: 17.137, lng: 96.2646 },
    { lat: 17.2249, lng: 94.9562 },
    { lat: 16.9652, lng: 93.6018 },
    { lat: 16.2915, lng: 92.3904 },
    { lat: 15.8444, lng: 91.2753 },
    { lat: 16.8285, lng: 90.3046 },
    { lat: 18.1248, lng: 90.138 },
    { lat: 19.4102, lng: 90.5365 },
    { lat: 20.419, lng: 91.3306 },
    { lat: 20.9026, lng: 92.6637 },
    { lat: 21.0467, lng: 94.0509 },
    { lat: 20.7758, lng: 95.3887 },
    { lat: 20.3404, lng: 96.7226 },
    { lat: 20.2763, lng: 98.0947 },
    { lat: 20.594, lng: 99.4504 },
    { lat: 20.9141, lng: 100.812 },
    { lat: 21.1169, lng: 102.1857 },
    { lat: 21.4009, lng: 103.5475 },
    { lat: 21.7395, lng: 104.9006 },
    { lat: 22.0672, lng: 106.2531 },
    { lat: 22.3869, lng: 107.6083 },
    { lat: 22.7139, lng: 108.9624 },
    { lat: 23.074, lng: 110.3071 },
    { lat: 23.4417, lng: 111.651 },
    { lat: 23.8711, lng: 112.9733 },
    { lat: 24.4436, lng: 114.2422 },
    { lat: 25.0909, lng: 115.4781 },
    { lat: 25.7149, lng: 116.717 },
    { lat: 26.1689, lng: 118.028 },
    { lat: 26.3929, lng: 119.4375 },
    { lat: 27.254, lng: 120.437 },
    { lat: 28.5256, lng: 120.9763 },
    { lat: 29.8934, lng: 121.2902 },
    { lat: 31.2367, lng: 121.624 },
    { lat: 32.6447, lng: 121.6532 },
    { lat: 34.0139, lng: 121.8324 },
    { lat: 35.3126, lng: 122.2729 },
    { lat: 36.5633, lng: 122.8581 },
    { lat: 37.8425, lng: 123.4451 },
    { lat: 39.1537, lng: 123.7316 },
    { lat: 40.5691, lng: 123.7856 },
    { lat: 41.9013, lng: 124.0912 },
    { lat: 42.6891, lng: 125.2177 },
    { lat: 42.5215, lng: 126.5092 },
  ],
  c13: [
    { lat: -5.169, lng: 120.304 },
    { lat: -5.172, lng: 120.338 },
    { lat: -5.406, lng: 120.959 },
    { lat: -5.739, lng: 121.342 },
    { lat: -6.318, lng: 121.153 },
    { lat: -6.529, lng: 120.906 },
    { lat: -6.901, lng: 120.549 },
    { lat: -7.165, lng: 120.237 },
    { lat: -7.489, lng: 119.811 },
    { lat: -7.658, lng: 119.567 },
    { lat: -8.015, lng: 118.897 },
    { lat: -8.083, lng: 118.65 },
    { lat: -8.173, lng: 118.236 },
    { lat: -8.11, lng: 117.585 },
    { lat: -8.099, lng: 117.235 },
    { lat: -8.087, lng: 116.926 },
    { lat: -7.914, lng: 116.274 },
    { lat: -7.532, lng: 115.767 },
    { lat: -7.137, lng: 115.663 },
    { lat: -6.95, lng: 115.618 },
    { lat: -6.367, lng: 115.654 },
    { lat: -5.784, lng: 115.726 },
    { lat: -5.203, lng: 115.776 },
    { lat: -5.187, lng: 115.787 },
    { lat: -4.919, lng: 116.467 },
    { lat: -4.837, lng: 117.13 },
    { lat: -4.827, lng: 117.785 },
    { lat: -4.862, lng: 118.432 },
    { lat: -5.026, lng: 119.07 },
    { lat: -5.14, lng: 119.704 },
    { lat: -5.169, lng: 120.304 },
  ],
  c14: [
    { lat: 22.112, lng: 116.916 },
    { lat: 21.921, lng: 117.633 },
    { lat: 21.424, lng: 118.369 },
    { lat: 21.294, lng: 118.464 },
    { lat: 20.776, lng: 118.757 },
    { lat: 20.137, lng: 118.964 },
    { lat: 19.507, lng: 118.976 },
    { lat: 19.039, lng: 118.884 },
    { lat: 18.885, lng: 118.847 },
    { lat: 18.27, lng: 118.548 },
    { lat: 17.982, lng: 118.383 },
    { lat: 17.66, lng: 118.179 },
    { lat: 17.048, lng: 117.98 },
    { lat: 16.431, lng: 118.159 },
    { lat: 15.813, lng: 118.474 },
    { lat: 15.414, lng: 118.776 },
    { lat: 15.197, lng: 118.897 },
    { lat: 14.585, lng: 119.273 },
    { lat: 13.978, lng: 119.549 },
    { lat: 13.694, lng: 119.66 },
    { lat: 13.375, lng: 119.762 },
    { lat: 12.776, lng: 119.9 },
    { lat: 12.18, lng: 119.962 },
    { lat: 11.587, lng: 119.992 },
    { lat: 10.997, lng: 119.989 },
    { lat: 10.407, lng: 120.036 },
    { lat: 9.819, lng: 120.089 },
    { lat: 9.368, lng: 120.084 },
    { lat: 9.234, lng: 120.077 },
    { lat: 8.652, lng: 119.861 },
    { lat: 8.25, lng: 119.526 },
    { lat: 8.076, lng: 119.302 },
    { lat: 7.843, lng: 118.91 },
    { lat: 7.625, lng: 118.275 },
    { lat: 7.509, lng: 117.826 },
    { lat: 7.462, lng: 117.633 },
    { lat: 7.353, lng: 116.981 },
    { lat: 7.148, lng: 116.332 },
    { lat: 6.991, lng: 115.674 },
    { lat: 6.952, lng: 115.431 },
    { lat: 6.861, lng: 115.008 },
    { lat: 6.462, lng: 114.355 },
    { lat: 6.381, lng: 114.221 },
    { lat: 6.065, lng: 113.696 },
    { lat: 5.808, lng: 113.231 },
    { lat: 5.704, lng: 113.027 },
    { lat: 5.342, lng: 112.351 },
    { lat: 5.236, lng: 111.952 },
    { lat: 5.121, lng: 111.658 },
    { lat: 5.027, lng: 110.95 },
    { lat: 4.706, lng: 110.247 },
    { lat: 4.667, lng: 110.173 },
    { lat: 4.216, lng: 110.276 },
    { lat: 4.081, lng: 110.298 },
    { lat: 3.493, lng: 110.868 },
    { lat: 3.392, lng: 111.037 },
    { lat: 2.907, lng: 111.515 },
    { lat: 2.708, lng: 111.774 },
    { lat: 2.323, lng: 112.073 },
    { lat: 1.901, lng: 112.5 },
    { lat: 1.74, lng: 112.63 },
    { lat: 1.159, lng: 113.093 },
    { lat: 0.961, lng: 113.21 },
    { lat: 0.579, lng: 113.409 },
    { lat: 0.0, lng: 113.671 },
    { lat: -0.579, lng: 113.878 },
    { lat: -0.715, lng: 113.9 },
    { lat: -1.157, lng: 113.952 },
    { lat: -1.736, lng: 114.013 },
    { lat: -2.315, lng: 114.089 },
    { lat: -2.894, lng: 114.14 },
    { lat: -3.475, lng: 113.989 },
    { lat: -3.621, lng: 113.829 },
    { lat: -3.847, lng: 113.13 },
    { lat: -3.898, lng: 112.431 },
    { lat: -3.849, lng: 111.728 },
    { lat: -3.657, lng: 111.025 },
    { lat: -3.523, lng: 110.311 },
    { lat: -3.498, lng: 110.127 },
    { lat: -3.428, lng: 109.586 },
    { lat: -3.231, lng: 108.857 },
    { lat: -3.025, lng: 108.118 },
    { lat: -2.927, lng: 107.844 },
    { lat: -2.69, lng: 107.375 },
    { lat: -2.346, lng: 106.789 },
    { lat: -2.206, lng: 106.624 },
    { lat: -1.762, lng: 106.013 },
    { lat: -1.609, lng: 105.863 },
    { lat: -1.175, lng: 105.571 },
    { lat: -0.588, lng: 105.312 },
    { lat: 0.0, lng: 105.183 },
    { lat: 0.297, lng: 105.093 },
    { lat: 0.588, lng: 104.97 },
    { lat: 1.177, lng: 105.01 },
    { lat: 1.766, lng: 104.821 },
    { lat: 2.355, lng: 104.75 },
    { lat: 2.687, lng: 104.231 },
    { lat: 2.79, lng: 103.407 },
    { lat: 2.956, lng: 102.913 },
    { lat: 3.032, lng: 102.561 },
    { lat: 3.224, lng: 101.701 },
    { lat: 3.446, lng: 100.821 },
    { lat: 3.567, lng: 100.493 },
    { lat: 3.797, lng: 99.911 },
    { lat: 4.175, lng: 99.15 },
    { lat: 4.27, lng: 98.967 },
    { lat: 4.784, lng: 98.105 },
    { lat: 4.86, lng: 97.982 },
    { lat: 5.394, lng: 97.361 },
    { lat: 5.908, lng: 96.905 },
    { lat: 6.003, lng: 96.843 },
    { lat: 6.61, lng: 96.601 },
    { lat: 7.216, lng: 96.548 },
    { lat: 7.739, lng: 96.626 },
    { lat: 7.82, lng: 96.648 },
    { lat: 8.416, lng: 97.256 },
    { lat: 8.547, lng: 97.451 },
    { lat: 9.004, lng: 98.191 },
    { lat: 9.066, lng: 98.303 },
    { lat: 9.541, lng: 99.144 },
    { lat: 9.59, lng: 99.238 },
    { lat: 9.943, lng: 99.979 },
    { lat: 10.167, lng: 100.582 },
    { lat: 10.291, lng: 100.807 },
    { lat: 10.754, lng: 101.468 },
    { lat: 10.846, lng: 101.577 },
    { lat: 11.333, lng: 102.346 },
    { lat: 11.341, lng: 102.359 },
    { lat: 11.933, lng: 103.041 },
    { lat: 11.982, lng: 103.066 },
    { lat: 12.536, lng: 103.281 },
    { lat: 13.149, lng: 103.222 },
    { lat: 13.769, lng: 103.033 },
    { lat: 14.392, lng: 102.785 },
    { lat: 15.015, lng: 102.668 },
    { lat: 15.639, lng: 102.595 },
    { lat: 16.264, lng: 102.569 },
    { lat: 16.892, lng: 102.564 },
    { lat: 17.052, lng: 102.619 },
    { lat: 17.515, lng: 102.75 },
    { lat: 18.141, lng: 102.923 },
    { lat: 18.412, lng: 103.063 },
    { lat: 18.762, lng: 103.31 },
    { lat: 19.075, lng: 103.715 },
    { lat: 19.369, lng: 104.12 },
    { lat: 19.537, lng: 104.424 },
    { lat: 19.921, lng: 105.149 },
    { lat: 19.964, lng: 105.264 },
    { lat: 20.153, lng: 105.915 },
    { lat: 20.32, lng: 106.691 },
    { lat: 20.454, lng: 107.468 },
    { lat: 20.513, lng: 107.646 },
    { lat: 20.676, lng: 108.209 },
    { lat: 20.926, lng: 108.933 },
    { lat: 21.089, lng: 109.393 },
    { lat: 21.169, lng: 109.652 },
    { lat: 21.365, lng: 110.378 },
    { lat: 21.499, lng: 111.115 },
    { lat: 21.624, lng: 111.846 },
    { lat: 21.639, lng: 111.926 },
    { lat: 21.73, lng: 112.576 },
    { lat: 21.903, lng: 113.282 },
    { lat: 22.093, lng: 113.977 },
    { lat: 22.189, lng: 114.621 },
    { lat: 22.199, lng: 114.689 },
    { lat: 22.193, lng: 115.422 },
    { lat: 22.139, lng: 116.158 },
    { lat: 22.119, lng: 116.88 },
    { lat: 22.112, lng: 116.916 },
  ],
};

export var apstar92gain = {
  c1: "42.00",
  c2: "43.00",
  c3: "44.00",
  c4: "45.00",
  c5: "46.00",
  c6: "46.00",
  c7: "47.00",
  c8: "47.00",
  c9: "47.00",
  c10: "47.00",
  c11: "48.00",
  c12: "48.00",
  c13: "49.00",
  c14: "49.00",
};

export var apstar92_gnt = {
  c1: [
    { lat: 9.103, lng: 60.955 },
    { lat: 9.216, lng: 62.147 },
    { lat: 9.799, lng: 65.254 },
    { lat: 10.413, lng: 66.936 },
    { lat: 10.457, lng: 67.028 },
    { lat: 11.035, lng: 68.185 },
    { lat: 11.672, lng: 68.89 },
    { lat: 12.286, lng: 69.213 },
    { lat: 12.32, lng: 69.231 },
    { lat: 12.969, lng: 69.547 },
    { lat: 13.621, lng: 69.815 },
    { lat: 14.276, lng: 70.043 },
    { lat: 14.447, lng: 70.154 },
    { lat: 14.923, lng: 70.506 },
    { lat: 15.562, lng: 71.173 },
    { lat: 15.898, lng: 71.642 },
    { lat: 16.192, lng: 72.062 },
    { lat: 16.816, lng: 73.068 },
    { lat: 16.961, lng: 73.311 },
    { lat: 17.439, lng: 74.079 },
    { lat: 17.954, lng: 74.802 },
    { lat: 18.067, lng: 74.983 },
    { lat: 18.695, lng: 75.883 },
    { lat: 18.919, lng: 76.129 },
    { lat: 19.333, lng: 76.579 },
    { lat: 19.984, lng: 77.063 },
    { lat: 20.063, lng: 77.101 },
    { lat: 20.645, lng: 77.382 },
    { lat: 21.324, lng: 77.424 },
    { lat: 21.698, lng: 77.365 },
    { lat: 22.014, lng: 77.349 },
    { lat: 22.71, lng: 77.241 },
    { lat: 23.404, lng: 77.224 },
    { lat: 23.512, lng: 77.244 },
    { lat: 24.089, lng: 77.433 },
    { lat: 24.662, lng: 77.893 },
    { lat: 24.755, lng: 78.002 },
    { lat: 25.353, lng: 79.079 },
    { lat: 25.385, lng: 79.151 },
    { lat: 25.787, lng: 80.505 },
    { lat: 25.951, lng: 81.29 },
    { lat: 26.061, lng: 82.022 },
    { lat: 26.279, lng: 83.501 },
    { lat: 26.432, lng: 84.725 },
    { lat: 26.458, lng: 84.934 },
    { lat: 26.583, lng: 86.343 },
    { lat: 26.69, lng: 87.697 },
    { lat: 26.727, lng: 89.051 },
    { lat: 26.708, lng: 90.39 },
    { lat: 26.601, lng: 91.74 },
    { lat: 26.34, lng: 93.143 },
    { lat: 25.967, lng: 94.558 },
    { lat: 25.779, lng: 95.146 },
    { lat: 25.489, lng: 95.973 },
    { lat: 24.982, lng: 97.072 },
    { lat: 24.785, lng: 97.449 },
    { lat: 24.225, lng: 98.507 },
    { lat: 24.042, lng: 98.867 },
    { lat: 23.468, lng: 100.127 },
    { lat: 23.465, lng: 100.135 },
    { lat: 23.031, lng: 101.286 },
    { lat: 22.795, lng: 102.316 },
    { lat: 22.713, lng: 103.255 },
    { lat: 22.695, lng: 104.151 },
    { lat: 22.753, lng: 105.002 },
    { lat: 22.904, lng: 105.805 },
    { lat: 23.102, lng: 106.58 },
    { lat: 23.155, lng: 106.741 },
    { lat: 23.354, lng: 107.326 },
    { lat: 23.654, lng: 108.048 },
    { lat: 23.749, lng: 108.265 },
    { lat: 23.957, lng: 108.762 },
    { lat: 24.233, lng: 109.479 },
    { lat: 24.349, lng: 109.756 },
    { lat: 24.518, lng: 110.187 },
    { lat: 24.777, lng: 110.897 },
    { lat: 24.944, lng: 111.453 },
    { lat: 24.996, lng: 111.615 },
    { lat: 25.171, lng: 112.342 },
    { lat: 25.303, lng: 113.075 },
    { lat: 25.501, lng: 113.783 },
    { lat: 25.516, lng: 113.829 },
    { lat: 25.776, lng: 114.463 },
    { lat: 26.119, lng: 115.119 },
    { lat: 26.138, lng: 115.144 },
    { lat: 26.801, lng: 115.555 },
    { lat: 27.068, lng: 115.596 },
    { lat: 27.483, lng: 115.632 },
    { lat: 28.178, lng: 115.561 },
    { lat: 28.882, lng: 115.425 },
    { lat: 29.593, lng: 115.285 },
    { lat: 30.31, lng: 115.198 },
    { lat: 30.708, lng: 115.195 },
    { lat: 31.03, lng: 115.219 },
    { lat: 31.75, lng: 115.384 },
    { lat: 32.094, lng: 115.496 },
    { lat: 32.473, lng: 115.685 },
    { lat: 33.073, lng: 115.94 },
    { lat: 33.203, lng: 115.988 },
    { lat: 33.952, lng: 116.116 },
    { lat: 34.72, lng: 116.021 },
    { lat: 35.507, lng: 115.801 },
    { lat: 35.73, lng: 115.726 },
    { lat: 36.307, lng: 115.527 },
    { lat: 37.121, lng: 115.247 },
    { lat: 37.943, lng: 115.046 },
    { lat: 38.772, lng: 114.963 },
    { lat: 39.32, lng: 114.996 },
    { lat: 39.606, lng: 115.027 },
    { lat: 40.441, lng: 115.329 },
    { lat: 40.457, lng: 115.339 },
    { lat: 41.145, lng: 115.904 },
    { lat: 41.267, lng: 116.042 },
    { lat: 41.611, lng: 116.582 },
    { lat: 41.954, lng: 117.321 },
    { lat: 42.06, lng: 117.628 },
    { lat: 42.198, lng: 118.107 },
    { lat: 42.424, lng: 118.9 },
    { lat: 42.669, lng: 119.684 },
    { lat: 42.814, lng: 120.322 },
    { lat: 42.851, lng: 120.495 },
    { lat: 43.002, lng: 121.317 },
    { lat: 43.178, lng: 122.127 },
    { lat: 43.463, lng: 122.896 },
    { lat: 43.59, lng: 123.177 },
    { lat: 43.828, lng: 123.639 },
    { lat: 44.335, lng: 124.338 },
    { lat: 44.467, lng: 124.491 },
    { lat: 45.032, lng: 124.979 },
    { lat: 45.391, lng: 125.277 },
    { lat: 45.875, lng: 125.584 },
    { lat: 46.346, lng: 125.927 },
    { lat: 46.767, lng: 126.186 },
    { lat: 47.326, lng: 126.63 },
    { lat: 47.64, lng: 126.81 },
    { lat: 48.331, lng: 127.474 },
    { lat: 48.371, lng: 127.499 },
    { lat: 49.064, lng: 128.216 },
    { lat: 49.351, lng: 128.792 },
    { lat: 49.636, lng: 128.987 },
    { lat: 50.085, lng: 129.81 },
    { lat: 49.832, lng: 130.831 },
    { lat: 49.774, lng: 131.789 },
    { lat: 49.226, lng: 132.692 },
    { lat: 49.104, lng: 132.87 },
    { lat: 48.132, lng: 133.732 },
    { lat: 47.838, lng: 134.017 },
    { lat: 47.078, lng: 134.674 },
    { lat: 46.267, lng: 135.141 },
    { lat: 46.062, lng: 135.242 },
    { lat: 45.079, lng: 135.596 },
    { lat: 44.123, lng: 135.825 },
    { lat: 43.192, lng: 135.914 },
    { lat: 42.284, lng: 135.886 },
    { lat: 41.396, lng: 135.776 },
    { lat: 41.302, lng: 135.753 },
    { lat: 40.528, lng: 135.588 },
    { lat: 39.678, lng: 135.325 },
    { lat: 39.335, lng: 135.195 },
    { lat: 38.845, lng: 135.0 },
    { lat: 38.026, lng: 134.706 },
    { lat: 37.695, lng: 134.627 },
    { lat: 37.219, lng: 134.51 },
    { lat: 36.423, lng: 134.442 },
    { lat: 35.638, lng: 134.493 },
    { lat: 34.862, lng: 134.64 },
    { lat: 34.097, lng: 134.839 },
    { lat: 33.342, lng: 135.042 },
    { lat: 33.178, lng: 135.085 },
    { lat: 32.598, lng: 135.234 },
    { lat: 31.863, lng: 135.373 },
    { lat: 31.139, lng: 135.399 },
    { lat: 30.573, lng: 135.306 },
    { lat: 30.425, lng: 135.275 },
    { lat: 29.721, lng: 134.946 },
    { lat: 29.441, lng: 134.727 },
    { lat: 29.03, lng: 134.315 },
    { lat: 28.87, lng: 134.111 },
    { lat: 28.427, lng: 133.488 },
    { lat: 28.349, lng: 133.4 },
    { lat: 27.89, lng: 132.878 },
    { lat: 27.674, lng: 132.695 },
    { lat: 27.137, lng: 132.295 },
    { lat: 27.001, lng: 132.219 },
    { lat: 26.332, lng: 131.877 },
    { lat: 26.069, lng: 131.75 },
    { lat: 25.669, lng: 131.555 },
    { lat: 25.026, lng: 131.208 },
    { lat: 25.012, lng: 131.2 },
    { lat: 24.362, lng: 130.768 },
    { lat: 24.22, lng: 130.646 },
    { lat: 23.719, lng: 130.201 },
    { lat: 23.594, lng: 130.07 },
    { lat: 23.115, lng: 129.48 },
    { lat: 23.085, lng: 129.437 },
    { lat: 22.71, lng: 128.884 },
    { lat: 22.457, lng: 128.547 },
    { lat: 22.252, lng: 128.294 },
    { lat: 21.832, lng: 127.809 },
    { lat: 21.74, lng: 127.712 },
    { lat: 21.217, lng: 127.133 },
    { lat: 21.209, lng: 127.125 },
    { lat: 20.683, lng: 126.556 },
    { lat: 20.591, lng: 126.45 },
    { lat: 20.181, lng: 125.975 },
    { lat: 19.977, lng: 125.733 },
    { lat: 19.69, lng: 125.394 },
    { lat: 19.367, lng: 124.987 },
    { lat: 19.218, lng: 124.81 },
    { lat: 18.759, lng: 124.305 },
    { lat: 18.69, lng: 124.232 },
    { lat: 18.151, lng: 123.772 },
    { lat: 18.018, lng: 123.672 },
    { lat: 17.544, lng: 123.369 },
    { lat: 17.094, lng: 123.143 },
    { lat: 16.937, lng: 123.069 },
    { lat: 16.332, lng: 122.861 },
    { lat: 15.727, lng: 122.747 },
    { lat: 15.351, lng: 122.706 },
    { lat: 15.124, lng: 122.684 },
    { lat: 14.522, lng: 122.658 },
    { lat: 13.924, lng: 122.642 },
    { lat: 13.327, lng: 122.633 },
    { lat: 12.732, lng: 122.654 },
    { lat: 12.139, lng: 122.703 },
    { lat: 11.547, lng: 122.753 },
    { lat: 10.958, lng: 122.811 },
    { lat: 10.37, lng: 122.869 },
    { lat: 9.785, lng: 122.92 },
    { lat: 9.201, lng: 122.99 },
    { lat: 8.617, lng: 123.132 },
    { lat: 8.195, lng: 123.312 },
    { lat: 8.034, lng: 123.39 },
    { lat: 7.452, lng: 123.813 },
    { lat: 7.251, lng: 123.978 },
    { lat: 6.87, lng: 124.321 },
    { lat: 6.507, lng: 124.623 },
    { lat: 6.291, lng: 124.81 },
    { lat: 5.714, lng: 125.184 },
    { lat: 5.548, lng: 125.266 },
    { lat: 5.138, lng: 125.419 },
    { lat: 4.565, lng: 125.603 },
    { lat: 3.992, lng: 125.804 },
    { lat: 3.663, lng: 125.92 },
    { lat: 3.42, lng: 125.99 },
    { lat: 2.848, lng: 126.155 },
    { lat: 2.278, lng: 126.306 },
    { lat: 1.708, lng: 126.446 },
    { lat: 1.334, lng: 126.551 },
    { lat: 1.138, lng: 126.6 },
    { lat: 0.569, lng: 126.789 },
    { lat: 0.0, lng: 127.026 },
    { lat: -0.284, lng: 127.15 },
    { lat: -0.569, lng: 127.268 },
    { lat: -1.137, lng: 127.484 },
    { lat: -1.706, lng: 127.662 },
    { lat: -2.004, lng: 127.73 },
    { lat: -2.275, lng: 127.795 },
    { lat: -2.844, lng: 127.941 },
    { lat: -3.413, lng: 128.118 },
    { lat: -3.835, lng: 128.293 },
    { lat: -3.982, lng: 128.355 },
    { lat: -4.552, lng: 128.622 },
    { lat: -5.122, lng: 128.817 },
    { lat: -5.656, lng: 128.839 },
    { lat: -5.694, lng: 128.84 },
    { lat: -5.706, lng: 128.837 },
    { lat: -6.268, lng: 128.713 },
    { lat: -6.842, lng: 128.529 },
    { lat: -7.418, lng: 128.364 },
    { lat: -7.995, lng: 128.251 },
    { lat: -8.573, lng: 128.171 },
    { lat: -8.818, lng: 128.133 },
    { lat: -9.153, lng: 128.077 },
    { lat: -9.735, lng: 127.925 },
    { lat: -10.319, lng: 127.752 },
    { lat: -10.903, lng: 127.669 },
    { lat: -11.489, lng: 127.677 },
    { lat: -12.076, lng: 127.697 },
    { lat: -12.666, lng: 127.708 },
    { lat: -13.258, lng: 127.621 },
    { lat: -13.855, lng: 127.427 },
    { lat: -14.453, lng: 127.259 },
    { lat: -15.052, lng: 127.243 },
    { lat: -15.652, lng: 127.337 },
    { lat: -16.254, lng: 127.495 },
    { lat: -16.768, lng: 127.6 },
    { lat: -16.859, lng: 127.617 },
    { lat: -17.469, lng: 127.6 },
    { lat: -18.083, lng: 127.544 },
    { lat: -18.7, lng: 127.524 },
    { lat: -19.32, lng: 127.514 },
    { lat: -19.944, lng: 127.502 },
    { lat: -20.572, lng: 127.418 },
    { lat: -21.208, lng: 127.195 },
    { lat: -21.467, lng: 127.103 },
    { lat: -21.848, lng: 126.954 },
    { lat: -22.489, lng: 126.944 },
    { lat: -22.554, lng: 126.966 },
    { lat: -23.128, lng: 127.176 },
    { lat: -23.611, lng: 127.479 },
    { lat: -23.769, lng: 127.561 },
    { lat: -24.419, lng: 127.773 },
    { lat: -25.077, lng: 127.799 },
    { lat: -25.745, lng: 127.63 },
    { lat: -26.423, lng: 127.315 },
    { lat: -27.083, lng: 126.977 },
    { lat: -27.108, lng: 126.963 },
    { lat: -27.801, lng: 126.573 },
    { lat: -28.503, lng: 126.106 },
    { lat: -28.585, lng: 126.038 },
    { lat: -29.217, lng: 125.503 },
    { lat: -29.547, lng: 125.159 },
    { lat: -29.947, lng: 124.656 },
    { lat: -30.24, lng: 124.307 },
    { lat: -30.687, lng: 123.794 },
    { lat: -31.089, lng: 123.396 },
    { lat: -31.433, lng: 123.069 },
    { lat: -32.05, lng: 122.427 },
    { lat: -32.192, lng: 122.276 },
    { lat: -32.804, lng: 121.477 },
    { lat: -32.971, lng: 121.256 },
    { lat: -33.506, lng: 120.512 },
    { lat: -33.768, lng: 120.112 },
    { lat: -34.095, lng: 119.553 },
    { lat: -34.588, lng: 118.763 },
    { lat: -34.691, lng: 118.565 },
    { lat: -35.086, lng: 117.621 },
    { lat: -35.432, lng: 116.674 },
    { lat: -35.469, lng: 116.537 },
    { lat: -35.651, lng: 115.759 },
    { lat: -35.766, lng: 114.871 },
    { lat: -35.767, lng: 114.021 },
    { lat: -35.709, lng: 113.188 },
    { lat: -35.661, lng: 112.96 },
    { lat: -35.502, lng: 112.418 },
    { lat: -35.111, lng: 111.731 },
    { lat: -34.963, lng: 111.557 },
    { lat: -34.544, lng: 111.126 },
    { lat: -34.237, lng: 110.798 },
    { lat: -33.98, lng: 110.521 },
    { lat: -33.521, lng: 110.036 },
    { lat: -33.417, lng: 109.914 },
    { lat: -32.904, lng: 109.281 },
    { lat: -32.82, lng: 109.158 },
    { lat: -32.442, lng: 108.621 },
    { lat: -32.137, lng: 108.109 },
    { lat: -32.021, lng: 107.939 },
    { lat: -31.591, lng: 107.256 },
    { lat: -31.462, lng: 107.036 },
    { lat: -31.143, lng: 106.579 },
    { lat: -30.79, lng: 106.044 },
    { lat: -30.666, lng: 105.913 },
    { lat: -30.152, lng: 105.26 },
    { lat: -30.115, lng: 105.213 },
    { lat: -29.556, lng: 104.645 },
    { lat: -29.439, lng: 104.498 },
    { lat: -29.069, lng: 103.968 },
    { lat: -28.789, lng: 103.426 },
    { lat: -28.702, lng: 103.222 },
    { lat: -28.373, lng: 102.447 },
    { lat: -28.173, lng: 101.871 },
    { lat: -28.087, lng: 101.639 },
    { lat: -27.841, lng: 100.797 },
    { lat: -27.579, lng: 100.022 },
    { lat: -27.553, lng: 99.966 },
    { lat: -27.209, lng: 99.154 },
    { lat: -26.964, lng: 98.592 },
    { lat: -26.818, lng: 98.356 },
    { lat: -26.325, lng: 97.635 },
    { lat: -26.304, lng: 97.618 },
    { lat: -25.661, lng: 97.183 },
    { lat: -25.445, lng: 97.072 },
    { lat: -24.995, lng: 96.839 },
    { lat: -24.579, lng: 96.514 },
    { lat: -24.343, lng: 96.314 },
    { lat: -23.939, lng: 95.809 },
    { lat: -23.707, lng: 95.569 },
    { lat: -23.312, lng: 95.081 },
    { lat: -23.076, lng: 94.778 },
    { lat: -22.744, lng: 94.302 },
    { lat: -22.45, lng: 93.938 },
    { lat: -22.098, lng: 93.549 },
    { lat: -21.817, lng: 93.285 },
    { lat: -21.323, lng: 92.849 },
    { lat: -21.181, lng: 92.725 },
    { lat: -20.546, lng: 92.2 },
    { lat: -20.489, lng: 92.158 },
    { lat: -19.906, lng: 91.828 },
    { lat: -19.423, lng: 91.569 },
    { lat: -19.267, lng: 91.492 },
    { lat: -18.632, lng: 91.125 },
    { lat: -18.411, lng: 90.917 },
    { lat: -18.004, lng: 90.633 },
    { lat: -17.496, lng: 90.178 },
    { lat: -17.38, lng: 90.089 },
    { lat: -16.76, lng: 89.499 },
    { lat: -16.645, lng: 89.37 },
    { lat: -16.142, lng: 88.865 },
    { lat: -15.771, lng: 88.535 },
    { lat: -15.522, lng: 88.322 },
    { lat: -14.899, lng: 87.864 },
    { lat: -14.706, lng: 87.749 },
    { lat: -14.273, lng: 87.525 },
    { lat: -13.644, lng: 87.317 },
    { lat: -13.118, lng: 87.146 },
    { lat: -13.017, lng: 87.111 },
    { lat: -12.392, lng: 86.853 },
    { lat: -11.775, lng: 86.405 },
    { lat: -11.737, lng: 86.366 },
    { lat: -11.165, lng: 85.688 },
    { lat: -10.895, lng: 85.292 },
    { lat: -10.56, lng: 84.803 },
    { lat: -10.112, lng: 84.124 },
    { lat: -9.954, lng: 83.873 },
    { lat: -9.346, lng: 82.992 },
    { lat: -9.273, lng: 82.893 },
    { lat: -8.739, lng: 82.025 },
    { lat: -8.462, lng: 81.552 },
    { lat: -8.133, lng: 80.911 },
    { lat: -7.677, lng: 80.078 },
    { lat: -7.524, lng: 79.878 },
    { lat: -6.903, lng: 79.32 },
    { lat: -6.281, lng: 78.794 },
    { lat: -6.186, lng: 78.694 },
    { lat: -5.658, lng: 78.273 },
    { lat: -5.035, lng: 77.72 },
    { lat: -4.528, lng: 77.111 },
    { lat: -4.412, lng: 76.996 },
    { lat: -3.789, lng: 76.247 },
    { lat: -3.166, lng: 75.133 },
    { lat: -3.159, lng: 75.122 },
    { lat: -2.538, lng: 74.292 },
    { lat: -1.909, lng: 73.179 },
    { lat: -1.641, lng: 72.672 },
    { lat: -1.277, lng: 71.971 },
    { lat: -0.64, lng: 70.737 },
    { lat: -0.0, lng: 69.322 },
    { lat: 0.014, lng: 69.286 },
    { lat: 0.646, lng: 67.638 },
    { lat: 1.3, lng: 65.343 },
    { lat: 1.856, lng: 60.846 },
  ],
  c2: [
    { lat: 59.907, lng: 125.462 },
    { lat: 58.92, lng: 126.454 },
    { lat: 58.263, lng: 127.043 },
    { lat: 56.76, lng: 127.959 },
    { lat: 55.36, lng: 128.528 },
    { lat: 54.049, lng: 128.656 },
    { lat: 53.644, lng: 128.678 },
    { lat: 53.902, lng: 127.525 },
    { lat: 54.108, lng: 127.274 },
    { lat: 54.728, lng: 126.103 },
    { lat: 55.336, lng: 124.708 },
    { lat: 55.556, lng: 124.478 },
    { lat: 56.996, lng: 123.44 },
    { lat: 57.902, lng: 123.325 },
    { lat: 58.472, lng: 123.263 },
    { lat: 59.711, lng: 123.486 },
    { lat: 60.013, lng: 123.668 },
    { lat: 60.476, lng: 124.318 },
    { lat: 59.907, lng: 125.462 },
  ],
  c3: [
    { lat: 11.765, lng: 61.033 },
    { lat: 11.902, lng: 61.592 },
    { lat: 12.091, lng: 62.874 },
    { lat: 12.491, lng: 64.064 },
    { lat: 13.13, lng: 64.929 },
    { lat: 13.777, lng: 65.568 },
    { lat: 14.084, lng: 65.872 },
    { lat: 14.424, lng: 66.179 },
    { lat: 15.068, lng: 66.885 },
    { lat: 15.705, lng: 67.741 },
    { lat: 15.764, lng: 67.844 },
    { lat: 16.328, lng: 68.925 },
    { lat: 16.88, lng: 70.12 },
    { lat: 16.942, lng: 70.247 },
    { lat: 17.558, lng: 71.51 },
    { lat: 17.854, lng: 72.066 },
    { lat: 18.18, lng: 72.618 },
    { lat: 18.812, lng: 73.503 },
    { lat: 18.886, lng: 73.581 },
    { lat: 19.45, lng: 74.283 },
    { lat: 20.031, lng: 74.722 },
    { lat: 20.104, lng: 74.78 },
    { lat: 20.77, lng: 75.075 },
    { lat: 21.457, lng: 75.049 },
    { lat: 21.719, lng: 74.94 },
    { lat: 22.163, lng: 74.759 },
    { lat: 22.885, lng: 74.297 },
    { lat: 23.609, lng: 73.876 },
    { lat: 24.129, lng: 73.771 },
    { lat: 24.321, lng: 73.753 },
    { lat: 25.015, lng: 73.964 },
    { lat: 25.395, lng: 74.31 },
    { lat: 25.681, lng: 74.663 },
    { lat: 26.149, lng: 75.631 },
    { lat: 26.296, lng: 76.16 },
    { lat: 26.533, lng: 77.406 },
    { lat: 26.769, lng: 79.219 },
    { lat: 26.787, lng: 79.444 },
    { lat: 26.891, lng: 81.02 },
    { lat: 27.039, lng: 82.648 },
    { lat: 27.181, lng: 84.147 },
    { lat: 27.183, lng: 84.172 },
    { lat: 27.288, lng: 85.647 },
    { lat: 27.389, lng: 87.046 },
    { lat: 27.384, lng: 88.474 },
    { lat: 27.346, lng: 89.862 },
    { lat: 27.257, lng: 91.229 },
    { lat: 27.05, lng: 92.626 },
    { lat: 26.7, lng: 94.062 },
    { lat: 26.496, lng: 94.762 },
    { lat: 26.273, lng: 95.481 },
    { lat: 25.676, lng: 96.935 },
    { lat: 25.675, lng: 96.938 },
    { lat: 24.91, lng: 98.369 },
    { lat: 24.88, lng: 98.426 },
    { lat: 24.219, lng: 99.767 },
    { lat: 24.15, lng: 99.925 },
    { lat: 23.718, lng: 100.973 },
    { lat: 23.391, lng: 102.058 },
    { lat: 23.363, lng: 102.248 },
    { lat: 23.256, lng: 103.031 },
    { lat: 23.224, lng: 103.94 },
    { lat: 23.258, lng: 104.466 },
    { lat: 23.279, lng: 104.798 },
    { lat: 23.419, lng: 105.61 },
    { lat: 23.616, lng: 106.39 },
    { lat: 23.809, lng: 106.918 },
    { lat: 23.89, lng: 107.132 },
    { lat: 24.2, lng: 107.853 },
    { lat: 24.411, lng: 108.356 },
    { lat: 24.497, lng: 108.572 },
    { lat: 24.771, lng: 109.292 },
    { lat: 25.01, lng: 109.929 },
    { lat: 25.041, lng: 110.008 },
    { lat: 25.299, lng: 110.722 },
    { lat: 25.539, lng: 111.437 },
    { lat: 25.606, lng: 111.693 },
    { lat: 25.737, lng: 112.16 },
    { lat: 25.906, lng: 112.886 },
    { lat: 26.147, lng: 113.585 },
    { lat: 26.197, lng: 113.687 },
    { lat: 26.59, lng: 114.217 },
    { lat: 26.846, lng: 114.463 },
    { lat: 27.455, lng: 114.715 },
    { lat: 27.521, lng: 114.732 },
    { lat: 28.212, lng: 114.756 },
    { lat: 28.915, lng: 114.664 },
    { lat: 29.628, lng: 114.531 },
    { lat: 30.347, lng: 114.411 },
    { lat: 30.82, lng: 114.359 },
    { lat: 31.071, lng: 114.344 },
    { lat: 31.799, lng: 114.387 },
    { lat: 32.531, lng: 114.505 },
    { lat: 32.566, lng: 114.512 },
    { lat: 33.266, lng: 114.736 },
    { lat: 33.755, lng: 114.865 },
    { lat: 34.011, lng: 114.933 },
    { lat: 34.776, lng: 114.946 },
    { lat: 35.56, lng: 114.786 },
    { lat: 36.362, lng: 114.526 },
    { lat: 37.167, lng: 114.251 },
    { lat: 37.177, lng: 114.247 },
    { lat: 38.001, lng: 114.029 },
    { lat: 38.834, lng: 113.902 },
    { lat: 39.674, lng: 113.886 },
    { lat: 39.734, lng: 113.894 },
    { lat: 40.518, lng: 114.056 },
    { lat: 40.893, lng: 114.209 },
    { lat: 41.361, lng: 114.492 },
    { lat: 41.626, lng: 114.747 },
    { lat: 42.133, lng: 115.408 },
    { lat: 42.186, lng: 115.493 },
    { lat: 42.516, lng: 116.135 },
    { lat: 42.787, lng: 116.92 },
    { lat: 42.961, lng: 117.638 },
    { lat: 42.982, lng: 117.741 },
    { lat: 43.167, lng: 118.564 },
    { lat: 43.39, lng: 119.369 },
    { lat: 43.587, lng: 120.185 },
    { lat: 43.711, lng: 120.725 },
    { lat: 43.778, lng: 121.001 },
    { lat: 43.996, lng: 121.807 },
    { lat: 44.347, lng: 122.561 },
    { lat: 44.541, lng: 122.887 },
    { lat: 44.847, lng: 123.263 },
    { lat: 45.457, lng: 123.814 },
    { lat: 45.588, lng: 123.884 },
    { lat: 46.418, lng: 124.316 },
    { lat: 46.663, lng: 124.392 },
    { lat: 47.411, lng: 124.694 },
    { lat: 47.989, lng: 124.821 },
    { lat: 48.438, lng: 125.002 },
    { lat: 49.507, lng: 125.094 },
    { lat: 50.238, lng: 124.895 },
    { lat: 50.632, lng: 124.791 },
    { lat: 50.985, lng: 124.581 },
    { lat: 51.819, lng: 124.095 },
    { lat: 53.068, lng: 123.155 },
    { lat: 54.394, lng: 121.894 },
    { lat: 54.526, lng: 121.762 },
    { lat: 55.802, lng: 120.422 },
    { lat: 57.286, lng: 119.02 },
    { lat: 58.846, lng: 117.861 },
    { lat: 60.495, lng: 116.982 },
    { lat: 61.412, lng: 116.715 },
    { lat: 62.231, lng: 116.64 },
    { lat: 62.774, lng: 116.885 },
    { lat: 63.551, lng: 117.651 },
    { lat: 63.973, lng: 118.145 },
    { lat: 64.237, lng: 118.543 },
    { lat: 64.912, lng: 119.483 },
    { lat: 65.46, lng: 120.582 },
    { lat: 65.803, lng: 121.252 },
    { lat: 66.017, lng: 121.711 },
    { lat: 66.657, lng: 122.81 },
    { lat: 66.683, lng: 124.454 },
    { lat: 66.041, lng: 126.542 },
    { lat: 65.332, lng: 128.403 },
    { lat: 65.227, lng: 128.593 },
    { lat: 63.159, lng: 131.001 },
    { lat: 62.765, lng: 131.196 },
    { lat: 61.291, lng: 131.858 },
    { lat: 60.442, lng: 132.044 },
    { lat: 59.602, lng: 132.224 },
    { lat: 58.04, lng: 132.495 },
    { lat: 56.577, lng: 132.807 },
    { lat: 55.195, lng: 133.185 },
    { lat: 53.878, lng: 133.778 },
    { lat: 53.803, lng: 133.828 },
    { lat: 52.617, lng: 134.597 },
    { lat: 52.012, lng: 135.176 },
    { lat: 51.405, lng: 135.703 },
    { lat: 50.668, lng: 136.342 },
    { lat: 50.245, lng: 136.709 },
    { lat: 49.131, lng: 137.451 },
    { lat: 49.129, lng: 137.452 },
    { lat: 48.059, lng: 137.968 },
    { lat: 47.025, lng: 138.102 },
    { lat: 46.022, lng: 138.045 },
    { lat: 45.047, lng: 137.89 },
    { lat: 44.833, lng: 137.842 },
    { lat: 44.097, lng: 137.697 },
    { lat: 43.17, lng: 137.509 },
    { lat: 42.265, lng: 137.274 },
    { lat: 42.193, lng: 137.247 },
    { lat: 41.38, lng: 136.954 },
    { lat: 40.555, lng: 136.607 },
    { lat: 40.514, lng: 136.594 },
    { lat: 39.664, lng: 136.278 },
    { lat: 38.983, lng: 135.99 },
    { lat: 38.831, lng: 135.928 },
    { lat: 38.012, lng: 135.597 },
    { lat: 37.362, lng: 135.403 },
    { lat: 37.206, lng: 135.357 },
    { lat: 36.411, lng: 135.235 },
    { lat: 35.626, lng: 135.241 },
    { lat: 34.852, lng: 135.356 },
    { lat: 34.087, lng: 135.539 },
    { lat: 33.333, lng: 135.745 },
    { lat: 33.202, lng: 135.779 },
    { lat: 32.589, lng: 135.918 },
    { lat: 31.855, lng: 136.042 },
    { lat: 31.131, lng: 136.097 },
    { lat: 30.416, lng: 136.04 },
    { lat: 30.265, lng: 136.001 },
    { lat: 29.711, lng: 135.815 },
    { lat: 29.114, lng: 135.418 },
    { lat: 29.017, lng: 135.341 },
    { lat: 28.527, lng: 134.801 },
    { lat: 28.333, lng: 134.554 },
    { lat: 28.078, lng: 134.179 },
    { lat: 27.66, lng: 133.621 },
    { lat: 27.613, lng: 133.562 },
    { lat: 26.997, lng: 132.964 },
    { lat: 26.99, lng: 132.958 },
    { lat: 26.323, lng: 132.504 },
    { lat: 26.127, lng: 132.394 },
    { lat: 25.66, lng: 132.157 },
    { lat: 25.052, lng: 131.851 },
    { lat: 25.002, lng: 131.824 },
    { lat: 24.351, lng: 131.424 },
    { lat: 24.184, lng: 131.293 },
    { lat: 23.708, lng: 130.883 },
    { lat: 23.551, lng: 130.715 },
    { lat: 23.071, lng: 130.213 },
    { lat: 23.0, lng: 130.132 },
    { lat: 22.543, lng: 129.542 },
    { lat: 22.442, lng: 129.4 },
    { lat: 22.103, lng: 128.951 },
    { lat: 21.817, lng: 128.611 },
    { lat: 21.59, lng: 128.369 },
    { lat: 21.194, lng: 127.956 },
    { lat: 21.019, lng: 127.796 },
    { lat: 20.574, lng: 127.353 },
    { lat: 20.442, lng: 127.224 },
    { lat: 19.958, lng: 126.702 },
    { lat: 19.909, lng: 126.648 },
    { lat: 19.415, lng: 126.067 },
    { lat: 19.348, lng: 125.989 },
    { lat: 18.912, lng: 125.488 },
    { lat: 18.74, lng: 125.289 },
    { lat: 18.407, lng: 124.909 },
    { lat: 18.135, lng: 124.625 },
    { lat: 17.828, lng: 124.338 },
    { lat: 17.53, lng: 124.108 },
    { lat: 17.038, lng: 123.792 },
    { lat: 16.925, lng: 123.73 },
    { lat: 16.321, lng: 123.459 },
    { lat: 15.754, lng: 123.301 },
    { lat: 15.717, lng: 123.291 },
    { lat: 15.115, lng: 123.191 },
    { lat: 14.514, lng: 123.139 },
    { lat: 13.916, lng: 123.108 },
    { lat: 13.32, lng: 123.09 },
    { lat: 12.725, lng: 123.099 },
    { lat: 12.132, lng: 123.142 },
    { lat: 11.541, lng: 123.197 },
    { lat: 10.952, lng: 123.27 },
    { lat: 10.365, lng: 123.349 },
    { lat: 9.779, lng: 123.43 },
    { lat: 9.195, lng: 123.545 },
    { lat: 8.611, lng: 123.757 },
    { lat: 8.322, lng: 123.921 },
    { lat: 8.028, lng: 124.12 },
    { lat: 7.508, lng: 124.577 },
    { lat: 7.445, lng: 124.644 },
    { lat: 6.899, lng: 125.213 },
    { lat: 6.863, lng: 125.249 },
    { lat: 6.285, lng: 125.671 },
    { lat: 5.884, lng: 125.857 },
    { lat: 5.709, lng: 125.927 },
    { lat: 5.135, lng: 126.074 },
    { lat: 4.562, lng: 126.195 },
    { lat: 3.99, lng: 126.334 },
    { lat: 3.418, lng: 126.483 },
    { lat: 3.264, lng: 126.526 },
    { lat: 2.847, lng: 126.63 },
    { lat: 2.277, lng: 126.766 },
    { lat: 1.707, lng: 126.894 },
    { lat: 1.138, lng: 127.045 },
    { lat: 0.839, lng: 127.148 },
    { lat: 0.569, lng: 127.236 },
    { lat: 0.0, lng: 127.465 },
    { lat: -0.569, lng: 127.715 },
    { lat: -0.629, lng: 127.74 },
    { lat: -1.137, lng: 127.958 },
    { lat: -1.705, lng: 128.18 },
    { lat: -2.147, lng: 128.317 },
    { lat: -2.274, lng: 128.361 },
    { lat: -2.842, lng: 128.541 },
    { lat: -3.411, lng: 128.742 },
    { lat: -3.729, lng: 128.882 },
    { lat: -3.98, lng: 129.003 },
    { lat: -4.549, lng: 129.261 },
    { lat: -5.12, lng: 129.394 },
    { lat: -5.692, lng: 129.395 },
    { lat: -6.265, lng: 129.266 },
    { lat: -6.839, lng: 129.066 },
    { lat: -7.415, lng: 128.871 },
    { lat: -7.864, lng: 128.766 },
    { lat: -7.992, lng: 128.737 },
    { lat: -8.57, lng: 128.67 },
    { lat: -9.149, lng: 128.59 },
    { lat: -9.73, lng: 128.463 },
    { lat: -10.313, lng: 128.324 },
    { lat: -10.898, lng: 128.249 },
    { lat: -11.483, lng: 128.234 },
    { lat: -12.07, lng: 128.27 },
    { lat: -12.659, lng: 128.326 },
    { lat: -13.25, lng: 128.297 },
    { lat: -13.845, lng: 128.182 },
    { lat: -14.443, lng: 128.059 },
    { lat: -15.042, lng: 128.021 },
    { lat: -15.641, lng: 128.122 },
    { lat: -16.093, lng: 128.275 },
    { lat: -16.242, lng: 128.33 },
    { lat: -16.846, lng: 128.523 },
    { lat: -17.454, lng: 128.646 },
    { lat: -18.066, lng: 128.643 },
    { lat: -18.684, lng: 128.529 },
    { lat: -19.304, lng: 128.454 },
    { lat: -19.928, lng: 128.418 },
    { lat: -20.555, lng: 128.385 },
    { lat: -21.187, lng: 128.338 },
    { lat: -21.823, lng: 128.295 },
    { lat: -22.292, lng: 128.289 },
    { lat: -22.462, lng: 128.284 },
    { lat: -23.104, lng: 128.394 },
    { lat: -23.75, lng: 128.532 },
    { lat: -24.401, lng: 128.622 },
    { lat: -24.818, lng: 128.63 },
    { lat: -25.06, lng: 128.635 },
    { lat: -25.511, lng: 128.54 },
    { lat: -25.727, lng: 128.494 },
    { lat: -26.402, lng: 128.228 },
    { lat: -27.085, lng: 127.911 },
    { lat: -27.774, lng: 127.545 },
    { lat: -27.776, lng: 127.544 },
    { lat: -28.475, lng: 127.178 },
    { lat: -29.183, lng: 126.703 },
    { lat: -29.295, lng: 126.604 },
    { lat: -29.906, lng: 126.014 },
    { lat: -30.153, lng: 125.745 },
    { lat: -30.643, lng: 125.186 },
    { lat: -30.917, lng: 124.878 },
    { lat: -31.389, lng: 124.363 },
    { lat: -31.747, lng: 123.971 },
    { lat: -32.146, lng: 123.563 },
    { lat: -32.558, lng: 123.039 },
    { lat: -32.921, lng: 122.563 },
    { lat: -33.244, lng: 122.111 },
    { lat: -33.711, lng: 121.486 },
    { lat: -33.938, lng: 121.152 },
    { lat: -34.522, lng: 120.27 },
    { lat: -34.575, lng: 120.182 },
    { lat: -35.083, lng: 119.227 },
    { lat: -35.364, lng: 118.678 },
    { lat: -35.554, lng: 118.259 },
    { lat: -35.89, lng: 117.319 },
    { lat: -36.164, lng: 116.384 },
    { lat: -36.288, lng: 115.891 },
    { lat: -36.4, lng: 115.446 },
    { lat: -36.554, lng: 114.528 },
    { lat: -36.581, lng: 113.653 },
    { lat: -36.567, lng: 112.786 },
    { lat: -36.493, lng: 112.236 },
    { lat: -36.447, lng: 111.962 },
    { lat: -36.2, lng: 111.195 },
    { lat: -35.804, lng: 110.535 },
    { lat: -35.784, lng: 110.511 },
    { lat: -35.192, lng: 109.917 },
    { lat: -35.067, lng: 109.796 },
    { lat: -34.589, lng: 109.328 },
    { lat: -34.336, lng: 109.125 },
    { lat: -33.938, lng: 108.762 },
    { lat: -33.615, lng: 108.448 },
    { lat: -33.388, lng: 108.143 },
    { lat: -32.954, lng: 107.461 },
    { lat: -32.923, lng: 107.413 },
    { lat: -32.515, lng: 106.779 },
    { lat: -32.242, lng: 106.343 },
    { lat: -32.08, lng: 106.09 },
    { lat: -31.658, lng: 105.389 },
    { lat: -31.569, lng: 105.25 },
    { lat: -31.185, lng: 104.711 },
    { lat: -30.897, lng: 104.26 },
    { lat: -30.708, lng: 104.03 },
    { lat: -30.222, lng: 103.415 },
    { lat: -30.183, lng: 103.37 },
    { lat: -29.694, lng: 102.683 },
    { lat: -29.56, lng: 102.452 },
    { lat: -29.287, lng: 101.943 },
    { lat: -28.922, lng: 101.176 },
    { lat: -28.92, lng: 101.171 },
    { lat: -28.655, lng: 100.33 },
    { lat: -28.41, lng: 99.463 },
    { lat: -28.331, lng: 99.216 },
    { lat: -28.11, lng: 98.615 },
    { lat: -27.788, lng: 97.765 },
    { lat: -27.725, lng: 97.578 },
    { lat: -27.452, lng: 96.91 },
    { lat: -27.107, lng: 96.199 },
    { lat: -27.023, lng: 96.098 },
    { lat: -26.45, lng: 95.53 },
    { lat: -26.319, lng: 95.451 },
    { lat: -25.782, lng: 95.105 },
    { lat: -25.435, lng: 94.904 },
    { lat: -25.115, lng: 94.724 },
    { lat: -24.582, lng: 94.318 },
    { lat: -24.459, lng: 94.241 },
    { lat: -23.85, lng: 93.638 },
    { lat: -23.815, lng: 93.605 },
    { lat: -23.196, lng: 92.889 },
    { lat: -23.179, lng: 92.87 },
    { lat: -22.544, lng: 92.171 },
    { lat: -22.51, lng: 92.139 },
    { lat: -21.904, lng: 91.604 },
    { lat: -21.714, lng: 91.435 },
    { lat: -21.263, lng: 91.116 },
    { lat: -20.764, lng: 90.798 },
    { lat: -20.619, lng: 90.718 },
    { lat: -19.978, lng: 90.327 },
    { lat: -19.723, lng: 90.183 },
    { lat: -19.337, lng: 89.992 },
    { lat: -18.698, lng: 89.654 },
    { lat: -18.597, lng: 89.579 },
    { lat: -18.064, lng: 89.261 },
    { lat: -17.587, lng: 88.869 },
    { lat: -17.438, lng: 88.744 },
    { lat: -16.816, lng: 88.132 },
    { lat: -16.725, lng: 88.036 },
    { lat: -16.196, lng: 87.521 },
    { lat: -15.824, lng: 87.18 },
    { lat: -15.575, lng: 86.962 },
    { lat: -14.951, lng: 86.477 },
    { lat: -14.759, lng: 86.36 },
    { lat: -14.324, lng: 86.136 },
    { lat: -13.695, lng: 85.863 },
    { lat: -13.313, lng: 85.666 },
    { lat: -13.069, lng: 85.529 },
    { lat: -12.447, lng: 85.118 },
    { lat: -12.103, lng: 84.777 },
    { lat: -11.832, lng: 84.496 },
    { lat: -11.243, lng: 83.65 },
    { lat: -11.225, lng: 83.618 },
    { lat: -10.622, lng: 82.509 },
    { lat: -10.54, lng: 82.368 },
    { lat: -10.015, lng: 81.539 },
    { lat: -9.734, lng: 81.032 },
    { lat: -9.411, lng: 80.342 },
    { lat: -9.057, lng: 79.515 },
    { lat: -8.809, lng: 79.008 },
    { lat: -8.23, lng: 77.908 },
    { lat: -8.199, lng: 77.858 },
    { lat: -7.581, lng: 77.031 },
    { lat: -6.959, lng: 76.296 },
    { lat: -6.951, lng: 76.289 },
    { lat: -6.332, lng: 75.734 },
    { lat: -5.704, lng: 75.227 },
    { lat: -5.076, lng: 74.671 },
    { lat: -5.027, lng: 74.608 },
    { lat: -4.449, lng: 73.902 },
    { lat: -3.822, lng: 72.934 },
    { lat: -3.488, lng: 72.285 },
    { lat: -3.195, lng: 71.703 },
    { lat: -2.565, lng: 70.348 },
    { lat: -2.107, lng: 69.031 },
    { lat: -1.933, lng: 68.503 },
    { lat: -1.295, lng: 66.577 },
    { lat: -0.652, lng: 63.965 },
    { lat: -0.37, lng: 61.219 },
    { lat: -0.36, lng: 60.841 },
  ],
  c4: [
    { lat: 15.99, lng: 61.2 },
    { lat: 16.535, lng: 64.187 },
    { lat: 16.755, lng: 65.213 },
    { lat: 17.108, lng: 66.59 },
    { lat: 17.7, lng: 68.491 },
    { lat: 17.71, lng: 68.521 },
    { lat: 18.306, lng: 70.013 },
    { lat: 18.687, lng: 70.695 },
    { lat: 18.932, lng: 71.103 },
    { lat: 19.574, lng: 71.883 },
    { lat: 19.857, lng: 72.092 },
    { lat: 20.23, lng: 72.418 },
    { lat: 20.904, lng: 72.63 },
    { lat: 21.5, lng: 72.449 },
    { lat: 21.606, lng: 72.426 },
    { lat: 22.331, lng: 71.896 },
    { lat: 23.086, lng: 70.957 },
    { lat: 23.867, lng: 69.745 },
    { lat: 24.656, lng: 68.519 },
    { lat: 25.007, lng: 68.156 },
    { lat: 25.413, lng: 67.924 },
    { lat: 26.095, lng: 68.54 },
    { lat: 26.257, lng: 68.95 },
    { lat: 26.724, lng: 69.936 },
    { lat: 26.961, lng: 71.01 },
    { lat: 27.262, lng: 72.637 },
    { lat: 27.355, lng: 73.375 },
    { lat: 27.539, lng: 75.754 },
    { lat: 27.632, lng: 77.659 },
    { lat: 27.643, lng: 77.942 },
    { lat: 27.67, lng: 79.995 },
    { lat: 27.755, lng: 81.784 },
    { lat: 27.847, lng: 83.428 },
    { lat: 27.889, lng: 84.264 },
    { lat: 27.932, lng: 84.972 },
    { lat: 28.019, lng: 86.426 },
    { lat: 27.985, lng: 87.919 },
    { lat: 27.928, lng: 89.358 },
    { lat: 27.841, lng: 90.754 },
    { lat: 27.671, lng: 92.153 },
    { lat: 27.358, lng: 93.594 },
    { lat: 27.242, lng: 94.019 },
    { lat: 26.956, lng: 95.029 },
    { lat: 26.42, lng: 96.482 },
    { lat: 26.389, lng: 96.55 },
    { lat: 25.651, lng: 97.998 },
    { lat: 25.611, lng: 98.073 },
    { lat: 24.912, lng: 99.414 },
    { lat: 24.846, lng: 99.554 },
    { lat: 24.344, lng: 100.676 },
    { lat: 24.074, lng: 101.411 },
    { lat: 23.94, lng: 101.812 },
    { lat: 23.745, lng: 102.822 },
    { lat: 23.695, lng: 103.746 },
    { lat: 23.749, lng: 104.61 },
    { lat: 23.878, lng: 105.427 },
    { lat: 23.879, lng: 105.431 },
    { lat: 24.082, lng: 106.212 },
    { lat: 24.358, lng: 106.956 },
    { lat: 24.464, lng: 107.196 },
    { lat: 24.671, lng: 107.679 },
    { lat: 24.967, lng: 108.401 },
    { lat: 25.067, lng: 108.66 },
    { lat: 25.261, lng: 109.118 },
    { lat: 25.529, lng: 109.837 },
    { lat: 25.67, lng: 110.248 },
    { lat: 25.775, lng: 110.558 },
    { lat: 26.022, lng: 111.274 },
    { lat: 26.249, lng: 111.99 },
    { lat: 26.267, lng: 112.06 },
    { lat: 26.475, lng: 112.702 },
    { lat: 26.826, lng: 113.369 },
    { lat: 26.889, lng: 113.448 },
    { lat: 27.511, lng: 113.924 },
    { lat: 27.555, lng: 113.945 },
    { lat: 28.243, lng: 114.064 },
    { lat: 28.944, lng: 114.025 },
    { lat: 29.656, lng: 113.913 },
    { lat: 30.019, lng: 113.851 },
    { lat: 30.376, lng: 113.788 },
    { lat: 31.103, lng: 113.684 },
    { lat: 31.836, lng: 113.634 },
    { lat: 32.574, lng: 113.648 },
    { lat: 32.662, lng: 113.654 },
    { lat: 33.316, lng: 113.758 },
    { lat: 34.067, lng: 113.858 },
    { lat: 34.171, lng: 113.864 },
    { lat: 34.83, lng: 113.933 },
    { lat: 35.611, lng: 113.852 },
    { lat: 36.411, lng: 113.644 },
    { lat: 36.945, lng: 113.483 },
    { lat: 37.226, lng: 113.392 },
    { lat: 38.053, lng: 113.158 },
    { lat: 38.889, lng: 112.997 },
    { lat: 39.734, lng: 112.921 },
    { lat: 39.841, lng: 112.926 },
    { lat: 40.584, lng: 113.004 },
    { lat: 41.151, lng: 113.143 },
    { lat: 41.44, lng: 113.251 },
    { lat: 42.019, lng: 113.602 },
    { lat: 42.288, lng: 113.875 },
    { lat: 42.557, lng: 114.248 },
    { lat: 42.948, lng: 114.977 },
    { lat: 43.102, lng: 115.306 },
    { lat: 43.265, lng: 115.748 },
    { lat: 43.476, lng: 116.574 },
    { lat: 43.637, lng: 117.423 },
    { lat: 43.811, lng: 118.262 },
    { lat: 43.84, lng: 118.369 },
    { lat: 44.028, lng: 119.079 },
    { lat: 44.245, lng: 119.896 },
    { lat: 44.473, lng: 120.707 },
    { lat: 44.627, lng: 121.173 },
    { lat: 44.765, lng: 121.492 },
    { lat: 45.235, lng: 122.207 },
    { lat: 45.519, lng: 122.524 },
    { lat: 45.989, lng: 122.817 },
    { lat: 46.478, lng: 123.066 },
    { lat: 47.19, lng: 123.256 },
    { lat: 47.477, lng: 123.337 },
    { lat: 48.517, lng: 123.377 },
    { lat: 49.602, lng: 123.188 },
    { lat: 49.614, lng: 123.184 },
    { lat: 50.738, lng: 122.735 },
    { lat: 51.23, lng: 122.427 },
    { lat: 51.935, lng: 121.976 },
    { lat: 53.202, lng: 120.865 },
    { lat: 54.545, lng: 119.519 },
    { lat: 54.677, lng: 119.39 },
    { lat: 55.956, lng: 118.189 },
    { lat: 57.452, lng: 116.8 },
    { lat: 59.049, lng: 115.344 },
    { lat: 60.752, lng: 114.002 },
    { lat: 61.684, lng: 113.478 },
    { lat: 62.562, lng: 113.001 },
    { lat: 63.702, lng: 112.712 },
    { lat: 64.494, lng: 112.55 },
    { lat: 65.035, lng: 112.71 },
    { lat: 65.823, lng: 113.429 },
    { lat: 66.472, lng: 113.878 },
    { lat: 66.703, lng: 114.066 },
    { lat: 67.838, lng: 114.397 },
    { lat: 68.781, lng: 115.029 },
    { lat: 68.792, lng: 115.053 },
    { lat: 69.321, lng: 116.269 },
    { lat: 70.248, lng: 117.038 },
    { lat: 71.346, lng: 117.635 },
    { lat: 71.417, lng: 118.486 },
    { lat: 71.497, lng: 119.604 },
    { lat: 71.154, lng: 121.131 },
    { lat: 70.818, lng: 122.507 },
    { lat: 70.151, lng: 125.148 },
    { lat: 69.332, lng: 127.686 },
    { lat: 67.592, lng: 130.444 },
    { lat: 67.5, lng: 130.552 },
    { lat: 65.135, lng: 132.83 },
    { lat: 64.109, lng: 133.503 },
    { lat: 63.049, lng: 134.123 },
    { lat: 61.199, lng: 134.816 },
    { lat: 59.518, lng: 135.142 },
    { lat: 57.964, lng: 135.288 },
    { lat: 56.509, lng: 135.44 },
    { lat: 55.133, lng: 135.763 },
    { lat: 53.821, lng: 136.431 },
    { lat: 53.154, lng: 136.991 },
    { lat: 52.564, lng: 137.508 },
    { lat: 51.923, lng: 138.117 },
    { lat: 51.363, lng: 138.542 },
    { lat: 50.463, lng: 139.189 },
    { lat: 50.214, lng: 139.347 },
    { lat: 49.108, lng: 139.862 },
    { lat: 48.042, lng: 140.095 },
    { lat: 47.009, lng: 140.093 },
    { lat: 46.006, lng: 139.961 },
    { lat: 45.032, lng: 139.702 },
    { lat: 44.552, lng: 139.52 },
    { lat: 44.083, lng: 139.285 },
    { lat: 43.158, lng: 138.807 },
    { lat: 43.112, lng: 138.781 },
    { lat: 42.253, lng: 138.467 },
    { lat: 41.367, lng: 138.104 },
    { lat: 41.357, lng: 138.099 },
    { lat: 40.501, lng: 137.672 },
    { lat: 40.078, lng: 137.416 },
    { lat: 39.653, lng: 137.199 },
    { lat: 38.828, lng: 136.757 },
    { lat: 38.82, lng: 136.754 },
    { lat: 38.001, lng: 136.391 },
    { lat: 37.351, lng: 136.141 },
    { lat: 37.196, lng: 136.089 },
    { lat: 36.401, lng: 135.92 },
    { lat: 35.617, lng: 135.888 },
    { lat: 34.843, lng: 135.969 },
    { lat: 34.08, lng: 136.116 },
    { lat: 33.326, lng: 136.281 },
    { lat: 32.583, lng: 136.429 },
    { lat: 31.849, lng: 136.56 },
    { lat: 31.697, lng: 136.578 },
    { lat: 31.125, lng: 136.646 },
    { lat: 30.615, lng: 136.649 },
    { lat: 30.41, lng: 136.648 },
    { lat: 29.704, lng: 136.513 },
    { lat: 29.007, lng: 136.183 },
    { lat: 28.889, lng: 136.094 },
    { lat: 28.322, lng: 135.501 },
    { lat: 28.306, lng: 135.476 },
    { lat: 27.876, lng: 134.852 },
    { lat: 27.648, lng: 134.521 },
    { lat: 27.458, lng: 134.23 },
    { lat: 26.98, lng: 133.651 },
    { lat: 26.952, lng: 133.621 },
    { lat: 26.314, lng: 133.083 },
    { lat: 26.242, lng: 133.033 },
    { lat: 25.652, lng: 132.676 },
    { lat: 25.272, lng: 132.475 },
    { lat: 24.995, lng: 132.329 },
    { lat: 24.343, lng: 131.98 },
    { lat: 24.258, lng: 131.926 },
    { lat: 23.698, lng: 131.486 },
    { lat: 23.561, lng: 131.353 },
    { lat: 23.061, lng: 130.812 },
    { lat: 23.025, lng: 130.767 },
    { lat: 22.509, lng: 130.182 },
    { lat: 22.43, lng: 130.082 },
    { lat: 22.046, lng: 129.594 },
    { lat: 21.805, lng: 129.284 },
    { lat: 21.567, lng: 129.009 },
    { lat: 21.182, lng: 128.603 },
    { lat: 20.998, lng: 128.434 },
    { lat: 20.561, lng: 128.033 },
    { lat: 20.377, lng: 127.867 },
    { lat: 19.945, lng: 127.443 },
    { lat: 19.795, lng: 127.297 },
    { lat: 19.333, lng: 126.794 },
    { lat: 19.266, lng: 126.721 },
    { lat: 18.769, lng: 126.142 },
    { lat: 18.725, lng: 126.091 },
    { lat: 18.273, lng: 125.563 },
    { lat: 18.12, lng: 125.392 },
    { lat: 17.732, lng: 124.988 },
    { lat: 17.517, lng: 124.786 },
    { lat: 17.081, lng: 124.426 },
    { lat: 16.914, lng: 124.313 },
    { lat: 16.311, lng: 123.982 },
    { lat: 16.099, lng: 123.899 },
    { lat: 15.709, lng: 123.766 },
    { lat: 15.107, lng: 123.632 },
    { lat: 14.508, lng: 123.558 },
    { lat: 13.91, lng: 123.511 },
    { lat: 13.791, lng: 123.505 },
    { lat: 13.314, lng: 123.48 },
    { lat: 12.72, lng: 123.473 },
    { lat: 12.127, lng: 123.509 },
    { lat: 11.536, lng: 123.57 },
    { lat: 10.947, lng: 123.653 },
    { lat: 10.36, lng: 123.754 },
    { lat: 10.045, lng: 123.814 },
    { lat: 9.774, lng: 123.87 },
    { lat: 9.19, lng: 124.053 },
    { lat: 8.605, lng: 124.352 },
    { lat: 8.352, lng: 124.533 },
    { lat: 8.022, lng: 124.838 },
    { lat: 7.702, lng: 125.177 },
    { lat: 7.438, lng: 125.485 },
    { lat: 7.107, lng: 125.809 },
    { lat: 6.858, lng: 126.036 },
    { lat: 6.281, lng: 126.361 },
    { lat: 5.987, lng: 126.454 },
    { lat: 5.706, lng: 126.531 },
    { lat: 5.132, lng: 126.612 },
    { lat: 4.56, lng: 126.675 },
    { lat: 3.988, lng: 126.769 },
    { lat: 3.417, lng: 126.886 },
    { lat: 2.846, lng: 127.021 },
    { lat: 2.353, lng: 127.134 },
    { lat: 2.276, lng: 127.152 },
    { lat: 1.707, lng: 127.278 },
    { lat: 1.137, lng: 127.426 },
    { lat: 0.569, lng: 127.613 },
    { lat: 0.256, lng: 127.741 },
    { lat: 0.0, lng: 127.845 },
    { lat: -0.568, lng: 128.107 },
    { lat: -1.041, lng: 128.326 },
    { lat: -1.136, lng: 128.376 },
    { lat: -1.705, lng: 128.644 },
    { lat: -2.273, lng: 128.866 },
    { lat: -2.375, lng: 128.902 },
    { lat: -2.841, lng: 129.089 },
    { lat: -3.409, lng: 129.335 },
    { lat: -3.688, lng: 129.468 },
    { lat: -3.978, lng: 129.604 },
    { lat: -4.548, lng: 129.798 },
    { lat: -5.118, lng: 129.879 },
    { lat: -5.689, lng: 129.865 },
    { lat: -6.263, lng: 129.732 },
    { lat: -6.837, lng: 129.525 },
    { lat: -7.228, lng: 129.38 },
    { lat: -7.413, lng: 129.312 },
    { lat: -7.989, lng: 129.169 },
    { lat: -8.567, lng: 129.085 },
    { lat: -9.146, lng: 129.007 },
    { lat: -9.727, lng: 128.906 },
    { lat: -10.309, lng: 128.794 },
    { lat: -10.893, lng: 128.718 },
    { lat: -11.479, lng: 128.69 },
    { lat: -12.065, lng: 128.732 },
    { lat: -12.654, lng: 128.798 },
    { lat: -13.245, lng: 128.813 },
    { lat: -13.838, lng: 128.772 },
    { lat: -14.435, lng: 128.702 },
    { lat: -15.033, lng: 128.678 },
    { lat: -15.633, lng: 128.792 },
    { lat: -15.91, lng: 128.904 },
    { lat: -16.233, lng: 129.052 },
    { lat: -16.835, lng: 129.334 },
    { lat: -17.12, lng: 129.421 },
    { lat: -17.441, lng: 129.522 },
    { lat: -18.053, lng: 129.55 },
    { lat: -18.67, lng: 129.438 },
    { lat: -19.291, lng: 129.302 },
    { lat: -19.915, lng: 129.248 },
    { lat: -20.54, lng: 129.296 },
    { lat: -21.17, lng: 129.327 },
    { lat: -21.804, lng: 129.328 },
    { lat: -22.444, lng: 129.314 },
    { lat: -23.087, lng: 129.309 },
    { lat: -23.734, lng: 129.337 },
    { lat: -24.061, lng: 129.373 },
    { lat: -24.386, lng: 129.398 },
    { lat: -25.045, lng: 129.372 },
    { lat: -25.711, lng: 129.246 },
    { lat: -26.148, lng: 129.117 },
    { lat: -26.385, lng: 129.04 },
    { lat: -27.066, lng: 128.758 },
    { lat: -27.756, lng: 128.425 },
    { lat: -28.391, lng: 128.129 },
    { lat: -28.452, lng: 128.099 },
    { lat: -29.158, lng: 127.701 },
    { lat: -29.827, lng: 127.206 },
    { lat: -29.875, lng: 127.165 },
    { lat: -30.608, lng: 126.373 },
    { lat: -30.618, lng: 126.361 },
    { lat: -31.351, lng: 125.568 },
    { lat: -31.42, lng: 125.489 },
    { lat: -32.108, lng: 124.683 },
    { lat: -32.167, lng: 124.604 },
    { lat: -32.864, lng: 123.704 },
    { lat: -32.88, lng: 123.684 },
    { lat: -33.534, lng: 122.787 },
    { lat: -33.668, lng: 122.601 },
    { lat: -34.178, lng: 121.85 },
    { lat: -34.473, lng: 121.415 },
    { lat: -34.811, lng: 120.889 },
    { lat: -35.302, lng: 120.062 },
    { lat: -35.379, lng: 119.922 },
    { lat: -35.845, lng: 118.963 },
    { lat: -36.171, lng: 118.205 },
    { lat: -36.261, lng: 117.999 },
    { lat: -36.586, lng: 117.048 },
    { lat: -36.832, lng: 116.109 },
    { lat: -37.065, lng: 115.159 },
    { lat: -37.153, lng: 114.66 },
    { lat: -37.231, lng: 114.221 },
    { lat: -37.266, lng: 113.33 },
    { lat: -37.281, lng: 112.461 },
    { lat: -37.281, lng: 112.437 },
    { lat: -37.188, lng: 111.588 },
    { lat: -37.02, lng: 110.77 },
    { lat: -36.711, lng: 110.022 },
    { lat: -36.636, lng: 109.888 },
    { lat: -36.236, lng: 109.362 },
    { lat: -35.9, lng: 108.989 },
    { lat: -35.656, lng: 108.759 },
    { lat: -35.157, lng: 108.356 },
    { lat: -34.974, lng: 108.211 },
    { lat: -34.417, lng: 107.808 },
    { lat: -34.273, lng: 107.672 },
    { lat: -33.719, lng: 107.051 },
    { lat: -33.702, lng: 107.03 },
    { lat: -33.273, lng: 106.367 },
    { lat: -33.012, lng: 105.978 },
    { lat: -32.819, lng: 105.684 },
    { lat: -32.395, lng: 104.98 },
    { lat: -32.332, lng: 104.887 },
    { lat: -31.936, lng: 104.29 },
    { lat: -31.658, lng: 103.806 },
    { lat: -31.508, lng: 103.577 },
    { lat: -31.05, lng: 102.875 },
    { lat: -30.988, lng: 102.785 },
    { lat: -30.537, lng: 102.199 },
    { lat: -30.318, lng: 101.84 },
    { lat: -30.099, lng: 101.471 },
    { lat: -29.665, lng: 100.741 },
    { lat: -29.662, lng: 100.735 },
    { lat: -29.351, lng: 99.911 },
    { lat: -29.092, lng: 99.043 },
    { lat: -29.06, lng: 98.947 },
    { lat: -28.819, lng: 98.169 },
    { lat: -28.54, lng: 97.282 },
    { lat: -28.468, lng: 97.021 },
    { lat: -28.265, lng: 96.376 },
    { lat: -27.971, lng: 95.463 },
    { lat: -27.871, lng: 95.237 },
    { lat: -27.526, lng: 94.637 },
    { lat: -27.232, lng: 94.169 },
    { lat: -26.94, lng: 93.894 },
    { lat: -26.568, lng: 93.587 },
    { lat: -26.116, lng: 93.301 },
    { lat: -25.895, lng: 93.208 },
    { lat: -25.218, lng: 92.973 },
    { lat: -25.042, lng: 92.861 },
    { lat: -24.555, lng: 92.559 },
    { lat: -24.206, lng: 92.235 },
    { lat: -23.908, lng: 91.946 },
    { lat: -23.507, lng: 91.494 },
    { lat: -23.267, lng: 91.268 },
    { lat: -22.743, lng: 90.773 },
    { lat: -22.625, lng: 90.668 },
    { lat: -21.98, lng: 90.162 },
    { lat: -21.879, lng: 90.093 },
    { lat: -21.331, lng: 89.79 },
    { lat: -20.813, lng: 89.513 },
    { lat: -20.683, lng: 89.442 },
    { lat: -20.04, lng: 89.061 },
    { lat: -19.729, lng: 88.906 },
    { lat: -19.395, lng: 88.768 },
    { lat: -18.752, lng: 88.5 },
    { lat: -18.5, lng: 88.345 },
    { lat: -18.116, lng: 88.103 },
    { lat: -17.524, lng: 87.585 },
    { lat: -17.489, lng: 87.555 },
    { lat: -16.866, lng: 86.955 },
    { lat: -16.64, lng: 86.727 },
    { lat: -16.243, lng: 86.362 },
    { lat: -15.694, lng: 85.855 },
    { lat: -15.621, lng: 85.793 },
    { lat: -14.994, lng: 85.357 },
    { lat: -14.498, lng: 85.062 },
    { lat: -14.366, lng: 84.977 },
    { lat: -13.739, lng: 84.608 },
    { lat: -13.216, lng: 84.239 },
    { lat: -13.115, lng: 84.159 },
    { lat: -12.495, lng: 83.63 },
    { lat: -12.149, lng: 83.224 },
    { lat: -11.883, lng: 82.837 },
    { lat: -11.412, lng: 81.958 },
    { lat: -11.28, lng: 81.71 },
    { lat: -10.675, lng: 80.623 },
    { lat: -10.66, lng: 80.597 },
    { lat: -10.07, lng: 79.44 },
    { lat: -9.926, lng: 79.101 },
    { lat: -9.468, lng: 78.085 },
    { lat: -9.186, lng: 77.446 },
    { lat: -8.86, lng: 76.815 },
    { lat: -8.26, lng: 75.671 },
    { lat: -8.248, lng: 75.649 },
    { lat: -7.629, lng: 74.649 },
    { lat: -7.03, lng: 73.751 },
    { lat: -7.007, lng: 73.716 },
    { lat: -6.379, lng: 73.003 },
    { lat: -5.748, lng: 72.426 },
    { lat: -5.116, lng: 71.773 },
    { lat: -5.073, lng: 71.705 },
    { lat: -4.487, lng: 70.805 },
    { lat: -3.857, lng: 69.586 },
    { lat: -3.504, lng: 68.558 },
    { lat: -3.23, lng: 67.64 },
    { lat: -2.603, lng: 64.866 },
    { lat: -2.267, lng: 60.848 },
  ],
  c5: [
    { lat: 17.701, lng: 61.284 },
    { lat: 17.937, lng: 63.461 },
    { lat: 18.457, lng: 66.649 },
    { lat: 18.468, lng: 66.691 },
    { lat: 19.074, lng: 68.289 },
    { lat: 19.532, lng: 69.0 },
    { lat: 19.71, lng: 69.268 },
    { lat: 20.369, lng: 69.828 },
    { lat: 20.999, lng: 69.918 },
    { lat: 21.054, lng: 69.933 },
    { lat: 21.773, lng: 69.494 },
    { lat: 22.539, lng: 68.377 },
    { lat: 23.382, lng: 66.12 },
    { lat: 24.073, lng: 61.691 },
  ],
  c6: [
    { lat: 28.282, lng: 62.051 },
    { lat: 28.396, lng: 66.319 },
    { lat: 28.395, lng: 67.127 },
    { lat: 28.405, lng: 70.912 },
    { lat: 28.414, lng: 74.089 },
    { lat: 28.42, lng: 76.677 },
    { lat: 28.418, lng: 76.854 },
    { lat: 28.412, lng: 78.931 },
    { lat: 28.455, lng: 80.874 },
    { lat: 28.502, lng: 82.645 },
    { lat: 28.549, lng: 84.288 },
    { lat: 28.568, lng: 84.873 },
    { lat: 28.601, lng: 85.822 },
    { lat: 28.547, lng: 87.376 },
    { lat: 28.472, lng: 88.866 },
    { lat: 28.376, lng: 90.299 },
    { lat: 28.23, lng: 91.707 },
    { lat: 28.026, lng: 92.822 },
    { lat: 27.963, lng: 93.145 },
    { lat: 27.583, lng: 94.597 },
    { lat: 27.124, lng: 95.915 },
    { lat: 27.068, lng: 96.069 },
    { lat: 26.369, lng: 97.58 },
    { lat: 26.323, lng: 97.664 },
    { lat: 25.555, lng: 99.07 },
    { lat: 25.554, lng: 99.072 },
    { lat: 24.931, lng: 100.386 },
    { lat: 24.783, lng: 100.737 },
    { lat: 24.462, lng: 101.571 },
    { lat: 24.197, lng: 102.623 },
    { lat: 24.124, lng: 103.564 },
    { lat: 24.176, lng: 104.435 },
    { lat: 24.302, lng: 105.262 },
    { lat: 24.504, lng: 106.047 },
    { lat: 24.516, lng: 106.079 },
    { lat: 24.776, lng: 106.796 },
    { lat: 25.082, lng: 107.523 },
    { lat: 25.116, lng: 107.606 },
    { lat: 25.396, lng: 108.241 },
    { lat: 25.711, lng: 108.952 },
    { lat: 25.727, lng: 108.997 },
    { lat: 25.971, lng: 109.678 },
    { lat: 26.208, lng: 110.405 },
    { lat: 26.326, lng: 110.749 },
    { lat: 26.462, lng: 111.121 },
    { lat: 26.728, lng: 111.827 },
    { lat: 26.937, lng: 112.351 },
    { lat: 27.024, lng: 112.519 },
    { lat: 27.536, lng: 113.133 },
    { lat: 27.589, lng: 113.174 },
    { lat: 28.271, lng: 113.429 },
    { lat: 28.894, lng: 113.455 },
    { lat: 28.971, lng: 113.454 },
    { lat: 29.682, lng: 113.373 },
    { lat: 30.402, lng: 113.254 },
    { lat: 31.131, lng: 113.128 },
    { lat: 31.867, lng: 113.026 },
    { lat: 32.236, lng: 112.999 },
    { lat: 32.608, lng: 112.991 },
    { lat: 33.356, lng: 113.006 },
    { lat: 34.112, lng: 113.021 },
    { lat: 34.173, lng: 113.02 },
    { lat: 34.879, lng: 113.04 },
    { lat: 35.66, lng: 112.98 },
    { lat: 36.458, lng: 112.829 },
    { lat: 36.498, lng: 112.819 },
    { lat: 37.272, lng: 112.61 },
    { lat: 38.098, lng: 112.399 },
    { lat: 38.936, lng: 112.227 },
    { lat: 39.604, lng: 112.131 },
    { lat: 39.785, lng: 112.11 },
    { lat: 40.64, lng: 112.136 },
    { lat: 41.152, lng: 112.201 },
    { lat: 41.503, lng: 112.276 },
    { lat: 42.244, lng: 112.521 },
    { lat: 42.371, lng: 112.597 },
    { lat: 42.93, lng: 113.081 },
    { lat: 43.217, lng: 113.52 },
    { lat: 43.362, lng: 113.794 },
    { lat: 43.649, lng: 114.591 },
    { lat: 43.895, lng: 115.41 },
    { lat: 43.992, lng: 115.846 },
    { lat: 44.074, lng: 116.263 },
    { lat: 44.227, lng: 117.127 },
    { lat: 44.393, lng: 117.98 },
    { lat: 44.601, lng: 118.811 },
    { lat: 44.73, lng: 119.266 },
    { lat: 44.853, lng: 119.62 },
    { lat: 45.158, lng: 120.406 },
    { lat: 45.54, lng: 121.161 },
    { lat: 45.586, lng: 121.227 },
    { lat: 46.266, lng: 121.775 },
    { lat: 46.535, lng: 121.935 },
    { lat: 47.496, lng: 122.181 },
    { lat: 47.536, lng: 122.19 },
    { lat: 48.583, lng: 122.116 },
    { lat: 49.677, lng: 121.791 },
    { lat: 50.827, lng: 121.17 },
    { lat: 52.035, lng: 120.305 },
    { lat: 53.308, lng: 119.217 },
    { lat: 53.662, lng: 118.902 },
    { lat: 54.649, lng: 118.005 },
    { lat: 56.073, lng: 116.624 },
    { lat: 57.589, lng: 115.095 },
    { lat: 59.213, lng: 113.447 },
    { lat: 60.963, lng: 111.74 },
    { lat: 62.86, lng: 110.031 },
    { lat: 63.617, lng: 109.482 },
    { lat: 64.91, lng: 108.651 },
    { lat: 65.537, lng: 108.413 },
    { lat: 67.083, lng: 107.787 },
    { lat: 67.15, lng: 107.788 },
    { lat: 67.952, lng: 108.296 },
    { lat: 68.628, lng: 109.18 },
    { lat: 69.459, lng: 109.259 },
    { lat: 69.787, lng: 109.233 },
    { lat: 71.698, lng: 107.725 },
    { lat: 72.868, lng: 106.982 },
    { lat: 73.331, lng: 106.527 },
    { lat: 74.739, lng: 105.687 },
    { lat: 78.286, lng: 101.201 },
  ],
  c7: [
    { lat: 78.643, lng: 103.304 },
    { lat: 76.529, lng: 116.521 },
    { lat: 75.512, lng: 119.744 },
    { lat: 74.631, lng: 122.485 },
    { lat: 72.616, lng: 127.066 },
    { lat: 70.455, lng: 130.608 },
    { lat: 70.331, lng: 130.767 },
    { lat: 67.453, lng: 133.803 },
    { lat: 67.285, lng: 133.933 },
    { lat: 65.053, lng: 135.513 },
    { lat: 62.992, lng: 136.36 },
    { lat: 61.156, lng: 136.757 },
    { lat: 59.481, lng: 136.942 },
    { lat: 57.928, lng: 137.114 },
    { lat: 56.474, lng: 137.381 },
    { lat: 55.531, lng: 137.733 },
    { lat: 55.098, lng: 137.894 },
    { lat: 53.79, lng: 138.633 },
    { lat: 53.277, lng: 138.989 },
    { lat: 52.543, lng: 139.504 },
    { lat: 51.755, lng: 140.068 },
    { lat: 51.35, lng: 140.361 },
    { lat: 50.205, lng: 141.036 },
    { lat: 50.117, lng: 141.071 },
    { lat: 49.102, lng: 141.443 },
    { lat: 48.037, lng: 141.575 },
    { lat: 47.004, lng: 141.506 },
    { lat: 46.001, lng: 141.312 },
    { lat: 45.507, lng: 141.158 },
    { lat: 45.026, lng: 141.021 },
    { lat: 44.075, lng: 140.662 },
    { lat: 43.436, lng: 140.382 },
    { lat: 43.148, lng: 140.245 },
    { lat: 42.243, lng: 139.713 },
    { lat: 42.117, lng: 139.629 },
    { lat: 41.359, lng: 139.149 },
    { lat: 40.959, lng: 138.901 },
    { lat: 40.492, lng: 138.672 },
    { lat: 39.68, lng: 138.207 },
    { lat: 39.643, lng: 138.184 },
    { lat: 38.811, lng: 137.605 },
    { lat: 38.68, lng: 137.519 },
    { lat: 37.992, lng: 137.158 },
    { lat: 37.362, lng: 136.875 },
    { lat: 37.187, lng: 136.805 },
    { lat: 36.393, lng: 136.568 },
    { lat: 35.61, lng: 136.472 },
    { lat: 34.837, lng: 136.503 },
    { lat: 34.074, lng: 136.609 },
    { lat: 33.321, lng: 136.738 },
    { lat: 32.578, lng: 136.867 },
    { lat: 31.845, lng: 137.001 },
    { lat: 31.12, lng: 137.11 },
    { lat: 30.405, lng: 137.147 },
    { lat: 29.698, lng: 137.072 },
    { lat: 29.001, lng: 136.833 },
    { lat: 28.872, lng: 136.754 },
    { lat: 28.313, lng: 136.335 },
    { lat: 28.136, lng: 136.143 },
    { lat: 27.733, lng: 135.516 },
    { lat: 27.637, lng: 135.375 },
    { lat: 27.356, lng: 134.891 },
    { lat: 26.971, lng: 134.317 },
    { lat: 26.94, lng: 134.272 },
    { lat: 26.393, lng: 133.668 },
    { lat: 26.307, lng: 133.595 },
    { lat: 25.645, lng: 133.129 },
    { lat: 25.583, lng: 133.091 },
    { lat: 24.989, lng: 132.757 },
    { lat: 24.561, lng: 132.538 },
    { lat: 24.336, lng: 132.416 },
    { lat: 23.691, lng: 132.004 },
    { lat: 23.66, lng: 131.98 },
    { lat: 23.067, lng: 131.399 },
    { lat: 23.053, lng: 131.384 },
    { lat: 22.588, lng: 130.81 },
    { lat: 22.422, lng: 130.621 },
    { lat: 22.078, lng: 130.225 },
    { lat: 21.795, lng: 129.869 },
    { lat: 21.596, lng: 129.64 },
    { lat: 21.173, lng: 129.155 },
    { lat: 21.076, lng: 129.06 },
    { lat: 20.552, lng: 128.578 },
    { lat: 20.455, lng: 128.492 },
    { lat: 19.935, lng: 128.021 },
    { lat: 19.829, lng: 127.926 },
    { lat: 19.321, lng: 127.429 },
    { lat: 19.247, lng: 127.356 },
    { lat: 18.72, lng: 126.781 },
    { lat: 18.713, lng: 126.773 },
    { lat: 18.241, lng: 126.2 },
    { lat: 18.109, lng: 126.047 },
    { lat: 17.717, lng: 125.625 },
    { lat: 17.506, lng: 125.406 },
    { lat: 17.144, lng: 125.054 },
    { lat: 16.904, lng: 124.853 },
    { lat: 16.396, lng: 124.502 },
    { lat: 16.303, lng: 124.449 },
    { lat: 15.701, lng: 124.187 },
    { lat: 15.101, lng: 124.02 },
    { lat: 15.053, lng: 124.011 },
    { lat: 14.502, lng: 123.919 },
    { lat: 13.904, lng: 123.858 },
    { lat: 13.309, lng: 123.819 },
    { lat: 12.715, lng: 123.806 },
    { lat: 12.123, lng: 123.836 },
    { lat: 11.532, lng: 123.899 },
    { lat: 10.943, lng: 123.993 },
    { lat: 10.356, lng: 124.12 },
    { lat: 9.77, lng: 124.284 },
    { lat: 9.295, lng: 124.479 },
    { lat: 9.185, lng: 124.539 },
    { lat: 8.6, lng: 124.965 },
    { lat: 8.412, lng: 125.141 },
    { lat: 8.015, lng: 125.617 },
    { lat: 7.881, lng: 125.775 },
    { lat: 7.432, lng: 126.335 },
    { lat: 7.356, lng: 126.401 },
    { lat: 6.853, lng: 126.776 },
    { lat: 6.277, lng: 126.985 },
    { lat: 5.703, lng: 127.059 },
    { lat: 5.13, lng: 127.073 },
    { lat: 4.558, lng: 127.087 },
    { lat: 4.484, lng: 127.095 },
    { lat: 3.987, lng: 127.145 },
    { lat: 3.416, lng: 127.24 },
    { lat: 2.845, lng: 127.362 },
    { lat: 2.275, lng: 127.488 },
    { lat: 1.706, lng: 127.611 },
    { lat: 1.229, lng: 127.737 },
    { lat: 1.137, lng: 127.76 },
    { lat: 0.568, lng: 127.947 },
    { lat: 0.0, lng: 128.181 },
    { lat: -0.312, lng: 128.329 },
    { lat: -0.568, lng: 128.462 },
    { lat: -1.136, lng: 128.762 },
    { lat: -1.431, lng: 128.91 },
    { lat: -1.704, lng: 129.068 },
    { lat: -2.272, lng: 129.34 },
    { lat: -2.593, lng: 129.484 },
    { lat: -2.84, lng: 129.622 },
    { lat: -3.408, lng: 129.911 },
    { lat: -3.749, lng: 130.051 },
    { lat: -3.977, lng: 130.141 },
    { lat: -4.546, lng: 130.288 },
    { lat: -5.116, lng: 130.348 },
    { lat: -5.687, lng: 130.319 },
    { lat: -6.26, lng: 130.159 },
    { lat: -6.678, lng: 129.984 },
    { lat: -6.835, lng: 129.926 },
    { lat: -7.41, lng: 129.708 },
    { lat: -7.987, lng: 129.551 },
    { lat: -8.564, lng: 129.449 },
    { lat: -9.143, lng: 129.367 },
    { lat: -9.716, lng: 129.282 },
    { lat: -9.724, lng: 129.281 },
    { lat: -10.306, lng: 129.175 },
    { lat: -10.89, lng: 129.092 },
    { lat: -11.475, lng: 129.069 },
    { lat: -12.062, lng: 129.117 },
    { lat: -12.304, lng: 129.148 },
    { lat: -12.65, lng: 129.195 },
    { lat: -13.24, lng: 129.247 },
    { lat: -13.833, lng: 129.256 },
    { lat: -14.429, lng: 129.235 },
    { lat: -15.026, lng: 129.248 },
    { lat: -15.625, lng: 129.408 },
    { lat: -15.85, lng: 129.519 },
    { lat: -16.224, lng: 129.717 },
    { lat: -16.826, lng: 130.039 },
    { lat: -16.868, lng: 130.054 },
    { lat: -17.432, lng: 130.257 },
    { lat: -18.043, lng: 130.336 },
    { lat: -18.659, lng: 130.258 },
    { lat: -19.279, lng: 130.098 },
    { lat: -19.903, lng: 130.031 },
    { lat: -20.528, lng: 130.063 },
    { lat: -21.158, lng: 130.091 },
    { lat: -21.792, lng: 130.107 },
    { lat: -22.43, lng: 130.094 },
    { lat: -23.074, lng: 130.047 },
    { lat: -23.722, lng: 130.014 },
    { lat: -24.066, lng: 130.019 },
    { lat: -24.375, lng: 130.021 },
    { lat: -25.034, lng: 129.973 },
    { lat: -25.699, lng: 129.861 },
    { lat: -25.948, lng: 129.801 },
    { lat: -26.372, lng: 129.692 },
    { lat: -27.052, lng: 129.449 },
    { lat: -27.74, lng: 129.153 },
    { lat: -28.435, lng: 128.858 },
    { lat: -28.614, lng: 128.775 },
    { lat: -29.138, lng: 128.519 },
    { lat: -29.851, lng: 128.066 },
    { lat: -30.114, lng: 127.852 },
    { lat: -30.579, lng: 127.435 },
    { lat: -30.995, lng: 126.996 },
    { lat: -31.32, lng: 126.609 },
    { lat: -31.692, lng: 126.15 },
    { lat: -32.075, lng: 125.714 },
    { lat: -32.407, lng: 125.278 },
    { lat: -32.845, lng: 124.696 },
    { lat: -33.078, lng: 124.393 },
    { lat: -33.629, lng: 123.656 },
    { lat: -33.762, lng: 123.479 },
    { lat: -34.399, lng: 122.552 },
    { lat: -34.43, lng: 122.506 },
    { lat: -35.029, lng: 121.601 },
    { lat: -35.25, lng: 121.245 },
    { lat: -35.62, lng: 120.635 },
    { lat: -36.1, lng: 119.678 },
    { lat: -36.101, lng: 119.677 },
    { lat: -36.575, lng: 118.7 },
    { lat: -36.954, lng: 117.734 },
    { lat: -36.996, lng: 117.597 },
    { lat: -37.275, lng: 116.769 },
    { lat: -37.502, lng: 115.824 },
    { lat: -37.706, lng: 114.872 },
    { lat: -37.832, lng: 113.939 },
    { lat: -37.855, lng: 113.042 },
    { lat: -37.858, lng: 112.144 },
    { lat: -37.786, lng: 111.274 },
    { lat: -37.671, lng: 110.419 },
    { lat: -37.454, lng: 109.675 },
    { lat: -37.434, lng: 109.623 },
    { lat: -37.051, lng: 108.906 },
    { lat: -36.729, lng: 108.445 },
    { lat: -36.555, lng: 108.253 },
    { lat: -35.984, lng: 107.681 },
    { lat: -35.955, lng: 107.658 },
    { lat: -35.23, lng: 107.204 },
    { lat: -35.176, lng: 107.166 },
    { lat: -34.524, lng: 106.599 },
    { lat: -34.496, lng: 106.574 },
    { lat: -33.964, lng: 105.976 },
    { lat: -33.781, lng: 105.782 },
    { lat: -33.46, lng: 105.318 },
    { lat: -33.093, lng: 104.694 },
    { lat: -33.04, lng: 104.607 },
    { lat: -32.567, lng: 103.922 },
    { lat: -32.411, lng: 103.64 },
    { lat: -32.159, lng: 103.193 },
    { lat: -31.748, lng: 102.458 },
    { lat: -31.744, lng: 102.451 },
    { lat: -31.279, lng: 101.752 },
    { lat: -31.076, lng: 101.388 },
    { lat: -30.853, lng: 101.011 },
    { lat: -30.417, lng: 100.277 },
    { lat: -30.414, lng: 100.271 },
    { lat: -30.043, lng: 99.477 },
    { lat: -29.789, lng: 98.782 },
    { lat: -29.743, lng: 98.624 },
    { lat: -29.472, lng: 97.739 },
    { lat: -29.192, lng: 96.868 },
    { lat: -29.186, lng: 96.847 },
    { lat: -28.951, lng: 95.901 },
    { lat: -28.752, lng: 94.908 },
    { lat: -28.632, lng: 94.456 },
    { lat: -28.454, lng: 93.964 },
    { lat: -28.053, lng: 93.076 },
    { lat: -28.014, lng: 93.006 },
    { lat: -27.497, lng: 92.287 },
    { lat: -27.362, lng: 92.123 },
    { lat: -26.682, lng: 91.75 },
    { lat: -26.615, lng: 91.729 },
    { lat: -25.993, lng: 91.605 },
    { lat: -25.307, lng: 91.467 },
    { lat: -25.305, lng: 91.465 },
    { lat: -24.644, lng: 91.038 },
    { lat: -24.444, lng: 90.843 },
    { lat: -23.994, lng: 90.434 },
    { lat: -23.669, lng: 90.133 },
    { lat: -23.346, lng: 89.855 },
    { lat: -22.852, lng: 89.426 },
    { lat: -22.7, lng: 89.302 },
    { lat: -22.049, lng: 88.878 },
    { lat: -21.884, lng: 88.797 },
    { lat: -21.395, lng: 88.567 },
    { lat: -20.75, lng: 88.244 },
    { lat: -20.744, lng: 88.241 },
    { lat: -20.097, lng: 87.917 },
    { lat: -19.508, lng: 87.718 },
    { lat: -19.446, lng: 87.703 },
    { lat: -18.8, lng: 87.471 },
    { lat: -18.284, lng: 87.128 },
    { lat: -18.164, lng: 87.041 },
    { lat: -17.536, lng: 86.491 },
    { lat: -17.349, lng: 86.307 },
    { lat: -16.91, lng: 85.924 },
    { lat: -16.404, lng: 85.442 },
    { lat: -16.286, lng: 85.334 },
    { lat: -15.66, lng: 84.81 },
    { lat: -15.348, lng: 84.584 },
    { lat: -15.032, lng: 84.346 },
    { lat: -14.405, lng: 83.926 },
    { lat: -14.139, lng: 83.745 },
    { lat: -13.779, lng: 83.464 },
    { lat: -13.157, lng: 82.923 },
    { lat: -13.007, lng: 82.78 },
    { lat: -12.54, lng: 82.247 },
    { lat: -12.127, lng: 81.589 },
    { lat: -11.933, lng: 81.232 },
    { lat: -11.412, lng: 80.205 },
    { lat: -11.329, lng: 80.052 },
    { lat: -10.724, lng: 78.895 },
    { lat: -10.643, lng: 78.722 },
    { lat: -10.12, lng: 77.594 },
    { lat: -9.898, lng: 77.058 },
    { lat: -9.514, lng: 76.23 },
    { lat: -9.048, lng: 75.231 },
    { lat: -8.905, lng: 74.938 },
    { lat: -8.291, lng: 73.679 },
    { lat: -8.043, lng: 73.177 },
    { lat: -7.676, lng: 72.35 },
    { lat: -7.054, lng: 71.232 },
    { lat: -6.763, lng: 70.789 },
    { lat: -6.426, lng: 70.257 },
    { lat: -5.794, lng: 69.423 },
    { lat: -5.161, lng: 68.549 },
    { lat: -4.828, lng: 67.84 },
    { lat: -4.532, lng: 67.108 },
    { lat: -3.907, lng: 64.849 },
    { lat: -3.567, lng: 60.859 },
  ],
  c8: [
    { lat: 15.577, lng: 134.211 },
    { lat: 15.246, lng: 134.359 },
    { lat: 14.977, lng: 134.422 },
    { lat: 14.683, lng: 134.383 },
    { lat: 14.382, lng: 134.31 },
    { lat: 13.839, lng: 133.827 },
    { lat: 13.793, lng: 133.697 },
    { lat: 13.701, lng: 133.242 },
    { lat: 13.799, lng: 132.897 },
    { lat: 13.934, lng: 132.639 },
    { lat: 14.398, lng: 132.243 },
    { lat: 14.995, lng: 132.212 },
    { lat: 15.592, lng: 132.495 },
    { lat: 15.657, lng: 132.551 },
    { lat: 16.015, lng: 133.131 },
    { lat: 15.945, lng: 133.732 },
    { lat: 15.577, lng: 134.211 },
  ],
  c9: [
    { lat: 80.237, lng: 117.008 },
    { lat: 74.499, lng: 129.903 },
    { lat: 74.215, lng: 130.295 },
    { lat: 70.721, lng: 134.389 },
    { lat: 70.274, lng: 134.778 },
    { lat: 67.372, lng: 136.647 },
    { lat: 65.008, lng: 137.596 },
    { lat: 64.815, lng: 137.647 },
    { lat: 62.961, lng: 138.091 },
    { lat: 61.13, lng: 138.354 },
    { lat: 59.457, lng: 138.504 },
    { lat: 57.907, lng: 138.678 },
    { lat: 56.454, lng: 138.968 },
    { lat: 55.081, lng: 139.461 },
    { lat: 54.23, lng: 139.943 },
    { lat: 53.777, lng: 140.209 },
    { lat: 52.631, lng: 141.013 },
    { lat: 52.534, lng: 141.086 },
    { lat: 51.346, lng: 141.914 },
    { lat: 51.181, lng: 142.0 },
    { lat: 50.204, lng: 142.45 },
    { lat: 49.103, lng: 142.762 },
    { lat: 48.038, lng: 142.844 },
    { lat: 47.005, lng: 142.776 },
    { lat: 46.001, lng: 142.575 },
    { lat: 45.024, lng: 142.243 },
    { lat: 44.505, lng: 142.0 },
    { lat: 44.073, lng: 141.812 },
    { lat: 43.145, lng: 141.351 },
    { lat: 42.879, lng: 141.199 },
    { lat: 42.239, lng: 140.841 },
    { lat: 41.652, lng: 140.433 },
    { lat: 41.353, lng: 140.23 },
    { lat: 40.594, lng: 139.691 },
    { lat: 40.486, lng: 139.621 },
    { lat: 39.636, lng: 139.049 },
    { lat: 39.539, lng: 138.974 },
    { lat: 38.803, lng: 138.482 },
    { lat: 38.498, lng: 138.278 },
    { lat: 37.984, lng: 137.93 },
    { lat: 37.451, lng: 137.604 },
    { lat: 37.179, lng: 137.475 },
    { lat: 36.386, lng: 137.179 },
    { lat: 35.604, lng: 137.007 },
    { lat: 35.594, lng: 137.007 },
    { lat: 34.832, lng: 136.979 },
    { lat: 34.069, lng: 137.041 },
    { lat: 33.317, lng: 137.137 },
    { lat: 33.079, lng: 137.174 },
    { lat: 32.574, lng: 137.255 },
    { lat: 31.841, lng: 137.395 },
    { lat: 31.117, lng: 137.514 },
    { lat: 30.401, lng: 137.564 },
    { lat: 29.695, lng: 137.526 },
    { lat: 29.145, lng: 137.398 },
    { lat: 28.996, lng: 137.352 },
    { lat: 28.307, lng: 136.943 },
    { lat: 28.148, lng: 136.796 },
    { lat: 27.629, lng: 136.222 },
    { lat: 27.597, lng: 136.176 },
    { lat: 27.279, lng: 135.547 },
    { lat: 26.962, lng: 135.004 },
    { lat: 26.918, lng: 134.923 },
    { lat: 26.498, lng: 134.307 },
    { lat: 26.3, lng: 134.092 },
    { lat: 25.891, lng: 133.709 },
    { lat: 25.64, lng: 133.529 },
    { lat: 25.007, lng: 133.14 },
    { lat: 24.983, lng: 133.127 },
    { lat: 24.331, lng: 132.785 },
    { lat: 23.988, lng: 132.588 },
    { lat: 23.685, lng: 132.395 },
    { lat: 23.199, lng: 132.021 },
    { lat: 23.045, lng: 131.87 },
    { lat: 22.658, lng: 131.436 },
    { lat: 22.414, lng: 131.129 },
    { lat: 22.199, lng: 130.846 },
    { lat: 21.787, lng: 130.369 },
    { lat: 21.69, lng: 130.263 },
    { lat: 21.186, lng: 129.681 },
    { lat: 21.165, lng: 129.657 },
    { lat: 20.612, lng: 129.107 },
    { lat: 20.544, lng: 129.045 },
    { lat: 19.979, lng: 128.541 },
    { lat: 19.927, lng: 128.493 },
    { lat: 19.354, lng: 127.975 },
    { lat: 19.313, lng: 127.935 },
    { lat: 18.779, lng: 127.405 },
    { lat: 18.703, lng: 127.322 },
    { lat: 18.275, lng: 126.828 },
    { lat: 18.099, lng: 126.617 },
    { lat: 17.776, lng: 126.25 },
    { lat: 17.496, lng: 125.951 },
    { lat: 17.232, lng: 125.677 },
    { lat: 16.896, lng: 125.351 },
    { lat: 16.613, lng: 125.112 },
    { lat: 16.295, lng: 124.883 },
    { lat: 15.722, lng: 124.574 },
    { lat: 15.695, lng: 124.561 },
    { lat: 15.095, lng: 124.362 },
    { lat: 14.497, lng: 124.237 },
    { lat: 13.9, lng: 124.164 },
    { lat: 13.661, lng: 124.146 },
    { lat: 13.304, lng: 124.119 },
    { lat: 12.711, lng: 124.102 },
    { lat: 12.119, lng: 124.127 },
    { lat: 11.528, lng: 124.191 },
    { lat: 10.94, lng: 124.294 },
    { lat: 10.526, lng: 124.399 },
    { lat: 10.352, lng: 124.451 },
    { lat: 9.766, lng: 124.69 },
    { lat: 9.18, lng: 125.044 },
    { lat: 9.103, lng: 125.103 },
    { lat: 8.594, lng: 125.661 },
    { lat: 8.526, lng: 125.742 },
    { lat: 8.124, lng: 126.367 },
    { lat: 8.008, lng: 126.56 },
    { lat: 7.65, lng: 126.989 },
    { lat: 7.426, lng: 127.199 },
    { lat: 6.849, lng: 127.464 },
    { lat: 6.274, lng: 127.551 },
    { lat: 5.7, lng: 127.539 },
    { lat: 5.128, lng: 127.484 },
    { lat: 4.557, lng: 127.458 },
    { lat: 3.985, lng: 127.482 },
    { lat: 3.415, lng: 127.555 },
    { lat: 2.844, lng: 127.664 },
    { lat: 2.57, lng: 127.724 },
    { lat: 2.275, lng: 127.785 },
    { lat: 1.706, lng: 127.908 },
    { lat: 1.137, lng: 128.057 },
    { lat: 0.568, lng: 128.244 },
    { lat: 0.362, lng: 128.329 },
    { lat: 0.0, lng: 128.492 },
    { lat: -0.568, lng: 128.789 },
    { lat: -0.798, lng: 128.914 },
    { lat: -1.136, lng: 129.129 },
    { lat: -1.703, lng: 129.459 },
    { lat: -1.77, lng: 129.492 },
    { lat: -2.271, lng: 129.822 },
    { lat: -2.695, lng: 130.065 },
    { lat: -2.839, lng: 130.17 },
    { lat: -3.406, lng: 130.472 },
    { lat: -3.917, lng: 130.63 },
    { lat: -3.975, lng: 130.65 },
    { lat: -4.544, lng: 130.78 },
    { lat: -5.114, lng: 130.822 },
    { lat: -5.686, lng: 130.765 },
    { lat: -6.22, lng: 130.581 },
    { lat: -6.258, lng: 130.57 },
    { lat: -6.833, lng: 130.324 },
    { lat: -7.408, lng: 130.069 },
    { lat: -7.773, lng: 129.949 },
    { lat: -7.985, lng: 129.887 },
    { lat: -8.562, lng: 129.77 },
    { lat: -9.141, lng: 129.688 },
    { lat: -9.721, lng: 129.615 },
    { lat: -10.303, lng: 129.519 },
    { lat: -10.887, lng: 129.438 },
    { lat: -11.472, lng: 129.42 },
    { lat: -12.058, lng: 129.468 },
    { lat: -12.646, lng: 129.55 },
    { lat: -13.236, lng: 129.624 },
    { lat: -13.75, lng: 129.662 },
    { lat: -13.829, lng: 129.668 },
    { lat: -14.423, lng: 129.697 },
    { lat: -15.02, lng: 129.764 },
    { lat: -15.618, lng: 129.962 },
    { lat: -15.908, lng: 130.123 },
    { lat: -16.217, lng: 130.313 },
    { lat: -16.819, lng: 130.661 },
    { lat: -16.832, lng: 130.666 },
    { lat: -17.424, lng: 130.897 },
    { lat: -18.034, lng: 131.002 },
    { lat: -18.65, lng: 130.93 },
    { lat: -19.27, lng: 130.783 },
    { lat: -19.893, lng: 130.695 },
    { lat: -20.519, lng: 130.697 },
    { lat: -21.148, lng: 130.757 },
    { lat: -21.781, lng: 130.824 },
    { lat: -22.313, lng: 130.836 },
    { lat: -22.419, lng: 130.836 },
    { lat: -23.062, lng: 130.78 },
    { lat: -23.433, lng: 130.727 },
    { lat: -23.711, lng: 130.686 },
    { lat: -24.365, lng: 130.604 },
    { lat: -25.025, lng: 130.488 },
    { lat: -25.69, lng: 130.357 },
    { lat: -26.362, lng: 130.213 },
    { lat: -27.04, lng: 130.027 },
    { lat: -27.727, lng: 129.783 },
    { lat: -28.42, lng: 129.509 },
    { lat: -28.516, lng: 129.467 },
    { lat: -29.122, lng: 129.216 },
    { lat: -29.833, lng: 128.831 },
    { lat: -30.245, lng: 128.524 },
    { lat: -30.557, lng: 128.281 },
    { lat: -31.201, lng: 127.664 },
    { lat: -31.294, lng: 127.567 },
    { lat: -31.96, lng: 126.813 },
    { lat: -32.045, lng: 126.707 },
    { lat: -32.602, lng: 125.963 },
    { lat: -32.813, lng: 125.682 },
    { lat: -33.269, lng: 125.085 },
    { lat: -33.593, lng: 124.66 },
    { lat: -33.978, lng: 124.174 },
    { lat: -34.387, lng: 123.623 },
    { lat: -34.702, lng: 123.231 },
    { lat: -35.2, lng: 122.472 },
    { lat: -35.334, lng: 122.286 },
    { lat: -35.967, lng: 121.313 },
    { lat: -36.034, lng: 121.191 },
    { lat: -36.527, lng: 120.336 },
    { lat: -36.905, lng: 119.461 },
    { lat: -36.949, lng: 119.381 },
    { lat: -37.437, lng: 118.377 },
    { lat: -37.767, lng: 117.41 },
    { lat: -37.826, lng: 117.178 },
    { lat: -38.045, lng: 116.445 },
    { lat: -38.223, lng: 115.505 },
    { lat: -38.357, lng: 114.57 },
    { lat: -38.42, lng: 113.654 },
    { lat: -38.41, lng: 112.763 },
    { lat: -38.384, lng: 111.869 },
    { lat: -38.307, lng: 110.992 },
    { lat: -38.23, lng: 110.306 },
    { lat: -38.208, lng: 110.119 },
    { lat: -37.994, lng: 109.303 },
    { lat: -37.698, lng: 108.529 },
    { lat: -37.549, lng: 108.243 },
    { lat: -37.268, lng: 107.832 },
    { lat: -36.81, lng: 107.226 },
    { lat: -36.763, lng: 107.179 },
    { lat: -36.05, lng: 106.673 },
    { lat: -36.031, lng: 106.662 },
    { lat: -35.32, lng: 106.131 },
    { lat: -35.301, lng: 106.116 },
    { lat: -34.667, lng: 105.562 },
    { lat: -34.567, lng: 105.479 },
    { lat: -34.07, lng: 104.958 },
    { lat: -33.853, lng: 104.67 },
    { lat: -33.616, lng: 104.262 },
    { lat: -33.163, lng: 103.62 },
    { lat: -33.134, lng: 103.579 },
    { lat: -32.736, lng: 102.839 },
    { lat: -32.494, lng: 102.363 },
    { lat: -32.346, lng: 102.086 },
    { lat: -31.937, lng: 101.338 },
    { lat: -31.832, lng: 101.107 },
    { lat: -31.551, lng: 100.566 },
    { lat: -31.178, lng: 99.822 },
    { lat: -31.16, lng: 99.789 },
    { lat: -30.776, lng: 98.995 },
    { lat: -30.536, lng: 98.453 },
    { lat: -30.435, lng: 98.161 },
    { lat: -30.152, lng: 97.272 },
    { lat: -29.931, lng: 96.597 },
    { lat: -29.872, lng: 96.365 },
    { lat: -29.624, lng: 95.415 },
    { lat: -29.437, lng: 94.397 },
    { lat: -29.374, lng: 94.104 },
    { lat: -29.228, lng: 93.369 },
    { lat: -28.95, lng: 92.369 },
    { lat: -28.796, lng: 91.99 },
    { lat: -28.519, lng: 91.47 },
    { lat: -28.166, lng: 90.697 },
    { lat: -28.064, lng: 90.566 },
    { lat: -27.497, lng: 90.055 },
    { lat: -27.184, lng: 89.999 },
    { lat: -26.795, lng: 89.977 },
    { lat: -26.089, lng: 90.046 },
    { lat: -25.592, lng: 89.981 },
    { lat: -25.401, lng: 89.929 },
    { lat: -24.735, lng: 89.511 },
    { lat: -24.629, lng: 89.42 },
    { lat: -24.077, lng: 89.008 },
    { lat: -23.768, lng: 88.752 },
    { lat: -23.426, lng: 88.459 },
    { lat: -22.893, lng: 88.064 },
    { lat: -22.773, lng: 87.987 },
    { lat: -22.116, lng: 87.649 },
    { lat: -21.784, lng: 87.516 },
    { lat: -21.459, lng: 87.348 },
    { lat: -20.806, lng: 87.051 },
    { lat: -20.603, lng: 86.975 },
    { lat: -20.15, lng: 86.864 },
    { lat: -19.493, lng: 86.748 },
    { lat: -19.088, lng: 86.61 },
    { lat: -18.845, lng: 86.506 },
    { lat: -18.21, lng: 86.051 },
    { lat: -18.02, lng: 85.884 },
    { lat: -17.578, lng: 85.547 },
    { lat: -17.022, lng: 85.059 },
    { lat: -16.95, lng: 84.995 },
    { lat: -16.323, lng: 84.423 },
    { lat: -16.027, lng: 84.174 },
    { lat: -15.697, lng: 83.888 },
    { lat: -15.069, lng: 83.411 },
    { lat: -14.906, lng: 83.298 },
    { lat: -14.442, lng: 82.929 },
    { lat: -13.817, lng: 82.4 },
    { lat: -13.765, lng: 82.353 },
    { lat: -13.198, lng: 81.736 },
    { lat: -12.789, lng: 81.22 },
    { lat: -12.583, lng: 80.912 },
    { lat: -12.04, lng: 79.85 },
    { lat: -11.98, lng: 79.733 },
    { lat: -11.374, lng: 78.551 },
    { lat: -11.278, lng: 78.355 },
    { lat: -10.769, lng: 77.293 },
    { lat: -10.511, lng: 76.692 },
    { lat: -10.165, lng: 75.927 },
    { lat: -9.692, lng: 74.83 },
    { lat: -9.558, lng: 74.53 },
    { lat: -8.947, lng: 73.173 },
    { lat: -8.749, lng: 72.712 },
    { lat: -8.338, lng: 71.571 },
    { lat: -7.777, lng: 70.054 },
    { lat: -7.727, lng: 69.92 },
    { lat: -7.106, lng: 68.52 },
    { lat: -6.483, lng: 67.007 },
    { lat: -6.303, lng: 66.611 },
    { lat: -5.858, lng: 65.383 },
    { lat: -5.235, lng: 63.197 },
    { lat: -5.039, lng: 60.876 },
  ],
  c10: [
    { lat: 18.974, lng: 61.354 },
    { lat: 19.251, lng: 63.485 },
    { lat: 19.299, lng: 63.869 },
    { lat: 19.893, lng: 65.781 },
    { lat: 20.468, lng: 66.476 },
    { lat: 20.545, lng: 66.567 },
    { lat: 21.246, lng: 66.482 },
    { lat: 22.022, lng: 65.187 },
    { lat: 22.656, lng: 61.587 },
  ],
  c11: [
    { lat: 29.524, lng: 62.174 },
    { lat: 29.41, lng: 67.724 },
    { lat: 29.245, lng: 72.232 },
    { lat: 29.164, lng: 75.321 },
    { lat: 29.143, lng: 76.975 },
    { lat: 29.144, lng: 77.779 },
    { lat: 29.151, lng: 79.896 },
    { lat: 29.144, lng: 81.826 },
    { lat: 29.15, lng: 83.583 },
    { lat: 29.143, lng: 85.231 },
    { lat: 29.079, lng: 86.838 },
    { lat: 28.987, lng: 88.379 },
    { lat: 28.872, lng: 89.861 },
    { lat: 28.737, lng: 91.287 },
    { lat: 28.515, lng: 92.718 },
    { lat: 28.158, lng: 94.183 },
    { lat: 27.884, lng: 95.027 },
    { lat: 27.664, lng: 95.673 },
    { lat: 27.052, lng: 97.109 },
    { lat: 27.017, lng: 97.185 },
    { lat: 26.271, lng: 98.576 },
    { lat: 26.194, lng: 98.717 },
    { lat: 25.5, lng: 100.071 },
    { lat: 25.484, lng: 100.103 },
    { lat: 24.955, lng: 101.335 },
    { lat: 24.712, lng: 102.106 },
    { lat: 24.622, lng: 102.43 },
    { lat: 24.519, lng: 103.392 },
    { lat: 24.561, lng: 104.273 },
    { lat: 24.593, lng: 104.486 },
    { lat: 24.684, lng: 105.106 },
    { lat: 24.888, lng: 105.893 },
    { lat: 25.148, lng: 106.649 },
    { lat: 25.16, lng: 106.678 },
    { lat: 25.465, lng: 107.375 },
    { lat: 25.771, lng: 108.064 },
    { lat: 25.784, lng: 108.093 },
    { lat: 26.114, lng: 108.801 },
    { lat: 26.369, lng: 109.531 },
    { lat: 26.381, lng: 109.568 },
    { lat: 26.614, lng: 110.258 },
    { lat: 26.869, lng: 110.976 },
    { lat: 26.986, lng: 111.272 },
    { lat: 27.166, lng: 111.674 },
    { lat: 27.553, lng: 112.336 },
    { lat: 27.623, lng: 112.417 },
    { lat: 28.298, lng: 112.833 },
    { lat: 28.362, lng: 112.848 },
    { lat: 28.995, lng: 112.935 },
    { lat: 29.705, lng: 112.895 },
    { lat: 30.425, lng: 112.785 },
    { lat: 30.98, lng: 112.678 },
    { lat: 31.155, lng: 112.645 },
    { lat: 31.893, lng: 112.524 },
    { lat: 32.637, lng: 112.436 },
    { lat: 33.389, lng: 112.38 },
    { lat: 33.763, lng: 112.35 },
    { lat: 34.15, lng: 112.335 },
    { lat: 34.921, lng: 112.288 },
    { lat: 35.705, lng: 112.2 },
    { lat: 36.14, lng: 112.112 },
    { lat: 36.504, lng: 112.039 },
    { lat: 37.317, lng: 111.857 },
    { lat: 38.142, lng: 111.7 },
    { lat: 38.932, lng: 111.574 },
    { lat: 38.978, lng: 111.564 },
    { lat: 39.829, lng: 111.438 },
    { lat: 40.688, lng: 111.406 },
    { lat: 40.866, lng: 111.416 },
    { lat: 41.556, lng: 111.49 },
    { lat: 42.172, lng: 111.598 },
    { lat: 42.434, lng: 111.678 },
    { lat: 43.144, lng: 111.979 },
    { lat: 43.311, lng: 112.13 },
    { lat: 43.713, lng: 112.612 },
    { lat: 44.068, lng: 113.379 },
    { lat: 44.136, lng: 113.628 },
    { lat: 44.269, lng: 114.236 },
    { lat: 44.444, lng: 115.105 },
    { lat: 44.6, lng: 115.981 },
    { lat: 44.758, lng: 116.851 },
    { lat: 44.845, lng: 117.312 },
    { lat: 44.928, lng: 117.712 },
    { lat: 45.158, lng: 118.541 },
    { lat: 45.453, lng: 119.338 },
    { lat: 45.663, lng: 119.822 },
    { lat: 45.845, lng: 120.092 },
    { lat: 46.457, lng: 120.751 },
    { lat: 46.593, lng: 120.854 },
    { lat: 47.595, lng: 121.088 },
    { lat: 47.895, lng: 121.037 },
    { lat: 48.649, lng: 120.923 },
    { lat: 49.752, lng: 120.498 },
    { lat: 50.91, lng: 119.795 },
    { lat: 52.125, lng: 118.903 },
    { lat: 52.923, lng: 118.253 },
    { lat: 53.401, lng: 117.852 },
    { lat: 54.752, lng: 116.596 },
    { lat: 56.188, lng: 115.149 },
    { lat: 57.718, lng: 113.568 },
    { lat: 59.357, lng: 111.874 },
    { lat: 61.136, lng: 109.979 },
    { lat: 63.096, lng: 107.82 },
    { lat: 64.648, lng: 106.136 },
    { lat: 65.283, lng: 105.431 },
    { lat: 66.874, lng: 104.086 },
    { lat: 67.706, lng: 103.353 },
    { lat: 68.553, lng: 102.757 },
    { lat: 70.216, lng: 101.187 },
    { lat: 70.557, lng: 101.115 },
    { lat: 71.295, lng: 100.946 },
    { lat: 72.233, lng: 101.081 },
    { lat: 74.54, lng: 96.528 },
    { lat: 74.552, lng: 96.488 },
    { lat: 76.185, lng: 92.067 },
  ],
  c12: [
    { lat: 16.784, lng: 134.089 },
    { lat: 16.715, lng: 134.293 },
    { lat: 16.174, lng: 134.813 },
    { lat: 15.944, lng: 134.924 },
    { lat: 15.571, lng: 135.025 },
    { lat: 14.973, lng: 135.064 },
    { lat: 14.378, lng: 134.987 },
    { lat: 14.371, lng: 134.985 },
    { lat: 13.786, lng: 134.713 },
    { lat: 13.448, lng: 134.431 },
    { lat: 13.199, lng: 134.013 },
    { lat: 13.136, lng: 133.855 },
    { lat: 13.068, lng: 133.269 },
    { lat: 13.187, lng: 132.674 },
    { lat: 13.209, lng: 132.615 },
    { lat: 13.527, lng: 132.065 },
    { lat: 13.808, lng: 131.759 },
    { lat: 14.405, lng: 131.47 },
    { lat: 15.002, lng: 131.49 },
    { lat: 15.599, lng: 131.716 },
    { lat: 15.943, lng: 131.934 },
    { lat: 16.197, lng: 132.151 },
    { lat: 16.542, lng: 132.501 },
    { lat: 16.794, lng: 132.97 },
    { lat: 16.855, lng: 133.086 },
    { lat: 16.89, lng: 133.685 },
    { lat: 16.784, lng: 134.089 },
  ],
  c13: [
    { lat: 80.944, lng: 129.556 },
    { lat: 74.157, lng: 136.598 },
    { lat: 70.185, lng: 138.256 },
    { lat: 69.581, lng: 138.419 },
    { lat: 67.331, lng: 138.947 },
    { lat: 64.984, lng: 139.317 },
    { lat: 62.944, lng: 139.505 },
    { lat: 61.116, lng: 139.622 },
    { lat: 59.445, lng: 139.731 },
    { lat: 57.895, lng: 139.907 },
    { lat: 56.444, lng: 140.218 },
    { lat: 55.073, lng: 140.746 },
    { lat: 54.716, lng: 140.958 },
    { lat: 53.773, lng: 141.586 },
    { lat: 53.209, lng: 142.0 },
    { lat: 52.533, lng: 142.45 },
    { lat: 51.635, lng: 142.963 },
    { lat: 51.347, lng: 143.109 },
    { lat: 50.207, lng: 143.558 },
    { lat: 49.142, lng: 143.818 },
    { lat: 49.107, lng: 143.826 },
    { lat: 48.042, lng: 143.925 },
    { lat: 47.009, lng: 143.878 },
    { lat: 46.095, lng: 143.704 },
    { lat: 46.004, lng: 143.686 },
    { lat: 45.027, lng: 143.377 },
    { lat: 44.074, lng: 142.979 },
    { lat: 43.763, lng: 142.814 },
    { lat: 43.145, lng: 142.446 },
    { lat: 42.513, lng: 142.0 },
    { lat: 42.237, lng: 141.838 },
    { lat: 41.349, lng: 141.251 },
    { lat: 41.308, lng: 141.221 },
    { lat: 40.481, lng: 140.588 },
    { lat: 40.337, lng: 140.468 },
    { lat: 39.631, lng: 139.937 },
    { lat: 39.384, lng: 139.737 },
    { lat: 38.797, lng: 139.281 },
    { lat: 38.471, lng: 139.025 },
    { lat: 37.978, lng: 138.693 },
    { lat: 37.46, lng: 138.338 },
    { lat: 37.173, lng: 138.156 },
    { lat: 36.381, lng: 137.74 },
    { lat: 36.232, lng: 137.683 },
    { lat: 35.599, lng: 137.503 },
    { lat: 34.827, lng: 137.42 },
    { lat: 34.065, lng: 137.443 },
    { lat: 33.314, lng: 137.514 },
    { lat: 32.571, lng: 137.617 },
    { lat: 31.838, lng: 137.751 },
    { lat: 31.114, lng: 137.866 },
    { lat: 30.398, lng: 137.92 },
    { lat: 29.692, lng: 137.893 },
    { lat: 28.993, lng: 137.754 },
    { lat: 28.303, lng: 137.443 },
    { lat: 28.3, lng: 137.441 },
    { lat: 27.634, lng: 136.824 },
    { lat: 27.623, lng: 136.812 },
    { lat: 27.202, lng: 136.2 },
    { lat: 26.955, lng: 135.706 },
    { lat: 26.895, lng: 135.572 },
    { lat: 26.548, lng: 134.95 },
    { lat: 26.294, lng: 134.587 },
    { lat: 26.101, lng: 134.337 },
    { lat: 25.635, lng: 133.903 },
    { lat: 25.435, lng: 133.746 },
    { lat: 24.979, lng: 133.457 },
    { lat: 24.477, lng: 133.184 },
    { lat: 24.327, lng: 133.105 },
    { lat: 23.68, lng: 132.738 },
    { lat: 23.514, lng: 132.628 },
    { lat: 23.04, lng: 132.267 },
    { lat: 22.806, lng: 132.055 },
    { lat: 22.407, lng: 131.602 },
    { lat: 22.297, lng: 131.469 },
    { lat: 21.853, lng: 130.878 },
    { lat: 21.781, lng: 130.795 },
    { lat: 21.327, lng: 130.297 },
    { lat: 21.158, lng: 130.102 },
    { lat: 20.779, lng: 129.72 },
    { lat: 20.537, lng: 129.48 },
    { lat: 20.184, lng: 129.149 },
    { lat: 19.92, lng: 128.913 },
    { lat: 19.547, lng: 128.584 },
    { lat: 19.306, lng: 128.365 },
    { lat: 18.935, lng: 128.017 },
    { lat: 18.696, lng: 127.779 },
    { lat: 18.381, lng: 127.445 },
    { lat: 18.09, lng: 127.111 },
    { lat: 17.882, lng: 126.868 },
    { lat: 17.488, lng: 126.426 },
    { lat: 17.368, lng: 126.293 },
    { lat: 16.888, lng: 125.799 },
    { lat: 16.805, lng: 125.722 },
    { lat: 16.289, lng: 125.279 },
    { lat: 16.12, lng: 125.163 },
    { lat: 15.689, lng: 124.913 },
    { lat: 15.09, lng: 124.667 },
    { lat: 15.015, lng: 124.645 },
    { lat: 14.492, lng: 124.518 },
    { lat: 13.895, lng: 124.429 },
    { lat: 13.301, lng: 124.379 },
    { lat: 12.707, lng: 124.364 },
    { lat: 12.116, lng: 124.389 },
    { lat: 11.525, lng: 124.458 },
    { lat: 10.936, lng: 124.584 },
    { lat: 10.349, lng: 124.785 },
    { lat: 9.786, lng: 125.062 },
    { lat: 9.762, lng: 125.081 },
    { lat: 9.174, lng: 125.645 },
    { lat: 9.114, lng: 125.711 },
    { lat: 8.75, lng: 126.336 },
    { lat: 8.584, lng: 126.747 },
    { lat: 8.478, lng: 126.952 },
    { lat: 8.16, lng: 127.565 },
    { lat: 7.999, lng: 127.771 },
    { lat: 7.42, lng: 128.056 },
    { lat: 6.845, lng: 128.092 },
    { lat: 6.271, lng: 128.057 },
    { lat: 5.698, lng: 127.966 },
    { lat: 5.127, lng: 127.852 },
    { lat: 4.555, lng: 127.788 },
    { lat: 3.984, lng: 127.781 },
    { lat: 3.414, lng: 127.834 },
    { lat: 2.844, lng: 127.93 },
    { lat: 2.274, lng: 128.047 },
    { lat: 1.705, lng: 128.172 },
    { lat: 1.137, lng: 128.321 },
    { lat: 1.122, lng: 128.326 },
    { lat: 0.568, lng: 128.524 },
    { lat: 0.0, lng: 128.779 },
    { lat: -0.258, lng: 128.915 },
    { lat: -0.568, lng: 129.114 },
    { lat: -1.135, lng: 129.473 },
    { lat: -1.176, lng: 129.496 },
    { lat: -1.703, lng: 129.898 },
    { lat: -1.966, lng: 130.073 },
    { lat: -2.27, lng: 130.37 },
    { lat: -2.676, lng: 130.646 },
    { lat: -2.837, lng: 130.799 },
    { lat: -3.405, lng: 131.1 },
    { lat: -3.866, lng: 131.21 },
    { lat: -3.974, lng: 131.237 },
    { lat: -4.543, lng: 131.313 },
    { lat: -5.113, lng: 131.299 },
    { lat: -5.684, lng: 131.202 },
    { lat: -5.753, lng: 131.174 },
    { lat: -6.257, lng: 130.995 },
    { lat: -6.831, lng: 130.705 },
    { lat: -7.09, lng: 130.557 },
    { lat: -7.406, lng: 130.41 },
    { lat: -7.983, lng: 130.199 },
    { lat: -8.561, lng: 130.066 },
    { lat: -9.139, lng: 129.981 },
    { lat: -9.719, lng: 129.913 },
    { lat: -10.091, lng: 129.858 },
    { lat: -10.301, lng: 129.827 },
    { lat: -10.884, lng: 129.754 },
    { lat: -11.469, lng: 129.739 },
    { lat: -11.888, lng: 129.77 },
    { lat: -12.056, lng: 129.781 },
    { lat: -12.643, lng: 129.866 },
    { lat: -13.233, lng: 129.957 },
    { lat: -13.825, lng: 130.034 },
    { lat: -14.419, lng: 130.116 },
    { lat: -14.84, lng: 130.195 },
    { lat: -15.015, lng: 130.234 },
    { lat: -15.612, lng: 130.475 },
    { lat: -16.007, lng: 130.723 },
    { lat: -16.211, lng: 130.858 },
    { lat: -16.812, lng: 131.197 },
    { lat: -16.973, lng: 131.265 },
    { lat: -17.417, lng: 131.46 },
    { lat: -18.027, lng: 131.565 },
    { lat: -18.643, lng: 131.514 },
    { lat: -19.262, lng: 131.382 },
    { lat: -19.885, lng: 131.279 },
    { lat: -20.511, lng: 131.268 },
    { lat: -21.14, lng: 131.317 },
    { lat: -21.772, lng: 131.378 },
    { lat: -22.41, lng: 131.405 },
    { lat: -23.053, lng: 131.331 },
    { lat: -23.703, lng: 131.215 },
    { lat: -24.357, lng: 131.097 },
    { lat: -25.017, lng: 130.946 },
    { lat: -25.682, lng: 130.817 },
    { lat: -26.353, lng: 130.701 },
    { lat: -27.03, lng: 130.568 },
    { lat: -27.714, lng: 130.401 },
    { lat: -28.406, lng: 130.184 },
    { lat: -28.507, lng: 130.143 },
    { lat: -29.106, lng: 129.911 },
    { lat: -29.816, lng: 129.558 },
    { lat: -30.368, lng: 129.197 },
    { lat: -30.537, lng: 129.081 },
    { lat: -31.271, lng: 128.432 },
    { lat: -31.358, lng: 128.34 },
    { lat: -32.019, lng: 127.637 },
    { lat: -32.148, lng: 127.491 },
    { lat: -32.782, lng: 126.696 },
    { lat: -32.836, lng: 126.639 },
    { lat: -33.556, lng: 125.777 },
    { lat: -33.589, lng: 125.749 },
    { lat: -34.344, lng: 124.849 },
    { lat: -34.373, lng: 124.825 },
    { lat: -35.141, lng: 124.017 },
    { lat: -35.396, lng: 123.808 },
    { lat: -35.949, lng: 123.264 },
    { lat: -36.597, lng: 122.695 },
    { lat: -36.77, lng: 122.525 },
    { lat: -37.071, lng: 122.552 },
    { lat: -36.853, lng: 123.404 },
    { lat: -36.725, lng: 123.639 },
    { lat: -36.412, lng: 124.305 },
    { lat: -36.633, lng: 125.019 },
    { lat: -36.668, lng: 125.154 },
    { lat: -36.822, lng: 124.97 },
    { lat: -37.491, lng: 124.597 },
    { lat: -38.337, lng: 123.805 },
    { lat: -38.39, lng: 123.746 },
    { lat: -39.21, lng: 122.786 },
    { lat: -39.285, lng: 122.658 },
    { lat: -39.452, lng: 121.78 },
    { lat: -39.719, lng: 120.853 },
    { lat: -39.727, lng: 120.009 },
    { lat: -39.712, lng: 119.167 },
    { lat: -39.822, lng: 118.266 },
    { lat: -39.456, lng: 117.704 },
    { lat: -39.372, lng: 117.595 },
    { lat: -39.295, lng: 116.763 },
    { lat: -39.132, lng: 115.965 },
    { lat: -39.105, lng: 115.098 },
    { lat: -39.055, lng: 114.234 },
    { lat: -39.002, lng: 113.363 },
    { lat: -38.925, lng: 112.495 },
    { lat: -38.922, lng: 112.463 },
    { lat: -38.863, lng: 111.611 },
    { lat: -38.773, lng: 110.733 },
    { lat: -38.671, lng: 109.853 },
    { lat: -38.486, lng: 109.013 },
    { lat: -38.346, lng: 108.563 },
    { lat: -38.221, lng: 108.214 },
    { lat: -37.86, lng: 107.47 },
    { lat: -37.628, lng: 107.07 },
    { lat: -37.413, lng: 106.777 },
    { lat: -36.874, lng: 106.276 },
    { lat: -36.772, lng: 106.205 },
    { lat: -36.115, lng: 105.718 },
    { lat: -36.058, lng: 105.677 },
    { lat: -35.367, lng: 105.138 },
    { lat: -35.363, lng: 105.135 },
    { lat: -34.687, lng: 104.578 },
    { lat: -34.631, lng: 104.521 },
    { lat: -34.169, lng: 103.92 },
    { lat: -33.923, lng: 103.621 },
    { lat: -33.664, lng: 103.248 },
    { lat: -33.257, lng: 102.509 },
    { lat: -33.239, lng: 102.473 },
    { lat: -32.883, lng: 101.741 },
    { lat: -32.58, lng: 101.075 },
    { lat: -32.519, lng: 100.958 },
    { lat: -32.184, lng: 100.146 },
    { lat: -31.94, lng: 99.485 },
    { lat: -31.865, lng: 99.312 },
    { lat: -31.555, lng: 98.458 },
    { lat: -31.309, lng: 97.874 },
    { lat: -31.209, lng: 97.617 },
    { lat: -30.924, lng: 96.716 },
    { lat: -30.701, lng: 96.005 },
    { lat: -30.646, lng: 95.793 },
    { lat: -30.372, lng: 94.847 },
    { lat: -30.14, lng: 93.846 },
    { lat: -30.123, lng: 93.776 },
    { lat: -29.945, lng: 92.788 },
    { lat: -29.691, lng: 91.75 },
    { lat: -29.559, lng: 91.409 },
    { lat: -29.322, lng: 90.783 },
    { lat: -28.984, lng: 89.759 },
    { lat: -28.955, lng: 89.668 },
    { lat: -28.539, lng: 88.803 },
    { lat: -28.315, lng: 88.496 },
    { lat: -27.712, lng: 88.175 },
    { lat: -27.625, lng: 88.131 },
    { lat: -26.914, lng: 88.145 },
    { lat: -26.201, lng: 88.284 },
    { lat: -26.05, lng: 88.262 },
    { lat: -25.507, lng: 88.213 },
    { lat: -24.825, lng: 88.021 },
    { lat: -24.752, lng: 87.971 },
    { lat: -24.165, lng: 87.528 },
    { lat: -23.873, lng: 87.294 },
    { lat: -23.509, lng: 87.028 },
    { lat: -22.861, lng: 86.689 },
    { lat: -22.847, lng: 86.68 },
    { lat: -22.188, lng: 86.334 },
    { lat: -21.719, lng: 86.145 },
    { lat: -21.528, lng: 86.064 },
    { lat: -20.865, lng: 85.917 },
    { lat: -20.198, lng: 85.895 },
    { lat: -20.125, lng: 85.888 },
    { lat: -19.54, lng: 85.794 },
    { lat: -18.89, lng: 85.556 },
    { lat: -18.705, lng: 85.426 },
    { lat: -18.251, lng: 85.143 },
    { lat: -17.616, lng: 84.692 },
    { lat: -17.602, lng: 84.68 },
    { lat: -16.987, lng: 84.136 },
    { lat: -16.612, lng: 83.798 },
    { lat: -16.36, lng: 83.557 },
    { lat: -15.731, lng: 83.027 },
    { lat: -15.555, lng: 82.892 },
    { lat: -15.104, lng: 82.509 },
    { lat: -14.477, lng: 81.991 },
    { lat: -14.429, lng: 81.95 },
    { lat: -13.856, lng: 81.328 },
    { lat: -13.427, lng: 80.838 },
    { lat: -13.237, lng: 80.594 },
    { lat: -12.626, lng: 79.617 },
    { lat: -12.58, lng: 79.523 },
    { lat: -12.022, lng: 78.406 },
    { lat: -11.83, lng: 78.011 },
    { lat: -11.417, lng: 77.135 },
    { lat: -11.062, lng: 76.336 },
    { lat: -10.813, lng: 75.777 },
    { lat: -10.253, lng: 74.446 },
    { lat: -10.208, lng: 74.338 },
    { lat: -9.6, lng: 72.887 },
    { lat: -9.347, lng: 72.27 },
    { lat: -8.994, lng: 71.227 },
    { lat: -8.457, lng: 69.462 },
    { lat: -8.391, lng: 69.248 },
    { lat: -7.782, lng: 67.291 },
    { lat: -7.28, lng: 65.388 },
    { lat: -7.177, lng: 64.796 },
    { lat: -6.737, lng: 60.903 },
  ],
  c14: [
    { lat: 30.372, lng: 62.262 },
    { lat: 30.089, lng: 69.917 },
    { lat: 29.912, lng: 73.772 },
    { lat: 29.872, lng: 76.513 },
    { lat: 29.853, lng: 77.374 },
    { lat: 29.837, lng: 78.851 },
    { lat: 29.785, lng: 80.951 },
    { lat: 29.745, lng: 82.842 },
    { lat: 29.687, lng: 84.608 },
    { lat: 29.627, lng: 86.257 },
    { lat: 29.522, lng: 87.853 },
    { lat: 29.384, lng: 89.391 },
    { lat: 29.243, lng: 90.852 },
    { lat: 29.04, lng: 92.296 },
    { lat: 28.682, lng: 93.793 },
    { lat: 28.674, lng: 93.815 },
    { lat: 28.204, lng: 95.301 },
    { lat: 27.799, lng: 96.373 },
    { lat: 27.621, lng: 96.801 },
    { lat: 26.998, lng: 98.014 },
    { lat: 26.81, lng: 98.36 },
    { lat: 26.22, lng: 99.469 },
    { lat: 26.047, lng: 99.804 },
    { lat: 25.447, lng: 101.045 },
    { lat: 25.42, lng: 101.106 },
    { lat: 25.032, lng: 102.24 },
    { lat: 24.887, lng: 103.229 },
    { lat: 24.915, lng: 104.121 },
    { lat: 25.03, lng: 104.961 },
    { lat: 25.209, lng: 105.668 },
    { lat: 25.233, lng: 105.752 },
    { lat: 25.504, lng: 106.506 },
    { lat: 25.81, lng: 107.238 },
    { lat: 25.811, lng: 107.24 },
    { lat: 26.137, lng: 107.955 },
    { lat: 26.428, lng: 108.568 },
    { lat: 26.477, lng: 108.661 },
    { lat: 26.746, lng: 109.388 },
    { lat: 26.984, lng: 110.121 },
    { lat: 27.033, lng: 110.261 },
    { lat: 27.243, lng: 110.84 },
    { lat: 27.564, lng: 111.532 },
    { lat: 27.657, lng: 111.684 },
    { lat: 28.098, lng: 112.143 },
    { lat: 28.324, lng: 112.288 },
    { lat: 29.017, lng: 112.471 },
    { lat: 29.381, lng: 112.477 },
    { lat: 29.725, lng: 112.47 },
    { lat: 30.445, lng: 112.382 },
    { lat: 31.176, lng: 112.247 },
    { lat: 31.915, lng: 112.095 },
    { lat: 32.663, lng: 111.952 },
    { lat: 32.784, lng: 111.931 },
    { lat: 33.419, lng: 111.842 },
    { lat: 34.183, lng: 111.74 },
    { lat: 34.958, lng: 111.639 },
    { lat: 35.472, lng: 111.557 },
    { lat: 35.745, lng: 111.518 },
    { lat: 36.546, lng: 111.352 },
    { lat: 37.359, lng: 111.179 },
    { lat: 38.183, lng: 111.044 },
    { lat: 38.22, lng: 111.04 },
    { lat: 39.019, lng: 110.938 },
    { lat: 39.868, lng: 110.839 },
    { lat: 40.255, lng: 110.821 },
    { lat: 40.729, lng: 110.795 },
    { lat: 41.602, lng: 110.816 },
    { lat: 41.828, lng: 110.834 },
    { lat: 42.486, lng: 110.924 },
    { lat: 43.069, lng: 111.037 },
    { lat: 43.379, lng: 111.159 },
    { lat: 43.911, lng: 111.493 },
    { lat: 44.252, lng: 111.938 },
    { lat: 44.401, lng: 112.181 },
    { lat: 44.685, lng: 113.001 },
    { lat: 44.818, lng: 113.911 },
    { lat: 44.935, lng: 114.824 },
    { lat: 44.983, lng: 115.121 },
    { lat: 45.08, lng: 115.715 },
    { lat: 45.254, lng: 116.587 },
    { lat: 45.439, lng: 117.448 },
    { lat: 45.678, lng: 118.281 },
    { lat: 45.742, lng: 118.448 },
    { lat: 46.075, lng: 119.035 },
    { lat: 46.593, lng: 119.736 },
    { lat: 46.653, lng: 119.797 },
    { lat: 47.65, lng: 120.111 },
    { lat: 47.889, lng: 120.066 },
    { lat: 48.709, lng: 119.897 },
    { lat: 49.819, lng: 119.383 },
    { lat: 50.984, lng: 118.623 },
    { lat: 52.208, lng: 117.664 },
    { lat: 52.527, lng: 117.396 },
    { lat: 53.494, lng: 116.559 },
    { lat: 54.854, lng: 115.261 },
    { lat: 56.296, lng: 113.828 },
    { lat: 57.834, lng: 112.252 },
    { lat: 59.491, lng: 110.463 },
    { lat: 61.306, lng: 108.325 },
    { lat: 63.325, lng: 105.768 },
    { lat: 65.236, lng: 103.174 },
    { lat: 65.636, lng: 102.558 },
    { lat: 68.378, lng: 98.411 },
    { lat: 68.386, lng: 98.397 },
    { lat: 70.229, lng: 95.784 },
    { lat: 71.952, lng: 92.052 },
    { lat: 72.635, lng: 90.086 },
    { lat: 74.283, lng: 86.569 },
  ],
  c15: [
    { lat: 17.384, lng: 134.993 },
    { lat: 16.774, lng: 135.302 },
    { lat: 16.17, lng: 135.439 },
    { lat: 15.568, lng: 135.507 },
    { lat: 14.97, lng: 135.497 },
    { lat: 14.375, lng: 135.413 },
    { lat: 13.782, lng: 135.227 },
    { lat: 13.39, lng: 135.02 },
    { lat: 13.194, lng: 134.858 },
    { lat: 12.879, lng: 134.451 },
    { lat: 12.674, lng: 133.873 },
    { lat: 12.634, lng: 133.287 },
    { lat: 12.759, lng: 132.693 },
    { lat: 12.973, lng: 132.092 },
    { lat: 13.217, lng: 131.661 },
    { lat: 13.384, lng: 131.478 },
    { lat: 13.814, lng: 131.111 },
    { lat: 14.41, lng: 130.93 },
    { lat: 15.007, lng: 130.994 },
    { lat: 15.605, lng: 131.206 },
    { lat: 15.844, lng: 131.338 },
    { lat: 16.203, lng: 131.569 },
    { lat: 16.622, lng: 131.893 },
    { lat: 16.803, lng: 132.075 },
    { lat: 17.173, lng: 132.464 },
    { lat: 17.402, lng: 132.873 },
    { lat: 17.5, lng: 133.049 },
    { lat: 17.644, lng: 133.645 },
    { lat: 17.676, lng: 134.246 },
    { lat: 17.514, lng: 134.855 },
    { lat: 17.384, lng: 134.993 },
  ],
  c16: [
    { lat: 21.07, lng: 139.225 },
    { lat: 20.442, lng: 139.379 },
    { lat: 19.819, lng: 139.168 },
    { lat: 19.594, lng: 138.991 },
    { lat: 19.202, lng: 138.547 },
    { lat: 19.107, lng: 138.4 },
    { lat: 18.924, lng: 137.804 },
    { lat: 18.998, lng: 137.2 },
    { lat: 19.211, lng: 136.839 },
    { lat: 19.832, lng: 136.71 },
    { lat: 20.454, lng: 136.976 },
    { lat: 20.706, lng: 137.14 },
    { lat: 21.078, lng: 137.496 },
    { lat: 21.238, lng: 137.731 },
    { lat: 21.392, lng: 138.338 },
    { lat: 21.307, lng: 138.952 },
    { lat: 21.07, lng: 139.225 },
  ],
  c17: [
    { lat: 55.264, lng: 67.649 },
    { lat: 52.755, lng: 78.644 },
    { lat: 52.432, lng: 79.354 },
    { lat: 51.937, lng: 78.16 },
    { lat: 53.239, lng: 66.881 },
  ],
  c18: [
    { lat: 46.162, lng: 131.06 },
    { lat: 45.913, lng: 131.743 },
    { lat: 45.335, lng: 132.717 },
    { lat: 45.133, lng: 132.94 },
    { lat: 44.323, lng: 133.735 },
    { lat: 44.159, lng: 133.854 },
    { lat: 43.22, lng: 134.302 },
    { lat: 42.307, lng: 134.497 },
    { lat: 41.417, lng: 134.518 },
    { lat: 40.549, lng: 134.369 },
    { lat: 40.336, lng: 134.307 },
    { lat: 39.699, lng: 134.138 },
    { lat: 38.865, lng: 133.874 },
    { lat: 38.538, lng: 133.775 },
    { lat: 38.045, lng: 133.615 },
    { lat: 37.238, lng: 133.446 },
    { lat: 36.441, lng: 133.425 },
    { lat: 35.654, lng: 133.542 },
    { lat: 34.877, lng: 133.76 },
    { lat: 34.11, lng: 134.004 },
    { lat: 33.354, lng: 134.215 },
    { lat: 32.609, lng: 134.393 },
    { lat: 31.875, lng: 134.506 },
    { lat: 31.151, lng: 134.488 },
    { lat: 30.439, lng: 134.232 },
    { lat: 30.098, lng: 134.0 },
    { lat: 29.739, lng: 133.663 },
    { lat: 29.519, lng: 133.384 },
    { lat: 29.051, lng: 132.876 },
    { lat: 28.961, lng: 132.771 },
    { lat: 28.368, lng: 132.249 },
    { lat: 28.281, lng: 132.176 },
    { lat: 27.688, lng: 131.807 },
    { lat: 27.323, lng: 131.617 },
    { lat: 27.014, lng: 131.466 },
    { lat: 26.344, lng: 131.17 },
    { lat: 26.17, lng: 131.087 },
    { lat: 25.682, lng: 130.834 },
    { lat: 25.166, lng: 130.545 },
    { lat: 25.025, lng: 130.452 },
    { lat: 24.38, lng: 129.984 },
    { lat: 24.376, lng: 129.98 },
    { lat: 23.854, lng: 129.397 },
    { lat: 23.736, lng: 129.236 },
    { lat: 23.451, lng: 128.798 },
    { lat: 23.105, lng: 128.319 },
    { lat: 23.022, lng: 128.203 },
    { lat: 22.571, lng: 127.612 },
    { lat: 22.477, lng: 127.489 },
    { lat: 22.104, lng: 127.024 },
    { lat: 21.853, lng: 126.719 },
    { lat: 21.612, lng: 126.44 },
    { lat: 21.232, lng: 125.99 },
    { lat: 21.117, lng: 125.857 },
    { lat: 20.646, lng: 125.271 },
    { lat: 20.616, lng: 125.225 },
    { lat: 20.241, lng: 124.676 },
    { lat: 20.005, lng: 124.381 },
    { lat: 19.764, lng: 124.091 },
    { lat: 19.394, lng: 123.703 },
    { lat: 19.192, lng: 123.518 },
    { lat: 18.782, lng: 123.217 },
    { lat: 18.418, lng: 122.972 },
    { lat: 18.171, lng: 122.808 },
    { lat: 17.562, lng: 122.489 },
    { lat: 17.458, lng: 122.449 },
    { lat: 16.953, lng: 122.281 },
    { lat: 16.345, lng: 122.166 },
    { lat: 15.738, lng: 122.123 },
    { lat: 15.134, lng: 122.11 },
    { lat: 14.958, lng: 122.109 },
    { lat: 14.532, lng: 122.102 },
    { lat: 13.933, lng: 122.092 },
    { lat: 13.335, lng: 122.09 },
    { lat: 12.74, lng: 122.124 },
    { lat: 12.146, lng: 122.179 },
    { lat: 11.555, lng: 122.228 },
    { lat: 10.965, lng: 122.275 },
    { lat: 10.377, lng: 122.311 },
    { lat: 9.792, lng: 122.332 },
    { lat: 9.207, lng: 122.364 },
    { lat: 8.624, lng: 122.447 },
    { lat: 8.042, lng: 122.608 },
    { lat: 7.8, lng: 122.714 },
    { lat: 7.46, lng: 122.891 },
    { lat: 6.878, lng: 123.29 },
    { lat: 6.745, lng: 123.387 },
    { lat: 6.298, lng: 123.72 },
    { lat: 5.819, lng: 124.041 },
    { lat: 5.72, lng: 124.108 },
    { lat: 5.144, lng: 124.48 },
    { lat: 4.817, lng: 124.684 },
    { lat: 4.568, lng: 124.806 },
    { lat: 3.995, lng: 125.084 },
    { lat: 3.472, lng: 125.324 },
    { lat: 3.422, lng: 125.342 },
    { lat: 2.85, lng: 125.538 },
    { lat: 2.279, lng: 125.715 },
    { lat: 1.709, lng: 125.877 },
    { lat: 1.456, lng: 125.953 },
    { lat: 1.139, lng: 126.034 },
    { lat: 0.569, lng: 126.236 },
    { lat: 0.0, lng: 126.488 },
    { lat: -0.157, lng: 126.556 },
    { lat: -0.569, lng: 126.719 },
    { lat: -1.138, lng: 126.915 },
    { lat: -1.707, lng: 127.057 },
    { lat: -2.276, lng: 127.132 },
    { lat: -2.292, lng: 127.135 },
    { lat: -2.846, lng: 127.239 },
    { lat: -3.415, lng: 127.396 },
    { lat: -3.985, lng: 127.626 },
    { lat: -4.141, lng: 127.696 },
    { lat: -4.555, lng: 127.873 },
    { lat: -5.126, lng: 128.058 },
    { lat: -5.698, lng: 128.107 },
    { lat: -6.271, lng: 128.007 },
    { lat: -6.846, lng: 127.858 },
    { lat: -7.422, lng: 127.745 },
    { lat: -8.0, lng: 127.671 },
    { lat: -8.578, lng: 127.583 },
    { lat: -8.791, lng: 127.537 },
    { lat: -9.158, lng: 127.438 },
    { lat: -9.741, lng: 127.236 },
    { lat: -10.325, lng: 127.02 },
    { lat: -10.911, lng: 126.919 },
    { lat: -11.497, lng: 126.94 },
    { lat: -12.084, lng: 126.944 },
    { lat: -12.675, lng: 126.86 },
    { lat: -13.27, lng: 126.667 },
    { lat: -13.296, lng: 126.656 },
    { lat: -13.868, lng: 126.386 },
    { lat: -14.466, lng: 126.264 },
    { lat: -15.065, lng: 126.298 },
    { lat: -15.666, lng: 126.369 },
    { lat: -16.214, lng: 126.407 },
    { lat: -16.27, lng: 126.411 },
    { lat: -16.879, lng: 126.342 },
    { lat: -16.88, lng: 126.342 },
    { lat: -17.492, lng: 126.183 },
    { lat: -18.107, lng: 126.163 },
    { lat: -18.248, lng: 126.2 },
    { lat: -18.721, lng: 126.292 },
    { lat: -19.34, lng: 126.398 },
    { lat: -19.965, lng: 126.362 },
    { lat: -20.597, lng: 126.149 },
    { lat: -21.121, lng: 125.856 },
    { lat: -21.236, lng: 125.79 },
    { lat: -21.878, lng: 125.549 },
    { lat: -22.517, lng: 125.615 },
    { lat: -22.603, lng: 125.654 },
    { lat: -23.156, lng: 125.883 },
    { lat: -23.682, lng: 126.157 },
    { lat: -23.799, lng: 126.217 },
    { lat: -24.447, lng: 126.488 },
    { lat: -25.105, lng: 126.579 },
    { lat: -25.774, lng: 126.414 },
    { lat: -26.453, lng: 126.087 },
    { lat: -27.139, lng: 125.73 },
    { lat: -27.493, lng: 125.545 },
    { lat: -27.834, lng: 125.331 },
    { lat: -28.542, lng: 124.716 },
    { lat: -28.604, lng: 124.643 },
    { lat: -29.191, lng: 123.819 },
    { lat: -29.271, lng: 123.69 },
    { lat: -29.76, lng: 122.98 },
    { lat: -30.011, lng: 122.657 },
    { lat: -30.699, lng: 122.031 },
    { lat: -30.749, lng: 121.992 },
    { lat: -31.495, lng: 121.374 },
    { lat: -31.851, lng: 120.988 },
    { lat: -32.26, lng: 120.516 },
    { lat: -32.608, lng: 120.014 },
    { lat: -33.048, lng: 119.39 },
    { lat: -33.292, lng: 119.031 },
    { lat: -33.856, lng: 118.125 },
    { lat: -33.898, lng: 118.043 },
    { lat: -34.325, lng: 117.091 },
    { lat: -34.595, lng: 116.177 },
    { lat: -34.695, lng: 115.315 },
    { lat: -34.643, lng: 114.504 },
    { lat: -34.444, lng: 113.748 },
    { lat: -34.122, lng: 113.042 },
    { lat: -34.112, lng: 113.028 },
    { lat: -33.581, lng: 112.43 },
    { lat: -33.397, lng: 112.241 },
    { lat: -32.977, lng: 111.846 },
    { lat: -32.689, lng: 111.481 },
    { lat: -32.435, lng: 111.234 },
    { lat: -31.99, lng: 110.696 },
    { lat: -31.913, lng: 110.612 },
    { lat: -31.423, lng: 109.974 },
    { lat: -31.306, lng: 109.788 },
    { lat: -30.916, lng: 109.341 },
    { lat: -30.631, lng: 108.85 },
    { lat: -30.485, lng: 108.671 },
    { lat: -29.952, lng: 108.09 },
    { lat: -29.916, lng: 108.061 },
    { lat: -29.296, lng: 107.47 },
    { lat: -29.273, lng: 107.448 },
    { lat: -28.751, lng: 106.842 },
    { lat: -28.608, lng: 106.655 },
    { lat: -28.352, lng: 106.141 },
    { lat: -28.013, lng: 105.407 },
    { lat: -27.979, lng: 105.317 },
    { lat: -27.721, lng: 104.642 },
    { lat: -27.43, lng: 103.868 },
    { lat: -27.37, lng: 103.691 },
    { lat: -27.168, lng: 103.071 },
    { lat: -26.93, lng: 102.25 },
    { lat: -26.781, lng: 101.782 },
    { lat: -26.617, lng: 101.457 },
    { lat: -26.147, lng: 100.774 },
    { lat: -26.119, lng: 100.749 },
    { lat: -25.655, lng: 100.013 },
    { lat: -25.512, lng: 99.85 },
    { lat: -24.945, lng: 99.396 },
    { lat: -24.857, lng: 99.353 },
    { lat: -24.202, lng: 98.926 },
    { lat: -24.114, lng: 98.83 },
    { lat: -23.569, lng: 98.166 },
    { lat: -23.543, lng: 98.117 },
    { lat: -23.08, lng: 97.334 },
    { lat: -22.953, lng: 97.128 },
    { lat: -22.597, lng: 96.547 },
    { lat: -22.333, lng: 96.204 },
    { lat: -21.989, lng: 95.809 },
    { lat: -21.706, lng: 95.463 },
    { lat: -21.318, lng: 95.086 },
    { lat: -21.075, lng: 94.863 },
    { lat: -20.555, lng: 94.393 },
    { lat: -20.444, lng: 94.303 },
    { lat: -19.812, lng: 93.833 },
    { lat: -19.676, lng: 93.736 },
    { lat: -19.174, lng: 93.513 },
    { lat: -18.538, lng: 93.233 },
    { lat: -18.494, lng: 93.204 },
    { lat: -17.914, lng: 92.705 },
    { lat: -17.744, lng: 92.43 },
    { lat: -17.303, lng: 91.916 },
    { lat: -17.063, lng: 91.596 },
    { lat: -16.688, lng: 91.241 },
    { lat: -16.279, lng: 90.78 },
    { lat: -16.074, lng: 90.569 },
    { lat: -15.473, lng: 89.94 },
    { lat: -15.46, lng: 89.93 },
    { lat: -14.835, lng: 89.602 },
    { lat: -14.209, lng: 89.351 },
    { lat: -14.081, lng: 89.322 },
    { lat: -13.58, lng: 89.201 },
    { lat: -12.952, lng: 89.105 },
    { lat: -12.328, lng: 88.941 },
    { lat: -12.121, lng: 88.859 },
    { lat: -11.707, lng: 88.698 },
    { lat: -11.096, lng: 88.156 },
    { lat: -10.962, lng: 88.002 },
    { lat: -10.486, lng: 87.538 },
    { lat: -9.88, lng: 87.055 },
    { lat: -9.874, lng: 87.048 },
    { lat: -9.263, lng: 86.462 },
    { lat: -8.902, lng: 86.002 },
    { lat: -8.656, lng: 85.725 },
    { lat: -8.044, lng: 85.173 },
    { lat: -7.719, lng: 84.933 },
    { lat: -7.43, lng: 84.684 },
    { lat: -6.82, lng: 83.964 },
    { lat: -6.621, lng: 83.742 },
    { lat: -6.207, lng: 83.29 },
    { lat: -5.594, lng: 82.624 },
    { lat: -5.461, lng: 82.452 },
    { lat: -4.979, lng: 81.93 },
    { lat: -4.364, lng: 81.156 },
    { lat: -4.272, lng: 81.028 },
    { lat: -3.746, lng: 80.476 },
    { lat: -3.129, lng: 79.566 },
    { lat: -3.041, lng: 79.431 },
    { lat: -2.508, lng: 78.685 },
    { lat: -1.94, lng: 77.585 },
    { lat: -1.887, lng: 77.497 },
    { lat: -1.262, lng: 76.314 },
    { lat: -0.819, lng: 75.426 },
    { lat: -0.633, lng: 75.126 },
    { lat: -0.0, lng: 74.099 },
    { lat: 0.637, lng: 72.909 },
    { lat: 0.706, lng: 72.76 },
    { lat: 1.278, lng: 71.633 },
    { lat: 1.924, lng: 70.207 },
    { lat: 2.477, lng: 68.93 },
    { lat: 2.576, lng: 68.717 },
    { lat: 3.233, lng: 67.313 },
    { lat: 3.896, lng: 65.911 },
    { lat: 4.567, lng: 64.223 },
    { lat: 5.243, lng: 62.67 },
    { lat: 5.904, lng: 62.429 },
    { lat: 6.536, lng: 63.983 },
    { lat: 7.163, lng: 65.539 },
    { lat: 7.168, lng: 65.554 },
    { lat: 7.78, lng: 67.386 },
    { lat: 8.404, lng: 68.659 },
    { lat: 8.711, lng: 69.217 },
    { lat: 9.027, lng: 69.862 },
    { lat: 9.651, lng: 70.902 },
    { lat: 10.139, lng: 71.608 },
    { lat: 10.277, lng: 71.804 },
    { lat: 10.911, lng: 72.39 },
    { lat: 11.553, lng: 72.696 },
    { lat: 12.19, lng: 72.836 },
    { lat: 12.201, lng: 72.839 },
    { lat: 12.851, lng: 72.961 },
    { lat: 13.504, lng: 73.051 },
    { lat: 14.157, lng: 73.173 },
    { lat: 14.515, lng: 73.355 },
    { lat: 14.803, lng: 73.531 },
    { lat: 15.441, lng: 74.098 },
    { lat: 15.885, lng: 74.59 },
    { lat: 16.073, lng: 74.822 },
    { lat: 16.7, lng: 75.678 },
    { lat: 16.921, lng: 75.973 },
    { lat: 17.324, lng: 76.572 },
    { lat: 17.848, lng: 77.3 },
    { lat: 17.95, lng: 77.445 },
    { lat: 18.58, lng: 78.227 },
    { lat: 18.813, lng: 78.452 },
    { lat: 19.217, lng: 78.88 },
    { lat: 19.865, lng: 79.352 },
    { lat: 19.905, lng: 79.37 },
    { lat: 20.523, lng: 79.654 },
    { lat: 21.193, lng: 79.794 },
    { lat: 21.367, lng: 79.812 },
    { lat: 21.871, lng: 79.859 },
    { lat: 22.552, lng: 79.937 },
    { lat: 22.926, lng: 80.046 },
    { lat: 23.225, lng: 80.192 },
    { lat: 23.886, lng: 80.714 },
    { lat: 23.95, lng: 80.789 },
    { lat: 24.525, lng: 81.63 },
    { lat: 24.635, lng: 81.848 },
    { lat: 25.109, lng: 83.072 },
    { lat: 25.128, lng: 83.137 },
    { lat: 25.436, lng: 84.38 },
    { lat: 25.658, lng: 85.721 },
    { lat: 25.662, lng: 85.751 },
    { lat: 25.798, lng: 87.071 },
    { lat: 25.903, lng: 88.388 },
    { lat: 25.965, lng: 89.684 },
    { lat: 25.953, lng: 90.983 },
    { lat: 25.803, lng: 92.329 },
    { lat: 25.498, lng: 93.724 },
    { lat: 25.123, lng: 95.1 },
    { lat: 25.088, lng: 95.198 },
    { lat: 24.522, lng: 96.546 },
    { lat: 24.308, lng: 96.961 },
    { lat: 23.727, lng: 98.022 },
    { lat: 23.559, lng: 98.359 },
    { lat: 23.094, lng: 99.339 },
    { lat: 22.808, lng: 100.01 },
    { lat: 22.58, lng: 100.544 },
    { lat: 22.248, lng: 101.626 },
    { lat: 22.107, lng: 102.6 },
    { lat: 22.077, lng: 103.509 },
    { lat: 22.078, lng: 104.388 },
    { lat: 22.148, lng: 105.228 },
    { lat: 22.315, lng: 106.02 },
    { lat: 22.499, lng: 106.693 },
    { lat: 22.523, lng: 106.787 },
    { lat: 22.741, lng: 107.541 },
    { lat: 23.017, lng: 108.268 },
    { lat: 23.082, lng: 108.403 },
    { lat: 23.337, lng: 108.973 },
    { lat: 23.624, lng: 109.683 },
    { lat: 23.682, lng: 109.819 },
    { lat: 23.918, lng: 110.385 },
    { lat: 24.197, lng: 111.085 },
    { lat: 24.28, lng: 111.383 },
    { lat: 24.393, lng: 111.807 },
    { lat: 24.548, lng: 112.535 },
    { lat: 24.658, lng: 113.27 },
    { lat: 24.819, lng: 113.984 },
    { lat: 24.836, lng: 114.085 },
    { lat: 24.972, lng: 114.695 },
    { lat: 25.132, lng: 115.399 },
    { lat: 25.412, lng: 116.066 },
    { lat: 25.427, lng: 116.09 },
    { lat: 26.068, lng: 116.629 },
    { lat: 26.079, lng: 116.636 },
    { lat: 26.753, lng: 116.769 },
    { lat: 27.438, lng: 116.74 },
    { lat: 28.134, lng: 116.614 },
    { lat: 28.838, lng: 116.461 },
    { lat: 29.548, lng: 116.33 },
    { lat: 29.662, lng: 116.324 },
    { lat: 30.258, lng: 116.355 },
    { lat: 30.968, lng: 116.572 },
    { lat: 31.093, lng: 116.634 },
    { lat: 31.678, lng: 116.937 },
    { lat: 32.035, lng: 117.101 },
    { lat: 32.398, lng: 117.275 },
    { lat: 33.135, lng: 117.422 },
    { lat: 33.4, lng: 117.422 },
    { lat: 33.89, lng: 117.396 },
    { lat: 34.662, lng: 117.206 },
    { lat: 35.448, lng: 116.953 },
    { lat: 36.248, lng: 116.667 },
    { lat: 37.058, lng: 116.401 },
    { lat: 37.878, lng: 116.221 },
    { lat: 38.617, lng: 116.196 },
    { lat: 38.702, lng: 116.198 },
    { lat: 39.526, lng: 116.425 },
    { lat: 39.761, lng: 116.555 },
    { lat: 40.343, lng: 117.032 },
    { lat: 40.431, lng: 117.131 },
    { lat: 40.924, lng: 117.791 },
    { lat: 41.144, lng: 118.212 },
    { lat: 41.264, lng: 118.522 },
    { lat: 41.541, lng: 119.281 },
    { lat: 41.803, lng: 120.047 },
    { lat: 41.905, lng: 120.513 },
    { lat: 41.97, lng: 120.851 },
    { lat: 42.102, lng: 121.666 },
    { lat: 42.255, lng: 122.472 },
    { lat: 42.486, lng: 123.249 },
    { lat: 42.649, lng: 123.738 },
    { lat: 42.745, lng: 124.017 },
    { lat: 43.109, lng: 124.753 },
    { lat: 43.496, lng: 125.304 },
    { lat: 43.609, lng: 125.45 },
    { lat: 44.232, lng: 126.118 },
    { lat: 44.392, lng: 126.27 },
    { lat: 44.96, lng: 126.765 },
    { lat: 45.314, lng: 127.171 },
    { lat: 45.593, lng: 127.453 },
    { lat: 46.089, lng: 128.193 },
    { lat: 46.237, lng: 128.727 },
    { lat: 46.329, lng: 129.013 },
    { lat: 46.388, lng: 129.883 },
    { lat: 46.252, lng: 130.796 },
    { lat: 46.162, lng: 131.06 },
  ],
  c19: [
    { lat: 44.243, lng: 130.526 },
    { lat: 43.923, lng: 131.301 },
    { lat: 43.271, lng: 132.047 },
    { lat: 42.904, lng: 132.323 },
    { lat: 42.346, lng: 132.664 },
    { lat: 41.45, lng: 132.903 },
    { lat: 40.578, lng: 132.896 },
    { lat: 40.034, lng: 132.797 },
    { lat: 39.727, lng: 132.73 },
    { lat: 38.893, lng: 132.464 },
    { lat: 38.26, lng: 132.303 },
    { lat: 38.074, lng: 132.237 },
    { lat: 37.266, lng: 132.094 },
    { lat: 36.468, lng: 132.078 },
    { lat: 35.681, lng: 132.175 },
    { lat: 34.901, lng: 132.453 },
    { lat: 34.13, lng: 132.842 },
    { lat: 34.039, lng: 132.884 },
    { lat: 33.373, lng: 133.107 },
    { lat: 32.628, lng: 133.267 },
    { lat: 31.894, lng: 133.262 },
    { lat: 31.548, lng: 133.173 },
    { lat: 31.173, lng: 133.051 },
    { lat: 30.464, lng: 132.629 },
    { lat: 30.444, lng: 132.612 },
    { lat: 29.764, lng: 132.137 },
    { lat: 29.631, lng: 132.026 },
    { lat: 29.073, lng: 131.599 },
    { lat: 28.848, lng: 131.444 },
    { lat: 28.387, lng: 131.15 },
    { lat: 27.859, lng: 130.893 },
    { lat: 27.706, lng: 130.824 },
    { lat: 27.03, lng: 130.541 },
    { lat: 26.63, lng: 130.377 },
    { lat: 26.361, lng: 130.24 },
    { lat: 25.699, lng: 129.863 },
    { lat: 25.656, lng: 129.836 },
    { lat: 25.046, lng: 129.34 },
    { lat: 24.966, lng: 129.266 },
    { lat: 24.476, lng: 128.673 },
    { lat: 24.402, lng: 128.574 },
    { lat: 24.066, lng: 128.073 },
    { lat: 23.767, lng: 127.661 },
    { lat: 23.636, lng: 127.476 },
    { lat: 23.214, lng: 126.879 },
    { lat: 23.137, lng: 126.779 },
    { lat: 22.777, lng: 126.285 },
    { lat: 22.509, lng: 125.957 },
    { lat: 22.307, lng: 125.696 },
    { lat: 21.896, lng: 125.099 },
    { lat: 21.888, lng: 125.086 },
    { lat: 21.55, lng: 124.493 },
    { lat: 21.274, lng: 124.072 },
    { lat: 21.149, lng: 123.895 },
    { lat: 20.748, lng: 123.296 },
    { lat: 20.661, lng: 123.194 },
    { lat: 20.154, lng: 122.725 },
    { lat: 20.043, lng: 122.644 },
    { lat: 19.426, lng: 122.23 },
    { lat: 19.355, lng: 122.185 },
    { lat: 18.811, lng: 121.901 },
    { lat: 18.321, lng: 121.676 },
    { lat: 18.197, lng: 121.629 },
    { lat: 17.584, lng: 121.446 },
    { lat: 16.973, lng: 121.328 },
    { lat: 16.362, lng: 121.292 },
    { lat: 16.336, lng: 121.293 },
    { lat: 15.754, lng: 121.32 },
    { lat: 15.148, lng: 121.362 },
    { lat: 14.545, lng: 121.391 },
    { lat: 13.944, lng: 121.407 },
    { lat: 13.346, lng: 121.42 },
    { lat: 12.75, lng: 121.47 },
    { lat: 12.156, lng: 121.537 },
    { lat: 11.563, lng: 121.595 },
    { lat: 10.974, lng: 121.639 },
    { lat: 10.386, lng: 121.652 },
    { lat: 9.8, lng: 121.634 },
    { lat: 9.216, lng: 121.612 },
    { lat: 8.633, lng: 121.633 },
    { lat: 8.05, lng: 121.711 },
    { lat: 7.469, lng: 121.862 },
    { lat: 6.888, lng: 122.082 },
    { lat: 6.725, lng: 122.149 },
    { lat: 6.308, lng: 122.346 },
    { lat: 5.729, lng: 122.752 },
    { lat: 5.655, lng: 122.818 },
    { lat: 5.151, lng: 123.195 },
    { lat: 4.794, lng: 123.465 },
    { lat: 4.574, lng: 123.618 },
    { lat: 3.999, lng: 124.036 },
    { lat: 3.908, lng: 124.103 },
    { lat: 3.425, lng: 124.395 },
    { lat: 2.853, lng: 124.66 },
    { lat: 2.656, lng: 124.736 },
    { lat: 2.281, lng: 124.861 },
    { lat: 1.71, lng: 125.046 },
    { lat: 1.14, lng: 125.257 },
    { lat: 0.922, lng: 125.358 },
    { lat: 0.57, lng: 125.496 },
    { lat: 0.0, lng: 125.756 },
    { lat: -0.479, lng: 125.959 },
    { lat: -0.569, lng: 125.994 },
    { lat: -1.139, lng: 126.151 },
    { lat: -1.708, lng: 126.25 },
    { lat: -2.278, lng: 126.277 },
    { lat: -2.848, lng: 126.324 },
    { lat: -3.418, lng: 126.445 },
    { lat: -3.621, lng: 126.519 },
    { lat: -3.988, lng: 126.656 },
    { lat: -4.559, lng: 126.909 },
    { lat: -5.061, lng: 127.08 },
    { lat: -5.13, lng: 127.098 },
    { lat: -5.703, lng: 127.112 },
    { lat: -6.107, lng: 127.047 },
    { lat: -6.277, lng: 127.014 },
    { lat: -6.852, lng: 126.906 },
    { lat: -7.429, lng: 126.848 },
    { lat: -8.006, lng: 126.815 },
    { lat: -8.585, lng: 126.72 },
    { lat: -9.166, lng: 126.527 },
    { lat: -9.713, lng: 126.285 },
    { lat: -9.75, lng: 126.261 },
    { lat: -10.336, lng: 125.964 },
    { lat: -10.922, lng: 125.877 },
    { lat: -11.508, lng: 125.89 },
    { lat: -12.098, lng: 125.813 },
    { lat: -12.692, lng: 125.477 },
    { lat: -12.699, lng: 125.472 },
    { lat: -13.232, lng: 124.809 },
    { lat: -13.296, lng: 124.689 },
    { lat: -13.895, lng: 124.476 },
    { lat: -14.49, lng: 124.659 },
    { lat: -14.557, lng: 124.689 },
    { lat: -15.087, lng: 124.847 },
    { lat: -15.689, lng: 124.925 },
    { lat: -16.299, lng: 124.653 },
    { lat: -16.573, lng: 124.483 },
    { lat: -16.916, lng: 124.206 },
    { lat: -17.527, lng: 124.249 },
    { lat: -17.689, lng: 124.355 },
    { lat: -18.137, lng: 124.507 },
    { lat: -18.749, lng: 124.828 },
    { lat: -18.827, lng: 124.858 },
    { lat: -19.366, lng: 125.027 },
    { lat: -19.993, lng: 124.943 },
    { lat: -20.628, lng: 124.638 },
    { lat: -20.666, lng: 124.619 },
    { lat: -21.269, lng: 124.28 },
    { lat: -21.911, lng: 124.068 },
    { lat: -22.554, lng: 124.052 },
    { lat: -23.198, lng: 124.142 },
    { lat: -23.724, lng: 124.157 },
    { lat: -23.848, lng: 124.174 },
    { lat: -24.503, lng: 124.193 },
    { lat: -25.167, lng: 124.088 },
    { lat: -25.837, lng: 123.991 },
    { lat: -26.51, lng: 123.957 },
    { lat: -27.195, lng: 123.732 },
    { lat: -27.533, lng: 123.459 },
    { lat: -27.902, lng: 123.032 },
    { lat: -28.133, lng: 122.629 },
    { lat: -28.369, lng: 121.865 },
    { lat: -28.42, lng: 121.135 },
    { lat: -28.565, lng: 120.376 },
    { lat: -28.712, lng: 119.628 },
    { lat: -28.739, lng: 119.6 },
    { lat: -29.431, lng: 119.17 },
    { lat: -29.894, lng: 119.287 },
    { lat: -30.135, lng: 119.308 },
    { lat: -30.862, lng: 119.049 },
    { lat: -30.904, lng: 118.997 },
    { lat: -31.407, lng: 118.081 },
    { lat: -31.624, lng: 117.237 },
    { lat: -31.537, lng: 116.484 },
    { lat: -31.168, lng: 115.824 },
    { lat: -31.007, lng: 115.7 },
    { lat: -30.508, lng: 115.264 },
    { lat: -30.317, lng: 115.045 },
    { lat: -29.819, lng: 114.714 },
    { lat: -29.624, lng: 114.605 },
    { lat: -28.909, lng: 114.804 },
    { lat: -28.769, lng: 115.067 },
    { lat: -28.594, lng: 115.893 },
    { lat: -28.728, lng: 116.615 },
    { lat: -28.778, lng: 117.357 },
    { lat: -28.487, lng: 118.189 },
    { lat: -28.065, lng: 118.35 },
    { lat: -28.041, lng: 118.312 },
    { lat: -27.581, lng: 117.697 },
    { lat: -27.409, lng: 117.475 },
    { lat: -27.26, lng: 117.043 },
    { lat: -26.934, lng: 116.388 },
    { lat: -26.775, lng: 116.205 },
    { lat: -26.609, lng: 115.731 },
    { lat: -26.472, lng: 115.015 },
    { lat: -26.484, lng: 114.249 },
    { lat: -26.381, lng: 113.511 },
    { lat: -26.226, lng: 113.0 },
    { lat: -26.194, lng: 112.794 },
    { lat: -26.208, lng: 112.004 },
    { lat: -26.274, lng: 111.187 },
    { lat: -26.33, lng: 110.362 },
    { lat: -26.359, lng: 110.034 },
    { lat: -26.393, lng: 109.521 },
    { lat: -26.385, lng: 109.475 },
    { lat: -26.079, lng: 108.814 },
    { lat: -25.803, lng: 108.086 },
    { lat: -25.899, lng: 107.203 },
    { lat: -25.838, lng: 106.689 },
    { lat: -25.141, lng: 107.083 },
    { lat: -25.084, lng: 107.522 },
    { lat: -25.11, lng: 107.73 },
    { lat: -25.652, lng: 108.144 },
    { lat: -25.154, lng: 109.156 },
    { lat: -25.039, lng: 109.274 },
    { lat: -24.328, lng: 110.247 },
    { lat: -24.324, lng: 110.252 },
    { lat: -23.634, lng: 110.968 },
    { lat: -23.436, lng: 111.323 },
    { lat: -22.932, lng: 112.093 },
    { lat: -22.841, lng: 112.27 },
    { lat: -22.437, lng: 113.14 },
    { lat: -22.229, lng: 113.479 },
    { lat: -21.563, lng: 114.039 },
    { lat: -20.919, lng: 114.139 },
    { lat: -20.282, lng: 114.121 },
    { lat: -19.645, lng: 114.226 },
    { lat: -19.37, lng: 114.618 },
    { lat: -18.989, lng: 115.109 },
    { lat: -18.759, lng: 115.46 },
    { lat: -18.34, lng: 115.933 },
    { lat: -17.711, lng: 116.179 },
    { lat: -17.089, lng: 116.361 },
    { lat: -16.918, lng: 116.504 },
    { lat: -16.455, lng: 117.145 },
    { lat: -16.419, lng: 117.275 },
    { lat: -16.355, lng: 117.965 },
    { lat: -16.277, lng: 118.652 },
    { lat: -16.082, lng: 119.349 },
    { lat: -15.785, lng: 120.053 },
    { lat: -15.779, lng: 120.062 },
    { lat: -15.169, lng: 120.247 },
    { lat: -14.917, lng: 120.166 },
    { lat: -14.571, lng: 119.957 },
    { lat: -13.976, lng: 119.649 },
    { lat: -13.857, lng: 119.64 },
    { lat: -13.379, lng: 119.525 },
    { lat: -13.012, lng: 119.083 },
    { lat: -13.063, lng: 118.417 },
    { lat: -13.011, lng: 117.757 },
    { lat: -12.884, lng: 117.102 },
    { lat: -12.832, lng: 116.774 },
    { lat: -12.781, lng: 116.44 },
    { lat: -12.787, lng: 115.757 },
    { lat: -12.644, lng: 115.089 },
    { lat: -12.559, lng: 114.407 },
    { lat: -12.517, lng: 113.713 },
    { lat: -12.417, lng: 113.02 },
    { lat: -12.315, lng: 112.657 },
    { lat: -12.196, lng: 112.338 },
    { lat: -11.992, lng: 111.648 },
    { lat: -11.855, lng: 110.94 },
    { lat: -11.756, lng: 110.702 },
    { lat: -11.445, lng: 110.266 },
    { lat: -11.171, lng: 110.005 },
    { lat: -10.904, lng: 109.604 },
    { lat: -11.066, lng: 108.828 },
    { lat: -11.2, lng: 108.619 },
    { lat: -11.802, lng: 108.558 },
    { lat: -12.009, lng: 108.676 },
    { lat: -12.392, lng: 109.14 },
    { lat: -12.501, lng: 109.35 },
    { lat: -12.981, lng: 109.794 },
    { lat: -13.588, lng: 109.765 },
    { lat: -14.212, lng: 109.164 },
    { lat: -14.276, lng: 109.022 },
    { lat: -14.666, lng: 108.172 },
    { lat: -14.758, lng: 107.369 },
    { lat: -14.756, lng: 106.575 },
    { lat: -14.921, lng: 105.749 },
    { lat: -14.927, lng: 105.728 },
    { lat: -15.477, lng: 104.769 },
    { lat: -15.445, lng: 103.939 },
    { lat: -15.23, lng: 103.143 },
    { lat: -15.02, lng: 102.499 },
    { lat: -14.959, lng: 102.35 },
    { lat: -14.866, lng: 101.494 },
    { lat: -14.889, lng: 100.589 },
    { lat: -14.725, lng: 99.719 },
    { lat: -14.526, lng: 98.843 },
    { lat: -14.61, lng: 97.858 },
    { lat: -15.205, lng: 96.937 },
    { lat: -15.387, lng: 96.613 },
    { lat: -15.885, lng: 95.54 },
    { lat: -15.934, lng: 95.389 },
    { lat: -15.966, lng: 94.318 },
    { lat: -15.934, lng: 94.222 },
    { lat: -15.57, lng: 93.388 },
    { lat: -15.342, lng: 93.101 },
    { lat: -14.922, lng: 92.533 },
    { lat: -14.734, lng: 92.398 },
    { lat: -14.11, lng: 92.199 },
    { lat: -13.483, lng: 92.147 },
    { lat: -12.853, lng: 92.239 },
    { lat: -12.41, lng: 92.327 },
    { lat: -12.224, lng: 92.369 },
    { lat: -11.605, lng: 92.266 },
    { lat: -11.0, lng: 91.669 },
    { lat: -10.979, lng: 91.647 },
    { lat: -10.389, lng: 91.28 },
    { lat: -9.904, lng: 90.803 },
    { lat: -9.784, lng: 90.698 },
    { lat: -9.172, lng: 90.409 },
    { lat: -8.558, lng: 90.225 },
    { lat: -8.023, lng: 90.108 },
    { lat: -7.944, lng: 90.088 },
    { lat: -7.333, lng: 89.834 },
    { lat: -6.723, lng: 89.576 },
    { lat: -6.11, lng: 89.505 },
    { lat: -5.492, lng: 89.802 },
    { lat: -4.876, lng: 90.107 },
    { lat: -4.266, lng: 90.018 },
    { lat: -3.956, lng: 89.627 },
    { lat: -3.664, lng: 89.091 },
    { lat: -3.469, lng: 88.448 },
    { lat: -3.067, lng: 87.247 },
    { lat: -3.051, lng: 87.203 },
    { lat: -2.554, lng: 85.896 },
    { lat: -2.463, lng: 85.626 },
    { lat: -2.105, lng: 84.503 },
    { lat: -1.857, lng: 83.459 },
    { lat: -1.757, lng: 83.007 },
    { lat: -1.243, lng: 81.974 },
    { lat: -0.968, lng: 81.416 },
    { lat: -0.623, lng: 80.718 },
    { lat: -0.001, lng: 79.66 },
    { lat: -0.0, lng: 79.659 },
    { lat: 0.627, lng: 78.833 },
    { lat: 1.129, lng: 77.654 },
    { lat: 1.258, lng: 77.437 },
    { lat: 1.893, lng: 76.325 },
    { lat: 2.404, lng: 75.261 },
    { lat: 2.533, lng: 75.005 },
    { lat: 3.18, lng: 73.465 },
    { lat: 3.797, lng: 72.19 },
    { lat: 3.831, lng: 72.142 },
    { lat: 4.479, lng: 71.418 },
    { lat: 5.125, lng: 71.091 },
    { lat: 5.768, lng: 71.102 },
    { lat: 5.978, lng: 71.257 },
    { lat: 6.401, lng: 71.757 },
    { lat: 7.028, lng: 72.593 },
    { lat: 7.657, lng: 73.288 },
    { lat: 7.735, lng: 73.363 },
    { lat: 8.282, lng: 74.075 },
    { lat: 8.908, lng: 74.798 },
    { lat: 9.253, lng: 75.107 },
    { lat: 9.536, lng: 75.381 },
    { lat: 10.167, lng: 75.827 },
    { lat: 10.804, lng: 76.064 },
    { lat: 11.323, lng: 76.158 },
    { lat: 11.446, lng: 76.183 },
    { lat: 12.091, lng: 76.227 },
    { lat: 12.741, lng: 76.202 },
    { lat: 13.392, lng: 76.185 },
    { lat: 14.042, lng: 76.255 },
    { lat: 14.136, lng: 76.289 },
    { lat: 14.684, lng: 76.567 },
    { lat: 15.321, lng: 77.05 },
    { lat: 15.57, lng: 77.284 },
    { lat: 15.951, lng: 77.706 },
    { lat: 16.579, lng: 78.436 },
    { lat: 16.61, lng: 78.475 },
    { lat: 17.202, lng: 79.275 },
    { lat: 17.523, lng: 79.656 },
    { lat: 17.827, lng: 80.059 },
    { lat: 18.43, lng: 80.743 },
    { lat: 18.457, lng: 80.778 },
    { lat: 19.092, lng: 81.401 },
    { lat: 19.47, lng: 81.63 },
    { lat: 19.739, lng: 81.799 },
    { lat: 20.398, lng: 82.034 },
    { lat: 20.886, lng: 82.107 },
    { lat: 21.065, lng: 82.16 },
    { lat: 21.73, lng: 82.368 },
    { lat: 22.225, lng: 82.573 },
    { lat: 22.394, lng: 82.678 },
    { lat: 23.05, lng: 83.161 },
    { lat: 23.207, lng: 83.313 },
    { lat: 23.693, lng: 83.899 },
    { lat: 23.91, lng: 84.266 },
    { lat: 24.31, lng: 85.118 },
    { lat: 24.399, lng: 85.371 },
    { lat: 24.667, lng: 86.623 },
    { lat: 24.846, lng: 87.668 },
    { lat: 24.876, lng: 87.869 },
    { lat: 25.006, lng: 89.125 },
    { lat: 25.076, lng: 90.377 },
    { lat: 25.019, lng: 91.671 },
    { lat: 24.8, lng: 93.024 },
    { lat: 24.458, lng: 94.395 },
    { lat: 24.448, lng: 94.427 },
    { lat: 23.948, lng: 95.804 },
    { lat: 23.663, lng: 96.381 },
    { lat: 23.178, lng: 97.283 },
    { lat: 22.92, lng: 97.771 },
    { lat: 22.46, lng: 98.656 },
    { lat: 22.182, lng: 99.256 },
    { lat: 21.861, lng: 99.91 },
    { lat: 21.464, lng: 101.027 },
    { lat: 21.433, lng: 101.198 },
    { lat: 21.291, lng: 102.02 },
    { lat: 21.251, lng: 102.938 },
    { lat: 21.275, lng: 103.815 },
    { lat: 21.301, lng: 104.178 },
    { lat: 21.327, lng: 104.665 },
    { lat: 21.429, lng: 105.485 },
    { lat: 21.607, lng: 106.267 },
    { lat: 21.83, lng: 107.025 },
    { lat: 21.831, lng: 107.027 },
    { lat: 22.021, lng: 107.784 },
    { lat: 22.26, lng: 108.519 },
    { lat: 22.41, lng: 108.809 },
    { lat: 22.605, lng: 109.213 },
    { lat: 22.914, lng: 109.912 },
    { lat: 23.01, lng: 110.139 },
    { lat: 23.201, lng: 110.612 },
    { lat: 23.484, lng: 111.308 },
    { lat: 23.603, lng: 111.752 },
    { lat: 23.671, lng: 112.028 },
    { lat: 23.835, lng: 112.748 },
    { lat: 23.954, lng: 113.475 },
    { lat: 24.086, lng: 114.192 },
    { lat: 24.14, lng: 114.91 },
    { lat: 24.142, lng: 114.925 },
    { lat: 24.204, lng: 115.649 },
    { lat: 24.32, lng: 116.353 },
    { lat: 24.488, lng: 117.04 },
    { lat: 24.705, lng: 117.604 },
    { lat: 24.77, lng: 117.695 },
    { lat: 25.349, lng: 118.234 },
    { lat: 25.423, lng: 118.258 },
    { lat: 26.014, lng: 118.41 },
    { lat: 26.69, lng: 118.428 },
    { lat: 27.375, lng: 118.36 },
    { lat: 28.069, lng: 118.25 },
    { lat: 28.463, lng: 118.196 },
    { lat: 28.768, lng: 118.171 },
    { lat: 29.466, lng: 118.275 },
    { lat: 29.986, lng: 118.511 },
    { lat: 30.162, lng: 118.619 },
    { lat: 30.854, lng: 119.012 },
    { lat: 30.863, lng: 119.015 },
    { lat: 31.581, lng: 119.178 },
    { lat: 32.317, lng: 119.131 },
    { lat: 33.067, lng: 118.953 },
    { lat: 33.828, lng: 118.728 },
    { lat: 34.602, lng: 118.464 },
    { lat: 35.387, lng: 118.202 },
    { lat: 36.183, lng: 117.94 },
    { lat: 36.902, lng: 117.754 },
    { lat: 36.99, lng: 117.732 },
    { lat: 37.801, lng: 117.672 },
    { lat: 38.612, lng: 117.86 },
    { lat: 38.669, lng: 117.888 },
    { lat: 39.424, lng: 118.304 },
    { lat: 39.52, lng: 118.392 },
    { lat: 40.018, lng: 119.044 },
    { lat: 40.218, lng: 119.366 },
    { lat: 40.404, lng: 119.745 },
    { lat: 40.679, lng: 120.493 },
    { lat: 40.895, lng: 121.263 },
    { lat: 40.968, lng: 121.671 },
    { lat: 41.024, lng: 122.063 },
    { lat: 41.151, lng: 122.863 },
    { lat: 41.277, lng: 123.66 },
    { lat: 41.406, lng: 124.454 },
    { lat: 41.644, lng: 125.214 },
    { lat: 41.688, lng: 125.295 },
    { lat: 42.027, lng: 125.934 },
    { lat: 42.525, lng: 126.626 },
    { lat: 42.53, lng: 126.631 },
    { lat: 43.126, lng: 127.297 },
    { lat: 43.407, lng: 127.605 },
    { lat: 43.708, lng: 127.984 },
    { lat: 44.068, lng: 128.739 },
    { lat: 44.276, lng: 129.466 },
    { lat: 44.293, lng: 129.533 },
    { lat: 44.289, lng: 130.382 },
    { lat: 44.243, lng: 130.526 },
  ],
  c20: [
    { lat: -20.153, lng: 100.694 },
    { lat: -20.706, lng: 100.404 },
    { lat: -20.16, lng: 100.54 },
    { lat: -20.137, lng: 100.633 },
    { lat: -20.153, lng: 100.694 },
  ],
  c21: [
    { lat: -7.497, lng: 119.009 },
    { lat: -8.076, lng: 119.246 },
    { lat: -8.257, lng: 118.88 },
    { lat: -8.29, lng: 118.227 },
    { lat: -8.09, lng: 118.062 },
    { lat: -7.505, lng: 118.247 },
    { lat: -7.47, lng: 118.286 },
    { lat: -7.45, lng: 118.936 },
    { lat: -7.497, lng: 119.009 },
  ],
  c22: [
    { lat: -2.397, lng: 96.599 },
    { lat: -2.994, lng: 97.165 },
    { lat: -3.418, lng: 97.161 },
    { lat: -3.593, lng: 97.163 },
    { lat: -3.6, lng: 97.147 },
    { lat: -3.714, lng: 96.165 },
    { lat: -3.606, lng: 95.618 },
    { lat: -3.455, lng: 95.187 },
    { lat: -3.089, lng: 94.188 },
    { lat: -3.015, lng: 94.085 },
    { lat: -2.413, lng: 93.881 },
    { lat: -1.808, lng: 94.265 },
    { lat: -1.899, lng: 95.285 },
    { lat: -2.18, lng: 96.268 },
    { lat: -2.397, lng: 96.599 },
  ],
  c23: [
    { lat: 6.59, lng: 97.926 },
    { lat: 5.984, lng: 98.226 },
    { lat: 5.382, lng: 98.3 },
    { lat: 4.782, lng: 98.31 },
    { lat: 4.183, lng: 98.274 },
    { lat: 3.585, lng: 98.175 },
    { lat: 3.413, lng: 98.109 },
    { lat: 2.99, lng: 97.62 },
    { lat: 2.643, lng: 97.212 },
    { lat: 2.399, lng: 96.36 },
    { lat: 1.799, lng: 96.349 },
    { lat: 1.199, lng: 96.348 },
    { lat: 1.084, lng: 96.308 },
    { lat: 0.6, lng: 95.937 },
    { lat: 0.041, lng: 95.328 },
    { lat: -0.0, lng: 95.162 },
    { lat: -0.552, lng: 94.302 },
    { lat: -0.0, lng: 94.124 },
    { lat: 0.603, lng: 93.941 },
    { lat: 1.206, lng: 93.903 },
    { lat: 1.808, lng: 94.105 },
    { lat: 1.994, lng: 94.257 },
    { lat: 2.393, lng: 95.26 },
    { lat: 2.404, lng: 95.446 },
    { lat: 3.001, lng: 96.033 },
    { lat: 3.603, lng: 95.993 },
    { lat: 4.205, lng: 96.008 },
    { lat: 4.807, lng: 95.995 },
    { lat: 5.191, lng: 96.014 },
    { lat: 5.41, lng: 96.05 },
    { lat: 6.009, lng: 96.424 },
    { lat: 6.608, lng: 96.745 },
    { lat: 6.679, lng: 96.797 },
    { lat: 6.773, lng: 97.744 },
    { lat: 6.59, lng: 97.926 },
  ],
  c24: [
    { lat: -19.428, lng: 122.167 },
    { lat: -20.06, lng: 121.929 },
    { lat: -20.691, lng: 121.92 },
    { lat: -20.769, lng: 121.965 },
    { lat: -21.321, lng: 122.105 },
    { lat: -21.96, lng: 122.123 },
    { lat: -22.611, lng: 121.808 },
    { lat: -22.786, lng: 121.618 },
    { lat: -23.148, lng: 120.866 },
    { lat: -23.29, lng: 120.147 },
    { lat: -23.313, lng: 119.961 },
    { lat: -23.383, lng: 119.431 },
    { lat: -23.35, lng: 118.758 },
    { lat: -23.349, lng: 118.736 },
    { lat: -23.236, lng: 118.053 },
    { lat: -22.891, lng: 117.419 },
    { lat: -22.748, lng: 117.263 },
    { lat: -22.109, lng: 116.997 },
    { lat: -21.463, lng: 117.094 },
    { lat: -20.808, lng: 117.657 },
    { lat: -20.677, lng: 117.89 },
    { lat: -20.333, lng: 118.65 },
    { lat: -20.185, lng: 119.364 },
    { lat: -20.12, lng: 119.597 },
    { lat: -19.898, lng: 120.094 },
    { lat: -19.46, lng: 120.824 },
    { lat: -19.432, lng: 120.844 },
    { lat: -18.837, lng: 121.601 },
    { lat: -19.428, lng: 122.167 },
  ],
  c25: [
    { lat: -8.357, lng: 100.362 },
    { lat: -8.79, lng: 101.079 },
    { lat: -8.943, lng: 101.271 },
    { lat: -9.55, lng: 101.114 },
    { lat: -9.625, lng: 100.933 },
    { lat: -9.71, lng: 100.024 },
    { lat: -9.666, lng: 99.119 },
    { lat: -9.608, lng: 98.376 },
    { lat: -9.569, lng: 98.205 },
    { lat: -9.188, lng: 97.327 },
    { lat: -9.032, lng: 96.856 },
    { lat: -8.689, lng: 96.45 },
    { lat: -8.435, lng: 96.282 },
    { lat: -8.265, lng: 96.531 },
    { lat: -8.263, lng: 97.502 },
    { lat: -8.299, lng: 98.443 },
    { lat: -8.319, lng: 99.365 },
    { lat: -8.334, lng: 100.267 },
    { lat: -8.357, lng: 100.362 },
  ],
  c26: [
    { lat: -6.868, lng: 124.673 },
    { lat: -7.313, lng: 125.195 },
    { lat: -7.44, lng: 125.248 },
    { lat: -8.017, lng: 125.35 },
    { lat: -8.566, lng: 125.133 },
    { lat: -8.599, lng: 125.105 },
    { lat: -8.969, lng: 124.499 },
    { lat: -9.19, lng: 123.972 },
    { lat: -9.258, lng: 123.866 },
    { lat: -9.193, lng: 123.696 },
    { lat: -8.987, lng: 123.264 },
    { lat: -8.618, lng: 123.021 },
    { lat: -8.04, lng: 122.77 },
    { lat: -7.46, lng: 122.873 },
    { lat: -6.97, lng: 123.377 },
    { lat: -6.876, lng: 123.602 },
    { lat: -6.709, lng: 124.003 },
    { lat: -6.819, lng: 124.609 },
    { lat: -6.868, lng: 124.673 },
  ],
  c27: [
    { lat: 22.665, lng: 119.901 },
    { lat: 22.285, lng: 120.344 },
    { lat: 22.003, lng: 120.54 },
    { lat: 21.36, lng: 120.649 },
    { lat: 20.724, lng: 120.655 },
    { lat: 20.614, lng: 120.647 },
    { lat: 20.093, lng: 120.63 },
    { lat: 19.468, lng: 120.514 },
    { lat: 18.847, lng: 120.355 },
    { lat: 18.65, lng: 120.299 },
    { lat: 18.23, lng: 120.167 },
    { lat: 17.614, lng: 120.096 },
    { lat: 16.999, lng: 120.113 },
    { lat: 16.386, lng: 120.189 },
    { lat: 15.774, lng: 120.294 },
    { lat: 15.167, lng: 120.375 },
    { lat: 14.562, lng: 120.435 },
    { lat: 13.961, lng: 120.491 },
    { lat: 13.361, lng: 120.543 },
    { lat: 12.764, lng: 120.613 },
    { lat: 12.169, lng: 120.683 },
    { lat: 11.576, lng: 120.738 },
    { lat: 10.985, lng: 120.766 },
    { lat: 10.398, lng: 120.751 },
    { lat: 9.812, lng: 120.694 },
    { lat: 9.787, lng: 120.691 },
    { lat: 9.228, lng: 120.617 },
    { lat: 8.644, lng: 120.569 },
    { lat: 8.062, lng: 120.542 },
    { lat: 7.482, lng: 120.516 },
    { lat: 6.901, lng: 120.537 },
    { lat: 6.322, lng: 120.639 },
    { lat: 5.742, lng: 120.888 },
    { lat: 5.655, lng: 120.948 },
    { lat: 5.163, lng: 121.225 },
    { lat: 4.64, lng: 121.616 },
    { lat: 4.585, lng: 121.668 },
    { lat: 4.012, lng: 122.258 },
    { lat: 4.007, lng: 122.262 },
    { lat: 3.432, lng: 122.724 },
    { lat: 3.209, lng: 122.896 },
    { lat: 2.858, lng: 123.136 },
    { lat: 2.285, lng: 123.487 },
    { lat: 2.21, lng: 123.528 },
    { lat: 1.713, lng: 123.736 },
    { lat: 1.141, lng: 124.026 },
    { lat: 0.93, lng: 124.151 },
    { lat: 0.57, lng: 124.334 },
    { lat: 0.0, lng: 124.658 },
    { lat: -0.257, lng: 124.758 },
    { lat: -0.57, lng: 124.862 },
    { lat: -1.14, lng: 124.954 },
    { lat: -1.71, lng: 124.961 },
    { lat: -2.281, lng: 124.936 },
    { lat: -2.852, lng: 124.991 },
    { lat: -3.423, lng: 125.056 },
    { lat: -3.995, lng: 125.124 },
    { lat: -4.567, lng: 125.095 },
    { lat: -5.142, lng: 124.79 },
    { lat: -5.264, lng: 124.67 },
    { lat: -5.424, lng: 124.056 },
    { lat: -5.249, lng: 123.45 },
    { lat: -5.153, lng: 122.895 },
    { lat: -5.148, lng: 122.838 },
    { lat: -4.993, lng: 122.225 },
    { lat: -4.741, lng: 121.612 },
    { lat: -4.587, lng: 121.387 },
    { lat: -4.333, lng: 121.001 },
    { lat: -4.07, lng: 120.38 },
    { lat: -4.017, lng: 120.307 },
    { lat: -3.465, lng: 119.765 },
    { lat: -3.445, lng: 119.75 },
    { lat: -2.871, lng: 119.427 },
    { lat: -2.297, lng: 119.193 },
    { lat: -2.237, lng: 119.158 },
    { lat: -1.723, lng: 118.894 },
    { lat: -1.149, lng: 118.653 },
    { lat: -0.894, lng: 118.536 },
    { lat: -0.575, lng: 118.366 },
    { lat: 0.0, lng: 117.94 },
    { lat: 0.057, lng: 117.894 },
    { lat: 0.575, lng: 117.435 },
    { lat: 0.852, lng: 117.24 },
    { lat: 1.152, lng: 116.942 },
    { lat: 1.505, lng: 116.576 },
    { lat: 1.731, lng: 116.008 },
    { lat: 1.771, lng: 115.909 },
    { lat: 1.734, lng: 115.241 },
    { lat: 1.733, lng: 115.237 },
    { lat: 1.444, lng: 114.572 },
    { lat: 1.157, lng: 114.328 },
    { lat: 0.579, lng: 113.903 },
    { lat: 0.576, lng: 113.901 },
    { lat: 0.0, lng: 113.509 },
    { lat: -0.339, lng: 113.215 },
    { lat: -0.58, lng: 113.02 },
    { lat: -1.041, lng: 112.515 },
    { lat: -1.16, lng: 112.333 },
    { lat: -1.471, lng: 111.806 },
    { lat: -1.744, lng: 111.362 },
    { lat: -1.928, lng: 111.086 },
    { lat: -2.329, lng: 110.528 },
    { lat: -2.477, lng: 110.352 },
    { lat: -2.745, lng: 109.614 },
    { lat: -2.832, lng: 108.874 },
    { lat: -2.892, lng: 108.124 },
    { lat: -2.927, lng: 107.684 },
    { lat: -2.952, lng: 107.364 },
    { lat: -3.064, lng: 106.59 },
    { lat: -3.195, lng: 105.804 },
    { lat: -3.215, lng: 105.01 },
    { lat: -3.226, lng: 104.204 },
    { lat: -3.083, lng: 103.392 },
    { lat: -2.956, lng: 102.939 },
    { lat: -2.84, lng: 102.571 },
    { lat: -2.37, lng: 101.745 },
    { lat: -2.37, lng: 101.744 },
    { lat: -1.78, lng: 100.975 },
    { lat: -1.707, lng: 100.906 },
    { lat: -1.188, lng: 100.409 },
    { lat: -0.594, lng: 100.225 },
    { lat: 0.0, lng: 100.25 },
    { lat: 0.594, lng: 100.381 },
    { lat: 1.188, lng: 100.633 },
    { lat: 1.664, lng: 100.907 },
    { lat: 1.78, lng: 100.967 },
    { lat: 2.373, lng: 101.18 },
    { lat: 2.966, lng: 101.263 },
    { lat: 3.56, lng: 101.273 },
    { lat: 4.155, lng: 101.293 },
    { lat: 4.749, lng: 101.385 },
    { lat: 5.344, lng: 101.503 },
    { lat: 5.488, lng: 101.519 },
    { lat: 5.94, lng: 101.565 },
    { lat: 6.537, lng: 101.544 },
    { lat: 7.138, lng: 101.427 },
    { lat: 7.74, lng: 101.249 },
    { lat: 7.774, lng: 101.237 },
    { lat: 8.345, lng: 101.02 },
    { lat: 8.955, lng: 100.648 },
    { lat: 9.555, lng: 100.053 },
    { lat: 9.573, lng: 100.028 },
    { lat: 10.125, lng: 99.026 },
    { lat: 10.209, lng: 98.738 },
    { lat: 10.414, lng: 98.025 },
    { lat: 10.735, lng: 96.987 },
    { lat: 10.873, lng: 96.546 },
    { lat: 11.097, lng: 95.904 },
    { lat: 11.468, lng: 94.779 },
    { lat: 11.502, lng: 93.711 },
    { lat: 11.576, lng: 93.303 },
    { lat: 11.726, lng: 92.547 },
    { lat: 11.623, lng: 91.603 },
    { lat: 11.586, lng: 91.458 },
    { lat: 11.017, lng: 91.027 },
    { lat: 10.402, lng: 90.762 },
    { lat: 10.156, lng: 90.731 },
    { lat: 9.784, lng: 90.707 },
    { lat: 9.162, lng: 90.844 },
    { lat: 8.539, lng: 91.146 },
    { lat: 7.922, lng: 91.238 },
    { lat: 7.31, lng: 91.152 },
    { lat: 6.698, lng: 91.048 },
    { lat: 6.087, lng: 90.989 },
    { lat: 5.48, lng: 90.668 },
    { lat: 5.428, lng: 90.614 },
    { lat: 4.978, lng: 89.49 },
    { lat: 4.989, lng: 88.245 },
    { lat: 5.181, lng: 86.908 },
    { lat: 5.544, lng: 86.04 },
    { lat: 5.918, lng: 85.385 },
    { lat: 6.183, lng: 84.781 },
    { lat: 6.687, lng: 83.725 },
    { lat: 6.83, lng: 83.375 },
    { lat: 7.472, lng: 82.509 },
    { lat: 8.108, lng: 82.091 },
    { lat: 8.745, lng: 81.779 },
    { lat: 9.383, lng: 81.498 },
    { lat: 10.022, lng: 81.263 },
    { lat: 10.663, lng: 81.051 },
    { lat: 11.309, lng: 80.741 },
    { lat: 11.959, lng: 80.406 },
    { lat: 12.61, lng: 80.113 },
    { lat: 13.258, lng: 79.997 },
    { lat: 13.903, lng: 80.01 },
    { lat: 14.462, lng: 80.158 },
    { lat: 14.545, lng: 80.189 },
    { lat: 15.177, lng: 80.656 },
    { lat: 15.692, lng: 81.102 },
    { lat: 15.805, lng: 81.238 },
    { lat: 16.427, lng: 81.974 },
    { lat: 16.613, lng: 82.172 },
    { lat: 17.051, lng: 82.66 },
    { lat: 17.519, lng: 83.173 },
    { lat: 17.676, lng: 83.363 },
    { lat: 18.305, lng: 83.996 },
    { lat: 18.447, lng: 84.091 },
    { lat: 18.942, lng: 84.469 },
    { lat: 19.591, lng: 84.75 },
    { lat: 19.631, lng: 84.761 },
    { lat: 20.246, lng: 84.955 },
    { lat: 20.906, lng: 85.14 },
    { lat: 21.039, lng: 85.195 },
    { lat: 21.558, lng: 85.508 },
    { lat: 22.042, lng: 85.89 },
    { lat: 22.205, lng: 86.033 },
    { lat: 22.847, lng: 86.666 },
    { lat: 22.863, lng: 86.687 },
    { lat: 23.384, lng: 87.686 },
    { lat: 23.458, lng: 87.907 },
    { lat: 23.683, lng: 88.817 },
    { lat: 23.873, lng: 89.985 },
    { lat: 23.899, lng: 91.227 },
    { lat: 23.703, lng: 92.566 },
    { lat: 23.425, lng: 93.899 },
    { lat: 23.065, lng: 94.978 },
    { lat: 22.928, lng: 95.3 },
    { lat: 22.322, lng: 96.417 },
    { lat: 22.068, lng: 96.82 },
    { lat: 21.602, lng: 97.591 },
    { lat: 21.219, lng: 98.245 },
    { lat: 20.883, lng: 98.914 },
    { lat: 20.592, lng: 99.5 },
    { lat: 20.174, lng: 100.619 },
    { lat: 20.147, lng: 100.834 },
    { lat: 20.055, lng: 101.588 },
    { lat: 20.042, lng: 102.497 },
    { lat: 20.064, lng: 102.807 },
    { lat: 20.089, lng: 103.367 },
    { lat: 20.179, lng: 104.207 },
    { lat: 20.357, lng: 105.004 },
    { lat: 20.548, lng: 105.785 },
    { lat: 20.581, lng: 105.911 },
    { lat: 20.719, lng: 106.563 },
    { lat: 20.921, lng: 107.322 },
    { lat: 21.126, lng: 108.071 },
    { lat: 21.138, lng: 108.112 },
    { lat: 21.338, lng: 108.809 },
    { lat: 21.694, lng: 109.496 },
    { lat: 21.728, lng: 109.569 },
    { lat: 22.001, lng: 110.192 },
    { lat: 22.292, lng: 110.887 },
    { lat: 22.324, lng: 110.958 },
    { lat: 22.567, lng: 111.581 },
    { lat: 22.777, lng: 112.288 },
    { lat: 22.909, lng: 112.717 },
    { lat: 22.98, lng: 112.992 },
    { lat: 23.107, lng: 113.711 },
    { lat: 23.185, lng: 114.436 },
    { lat: 23.164, lng: 115.181 },
    { lat: 23.189, lng: 115.907 },
    { lat: 23.169, lng: 116.637 },
    { lat: 23.074, lng: 117.378 },
    { lat: 22.962, lng: 118.114 },
    { lat: 22.915, lng: 118.829 },
    { lat: 22.772, lng: 119.557 },
    { lat: 22.665, lng: 119.901 },
  ],
  c28: [
    { lat: 38.986, lng: 128.89 },
    { lat: 38.156, lng: 129.021 },
    { lat: 37.339, lng: 129.174 },
    { lat: 36.535, lng: 129.346 },
    { lat: 35.739, lng: 129.695 },
    { lat: 35.659, lng: 129.749 },
    { lat: 34.951, lng: 130.178 },
    { lat: 34.203, lng: 130.718 },
    { lat: 34.174, lng: 130.736 },
    { lat: 33.412, lng: 131.133 },
    { lat: 32.664, lng: 131.338 },
    { lat: 31.93, lng: 131.356 },
    { lat: 31.208, lng: 131.188 },
    { lat: 31.143, lng: 131.157 },
    { lat: 30.498, lng: 130.866 },
    { lat: 30.086, lng: 130.611 },
    { lat: 29.796, lng: 130.485 },
    { lat: 29.102, lng: 130.144 },
    { lat: 28.972, lng: 130.081 },
    { lat: 28.414, lng: 129.817 },
    { lat: 27.848, lng: 129.558 },
    { lat: 27.733, lng: 129.488 },
    { lat: 27.058, lng: 129.134 },
    { lat: 26.865, lng: 129.022 },
    { lat: 26.393, lng: 128.642 },
    { lat: 26.185, lng: 128.45 },
    { lat: 25.764, lng: 127.844 },
    { lat: 25.742, lng: 127.782 },
    { lat: 25.526, lng: 127.214 },
    { lat: 25.421, lng: 126.563 },
    { lat: 25.231, lng: 125.923 },
    { lat: 25.126, lng: 125.677 },
    { lat: 24.938, lng: 125.3 },
    { lat: 24.487, lng: 124.823 },
    { lat: 24.396, lng: 124.717 },
    { lat: 23.848, lng: 124.166 },
    { lat: 23.83, lng: 124.139 },
    { lat: 23.585, lng: 123.508 },
    { lat: 23.237, lng: 122.894 },
    { lat: 23.28, lng: 122.209 },
    { lat: 23.921, lng: 121.479 },
    { lat: 24.578, lng: 121.511 },
    { lat: 25.235, lng: 121.722 },
    { lat: 25.372, lng: 121.808 },
    { lat: 25.848, lng: 122.406 },
    { lat: 25.88, lng: 122.49 },
    { lat: 26.106, lng: 123.048 },
    { lat: 26.52, lng: 123.597 },
    { lat: 26.59, lng: 123.646 },
    { lat: 27.193, lng: 123.816 },
    { lat: 27.888, lng: 123.461 },
    { lat: 28.011, lng: 123.361 },
    { lat: 28.596, lng: 122.965 },
    { lat: 29.312, lng: 122.455 },
    { lat: 29.487, lng: 122.324 },
    { lat: 30.038, lng: 121.903 },
    { lat: 30.769, lng: 121.439 },
    { lat: 31.462, lng: 121.094 },
    { lat: 31.506, lng: 121.074 },
    { lat: 32.251, lng: 120.735 },
    { lat: 33.005, lng: 120.42 },
    { lat: 33.767, lng: 120.126 },
    { lat: 34.54, lng: 119.845 },
    { lat: 35.322, lng: 119.615 },
    { lat: 36.112, lng: 119.447 },
    { lat: 36.882, lng: 119.404 },
    { lat: 36.908, lng: 119.406 },
    { lat: 37.704, lng: 119.626 },
    { lat: 38.04, lng: 119.805 },
    { lat: 38.497, lng: 120.178 },
    { lat: 38.68, lng: 120.398 },
    { lat: 39.036, lng: 121.098 },
    { lat: 39.262, lng: 121.605 },
    { lat: 39.335, lng: 121.82 },
    { lat: 39.438, lng: 122.608 },
    { lat: 39.506, lng: 123.404 },
    { lat: 39.489, lng: 124.222 },
    { lat: 39.421, lng: 125.049 },
    { lat: 39.384, lng: 125.861 },
    { lat: 39.54, lng: 126.617 },
    { lat: 39.481, lng: 127.426 },
    { lat: 39.397, lng: 128.234 },
    { lat: 38.986, lng: 128.89 },
  ],
  c29: [
    { lat: 21.465, lng: 117.036 },
    { lat: 20.806, lng: 117.724 },
    { lat: 20.331, lng: 117.958 },
    { lat: 20.163, lng: 118.031 },
    { lat: 19.601, lng: 118.097 },
    { lat: 19.531, lng: 118.103 },
    { lat: 18.907, lng: 118.003 },
    { lat: 18.285, lng: 117.96 },
    { lat: 17.664, lng: 118.038 },
    { lat: 17.043, lng: 118.216 },
    { lat: 16.424, lng: 118.447 },
    { lat: 15.808, lng: 118.697 },
    { lat: 15.684, lng: 118.738 },
    { lat: 15.197, lng: 118.889 },
    { lat: 14.589, lng: 119.039 },
    { lat: 13.985, lng: 119.156 },
    { lat: 13.384, lng: 119.265 },
    { lat: 12.785, lng: 119.349 },
    { lat: 12.189, lng: 119.414 },
    { lat: 11.595, lng: 119.458 },
    { lat: 11.005, lng: 119.456 },
    { lat: 10.416, lng: 119.401 },
    { lat: 10.247, lng: 119.366 },
    { lat: 9.831, lng: 119.208 },
    { lat: 9.248, lng: 118.959 },
    { lat: 8.893, lng: 118.831 },
    { lat: 8.666, lng: 118.709 },
    { lat: 8.086, lng: 118.36 },
    { lat: 7.942, lng: 118.253 },
    { lat: 7.511, lng: 117.656 },
    { lat: 7.496, lng: 117.63 },
    { lat: 7.371, lng: 116.98 },
    { lat: 7.459, lng: 116.309 },
    { lat: 7.528, lng: 116.103 },
    { lat: 7.692, lng: 115.62 },
    { lat: 7.939, lng: 114.922 },
    { lat: 8.135, lng: 114.385 },
    { lat: 8.203, lng: 114.214 },
    { lat: 8.408, lng: 113.503 },
    { lat: 8.49, lng: 112.796 },
    { lat: 8.563, lng: 112.081 },
    { lat: 8.463, lng: 111.376 },
    { lat: 8.179, lng: 111.199 },
    { lat: 7.592, lng: 111.056 },
    { lat: 7.002, lng: 111.121 },
    { lat: 6.413, lng: 111.276 },
    { lat: 5.826, lng: 111.37 },
    { lat: 5.241, lng: 111.42 },
    { lat: 4.656, lng: 111.442 },
    { lat: 4.073, lng: 111.357 },
    { lat: 3.491, lng: 111.184 },
    { lat: 3.062, lng: 111.05 },
    { lat: 2.91, lng: 110.995 },
    { lat: 2.328, lng: 110.787 },
    { lat: 1.747, lng: 110.509 },
    { lat: 1.55, lng: 110.376 },
    { lat: 1.166, lng: 109.91 },
    { lat: 1.027, lng: 109.658 },
    { lat: 0.83, lng: 108.924 },
    { lat: 0.748, lng: 108.179 },
    { lat: 0.841, lng: 107.423 },
    { lat: 1.08, lng: 106.652 },
    { lat: 1.173, lng: 106.388 },
    { lat: 1.391, lng: 105.868 },
    { lat: 1.763, lng: 105.532 },
    { lat: 2.226, lng: 105.053 },
    { lat: 2.354, lng: 104.961 },
    { lat: 2.945, lng: 104.625 },
    { lat: 3.537, lng: 104.411 },
    { lat: 4.129, lng: 104.257 },
    { lat: 4.721, lng: 104.195 },
    { lat: 5.314, lng: 104.161 },
    { lat: 5.907, lng: 104.147 },
    { lat: 6.501, lng: 104.164 },
    { lat: 7.096, lng: 104.217 },
    { lat: 7.691, lng: 104.303 },
    { lat: 8.287, lng: 104.367 },
    { lat: 8.884, lng: 104.418 },
    { lat: 9.069, lng: 104.422 },
    { lat: 9.483, lng: 104.48 },
    { lat: 10.083, lng: 104.52 },
    { lat: 10.686, lng: 104.515 },
    { lat: 11.288, lng: 104.59 },
    { lat: 11.893, lng: 104.652 },
    { lat: 12.131, lng: 104.705 },
    { lat: 12.499, lng: 104.736 },
    { lat: 13.11, lng: 104.679 },
    { lat: 13.726, lng: 104.54 },
    { lat: 14.346, lng: 104.349 },
    { lat: 14.806, lng: 104.103 },
    { lat: 14.974, lng: 103.981 },
    { lat: 15.61, lng: 103.487 },
    { lat: 16.245, lng: 103.14 },
    { lat: 16.872, lng: 103.106 },
    { lat: 17.209, lng: 103.441 },
    { lat: 17.484, lng: 103.604 },
    { lat: 17.82, lng: 104.114 },
    { lat: 18.084, lng: 104.465 },
    { lat: 18.251, lng: 104.831 },
    { lat: 18.681, lng: 105.459 },
    { lat: 18.716, lng: 105.529 },
    { lat: 19.067, lng: 106.253 },
    { lat: 19.269, lng: 106.714 },
    { lat: 19.356, lng: 106.986 },
    { lat: 19.592, lng: 107.727 },
    { lat: 19.848, lng: 108.293 },
    { lat: 19.897, lng: 108.439 },
    { lat: 20.104, lng: 109.172 },
    { lat: 20.425, lng: 109.865 },
    { lat: 20.429, lng: 109.875 },
    { lat: 20.661, lng: 110.576 },
    { lat: 20.93, lng: 111.272 },
    { lat: 21.012, lng: 111.466 },
    { lat: 21.212, lng: 111.958 },
    { lat: 21.501, lng: 112.637 },
    { lat: 21.604, lng: 112.888 },
    { lat: 21.743, lng: 113.324 },
    { lat: 21.843, lng: 114.041 },
    { lat: 21.865, lng: 114.771 },
    { lat: 21.808, lng: 115.513 },
    { lat: 21.764, lng: 116.244 },
    { lat: 21.473, lng: 117.022 },
    { lat: 21.465, lng: 117.036 },
  ],
  c30: [
    { lat: 21.107, lng: 94.22 },
    { lat: 20.41, lng: 95.034 },
    { lat: 19.737, lng: 95.446 },
    { lat: 19.072, lng: 95.807 },
    { lat: 18.399, lng: 96.463 },
    { lat: 18.399, lng: 96.464 },
    { lat: 17.729, lng: 97.177 },
    { lat: 17.069, lng: 97.771 },
    { lat: 16.434, lng: 97.805 },
    { lat: 16.292, lng: 97.289 },
    { lat: 16.469, lng: 96.872 },
    { lat: 16.947, lng: 96.031 },
    { lat: 17.159, lng: 95.469 },
    { lat: 17.321, lng: 94.831 },
    { lat: 17.244, lng: 93.782 },
    { lat: 17.247, lng: 93.284 },
    { lat: 17.258, lng: 92.659 },
    { lat: 17.276, lng: 92.573 },
    { lat: 17.652, lng: 91.308 },
    { lat: 18.005, lng: 90.606 },
    { lat: 18.686, lng: 89.936 },
    { lat: 19.356, lng: 89.587 },
    { lat: 20.022, lng: 89.44 },
    { lat: 20.682, lng: 89.47 },
    { lat: 20.798, lng: 89.522 },
    { lat: 21.3, lng: 90.387 },
    { lat: 21.326, lng: 90.449 },
    { lat: 21.574, lng: 91.52 },
    { lat: 21.514, lng: 92.739 },
    { lat: 21.184, lng: 94.06 },
    { lat: 21.107, lng: 94.22 },
  ],
  c31: [
    { lat: 35.859, lng: 125.702 },
    { lat: 35.698, lng: 126.013 },
    { lat: 35.046, lng: 126.791 },
    { lat: 34.939, lng: 126.932 },
    { lat: 34.252, lng: 127.759 },
    { lat: 34.192, lng: 127.822 },
    { lat: 33.478, lng: 128.428 },
    { lat: 32.879, lng: 128.787 },
    { lat: 32.721, lng: 128.886 },
    { lat: 31.98, lng: 129.143 },
    { lat: 31.252, lng: 129.212 },
    { lat: 30.732, lng: 129.14 },
    { lat: 30.537, lng: 129.104 },
    { lat: 29.834, lng: 128.787 },
    { lat: 29.579, lng: 128.629 },
    { lat: 29.146, lng: 128.16 },
    { lat: 29.032, lng: 128.029 },
    { lat: 28.702, lng: 127.396 },
    { lat: 28.592, lng: 126.727 },
    { lat: 28.767, lng: 126.006 },
    { lat: 29.224, lng: 125.237 },
    { lat: 29.234, lng: 125.22 },
    { lat: 29.768, lng: 124.407 },
    { lat: 29.961, lng: 124.185 },
    { lat: 30.603, lng: 123.509 },
    { lat: 30.699, lng: 123.418 },
    { lat: 31.442, lng: 122.81 },
    { lat: 31.992, lng: 122.441 },
    { lat: 32.19, lng: 122.323 },
    { lat: 32.942, lng: 121.993 },
    { lat: 33.7, lng: 121.774 },
    { lat: 34.464, lng: 121.645 },
    { lat: 34.886, lng: 121.643 },
    { lat: 35.232, lng: 121.691 },
    { lat: 36.0, lng: 121.987 },
    { lat: 36.103, lng: 122.058 },
    { lat: 36.589, lng: 122.697 },
    { lat: 36.576, lng: 123.483 },
    { lat: 36.389, lng: 124.311 },
    { lat: 36.142, lng: 125.144 },
    { lat: 35.859, lng: 125.702 },
  ],
  c32: [
    { lat: 17.802, lng: 112.965 },
    { lat: 17.296, lng: 113.607 },
    { lat: 17.157, lng: 113.821 },
    { lat: 16.74, lng: 114.431 },
    { lat: 16.518, lng: 114.63 },
    { lat: 15.889, lng: 115.247 },
    { lat: 15.839, lng: 115.296 },
    { lat: 15.267, lng: 115.729 },
    { lat: 14.65, lng: 116.149 },
    { lat: 14.561, lng: 116.192 },
    { lat: 14.04, lng: 116.427 },
    { lat: 13.436, lng: 116.559 },
    { lat: 12.837, lng: 116.515 },
    { lat: 12.692, lng: 116.451 },
    { lat: 12.249, lng: 116.039 },
    { lat: 12.148, lng: 115.84 },
    { lat: 12.129, lng: 115.157 },
    { lat: 12.157, lng: 114.462 },
    { lat: 12.251, lng: 113.75 },
    { lat: 12.297, lng: 113.54 },
    { lat: 12.402, lng: 113.022 },
    { lat: 12.544, lng: 112.286 },
    { lat: 12.826, lng: 111.519 },
    { lat: 12.949, lng: 111.204 },
    { lat: 13.189, lng: 110.725 },
    { lat: 13.578, lng: 110.183 },
    { lat: 13.906, lng: 109.851 },
    { lat: 14.201, lng: 109.618 },
    { lat: 14.818, lng: 109.358 },
    { lat: 15.436, lng: 109.232 },
    { lat: 16.054, lng: 109.179 },
    { lat: 16.414, lng: 109.333 },
    { lat: 16.664, lng: 109.459 },
    { lat: 17.192, lng: 109.92 },
    { lat: 17.269, lng: 110.005 },
    { lat: 17.658, lng: 110.572 },
    { lat: 17.857, lng: 111.142 },
    { lat: 17.893, lng: 111.272 },
    { lat: 18.056, lng: 111.982 },
    { lat: 17.939, lng: 112.746 },
    { lat: 17.802, lng: 112.965 },
  ],
};

export var apstar92_gntgain = {
  c1: "-1",
  c2: "-1",
  c3: "-2",
  c4: "-3",
  c5: "-4",
  c6: "-4",
  c7: "-4",
  c8: "-4",
  c9: "-5",
  c10: "-5",
  c11: "-5",
  c12: "-5",
  c13: "-6",
  c14: "-6",
  c15: "-6",
  c16: "-6",
  c17: "-6",
  c18: "0",
  c19: "1",
  c20: "1",
  c21: "1",
  c22: "1",
  c23: "1",
  c24: "2",
  c25: "2",
  c26: "2",
  c27: "2",
  c28: "2",
  c29: "3",
  c30: "3",
  c31: "3",
  c32: "4",
};
