export var apstar5 = {
  c6: [
    { lat: -5.9283, lng: 110.4384 },
    { lat: -6.0671, lng: 111.0014 },
    { lat: -6.2335, lng: 111.5569 },
    { lat: -6.4278, lng: 112.1032 },
    { lat: -6.594, lng: 112.6588 },
    { lat: -6.7259, lng: 113.2233 },
    { lat: -6.812, lng: 113.7968 },
    { lat: -6.8857, lng: 114.372 },
    { lat: -6.9885, lng: 114.9425 },
    { lat: -7.1299, lng: 115.5049 },
    { lat: -7.3088, lng: 116.0564 },
    { lat: -7.5081, lng: 116.6009 },
    { lat: -7.6963, lng: 117.1495 },
    { lat: -7.8428, lng: 117.7103 },
    { lat: -7.931, lng: 118.2834 },
    { lat: -7.9736, lng: 118.8616 },
    { lat: -7.9938, lng: 119.4412 },
    { lat: -8.041, lng: 120.019 },
    { lat: -8.1732, lng: 120.5828 },
    { lat: -8.4327, lng: 121.1 },
    { lat: -8.7804, lng: 121.563 },
    { lat: -9.2066, lng: 121.9541 },
    { lat: -9.7505, lng: 122.1303 },
    { lat: -10.2894, lng: 121.9363 },
    { lat: -10.6212, lng: 121.4679 },
    { lat: -10.7541, lng: 120.9074 },
    { lat: -10.7247, lng: 120.3294 },
    { lat: -10.5959, lng: 119.7642 },
    { lat: -10.4511, lng: 119.2026 },
    { lat: -10.2998, lng: 118.643 },
    { lat: -10.1333, lng: 118.0874 },
    { lat: -9.9517, lng: 117.5371 },
    { lat: -9.7597, lng: 116.99 },
    { lat: -9.5767, lng: 116.4398 },
    { lat: -9.4264, lng: 115.8799 },
    { lat: -9.2902, lng: 115.3163 },
    { lat: -9.1586, lng: 114.7515 },
    { lat: -9.0496, lng: 114.1819 },
    { lat: -8.9338, lng: 113.6137 },
    { lat: -8.8402, lng: 113.0414 },
    { lat: -8.7725, lng: 112.4655 },
    { lat: -8.7228, lng: 111.8877 },
    { lat: -8.6733, lng: 111.31 },
    { lat: -8.6202, lng: 110.7324 },
    { lat: -8.5635, lng: 110.1553 },
    { lat: -8.4966, lng: 109.5792 },
    { lat: -8.4204, lng: 109.0043 },
    { lat: -8.3113, lng: 108.4349 },
    { lat: -8.1773, lng: 107.8709 },
    { lat: -8.015, lng: 107.315 },
    { lat: -7.7815, lng: 106.784 },
    { lat: -7.467, lng: 106.2983 },
    { lat: -7.0699, lng: 105.8775 },
    { lat: -6.5644, lng: 105.6076 },
    { lat: -6.0972, lng: 105.8659 },
    { lat: -5.9009, lng: 106.3958 },
    { lat: -5.8365, lng: 106.9708 },
    { lat: -5.7967, lng: 107.5495 },
    { lat: -5.7706, lng: 108.1289 },
    { lat: -5.7634, lng: 108.7087 },
    { lat: -5.7851, lng: 109.2882 },
    { lat: -5.8386, lng: 109.8656 },
    { lat: -5.9283, lng: 110.4384 },
  ],
  c7: [
    { lat: 7.5781, lng: 96.9782 },
    { lat: 7.1627, lng: 98.2413 },
    { lat: 6.9267, lng: 99.5502 },
    { lat: 6.6918, lng: 100.8585 },
    { lat: 6.305, lng: 102.1298 },
    { lat: 6.2783, lng: 103.4635 },
    { lat: 6.3253, lng: 104.7891 },
    { lat: 6.1419, lng: 106.109 },
    { lat: 5.4119, lng: 106.8086 },
    { lat: 5.3921, lng: 108.1126 },
    { lat: 5.2008, lng: 109.4621 },
    { lat: 4.7597, lng: 110.7378 },
    { lat: 3.856, lng: 111.213 },
    { lat: 3.105, lng: 111.9672 },
    { lat: 3.0275, lng: 113.363 },
    { lat: 3.0568, lng: 114.6698 },
    { lat: 3.4857, lng: 115.935 },
    { lat: 3.9903, lng: 117.1648 },
    { lat: 4.2969, lng: 118.4562 },
    { lat: 4.3442, lng: 119.787 },
    { lat: 4.2492, lng: 121.1137 },
    { lat: 4.1399, lng: 122.4387 },
    { lat: 4.0143, lng: 123.7626 },
    { lat: 3.8316, lng: 125.0799 },
    { lat: 3.7012, lng: 126.4033 },
    { lat: 3.6433, lng: 127.7318 },
    { lat: 3.5618, lng: 129.0591 },
    { lat: 3.4048, lng: 130.3795 },
    { lat: 3.1788, lng: 131.69 },
    { lat: 2.8737, lng: 132.9843 },
    { lat: 2.4792, lng: 134.2541 },
    { lat: 1.9909, lng: 135.491 },
    { lat: 1.4153, lng: 136.6897 },
    { lat: 0.7888, lng: 137.8627 },
    { lat: 0.1834, lng: 139.0466 },
    { lat: -0.3891, lng: 140.247 },
    { lat: -1.0389, lng: 141.4062 },
    { lat: -1.947, lng: 142.3785 },
    { lat: -3.1534, lng: 142.9425 },
    { lat: -4.4586, lng: 143.1754 },
    { lat: -5.7769, lng: 143.3684 },
    { lat: -7.0765, lng: 143.6351 },
    { lat: -8.4206, lng: 143.6495 },
    { lat: -9.5781, lng: 143.0122 },
    { lat: -10.2193, lng: 141.8604 },
    { lat: -10.4509, lng: 140.551 },
    { lat: -10.5578, lng: 139.2251 },
    { lat: -10.6488, lng: 137.8985 },
    { lat: -10.6998, lng: 136.5698 },
    { lat: -10.7094, lng: 135.24 },
    { lat: -10.719, lng: 133.9103 },
    { lat: -10.7699, lng: 132.5816 },
    { lat: -10.861, lng: 131.2548 },
    { lat: -10.9434, lng: 129.9277 },
    { lat: -10.9956, lng: 128.5989 },
    { lat: -11.0859, lng: 127.2726 },
    { lat: -11.3317, lng: 125.9666 },
    { lat: -11.7886, lng: 124.7151 },
    { lat: -12.386, lng: 123.5297 },
    { lat: -12.9163, lng: 122.3083 },
    { lat: -13.1535, lng: 120.9987 },
    { lat: -13.0723, lng: 119.6727 },
    { lat: -12.7962, lng: 118.3725 },
    { lat: -12.4727, lng: 117.0826 },
    { lat: -12.1953, lng: 115.7821 },
    { lat: -11.9919, lng: 114.468 },
    { lat: -11.8401, lng: 113.147 },
    { lat: -11.6868, lng: 111.8258 },
    { lat: -11.4798, lng: 110.513 },
    { lat: -11.233, lng: 109.2047 },
    { lat: -11.0627, lng: 107.8894 },
    { lat: -11.3217, lng: 106.5748 },
    { lat: -12.0878, lng: 105.52 },
    { lat: -13.084, lng: 104.5841 },
    { lat: -13.3555, lng: 103.3057 },
    { lat: -12.5088, lng: 102.311 },
    { lat: -11.2786, lng: 101.8222 },
    { lat: -10.0079, lng: 101.4277 },
    { lat: -8.7947, lng: 100.8835 },
    { lat: -7.6661, lng: 100.1815 },
    { lat: -6.6071, lng: 99.377 },
    { lat: -5.6119, lng: 98.4953 },
    { lat: -4.6663, lng: 97.5603 },
    { lat: -3.7384, lng: 96.6076 },
    { lat: -2.7729, lng: 95.6934 },
    { lat: -1.7083, lng: 94.8986 },
    { lat: -0.5293, lng: 94.283 },
    { lat: 0.7074, lng: 93.791 },
    { lat: 1.9187, lng: 93.2467 },
    { lat: 3.0152, lng: 92.4966 },
    { lat: 3.9661, lng: 91.5575 },
    { lat: 4.9291, lng: 90.6597 },
    { lat: 6.1694, lng: 90.1633 },
    { lat: 7.4285, lng: 90.6086 },
    { lat: 8.1007, lng: 91.75 },
    { lat: 8.2673, lng: 93.0674 },
    { lat: 8.1932, lng: 94.3951 },
    { lat: 7.9571, lng: 95.7036 },
    { lat: 7.5781, lng: 96.9782 },
  ],
  c5: [
    { lat: 0.345, lng: 114.48 },
    { lat: 0.311, lng: 114.796 },
    { lat: 0.286, lng: 115.18 },
    { lat: 0.271, lng: 115.562 },
    { lat: 0.25, lng: 115.943 },
    { lat: 0.204, lng: 116.322 },
    { lat: 0.106, lng: 116.699 },
    { lat: 0.0, lng: 116.939 },
    { lat: -0.172, lng: 117.075 },
    { lat: -0.344, lng: 117.183 },
    { lat: -0.621, lng: 117.074 },
    { lat: -0.687, lng: 117.032 },
    { lat: -0.83, lng: 116.696 },
    { lat: -0.927, lng: 116.318 },
    { lat: -0.988, lng: 115.939 },
    { lat: -1.023, lng: 115.558 },
    { lat: -1.033, lng: 115.208 },
    { lat: -1.034, lng: 115.176 },
    { lat: -1.033, lng: 115.149 },
    { lat: -1.023, lng: 114.792 },
    { lat: -0.992, lng: 114.406 },
    { lat: -0.946, lng: 114.019 },
    { lat: -0.889, lng: 113.63 },
    { lat: -0.821, lng: 113.24 },
    { lat: -0.735, lng: 112.847 },
    { lat: -0.691, lng: 112.694 },
    { lat: -0.574, lng: 112.453 },
    { lat: -0.346, lng: 112.114 },
    { lat: -0.139, lng: 112.057 },
    { lat: 0.0, lng: 112.029 },
    { lat: 0.038, lng: 112.057 },
    { lat: 0.346, lng: 112.397 },
    { lat: 0.366, lng: 112.454 },
    { lat: 0.436, lng: 112.849 },
    { lat: 0.447, lng: 113.242 },
    { lat: 0.423, lng: 113.633 },
    { lat: 0.385, lng: 114.023 },
    { lat: 0.35, lng: 114.41 },
    { lat: 0.345, lng: 114.48 },
  ],
  c4: [
    { lat: 6.4136, lng: 96.2694 },
    { lat: 6.0264, lng: 97.4379 },
    { lat: 5.478, lng: 98.5404 },
    { lat: 4.8542, lng: 99.6026 },
    { lat: 4.1846, lng: 100.6365 },
    { lat: 3.4603, lng: 101.6329 },
    { lat: 2.7192, lng: 102.6172 },
    { lat: 2.0536, lng: 103.6527 },
    { lat: 1.4979, lng: 104.7518 },
    { lat: 0.9262, lng: 105.8428 },
    { lat: 0.2595, lng: 106.8776 },
    { lat: -0.5422, lng: 107.8104 },
    { lat: -1.5059, lng: 108.5761 },
    { lat: -2.4706, lng: 109.3417 },
    { lat: -3.3171, lng: 110.2336 },
    { lat: -3.9319, lng: 111.2974 },
    { lat: -4.3021, lng: 112.4708 },
    { lat: -4.5571, lng: 113.676 },
    { lat: -4.7587, lng: 114.8913 },
    { lat: -4.9445, lng: 116.1091 },
    { lat: -5.2508, lng: 117.3011 },
    { lat: -5.6406, lng: 118.469 },
    { lat: -5.7672, lng: 119.6889 },
    { lat: -5.6386, lng: 120.9139 },
    { lat: -5.8159, lng: 122.1227 },
    { lat: -6.7342, lng: 122.8976 },
    { lat: -7.8378, lng: 123.4342 },
    { lat: -8.9285, lng: 123.9896 },
    { lat: -10.1361, lng: 123.8841 },
    { lat: -11.0964, lng: 123.1295 },
    { lat: -11.7317, lng: 122.0815 },
    { lat: -11.9549, lng: 120.8778 },
    { lat: -11.7655, lng: 119.6639 },
    { lat: -11.4099, lng: 118.4844 },
    { lat: -11.111, lng: 117.2894 },
    { lat: -10.8058, lng: 116.0959 },
    { lat: -10.5303, lng: 114.8954 },
    { lat: -10.3572, lng: 113.6759 },
    { lat: -10.2655, lng: 112.4474 },
    { lat: -10.1795, lng: 111.2184 },
    { lat: -9.9845, lng: 110.0027 },
    { lat: -9.7142, lng: 108.8007 },
    { lat: -9.4896, lng: 107.5897 },
    { lat: -9.3141, lng: 106.3703 },
    { lat: -9.0389, lng: 105.1711 },
    { lat: -8.3891, lng: 104.1345 },
    { lat: -7.4966, lng: 103.285 },
    { lat: -6.5455, lng: 102.5028 },
    { lat: -5.5307, lng: 101.8043 },
    { lat: -4.5075, lng: 101.1182 },
    { lat: -3.5425, lng: 100.356 },
    { lat: -2.7467, lng: 99.4172 },
    { lat: -1.98, lng: 98.4546 },
    { lat: -0.9501, lng: 97.8155 },
    { lat: 0.2778, lng: 97.7941 },
    { lat: 1.3459, lng: 97.2104 },
    { lat: 2.2574, lng: 96.3825 },
    { lat: 3.0962, lng: 95.481 },
    { lat: 3.8512, lng: 94.5083 },
    { lat: 4.6703, lng: 93.5914 },
    { lat: 5.7267, lng: 92.9885 },
    { lat: 6.4924, lng: 93.8314 },
    { lat: 6.5914, lng: 95.0523 },
    { lat: 6.4136, lng: 96.2694 },
  ],
  c3: [
    { lat: 2.381, lng: 128.282 },
    { lat: 2.331, lng: 128.495 },
    { lat: 2.254, lng: 128.84 },
    { lat: 2.164, lng: 129.185 },
    { lat: 2.039, lng: 129.514 },
    { lat: 2.024, lng: 129.528 },
    { lat: 1.699, lng: 129.752 },
    { lat: 1.359, lng: 129.709 },
    { lat: 1.035, lng: 129.534 },
    { lat: 1.019, lng: 129.521 },
    { lat: 0.704, lng: 129.191 },
    { lat: 0.68, lng: 129.106 },
    { lat: 0.603, lng: 128.848 },
    { lat: 0.68, lng: 128.583 },
    { lat: 0.695, lng: 128.504 },
    { lat: 0.849, lng: 128.159 },
    { lat: 1.013, lng: 127.814 },
    { lat: 1.02, lng: 127.799 },
    { lat: 1.177, lng: 127.467 },
    { lat: 1.312, lng: 127.12 },
    { lat: 1.361, lng: 126.98 },
    { lat: 1.457, lng: 126.773 },
    { lat: 1.612, lng: 126.424 },
    { lat: 1.702, lng: 126.228 },
    { lat: 1.88, lng: 126.073 },
    { lat: 2.044, lng: 125.944 },
    { lat: 2.225, lng: 126.071 },
    { lat: 2.384, lng: 126.201 },
    { lat: 2.454, lng: 126.417 },
    { lat: 2.515, lng: 126.764 },
    { lat: 2.525, lng: 127.111 },
    { lat: 2.498, lng: 127.458 },
    { lat: 2.451, lng: 127.804 },
    { lat: 2.4, lng: 128.15 },
    { lat: 2.381, lng: 128.282 },
  ],
  c2: [
    { lat: 7.5331, lng: 94.9094 },
    { lat: 7.2563, lng: 96.2464 },
    { lat: 6.8209, lng: 97.545 },
    { lat: 6.2239, lng: 98.776 },
    { lat: 5.5327, lng: 99.9581 },
    { lat: 4.8212, lng: 101.1285 },
    { lat: 4.0834, lng: 102.2816 },
    { lat: 3.3442, lng: 103.4354 },
    { lat: 2.6907, lng: 104.6378 },
    { lat: 2.1087, lng: 105.8781 },
    { lat: 1.5634, lng: 107.1306 },
    { lat: 1.1274, lng: 108.4343 },
    { lat: 1.23, lng: 109.7808 },
    { lat: 1.5815, lng: 111.1071 },
    { lat: 1.7172, lng: 112.461 },
    { lat: 1.6298, lng: 113.834 },
    { lat: 1.5701, lng: 115.1967 },
    { lat: 1.7031, lng: 116.5605 },
    { lat: 1.7168, lng: 117.9164 },
    { lat: 1.0779, lng: 119.1191 },
    { lat: 0.0417, lng: 120.0105 },
    { lat: -1.1202, lng: 120.7341 },
    { lat: -2.3627, lng: 121.3028 },
    { lat: -3.4439, lng: 122.142 },
    { lat: -4.0922, lng: 123.3339 },
    { lat: -4.8118, lng: 124.5198 },
    { lat: -4.99, lng: 125.8478 },
    { lat: -5.0702, lng: 127.2021 },
    { lat: -5.4799, lng: 128.5097 },
    { lat: -5.9388, lng: 129.8002 },
    { lat: -6.3047, lng: 131.0813 },
    { lat: -5.3318, lng: 132.0631 },
    { lat: -4.2942, lng: 131.5387 },
    { lat: -3.5783, lng: 130.3663 },
    { lat: -2.8435, lng: 129.2162 },
    { lat: -2.074, lng: 128.1366 },
    { lat: -0.8281, lng: 128.6054 },
    { lat: -0.5633, lng: 129.8227 },
    { lat: -0.7287, lng: 131.1849 },
    { lat: -0.8543, lng: 132.545 },
    { lat: -0.9526, lng: 133.9086 },
    { lat: -0.881, lng: 135.2786 },
    { lat: -0.8616, lng: 136.6423 },
    { lat: -1.0224, lng: 138.01 },
    { lat: -1.6851, lng: 139.1647 },
    { lat: -2.9844, lng: 139.6429 },
    { lat: -4.0223, lng: 138.8995 },
    { lat: -4.9677, lng: 137.8854 },
    { lat: -6.2394, lng: 138.0029 },
    { lat: -7.2235, lng: 138.9553 },
    { lat: -8.511, lng: 138.7808 },
    { lat: -9.3913, lng: 137.7461 },
    { lat: -9.2987, lng: 136.4443 },
    { lat: -8.3855, lng: 135.4646 },
    { lat: -7.0375, lng: 135.6296 },
    { lat: -5.8606, lng: 136.217 },
    { lat: -4.4857, lng: 136.6754 },
    { lat: -4.134, lng: 135.6515 },
    { lat: -4.9173, lng: 134.519 },
    { lat: -6.1539, lng: 134.0744 },
    { lat: -7.4235, lng: 133.4556 },
    { lat: -7.965, lng: 132.2641 },
    { lat: -8.2962, lng: 130.9969 },
    { lat: -9.2657, lng: 130.0269 },
    { lat: -9.8738, lng: 128.82 },
    { lat: -10.0148, lng: 127.4578 },
    { lat: -10.212, lng: 126.1086 },
    { lat: -10.8218, lng: 124.8958 },
    { lat: -11.6368, lng: 123.7928 },
    { lat: -12.362, lng: 122.629 },
    { lat: -12.7498, lng: 121.3324 },
    { lat: -12.6481, lng: 119.9675 },
    { lat: -12.2433, lng: 118.6589 },
    { lat: -11.8618, lng: 117.3453 },
    { lat: -11.579, lng: 116.0046 },
    { lat: -11.3117, lng: 114.6613 },
    { lat: -11.1677, lng: 113.3009 },
    { lat: -11.125, lng: 111.9296 },
    { lat: -10.9745, lng: 110.5725 },
    { lat: -10.6639, lng: 109.2384 },
    { lat: -10.318, lng: 107.9128 },
    { lat: -10.1568, lng: 106.5578 },
    { lat: -10.1493, lng: 105.175 },
    { lat: -9.7954, lng: 103.8744 },
    { lat: -8.9065, lng: 102.8566 },
    { lat: -7.803, lng: 102.0319 },
    { lat: -6.7424, lng: 101.1713 },
    { lat: -5.6721, lng: 100.3149 },
    { lat: -4.681, lng: 99.3726 },
    { lat: -3.8047, lng: 98.3202 },
    { lat: -3.0035, lng: 97.2119 },
    { lat: -2.069, lng: 96.2148 },
    { lat: -0.9057, lng: 95.4998 },
    { lat: 0.4398, lng: 95.2865 },
    { lat: 1.7606, lng: 95.0088 },
    { lat: 2.8241, lng: 94.1331 },
    { lat: 3.6672, lng: 93.0645 },
    { lat: 4.5357, lng: 92.0061 },
    { lat: 5.5287, lng: 91.0749 },
    { lat: 6.7792, lng: 90.9903 },
    { lat: 7.4651, lng: 92.2035 },
    { lat: 7.6117, lng: 93.5309 },
    { lat: 7.5331, lng: 94.9094 },
  ],
  c1: [
    { lat: 8.2682, lng: 94.0719 },
    { lat: 8.0584, lng: 95.425 },
    { lat: 7.6956, lng: 96.749 },
    { lat: 7.1955, lng: 98.0263 },
    { lat: 6.5714, lng: 99.2479 },
    { lat: 5.8677, lng: 100.426 },
    { lat: 5.172, lng: 101.609 },
    { lat: 4.4704, lng: 102.7883 },
    { lat: 3.7678, lng: 103.9671 },
    { lat: 3.1567, lng: 105.1955 },
    { lat: 2.6352, lng: 106.4647 },
    { lat: 2.2226, lng: 107.7741 },
    { lat: 2.1488, lng: 109.1371 },
    { lat: 2.3371, lng: 110.4958 },
    { lat: 2.3971, lng: 111.8669 },
    { lat: 2.3277, lng: 113.2367 },
    { lat: 2.3273, lng: 114.6085 },
    { lat: 2.596, lng: 115.9444 },
    { lat: 3.1757, lng: 117.1979 },
    { lat: 3.4086, lng: 118.5265 },
    { lat: 3.3005, lng: 119.9063 },
    { lat: 3.4222, lng: 121.2695 },
    { lat: 3.4371, lng: 122.6421 },
    { lat: 3.5051, lng: 124.0109 },
    { lat: 3.6581, lng: 125.3737 },
    { lat: 3.6981, lng: 126.743 },
    { lat: 3.5501, lng: 128.1066 },
    { lat: 3.4807, lng: 129.4793 },
    { lat: 3.3632, lng: 130.8396 },
    { lat: 3.0635, lng: 132.1812 },
    { lat: 2.7688, lng: 133.5181 },
    { lat: 2.2889, lng: 134.8037 },
    { lat: 1.8061, lng: 136.0856 },
    { lat: 1.1089, lng: 137.2677 },
    { lat: 0.4116, lng: 138.4473 },
    { lat: -0.2194, lng: 139.6671 },
    { lat: -0.7592, lng: 140.9222 },
    { lat: -1.2012, lng: 142.1979 },
    { lat: -2.1769, lng: 143.1629 },
    { lat: -3.4919, lng: 143.3546 },
    { lat: -4.8481, lng: 143.1304 },
    { lat: -6.2075, lng: 143.2372 },
    { lat: -7.4352, lng: 143.8268 },
    { lat: -8.7109, lng: 144.433 },
    { lat: -9.8534, lng: 143.9753 },
    { lat: -10.3565, lng: 142.6997 },
    { lat: -10.4701, lng: 141.3319 },
    { lat: -10.5508, lng: 139.9614 },
    { lat: -10.6399, lng: 138.5943 },
    { lat: -10.8693, lng: 137.2358 },
    { lat: -10.7999, lng: 135.8736 },
    { lat: -10.7147, lng: 134.5062 },
    { lat: -10.7781, lng: 133.1359 },
    { lat: -10.8341, lng: 131.7675 },
    { lat: -11.0726, lng: 130.4121 },
    { lat: -11.0926, lng: 129.0458 },
    { lat: -11.0011, lng: 127.6741 },
    { lat: -11.0819, lng: 126.3105 },
    { lat: -11.5514, lng: 125.0272 },
    { lat: -12.2857, lng: 123.8657 },
    { lat: -13.052, lng: 122.7277 },
    { lat: -13.5508, lng: 121.4541 },
    { lat: -13.421, lng: 120.0996 },
    { lat: -12.9811, lng: 118.8019 },
    { lat: -12.5422, lng: 117.5022 },
    { lat: -12.2421, lng: 116.1647 },
    { lat: -12.0373, lng: 114.8064 },
    { lat: -11.8768, lng: 113.4447 },
    { lat: -11.8379, lng: 112.0719 },
    { lat: -11.6924, lng: 110.708 },
    { lat: -11.3336, lng: 109.3872 },
    { lat: -10.9443, lng: 108.0691 },
    { lat: -10.8196, lng: 106.7055 },
    { lat: -11.2245, lng: 105.4121 },
    { lat: -12.3725, lng: 105.0125 },
    { lat: -13.7907, lng: 105.1451 },
    { lat: -14.6804, lng: 104.2942 },
    { lat: -14.2487, lng: 102.9967 },
    { lat: -13.0985, lng: 102.2876 },
    { lat: -11.7662, lng: 101.9838 },
    { lat: -10.4154, lng: 101.7298 },
    { lat: -9.1243, lng: 101.2829 },
    { lat: -7.9534, lng: 100.5727 },
    { lat: -6.9063, lng: 99.6868 },
    { lat: -5.8556, lng: 98.8064 },
    { lat: -4.9242, lng: 97.7988 },
    { lat: -4.0225, lng: 96.766 },
    { lat: -3.1011, lng: 95.7438 },
    { lat: -2.0497, lng: 94.8736 },
    { lat: -0.8845, lng: 94.1433 },
    { lat: 0.4396, lng: 93.8515 },
    { lat: 1.7947, lng: 93.6842 },
    { lat: 2.8669, lng: 92.8275 },
    { lat: 3.6978, lng: 91.752 },
    { lat: 4.5541, lng: 90.6673 },
    { lat: 5.509, lng: 89.699 },
    { lat: 6.7516, lng: 89.2336 },
    { lat: 7.814, lng: 90.0006 },
    { lat: 8.1928, lng: 91.3428 },
    { lat: 8.3234, lng: 92.6959 },
    { lat: 8.2682, lng: 94.0719 },
  ],
};

export var apstar5gain = {
  c1: "48.00",
  c2: "50.00",
  c3: "50.00",
  c4: "52.00",
  c5: "52.00",
  c6: "54.00",
  c7: "48.00",
};

export var apstar5_gnt = {
  c1: [
    { lat: 6.437, lng: 96.206 },
    { lat: 6.404, lng: 96.365 },
    { lat: 6.263, lng: 96.912 },
    { lat: 6.105, lng: 97.453 },
    { lat: 6.059, lng: 97.592 },
    { lat: 5.925, lng: 97.989 },
    { lat: 5.734, lng: 98.52 },
    { lat: 5.688, lng: 98.636 },
    { lat: 5.524, lng: 99.044 },
    { lat: 5.321, lng: 99.525 },
    { lat: 5.304, lng: 99.563 },
    { lat: 5.063, lng: 100.077 },
    { lat: 4.957, lng: 100.295 },
    { lat: 4.81, lng: 100.585 },
    { lat: 4.595, lng: 100.988 },
    { lat: 4.54, lng: 101.087 },
    { lat: 4.242, lng: 101.585 },
    { lat: 4.236, lng: 101.595 },
    { lat: 3.887, lng: 102.079 },
    { lat: 3.878, lng: 102.091 },
    { lat: 3.522, lng: 102.492 },
    { lat: 3.442, lng: 102.571 },
    { lat: 3.167, lng: 102.845 },
    { lat: 2.946, lng: 103.058 },
    { lat: 2.813, lng: 103.192 },
    { lat: 2.479, lng: 103.535 },
    { lat: 2.459, lng: 103.557 },
    { lat: 2.106, lng: 103.945 },
    { lat: 2.05, lng: 104.003 },
    { lat: 1.754, lng: 104.353 },
    { lat: 1.652, lng: 104.464 },
    { lat: 1.402, lng: 104.782 },
    { lat: 1.282, lng: 104.917 },
    { lat: 1.05, lng: 105.228 },
    { lat: 0.928, lng: 105.366 },
    { lat: 0.7, lng: 105.672 },
    { lat: 0.571, lng: 105.808 },
    { lat: 0.35, lng: 106.092 },
    { lat: 0.188, lng: 106.247 },
    { lat: -0.0, lng: 106.459 },
    { lat: -0.28, lng: 106.679 },
    { lat: -0.349, lng: 106.741 },
    { lat: -0.698, lng: 106.933 },
    { lat: -1.047, lng: 107.078 },
    { lat: -1.095, lng: 107.102 },
    { lat: -1.395, lng: 107.257 },
    { lat: -1.744, lng: 107.497 },
    { lat: -1.766, lng: 107.517 },
    { lat: -2.091, lng: 107.775 },
    { lat: -2.264, lng: 107.929 },
    { lat: -2.439, lng: 108.062 },
    { lat: -2.737, lng: 108.337 },
    { lat: -2.786, lng: 108.378 },
    { lat: -3.113, lng: 108.743 },
    { lat: -3.133, lng: 108.766 },
    { lat: -3.385, lng: 109.149 },
    { lat: -3.478, lng: 109.315 },
    { lat: -3.587, lng: 109.556 },
    { lat: -3.729, lng: 109.962 },
    { lat: -3.821, lng: 110.327 },
    { lat: -3.829, lng: 110.367 },
    { lat: -3.884, lng: 110.772 },
    { lat: -3.912, lng: 111.176 },
    { lat: -3.916, lng: 111.579 },
    { lat: -3.903, lng: 111.98 },
    { lat: -3.879, lng: 112.38 },
    { lat: -3.856, lng: 112.777 },
    { lat: -3.848, lng: 113.172 },
    { lat: -3.867, lng: 113.563 },
    { lat: -3.929, lng: 113.952 },
    { lat: -4.048, lng: 114.336 },
    { lat: -4.142, lng: 114.534 },
    { lat: -4.204, lng: 114.717 },
    { lat: -4.361, lng: 115.097 },
    { lat: -4.484, lng: 115.351 },
    { lat: -4.531, lng: 115.474 },
    { lat: -4.673, lng: 115.851 },
    { lat: -4.809, lng: 116.226 },
    { lat: -4.824, lng: 116.28 },
    { lat: -4.899, lng: 116.602 },
    { lat: -4.951, lng: 116.978 },
    { lat: -4.954, lng: 117.354 },
    { lat: -4.905, lng: 117.731 },
    { lat: -4.82, lng: 118.107 },
    { lat: -4.812, lng: 118.14 },
    { lat: -4.698, lng: 118.483 },
    { lat: -4.625, lng: 118.856 },
    { lat: -4.632, lng: 119.225 },
    { lat: -4.729, lng: 119.59 },
    { lat: -4.803, lng: 119.73 },
    { lat: -4.894, lng: 119.952 },
    { lat: -5.103, lng: 120.31 },
    { lat: -5.144, lng: 120.371 },
    { lat: -5.307, lng: 120.668 },
    { lat: -5.485, lng: 121.009 },
    { lat: -5.492, lng: 121.025 },
    { lat: -5.633, lng: 121.383 },
    { lat: -5.758, lng: 121.741 },
    { lat: -5.824, lng: 121.944 },
    { lat: -5.872, lng: 122.098 },
    { lat: -5.989, lng: 122.454 },
    { lat: -6.123, lng: 122.808 },
    { lat: -6.163, lng: 122.908 },
    { lat: -6.271, lng: 123.161 },
    { lat: -6.437, lng: 123.513 },
    { lat: -6.503, lng: 123.649 },
    { lat: -6.611, lng: 123.863 },
    { lat: -6.806, lng: 124.212 },
    { lat: -6.844, lng: 124.275 },
    { lat: -7.024, lng: 124.56 },
    { lat: -7.186, lng: 124.767 },
    { lat: -7.305, lng: 124.905 },
    { lat: -7.529, lng: 125.12 },
    { lat: -7.678, lng: 125.246 },
    { lat: -7.873, lng: 125.39 },
    { lat: -8.184, lng: 125.581 },
    { lat: -8.217, lng: 125.601 },
    { lat: -8.563, lng: 125.758 },
    { lat: -8.909, lng: 125.822 },
    { lat: -9.256, lng: 125.78 },
    { lat: -9.605, lng: 125.608 },
    { lat: -9.698, lng: 125.52 },
    { lat: -9.956, lng: 125.274 },
    { lat: -10.042, lng: 125.148 },
    { lat: -10.28, lng: 124.779 },
    { lat: -10.31, lng: 124.731 },
    { lat: -10.46, lng: 124.411 },
    { lat: -10.631, lng: 124.042 },
    { lat: -10.667, lng: 123.97 },
    { lat: -10.788, lng: 123.673 },
    { lat: -10.948, lng: 123.302 },
    { lat: -11.026, lng: 123.128 },
    { lat: -11.107, lng: 122.929 },
    { lat: -11.257, lng: 122.555 },
    { lat: -11.387, lng: 122.2 },
    { lat: -11.394, lng: 122.181 },
    { lat: -11.507, lng: 121.806 },
    { lat: -11.588, lng: 121.432 },
    { lat: -11.633, lng: 121.06 },
    { lat: -11.639, lng: 120.689 },
    { lat: -11.604, lng: 120.32 },
    { lat: -11.528, lng: 119.953 },
    { lat: -11.418, lng: 119.609 },
    { lat: -11.412, lng: 119.589 },
    { lat: -11.266, lng: 119.225 },
    { lat: -11.097, lng: 118.863 },
    { lat: -11.076, lng: 118.818 },
    { lat: -10.927, lng: 118.5 },
    { lat: -10.768, lng: 118.135 },
    { lat: -10.733, lng: 118.04 },
    { lat: -10.632, lng: 117.767 },
    { lat: -10.527, lng: 117.395 },
    { lat: -10.458, lng: 117.019 },
    { lat: -10.426, lng: 116.638 },
    { lat: -10.427, lng: 116.254 },
    { lat: -10.452, lng: 115.865 },
    { lat: -10.484, lng: 115.474 },
    { lat: -10.51, lng: 115.082 },
    { lat: -10.517, lng: 114.69 },
    { lat: -10.495, lng: 114.298 },
    { lat: -10.438, lng: 113.909 },
    { lat: -10.438, lng: 113.907 },
    { lat: -10.341, lng: 113.521 },
    { lat: -10.216, lng: 113.135 },
    { lat: -10.101, lng: 112.814 },
    { lat: -10.076, lng: 112.748 },
    { lat: -9.935, lng: 112.36 },
    { lat: -9.81, lng: 111.969 },
    { lat: -9.762, lng: 111.797 },
    { lat: -9.699, lng: 111.574 },
    { lat: -9.605, lng: 111.175 },
    { lat: -9.531, lng: 110.773 },
    { lat: -9.473, lng: 110.366 },
    { lat: -9.435, lng: 110.017 },
    { lat: -9.428, lng: 109.956 },
    { lat: -9.389, lng: 109.543 },
    { lat: -9.357, lng: 109.126 },
    { lat: -9.329, lng: 108.707 },
    { lat: -9.299, lng: 108.286 },
    { lat: -9.264, lng: 107.862 },
    { lat: -9.221, lng: 107.436 },
    { lat: -9.165, lng: 107.009 },
    { lat: -9.131, lng: 106.797 },
    { lat: -9.094, lng: 106.581 },
    { lat: -9.006, lng: 106.153 },
    { lat: -8.894, lng: 105.723 },
    { lat: -8.798, lng: 105.428 },
    { lat: -8.751, lng: 105.295 },
    { lat: -8.571, lng: 104.868 },
    { lat: -8.454, lng: 104.647 },
    { lat: -8.331, lng: 104.445 },
    { lat: -8.106, lng: 104.149 },
    { lat: -7.995, lng: 104.029 },
    { lat: -7.756, lng: 103.81 },
    { lat: -7.505, lng: 103.627 },
    { lat: -7.404, lng: 103.56 },
    { lat: -7.052, lng: 103.346 },
    { lat: -6.867, lng: 103.234 },
    { lat: -6.699, lng: 103.133 },
    { lat: -6.347, lng: 102.907 },
    { lat: -6.235, lng: 102.832 },
    { lat: -5.996, lng: 102.671 },
    { lat: -5.644, lng: 102.436 },
    { lat: -5.623, lng: 102.421 },
    { lat: -5.292, lng: 102.183 },
    { lat: -5.057, lng: 101.998 },
    { lat: -4.941, lng: 101.905 },
    { lat: -4.59, lng: 101.601 },
    { lat: -4.548, lng: 101.563 },
    { lat: -4.238, lng: 101.283 },
    { lat: -4.062, lng: 101.12 },
    { lat: -3.887, lng: 100.958 },
    { lat: -3.583, lng: 100.669 },
    { lat: -3.535, lng: 100.624 },
    { lat: -3.183, lng: 100.289 },
    { lat: -3.098, lng: 100.211 },
    { lat: -2.831, lng: 99.964 },
    { lat: -2.583, lng: 99.745 },
    { lat: -2.479, lng: 99.65 },
    { lat: -2.126, lng: 99.34 },
    { lat: -2.046, lng: 99.273 },
    { lat: -1.772, lng: 99.032 },
    { lat: -1.47, lng: 98.791 },
    { lat: -1.419, lng: 98.748 },
    { lat: -1.064, lng: 98.513 },
    { lat: -0.71, lng: 98.305 },
    { lat: -0.704, lng: 98.302 },
    { lat: -0.355, lng: 98.109 },
    { lat: -0.0, lng: 97.908 },
    { lat: 0.197, lng: 97.796 },
    { lat: 0.355, lng: 97.715 },
    { lat: 0.711, lng: 97.533 },
    { lat: 1.067, lng: 97.337 },
    { lat: 1.174, lng: 97.268 },
    { lat: 1.424, lng: 97.127 },
    { lat: 1.781, lng: 96.892 },
    { lat: 1.995, lng: 96.72 },
    { lat: 2.138, lng: 96.615 },
    { lat: 2.497, lng: 96.289 },
    { lat: 2.616, lng: 96.163 },
    { lat: 2.856, lng: 95.918 },
    { lat: 3.143, lng: 95.596 },
    { lat: 3.216, lng: 95.515 },
    { lat: 3.578, lng: 95.099 },
    { lat: 3.648, lng: 95.017 },
    { lat: 3.94, lng: 94.671 },
    { lat: 4.153, lng: 94.423 },
    { lat: 4.303, lng: 94.245 },
    { lat: 4.667, lng: 93.855 },
    { lat: 4.722, lng: 93.807 },
    { lat: 5.031, lng: 93.536 },
    { lat: 5.394, lng: 93.285 },
    { lat: 5.758, lng: 93.141 },
    { lat: 6.118, lng: 93.207 },
    { lat: 6.432, lng: 93.603 },
    { lat: 6.473, lng: 93.708 },
    { lat: 6.572, lng: 94.151 },
    { lat: 6.609, lng: 94.705 },
    { lat: 6.584, lng: 95.26 },
    { lat: 6.513, lng: 95.814 },
    { lat: 6.437, lng: 96.206 },
  ],
  c2: [
    { lat: 0.689, lng: 115.538 },
    { lat: 0.68, lng: 115.56 },
    { lat: 0.507, lng: 115.942 },
    { lat: 0.344, lng: 116.233 },
    { lat: 0.289, lng: 116.321 },
    { lat: 0.037, lng: 116.699 },
    { lat: -0.0, lng: 116.752 },
    { lat: -0.239, lng: 117.075 },
    { lat: -0.344, lng: 117.217 },
    { lat: -0.529, lng: 117.448 },
    { lat: -0.687, lng: 117.659 },
    { lat: -0.826, lng: 117.82 },
    { lat: -1.03, lng: 118.079 },
    { lat: -1.137, lng: 118.189 },
    { lat: -1.372, lng: 118.458 },
    { lat: -1.492, lng: 118.556 },
    { lat: -1.715, lng: 118.763 },
    { lat: -2.001, lng: 118.919 },
    { lat: -2.057, lng: 118.953 },
    { lat: -2.4, lng: 118.996 },
    { lat: -2.653, lng: 118.908 },
    { lat: -2.744, lng: 118.861 },
    { lat: -3.021, lng: 118.531 },
    { lat: -3.09, lng: 118.29 },
    { lat: -3.121, lng: 118.158 },
    { lat: -3.122, lng: 117.785 },
    { lat: -3.093, lng: 117.551 },
    { lat: -3.08, lng: 117.412 },
    { lat: -3.035, lng: 117.039 },
    { lat: -2.998, lng: 116.663 },
    { lat: -2.977, lng: 116.285 },
    { lat: -2.974, lng: 115.906 },
    { lat: -2.987, lng: 115.524 },
    { lat: -3.012, lng: 115.141 },
    { lat: -3.041, lng: 114.755 },
    { lat: -3.064, lng: 114.368 },
    { lat: -3.07, lng: 113.98 },
    { lat: -3.046, lng: 113.59 },
    { lat: -2.97, lng: 113.201 },
    { lat: -2.806, lng: 112.811 },
    { lat: -2.767, lng: 112.756 },
    { lat: -2.61, lng: 112.421 },
    { lat: -2.423, lng: 112.164 },
    { lat: -2.341, lng: 112.03 },
    { lat: -2.078, lng: 111.688 },
    { lat: -2.041, lng: 111.637 },
    { lat: -1.735, lng: 111.241 },
    { lat: -1.733, lng: 111.238 },
    { lat: -1.426, lng: 110.843 },
    { lat: -1.387, lng: 110.788 },
    { lat: -1.075, lng: 110.441 },
    { lat: -1.041, lng: 110.4 },
    { lat: -0.694, lng: 110.115 },
    { lat: -0.5, lng: 110.038 },
    { lat: -0.347, lng: 109.968 },
    { lat: -0.0, lng: 109.982 },
    { lat: 0.086, lng: 110.039 },
    { lat: 0.347, lng: 110.201 },
    { lat: 0.516, lng: 110.446 },
    { lat: 0.693, lng: 110.757 },
    { lat: 0.726, lng: 110.85 },
    { lat: 0.833, lng: 111.253 },
    { lat: 0.902, lng: 111.654 },
    { lat: 0.943, lng: 112.053 },
    { lat: 0.964, lng: 112.45 },
    { lat: 0.973, lng: 112.845 },
    { lat: 0.975, lng: 113.238 },
    { lat: 0.972, lng: 113.63 },
    { lat: 0.96, lng: 114.019 },
    { lat: 0.935, lng: 114.407 },
    { lat: 0.887, lng: 114.793 },
    { lat: 0.806, lng: 115.177 },
    { lat: 0.689, lng: 115.538 },
  ],
  c3: [
    { lat: 7.162, lng: 95.892 },
    { lat: 7.071, lng: 96.277 },
    { lat: 6.916, lng: 96.83 },
    { lat: 6.781, lng: 97.254 },
    { lat: 6.742, lng: 97.377 },
    { lat: 6.552, lng: 97.918 },
    { lat: 6.407, lng: 98.3 },
    { lat: 6.35, lng: 98.453 },
    { lat: 6.137, lng: 98.982 },
    { lat: 6.038, lng: 99.213 },
    { lat: 5.914, lng: 99.505 },
    { lat: 5.684, lng: 100.021 },
    { lat: 5.671, lng: 100.048 },
    { lat: 5.444, lng: 100.531 },
    { lat: 5.307, lng: 100.814 },
    { lat: 5.195, lng: 101.036 },
    { lat: 4.945, lng: 101.511 },
    { lat: 4.931, lng: 101.535 },
    { lat: 4.643, lng: 102.029 },
    { lat: 4.585, lng: 102.123 },
    { lat: 4.312, lng: 102.52 },
    { lat: 4.227, lng: 102.636 },
    { lat: 3.916, lng: 103.008 },
    { lat: 3.871, lng: 103.06 },
    { lat: 3.516, lng: 103.437 },
    { lat: 3.462, lng: 103.492 },
    { lat: 3.161, lng: 103.817 },
    { lat: 3.027, lng: 103.967 },
    { lat: 2.807, lng: 104.239 },
    { lat: 2.656, lng: 104.433 },
    { lat: 2.454, lng: 104.728 },
    { lat: 2.341, lng: 104.891 },
    { lat: 2.101, lng: 105.294 },
    { lat: 2.07, lng: 105.342 },
    { lat: 1.832, lng: 105.788 },
    { lat: 1.748, lng: 105.979 },
    { lat: 1.628, lng: 106.229 },
    { lat: 1.461, lng: 106.666 },
    { lat: 1.396, lng: 106.892 },
    { lat: 1.327, lng: 107.098 },
    { lat: 1.231, lng: 107.527 },
    { lat: 1.184, lng: 107.952 },
    { lat: 1.188, lng: 108.373 },
    { lat: 1.237, lng: 108.79 },
    { lat: 1.316, lng: 109.205 },
    { lat: 1.39, lng: 109.553 },
    { lat: 1.401, lng: 109.618 },
    { lat: 1.467, lng: 110.027 },
    { lat: 1.519, lng: 110.435 },
    { lat: 1.555, lng: 110.84 },
    { lat: 1.576, lng: 111.244 },
    { lat: 1.585, lng: 111.645 },
    { lat: 1.586, lng: 112.045 },
    { lat: 1.585, lng: 112.442 },
    { lat: 1.585, lng: 112.837 },
    { lat: 1.591, lng: 113.231 },
    { lat: 1.608, lng: 113.622 },
    { lat: 1.635, lng: 114.011 },
    { lat: 1.671, lng: 114.398 },
    { lat: 1.712, lng: 114.783 },
    { lat: 1.723, lng: 114.912 },
    { lat: 1.755, lng: 115.167 },
    { lat: 1.786, lng: 115.549 },
    { lat: 1.79, lng: 115.929 },
    { lat: 1.755, lng: 116.309 },
    { lat: 1.719, lng: 116.526 },
    { lat: 1.688, lng: 116.688 },
    { lat: 1.604, lng: 117.065 },
    { lat: 1.537, lng: 117.44 },
    { lat: 1.574, lng: 117.813 },
    { lat: 1.716, lng: 118.013 },
    { lat: 1.779, lng: 118.182 },
    { lat: 1.952, lng: 118.55 },
    { lat: 2.058, lng: 118.788 },
    { lat: 2.084, lng: 118.918 },
    { lat: 2.143, lng: 119.284 },
    { lat: 2.178, lng: 119.651 },
    { lat: 2.191, lng: 120.016 },
    { lat: 2.184, lng: 120.38 },
    { lat: 2.163, lng: 120.743 },
    { lat: 2.133, lng: 121.106 },
    { lat: 2.101, lng: 121.467 },
    { lat: 2.078, lng: 121.827 },
    { lat: 2.069, lng: 122.186 },
    { lat: 2.078, lng: 122.544 },
    { lat: 2.098, lng: 122.9 },
    { lat: 2.12, lng: 123.256 },
    { lat: 2.136, lng: 123.61 },
    { lat: 2.144, lng: 123.965 },
    { lat: 2.15, lng: 124.317 },
    { lat: 2.168, lng: 124.67 },
    { lat: 2.209, lng: 125.021 },
    { lat: 2.277, lng: 125.371 },
    { lat: 2.368, lng: 125.72 },
    { lat: 2.385, lng: 125.778 },
    { lat: 2.443, lng: 126.068 },
    { lat: 2.502, lng: 126.416 },
    { lat: 2.536, lng: 126.764 },
    { lat: 2.543, lng: 127.111 },
    { lat: 2.532, lng: 127.457 },
    { lat: 2.514, lng: 127.804 },
    { lat: 2.497, lng: 128.149 },
    { lat: 2.483, lng: 128.494 },
    { lat: 2.465, lng: 128.839 },
    { lat: 2.439, lng: 129.182 },
    { lat: 2.4, lng: 129.526 },
    { lat: 2.379, lng: 129.659 },
    { lat: 2.339, lng: 129.87 },
    { lat: 2.264, lng: 130.212 },
    { lat: 2.187, lng: 130.555 },
    { lat: 2.11, lng: 130.896 },
    { lat: 2.037, lng: 131.22 },
    { lat: 2.032, lng: 131.237 },
    { lat: 1.932, lng: 131.578 },
    { lat: 1.828, lng: 131.919 },
    { lat: 1.717, lng: 132.259 },
    { lat: 1.697, lng: 132.314 },
    { lat: 1.565, lng: 132.598 },
    { lat: 1.401, lng: 132.938 },
    { lat: 1.357, lng: 133.025 },
    { lat: 1.185, lng: 133.278 },
    { lat: 1.017, lng: 133.538 },
    { lat: 0.948, lng: 133.615 },
    { lat: 0.689, lng: 133.954 },
    { lat: 0.678, lng: 133.971 },
    { lat: 0.389, lng: 134.293 },
    { lat: 0.339, lng: 134.355 },
    { lat: 0.031, lng: 134.63 },
    { lat: -0.0, lng: 134.658 },
    { lat: -0.339, lng: 134.906 },
    { lat: -0.428, lng: 134.967 },
    { lat: -0.678, lng: 135.166 },
    { lat: -0.835, lng: 135.304 },
    { lat: -1.017, lng: 135.553 },
    { lat: -1.074, lng: 135.642 },
    { lat: -1.156, lng: 135.979 },
    { lat: -1.141, lng: 136.317 },
    { lat: -1.093, lng: 136.652 },
    { lat: -1.062, lng: 136.99 },
    { lat: -1.079, lng: 137.326 },
    { lat: -1.133, lng: 137.663 },
    { lat: -1.188, lng: 138.0 },
    { lat: -1.223, lng: 138.337 },
    { lat: -1.231, lng: 138.673 },
    { lat: -1.226, lng: 139.01 },
    { lat: -1.224, lng: 139.346 },
    { lat: -1.244, lng: 139.684 },
    { lat: -1.301, lng: 140.022 },
    { lat: -1.356, lng: 140.207 },
    { lat: -1.406, lng: 140.358 },
    { lat: -1.565, lng: 140.697 },
    { lat: -1.695, lng: 140.926 },
    { lat: -1.767, lng: 141.034 },
    { lat: -2.027, lng: 141.373 },
    { lat: -2.035, lng: 141.381 },
    { lat: -2.375, lng: 141.669 },
    { lat: -2.448, lng: 141.711 },
    { lat: -2.714, lng: 141.832 },
    { lat: -3.054, lng: 141.904 },
    { lat: -3.394, lng: 141.887 },
    { lat: -3.734, lng: 141.781 },
    { lat: -3.853, lng: 141.717 },
    { lat: -4.074, lng: 141.521 },
    { lat: -4.205, lng: 141.38 },
    { lat: -4.397, lng: 141.043 },
    { lat: -4.414, lng: 140.994 },
    { lat: -4.536, lng: 140.704 },
    { lat: -4.646, lng: 140.367 },
    { lat: -4.74, lng: 140.029 },
    { lat: -4.754, lng: 139.973 },
    { lat: -4.879, lng: 139.691 },
    { lat: -4.985, lng: 139.353 },
    { lat: -5.03, lng: 139.015 },
    { lat: -5.026, lng: 138.676 },
    { lat: -4.974, lng: 138.338 },
    { lat: -4.867, lng: 138.0 },
    { lat: -4.754, lng: 137.754 },
    { lat: -4.721, lng: 137.662 },
    { lat: -4.602, lng: 137.324 },
    { lat: -4.514, lng: 136.986 },
    { lat: -4.468, lng: 136.648 },
    { lat: -4.443, lng: 136.31 },
    { lat: -4.413, lng: 136.109 },
    { lat: -4.395, lng: 135.973 },
    { lat: -4.29, lng: 135.635 },
    { lat: -4.114, lng: 135.297 },
    { lat: -4.073, lng: 135.223 },
    { lat: -3.936, lng: 134.96 },
    { lat: -3.854, lng: 134.621 },
    { lat: -3.926, lng: 134.283 },
    { lat: -4.074, lng: 134.085 },
    { lat: -4.197, lng: 133.942 },
    { lat: -4.415, lng: 133.786 },
    { lat: -4.699, lng: 133.6 },
    { lat: -4.756, lng: 133.567 },
    { lat: -5.097, lng: 133.371 },
    { lat: -5.281, lng: 133.255 },
    { lat: -5.44, lng: 133.14 },
    { lat: -5.719, lng: 132.91 },
    { lat: -5.782, lng: 132.838 },
    { lat: -6.011, lng: 132.566 },
    { lat: -6.125, lng: 132.374 },
    { lat: -6.223, lng: 132.222 },
    { lat: -6.424, lng: 131.877 },
    { lat: -6.469, lng: 131.812 },
    { lat: -6.751, lng: 131.53 },
    { lat: -6.812, lng: 131.491 },
    { lat: -7.156, lng: 131.303 },
    { lat: -7.436, lng: 131.175 },
    { lat: -7.501, lng: 131.148 },
    { lat: -7.845, lng: 130.998 },
    { lat: -8.191, lng: 130.83 },
    { lat: -8.213, lng: 130.815 },
    { lat: -8.537, lng: 130.56 },
    { lat: -8.622, lng: 130.46 },
    { lat: -8.817, lng: 130.109 },
    { lat: -8.886, lng: 129.814 },
    { lat: -8.895, lng: 129.761 },
    { lat: -8.904, lng: 129.413 },
    { lat: -8.909, lng: 129.065 },
    { lat: -8.944, lng: 128.715 },
    { lat: -9.028, lng: 128.364 },
    { lat: -9.171, lng: 128.009 },
    { lat: -9.243, lng: 127.895 },
    { lat: -9.367, lng: 127.652 },
    { lat: -9.593, lng: 127.342 },
    { lat: -9.622, lng: 127.291 },
    { lat: -9.883, lng: 126.929 },
    { lat: -9.944, lng: 126.857 },
    { lat: -10.133, lng: 126.565 },
    { lat: -10.297, lng: 126.332 },
    { lat: -10.37, lng: 126.2 },
    { lat: -10.574, lng: 125.836 },
    { lat: -10.652, lng: 125.692 },
    { lat: -10.751, lng: 125.47 },
    { lat: -10.902, lng: 125.105 },
    { lat: -11.009, lng: 124.823 },
    { lat: -11.038, lng: 124.74 },
    { lat: -11.156, lng: 124.374 },
    { lat: -11.276, lng: 124.006 },
    { lat: -11.371, lng: 123.73 },
    { lat: -11.402, lng: 123.637 },
    { lat: -11.537, lng: 123.266 },
    { lat: -11.68, lng: 122.892 },
    { lat: -11.734, lng: 122.753 },
    { lat: -11.827, lng: 122.518 },
    { lat: -11.965, lng: 122.141 },
    { lat: -12.083, lng: 121.765 },
    { lat: -12.099, lng: 121.696 },
    { lat: -12.179, lng: 121.389 },
    { lat: -12.242, lng: 121.014 },
    { lat: -12.269, lng: 120.64 },
    { lat: -12.259, lng: 120.268 },
    { lat: -12.214, lng: 119.898 },
    { lat: -12.133, lng: 119.53 },
    { lat: -12.127, lng: 119.509 },
    { lat: -12.024, lng: 119.163 },
    { lat: -11.883, lng: 118.798 },
    { lat: -11.786, lng: 118.578 },
    { lat: -11.723, lng: 118.433 },
    { lat: -11.566, lng: 118.067 },
    { lat: -11.443, lng: 117.747 },
    { lat: -11.426, lng: 117.699 },
    { lat: -11.324, lng: 117.327 },
    { lat: -11.254, lng: 116.949 },
    { lat: -11.216, lng: 116.568 },
    { lat: -11.202, lng: 116.184 },
    { lat: -11.203, lng: 115.796 },
    { lat: -11.208, lng: 115.406 },
    { lat: -11.208, lng: 115.015 },
    { lat: -11.193, lng: 114.623 },
    { lat: -11.158, lng: 114.232 },
    { lat: -11.143, lng: 114.135 },
    { lat: -11.098, lng: 113.842 },
    { lat: -11.008, lng: 113.453 },
    { lat: -10.885, lng: 113.066 },
    { lat: -10.808, lng: 112.869 },
    { lat: -10.735, lng: 112.68 },
    { lat: -10.573, lng: 112.294 },
    { lat: -10.467, lng: 112.037 },
    { lat: -10.416, lng: 111.906 },
    { lat: -10.276, lng: 111.513 },
    { lat: -10.159, lng: 111.117 },
    { lat: -10.129, lng: 110.997 },
    { lat: -10.061, lng: 110.716 },
    { lat: -9.983, lng: 110.311 },
    { lat: -9.923, lng: 109.902 },
    { lat: -9.88, lng: 109.489 },
    { lat: -9.85, lng: 109.071 },
    { lat: -9.831, lng: 108.65 },
    { lat: -9.821, lng: 108.225 },
    { lat: -9.821, lng: 108.105 },
    { lat: -9.821, lng: 107.797 },
    { lat: -9.829, lng: 107.364 },
    { lat: -9.837, lng: 107.162 },
    { lat: -9.848, lng: 106.927 },
    { lat: -9.879, lng: 106.485 },
    { lat: -9.918, lng: 106.038 },
    { lat: -9.952, lng: 105.589 },
    { lat: -9.952, lng: 105.141 },
    { lat: -9.884, lng: 104.699 },
    { lat: -9.882, lng: 104.693 },
    { lat: -9.751, lng: 104.263 },
    { lat: -9.539, lng: 103.843 },
    { lat: -9.535, lng: 103.835 },
    { lat: -9.258, lng: 103.411 },
    { lat: -9.19, lng: 103.331 },
    { lat: -8.895, lng: 102.996 },
    { lat: -8.839, lng: 102.946 },
    { lat: -8.487, lng: 102.659 },
    { lat: -8.393, lng: 102.594 },
    { lat: -8.133, lng: 102.435 },
    { lat: -7.779, lng: 102.24 },
    { lat: -7.714, lng: 102.207 },
    { lat: -7.426, lng: 102.065 },
    { lat: -7.072, lng: 101.881 },
    { lat: -6.964, lng: 101.82 },
    { lat: -6.719, lng: 101.671 },
    { lat: -6.366, lng: 101.441 },
    { lat: -6.319, lng: 101.411 },
    { lat: -6.013, lng: 101.204 },
    { lat: -5.675, lng: 100.994 },
    { lat: -5.66, lng: 100.984 },
    { lat: -5.308, lng: 100.74 },
    { lat: -5.075, lng: 100.563 },
    { lat: -4.955, lng: 100.469 },
    { lat: -4.603, lng: 100.172 },
    { lat: -4.542, lng: 100.119 },
    { lat: -4.251, lng: 99.864 },
    { lat: -4.019, lng: 99.665 },
    { lat: -3.898, lng: 99.56 },
    { lat: -3.546, lng: 99.243 },
    { lat: -3.504, lng: 99.203 },
    { lat: -3.193, lng: 98.896 },
    { lat: -3.026, lng: 98.729 },
    { lat: -2.84, lng: 98.535 },
    { lat: -2.561, lng: 98.247 },
    { lat: -2.486, lng: 98.166 },
    { lat: -2.133, lng: 97.787 },
    { lat: -2.102, lng: 97.756 },
    { lat: -1.779, lng: 97.411 },
    { lat: -1.615, lng: 97.256 },
    { lat: -1.424, lng: 97.069 },
    { lat: -1.068, lng: 96.774 },
    { lat: -1.031, lng: 96.748 },
    { lat: -0.713, lng: 96.521 },
    { lat: -0.356, lng: 96.321 },
    { lat: -0.107, lng: 96.23 },
    { lat: 0.0, lng: 96.192 },
    { lat: 0.357, lng: 96.132 },
    { lat: 0.713, lng: 96.105 },
    { lat: 1.07, lng: 96.067 },
    { lat: 1.427, lng: 95.985 },
    { lat: 1.785, lng: 95.839 },
    { lat: 2.076, lng: 95.652 },
    { lat: 2.143, lng: 95.611 },
    { lat: 2.502, lng: 95.303 },
    { lat: 2.72, lng: 95.077 },
    { lat: 2.862, lng: 94.931 },
    { lat: 3.223, lng: 94.52 },
    { lat: 3.246, lng: 94.493 },
    { lat: 3.586, lng: 94.078 },
    { lat: 3.727, lng: 93.897 },
    { lat: 3.949, lng: 93.604 },
    { lat: 4.193, lng: 93.288 },
    { lat: 4.314, lng: 93.126 },
    { lat: 4.679, lng: 92.679 },
    { lat: 4.699, lng: 92.659 },
    { lat: 5.045, lng: 92.282 },
    { lat: 5.345, lng: 91.995 },
    { lat: 5.411, lng: 91.929 },
    { lat: 5.778, lng: 91.652 },
    { lat: 6.143, lng: 91.48 },
    { lat: 6.507, lng: 91.459 },
    { lat: 6.867, lng: 91.641 },
    { lat: 6.971, lng: 91.764 },
    { lat: 7.221, lng: 92.237 },
    { lat: 7.247, lng: 92.316 },
    { lat: 7.362, lng: 92.885 },
    { lat: 7.412, lng: 93.456 },
    { lat: 7.414, lng: 94.026 },
    { lat: 7.377, lng: 94.594 },
    { lat: 7.305, lng: 95.159 },
    { lat: 7.203, lng: 95.72 },
    { lat: 7.162, lng: 95.892 },
  ],
  c4: [
    { lat: -5.778, lng: 139.933 },
    { lat: -5.813, lng: 140.033 },
    { lat: -6.12, lng: 140.344 },
    { lat: -6.144, lng: 140.374 },
    { lat: -6.462, lng: 140.622 },
    { lat: -6.564, lng: 140.715 },
    { lat: -6.805, lng: 140.94 },
    { lat: -6.912, lng: 141.058 },
    { lat: -7.149, lng: 141.379 },
    { lat: -7.165, lng: 141.401 },
    { lat: -7.451, lng: 141.744 },
    { lat: -7.493, lng: 141.78 },
    { lat: -7.837, lng: 141.965 },
    { lat: -8.182, lng: 142.022 },
    { lat: -8.527, lng: 141.971 },
    { lat: -8.871, lng: 141.799 },
    { lat: -8.914, lng: 141.76 },
    { lat: -9.211, lng: 141.421 },
    { lat: -9.216, lng: 141.412 },
    { lat: -9.343, lng: 141.08 },
    { lat: -9.4, lng: 140.738 },
    { lat: -9.393, lng: 140.395 },
    { lat: -9.348, lng: 140.052 },
    { lat: -9.3, lng: 139.71 },
    { lat: -9.27, lng: 139.368 },
    { lat: -9.26, lng: 139.026 },
    { lat: -9.258, lng: 138.684 },
    { lat: -9.243, lng: 138.342 },
    { lat: -9.213, lng: 138.104 },
    { lat: -9.193, lng: 138.0 },
    { lat: -9.074, lng: 137.658 },
    { lat: -8.923, lng: 137.317 },
    { lat: -8.867, lng: 137.187 },
    { lat: -8.731, lng: 136.976 },
    { lat: -8.549, lng: 136.635 },
    { lat: -8.522, lng: 136.576 },
    { lat: -8.279, lng: 136.296 },
    { lat: -8.178, lng: 136.177 },
    { lat: -7.834, lng: 136.099 },
    { lat: -7.491, lng: 136.221 },
    { lat: -7.373, lng: 136.3 },
    { lat: -7.147, lng: 136.47 },
    { lat: -6.961, lng: 136.641 },
    { lat: -6.804, lng: 136.835 },
    { lat: -6.684, lng: 136.982 },
    { lat: -6.496, lng: 137.322 },
    { lat: -6.461, lng: 137.407 },
    { lat: -6.336, lng: 137.661 },
    { lat: -6.198, lng: 138.0 },
    { lat: -6.119, lng: 138.202 },
    { lat: -6.039, lng: 138.339 },
    { lat: -5.866, lng: 138.677 },
    { lat: -5.776, lng: 138.928 },
    { lat: -5.714, lng: 139.016 },
    { lat: -5.606, lng: 139.354 },
    { lat: -5.631, lng: 139.693 },
    { lat: -5.778, lng: 139.933 },
  ],
  c5: [
    { lat: -4.091, lng: 126.156 },
    { lat: -4.329, lng: 126.39 },
    { lat: -4.431, lng: 126.478 },
    { lat: -4.57, lng: 126.386 },
    { lat: -4.699, lng: 126.033 },
    { lat: -4.652, lng: 125.684 },
    { lat: -4.533, lng: 125.335 },
    { lat: -4.436, lng: 125.134 },
    { lat: -4.094, lng: 125.098 },
    { lat: -3.987, lng: 125.346 },
    { lat: -3.959, lng: 125.697 },
    { lat: -4.039, lng: 126.046 },
    { lat: -4.091, lng: 126.156 },
  ],
  c6: [
    { lat: -0.0, lng: 128.549 },
    { lat: -0.035, lng: 128.849 },
    { lat: -0.052, lng: 129.192 },
    { lat: -0.061, lng: 129.535 },
    { lat: -0.078, lng: 129.877 },
    { lat: -0.115, lng: 130.219 },
    { lat: -0.183, lng: 130.56 },
    { lat: -0.285, lng: 130.902 },
    { lat: -0.339, lng: 131.042 },
    { lat: -0.445, lng: 131.242 },
    { lat: -0.636, lng: 131.582 },
    { lat: -0.679, lng: 131.658 },
    { lat: -0.917, lng: 131.921 },
    { lat: -1.018, lng: 132.051 },
    { lat: -1.357, lng: 132.194 },
    { lat: -1.564, lng: 131.919 },
    { lat: -1.622, lng: 131.579 },
    { lat: -1.625, lng: 131.239 },
    { lat: -1.613, lng: 130.898 },
    { lat: -1.593, lng: 130.557 },
    { lat: -1.566, lng: 130.215 },
    { lat: -1.531, lng: 129.874 },
    { lat: -1.486, lng: 129.532 },
    { lat: -1.432, lng: 129.189 },
    { lat: -1.369, lng: 128.846 },
    { lat: -1.359, lng: 128.798 },
    { lat: -1.28, lng: 128.502 },
    { lat: -1.193, lng: 128.158 },
    { lat: -1.115, lng: 127.813 },
    { lat: -1.043, lng: 127.468 },
    { lat: -1.021, lng: 127.361 },
    { lat: -0.947, lng: 127.122 },
    { lat: -0.835, lng: 126.776 },
    { lat: -0.699, lng: 126.428 },
    { lat: -0.681, lng: 126.388 },
    { lat: -0.402, lng: 126.081 },
    { lat: -0.34, lng: 126.006 },
    { lat: -0.0, lng: 126.055 },
    { lat: 0.017, lng: 126.081 },
    { lat: 0.21, lng: 126.429 },
    { lat: 0.294, lng: 126.777 },
    { lat: 0.287, lng: 127.123 },
    { lat: 0.222, lng: 127.47 },
    { lat: 0.137, lng: 127.816 },
    { lat: 0.06, lng: 128.161 },
    { lat: 0.004, lng: 128.505 },
    { lat: -0.0, lng: 128.549 },
  ],
  c7: [
    { lat: 7.907, lng: 94.558 },
    { lat: 7.831, lng: 95.078 },
    { lat: 7.721, lng: 95.643 },
    { lat: 7.583, lng: 96.204 },
    { lat: 7.513, lng: 96.44 },
    { lat: 7.421, lng: 96.761 },
    { lat: 7.239, lng: 97.312 },
    { lat: 7.135, lng: 97.598 },
    { lat: 7.043, lng: 97.857 },
    { lat: 6.837, lng: 98.396 },
    { lat: 6.762, lng: 98.58 },
    { lat: 6.621, lng: 98.928 },
    { lat: 6.393, lng: 99.454 },
    { lat: 6.392, lng: 99.457 },
    { lat: 6.163, lng: 99.973 },
    { lat: 6.025, lng: 100.275 },
    { lat: 5.928, lng: 100.486 },
    { lat: 5.694, lng: 100.992 },
    { lat: 5.659, lng: 101.067 },
    { lat: 5.457, lng: 101.492 },
    { lat: 5.296, lng: 101.821 },
    { lat: 5.209, lng: 101.986 },
    { lat: 4.936, lng: 102.476 },
    { lat: 4.935, lng: 102.478 },
    { lat: 4.621, lng: 102.963 },
    { lat: 4.577, lng: 103.026 },
    { lat: 4.239, lng: 103.448 },
    { lat: 4.22, lng: 103.471 },
    { lat: 3.865, lng: 103.861 },
    { lat: 3.8, lng: 103.93 },
    { lat: 3.51, lng: 104.249 },
    { lat: 3.377, lng: 104.403 },
    { lat: 3.156, lng: 104.686 },
    { lat: 3.027, lng: 104.866 },
    { lat: 2.802, lng: 105.225 },
    { lat: 2.745, lng: 105.32 },
    { lat: 2.519, lng: 105.768 },
    { lat: 2.448, lng: 105.937 },
    { lat: 2.334, lng: 106.211 },
    { lat: 2.192, lng: 106.649 },
    { lat: 2.094, lng: 107.068 },
    { lat: 2.091, lng: 107.082 },
    { lat: 2.027, lng: 107.511 },
    { lat: 2.001, lng: 107.936 },
    { lat: 2.003, lng: 108.357 },
    { lat: 2.021, lng: 108.776 },
    { lat: 2.045, lng: 109.191 },
    { lat: 2.065, lng: 109.604 },
    { lat: 2.076, lng: 110.015 },
    { lat: 2.08, lng: 110.424 },
    { lat: 2.077, lng: 110.83 },
    { lat: 2.069, lng: 111.234 },
    { lat: 2.061, lng: 111.636 },
    { lat: 2.054, lng: 112.036 },
    { lat: 2.054, lng: 112.434 },
    { lat: 2.066, lng: 112.829 },
    { lat: 2.074, lng: 112.936 },
    { lat: 2.1, lng: 113.222 },
    { lat: 2.168, lng: 113.612 },
    { lat: 2.275, lng: 113.999 },
    { lat: 2.415, lng: 114.367 },
    { lat: 2.424, lng: 114.384 },
    { lat: 2.65, lng: 114.765 },
    { lat: 2.758, lng: 114.954 },
    { lat: 2.877, lng: 115.144 },
    { lat: 3.048, lng: 115.523 },
    { lat: 3.1, lng: 115.715 },
    { lat: 3.141, lng: 115.901 },
    { lat: 3.172, lng: 116.28 },
    { lat: 3.165, lng: 116.659 },
    { lat: 3.138, lng: 117.036 },
    { lat: 3.105, lng: 117.412 },
    { lat: 3.092, lng: 117.583 },
    { lat: 3.076, lng: 117.786 },
    { lat: 3.058, lng: 118.159 },
    { lat: 3.053, lng: 118.53 },
    { lat: 3.056, lng: 118.9 },
    { lat: 3.062, lng: 119.268 },
    { lat: 3.067, lng: 119.635 },
    { lat: 3.07, lng: 120.0 },
    { lat: 3.07, lng: 120.365 },
    { lat: 3.07, lng: 120.728 },
    { lat: 3.07, lng: 121.091 },
    { lat: 3.073, lng: 121.452 },
    { lat: 3.078, lng: 121.701 },
    { lat: 3.08, lng: 121.812 },
    { lat: 3.089, lng: 122.171 },
    { lat: 3.101, lng: 122.529 },
    { lat: 3.114, lng: 122.886 },
    { lat: 3.127, lng: 123.242 },
    { lat: 3.14, lng: 123.597 },
    { lat: 3.151, lng: 123.951 },
    { lat: 3.164, lng: 124.304 },
    { lat: 3.183, lng: 124.657 },
    { lat: 3.215, lng: 125.008 },
    { lat: 3.263, lng: 125.358 },
    { lat: 3.323, lng: 125.708 },
    { lat: 3.384, lng: 126.056 },
    { lat: 3.407, lng: 126.223 },
    { lat: 3.433, lng: 126.404 },
    { lat: 3.46, lng: 126.752 },
    { lat: 3.458, lng: 127.1 },
    { lat: 3.43, lng: 127.448 },
    { lat: 3.404, lng: 127.655 },
    { lat: 3.387, lng: 127.794 },
    { lat: 3.342, lng: 128.141 },
    { lat: 3.302, lng: 128.486 },
    { lat: 3.266, lng: 128.831 },
    { lat: 3.225, lng: 129.176 },
    { lat: 3.17, lng: 129.519 },
    { lat: 3.097, lng: 129.863 },
    { lat: 3.059, lng: 130.009 },
    { lat: 3.009, lng: 130.207 },
    { lat: 2.911, lng: 130.549 },
    { lat: 2.809, lng: 130.891 },
    { lat: 2.717, lng: 131.196 },
    { lat: 2.706, lng: 131.234 },
    { lat: 2.597, lng: 131.575 },
    { lat: 2.49, lng: 131.916 },
    { lat: 2.382, lng: 132.256 },
    { lat: 2.376, lng: 132.275 },
    { lat: 2.264, lng: 132.596 },
    { lat: 2.148, lng: 132.936 },
    { lat: 2.035, lng: 133.269 },
    { lat: 2.033, lng: 133.275 },
    { lat: 1.911, lng: 133.613 },
    { lat: 1.805, lng: 133.953 },
    { lat: 1.725, lng: 134.291 },
    { lat: 1.696, lng: 134.497 },
    { lat: 1.672, lng: 134.628 },
    { lat: 1.64, lng: 134.967 },
    { lat: 1.609, lng: 135.304 },
    { lat: 1.551, lng: 135.642 },
    { lat: 1.458, lng: 135.979 },
    { lat: 1.357, lng: 136.277 },
    { lat: 1.341, lng: 136.315 },
    { lat: 1.206, lng: 136.652 },
    { lat: 1.092, lng: 136.991 },
    { lat: 1.017, lng: 137.237 },
    { lat: 0.983, lng: 137.328 },
    { lat: 0.85, lng: 137.665 },
    { lat: 0.698, lng: 138.0 },
    { lat: 0.679, lng: 138.035 },
    { lat: 0.521, lng: 138.338 },
    { lat: 0.368, lng: 138.677 },
    { lat: 0.339, lng: 138.751 },
    { lat: 0.261, lng: 139.008 },
    { lat: 0.179, lng: 139.348 },
    { lat: 0.075, lng: 139.682 },
    { lat: -0.0, lng: 139.834 },
    { lat: -0.074, lng: 140.022 },
    { lat: -0.267, lng: 140.356 },
    { lat: -0.339, lng: 140.453 },
    { lat: -0.493, lng: 140.694 },
    { lat: -0.678, lng: 140.941 },
    { lat: -0.739, lng: 141.033 },
    { lat: -0.994, lng: 141.37 },
    { lat: -1.017, lng: 141.398 },
    { lat: -1.27, lng: 141.708 },
    { lat: -1.356, lng: 141.805 },
    { lat: -1.586, lng: 142.047 },
    { lat: -1.696, lng: 142.151 },
    { lat: -1.992, lng: 142.386 },
    { lat: -2.035, lng: 142.416 },
    { lat: -2.375, lng: 142.603 },
    { lat: -2.681, lng: 142.727 },
    { lat: -2.715, lng: 142.741 },
    { lat: -3.055, lng: 142.832 },
    { lat: -3.395, lng: 142.896 },
    { lat: -3.735, lng: 142.942 },
    { lat: -4.075, lng: 142.979 },
    { lat: -4.416, lng: 143.016 },
    { lat: -4.757, lng: 143.06 },
    { lat: -4.888, lng: 143.082 },
    { lat: -5.098, lng: 143.112 },
    { lat: -5.44, lng: 143.172 },
    { lat: -5.782, lng: 143.249 },
    { lat: -6.124, lng: 143.344 },
    { lat: -6.415, lng: 143.439 },
    { lat: -6.467, lng: 143.456 },
    { lat: -6.81, lng: 143.579 },
    { lat: -7.154, lng: 143.706 },
    { lat: -7.476, lng: 143.796 },
    { lat: -7.498, lng: 143.802 },
    { lat: -7.842, lng: 143.833 },
    { lat: -8.129, lng: 143.806 },
    { lat: -8.186, lng: 143.801 },
    { lat: -8.531, lng: 143.696 },
    { lat: -8.876, lng: 143.531 },
    { lat: -8.955, lng: 143.477 },
    { lat: -9.221, lng: 143.288 },
    { lat: -9.38, lng: 143.141 },
    { lat: -9.566, lng: 142.955 },
    { lat: -9.689, lng: 142.802 },
    { lat: -9.912, lng: 142.483 },
    { lat: -9.924, lng: 142.461 },
    { lat: -10.081, lng: 142.119 },
    { lat: -10.189, lng: 141.777 },
    { lat: -10.248, lng: 141.433 },
    { lat: -10.257, lng: 141.307 },
    { lat: -10.268, lng: 141.09 },
    { lat: -10.26, lng: 140.746 },
    { lat: -10.255, lng: 140.698 },
    { lat: -10.224, lng: 140.402 },
    { lat: -10.164, lng: 140.058 },
    { lat: -10.093, lng: 139.715 },
    { lat: -10.033, lng: 139.371 },
    { lat: -10.0, lng: 139.028 },
    { lat: -9.995, lng: 138.685 },
    { lat: -10.003, lng: 138.343 },
    { lat: -10.005, lng: 138.0 },
    { lat: -9.983, lng: 137.657 },
    { lat: -9.929, lng: 137.315 },
    { lat: -9.906, lng: 137.215 },
    { lat: -9.84, lng: 136.972 },
    { lat: -9.75, lng: 136.63 },
    { lat: -9.687, lng: 136.288 },
    { lat: -9.659, lng: 135.945 },
    { lat: -9.667, lng: 135.603 },
    { lat: -9.709, lng: 135.259 },
    { lat: -9.776, lng: 134.916 },
    { lat: -9.854, lng: 134.571 },
    { lat: -9.91, lng: 134.298 },
    { lat: -9.922, lng: 134.227 },
    { lat: -9.96, lng: 133.882 },
    { lat: -9.977, lng: 133.538 },
    { lat: -9.978, lng: 133.193 },
    { lat: -9.974, lng: 132.849 },
    { lat: -9.971, lng: 132.504 },
    { lat: -9.975, lng: 132.158 },
    { lat: -9.99, lng: 131.812 },
    { lat: -10.021, lng: 131.466 },
    { lat: -10.064, lng: 131.118 },
    { lat: -10.112, lng: 130.77 },
    { lat: -10.155, lng: 130.421 },
    { lat: -10.186, lng: 130.072 },
    { lat: -10.202, lng: 129.723 },
    { lat: -10.202, lng: 129.374 },
    { lat: -10.194, lng: 129.025 },
    { lat: -10.191, lng: 128.675 },
    { lat: -10.211, lng: 128.324 },
    { lat: -10.271, lng: 127.971 },
    { lat: -10.286, lng: 127.921 },
    { lat: -10.364, lng: 127.615 },
    { lat: -10.495, lng: 127.257 },
    { lat: -10.642, lng: 126.93 },
    { lat: -10.655, lng: 126.897 },
    { lat: -10.817, lng: 126.535 },
    { lat: -10.986, lng: 126.172 },
    { lat: -10.998, lng: 126.145 },
    { lat: -11.14, lng: 125.808 },
    { lat: -11.285, lng: 125.443 },
    { lat: -11.357, lng: 125.247 },
    { lat: -11.415, lng: 125.078 },
    { lat: -11.532, lng: 124.712 },
    { lat: -11.644, lng: 124.345 },
    { lat: -11.719, lng: 124.097 },
    { lat: -11.756, lng: 123.977 },
    { lat: -11.88, lng: 123.607 },
    { lat: -12.015, lng: 123.235 },
    { lat: -12.083, lng: 123.058 },
    { lat: -12.166, lng: 122.86 },
    { lat: -12.323, lng: 122.483 },
    { lat: -12.447, lng: 122.162 },
    { lat: -12.472, lng: 122.105 },
    { lat: -12.613, lng: 121.725 },
    { lat: -12.721, lng: 121.347 },
    { lat: -12.797, lng: 120.969 },
    { lat: -12.82, lng: 120.761 },
    { lat: -12.841, lng: 120.593 },
    { lat: -12.854, lng: 120.218 },
    { lat: -12.836, lng: 119.845 },
    { lat: -12.833, lng: 119.824 },
    { lat: -12.789, lng: 119.473 },
    { lat: -12.71, lng: 119.102 },
    { lat: -12.598, lng: 118.734 },
    { lat: -12.497, lng: 118.467 },
    { lat: -12.459, lng: 118.367 },
    { lat: -12.302, lng: 118.001 },
    { lat: -12.154, lng: 117.649 },
    { lat: -12.148, lng: 117.633 },
    { lat: -12.021, lng: 117.262 },
    { lat: -11.93, lng: 116.886 },
    { lat: -11.873, lng: 116.506 },
    { lat: -11.843, lng: 116.122 },
    { lat: -11.827, lng: 115.782 },
    { lat: -11.825, lng: 115.734 },
    { lat: -11.808, lng: 115.346 },
    { lat: -11.782, lng: 114.956 },
    { lat: -11.744, lng: 114.566 },
    { lat: -11.691, lng: 114.176 },
    { lat: -11.62, lng: 113.786 },
    { lat: -11.525, lng: 113.397 },
    { lat: -11.511, lng: 113.353 },
    { lat: -11.406, lng: 113.009 },
    { lat: -11.256, lng: 112.623 },
    { lat: -11.171, lng: 112.442 },
    { lat: -11.08, lng: 112.239 },
    { lat: -10.897, lng: 111.853 },
    { lat: -10.827, lng: 111.704 },
    { lat: -10.726, lng: 111.464 },
    { lat: -10.579, lng: 111.07 },
    { lat: -10.487, lng: 110.78 },
    { lat: -10.456, lng: 110.672 },
    { lat: -10.361, lng: 110.269 },
    { lat: -10.29, lng: 109.86 },
    { lat: -10.243, lng: 109.447 },
    { lat: -10.219, lng: 109.028 },
    { lat: -10.217, lng: 108.604 },
    { lat: -10.244, lng: 108.174 },
    { lat: -10.306, lng: 107.736 },
    { lat: -10.421, lng: 107.288 },
    { lat: -10.555, lng: 106.936 },
    { lat: -10.641, lng: 106.823 },
    { lat: -10.922, lng: 106.484 },
    { lat: -11.285, lng: 106.296 },
    { lat: -11.329, lng: 106.283 },
    { lat: -11.648, lng: 106.152 },
    { lat: -12.014, lng: 105.874 },
    { lat: -12.092, lng: 105.719 },
    { lat: -12.064, lng: 105.273 },
    { lat: -12.03, lng: 105.183 },
    { lat: -11.896, lng: 104.847 },
    { lat: -11.684, lng: 104.455 },
    { lat: -11.67, lng: 104.428 },
    { lat: -11.381, lng: 104.016 },
    { lat: -11.334, lng: 103.961 },
    { lat: -11.041, lng: 103.609 },
    { lat: -10.982, lng: 103.55 },
    { lat: -10.643, lng: 103.207 },
    { lat: -10.629, lng: 103.195 },
    { lat: -10.275, lng: 102.884 },
    { lat: -10.199, lng: 102.808 },
    { lat: -9.922, lng: 102.576 },
    { lat: -9.741, lng: 102.407 },
    { lat: -9.568, lng: 102.269 },
    { lat: -9.246, lng: 102.006 },
    { lat: -9.215, lng: 101.983 },
    { lat: -8.86, lng: 101.749 },
    { lat: -8.643, lng: 101.613 },
    { lat: -8.506, lng: 101.535 },
    { lat: -8.151, lng: 101.332 },
    { lat: -7.966, lng: 101.223 },
    { lat: -7.797, lng: 101.121 },
    { lat: -7.443, lng: 100.893 },
    { lat: -7.334, lng: 100.818 },
    { lat: -7.09, lng: 100.634 },
    { lat: -6.8, lng: 100.394 },
    { lat: -6.737, lng: 100.334 },
    { lat: -6.385, lng: 100.005 },
    { lat: -6.325, lng: 99.956 },
    { lat: -6.032, lng: 99.676 },
    { lat: -5.824, lng: 99.514 },
    { lat: -5.679, lng: 99.381 },
    { lat: -5.326, lng: 99.111 },
    { lat: -5.268, lng: 99.068 },
    { lat: -4.972, lng: 98.825 },
    { lat: -4.697, lng: 98.616 },
    { lat: -4.619, lng: 98.552 },
    { lat: -4.265, lng: 98.298 },
    { lat: -4.056, lng: 98.158 },
    { lat: -3.911, lng: 98.06 },
    { lat: -3.557, lng: 97.781 },
    { lat: -3.457, lng: 97.687 },
    { lat: -3.203, lng: 97.442 },
    { lat: -2.971, lng: 97.198 },
    { lat: -2.849, lng: 97.07 },
    { lat: -2.507, lng: 96.698 },
    { lat: -2.494, lng: 96.686 },
    { lat: -2.14, lng: 96.301 },
    { lat: -2.034, lng: 96.19 },
    { lat: -1.784, lng: 95.936 },
    { lat: -1.509, lng: 95.672 },
    { lat: -1.428, lng: 95.597 },
    { lat: -1.072, lng: 95.289 },
    { lat: -0.877, lng: 95.144 },
    { lat: -0.715, lng: 95.022 },
    { lat: -0.358, lng: 94.834 },
    { lat: 0.0, lng: 94.765 },
    { lat: 0.358, lng: 94.813 },
    { lat: 0.715, lng: 94.924 },
    { lat: 1.073, lng: 95.028 },
    { lat: 1.43, lng: 95.065 },
    { lat: 1.788, lng: 94.998 },
    { lat: 2.147, lng: 94.814 },
    { lat: 2.492, lng: 94.538 },
    { lat: 2.506, lng: 94.527 },
    { lat: 2.867, lng: 94.159 },
    { lat: 3.053, lng: 93.946 },
    { lat: 3.229, lng: 93.741 },
    { lat: 3.541, lng: 93.343 },
    { lat: 3.592, lng: 93.276 },
    { lat: 3.957, lng: 92.768 },
    { lat: 3.986, lng: 92.728 },
    { lat: 4.323, lng: 92.251 },
    { lat: 4.442, lng: 92.097 },
    { lat: 4.689, lng: 91.765 },
    { lat: 4.961, lng: 91.441 },
    { lat: 5.057, lng: 91.322 },
    { lat: 5.424, lng: 90.918 },
    { lat: 5.607, lng: 90.749 },
    { lat: 5.792, lng: 90.568 },
    { lat: 6.16, lng: 90.302 },
    { lat: 6.527, lng: 90.132 },
    { lat: 6.893, lng: 90.068 },
    { lat: 7.257, lng: 90.159 },
    { lat: 7.539, lng: 90.44 },
    { lat: 7.615, lng: 90.553 },
    { lat: 7.786, lng: 91.014 },
    { lat: 7.915, lng: 91.6 },
    { lat: 7.955, lng: 91.923 },
    { lat: 7.98, lng: 92.188 },
    { lat: 8.004, lng: 92.775 },
    { lat: 7.999, lng: 93.357 },
    { lat: 7.97, lng: 93.935 },
    { lat: 7.914, lng: 94.508 },
    { lat: 7.907, lng: 94.558 },
  ],
  c8: [
    { lat: -2.11, lng: 103.099 },
    { lat: -2.331, lng: 103.54 },
    { lat: -2.458, lng: 103.765 },
    { lat: -2.568, lng: 103.986 },
    { lat: -2.797, lng: 104.428 },
    { lat: -2.806, lng: 104.445 },
    { lat: -3.019, lng: 104.866 },
    { lat: -3.153, lng: 105.136 },
    { lat: -3.229, lng: 105.301 },
    { lat: -3.437, lng: 105.732 },
    { lat: -3.5, lng: 105.852 },
    { lat: -3.654, lng: 106.159 },
    { lat: -3.846, lng: 106.513 },
    { lat: -3.882, lng: 106.583 },
    { lat: -4.115, lng: 107.003 },
    { lat: -4.192, lng: 107.138 },
    { lat: -4.335, lng: 107.42 },
    { lat: -4.536, lng: 107.834 },
    { lat: -4.537, lng: 107.835 },
    { lat: -4.712, lng: 108.248 },
    { lat: -4.877, lng: 108.66 },
    { lat: -4.879, lng: 108.667 },
    { lat: -5.035, lng: 109.069 },
    { lat: -5.188, lng: 109.476 },
    { lat: -5.221, lng: 109.571 },
    { lat: -5.342, lng: 109.88 },
    { lat: -5.483, lng: 110.283 },
    { lat: -5.562, lng: 110.557 },
    { lat: -5.602, lng: 110.685 },
    { lat: -5.689, lng: 111.086 },
    { lat: -5.74, lng: 111.487 },
    { lat: -5.76, lng: 111.889 },
    { lat: -5.759, lng: 112.289 },
    { lat: -5.752, lng: 112.687 },
    { lat: -5.753, lng: 113.083 },
    { lat: -5.772, lng: 113.476 },
    { lat: -5.816, lng: 113.866 },
    { lat: -5.879, lng: 114.2 },
    { lat: -5.889, lng: 114.253 },
    { lat: -5.995, lng: 114.636 },
    { lat: -6.124, lng: 115.016 },
    { lat: -6.218, lng: 115.256 },
    { lat: -6.273, lng: 115.393 },
    { lat: -6.423, lng: 115.769 },
    { lat: -6.553, lng: 116.144 },
    { lat: -6.557, lng: 116.161 },
    { lat: -6.652, lng: 116.519 },
    { lat: -6.712, lng: 116.896 },
    { lat: -6.737, lng: 117.272 },
    { lat: -6.733, lng: 117.649 },
    { lat: -6.71, lng: 118.025 },
    { lat: -6.678, lng: 118.401 },
    { lat: -6.649, lng: 118.774 },
    { lat: -6.634, lng: 119.146 },
    { lat: -6.637, lng: 119.515 },
    { lat: -6.656, lng: 119.883 },
    { lat: -6.693, lng: 120.248 },
    { lat: -6.747, lng: 120.612 },
    { lat: -6.823, lng: 120.974 },
    { lat: -6.864, lng: 121.119 },
    { lat: -6.924, lng: 121.333 },
    { lat: -7.056, lng: 121.691 },
    { lat: -7.204, lng: 122.003 },
    { lat: -7.224, lng: 122.045 },
    { lat: -7.416, lng: 122.398 },
    { lat: -7.546, lng: 122.612 },
    { lat: -7.634, lng: 122.75 },
    { lat: -7.876, lng: 123.099 },
    { lat: -7.888, lng: 123.116 },
    { lat: -8.171, lng: 123.445 },
    { lat: -8.232, lng: 123.511 },
    { lat: -8.575, lng: 123.786 },
    { lat: -8.576, lng: 123.787 },
    { lat: -8.923, lng: 123.933 },
    { lat: -9.27, lng: 123.929 },
    { lat: -9.62, lng: 123.759 },
    { lat: -9.646, lng: 123.734 },
    { lat: -9.972, lng: 123.42 },
    { lat: -10.018, lng: 123.355 },
    { lat: -10.279, lng: 122.978 },
    { lat: -10.327, lng: 122.907 },
    { lat: -10.485, lng: 122.603 },
    { lat: -10.659, lng: 122.228 },
    { lat: -10.685, lng: 122.159 },
    { lat: -10.784, lng: 121.855 },
    { lat: -10.866, lng: 121.482 },
    { lat: -10.902, lng: 121.112 },
    { lat: -10.889, lng: 120.744 },
    { lat: -10.827, lng: 120.378 },
    { lat: -10.719, lng: 120.014 },
    { lat: -10.709, lng: 119.989 },
    { lat: -10.565, lng: 119.653 },
    { lat: -10.387, lng: 119.293 },
    { lat: -10.366, lng: 119.253 },
    { lat: -10.185, lng: 118.933 },
    { lat: -10.022, lng: 118.634 },
    { lat: -9.983, lng: 118.572 },
    { lat: -9.779, lng: 118.211 },
    { lat: -9.679, lng: 117.997 },
    { lat: -9.594, lng: 117.847 },
    { lat: -9.451, lng: 117.479 },
    { lat: -9.376, lng: 117.105 },
    { lat: -9.361, lng: 116.724 },
    { lat: -9.389, lng: 116.339 },
    { lat: -9.44, lng: 115.95 },
    { lat: -9.495, lng: 115.559 },
    { lat: -9.536, lng: 115.168 },
    { lat: -9.548, lng: 114.777 },
    { lat: -9.52, lng: 114.388 },
    { lat: -9.453, lng: 114.0 },
    { lat: -9.382, lng: 113.714 },
    { lat: -9.353, lng: 113.614 },
    { lat: -9.226, lng: 113.228 },
    { lat: -9.105, lng: 112.84 },
    { lat: -9.044, lng: 112.628 },
    { lat: -8.986, lng: 112.45 },
    { lat: -8.875, lng: 112.058 },
    { lat: -8.789, lng: 111.661 },
    { lat: -8.727, lng: 111.261 },
    { lat: -8.712, lng: 111.125 },
    { lat: -8.677, lng: 110.856 },
    { lat: -8.646, lng: 110.448 },
    { lat: -8.63, lng: 110.036 },
    { lat: -8.62, lng: 109.622 },
    { lat: -8.606, lng: 109.205 },
    { lat: -8.583, lng: 108.786 },
    { lat: -8.546, lng: 108.367 },
    { lat: -8.49, lng: 107.946 },
    { lat: -8.412, lng: 107.525 },
    { lat: -8.411, lng: 107.519 },
    { lat: -8.295, lng: 107.106 },
    { lat: -8.155, lng: 106.686 },
    { lat: -8.072, lng: 106.475 },
    { lat: -7.975, lng: 106.268 },
    { lat: -7.752, lng: 105.85 },
    { lat: -7.727, lng: 105.809 },
    { lat: -7.451, lng: 105.438 },
    { lat: -7.379, lng: 105.353 },
    { lat: -7.044, lng: 105.033 },
    { lat: -7.029, lng: 105.02 },
    { lat: -6.679, lng: 104.761 },
    { lat: -6.493, lng: 104.636 },
    { lat: -6.328, lng: 104.528 },
    { lat: -5.977, lng: 104.304 },
    { lat: -5.872, lng: 104.239 },
    { lat: -5.626, lng: 104.088 },
    { lat: -5.275, lng: 103.863 },
    { lat: -5.234, lng: 103.836 },
    { lat: -4.924, lng: 103.64 },
    { lat: -4.615, lng: 103.424 },
    { lat: -4.574, lng: 103.395 },
    { lat: -4.223, lng: 103.165 },
    { lat: -3.988, lng: 103.004 },
    { lat: -3.872, lng: 102.922 },
    { lat: -3.521, lng: 102.674 },
    { lat: -3.384, lng: 102.574 },
    { lat: -3.17, lng: 102.42 },
    { lat: -2.819, lng: 102.182 },
    { lat: -2.713, lng: 102.139 },
    { lat: -2.467, lng: 102.051 },
    { lat: -2.114, lng: 102.053 },
    { lat: -1.922, lng: 102.167 },
    { lat: -1.924, lng: 102.633 },
    { lat: -2.103, lng: 103.089 },
    { lat: -2.11, lng: 103.099 },
  ],
  c9: [
    { lat: -0.345, lng: 114.748 },
    { lat: -0.384, lng: 114.796 },
    { lat: -0.689, lng: 115.102 },
    { lat: -1.033, lng: 115.163 },
    { lat: -1.345, lng: 114.788 },
    { lat: -1.379, lng: 114.701 },
    { lat: -1.438, lng: 114.401 },
    { lat: -1.456, lng: 114.014 },
    { lat: -1.414, lng: 113.625 },
    { lat: -1.381, lng: 113.503 },
    { lat: -1.28, lng: 113.235 },
    { lat: -1.051, lng: 112.844 },
    { lat: -1.037, lng: 112.826 },
    { lat: -0.691, lng: 112.613 },
    { lat: -0.346, lng: 112.711 },
    { lat: -0.257, lng: 112.849 },
    { lat: -0.119, lng: 113.243 },
    { lat: -0.075, lng: 113.634 },
    { lat: -0.105, lng: 114.023 },
    { lat: -0.204, lng: 114.411 },
    { lat: -0.345, lng: 114.748 },
  ],
  c10: [
    { lat: 4.98, lng: 98.088 },
    { lat: 4.797, lng: 98.607 },
    { lat: 4.613, lng: 99.103 },
    { lat: 4.605, lng: 99.125 },
    { lat: 4.375, lng: 99.64 },
    { lat: 4.25, lng: 99.908 },
    { lat: 4.13, lng: 100.148 },
    { lat: 3.89, lng: 100.591 },
    { lat: 3.851, lng: 100.653 },
    { lat: 3.532, lng: 101.106 },
    { lat: 3.49, lng: 101.155 },
    { lat: 3.176, lng: 101.482 },
    { lat: 2.976, lng: 101.657 },
    { lat: 2.821, lng: 101.786 },
    { lat: 2.467, lng: 102.04 },
    { lat: 2.272, lng: 102.156 },
    { lat: 2.113, lng: 102.253 },
    { lat: 1.76, lng: 102.401 },
    { lat: 1.408, lng: 102.477 },
    { lat: 1.056, lng: 102.461 },
    { lat: 0.704, lng: 102.306 },
    { lat: 0.57, lng: 102.193 },
    { lat: 0.352, lng: 101.801 },
    { lat: 0.315, lng: 101.725 },
    { lat: 0.282, lng: 101.251 },
    { lat: 0.353, lng: 101.021 },
    { lat: 0.407, lng: 100.772 },
    { lat: 0.622, lng: 100.287 },
    { lat: 0.707, lng: 100.157 },
    { lat: 0.886, lng: 99.795 },
    { lat: 1.062, lng: 99.532 },
    { lat: 1.193, lng: 99.296 },
    { lat: 1.418, lng: 98.988 },
    { lat: 1.553, lng: 98.789 },
    { lat: 1.774, lng: 98.531 },
    { lat: 1.993, lng: 98.27 },
    { lat: 2.131, lng: 98.132 },
    { lat: 2.489, lng: 97.747 },
    { lat: 2.495, lng: 97.739 },
    { lat: 2.847, lng: 97.379 },
    { lat: 3.019, lng: 97.195 },
    { lat: 3.206, lng: 97.003 },
    { lat: 3.558, lng: 96.637 },
    { lat: 3.565, lng: 96.63 },
    { lat: 3.926, lng: 96.3 },
    { lat: 4.208, lng: 96.057 },
    { lat: 4.286, lng: 95.99 },
    { lat: 4.646, lng: 95.798 },
    { lat: 5.005, lng: 95.822 },
    { lat: 5.123, lng: 95.973 },
    { lat: 5.238, lng: 96.496 },
    { lat: 5.206, lng: 97.026 },
    { lat: 5.112, lng: 97.556 },
    { lat: 4.982, lng: 98.082 },
    { lat: 4.98, lng: 98.088 },
  ],
  c11: [
    { lat: -14.932, lng: 123.217 },
    { lat: -14.973, lng: 123.383 },
    { lat: -15.075, lng: 123.744 },
    { lat: -15.178, lng: 124.104 },
    { lat: -15.276, lng: 124.441 },
    { lat: -15.283, lng: 124.463 },
    { lat: -15.406, lng: 124.82 },
    { lat: -15.55, lng: 125.175 },
    { lat: -15.626, lng: 125.338 },
    { lat: -15.748, lng: 125.526 },
    { lat: -15.981, lng: 125.85 },
    { lat: -16.005, lng: 125.873 },
    { lat: -16.328, lng: 126.215 },
    { lat: -16.339, lng: 126.229 },
    { lat: -16.699, lng: 126.52 },
    { lat: -16.812, lng: 126.546 },
    { lat: -17.062, lng: 126.613 },
    { lat: -17.334, lng: 126.508 },
    { lat: -17.429, lng: 126.476 },
    { lat: -17.799, lng: 126.183 },
    { lat: -17.873, lng: 126.099 },
    { lat: -18.173, lng: 125.791 },
    { lat: -18.246, lng: 125.698 },
    { lat: -18.548, lng: 125.336 },
    { lat: -18.576, lng: 125.299 },
    { lat: -18.864, lng: 124.899 },
    { lat: -18.927, lng: 124.796 },
    { lat: -19.103, lng: 124.502 },
    { lat: -19.27, lng: 124.109 },
    { lat: -19.313, lng: 123.909 },
    { lat: -19.358, lng: 123.723 },
    { lat: -19.353, lng: 123.344 },
    { lat: -19.325, lng: 123.24 },
    { lat: -19.268, lng: 122.974 },
    { lat: -19.113, lng: 122.611 },
    { lat: -18.967, lng: 122.399 },
    { lat: -18.877, lng: 122.257 },
    { lat: -18.604, lng: 121.969 },
    { lat: -18.545, lng: 121.913 },
    { lat: -18.238, lng: 121.693 },
    { lat: -18.064, lng: 121.586 },
    { lat: -17.873, lng: 121.486 },
    { lat: -17.507, lng: 121.317 },
    { lat: -17.408, lng: 121.279 },
    { lat: -17.143, lng: 121.178 },
    { lat: -16.778, lng: 121.092 },
    { lat: -16.413, lng: 121.073 },
    { lat: -16.049, lng: 121.127 },
    { lat: -15.684, lng: 121.246 },
    { lat: -15.319, lng: 121.42 },
    { lat: -15.145, lng: 121.509 },
    { lat: -14.954, lng: 121.694 },
    { lat: -14.736, lng: 121.921 },
    { lat: -14.717, lng: 122.294 },
    { lat: -14.782, lng: 122.66 },
    { lat: -14.874, lng: 123.022 },
    { lat: -14.932, lng: 123.217 },
  ],
  c12: [
    { lat: -18.493, lng: 99.68 },
    { lat: -18.561, lng: 99.882 },
    { lat: -18.821, lng: 100.314 },
    { lat: -18.849, lng: 100.348 },
    { lat: -19.221, lng: 100.623 },
    { lat: -19.432, lng: 100.629 },
    { lat: -19.605, lng: 100.625 },
    { lat: -19.689, lng: 100.544 },
    { lat: -19.921, lng: 99.949 },
    { lat: -19.813, lng: 99.464 },
    { lat: -19.66, lng: 99.242 },
    { lat: -19.452, lng: 99.063 },
    { lat: -19.285, lng: 98.991 },
    { lat: -18.903, lng: 98.956 },
    { lat: -18.518, lng: 99.023 },
    { lat: -18.354, lng: 99.43 },
    { lat: -18.493, lng: 99.68 },
  ],
  c13: [
    { lat: -13.291, lng: 98.546 },
    { lat: -13.326, lng: 98.765 },
    { lat: -13.453, lng: 99.248 },
    { lat: -13.61, lng: 99.719 },
    { lat: -13.625, lng: 99.769 },
    { lat: -13.782, lng: 100.183 },
    { lat: -13.972, lng: 100.531 },
    { lat: -14.052, lng: 100.619 },
    { lat: -14.333, lng: 100.801 },
    { lat: -14.7, lng: 100.887 },
    { lat: -15.072, lng: 100.841 },
    { lat: -15.448, lng: 100.666 },
    { lat: -15.829, lng: 100.407 },
    { lat: -16.212, lng: 100.129 },
    { lat: -16.355, lng: 100.03 },
    { lat: -16.596, lng: 99.862 },
    { lat: -16.98, lng: 99.611 },
    { lat: -17.366, lng: 99.387 },
    { lat: -17.751, lng: 99.193 },
    { lat: -18.138, lng: 99.017 },
    { lat: -18.22, lng: 98.95 },
    { lat: -18.141, lng: 98.935 },
    { lat: -18.006, lng: 98.492 },
    { lat: -17.795, lng: 98.028 },
    { lat: -17.795, lng: 98.028 },
    { lat: -17.534, lng: 97.577 },
    { lat: -17.436, lng: 97.459 },
    { lat: -17.202, lng: 97.144 },
    { lat: -17.073, lng: 97.023 },
    { lat: -16.779, lng: 96.736 },
    { lat: -16.707, lng: 96.684 },
    { lat: -16.339, lng: 96.422 },
    { lat: -16.245, lng: 96.358 },
    { lat: -15.969, lng: 96.217 },
    { lat: -15.599, lng: 96.046 },
    { lat: -15.539, lng: 96.028 },
    { lat: -15.228, lng: 95.953 },
    { lat: -14.855, lng: 95.889 },
    { lat: -14.484, lng: 95.841 },
    { lat: -14.261, lng: 95.854 },
    { lat: -14.11, lng: 95.859 },
    { lat: -13.941, lng: 95.944 },
    { lat: -13.732, lng: 96.093 },
    { lat: -13.469, lng: 96.617 },
    { lat: -13.331, lng: 97.117 },
    { lat: -13.311, lng: 97.195 },
    { lat: -13.232, lng: 97.745 },
    { lat: -13.24, lng: 98.267 },
    { lat: -13.291, lng: 98.546 },
  ],
  c14: [
    { lat: -12.935, lng: 85.643 },
    { lat: -13.125, lng: 86.204 },
    { lat: -13.281, lng: 86.627 },
    { lat: -13.353, lng: 86.835 },
    { lat: -13.589, lng: 87.449 },
    { lat: -13.628, lng: 87.54 },
    { lat: -13.846, lng: 88.043 },
    { lat: -13.98, lng: 88.329 },
    { lat: -14.128, lng: 88.615 },
    { lat: -14.336, lng: 88.969 },
    { lat: -14.468, lng: 89.156 },
    { lat: -14.698, lng: 89.435 },
    { lat: -14.914, lng: 89.645 },
    { lat: -15.064, lng: 89.773 },
    { lat: -15.435, lng: 90.015 },
    { lat: -15.534, lng: 90.058 },
    { lat: -15.81, lng: 90.157 },
    { lat: -16.188, lng: 90.22 },
    { lat: -16.572, lng: 90.173 },
    { lat: -16.957, lng: 89.467 },
    { lat: -16.859, lng: 88.84 },
    { lat: -16.697, lng: 88.23 },
    { lat: -16.653, lng: 88.116 },
    { lat: -16.486, lng: 87.632 },
    { lat: -16.308, lng: 87.149 },
    { lat: -16.263, lng: 87.027 },
    { lat: -16.001, lng: 86.428 },
    { lat: -15.956, lng: 86.339 },
    { lat: -15.672, lng: 85.847 },
    { lat: -15.595, lng: 85.732 },
    { lat: -15.258, lng: 85.294 },
    { lat: -15.23, lng: 85.262 },
    { lat: -14.861, lng: 84.903 },
    { lat: -14.725, lng: 84.781 },
    { lat: -14.49, lng: 84.576 },
    { lat: -14.152, lng: 84.271 },
    { lat: -14.121, lng: 84.241 },
    { lat: -13.749, lng: 83.963 },
    { lat: -13.505, lng: 83.775 },
    { lat: -13.379, lng: 83.667 },
    { lat: -13.004, lng: 83.507 },
    { lat: -12.665, lng: 84.132 },
    { lat: -12.757, lng: 84.864 },
    { lat: -12.905, lng: 85.556 },
    { lat: -12.935, lng: 85.643 },
  ],
  c15: [
    { lat: -13.767, lng: 134.979 },
    { lat: -13.866, lng: 135.211 },
    { lat: -14.014, lng: 135.558 },
    { lat: -14.12, lng: 135.801 },
    { lat: -14.264, lng: 135.904 },
    { lat: -14.476, lng: 136.004 },
    { lat: -14.719, lng: 135.899 },
    { lat: -14.833, lng: 135.836 },
    { lat: -15.045, lng: 135.544 },
    { lat: -15.192, lng: 135.292 },
    { lat: -15.233, lng: 135.19 },
    { lat: -15.36, lng: 134.836 },
    { lat: -15.487, lng: 134.482 },
    { lat: -15.555, lng: 134.292 },
    { lat: -15.604, lng: 134.126 },
    { lat: -15.701, lng: 133.771 },
    { lat: -15.767, lng: 133.416 },
    { lat: -15.785, lng: 133.061 },
    { lat: -15.737, lng: 132.708 },
    { lat: -15.61, lng: 132.358 },
    { lat: -15.564, lng: 132.274 },
    { lat: -15.414, lng: 132.01 },
    { lat: -15.227, lng: 131.662 },
    { lat: -15.208, lng: 131.619 },
    { lat: -15.074, lng: 131.313 },
    { lat: -14.966, lng: 130.963 },
    { lat: -14.872, lng: 130.612 },
    { lat: -14.857, lng: 130.559 },
    { lat: -14.716, lng: 130.263 },
    { lat: -14.503, lng: 129.971 },
    { lat: -14.18, lng: 129.931 },
    { lat: -14.147, lng: 129.928 },
    { lat: -14.142, lng: 129.932 },
    { lat: -13.789, lng: 130.254 },
    { lat: -13.764, lng: 130.301 },
    { lat: -13.61, lng: 130.66 },
    { lat: -13.505, lng: 131.016 },
    { lat: -13.456, lng: 131.369 },
    { lat: -13.456, lng: 131.72 },
    { lat: -13.489, lng: 132.07 },
    { lat: -13.532, lng: 132.42 },
    { lat: -13.568, lng: 132.769 },
    { lat: -13.591, lng: 133.119 },
    { lat: -13.604, lng: 133.468 },
    { lat: -13.614, lng: 133.818 },
    { lat: -13.634, lng: 134.167 },
    { lat: -13.674, lng: 134.515 },
    { lat: -13.739, lng: 134.863 },
    { lat: -13.767, lng: 134.979 },
  ],
  c16: [
    { lat: 9.024, lng: 93.244 },
    { lat: 8.98, lng: 93.751 },
    { lat: 8.92, lng: 94.331 },
    { lat: 8.847, lng: 94.904 },
    { lat: 8.756, lng: 95.472 },
    { lat: 8.639, lng: 96.037 },
    { lat: 8.602, lng: 96.185 },
    { lat: 8.5, lng: 96.596 },
    { lat: 8.329, lng: 97.153 },
    { lat: 8.217, lng: 97.454 },
    { lat: 8.127, lng: 97.706 },
    { lat: 7.903, lng: 98.255 },
    { lat: 7.841, lng: 98.393 },
    { lat: 7.675, lng: 98.795 },
    { lat: 7.468, lng: 99.262 },
    { lat: 7.44, lng: 99.329 },
    { lat: 7.213, lng: 99.855 },
    { lat: 7.097, lng: 100.109 },
    { lat: 6.98, lng: 100.374 },
    { lat: 6.748, lng: 100.885 },
    { lat: 6.729, lng: 100.932 },
    { lat: 6.546, lng: 101.388 },
    { lat: 6.373, lng: 101.881 },
    { lat: 6.36, lng: 101.941 },
    { lat: 6.263, lng: 102.363 },
    { lat: 6.215, lng: 102.834 },
    { lat: 6.252, lng: 103.293 },
    { lat: 6.339, lng: 103.593 },
    { lat: 6.392, lng: 103.739 },
    { lat: 6.574, lng: 104.177 },
    { lat: 6.682, lng: 104.508 },
    { lat: 6.713, lng: 104.616 },
    { lat: 6.761, lng: 105.06 },
    { lat: 6.738, lng: 105.507 },
    { lat: 6.664, lng: 105.944 },
    { lat: 6.662, lng: 105.955 },
    { lat: 6.532, lng: 106.404 },
    { lat: 6.382, lng: 106.851 },
    { lat: 6.298, lng: 107.08 },
    { lat: 6.192, lng: 107.297 },
    { lat: 5.95, lng: 107.744 },
    { lat: 5.939, lng: 107.763 },
    { lat: 5.585, lng: 108.107 },
    { lat: 5.234, lng: 108.186 },
    { lat: 4.884, lng: 108.073 },
    { lat: 4.536, lng: 107.855 },
    { lat: 4.501, lng: 107.837 },
    { lat: 4.188, lng: 107.641 },
    { lat: 3.838, lng: 107.709 },
    { lat: 3.758, lng: 107.874 },
    { lat: 3.57, lng: 108.305 },
    { lat: 3.483, lng: 108.535 },
    { lat: 3.445, lng: 108.73 },
    { lat: 3.366, lng: 109.15 },
    { lat: 3.292, lng: 109.567 },
    { lat: 3.223, lng: 109.981 },
    { lat: 3.163, lng: 110.393 },
    { lat: 3.123, lng: 110.772 },
    { lat: 3.12, lng: 110.801 },
    { lat: 3.111, lng: 111.206 },
    { lat: 3.12, lng: 111.347 },
    { lat: 3.151, lng: 111.607 },
    { lat: 3.3, lng: 112.002 },
    { lat: 3.462, lng: 112.225 },
    { lat: 3.809, lng: 112.311 },
    { lat: 4.156, lng: 112.235 },
    { lat: 4.505, lng: 112.113 },
    { lat: 4.853, lng: 112.078 },
    { lat: 5.112, lng: 112.324 },
    { lat: 5.197, lng: 112.49 },
    { lat: 5.252, lng: 112.714 },
    { lat: 5.298, lng: 113.108 },
    { lat: 5.3, lng: 113.501 },
    { lat: 5.266, lng: 113.895 },
    { lat: 5.202, lng: 114.287 },
    { lat: 5.183, lng: 114.373 },
    { lat: 5.124, lng: 114.679 },
    { lat: 5.042, lng: 115.069 },
    { lat: 4.955, lng: 115.457 },
    { lat: 4.859, lng: 115.843 },
    { lat: 4.826, lng: 115.959 },
    { lat: 4.768, lng: 116.228 },
    { lat: 4.677, lng: 116.611 },
    { lat: 4.575, lng: 116.992 },
    { lat: 4.472, lng: 117.346 },
    { lat: 4.466, lng: 117.372 },
    { lat: 4.388, lng: 117.749 },
    { lat: 4.328, lng: 118.124 },
    { lat: 4.294, lng: 118.497 },
    { lat: 4.287, lng: 118.867 },
    { lat: 4.301, lng: 119.236 },
    { lat: 4.328, lng: 119.603 },
    { lat: 4.358, lng: 119.968 },
    { lat: 4.382, lng: 120.333 },
    { lat: 4.398, lng: 120.697 },
    { lat: 4.405, lng: 121.059 },
    { lat: 4.407, lng: 121.421 },
    { lat: 4.407, lng: 121.782 },
    { lat: 4.409, lng: 122.142 },
    { lat: 4.419, lng: 122.5 },
    { lat: 4.44, lng: 122.858 },
    { lat: 4.445, lng: 122.898 },
    { lat: 4.49, lng: 123.213 },
    { lat: 4.573, lng: 123.567 },
    { lat: 4.691, lng: 123.919 },
    { lat: 4.782, lng: 124.134 },
    { lat: 4.902, lng: 124.269 },
    { lat: 5.124, lng: 124.507 },
    { lat: 5.453, lng: 124.608 },
    { lat: 5.466, lng: 124.613 },
    { lat: 5.595, lng: 124.605 },
    { lat: 5.81, lng: 124.589 },
    { lat: 6.154, lng: 124.567 },
    { lat: 6.196, lng: 124.587 },
    { lat: 6.496, lng: 124.827 },
    { lat: 6.537, lng: 124.931 },
    { lat: 6.632, lng: 125.281 },
    { lat: 6.689, lng: 125.632 },
    { lat: 6.722, lng: 125.983 },
    { lat: 6.736, lng: 126.334 },
    { lat: 6.734, lng: 126.684 },
    { lat: 6.713, lng: 127.034 },
    { lat: 6.668, lng: 127.385 },
    { lat: 6.593, lng: 127.735 },
    { lat: 6.485, lng: 128.086 },
    { lat: 6.481, lng: 128.095 },
    { lat: 6.324, lng: 128.436 },
    { lat: 6.148, lng: 128.786 },
    { lat: 6.135, lng: 128.811 },
    { lat: 5.938, lng: 129.136 },
    { lat: 5.79, lng: 129.4 },
    { lat: 5.73, lng: 129.485 },
    { lat: 5.48, lng: 129.833 },
    { lat: 5.447, lng: 129.876 },
    { lat: 5.104, lng: 130.181 },
    { lat: 5.102, lng: 130.183 },
    { lat: 4.762, lng: 130.405 },
    { lat: 4.6, lng: 130.532 },
    { lat: 4.42, lng: 130.657 },
    { lat: 4.216, lng: 130.879 },
    { lat: 4.079, lng: 131.023 },
    { lat: 3.956, lng: 131.223 },
    { lat: 3.756, lng: 131.566 },
    { lat: 3.737, lng: 131.603 },
    { lat: 3.621, lng: 131.908 },
    { lat: 3.509, lng: 132.249 },
    { lat: 3.41, lng: 132.589 },
    { lat: 3.395, lng: 132.663 },
    { lat: 3.353, lng: 132.93 },
    { lat: 3.328, lng: 133.269 },
    { lat: 3.332, lng: 133.608 },
    { lat: 3.361, lng: 133.946 },
    { lat: 3.394, lng: 134.263 },
    { lat: 3.397, lng: 134.285 },
    { lat: 3.427, lng: 134.623 },
    { lat: 3.43, lng: 134.961 },
    { lat: 3.41, lng: 135.299 },
    { lat: 3.392, lng: 135.447 },
    { lat: 3.373, lng: 135.638 },
    { lat: 3.325, lng: 135.975 },
    { lat: 3.269, lng: 136.313 },
    { lat: 3.22, lng: 136.651 },
    { lat: 3.213, lng: 136.988 },
    { lat: 3.282, lng: 137.325 },
    { lat: 3.392, lng: 137.567 },
    { lat: 3.443, lng: 137.663 },
    { lat: 3.635, lng: 138.0 },
    { lat: 3.732, lng: 138.242 },
    { lat: 3.765, lng: 138.338 },
    { lat: 3.811, lng: 138.675 },
    { lat: 3.789, lng: 139.013 },
    { lat: 3.732, lng: 139.302 },
    { lat: 3.723, lng: 139.35 },
    { lat: 3.634, lng: 139.688 },
    { lat: 3.559, lng: 140.025 },
    { lat: 3.522, lng: 140.363 },
    { lat: 3.531, lng: 140.701 },
    { lat: 3.587, lng: 141.039 },
    { lat: 3.68, lng: 141.377 },
    { lat: 3.733, lng: 141.552 },
    { lat: 3.784, lng: 141.717 },
    { lat: 3.869, lng: 142.056 },
    { lat: 3.919, lng: 142.395 },
    { lat: 3.929, lng: 142.734 },
    { lat: 3.899, lng: 143.074 },
    { lat: 3.827, lng: 143.413 },
    { lat: 3.736, lng: 143.666 },
    { lat: 3.705, lng: 143.752 },
    { lat: 3.532, lng: 144.091 },
    { lat: 3.396, lng: 144.282 },
    { lat: 3.278, lng: 144.43 },
    { lat: 3.057, lng: 144.659 },
    { lat: 2.927, lng: 144.769 },
    { lat: 2.717, lng: 144.94 },
    { lat: 2.437, lng: 145.106 },
    { lat: 2.377, lng: 145.144 },
    { lat: 2.038, lng: 145.285 },
    { lat: 1.698, lng: 145.344 },
    { lat: 1.358, lng: 145.323 },
    { lat: 1.018, lng: 145.218 },
    { lat: 0.811, lng: 145.099 },
    { lat: 0.679, lng: 145.002 },
    { lat: 0.466, lng: 144.758 },
    { lat: 0.339, lng: 144.476 },
    { lat: 0.319, lng: 144.418 },
    { lat: 0.278, lng: 144.078 },
    { lat: 0.289, lng: 143.738 },
    { lat: 0.333, lng: 143.399 },
    { lat: 0.339, lng: 143.362 },
    { lat: 0.39, lng: 143.06 },
    { lat: 0.443, lng: 142.722 },
    { lat: 0.44, lng: 142.383 },
    { lat: 0.339, lng: 142.216 },
    { lat: -0.0, lng: 142.231 },
    { lat: -0.232, lng: 142.383 },
    { lat: -0.339, lng: 142.445 },
    { lat: -0.663, lng: 142.722 },
    { lat: -0.678, lng: 142.733 },
    { lat: -1.018, lng: 143.037 },
    { lat: -1.046, lng: 143.061 },
    { lat: -1.357, lng: 143.323 },
    { lat: -1.463, lng: 143.401 },
    { lat: -1.697, lng: 143.586 },
    { lat: -1.93, lng: 143.742 },
    { lat: -2.036, lng: 143.825 },
    { lat: -2.376, lng: 144.045 },
    { lat: -2.452, lng: 144.084 },
    { lat: -2.716, lng: 144.24 },
    { lat: -3.057, lng: 144.389 },
    { lat: -3.168, lng: 144.429 },
    { lat: -3.397, lng: 144.524 },
    { lat: -3.737, lng: 144.66 },
    { lat: -3.994, lng: 144.777 },
    { lat: -4.078, lng: 144.847 },
    { lat: -4.333, lng: 145.123 },
    { lat: -4.42, lng: 145.386 },
    { lat: -4.446, lng: 145.466 },
    { lat: -4.528, lng: 145.81 },
    { lat: -4.678, lng: 146.155 },
    { lat: -4.764, lng: 146.28 },
    { lat: -4.927, lng: 146.502 },
    { lat: -5.107, lng: 146.705 },
    { lat: -5.243, lng: 146.851 },
    { lat: -5.449, lng: 147.039 },
    { lat: -5.657, lng: 147.204 },
    { lat: -5.793, lng: 147.289 },
    { lat: -6.136, lng: 147.457 },
    { lat: -6.46, lng: 147.567 },
    { lat: -6.48, lng: 147.573 },
    { lat: -6.823, lng: 147.63 },
    { lat: -7.167, lng: 147.645 },
    { lat: -7.511, lng: 147.612 },
    { lat: -7.586, lng: 147.594 },
    { lat: -7.856, lng: 147.515 },
    { lat: -8.2, lng: 147.342 },
    { lat: -8.305, lng: 147.266 },
    { lat: -8.544, lng: 147.065 },
    { lat: -8.676, lng: 146.928 },
    { lat: -8.888, lng: 146.706 },
    { lat: -8.991, lng: 146.59 },
    { lat: -9.233, lng: 146.345 },
    { lat: -9.325, lng: 146.251 },
    { lat: -9.578, lng: 146.021 },
    { lat: -9.695, lng: 145.914 },
    { lat: -9.924, lng: 145.71 },
    { lat: -10.065, lng: 145.577 },
    { lat: -10.271, lng: 145.364 },
    { lat: -10.38, lng: 145.237 },
    { lat: -10.617, lng: 144.902 },
    { lat: -10.62, lng: 144.896 },
    { lat: -10.797, lng: 144.554 },
    { lat: -10.94, lng: 144.21 },
    { lat: -10.963, lng: 144.157 },
    { lat: -11.073, lng: 143.866 },
    { lat: -11.207, lng: 143.522 },
    { lat: -11.309, lng: 143.277 },
    { lat: -11.347, lng: 143.179 },
    { lat: -11.489, lng: 142.835 },
    { lat: -11.618, lng: 142.491 },
    { lat: -11.656, lng: 142.373 },
    { lat: -11.731, lng: 142.146 },
    { lat: -11.813, lng: 141.801 },
    { lat: -11.849, lng: 141.455 },
    { lat: -11.826, lng: 141.109 },
    { lat: -11.73, lng: 140.762 },
    { lat: -11.651, lng: 140.589 },
    { lat: -11.59, lng: 140.415 },
    { lat: -11.46, lng: 140.069 },
    { lat: -11.341, lng: 139.723 },
    { lat: -11.3, lng: 139.591 },
    { lat: -11.244, lng: 139.378 },
    { lat: -11.171, lng: 139.033 },
    { lat: -11.126, lng: 138.688 },
    { lat: -11.112, lng: 138.344 },
    { lat: -11.119, lng: 138.0 },
    { lat: -11.126, lng: 137.656 },
    { lat: -11.114, lng: 137.312 },
    { lat: -11.073, lng: 136.968 },
    { lat: -11.007, lng: 136.624 },
    { lat: -10.95, lng: 136.324 },
    { lat: -10.942, lng: 136.28 },
    { lat: -10.911, lng: 135.936 },
    { lat: -10.931, lng: 135.591 },
    { lat: -10.951, lng: 135.487 },
    { lat: -10.997, lng: 135.246 },
    { lat: -11.086, lng: 134.9 },
    { lat: -11.176, lng: 134.554 },
    { lat: -11.247, lng: 134.208 },
    { lat: -11.29, lng: 133.861 },
    { lat: -11.306, lng: 133.55 },
    { lat: -11.307, lng: 133.515 },
    { lat: -11.31, lng: 133.169 },
    { lat: -11.311, lng: 132.822 },
    { lat: -11.324, lng: 132.475 },
    { lat: -11.355, lng: 132.127 },
    { lat: -11.399, lng: 131.778 },
    { lat: -11.447, lng: 131.429 },
    { lat: -11.485, lng: 131.08 },
    { lat: -11.503, lng: 130.73 },
    { lat: -11.502, lng: 130.381 },
    { lat: -11.489, lng: 130.031 },
    { lat: -11.47, lng: 129.682 },
    { lat: -11.455, lng: 129.332 },
    { lat: -11.449, lng: 128.981 },
    { lat: -11.454, lng: 128.629 },
    { lat: -11.475, lng: 128.276 },
    { lat: -11.515, lng: 127.921 },
    { lat: -11.579, lng: 127.564 },
    { lat: -11.667, lng: 127.206 },
    { lat: -11.692, lng: 127.123 },
    { lat: -11.777, lng: 126.846 },
    { lat: -11.902, lng: 126.483 },
    { lat: -12.03, lng: 126.12 },
    { lat: -12.053, lng: 126.053 },
    { lat: -12.163, lng: 125.755 },
    { lat: -12.286, lng: 125.389 },
    { lat: -12.397, lng: 125.022 },
    { lat: -12.416, lng: 124.957 },
    { lat: -12.514, lng: 124.654 },
    { lat: -12.636, lng: 124.285 },
    { lat: -12.773, lng: 123.913 },
    { lat: -12.782, lng: 123.892 },
    { lat: -12.956, lng: 123.536 },
    { lat: -13.145, lng: 123.193 },
    { lat: -13.173, lng: 123.155 },
    { lat: -13.456, lng: 122.766 },
    { lat: -13.507, lng: 122.688 },
    { lat: -13.803, lng: 122.37 },
    { lat: -13.869, lng: 122.27 },
    { lat: -14.171, lng: 121.97 },
    { lat: -14.234, lng: 121.807 },
    { lat: -14.365, lng: 121.581 },
    { lat: -14.352, lng: 121.208 },
    { lat: -14.308, lng: 120.838 },
    { lat: -14.265, lng: 120.466 },
    { lat: -14.255, lng: 120.386 },
    { lat: -14.228, lng: 120.093 },
    { lat: -14.192, lng: 119.719 },
    { lat: -14.149, lng: 119.344 },
    { lat: -14.094, lng: 118.969 },
    { lat: -14.018, lng: 118.595 },
    { lat: -13.929, lng: 118.282 },
    { lat: -13.913, lng: 118.223 },
    { lat: -13.778, lng: 117.853 },
    { lat: -13.616, lng: 117.486 },
    { lat: -13.585, lng: 117.422 },
    { lat: -13.444, lng: 117.118 },
    { lat: -13.289, lng: 116.747 },
    { lat: -13.241, lng: 116.6 },
    { lat: -13.171, lng: 116.372 },
    { lat: -13.097, lng: 115.99 },
    { lat: -13.058, lng: 115.602 },
    { lat: -13.037, lng: 115.211 },
    { lat: -13.014, lng: 114.819 },
    { lat: -12.974, lng: 114.427 },
    { lat: -12.926, lng: 114.132 },
    { lat: -12.912, lng: 114.036 },
    { lat: -12.844, lng: 113.644 },
    { lat: -12.77, lng: 113.251 },
    { lat: -12.682, lng: 112.858 },
    { lat: -12.596, lng: 112.596 },
    { lat: -12.561, lng: 112.468 },
    { lat: -12.387, lng: 112.082 },
    { lat: -12.251, lng: 111.935 },
    { lat: -12.099, lng: 111.709 },
    { lat: -11.9, lng: 111.518 },
    { lat: -11.774, lng: 111.339 },
    { lat: -11.551, lng: 111.073 },
    { lat: -11.486, lng: 110.963 },
    { lat: -11.265, lng: 110.576 },
    { lat: -11.205, lng: 110.451 },
    { lat: -11.104, lng: 110.18 },
    { lat: -11.001, lng: 109.775 },
    { lat: -10.958, lng: 109.36 },
    { lat: -10.995, lng: 108.932 },
    { lat: -11.179, lng: 108.481 },
    { lat: -11.244, lng: 108.391 },
    { lat: -11.605, lng: 108.252 },
    { lat: -11.963, lng: 108.328 },
    { lat: -12.038, lng: 108.361 },
    { lat: -12.319, lng: 108.5 },
    { lat: -12.675, lng: 108.692 },
    { lat: -12.686, lng: 108.695 },
    { lat: -13.033, lng: 108.83 },
    { lat: -13.395, lng: 108.839 },
    { lat: -13.762, lng: 108.646 },
    { lat: -13.885, lng: 108.504 },
    { lat: -14.136, lng: 108.201 },
    { lat: -14.25, lng: 108.007 },
    { lat: -14.48, lng: 107.527 },
    { lat: -14.521, lng: 107.392 },
    { lat: -14.611, lng: 107.061 },
    { lat: -14.646, lng: 106.609 },
    { lat: -14.591, lng: 106.171 },
    { lat: -14.555, lng: 106.059 },
    { lat: -14.462, lng: 105.745 },
    { lat: -14.29, lng: 105.324 },
    { lat: -14.213, lng: 105.154 },
    { lat: -14.101, lng: 104.903 },
    { lat: -13.922, lng: 104.478 },
    { lat: -13.868, lng: 104.328 },
    { lat: -13.763, lng: 104.046 },
    { lat: -13.632, lng: 103.606 },
    { lat: -13.532, lng: 103.204 },
    { lat: -13.519, lng: 103.158 },
    { lat: -13.434, lng: 102.701 },
    { lat: -13.344, lng: 102.241 },
    { lat: -13.201, lng: 101.851 },
    { lat: -13.173, lng: 101.794 },
    { lat: -12.846, lng: 101.439 },
    { lat: -12.773, lng: 101.391 },
    { lat: -12.486, lng: 101.25 },
    { lat: -12.124, lng: 101.155 },
    { lat: -11.784, lng: 101.1 },
    { lat: -11.761, lng: 101.096 },
    { lat: -11.4, lng: 101.003 },
    { lat: -11.039, lng: 100.912 },
    { lat: -10.682, lng: 100.813 },
    { lat: -10.679, lng: 100.812 },
    { lat: -10.321, lng: 100.656 },
    { lat: -9.963, lng: 100.498 },
    { lat: -9.886, lng: 100.457 },
    { lat: -9.607, lng: 100.291 },
    { lat: -9.25, lng: 100.106 },
    { lat: -9.191, lng: 100.075 },
    { lat: -8.894, lng: 99.871 },
    { lat: -8.581, lng: 99.671 },
    { lat: -8.539, lng: 99.624 },
    { lat: -8.186, lng: 99.241 },
    { lat: -8.176, lng: 99.23 },
    { lat: -8.034, lng: 98.746 },
    { lat: -8.2, lng: 98.474 },
    { lat: -8.562, lng: 98.36 },
    { lat: -8.922, lng: 98.378 },
    { lat: -9.283, lng: 98.409 },
    { lat: -9.644, lng: 98.422 },
    { lat: -10.006, lng: 98.414 },
    { lat: -10.255, lng: 98.383 },
    { lat: -10.37, lng: 98.37 },
    { lat: -10.437, lng: 98.35 },
    { lat: -10.737, lng: 98.235 },
    { lat: -11.111, lng: 97.818 },
    { lat: -11.178, lng: 97.684 },
    { lat: -11.213, lng: 97.15 },
    { lat: -11.135, lng: 96.8 },
    { lat: -11.111, lng: 96.638 },
    { lat: -10.957, lng: 96.131 },
    { lat: -10.794, lng: 95.771 },
    { lat: -10.739, lng: 95.633 },
    { lat: -10.465, lng: 95.139 },
    { lat: -10.444, lng: 95.11 },
    { lat: -10.137, lng: 94.651 },
    { lat: -10.09, lng: 94.595 },
    { lat: -9.775, lng: 94.162 },
    { lat: -9.736, lng: 94.116 },
    { lat: -9.397, lng: 93.669 },
    { lat: -9.38, lng: 93.649 },
    { lat: -9.025, lng: 93.208 },
    { lat: -8.995, lng: 93.172 },
    { lat: -8.668, lng: 92.793 },
    { lat: -8.561, lng: 92.671 },
    { lat: -8.311, lng: 92.397 },
    { lat: -8.096, lng: 92.167 },
    { lat: -7.954, lng: 92.011 },
    { lat: -7.616, lng: 91.654 },
    { lat: -7.596, lng: 91.631 },
    { lat: -7.238, lng: 91.235 },
    { lat: -7.139, lng: 91.129 },
    { lat: -6.881, lng: 90.812 },
    { lat: -6.689, lng: 90.588 },
    { lat: -6.523, lng: 90.363 },
    { lat: -6.261, lng: 90.029 },
    { lat: -6.166, lng: 89.89 },
    { lat: -5.861, lng: 89.454 },
    { lat: -5.809, lng: 89.371 },
    { lat: -5.498, lng: 88.86 },
    { lat: -5.452, lng: 88.778 },
    { lat: -5.15, lng: 88.249 },
    { lat: -5.095, lng: 88.149 },
    { lat: -4.762, lng: 87.63 },
    { lat: -4.737, lng: 87.59 },
    { lat: -4.376, lng: 87.176 },
    { lat: -4.188, lng: 87.016 },
    { lat: -4.013, lng: 86.874 },
    { lat: -3.65, lng: 86.662 },
    { lat: -3.285, lng: 86.529 },
    { lat: -2.92, lng: 86.514 },
    { lat: -2.553, lng: 86.694 },
    { lat: -2.259, lng: 87.19 },
    { lat: -2.217, lng: 87.872 },
    { lat: -2.319, lng: 88.531 },
    { lat: -2.485, lng: 89.172 },
    { lat: -2.537, lng: 89.321 },
    { lat: -2.686, lng: 89.797 },
    { lat: -2.893, lng: 90.382 },
    { lat: -2.902, lng: 90.409 },
    { lat: -3.124, lng: 91.009 },
    { lat: -3.247, lng: 91.378 },
    { lat: -3.327, lng: 91.6 },
    { lat: -3.472, lng: 92.184 },
    { lat: -3.513, lng: 92.768 },
    { lat: -3.298, lng: 93.361 },
    { lat: -3.232, lng: 93.424 },
    { lat: -2.871, lng: 93.527 },
    { lat: -2.513, lng: 93.452 },
    { lat: -2.446, lng: 93.414 },
    { lat: -2.154, lng: 93.247 },
    { lat: -1.796, lng: 92.968 },
    { lat: -1.695, lng: 92.873 },
    { lat: -1.438, lng: 92.614 },
    { lat: -1.16, lng: 92.306 },
    { lat: -1.08, lng: 92.206 },
    { lat: -0.72, lng: 91.759 },
    { lat: -0.687, lng: 91.723 },
    { lat: -0.361, lng: 91.316 },
    { lat: -0.163, lng: 91.125 },
    { lat: 0.0, lng: 90.947 },
    { lat: 0.361, lng: 90.646 },
    { lat: 0.549, lng: 90.508 },
    { lat: 0.723, lng: 90.364 },
    { lat: 1.085, lng: 90.096 },
    { lat: 1.447, lng: 89.949 },
    { lat: 1.669, lng: 90.478 },
    { lat: 1.674, lng: 91.092 },
    { lat: 1.682, lng: 91.696 },
    { lat: 1.768, lng: 92.286 },
    { lat: 1.799, lng: 92.356 },
    { lat: 2.157, lng: 92.766 },
    { lat: 2.517, lng: 92.663 },
    { lat: 2.879, lng: 92.381 },
    { lat: 3.027, lng: 92.217 },
    { lat: 3.242, lng: 91.966 },
    { lat: 3.516, lng: 91.585 },
    { lat: 3.608, lng: 91.448 },
    { lat: 3.918, lng: 90.943 },
    { lat: 3.975, lng: 90.844 },
    { lat: 4.317, lng: 90.284 },
    { lat: 4.343, lng: 90.239 },
    { lat: 4.712, lng: 89.689 },
    { lat: 4.78, lng: 89.6 },
    { lat: 5.082, lng: 89.191 },
    { lat: 5.334, lng: 88.883 },
    { lat: 5.453, lng: 88.734 },
    { lat: 5.824, lng: 88.308 },
    { lat: 6.011, lng: 88.12 },
    { lat: 6.195, lng: 87.927 },
    { lat: 6.567, lng: 87.579 },
    { lat: 6.907, lng: 87.283 },
    { lat: 6.94, lng: 87.251 },
    { lat: 7.313, lng: 86.955 },
    { lat: 7.686, lng: 86.74 },
    { lat: 8.057, lng: 86.615 },
    { lat: 8.428, lng: 86.577 },
    { lat: 8.794, lng: 86.764 },
    { lat: 8.841, lng: 86.853 },
    { lat: 9.054, lng: 87.494 },
    { lat: 9.135, lng: 88.08 },
    { lat: 9.141, lng: 88.152 },
    { lat: 9.179, lng: 88.808 },
    { lat: 9.193, lng: 89.458 },
    { lat: 9.19, lng: 90.098 },
    { lat: 9.174, lng: 90.73 },
    { lat: 9.149, lng: 91.352 },
    { lat: 9.117, lng: 91.965 },
    { lat: 9.078, lng: 92.569 },
    { lat: 9.031, lng: 93.164 },
    { lat: 9.024, lng: 93.244 },
  ],
  c17: [
    { lat: -6.26, lng: 83.771 },
    { lat: -6.445, lng: 84.488 },
    { lat: -6.61, lng: 84.966 },
    { lat: -6.693, lng: 85.186 },
    { lat: -6.966, lng: 85.766 },
    { lat: -7.009, lng: 85.852 },
    { lat: -7.324, lng: 86.369 },
    { lat: -7.4, lng: 86.485 },
    { lat: -7.683, lng: 86.856 },
    { lat: -7.875, lng: 87.082 },
    { lat: -8.044, lng: 87.264 },
    { lat: -8.406, lng: 87.622 },
    { lat: -8.438, lng: 87.643 },
    { lat: -8.77, lng: 87.856 },
    { lat: -9.136, lng: 88.006 },
    { lat: -9.309, lng: 87.429 },
    { lat: -9.2, lng: 86.761 },
    { lat: -9.167, lng: 86.652 },
    { lat: -8.992, lng: 86.104 },
    { lat: -8.82, lng: 85.569 },
    { lat: -8.776, lng: 85.434 },
    { lat: -8.526, lng: 84.756 },
    { lat: -8.469, lng: 84.616 },
    { lat: -8.232, lng: 84.071 },
    { lat: -8.114, lng: 83.82 },
    { lat: -7.872, lng: 83.384 },
    { lat: -7.754, lng: 83.189 },
    { lat: -7.403, lng: 82.702 },
    { lat: -7.392, lng: 82.687 },
    { lat: -7.026, lng: 82.366 },
    { lat: -6.657, lng: 82.182 },
    { lat: -6.278, lng: 82.635 },
    { lat: -6.21, lng: 82.978 },
    { lat: -6.257, lng: 83.758 },
    { lat: -6.26, lng: 83.771 },
  ],
  c18: [
    { lat: 9.677, lng: 80.901 },
    { lat: 9.595, lng: 81.203 },
    { lat: 9.278, lng: 81.834 },
    { lat: 8.91, lng: 81.516 },
    { lat: 8.89, lng: 81.441 },
    { lat: 8.659, lng: 80.645 },
    { lat: 8.563, lng: 80.252 },
    { lat: 8.513, lng: 79.791 },
    { lat: 8.492, lng: 78.859 },
    { lat: 8.566, lng: 77.85 },
    { lat: 8.625, lng: 77.486 },
    { lat: 8.966, lng: 76.656 },
    { lat: 9.026, lng: 76.551 },
    { lat: 9.251, lng: 76.535 },
    { lat: 9.406, lng: 76.557 },
    { lat: 9.732, lng: 77.38 },
    { lat: 9.761, lng: 77.567 },
    { lat: 9.814, lng: 78.353 },
    { lat: 9.815, lng: 79.315 },
    { lat: 9.759, lng: 80.259 },
    { lat: 9.677, lng: 80.901 },
  ],
  c19: [
    { lat: 19.665, lng: -141.394 },
    { lat: 19.566, lng: -144.068 },
    { lat: 19.215, lng: -146.793 },
    { lat: 18.894, lng: -149.158 },
    { lat: 18.604, lng: -150.842 },
    { lat: 18.306, lng: -153.243 },
    { lat: 18.274, lng: -153.465 },
    { lat: 18.051, lng: -155.196 },
    { lat: 17.724, lng: -156.565 },
    { lat: 17.615, lng: -157.095 },
    { lat: 17.245, lng: -158.314 },
    { lat: 17.093, lng: -158.857 },
    { lat: 16.783, lng: -159.788 },
    { lat: 16.565, lng: -160.448 },
    { lat: 16.33, lng: -161.171 },
    { lat: 16.093, lng: -161.861 },
    { lat: 15.875, lng: -162.686 },
    { lat: 15.762, lng: -163.081 },
    { lat: 15.652, lng: -164.097 },
    { lat: 15.675, lng: -164.991 },
    { lat: 15.592, lng: -165.912 },
    { lat: 15.365, lng: -166.876 },
    { lat: 15.314, lng: -167.047 },
    { lat: 15.082, lng: -167.83 },
    { lat: 14.872, lng: -168.596 },
    { lat: 14.833, lng: -168.731 },
    { lat: 14.718, lng: -169.541 },
    { lat: 14.77, lng: -170.256 },
    { lat: 14.801, lng: -170.546 },
    { lat: 14.843, lng: -170.945 },
    { lat: 14.784, lng: -171.673 },
    { lat: 14.754, lng: -171.833 },
    { lat: 14.654, lng: -172.413 },
    { lat: 14.56, lng: -173.121 },
    { lat: 14.618, lng: -173.757 },
    { lat: 14.682, lng: -173.883 },
    { lat: 14.999, lng: -174.262 },
    { lat: 15.046, lng: -174.299 },
    { lat: 15.417, lng: -174.5 },
    { lat: 15.756, lng: -174.615 },
    { lat: 15.792, lng: -174.638 },
    { lat: 16.166, lng: -174.818 },
    { lat: 16.543, lng: -174.918 },
    { lat: 16.598, lng: -174.918 },
    { lat: 16.926, lng: -174.898 },
    { lat: 17.316, lng: -174.736 },
    { lat: 17.712, lng: -174.449 },
    { lat: 17.716, lng: -174.445 },
    { lat: 18.12, lng: -173.934 },
    { lat: 18.386, lng: -173.48 },
    { lat: 18.541, lng: -173.164 },
    { lat: 18.744, lng: -172.626 },
    { lat: 18.981, lng: -172.027 },
    { lat: 19.071, lng: -171.759 },
    { lat: 19.425, lng: -170.9 },
    { lat: 19.451, lng: -170.832 },
    { lat: 19.867, lng: -169.916 },
    { lat: 19.909, lng: -169.82 },
    { lat: 20.309, lng: -168.978 },
    { lat: 20.438, lng: -168.716 },
    { lat: 20.756, lng: -168.018 },
    { lat: 20.99, lng: -167.534 },
    { lat: 21.211, lng: -166.972 },
    { lat: 21.481, lng: -166.328 },
    { lat: 21.686, lng: -165.644 },
    { lat: 21.838, lng: -165.158 },
    { lat: 22.099, lng: -164.004 },
    { lat: 22.205, lng: -163.594 },
    { lat: 22.42, lng: -162.726 },
    { lat: 22.717, lng: -161.813 },
    { lat: 22.905, lng: -161.19 },
    { lat: 23.219, lng: -160.29 },
    { lat: 23.528, lng: -159.346 },
    { lat: 23.73, lng: -158.724 },
    { lat: 24.207, lng: -157.188 },
    { lat: 24.256, lng: -157.003 },
    { lat: 24.817, lng: -154.845 },
    { lat: 24.845, lng: -154.73 },
    { lat: 25.342, lng: -152.013 },
    { lat: 25.613, lng: -149.249 },
    { lat: 25.634, lng: -149.026 },
    { lat: 25.749, lng: -147.229 },
    { lat: 25.949, lng: -144.148 },
    { lat: 25.987, lng: -143.694 },
    { lat: 25.97, lng: -141.844 },
  ],
  c20: [
    { lat: -7.556, lng: 121.235 },
    { lat: -7.578, lng: 121.304 },
    { lat: -7.732, lng: 121.66 },
    { lat: -7.897, lng: 121.97 },
    { lat: -7.924, lng: 122.014 },
    { lat: -8.167, lng: 122.365 },
    { lat: -8.24, lng: 122.46 },
    { lat: -8.492, lng: 122.711 },
    { lat: -8.584, lng: 122.795 },
    { lat: -8.93, lng: 122.975 },
    { lat: -9.278, lng: 122.989 },
    { lat: -9.628, lng: 122.821 },
    { lat: -9.805, lng: 122.643 },
    { lat: -9.981, lng: 122.445 },
    { lat: -10.09, lng: 122.263 },
    { lat: -10.269, lng: 121.887 },
    { lat: -10.339, lng: 121.669 },
    { lat: -10.378, lng: 121.514 },
    { lat: -10.418, lng: 121.144 },
    { lat: -10.4, lng: 120.777 },
    { lat: -10.351, lng: 120.534 },
    { lat: -10.322, lng: 120.413 },
    { lat: -10.184, lng: 120.052 },
    { lat: -10.011, lng: 119.692 },
    { lat: -10.01, lng: 119.69 },
    { lat: -9.782, lng: 119.335 },
    { lat: -9.665, lng: 119.156 },
    { lat: -9.519, lng: 118.98 },
    { lat: -9.32, lng: 118.726 },
    { lat: -9.208, lng: 118.627 },
    { lat: -8.974, lng: 118.38 },
    { lat: -8.809, lng: 118.278 },
    { lat: -8.628, lng: 118.108 },
    { lat: -8.28, lng: 117.985 },
    { lat: -7.929, lng: 118.214 },
    { lat: -7.872, lng: 118.336 },
    { lat: -7.682, lng: 118.721 },
    { lat: -7.575, lng: 118.985 },
    { lat: -7.542, lng: 119.1 },
    { lat: -7.454, lng: 119.475 },
    { lat: -7.401, lng: 119.847 },
    { lat: -7.385, lng: 120.216 },
    { lat: -7.407, lng: 120.582 },
    { lat: -7.47, lng: 120.944 },
    { lat: -7.556, lng: 121.235 },
  ],
  c21: [
    { lat: -7.266, lng: 114.807 },
    { lat: -7.329, lng: 114.944 },
    { lat: -7.504, lng: 115.319 },
    { lat: -7.607, lng: 115.572 },
    { lat: -7.696, lng: 115.692 },
    { lat: -7.925, lng: 116.061 },
    { lat: -7.951, lng: 116.109 },
    { lat: -8.301, lng: 116.036 },
    { lat: -8.301, lng: 116.035 },
    { lat: -8.454, lng: 115.64 },
    { lat: -8.562, lng: 115.245 },
    { lat: -8.596, lng: 114.854 },
    { lat: -8.558, lng: 114.467 },
    { lat: -8.475, lng: 114.082 },
    { lat: -8.373, lng: 113.696 },
    { lat: -8.329, lng: 113.55 },
    { lat: -8.223, lng: 113.312 },
    { lat: -8.061, lng: 112.928 },
    { lat: -7.988, lng: 112.735 },
    { lat: -7.83, lng: 112.547 },
    { lat: -7.644, lng: 112.23 },
    { lat: -7.292, lng: 112.396 },
    { lat: -7.232, lng: 112.592 },
    { lat: -7.118, lng: 112.998 },
    { lat: -7.051, lng: 113.398 },
    { lat: -7.043, lng: 113.792 },
    { lat: -7.093, lng: 114.181 },
    { lat: -7.187, lng: 114.565 },
    { lat: -7.266, lng: 114.807 },
  ],
  c22: [
    { lat: -5.243, lng: 107.217 },
    { lat: -5.285, lng: 107.363 },
    { lat: -5.427, lng: 107.78 },
    { lat: -5.578, lng: 108.195 },
    { lat: -5.584, lng: 108.21 },
    { lat: -5.766, lng: 108.604 },
    { lat: -5.927, lng: 108.941 },
    { lat: -5.967, lng: 109.009 },
    { lat: -6.194, lng: 109.41 },
    { lat: -6.272, lng: 109.558 },
    { lat: -6.441, lng: 109.807 },
    { lat: -6.616, lng: 110.118 },
    { lat: -6.692, lng: 110.202 },
    { lat: -6.954, lng: 110.592 },
    { lat: -6.961, lng: 110.608 },
    { lat: -7.308, lng: 110.923 },
    { lat: -7.627, lng: 110.539 },
    { lat: -7.665, lng: 110.493 },
    { lat: -7.755, lng: 110.116 },
    { lat: -7.843, lng: 109.694 },
    { lat: -7.901, lng: 109.272 },
    { lat: -7.921, lng: 108.85 },
    { lat: -7.902, lng: 108.43 },
    { lat: -7.845, lng: 108.01 },
    { lat: -7.754, lng: 107.591 },
    { lat: -7.704, lng: 107.427 },
    { lat: -7.608, lng: 107.175 },
    { lat: -7.416, lng: 106.76 },
    { lat: -7.361, lng: 106.661 },
    { lat: -7.137, lng: 106.35 },
    { lat: -7.014, lng: 106.201 },
    { lat: -6.734, lng: 105.949 },
    { lat: -6.665, lng: 105.893 },
    { lat: -6.314, lng: 105.688 },
    { lat: -6.037, lng: 105.567 },
    { lat: -5.963, lng: 105.536 },
    { lat: -5.611, lng: 105.465 },
    { lat: -5.259, lng: 105.501 },
    { lat: -5.094, lng: 105.637 },
    { lat: -5.007, lng: 106.082 },
    { lat: -5.067, lng: 106.514 },
    { lat: -5.164, lng: 106.941 },
    { lat: -5.243, lng: 107.217 },
  ],
  c23: [
    { lat: 3.544, lng: 99.51 },
    { lat: 3.45, lng: 99.701 },
    { lat: 3.185, lng: 100.107 },
    { lat: 3.076, lng: 100.212 },
    { lat: 2.829, lng: 100.396 },
    { lat: 2.474, lng: 100.47 },
    { lat: 2.253, lng: 100.248 },
    { lat: 2.289, lng: 99.758 },
    { lat: 2.48, lng: 99.314 },
    { lat: 2.51, lng: 99.254 },
    { lat: 2.838, lng: 98.825 },
    { lat: 2.921, lng: 98.735 },
    { lat: 3.195, lng: 98.495 },
    { lat: 3.552, lng: 98.329 },
    { lat: 3.741, lng: 98.687 },
    { lat: 3.656, lng: 99.193 },
    { lat: 3.544, lng: 99.51 },
  ],
};
export var apstar5_gntgain = {
  c1: "4",
  c2: "4",
  c3: "2",
  c4: "2",
  c5: "2",
  c6: "2",
  c7: "0",
  c8: "6",
  c9: "6",
  c10: "6",
  c11: "-6",
  c12: "-6",
  c13: "-6",
  c14: "-6",
  c15: "-6",
  c16: "-6",
  c17: "-6",
  c18: "-6",
  c19: "-6",
  c20: "7",
  c21: "7",
  c22: "7",
  c23: "7",
};
