import { defineStore } from 'pinia';
import api from "@/plugins/axios";
import '@/plugins/pusher';
import pusher from '@/plugins/pusher';
import L from "leaflet";
import $ from "jquery";
import DataTable from 'datatables.net-dt';
import "leaflet-sidebar";
import "leaflet.marker.slideto";
import { useAuthStore, useNotifStore } from '../index';


//Import SatNet
import { cns11, cns11gain, cns11_gnt, cns11_gntgain, } from "@/assets/js/satnet/cns11.js";
import { apstar5, apstar5gain, apstar5_gnt, apstar5_gntgain } from "@/assets/js/satnet/apstar5.js";
import { apstar92, apstar92gain, apstar92_gnt, apstar92_gntgain } from "@/assets/js/satnet/apstar9.js";
import { abs, absgain, abs_gnt, abs_gntgain } from "@/assets/js/satnet/abs.js";
import { ocn, ocngain, ocn_gnt, ocn_gntgain } from "@/assets/js/satnet/oceanbeam.js";
import { hts, htsgain, hts_gnt, hts_gntgain } from "@/assets/js/satnet/hts.js";
import { beam3, beam3gain, beam3_gnt, beam3_gntgain } from "@/assets/js/satnet/beam3.js";
import { horizon, horizongain, horizon_gnt, horizon_gntgain } from "@/assets/js/satnet/horizon.js";
import { utelsat, utelsatgain, utelsat_gnt, utelsat_gntgain } from "@/assets/js/satnet/utelsat.js";

export const useNavplusStore = defineStore({
    id: 'navplus',

    state: () => ({
        notification_store:useNotifStore(),

        channel_sailink: "navplus.sailink.",
        event_sailink: "DumpdataUpdated",

        channel_thuraya: "navplus.thuraya.",
        event_thuraya: "TrackingMsgReceived",

        channel_orbcomm: "navplus.orbcomm.",
        event_orbcomm: "OrbcommUpdated",

        channel_globalstar: "navplus.gs.",
        event_globalstar: "GlobalStarUpdated",

        channel_iridium: "navplus.iridium.",
        event_iridium: "IridiumUpdated",

        channel_iotinactive: "navplus.sailink.iot-status",
        event_iotinactive: "IotStatusOnlyUpdated",

        showView: false,

        sailink_vessels: [],
        sailink_: [],

        thuraya_vessels: [],
        thuraya_: [],

        orbcomm_vessels: [],
        orbcomm_: [],

        globalstar_vessels: [],
        globalstar_: [],

        iridium_vessels: [],
        iridium_: [],

        excluded: [
            74,
            43,
            93,
            85,
            76,
            102,
            129,
            79,
            72,
            108,
            66,
            132,
            67,
            90,
            71,
            87,
            77,
            153,
            106,
            88,
        ],
        markerClicked: null,
        currentWeather: null,
        markerType: null,
        satnetData: null,
        targetLayer: L.layerGroup(),
        trackingLayer: L.layerGroup(),
        target: null,
        targetCircle: null,
        sidebar: null,
        searchBar: null,
        myMap: null,
        searchTable: null,
        searchKeyUp: '',
        pageOf: 10,
        searchbar_vessels: [],
        markerTracking: [],
        movingMarker: null,
        drivePath: null,
        driveLatLng: null,
        driveRotationAngle: null,
        IntervalMoving: null,
        countLength: null,
        authStore: useAuthStore(),
        fetchingPremission: null,
        searchbarClicked: false,
        thuraya_trackingoff: [],
        sailink_vsatdown: [],
        iot_inactive: [],
        alertYellow: require("@/assets/images/alert.png"),
        alertRed: require("@/assets/images/alert_red.png"),
        markerBlink: 0,
        intervalBlink: null,
        slider: 50,
        waveData: null,
        bmkgSwitch: false,
        geojson: null,
        day2: null,
        day3: null,
        slider: 50,
        thurayaArr: [],
        sailinkArr: [],
        snap_inactive: 0,

        //SATNET

        //Skyreach 1
        path_cns11: cns11,
        gain_cns11: cns11gain,
        path_cns11gnt: cns11_gnt,
        gain_cns11gnt: cns11_gntgain,
        //Skyreach 2
        path_apstar5: apstar5,
        gain_apstar5: apstar5gain,
        path_apstar5gnt: apstar5_gnt,
        gain_apstar5gnt: apstar5_gntgain,

        //Apstar 9
        path_apstar9: apstar92,
        gain_apstar9: apstar92gain,
        path_apstar9gnt: apstar92_gnt,
        gain_apstar9gnt: apstar92_gntgain,

        //ABS 2A
        path_abs: abs,
        gain_abs: absgain,
        path_absgnt: abs_gnt,
        gain_absgnt: abs_gntgain,

        //SR1 Maritime
        path_ocn: ocn,
        gain_ocn: ocngain,
        path_ocngnt: ocn_gnt,
        gain_ocngnt: ocn_gntgain,

        //SR2 HTS BEAM#4
        path_beam4: hts,
        gain_beam4: htsgain,
        path_beam4gnt: hts_gnt,
        gain_beam4gnt: hts_gntgain,

        //SR2 HTS BEAM#3
        path_beam3: beam3,
        gain_beam3: beam3gain,
        path_beam3gnt: beam3_gnt,
        gain_beam3gnt: beam3_gntgain,

        //Horizon
        path_hrz: horizon,
        gain_hrz: horizongain,
        path_hrzgnt: horizon_gnt,
        gain_hrzgnt: horizon_gntgain,

        //Utelsat
        path_utls: utelsat,
        gain_utls: utelsatgain,
        path_utlsgnt: utelsat_gnt,
        gain_utlsgnt: utelsat_gntgain,


        EIRP: false,
        GNT: false,
        SKYR1: false,
        SKYR2: false,
        APST9: false,
        ABS2A: false,
        SR1MR: false,
        BEAM4: false,
        BEAM3: false,
        HRZ: false,
        UTLS: false,


        polygonColor: {
            skyr1: "green",
            skyr2: "blue",
            apst9: "purple",
            abs2a: "orange",
            sr1mr: "#F7038B",
            beam4: "#DB6B0F",
            beam3: "#DB6B0F",
            hrz: "#DBC70f",
            utls: "#0CAD93"
        },


    }),



    actions: {



        //INITIALIZE FUNCTIONS 
        //------------------------------------------------------------------
        //Initialize Map and Layers
        initializeMap(map_) {
            this.myMap = map_;
            this.targetLayer.addTo(this.myMap);
            this.trackingLayer.addTo(this.myMap);
            var root = this;

            if (this.authStore.user.user.role_id > 2) {
                console.log(this.authStore.user.user.role_id);
                console.log(root.markerBlink);
                root.markerBlink = 1;
                console.log(root.markerBlink);
                setInterval(() => {
                    root.markerBlink = !root.markerBlink
                }, 500);
            }


            this.sidebar = L.control.sidebar('sidebar', {
                position: 'left'
            });
            this.searchBar = L.control.sidebar('searchbar', {
                position: 'right'
            });
            this.myMap.addControl(this.sidebar);
            this.myMap.addControl(this.searchBar);
            $('.leaflet-control-attribution').hide()
            this.myMap.invalidateSize();
        },
        //------------------------------------------------------------------



        // DATA FETCH FUNCTIONS
        //------------------------------------------------------------------
        //Fetch All Navigator Data
        fetch_navplus() {
            this.notification_store.fetch_notification();
            this.notification_store.subscribe_websocket_notif();
            this.fetch_vessel();
            this.fetch_IOT_inactive();
            this.fetch_json_wave();
            this.fetch_geo_json();
            
        },
        //Fetch Vessels & Websocket Subscribe
        async fetch_vessel() {
            await api.get(`/api/v1/navplus/gmaps/index`, {}
            ).then((res) => {

                console.log("FIRST RESPONSE");
                console.log(res);

                if (res.status == 200) {
                    this.snap_inactive = parseInt(res.data.data.snap_inactive_count)

                    //initialize data sailink vessels
                    for (var a = 0; a < res.data.data.locations.navplus.length; a++) {
                        if (
                            (res.data.data.locations.navplus[a]["pt"] == "PT Golden Vesta") ||
                            (res.data.data.locations.navplus[a]["pt"] ==
                                "PT Pelayaran Nasional Indonesia" &&
                                res.data.data.user.email.includes("pelni.co.id"))
                        ) {
                            continue;
                        } else {
                            this.sailink_.push(res.data.data.locations.navplus[a]);
                        }
                    }


                    //initialize data thuraya vessels
                    for (var b = 0; b < res.data.data.locations.thuraya.length; b++) {
                        if (
                            !res.data.data.locations.thuraya[b].rateplan.includes(
                                "SLTrack"
                            ) ||
                            this.excluded.includes(res.data.data.locations.thuraya[b]["id"])
                        ) {
                            continue;
                        } else {
                            this.thuraya_.push(res.data.data.locations.thuraya[b]);
                        }
                    }

                    //initialize data orbcomm vessels
                    for (var c = 0; c < res.data.data.locations.orbcomm.length; c++) {
                        this.orbcomm_.push(res.data.data.locations.orbcomm[c]);
                    }


                    //initialize data globalstar vessels
                    for (var d = 0; d < res.data.data.locations.gs.length; d++) {
                        this.globalstar_.push(res.data.data.locations.gs[d]);
                    }

                    //initialize data iridium vessels
                    for (var d = 0; d < res.data.data.locations.iridium.length; d++) {
                        this.iridium_.push(res.data.data.locations.iridium[d]);
                    }


                    this.sailink_vessels = this.sailink_
                    this.thuraya_vessels = this.thuraya_
                    this.orbcomm_vessels = this.orbcomm_
                    this.globalstar_vessels = this.globalstar_
                    this.iridium_vessels = this.iridium_

                    // this.delayset_thuraya(0);
                    // this.delayset_sailink(0);



                    var trackingoff = [];
                    for (var i in this.thuraya_vessels) {
                        if (
                            (this.thuraya_vessels[i].fill == "red" ||
                                this.thuraya_vessels[i].fill == "#828784") &&
                            this.thuraya_vessels[i].rateplan.includes("SLTrack")
                        ) {
                            trackingoff.push(this.thuraya_vessels[i]);
                        }
                    }
                    this.thuraya_trackingoff = trackingoff;





                    var vsatdown = [];

                    for (var j in this.sailink_vessels) {
                        if (this.sailink_vessels[j].fill == "red") {
                            vsatdown.push(this.sailink_vessels[j]);
                        }
                    }
                    this.sailink_vsatdown = vsatdown;

                    //initialize search bar
                    this.searchbar_vessels = res.data.data.sidebar_vessel
                    setTimeout(() => {
                        this.searchTable = new DataTable('#myTable');
                    }, 2000);



                    console.log("KAPAL IRIDIUM");
                    console.log(res.data.data.locations.iridium);


                    //Subsribe Sailink
                    for (let i = 0; i < res.data.data.locations.navplus.length; i++) {
                        pusher.subscribe(this.channel_sailink + res.data.data.locations.navplus[i]['id'])
                            .bind('pusher:subscription_succeeded', function () {
                                console.log('Sailink Websocket subscription for sailink id :');
                                console.log(res.data.data.locations.navplus[i]['id']);
                            })
                            .bind("App\\Events\\" + this.event_sailink, (data) => {
                                console.log(" Web Socket Sailink - Update ")
                                console.log(data);
                                if (useAuthStore.user != null) {
                                    this.update_sailink(data.data)
                                }

                            });
                    }



                    //Subscribe Thuraya
                    for (let i = 0; i < res.data.data.locations.thuraya.length; i++) {
                        pusher.subscribe(this.channel_thuraya + res.data.data.locations.thuraya[i]['thuraya_number'])
                            .bind('pusher:subscription_succeeded', function () {
                                console.log('Thuraya Websocket subscription for thuraya number :');
                                console.log(res.data.data.locations.thuraya[i]['thuraya_number']);
                            })
                            .bind("App\\Events\\" + this.event_thuraya, (data) => {
                                console.log(" Web Socket Thuraya - Update ")
                                console.log(data);
                                if (useAuthStore.user != null) {
                                    this.update_thuraya(data.data)
                                }

                            });
                    }



                    //Subscribe Orbcomm
                    for (let i = 0; i < res.data.data.locations.orbcomm.length; i++) {
                        pusher.subscribe(this.channel_orbcomm + res.data.data.locations.orbcomm[i]['id'])
                            .bind('pusher:subscription_succeeded', function () {
                                console.log('Orbcomm Websocket subscription for orbcomm id :');
                                console.log(res.data.data.locations.orbcomm[i]['id']);
                            })
                            .bind("App\\Events\\" + this.event_orbcomm, (data) => {
                                console.log(" Web Socket Orbcomm - Update ")
                                console.log(data);
                                if (useAuthStore.user != null) {
                                    this.update_orbcomm(data.data)
                                }

                            });
                    }


                    //Subscribe Globalstar
                    for (let i = 0; i < res.data.data.locations.gs.length; i++) {
                        pusher.subscribe(this.channel_globalstar + res.data.data.locations.gs[i]['id'])
                            .bind('pusher:subscription_succeeded', function () {
                                console.log('Globalstar Websocket subscription for Globalstar id :');
                                console.log(res.data.data.locations.gs[i]['id']);
                            })
                            .bind("App\\Events\\" + this.event_globalstar, (data) => {
                                console.log(" Web Socket Globalstar - Update ")
                                console.log(data);
                                if (useAuthStore.user != null) {
                                    this.update_globalstar(data.data)
                                }

                            });
                    }


                    //Subscribe Iridium
                    for (let i = 0; i < res.data.data.locations.iridium.length; i++) {
                        pusher.subscribe(this.channel_iridium + res.data.data.locations.iridium[i]['imei'])
                            .bind('pusher:subscription_succeeded', function () {
                                console.log('Iridium Websocket subscription for imei :');
                                console.log(res.data.data.locations.iridium[i]['imei']);
                            })
                            .bind("App\\Events\\" + this.event_iridium, (data) => {
                                console.log(" Web Socket Iridium - Update ")
                                console.log(data);
                                if (useAuthStore.user != null) {
                                    this.update_iridium(data.data)
                                }

                            });
                    }
                } else {
                    this.authStore.logout()
                }
            }).catch((err) => {
                console.log(err)
                this.authStore.logout()
            }).then(() => {
            });
        },
        //Fetch Weather
        async fetch_IOT_inactive() {
            await api.get(`/api/v1/navplus/gmaps/fetchIotInactive`, {}
            ).then((res) => {
                console.log('FETCH INACTIVE IOT :');
                console.log(res.data.data);
                this.iot_inactive = res.data.data.inactive_list;

                //Subscribe IoT Inactive
                pusher.subscribe(this.channel_iotinactive)
                    .bind('pusher:subscription_succeeded', function () {
                        console.log('IoT Inactive Websocket subscription succeed');
                    })
                    .bind("App\\Events\\" + this.event_iotinactive, (data) => {
                        console.log(" Web Socket IoT Inactive - Update ")
                        console.log(data.inactive_list);
                        this.iot_inactive = data.inactive_list;
                    });

            }).catch((err) => {

            }).then(() => {
            });
        },
        //Fetch Wave Data
        async fetch_json_wave() {
            var root = this;
            $.ajax({
                url: "https://peta-maritim.bmkg.go.id/public_api/overview/gelombang.json",
                type: 'GET',
                dataType: 'json',
                success: function (res) {
                    console.log("WAVE JSON");
                    console.log(res);
                    root.waveData = res;
                }
            });

        },
        //Fetch Geo Json
        async fetch_geo_json() {
            var root = this;
            $.ajax({
                type: "GET",
                url: "https://peta-maritim.bmkg.go.id/public_api/static/wilayah_perairan.json",
                dataType: "json",
                success: function (response) {
                    console.log("Fetch BMKG JSON");
                    console.log(response);
                    const today = new Date();
                    root.geojson = response;
                    root.day2 = new Date(today);
                    root.day3 = new Date(today);
                    root.day2.setDate(root.day2.getDate() + 2);
                    root.day3.setDate(root.day3.getDate() + 3);
                },
                error: function (response) {
                    console.log(response);
                }
            })
        },
        //Fetch Weather
        async fetch_weather(data) {
            await api.post(
                "/api/v1/navplus/gmaps/fetchWeatherCurrent",
                data,
                {
                }
            ).then((res) => {
                console.log('Weather Data Updated :');
                console.log(res.data.data);
                this.currentWeather = res.data.data
            }).catch((err) => {

            }).then(() => {
            });
        },
        //Fetch Satnet
        async fetch_SatNet(data) {
            await api.post(
                "/api/v1/navplus/gmaps/fetchSatnetData",
                data,
                {
                }
            ).then((res) => {
                console.log("SatNet Updated :");
                console.log(res.data.data);
                this.satnetData = res.data.data
            }).catch((err) => {

            }).then(() => {
            });
        },
        //Fetch Tracking 24 Hour When Marker Clicked
        async fetch_Tracking(data) {
            clearInterval(this.IntervalMoving);
            this.drivePath = null;
            this.driveLatLng = null;
            this.driveRotationAngle = null;
            var marker_type = this.markerType;
            console.log(marker_type);
            const datatrack = {
                id: data.id,
                range: data.range,
            };


            await api.post(
                "/api/v1/navplus/" + marker_type + "/date/trackingOnly",
                datatrack,
                {
                    headers: {
                        Authorization: "Bearer" + data.token,
                    },
                }
            ).then((res) => {

                if (this.markerClicked.id == data.id) {
                    this.markerTracking = res.data.data.tracking;
                    this.trackingLayer.clearLayers();

                    this.movingMarker = L.marker([res.data.data.tracking[0].lat, res.data.data.tracking[0].lng], {
                        icon: this.return_track_icon(),
                        rotationAngle: res.data.data.tracking[0].heading,
                    }).addTo(this.targetLayer);

                    var firstpolyline = new L.Polyline(res.data.data.tracking, {
                        color: '#FEE800',
                        weight: 3,
                        opacity: 0.5,
                        smoothFactor: 1
                    });
                    this.trackingLayer.addLayer(firstpolyline);
                    var mom = this
                    res.data.data.tracking.forEach(function (coord) {
                        if (!mom.authStore.userAllowed.allowed['hide marker dot']) {
                            var circle = L.circle([coord.lat, coord.lng], {
                                color: "#1DC01D",
                                fillColor: "#1DC01D",
                                fillOpacity: 0.3,
                                opacity: 0.6,
                                weight: 2,
                                radius: 3000,
                            }).bindPopup(
                                "<table><tr><td><b>Latitude</b></td><td>:</td><td>" +
                                coord.lat +
                                "</td></tr><tr><td><b>Longitude</b></td><td>:</td><td>" +
                                coord.lng +
                                "</td></tr><tr>" +
                                "<td><b>DMS</b></td><td>:</td>" +
                                "<td>" +
                                coord.dms +
                                "</td>" +
                                "</tr>" +
                                "<tr>" +
                                "<td><b>Speed</b></td>" +
                                "<td>:</td>" +
                                " <td>" +
                                coord.speed +
                                "&nbsp;knots </td>" +
                                "</tr>" +
                                "<tr>" +
                                " <td><b>Heading</b></td>" +
                                "<td>:</td>" +
                                "<td>" +
                                coord.heading +
                                "</td>" +
                                " </tr>" +
                                "<tr>" +
                                "<td><b>Last Update</b></td>" +
                                "<td>:</td>" +
                                "<td>" +
                                coord.updated +
                                "</td>" +
                                "</tr>" +
                                "</table>"
                            ).addTo(mom.targetLayer)
                        }
                    });
                    this.initialdata_moving();
                    this.moving_start();
                }

            }).catch((err) => {

            }).then(() => {
            });
        },
        //------------------------------------------------------------------



        // UPDATE VESSEL FUNCTIONS
        //------------------------------------------------------------------
        //Update Sailink Marker
        update_sailink(value) {
            this.sailink_vessels.forEach((element, index) => {
                if (element.id === value.id) {
                    console.log(value.id + " SAILINK IS MATCH");
                    this.sailink_vessels[index].dms = value.dms
                    this.sailink_vessels[index].fill = value.fill
                    this.sailink_vessels[index].heading = value.heading
                    this.sailink_vessels[index].id = value.id
                    this.sailink_vessels[index].identifier = value.identifier
                    this.sailink_vessels[index].last_up = value.last_up
                    this.sailink_vessels[index].lat = value.lat
                    this.sailink_vessels[index].lon = value.lon
                    this.sailink_vessels[index].name = value.name
                    this.sailink_vessels[index].photo = value.photo
                    this.sailink_vessels[index].pt = value.pt
                    this.sailink_vessels[index].rotation = value.rotation
                    this.sailink_vessels[index].sn = value.sn
                    this.sailink_vessels[index].speed = value.speed
                }
            });

            if (this.markerClicked && (value.id == this.markerClicked.id)) {
                this.toggle_sidebar(value, this.markerType)
            }
        },
        //Update Thuraya Marker
        update_thuraya(value) {
            this.thuraya_vessels.forEach((element, index) => {
                if (element.id === value.id) {
                    console.log(value.id + " THURAYA IS MATCH");
                    this.thuraya_vessels[index].blink = value.blink
                    this.thuraya_vessels[index].dms = value.dms
                    this.thuraya_vessels[index].ext_gps = value.ext_gps
                    this.thuraya_vessels[index].fill = value.fill
                    this.thuraya_vessels[index].heading = value.heading
                    this.thuraya_vessels[index].id = value.id
                    this.thuraya_vessels[index].lat = value.lat
                    this.thuraya_vessels[index].lon = value.lon
                    this.thuraya_vessels[index].name = value.name
                    this.thuraya_vessels[index].photo = value.photo
                    this.thuraya_vessels[index].pt = value.pt
                    this.thuraya_vessels[index].rateplan = value.rateplan
                    this.thuraya_vessels[index].rotation = value.rotation
                    this.thuraya_vessels[index].rpm = value.rpm
                    this.thuraya_vessels[index].rpmLabel = value.rpmLabel
                    this.thuraya_vessels[index].satnet = value.satnet
                    this.thuraya_vessels[index].speed = value.speed
                    this.thuraya_vessels[index].thuraya_number = value.thuraya_number
                    this.thuraya_vessels[index].updated = value.updated
                }

            });

            if (this.markerClicked && (value.id == this.markerClicked.id)) {
                this.toggle_sidebar(value, this.markerType)
            }
        },
        //Update Orbcomm Marker
        update_orbcomm(value) {
            this.orbcomm_vessels.forEach((element, index) => {
                if (element.id === value.id) {
                    console.log(value.id + " ORBCOMM IS MATCH");
                    this.orbcomm_vessels[index].MobileID = value.MobileID
                    this.orbcomm_vessels[index].dateTime_local = value.dateTime_local
                    this.orbcomm_vessels[index].dms = value.dms
                    this.orbcomm_vessels[index].fill = value.fill
                    this.orbcomm_vessels[index].heading = value.heading
                    this.orbcomm_vessels[index].id = value.id
                    this.orbcomm_vessels[index].lat = value.lat
                    this.orbcomm_vessels[index].lon = value.lon
                    this.orbcomm_vessels[index].name = value.name
                    this.orbcomm_vessels[index].photo = value.photo
                    this.orbcomm_vessels[index].pt = value.pt
                    this.orbcomm_vessels[index].pt_group = value.pt_group
                    this.orbcomm_vessels[index].rotation = value.rotation
                    this.orbcomm_vessels[index].speed = value.speed
                }

            });

            if (this.markerClicked && (value.id == this.markerClicked.id)) {
                this.toggle_sidebar(value, this.markerType)
            }
        },
        //Update Globalstar Marker
        update_globalstar(value) {
            this.globalstar_vessels.forEach((element, index) => {
                if (element.id === value.id) {
                    console.log(value.id + " GLOBALSTAR IS MATCH");
                    this.globalstar_vessels[index].dateTime_local = value.dateTime_local
                    this.globalstar_vessels[index].dev_id = value.dev_id
                    this.globalstar_vessels[index].dms = value.dms
                    this.globalstar_vessels[index].fill = value.fill
                    this.globalstar_vessels[index].heading = value.heading
                    this.globalstar_vessels[index].id = value.id
                    this.globalstar_vessels[index].lat = value.lat
                    this.globalstar_vessels[index].lon = value.lon
                    this.globalstar_vessels[index].name = value.name
                    this.globalstar_vessels[index].photo = value.photo
                    this.globalstar_vessels[index].pt = value.pt
                    this.globalstar_vessels[index].pt_group = value.pt_group
                    this.globalstar_vessels[index].rotation = value.rotation
                    this.globalstar_vessels[index].speed = value.speed
                }

            });

            if (this.markerClicked && (value.id == this.markerClicked.id)) {
                this.toggle_sidebar(value, this.markerType)
            }
        },
        //update Iridium Marker
        update_iridium(value) {
            this.iridium_vessels.forEach((element, index) => {
                if (element.id === value.id) {
                    console.log(value.id + " IRIDIUM IS MATCH");
                    this.iridium_vessels[index].dateTime_local = value.dateTime_local
                    this.iridium_vessels[index].dms = value.dms
                    this.iridium_vessels[index].fill = value.fill
                    this.iridium_vessels[index].heading = value.heading
                    this.iridium_vessels[index].id = value.id
                    this.iridium_vessels[index].imei = value.imei
                    this.iridium_vessels[index].lat = value.lat
                    this.iridium_vessels[index].lon = value.lon
                    this.iridium_vessels[index].name = value.name
                    this.iridium_vessels[index].photo = value.photo
                    this.iridium_vessels[index].pt = value.pt
                    this.iridium_vessels[index].pt_group = value.pt_group
                    this.iridium_vessels[index].rotation = value.rotation
                    this.iridium_vessels[index].rpm = value.rpm
                    this.iridium_vessels[index].speed = value.speed
                }

            });

            if (this.markerClicked && (value.id == this.markerClicked.id)) {
                this.toggle_sidebar(value, this.markerType)
            }
        },
        //------------------------------------------------------------------



        //SEARCH VESSEL ON MAP FUNCTIONS
        //------------------------------------------------------------------
        //Search Sailink
        search_sailink(id) {
            this.sailink_vessels.forEach((element, index) => {
                if (element.id === id) {
                    this.toggle_sidebar(element, 'sailink')
                }
            });
        },
        //Search Thuraya
        search_thuraya(id) {
            this.thuraya_vessels.forEach((element, index) => {
                if (element.id === id) {
                    this.toggle_sidebar(element, 'thuraya')
                }
            });
        },
        //Search Orbcomm
        search_orbcomm(id) {
            this.orbcomm_vessels.forEach((element, index) => {
                if (element.id === id) {
                    this.toggle_sidebar(element, 'orbcomm')
                }
            });
        },
        //Search Globalstar
        search_globalstar(id) {
            this.globalstar_vessels.forEach((element, index) => {
                if (element.id === id) {
                    this.toggle_sidebar(element, 'gs')
                }
            });
        },
        //Search Iridium
        search_iridium(id) {
            this.iridium_vessels.forEach((element, index) => {
                if (element.id === id) {
                    this.toggle_sidebar(element, 'iridium')
                }
            });
        },
        //------------------------------------------------------------------



        //CHECK DATA AVAILBLE FUNCTIONS
        //------------------------------------------------------------------
        //Check If Search Data Vessel Exsist in Vessels Array
        check_search_data(id, vessel) {
            var return_ = false;
            vessel.forEach((element, index) => {
                if (element.id === id) {
                    return_ = true
                }
            });
            return return_
        },
        //Check Permissions
        check_premission() {
            console.log("CHECK PREMISSION");
            console.log(this.authStore.userAllowed);
            var root = this;

            if (this.authStore.userAllowed && this.authStore.userAllowed.allowed['show marker label']) {
                setTimeout(() => {
                    if ($('#fleetlabel_switch')[0]) {
                        $('#fleetlabel_switch')[0].checked = true;
                        console.log("FLEET LABEL SWITCH ::" + $('#fleetlabel_switch')[0].checked);
                    }
                }, 2000);

            }

            if (this.authStore.userAllowed && this.authStore.userAllowed.allowed['show legend']) {
                setTimeout(() => {
                    if ($('#legend_switch')[0]) {
                        $('#legend_switch')[0].checked = true;
                        console.log("LEGEND SWITCH ::" + $('#legend_switch')[0].checked);
                    }
                }, 2000);

            }


            if (this.authStore.userAllowed && this.authStore.userAllowed.allowed['show vessel down info']) {
                setTimeout(() => {
                    if ($('#vdown_switch')[0]) {
                        $('#vdown_switch')[0].checked = true;
                        console.log("LEGEND SWITCH ::" + $('#vdown_switch')[0].checked);
                    }
                }, 2000);

            }

            if (this.authStore.userAllowed && this.authStore.userAllowed.allowed['show marker alert']) {

                setTimeout(() => {
                    if ($('#falert_switch')[0]) {
                        $('#falert_switch')[0].checked = true;
                        console.log("LEGEND SWITCH ::" + $('#falert_switch')[0].checked);
                    }
                }, 2000);


                this.markerBlink = 1;
                this.intervalBlink = setInterval(() => {
                    if (root.authStore.userAllowed.allowed['show marker alert']) {
                        root.markerBlink = !root.markerBlink
                    }
                }, 500);
            } else {
                this.markerBlink = 0;
                clearInterval(this.intervalBlink);
            }

            if (this.bmkgSwitch) {
                console.log("BMKG SWITCH");
                setTimeout(() => {
                    if ($('#bmkg_switch')[0]) {
                        $('#bmkg_switch')[0].checked = true;
                        console.log("BMKG LABEL SWITCH ::" + $('#bmkg_switch')[0].checked);
                    }
                }, 2000);
            }
        },
        //------------------------------------------------------------------



        //TABLE SEARCH & PAGE FUNCTIONS
        //------------------------------------------------------------------
        //Search Vessels on SideBar Search
        searchTable_search() {
            var mom = this;
            if (mom.searchbar_vessels) {
                if (mom.searchKeyUp) {
                    mom.searchTable.search(mom.searchKeyUp).draw();
                }
                else {
                    mom.searchTable.search('').draw();
                }
            }

        },
        //Show Search Data Vessel Base on Quantities
        searchTable_showof(page) {
            this.searchTable.page.len(page).draw()
            this.pageOf = page;
        },
        //------------------------------------------------------------------



        // TOGGLES FUNCTIONS
        //------------------------------------------------------------------

        //toggle sidebar / marker drawer 
        //Toggle Sidebar
        toggle_sidebar(data, type) {
            this.targetLayer.clearLayers();
            this.trackingLayer.clearLayers();
            this.markerClicked = data;
            this.currentWeather = null;
            this.satnetData = null;
            this.markerType = type;

            console.log("TIPE MARKER");
            console.log(this.markerType);


            this.target = new L.marker(
                [data.lat, data.lon],
                {
                    icon: this.return_target()
                }
            );
            this.targetLayer.addLayer(this.target);
            this.targetCircle = L.circleMarker([data.lat, data.lon], {
                color:
                    type == 'sailink' ? '#4578F9' :
                        type == 'thuraya' ? '#FFA200' :
                            type == 'orbcomm' ? '#008000' :
                                type == 'gs' ? '#800080' :
                                    type == 'iridium' ? '#32CD32' :
                                        '#198754'
                ,
                fillColor:
                    type == 'sailink' ? '#4578F9' :
                        type == 'thuraya' ? '#FFA200' :
                            type == 'orbcomm' ? '#008000' :
                                type == 'gs' ? '#800080' :
                                    type == 'iridium' ? '#32CD32' :
                                        '#198754',
                fillOpacity: 0.4,
                className: "box",
                radius: 45,
            });
            this.targetLayer.addLayer(this.targetCircle);

            if (!this.sidebar.isVisible()) {
                this.sidebar.toggle()
            }
            this.myMap.panTo([data.lat, data.lon])

            this.fetch_weather(data);

            if (data.satnet) {
                const fetch_satnet = {
                    lat: data.lat,
                    lon: data.lon,
                    satnet: data.satnet,
                };

                this.fetch_SatNet(fetch_satnet);
            }


            const fetch_tracking = {
                id: data.id,
                range: '24',
                token: localStorage.getItem("token"),
            };

            this.fetch_Tracking(fetch_tracking);


        },
        //Toggle Searchbar
        toggle_searchbar() {
            this.searchBar.toggle();
            if (this.searchBar.isVisible()) {
                this.searchbarClicked = true
            } else {
                this.searchbarClicked = false
            }
        },
        //Toggle Premission
        async toggle_premission(data, type) {
            this.fetchingPremission = type

            await api.post(
                "/api/v1/navplus/user/setPermission",
                {
                    permission: data,
                },
                {

                }
            ).then((res) => {
                console.log("Premission Toggle :");
                console.log(res);
                this.authStore.fetch_allowed()

            }).catch((err) => {

            }).then(() => {
            });
        },
        //Toggle Satnet
        toggle_satnet(id, variable, type) {
            console.log(id);
            var status = $(id)[0].checked;
            if (type == 'subsatnet') {
                console.log('subsatnet');
                if (!this.EIRP && !this.GNT && status == true) {
                    $('#eirp_switch')[0].checked = true;
                    this.EIRP = status
                }
                console.log(status);
                this[variable] = status
            } else {
                console.log('mainsatnet');
                $(id)[0].checked = status
                this[variable] = status
            }
            console.log("FLEET LABEL SATNET ::" + $(id)[0].checked);
        },
        //Toggle BMKG
        toggle_bmkg() {
            this.bmkgSwitch = $('#bmkg_switch')[0].checked;
            this.slider = 50;
            setTimeout(() => {
                $("#bmkg_range").val(50);
            }, 2000);

        },
        //------------------------------------------------------------------



        // RETURN ICONS & MARKER
        //------------------------------------------------------------------
        //Return Icon 
        //return icon for vessels
        return_icon(marker) {
            let svgIcon;



            if (marker.blink && (marker.fill == 'orange' || marker.fill == '#828784') && this.markerBlink == 1) {
                if (this.authStore.user.user.role_id > 2) {
                    svgIcon = L.divIcon({
                        html: ``,
                        className: "",
                        iconSize: [40 / 5, 100 / 5],
                        iconAnchor: [40 / 5 / 2, 100 / 5 / 2],
                    });
                } else {
                    svgIcon = L.icon({
                        iconUrl: this.alertYellow,
                        iconSize: [19, 19],
                        iconAnchor: [9.5, 9.5],
                    });
                }
            } else if (marker.blink && (marker.fill == 'red') && this.markerBlink == 1) {
                svgIcon = L.icon({
                    iconUrl: this.alertRed,
                    iconSize: [19, 19],
                    iconAnchor: [9.5, 9.5],
                });

            } else if (marker.blink && this.markerBlink == 1) {
                svgIcon = L.divIcon({
                    html: ``,
                    className: "",
                    iconSize: [40 / 5, 100 / 5],
                    iconAnchor: [40 / 5 / 2, 100 / 5 / 2],
                });
            } else {
                svgIcon = L.divIcon({
                    html: `
                        <svg
                        stroke="#FFF" 
                        stroke-width="10"
                        viewBox="0 0 40 100"
                        xmlns="http://www.w3.org/2000/svg"
                        >
                        <path d="M 37.93 32.68 L 20 0 L 2.07 32.68 c -1.35 2.45 -2.07 5.41 -2.07 8.44 v 57.33 H 40 V 41.12 c 0 -3.03 -0.72 -5.98 -2.07 -8.44 Z" fill="`+ marker.fill + `"></path>
                        </svg>`,
                    className: "",
                    iconSize: [40 / 5, 100 / 5],
                    iconAnchor: [40 / 5 / 2, 100 / 5 / 2],
                });
            }



            return svgIcon
        },
        //Return Target
        return_target() {
            const svgIcon = L.divIcon({
                html: `
                <svg
                stroke="#FFF" 
                stroke-width="0"
                viewBox="0 0 400 400"
                xmlns="http://www.w3.org/2000/svg"
                >
                <path d="
                M362.4,180.9C353.7,105.7,293.9,46,218.6,37.5V0h-0.5v0h-36.4v0h-0.5v37.5C105.9,46,46,105.9,37.5,181.4H0v0.5v36.4v0.5
                h37.5C46,294.1,105.9,354,181.4,362.5V400h0.5h36.4h0.5v-37.5c75.6-8.6,135.6-68.6,143.9-144.3H400v-36.4v-0.9H362.4z M218.6,332.5
                v-26.4h-37.3v26.4c-59-8.2-105.6-54.9-113.9-113.9h26.4v-37.3H67.5c8.2-59,54.9-105.6,113.9-113.9v26.4h37.3V67.5
                c58.8,8.2,105.4,54.6,113.8,113.4h-26.3v37.3h26.4C324.5,277.4,277.8,324.3,218.6,332.5z
                " fill="#212529"></path>
                </svg>`,
                className: "",
                iconSize: [400 / 6, 400 / 6],
                iconAnchor: [400 / 6 / 2, 400 / 6 / 2],
            });
            return svgIcon
        },
        //Return Tracking Icon
        return_track_icon() {
            const svgIcon = L.divIcon({
                html: `
                <svg
                stroke="#212529" 
                stroke-width="0"
                viewBox="0 0 57 57"
                xmlns="http://www.w3.org/2000/svg"
                >
                <path d="M50.8,56.4L30.6,43.7c-1.3-0.8-3-0.8-4.3,0L6.2,56.4c-3.4,2.2-7.5-1.6-5.7-5.2L24.9,2.2c1.5-3,5.7-3,7.2,0l24.5,48.9
                C58.4,54.8,54.3,58.5,50.8,56.4z" fill="##212529"></path>
                </svg>`,
                className: "",
                iconSize: [57 / 3.5, 57 / 3.5],
                iconAnchor: [57 / 3.5 / 2, 57 / 3.5 / 2],
            });
            return svgIcon
        },
        //Return Marker Label
        return_label(marker) {
            var speed = '';
            var rpm = '';

            if (marker.speed && marker.speed != 0.0 && marker.speed != 0) {
                speed = `<b style="font-weight:600">[` + marker.speed + ` Kn]</b>`
            }

            if (marker.rpmLabel && !this.authStore.userAllowed.allowed['hide iot list']) {
                rpm = `<b style="font-weight:600">` + marker.rpmLabel + `</b>`
            }

            var icon = L.divIcon({
                iconSize: [500 / 5, 40 / 5],
                iconAnchor: [500 / 5 / 2, 40 / 5 / 2],
                html:
                    `
                      <div  v-if="tr_flabel" style="
                        background:none !important;
                        margin-top: 1em;
                        margin-left:-2em
                        min-width: 8em;
                        height: 20px;
                        white-space: nowrap;
                      "
                      class="text-center"
                      >
                      <p class="markerLabel" style="
                          white-space: nowrap;
                          font-family: OpenSans, sans-serif !important;
                        ">
                     `+ marker.name + ` ` + speed + ` ` + rpm + `</p>
                    </div>
                      `
            })
            return icon
        },



        // SIDE BAR ACTION FUNCTIONS
        //------------------------------------------------------------------
        //Hide Sidebar
        hide_sidebar() {
            if (this.sidebar.isVisible()) {
                this.sidebar.hide();
            }
        },
        //------------------------------------------------------------------



        // MAPS ACTION FUNCTIONS
        //------------------------------------------------------------------
        //Setview Map
        setview_map() {
            this.myMap.setView([-1.4312313766684295, 119.1498789812993], 5)
        },
        //------------------------------------------------------------------



        // MARKER TRACKING ANIMATION
        //------------------------------------------------------------------
        //Initial Data
        initialdata_moving() {
            this.drivePath = this.markerTracking.slice()
            if (this.drivePath[0]) {
                this.driveLatLng = L.latLng([this.drivePath[0].lat, this.drivePath[0].lng])
                this.driveRotationAngle = this.drivePath[0].heading
            }

        },
        //Start Moving
        moving_start() {
            var mother = this
            this.IntervalMoving = setInterval(function () {
                mother.simulate()
            }, 250);
        },
        //Simulate
        simulate() {
            if (this.drivePath && this.drivePath.length > 0) {
                let point = this.drivePath.shift()
                this.driveLatLng = L.latLng([point.lat, point.lng])
                this.driveRotationAngle = point.heading
                this.movingMarker.setLatLng(this.driveLatLng)
                this.movingMarker.slideTo(this.driveLatLng, {
                    duration: 500,
                });
                this.movingMarker.options.rotationAngle = this.driveRotationAngle;
            } else {
                if (this.markerClicked) {
                    this.initialdata_moving();
                } else {
                    clearInterval(this.IntervalMoving);
                    this.drivePath = null;
                }
            }

        },
        //------------------------------------------------------------------



        // PERIODICALLY LOADING MARKER
        //------------------------------------------------------------------
        //Delay Set Thuraya
        delayset_thuraya(id) {
            if (id == this.thuraya_.length) {
                return;
            }
            this.thurayaArr.push(this.thuraya_[id]);
            this.thuraya_vessels = this.thurayaArr;
            setTimeout(() => {
                this.delayset_thuraya(parseInt(id) + 1);
            }, 1);
        },
        //Delay Set Sailink
        delayset_sailink(id) {
            if (id == this.sailink_.length) {
                return;
            }
            this.sailinkArr.push(this.sailink_[id]);
            this.sailink_vessels = this.sailinkArr;
            setTimeout(() => {
                this.delayset_sailink(parseInt(id) + 1);
            }, 1);
        },
        //------------------------------------------------------------------



        // UNINITIALIZE DATA WHEN LOGOUT
        //------------------------------------------------------------------
        //Uninit Data 
        uninit_data() {
            console.log("UNINITIALIZED NAVPLUS STORE")
            this.sailink_vessels = [];
            this.sailink_ = [];
            this.thuraya_vessels = [];
            this.thuraya_ = [];
            this.markerClicked = null;
            this.currentWeather = null;
            this.markerType = null;
            this.satnetData = null;
            this.targetLayer.clearLayers()
            this.trackingLayer.clearLayers()
            this.target = null;
            this.targetCircle = null;
            this.sidebar = null;
            this.searchBar = null;
            this.myMap = null;
            this.searchTable = null;
            this.searchKeyUp = '';
            this.pageOf = 10;
            this.searchbar_vessels = [];
            this.markerTracking = [];
            this.movingMarker = null;
            this.drivePath = null;
            this.driveLatLng = null;
            this.driveRotationAngle = null;
            this.IntervalMoving = null;
            this.countLength = null;
            this.fetchingPremission = null;
            this.searchbarClicked = false;
            this.thuraya_trackingoff = [];
            this.sailink_vsatdown = [];
            this.iot_inactive = [];
            this.markerBlink = 0;
            this.intervalBlink = null;
            this.slider = 50;
            this.waveData = null;
            this.bmkgSwitch = false;
            this.geojson = null;
            this.day2 = null;
            this.day3 = null;
            this.slider = 50;
        },
        //------------------------------------------------------------------
    },




});
