import { defineStore } from 'pinia'
import { useOrbCommStore, useGlobalStarStore, useIridiumStore, useSailinkStore, useThurayaStore } from '../index';
import $ from "jquery";
import DataTable from 'datatables.net-dt';
import L from "leaflet";



export const useEtaStore = defineStore({
    id: 'eta',
    state: () => ({
        sailink_store: useSailinkStore(),
        thuraya_store: useThurayaStore(),
        orbcomm_store: useOrbCommStore(),
        globalstar_store: useGlobalStarStore(),
        iridium_store: useIridiumStore(),
        onclick_eta: '',
        coordinates: [],
        edit_id: null,
        input_dmslat_1: '',
        input_dmslat_2: '',
        input_dmslat_3: '',
        input_dmslat_4: 'S',
        input_dmslon_1: '',
        input_dmslon_2: '',
        input_dmslon_3: '',
        input_dmslon_4: 'W',
        input_ddlat: '',
        input_ddlon: '',
        on_routedetail: false,
        onstart_card: false,
        tableKoordinat: null,
        tableRoutedetail: null,
        isopen_detail: false,
    }),
    actions: {



        //INITIALIZE FUNCTIONS 
        //------------------------------------------------------------------
        //Initialize ETA
        init_eta(map, flag) {
            L.Control.MyControl = L.Control.extend({
                onAdd: function () {
                    var el = L.DomUtil.create("div", "leaflet-bar my-control");
                    el.innerHTML =
                        `
                        <div style="display:flex">
                        <button
                            style="background-color:#373A3E;"
                            onclick="open_ETA_`+ flag + `()"
                            id="eta_toogle_`+ flag + `"
                            class="btn text-white btn-sm text-uppercase  rounded-none"
                            type="button"
                        >
                            <i class="fas fa-exchange-alt me-2"></i>
                            ETA
                        </button>
                        <div class="card rounded-none" id="eta_card" style="display:none;width:40vw">
                            <div class="card-header bg-light bg-opacity-10 text-start rounded-none">
                                <div class="row">
                                    <div class="col pe-0 pt-1">
                                        <button
                                            id="toggle_start_card_`+ flag + `"
                                            type="button"
                                            onclick="toggle_start_card_`+ flag + `()"
                                            class="btn btn-sm border-0 btn-dark rounded-none "
                                        >Start Draw</button>
                                        <button
                                            disabled
                                            id="btn_cleareta_`+ flag + `"
                                            type="button"
                                            onclick="clear_ETA_`+ flag + `()"
                                            class="btn btn-sm border-0 btn-secondary rounded-none "
                                        >Clear</button>
                                        <button
                                            disabled
                                            id="btn_counteta_`+ flag + `"
                                            type="button"
                                            onclick="count_ETA_`+ flag + `()"
                                            class="btn btn-sm border-0 btn-secondary rounded-none "
                                        >Count</button>
                                    </div>
                                    <div class="col-sm-3 d-flex text-center align-items-center" style="padding-left:18%;">
                                        <button
                                            id="btn_closeeta_`+ flag + `"
                                            type="button"
                                            class="btn-close"
                                            onclick="close_ETA_`+ flag + `()"
                                        ></button>
                                    </div>
                                </div>
                            </div>
                            <div id="body_eta" style="display:block" class="card-body text-start ">
                                <div class="row">
                                    <div class="col-sm-4">
                                        <label for="eta_distance_`+ flag + `" class="form-label ">Distance</label>
                                        <div class="input-group mb-3">
                                            <input
                                                disabled
                                                value="0"
                                                type="number"
                                                id="eta_distance_`+ flag + `"
                                                class="form-control rounded-none  text-dark"
                                                placeholder="Disabled input"
                                            >
                                            <span class="input-group-text rounded-none  txt-1em text-dark" id="basic-addon2">Nmi</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <label for="eta_duration_`+ flag + `" class="form-label ">Duration</label>
                                        <div class="input-group mb-3">
                                            <input
                                                disabled
                                                value="0"
                                                type="text"
                                                id="eta_duration_`+ flag + `"
                                                class="form-control rounded-none text-dark"
                                                placeholder="Disabled input"
                                            >
                                            <span class="input-group-text rounded-none txt-1em text-dark">Hours</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <label for="disabledSelect" class="form-label ">Show Route Detail</label>
                                        <div class="input-group mb-3">
                                            <input
                                                id="btn_route_detail_`+ flag + `"
                                                oninput="toggle_detailroute_`+ flag + `(this)"
                                                type="checkbox"
                                                class="btn-check w-100 "
                                                autocomplete="off"
                                            >
                                            <label id="label_btn_routedetail_`+ flag + `" class="btn btn-outline-primary btn-sm w-100 rounded-none" for="btn_route_detail_` + flag + `">Route Detail</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-4">
                                        <label for="eta_speed_`+ flag + `" class="form-label ">Speed</label>
                                        <div class="input-group mb-3">
                                            <input
                                                onkeyup="count_ETA_`+ flag + `()"
                                                oninput="count_ETA_`+ flag + `()"
                                                type="number"
                                                id="eta_speed_`+ flag + `"
                                                class="form-control rounded-none  text-dark"
                                            >
                                            <span class="input-group-text rounded-none txt-1em text-dark" id="basic-addon2">Knots</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <label for="eta_depature_`+ flag + `" class="form-label ">Depature</label>
                                        <div class="input-group mb-3">
                                            <input
                                                oninput="count_ETA_`+ flag + `()"
                                                type="datetime-local"
                                                id="eta_depature_`+ flag + `"
                                                class="form-control rounded-none  text-dark"
                                            >
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <label for="eta_arrival_`+ flag + `" class="form-label">Arrival</label>
                                        <div class="input-group mb-3">
                                            <input
                                                disabled
                                                type="text"
                                                id="eta_arrival_`+ flag + `"
                                                class="form-control rounded-none  text-dark"
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                        `
                        ;
                    return el;
                },
            });

            L.control.myControl = function (opts) {
                return new L.Control.MyControl(opts);
            };

            L.control.myControl({
                position: "bottomleft",
            }).addTo(map);

            $("#btn_route_detail" + this.onclick_eta).prop("checked", false);
        },
        //Initialize Start ETA
        init_start_eta(map, flag, maptop) {

            if (maptop) {
                L.Control.MyControl = L.Control.extend({
                    onAdd: function () {
                        var el = L.DomUtil.create("div", "leaflet-bar my-control");
                        el.innerHTML =
                            `
                      <div id="start_card" class="card rounded-none" style="display:none">
                      <div class="card-body">
                      <button id="btn_startinput_`+ flag + `" type="button" onclick="open_table_koordinat_` + flag + `()" class="btn btn-sm border-0 btn-primary rounded-none " data-bs-toggle="modal" data-bs-target="#koordinatModal">Input Coordinate</button>
                        <button id="btn_startfrom_`+ flag + `" type="button" onclick="start_draw_marker_` + flag + `()" class="btn btn-sm border-0 btn-primary rounded-none ">Start from Marker</button>
                    <button id="btn_startrandom_`+ flag + `" type="button" onclick="start_draw_` + flag + `()" class="btn btn-sm border-0 btn-primary rounded-none ">Start Random</button>
                      </div>
                    </div>
                      `;
                        return el;
                    },
                });

                L.control.myControl = function (opts) {
                    return new L.Control.MyControl(opts);
                };

                L.control.myControl({
                    position: "bottomleft",
                }).addTo(map);
            } else {
                L.Control.MyControl = L.Control.extend({
                    onAdd: function () {
                        var el = L.DomUtil.create("div", "leaflet-bar my-control");
                        el.innerHTML =
                            `
                      <div id="start_card" class="card rounded-none" style="display:none">
                      <div class="card-body">
                      <button id="btn_startinput_`+ flag + `" type="button" onclick="open_table_koordinat_` + flag + `()" class="btn btn-sm border-0 btn-primary rounded-none " data-bs-toggle="modal" data-bs-target="#koordinatModal">Input Coordinate</button>
                    <button id="btn_startrandom_`+ flag + `" type="button" onclick="start_draw_` + flag + `()" class="btn btn-sm border-0 btn-primary rounded-none ">Start Random</button>
                      </div>
                    </div>
                      `;
                        return el;
                    },
                });

                L.control.myControl = function (opts) {
                    return new L.Control.MyControl(opts);
                };

                L.control.myControl({
                    position: "bottomleft",
                }).addTo(map);
            }

        },
        //Initialize Detail Route
        init_detail_route(map, flag) {
            L.Control.MyControl = L.Control.extend({
                onAdd: function (map) {
                    var el = L.DomUtil.create("div", "leaflet-bar my-control");

                    el.innerHTML =
                        `
              <div id="card_routedetail" class="card rounded-none" style="width:30em;max-height:48em;display:none">
                  <div class="card-header text-uppercase">
                      Coordinates
                  </div>
                  <div class="card-body">
                      <table id="route_detail_table" class="table  table-white table-striped "
                    style="width:100%;vertical-align: middle;">
                          <thead>
                              <tr>
                                  <th>No</th>
                                  <th>Latitude</th>
                                  <th>Longitude</th>
                                  
                              </tr>
                          </thead>
                          <tbody>
                          </tbody>
                      </table>
                  </div>
              </div>
          </div>
          
                  `;

                    return el;
                },
            });

            L.control.myControl = function (opts) {
                return new L.Control.MyControl(opts);
            };

            L.control.myControl({
                position: "topright",
            }).addTo(map);


            this.table_route_detail();
        },
        //------------------------------------------------------------------



        //OPEN & CLOSE FUNCTIONS 
        //------------------------------------------------------------------
        //Open ETA
        open_ETA(map, setclick) {
            let now = new Date();
            this.set_onclick_eta(setclick);
            this.isopen_detail = false;

            if (this.isopen_detail) {
                $('#btn_route_detail_' + this.onclick_eta).prop('checked', true);
            } else {
                $('#btn_route_detail_' + this.onclick_eta).prop('checked', false);
            }


            if (map._isFullscreen != true) {
                map.toggleFullscreen();
            } else {
                console.log("ETA ALREADY OPEN");
            }

            $("#eta_card").show();
            $("#eta_toogle_" + this.onclick_eta).hide();
            if (this.on_routedetail) {
                $("#card_routedetail").show()
            } else {
                $("#card_routedetail").hide()
            }
            $("#btn_route_detail" + this.onclick_eta).prop("checked", this.on_routedetail);
            now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
            $("#eta_depature_" + this.onclick_eta).val(now.toISOString().slice(0, 16));
            $("#eta_arrival_" + this.onclick_eta).val(now.getMonth() + '/' + now.getDate() + '/' + now.getFullYear() + ' ' + now.getHours() + ':' + now.getMinutes())
            $("#eta_speed_" + this.onclick_eta).val(1);

        },
        //Close ETA
        close_ETA(map, polyMeasure) {
            this.coordinates = [];
            this.clear_dd_input();
            this.clear_dms_input();
            this.table_koordinat();
            this.on_routedetail = false;
            this.check_map_fullscreen(map)
            this.clear_ETA(polyMeasure);
            if (this.on_routedetail) {
                $("#card_routedetail").show()
            } else {
                $("#card_routedetail").hide()
            }

            $("#eta_card").hide();
            $("#eta_toogle_" + this.onclick_eta).show();
            if (this.onstart_card == true) {
                this.onstart_card = false;
                $("#start_card").hide()
                $("#toggle_start_card_" + this.onclick_eta).removeClass("btn-warning")
                $("#toggle_start_card_" + this.onclick_eta).addClass("btn-primary")
            }
            var mother = this;
            setTimeout(() => {
                if (mother.onclick_eta == 'orbcomm') {
                    $('html, body').animate({
                        scrollTop: $("#maporbcomm_card").offset().top - 68
                    }, 10);
                } else if (mother.onclick_eta == 'globalstar') {
                    $('html, body').animate({
                        scrollTop: $("#mapglobalstar_card").offset().top - 68
                    }, 10);
                } else if (mother.onclick_eta == 'iridium') {
                    $('html, body').animate({
                        scrollTop: $("#mapiridium_card").offset().top - 68
                    }, 10);
                }

            }, 100);
        },
        //------------------------------------------------------------------



        //INITIALIZE TABLE FUNCTIONS
        //------------------------------------------------------------------
        //Initialize Route Table
        table_route_detail() {
            if (!this.tableRoutedetail) {
                this.tableRoutedetail = $('#route_detail_table').DataTable({
                    data: [],
                    scrollCollapse: true,
                    scroller: true,
                    scrollY: 120,
                    deferRender: true,
                    ordering: false,
                    bLengthChange: false,
                    info: false,
                    paging: false,
                    columns: [
                        {
                            data: '', className: 'td_koordinat', render: (data, type, row, meta) => {
                                return `
                      <div class="p-1 h-100 row`+ meta.row + `">
                      ${meta.row + 1}
                      </div>
                      `
                            }
                        },
                        {
                            data: 'lat', className: 'td_koordinat', render: (data, type, row, meta) => {
                                return `
                        <div class="p-1 h-100">
                        ${data.toFixed(6)}<br/>
                        [ ${this.convert_DMS_lat(data)} ]
                        </div>
                        `
                            }
                        },
                        {
                            data: 'lng', className: 'td_koordinat', render: (data, type, row, meta) => {
                                return `
                        <div class="p-1 h-100">
                        ${data.toFixed(6)}<br/>
                        [ ${this.convert_DMS_lon(data)} ]
                        </div>
                        `
                            }
                        },


                    ]
                });
            }



        },
        //Initialize Coordinate Taable
        table_koordinat() {
            if (this.tableKoordinat) {
                this.tableKoordinat.clear()
            }
            this.tableKoordinat =
                new DataTable('#koordinat_table_', {
                    data: this.coordinates,
                    scrollCollapse: true,
                    scroller: true,
                    destroy: true,
                    deferRender: true,
                    ordering: true,
                    bLengthChange: false,
                    scrollX: true,
                    responsive: true,
                    dom: 'lrtip',
                    columns: [
                        {
                            data: '', className: 'td_koordinat', render: (data, type, row, meta) => {
                                return `
          <div class="p-1 h-100 row`+ meta.row + `">
          ${meta.row + 1} ${(meta.row == 0) ? '<span class="badge bg-success ms-2 text-uppercase">Leave</span>' : ((meta.row + 1) >= this.coordinates.length) ? '<span class="badge bg-primary ms-2 text-uppercase">Arrival</span>' : ''}
          </div>
          `
                            }
                        },
                        {
                            data: 'show_lat', className: 'td_koordinat', render: (data, type, row, meta) => {
                                return `
            <div class="p-1 h-100 row`+ meta.row + `">
            ${data}
            </div>
            `
                            }
                        },
                        {
                            data: 'show_lon', className: 'td_koordinat', render: (data, type, row, meta) => {
                                return `
            <div class="p-1 h-100 row`+ meta.row + `">
            ${data}
            </div>
            `
                            }
                        },
                        {
                            data: '',
                            render: (data, type, row, meta) => {
                                return `
              <div class="btn-group p-1 w-100 rounded-none row`+ meta.row + `" role="group" aria-label="Basic example">` +
                                    (row.format == 'dd' ?
                                        `<button onclick="koordinat_edit('${row.id}','${row.format}')" data-bs-toggle="modal"
                            data-bs-target="#editddModal"  type="button" class="btn btn-sm btn-primary rounded-none"><i class="fas fa-edit"></i></button>`
                                        : row.format == 'dms' ?
                                            `<button onclick="koordinat_edit('${row.id}','${row.format}')" data-bs-toggle="modal" data-bs-target="#editdmsModal" type="button" class="btn btn-sm btn-primary rounded-none"><i class="fas fa-edit"></i></button>`
                                            : ``)
                                    + `<button onclick="koordinat_delete('${row.id}')" type="button" class="btn btn-sm btn-danger rounded-none"><i class="fas fa-trash"></i></button>
              <button ${meta.row == 0 ? 'disabled' : ''} onclick="koordinat_move('${meta.row}','${meta.row - 1}')"  type="button" class="btn btn-sm ${meta.row == 0 ? 'btn-dark' : 'btn-warning'} rounded-none"><i class="fas fa-chevron-up"></i></button>
              <button ${(meta.row + 1) >= this.coordinates.length ? 'disabled' : ''} onclick="koordinat_move('${meta.row}','${meta.row + 1}')" type="button" class="btn btn-sm ${(meta.row + 1) >= this.coordinates.length ? 'btn-dark' : 'btn-warning'} rounded-none" ><i class="fas fa-chevron-down"></i></button>
              </div>`;
                            },
                            className: 'td_koordinat_action'
                        },
                    ]
                });
            this.tableKoordinat.columns.adjust();
        },
        //------------------------------------------------------------------



        //CONVERT DATA FUNCTIONS
        //------------------------------------------------------------------
        //Convert DMS Latitude
        convert_DMS_lat(lat) {
            var latitude = this.to_degrees_minutes_seconds(lat);
            var latitudeCardinal = lat >= 0 ? "N" : "S";
            return latitude + latitudeCardinal;
        },
        //Convert DMS Longitude
        convert_DMS_lon(lng) {
            var longitude = this.to_degrees_minutes_seconds(lng);
            var longitudeCardinal = lng >= 0 ? "E" : "W";
            return longitude + longitudeCardinal;
        },
        //Convert To Degree Decimal
        convert_DD(dmsStr) {

            var dmsToLonLatRegex = /[-]{0,1}[\d.]*[\d]|([NSEW])+/g;
            var dmsParsed = dmsStr.match(dmsToLonLatRegex);
            var dmsParsedObj = {
                latitude: {
                    degree: dmsParsed[0],
                    minute: dmsParsed[1],
                    second: dmsParsed[2],
                    direction: dmsParsed[3]
                },
                longitude: {
                    degree: dmsParsed[4],
                    minute: dmsParsed[5],
                    second: dmsParsed[6],
                    direction: dmsParsed[7]
                }
            }

            return { lat: this.to_degrees_decimal(dmsParsedObj.latitude), lon: this.to_degrees_decimal(dmsParsedObj.longitude) }
        },
        //To Degree Minutes Seconds
        to_degrees_minutes_seconds(coordinate) {
            var absolute = Math.abs(coordinate);
            var degrees = Math.floor(absolute);
            var minutesNotTruncated = (absolute - degrees) * 60;
            var minutes = Math.floor(minutesNotTruncated);
            var seconds = Math.floor((minutesNotTruncated - minutes) * 60);
            return degrees + "&deg;" + minutes + "&apos;" + seconds + "&quot;";
        },
        //To Degree Decimal
        to_degrees_decimal(o) {
            var n = NaN;
            if (o) {
                var t = Number(o.degree),
                    d = "undefined" != typeof o.minute ? Number(o.minute) / 60 : 0,
                    l = "undefined" != typeof o.second ? Number(o.second) / 3600 : 0,
                    r = o.direction || null;
                null !== r && /[SW]/i.test(r) && (t = -1 * Math.abs(t)), n = 0 > t ? t - d - l : t + d + l
            }
            return n
        },
        //------------------------------------------------------------------



        //ETA ACTION FUNCTIONS
        //------------------------------------------------------------------
        //Count ETA
        count_ETA(polyMeasure) {
            console.log(polyMeasure);
            var toolTipsArr = polyMeasure._arrPolylines[0].tooltips;
            var coords = polyMeasure._arrPolylines[0].circleCoords;
            console.log(coords);
            this.tableRoutedetail.clear();
            this.tableRoutedetail.rows.add(coords);
            this.tableRoutedetail.draw();
            this.tableKoordinat.columns.adjust();
            var totalDistance;
            var miles = 0;
            coords.forEach((coor, i) => {
                totalDistance = toolTipsArr[i]._icon.childNodes[4].innerText;
                miles = parseFloat(toolTipsArr[i]._icon.childNodes[4].innerText);
            });

            var duration_raw = miles / $("#eta_speed_" + this.onclick_eta).val();
            var duration = duration_raw.toFixed(2);
            var departDateInput = $("#eta_depature_" + this.onclick_eta).val();
            var today = new Date(departDateInput);
            var seconds = Math.round(duration * 60 * 60);
            today.setSeconds(today.getSeconds() + seconds);
            var dd = String(today.getDate()).padStart(2, "0");
            var mm = String(today.getMonth() + 1).padStart(2, "0");
            var yyyy = today.getFullYear();
            var h = today.getHours();
            var i = today.getMinutes();

            if (h < 10) {
                h = "0" + h;
            }

            if (i < 10) {
                i = "0" + i;
            }

            today = dd + "/" + mm + "/" + yyyy + " " + h + ":" + i;
            $("#eta_duration_" + this.onclick_eta).val(duration);
            $("#eta_arrival_" + this.onclick_eta).val(today);
            $("#eta_distance_" + this.onclick_eta).val(miles);

        },
        //Clear ETA
        clear_ETA(polyMeasure) {
            this.tableRoutedetail.clear();
            this.tableRoutedetail.rows.add([]);
            this.tableRoutedetail.draw();
            this.tableKoordinat.columns.adjust();
            $('#btn_cleareta_' + this.onclick_eta).prop('disabled', true);
            $('#btn_cleareta_' + this.onclick_eta).removeClass('bg-primary');
            $('#btn_cleareta_' + this.onclick_eta).addClass('bg-secondary');

            $('#btn_startfrom_' + this.onclick_eta).prop('disabled', false);
            $('#btn_startfrom_' + this.onclick_eta).removeClass('bg-secondary');
            $('#btn_startfrom_' + this.onclick_eta).addClass('bg-primary');
            $('#btn_startrandom_' + this.onclick_eta).prop('disabled', false);
            $('#btn_startrandom_' + this.onclick_eta).removeClass('bg-secondary');
            $('#btn_startrandom_' + this.onclick_eta).addClass('bg-primary');
            $('#btn_startinput_' + this.onclick_eta).prop('disabled', false);
            $('#btn_startinput_' + this.onclick_eta).removeClass('bg-secondary');
            $('#btn_startinput_' + this.onclick_eta).addClass('bg-primary');

            polyMeasure._clearAllMeasurements();
            let now = new Date();
            now.setMinutes(now.getMinutes() - now.getTimezoneOffset());

            $("#eta_depature_" + this.onclick_eta).val(now.toISOString().slice(0, 16));
            $("#eta_arrival_" + this.onclick_eta).val(now.getMonth() + '/' + now.getDate() + '/' + now.getFullYear() + ' ' + now.getHours() + ':' + now.getMinutes())
            $("#eta_speed_" + this.onclick_eta).val(1);
            $("#eta_duration_" + this.onclick_eta).val(0);
            $("#eta_distance_" + this.onclick_eta).val(0);

            $('#btn_counteta_' + this.onclick_eta).prop('disabled', true);
            $('#btn_counteta_' + this.onclick_eta).addClass('bg-secondary');
            $('#btn_counteta_' + this.onclick_eta).removeClass('bg-primary');
        },
        //Start Draw Multi Coordinate
        start_draw_multi_coor(maps, polyMeasure) {
            var coordi_ = this.coordinates;
            polyMeasure._clearAllMeasurements();

            setTimeout(() => {
                polyMeasure._toggleMeasure();
                var latLng;
                for (var key in coordi_) {
                    var coor_ = coordi_[key];
                    latLng = L.latLng([parseFloat(coor_.lat), parseFloat(coor_.lon)]);
                    if (key == 0) {
                        maps.panTo(latLng);
                        polyMeasure._startLine(latLng);
                    }
                    polyMeasure._mouseMove({ latLng });
                    polyMeasure._currentLine.addPoint(latLng);
                }
            }, 100);

            if (maps._isFullscreen == false) {
                maps.toggleFullscreen();
            }

            var mama = this
            setTimeout(() => {
                polyMeasure._finishPolylinePath()
                if (polyMeasure._arrPolylines.length > 0) {
                    setTimeout(() => {
                        mama.count_ETA(polyMeasure);
                    }, 100);
                }
            }, 100);



            $('#btn_startfrom_' + this.onclick_eta).prop('disabled', true);
            $('#btn_startfrom_' + this.onclick_eta).removeClass('bg-primary');
            $('#btn_startfrom_' + this.onclick_eta).addClass('bg-secondary');
            $('#btn_startrandom_' + this.onclick_eta).prop('disabled', true);
            $('#btn_startrandom_' + this.onclick_eta).removeClass('bg-primary');
            $('#btn_startrandom_' + this.onclick_eta).addClass('bg-secondary');

        },
        //Start Draw From Marker
        start_draw_marker(polyMeasure) {
            if (polyMeasure._arrPolylines.length > 0) {
                polyMeasure._clearAllMeasurements();
            }

            var lati;
            var longi;

            if (this.onclick_eta == 'sailink') {
                lati = this.sailink_store.mapsailink_data_date.maptop.lat;
                longi = this.sailink_store.mapsailink_data_date.maptop.lon;
            } else if (this.onclick_eta == 'thuraya') {
                lati = this.thuraya_store.mapthuraya_data_date.maptop.lat;
                longi = this.thuraya_store.mapthuraya_data_date.maptop.lon;
            }
            else if (this.onclick_eta == 'orbcomm') {
                lati = this.orbcomm_store.maporbcomm_data_date.maptop.lat;
                longi = this.orbcomm_store.maporbcomm_data_date.maptop.lon;
            } else if (this.onclick_eta == 'globalstar') {
                lati = this.globalstar_store.mapglobalstar_data_date.maptop.lat;
                longi = this.globalstar_store.mapglobalstar_data_date.maptop.lon;
            } else if (this.onclick_eta == 'iridium') {
                lati = this.iridium_store.mapiridium_data_date.maptop.lat;
                longi = this.iridium_store.mapiridium_data_date.maptop.lon;
            }

            setTimeout(() => {
                polyMeasure._toggleMeasure();
                var latLng = L.latLng([
                    parseFloat(lati),
                    parseFloat(longi)
                ]);
                polyMeasure._startLine(latLng);
                polyMeasure._mouseMove({ latLng });
                polyMeasure._currentLine.addPoint(latLng);
            }, 100);
            $('#btn_startinput_' + this.onclick_eta).prop('disabled', true);
            $('#btn_startinput_' + this.onclick_eta).removeClass('bg-primary');
            $('#btn_startinput_' + this.onclick_eta).addClass('bg-secondary');
            $('#btn_startrandom_' + this.onclick_eta).prop('disabled', true);
            $('#btn_startrandom_' + this.onclick_eta).removeClass('bg-primary');
            $('#btn_startrandom_' + this.onclick_eta).addClass('bg-secondary');
        },
        //Start Draw Randomly
        start_draw(polyMeasure) {
            polyMeasure._clearAllMeasurements();
            setTimeout(() => {
                polyMeasure._toggleMeasure();
            }, 100);
            $('#btn_startinput_' + this.onclick_eta).prop('disabled', true);
            $('#btn_startinput_' + this.onclick_eta).removeClass('bg-primary');
            $('#btn_startinput_' + this.onclick_eta).addClass('bg-secondary');
            $('#btn_startfrom_' + this.onclick_eta).prop('disabled', true);
            $('#btn_startfrom_' + this.onclick_eta).removeClass('bg-primary');
            $('#btn_startfrom_' + this.onclick_eta).addClass('bg-secondary');

        },
        //Finish Draw
        finish_draw(polyMeasure) {
            if (polyMeasure._arrPolylines.length > 0) {
                polyMeasure._toggleMeasure();
                $('#btn_cleareta_' + this.onclick_eta).prop('disabled', false);
                $('#btn_cleareta_' + this.onclick_eta).removeClass('bg-secondary');
                $('#btn_cleareta_' + this.onclick_eta).addClass('bg-primary');
                $('#btn_counteta_' + this.onclick_eta).prop('disabled', false);
                $('#btn_counteta_' + this.onclick_eta).addClass('bg-primary');
                $('#btn_counteta_' + this.onclick_eta).removeClass('bg-secondary');

                $('#btn_startfrom_' + this.onclick_eta).prop('disabled', false);
                $('#btn_startfrom_' + this.onclick_eta).removeClass('bg-secondary');
                $('#btn_startfrom_' + this.onclick_eta).addClass('bg-primary');
                $('#btn_startrandom_' + this.onclick_eta).prop('disabled', false);
                $('#btn_startrandom_' + this.onclick_eta).removeClass('bg-secondary');
                $('#btn_startrandom_' + this.onclick_eta).addClass('bg-primary');
                $('#btn_startinput_' + this.onclick_eta).prop('disabled', false);
                $('#btn_startinput_' + this.onclick_eta).removeClass('bg-secondary');
                $('#btn_startinput_' + this.onclick_eta).addClass('bg-primary');

                this.count_ETA(polyMeasure);
            }
        },
        //------------------------------------------------------------------



        // TOGGLES FUNCTIONS
        //------------------------------------------------------------------
        //Toggle Start Card
        toggle_start_card() {
            this.onstart_card = !this.onstart_card
            if (this.onstart_card == true) {
                $("#start_card").show()
                $("#toggle_start_card_" + this.onclick_eta).removeClass("btn-dark")
                $("#toggle_start_card_" + this.onclick_eta).addClass("btn-primary")
            } else {
                $("#start_card").hide()
                $("#toggle_start_card_" + this.onclick_eta).removeClass("btn-primary")
                $("#toggle_start_card_" + this.onclick_eta).addClass("btn-dark")
            }
        },
        //Toggle Detail Route
        toggle_detailroute(e) {
            var on_routedetail = $(e).prop("checked")
            this.isopen_detail = on_routedetail;
            if (on_routedetail) {
                $("#card_routedetail").show()
            } else {
                $("#card_routedetail").hide()
            }
        },
        //------------------------------------------------------------------



        // OPEN FUNCTIONS
        //------------------------------------------------------------------
        //Open Table Coordinate
        open_table_koordinat(map) {
            this.check_map_fullscreen(map);
        },
        //Open Edit DMS
        open_edit_dms(id, placeholder_) {
            console.log(placeholder_);
            var dmsStr = placeholder_.lat + ' ' + placeholder_.lon;
            var dmsToLonLatRegex = /[-]{0,1}[\d.]*[\d]|([NSEW])+/g;
            var dmsParsed = dmsStr.match(dmsToLonLatRegex);
            var dmsParsedObj = {
                latitude: {
                    degree: dmsParsed[0],
                    minute: dmsParsed[1],
                    second: dmsParsed[2],
                    direction: dmsParsed[3]
                },
                longitude: {
                    degree: dmsParsed[4],
                    minute: dmsParsed[5],
                    second: dmsParsed[6],
                    direction: dmsParsed[7]
                }
            }
            console.log(dmsParsedObj);
            //latitude
            this.input_dmslat_1 = dmsParsedObj.latitude.degree
            this.input_dmslat_2 = dmsParsedObj.latitude.minute
            this.input_dmslat_3 = dmsParsedObj.latitude.second
            this.input_dmslat_4 = dmsParsedObj.latitude.direction

            //longitude
            this.input_dmslon_1 = dmsParsedObj.longitude.degree
            this.input_dmslon_2 = dmsParsedObj.longitude.minute
            this.input_dmslon_3 = dmsParsedObj.longitude.second
            this.input_dmslon_4 = dmsParsedObj.longitude.direction
        },
        //Open Edit DD
        open_edit_dd(id, placeholder_) {
            this.input_ddlat = placeholder_.lat
            this.input_ddlon = placeholder_.lon
        },
        //------------------------------------------------------------------



        // CLOSE FUNCTIONS
        //------------------------------------------------------------------
        //Close Input Coordinates
        close_coordinate_input() {
            if (this.onclick_eta == 'sailink') {
                this.sailink_store.sailink_map.toggleFullscreen()
            } else if (this.onclick_eta == 'thuraya') {
                this.thuraya_store.thuraya_map.toggleFullscreen()
            }
            else if (this.onclick_eta == 'orbcomm') {
                this.orbcomm_store.orbcomm_map.toggleFullscreen()
            } else if (this.onclick_eta == 'globalstar') {
                this.globalstar_store.globalstar_map.toggleFullscreen()
            } else if (this.onclick_eta == 'iridium') {
                this.iridium_store.iridium_map.toggleFullscreen()
            }
        },
        //------------------------------------------------------------------



        // COORDINATE ACTIONS FUNCTIONS
        //------------------------------------------------------------------
        //Draw Coordinates
        draw_coordinates() {
            $('#btn_cleareta_' + this.onclick_eta).prop('disabled', false);
            $('#btn_cleareta_' + this.onclick_eta).removeClass('bg-secondary');
            $('#btn_cleareta_' + this.onclick_eta).addClass('bg-primary');

            if (this.onclick_eta == 'sailink') {
                window.start_draw_multi_coor_sailink()
            } else if (this.onclick_eta == 'thuraya') {
                window.start_draw_multi_coor_thuraya()
            } else if (this.onclick_eta == 'orbcomm') {
                window.start_draw_multi_coor_orbcomm()
            } if (this.onclick_eta == 'globalstar') {
                window.start_draw_multi_coor_globalstar()
            } if (this.onclick_eta == 'iridium') {
                window.start_draw_multi_coor_iridium()
            }

        },
        //Edit DMS Coordinates
        koordinat_edit_dms() {
            var id_ = this.edit_id;
            console.log("EDIT KOORDINAT");

            //latitude
            var lat_1 = this.input_dmslat_1;
            var lat_2 = this.input_dmslat_2;
            var lat_3 = this.input_dmslat_3;
            var lat_4 = this.input_dmslat_4;

            //longitude
            var lon_1 = this.input_dmslon_1;
            var lon_2 = this.input_dmslon_2;
            var lon_3 = this.input_dmslon_3;
            var lon_4 = this.input_dmslon_4;

            var lat_temp = lat_1 + `°` + lat_2 + `´` + lat_3 + `"` + lat_4;
            var lon_temp = lon_1 + `°` + lon_2 + `´` + lon_3 + `"` + lon_4;

            var lati;
            var longi;
            var dd_latlon = this.convert_DD(`` + lat_temp + ` ` + lon_temp + ``);
            lati = dd_latlon.lat;
            longi = dd_latlon.lon;

            var json = this.coordinates;


            json.forEach((x) => {
                if (x.id === id_) {
                    x.id = id_,
                        x.format = 'dms',
                        x.show_lat = lat_temp,
                        x.show_lon = lon_temp,
                        x.lat = lati,
                        x.lon = longi

                    console.log("======== TEMPJSON ========");
                    console.log(json);

                }
            });

            this.coordinates = json;
            var mama = this;
            setTimeout(() => {
                mama.table_koordinat();
                mama.clear_dms_input();
            }, 500);

        },
        //Edit DD Coordinates
        koordinat_edit_dd() {
            var id_ = this.edit_id;

            var lat_temp = this.input_ddlat;
            var lon_temp = this.input_ddlon;
            var lati;
            var longi;
            lati = lat_temp;
            longi = lon_temp;

            var json = this.coordinates;



            json.forEach((x) => {

                if (x.id === id_) {
                    x.id = id_,
                        x.format = 'dd',
                        x.show_lat = lat_temp,
                        x.show_lon = lon_temp,
                        x.lat = lati,
                        x.lon = longi

                    console.log("======== TEMPJSON ========");
                    console.log(json);

                }
            });

            this.coordinates = json;

            var mama = this;
            setTimeout(() => {
                mama.table_koordinat();
                mama.clear_dd_input();
            }, 500);


        },
        //Clear Coordinates
        koordinat_clear() {
            console.log("CLEAR ALL");
            this.coordinates = [];
            var mama = this;
            setTimeout(() => {
                console.log(mama.coordinates);
                mama.table_koordinat();
                mama.clear_dms_input();
            }, 500);
        },
        //Move Coordinates
        koordinat_move(index, indexnew) {
            console.log("====== MOVE =======");
            var index_new = parseInt(indexnew);
            var json = this.coordinates;
            var moveObject = this.array_move(json, index, index_new)
            console.log(moveObject);
            this.coordinates = moveObject;
            var mama = this;
            setTimeout(() => {
                mama.table_koordinat();
            }, 1000);
        },
        //Delete Coordinates
        koordinat_delete(id) {
            console.log("DELETE KOORDINAT");
            console.log(id);
            var koordinat = this.coordinates;
            var json = koordinat.filter((koor) => { return koor.id !== id });
            this.coordinates = json;
            var mama = this;
            setTimeout(() => {
                mama.table_koordinat();
            }, 500);
        },
        //Edit Coordinates
        koordinat_edit(id, format) {
            console.log("EDIT KOORDINAT");
            var placeholder_;
            var json = this.coordinates;
            this.edit_id = id;
            json.forEach((x) => {
                if (x.id === id) {
                    placeholder_ = {
                        id: x.id,
                        lat: x.show_lat,
                        lon: x.show_lon,
                    }
                }
            });
            if (format == 'dms') {
                this.open_edit_dms(id, placeholder_)
            } else if (format == 'dd') {
                this.open_edit_dd(id, placeholder_)
            }
        },
        //------------------------------------------------------------------



        // CLEAR INPUT FUNCTIONS
        //------------------------------------------------------------------
        //Clear Input DMS
        clear_dms_input() {
            //latitude
            this.input_dmslat_1 = '';
            this.input_dmslat_2 = '';
            this.input_dmslat_3 = '';
            this.input_dmslat_4 = 'S';

            //longitude
            this.input_dmslon_1 = '';
            this.input_dmslon_2 = '';
            this.input_dmslon_3 = '';
            this.input_dmslon_4 = 'W';
        },
        //Clear DD Input
        clear_dd_input() {
            this.input_ddlat = '';
            this.input_ddlon = '';
        },
        //------------------------------------------------------------------



        //INPUT FORMATTER
        //------------------------------------------------------------------
        //DMS Formatter
        enter_dms() {
            //latitude
            var lat_1 = this.input_dmslat_1;
            var lat_2 = this.input_dmslat_2;
            var lat_3 = this.input_dmslat_3;
            var lat_4 = this.input_dmslat_4;

            //longitude
            var lon_1 = this.input_dmslon_1;
            var lon_2 = this.input_dmslon_2;
            var lon_3 = this.input_dmslon_3;
            var lon_4 = this.input_dmslon_4;

            var lat_temp = lat_1 + `°` + lat_2 + `´` + lat_3 + `"` + lat_4;
            var lon_temp = lon_1 + `°` + lon_2 + `´` + lon_3 + `"` + lon_4;

            var lati;
            var longi;
            var dd_latlon = this.convert_DD(`` + lat_temp + ` ` + lon_temp + ``);
            lati = dd_latlon.lat;
            longi = dd_latlon.lon;


            var ids = Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
            var coor_data = {
                "id": ids,
                "format": "dms",
                "show_lat": lat_temp,
                "show_lon": lon_temp,
                "lat": lati,
                "lon": longi
            };
            this.coordinates.push(coor_data);

            var mama = this;
            setTimeout(() => {
                console.log("FUNCTION DI TABLE");
                console.log("ARRAY COORDINATE");
                console.log(mama.coordinates);
                mama.table_koordinat();
                mama.clear_dms_input();
            }, 1000);

        },
        //DD Formatter
        enter_dd() {
            //latitude
            var lat = this.input_ddlat;
            var lon = this.input_ddlon;
            var ids = Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);

            var coor_data = {
                "id": ids,
                "format": "dd",
                "show_lat": lat,
                "show_lon": lon,
                "lat": lat,
                "lon": lon
            };

            this.coordinates.push(coor_data);

            var mama = this;
            setTimeout(() => {
                console.log("FUNCTION DI TABLE");
                console.log("ARRAY COORDINATE");
                console.log(mama.coordinates);
                mama.table_koordinat();
                mama.clear_dd_input();
            }, 1000);

        },
        //------------------------------------------------------------------



        // CLEAR INPUT FUNCTIONS
        //------------------------------------------------------------------
        //Update Route Detail
        update_route_detail(polyMeasure) {
            console.log("ON ADD");
            console.log(polyMeasure);


            // var coords = polyMeasure._arrPolylines[0].circleCoords;
            var coords = polyMeasure._currentLine.circleCoords;
            var toolTipsArr = polyMeasure._currentLine.tooltips;

            if (!this.tableRoutedetail) {
                this.tableRoutedetail = $('#route_detail_table').DataTable({
                    data: [],
                    scrollCollapse: true,
                    scroller: true,
                    scrollY: 120,
                    deferRender: true,
                    ordering: false,
                    bLengthChange: false,
                    info: false,
                    paging: false,
                    columns: [
                        {
                            data: '', className: 'td_koordinat', render: (data, type, row, meta) => {
                                return `
                      <div class="p-1 h-100 row`+ meta.row + `">
                      ${meta.row + 1}
                      </div>
                      `
                            }
                        },
                        {
                            data: 'lat', className: 'td_koordinat', render: (data, type, row, meta) => {
                                return `
                        <div class="p-1 h-100">
                        ${data.toFixed(6)}<br/>
                        [ ${this.convert_DMS_lat(data)} ]
                        </div>
                        `
                            }
                        },
                        {
                            data: 'lng', className: 'td_koordinat', render: (data, type, row, meta) => {
                                return `
                        <div class="p-1 h-100">
                        ${data.toFixed(6)}<br/>
                        [ ${this.convert_DMS_lon(data)} ]
                        </div>
                        `
                            }
                        },


                    ]
                });
            } else {
                //    this.tableRoutedetail.clear();
                //   this.tableRoutedetail.rows.add(this.coordinates);
                //   this.tableRoutedetail.draw();

                this.tableRoutedetail.clear();
                this.tableRoutedetail.rows.add(coords);
                this.tableRoutedetail.draw();
                this.tableKoordinat.columns.adjust();
            }



        },
        //Check Map Fullscreen
        check_map_fullscreen(map) {
            if (map._isFullscreen == true) {
                map.toggleFullscreen();
            }
        },
        //Set Onclic ETA
        set_onclick_eta(onactif) {
            this.onclick_eta = onactif
        },
        //Move Array
        array_move(arr, old_index, new_index) {
            if (new_index >= arr.length) {
                var k = new_index - arr.length + 1;
                while (k--) {
                    arr.push(undefined);
                }
            }
            arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
            return arr; // for testing
        },
        //------------------------------------------------------------------


        // UNINITIALIZE DATA WHEN LOGOUT
        //------------------------------------------------------------------
        //Uninit Data 
        uninit_data() {
            this.onclick_eta= '';
            this.coordinates= [];
            this.edit_id= null;
            this.input_dmslat_1= '';
            this.input_dmslat_2= '';
            this.input_dmslat_3= '';
            this.input_dmslat_4= 'S';
            this.input_dmslon_1= '';
            this.input_dmslon_2= '';
            this.input_dmslon_3= '';
            this.input_dmslon_4= 'W';
            this.input_ddlat= '';
            this.input_ddlon= '';
            this.on_routedetail= false;
            this.onstart_card= false;
            this.tableKoordinat= null;
            this.tableRoutedetail= null;
            this.isopen_detail= false;
        }
        //------------------------------------------------------------------

        


    }
})
