import { useSnapshotStore } from '@/stores';
import ShowSnapshot from '@/views/showonmap/ShowSnapshot_view.vue';

export default {
  name: "Snapshot",
  components: {
    ShowSnapshot
  },
  data() {
    return {
      snapshot_store: useSnapshotStore(),
      ShowsnapshotComp : ShowSnapshot
    }
  },

  mounted() {
    this.snapshot_store.fetch_snapshot()
  },

  methods: {

  },

  watch: {
  },
  computed: {
  }
};